import { i18n } from "@i18n/i18n";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect } from "react";
import { Alert, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import WarningIcon from "../../../../assets/svg/WarningIcon";
import SharedContext from "../../../navigation/SharedContext";
import { DownloadedBalance, DownloadedBalanceWithoutFinancialData, RootStackParamList } from "../../../../types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { NavigationHelper } from "@helpers/NavigationHelper";
import {
    BalanceXbrlDTOCurrentMoreFieldsStateEnum,
    Company,
    CompanyBalanceSheetCurrentMoreFieldsStateEnum,
    CompanyBalanceSheetTypeEnum,
    CompanyData,
    UserExtendedTypeEnum
} from "@services/apis/generated";
import Icon from "@screens/Tools/Icon";
import ModalSimpleConfirm from "@screens/Modal/ModalSimpleConfirm";
import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { BalanceObj } from "../../../../types";
import ModalSimpleInfoText from "@screens/Modal/ModalSimpleInfoText";

export default function FinancialScore(props: {
    navigation: NativeStackNavigationProp<RootStackParamList, "DashboardCompany" | "DashboardSavedCompany" | "DashboardCompanySearched">,
    onMyCompanies: boolean,
    lastCompanyData: CompanyData,
    balanceObject: BalanceObj,
    company: Company,
}): JSX.Element {

    const sharedContext = React.useContext(SharedContext);
    let [modalVisible, setModalVisible] = React.useState(false);
    const [infoTextVisible, setInfoTextVisible] = React.useState<boolean>(false);
    const [infoText, setInfoText] = React.useState<string>()

    // types = IT_BILANCIO_MICROIMPRESA
    function navigateToMicro() {
        if (isMicroBalance()) {
            NavigationHelper.navigateToCompanyMicro(props.navigation, props.company);
        }
    }

    const onMyCompanyOrConsultant = () => {
        if (props.onMyCompanies || sharedContext.userExtendedMe?.type == UserExtendedTypeEnum.Consultant)
            return true;

        return false;
    }

    /**
     * Check: BilancioAbbreviatoEsercizio or BilancioMicroimpresa
     * @returns boolean 
     */
    function isMicroBalance() {
        if (props.balanceObject && props.balanceObject.lastBalance && (props.balanceObject.lastBalance?.type == CompanyBalanceSheetTypeEnum.BilancioAbbreviatoEsercizio || props.balanceObject.lastBalance?.type == CompanyBalanceSheetTypeEnum.BilancioMicroimpresa)) {
            return true
        }
        return false
    }

    // If the company is my company, or if I am a consultant, and if balance type is micro returns true
    function isMicroBalanceAndCanEdit() {
        if (onMyCompanyOrConsultant() && isMicroBalance())
            return true

        return false;
    }


    // Checks whether the missing data was entered 
    function isMicroComplete() {
        if (props.balanceObject) {
            let lastBalance = props.balanceObject.lastBalance;
            if (props.balanceObject.lastBalance)
                return lastBalance?.spHykeeCreditiCommerciali != null && lastBalance?.spHykeeDebitiCommerciali != null && lastBalance?.spHykeeDebitiFinanziari != null;
        }
    }


    function sortItems(array1: any[], array2: any[]) {
        for (let i = 0; i < array1.length; i++) {
            for (let j = 0; j < array1.length; j++) {
                if (array1[j] < array1[j + 1]) {
                    let temp = array1[j];
                    array1[j] = array1[j + 1];
                    array1[j + 1] = temp;
                    let temp2 = array2[j];
                    array2[j] = array2[j + 1];
                    array2[j + 1] = temp2;
                }
            }
        }
        return array2;
    }

    // If balances were downloaded and the balances' state is Missing, null or undefined returns false
    function balancesCompleted() {
        if (props.balanceObject && props.balanceObject.downloadedBalances && props.balanceObject.downloadedBalances.length > 0) {
            let res = true
            const balanceList: any[] = []
            const balanceYearList: any[] = []
            props.balanceObject.downloadedBalances.forEach((balances: DownloadedBalanceWithoutFinancialData) => {
                balanceList.push(balances)
                balanceYearList.push(balances.anno)
            })
            sortItems(balanceYearList, balanceList)
            for (let i = 0; i < 2; i++) {
                let balanceCurrentFieldsState = balanceList[i].currentMoreFieldsState;
                if (isMicroBalance() && (balanceCurrentFieldsState == CompanyBalanceSheetCurrentMoreFieldsStateEnum.Missing
                    || balanceCurrentFieldsState == null
                    || balanceCurrentFieldsState == undefined)) {
                    res = false
                }
            }
            return res
        } else {
            return false
        }
    }


    //VERIFIED
    // Checks whether the balance sheet is an ordinary balance sheet
    function financialScoreVisible() {
        if (props.balanceObject) {
            let type = props.balanceObject.lastBalance?.type;
            if (props.balanceObject.lastBalance &&
                (type == CompanyBalanceSheetTypeEnum.Bilancio
                    || type == CompanyBalanceSheetTypeEnum.BilancioEsercizio
                    || type == CompanyBalanceSheetTypeEnum.BilancioConsolidatoEsercizio
                    || type == CompanyBalanceSheetTypeEnum.BilancioOrdinarioEsercizio)) {
                return true;
            }
        }
    }

    //UNVERIFIED
    // If the last balance's state is insecure, missing or null/undefined will return true
    const orangeVerification = () => {
        if (props.balanceObject) {
            let moreFieldsState = props.balanceObject.lastBalance?.currentMoreFieldsState;

            return (props.balanceObject.lastBalance
                && moreFieldsState != CompanyBalanceSheetCurrentMoreFieldsStateEnum.Insecure
                && moreFieldsState != CompanyBalanceSheetCurrentMoreFieldsStateEnum.Missing
                && moreFieldsState != null
                && moreFieldsState != undefined)
        }
    }

    //TODO: controllare se sono stati inseriti dei valori
    const balancesDataInsert = () => {
        if (props.balanceObject && props.balanceObject.downloadedBalances) {
            let balances = props.balanceObject.downloadedBalances;
            let dataInsert: boolean = false;

            for (let balance of balances) {
                if (balance.spHykeeCreditiCommerciali != 0 && balance.spHykeeDebitiCommerciali != 0 && balance.spHykeeDebitiFinanziari != 0) {
                    dataInsert = true
                }
            }
        }
    }

    /**
     * returns the correct text for the message in financial score depending on the state of lastbalance
     * 
     * error:'Fill in the Self Assessment to see the Business score and Hykee score',
     * error_data_incomplete: "The financial data needed to calculate the Financial Score are missing",
     * error_search:'This field is not visible for privacy reasons or This Company don\'t complete the Self Assessment',
     */
    function getTextMessageByState(): string {
        if (orangeVerification()) {
            return props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')
        } else {
            return props.onMyCompanies || !isMicroComplete() ? i18n.t('components.business_score.error_data_incomplete') : i18n.t('components.business_score.error_search')
        }
    }

    const checkForNavigate = () => {
        if (props.balanceObject && props.balanceObject.downloadedBalances && props.balanceObject.downloadedBalances.length > 1 && checkRoleAndMyCompany()) {
            if (isMicroBalance() && isMicroComplete()) {
                navigateToMicro();
            } else if (isMicroBalance() && !isMicroComplete()) {
                setModalVisible(true);
            }
        }
    }

    const checkFinancialScore = () => {
        if (!props.lastCompanyData?.financialScore)
            return false;

        return isMicroComplete();
    }

    const checkRoleAndMyCompany = () => {
        if (sharedContext.who == UserExtendedTypeEnum.Company && !props.onMyCompanies) {
            return false
        }
        return true
    }

    const textByUserRole = () => {
        if (sharedContext.who == UserExtendedTypeEnum.Company && !props.onMyCompanies) {
            return i18n.t('components.business_score.error_data_incomplete')
        }
        return i18n.t('components.financial_score.incomplete_insert');
    }

    const financialScoreAlert = () => {
        let type = props.balanceObject.lastBalance?.type;
        if (props.balanceObject.lastBalance && props.onMyCompanies && (type == CompanyBalanceSheetTypeEnum.BilancioAbbreviatoEsercizio || type == CompanyBalanceSheetTypeEnum.BilancioMicroimpresa)) {
            Alert.alert(
                i18n.t('alerts.financial_score_title'),
                i18n.t('alerts.financial_score_message'),
                [
                    {
                        text: i18n.t('alerts.cancel'),
                        onPress: () => console.log("Cancel Pressed"),

                    },
                    { text: i18n.t('alerts.ok'), onPress: () => navigateToMicro() }
                ]
            );
        }
    }

    function drawFinancialScoreInfo(): React.ReactNode {
        return (
            <View>
                <TouchableOpacity onPress={() => {
                    setInfoText(i18n.t("components.financial_score.info"))
                    setInfoTextVisible(!infoTextVisible)
                }}>
                    <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: "center", alignSelf: "center", alignContent: "center" }}>
                        <Text style={{ fontStyle: "italic", fontFamily: "poppins", color: "white", fontSize: 11 }}>
                            {i18n.t("components.financial_score.meaning")}
                        </Text>
                        <View style={{ padding: 10, marginLeft: -5 }}>
                            <Icon name="info-circle" size={12} color={"white"} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <LinearGradient
            colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
            locations={[0.4, 0.8]}
            start={{ x: 1, y: 1 }}
            end={{ x: 0, y: 0 }}
            style={styles.borderGradient}
        >
            <LinearGradient
                colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.containerBack}
            >

                <TouchableOpacity onPress={() => {
                    checkForNavigate();
                }}>
                    {modalVisible && isMicroBalance() &&
                        <ModalSimpleConfirm
                            msg={i18n.t('alerts.financial_score_title')}
                            msg2={i18n.t('alerts.financial_score_message')}
                            action={() => { navigateToMicro() }}
                            visible={(visible) => { setModalVisible(false) }}

                        />
                    }

                    <View style={{}}>

                        {props.lastCompanyData?.financialScore != undefined &&
                            <View>

                                {/* FINANCIAL SCORE */}
                                <View style={styles.containerLabel}>
                                    <View>
                                        <Text style={styles.label}>
                                            {i18n.t('components.financial_score.title')}
                                        </Text>
                                        {drawFinancialScoreInfo()}
                                    </View>

                                </View>



                                <View style={styles.containerScore}>

                                    {/* TITLE */}
                                    <View>
                                        {/* .incomplete: The data to calculate the score is not complete, do you want to insert the missing data? */}
                                        {isMicroBalance() && !balancesCompleted() ?
                                            <Text style={styles.labelScore13}>{textByUserRole()}</Text> :
                                            <Text style={styles.labelScore}>{props.lastCompanyData?.financialScore.toFixed(1) + "%"}</Text>}
                                    </View>

                                    {/* DOT - UNVERIFIED     */}
                                    {isMicroBalance() &&
                                        <>
                                            <Text style={orangeVerification() ? [styles.labelBalanceVerified] : [styles.labelBalanceVerified, { color: "red" }]}>
                                                <Icon name={'circle'} color={orangeVerification() ? "orange" : "red"} />
                                                {i18n.t('components.financial_score.unverified')}
                                            </Text>

                                            {checkRoleAndMyCompany() &&
                                                <Text style={[styles.labelBalanceVerified, { color: 'white', textDecorationLine: 'underline' }]}>
                                                    {!balancesCompleted() ? i18n.t('components.financial_score.enter_data') : i18n.t('components.financial_score.show_data')}
                                                </Text>}
                                        </>}

                                    {/* DOT - VERIFIED - ORDINARY */}
                                    {(props.balanceObject && financialScoreVisible()) &&
                                        <>
                                            <Text style={[styles.labelBalanceVerified, { color: BackgroundColorButtonGreen() }]}>
                                                <Icon name={'circle'} color={BackgroundColorButtonGreen()} />
                                                {i18n.t('components.financial_score.verified')}
                                            </Text>
                                        </>
                                    }

                                    {/* YEAR */}
                                    {(props.balanceObject && props.balanceObject.lastBalance?.year) &&
                                        <Text style={!balancesCompleted() ? { display: "none" } : styles.labelScoreDate}>
                                            {props.balanceObject.lastBalance?.year}
                                        </Text>
                                    }


                                </View>
                            </View>
                        }

                        {props.lastCompanyData?.financialScore == undefined &&
                            <View style={styles.containerError}>
                                <View style={styles.container}>
                                    <Text style={styles.label}>
                                        {i18n.t('components.financial_score.title')}
                                    </Text>
                                    {drawFinancialScoreInfo()}
                                </View>
                                <View style={styles.containerIconError}>
                                    <WarningIcon width={60} height={60} />
                                </View>
                                <View style={[styles.containerIconError]}>
                                    <Text style={styles.labelError}>
                                        {getTextMessageByState()}
                                    </Text>
                                </View>
                            </View>
                        }


                    </View>

                </TouchableOpacity>
                {infoTextVisible && <ModalSimpleInfoText message={infoText} setVisible={(infoText) => (infoText != null ? setInfoTextVisible(infoText) : null)} />}

            </LinearGradient>
        </LinearGradient>

    );
}



const styles = StyleSheet.create({
    container: {
    },
    containerError: {
        flexDirection: "column",
    },
    labelError: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        padding: 5,
    },
    containerIconError: {
        flexDirection: "column",
        alignSelf: 'center',
        justifyContent: 'center'
    },
    borderGradient: {
        flex: 0.49,
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        flex: 1,
        flexDirection: "column",
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
    },
    labelScore: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 40,
        padding: 10,
    },
    labelScore13: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        padding: 10,
    },
    labelBalanceVerified: {
        color: 'orange',
        fontFamily: "poppins",
        fontSize: 15,
    },
    labelScoreDate: {
        color: "white",
        fontFamily: "poppins",
        textAlign: 'center',
        fontSize: 16,
        padding: 10,
    },
    containerLabel: {
        flexDirection: "row"
    },
    containerScore: {
        alignItems: 'center',
        alignSelf: 'center',

    },
    label: {
        textAlign: "left",
        color: "white",
        fontFamily: "poppins",
        fontSize: 16,
        marginLeft: 15
    },
    tooltipText: {
        fontFamily: "poppins",
        margin: 5,
        textAlign: 'center',
        fontWeight: '300',
        color: "white"
    }
});


