// 
// 
//
import { NavigationHelper } from '@helpers/NavigationHelper';
import Icon from '@screens/Tools/Icon';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Linking, Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import CreditsSvg from '../../../../assets/svg/CreditsSvg';
import { RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import AvailableCredits from './AvailableCredits';
import AvaiableResearch from './AvailableResearch';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import { BackgroundColorButtonGreen } from '@components/ColorTheme';

export default function PlanCredits({ navigation, route }: RootStackScreenProps<'PlanCredits'>) {

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
        <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
          <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={'#00CC83'} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>
                    {i18n.t('components.credits.credits')}
                </Text>
            </View>
            <ScrollView style={hykeeStyle.containerScroll}>
                <View style={styles.container}>
                    <View style={styles.contentContainer}>
                        <Text style={styles.contentText}>
                            {i18n.t('components.credits.download_chamber_data')}
                        </Text>
                        <Text style={styles.contentText}>
                            {i18n.t('components.credits.try_the_platform_features')}
                        </Text>
                    </View>
                    <View style={styles.contentContainerButton}>
                        <TouchableOpacity style={styles.activePlan} onPress={() => Linking.openURL('http://rbhq.it')}>
                            <Text style={styles.buttonTextContainer}>
                                {i18n.t('components.credits.buttons.active_the_easy_plan')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </LinearGradient>
    );

}

const styles = StyleSheet.create({
    containerScrollView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        padding: 20,
        flexDirection: 'column',
        fontFamily: 'poppins',
        justifyContent: 'space-evenly'
    },
    contentLabel: {
        flex: Platform.OS == 'ios' ? 0.3 : 0.25,
        marginBottom: 10
    },
    contentTitle: {
        height: '10%',
        flex: 0.2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    goBack: {
        paddingRight: 10
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainerButton: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentText: {
        color: 'white',
        fontSize: 18,
        paddingBottom: 40,
        fontFamily: 'poppins',

    },
    activePlan: {
        backgroundColor: BackgroundColorButtonGreen(),
        alignItems:'center',
        borderRadius:10,
    },
    buttonTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        fontSize:18,
        fontFamily: 'poppins',
        color: 'white'
    },
    title: {
        alignSelf: 'center',
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
});