/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface BudgetHandling
 */
export interface BudgetHandling {
    /**
     * 
     * @type {number}
     * @memberof BudgetHandling
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BudgetHandling
     */
    movement?: BudgetHandlingMovementEnum;
    /**
     * 
     * @type {string}
     * @memberof BudgetHandling
     */
    type?: BudgetHandlingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BudgetHandling
     */
    handlingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof BudgetHandling
     */
    finalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BudgetHandling
     */
    description?: string;
    /**
     * 
     * @type {User}
     * @memberof BudgetHandling
     */
    user?: User;
}

/**
* @export
* @enum {string}
*/
export enum BudgetHandlingMovementEnum {
    Increase = 'INCREASE',
    Decrease = 'DECREASE'
}/**
* @export
* @enum {string}
*/
export enum BudgetHandlingTypeEnum {
    Credit = 'CREDIT',
    Search = 'SEARCH',
    ConsultationHour = 'CONSULTATION_HOUR'
}

export function BudgetHandlingFromJSON(json: any): BudgetHandling {
    return BudgetHandlingFromJSONTyped(json, false);
}

export function BudgetHandlingFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetHandling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'movement': !exists(json, 'movement') ? undefined : json['movement'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'handlingAmount': !exists(json, 'handlingAmount') ? undefined : json['handlingAmount'],
        'finalAmount': !exists(json, 'finalAmount') ? undefined : json['finalAmount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function BudgetHandlingToJSON(value?: BudgetHandling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'movement': value.movement,
        'type': value.type,
        'handlingAmount': value.handlingAmount,
        'finalAmount': value.finalAmount,
        'description': value.description,
        'user': UserToJSON(value.user),
    };
}


