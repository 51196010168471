import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { AlertHelper } from "@helpers/AlertHelper";
import { Company, CompanyData, MarketCompetitorResponseDTO, PlanNameEnum } from "@services/apis/generated";
import React, { useContext, useEffect } from "react";
import { Linking, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";
import { CompetitorsList } from "../../../../../types";
import { i18n } from "../../../../i18n/i18n";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import AddNewMarketCompetitor from "./AddNewMarketCompetitor";
import CollapsibleMarketCompetitors from "./CollapsibleMarketCompetitors";


export default function MarketCompetitors(props: { competitors?: MarketCompetitorResponseDTO[], goToCompany: (company: Company) => void, company: Company }) {
    const url = "http://www.rbhq.it"
    const [collapsed, setCollapsed] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);
    const [companyCompetitors, setCompanyCompetitors] = React.useState<MarketCompetitorResponseDTO[]>([]);

    const sharedContext = useContext(SharedContext);

    const buyPlan = async (plan: PlanNameEnum) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
            if (plan == PlanNameEnum.Plus) {
                await Linking.openURL(url);
            } else {
                await Linking.openURL(url);
            }
        } else {
            AlertHelper.showSimpleErrorAlert()
        }
    }

    const toggleExpanded = () => {
        setCollapsed(!collapsed);
    };

    const hasPlanWithName = (name: PlanNameEnum) => {
        return sharedContext.myUserPlans && sharedContext.myUserPlans.find(up => up.plan && up.plan.name == name) != null;
    };


    const hasPlanForMarketCompetitors = () => {
        return hasPlanWithName(PlanNameEnum.Plus)
            || hasPlanWithName(PlanNameEnum.Advisory)
            || hasPlanWithName(PlanNameEnum.Analyst);
    }

    useEffect(() => {
        if (props.competitors && props.company)
            setCompanyCompetitors(props.competitors?.filter(mkr => mkr.marketCompetitor?.reference?.id == props.company.id));
    }, [props.competitors, props.company]);
    
    return (
        <View style={[styles.label, { backgroundColor: (collapsed ? '#222222' : '#292929') }]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style={styles.textLabel}>
                        {i18n.t('components.headers.market')}
                    </Text>
                    <Icon name={collapsed ? "angle-down" : "angle-up"} color="white" />
                </View>
            </TouchableOpacity>
            <Collapsible
                duration={1000}
                collapsed={collapsed}
                style={styles.contentAccordion}
            >
                {hasPlanForMarketCompetitors()
                    && companyCompetitors && companyCompetitors?.length > 0 && //se ho una lista di market competitor e il piano plus/analyst/Advisory attivo allora visualizzo la lista con la sua gestione
                    <CollapsibleMarketCompetitors company={props.company} competitors={companyCompetitors} goToCompany={(company) => props.goToCompany(company)} />}

                {!hasPlanForMarketCompetitors() && // Se non è sottoscritto al piano plus/analyst/Advisory viene reindirizzato a comprare il piano
                    <View style={styles.containerCollapse}>
                        <Text style={styles.labelInfoPlan}>
                            {i18n.t('components.market_competitors.info_plan')}
                        </Text>
                        <TouchableOpacity
                            style={styles.buttonPlan}
                            onPress={() => {
                                buyPlan(PlanNameEnum.Plus)
                            }}
                        >
                            <Text style={styles.labelInfoPlan}>
                                {i18n.t('components.market_competitors.button_plan')}
                            </Text>
                        </TouchableOpacity>
                    </View>}

                {hasPlanForMarketCompetitors()
                    && companyCompetitors && companyCompetitors.length == 0 && //Se è attivo il piano plus/analyst/Advisory ma non ho aggiunto nessuno marketCompetitor allora visualizzo una schermata di aggiunta
                    <AddNewMarketCompetitor company={props.company} competitors={props.competitors}/>}
            </Collapsible>
        </View>
    );
}

const styles = StyleSheet.create({

    header: {
        flexDirection: "row",
        flex: 1,
        alignItems: 'center',
    },
    contentAccordion: {
        height: 'auto',
        flexDirection: "column",
    },
    containerCollapse: {
        marginVertical: 20,
        flexDirection: 'column',
        alignItems: 'center'
    },
    label: {
        marginBottom: 10,
        borderRadius: 5,
        display: "flex",
    },
    textLabel: {
        paddingLeft: 15,
        paddingVertical: 10,
        flex: 0.95,
        alignSelf: "flex-start",
        color: "white",
        textAlign: 'left',
        fontWeight: 'bold',
    },
    labelInfoPlan: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30,
    },
    buttonPlan: {
        marginVertical: 7,
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius: 5,
        alignItems: 'center',
    },
    icon: {
        color: 'white',
        paddingVertical: 5,
        marginLeft: -12,
        fontSize: 10,
        fontFamily: 'poppins'
    },

});