/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BudgetHandling,
    BudgetHandlingFromJSON,
    BudgetHandlingToJSON,
} from '../models';

export interface CreateBudgetHandlingRequest {
    budgetHandling: BudgetHandling;
}

export interface DeleteBudgetHandlingRequest {
    id: number;
}

export interface GetBudgetHandlingRequest {
    id: number;
}

export interface PartialUpdateBudgetHandlingRequest {
    id: number;
    budgetHandling: BudgetHandling;
}

export interface UpdateBudgetHandlingRequest {
    id: number;
    budgetHandling: BudgetHandling;
}

/**
 * 
 */
export class BudgetHandlingResourceApi extends runtime.BaseAPI {

    /**
     */
    async createBudgetHandlingRaw(requestParameters: CreateBudgetHandlingRequest): Promise<runtime.ApiResponse<BudgetHandling>> {
        if (requestParameters.budgetHandling === null || requestParameters.budgetHandling === undefined) {
            throw new runtime.RequiredError('budgetHandling','Required parameter requestParameters.budgetHandling was null or undefined when calling createBudgetHandling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/budget-handlings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BudgetHandlingToJSON(requestParameters.budgetHandling),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetHandlingFromJSON(jsonValue));
    }

    /**
     */
    async createBudgetHandling(requestParameters: CreateBudgetHandlingRequest): Promise<BudgetHandling> {
        const response = await this.createBudgetHandlingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteBudgetHandlingRaw(requestParameters: DeleteBudgetHandlingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBudgetHandling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/budget-handlings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBudgetHandling(requestParameters: DeleteBudgetHandlingRequest): Promise<void> {
        await this.deleteBudgetHandlingRaw(requestParameters);
    }

    /**
     */
    async getAllBudgetHandlingsRaw(): Promise<runtime.ApiResponse<Array<BudgetHandling>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/budget-handlings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BudgetHandlingFromJSON));
    }

    /**
     */
    async getAllBudgetHandlings(): Promise<Array<BudgetHandling>> {
        const response = await this.getAllBudgetHandlingsRaw();
        return await response.value();
    }

    /**
     */
    async getBudgetHandlingRaw(requestParameters: GetBudgetHandlingRequest): Promise<runtime.ApiResponse<BudgetHandling>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBudgetHandling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/budget-handlings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetHandlingFromJSON(jsonValue));
    }

    /**
     */
    async getBudgetHandling(requestParameters: GetBudgetHandlingRequest): Promise<BudgetHandling> {
        const response = await this.getBudgetHandlingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateBudgetHandlingRaw(requestParameters: PartialUpdateBudgetHandlingRequest): Promise<runtime.ApiResponse<BudgetHandling>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateBudgetHandling.');
        }

        if (requestParameters.budgetHandling === null || requestParameters.budgetHandling === undefined) {
            throw new runtime.RequiredError('budgetHandling','Required parameter requestParameters.budgetHandling was null or undefined when calling partialUpdateBudgetHandling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/budget-handlings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BudgetHandlingToJSON(requestParameters.budgetHandling),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetHandlingFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateBudgetHandling(requestParameters: PartialUpdateBudgetHandlingRequest): Promise<BudgetHandling> {
        const response = await this.partialUpdateBudgetHandlingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateBudgetHandlingRaw(requestParameters: UpdateBudgetHandlingRequest): Promise<runtime.ApiResponse<BudgetHandling>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBudgetHandling.');
        }

        if (requestParameters.budgetHandling === null || requestParameters.budgetHandling === undefined) {
            throw new runtime.RequiredError('budgetHandling','Required parameter requestParameters.budgetHandling was null or undefined when calling updateBudgetHandling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/budget-handlings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BudgetHandlingToJSON(requestParameters.budgetHandling),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetHandlingFromJSON(jsonValue));
    }

    /**
     */
    async updateBudgetHandling(requestParameters: UpdateBudgetHandlingRequest): Promise<BudgetHandling> {
        const response = await this.updateBudgetHandlingRaw(requestParameters);
        return await response.value();
    }

}
