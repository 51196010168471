/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoteriPersona
 */
export interface PoteriPersona {
    /**
     * 
     * @type {string}
     * @memberof PoteriPersona
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof PoteriPersona
     */
    ppoteri?: string;
}

export function PoteriPersonaFromJSON(json: any): PoteriPersona {
    return PoteriPersonaFromJSONTyped(json, false);
}

export function PoteriPersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoteriPersona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'ppoteri': !exists(json, 'ppoteri') ? undefined : json['ppoteri'],
    };
}

export function PoteriPersonaToJSON(value?: PoteriPersona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'ppoteri': value.ppoteri,
    };
}


