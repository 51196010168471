/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CollegioSindacale,
    CollegioSindacaleFromJSON,
    CollegioSindacaleFromJSONTyped,
    CollegioSindacaleToJSON,
    CollegioSindacaleInCarica,
    CollegioSindacaleInCaricaFromJSON,
    CollegioSindacaleInCaricaFromJSONTyped,
    CollegioSindacaleInCaricaToJSON,
    FormeAmministrative,
    FormeAmministrativeFromJSON,
    FormeAmministrativeFromJSONTyped,
    FormeAmministrativeToJSON,
    FormeAmministrativeInCarica,
    FormeAmministrativeInCaricaFromJSON,
    FormeAmministrativeInCaricaFromJSONTyped,
    FormeAmministrativeInCaricaToJSON,
    NSoci,
    NSociFromJSON,
    NSociFromJSONTyped,
    NSociToJSON,
    OrganiControlloInCarica,
    OrganiControlloInCaricaFromJSON,
    OrganiControlloInCaricaFromJSONTyped,
    OrganiControlloInCaricaToJSON,
    SistemaAmministrazione,
    SistemaAmministrazioneFromJSON,
    SistemaAmministrazioneFromJSONTyped,
    SistemaAmministrazioneToJSON,
    SoggettoControlloContabile,
    SoggettoControlloContabileFromJSON,
    SoggettoControlloContabileFromJSONTyped,
    SoggettoControlloContabileToJSON,
} from './';

/**
 * 
 * @export
 * @interface AmministrazioneControllo
 */
export interface AmministrazioneControllo {
    /**
     * 
     * @type {SistemaAmministrazione}
     * @memberof AmministrazioneControllo
     */
    sistemaAmministrazione?: SistemaAmministrazione;
    /**
     * 
     * @type {SoggettoControlloContabile}
     * @memberof AmministrazioneControllo
     */
    soggettoControlloContabile?: SoggettoControlloContabile;
    /**
     * 
     * @type {FormeAmministrative}
     * @memberof AmministrazioneControllo
     */
    formeAmministrative?: FormeAmministrative;
    /**
     * 
     * @type {FormeAmministrativeInCarica}
     * @memberof AmministrazioneControllo
     */
    formeAmministrativeInCarica?: FormeAmministrativeInCarica;
    /**
     * 
     * @type {OrganiControlloInCarica}
     * @memberof AmministrazioneControllo
     */
    organiControlloInCarica?: OrganiControlloInCarica;
    /**
     * 
     * @type {CollegioSindacale}
     * @memberof AmministrazioneControllo
     */
    collegioSindacale?: CollegioSindacale;
    /**
     * 
     * @type {CollegioSindacaleInCarica}
     * @memberof AmministrazioneControllo
     */
    collegioSindacaleInCarica?: CollegioSindacaleInCarica;
    /**
     * 
     * @type {NSoci}
     * @memberof AmministrazioneControllo
     */
    nsoci?: NSoci;
}

export function AmministrazioneControlloFromJSON(json: any): AmministrazioneControllo {
    return AmministrazioneControlloFromJSONTyped(json, false);
}

export function AmministrazioneControlloFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmministrazioneControllo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sistemaAmministrazione': !exists(json, 'sistemaAmministrazione') ? undefined : SistemaAmministrazioneFromJSON(json['sistemaAmministrazione']),
        'soggettoControlloContabile': !exists(json, 'soggettoControlloContabile') ? undefined : SoggettoControlloContabileFromJSON(json['soggettoControlloContabile']),
        'formeAmministrative': !exists(json, 'formeAmministrative') ? undefined : FormeAmministrativeFromJSON(json['formeAmministrative']),
        'formeAmministrativeInCarica': !exists(json, 'formeAmministrativeInCarica') ? undefined : FormeAmministrativeInCaricaFromJSON(json['formeAmministrativeInCarica']),
        'organiControlloInCarica': !exists(json, 'organiControlloInCarica') ? undefined : OrganiControlloInCaricaFromJSON(json['organiControlloInCarica']),
        'collegioSindacale': !exists(json, 'collegioSindacale') ? undefined : CollegioSindacaleFromJSON(json['collegioSindacale']),
        'collegioSindacaleInCarica': !exists(json, 'collegioSindacaleInCarica') ? undefined : CollegioSindacaleInCaricaFromJSON(json['collegioSindacaleInCarica']),
        'nsoci': !exists(json, 'nsoci') ? undefined : NSociFromJSON(json['nsoci']),
    };
}

export function AmministrazioneControlloToJSON(value?: AmministrazioneControllo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sistemaAmministrazione': SistemaAmministrazioneToJSON(value.sistemaAmministrazione),
        'soggettoControlloContabile': SoggettoControlloContabileToJSON(value.soggettoControlloContabile),
        'formeAmministrative': FormeAmministrativeToJSON(value.formeAmministrative),
        'formeAmministrativeInCarica': FormeAmministrativeInCaricaToJSON(value.formeAmministrativeInCarica),
        'organiControlloInCarica': OrganiControlloInCaricaToJSON(value.organiControlloInCarica),
        'collegioSindacale': CollegioSindacaleToJSON(value.collegioSindacale),
        'collegioSindacaleInCarica': CollegioSindacaleInCaricaToJSON(value.collegioSindacaleInCarica),
        'nsoci': NSociToJSON(value.nsoci),
    };
}


