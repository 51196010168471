/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LongFilter,
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomCompanySurveyQuestionResCriteria
 */
export interface CustomCompanySurveyQuestionResCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyQuestionResCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyQuestionResCriteria
     */
    surveyQuestionId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyQuestionResCriteria
     */
    surveyQuestionResponseId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyQuestionResCriteria
     */
    companySurveyId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomCompanySurveyQuestionResCriteria
     */
    distinct?: boolean;
}

export function CustomCompanySurveyQuestionResCriteriaFromJSON(json: any): CustomCompanySurveyQuestionResCriteria {
    return CustomCompanySurveyQuestionResCriteriaFromJSONTyped(json, false);
}

export function CustomCompanySurveyQuestionResCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomCompanySurveyQuestionResCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'surveyQuestionId': !exists(json, 'surveyQuestionId') ? undefined : LongFilterFromJSON(json['surveyQuestionId']),
        'surveyQuestionResponseId': !exists(json, 'surveyQuestionResponseId') ? undefined : LongFilterFromJSON(json['surveyQuestionResponseId']),
        'companySurveyId': !exists(json, 'companySurveyId') ? undefined : LongFilterFromJSON(json['companySurveyId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomCompanySurveyQuestionResCriteriaToJSON(value?: CustomCompanySurveyQuestionResCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'surveyQuestionId': LongFilterToJSON(value.surveyQuestionId),
        'surveyQuestionResponseId': LongFilterToJSON(value.surveyQuestionResponseId),
        'companySurveyId': LongFilterToJSON(value.companySurveyId),
        'distinct': value.distinct,
    };
}


