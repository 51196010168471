/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Modifica,
    ModificaFromJSON,
    ModificaFromJSONTyped,
    ModificaToJSON,
} from './';

/**
 * 
 * @export
 * @interface FinanziamentoSpecificoAffare
 */
export interface FinanziamentoSpecificoAffare {
    /**
     * 
     * @type {string}
     * @memberof FinanziamentoSpecificoAffare
     */
    costituzione?: string;
    /**
     * 
     * @type {Modifica}
     * @memberof FinanziamentoSpecificoAffare
     */
    modifica?: Modifica;
    /**
     * 
     * @type {string}
     * @memberof FinanziamentoSpecificoAffare
     */
    cessazione?: string;
}

export function FinanziamentoSpecificoAffareFromJSON(json: any): FinanziamentoSpecificoAffare {
    return FinanziamentoSpecificoAffareFromJSONTyped(json, false);
}

export function FinanziamentoSpecificoAffareFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinanziamentoSpecificoAffare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costituzione': !exists(json, 'costituzione') ? undefined : json['costituzione'],
        'modifica': !exists(json, 'modifica') ? undefined : ModificaFromJSON(json['modifica']),
        'cessazione': !exists(json, 'cessazione') ? undefined : json['cessazione'],
    };
}

export function FinanziamentoSpecificoAffareToJSON(value?: FinanziamentoSpecificoAffare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costituzione': value.costituzione,
        'modifica': ModificaToJSON(value.modifica),
        'cessazione': value.cessazione,
    };
}


