/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Carica
 */
export interface Carica {
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtIscrizioneLibroSoci?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtAttoNomina?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtNomina?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtFine?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    descrizioneDurata?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtRiferimentoBilancio?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    dtPresentazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    nanniEsercizio?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    ccarica?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    pcarica?: string;
    /**
     * 
     * @type {string}
     * @memberof Carica
     */
    cdurata?: string;
}

export function CaricaFromJSON(json: any): Carica {
    return CaricaFromJSONTyped(json, false);
}

export function CaricaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Carica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'dtIscrizioneLibroSoci': !exists(json, 'dtIscrizioneLibroSoci') ? undefined : json['dtIscrizioneLibroSoci'],
        'dtAttoNomina': !exists(json, 'dtAttoNomina') ? undefined : json['dtAttoNomina'],
        'dtNomina': !exists(json, 'dtNomina') ? undefined : json['dtNomina'],
        'dtFine': !exists(json, 'dtFine') ? undefined : json['dtFine'],
        'descrizioneDurata': !exists(json, 'descrizioneDurata') ? undefined : json['descrizioneDurata'],
        'dtRiferimentoBilancio': !exists(json, 'dtRiferimentoBilancio') ? undefined : json['dtRiferimentoBilancio'],
        'dtPresentazione': !exists(json, 'dtPresentazione') ? undefined : json['dtPresentazione'],
        'nanniEsercizio': !exists(json, 'nanniEsercizio') ? undefined : json['nanniEsercizio'],
        'ccarica': !exists(json, 'ccarica') ? undefined : json['ccarica'],
        'pcarica': !exists(json, 'pcarica') ? undefined : json['pcarica'],
        'cdurata': !exists(json, 'cdurata') ? undefined : json['cdurata'],
    };
}

export function CaricaToJSON(value?: Carica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'dtIscrizione': value.dtIscrizione,
        'dtIscrizioneLibroSoci': value.dtIscrizioneLibroSoci,
        'dtAttoNomina': value.dtAttoNomina,
        'dtNomina': value.dtNomina,
        'dtFine': value.dtFine,
        'descrizioneDurata': value.descrizioneDurata,
        'dtRiferimentoBilancio': value.dtRiferimentoBilancio,
        'dtPresentazione': value.dtPresentazione,
        'nanniEsercizio': value.nanniEsercizio,
        'ccarica': value.ccarica,
        'pcarica': value.pcarica,
        'cdurata': value.cdurata,
    };
}


