import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { customCompanySearchedResourceApi, customUserResourceApi } from '@services/apis/ApiConfiguration';
import { CompanySaved, CompanySearched } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, Text, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../../../../types';
import SharedContext from '../../../navigation/SharedContext';
import FavoriteCompanies from './FavoriteCompanies';
import PreviousSearches from './PreviousSearches';
import Colors from "../../../../constants/Colors";
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";

export default function SavedPartner({ navigation, route }: RootStackScreenProps<'SavedPartner'>) {

  const sharedContext = React.useContext(SharedContext);

  const [companiesSearched, setCompaniesSearched] = React.useState<CompanySearched[]>([]);

  const goToDashboardSavedCompany = (companySelected: CompanySaved | CompanySearched): void => {
    sharedContext.setSelectedCompany(companySelected.company)
    sharedContext.setSelectedCompanyLastData(companySelected.companyData)
    NavigationHelper.navigateToDashboardSavedCompany(navigation, companySelected.company?.id!);
  }

  useFocusEffect(
    useCallback(() => {
      getPreviousSearches();
    }, [])
  );

  const getPreviousSearches = (): void => {
    BaseAPIManager.contextLoadingCallback(true);
    customUserResourceApi.getMe().then((userMe) => {
      customCompanySearchedResourceApi.getAllCompanySearchedsFilter({ customCompanySearchedCriteria: { userId: { equals: userMe.id } } }).then((companies) => {
        companies.sort((a, b) => a.date! < b.date! ? 1 : -1);
        setCompaniesSearched(companies);
        BaseAPIManager.contextLoadingCallback(false);
      }).catch((error) => { AlertHelper.showSimpleErrorAlert(); });
    }).catch((error) => { AlertHelper.showSimpleErrorAlert(); });
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <ScrollView style={styles.container}>
        <Text style={styles.title}>
          {i18n.t('screens.saved_companies.title')}
        </Text>
        <FavoriteCompanies goToCompany={(companySelected) => goToDashboardSavedCompany(companySelected)} />
        <PreviousSearches companiesSearched={companiesSearched!} goToCompany={(companySelected) => goToDashboardSavedCompany(companySelected)} />
      </ScrollView>
    </LinearGradient>
  );
}


const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    alignSelf: 'flex-start',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    marginVertical: 30,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
