/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstantFilter,
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
    LongFilter,
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomCompanySearchedCriteria
 */
export interface CustomCompanySearchedCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySearchedCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomCompanySearchedCriteria
     */
    date?: InstantFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySearchedCriteria
     */
    userId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySearchedCriteria
     */
    companyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySearchedCriteria
     */
    companyDataId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomCompanySearchedCriteria
     */
    distinct?: boolean;
}

export function CustomCompanySearchedCriteriaFromJSON(json: any): CustomCompanySearchedCriteria {
    return CustomCompanySearchedCriteriaFromJSONTyped(json, false);
}

export function CustomCompanySearchedCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomCompanySearchedCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'date': !exists(json, 'date') ? undefined : InstantFilterFromJSON(json['date']),
        'userId': !exists(json, 'userId') ? undefined : LongFilterFromJSON(json['userId']),
        'companyId': !exists(json, 'companyId') ? undefined : LongFilterFromJSON(json['companyId']),
        'companyDataId': !exists(json, 'companyDataId') ? undefined : LongFilterFromJSON(json['companyDataId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomCompanySearchedCriteriaToJSON(value?: CustomCompanySearchedCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'date': InstantFilterToJSON(value.date),
        'userId': LongFilterToJSON(value.userId),
        'companyId': LongFilterToJSON(value.companyId),
        'companyDataId': LongFilterToJSON(value.companyDataId),
        'distinct': value.distinct,
    };
}


