/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Sezione
 */
export interface Sezione {
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    descrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    cciaaAa?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    dtDecorrenza?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    fcoltivatoreDiretto?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    fattesaDecisione?: string;
    /**
     * 
     * @type {string}
     * @memberof Sezione
     */
    naa?: string;
}

export function SezioneFromJSON(json: any): Sezione {
    return SezioneFromJSONTyped(json, false);
}

export function SezioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sezione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'c': !exists(json, 'c') ? undefined : json['c'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'cciaaAa': !exists(json, 'cciaaAa') ? undefined : json['cciaaAa'],
        'dtDecorrenza': !exists(json, 'dtDecorrenza') ? undefined : json['dtDecorrenza'],
        'fcoltivatoreDiretto': !exists(json, 'fcoltivatoreDiretto') ? undefined : json['fcoltivatoreDiretto'],
        'fattesaDecisione': !exists(json, 'fattesaDecisione') ? undefined : json['fattesaDecisione'],
        'naa': !exists(json, 'naa') ? undefined : json['naa'],
    };
}

export function SezioneToJSON(value?: Sezione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'c': value.c,
        'descrizione': value.descrizione,
        'dtIscrizione': value.dtIscrizione,
        'cciaaAa': value.cciaaAa,
        'dtDecorrenza': value.dtDecorrenza,
        'fcoltivatoreDiretto': value.fcoltivatoreDiretto,
        'fattesaDecisione': value.fattesaDecisione,
        'naa': value.naa,
    };
}


