/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BooleanFilter,
    BooleanFilterFromJSON,
    BooleanFilterFromJSONTyped,
    BooleanFilterToJSON,
    CompanySectorEnumFilter,
    CompanySectorEnumFilterFromJSON,
    CompanySectorEnumFilterFromJSONTyped,
    CompanySectorEnumFilterToJSON,
    LongFilter,
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
    StringFilter,
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyCriteria
 */
export interface CompanyCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CompanyCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CompanyCriteria
     */
    vatNumber?: StringFilter;
    /**
     * 
     * @type {BooleanFilter}
     * @memberof CompanyCriteria
     */
    registeredBySearch?: BooleanFilter;
    /**
     * 
     * @type {CompanySectorEnumFilter}
     * @memberof CompanyCriteria
     */
    sector?: CompanySectorEnumFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CompanyCriteria
     */
    partnerProgramDescriptionText?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    companyDataId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    companySurveyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    companyBalanceSheetId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    activityId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    consultedId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    userCompanyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    userConsultantId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    companySavedId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    companySearchedId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    insightId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    consultantId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    postId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CompanyCriteria
     */
    receivedInsightId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyCriteria
     */
    distinct?: boolean;
}

export function CompanyCriteriaFromJSON(json: any): CompanyCriteria {
    return CompanyCriteriaFromJSONTyped(json, false);
}

export function CompanyCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'vatNumber': !exists(json, 'vatNumber') ? undefined : StringFilterFromJSON(json['vatNumber']),
        'registeredBySearch': !exists(json, 'registeredBySearch') ? undefined : BooleanFilterFromJSON(json['registeredBySearch']),
        'sector': !exists(json, 'sector') ? undefined : CompanySectorEnumFilterFromJSON(json['sector']),
        'partnerProgramDescriptionText': !exists(json, 'partnerProgramDescriptionText') ? undefined : StringFilterFromJSON(json['partnerProgramDescriptionText']),
        'companyDataId': !exists(json, 'companyDataId') ? undefined : LongFilterFromJSON(json['companyDataId']),
        'companySurveyId': !exists(json, 'companySurveyId') ? undefined : LongFilterFromJSON(json['companySurveyId']),
        'companyBalanceSheetId': !exists(json, 'companyBalanceSheetId') ? undefined : LongFilterFromJSON(json['companyBalanceSheetId']),
        'activityId': !exists(json, 'activityId') ? undefined : LongFilterFromJSON(json['activityId']),
        'consultedId': !exists(json, 'consultedId') ? undefined : LongFilterFromJSON(json['consultedId']),
        'userCompanyId': !exists(json, 'userCompanyId') ? undefined : LongFilterFromJSON(json['userCompanyId']),
        'userConsultantId': !exists(json, 'userConsultantId') ? undefined : LongFilterFromJSON(json['userConsultantId']),
        'companySavedId': !exists(json, 'companySavedId') ? undefined : LongFilterFromJSON(json['companySavedId']),
        'companySearchedId': !exists(json, 'companySearchedId') ? undefined : LongFilterFromJSON(json['companySearchedId']),
        'insightId': !exists(json, 'insightId') ? undefined : LongFilterFromJSON(json['insightId']),
        'consultantId': !exists(json, 'consultantId') ? undefined : LongFilterFromJSON(json['consultantId']),
        'postId': !exists(json, 'postId') ? undefined : LongFilterFromJSON(json['postId']),
        'receivedInsightId': !exists(json, 'receivedInsightId') ? undefined : LongFilterFromJSON(json['receivedInsightId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CompanyCriteriaToJSON(value?: CompanyCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'name': StringFilterToJSON(value.name),
        'vatNumber': StringFilterToJSON(value.vatNumber),
        'registeredBySearch': BooleanFilterToJSON(value.registeredBySearch),
        'sector': CompanySectorEnumFilterToJSON(value.sector),
        'partnerProgramDescriptionText': StringFilterToJSON(value.partnerProgramDescriptionText),
        'companyDataId': LongFilterToJSON(value.companyDataId),
        'companySurveyId': LongFilterToJSON(value.companySurveyId),
        'companyBalanceSheetId': LongFilterToJSON(value.companyBalanceSheetId),
        'activityId': LongFilterToJSON(value.activityId),
        'consultedId': LongFilterToJSON(value.consultedId),
        'userCompanyId': LongFilterToJSON(value.userCompanyId),
        'userConsultantId': LongFilterToJSON(value.userConsultantId),
        'companySavedId': LongFilterToJSON(value.companySavedId),
        'companySearchedId': LongFilterToJSON(value.companySearchedId),
        'insightId': LongFilterToJSON(value.insightId),
        'consultantId': LongFilterToJSON(value.consultantId),
        'postId': LongFilterToJSON(value.postId),
        'receivedInsightId': LongFilterToJSON(value.receivedInsightId),
        'distinct': value.distinct,
    };
}


