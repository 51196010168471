/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserCompany
 */
export interface UserCompany {
    /**
     * 
     * @type {number}
     * @memberof UserCompany
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCompany
     */
    role?: UserCompanyRoleEnum;
    /**
     * 
     * @type {User}
     * @memberof UserCompany
     */
    user?: User;
    /**
     * 
     * @type {Company}
     * @memberof UserCompany
     */
    company?: Company;
}

/**
* @export
* @enum {string}
*/
export enum UserCompanyRoleEnum {
    Administrator = 'ADMINISTRATOR',
    Employee = 'EMPLOYEE'
}

export function UserCompanyFromJSON(json: any): UserCompany {
    return UserCompanyFromJSONTyped(json, false);
}

export function UserCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
    };
}

export function UserCompanyToJSON(value?: UserCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'user': UserToJSON(value.user),
        'company': CompanyToJSON(value.company),
    };
}


