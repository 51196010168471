import hykeeStyle from '@components/hykeeStyle';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Company, UserExtendedTypeEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { Component, useEffect, useState } from 'react';
import { RootStackScreenProps } from '../../../types';
import LinkedCompaniesData from './ComponentProfile/LinkedCompaniesData';
import Search from './ComponentSearch/Search';
import DashboardCompanySearched from './DashboardCompanySearched';
import DashboardScreen from './DashboardScreen';
import Colors from "../../../constants/Colors";
import AdministratorScreen from './Component/FinancialStatements/AdministratorScreen';
import PartnerScreen from './Component/FinancialStatements/PartnerScreen';
import SharesScreen from './Component/FinancialStatements/SharesScreen';

export default function SearchScreen({ navigation, route }: RootStackScreenProps<'SearchScreen'>) {

  const Stack = createNativeStackNavigator();

  return (

    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={hykeeStyle.containerBack}
    >
      <StatusBar style="inverted" />
      <Stack.Navigator
        initialRouteName={"Search"}>
        <Stack.Screen name="Search" component={Search} options={{ headerShown: false }} />
        <Stack.Screen name="DashboardCompanySearched" component={DashboardCompanySearched} options={{ headerShown: false }} />
        <Stack.Screen name="LinkedCompaniesData" component={LinkedCompaniesData} options={{ headerShown: false }} />
        <Stack.Screen name="SharesScreen" component={SharesScreen} options={{ headerShown: false }} />
        <Stack.Screen name="AdministratorScreen" component={AdministratorScreen} options={{ headerShown: false }} />
        <Stack.Screen name="PartnerScreen" component={PartnerScreen} options={{ headerShown: false }} />
      </Stack.Navigator>
    </LinearGradient>
  );
}
