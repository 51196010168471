/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SedeFuoriProvincia
 */
export interface SedeFuoriProvincia {
    /**
     * 
     * @type {string}
     * @memberof SedeFuoriProvincia
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeFuoriProvincia
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeFuoriProvincia
     */
    nrd?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeFuoriProvincia
     */
    naa?: string;
}

export function SedeFuoriProvinciaFromJSON(json: any): SedeFuoriProvincia {
    return SedeFuoriProvinciaFromJSONTyped(json, false);
}

export function SedeFuoriProvinciaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SedeFuoriProvincia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
        'naa': !exists(json, 'naa') ? undefined : json['naa'],
    };
}

export function SedeFuoriProvinciaToJSON(value?: SedeFuoriProvincia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cciaa': value.cciaa,
        'nrea': value.nrea,
        'nrd': value.nrd,
        'naa': value.naa,
    };
}


