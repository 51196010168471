import * as React from 'react';
import { FontAwesome } from "@expo/vector-icons";
import { FontAwesome5, Fontisto } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
MaterialIcons 

  /**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
export default function Icon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'] | any;
    color: string;
    size?:number;
    type?:string;
}) {
    if(props.type=="FontAwesome5"){
        return (<FontAwesome5 size={props.size? props.size : 16} style={{ justifyContent:'flex-start' }} {...props} >
            </FontAwesome5>)
    }else if(props.type=="MaterialIcons"){
        return (<MaterialIcons size={props.size? props.size : 16} style={{ justifyContent:'flex-start' }} {...props} >
            </MaterialIcons>)
    }else if(props.type=="Fontisto"){
        return (<Fontisto size={props.size? props.size : 16} style={{ justifyContent:'flex-start' }} {...props} >
            </Fontisto>)
    }else{
        return (<FontAwesome size={props.size? props.size : 16} style={{ justifyContent:'flex-start' }} {...props} >
            </FontAwesome>)
    }
}