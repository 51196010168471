/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Modifiche,
    ModificheFromJSON,
    ModificheFromJSONTyped,
    ModificheToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessioneAa
 */
export interface SessioneAa {
    /**
     * 
     * @type {Array<Modifiche>}
     * @memberof SessioneAa
     */
    modifiche?: Array<Modifiche>;
    /**
     * 
     * @type {string}
     * @memberof SessioneAa
     */
    movimentazione?: string;
    /**
     * 
     * @type {string}
     * @memberof SessioneAa
     */
    dtDelibera?: string;
    /**
     * 
     * @type {string}
     * @memberof SessioneAa
     */
    dtDomandaAccertamento?: string;
    /**
     * 
     * @type {string}
     * @memberof SessioneAa
     */
    cmovimentazione?: string;
}

export function SessioneAaFromJSON(json: any): SessioneAa {
    return SessioneAaFromJSONTyped(json, false);
}

export function SessioneAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessioneAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modifiche': !exists(json, 'modifiche') ? undefined : ((json['modifiche'] as Array<any>).map(ModificheFromJSON)),
        'movimentazione': !exists(json, 'movimentazione') ? undefined : json['movimentazione'],
        'dtDelibera': !exists(json, 'dtDelibera') ? undefined : json['dtDelibera'],
        'dtDomandaAccertamento': !exists(json, 'dtDomandaAccertamento') ? undefined : json['dtDomandaAccertamento'],
        'cmovimentazione': !exists(json, 'cmovimentazione') ? undefined : json['cmovimentazione'],
    };
}

export function SessioneAaToJSON(value?: SessioneAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modifiche': value.modifiche === undefined ? undefined : ((value.modifiche as Array<any>).map(ModificheToJSON)),
        'movimentazione': value.movimentazione,
        'dtDelibera': value.dtDelibera,
        'dtDomandaAccertamento': value.dtDomandaAccertamento,
        'cmovimentazione': value.cmovimentazione,
    };
}


