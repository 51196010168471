/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttiTrascrizioni,
    AttiTrascrizioniFromJSON,
    AttiTrascrizioniFromJSONTyped,
    AttiTrascrizioniToJSON,
    ModelliTrascrizioni,
    ModelliTrascrizioniFromJSON,
    ModelliTrascrizioniFromJSONTyped,
    ModelliTrascrizioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolloRi
 */
export interface ProtocolloRi {
    /**
     * 
     * @type {ModelliTrascrizioni}
     * @memberof ProtocolloRi
     */
    modelliTrascrizioni?: ModelliTrascrizioni;
    /**
     * 
     * @type {AttiTrascrizioni}
     * @memberof ProtocolloRi
     */
    attiTrascrizioni?: AttiTrascrizioni;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    dtProtocollo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    annoComunicazioneIntercam?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    annoProtocolloIntercam?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    cfImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    denomCciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    dtDenuncia?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    nprotocollo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    nprotocolloUfficio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    fattoPreIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    ncomunicazioneIntercamerale?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRi
     */
    nprotocolloIntercamerale?: string;
}

export function ProtocolloRiFromJSON(json: any): ProtocolloRi {
    return ProtocolloRiFromJSONTyped(json, false);
}

export function ProtocolloRiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolloRi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelliTrascrizioni': !exists(json, 'modelliTrascrizioni') ? undefined : ModelliTrascrizioniFromJSON(json['modelliTrascrizioni']),
        'attiTrascrizioni': !exists(json, 'attiTrascrizioni') ? undefined : AttiTrascrizioniFromJSON(json['attiTrascrizioni']),
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'dtProtocollo': !exists(json, 'dtProtocollo') ? undefined : json['dtProtocollo'],
        'annoComunicazioneIntercam': !exists(json, 'annoComunicazioneIntercam') ? undefined : json['annoComunicazioneIntercam'],
        'annoProtocolloIntercam': !exists(json, 'annoProtocolloIntercam') ? undefined : json['annoProtocolloIntercam'],
        'cfImpresa': !exists(json, 'cfImpresa') ? undefined : json['cfImpresa'],
        'denomCciaa': !exists(json, 'denomCciaa') ? undefined : json['denomCciaa'],
        'dtDenuncia': !exists(json, 'dtDenuncia') ? undefined : json['dtDenuncia'],
        'nprotocollo': !exists(json, 'nprotocollo') ? undefined : json['nprotocollo'],
        'nprotocolloUfficio': !exists(json, 'nprotocolloUfficio') ? undefined : json['nprotocolloUfficio'],
        'fattoPreIscrizione': !exists(json, 'fattoPreIscrizione') ? undefined : json['fattoPreIscrizione'],
        'ncomunicazioneIntercamerale': !exists(json, 'ncomunicazioneIntercamerale') ? undefined : json['ncomunicazioneIntercamerale'],
        'nprotocolloIntercamerale': !exists(json, 'nprotocolloIntercamerale') ? undefined : json['nprotocolloIntercamerale'],
    };
}

export function ProtocolloRiToJSON(value?: ProtocolloRi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelliTrascrizioni': ModelliTrascrizioniToJSON(value.modelliTrascrizioni),
        'attiTrascrizioni': AttiTrascrizioniToJSON(value.attiTrascrizioni),
        'anno': value.anno,
        'dtProtocollo': value.dtProtocollo,
        'annoComunicazioneIntercam': value.annoComunicazioneIntercam,
        'annoProtocolloIntercam': value.annoProtocolloIntercam,
        'cfImpresa': value.cfImpresa,
        'denomCciaa': value.denomCciaa,
        'dtDenuncia': value.dtDenuncia,
        'nprotocollo': value.nprotocollo,
        'nprotocolloUfficio': value.nprotocolloUfficio,
        'fattoPreIscrizione': value.fattoPreIscrizione,
        'ncomunicazioneIntercamerale': value.ncomunicazioneIntercamerale,
        'nprotocolloIntercamerale': value.nprotocolloIntercamerale,
    };
}


