/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Partecipazioni,
    PartecipazioniFromJSON,
    PartecipazioniFromJSONTyped,
    PartecipazioniToJSON,
    Trasferimenti,
    TrasferimentiFromJSON,
    TrasferimentiFromJSONTyped,
    TrasferimentiToJSON,
} from './';

/**
 * 
 * @export
 * @interface PartecipazioniSocieta
 */
export interface PartecipazioniSocieta {
    /**
     * 
     * @type {Partecipazioni}
     * @memberof PartecipazioniSocieta
     */
    partecipazioni?: Partecipazioni;
    /**
     * 
     * @type {Trasferimenti}
     * @memberof PartecipazioniSocieta
     */
    trasferimenti?: Trasferimenti;
}

export function PartecipazioniSocietaFromJSON(json: any): PartecipazioniSocieta {
    return PartecipazioniSocietaFromJSONTyped(json, false);
}

export function PartecipazioniSocietaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartecipazioniSocieta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partecipazioni': !exists(json, 'partecipazioni') ? undefined : PartecipazioniFromJSON(json['partecipazioni']),
        'trasferimenti': !exists(json, 'trasferimenti') ? undefined : TrasferimentiFromJSON(json['trasferimenti']),
    };
}

export function PartecipazioniSocietaToJSON(value?: PartecipazioniSocieta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partecipazioni': PartecipazioniToJSON(value.partecipazioni),
        'trasferimenti': TrasferimentiToJSON(value.trasferimenti),
    };
}


