/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiImpresa,
    EstremiImpresaFromJSON,
    EstremiImpresaFromJSONTyped,
    EstremiImpresaToJSON,
    EstremiPratica,
    EstremiPraticaFromJSON,
    EstremiPraticaFromJSONTyped,
    EstremiPraticaToJSON,
    Riquadri,
    RiquadriFromJSON,
    RiquadriFromJSONTyped,
    RiquadriToJSON,
} from './';

/**
 * 
 * @export
 * @interface Trasferimento
 */
export interface Trasferimento {
    /**
     * 
     * @type {EstremiImpresa}
     * @memberof Trasferimento
     */
    estremiImpresa: EstremiImpresa;
    /**
     * 
     * @type {EstremiPratica}
     * @memberof Trasferimento
     */
    estremiPratica: EstremiPratica;
    /**
     * 
     * @type {Riquadri}
     * @memberof Trasferimento
     */
    riquadri: Riquadri;
    /**
     * 
     * @type {string}
     * @memberof Trasferimento
     */
    fsuccessivoUltimoEs?: string;
}

export function TrasferimentoFromJSON(json: any): Trasferimento {
    return TrasferimentoFromJSONTyped(json, false);
}

export function TrasferimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trasferimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiImpresa': EstremiImpresaFromJSON(json['estremiImpresa']),
        'estremiPratica': EstremiPraticaFromJSON(json['estremiPratica']),
        'riquadri': RiquadriFromJSON(json['riquadri']),
        'fsuccessivoUltimoEs': !exists(json, 'fsuccessivoUltimoEs') ? undefined : json['fsuccessivoUltimoEs'],
    };
}

export function TrasferimentoToJSON(value?: Trasferimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiImpresa': EstremiImpresaToJSON(value.estremiImpresa),
        'estremiPratica': EstremiPraticaToJSON(value.estremiPratica),
        'riquadri': RiquadriToJSON(value.riquadri),
        'fsuccessivoUltimoEs': value.fsuccessivoUltimoEs,
    };
}


