/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StoriaSedePrecedente,
    StoriaSedePrecedenteFromJSON,
    StoriaSedePrecedenteFromJSONTyped,
    StoriaSedePrecedenteToJSON,
} from './';

/**
 * 
 * @export
 * @interface StoriaSediPrecedenti
 */
export interface StoriaSediPrecedenti {
    /**
     * 
     * @type {Array<StoriaSedePrecedente>}
     * @memberof StoriaSediPrecedenti
     */
    storiaSedePrecedente: Array<StoriaSedePrecedente>;
}

export function StoriaSediPrecedentiFromJSON(json: any): StoriaSediPrecedenti {
    return StoriaSediPrecedentiFromJSONTyped(json, false);
}

export function StoriaSediPrecedentiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoriaSediPrecedenti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storiaSedePrecedente': ((json['storiaSedePrecedente'] as Array<any>).map(StoriaSedePrecedenteFromJSON)),
    };
}

export function StoriaSediPrecedentiToJSON(value?: StoriaSediPrecedenti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storiaSedePrecedente': ((value.storiaSedePrecedente as Array<any>).map(StoriaSedePrecedenteToJSON)),
    };
}


