// QT00LU
// 5Hz2ue6qz7Pnj5!
//
import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { RootStackScreenProps, UserExtendedType } from '../../../../types';
import {
    BackgroundColorButtonDarkLight,
    BackgroundColorInputText,
    ColorLabel,
    ColorPlaceHolder,
    ColorTextInput,
    ColorTextLightGreen
} from '../../../components/ColorTheme';
import { storeAccessToken, storeUserType } from '../../../helpers/StorageHelper';
import { i18n } from '../../../i18n/i18n'
import { SplashLogoScreen } from '../../../navigation/SplashLogoScreen';
import { customUserExtendedResourceApi, userJwtControllerApi } from '../../../services/apis/ApiConfiguration';
import { LoginVM, UserExtended, UserExtendedTypeEnum, } from '../../../services/apis/generated';
import { AlertHelper } from "@helpers/AlertHelper";
import { NavigationHelper } from "@helpers/NavigationHelper";
import Colors from "../../../../constants/Colors";
import { useEffect } from 'react';
import { BaseAPIManager } from '@services/model/BaseAPIManager';
import { useFocusEffect } from '@react-navigation/native';
import { ErrorHelper } from '@helpers/ErrorHelper';
import AppContext from '../../../navigation/AppContext';
import ModalSimpleError from '@screens/Modal/ModalSimpleError';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { environment } from "../../../environments/environment";
import { NotificationHelper } from "../../../helpers/NotificationHelper";


export default function LoginScreen({ navigation, route }: RootStackScreenProps<'Login'>) {

    interface Login {
        email?: string,
        password?: string
    }

    enum ValidationFields {
        username = "username",
        password = "password",
    }

    let initErrorFields: { [key in ValidationFields]: [] } = {
        username: [],
        password: [],
    }

    const userType: UserExtendedType = {
        userType: route.params.userType
    }
    const appContext = useContext(AppContext);
    const [show, setShow] = React.useState(true);
    const [iconState, setIcon] = React.useState('eye-slash');
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [modalErrorActiveUser, setModalErrorActiveUser] = useState<{ visible: boolean, msg: string, var?: string }>({ visible: false, msg: '' });
    const [errorFields, setErrorFields] = React.useState(initErrorFields);

    let TxtInpEmail: TextInput | null;
    let TxtInpPass: TextInput | null;

    function canShowFull() {
        return !PlatformHelper.isWeb() || environment.debugRoom;
    }


    useFocusEffect(() => {
        if (BaseAPIManager.token && canShowFull()) {
            customUserExtendedResourceApi.getUserExtendedMe().then((responseUser: UserExtended) => {
                if (responseUser) {
                    NavigationHelper.navigateToBottomTab(navigation, responseUser)
                }
            })
        }
    })

    function clearErrorFields() {
        setErrorFields({
            username: [],
            password: []
        })
    }

    function loginAutenticate() {
        clearErrorFields()
        const loginVM: LoginVM = {
            username: email,
            password: password,
        }
        BaseAPIManager.contextLoadingCallback(true);
        userJwtControllerApi.authorize({ loginVM: loginVM }).then(async res => {
            console.log(res);
            await storeAccessToken(res.idToken!);
            customUserExtendedResourceApi.getUserExtendedMe().then(async response => {
                BaseAPIManager.contextLoadingCallback(false);
                if (response.type) {
                    await storeUserType(response.type);
                    clearFields();
                    NotificationHelper.registerForPushNotificationsAsync();
                    NavigationHelper.navigateToBottomTab(navigation, response);
                }
            }).catch(async (error) => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
            })
        }).catch(async (error) => {
            let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
            parseErrors(errorMessage)
            let errorStatus = ErrorHelper.getStatusFromServerMessage(errorMessage)
            console.log(errorStatus)
            if (errorStatus == 401) {
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage('failed_authentication') })
            } else {
                let errorJson = ErrorHelper.getDetailFromServerMessage(errorMessage)
                if (errorJson) {
                    if (errorJson == 'User ' + loginVM.username + ' was not activated') {
                        appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage('error_generic') })
                    } else {
                        appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorStatus) })
                    }
                }
            }

        })
    }

    function parseErrors(error: any) {
        if (!error) return
        let errorArray = error.fieldErrors

        let validationArray: { [key in ValidationFields]: [] } = {
            username: [],
            password: [],
        }

        if (errorArray) {
            for (let index = 0; index < errorArray.length; index++) {
                let errorField = errorArray[index];

                for (let field in ValidationFields) {
                    if (field == errorField.field) {
                        // Add only if doesn't exists already
                        if (!(validationArray[field as ValidationFields].filter(e => e === field).length > 0)) {
                            validationArray[field as ValidationFields].push(errorField.message as never)
                        }
                    }
                }
            }
        }

        setErrorFields({ ...validationArray })
        console.log(validationArray)
    }

    function drawValidationErrors(field: ValidationFields): React.ReactNode {
        let validationErrors: any = [];

        for (const [key, value] of Object.entries(errorFields)) {
            if (key == field) {
                value.forEach((element) => {
                    validationErrors.push(<Text style={styles.error} key={element} >{i18n.t("errors.validation.registration." + element)}</Text>)
                })
            }
        }

        if (validationErrors.length > 0) {
            return validationErrors
        } else {
            return null
        }
    }

    function clearFields() {
        setEmail('')
        setPassword('')
        TxtInpEmail?.clear()
        TxtInpPass?.clear()
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <ScrollView style={styles.container}>
                <SplashLogoScreen welcome={true} />

                <View style={styles.containerTextInput}>
                    <Text style={styles.labelTextInput}>{i18n.t('screens.signup.mail_address')}</Text>
                    <TextInput
                        ref={(ref) => { TxtInpEmail = ref }}
                        placeholder={i18n.t('screens.signup.mail_address')}
                        placeholderTextColor={ColorPlaceHolder()}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onEndEditing={(email) => { PlatformHelper.isAndroid() ? setEmail(email.nativeEvent.text) : null }}
                        onBlur={(email) => { !PlatformHelper.isAndroid() ? setEmail(email.nativeEvent.text) : null }}
                        onChangeText={(email) => { setEmail(email) }}
                        style={styles.textInput}
                    />
                    {errorFields.username.length > 0 && drawValidationErrors(ValidationFields.username)}
                    <Text style={styles.labelTextInput}>{i18n.t('login.password')}</Text>
                    <View
                        style={[styles.textInput, { flexDirection: 'row', padding: 15 }]}>
                        <TextInput
                            ref={(ref) => { TxtInpPass = ref }}
                            placeholder={i18n.t('login.password')}
                            placeholderTextColor={ColorPlaceHolder()}
                            autoCorrect={false}
                            onEndEditing={(password) => { PlatformHelper.isAndroid() ? setPassword(password.nativeEvent.text) : null }}
                            onBlur={(password) => { !PlatformHelper.isAndroid() ? setPassword(password.nativeEvent.text) : null }}
                            onChangeText={(password) => { setPassword(password) }}
                            secureTextEntry={show}
                            style={{ alignSelf: 'flex-start', color: 'white', flex: 0.9 }}
                            autoCapitalize="none"
                        >
                        </TextInput>
                        <TouchableOpacity
                            style={styles.buttonShowHide}
                            activeOpacity={0.5}
                            onPress={() => {
                                if (show) {
                                    setShow(false), setIcon('eye')
                                } else {
                                    setShow(true), setIcon('eye-slash')
                                }
                            }}
                        >
                            <Icon name={iconState} color={"#737373"} />
                        </TouchableOpacity>
                    </View>
                    {errorFields.password.length > 0 && drawValidationErrors(ValidationFields.password)}
                    <View style={styles.viewForgot}>
                        <Text
                            style={styles.textForgot}
                            onPress={() => {
                                NavigationHelper.navigateToResetPassword(navigation)
                            }}
                        >
                            {i18n.t('screens.login.forgot_password')}
                        </Text>
                    </View>
                    <TouchableOpacity
                        style={styles.buttonLogin}
                        activeOpacity={0.5}
                        onPress={() => {
                            loginAutenticate();
                        }}
                    >
                        <Text style={styles.textLogin}>{i18n.t('buttons.login')}</Text>
                    </TouchableOpacity>

                </View>

                {userType.userType == UserExtendedTypeEnum.Company &&
                    <View style={styles.viewRegistration}>
                        <Text
                            style={styles.textRegistration}
                        >
                            {i18n.t('screens.login.registration_ask')}
                            <Text
                                style={[styles.textRegistration,
                                { fontWeight: 'bold', color: ColorTextLightGreen() }
                                ]}
                                onPress={() => navigation.navigate('Registration', userType)}
                            >
                                {i18n.t('screens.login.registration')}</Text>
                        </Text>
                    </View>
                }
            </ScrollView>
            {modalErrorActiveUser.visible && <ModalSimpleError msg={i18n.t('server_errors.' + modalErrorActiveUser.msg, { user: modalErrorActiveUser.var })}
                visible={(visible) => setModalErrorActiveUser({
                    visible: visible,
                    msg: '',
                    var: undefined
                })} />}
        </LinearGradient>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    buttonShowHide: {
        alignSelf: 'center',
        flex: 0.1,
    },
    containerTextInput: {
        flex: 0.7,
        justifyContent: 'flex-start',
        fontFamily: 'poppins',
    },
    error: {
        color: "#ff3333",
        fontSize: 12,
        marginHorizontal: 4
    },
    labelTextInput: {
        alignSelf: 'flex-start',
        color: ColorLabel(),
        marginBottom: 5,
        fontFamily: 'poppins',
    },
    textInput: {
        backgroundColor: BackgroundColorInputText(),
        padding: 13,
        color: ColorTextInput(),
        borderRadius: 5,
        marginBottom: 15,
        fontFamily: 'poppins',
    },
    viewForgot: {
        alignSelf: 'flex-end',
        fontFamily: 'poppins',
    },
    textForgot: {
        color: ColorTextLightGreen(),
        fontFamily: 'poppins',
    },
    buttonLogin: {
        marginTop: 40,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 20,
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    textLogin: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: 'white',
        fontFamily: 'poppins',
    },
    viewRegistration: {
        alignSelf: 'center',
        justifyContent: 'flex-end',
        marginTop: 60,
        marginBottom: 20,
        flex: 0.1
    },
    textRegistration: {
        color: 'white',
        fontFamily: 'poppins',
    }
});


/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function Icon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'] | any;
    color: string;
}) {
    return <FontAwesome size={16} style={{ justifyContent: 'flex-start' }} {...props} />;
}