import Constants from "expo-constants";

let extra = Constants.manifest?.extra;

export const environment = {
    debugRoom: extra?.debugRoom,
    production: extra?.isProduction,
    apiUrl: extra?.apiUrl,
    sentryDsn: extra?.sentryDsn,
    sentryEnableDevelopment: extra?.sentryEnableDevelopment,
    disableLoader: extra?.disableLoader,
};