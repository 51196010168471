/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoMese,
    InfoMeseFromJSON,
    InfoMeseFromJSONTyped,
    InfoMeseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoMesi
 */
export interface InfoMesi {
    /**
     * 
     * @type {Array<InfoMese>}
     * @memberof InfoMesi
     */
    infoMese: Array<InfoMese>;
}

export function InfoMesiFromJSON(json: any): InfoMesi {
    return InfoMesiFromJSONTyped(json, false);
}

export function InfoMesiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoMesi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoMese': ((json['infoMese'] as Array<any>).map(InfoMeseFromJSON)),
    };
}

export function InfoMesiToJSON(value?: InfoMesi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoMese': ((value.infoMese as Array<any>).map(InfoMeseToJSON)),
    };
}


