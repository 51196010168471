/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserPlan,
    UserPlanFromJSON,
    UserPlanToJSON,
} from '../models';

export interface CreateUserPlanRequest {
    userPlan: UserPlan;
}

export interface DeleteUserPlanRequest {
    id: number;
}

export interface GetUserPlanRequest {
    id: number;
}

export interface PartialUpdateUserPlanRequest {
    id: number;
    userPlan: UserPlan;
}

export interface UpdateUserPlanRequest {
    id: number;
    userPlan: UserPlan;
}

/**
 * 
 */
export class UserPlanResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserPlanRaw(requestParameters: CreateUserPlanRequest): Promise<runtime.ApiResponse<UserPlan>> {
        if (requestParameters.userPlan === null || requestParameters.userPlan === undefined) {
            throw new runtime.RequiredError('userPlan','Required parameter requestParameters.userPlan was null or undefined when calling createUserPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPlanToJSON(requestParameters.userPlan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPlanFromJSON(jsonValue));
    }

    /**
     */
    async createUserPlan(requestParameters: CreateUserPlanRequest): Promise<UserPlan> {
        const response = await this.createUserPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserPlanRaw(requestParameters: DeleteUserPlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserPlan(requestParameters: DeleteUserPlanRequest): Promise<void> {
        await this.deleteUserPlanRaw(requestParameters);
    }

    /**
     */
    async getAllUserPlansRaw(): Promise<runtime.ApiResponse<Array<UserPlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserPlanFromJSON));
    }

    /**
     */
    async getAllUserPlans(): Promise<Array<UserPlan>> {
        const response = await this.getAllUserPlansRaw();
        return await response.value();
    }

    /**
     */
    async getUserPlanRaw(requestParameters: GetUserPlanRequest): Promise<runtime.ApiResponse<UserPlan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPlanFromJSON(jsonValue));
    }

    /**
     */
    async getUserPlan(requestParameters: GetUserPlanRequest): Promise<UserPlan> {
        const response = await this.getUserPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserPlanRaw(requestParameters: PartialUpdateUserPlanRequest): Promise<runtime.ApiResponse<UserPlan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserPlan.');
        }

        if (requestParameters.userPlan === null || requestParameters.userPlan === undefined) {
            throw new runtime.RequiredError('userPlan','Required parameter requestParameters.userPlan was null or undefined when calling partialUpdateUserPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserPlanToJSON(requestParameters.userPlan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPlanFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserPlan(requestParameters: PartialUpdateUserPlanRequest): Promise<UserPlan> {
        const response = await this.partialUpdateUserPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserPlanRaw(requestParameters: UpdateUserPlanRequest): Promise<runtime.ApiResponse<UserPlan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserPlan.');
        }

        if (requestParameters.userPlan === null || requestParameters.userPlan === undefined) {
            throw new runtime.RequiredError('userPlan','Required parameter requestParameters.userPlan was null or undefined when calling updateUserPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserPlanToJSON(requestParameters.userPlan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPlanFromJSON(jsonValue));
    }

    /**
     */
    async updateUserPlan(requestParameters: UpdateUserPlanRequest): Promise<UserPlan> {
        const response = await this.updateUserPlanRaw(requestParameters);
        return await response.value();
    }

}
