/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComunicazioniCuratore,
    ComunicazioniCuratoreFromJSON,
    ComunicazioniCuratoreFromJSONTyped,
    ComunicazioniCuratoreToJSON,
    Dichiarazioni,
    DichiarazioniFromJSON,
    DichiarazioniFromJSONTyped,
    DichiarazioniToJSON,
    PersonePco,
    PersonePcoFromJSON,
    PersonePcoFromJSONTyped,
    PersonePcoToJSON,
    ProceduraConcorsuale,
    ProceduraConcorsualeFromJSON,
    ProceduraConcorsualeFromJSONTyped,
    ProceduraConcorsualeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProcedureConcorsuali
 */
export interface ProcedureConcorsuali {
    /**
     * 
     * @type {Array<ProceduraConcorsuale>}
     * @memberof ProcedureConcorsuali
     */
    proceduraConcorsuale?: Array<ProceduraConcorsuale>;
    /**
     * 
     * @type {ComunicazioniCuratore}
     * @memberof ProcedureConcorsuali
     */
    comunicazioniCuratore?: ComunicazioniCuratore;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    informazioniCuratore?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    accordiDebiti?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    rapportoCuratore?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    appelliReclami?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    propostaConcordato?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    esercizioProvvisorio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    continuazioneEsercizioProvv?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    cessazioneEsercizioProvv?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    esecuzioneConcordato?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    annotazioniProcedure?: string;
    /**
     * 
     * @type {Dichiarazioni}
     * @memberof ProcedureConcorsuali
     */
    dichiarazioni?: Dichiarazioni;
    /**
     * 
     * @type {PersonePco}
     * @memberof ProcedureConcorsuali
     */
    personePco?: PersonePco;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    cciaaFuoriProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcedureConcorsuali
     */
    fpresenzaInfo?: string;
}

export function ProcedureConcorsualiFromJSON(json: any): ProcedureConcorsuali {
    return ProcedureConcorsualiFromJSONTyped(json, false);
}

export function ProcedureConcorsualiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcedureConcorsuali {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proceduraConcorsuale': !exists(json, 'proceduraConcorsuale') ? undefined : ((json['proceduraConcorsuale'] as Array<any>).map(ProceduraConcorsualeFromJSON)),
        'comunicazioniCuratore': !exists(json, 'comunicazioniCuratore') ? undefined : ComunicazioniCuratoreFromJSON(json['comunicazioniCuratore']),
        'informazioniCuratore': !exists(json, 'informazioniCuratore') ? undefined : json['informazioniCuratore'],
        'accordiDebiti': !exists(json, 'accordiDebiti') ? undefined : json['accordiDebiti'],
        'rapportoCuratore': !exists(json, 'rapportoCuratore') ? undefined : json['rapportoCuratore'],
        'appelliReclami': !exists(json, 'appelliReclami') ? undefined : json['appelliReclami'],
        'propostaConcordato': !exists(json, 'propostaConcordato') ? undefined : json['propostaConcordato'],
        'esercizioProvvisorio': !exists(json, 'esercizioProvvisorio') ? undefined : json['esercizioProvvisorio'],
        'continuazioneEsercizioProvv': !exists(json, 'continuazioneEsercizioProvv') ? undefined : json['continuazioneEsercizioProvv'],
        'cessazioneEsercizioProvv': !exists(json, 'cessazioneEsercizioProvv') ? undefined : json['cessazioneEsercizioProvv'],
        'esecuzioneConcordato': !exists(json, 'esecuzioneConcordato') ? undefined : json['esecuzioneConcordato'],
        'annotazioniProcedure': !exists(json, 'annotazioniProcedure') ? undefined : json['annotazioniProcedure'],
        'dichiarazioni': !exists(json, 'dichiarazioni') ? undefined : DichiarazioniFromJSON(json['dichiarazioni']),
        'personePco': !exists(json, 'personePco') ? undefined : PersonePcoFromJSON(json['personePco']),
        'cciaaFuoriProvincia': !exists(json, 'cciaaFuoriProvincia') ? undefined : json['cciaaFuoriProvincia'],
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function ProcedureConcorsualiToJSON(value?: ProcedureConcorsuali | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proceduraConcorsuale': value.proceduraConcorsuale === undefined ? undefined : ((value.proceduraConcorsuale as Array<any>).map(ProceduraConcorsualeToJSON)),
        'comunicazioniCuratore': ComunicazioniCuratoreToJSON(value.comunicazioniCuratore),
        'informazioniCuratore': value.informazioniCuratore,
        'accordiDebiti': value.accordiDebiti,
        'rapportoCuratore': value.rapportoCuratore,
        'appelliReclami': value.appelliReclami,
        'propostaConcordato': value.propostaConcordato,
        'esercizioProvvisorio': value.esercizioProvvisorio,
        'continuazioneEsercizioProvv': value.continuazioneEsercizioProvv,
        'cessazioneEsercizioProvv': value.cessazioneEsercizioProvv,
        'esecuzioneConcordato': value.esecuzioneConcordato,
        'annotazioniProcedure': value.annotazioniProcedure,
        'dichiarazioni': DichiarazioniToJSON(value.dichiarazioni),
        'personePco': PersonePcoToJSON(value.personePco),
        'cciaaFuoriProvincia': value.cciaaFuoriProvincia,
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


