/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyDataFullDTO,
    CompanyDataFullDTOFromJSON,
    CompanyDataFullDTOToJSON,
} from '../models';

export interface GetCompanyDataByCompanyDataIdRequest {
    companyDataId: number;
}

export interface GetLastCompanyDataByCompanyRequest {
    companyId: number;
}

/**
 * 
 */
export class CustomCompanyDataResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCompanyDataByCompanyDataIdRaw(requestParameters: GetCompanyDataByCompanyDataIdRequest): Promise<runtime.ApiResponse<CompanyDataFullDTO>> {
        if (requestParameters.companyDataId === null || requestParameters.companyDataId === undefined) {
            throw new runtime.RequiredError('companyDataId','Required parameter requestParameters.companyDataId was null or undefined when calling getCompanyDataByCompanyDataId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-data/company/{companyDataId}`.replace(`{${"companyDataId"}}`, encodeURIComponent(String(requestParameters.companyDataId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFullDTOFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyDataByCompanyDataId(requestParameters: GetCompanyDataByCompanyDataIdRequest): Promise<CompanyDataFullDTO> {
        const response = await this.getCompanyDataByCompanyDataIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLastCompanyDataByCompanyRaw(requestParameters: GetLastCompanyDataByCompanyRequest): Promise<runtime.ApiResponse<CompanyDataFullDTO>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getLastCompanyDataByCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-data/company/{companyId}/last`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFullDTOFromJSON(jsonValue));
    }

    /**
     */
    async getLastCompanyDataByCompany(requestParameters: GetLastCompanyDataByCompanyRequest): Promise<CompanyDataFullDTO> {
        const response = await this.getLastCompanyDataByCompanyRaw(requestParameters);
        return await response.value();
    }

}
