/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Partecipazione,
    PartecipazioneFromJSON,
    PartecipazioneFromJSONTyped,
    PartecipazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Partecipazioni
 */
export interface Partecipazioni {
    /**
     * 
     * @type {Array<Partecipazione>}
     * @memberof Partecipazioni
     */
    partecipazione: Array<Partecipazione>;
    /**
     * 
     * @type {string}
     * @memberof Partecipazioni
     */
    fpresenzaInfo?: string;
}

export function PartecipazioniFromJSON(json: any): Partecipazioni {
    return PartecipazioniFromJSONTyped(json, false);
}

export function PartecipazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partecipazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partecipazione': ((json['partecipazione'] as Array<any>).map(PartecipazioneFromJSON)),
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function PartecipazioniToJSON(value?: Partecipazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partecipazione': ((value.partecipazione as Array<any>).map(PartecipazioneToJSON)),
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


