import { BackgroundColorButtonGreen, ColorTextLightGreen } from "@components/ColorTheme";
import { i18n } from "@i18n/i18n";
import { BusinessRatingTableDTOSectionTableEnum } from "@services/apis/generated/models/BusinessRatingTableDTO";
import { SurveyQuestionQuestionAgilityEnum, SurveyQuestionQuestionAreaEnum, SurveyQuestionQuestionAttitudeEnum, SurveyQuestionQuestionGrowthEnum } from "@services/apis/generated/models/SurveyQuestion";
import { parse } from "expo-linking";
import React from "react";
import {StyleSheet, ScrollView, TouchableOpacity, View,Text, StatusBar } from "react-native";
import Collapsible from "react-native-collapsible";
import { State, Table } from "../../../../../types";
import Icon from "../../../Tools/Icon";
import { BusinessRatingTable } from "../SelfAssestment/BusinessRatingScreen";



export default function TableBusinessRating(props:{tab:BusinessRatingTable|undefined}) {
    const headerTab :string[] = [props.tab?.sectionTable!, 'Score%', i18n.t('busines_rating.area.result')];

    const parseData = (data: string)=>{
        let section = props.tab?.sectionTable!
        if(section==BusinessRatingTableDTOSectionTableEnum.Area.toUpperCase()){
            for(let el in SurveyQuestionQuestionAreaEnum){
                if (data.toLowerCase() == el.toLowerCase()){
                    return i18n.t('busines_rating.'+section.toLowerCase()+'.'+data.toLowerCase())
                }
            }
        }
        if(section==BusinessRatingTableDTOSectionTableEnum.Agility.toUpperCase()){
            for(let el in SurveyQuestionQuestionAgilityEnum){
                if (data.toLowerCase() == el.toLowerCase()){
                    return i18n.t('busines_rating.'+section.toLowerCase()+'.'+data.toLowerCase())
                }
            }
        }
        if(section==BusinessRatingTableDTOSectionTableEnum.Growth.toUpperCase()){
            for(let el in SurveyQuestionQuestionGrowthEnum){
                if (data.toLowerCase() == el.toLowerCase()){
                    return i18n.t('busines_rating.'+section.toLowerCase()+'.'+data.toLowerCase())
                }
            }
        }
        if(section==BusinessRatingTableDTOSectionTableEnum.Attitude.toUpperCase()){
            for(let el in SurveyQuestionQuestionAttitudeEnum){
                if (data.toLowerCase() == el.toLowerCase()){
                    return i18n.t('busines_rating.'+section.toLowerCase()+'.'+data.toLowerCase())
                }
            }
        }
    }
    const parseHeader =(headerCol : string) =>{
        if(headerCol==BusinessRatingTableDTOSectionTableEnum.Area){
            return i18n.t('busines_rating.'+headerCol.toLowerCase()+'.'+headerCol.toLowerCase())
        }
        if(headerCol==BusinessRatingTableDTOSectionTableEnum.Agility){
            return i18n.t('busines_rating.'+headerCol.toLowerCase()+'.'+headerCol.toLowerCase())
        }
        if(headerCol==BusinessRatingTableDTOSectionTableEnum.Growth){
            return i18n.t('busines_rating.'+headerCol.toLowerCase()+'.'+headerCol.toLowerCase())
        }
        if(headerCol==BusinessRatingTableDTOSectionTableEnum.Attitude){
            return i18n.t('busines_rating.'+headerCol.toLowerCase()+'.'+headerCol.toLowerCase())
        }
    }


    return(
        <View >
            <View style={styles.tableHeaderCol}>
            {props.tab && headerTab.map((headerCol:string, index: number) =>{
                return(
                    <View key={index.toString()}
                          style={props.tab?.sectionTable == headerCol ? [styles.data, {flex: 0.33}] : [styles.data, {flex: 0.33}]}>

                        <Text
                            style={props.tab?.sectionTable == headerCol ? [styles.textHeader, {textAlign: 'left'}] : [styles.textHeader, {textAlign: 'center'}]}>
                        {props.tab?.sectionTable==headerCol? parseHeader(headerCol):headerCol}
                    </Text>
                    
                </View>
            )})}
            </View>
            <View style={{flexDirection:'column',  }}>
            {props.tab && props.tab.dataTable.map((dataRow, index: number)=>{
                return(
                    <View key={index.toString()} style={[styles.row,{backgroundColor:index%2==1?'#343434': '#292929'}]}>
                        {dataRow && dataRow.map((data, index: number)=>{
                            return(
                                <View key={index.toString()} style={dataRow[0]==data?{flex:0.33, justifyContent:'center'}:{flex:0.33, justifyContent:'center'}}>
                                    <Text style={dataRow[0]==data?[styles.dataTab,{textAlign:'left'}]:
                                    data=='Critical'?[styles.dataTab,{textAlign:'center',color:'#FF3205'}]:
                                    data=='Weak'?[styles.dataTab,{textAlign:'center',color:'#FFC700'}]:
                                    data=='Good'?[styles.dataTab,{textAlign:'center',color:ColorTextLightGreen()}]:
                                    [styles.dataTab,{textAlign:'center'}]
                                }>
                                        {dataRow[0]==data? parseData(data) :
                                            data == 'Critical' ? i18n.t('screens.business_rating.critical') :
                                                data == 'Weak' ? i18n.t('screens.business_rating.weak') :
                                                    data == 'Good' ? i18n.t('screens.business_rating.good') :
                                                        data
                                        }
                                        </Text>
                                </View>
                            )
                        })}
                    </View>
                )
            })}
            </View>
        </View>

    )
}

const styles = StyleSheet.create({
data:{
    paddingVertical:3,
    textAlign:'center',
    alignSelf:'center'
},
dataTab:{
    marginVertical:2,
    fontSize:12,
    color:'white',
    fontFamily:"poppins",
    textAlignVertical:'center'
},
row:{
    flexDirection:'row',
    paddingLeft:15,
    paddingRight:5,
    paddingVertical:5
},
textHeader:{
    marginVertical:3,
    fontSize:14,
    fontWeight:"bold",
    color:'white',
    fontFamily:"poppins",
},
tableHeaderCol:{
    display:"flex",
    flexDirection:"row",
    backgroundColor:"#494949",
    
    paddingLeft:15,
    paddingRight:5,
    paddingVertical:5
},
})