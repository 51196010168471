/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanySearched,
    CompanySearchedFromJSON,
    CompanySearchedToJSON,
} from '../models';

export interface CountCompanySearchedsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    dateGreaterThan?: Date;
    dateLessThan?: Date;
    dateGreaterThanOrEqual?: Date;
    dateLessThanOrEqual?: Date;
    dateEquals?: Date;
    dateNotEquals?: Date;
    dateSpecified?: boolean;
    dateIn?: Array<Date>;
    dateNotIn?: Array<Date>;
    userIdGreaterThan?: number;
    userIdLessThan?: number;
    userIdGreaterThanOrEqual?: number;
    userIdLessThanOrEqual?: number;
    userIdEquals?: number;
    userIdNotEquals?: number;
    userIdSpecified?: boolean;
    userIdIn?: Array<number>;
    userIdNotIn?: Array<number>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanySearchedRequest {
    companySearched: CompanySearched;
}

export interface DeleteCompanySearchedRequest {
    id: number;
}

export interface GetAllCompanySearchedsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    dateGreaterThan?: Date;
    dateLessThan?: Date;
    dateGreaterThanOrEqual?: Date;
    dateLessThanOrEqual?: Date;
    dateEquals?: Date;
    dateNotEquals?: Date;
    dateSpecified?: boolean;
    dateIn?: Array<Date>;
    dateNotIn?: Array<Date>;
    userIdGreaterThan?: number;
    userIdLessThan?: number;
    userIdGreaterThanOrEqual?: number;
    userIdLessThanOrEqual?: number;
    userIdEquals?: number;
    userIdNotEquals?: number;
    userIdSpecified?: boolean;
    userIdIn?: Array<number>;
    userIdNotIn?: Array<number>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanySearchedRequest {
    id: number;
}

export interface PartialUpdateCompanySearchedRequest {
    id: number;
    companySearched: CompanySearched;
}

export interface UpdateCompanySearchedRequest {
    id: number;
    companySearched: CompanySearched;
}

/**
 * 
 */
export class CompanySearchedResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanySearchedsRaw(requestParameters: CountCompanySearchedsRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.dateGreaterThan !== undefined) {
            queryParameters['date.greaterThan'] = (requestParameters.dateGreaterThan as any).toISOString();
        }

        if (requestParameters.dateLessThan !== undefined) {
            queryParameters['date.lessThan'] = (requestParameters.dateLessThan as any).toISOString();
        }

        if (requestParameters.dateGreaterThanOrEqual !== undefined) {
            queryParameters['date.greaterThanOrEqual'] = (requestParameters.dateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateLessThanOrEqual !== undefined) {
            queryParameters['date.lessThanOrEqual'] = (requestParameters.dateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateEquals !== undefined) {
            queryParameters['date.equals'] = (requestParameters.dateEquals as any).toISOString();
        }

        if (requestParameters.dateNotEquals !== undefined) {
            queryParameters['date.notEquals'] = (requestParameters.dateNotEquals as any).toISOString();
        }

        if (requestParameters.dateSpecified !== undefined) {
            queryParameters['date.specified'] = requestParameters.dateSpecified;
        }

        if (requestParameters.dateIn) {
            queryParameters['date.in'] = requestParameters.dateIn;
        }

        if (requestParameters.dateNotIn) {
            queryParameters['date.notIn'] = requestParameters.dateNotIn;
        }

        if (requestParameters.userIdGreaterThan !== undefined) {
            queryParameters['userId.greaterThan'] = requestParameters.userIdGreaterThan;
        }

        if (requestParameters.userIdLessThan !== undefined) {
            queryParameters['userId.lessThan'] = requestParameters.userIdLessThan;
        }

        if (requestParameters.userIdGreaterThanOrEqual !== undefined) {
            queryParameters['userId.greaterThanOrEqual'] = requestParameters.userIdGreaterThanOrEqual;
        }

        if (requestParameters.userIdLessThanOrEqual !== undefined) {
            queryParameters['userId.lessThanOrEqual'] = requestParameters.userIdLessThanOrEqual;
        }

        if (requestParameters.userIdEquals !== undefined) {
            queryParameters['userId.equals'] = requestParameters.userIdEquals;
        }

        if (requestParameters.userIdNotEquals !== undefined) {
            queryParameters['userId.notEquals'] = requestParameters.userIdNotEquals;
        }

        if (requestParameters.userIdSpecified !== undefined) {
            queryParameters['userId.specified'] = requestParameters.userIdSpecified;
        }

        if (requestParameters.userIdIn) {
            queryParameters['userId.in'] = requestParameters.userIdIn;
        }

        if (requestParameters.userIdNotIn) {
            queryParameters['userId.notIn'] = requestParameters.userIdNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-searcheds/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanySearcheds(requestParameters: CountCompanySearchedsRequest): Promise<number> {
        const response = await this.countCompanySearchedsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanySearchedRaw(requestParameters: CreateCompanySearchedRequest): Promise<runtime.ApiResponse<CompanySearched>> {
        if (requestParameters.companySearched === null || requestParameters.companySearched === undefined) {
            throw new runtime.RequiredError('companySearched','Required parameter requestParameters.companySearched was null or undefined when calling createCompanySearched.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-searcheds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySearchedToJSON(requestParameters.companySearched),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySearchedFromJSON(jsonValue));
    }

    /**
     */
    async createCompanySearched(requestParameters: CreateCompanySearchedRequest): Promise<CompanySearched> {
        const response = await this.createCompanySearchedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanySearchedRaw(requestParameters: DeleteCompanySearchedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanySearched.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-searcheds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanySearched(requestParameters: DeleteCompanySearchedRequest): Promise<void> {
        await this.deleteCompanySearchedRaw(requestParameters);
    }

    /**
     */
    async getAllCompanySearchedsRaw(requestParameters: GetAllCompanySearchedsRequest): Promise<runtime.ApiResponse<Array<CompanySearched>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.dateGreaterThan !== undefined) {
            queryParameters['date.greaterThan'] = (requestParameters.dateGreaterThan as any).toISOString();
        }

        if (requestParameters.dateLessThan !== undefined) {
            queryParameters['date.lessThan'] = (requestParameters.dateLessThan as any).toISOString();
        }

        if (requestParameters.dateGreaterThanOrEqual !== undefined) {
            queryParameters['date.greaterThanOrEqual'] = (requestParameters.dateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateLessThanOrEqual !== undefined) {
            queryParameters['date.lessThanOrEqual'] = (requestParameters.dateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateEquals !== undefined) {
            queryParameters['date.equals'] = (requestParameters.dateEquals as any).toISOString();
        }

        if (requestParameters.dateNotEquals !== undefined) {
            queryParameters['date.notEquals'] = (requestParameters.dateNotEquals as any).toISOString();
        }

        if (requestParameters.dateSpecified !== undefined) {
            queryParameters['date.specified'] = requestParameters.dateSpecified;
        }

        if (requestParameters.dateIn) {
            queryParameters['date.in'] = requestParameters.dateIn;
        }

        if (requestParameters.dateNotIn) {
            queryParameters['date.notIn'] = requestParameters.dateNotIn;
        }

        if (requestParameters.userIdGreaterThan !== undefined) {
            queryParameters['userId.greaterThan'] = requestParameters.userIdGreaterThan;
        }

        if (requestParameters.userIdLessThan !== undefined) {
            queryParameters['userId.lessThan'] = requestParameters.userIdLessThan;
        }

        if (requestParameters.userIdGreaterThanOrEqual !== undefined) {
            queryParameters['userId.greaterThanOrEqual'] = requestParameters.userIdGreaterThanOrEqual;
        }

        if (requestParameters.userIdLessThanOrEqual !== undefined) {
            queryParameters['userId.lessThanOrEqual'] = requestParameters.userIdLessThanOrEqual;
        }

        if (requestParameters.userIdEquals !== undefined) {
            queryParameters['userId.equals'] = requestParameters.userIdEquals;
        }

        if (requestParameters.userIdNotEquals !== undefined) {
            queryParameters['userId.notEquals'] = requestParameters.userIdNotEquals;
        }

        if (requestParameters.userIdSpecified !== undefined) {
            queryParameters['userId.specified'] = requestParameters.userIdSpecified;
        }

        if (requestParameters.userIdIn) {
            queryParameters['userId.in'] = requestParameters.userIdIn;
        }

        if (requestParameters.userIdNotIn) {
            queryParameters['userId.notIn'] = requestParameters.userIdNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-searcheds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySearchedFromJSON));
    }

    /**
     */
    async getAllCompanySearcheds(requestParameters: GetAllCompanySearchedsRequest): Promise<Array<CompanySearched>> {
        const response = await this.getAllCompanySearchedsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanySearchedRaw(requestParameters: GetCompanySearchedRequest): Promise<runtime.ApiResponse<CompanySearched>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanySearched.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-searcheds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySearchedFromJSON(jsonValue));
    }

    /**
     */
    async getCompanySearched(requestParameters: GetCompanySearchedRequest): Promise<CompanySearched> {
        const response = await this.getCompanySearchedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanySearchedRaw(requestParameters: PartialUpdateCompanySearchedRequest): Promise<runtime.ApiResponse<CompanySearched>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanySearched.');
        }

        if (requestParameters.companySearched === null || requestParameters.companySearched === undefined) {
            throw new runtime.RequiredError('companySearched','Required parameter requestParameters.companySearched was null or undefined when calling partialUpdateCompanySearched.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-searcheds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySearchedToJSON(requestParameters.companySearched),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySearchedFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanySearched(requestParameters: PartialUpdateCompanySearchedRequest): Promise<CompanySearched> {
        const response = await this.partialUpdateCompanySearchedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanySearchedRaw(requestParameters: UpdateCompanySearchedRequest): Promise<runtime.ApiResponse<CompanySearched>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanySearched.');
        }

        if (requestParameters.companySearched === null || requestParameters.companySearched === undefined) {
            throw new runtime.RequiredError('companySearched','Required parameter requestParameters.companySearched was null or undefined when calling updateCompanySearched.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-searcheds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySearchedToJSON(requestParameters.companySearched),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySearchedFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanySearched(requestParameters: UpdateCompanySearchedRequest): Promise<CompanySearched> {
        const response = await this.updateCompanySearchedRaw(requestParameters);
        return await response.value();
    }

}
