/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CancellazioneAaBz,
    CancellazioneAaBzFromJSON,
    CancellazioneAaBzFromJSONTyped,
    CancellazioneAaBzToJSON,
    MestieriAa,
    MestieriAaFromJSON,
    MestieriAaFromJSONTyped,
    MestieriAaToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttivitaAaBz
 */
export interface AttivitaAaBz {
    /**
     * 
     * @type {MestieriAa}
     * @memberof AttivitaAaBz
     */
    mestieriAa?: MestieriAa;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttivitaAaBz
     */
    descrizione?: Array<string>;
    /**
     * 
     * @type {CancellazioneAaBz}
     * @memberof AttivitaAaBz
     */
    cancellazioneAaBz?: CancellazioneAaBz;
    /**
     * 
     * @type {string}
     * @memberof AttivitaAaBz
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof AttivitaAaBz
     */
    fattivitaSecondaria?: string;
}

export function AttivitaAaBzFromJSON(json: any): AttivitaAaBz {
    return AttivitaAaBzFromJSONTyped(json, false);
}

export function AttivitaAaBzFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttivitaAaBz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mestieriAa': !exists(json, 'mestieriAa') ? undefined : MestieriAaFromJSON(json['mestieriAa']),
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'cancellazioneAaBz': !exists(json, 'cancellazioneAaBz') ? undefined : CancellazioneAaBzFromJSON(json['cancellazioneAaBz']),
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'fattivitaSecondaria': !exists(json, 'fattivitaSecondaria') ? undefined : json['fattivitaSecondaria'],
    };
}

export function AttivitaAaBzToJSON(value?: AttivitaAaBz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mestieriAa': MestieriAaToJSON(value.mestieriAa),
        'descrizione': value.descrizione,
        'cancellazioneAaBz': CancellazioneAaBzToJSON(value.cancellazioneAaBz),
        'dtInizio': value.dtInizio,
        'fattivitaSecondaria': value.fattivitaSecondaria,
    };
}


