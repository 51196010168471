/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyLinkRequest,
    CompanyLinkRequestFromJSON,
    CompanyLinkRequestToJSON,
    CompanyLinkRequestState,
    CompanyLinkRequestStateFromJSON,
    CompanyLinkRequestStateToJSON,
    InvitationDTO,
    InvitationDTOFromJSON,
    InvitationDTOToJSON,
} from '../models';

export interface CreateCompanyLinkRequestWithDefaultStateRequest {
    invitationDTO: InvitationDTO;
}

export interface GetAllCompanyLinkRequestsByCompanyRequest {
    id: number;
}

export interface GetAllCompanyLinkRequestsByConsultantRequest {
    id: number;
}

export interface UpdateCompanyLinkRequestState1Request {
    id: number;
    companyLinkRequestState: CompanyLinkRequestState;
}

/**
 * 
 */
export class CustomCompanyLinkRequestResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyLinkRequestWithDefaultStateRaw(requestParameters: CreateCompanyLinkRequestWithDefaultStateRequest): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.invitationDTO === null || requestParameters.invitationDTO === undefined) {
            throw new runtime.RequiredError('invitationDTO','Required parameter requestParameters.invitationDTO was null or undefined when calling createCompanyLinkRequestWithDefaultState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-link-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationDTOToJSON(requestParameters.invitationDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyLinkRequestWithDefaultState(requestParameters: CreateCompanyLinkRequestWithDefaultStateRequest): Promise<CompanyLinkRequest> {
        const response = await this.createCompanyLinkRequestWithDefaultStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCompanyLinkRequestsByCompanyRaw(requestParameters: GetAllCompanyLinkRequestsByCompanyRequest): Promise<runtime.ApiResponse<Array<CompanyLinkRequest>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllCompanyLinkRequestsByCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-link-requests/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyLinkRequestFromJSON));
    }

    /**
     */
    async getAllCompanyLinkRequestsByCompany(requestParameters: GetAllCompanyLinkRequestsByCompanyRequest): Promise<Array<CompanyLinkRequest>> {
        const response = await this.getAllCompanyLinkRequestsByCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCompanyLinkRequestsByConsultantRaw(requestParameters: GetAllCompanyLinkRequestsByConsultantRequest): Promise<runtime.ApiResponse<Array<CompanyLinkRequest>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllCompanyLinkRequestsByConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-link-requests/consultant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyLinkRequestFromJSON));
    }

    /**
     */
    async getAllCompanyLinkRequestsByConsultant(requestParameters: GetAllCompanyLinkRequestsByConsultantRequest): Promise<Array<CompanyLinkRequest>> {
        const response = await this.getAllCompanyLinkRequestsByConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyLinkRequestState1Raw(requestParameters: UpdateCompanyLinkRequestState1Request): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyLinkRequestState1.');
        }

        if (requestParameters.companyLinkRequestState === null || requestParameters.companyLinkRequestState === undefined) {
            throw new runtime.RequiredError('companyLinkRequestState','Required parameter requestParameters.companyLinkRequestState was null or undefined when calling updateCompanyLinkRequestState1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-link-requests/{id}/state/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestStateToJSON(requestParameters.companyLinkRequestState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyLinkRequestState1(requestParameters: UpdateCompanyLinkRequestState1Request): Promise<CompanyLinkRequest> {
        const response = await this.updateCompanyLinkRequestState1Raw(requestParameters);
        return await response.value();
    }

}
