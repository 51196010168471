const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const baseGradient = ['rgba(34, 34, 34, 1) ', 'rgba(65, 65, 65, 1)'];

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  gradient: {
    baseGradient: baseGradient
  }
};
