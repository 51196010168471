/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoSupplementariStoriche
 */
export interface InfoSupplementariStoriche {
    /**
     * 
     * @type {string}
     * @memberof InfoSupplementariStoriche
     */
    descrizioniAttoCostitutivo?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoSupplementariStoriche
     */
    notizieStoriche?: string;
}

export function InfoSupplementariStoricheFromJSON(json: any): InfoSupplementariStoriche {
    return InfoSupplementariStoricheFromJSONTyped(json, false);
}

export function InfoSupplementariStoricheFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoSupplementariStoriche {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'descrizioniAttoCostitutivo': !exists(json, 'descrizioniAttoCostitutivo') ? undefined : json['descrizioniAttoCostitutivo'],
        'notizieStoriche': !exists(json, 'notizieStoriche') ? undefined : json['notizieStoriche'],
    };
}

export function InfoSupplementariStoricheToJSON(value?: InfoSupplementariStoriche | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'descrizioniAttoCostitutivo': value.descrizioniAttoCostitutivo,
        'notizieStoriche': value.notizieStoriche,
    };
}


