/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InsightLike,
    InsightLikeFromJSON,
    InsightLikeToJSON,
} from '../models';

export interface CountInsightLikesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    likeStateEquals?: CountInsightLikesLikeStateEqualsEnum;
    likeStateNotEquals?: CountInsightLikesLikeStateNotEqualsEnum;
    likeStateSpecified?: boolean;
    likeStateIn?: Array<CountInsightLikesLikeStateInEnum>;
    likeStateNotIn?: Array<CountInsightLikesLikeStateNotInEnum>;
    editedAtGreaterThan?: Date;
    editedAtLessThan?: Date;
    editedAtGreaterThanOrEqual?: Date;
    editedAtLessThanOrEqual?: Date;
    editedAtEquals?: Date;
    editedAtNotEquals?: Date;
    editedAtSpecified?: boolean;
    editedAtIn?: Array<Date>;
    editedAtNotIn?: Array<Date>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    insightIdGreaterThan?: number;
    insightIdLessThan?: number;
    insightIdGreaterThanOrEqual?: number;
    insightIdLessThanOrEqual?: number;
    insightIdEquals?: number;
    insightIdNotEquals?: number;
    insightIdSpecified?: boolean;
    insightIdIn?: Array<number>;
    insightIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateInsightLikeRequest {
    insightLike: InsightLike;
}

export interface DeleteInsightLikeRequest {
    id: number;
}

export interface GetAllInsightLikesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    likeStateEquals?: GetAllInsightLikesLikeStateEqualsEnum;
    likeStateNotEquals?: GetAllInsightLikesLikeStateNotEqualsEnum;
    likeStateSpecified?: boolean;
    likeStateIn?: Array<GetAllInsightLikesLikeStateInEnum>;
    likeStateNotIn?: Array<GetAllInsightLikesLikeStateNotInEnum>;
    editedAtGreaterThan?: Date;
    editedAtLessThan?: Date;
    editedAtGreaterThanOrEqual?: Date;
    editedAtLessThanOrEqual?: Date;
    editedAtEquals?: Date;
    editedAtNotEquals?: Date;
    editedAtSpecified?: boolean;
    editedAtIn?: Array<Date>;
    editedAtNotIn?: Array<Date>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    insightIdGreaterThan?: number;
    insightIdLessThan?: number;
    insightIdGreaterThanOrEqual?: number;
    insightIdLessThanOrEqual?: number;
    insightIdEquals?: number;
    insightIdNotEquals?: number;
    insightIdSpecified?: boolean;
    insightIdIn?: Array<number>;
    insightIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetInsightLikeRequest {
    id: number;
}

export interface PartialUpdateInsightLikeRequest {
    id: number;
    insightLike: InsightLike;
}

export interface UpdateInsightLikeRequest {
    id: number;
    insightLike: InsightLike;
}

/**
 * 
 */
export class InsightLikeResourceApi extends runtime.BaseAPI {

    /**
     */
    async countInsightLikesRaw(requestParameters: CountInsightLikesRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.likeStateEquals !== undefined) {
            queryParameters['likeState.equals'] = requestParameters.likeStateEquals;
        }

        if (requestParameters.likeStateNotEquals !== undefined) {
            queryParameters['likeState.notEquals'] = requestParameters.likeStateNotEquals;
        }

        if (requestParameters.likeStateSpecified !== undefined) {
            queryParameters['likeState.specified'] = requestParameters.likeStateSpecified;
        }

        if (requestParameters.likeStateIn) {
            queryParameters['likeState.in'] = requestParameters.likeStateIn;
        }

        if (requestParameters.likeStateNotIn) {
            queryParameters['likeState.notIn'] = requestParameters.likeStateNotIn;
        }

        if (requestParameters.editedAtGreaterThan !== undefined) {
            queryParameters['editedAt.greaterThan'] = (requestParameters.editedAtGreaterThan as any).toISOString();
        }

        if (requestParameters.editedAtLessThan !== undefined) {
            queryParameters['editedAt.lessThan'] = (requestParameters.editedAtLessThan as any).toISOString();
        }

        if (requestParameters.editedAtGreaterThanOrEqual !== undefined) {
            queryParameters['editedAt.greaterThanOrEqual'] = (requestParameters.editedAtGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.editedAtLessThanOrEqual !== undefined) {
            queryParameters['editedAt.lessThanOrEqual'] = (requestParameters.editedAtLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.editedAtEquals !== undefined) {
            queryParameters['editedAt.equals'] = (requestParameters.editedAtEquals as any).toISOString();
        }

        if (requestParameters.editedAtNotEquals !== undefined) {
            queryParameters['editedAt.notEquals'] = (requestParameters.editedAtNotEquals as any).toISOString();
        }

        if (requestParameters.editedAtSpecified !== undefined) {
            queryParameters['editedAt.specified'] = requestParameters.editedAtSpecified;
        }

        if (requestParameters.editedAtIn) {
            queryParameters['editedAt.in'] = requestParameters.editedAtIn;
        }

        if (requestParameters.editedAtNotIn) {
            queryParameters['editedAt.notIn'] = requestParameters.editedAtNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.insightIdGreaterThan !== undefined) {
            queryParameters['insightId.greaterThan'] = requestParameters.insightIdGreaterThan;
        }

        if (requestParameters.insightIdLessThan !== undefined) {
            queryParameters['insightId.lessThan'] = requestParameters.insightIdLessThan;
        }

        if (requestParameters.insightIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightId.greaterThanOrEqual'] = requestParameters.insightIdGreaterThanOrEqual;
        }

        if (requestParameters.insightIdLessThanOrEqual !== undefined) {
            queryParameters['insightId.lessThanOrEqual'] = requestParameters.insightIdLessThanOrEqual;
        }

        if (requestParameters.insightIdEquals !== undefined) {
            queryParameters['insightId.equals'] = requestParameters.insightIdEquals;
        }

        if (requestParameters.insightIdNotEquals !== undefined) {
            queryParameters['insightId.notEquals'] = requestParameters.insightIdNotEquals;
        }

        if (requestParameters.insightIdSpecified !== undefined) {
            queryParameters['insightId.specified'] = requestParameters.insightIdSpecified;
        }

        if (requestParameters.insightIdIn) {
            queryParameters['insightId.in'] = requestParameters.insightIdIn;
        }

        if (requestParameters.insightIdNotIn) {
            queryParameters['insightId.notIn'] = requestParameters.insightIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-likes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countInsightLikes(requestParameters: CountInsightLikesRequest): Promise<number> {
        const response = await this.countInsightLikesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createInsightLikeRaw(requestParameters: CreateInsightLikeRequest): Promise<runtime.ApiResponse<InsightLike>> {
        if (requestParameters.insightLike === null || requestParameters.insightLike === undefined) {
            throw new runtime.RequiredError('insightLike','Required parameter requestParameters.insightLike was null or undefined when calling createInsightLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-likes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightLikeToJSON(requestParameters.insightLike),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightLikeFromJSON(jsonValue));
    }

    /**
     */
    async createInsightLike(requestParameters: CreateInsightLikeRequest): Promise<InsightLike> {
        const response = await this.createInsightLikeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteInsightLikeRaw(requestParameters: DeleteInsightLikeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInsightLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-likes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteInsightLike(requestParameters: DeleteInsightLikeRequest): Promise<void> {
        await this.deleteInsightLikeRaw(requestParameters);
    }

    /**
     */
    async getAllInsightLikesRaw(requestParameters: GetAllInsightLikesRequest): Promise<runtime.ApiResponse<Array<InsightLike>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.likeStateEquals !== undefined) {
            queryParameters['likeState.equals'] = requestParameters.likeStateEquals;
        }

        if (requestParameters.likeStateNotEquals !== undefined) {
            queryParameters['likeState.notEquals'] = requestParameters.likeStateNotEquals;
        }

        if (requestParameters.likeStateSpecified !== undefined) {
            queryParameters['likeState.specified'] = requestParameters.likeStateSpecified;
        }

        if (requestParameters.likeStateIn) {
            queryParameters['likeState.in'] = requestParameters.likeStateIn;
        }

        if (requestParameters.likeStateNotIn) {
            queryParameters['likeState.notIn'] = requestParameters.likeStateNotIn;
        }

        if (requestParameters.editedAtGreaterThan !== undefined) {
            queryParameters['editedAt.greaterThan'] = (requestParameters.editedAtGreaterThan as any).toISOString();
        }

        if (requestParameters.editedAtLessThan !== undefined) {
            queryParameters['editedAt.lessThan'] = (requestParameters.editedAtLessThan as any).toISOString();
        }

        if (requestParameters.editedAtGreaterThanOrEqual !== undefined) {
            queryParameters['editedAt.greaterThanOrEqual'] = (requestParameters.editedAtGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.editedAtLessThanOrEqual !== undefined) {
            queryParameters['editedAt.lessThanOrEqual'] = (requestParameters.editedAtLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.editedAtEquals !== undefined) {
            queryParameters['editedAt.equals'] = (requestParameters.editedAtEquals as any).toISOString();
        }

        if (requestParameters.editedAtNotEquals !== undefined) {
            queryParameters['editedAt.notEquals'] = (requestParameters.editedAtNotEquals as any).toISOString();
        }

        if (requestParameters.editedAtSpecified !== undefined) {
            queryParameters['editedAt.specified'] = requestParameters.editedAtSpecified;
        }

        if (requestParameters.editedAtIn) {
            queryParameters['editedAt.in'] = requestParameters.editedAtIn;
        }

        if (requestParameters.editedAtNotIn) {
            queryParameters['editedAt.notIn'] = requestParameters.editedAtNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.insightIdGreaterThan !== undefined) {
            queryParameters['insightId.greaterThan'] = requestParameters.insightIdGreaterThan;
        }

        if (requestParameters.insightIdLessThan !== undefined) {
            queryParameters['insightId.lessThan'] = requestParameters.insightIdLessThan;
        }

        if (requestParameters.insightIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightId.greaterThanOrEqual'] = requestParameters.insightIdGreaterThanOrEqual;
        }

        if (requestParameters.insightIdLessThanOrEqual !== undefined) {
            queryParameters['insightId.lessThanOrEqual'] = requestParameters.insightIdLessThanOrEqual;
        }

        if (requestParameters.insightIdEquals !== undefined) {
            queryParameters['insightId.equals'] = requestParameters.insightIdEquals;
        }

        if (requestParameters.insightIdNotEquals !== undefined) {
            queryParameters['insightId.notEquals'] = requestParameters.insightIdNotEquals;
        }

        if (requestParameters.insightIdSpecified !== undefined) {
            queryParameters['insightId.specified'] = requestParameters.insightIdSpecified;
        }

        if (requestParameters.insightIdIn) {
            queryParameters['insightId.in'] = requestParameters.insightIdIn;
        }

        if (requestParameters.insightIdNotIn) {
            queryParameters['insightId.notIn'] = requestParameters.insightIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-likes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightLikeFromJSON));
    }

    /**
     */
    async getAllInsightLikes(requestParameters: GetAllInsightLikesRequest): Promise<Array<InsightLike>> {
        const response = await this.getAllInsightLikesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInsightLikeRaw(requestParameters: GetInsightLikeRequest): Promise<runtime.ApiResponse<InsightLike>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInsightLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-likes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightLikeFromJSON(jsonValue));
    }

    /**
     */
    async getInsightLike(requestParameters: GetInsightLikeRequest): Promise<InsightLike> {
        const response = await this.getInsightLikeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateInsightLikeRaw(requestParameters: PartialUpdateInsightLikeRequest): Promise<runtime.ApiResponse<InsightLike>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInsightLike.');
        }

        if (requestParameters.insightLike === null || requestParameters.insightLike === undefined) {
            throw new runtime.RequiredError('insightLike','Required parameter requestParameters.insightLike was null or undefined when calling partialUpdateInsightLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-likes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InsightLikeToJSON(requestParameters.insightLike),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightLikeFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateInsightLike(requestParameters: PartialUpdateInsightLikeRequest): Promise<InsightLike> {
        const response = await this.partialUpdateInsightLikeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateInsightLikeRaw(requestParameters: UpdateInsightLikeRequest): Promise<runtime.ApiResponse<InsightLike>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInsightLike.');
        }

        if (requestParameters.insightLike === null || requestParameters.insightLike === undefined) {
            throw new runtime.RequiredError('insightLike','Required parameter requestParameters.insightLike was null or undefined when calling updateInsightLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-likes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightLikeToJSON(requestParameters.insightLike),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightLikeFromJSON(jsonValue));
    }

    /**
     */
    async updateInsightLike(requestParameters: UpdateInsightLikeRequest): Promise<InsightLike> {
        const response = await this.updateInsightLikeRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountInsightLikesLikeStateEqualsEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightLikesLikeStateNotEqualsEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightLikesLikeStateInEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightLikesLikeStateNotInEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightLikesLikeStateEqualsEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightLikesLikeStateNotEqualsEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightLikesLikeStateInEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightLikesLikeStateNotInEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}
