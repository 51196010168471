/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Descrizioni,
    DescrizioniFromJSON,
    DescrizioniFromJSONTyped,
    DescrizioniToJSON,
    Persona,
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
    TipoTrascrizione,
    TipoTrascrizioneFromJSON,
    TipoTrascrizioneFromJSONTyped,
    TipoTrascrizioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrascrizioneRs
 */
export interface TrascrizioneRs {
    /**
     * 
     * @type {Persona}
     * @memberof TrascrizioneRs
     */
    persona?: Persona;
    /**
     * 
     * @type {TipoTrascrizione}
     * @memberof TrascrizioneRs
     */
    tipoTrascrizione?: TipoTrascrizione;
    /**
     * 
     * @type {Descrizioni}
     * @memberof TrascrizioneRs
     */
    descrizioni?: Descrizioni;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneRs
     */
    tipoModifica?: string;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneRs
     */
    ptrascrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneRs
     */
    ctipoModifica?: string;
}

export function TrascrizioneRsFromJSON(json: any): TrascrizioneRs {
    return TrascrizioneRsFromJSONTyped(json, false);
}

export function TrascrizioneRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrascrizioneRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'persona': !exists(json, 'persona') ? undefined : PersonaFromJSON(json['persona']),
        'tipoTrascrizione': !exists(json, 'tipoTrascrizione') ? undefined : TipoTrascrizioneFromJSON(json['tipoTrascrizione']),
        'descrizioni': !exists(json, 'descrizioni') ? undefined : DescrizioniFromJSON(json['descrizioni']),
        'tipoModifica': !exists(json, 'tipoModifica') ? undefined : json['tipoModifica'],
        'ptrascrizione': !exists(json, 'ptrascrizione') ? undefined : json['ptrascrizione'],
        'ctipoModifica': !exists(json, 'ctipoModifica') ? undefined : json['ctipoModifica'],
    };
}

export function TrascrizioneRsToJSON(value?: TrascrizioneRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona': PersonaToJSON(value.persona),
        'tipoTrascrizione': TipoTrascrizioneToJSON(value.tipoTrascrizione),
        'descrizioni': DescrizioniToJSON(value.descrizioni),
        'tipoModifica': value.tipoModifica,
        'ptrascrizione': value.ptrascrizione,
        'ctipoModifica': value.ctipoModifica,
    };
}


