/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    name?: PlanNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    recurrence?: PlanRecurrenceEnum;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    monthsDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    creditsAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    searchesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    consultationHoursAmount?: number;
}

/**
* @export
* @enum {string}
*/
export enum PlanNameEnum {
    Start = 'START',
    Plus = 'PLUS',
    Easy = 'EASY',
    Advisory = 'ADVISORY',
    Analyst = 'ANALYST'
}/**
* @export
* @enum {string}
*/
export enum PlanRecurrenceEnum {
    UnaTantum = 'UNA_TANTUM',
    Subscription = 'SUBSCRIPTION'
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recurrence': !exists(json, 'recurrence') ? undefined : json['recurrence'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'monthsDuration': !exists(json, 'monthsDuration') ? undefined : json['monthsDuration'],
        'creditsAmount': !exists(json, 'creditsAmount') ? undefined : json['creditsAmount'],
        'searchesAmount': !exists(json, 'searchesAmount') ? undefined : json['searchesAmount'],
        'consultationHoursAmount': !exists(json, 'consultationHoursAmount') ? undefined : json['consultationHoursAmount'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'recurrence': value.recurrence,
        'price': value.price,
        'monthsDuration': value.monthsDuration,
        'creditsAmount': value.creditsAmount,
        'searchesAmount': value.searchesAmount,
        'consultationHoursAmount': value.consultationHoursAmount,
    };
}


