
import { CompanyBalanceSheet, CompanyData, CompanyLinkRequest, CompanySaved, DataBlockUser, MarketCompetitor, MarketCompetitorResponseDTO, User, UserExtended, UserExtendedTypeEnum, UserPlan } from "@services/apis/generated";
import { Company } from "@services/apis/generated/models/Company";
import { DataBlockUserDTO } from "@services/apis/generated/models/DataBlockUserDTO";
import React from "react";
import { Use } from "react-native-svg";
import { DownloadedBalance } from "../../types";

export type SharedContextType =
    {
        userMe?: User,//il mio User
        setUserMe: React.Dispatch<React.SetStateAction<User | undefined>>;
        userExtendedMe?: UserExtended,//il mio user extended
        setUserExtendedMe: React.Dispatch<React.SetStateAction<UserExtended>>;
        myCompanies?: Company[],//tutte le mie compagnie
        setMyCompanies: React.Dispatch<React.SetStateAction<Company[]>>;
        myCompanySelected?: Company,//la mia compagnia selezionata
        setMyCompanySelected: React.Dispatch<React.SetStateAction<Company | undefined>>;
        mySelectedCompanyLastData?: CompanyData, //i dati della mia compagnia selezionata
        setMySelectedCompanyLastData: React.Dispatch<React.SetStateAction<CompanyData | undefined>>,
        selectedCompany?: Company,//azienda che viene visionata nella dashboard
        setSelectedCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
        selectedCompanyLastData?: CompanyData,//dati dell'azienda che viene visionata nella dashboard
        setSelectedCompanyLastData: React.Dispatch<React.SetStateAction<CompanyData | undefined>>,
        companiesSaved?: CompanySaved[],//lista delle compagnie salvate dallo user 
        setCompaniesSaved: React.Dispatch<React.SetStateAction<CompanySaved[]>>;
        companyFavorite?: CompanySaved,//viene utilizzato per la logica della stellina del savataggio per l'azienda selezionata in quel momento
        setCompanyFavorite: React.Dispatch<React.SetStateAction<CompanySaved | undefined>>;
        who?: UserExtendedTypeEnum,//Lo user Type dell'utente loggato (Consultant or Company)
        setWho: React.Dispatch<React.SetStateAction<UserExtendedTypeEnum | undefined>>,
        myCompaniesConsultedRequest?: CompanyLinkRequest[],//lista delle aziende a cui ho inviato la richeista di collegamento
        setMyCompaniesConsultedRequest: React.Dispatch<React.SetStateAction<CompanyLinkRequest[]>>;
        myCompaniesConsultantRequest?: CompanyLinkRequest[],//lista delle aziende che mi hanno mandato la richeista di collegamento
        setMyCompaniesConsultantRequest: React.Dispatch<React.SetStateAction<CompanyLinkRequest[]>>;
        myMarketCompetitors?: MarketCompetitorResponseDTO[],//lisa di market e competitors salvati per la propria azienda
        setMyMarketCompetitors: React.Dispatch<React.SetStateAction<MarketCompetitorResponseDTO[]>>;
        myUserPlans?: UserPlan[];//indica il piano a cui è sottocritto l'user loggato
        setMyUserPlans: React.Dispatch<React.SetStateAction<UserPlan[]>>;
        lastBalance?: CompanyBalanceSheet;
        setLastBalance: React.Dispatch<React.SetStateAction<CompanyBalanceSheet>>;
        myDataBlock? :DataBlockUserDTO[];
        setMyDataBlock: React.Dispatch<React.SetStateAction<DataBlockUserDTO[]>>;
        revenue?:number;
        setRevenue: React.Dispatch<React.SetStateAction<number>>;
        downloadedBalances: DownloadedBalance[];
        setDownloadedBalances: React.Dispatch<React.SetStateAction<DownloadedBalance[]>>;
        notificationCheck?: Boolean;
        setNotificationCheck: React.Dispatch<React.SetStateAction<Boolean>>;
        myConsultedsForConsultant?: Company[],
        setMyConsultedsForConsultant: React.Dispatch<React.SetStateAction<Company[]>>;
        linkRequestCheck?: Boolean;
        setLinkRequestCheck: React.Dispatch<React.SetStateAction<Boolean>>;
        linkButton?: Boolean;
        setLinkButton: React.Dispatch<React.SetStateAction<Boolean>>;
    }

const SharedContext = React.createContext<SharedContextType>({
    userMe: undefined,
    setUserMe(value: ((prevState: (User)) => (User | undefined)) | User | undefined): void { },
    userExtendedMe: undefined,
    setUserExtendedMe(value: ((prevState: (UserExtended)) => (UserExtended | undefined)) | UserExtended | undefined): void { },
    myCompanies: undefined,
    setMyCompanies(value: ((prevState: (Company[])) => (Company[])) | Company[]): void { },
    myCompanySelected: undefined,
    setMyCompanySelected(value: ((prevState: (Company)) => (Company | undefined)) | Company | undefined): void { },
    mySelectedCompanyLastData: undefined,
    setMySelectedCompanyLastData(value: ((prevState: (CompanyData)) => (CompanyData | undefined)) | CompanyData | undefined): void { },
    myCompaniesConsultedRequest: undefined,
    setMyCompaniesConsultedRequest(value: ((prevState: (Company[])) => (Company[])) | Company[]): void { },
    myCompaniesConsultantRequest: undefined,
    setMyCompaniesConsultantRequest(value: ((prevState: (Company[])) => (Company[])) | Company[]): void { },
    selectedCompany: undefined,
    setSelectedCompany(value: ((prevState: (Company)) => (Company | undefined)) | Company | undefined): void { },
    selectedCompanyLastData: undefined,
    setSelectedCompanyLastData(value: ((prevState: (CompanyData)) => (CompanyData | undefined)) | CompanyData | undefined): void { },
    companiesSaved: undefined,
    setCompaniesSaved(value: ((prevState: (CompanySaved[])) => (CompanySaved[])) | CompanySaved[]): void { },
    companyFavorite: undefined,
    setCompanyFavorite(value: ((prevState: (CompanySaved)) => (CompanySaved | undefined)) | CompanySaved | undefined): void { },
    who: undefined,
    setWho(value: ((prevState: (UserExtendedTypeEnum)) => (UserExtendedTypeEnum | undefined)) | UserExtendedTypeEnum | undefined): void { },
    myMarketCompetitors: undefined,
    setMyMarketCompetitors(value: ((prevState: (MarketCompetitorResponseDTO[])) => (MarketCompetitorResponseDTO[])) | MarketCompetitorResponseDTO[]): void { },
    myUserPlans: undefined,
    setMyUserPlans(value: ((prevState: (UserPlan[])) => (UserPlan[])) | UserPlan[]): void { },
    lastBalance: undefined,
    setLastBalance(value: ((prevState: (CompanyBalanceSheet)) => (CompanyBalanceSheet | undefined)) | CompanyBalanceSheet | undefined): void { },
    myDataBlock:undefined,
    setMyDataBlock(value: ((prevState: (DataBlockUserDTO[])) => (DataBlockUserDTO[] )) | DataBlockUserDTO[] ): void { },
    revenue:undefined,
    setRevenue(value: ((prevState: (number)) => (number | undefined)) | number | undefined): void { },
    downloadedBalances: [],
    setDownloadedBalances(value: ((prevState: (DownloadedBalance[])) => (DownloadedBalance[] )) | DownloadedBalance[] ): void { },
    notificationCheck: undefined,
    setNotificationCheck(value: ((prevState: (Boolean)) => (Boolean | undefined)) | Boolean | undefined): void { },
    myConsultedsForConsultant: undefined,
    setMyConsultedsForConsultant(value: ((prevState: (Company[])) => (Company[])) | Company[]): void { },
    linkRequestCheck: undefined,
    setLinkRequestCheck(value: ((prevState: (Boolean)) => (Boolean | undefined)) | Boolean | undefined): void { },
    linkButton: undefined,
    setLinkButton(value: ((prevState: (Boolean)) => (Boolean | undefined)) | Boolean | undefined): void { },
});


export default SharedContext;