/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatiBilancio,
    DatiBilancioFromJSON,
    DatiBilancioFromJSONTyped,
    DatiBilancioToJSON,
} from './';

/**
 * 
 * @export
 * @interface DatiBilanci
 */
export interface DatiBilanci {
    /**
     * 
     * @type {Array<DatiBilancio>}
     * @memberof DatiBilanci
     */
    datiBilancio: Array<DatiBilancio>;
}

export function DatiBilanciFromJSON(json: any): DatiBilanci {
    return DatiBilanciFromJSONTyped(json, false);
}

export function DatiBilanciFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatiBilanci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datiBilancio': ((json['datiBilancio'] as Array<any>).map(DatiBilancioFromJSON)),
    };
}

export function DatiBilanciToJSON(value?: DatiBilanci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datiBilancio': ((value.datiBilancio as Array<any>).map(DatiBilancioToJSON)),
    };
}


