/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Apparecchi
 */
export interface Apparecchi {
    /**
     * 
     * @type {string}
     * @memberof Apparecchi
     */
    nimpastatrici?: string;
    /**
     * 
     * @type {string}
     * @memberof Apparecchi
     */
    nformatrici?: string;
    /**
     * 
     * @type {string}
     * @memberof Apparecchi
     */
    ngrissinatrici?: string;
    /**
     * 
     * @type {string}
     * @memberof Apparecchi
     */
    nspezzatrici?: string;
    /**
     * 
     * @type {string}
     * @memberof Apparecchi
     */
    nlaminatoi?: string;
}

export function ApparecchiFromJSON(json: any): Apparecchi {
    return ApparecchiFromJSONTyped(json, false);
}

export function ApparecchiFromJSONTyped(json: any, ignoreDiscriminator: boolean): Apparecchi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nimpastatrici': !exists(json, 'nimpastatrici') ? undefined : json['nimpastatrici'],
        'nformatrici': !exists(json, 'nformatrici') ? undefined : json['nformatrici'],
        'ngrissinatrici': !exists(json, 'ngrissinatrici') ? undefined : json['ngrissinatrici'],
        'nspezzatrici': !exists(json, 'nspezzatrici') ? undefined : json['nspezzatrici'],
        'nlaminatoi': !exists(json, 'nlaminatoi') ? undefined : json['nlaminatoi'],
    };
}

export function ApparecchiToJSON(value?: Apparecchi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nimpastatrici': value.nimpastatrici,
        'nformatrici': value.nformatrici,
        'ngrissinatrici': value.ngrissinatrici,
        'nspezzatrici': value.nspezzatrici,
        'nlaminatoi': value.nlaminatoi,
    };
}


