/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PraticaSoggettiControllanti,
    PraticaSoggettiControllantiFromJSON,
    PraticaSoggettiControllantiFromJSONTyped,
    PraticaSoggettiControllantiToJSON,
} from './';

/**
 * 
 * @export
 * @interface PraticheSoggettiControllanti
 */
export interface PraticheSoggettiControllanti {
    /**
     * 
     * @type {Array<PraticaSoggettiControllanti>}
     * @memberof PraticheSoggettiControllanti
     */
    praticaSoggettiControllanti?: Array<PraticaSoggettiControllanti>;
    /**
     * 
     * @type {string}
     * @memberof PraticheSoggettiControllanti
     */
    fpresenzaInfo?: string;
}

export function PraticheSoggettiControllantiFromJSON(json: any): PraticheSoggettiControllanti {
    return PraticheSoggettiControllantiFromJSONTyped(json, false);
}

export function PraticheSoggettiControllantiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PraticheSoggettiControllanti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'praticaSoggettiControllanti': !exists(json, 'praticaSoggettiControllanti') ? undefined : ((json['praticaSoggettiControllanti'] as Array<any>).map(PraticaSoggettiControllantiFromJSON)),
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function PraticheSoggettiControllantiToJSON(value?: PraticheSoggettiControllanti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'praticaSoggettiControllanti': value.praticaSoggettiControllanti === undefined ? undefined : ((value.praticaSoggettiControllanti as Array<any>).map(PraticaSoggettiControllantiToJSON)),
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


