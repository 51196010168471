/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiNascita
 */
export interface EstremiNascita {
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    dt?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    comune?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    cstato?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiNascita
     */
    ccomune?: string;
}

export function EstremiNascitaFromJSON(json: any): EstremiNascita {
    return EstremiNascitaFromJSONTyped(json, false);
}

export function EstremiNascitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiNascita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dt': !exists(json, 'dt') ? undefined : json['dt'],
        'comune': !exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
        'ccomune': !exists(json, 'ccomune') ? undefined : json['ccomune'],
    };
}

export function EstremiNascitaToJSON(value?: EstremiNascita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dt': value.dt,
        'comune': value.comune,
        'provincia': value.provincia,
        'stato': value.stato,
        'cstato': value.cstato,
        'ccomune': value.ccomune,
    };
}


