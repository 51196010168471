/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NSoci
 */
export interface NSoci {
    /**
     * 
     * @type {string}
     * @memberof NSoci
     */
    soci?: string;
    /**
     * 
     * @type {string}
     * @memberof NSoci
     */
    accomandatari?: string;
    /**
     * 
     * @type {string}
     * @memberof NSoci
     */
    amministratori?: string;
}

export function NSociFromJSON(json: any): NSoci {
    return NSociFromJSONTyped(json, false);
}

export function NSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): NSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'soci': !exists(json, 'soci') ? undefined : json['soci'],
        'accomandatari': !exists(json, 'accomandatari') ? undefined : json['accomandatari'],
        'amministratori': !exists(json, 'amministratori') ? undefined : json['amministratori'],
    };
}

export function NSociToJSON(value?: NSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'soci': value.soci,
        'accomandatari': value.accomandatari,
        'amministratori': value.amministratori,
    };
}


