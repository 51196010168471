/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Modelli,
    ModelliFromJSON,
    ModelliFromJSONTyped,
    ModelliToJSON,
    TrascrizioniRi,
    TrascrizioniRiFromJSON,
    TrascrizioniRiFromJSONTyped,
    TrascrizioniRiToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModelliTrascrizioni
 */
export interface ModelliTrascrizioni {
    /**
     * 
     * @type {Modelli}
     * @memberof ModelliTrascrizioni
     */
    modelli?: Modelli;
    /**
     * 
     * @type {TrascrizioniRi}
     * @memberof ModelliTrascrizioni
     */
    trascrizioniRi?: TrascrizioniRi;
}

export function ModelliTrascrizioniFromJSON(json: any): ModelliTrascrizioni {
    return ModelliTrascrizioniFromJSONTyped(json, false);
}

export function ModelliTrascrizioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelliTrascrizioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelli': !exists(json, 'modelli') ? undefined : ModelliFromJSON(json['modelli']),
        'trascrizioniRi': !exists(json, 'trascrizioniRi') ? undefined : TrascrizioniRiFromJSON(json['trascrizioniRi']),
    };
}

export function ModelliTrascrizioniToJSON(value?: ModelliTrascrizioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelli': ModelliToJSON(value.modelli),
        'trascrizioniRi': TrascrizioniRiToJSON(value.trascrizioniRi),
    };
}


