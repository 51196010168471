/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserConsultantInvite,
    UserConsultantInviteFromJSON,
    UserConsultantInviteToJSON,
    UserConsultantInviteDTO,
    UserConsultantInviteDTOFromJSON,
    UserConsultantInviteDTOToJSON,
    UserConsultantInviteState,
    UserConsultantInviteStateFromJSON,
    UserConsultantInviteStateToJSON,
} from '../models';

export interface CreateUserConsultantInviteWithDefaultStateRequest {
    userConsultantInviteDTO: UserConsultantInviteDTO;
}

export interface DeleteUserConsultantInviteForLoggedUserRequest {
    id: number;
}

export interface GetAllUserConsultantInviteByConsultantRequest {
    id: number;
}

export interface GetAllUserConsultantInviteByUserRequest {
    id: number;
}

export interface UpdateUserConsultantInviteState1Request {
    id: number;
    userConsultantInviteState: UserConsultantInviteState;
}

/**
 * 
 */
export class CustomUserConsultantInviteResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserConsultantInviteWithDefaultStateRaw(requestParameters: CreateUserConsultantInviteWithDefaultStateRequest): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.userConsultantInviteDTO === null || requestParameters.userConsultantInviteDTO === undefined) {
            throw new runtime.RequiredError('userConsultantInviteDTO','Required parameter requestParameters.userConsultantInviteDTO was null or undefined when calling createUserConsultantInviteWithDefaultState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-consultant-invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteDTOToJSON(requestParameters.userConsultantInviteDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async createUserConsultantInviteWithDefaultState(requestParameters: CreateUserConsultantInviteWithDefaultStateRequest): Promise<UserConsultantInvite> {
        const response = await this.createUserConsultantInviteWithDefaultStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserConsultantInviteForLoggedUserRaw(requestParameters: DeleteUserConsultantInviteForLoggedUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConsultantInviteForLoggedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultant-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserConsultantInviteForLoggedUser(requestParameters: DeleteUserConsultantInviteForLoggedUserRequest): Promise<void> {
        await this.deleteUserConsultantInviteForLoggedUserRaw(requestParameters);
    }

    /**
     */
    async getAllUserConsultantInviteByConsultantRaw(requestParameters: GetAllUserConsultantInviteByConsultantRequest): Promise<runtime.ApiResponse<Array<UserConsultantInvite>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllUserConsultantInviteByConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultant-invites/consultant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantInviteFromJSON));
    }

    /**
     */
    async getAllUserConsultantInviteByConsultant(requestParameters: GetAllUserConsultantInviteByConsultantRequest): Promise<Array<UserConsultantInvite>> {
        const response = await this.getAllUserConsultantInviteByConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllUserConsultantInviteByUserRaw(requestParameters: GetAllUserConsultantInviteByUserRequest): Promise<runtime.ApiResponse<Array<UserConsultantInvite>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllUserConsultantInviteByUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultant-invites/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantInviteFromJSON));
    }

    /**
     */
    async getAllUserConsultantInviteByUser(requestParameters: GetAllUserConsultantInviteByUserRequest): Promise<Array<UserConsultantInvite>> {
        const response = await this.getAllUserConsultantInviteByUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserConsultantInviteState1Raw(requestParameters: UpdateUserConsultantInviteState1Request): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserConsultantInviteState1.');
        }

        if (requestParameters.userConsultantInviteState === null || requestParameters.userConsultantInviteState === undefined) {
            throw new runtime.RequiredError('userConsultantInviteState','Required parameter requestParameters.userConsultantInviteState was null or undefined when calling updateUserConsultantInviteState1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-consultant-invites/{id}/state/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteStateToJSON(requestParameters.userConsultantInviteState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async updateUserConsultantInviteState1(requestParameters: UpdateUserConsultantInviteState1Request): Promise<UserConsultantInvite> {
        const response = await this.updateUserConsultantInviteState1Raw(requestParameters);
        return await response.value();
    }

}
