/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Prelazione
 */
export interface Prelazione {
    /**
     * 
     * @type {string}
     * @memberof Prelazione
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Prelazione
     */
    fpresenzaNelloStatuto?: string;
}

export function PrelazioneFromJSON(json: any): Prelazione {
    return PrelazioneFromJSONTyped(json, false);
}

export function PrelazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prelazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'fpresenzaNelloStatuto': !exists(json, 'fpresenzaNelloStatuto') ? undefined : json['fpresenzaNelloStatuto'],
    };
}

export function PrelazioneToJSON(value?: Prelazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'fpresenzaNelloStatuto': value.fpresenzaNelloStatuto,
    };
}


