import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import hykeeStyle from '@components/hykeeStyle';
import Collapsible from 'react-native-collapsible';
import Icon from '@screens/Tools/Icon';
import { BackgroundColorButtonGreen, BackgroundColorButtonGreyLight } from '@components/ColorTheme';
import { CriteriaSelected, CriteriaType } from '../../../types';
import { i18n } from '@i18n/i18n';
import { Dictionary, groupBy } from 'lodash';
import _ from 'lodash';

export default function HykeeFilter(props: { criteria?: CriteriaType[], callbackCriteria: Function, isMultiple: boolean, showSelected?: boolean }) {

    let referenceList: any

    const [criteriaList, setCriteriaList] = React.useState<CriteriaType[]>([])
    const [criteriaListFullReference, setCriteriaListFullReference] = React.useState<CriteriaType[]>([])

    const [collapsedTop, setCollapsedTop] = React.useState<boolean>(true);
    const [collapsedBottom, setCollapsedBottom] = React.useState<boolean>(true);

    //SINGLE SELECT
    const [criteria, setCriteria] = React.useState<string>('');
    const [criteriaOptions, setCriteriaOptions] = React.useState<CriteriaType>();
    const [criteriaOptionSelected, setCriteriaOptionSelected] = React.useState<string>('');

    //MULTIPLE SELECT
    const [criteriaDataSelectedMultiple, setCriteriaDataSelectedMultiple] = React.useState<CriteriaSelected[]>([]);
    let groupedCriteria = {};

    const [addresseeList, setAddresseeList] = React.useState<Dictionary<CriteriaSelected[]>>();

    useFocusEffect(
        useCallback(() => {
            if (props.criteria && props.criteria?.length > 0) {
                referenceList = _.cloneDeep(props.criteria)
                setCriteriaList(props.criteria);
                setCriteriaListFullReference(referenceList);
            }
        }, [props.criteria])
    );


    const criteriaSelected = (criteria: string) => {
        let criteriaFound = criteriaList?.find((elem) => elem.nameCriteria == criteria);
        if (props.isMultiple) {
            if (criteriaFound) {
                setCriteriaOptions(criteriaFound);
                setCriteriaOptionSelected('');
                setCriteria(criteriaFound.nameCriteria);
                setCollapsedBottom(false);
            }
        } else {
            if (criteriaFound) {
                setCriteriaOptions(criteriaFound);
                setCriteriaOptionSelected('');
                setCriteria(criteriaFound.nameCriteria);
                setCollapsedBottom(false);
            }
        }
    }

    const setOptionForCriteria = async (data: string, criteriaOptions: CriteriaType) => {
        if (props.isMultiple) {
            let alreadyAdd = criteriaDataSelectedMultiple.find((elem) => elem.criteriaSelected == data);
            if (!alreadyAdd) {
                let criteriaObj: CriteriaSelected = {
                    nameCriteria: criteriaOptions.nameCriteria,
                    criteriaSelected: data
                }
                setCriteriaDataSelectedMultiple([...criteriaDataSelectedMultiple, criteriaObj]);
                updateListAfterSelection(criteriaObj, false);
            }
            groupCriteria();
            props.callbackCriteria(criteriaDataSelectedMultiple);
        } else {
            setCriteriaOptionSelected(data);
            setCollapsedBottom(true);
            groupCriteria();
            props.callbackCriteria(data);
        }

    }

    const updateListAfterSelection = (data: CriteriaSelected, removeFromSelected: boolean) => {
        let criteriaEdited = criteriaList;
        if (!removeFromSelected) {
            for (let criteriaElem of criteriaEdited) {
                if (criteriaElem.nameCriteria == criteriaOptions?.nameCriteria) {
                    if (!criteriaOptions.multipleSelect) {
                        //Remove criteria from list
                        criteriaEdited = criteriaEdited.filter(crit => crit.nameCriteria != criteriaOptions.nameCriteria);
                        setCriteriaOptions(undefined); //DISABLE OPTIONS
                        setCriteria('')
                    } else {
                        //Remove criteria option from list
                        criteriaElem.dataCriteria = criteriaElem.dataCriteria.filter(option => option != data.criteriaSelected);
                    }
                }
            }
            setCriteriaList([...criteriaEdited]);
        } else {
            let criteriaFound = criteriaListFullReference.find((crit) => crit.nameCriteria == data.nameCriteria);

            if (!criteriaFound?.multipleSelect) {
                criteriaEdited.push(criteriaFound!);
            } else {
                for (let criteriaElem of criteriaEdited) {
                    if (criteriaElem.nameCriteria == data.nameCriteria) {
                        criteriaElem.dataCriteria.push(data.criteriaSelected);
                    }
                }
            }

            setCriteriaList([...criteriaEdited]);
        }
    }

    const removeFilter = (removed?: CriteriaSelected) => {
        let crit = criteriaDataSelectedMultiple.filter(item => item !== removed)
        setCriteriaDataSelectedMultiple([...crit]);
        updateListAfterSelection(removed!, true);
        groupCriteria(crit);
    }

    const groupCriteria = (crit?: any) => {
        let grouped
        if (crit) {
            grouped = groupBy(crit, groupByElem => groupByElem.nameCriteria);
        } else {
            grouped = groupBy(criteriaDataSelectedMultiple, groupByElem => groupByElem.nameCriteria);
        }
        setAddresseeList(grouped);
    }

    return (
        <View style={styles.criteriaContainer}>

            {/**GREEN BOX WITH CRITERIA SELECTED*/}
            <View style={styles.criteriaMultipleContainer}>
                {props.isMultiple && criteriaDataSelectedMultiple.length > 0 && criteriaDataSelectedMultiple.map((data, key) => {
                    return (
                        <View key={key} style={styles.criteriaMultipleList}>
                            <Text style={[styles.textRowPlaceholder, { marginRight: 4 }]}>{data.criteriaSelected}</Text>
                            <TouchableOpacity style={{ padding: 10 }} onPress={() => removeFilter(data)} >
                                <Icon name={"remove"} color={"white"} />
                            </TouchableOpacity>
                        </View>
                    )
                })
                }
            </View>

            {/**SELECT TOP OPTION*/}
            <View style={[styles.inputRow]}>
                <View style={{ flex: 1 }}>
                    <TouchableOpacity
                        style={[styles.inputCollapsible, { flexDirection: 'row', alignItems: 'center' }]}
                        onPress={() => setCollapsedTop(!collapsedTop)}>

                        {criteria ? <Text style={[styles.textRowPlaceholder, { color: "white" }]}>{i18n.t("components.partner_program.filters." + criteria, { defaultValue: criteria })}</Text>
                            : <Text style={styles.textRowPlaceholder}>{i18n.t("components.hykeeFilter.filter_category")}</Text>}

                        <Icon name="angle-down" size={24} color={'#737373'} />
                    </TouchableOpacity>
                </View>
            </View>

            <View style={[{ marginTop: 10 }]}>
                <Collapsible
                    duration={500}
                    easing={'easeInBounce'}
                    collapsed={collapsedTop}
                    style={hykeeStyle.containerCollapse}
                >
                    {criteriaList && criteriaList.map((criteria, key) => {
                        return (
                            <TouchableOpacity
                                key={key}
                                style={hykeeStyle.containerTextCollapse}
                                onPress={() => {
                                    criteriaSelected(criteria.nameCriteria);
                                    setCollapsedTop(!collapsedTop)
                                }}>
                                <Text style={styles.textInputRow}>
                                    {i18n.t("components.partner_program.filters." + criteria.nameCriteria, { defaultValue: criteria.nameCriteria })}
                                </Text>
                            </TouchableOpacity>
                        )
                    })}
                </Collapsible>
            </View>

            <View style={[styles.inputRow]}>

                {/**SELECT BOTTOM OPTION*/}
                <View style={{ flex: 1 }}>
                    <TouchableOpacity
                        style={[styles.inputCollapsible, { flexDirection: 'row', alignItems: 'center' }]}
                        onPress={() => setCollapsedBottom(!collapsedBottom)}>
                        <Text style={styles.textRowPlaceholder}>{criteriaOptionSelected ? i18n.t("components.partner_program.filters." + criteriaOptionSelected, {defaultValue: criteriaOptionSelected}) : i18n.t("components.hykeeFilter.filter_order")}</Text>
                        <Icon name="angle-down" size={24} color={'#737373'} />
                    </TouchableOpacity>
                </View>
            </View>

            <View>
                <Collapsible
                    duration={500}
                    easing={'easeInBounce'}
                    collapsed={collapsedBottom}
                    style={hykeeStyle.containerCollapse}
                >
                    {criteriaOptions && criteriaOptions.dataCriteria.map((data, key) => {
                        return (
                            <TouchableOpacity
                                key={key}
                                style={hykeeStyle.containerTextCollapse}
                                onPress={() => {
                                    setOptionForCriteria(data, criteriaOptions)
                                    setCollapsedBottom(!collapsedBottom)
                                }}>
                                <Text style={styles.textInputRow}>
                                    {i18n.t("components.partner_program.filters." + data, { defaultValue: data })}
                                </Text>
                            </TouchableOpacity>
                        )
                    })}
                </Collapsible>
            </View>
            {addresseeList &&
                Object.values(addresseeList).map((array, index) => {
                    let key = Object.keys(addresseeList)[index]
                    let values: string[] = []
                    array.map((element) => {
                        values.push(element.criteriaSelected)
                    })
                    return (
                        <View key={index} style={styles.addresseesContainer}>
                            <View style={{ flex: 0.4, margin: 5 }}>
                                <Text style={styles.addresseesTitle}>
                                    {i18n.t("components.partner_program.filters." + key, { defaultValue: key })}
                                </Text>
                            </View>
                            <View style={{ flex: 0.6, margin: 5 }}>
                                {values.map((val, index) => {
                                    return (
                                        <Text key={index} style={styles.addresseesValue}>{val}</Text>
                                    )
                                })}
                            </View>

                        </View>
                    )

                })
            }

        </View>
    )
}

const styles = StyleSheet.create({
    criteriaMultipleContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 4,
        marginBottom: 10
    },
    criteriaMultipleList: {
        minWidth: 140,
        marginRight: 4,
        marginTop: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: BackgroundColorButtonGreen(),
        color: 'white',
        fontSize: 13,
        borderRadius: 5,
        padding: 8
    },
    criteriaContainer: {
        flexDirection: 'column',
        marginVertical: 20,
    },
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textInputRow: {
        backgroundColor: '#464646',
        borderRadius: 5,
        fontSize: 12,
        padding: 10,
        color: 'white',
        fontFamily: 'poppins',
    },
    inputCollapsible: {
        borderRadius: 5,
        flex: 1,
        padding: 7,
        backgroundColor: BackgroundColorButtonGreyLight(),
    },
    textRowPlaceholder: {
        paddingLeft: 10,
        flex: 0.9,
        fontSize: 12,
        color: '#737373',
        fontFamily: 'poppins',
    },
    addresseesContainer: {
        flexDirection: "row",
        margin: 10,
    },
    addresseesTitle: {
        color: "white",
        fontFamily: "poppins",
    },
    addresseesValue: {
        color: "white",
        fontFamily: "poppins",
    }
})