/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProvenienzaTrasferimento
 */
export interface ProvenienzaTrasferimento {
    /**
     * 
     * @type {string}
     * @memberof ProvenienzaTrasferimento
     */
    dtTrasferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof ProvenienzaTrasferimento
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof ProvenienzaTrasferimento
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof ProvenienzaTrasferimento
     */
    nrd?: string;
    /**
     * 
     * @type {string}
     * @memberof ProvenienzaTrasferimento
     */
    naa?: string;
}

export function ProvenienzaTrasferimentoFromJSON(json: any): ProvenienzaTrasferimento {
    return ProvenienzaTrasferimentoFromJSONTyped(json, false);
}

export function ProvenienzaTrasferimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProvenienzaTrasferimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtTrasferimento': !exists(json, 'dtTrasferimento') ? undefined : json['dtTrasferimento'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
        'naa': !exists(json, 'naa') ? undefined : json['naa'],
    };
}

export function ProvenienzaTrasferimentoToJSON(value?: ProvenienzaTrasferimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtTrasferimento': value.dtTrasferimento,
        'cciaa': value.cciaa,
        'nrea': value.nrea,
        'nrd': value.nrd,
        'naa': value.naa,
    };
}


