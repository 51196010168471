/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringFilter
 */
export interface StringFilter {
    /**
     * 
     * @type {string}
     * @memberof StringFilter
     */
    equals?: string;
    /**
     * 
     * @type {string}
     * @memberof StringFilter
     */
    notEquals?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StringFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringFilter
     */
    _in?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringFilter
     */
    notIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StringFilter
     */
    contains?: string;
    /**
     * 
     * @type {string}
     * @memberof StringFilter
     */
    doesNotContain?: string;
}

export function StringFilterFromJSON(json: any): StringFilter {
    return StringFilterFromJSONTyped(json, false);
}

export function StringFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
        'contains': !exists(json, 'contains') ? undefined : json['contains'],
        'doesNotContain': !exists(json, 'doesNotContain') ? undefined : json['doesNotContain'],
    };
}

export function StringFilterToJSON(value?: StringFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
        'contains': value.contains,
        'doesNotContain': value.doesNotContain,
    };
}


