/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyStatusFilter
 */
export interface SurveyStatusFilter {
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusFilter
     */
    equals?: SurveyStatusFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusFilter
     */
    notEquals?: SurveyStatusFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyStatusFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyStatusFilter
     */
    _in?: Array<SurveyStatusFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyStatusFilter
     */
    notIn?: Array<SurveyStatusFilterNotInEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SurveyStatusFilterEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}/**
* @export
* @enum {string}
*/
export enum SurveyStatusFilterNotEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}/**
* @export
* @enum {string}
*/
export enum SurveyStatusFilterInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}/**
* @export
* @enum {string}
*/
export enum SurveyStatusFilterNotInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}

export function SurveyStatusFilterFromJSON(json: any): SurveyStatusFilter {
    return SurveyStatusFilterFromJSONTyped(json, false);
}

export function SurveyStatusFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyStatusFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function SurveyStatusFilterToJSON(value?: SurveyStatusFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}


