/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TipoConferimenti
 */
export interface TipoConferimenti {
    /**
     * 
     * @type {string}
     * @memberof TipoConferimenti
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof TipoConferimenti
     */
    c?: string;
}

export function TipoConferimentiFromJSON(json: any): TipoConferimenti {
    return TipoConferimentiFromJSONTyped(json, false);
}

export function TipoConferimentiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipoConferimenti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'c': !exists(json, 'c') ? undefined : json['c'],
    };
}

export function TipoConferimentiToJSON(value?: TipoConferimenti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'c': value.c,
    };
}


