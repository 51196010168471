import React from "react";
import {CustomModalErrorInput} from "../interfaces/CustomModalErrorInput";

export type AppContextType =
    {
        loading: boolean | undefined,
        setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
        loggedIn: boolean,
        setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
        modalError: CustomModalErrorInput,
        setModalError: React.Dispatch<React.SetStateAction<CustomModalErrorInput>>;
        setCustomModalError: (newValue: CustomModalErrorInput) => void;
    }

const AppContext = React.createContext<AppContextType>({
    loading: undefined,
    setLoading(value: ((prevState: boolean) => boolean | undefined) | boolean | undefined): void {
    },
    loggedIn: false,
    setLoggedIn(value: ((prevState: boolean) => boolean) | boolean): void {
    },
    modalError: {visible: false, msg: ""},
    setModalError(value: ((prevState: CustomModalErrorInput) => CustomModalErrorInput) | CustomModalErrorInput): void {
    },
    setCustomModalError(newValue: CustomModalErrorInput): void {
    },
});


export default AppContext;