/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiAtto,
    EstremiAttoFromJSON,
    EstremiAttoFromJSONTyped,
    EstremiAttoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CessazioneLocalizzazione
 */
export interface CessazioneLocalizzazione {
    /**
     * 
     * @type {EstremiAtto}
     * @memberof CessazioneLocalizzazione
     */
    estremiAtto?: EstremiAtto;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    infoCessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    dtCessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    dtDomanda?: string;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    dtDenuncia?: string;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    causale?: string;
    /**
     * 
     * @type {string}
     * @memberof CessazioneLocalizzazione
     */
    ccausale?: string;
}

export function CessazioneLocalizzazioneFromJSON(json: any): CessazioneLocalizzazione {
    return CessazioneLocalizzazioneFromJSONTyped(json, false);
}

export function CessazioneLocalizzazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): CessazioneLocalizzazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiAtto': !exists(json, 'estremiAtto') ? undefined : EstremiAttoFromJSON(json['estremiAtto']),
        'infoCessazione': !exists(json, 'infoCessazione') ? undefined : json['infoCessazione'],
        'dtCessazione': !exists(json, 'dtCessazione') ? undefined : json['dtCessazione'],
        'dtDomanda': !exists(json, 'dtDomanda') ? undefined : json['dtDomanda'],
        'dtDenuncia': !exists(json, 'dtDenuncia') ? undefined : json['dtDenuncia'],
        'causale': !exists(json, 'causale') ? undefined : json['causale'],
        'ccausale': !exists(json, 'ccausale') ? undefined : json['ccausale'],
    };
}

export function CessazioneLocalizzazioneToJSON(value?: CessazioneLocalizzazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiAtto': EstremiAttoToJSON(value.estremiAtto),
        'infoCessazione': value.infoCessazione,
        'dtCessazione': value.dtCessazione,
        'dtDomanda': value.dtDomanda,
        'dtDenuncia': value.dtDenuncia,
        'causale': value.causale,
        'ccausale': value.ccausale,
    };
}


