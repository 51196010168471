/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sezioni,
    SezioniFromJSON,
    SezioniFromJSONTyped,
    SezioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface Runts
 */
export interface Runts {
    /**
     * 
     * @type {Sezioni}
     * @memberof Runts
     */
    sezioni?: Sezioni;
    /**
     * 
     * @type {string}
     * @memberof Runts
     */
    id?: string;
}

export function RuntsFromJSON(json: any): Runts {
    return RuntsFromJSONTyped(json, false);
}

export function RuntsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Runts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sezioni': !exists(json, 'sezioni') ? undefined : SezioniFromJSON(json['sezioni']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function RuntsToJSON(value?: Runts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sezioni': SezioniToJSON(value.sezioni),
        'id': value.id,
    };
}


