/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartitaIva
 */
export interface PartitaIva {
    /**
     * 
     * @type {string}
     * @memberof PartitaIva
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof PartitaIva
     */
    dtUltimoAggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof PartitaIva
     */
    dtCessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof PartitaIva
     */
    cfonteCess?: string;
}

export function PartitaIvaFromJSON(json: any): PartitaIva {
    return PartitaIvaFromJSONTyped(json, false);
}

export function PartitaIvaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartitaIva {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'dtUltimoAggiornamento': !exists(json, 'dtUltimoAggiornamento') ? undefined : json['dtUltimoAggiornamento'],
        'dtCessazione': !exists(json, 'dtCessazione') ? undefined : json['dtCessazione'],
        'cfonteCess': !exists(json, 'cfonteCess') ? undefined : json['cfonteCess'],
    };
}

export function PartitaIvaToJSON(value?: PartitaIva | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'dtUltimoAggiornamento': value.dtUltimoAggiornamento,
        'dtCessazione': value.dtCessazione,
        'cfonteCess': value.cfonteCess,
    };
}


