/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InsightState,
    InsightStateFromJSON,
    InsightStateToJSON,
} from '../models';

export interface CreateInsightStateRequest {
    insightState: InsightState;
}

export interface DeleteInsightStateRequest {
    id: number;
}

export interface GetInsightStateRequest {
    id: number;
}

export interface PartialUpdateInsightStateRequest {
    id: number;
    insightState: InsightState;
}

export interface UpdateInsightStateRequest {
    id: number;
    insightState: InsightState;
}

/**
 * 
 */
export class InsightStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createInsightStateRaw(requestParameters: CreateInsightStateRequest): Promise<runtime.ApiResponse<InsightState>> {
        if (requestParameters.insightState === null || requestParameters.insightState === undefined) {
            throw new runtime.RequiredError('insightState','Required parameter requestParameters.insightState was null or undefined when calling createInsightState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightStateToJSON(requestParameters.insightState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightStateFromJSON(jsonValue));
    }

    /**
     */
    async createInsightState(requestParameters: CreateInsightStateRequest): Promise<InsightState> {
        const response = await this.createInsightStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteInsightStateRaw(requestParameters: DeleteInsightStateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInsightState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteInsightState(requestParameters: DeleteInsightStateRequest): Promise<void> {
        await this.deleteInsightStateRaw(requestParameters);
    }

    /**
     */
    async getAllInsightStatesRaw(): Promise<runtime.ApiResponse<Array<InsightState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightStateFromJSON));
    }

    /**
     */
    async getAllInsightStates(): Promise<Array<InsightState>> {
        const response = await this.getAllInsightStatesRaw();
        return await response.value();
    }

    /**
     */
    async getInsightStateRaw(requestParameters: GetInsightStateRequest): Promise<runtime.ApiResponse<InsightState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInsightState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insight-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightStateFromJSON(jsonValue));
    }

    /**
     */
    async getInsightState(requestParameters: GetInsightStateRequest): Promise<InsightState> {
        const response = await this.getInsightStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateInsightStateRaw(requestParameters: PartialUpdateInsightStateRequest): Promise<runtime.ApiResponse<InsightState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInsightState.');
        }

        if (requestParameters.insightState === null || requestParameters.insightState === undefined) {
            throw new runtime.RequiredError('insightState','Required parameter requestParameters.insightState was null or undefined when calling partialUpdateInsightState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InsightStateToJSON(requestParameters.insightState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateInsightState(requestParameters: PartialUpdateInsightStateRequest): Promise<InsightState> {
        const response = await this.partialUpdateInsightStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateInsightStateRaw(requestParameters: UpdateInsightStateRequest): Promise<runtime.ApiResponse<InsightState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInsightState.');
        }

        if (requestParameters.insightState === null || requestParameters.insightState === undefined) {
            throw new runtime.RequiredError('insightState','Required parameter requestParameters.insightState was null or undefined when calling updateInsightState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insight-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightStateToJSON(requestParameters.insightState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightStateFromJSON(jsonValue));
    }

    /**
     */
    async updateInsightState(requestParameters: UpdateInsightStateRequest): Promise<InsightState> {
        const response = await this.updateInsightStateRaw(requestParameters);
        return await response.value();
    }

}
