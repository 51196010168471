/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttestazioneQualificazioni,
    AttestazioneQualificazioniFromJSON,
    AttestazioneQualificazioniFromJSONTyped,
    AttestazioneQualificazioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttestazioniQualificazioni
 */
export interface AttestazioniQualificazioni {
    /**
     * 
     * @type {Array<AttestazioneQualificazioni>}
     * @memberof AttestazioniQualificazioni
     */
    attestazioneQualificazioni: Array<AttestazioneQualificazioni>;
}

export function AttestazioniQualificazioniFromJSON(json: any): AttestazioniQualificazioni {
    return AttestazioniQualificazioniFromJSONTyped(json, false);
}

export function AttestazioniQualificazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttestazioniQualificazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestazioneQualificazioni': ((json['attestazioneQualificazioni'] as Array<any>).map(AttestazioneQualificazioniFromJSON)),
    };
}

export function AttestazioniQualificazioniToJSON(value?: AttestazioniQualificazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestazioneQualificazioni': ((value.attestazioneQualificazioni as Array<any>).map(AttestazioneQualificazioniToJSON)),
    };
}


