/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchCompanyFullDTO
 */
export interface SearchCompanyFullDTO {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyFullDTO
     */
    vatNumber?: string;
}

export function SearchCompanyFullDTOFromJSON(json: any): SearchCompanyFullDTO {
    return SearchCompanyFullDTOFromJSONTyped(json, false);
}

export function SearchCompanyFullDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchCompanyFullDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
    };
}

export function SearchCompanyFullDTOToJSON(value?: SearchCompanyFullDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vatNumber': value.vatNumber,
    };
}


