/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseToJSON,
} from '../models';

export interface CountSurveyQuestionResponsesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    responseEnContains?: string;
    responseEnDoesNotContain?: string;
    responseEnEquals?: string;
    responseEnNotEquals?: string;
    responseEnSpecified?: boolean;
    responseEnIn?: Array<string>;
    responseEnNotIn?: Array<string>;
    responseItContains?: string;
    responseItDoesNotContain?: string;
    responseItEquals?: string;
    responseItNotEquals?: string;
    responseItSpecified?: boolean;
    responseItIn?: Array<string>;
    responseItNotIn?: Array<string>;
    scoreGreaterThan?: number;
    scoreLessThan?: number;
    scoreGreaterThanOrEqual?: number;
    scoreLessThanOrEqual?: number;
    scoreEquals?: number;
    scoreNotEquals?: number;
    scoreSpecified?: boolean;
    scoreIn?: Array<number>;
    scoreNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateSurveyQuestionResponseRequest {
    surveyQuestionResponse: SurveyQuestionResponse;
}

export interface DeleteSurveyQuestionResponseRequest {
    id: number;
}

export interface GetAllSurveyQuestionResponsesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    responseEnContains?: string;
    responseEnDoesNotContain?: string;
    responseEnEquals?: string;
    responseEnNotEquals?: string;
    responseEnSpecified?: boolean;
    responseEnIn?: Array<string>;
    responseEnNotIn?: Array<string>;
    responseItContains?: string;
    responseItDoesNotContain?: string;
    responseItEquals?: string;
    responseItNotEquals?: string;
    responseItSpecified?: boolean;
    responseItIn?: Array<string>;
    responseItNotIn?: Array<string>;
    scoreGreaterThan?: number;
    scoreLessThan?: number;
    scoreGreaterThanOrEqual?: number;
    scoreLessThanOrEqual?: number;
    scoreEquals?: number;
    scoreNotEquals?: number;
    scoreSpecified?: boolean;
    scoreIn?: Array<number>;
    scoreNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetSurveyQuestionResponseRequest {
    id: number;
}

export interface PartialUpdateSurveyQuestionResponseRequest {
    id: number;
    surveyQuestionResponse: SurveyQuestionResponse;
}

export interface UpdateSurveyQuestionResponseRequest {
    id: number;
    surveyQuestionResponse: SurveyQuestionResponse;
}

/**
 * 
 */
export class SurveyQuestionResponseResourceApi extends runtime.BaseAPI {

    /**
     */
    async countSurveyQuestionResponsesRaw(requestParameters: CountSurveyQuestionResponsesRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.responseEnContains !== undefined) {
            queryParameters['responseEn.contains'] = requestParameters.responseEnContains;
        }

        if (requestParameters.responseEnDoesNotContain !== undefined) {
            queryParameters['responseEn.doesNotContain'] = requestParameters.responseEnDoesNotContain;
        }

        if (requestParameters.responseEnEquals !== undefined) {
            queryParameters['responseEn.equals'] = requestParameters.responseEnEquals;
        }

        if (requestParameters.responseEnNotEquals !== undefined) {
            queryParameters['responseEn.notEquals'] = requestParameters.responseEnNotEquals;
        }

        if (requestParameters.responseEnSpecified !== undefined) {
            queryParameters['responseEn.specified'] = requestParameters.responseEnSpecified;
        }

        if (requestParameters.responseEnIn) {
            queryParameters['responseEn.in'] = requestParameters.responseEnIn;
        }

        if (requestParameters.responseEnNotIn) {
            queryParameters['responseEn.notIn'] = requestParameters.responseEnNotIn;
        }

        if (requestParameters.responseItContains !== undefined) {
            queryParameters['responseIt.contains'] = requestParameters.responseItContains;
        }

        if (requestParameters.responseItDoesNotContain !== undefined) {
            queryParameters['responseIt.doesNotContain'] = requestParameters.responseItDoesNotContain;
        }

        if (requestParameters.responseItEquals !== undefined) {
            queryParameters['responseIt.equals'] = requestParameters.responseItEquals;
        }

        if (requestParameters.responseItNotEquals !== undefined) {
            queryParameters['responseIt.notEquals'] = requestParameters.responseItNotEquals;
        }

        if (requestParameters.responseItSpecified !== undefined) {
            queryParameters['responseIt.specified'] = requestParameters.responseItSpecified;
        }

        if (requestParameters.responseItIn) {
            queryParameters['responseIt.in'] = requestParameters.responseItIn;
        }

        if (requestParameters.responseItNotIn) {
            queryParameters['responseIt.notIn'] = requestParameters.responseItNotIn;
        }

        if (requestParameters.scoreGreaterThan !== undefined) {
            queryParameters['score.greaterThan'] = requestParameters.scoreGreaterThan;
        }

        if (requestParameters.scoreLessThan !== undefined) {
            queryParameters['score.lessThan'] = requestParameters.scoreLessThan;
        }

        if (requestParameters.scoreGreaterThanOrEqual !== undefined) {
            queryParameters['score.greaterThanOrEqual'] = requestParameters.scoreGreaterThanOrEqual;
        }

        if (requestParameters.scoreLessThanOrEqual !== undefined) {
            queryParameters['score.lessThanOrEqual'] = requestParameters.scoreLessThanOrEqual;
        }

        if (requestParameters.scoreEquals !== undefined) {
            queryParameters['score.equals'] = requestParameters.scoreEquals;
        }

        if (requestParameters.scoreNotEquals !== undefined) {
            queryParameters['score.notEquals'] = requestParameters.scoreNotEquals;
        }

        if (requestParameters.scoreSpecified !== undefined) {
            queryParameters['score.specified'] = requestParameters.scoreSpecified;
        }

        if (requestParameters.scoreIn) {
            queryParameters['score.in'] = requestParameters.scoreIn;
        }

        if (requestParameters.scoreNotIn) {
            queryParameters['score.notIn'] = requestParameters.scoreNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-question-responses/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countSurveyQuestionResponses(requestParameters: CountSurveyQuestionResponsesRequest): Promise<number> {
        const response = await this.countSurveyQuestionResponsesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSurveyQuestionResponseRaw(requestParameters: CreateSurveyQuestionResponseRequest): Promise<runtime.ApiResponse<SurveyQuestionResponse>> {
        if (requestParameters.surveyQuestionResponse === null || requestParameters.surveyQuestionResponse === undefined) {
            throw new runtime.RequiredError('surveyQuestionResponse','Required parameter requestParameters.surveyQuestionResponse was null or undefined when calling createSurveyQuestionResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-question-responses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionResponseToJSON(requestParameters.surveyQuestionResponse),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSurveyQuestionResponse(requestParameters: CreateSurveyQuestionResponseRequest): Promise<SurveyQuestionResponse> {
        const response = await this.createSurveyQuestionResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSurveyQuestionResponseRaw(requestParameters: DeleteSurveyQuestionResponseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSurveyQuestionResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-question-responses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSurveyQuestionResponse(requestParameters: DeleteSurveyQuestionResponseRequest): Promise<void> {
        await this.deleteSurveyQuestionResponseRaw(requestParameters);
    }

    /**
     */
    async getAllSurveyQuestionResponsesRaw(requestParameters: GetAllSurveyQuestionResponsesRequest): Promise<runtime.ApiResponse<Array<SurveyQuestionResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.responseEnContains !== undefined) {
            queryParameters['responseEn.contains'] = requestParameters.responseEnContains;
        }

        if (requestParameters.responseEnDoesNotContain !== undefined) {
            queryParameters['responseEn.doesNotContain'] = requestParameters.responseEnDoesNotContain;
        }

        if (requestParameters.responseEnEquals !== undefined) {
            queryParameters['responseEn.equals'] = requestParameters.responseEnEquals;
        }

        if (requestParameters.responseEnNotEquals !== undefined) {
            queryParameters['responseEn.notEquals'] = requestParameters.responseEnNotEquals;
        }

        if (requestParameters.responseEnSpecified !== undefined) {
            queryParameters['responseEn.specified'] = requestParameters.responseEnSpecified;
        }

        if (requestParameters.responseEnIn) {
            queryParameters['responseEn.in'] = requestParameters.responseEnIn;
        }

        if (requestParameters.responseEnNotIn) {
            queryParameters['responseEn.notIn'] = requestParameters.responseEnNotIn;
        }

        if (requestParameters.responseItContains !== undefined) {
            queryParameters['responseIt.contains'] = requestParameters.responseItContains;
        }

        if (requestParameters.responseItDoesNotContain !== undefined) {
            queryParameters['responseIt.doesNotContain'] = requestParameters.responseItDoesNotContain;
        }

        if (requestParameters.responseItEquals !== undefined) {
            queryParameters['responseIt.equals'] = requestParameters.responseItEquals;
        }

        if (requestParameters.responseItNotEquals !== undefined) {
            queryParameters['responseIt.notEquals'] = requestParameters.responseItNotEquals;
        }

        if (requestParameters.responseItSpecified !== undefined) {
            queryParameters['responseIt.specified'] = requestParameters.responseItSpecified;
        }

        if (requestParameters.responseItIn) {
            queryParameters['responseIt.in'] = requestParameters.responseItIn;
        }

        if (requestParameters.responseItNotIn) {
            queryParameters['responseIt.notIn'] = requestParameters.responseItNotIn;
        }

        if (requestParameters.scoreGreaterThan !== undefined) {
            queryParameters['score.greaterThan'] = requestParameters.scoreGreaterThan;
        }

        if (requestParameters.scoreLessThan !== undefined) {
            queryParameters['score.lessThan'] = requestParameters.scoreLessThan;
        }

        if (requestParameters.scoreGreaterThanOrEqual !== undefined) {
            queryParameters['score.greaterThanOrEqual'] = requestParameters.scoreGreaterThanOrEqual;
        }

        if (requestParameters.scoreLessThanOrEqual !== undefined) {
            queryParameters['score.lessThanOrEqual'] = requestParameters.scoreLessThanOrEqual;
        }

        if (requestParameters.scoreEquals !== undefined) {
            queryParameters['score.equals'] = requestParameters.scoreEquals;
        }

        if (requestParameters.scoreNotEquals !== undefined) {
            queryParameters['score.notEquals'] = requestParameters.scoreNotEquals;
        }

        if (requestParameters.scoreSpecified !== undefined) {
            queryParameters['score.specified'] = requestParameters.scoreSpecified;
        }

        if (requestParameters.scoreIn) {
            queryParameters['score.in'] = requestParameters.scoreIn;
        }

        if (requestParameters.scoreNotIn) {
            queryParameters['score.notIn'] = requestParameters.scoreNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-question-responses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyQuestionResponseFromJSON));
    }

    /**
     */
    async getAllSurveyQuestionResponses(requestParameters: GetAllSurveyQuestionResponsesRequest): Promise<Array<SurveyQuestionResponse>> {
        const response = await this.getAllSurveyQuestionResponsesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSurveyQuestionResponseRaw(requestParameters: GetSurveyQuestionResponseRequest): Promise<runtime.ApiResponse<SurveyQuestionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSurveyQuestionResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-question-responses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSurveyQuestionResponse(requestParameters: GetSurveyQuestionResponseRequest): Promise<SurveyQuestionResponse> {
        const response = await this.getSurveyQuestionResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateSurveyQuestionResponseRaw(requestParameters: PartialUpdateSurveyQuestionResponseRequest): Promise<runtime.ApiResponse<SurveyQuestionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSurveyQuestionResponse.');
        }

        if (requestParameters.surveyQuestionResponse === null || requestParameters.surveyQuestionResponse === undefined) {
            throw new runtime.RequiredError('surveyQuestionResponse','Required parameter requestParameters.surveyQuestionResponse was null or undefined when calling partialUpdateSurveyQuestionResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-question-responses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionResponseToJSON(requestParameters.surveyQuestionResponse),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionResponseFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateSurveyQuestionResponse(requestParameters: PartialUpdateSurveyQuestionResponseRequest): Promise<SurveyQuestionResponse> {
        const response = await this.partialUpdateSurveyQuestionResponseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateSurveyQuestionResponseRaw(requestParameters: UpdateSurveyQuestionResponseRequest): Promise<runtime.ApiResponse<SurveyQuestionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSurveyQuestionResponse.');
        }

        if (requestParameters.surveyQuestionResponse === null || requestParameters.surveyQuestionResponse === undefined) {
            throw new runtime.RequiredError('surveyQuestionResponse','Required parameter requestParameters.surveyQuestionResponse was null or undefined when calling updateSurveyQuestionResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-question-responses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionResponseToJSON(requestParameters.surveyQuestionResponse),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateSurveyQuestionResponse(requestParameters: UpdateSurveyQuestionResponseRequest): Promise<SurveyQuestionResponse> {
        const response = await this.updateSurveyQuestionResponseRaw(requestParameters);
        return await response.value();
    }

}
