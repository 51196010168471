/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IscrizioneModifica
 */
export interface IscrizioneModifica {
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    tipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    dtDeposito?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    dtRettifica?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    ctipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneModifica
     */
    frettifica?: string;
}

export function IscrizioneModificaFromJSON(json: any): IscrizioneModifica {
    return IscrizioneModificaFromJSONTyped(json, false);
}

export function IscrizioneModificaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IscrizioneModifica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoIscrizione': !exists(json, 'tipoIscrizione') ? undefined : json['tipoIscrizione'],
        'dtDeposito': !exists(json, 'dtDeposito') ? undefined : json['dtDeposito'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'dtRettifica': !exists(json, 'dtRettifica') ? undefined : json['dtRettifica'],
        'ctipoIscrizione': !exists(json, 'ctipoIscrizione') ? undefined : json['ctipoIscrizione'],
        'frettifica': !exists(json, 'frettifica') ? undefined : json['frettifica'],
    };
}

export function IscrizioneModificaToJSON(value?: IscrizioneModifica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoIscrizione': value.tipoIscrizione,
        'dtDeposito': value.dtDeposito,
        'dtIscrizione': value.dtIscrizione,
        'dtRettifica': value.dtRettifica,
        'ctipoIscrizione': value.ctipoIscrizione,
        'frettifica': value.frettifica,
    };
}


