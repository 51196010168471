/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySaved
 */
export interface CompanySaved {
    /**
     * 
     * @type {number}
     * @memberof CompanySaved
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanySaved
     */
    date?: Date;
    /**
     * 
     * @type {User}
     * @memberof CompanySaved
     */
    user?: User;
    /**
     * 
     * @type {Company}
     * @memberof CompanySaved
     */
    company?: Company;
    /**
     * 
     * @type {CompanyData}
     * @memberof CompanySaved
     */
    companyData?: CompanyData;
}

export function CompanySavedFromJSON(json: any): CompanySaved {
    return CompanySavedFromJSONTyped(json, false);
}

export function CompanySavedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySaved {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companyData': !exists(json, 'companyData') ? undefined : CompanyDataFromJSON(json['companyData']),
    };
}

export function CompanySavedToJSON(value?: CompanySaved | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'user': UserToJSON(value.user),
        'company': CompanyToJSON(value.company),
        'companyData': CompanyDataToJSON(value.companyData),
    };
}


