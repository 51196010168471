/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyToJSON,
    CompanyRegisteredDTO,
    CompanyRegisteredDTOFromJSON,
    CompanyRegisteredDTOToJSON,
    CustomCompanyCriteria,
    CustomCompanyCriteriaFromJSON,
    CustomCompanyCriteriaToJSON,
    SearchCompanyFullDTO,
    SearchCompanyFullDTOFromJSON,
    SearchCompanyFullDTOToJSON,
} from '../models';

export interface CheckIfCompanyIsRegisteredRequest {
    company: Company;
}

export interface GetAllCompaniesConsultedsByConsultantRequest {
    company: Company;
}

export interface GetAllCompaniesCustomRequest {
    customCompanyCriteria: CustomCompanyCriteria;
}

export interface PartialUpdateCompanyCustomRequest {
    id: number;
    company: Company;
}

export interface SearchCompanyFullRequest {
    searchCompanyFullDTO: SearchCompanyFullDTO;
}

export interface SearchDashboardCompanyFullRequest {
    searchCompanyFullDTO: SearchCompanyFullDTO;
}

/**
 * 
 */
export class CustomCompanyResourceApi extends runtime.BaseAPI {

    /**
     */
    async checkIfCompanyIsRegisteredRaw(requestParameters: CheckIfCompanyIsRegisteredRequest): Promise<runtime.ApiResponse<CompanyRegisteredDTO>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling checkIfCompanyIsRegistered.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/check-company-registered`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyRegisteredDTOFromJSON(jsonValue));
    }

    /**
     */
    async checkIfCompanyIsRegistered(requestParameters: CheckIfCompanyIsRegisteredRequest): Promise<CompanyRegisteredDTO> {
        const response = await this.checkIfCompanyIsRegisteredRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCompaniesConsultedsByConsultantRaw(requestParameters: GetAllCompaniesConsultedsByConsultantRequest): Promise<runtime.ApiResponse<Array<Company>>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling getAllCompaniesConsultedsByConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/consultant/consulteds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getAllCompaniesConsultedsByConsultant(requestParameters: GetAllCompaniesConsultedsByConsultantRequest): Promise<Array<Company>> {
        const response = await this.getAllCompaniesConsultedsByConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCompaniesCustomRaw(requestParameters: GetAllCompaniesCustomRequest): Promise<runtime.ApiResponse<Array<Company>>> {
        if (requestParameters.customCompanyCriteria === null || requestParameters.customCompanyCriteria === undefined) {
            throw new runtime.RequiredError('customCompanyCriteria','Required parameter requestParameters.customCompanyCriteria was null or undefined when calling getAllCompaniesCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCompanyCriteriaToJSON(requestParameters.customCompanyCriteria),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getAllCompaniesCustom(requestParameters: GetAllCompaniesCustomRequest): Promise<Array<Company>> {
        const response = await this.getAllCompaniesCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyCustomRaw(requestParameters: PartialUpdateCompanyCustomRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyCustom.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling partialUpdateCompanyCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyCustom(requestParameters: PartialUpdateCompanyCustomRequest): Promise<Company> {
        const response = await this.partialUpdateCompanyCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchCompanyFullRaw(requestParameters: SearchCompanyFullRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.searchCompanyFullDTO === null || requestParameters.searchCompanyFullDTO === undefined) {
            throw new runtime.RequiredError('searchCompanyFullDTO','Required parameter requestParameters.searchCompanyFullDTO was null or undefined when calling searchCompanyFull.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/search/full`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchCompanyFullDTOToJSON(requestParameters.searchCompanyFullDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async searchCompanyFull(requestParameters: SearchCompanyFullRequest): Promise<Company> {
        const response = await this.searchCompanyFullRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchDashboardCompanyFullRaw(requestParameters: SearchDashboardCompanyFullRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.searchCompanyFullDTO === null || requestParameters.searchCompanyFullDTO === undefined) {
            throw new runtime.RequiredError('searchCompanyFullDTO','Required parameter requestParameters.searchCompanyFullDTO was null or undefined when calling searchDashboardCompanyFull.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/companies/dashboard/search/full`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchCompanyFullDTOToJSON(requestParameters.searchCompanyFullDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async searchDashboardCompanyFull(requestParameters: SearchDashboardCompanyFullRequest): Promise<Company> {
        const response = await this.searchDashboardCompanyFullRaw(requestParameters);
        return await response.value();
    }

}
