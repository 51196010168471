/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResFromJSONTyped,
    CompanySurveyQuestionResToJSON,
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionFromJSONTyped,
    SurveyQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyQuestionResponse
 */
export interface SurveyQuestionResponse {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionResponse
     */
    responseEn?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionResponse
     */
    responseIt?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionResponse
     */
    score?: number;
    /**
     * 
     * @type {SurveyQuestion}
     * @memberof SurveyQuestionResponse
     */
    surveyQuestion?: SurveyQuestion;
    /**
     * 
     * @type {Set<CompanySurveyQuestionRes>}
     * @memberof SurveyQuestionResponse
     */
    companySurveyQuestionRes?: Set<CompanySurveyQuestionRes>;
}

export function SurveyQuestionResponseFromJSON(json: any): SurveyQuestionResponse {
    return SurveyQuestionResponseFromJSONTyped(json, false);
}

export function SurveyQuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyQuestionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'responseEn': !exists(json, 'responseEn') ? undefined : json['responseEn'],
        'responseIt': !exists(json, 'responseIt') ? undefined : json['responseIt'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'surveyQuestion': !exists(json, 'surveyQuestion') ? undefined : SurveyQuestionFromJSON(json['surveyQuestion']),
        'companySurveyQuestionRes': !exists(json, 'companySurveyQuestionRes') ? undefined : ((json['companySurveyQuestionRes'] as Array<any>).map(CompanySurveyQuestionResFromJSON)),
    };
}

export function SurveyQuestionResponseToJSON(value?: SurveyQuestionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'responseEn': value.responseEn,
        'responseIt': value.responseIt,
        'score': value.score,
        'surveyQuestion': SurveyQuestionToJSON(value.surveyQuestion),
        'companySurveyQuestionRes': value.companySurveyQuestionRes === undefined ? undefined : ((value.companySurveyQuestionRes as Array<any>).map(CompanySurveyQuestionResToJSON)),
    };
}


