/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Deliberato,
    DeliberatoFromJSON,
    DeliberatoFromJSONTyped,
    DeliberatoToJSON,
    Sottoscritto,
    SottoscrittoFromJSON,
    SottoscrittoFromJSONTyped,
    SottoscrittoToJSON,
    TipoConferimenti,
    TipoConferimentiFromJSON,
    TipoConferimentiFromJSONTyped,
    TipoConferimentiToJSON,
    Versato,
    VersatoFromJSON,
    VersatoFromJSONTyped,
    VersatoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CapitaleSociale
 */
export interface CapitaleSociale {
    /**
     * 
     * @type {Deliberato}
     * @memberof CapitaleSociale
     */
    deliberato?: Deliberato;
    /**
     * 
     * @type {Sottoscritto}
     * @memberof CapitaleSociale
     */
    sottoscritto?: Sottoscritto;
    /**
     * 
     * @type {Versato}
     * @memberof CapitaleSociale
     */
    versato?: Versato;
    /**
     * 
     * @type {TipoConferimenti}
     * @memberof CapitaleSociale
     */
    tipoConferimenti?: TipoConferimenti;
    /**
     * 
     * @type {string}
     * @memberof CapitaleSociale
     */
    valuta?: string;
    /**
     * 
     * @type {string}
     * @memberof CapitaleSociale
     */
    ammontare?: string;
    /**
     * 
     * @type {string}
     * @memberof CapitaleSociale
     */
    cvaluta?: string;
    /**
     * 
     * @type {string}
     * @memberof CapitaleSociale
     */
    nazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof CapitaleSociale
     */
    nquote?: string;
}

export function CapitaleSocialeFromJSON(json: any): CapitaleSociale {
    return CapitaleSocialeFromJSONTyped(json, false);
}

export function CapitaleSocialeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapitaleSociale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliberato': !exists(json, 'deliberato') ? undefined : DeliberatoFromJSON(json['deliberato']),
        'sottoscritto': !exists(json, 'sottoscritto') ? undefined : SottoscrittoFromJSON(json['sottoscritto']),
        'versato': !exists(json, 'versato') ? undefined : VersatoFromJSON(json['versato']),
        'tipoConferimenti': !exists(json, 'tipoConferimenti') ? undefined : TipoConferimentiFromJSON(json['tipoConferimenti']),
        'valuta': !exists(json, 'valuta') ? undefined : json['valuta'],
        'ammontare': !exists(json, 'ammontare') ? undefined : json['ammontare'],
        'cvaluta': !exists(json, 'cvaluta') ? undefined : json['cvaluta'],
        'nazioni': !exists(json, 'nazioni') ? undefined : json['nazioni'],
        'nquote': !exists(json, 'nquote') ? undefined : json['nquote'],
    };
}

export function CapitaleSocialeToJSON(value?: CapitaleSociale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliberato': DeliberatoToJSON(value.deliberato),
        'sottoscritto': SottoscrittoToJSON(value.sottoscritto),
        'versato': VersatoToJSON(value.versato),
        'tipoConferimenti': TipoConferimentiToJSON(value.tipoConferimenti),
        'valuta': value.valuta,
        'ammontare': value.ammontare,
        'cvaluta': value.cvaluta,
        'nazioni': value.nazioni,
        'nquote': value.nquote,
    };
}


