/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoMesi,
    InfoMesiFromJSON,
    InfoMesiFromJSONTyped,
    InfoMesiToJSON,
} from './';

/**
 * 
 * @export
 * @interface DipendentiQualifica
 */
export interface DipendentiQualifica {
    /**
     * 
     * @type {InfoMesi}
     * @memberof DipendentiQualifica
     */
    infoMesi?: InfoMesi;
    /**
     * 
     * @type {string}
     * @memberof DipendentiQualifica
     */
    qualifica?: string;
}

export function DipendentiQualificaFromJSON(json: any): DipendentiQualifica {
    return DipendentiQualificaFromJSONTyped(json, false);
}

export function DipendentiQualificaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DipendentiQualifica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoMesi': !exists(json, 'infoMesi') ? undefined : InfoMesiFromJSON(json['infoMesi']),
        'qualifica': !exists(json, 'qualifica') ? undefined : json['qualifica'],
    };
}

export function DipendentiQualificaToJSON(value?: DipendentiQualifica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoMesi': InfoMesiToJSON(value.infoMesi),
        'qualifica': value.qualifica,
    };
}


