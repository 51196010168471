/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotaElencoSoci,
    NotaElencoSociFromJSON,
    NotaElencoSociFromJSONTyped,
    NotaElencoSociToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoteElencoSoci
 */
export interface NoteElencoSoci {
    /**
     * 
     * @type {Array<NotaElencoSoci>}
     * @memberof NoteElencoSoci
     */
    notaElencoSoci: Array<NotaElencoSoci>;
}

export function NoteElencoSociFromJSON(json: any): NoteElencoSoci {
    return NoteElencoSociFromJSONTyped(json, false);
}

export function NoteElencoSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteElencoSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notaElencoSoci': ((json['notaElencoSoci'] as Array<any>).map(NotaElencoSociFromJSON)),
    };
}

export function NoteElencoSociToJSON(value?: NoteElencoSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notaElencoSoci': ((value.notaElencoSoci as Array<any>).map(NotaElencoSociToJSON)),
    };
}


