/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FamiliarePartecipe,
    FamiliarePartecipeFromJSON,
    FamiliarePartecipeFromJSONTyped,
    FamiliarePartecipeToJSON,
} from './';

/**
 * 
 * @export
 * @interface FamiliariPartecipi
 */
export interface FamiliariPartecipi {
    /**
     * 
     * @type {Array<FamiliarePartecipe>}
     * @memberof FamiliariPartecipi
     */
    familiarePartecipe: Array<FamiliarePartecipe>;
}

export function FamiliariPartecipiFromJSON(json: any): FamiliariPartecipi {
    return FamiliariPartecipiFromJSONTyped(json, false);
}

export function FamiliariPartecipiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FamiliariPartecipi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'familiarePartecipe': ((json['familiarePartecipe'] as Array<any>).map(FamiliarePartecipeFromJSON)),
    };
}

export function FamiliariPartecipiToJSON(value?: FamiliariPartecipi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'familiarePartecipe': ((value.familiarePartecipe as Array<any>).map(FamiliarePartecipeToJSON)),
    };
}


