/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FallimentoInProprio,
    FallimentoInProprioFromJSON,
    FallimentoInProprioFromJSONTyped,
    FallimentoInProprioToJSON,
    FallimentoPerEstensione,
    FallimentoPerEstensioneFromJSON,
    FallimentoPerEstensioneFromJSONTyped,
    FallimentoPerEstensioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Fallimento
 */
export interface Fallimento {
    /**
     * 
     * @type {FallimentoInProprio}
     * @memberof Fallimento
     */
    fallimentoInProprio?: FallimentoInProprio;
    /**
     * 
     * @type {FallimentoPerEstensione}
     * @memberof Fallimento
     */
    fallimentoPerEstensione?: FallimentoPerEstensione;
    /**
     * 
     * @type {string}
     * @memberof Fallimento
     */
    pfallimento?: string;
}

export function FallimentoFromJSON(json: any): Fallimento {
    return FallimentoFromJSONTyped(json, false);
}

export function FallimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fallimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fallimentoInProprio': !exists(json, 'fallimentoInProprio') ? undefined : FallimentoInProprioFromJSON(json['fallimentoInProprio']),
        'fallimentoPerEstensione': !exists(json, 'fallimentoPerEstensione') ? undefined : FallimentoPerEstensioneFromJSON(json['fallimentoPerEstensione']),
        'pfallimento': !exists(json, 'pfallimento') ? undefined : json['pfallimento'],
    };
}

export function FallimentoToJSON(value?: Fallimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fallimentoInProprio': FallimentoInProprioToJSON(value.fallimentoInProprio),
        'fallimentoPerEstensione': FallimentoPerEstensioneToJSON(value.fallimentoPerEstensione),
        'pfallimento': value.pfallimento,
    };
}


