/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyAndPasswordVM
 */
export interface KeyAndPasswordVM {
    /**
     * 
     * @type {string}
     * @memberof KeyAndPasswordVM
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyAndPasswordVM
     */
    newPassword?: string;
}

export function KeyAndPasswordVMFromJSON(json: any): KeyAndPasswordVM {
    return KeyAndPasswordVMFromJSONTyped(json, false);
}

export function KeyAndPasswordVMFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyAndPasswordVM {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
    };
}

export function KeyAndPasswordVMToJSON(value?: KeyAndPasswordVM | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'newPassword': value.newPassword,
    };
}


