/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BusinessRatingDTO,
    BusinessRatingDTOFromJSON,
    BusinessRatingDTOToJSON,
    CompanyDataQuestionResponseDTO,
    CompanyDataQuestionResponseDTOFromJSON,
    CompanyDataQuestionResponseDTOToJSON,
    CompanySurvey,
    CompanySurveyFromJSON,
    CompanySurveyToJSON,
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResToJSON,
    CustomCompanySurveyCriteria,
    CustomCompanySurveyCriteriaFromJSON,
    CustomCompanySurveyCriteriaToJSON,
} from '../models';

export interface CreateCompanySurveyCustomRequest {
    companySurvey: CompanySurvey;
}

export interface GetAllCompanySurveysFilterRequest {
    customCompanySurveyCriteria: CustomCompanySurveyCriteria;
}

export interface GetBusinessRatingTableRequest {
    companySurvey: CompanySurvey;
}

export interface GetLastSurveyRequest {
    body: number;
}

export interface SubmitSurveyRequest {
    companyDataQuestionResponseDTO: CompanyDataQuestionResponseDTO;
}

/**
 * 
 */
export class CustomCompanySurveyResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanySurveyCustomRaw(requestParameters: CreateCompanySurveyCustomRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.companySurvey === null || requestParameters.companySurvey === undefined) {
            throw new runtime.RequiredError('companySurvey','Required parameter requestParameters.companySurvey was null or undefined when calling createCompanySurveyCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surveys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyToJSON(requestParameters.companySurvey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async createCompanySurveyCustom(requestParameters: CreateCompanySurveyCustomRequest): Promise<CompanySurvey> {
        const response = await this.createCompanySurveyCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllCompanySurveysFilterRaw(requestParameters: GetAllCompanySurveysFilterRequest): Promise<runtime.ApiResponse<Array<CompanySurvey>>> {
        if (requestParameters.customCompanySurveyCriteria === null || requestParameters.customCompanySurveyCriteria === undefined) {
            throw new runtime.RequiredError('customCompanySurveyCriteria','Required parameter requestParameters.customCompanySurveyCriteria was null or undefined when calling getAllCompanySurveysFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surveys/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCompanySurveyCriteriaToJSON(requestParameters.customCompanySurveyCriteria),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurveyFromJSON));
    }

    /**
     */
    async getAllCompanySurveysFilter(requestParameters: GetAllCompanySurveysFilterRequest): Promise<Array<CompanySurvey>> {
        const response = await this.getAllCompanySurveysFilterRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getBusinessRatingTableRaw(requestParameters: GetBusinessRatingTableRequest): Promise<runtime.ApiResponse<BusinessRatingDTO>> {
        if (requestParameters.companySurvey === null || requestParameters.companySurvey === undefined) {
            throw new runtime.RequiredError('companySurvey','Required parameter requestParameters.companySurvey was null or undefined when calling getBusinessRatingTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surveys/business-rating`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyToJSON(requestParameters.companySurvey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessRatingDTOFromJSON(jsonValue));
    }

    /**
     */
    async getBusinessRatingTable(requestParameters: GetBusinessRatingTableRequest): Promise<BusinessRatingDTO> {
        const response = await this.getBusinessRatingTableRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLastSurveyRaw(requestParameters: GetLastSurveyRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getLastSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surveys/latest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async getLastSurvey(requestParameters: GetLastSurveyRequest): Promise<CompanySurvey> {
        const response = await this.getLastSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async submitSurveyRaw(requestParameters: SubmitSurveyRequest): Promise<runtime.ApiResponse<Array<CompanySurveyQuestionRes>>> {
        if (requestParameters.companyDataQuestionResponseDTO === null || requestParameters.companyDataQuestionResponseDTO === undefined) {
            throw new runtime.RequiredError('companyDataQuestionResponseDTO','Required parameter requestParameters.companyDataQuestionResponseDTO was null or undefined when calling submitSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-surveys/survey/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataQuestionResponseDTOToJSON(requestParameters.companyDataQuestionResponseDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurveyQuestionResFromJSON));
    }

    /**
     */
    async submitSurvey(requestParameters: SubmitSurveyRequest): Promise<Array<CompanySurveyQuestionRes>> {
        const response = await this.submitSurveyRaw(requestParameters);
        return await response.value();
    }

}
