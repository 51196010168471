/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SottoTipo,
    SottoTipoFromJSON,
    SottoTipoFromJSONTyped,
    SottoTipoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SottoTipi
 */
export interface SottoTipi {
    /**
     * 
     * @type {Array<SottoTipo>}
     * @memberof SottoTipi
     */
    sottoTipo: Array<SottoTipo>;
}

export function SottoTipiFromJSON(json: any): SottoTipi {
    return SottoTipiFromJSONTyped(json, false);
}

export function SottoTipiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SottoTipi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sottoTipo': ((json['sottoTipo'] as Array<any>).map(SottoTipoFromJSON)),
    };
}

export function SottoTipiToJSON(value?: SottoTipi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sottoTipo': ((value.sottoTipo as Array<any>).map(SottoTipoToJSON)),
    };
}


