/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancellazioneAaBz
 */
export interface CancellazioneAaBz {
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAaBz
     */
    dtEffetto?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAaBz
     */
    dtDomandaAccertamento?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAaBz
     */
    causale?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAaBz
     */
    ccausale?: string;
}

export function CancellazioneAaBzFromJSON(json: any): CancellazioneAaBz {
    return CancellazioneAaBzFromJSONTyped(json, false);
}

export function CancellazioneAaBzFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellazioneAaBz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtEffetto': !exists(json, 'dtEffetto') ? undefined : json['dtEffetto'],
        'dtDomandaAccertamento': !exists(json, 'dtDomandaAccertamento') ? undefined : json['dtDomandaAccertamento'],
        'causale': !exists(json, 'causale') ? undefined : json['causale'],
        'ccausale': !exists(json, 'ccausale') ? undefined : json['ccausale'],
    };
}

export function CancellazioneAaBzToJSON(value?: CancellazioneAaBz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtEffetto': value.dtEffetto,
        'dtDomandaAccertamento': value.dtDomandaAccertamento,
        'causale': value.causale,
        'ccausale': value.ccausale,
    };
}


