import * as Localization from 'expo-localization';
import i18n from 'i18n-js'
import { it } from './i18n_it';
import { en } from "./i18n_en";


i18n.fallbacks = true;
i18n.translations = {en, it};
i18n.locale = Localization.locale;
if(i18n.locale != 'mock'){ // Avoid async function for test classes
    /* StorageHelper.readLanguage().then(language => {
        let locale = language != null ? language : Localization.locale;
        i18n.locale = locale;
        console.log('Locale: ' + i18n.locale);
    });*/
} 

const changeLanguage = (languageKey: any) => {
    i18n.locale = languageKey;
    //StorageHelper.storeLanguage(languageKey);
};

export const switchToItalian = () => {
    changeLanguage(LanguagesEnum.Italian);
};

export const switchToEnglish = () => {
    changeLanguage(LanguagesEnum.English);
};

export const getCurrentLocale = () => {
    return i18n.locale;
};

export enum LanguagesEnum {
    Italian = 'it',
    English = 'en',
}

export {i18n};
