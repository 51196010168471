/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Partecipazione
 */
export interface Partecipazione {
    /**
     * 
     * @type {Array<object>}
     * @memberof Partecipazione
     */
    estremiImpresaOrEstremiPraticaOrCapitaleSociale?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Partecipazione
     */
    fparagrafoAttuale?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipazione
     */
    finfoIncompleta?: string;
}

export function PartecipazioneFromJSON(json: any): Partecipazione {
    return PartecipazioneFromJSONTyped(json, false);
}

export function PartecipazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partecipazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiImpresaOrEstremiPraticaOrCapitaleSociale': !exists(json, 'estremiImpresaOrEstremiPraticaOrCapitaleSociale') ? undefined : json['estremiImpresaOrEstremiPraticaOrCapitaleSociale'],
        'fparagrafoAttuale': !exists(json, 'fparagrafoAttuale') ? undefined : json['fparagrafoAttuale'],
        'finfoIncompleta': !exists(json, 'finfoIncompleta') ? undefined : json['finfoIncompleta'],
    };
}

export function PartecipazioneToJSON(value?: Partecipazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiImpresaOrEstremiPraticaOrCapitaleSociale': value.estremiImpresaOrEstremiPraticaOrCapitaleSociale,
        'fparagrafoAttuale': value.fparagrafoAttuale,
        'finfoIncompleta': value.finfoIncompleta,
    };
}


