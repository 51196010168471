/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InformazioniSupplementariName
 */
export interface InformazioniSupplementariName {
    /**
     * 
     * @type {string}
     * @memberof InformazioniSupplementariName
     */
    namespaceURI?: string;
    /**
     * 
     * @type {string}
     * @memberof InformazioniSupplementariName
     */
    localPart?: string;
    /**
     * 
     * @type {string}
     * @memberof InformazioniSupplementariName
     */
    prefix?: string;
}

export function InformazioniSupplementariNameFromJSON(json: any): InformazioniSupplementariName {
    return InformazioniSupplementariNameFromJSONTyped(json, false);
}

export function InformazioniSupplementariNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformazioniSupplementariName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'namespaceURI': !exists(json, 'namespaceURI') ? undefined : json['namespaceURI'],
        'localPart': !exists(json, 'localPart') ? undefined : json['localPart'],
        'prefix': !exists(json, 'prefix') ? undefined : json['prefix'],
    };
}

export function InformazioniSupplementariNameToJSON(value?: InformazioniSupplementariName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'namespaceURI': value.namespaceURI,
        'localPart': value.localPart,
        'prefix': value.prefix,
    };
}


