/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonaGiuridica
 */
export interface PersonaGiuridica {
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    dtCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    statoCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    cstatoCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridica
     */
    nrd?: string;
}

export function PersonaGiuridicaFromJSON(json: any): PersonaGiuridica {
    return PersonaGiuridicaFromJSONTyped(json, false);
}

export function PersonaGiuridicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaGiuridica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtCostituzione': !exists(json, 'dtCostituzione') ? undefined : json['dtCostituzione'],
        'statoCostituzione': !exists(json, 'statoCostituzione') ? undefined : json['statoCostituzione'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'cstatoCostituzione': !exists(json, 'cstatoCostituzione') ? undefined : json['cstatoCostituzione'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
    };
}

export function PersonaGiuridicaToJSON(value?: PersonaGiuridica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cciaa': value.cciaa,
        'denominazione': value.denominazione,
        'dtCostituzione': value.dtCostituzione,
        'statoCostituzione': value.statoCostituzione,
        'cfiscale': value.cfiscale,
        'cstatoCostituzione': value.cstatoCostituzione,
        'nrea': value.nrea,
        'nrd': value.nrd,
    };
}


