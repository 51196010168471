/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminUserFullDTO,
    AdminUserFullDTOFromJSON,
    AdminUserFullDTOToJSON,
    Authority,
    AuthorityFromJSON,
    AuthorityToJSON,
    PageUser,
    PageUserFromJSON,
    PageUserToJSON,
    Pageable,
    PageableFromJSON,
    PageableToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ActivateUserRequest {
    login: string;
}

export interface CreateUserIncludingExtendedRequest {
    adminUserFullDTO: AdminUserFullDTO;
}

export interface DeleteUserCascadeRequest {
    login: string;
}

export interface GetAllUsersPageRequest {
    searchText: string;
    pageable: Pageable;
}

export interface PartialUpdateUserRequest {
    id: number;
    user: User;
}

/**
 * 
 */
export class CustomUserResourceApi extends runtime.BaseAPI {

    /**
     */
    async activateUserRaw(requestParameters: ActivateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling activateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/{login}/activate`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async activateUser(requestParameters: ActivateUserRequest): Promise<User> {
        const response = await this.activateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countUserExtendedsRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countUserExtendeds(): Promise<number> {
        const response = await this.countUserExtendedsRaw();
        return await response.value();
    }

    /**
     */
    async createUserIncludingExtendedRaw(requestParameters: CreateUserIncludingExtendedRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.adminUserFullDTO === null || requestParameters.adminUserFullDTO === undefined) {
            throw new runtime.RequiredError('adminUserFullDTO','Required parameter requestParameters.adminUserFullDTO was null or undefined when calling createUserIncludingExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/admin/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUserFullDTOToJSON(requestParameters.adminUserFullDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUserIncludingExtended(requestParameters: CreateUserIncludingExtendedRequest): Promise<User> {
        const response = await this.createUserIncludingExtendedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserCascadeRaw(requestParameters: DeleteUserCascadeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling deleteUserCascade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users/{login}`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserCascade(requestParameters: DeleteUserCascadeRequest): Promise<void> {
        await this.deleteUserCascadeRaw(requestParameters);
    }

    /**
     */
    async getAllUsersPageRaw(requestParameters: GetAllUsersPageRequest): Promise<runtime.ApiResponse<PageUser>> {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText','Required parameter requestParameters.searchText was null or undefined when calling getAllUsersPage.');
        }

        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getAllUsersPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserFromJSON(jsonValue));
    }

    /**
     */
    async getAllUsersPage(requestParameters: GetAllUsersPageRequest): Promise<PageUser> {
        const response = await this.getAllUsersPageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMeRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getMe(): Promise<User> {
        const response = await this.getMeRaw();
        return await response.value();
    }

    /**
     */
    async getMyAuthoritiesRaw(): Promise<runtime.ApiResponse<Set<Authority>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/users/me/authorities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthorityFromJSON));
    }

    /**
     */
    async getMyAuthorities(): Promise<Set<Authority>> {
        const response = await this.getMyAuthoritiesRaw();
        return await response.value();
    }

    /**
     */
    async partialUpdateUserRaw(requestParameters: PartialUpdateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUser.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling partialUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUser(requestParameters: PartialUpdateUserRequest): Promise<User> {
        const response = await this.partialUpdateUserRaw(requestParameters);
        return await response.value();
    }

}
