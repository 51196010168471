/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserExtended
 */
export interface UserExtended {
    /**
     * 
     * @type {number}
     * @memberof UserExtended
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    avatarFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    avatarFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtended
     */
    type?: UserExtendedTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserExtended
     */
    availableCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof UserExtended
     */
    availableSearches?: number;
    /**
     * 
     * @type {number}
     * @memberof UserExtended
     */
    availableConsultationHours?: number;
}

/**
* @export
* @enum {string}
*/
export enum UserExtendedTypeEnum {
    Company = 'COMPANY',
    Consultant = 'CONSULTANT'
}

export function UserExtendedFromJSON(json: any): UserExtended {
    return UserExtendedFromJSONTyped(json, false);
}

export function UserExtendedFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserExtended {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'avatarFilename': !exists(json, 'avatarFilename') ? undefined : json['avatarFilename'],
        'avatarFilepath': !exists(json, 'avatarFilepath') ? undefined : json['avatarFilepath'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'availableCredits': !exists(json, 'availableCredits') ? undefined : json['availableCredits'],
        'availableSearches': !exists(json, 'availableSearches') ? undefined : json['availableSearches'],
        'availableConsultationHours': !exists(json, 'availableConsultationHours') ? undefined : json['availableConsultationHours'],
    };
}

export function UserExtendedToJSON(value?: UserExtended | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'avatarFilename': value.avatarFilename,
        'avatarFilepath': value.avatarFilepath,
        'avatarUrl': value.avatarUrl,
        'telephone': value.telephone,
        'type': value.type,
        'availableCredits': value.availableCredits,
        'availableSearches': value.availableSearches,
        'availableConsultationHours': value.availableConsultationHours,
    };
}


