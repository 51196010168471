/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScadenzaEsercizi,
    ScadenzaEserciziFromJSON,
    ScadenzaEserciziFromJSONTyped,
    ScadenzaEserciziToJSON,
} from './';

/**
 * 
 * @export
 * @interface DurataSocieta
 */
export interface DurataSocieta {
    /**
     * 
     * @type {ScadenzaEsercizi}
     * @memberof DurataSocieta
     */
    scadenzaEsercizi?: ScadenzaEsercizi;
    /**
     * 
     * @type {string}
     * @memberof DurataSocieta
     */
    dtTermine?: string;
    /**
     * 
     * @type {string}
     * @memberof DurataSocieta
     */
    tipoProroga?: string;
    /**
     * 
     * @type {string}
     * @memberof DurataSocieta
     */
    fdurataIndeterminata?: string;
    /**
     * 
     * @type {string}
     * @memberof DurataSocieta
     */
    ctipoProroga?: string;
    /**
     * 
     * @type {string}
     * @memberof DurataSocieta
     */
    nanniProrogaTacita?: string;
}

export function DurataSocietaFromJSON(json: any): DurataSocieta {
    return DurataSocietaFromJSONTyped(json, false);
}

export function DurataSocietaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DurataSocieta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scadenzaEsercizi': !exists(json, 'scadenzaEsercizi') ? undefined : ScadenzaEserciziFromJSON(json['scadenzaEsercizi']),
        'dtTermine': !exists(json, 'dtTermine') ? undefined : json['dtTermine'],
        'tipoProroga': !exists(json, 'tipoProroga') ? undefined : json['tipoProroga'],
        'fdurataIndeterminata': !exists(json, 'fdurataIndeterminata') ? undefined : json['fdurataIndeterminata'],
        'ctipoProroga': !exists(json, 'ctipoProroga') ? undefined : json['ctipoProroga'],
        'nanniProrogaTacita': !exists(json, 'nanniProrogaTacita') ? undefined : json['nanniProrogaTacita'],
    };
}

export function DurataSocietaToJSON(value?: DurataSocieta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scadenzaEsercizi': ScadenzaEserciziToJSON(value.scadenzaEsercizi),
        'dtTermine': value.dtTermine,
        'tipoProroga': value.tipoProroga,
        'fdurataIndeterminata': value.fdurataIndeterminata,
        'ctipoProroga': value.ctipoProroga,
        'nanniProrogaTacita': value.nanniProrogaTacita,
    };
}


