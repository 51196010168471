/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminUserDTO,
    AdminUserDTOFromJSON,
    AdminUserDTOToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface CreateUserRequest {
    adminUserDTO: AdminUserDTO;
}

export interface DeleteUserRequest {
    login: string;
}

export interface GetAllUsersRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetUserRequest {
    login: string;
}

export interface UpdateUserRequest {
    adminUserDTO: AdminUserDTO;
}

/**
 * 
 */
export class UserResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.adminUserDTO === null || requestParameters.adminUserDTO === undefined) {
            throw new runtime.RequiredError('adminUserDTO','Required parameter requestParameters.adminUserDTO was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUserDTOToJSON(requestParameters.adminUserDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest): Promise<User> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users/{login}`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     */
    async getAllUsersRaw(requestParameters: GetAllUsersRequest): Promise<runtime.ApiResponse<Array<AdminUserDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminUserDTOFromJSON));
    }

    /**
     */
    async getAllUsers(requestParameters: GetAllUsersRequest): Promise<Array<AdminUserDTO>> {
        const response = await this.getAllUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<AdminUserDTO>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users/{login}`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters.login))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest): Promise<AdminUserDTO> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<AdminUserDTO>> {
        if (requestParameters.adminUserDTO === null || requestParameters.adminUserDTO === undefined) {
            throw new runtime.RequiredError('adminUserDTO','Required parameter requestParameters.adminUserDTO was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUserDTOToJSON(requestParameters.adminUserDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<AdminUserDTO> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }

}
