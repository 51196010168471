/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndirizzoLocalizzazione
 */
export interface IndirizzoLocalizzazione {
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    comune?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    provinciaTer?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    toponimo?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    via?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    cap?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    frazione?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    altreIndicazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    fsedePressoTerzi?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    cstradario?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    ctoponimo?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    cstato?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    ccomune?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    ncivico?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoLocalizzazione
     */
    czona?: string;
}

export function IndirizzoLocalizzazioneFromJSON(json: any): IndirizzoLocalizzazione {
    return IndirizzoLocalizzazioneFromJSONTyped(json, false);
}

export function IndirizzoLocalizzazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndirizzoLocalizzazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comune': !exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'provinciaTer': !exists(json, 'provinciaTer') ? undefined : json['provinciaTer'],
        'toponimo': !exists(json, 'toponimo') ? undefined : json['toponimo'],
        'via': !exists(json, 'via') ? undefined : json['via'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'frazione': !exists(json, 'frazione') ? undefined : json['frazione'],
        'altreIndicazioni': !exists(json, 'altreIndicazioni') ? undefined : json['altreIndicazioni'],
        'fsedePressoTerzi': !exists(json, 'fsedePressoTerzi') ? undefined : json['fsedePressoTerzi'],
        'cstradario': !exists(json, 'cstradario') ? undefined : json['cstradario'],
        'ctoponimo': !exists(json, 'ctoponimo') ? undefined : json['ctoponimo'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
        'ccomune': !exists(json, 'ccomune') ? undefined : json['ccomune'],
        'ncivico': !exists(json, 'ncivico') ? undefined : json['ncivico'],
        'czona': !exists(json, 'czona') ? undefined : json['czona'],
    };
}

export function IndirizzoLocalizzazioneToJSON(value?: IndirizzoLocalizzazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comune': value.comune,
        'provincia': value.provincia,
        'provinciaTer': value.provinciaTer,
        'toponimo': value.toponimo,
        'via': value.via,
        'cap': value.cap,
        'stato': value.stato,
        'frazione': value.frazione,
        'altreIndicazioni': value.altreIndicazioni,
        'fsedePressoTerzi': value.fsedePressoTerzi,
        'cstradario': value.cstradario,
        'ctoponimo': value.ctoponimo,
        'cstato': value.cstato,
        'ccomune': value.ccomune,
        'ncivico': value.ncivico,
        'czona': value.czona,
    };
}


