/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditamentiOdc,
    AccreditamentiOdcFromJSON,
    AccreditamentiOdcFromJSONTyped,
    AccreditamentiOdcToJSON,
    AddettiComuni,
    AddettiComuniFromJSON,
    AddettiComuniFromJSONTyped,
    AddettiComuniToJSON,
    AddettiImpresa,
    AddettiImpresaFromJSON,
    AddettiImpresaFromJSONTyped,
    AddettiImpresaToJSON,
    AttestazioniQualificazioni,
    AttestazioniQualificazioniFromJSON,
    AttestazioniQualificazioniFromJSONTyped,
    AttestazioniQualificazioniToJSON,
    AttivitaAaBz,
    AttivitaAaBzFromJSON,
    AttivitaAaBzFromJSONTyped,
    AttivitaAaBzToJSON,
    AttivitaAgricola,
    AttivitaAgricolaFromJSON,
    AttivitaAgricolaFromJSONTyped,
    AttivitaAgricolaToJSON,
    AttivitaNoAa,
    AttivitaNoAaFromJSON,
    AttivitaNoAaFromJSONTyped,
    AttivitaNoAaToJSON,
    AttivitaPrevalente,
    AttivitaPrevalenteFromJSON,
    AttivitaPrevalenteFromJSONTyped,
    AttivitaPrevalenteToJSON,
    Certificazioni,
    CertificazioniFromJSON,
    CertificazioniFromJSONTyped,
    CertificazioniToJSON,
    CertificazioniBio,
    CertificazioniBioFromJSON,
    CertificazioniBioFromJSONTyped,
    CertificazioniBioToJSON,
    ClassificazioniAteco,
    ClassificazioniAtecoFromJSON,
    ClassificazioniAtecoFromJSONTyped,
    ClassificazioniAtecoToJSON,
    ClassificazioniAteco2002,
    ClassificazioniAteco2002FromJSON,
    ClassificazioniAteco2002FromJSONTyped,
    ClassificazioniAteco2002ToJSON,
    FamiliariPartecipi,
    FamiliariPartecipiFromJSON,
    FamiliariPartecipiFromJSONTyped,
    FamiliariPartecipiToJSON,
    ImpresaSociale,
    ImpresaSocialeFromJSON,
    ImpresaSocialeFromJSONTyped,
    ImpresaSocialeToJSON,
    LavoroPrestatoFamiliariPart,
    LavoroPrestatoFamiliariPartFromJSON,
    LavoroPrestatoFamiliariPartFromJSONTyped,
    LavoroPrestatoFamiliariPartToJSON,
    RatingLegalita,
    RatingLegalitaFromJSON,
    RatingLegalitaFromJSONTyped,
    RatingLegalitaToJSON,
    Runts,
    RuntsFromJSON,
    RuntsFromJSONTyped,
    RuntsToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoAttivita
 */
export interface InfoAttivita {
    /**
     * 
     * @type {LavoroPrestatoFamiliariPart}
     * @memberof InfoAttivita
     */
    lavoroPrestatoFamiliariPart?: LavoroPrestatoFamiliariPart;
    /**
     * 
     * @type {FamiliariPartecipi}
     * @memberof InfoAttivita
     */
    familiariPartecipi?: FamiliariPartecipi;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    attivitaEsercitata?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    attivitaSecondariaEsercitata?: string;
    /**
     * 
     * @type {AttivitaAaBz}
     * @memberof InfoAttivita
     */
    attivitaAaBz?: AttivitaAaBz;
    /**
     * 
     * @type {AttivitaNoAa}
     * @memberof InfoAttivita
     */
    attivitaNoAa?: AttivitaNoAa;
    /**
     * 
     * @type {AttivitaAgricola}
     * @memberof InfoAttivita
     */
    attivitaAgricola?: AttivitaAgricola;
    /**
     * 
     * @type {ClassificazioniAteco2002}
     * @memberof InfoAttivita
     */
    classificazioniAteco2002?: ClassificazioniAteco2002;
    /**
     * 
     * @type {Array<ClassificazioniAteco>}
     * @memberof InfoAttivita
     */
    classificazioniAteco?: Array<ClassificazioniAteco>;
    /**
     * 
     * @type {AttivitaPrevalente}
     * @memberof InfoAttivita
     */
    attivitaPrevalente?: AttivitaPrevalente;
    /**
     * 
     * @type {AttestazioniQualificazioni}
     * @memberof InfoAttivita
     */
    attestazioniQualificazioni?: AttestazioniQualificazioni;
    /**
     * 
     * @type {CertificazioniBio}
     * @memberof InfoAttivita
     */
    certificazioniBio?: CertificazioniBio;
    /**
     * 
     * @type {Certificazioni}
     * @memberof InfoAttivita
     */
    certificazioni?: Certificazioni;
    /**
     * 
     * @type {AccreditamentiOdc}
     * @memberof InfoAttivita
     */
    accreditamentiOdc?: AccreditamentiOdc;
    /**
     * 
     * @type {ImpresaSociale}
     * @memberof InfoAttivita
     */
    impresaSociale?: ImpresaSociale;
    /**
     * 
     * @type {Runts}
     * @memberof InfoAttivita
     */
    runts?: Runts;
    /**
     * 
     * @type {RatingLegalita}
     * @memberof InfoAttivita
     */
    ratingLegalita?: RatingLegalita;
    /**
     * 
     * @type {Array<AddettiImpresa>}
     * @memberof InfoAttivita
     */
    addettiImpresa?: Array<AddettiImpresa>;
    /**
     * 
     * @type {Array<AddettiComuni>}
     * @memberof InfoAttivita
     */
    addettiComuni?: Array<AddettiComuni>;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    dtInizioAttivitaImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAttivita
     */
    cstato?: string;
}

export function InfoAttivitaFromJSON(json: any): InfoAttivita {
    return InfoAttivitaFromJSONTyped(json, false);
}

export function InfoAttivitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoAttivita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lavoroPrestatoFamiliariPart': !exists(json, 'lavoroPrestatoFamiliariPart') ? undefined : LavoroPrestatoFamiliariPartFromJSON(json['lavoroPrestatoFamiliariPart']),
        'familiariPartecipi': !exists(json, 'familiariPartecipi') ? undefined : FamiliariPartecipiFromJSON(json['familiariPartecipi']),
        'attivitaEsercitata': !exists(json, 'attivitaEsercitata') ? undefined : json['attivitaEsercitata'],
        'attivitaSecondariaEsercitata': !exists(json, 'attivitaSecondariaEsercitata') ? undefined : json['attivitaSecondariaEsercitata'],
        'attivitaAaBz': !exists(json, 'attivitaAaBz') ? undefined : AttivitaAaBzFromJSON(json['attivitaAaBz']),
        'attivitaNoAa': !exists(json, 'attivitaNoAa') ? undefined : AttivitaNoAaFromJSON(json['attivitaNoAa']),
        'attivitaAgricola': !exists(json, 'attivitaAgricola') ? undefined : AttivitaAgricolaFromJSON(json['attivitaAgricola']),
        'classificazioniAteco2002': !exists(json, 'classificazioniAteco2002') ? undefined : ClassificazioniAteco2002FromJSON(json['classificazioniAteco2002']),
        'classificazioniAteco': !exists(json, 'classificazioniAteco') ? undefined : ((json['classificazioniAteco'] as Array<any>).map(ClassificazioniAtecoFromJSON)),
        'attivitaPrevalente': !exists(json, 'attivitaPrevalente') ? undefined : AttivitaPrevalenteFromJSON(json['attivitaPrevalente']),
        'attestazioniQualificazioni': !exists(json, 'attestazioniQualificazioni') ? undefined : AttestazioniQualificazioniFromJSON(json['attestazioniQualificazioni']),
        'certificazioniBio': !exists(json, 'certificazioniBio') ? undefined : CertificazioniBioFromJSON(json['certificazioniBio']),
        'certificazioni': !exists(json, 'certificazioni') ? undefined : CertificazioniFromJSON(json['certificazioni']),
        'accreditamentiOdc': !exists(json, 'accreditamentiOdc') ? undefined : AccreditamentiOdcFromJSON(json['accreditamentiOdc']),
        'impresaSociale': !exists(json, 'impresaSociale') ? undefined : ImpresaSocialeFromJSON(json['impresaSociale']),
        'runts': !exists(json, 'runts') ? undefined : RuntsFromJSON(json['runts']),
        'ratingLegalita': !exists(json, 'ratingLegalita') ? undefined : RatingLegalitaFromJSON(json['ratingLegalita']),
        'addettiImpresa': !exists(json, 'addettiImpresa') ? undefined : ((json['addettiImpresa'] as Array<any>).map(AddettiImpresaFromJSON)),
        'addettiComuni': !exists(json, 'addettiComuni') ? undefined : ((json['addettiComuni'] as Array<any>).map(AddettiComuniFromJSON)),
        'dtInizioAttivitaImpresa': !exists(json, 'dtInizioAttivitaImpresa') ? undefined : json['dtInizioAttivitaImpresa'],
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
    };
}

export function InfoAttivitaToJSON(value?: InfoAttivita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lavoroPrestatoFamiliariPart': LavoroPrestatoFamiliariPartToJSON(value.lavoroPrestatoFamiliariPart),
        'familiariPartecipi': FamiliariPartecipiToJSON(value.familiariPartecipi),
        'attivitaEsercitata': value.attivitaEsercitata,
        'attivitaSecondariaEsercitata': value.attivitaSecondariaEsercitata,
        'attivitaAaBz': AttivitaAaBzToJSON(value.attivitaAaBz),
        'attivitaNoAa': AttivitaNoAaToJSON(value.attivitaNoAa),
        'attivitaAgricola': AttivitaAgricolaToJSON(value.attivitaAgricola),
        'classificazioniAteco2002': ClassificazioniAteco2002ToJSON(value.classificazioniAteco2002),
        'classificazioniAteco': value.classificazioniAteco === undefined ? undefined : ((value.classificazioniAteco as Array<any>).map(ClassificazioniAtecoToJSON)),
        'attivitaPrevalente': AttivitaPrevalenteToJSON(value.attivitaPrevalente),
        'attestazioniQualificazioni': AttestazioniQualificazioniToJSON(value.attestazioniQualificazioni),
        'certificazioniBio': CertificazioniBioToJSON(value.certificazioniBio),
        'certificazioni': CertificazioniToJSON(value.certificazioni),
        'accreditamentiOdc': AccreditamentiOdcToJSON(value.accreditamentiOdc),
        'impresaSociale': ImpresaSocialeToJSON(value.impresaSociale),
        'runts': RuntsToJSON(value.runts),
        'ratingLegalita': RatingLegalitaToJSON(value.ratingLegalita),
        'addettiImpresa': value.addettiImpresa === undefined ? undefined : ((value.addettiImpresa as Array<any>).map(AddettiImpresaToJSON)),
        'addettiComuni': value.addettiComuni === undefined ? undefined : ((value.addettiComuni as Array<any>).map(AddettiComuniToJSON)),
        'dtInizioAttivitaImpresa': value.dtInizioAttivitaImpresa,
        'dtInizio': value.dtInizio,
        'stato': value.stato,
        'cstato': value.cstato,
    };
}


