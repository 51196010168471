/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CertificazioneBio
 */
export interface CertificazioneBio {
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    fonte?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    dtUltimoAggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    operatore?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    dtAssoggettamento?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    attivita?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    odc?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    attivitaCertificata?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    dtScadenza?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    coperatore?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    ncertificato?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    ncertificatoConformita?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificazioneBio
     */
    codc?: string;
}

export function CertificazioneBioFromJSON(json: any): CertificazioneBio {
    return CertificazioneBioFromJSONTyped(json, false);
}

export function CertificazioneBioFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificazioneBio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fonte': !exists(json, 'fonte') ? undefined : json['fonte'],
        'dtUltimoAggiornamento': !exists(json, 'dtUltimoAggiornamento') ? undefined : json['dtUltimoAggiornamento'],
        'operatore': !exists(json, 'operatore') ? undefined : json['operatore'],
        'dtAssoggettamento': !exists(json, 'dtAssoggettamento') ? undefined : json['dtAssoggettamento'],
        'attivita': !exists(json, 'attivita') ? undefined : json['attivita'],
        'odc': !exists(json, 'odc') ? undefined : json['odc'],
        'attivitaCertificata': !exists(json, 'attivitaCertificata') ? undefined : json['attivitaCertificata'],
        'dtScadenza': !exists(json, 'dtScadenza') ? undefined : json['dtScadenza'],
        'coperatore': !exists(json, 'coperatore') ? undefined : json['coperatore'],
        'ncertificato': !exists(json, 'ncertificato') ? undefined : json['ncertificato'],
        'ncertificatoConformita': !exists(json, 'ncertificatoConformita') ? undefined : json['ncertificatoConformita'],
        'codc': !exists(json, 'codc') ? undefined : json['codc'],
    };
}

export function CertificazioneBioToJSON(value?: CertificazioneBio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fonte': value.fonte,
        'dtUltimoAggiornamento': value.dtUltimoAggiornamento,
        'operatore': value.operatore,
        'dtAssoggettamento': value.dtAssoggettamento,
        'attivita': value.attivita,
        'odc': value.odc,
        'attivitaCertificata': value.attivitaCertificata,
        'dtScadenza': value.dtScadenza,
        'coperatore': value.coperatore,
        'ncertificato': value.ncertificato,
        'ncertificatoConformita': value.ncertificatoConformita,
        'codc': value.codc,
    };
}


