/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DipendentiContratti,
    DipendentiContrattiFromJSON,
    DipendentiContrattiFromJSONTyped,
    DipendentiContrattiToJSON,
    DipendentiOrariLavoro,
    DipendentiOrariLavoroFromJSON,
    DipendentiOrariLavoroFromJSONTyped,
    DipendentiOrariLavoroToJSON,
    DipendentiQualifiche,
    DipendentiQualificheFromJSON,
    DipendentiQualificheFromJSONTyped,
    DipendentiQualificheToJSON,
} from './';

/**
 * 
 * @export
 * @interface DistribuzioneDipendenti
 */
export interface DistribuzioneDipendenti {
    /**
     * 
     * @type {DipendentiContratti}
     * @memberof DistribuzioneDipendenti
     */
    dipendentiContratti?: DipendentiContratti;
    /**
     * 
     * @type {DipendentiOrariLavoro}
     * @memberof DistribuzioneDipendenti
     */
    dipendentiOrariLavoro?: DipendentiOrariLavoro;
    /**
     * 
     * @type {DipendentiQualifiche}
     * @memberof DistribuzioneDipendenti
     */
    dipendentiQualifiche?: DipendentiQualifiche;
    /**
     * 
     * @type {string}
     * @memberof DistribuzioneDipendenti
     */
    fpresenzaAgricoli?: string;
}

export function DistribuzioneDipendentiFromJSON(json: any): DistribuzioneDipendenti {
    return DistribuzioneDipendentiFromJSONTyped(json, false);
}

export function DistribuzioneDipendentiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistribuzioneDipendenti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dipendentiContratti': !exists(json, 'dipendentiContratti') ? undefined : DipendentiContrattiFromJSON(json['dipendentiContratti']),
        'dipendentiOrariLavoro': !exists(json, 'dipendentiOrariLavoro') ? undefined : DipendentiOrariLavoroFromJSON(json['dipendentiOrariLavoro']),
        'dipendentiQualifiche': !exists(json, 'dipendentiQualifiche') ? undefined : DipendentiQualificheFromJSON(json['dipendentiQualifiche']),
        'fpresenzaAgricoli': !exists(json, 'fpresenzaAgricoli') ? undefined : json['fpresenzaAgricoli'],
    };
}

export function DistribuzioneDipendentiToJSON(value?: DistribuzioneDipendenti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dipendentiContratti': DipendentiContrattiToJSON(value.dipendentiContratti),
        'dipendentiOrariLavoro': DipendentiOrariLavoroToJSON(value.dipendentiOrariLavoro),
        'dipendentiQualifiche': DipendentiQualificheToJSON(value.dipendentiQualifiche),
        'fpresenzaAgricoli': value.fpresenzaAgricoli,
    };
}


