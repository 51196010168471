import React from "react";
import {StyleSheet, ScrollView, TouchableOpacity, Image, View,Text, StatusBar } from "react-native";
import Collapsible from "react-native-collapsible";
import { ProgressChart } from "react-native-chart-kit";
import * as Animatable from 'react-native-animatable';
import Icon from "../../../Tools/Icon";
import { i18n } from "../../../../i18n/i18n";
import ChamberCollapsible from "./ChamberCollapsible";
import { RootStackParamList, RootStackScreenProps } from "../../../../../types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";


export default function ChamberData(props: {navigation: NativeStackNavigationProp<RootStackParamList, "DashboardCompany" | "DashboardSavedCompany" | "DashboardCompanySearched">, companyDataId?: number}) {
    const [collapsed, setCollapsed] = React.useState(true);
   
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
      };
    return(
        <View style = {[styles.label, {backgroundColor:(collapsed?'#222222':'#292929')}]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style = {styles.textLabel}>
                        {i18n.t('components.headers.chamber_data')}
                    </Text> 
                    <Icon  name={collapsed ? "angle-down" : "angle-up"} color="white"/>
                </View>
            </TouchableOpacity>
            <Collapsible 
                duration={1000} 
                collapsed={collapsed}
                style={styles.contentAccordion}
            >   
                <View style={styles.delimiter}/>
                <View style={styles.col}>
                    
                    <ChamberCollapsible navigation={props.navigation} companyDataId={props.companyDataId}/>
                </View>
            </Collapsible>
        </View> 
    );
}

const styles = StyleSheet.create({
    
    header:{
        flexDirection:"row",
        flex:1,
        alignItems:'center',
    },
    table:{
        flex:1,

    },
    delimiter:{
      borderWidth:1,
      borderRadius:20,
      borderColor:'#505050',
    },
    row:{
        flexDirection:'row',
    },
    col:{
        flexDirection:'column',   
        marginVertical:5,
        borderRadius:5,
    },
    title:{
        padding:5,
        color:'white',
        fontFamily:'poppins',
    },
    contentAccordion:{
        backgroundColor:"#292929",
        flexDirection:"column",
        height:'auto',
        
        borderRadius:5,
    },
    legendText:{
        position:'relative',
        top:-37,
        color:'#00CC83'
    },
    label:{
        marginBottom:35,
        borderRadius:5,
        display:"flex",
    },
    textLabel:{
        paddingLeft:15,
        paddingVertical:10,
        flex:0.95,
        alignSelf:"flex-start",
        color:"white",
        textAlign:'left',
        fontWeight: 'bold',
    },
    bodyAccordion:{
        backgroundColor:"white",
        flex:1,
    },
    buttonSeeMore:{
        alignSelf:'center'
    },
    textSeeMore:{
        color:'#00CC83',
        padding:15,
        fontFamily:'poppins',
        fontSize:16,
        textDecorationLine:'underline',
    },
  });