/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnagraficaTitolare
 */
export interface AnagraficaTitolare {
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    cittadinanza?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    denominazioneDenunciata?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    dtCancellazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    ccittadinanza?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    fcessata?: string;
    /**
     * 
     * @type {string}
     * @memberof AnagraficaTitolare
     */
    ctipo?: string;
}

export function AnagraficaTitolareFromJSON(json: any): AnagraficaTitolare {
    return AnagraficaTitolareFromJSONTyped(json, false);
}

export function AnagraficaTitolareFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnagraficaTitolare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'cittadinanza': !exists(json, 'cittadinanza') ? undefined : json['cittadinanza'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'denominazioneDenunciata': !exists(json, 'denominazioneDenunciata') ? undefined : json['denominazioneDenunciata'],
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'dtCancellazione': !exists(json, 'dtCancellazione') ? undefined : json['dtCancellazione'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'ccittadinanza': !exists(json, 'ccittadinanza') ? undefined : json['ccittadinanza'],
        'fcessata': !exists(json, 'fcessata') ? undefined : json['fcessata'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function AnagraficaTitolareToJSON(value?: AnagraficaTitolare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'cittadinanza': value.cittadinanza,
        'denominazione': value.denominazione,
        'denominazioneDenunciata': value.denominazioneDenunciata,
        'cognome': value.cognome,
        'nome': value.nome,
        'dtCancellazione': value.dtCancellazione,
        'cfiscale': value.cfiscale,
        'ccittadinanza': value.ccittadinanza,
        'fcessata': value.fcessata,
        'ctipo': value.ctipo,
    };
}


