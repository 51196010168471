
import { BackgroundColorButtonGreen, BackgroundColorButtonDarkLight, ColorTextButton, ColorTextLightGreen, BackgroundColorButtonGreyLight, BackgroundColorButtonGreyDark } from '@components/ColorTheme';
import { useFocusEffect } from '@react-navigation/native';
import Icon from '@screens/Tools/Icon';
import { CompanySurveyCompletedEnum } from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, FlatList } from 'react-native';
import { i18n } from '@i18n/i18n';
export interface ConfigList {
    listTitle?: string,
    key: string,
    type: string,
    enableActions?: boolean,
    idResChecked?: number,
}

export default function HykeeList(props: { list: Array<object>, config: ConfigList, passToParent: any, navigate: any }) {

    const [array, setArray] = React.useState<any[]>([]);
    let listData: Array<object> = props.list;
    const [surveyDate, setSurveyDate] = useState([]);

    useFocusEffect(
        useCallback(() => {
            if (array.length > 0) {
                setArray([])
            }
        }, [])
    );

    useEffect(() => {
        setSurveyDate(surveyDate);
    }, []);

    return (
        <View style={{}}>
            <View style={{}}>
                {props.config.listTitle != undefined &&
                    <Text style={styles.listTitle}>{props.config.listTitle}</Text>
                }
                <FlatList nestedScrollEnabled={true}
                    data={listData}
                    renderItem={({ item, index, separators }) => (
                        <View style={styles.container}>
                            {(!props.config.enableActions) &&
                                <TouchableOpacity
                                    style={item.id == props.config.idResChecked ? styles.listSelected : styles.list}
                                    onPress={() => {
                                        array.push(item)
                                        props.passToParent({ item: item, type: props.config.type })
                                        props.config.idResChecked = item.id

                                    }}
                                >
                                    <View style={{ flex: 1 }} >
                                        {item[props.config.key] != undefined && <Text style={styles.elementList}>{item[props.config.key]}</Text>}
                                    </View>
                                </TouchableOpacity>
                            }
                            {(props.config.enableActions) &&
                                <View style={styles.actionButtonsContainer}>

                                    <View style={item.completed == CompanySurveyCompletedEnum.Completed ? styles.listContainerCompleted :
                                        item.completed == CompanySurveyCompletedEnum.Uncompleted ? styles.listContainerUncompleted : styles.listContainer} >
                                        <Text style={[styles.elementList, item.completed == CompanySurveyCompletedEnum.Uncompleted ? { color: 'black' } : null]}>{item[props.config.key]}</Text>
                                    </View>
                                    {item.completed &&
                                        <View style={{ flex: 0.4 }}>
                                            {item.updateDate != undefined &&
                                                <Text numberOfLines={1} style={{ color: "white", fontSize: 10, marginBottom: 5, alignSelf: 'center' }}>
                                                    {i18n.t('survey_screen.dashboard.update') + (item.updateDate)}
                                                </Text>}
                                            <View style={styles.iconContainer}>

                                                <TouchableOpacity
                                                    style={styles.iconButton}
                                                    onPress={() => {
                                                        array.push(item)
                                                        props.passToParent({ item: item, type: props.config.type })
                                                    }}
                                                >
                                                    <View style={styles.iconAction} >
                                                        <Icon name={'edit'} size={18} color={'#00CC83'} />
                                                    </View>
                                                </TouchableOpacity>

                                                {item.completed == CompanySurveyCompletedEnum.Completed &&
                                                    <View style={{ flex: 0.45 }}>

                                                        <TouchableOpacity
                                                            style={styles.iconButton}
                                                            onPress={() => {
                                                                array.push(item)
                                                                props.navigate({ item: item, type: props.config.type })
                                                            }}
                                                        >

                                                            <View style={styles.iconAction} >
                                                                <Icon name={'eye'} size={18} color={'#00CC83'} />
                                                            </View>

                                                        </TouchableOpacity></View>}

                                            </View>
                                        </View>
                                    }
                                    {!item.completed &&
                                        <View style={[styles.iconContainer, { flex: 0.4 }]}>
                                            <TouchableOpacity
                                                style={styles.iconButton}
                                                onPress={() => {
                                                    array.push(item)
                                                    props.passToParent({ item: item, type: props.config.type })
                                                }}
                                            >
                                                <View style={styles.iconAction} >
                                                    <Icon name={'file-o'} size={18} color={'#00CC83'} />
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>

                            }
                        </View>
                    )} />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 50 }}>
                {(props.config.type == 'moreElement' || props.config.type == 'lastElement') &&
                    <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => { props.passToParent({ type: 'back' }) }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text style={styles.elementList}>{i18n.t("components.hykeeList.previousButton")}</Text>
                        </View>
                    </TouchableOpacity>
                }
                {(props.config.type == 'moreElement' || props.config.type == 'firstElement') &&
                    <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => { props.passToParent({ type: 'next' }) }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text style={styles.elementList}>{i18n.t("components.hykeeList.nextButton")}</Text>
                        </View>
                    </TouchableOpacity>
                }
                {(props.config.type == 'lastElement') &&
                    <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => { props.passToParent({ type: props.config.type }) }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text style={styles.elementList}>{'Submit'}</Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {

        flexDirection: 'column',
        marginBottom: 30
    },
    list: {
        marginBottom: 15,
        borderRadius: 12,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: BackgroundColorButtonDarkLight(),
        backgroundColor: BackgroundColorButtonGreyLight(),
        color: 'white',
    },
    listTitle: {
        color: ColorTextLightGreen(),

        fontSize: 20,
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 20
    },
    listContainer: {
        borderRadius: 12,
        backgroundColor: BackgroundColorButtonGreyLight(),
        color: 'white',
        flex: 0.6,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: BackgroundColorButtonDarkLight(),
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'flex-start'
    },
    listContainerCompleted: {
        borderRadius: 12,
        backgroundColor: BackgroundColorButtonGreen(),
        justifyContent: 'space-around',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: BackgroundColorButtonDarkLight(),
        alignContent: 'center',
        alignItems: 'flex-start',
        flex: 0.6,
    },
    listContainerUncompleted: {
        borderRadius: 12,
        backgroundColor: 'yellow',
        justifyContent: 'space-around',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: BackgroundColorButtonDarkLight(),
        alignContent: 'center',
        alignItems: 'flex-start',
        flex: 0.6,
    },
    iconContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        alignItems: 'flex-end',
    },
    actionButtonsContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        backgroundColor: BackgroundColorButtonGreyLight(),
        paddingVertical: 8,
        paddingLeft: 7,
    },
    iconButton: {
        borderRadius: 20,
        flex: 0.45,
        backgroundColor: BackgroundColorButtonGreyDark(),
    },
    iconAction: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    listSelected: {
        marginBottom: 15,
        borderRadius: 12,
        backgroundColor: BackgroundColorButtonGreen(),
    },
    submitButton: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 12,
        backgroundColor: BackgroundColorButtonGreen(),
        flex: 0.3,
    },
    elementList: {
        color: ColorTextButton(),
        padding: 16,

    },
    title: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white'
    },
    col: {

        flexDirection: 'column',
        marginTop: 30,
        marginBottom: 30,
    },
})