/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDataBlockDTO,
    CompanyDataBlockDTOFromJSON,
    CompanyDataBlockDTOFromJSONTyped,
    CompanyDataBlockDTOToJSON,
    DataBlockUser,
    DataBlockUserFromJSON,
    DataBlockUserFromJSONTyped,
    DataBlockUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface DataBlockUserDTO
 */
export interface DataBlockUserDTO {
    /**
     * 
     * @type {CompanyDataBlockDTO}
     * @memberof DataBlockUserDTO
     */
    companyDataBlock?: CompanyDataBlockDTO;
    /**
     * 
     * @type {DataBlockUser}
     * @memberof DataBlockUserDTO
     */
    dataBlockUser?: DataBlockUser;
}

export function DataBlockUserDTOFromJSON(json: any): DataBlockUserDTO {
    return DataBlockUserDTOFromJSONTyped(json, false);
}

export function DataBlockUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataBlockUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyDataBlock': !exists(json, 'companyDataBlock') ? undefined : CompanyDataBlockDTOFromJSON(json['companyDataBlock']),
        'dataBlockUser': !exists(json, 'dataBlockUser') ? undefined : DataBlockUserFromJSON(json['dataBlockUser']),
    };
}

export function DataBlockUserDTOToJSON(value?: DataBlockUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyDataBlock': CompanyDataBlockDTOToJSON(value.companyDataBlock),
        'dataBlockUser': DataBlockUserToJSON(value.dataBlockUser),
    };
}


