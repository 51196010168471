
// 
// 
//
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { useFocusEffect } from '@react-navigation/native';
import { customUserPlanResourceApi } from '@services/apis/ApiConfiguration';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useContext, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, Linking, ScrollView } from 'react-native';
import { Profile, RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import Icon from '../../Tools/Icon';
import SharedContext from '../../../navigation/SharedContext';
import { PlanNameEnum } from '@services/apis/generated/models/Plan';
import hykeeStyle from '@components/hykeeStyle';
import { BackgroundColorButtonGreen, ColorTextLightGreen } from '@components/ColorTheme';
import { UserPlan } from '@services/apis/generated/models/UserPlan';
import Colors from "../../../../constants/Colors";



export default function ConsultancyData({ navigation, route }: RootStackScreenProps<'ConsultancyData'>, props: { profile: Profile | null }) {

  const url = "http://www.rbhq.it"
  const sharedContext = useContext(SharedContext)
  const [activePlanAdvisory, setActivePlanAdvisory] = useState<UserPlan | undefined>(sharedContext.myUserPlans?.find((userPlan) => userPlan.plan?.name == PlanNameEnum.Advisory));
  const [activePlanPlus, setActivePlanPlus] = useState<UserPlan | undefined>(sharedContext.myUserPlans?.find((userPlan) => userPlan.plan?.name == PlanNameEnum.Plus));
  const [askAdvicePlus, setAskAdvicePlus] = useState<boolean>(false);
  const [askAdviceAdvisory, setAskAdviceAdvisory] = useState<boolean>(false);

  useFocusEffect(
    useCallback(
      () => {
        customUserPlanResourceApi.getUserPlansByLoggedUser().then((planActive) => {
          let activePlans: UserPlan[] = [];
          planActive.map((plan) => activePlans?.push(plan));
          sharedContext.setMyUserPlans(activePlans);
        }).catch(error => AlertHelper.showSimpleErrorAlert())
      },
      [],
    )
  )


  const askConsultancyAdvisory = async () => {
    await Linking.openURL("mailto:hello@rbhq.it")
    //setAskAdviceAdvisory(!askAdviceAdvisory)
  }

  const askConsultancyPlus = async () => {
    await Linking.openURL("mailto:hello@rbhq.it")
    //setAskAdvicePlus(!askAdvicePlus)
  }


  const buyPlan = async (plan: PlanNameEnum) => {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      if (plan == PlanNameEnum.Plus) {
        await Linking.openURL(url);
      } else {
        await Linking.openURL(url);
      }
    } else {
      AlertHelper.showSimpleErrorAlert()
    }
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />

      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={'#00CC83'} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.consultancies")}</Text>
      </View>

      <ScrollView style={[styles.col, styles.containerScroll]}>
        {activePlanPlus && //piano Plus Attivo
          <View style={styles.row}>
            <View style={{ flex: 0.5 }}>
              <Text style={styles.textLabel}>
                {i18n.t("screens.consultancies_data.hour_consultancies", { hour: 2 })}
              </Text>
            </View>
            {!askAdvicePlus && //richiesta consulenza da effettuare
              <View style={[styles.col, { flex: 0.5, alignItems: 'flex-end' }]}>
                <Text style={styles.textLabel}>
                  {i18n.t("screens.consultancies_data.reserve")}
                </Text>
                <TouchableOpacity
                  onPress={() => { askConsultancyPlus() }}>
                  <Text style={styles.textLink}>
                    {i18n.t("screens.consultancies_data.ask_advice")}
                  </Text>
                </TouchableOpacity>
              </View>
            }
            {askAdvicePlus &&//richiesta consulenza effettuata
              <View style={[styles.col, { flex: 0.5, alignItems: 'flex-end' }]}>
                <Text style={styles.textLabel}>
                  {i18n.t("screens.consultancies_data.reserved")}
                </Text>
              </View>
            }
          </View>}

        {activePlanAdvisory && // piano advisory attivo
          <View style={styles.row}>
            <View style={{ flex: 0.5 }}>
              <Text style={styles.textLabel}>
                {i18n.t("screens.consultancies_data.advisory_module")}
              </Text>
            </View>
            {!askAdviceAdvisory && //richiesta consulenza da effettuare
              <View style={[styles.col, { flex: 0.5, alignItems: 'flex-end' }]}>
                <Text style={styles.textLabel}>
                  {i18n.t("screens.consultancies_data.time_left_advisory", { hour: 20 })}
                </Text>
                <TouchableOpacity
                  onPress={() => { askConsultancyAdvisory(); }}>
                  <Text style={styles.textLink}>
                    {i18n.t("screens.consultancies_data.ask_advice")}
                  </Text>
                </TouchableOpacity>
              </View>
            }
            {askAdviceAdvisory && //richiesta consulenza effettuata
              <View style={[styles.col, { flex: 0.5, alignItems: 'flex-end' }]}>
                <Text style={styles.textLabel}>
                  {i18n.t("screens.consultancies_data.reserved")}
                </Text>
              </View>
            }
          </View>}

        {activePlanPlus == undefined && //piano Plus non attivato
          <View style={[styles.col]}>
            <View style={[styles.row]}>
              <Text style={[styles.textSpam]}>
                {i18n.t("screens.consultancies_data.spam_plus")}
              </Text>
            </View>
            <TouchableOpacity
              style={styles.button}
              onPress={() => { buyPlan(PlanNameEnum.Plus) }}>
              <Text style={styles.textButton}>
                {i18n.t("screens.consultancies_data.active_plus")}
              </Text>
            </TouchableOpacity>
          </View>
        }

        {activePlanAdvisory == undefined && // piano Advisory non attivato
          <View style={[styles.col]}>
            <View style={[styles.row]}>
              <Text style={[styles.textSpam]}>
                {i18n.t("screens.consultancies_data.spam_advisory")}
              </Text>
            </View>
            <TouchableOpacity
              style={styles.button}
              onPress={() => { buyPlan(PlanNameEnum.Advisory) }}>
              <Text style={styles.textButton}>
                {i18n.t("screens.consultancies_data.active_advisory")}
              </Text>
            </TouchableOpacity>
          </View>
        }
      </ScrollView>
    </LinearGradient >
  )
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  col: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  textLink: {
    marginHorizontal: 15,
    padding: 5,
    fontFamily: 'poppins',
    color: ColorTextLightGreen()
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  textSpam: {
    marginTop: 30,
    marginBottom: 10,
    marginHorizontal: 15,
    fontSize: 12,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  button: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    backgroundColor: BackgroundColorButtonGreen(),
    alignItems: 'flex-start',
  },
  textButton: {
    color: 'white',
    fontSize: 12,
    fontFamily: 'poppins',
    padding: 5,
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
});


