/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiPratica,
    EstremiPraticaFromJSON,
    EstremiPraticaFromJSONTyped,
    EstremiPraticaToJSON,
    SoggettiControllanti,
    SoggettiControllantiFromJSON,
    SoggettiControllantiFromJSONTyped,
    SoggettiControllantiToJSON,
} from './';

/**
 * 
 * @export
 * @interface PraticaSoggettiControllanti
 */
export interface PraticaSoggettiControllanti {
    /**
     * 
     * @type {EstremiPratica}
     * @memberof PraticaSoggettiControllanti
     */
    estremiPratica?: EstremiPratica;
    /**
     * 
     * @type {SoggettiControllanti}
     * @memberof PraticaSoggettiControllanti
     */
    soggettiControllanti?: SoggettiControllanti;
    /**
     * 
     * @type {Array<string>}
     * @memberof PraticaSoggettiControllanti
     */
    note?: Array<string>;
}

export function PraticaSoggettiControllantiFromJSON(json: any): PraticaSoggettiControllanti {
    return PraticaSoggettiControllantiFromJSONTyped(json, false);
}

export function PraticaSoggettiControllantiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PraticaSoggettiControllanti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiPratica': !exists(json, 'estremiPratica') ? undefined : EstremiPraticaFromJSON(json['estremiPratica']),
        'soggettiControllanti': !exists(json, 'soggettiControllanti') ? undefined : SoggettiControllantiFromJSON(json['soggettiControllanti']),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function PraticaSoggettiControllantiToJSON(value?: PraticaSoggettiControllanti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiPratica': EstremiPraticaToJSON(value.estremiPratica),
        'soggettiControllanti': SoggettiControllantiToJSON(value.soggettiControllanti),
        'note': value.note,
    };
}


