/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySurvey,
    CompanySurveyFromJSON,
    CompanySurveyFromJSONTyped,
    CompanySurveyToJSON,
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionFromJSONTyped,
    SurveyQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * 
     * @type {number}
     * @memberof Survey
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    titleEn?: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    titleIt?: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    key?: string;
    /**
     * 
     * @type {Set<CompanySurvey>}
     * @memberof Survey
     */
    companySurveys?: Set<CompanySurvey>;
    /**
     * 
     * @type {Set<SurveyQuestion>}
     * @memberof Survey
     */
    surveyQuestions?: Set<SurveyQuestion>;
}

export function SurveyFromJSON(json: any): Survey {
    return SurveyFromJSONTyped(json, false);
}

export function SurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Survey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'titleEn': !exists(json, 'titleEn') ? undefined : json['titleEn'],
        'titleIt': !exists(json, 'titleIt') ? undefined : json['titleIt'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'companySurveys': !exists(json, 'companySurveys') ? undefined : ((json['companySurveys'] as Array<any>).map(CompanySurveyFromJSON)),
        'surveyQuestions': !exists(json, 'surveyQuestions') ? undefined : ((json['surveyQuestions'] as Array<any>).map(SurveyQuestionFromJSON)),
    };
}

export function SurveyToJSON(value?: Survey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'titleEn': value.titleEn,
        'titleIt': value.titleIt,
        'name': value.name,
        'key': value.key,
        'companySurveys': value.companySurveys === undefined ? undefined : ((value.companySurveys as Array<any>).map(CompanySurveyToJSON)),
        'surveyQuestions': value.surveyQuestions === undefined ? undefined : ((value.surveyQuestions as Array<any>).map(SurveyQuestionToJSON)),
    };
}


