/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MarketCompetitor,
    MarketCompetitorFromJSON,
    MarketCompetitorToJSON,
} from '../models';

export interface CreateMarketCompetitorRequest {
    marketCompetitor: MarketCompetitor;
}

export interface DeleteMarketCompetitorRequest {
    id: number;
}

export interface GetMarketCompetitorRequest {
    id: number;
}

export interface PartialUpdateMarketCompetitorRequest {
    id: number;
    marketCompetitor: MarketCompetitor;
}

export interface UpdateMarketCompetitorRequest {
    id: number;
    marketCompetitor: MarketCompetitor;
}

/**
 * 
 */
export class MarketCompetitorResourceApi extends runtime.BaseAPI {

    /**
     */
    async createMarketCompetitorRaw(requestParameters: CreateMarketCompetitorRequest): Promise<runtime.ApiResponse<MarketCompetitor>> {
        if (requestParameters.marketCompetitor === null || requestParameters.marketCompetitor === undefined) {
            throw new runtime.RequiredError('marketCompetitor','Required parameter requestParameters.marketCompetitor was null or undefined when calling createMarketCompetitor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/market-competitors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarketCompetitorToJSON(requestParameters.marketCompetitor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketCompetitorFromJSON(jsonValue));
    }

    /**
     */
    async createMarketCompetitor(requestParameters: CreateMarketCompetitorRequest): Promise<MarketCompetitor> {
        const response = await this.createMarketCompetitorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteMarketCompetitorRaw(requestParameters: DeleteMarketCompetitorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMarketCompetitor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/market-competitors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMarketCompetitor(requestParameters: DeleteMarketCompetitorRequest): Promise<void> {
        await this.deleteMarketCompetitorRaw(requestParameters);
    }

    /**
     */
    async getAllMarketCompetitorsRaw(): Promise<runtime.ApiResponse<Array<MarketCompetitor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/market-competitors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketCompetitorFromJSON));
    }

    /**
     */
    async getAllMarketCompetitors(): Promise<Array<MarketCompetitor>> {
        const response = await this.getAllMarketCompetitorsRaw();
        return await response.value();
    }

    /**
     */
    async getMarketCompetitorRaw(requestParameters: GetMarketCompetitorRequest): Promise<runtime.ApiResponse<MarketCompetitor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMarketCompetitor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/market-competitors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketCompetitorFromJSON(jsonValue));
    }

    /**
     */
    async getMarketCompetitor(requestParameters: GetMarketCompetitorRequest): Promise<MarketCompetitor> {
        const response = await this.getMarketCompetitorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateMarketCompetitorRaw(requestParameters: PartialUpdateMarketCompetitorRequest): Promise<runtime.ApiResponse<MarketCompetitor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateMarketCompetitor.');
        }

        if (requestParameters.marketCompetitor === null || requestParameters.marketCompetitor === undefined) {
            throw new runtime.RequiredError('marketCompetitor','Required parameter requestParameters.marketCompetitor was null or undefined when calling partialUpdateMarketCompetitor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/market-competitors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MarketCompetitorToJSON(requestParameters.marketCompetitor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketCompetitorFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateMarketCompetitor(requestParameters: PartialUpdateMarketCompetitorRequest): Promise<MarketCompetitor> {
        const response = await this.partialUpdateMarketCompetitorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateMarketCompetitorRaw(requestParameters: UpdateMarketCompetitorRequest): Promise<runtime.ApiResponse<MarketCompetitor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMarketCompetitor.');
        }

        if (requestParameters.marketCompetitor === null || requestParameters.marketCompetitor === undefined) {
            throw new runtime.RequiredError('marketCompetitor','Required parameter requestParameters.marketCompetitor was null or undefined when calling updateMarketCompetitor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/market-competitors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarketCompetitorToJSON(requestParameters.marketCompetitor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketCompetitorFromJSON(jsonValue));
    }

    /**
     */
    async updateMarketCompetitor(requestParameters: UpdateMarketCompetitorRequest): Promise<MarketCompetitor> {
        const response = await this.updateMarketCompetitorRaw(requestParameters);
        return await response.value();
    }

}
