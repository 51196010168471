/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SedeSecondariaRs
 */
export interface SedeSecondariaRs {
    /**
     * 
     * @type {string}
     * @memberof SedeSecondariaRs
     */
    comuneTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeSecondariaRs
     */
    provinciaTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeSecondariaRs
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeSecondariaRs
     */
    nsedeSecondaria?: string;
    /**
     * 
     * @type {string}
     * @memberof SedeSecondariaRs
     */
    ccomuneTribunale?: string;
}

export function SedeSecondariaRsFromJSON(json: any): SedeSecondariaRs {
    return SedeSecondariaRsFromJSONTyped(json, false);
}

export function SedeSecondariaRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SedeSecondariaRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comuneTribunale': !exists(json, 'comuneTribunale') ? undefined : json['comuneTribunale'],
        'provinciaTribunale': !exists(json, 'provinciaTribunale') ? undefined : json['provinciaTribunale'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'nsedeSecondaria': !exists(json, 'nsedeSecondaria') ? undefined : json['nsedeSecondaria'],
        'ccomuneTribunale': !exists(json, 'ccomuneTribunale') ? undefined : json['ccomuneTribunale'],
    };
}

export function SedeSecondariaRsToJSON(value?: SedeSecondariaRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comuneTribunale': value.comuneTribunale,
        'provinciaTribunale': value.provinciaTribunale,
        'dtIscrizione': value.dtIscrizione,
        'nsedeSecondaria': value.nsedeSecondaria,
        'ccomuneTribunale': value.ccomuneTribunale,
    };
}


