import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { AlertHelper } from "@helpers/AlertHelper";
import { ErrorHelper } from "@helpers/ErrorHelper";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import ModalSimpleConfirmCancel from "@screens/Modal/ModalSimpleConfirmCancel";
import ModalSimpleSuccess from "@screens/Modal/ModalSimpleSuccess";
import Icon from "@screens/Tools/Icon";
import {
    Company,
    CompanyLinkRequest,
    CompanyLinkRequestCurrentStateEnum,
    CompanySurvey,
    InvitationDTO,
    Notification,
    NotificationTypeEnum,
    UserExtendedTypeEnum
} from "@services/apis/generated";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import WarningIcon from "../../../../assets/svg/WarningIcon";
import { RootStackParamList } from "../../../../types";
import AppContext from "../../../navigation/AppContext";
import SharedContext from "../../../navigation/SharedContext";
import {
    customCompanyLinkRequestResourceApi, customCompanyResourceApi, customCompanySurveyResourceApi,
    customNotificationResourceApi,
    customScoreResourceApi, customSurveyResourceApi
} from "../../../services/apis/ApiConfiguration";
import { useFocusEffect } from "@react-navigation/native";
import ModalSimpleSendInvitation from "@screens/Modal/ModalSimpleSendInvitation";
import ModalSimpleInfoText from "@screens/Modal/ModalSimpleInfoText";

export default function BusinessScore(props: { navigation: NativeStackNavigationProp<RootStackParamList, "DashboardCompany" | "DashboardSavedCompany" | "DashboardCompanySearched">, onMyCompanies: boolean, surveyDate: any }): JSX.Element {
    const sharedContext = React.useContext(SharedContext);
    const appContext = useContext(AppContext);

    const [companiesConsulted, setCompaniesConsulted] = useState<CompanyLinkRequest[]>([])

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [modaleSendNotification, setModaleSendNotification] = useState<boolean>(false)
    const [modalSuccessSendNotification, setModalSuccessSendNotification] = useState<boolean>(false)
    const [surveyDate, setSurveyDate] = React.useState<any>()
    const [companySurveys, setCompanySurveys] = React.useState<CompanySurvey[]>([]);
    const [infoTextVisible, setInfoTextVisible] = React.useState<boolean>(false);
    const [infoText, setInfoText] = React.useState<string>()

    //const surveyDate = props.surveyDate

    useEffect(() => {
        getLastSurveyDate();
        return () => {
        }
    }, [sharedContext.selectedCompany?.id])


    function surveyCompile() {
        if (sharedContext.selectedCompany && props.onMyCompanies) {
            NavigationHelper.navigateToSurvey(props.navigation, sharedContext.selectedCompany);
        }
    };

    function changeColorButton() {
        if (checkRequestSent() && !checkRequestAccepted()) {
            sharedContext.setLinkButton(true)
        }
    }

    function sendRequest(email?: string) {
        customCompanyLinkRequestResourceApi.createCompanyLinkRequestWithDefaultState({ invitationDTO: { companyLinkRequest: { company: sharedContext.selectedCompany, consultant: sharedContext.myCompanySelected }, email: email } }).then((res) => {
            let companyLinkRequest: CompanyLinkRequest[] = sharedContext.myCompaniesConsultedRequest!;
            companyLinkRequest.push(res);
            sharedContext.setMyCompaniesConsultedRequest(companyLinkRequest);
            setCompaniesConsulted(companyLinkRequest);
            changeColorButton()
            //NavigationHelper.navigateToDashboardCompanySearched(props.navigation, sharedContext.selectedCompany!);
        }).catch(error => AlertHelper.showSimpleErrorAlert());
    };

    function sendNotificationSelfAssessment() {
        let notification: Notification = {
            type: NotificationTypeEnum.SelfAssessmentCompile,
            company: sharedContext.selectedCompany,
            consultant: sharedContext.myCompanySelected,
        };
        customNotificationResourceApi.createNotificationCustom({ notification: notification }).then((res) => {
            if (res.length > 0) {
                setModalSuccessSendNotification(true)
            } else {
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage('NOTIFICATION_ALREADY_SENT') })
            }

        })
            .catch(async (error) => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
            })

    }

    function checkCompanyRegistered() {
        if (sharedContext.who == UserExtendedTypeEnum.Consultant) {
            customCompanyResourceApi.checkIfCompanyIsRegistered({ company: sharedContext.selectedCompany! }).then((exist) => {
                if (!exist.registered) {
                    setModalVisible(true);
                }
                else {
                    sendRequest();
                }
            })
        }
    }


    function getLastSurveyDate() {
        if (sharedContext.selectedCompany?.id) {
            customCompanySurveyResourceApi.getLastSurvey({ body: sharedContext.selectedCompany?.id }).then(survey => {
                setSurveyDate(moment(survey.updateDate).format("DD/MM/yyyy"));
            }).catch(error => {
                //console.log(error);
            })
        }
    }

    const checkRequestSent = (): boolean => {
        return sharedContext.myCompaniesConsultedRequest != undefined
            && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
                && companyLinkRequest.currentState != CompanyLinkRequestCurrentStateEnum.Rejected) != undefined
    };

    const checkRequestAccepted = (): boolean => {
        return sharedContext.myCompaniesConsultedRequest != undefined
            && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
                && companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted) != undefined

    }

    const goToLinkedCompanies = (): void => {
        NavigationHelper.navigateToLinkedCompaniesData(props.navigation);
    };

    function drawBusinessScoreInfo(): React.ReactNode {
        return (
            <View>
                <TouchableOpacity onPress={() => {
                    setInfoText(i18n.t("components.business_score.info"))
                    setInfoTextVisible(!infoTextVisible)
                }}>
                    <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: "center", alignSelf: "center", alignContent: "center" }}>
                        <Text style={{ fontStyle: "italic", fontFamily: "poppins", color: "white", fontSize: 11 }}>
                            {i18n.t("components.business_score.meaning")}
                        </Text>
                        <View style={{ padding: 10, marginLeft: -5 }}>
                            <Icon name="info-circle" size={12} color={"white"} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <LinearGradient
            colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
            locations={[0.4, 0.8]}
            start={{ x: 1, y: 1 }}
            end={{ x: 0, y: 0 }}
            style={styles.borderGradient}
        >
            <LinearGradient
                colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.containerBack}
            >

                {sharedContext.selectedCompanyLastData?.businessScore != undefined &&//BusinessScore with result
                    <TouchableOpacity
                        activeOpacity={0.5}
                        onPress={() => { surveyCompile() }}
                    >
                        <View style={styles.container}>
                            <Text style={styles.label}>
                                {i18n.t('components.business_score.title')}
                            </Text>
                            {drawBusinessScoreInfo()}
                        </View>



                        {props.onMyCompanies || checkRequestAccepted() ?
                            <View style={styles.containerScore}>
                                <Text style={styles.labelScore}>
                                    {sharedContext.selectedCompanyLastData?.businessScore?.toFixed(1) + "%"}
                                </Text>
                                {surveyDate &&
                                    <Text style={styles.label}>
                                        {surveyDate}
                                    </Text>
                                }

                            </View> :
                            <>
                                <View style={styles.containerIconError}>
                                    <WarningIcon width={60} height={60} />
                                </View>
                                <View style={[styles.containerIconError]}>
                                    <Text style={styles.labelError}>
                                        {props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')}
                                    </Text>
                                </View>
                            </>
                        }
                    </TouchableOpacity>}
                {sharedContext.selectedCompanyLastData?.businessScore == undefined && !checkRequestAccepted() && //BusinessScore without result
                    <TouchableOpacity
                        activeOpacity={0.5}
                        onPress={() => surveyCompile()}
                    >
                        <View style={styles.containerError}>
                            <View style={styles.container}>
                                <Text style={styles.label}>
                                    {i18n.t('components.business_score.title')}
                                </Text>
                                {drawBusinessScoreInfo()}
                            </View>
                            <View style={styles.containerIconError}>
                                <WarningIcon width={60} height={60} />
                            </View>
                            <View style={[styles.containerIconError]}>
                                <Text style={styles.labelError}>
                                    {props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')}
                                </Text>
                            </View>
                        </View>
                    </TouchableOpacity>}
                {sharedContext.selectedCompanyLastData?.businessScore == undefined && checkRequestAccepted() && //BusinessScore without result
                    <View
                    >
                        <View style={styles.containerError}>
                            <View style={styles.container}>
                                <Text style={styles.label}>
                                    {i18n.t('components.business_score.title')}
                                </Text>
                                {drawBusinessScoreInfo()}
                            </View>
                            <View style={[styles.containerIconError]}>
                                <Text style={[styles.labelError, { fontSize: 14 }]}>
                                    {i18n.t('components.business_score.send_request_SA')}
                                </Text>
                            </View>
                            <TouchableOpacity
                                style={[styles.containerIconSend]}
                                onPress={() => {
                                    setModaleSendNotification(true)
                                }
                                }
                            >
                                <View style={styles.containerSend}>
                                    <Icon name={'send'} size={18} color={'white'} />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>}

                {sharedContext.who == UserExtendedTypeEnum.Consultant && !checkRequestSent()  &&
                    <TouchableOpacity
                        style={styles.containerRequest}
                        onPress={() => checkCompanyRegistered()}>
                        <Text style={styles.labelRequest}>
                            {i18n.t('components.business_score.send_request')}
                        </Text>
                    </TouchableOpacity>}
                {sharedContext.who == UserExtendedTypeEnum.Consultant && checkRequestSent() && !checkRequestAccepted() &&
                    <TouchableOpacity
                        style={styles.containerRequestSended}
                        onPress={() => goToLinkedCompanies()}>
                        <Text style={styles.labelRequest}>
                            {i18n.t('components.business_score.show_state_request')}
                        </Text>
                    </TouchableOpacity>}
                {modaleSendNotification &&
                    <ModalSimpleConfirmCancel
                        msg={i18n.t('components.business_score.send_notify')}
                        action={() => sendNotificationSelfAssessment()}
                        visible={(visible) => { setModaleSendNotification(visible) }}
                    />}

                {modalVisible &&
                    <ModalSimpleSendInvitation
                        msg={i18n.t('screens.search.send_invitation')}
                        companySearched={sharedContext.selectedCompany}
                        action={(email) => sendRequest(email)}
                        visible={(visible) => { setModalVisible(visible) }}
                    />
                }
                {modalSuccessSendNotification &&
                    <ModalSimpleSuccess
                        msg={i18n.t('components.business_score.send_notify_success')}
                        visible={(visible) => { setModalSuccessSendNotification(visible) }}
                    />
                }
            </LinearGradient>
            {infoTextVisible && <ModalSimpleInfoText message={infoText} setVisible={(infoText) => (infoText != null ? setInfoTextVisible(infoText) : null)} />}
        </LinearGradient>

    );
}
const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    borderGradient: {
        flex: 0.49,
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {

        flex: 1,
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
        flexDirection: 'column',
    },
    labelScore: {
        textAlign: "center",
        color: "white",
        fontFamily: "poppins",
        fontSize: 40,
        padding: 10
    },
    container: {
    },
    containerError: {
        flexDirection: "column",
    },
    containerRequest: {
        marginVertical: 7,
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius: 5,
        alignItems: 'center',
    },
    containerIconSend: {
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius: 5,

        alignItems: 'center',
    },
    containerSend: {
        paddingVertical: 10
    },
    containerRequestSended: {
        marginVertical: 7,
        backgroundColor: '#009dff',
        borderRadius: 5,
        alignItems: 'center',
    },
    labelRequest: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        padding: 5,
    },
    containerIconError: {
        flexDirection: "column",
        alignSelf: 'center',
        justifyContent: 'center'
    },
    containerScore: {
        flexDirection: "column",
        alignSelf: 'center'
    },
    label: {
        textAlign: "left",
        color: "white",
        fontFamily: "poppins",
        fontSize: 16,
        marginLeft: 15
    },
    labelError: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        padding: 5,
    }
});



