/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrasferimentoSedeUlAttiva
 */
export interface TrasferimentoSedeUlAttiva {
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSedeUlAttiva
     */
    causale?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSedeUlAttiva
     */
    dt?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSedeUlAttiva
     */
    ccausale?: string;
}

export function TrasferimentoSedeUlAttivaFromJSON(json: any): TrasferimentoSedeUlAttiva {
    return TrasferimentoSedeUlAttivaFromJSONTyped(json, false);
}

export function TrasferimentoSedeUlAttivaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentoSedeUlAttiva {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causale': !exists(json, 'causale') ? undefined : json['causale'],
        'dt': !exists(json, 'dt') ? undefined : json['dt'],
        'ccausale': !exists(json, 'ccausale') ? undefined : json['ccausale'],
    };
}

export function TrasferimentoSedeUlAttivaToJSON(value?: TrasferimentoSedeUlAttiva | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causale': value.causale,
        'dt': value.dt,
        'ccausale': value.ccausale,
    };
}


