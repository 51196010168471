import React, { useCallback } from "react";
import SharedContext from "../../../../navigation/SharedContext";
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import hykeeStyle from "@components/hykeeStyle";
import Icon from "@screens/Tools/Icon";
import { ColorTextLightGreen } from "@components/ColorTheme";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import { Preference, RootStackScreenProps } from "../../../../../types";
import Colors from "../../../../../constants/Colors";
import { LinearGradient } from "expo-linear-gradient";
import InsightComponent from "./InsightComponent";
import { customInsightResourceApi } from "@services/apis/ApiConfiguration";
import { Insight } from "@services/apis/generated";
import { useFocusEffect } from '@react-navigation/native';



export default function InsightHome({ navigation, route }: RootStackScreenProps<'InsightHome'>) {

    const sharedContext = React.useContext(SharedContext);
    const [insights, setInsights] = React.useState<Insight[]>([]);

    useFocusEffect(
        useCallback(() => {
            getInsights();
        }, [])
    );
    

    function getInsights(){
        customInsightResourceApi.getAllInsightForLoggedUser({company: sharedContext.myCompanies![0]})
        .then((insightList: Insight[]) => {
            insightList.sort((a,b) => b.dateOfPublication?.valueOf()! - a.dateOfPublication?.valueOf()!)
            setInsights(insightList);
        }).catch((e) => {
            console.log(e);
        })
    }

    const setPreference = (preference: Preference): void => {
        console.log(preference);
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={hykeeStyle.containerBack}
        >
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>
                    {i18n.t("screens.profile_menu.notices")}</Text>
            </View>
            {insights.length == 0 &&
                <View>
                    <Text style={styles.textLabel}>{i18n.t("components.insight.no_insight")}</Text>
                </View>
            }


            {insights &&
                <ScrollView style={[hykeeStyle.containerScroll, { marginBottom: 40 }]}>
                    {insights && insights.map((insight: Insight, idx: number) => {
                        return (
                            <View key={idx} style={styles.insightContainer}>
                                <InsightComponent insight={insight} navigation={navigation}/>
                            </View>
                        )
                    })}
                </ScrollView>
            }

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    insightContainer: {
        flexDirection: 'column',
        paddingBottom: 10
    },
    textLabel: {
        marginTop: 10,
        marginHorizontal: 15,
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
});


