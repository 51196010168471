/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbilitazioniProfessionali,
    AbilitazioniProfessionaliFromJSON,
    AbilitazioniProfessionaliFromJSONTyped,
    AbilitazioniProfessionaliToJSON,
    Cariche,
    CaricheFromJSON,
    CaricheFromJSONTyped,
    CaricheToJSON,
    ConferimentiPrestazioni,
    ConferimentiPrestazioniFromJSON,
    ConferimentiPrestazioniFromJSONTyped,
    ConferimentiPrestazioniToJSON,
    EstremiAtto,
    EstremiAttoFromJSON,
    EstremiAttoFromJSONTyped,
    EstremiAttoToJSON,
    PartecipazioneUtili,
    PartecipazioneUtiliFromJSON,
    PartecipazioneUtiliFromJSONTyped,
    PartecipazioneUtiliToJSON,
    PoteriPersona,
    PoteriPersonaFromJSON,
    PoteriPersonaFromJSONTyped,
    PoteriPersonaToJSON,
    ProprietaQuota,
    ProprietaQuotaFromJSON,
    ProprietaQuotaFromJSONTyped,
    ProprietaQuotaToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttoConferimentoCariche
 */
export interface AttoConferimentoCariche {
    /**
     * 
     * @type {Cariche}
     * @memberof AttoConferimentoCariche
     */
    cariche?: Cariche;
    /**
     * 
     * @type {PoteriPersona}
     * @memberof AttoConferimentoCariche
     */
    poteriPersona?: PoteriPersona;
    /**
     * 
     * @type {EstremiAtto}
     * @memberof AttoConferimentoCariche
     */
    estremiAtto?: EstremiAtto;
    /**
     * 
     * @type {ProprietaQuota}
     * @memberof AttoConferimentoCariche
     */
    proprietaQuota?: ProprietaQuota;
    /**
     * 
     * @type {PartecipazioneUtili}
     * @memberof AttoConferimentoCariche
     */
    partecipazioneUtili?: PartecipazioneUtili;
    /**
     * 
     * @type {ConferimentiPrestazioni}
     * @memberof AttoConferimentoCariche
     */
    conferimentiPrestazioni?: ConferimentiPrestazioni;
    /**
     * 
     * @type {AbilitazioniProfessionali}
     * @memberof AttoConferimentoCariche
     */
    abilitazioniProfessionali?: AbilitazioniProfessionali;
    /**
     * 
     * @type {string}
     * @memberof AttoConferimentoCariche
     */
    pgruppoCariche?: string;
}

export function AttoConferimentoCaricheFromJSON(json: any): AttoConferimentoCariche {
    return AttoConferimentoCaricheFromJSONTyped(json, false);
}

export function AttoConferimentoCaricheFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttoConferimentoCariche {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cariche': !exists(json, 'cariche') ? undefined : CaricheFromJSON(json['cariche']),
        'poteriPersona': !exists(json, 'poteriPersona') ? undefined : PoteriPersonaFromJSON(json['poteriPersona']),
        'estremiAtto': !exists(json, 'estremiAtto') ? undefined : EstremiAttoFromJSON(json['estremiAtto']),
        'proprietaQuota': !exists(json, 'proprietaQuota') ? undefined : ProprietaQuotaFromJSON(json['proprietaQuota']),
        'partecipazioneUtili': !exists(json, 'partecipazioneUtili') ? undefined : PartecipazioneUtiliFromJSON(json['partecipazioneUtili']),
        'conferimentiPrestazioni': !exists(json, 'conferimentiPrestazioni') ? undefined : ConferimentiPrestazioniFromJSON(json['conferimentiPrestazioni']),
        'abilitazioniProfessionali': !exists(json, 'abilitazioniProfessionali') ? undefined : AbilitazioniProfessionaliFromJSON(json['abilitazioniProfessionali']),
        'pgruppoCariche': !exists(json, 'pgruppoCariche') ? undefined : json['pgruppoCariche'],
    };
}

export function AttoConferimentoCaricheToJSON(value?: AttoConferimentoCariche | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cariche': CaricheToJSON(value.cariche),
        'poteriPersona': PoteriPersonaToJSON(value.poteriPersona),
        'estremiAtto': EstremiAttoToJSON(value.estremiAtto),
        'proprietaQuota': ProprietaQuotaToJSON(value.proprietaQuota),
        'partecipazioneUtili': PartecipazioneUtiliToJSON(value.partecipazioneUtili),
        'conferimentiPrestazioni': ConferimentiPrestazioniToJSON(value.conferimentiPrestazioni),
        'abilitazioniProfessionali': AbilitazioniProfessionaliToJSON(value.abilitazioniProfessionali),
        'pgruppoCariche': value.pgruppoCariche,
    };
}


