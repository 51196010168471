/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CodiceLei,
    CodiceLeiFromJSON,
    CodiceLeiFromJSONTyped,
    CodiceLeiToJSON,
    DatiIscrizioneReaRd,
    DatiIscrizioneReaRdFromJSON,
    DatiIscrizioneReaRdFromJSONTyped,
    DatiIscrizioneReaRdToJSON,
    Euid,
    EuidFromJSON,
    EuidFromJSONTyped,
    EuidToJSON,
    GruppoIva,
    GruppoIvaFromJSON,
    GruppoIvaFromJSONTyped,
    GruppoIvaToJSON,
    IndirizzoPostaCertificata,
    IndirizzoPostaCertificataFromJSON,
    IndirizzoPostaCertificataFromJSONTyped,
    IndirizzoPostaCertificataToJSON,
    InformazioniSupplementari,
    InformazioniSupplementariFromJSON,
    InformazioniSupplementariFromJSONTyped,
    InformazioniSupplementariToJSON,
    PartitaIva,
    PartitaIvaFromJSON,
    PartitaIvaFromJSONTyped,
    PartitaIvaToJSON,
    PersonaGiuridicaPrivata,
    PersonaGiuridicaPrivataFromJSON,
    PersonaGiuridicaPrivataFromJSONTyped,
    PersonaGiuridicaPrivataToJSON,
    ProvenienzaTrasferimento,
    ProvenienzaTrasferimentoFromJSON,
    ProvenienzaTrasferimentoFromJSONTyped,
    ProvenienzaTrasferimentoToJSON,
    SedeFuoriProvincia,
    SedeFuoriProvinciaFromJSON,
    SedeFuoriProvinciaFromJSONTyped,
    SedeFuoriProvinciaToJSON,
    SedeSecondariaRs,
    SedeSecondariaRsFromJSON,
    SedeSecondariaRsFromJSONTyped,
    SedeSecondariaRsToJSON,
    Telefax,
    TelefaxFromJSON,
    TelefaxFromJSONTyped,
    TelefaxToJSON,
    Telefono,
    TelefonoFromJSON,
    TelefonoFromJSONTyped,
    TelefonoToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoSede
 */
export interface InfoSede {
    /**
     * 
     * @type {Telefono}
     * @memberof InfoSede
     */
    telefono?: Telefono;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    telex?: string;
    /**
     * 
     * @type {Telefax}
     * @memberof InfoSede
     */
    telefax?: Telefax;
    /**
     * 
     * @type {IndirizzoPostaCertificata}
     * @memberof InfoSede
     */
    indirizzoPostaCertificata?: IndirizzoPostaCertificata;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    sitoInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    legalMail?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    altreFunzioniSede?: string;
    /**
     * 
     * @type {DatiIscrizioneReaRd}
     * @memberof InfoSede
     */
    datiIscrizioneReaRd?: DatiIscrizioneReaRd;
    /**
     * 
     * @type {PersonaGiuridicaPrivata}
     * @memberof InfoSede
     */
    personaGiuridicaPrivata?: PersonaGiuridicaPrivata;
    /**
     * 
     * @type {SedeFuoriProvincia}
     * @memberof InfoSede
     */
    sedeFuoriProvincia?: SedeFuoriProvincia;
    /**
     * 
     * @type {PartitaIva}
     * @memberof InfoSede
     */
    partitaIva?: PartitaIva;
    /**
     * 
     * @type {GruppoIva}
     * @memberof InfoSede
     */
    gruppoIva?: GruppoIva;
    /**
     * 
     * @type {CodiceLei}
     * @memberof InfoSede
     */
    codiceLei?: CodiceLei;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    insegna?: string;
    /**
     * 
     * @type {Euid}
     * @memberof InfoSede
     */
    euid?: Euid;
    /**
     * 
     * @type {SedeSecondariaRs}
     * @memberof InfoSede
     */
    sedeSecondariaRs?: SedeSecondariaRs;
    /**
     * 
     * @type {ProvenienzaTrasferimento}
     * @memberof InfoSede
     */
    provenienzaTrasferimento?: ProvenienzaTrasferimento;
    /**
     * 
     * @type {InformazioniSupplementari}
     * @memberof InfoSede
     */
    informazioniSupplementari?: InformazioniSupplementari;
    /**
     * 
     * @type {string}
     * @memberof InfoSede
     */
    fscrittureContabili?: string;
}

export function InfoSedeFromJSON(json: any): InfoSede {
    return InfoSedeFromJSONTyped(json, false);
}

export function InfoSedeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoSede {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'telefono': !exists(json, 'telefono') ? undefined : TelefonoFromJSON(json['telefono']),
        'telex': !exists(json, 'telex') ? undefined : json['telex'],
        'telefax': !exists(json, 'telefax') ? undefined : TelefaxFromJSON(json['telefax']),
        'indirizzoPostaCertificata': !exists(json, 'indirizzoPostaCertificata') ? undefined : IndirizzoPostaCertificataFromJSON(json['indirizzoPostaCertificata']),
        'sitoInternet': !exists(json, 'sitoInternet') ? undefined : json['sitoInternet'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'legalMail': !exists(json, 'legalMail') ? undefined : json['legalMail'],
        'altreFunzioniSede': !exists(json, 'altreFunzioniSede') ? undefined : json['altreFunzioniSede'],
        'datiIscrizioneReaRd': !exists(json, 'datiIscrizioneReaRd') ? undefined : DatiIscrizioneReaRdFromJSON(json['datiIscrizioneReaRd']),
        'personaGiuridicaPrivata': !exists(json, 'personaGiuridicaPrivata') ? undefined : PersonaGiuridicaPrivataFromJSON(json['personaGiuridicaPrivata']),
        'sedeFuoriProvincia': !exists(json, 'sedeFuoriProvincia') ? undefined : SedeFuoriProvinciaFromJSON(json['sedeFuoriProvincia']),
        'partitaIva': !exists(json, 'partitaIva') ? undefined : PartitaIvaFromJSON(json['partitaIva']),
        'gruppoIva': !exists(json, 'gruppoIva') ? undefined : GruppoIvaFromJSON(json['gruppoIva']),
        'codiceLei': !exists(json, 'codiceLei') ? undefined : CodiceLeiFromJSON(json['codiceLei']),
        'insegna': !exists(json, 'insegna') ? undefined : json['insegna'],
        'euid': !exists(json, 'euid') ? undefined : EuidFromJSON(json['euid']),
        'sedeSecondariaRs': !exists(json, 'sedeSecondariaRs') ? undefined : SedeSecondariaRsFromJSON(json['sedeSecondariaRs']),
        'provenienzaTrasferimento': !exists(json, 'provenienzaTrasferimento') ? undefined : ProvenienzaTrasferimentoFromJSON(json['provenienzaTrasferimento']),
        'informazioniSupplementari': !exists(json, 'informazioniSupplementari') ? undefined : InformazioniSupplementariFromJSON(json['informazioniSupplementari']),
        'fscrittureContabili': !exists(json, 'fscrittureContabili') ? undefined : json['fscrittureContabili'],
    };
}

export function InfoSedeToJSON(value?: InfoSede | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'telefono': TelefonoToJSON(value.telefono),
        'telex': value.telex,
        'telefax': TelefaxToJSON(value.telefax),
        'indirizzoPostaCertificata': IndirizzoPostaCertificataToJSON(value.indirizzoPostaCertificata),
        'sitoInternet': value.sitoInternet,
        'email': value.email,
        'legalMail': value.legalMail,
        'altreFunzioniSede': value.altreFunzioniSede,
        'datiIscrizioneReaRd': DatiIscrizioneReaRdToJSON(value.datiIscrizioneReaRd),
        'personaGiuridicaPrivata': PersonaGiuridicaPrivataToJSON(value.personaGiuridicaPrivata),
        'sedeFuoriProvincia': SedeFuoriProvinciaToJSON(value.sedeFuoriProvincia),
        'partitaIva': PartitaIvaToJSON(value.partitaIva),
        'gruppoIva': GruppoIvaToJSON(value.gruppoIva),
        'codiceLei': CodiceLeiToJSON(value.codiceLei),
        'insegna': value.insegna,
        'euid': EuidToJSON(value.euid),
        'sedeSecondariaRs': SedeSecondariaRsToJSON(value.sedeSecondariaRs),
        'provenienzaTrasferimento': ProvenienzaTrasferimentoToJSON(value.provenienzaTrasferimento),
        'informazioniSupplementari': InformazioniSupplementariToJSON(value.informazioniSupplementari),
        'fscrittureContabili': value.fscrittureContabili,
    };
}


