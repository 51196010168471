/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocietaQuotata
 */
export interface SocietaQuotata {
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    annoDal?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    annoAl?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    mercato?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    fonte?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    dtUltimoAggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    dtUltimoDepositoEs?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietaQuotata
     */
    cfonte?: string;
}

export function SocietaQuotataFromJSON(json: any): SocietaQuotata {
    return SocietaQuotataFromJSONTyped(json, false);
}

export function SocietaQuotataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietaQuotata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annoDal': !exists(json, 'annoDal') ? undefined : json['annoDal'],
        'annoAl': !exists(json, 'annoAl') ? undefined : json['annoAl'],
        'mercato': !exists(json, 'mercato') ? undefined : json['mercato'],
        'fonte': !exists(json, 'fonte') ? undefined : json['fonte'],
        'dtUltimoAggiornamento': !exists(json, 'dtUltimoAggiornamento') ? undefined : json['dtUltimoAggiornamento'],
        'dtUltimoDepositoEs': !exists(json, 'dtUltimoDepositoEs') ? undefined : json['dtUltimoDepositoEs'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function SocietaQuotataToJSON(value?: SocietaQuotata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annoDal': value.annoDal,
        'annoAl': value.annoAl,
        'mercato': value.mercato,
        'fonte': value.fonte,
        'dtUltimoAggiornamento': value.dtUltimoAggiornamento,
        'dtUltimoDepositoEs': value.dtUltimoDepositoEs,
        'cfonte': value.cfonte,
    };
}


