/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DomicilioFiscale,
    DomicilioFiscaleFromJSON,
    DomicilioFiscaleFromJSONTyped,
    DomicilioFiscaleToJSON,
    EstremiNascita,
    EstremiNascitaFromJSON,
    EstremiNascitaFromJSONTyped,
    EstremiNascitaToJSON,
    TitoliOnorifici,
    TitoliOnorificiFromJSON,
    TitoliOnorificiFromJSONTyped,
    TitoliOnorificiToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonaFisica
 */
export interface PersonaFisica {
    /**
     * 
     * @type {EstremiNascita}
     * @memberof PersonaFisica
     */
    estremiNascita?: EstremiNascita;
    /**
     * 
     * @type {DomicilioFiscale}
     * @memberof PersonaFisica
     */
    domicilioFiscale?: DomicilioFiscale;
    /**
     * 
     * @type {TitoliOnorifici}
     * @memberof PersonaFisica
     */
    titoliOnorifici?: TitoliOnorifici;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    sesso?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    cittadinanza?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    capacitaDiAgire?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    titoloStudio?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    precedenteOccupazione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    ccittadinanza?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    ccapacitaDiAgire?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    ctitoloStudio?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaFisica
     */
    cprecedenteOccupazione?: string;
}

export function PersonaFisicaFromJSON(json: any): PersonaFisica {
    return PersonaFisicaFromJSONTyped(json, false);
}

export function PersonaFisicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaFisica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiNascita': !exists(json, 'estremiNascita') ? undefined : EstremiNascitaFromJSON(json['estremiNascita']),
        'domicilioFiscale': !exists(json, 'domicilioFiscale') ? undefined : DomicilioFiscaleFromJSON(json['domicilioFiscale']),
        'titoliOnorifici': !exists(json, 'titoliOnorifici') ? undefined : TitoliOnorificiFromJSON(json['titoliOnorifici']),
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'sesso': !exists(json, 'sesso') ? undefined : json['sesso'],
        'cittadinanza': !exists(json, 'cittadinanza') ? undefined : json['cittadinanza'],
        'capacitaDiAgire': !exists(json, 'capacitaDiAgire') ? undefined : json['capacitaDiAgire'],
        'titoloStudio': !exists(json, 'titoloStudio') ? undefined : json['titoloStudio'],
        'precedenteOccupazione': !exists(json, 'precedenteOccupazione') ? undefined : json['precedenteOccupazione'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'ccittadinanza': !exists(json, 'ccittadinanza') ? undefined : json['ccittadinanza'],
        'ccapacitaDiAgire': !exists(json, 'ccapacitaDiAgire') ? undefined : json['ccapacitaDiAgire'],
        'ctitoloStudio': !exists(json, 'ctitoloStudio') ? undefined : json['ctitoloStudio'],
        'cprecedenteOccupazione': !exists(json, 'cprecedenteOccupazione') ? undefined : json['cprecedenteOccupazione'],
    };
}

export function PersonaFisicaToJSON(value?: PersonaFisica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiNascita': EstremiNascitaToJSON(value.estremiNascita),
        'domicilioFiscale': DomicilioFiscaleToJSON(value.domicilioFiscale),
        'titoliOnorifici': TitoliOnorificiToJSON(value.titoliOnorifici),
        'cognome': value.cognome,
        'nome': value.nome,
        'sesso': value.sesso,
        'cittadinanza': value.cittadinanza,
        'capacitaDiAgire': value.capacitaDiAgire,
        'titoloStudio': value.titoloStudio,
        'precedenteOccupazione': value.precedenteOccupazione,
        'cfiscale': value.cfiscale,
        'ccittadinanza': value.ccittadinanza,
        'ccapacitaDiAgire': value.ccapacitaDiAgire,
        'ctitoloStudio': value.ctitoloStudio,
        'cprecedenteOccupazione': value.cprecedenteOccupazione,
    };
}


