import React, { Component } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Platform } from 'react-native';
import { RootStackScreenProps, RootTabScreenProps, State } from '../../../types';
import { SplashLogoScreen } from '../../navigation/SplashLogoScreen';
import CustomResearch from './ComponentResearch/CustomResearch';
import CustomSearches from './ComponentResearch/InputCustomSearches';
import Research from './ComponentResearch/Research';



let myresearch = {
  nace: '',
  province: [''],
  year: ''
}

export default function ResearchScreen({ navigation, route }: RootStackScreenProps<'ResearchScreen'>) {


  let myroute: State = {
    profile: route.params?.profile,
    key_search: route.params?.key_search ? route.params?.key_search : "Ferrero S.P.A",
    userRole: route.params?.userRole,
    listFavorite: route.params?.listFavorite,
  };

  const [seeResearch, setSeeResearch] = React.useState(false)
  return (
    <>
      {!seeResearch &&
        <Research
          onSearch={(seeResearch, route, research) => {
            myroute = route
            myresearch = research
            seeResearch != null ? setSeeResearch(seeResearch) : null
          }}
          route={myroute}
          navigation={navigation}
        />
      }
      {seeResearch &&
        <CustomResearch
          research={myresearch}
          navigation={navigation}
          onSearch={(seeResearch) => { seeResearch != null ? setSeeResearch(seeResearch) : null }}
          myroute={myroute}
        />
      }
    </>
  );
}


const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins',
  },
  button: {
    flex: 0.3,
    backgroundColor: '#222222',
    padding: 5,
    marginVertical: 10,
    justifyContent: 'center',
    borderRadius: 5,
    alignItems: 'center'
  },
  textInput: {
    flex: 0.7,
    backgroundColor: '#464646',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    marginVertical: 10,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 0.2,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    alignSelf: 'flex-start',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    marginVertical: 30,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
