import React from "react";
import { StyleSheet, ScrollView, TextInput, TouchableOpacity, View, Text, StatusBar } from "react-native";
import Collapsible from "react-native-collapsible";
import Icon from "../../Tools/Icon";



export default function DataTop3(props: { top3?: { name: string, province: string, r: string, e: string, p: string }[] }) {

    const [refresh, setRefresh] = React.useState(false);
    let index: number = 0;
    return (
        <View style={styles.tabContainer}>
            <Text style={styles.tabTitle}>
                Top 3
            </Text>
            <View style={styles.delimiter} />
            {props.top3 && props.top3?.map((top3, index) => {
                return (
                    <View key={index.toString()} style={[styles.row, { backgroundColor: index % 2 == 0 ? '#393939' : '#2B2B2B' }]}>
                        <View style={{ flex: 0.25 }}>
                            <Text style={[styles.textStart, { textAlign: 'left' }]}>
                                {(props.top3?.indexOf(top3) == undefined ? null :
                                    props.top3.indexOf(top3) + 1) + '. ' + top3.name}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 0.1 }}>
                            <Text style={[styles.data, { textAlign: 'left' }]}>
                                {' ' + top3.province}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 0.2 }}>
                            <View style={{ alignSelf: 'center' }}>
                                <Icon name={'circle'} color={"#00CC83"} />
                            </View>
                            <Text style={[styles.data, { textAlign: 'left' }]}>
                                {' ' + top3.r}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 0.2 }}>
                            <View style={{ alignSelf: 'center' }}>
                                <Icon name={'circle'} color={"#00CC83"} />
                            </View>
                            <Text style={[styles.data, { textAlign: 'left' }]}>
                                {' ' + top3.e}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 0.2, }}>
                            <View style={{ alignSelf: 'center' }}>
                                <Icon name={'circle'} color={"#00CC83"} />
                            </View>
                            <Text style={[styles.data, { textAlign: 'left' }]}>
                                {" " + top3.p}
                            </Text>
                        </View>
                    </View>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        paddingLeft: 15,
        paddingVertical: 5,
        justifyContent: 'space-between'
    },
    delimiter: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#494949',
        marginBottom: 5

    },
    textStart: {
        color: 'white',
        paddingVertical: 5
    },
    data: {
        color: 'white',
        fontSize: 11,
        paddingVertical: 5,
        alignSelf: 'center'
    },
    tabContainer: {
        backgroundColor: '#2B2B2B',
        marginBottom: 20,
        paddingBottom: 5,
        borderRadius: 5,
    },
    tabTitle: {
        alignSelf: 'flex-start',
        fontSize: 15,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    }
})