// 
// 
//
import React from 'react';
import { StyleSheet, View, TouchableOpacity, Text, Platform } from 'react-native';
import { i18n } from '../../../i18n/i18n'

import Icon from '../../Tools/Icon';



export default function FilterResearch(props: {
  research: {
    nace: string,
    province: string[],
    year: string
  },
}) {

  let customResearch = {
    nace: props.research.nace,
    province: props.research.province,
    year: props.research.year
  }

  const [refresh, setRefresh] = React.useState(false)
  const [viewNace, setNace] = React.useState(customResearch.nace != '')
  const [viewProvince, setProvince] = React.useState(customResearch.province.length > 1)
  const [viewYear, setYear] = React.useState(customResearch.year != '')
  return (
    <View style={styles.containerResearch}>
      <Text style={styles.titleResearch}>
        {i18n.t('screens.research.title_custom')}
      </Text>
      {viewNace && <Text style={styles.subtitleResearch}>
        {i18n.t('screens.research.title_ateco')}
      </Text>}
      {viewNace && <View style={styles.filtro}>
        <Text style={styles.textFiltro}>
          {customResearch.nace}
        </Text>
        <TouchableOpacity
          style={styles.buttonFiltro}
          onPress={() => { customResearch.nace = ''; setNace(!viewNace) }}
        >
          <Icon name={'remove'} size={10} color={'#FF4D4D'} />
        </TouchableOpacity>
      </View>}
      {viewProvince && <Text style={styles.subtitleResearch}>
        {i18n.t('screens.research.title_province')}
      </Text>}
      <View style={{ flexDirection: 'row' }}>
        {viewProvince &&
          customResearch.province.map((province) => {
            return (
              <View key={province}>
                {province != '' && <View style={styles.filtro}>
                  <Text style={styles.textFiltro}>
                    {province}
                  </Text>
                  <TouchableOpacity
                    style={styles.buttonFiltro}
                    onPress={() => {
                      customResearch.province.splice(customResearch.province.indexOf(province), 1)
                      customResearch.province.length > 1 ? setRefresh(!refresh) : setProvince(!viewProvince)
                    }}
                  >
                    <Icon name={'remove'} size={10} color={'#FF4D4D'} />
                  </TouchableOpacity>
                </View>}
              </View>
            )
          })}
      </View>
      {viewYear && <Text style={styles.subtitleResearch}>
        {i18n.t('screens.research.title_year')}
      </Text>}
      {viewYear && <View style={styles.filtro}>
        <Text style={styles.textFiltro}>
          {customResearch.year}
        </Text>
        <TouchableOpacity
          style={styles.buttonFiltro}
          onPress={() => { customResearch.year = ''; setYear(!viewYear) }}
        >
          <Icon name={'remove'} size={10} color={'#FF4D4D'} />
        </TouchableOpacity>
      </View>}

    </View>
  )
}

const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins',
  },
  filtro: {
    marginLeft: 5,
    padding: 5,
    borderRadius: 20,
    flexDirection: 'row',
    backgroundColor: '#222222',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  textFiltro: {
    padding: 3,
    fontSize: 10,
    fontFamily: 'poppins',
    color: 'white',
    justifyContent: 'center',
    width: 'auto'
  },
  buttonFiltro: {
    padding: 3,
    width: 'auto'
  },
  containerResearch: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  labelAzienda: {
    padding: 10,
    flex: 1,
    marginVertical: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  subtitleResearch: {
    alignSelf: 'flex-start',
    fontSize: 12,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  goBack: {
    marginRight: 20
  },
  button: {
    flex: 0.3,
    backgroundColor: '#222222',
    padding: 5,
    marginVertical: 10,
    justifyContent: 'center',
    borderRadius: 5,
    alignItems: 'center'
  },
  titleResearch: {
    alignSelf: 'flex-start',
    fontSize: 15,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  textInput: {
    flex: 0.7,
    backgroundColor: '#464646',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    marginVertical: 10,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 0.2,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    textAlign: 'left',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
