import { Company, CompanyData, MarketCompetitor, MarketCompetitorResponseDTO } from "@services/apis/generated";
import React, { LegacyRef, useEffect } from "react";
import { StyleSheet, ScrollView, TextInput, TouchableOpacity, View, Text, StatusBar } from "react-native";
import Collapsible from "react-native-collapsible";
import { CompetitorsList } from "../../../../../types";
import { i18n } from "../../../../i18n/i18n";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import AddNewMarketCompetitor from "./AddNewMarketCompetitor";
import CompetitorList from "./CompetitorList";



export default function CollapsibleMarketCompetitors(props: { competitors?: MarketCompetitorResponseDTO[], goToCompany: (company: Company) => void, company: Company }) {

    const [deleteCompetitors, setDeleteCompetitors] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [seeMore, setSeeMore] = React.useState(true);
    const [competitorsVisible, setCompetitorsVisible] = React.useState<boolean>(false);

    const sharedContext = React.useContext(SharedContext)
    var TxtInp: TextInput | null;

    useEffect(() => {
        if (props.competitors) {
            setCompetitorsVisible(props.competitors.length > 0)
        }
        return () => { }
    }, [props.competitors])


    function addCompetitorToList() {
        const Competitor = {
            nameCorporate: '',
            r: "10000",
            e: '1100',
            p: '23456',
        }
        return (
            <View style={{ flexDirection: "row" }}>
                <TextInput
                    ref={(ref) => { TxtInp = ref }}
                    placeholder={i18n.t('buttons.placeholder_search')}
                    placeholderTextColor='#737373'
                    onChangeText={(name) => Competitor.nameCorporate = name}
                    style={styles.textInputRow}
                />
                <TouchableOpacity
                    style={styles.buttonAdd}
                    activeOpacity={0.5}
                    onPress={() => {
                        TxtInp?.clear(),
                            setRefresh(!refresh)
                    }
                    }
                >
                    <Text style={styles.textAdd}>
                        {i18n.t('buttons.add')}
                    </Text>
                </TouchableOpacity>
            </View>
        )
    }

    let index: number = 0;
    return (
        <View style={styles.contentAccordion}>
            <View
                style={{
                    marginBottom: 10,
                    borderBottomColor: '#494949',
                    borderBottomWidth: 1,
                }}
            />
            {competitorsVisible &&
                <CompetitorList company={props.company} competitors={props.competitors} goToCompany={(company) => props.goToCompany(company)} deleteCompetitors={deleteCompetitors} />
            }

            <Collapsible
                duration={1000}
                collapsed={seeMore}
                style={styles.contentAccordion}
            >
                <AddNewMarketCompetitor company={props.company} competitors={props.competitors} />
            </Collapsible>
            <View
                style={{
                    paddingBottom: 5,
                    borderBottomColor: '#808080',
                    borderBottomWidth: 2,
                }}
            />
            <View style={styles.rowButton}>
                <TouchableOpacity
                    disabled={!competitorsVisible}
                    style={[styles.buttonSeeMore]}
                    onPress={() => { setDeleteCompetitors(!deleteCompetitors) }}
                >
                    <Text style={[styles.textSeeMorelf, !competitorsVisible ? {color: "grey"} : null]}>
                        {i18n.t("components.market_competitors.manage_competitors")}
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    style={styles.buttonSeeMore}
                    onPress={() => { setSeeMore(!seeMore) }}
                >
                    <Text style={styles.textSeeMore}>
                        {!seeMore ? i18n.t('buttons.see_less') : i18n.t('buttons.see_more')}
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginLeft: 15,
        paddingVertical: 5,
        justifyContent: 'space-between'
    },
    rowButton: {
        flexDirection: 'row',
    },
    buttonSeeMore: {
        flex: 0.5,
        alignSelf: 'center',
    },
    textSeeMorelf: {
        color: '#00CC83',
        padding: 13,
        fontFamily: 'poppins',
        fontSize: 12,
    },
    textSeeMore: {
        color: '#00CC83',
        padding: 10,
        marginHorizontal: 10,
        textAlign: "right",
        fontFamily: 'poppins',
        fontSize: 12,
        textDecorationLine: 'underline',
    },
    contentAccordion: {
        height: 'auto',
        flexDirection: "column",
    },
    textStart: {
        color: 'white',
        paddingVertical: 5
    },
    data: {
        color: 'white',
        fontSize: 11,
        paddingVertical: 5,
        alignSelf: 'center'
    },
    deleteIcon: {
        justifyContent: 'flex-end'
    },
    textInputRow: {
        flex: 0.7,
        backgroundColor: '#464646',
        padding: 8,
        borderRadius: 5,
        fontSize: 12,
        color: 'white',
        margin: 15,
        fontFamily: 'poppins',
    },
    buttonAdd: {
        flex: 0.25,
        backgroundColor: '#222222',
        padding: 8,
        marginVertical: 15,
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    textAdd: {
        flex: 1,
        textAlignVertical: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 12,
        color: 'white',
    },
})