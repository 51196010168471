/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CertificatoQualita
 */
export interface CertificatoQualita {
    /**
     * 
     * @type {string}
     * @memberof CertificatoQualita
     */
    denominazioneOdc?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificatoQualita
     */
    dtScadenza?: string;
}

export function CertificatoQualitaFromJSON(json: any): CertificatoQualita {
    return CertificatoQualitaFromJSONTyped(json, false);
}

export function CertificatoQualitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificatoQualita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominazioneOdc': !exists(json, 'denominazioneOdc') ? undefined : json['denominazioneOdc'],
        'dtScadenza': !exists(json, 'dtScadenza') ? undefined : json['dtScadenza'],
    };
}

export function CertificatoQualitaToJSON(value?: CertificatoQualita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominazioneOdc': value.denominazioneOdc,
        'dtScadenza': value.dtScadenza,
    };
}


