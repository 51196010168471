/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BalanceXbrlDTO, BalanceXbrlDTOCurrentMoreFieldsStateEnum, BalanceXbrlDTOTypeEnum, Company, CompanyBalanceSheet, CompanyBalanceSheetCurrentMoreFieldsStateEnum, CompanyBalanceSheetTypeEnum, CompanyData, UserCompanyRoleEnum, UserExtended, UserExtendedTypeEnum } from './app/services/apis/generated';

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList { }
  }
}
export type Profile = {
  id?: number,
  firstName?: string,
  userRole?: UserExtendedTypeEnum,
  companyRole?: UserCompanyRoleEnum,
  address?: string,
  city?: string,
  postalCode?: string,
  email?: string,
  password?: string,
  lastName?: string,
  companyName?: string,
  vatNumber?: string,
  sector?: string,
  phone?: string,
  corporateRole?: string
}
export type Table = {
  labelTable: string[],
  dataTable: string[][][],
}[];
export type CompetitorsList = {
  nameCorporate: string,
  r: string,
  e: string,
  p: string,
}[]
export type CorporateList = {
  name: string,
  financialScore: string,
  businessScore: string,
  hykeeScore: string,
}[]

export type NoticeInterface = {
  title: string,
  sector?: string,
  description: string,
  data: string,
  likeCount?: number,
  dislikeCount?: number,
  preference?: Preference,
  read?: boolean,
}

export enum Preference {
  Like = 'LIKE',
  Dislike = 'DISLIKE',
}

export enum InsightEnum {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
}

export type State = {
  profiles?: Profile[]
  profile?: Profile
  key_search?: string
  userRole?: UserExtendedTypeEnum
  arrowBack?: boolean
  availableCredits?: string
  usedCredits?: string
  webSite?: string | null
  competitors?: CompetitorsList
  listFavorite?: CorporateList
  strategicPositioning?: string[][]
  exploreFinancialStatements?: Table
}

export type CompanyType = {
  company: Company
  companyData?: CompanyData
}
export type UserExtendedType = {
  userType: UserExtendedTypeEnum
}
export type RootStackParamList = {
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  Modal: undefined;
  NotFound: undefined;
  Registration: UserExtendedType;
  Login: UserExtendedType;
  ResetPassword: any;
  ActivateAccount: any;
  UserManagement: any;
  Profile: State;
  Credits: State;
  SavedCompaniesScreen: UserExtendedType;
  ResearchScreen: State;
  BottomTabNavigator: UserExtended;
  Search: any;
  SearchScreen: any;
  SearchScreenDashboard: any;
  DashboardScreen: UserExtended & CompanyType;
  DashboardCompany: any;
  DashboardConsultant: any;
  DashboardSavedCompany: Number;
  DashboardCompanySearched: Company;
  SplashScreen: State;
  SelfAssessment: any;
  Survey: any;
  BusinessRating: any;
  Wallet: any;
  PlanCredits: any;
  PlanResearch: any;
  ProfileData: any;
  CompanyData: any;
  PlansData: any;
  WalletData: any;
  ProfileScreen: State;
  ConsultancyData: any;
  PartnerData: any;
  SavedCompanies: any;
  SavedPartner: any;
  LinkedCompaniesData: any;
  PendingRequest: any
  CreditsNavigator: any;
  PartnerProgramScreen: any;
  AddNewPartnerProgram: any,
  AdministratorScreen: any;
  PartnerScreen: any;
  SharesScreen: any;
  Auth: any;
  CompanyMicro: any;
  NoticeHome: any;
  NoticeDetail: any;
  PartnerProgramList: any;
  NotificationPage: any;
  InsightHome: any;
  InsightDetail: any;
  PartnerProgramList: any;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  Screen
>;

export type RootTabParamList = {
  TabOne: undefined;
  TabTwo: undefined;
  Login: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<RootTabParamList, Screen>,
  NativeStackScreenProps<RootStackParamList>
>;

export interface Balance {
  year: number,
  data: BalanceXbrlDTO
}

export interface Header {
  headerName: string,
  headerValues: number[]
}

export type RowObject = {
  [x: number]: number | BalanceXbrlDTOTypeEnum | BalanceXbrlDTOCurrentMoreFieldsStateEnum | undefined;
}

export interface BalanceData {
  key: keyof BalanceXbrlDTO,
  data: RowObject[],
  state: BalanceXbrlDTOCurrentMoreFieldsStateEnum | undefined,
}

export type FieldsToBeShownObjectType = {
  key: string,
  hasEuroSign?: boolean,
  isPercentage?: boolean,
  bold?: boolean,
  italic?: boolean,
  hasXIndicator?: boolean,
  wideRow?: boolean,
  hasDays?: boolean,
  hasTooltip?: boolean,
  hasDecimal?: boolean,
}

export interface DownloadedBalance {
  anno: number
  type: BalanceXbrlDTOTypeEnum
  currentMoreFieldsState: BalanceXbrlDTOCurrentMoreFieldsStateEnum | undefined
  spHykeeCreditiCommerciali?: number
  spHykeeDebitiCommerciali?: number
  spHykeeDebitiFinanziari?: number
}

export interface DownloadedBalanceWithoutFinancialData {
  anno: number
  type: CompanyBalanceSheetTypeEnum
  currentMoreFieldsState: CompanyBalanceSheetCurrentMoreFieldsStateEnum | undefined
  spHykeeCreditiCommerciali?: number
  spHykeeDebitiCommerciali?: number
  spHykeeDebitiFinanziari?: number
}

export interface BalanceObj {
  lastBalance?: CompanyBalanceSheet,
  downloadedBalances?: DownloadedBalanceWithoutFinancialData[]
}

export type CriteriaType = {
  nameCriteria: string,
  dataCriteria: Array<string>,
  multipleSelect?: boolean,
}

export type CriteriaSelected = {
  nameCriteria: string,
  criteriaSelected: string,
}