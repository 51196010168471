/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Apparecchi,
    ApparecchiFromJSON,
    ApparecchiFromJSONTyped,
    ApparecchiToJSON,
    CaratteristicheImpianto1,
    CaratteristicheImpianto1FromJSON,
    CaratteristicheImpianto1FromJSONTyped,
    CaratteristicheImpianto1ToJSON,
    CaratteristicheImpianto2,
    CaratteristicheImpianto2FromJSON,
    CaratteristicheImpianto2FromJSONTyped,
    CaratteristicheImpianto2ToJSON,
} from './';

/**
 * 
 * @export
 * @interface Panificatori
 */
export interface Panificatori {
    /**
     * 
     * @type {CaratteristicheImpianto1}
     * @memberof Panificatori
     */
    caratteristicheImpianto1?: CaratteristicheImpianto1;
    /**
     * 
     * @type {CaratteristicheImpianto2}
     * @memberof Panificatori
     */
    caratteristicheImpianto2?: CaratteristicheImpianto2;
    /**
     * 
     * @type {Apparecchi}
     * @memberof Panificatori
     */
    apparecchi?: Apparecchi;
}

export function PanificatoriFromJSON(json: any): Panificatori {
    return PanificatoriFromJSONTyped(json, false);
}

export function PanificatoriFromJSONTyped(json: any, ignoreDiscriminator: boolean): Panificatori {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caratteristicheImpianto1': !exists(json, 'caratteristicheImpianto1') ? undefined : CaratteristicheImpianto1FromJSON(json['caratteristicheImpianto1']),
        'caratteristicheImpianto2': !exists(json, 'caratteristicheImpianto2') ? undefined : CaratteristicheImpianto2FromJSON(json['caratteristicheImpianto2']),
        'apparecchi': !exists(json, 'apparecchi') ? undefined : ApparecchiFromJSON(json['apparecchi']),
    };
}

export function PanificatoriToJSON(value?: Panificatori | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caratteristicheImpianto1': CaratteristicheImpianto1ToJSON(value.caratteristicheImpianto1),
        'caratteristicheImpianto2': CaratteristicheImpianto2ToJSON(value.caratteristicheImpianto2),
        'apparecchi': ApparecchiToJSON(value.apparecchi),
    };
}


