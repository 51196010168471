/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VariazioneFormaGiuridica,
    VariazioneFormaGiuridicaFromJSON,
    VariazioneFormaGiuridicaFromJSONTyped,
    VariazioneFormaGiuridicaToJSON,
} from './';

/**
 * 
 * @export
 * @interface VariazioniFormaGiuridica
 */
export interface VariazioniFormaGiuridica {
    /**
     * 
     * @type {Array<VariazioneFormaGiuridica>}
     * @memberof VariazioniFormaGiuridica
     */
    variazioneFormaGiuridica: Array<VariazioneFormaGiuridica>;
}

export function VariazioniFormaGiuridicaFromJSON(json: any): VariazioniFormaGiuridica {
    return VariazioniFormaGiuridicaFromJSONTyped(json, false);
}

export function VariazioniFormaGiuridicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariazioniFormaGiuridica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variazioneFormaGiuridica': ((json['variazioneFormaGiuridica'] as Array<any>).map(VariazioneFormaGiuridicaFromJSON)),
    };
}

export function VariazioniFormaGiuridicaToJSON(value?: VariazioniFormaGiuridica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variazioneFormaGiuridica': ((value.variazioneFormaGiuridica as Array<any>).map(VariazioneFormaGiuridicaToJSON)),
    };
}


