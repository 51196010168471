/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DichiarazioneStartUp,
    DichiarazioneStartUpFromJSON,
    DichiarazioneStartUpFromJSONTyped,
    DichiarazioneStartUpToJSON,
} from './';

/**
 * 
 * @export
 * @interface DichiarazioniStartUp
 */
export interface DichiarazioniStartUp {
    /**
     * 
     * @type {Array<DichiarazioneStartUp>}
     * @memberof DichiarazioniStartUp
     */
    dichiarazioneStartUp: Array<DichiarazioneStartUp>;
}

export function DichiarazioniStartUpFromJSON(json: any): DichiarazioniStartUp {
    return DichiarazioniStartUpFromJSONTyped(json, false);
}

export function DichiarazioniStartUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): DichiarazioniStartUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dichiarazioneStartUp': ((json['dichiarazioneStartUp'] as Array<any>).map(DichiarazioneStartUpFromJSON)),
    };
}

export function DichiarazioniStartUpToJSON(value?: DichiarazioniStartUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dichiarazioneStartUp': ((value.dichiarazioneStartUp as Array<any>).map(DichiarazioneStartUpToJSON)),
    };
}


