// 
// 
//
import { NavigationHelper } from '@helpers/NavigationHelper';
import Icon from '@screens/Tools/Icon';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Linking, Platform, ScrollView, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import CreditsSvg from '../../../../assets/svg/CreditsSvg';
import { RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import AvailableCredits from './AvailableCredits';
import AvaiableResearch from './AvailableResearch';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import { BackgroundColorButtonGreen, ColorTextLightGreen } from '@components/ColorTheme';

export default function PlanResearch({ navigation, route }: RootStackScreenProps<'PlanResearch'>) {

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >

            <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>
                    {i18n.t('components.research.research')}
                </Text>
            </View>
            <ScrollView style={hykeeStyle.containerScroll}>
                <View style={styles.container}>
                    <View style={styles.contentContainer}>
                        <View style={styles.contentTextContainer}>
                            <Text style={styles.contentText}>
                                {i18n.t('components.research.active_the_plus_plan')}
                            </Text>
                        </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.activePlan} onPress={() => Linking.openURL('http://rbhq.it')}>
                                <Text style={styles.buttonTextContainer}>
                                    {i18n.t('components.research.buttons.active_the_plus_plan')}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={styles.contentContainer}>
                        <View style={styles.contentTextContainer}>
                            <Text style={styles.contentText}>
                                {i18n.t('components.research.try_easy_plan')}
                            </Text>
                        </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.activePlan} onPress={() => Linking.openURL('http://rbhq.it')}>
                                <Text style={styles.buttonTextContainer}>
                                    {i18n.t('components.research.buttons.active_the_easy_plan')}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </ScrollView>
        </LinearGradient>
    );

}

const styles = StyleSheet.create({
    containerScrollView: {
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        padding: 20,
        flexDirection: 'column',
        fontFamily: 'poppins',
        justifyContent: 'space-evenly'
    },
    contentLabel: {
        marginBottom: 10
    },
    contentTitle: {
        height: '10%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    goBack: {
        paddingRight: 10
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 40
    },
    contentContainerButton: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentTextContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentText: {
        color: 'white',
        fontSize: 18,
        paddingBottom: 10,
        fontFamily: 'poppins'
    },
    buttonContainer: {
        flexDirection: 'column',
    },
    activePlan: {
        backgroundColor: BackgroundColorButtonGreen(),
        alignItems: 'center',
        borderRadius: 10,
    },
    buttonTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        fontSize: 18,
        color: 'white',
        fontFamily: 'poppins'
    },
    title: {
        alignSelf: 'center',
        color: '#00CC83',
        fontSize: 20,
        fontFamily: 'poppins'
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
});