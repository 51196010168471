import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import hykeeStyle from "@components/hykeeStyle";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { Insight } from "@services/apis/generated";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleSheet, ScrollView, TouchableOpacity, View, Text, StatusBar, Image } from "react-native";
import Collapsible from "react-native-collapsible";
import Colors from "../../../../constants/Colors";
import { NoticeInterface } from "../../../../types";
import { i18n } from "../../../i18n/i18n";
import Icon from "../../Tools/Icon";
import InsightComponent from "../ComponentProfile/Insights/InsightComponent";



export default function InsightNews(props: { insights: Insight[], navigation: any }) {

    const [collapsed, setCollapsed] = React.useState<boolean>(true);
    const [collapsibleAnimationEnd, setcollapsibleAnimationEnd] = React.useState<boolean>(false);

    const toggleExpanded = () => {
        setCollapsed(!collapsed);
        setcollapsibleAnimationEnd(false);
    };

    const goToInsightHome = () => {
        NavigationHelper.navigateToInsightHome(props.navigation);
    }

    const getInsights = () => {
        
    }


    return (
        <View style={[styles.label, { backgroundColor: (collapsed ? '#222222' : '#292929') }]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style={styles.textLabel}>
                        {i18n.t('components.insight.title')}
                    </Text>
                    <Icon name={collapsed ? "angle-down" : "angle-up"} color="white" />
                </View>
            </TouchableOpacity>



            <Collapsible
                duration={1000}
                collapsed={collapsed}
                style={styles.contentAccordion}
                enablePointerEvents
                onAnimationEnd={() => { setcollapsibleAnimationEnd(true) }}
            >
                {props.insights &&
                    <ScrollView style={[hykeeStyle.containerScroll, {paddingBottom: 20}]}>
                        {/**TODO: sort */}
                        {props.insights && props.insights.slice(0,2).map((insight, idx) => {
                            return (
                                <View key={idx} style={styles.insightContainer}>
                                    <InsightComponent insight={insight} navigation={props.navigation}/>
                                </View>
                            )
                        })}
                        <TouchableOpacity style={styles.buttonContainer} onPress={goToInsightHome}>
                            <Text style={styles.buttonTextContainer}>
                                {i18n.t('components.insight.see_all_the_news')}
                            </Text>
                        </TouchableOpacity>
                    </ScrollView>
                }
            </Collapsible>
        </View>
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
    },
    textLabel: {
        flex: 0.95,
        alignSelf: "flex-start",
        color: "white",
        marginLeft: 15,
        marginBottom: 10,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    contentAccordion: {
        color: "white",
        flexDirection: "column",
        height: 'auto',
    },
    label: {
        backgroundColor: "#222222",
        paddingTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        display: "flex",
    },
    labelText: {
        position: "relative",
        bottom: 100,
        marginBottom: -50
    },
    dateText: {
        color: 'white',
        fontSize: 10,
        fontFamily: 'poppins',
        marginLeft: 8
    },
    date: {
        flexDirection: 'row',
        marginLeft: 15,
        marginBottom: 5
    },
    insightText: {
        color: 'white',
        fontFamily: 'poppins',
        fontSize: 13,
        marginLeft: 15
    },
    title: {
        alignSelf: 'flex-start',
        fontSize: 20,
        paddingVertical: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    insightContainer: {
        flexDirection: 'column',
        paddingBottom: 10
    },
    buttonContainer: {
        backgroundColor: BackgroundColorButtonGreen(),
        alignItems: 'center',
        borderRadius: 10,
    },
    buttonTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        fontSize: 13,
        color: 'white',
        fontFamily: 'poppins',
    },
});