export const it = {
    login: {
        username: 'User',
        password: 'Password',
    },
    label: {
        welcome: 'Bentornato',
    },
    button: {
        confirm: 'Conferma',
        cancel: 'Annulla',
        ok: 'Ok',
    },
    foo: 'Fo it',
    bar: 'Bar it {{someValue}}',
    main_screen_description: 'Il sistema più evoluto per la gestione remota del check-in del tuo Bed & Breakfast',
    server_errors: {
        USER_ALREADY_REGISTERED_TO_COMPANY: "E' già presente un utente collegato alla compagnia avente la partita iva inserita",
        BLOCK_DATA_NOT_PRESENT: "I dati richiesti non sono presenti",
        BALANCE_DOES_NOT_EXIST: "Il Bilancio richiesto non esiste.",
        NO_CREDITS_AVAILABLE : "Credito esaurito.",
        CANNOT_DOWNLOAD_BLOCK : 'Impossibile scaricare il blocco.',
        ENTITY_NOT_FOUND: 'Entity non trovata.',
        CANNOT_ACTIVE_PLAN_AUTO_RENEW_IS_ACTIVATED: 'Impossibile attivare il piano, l\'auto rinnovo è attivo.',
        NO_SEARCHES_AVAILABLE: 'Nessuna ricerca disponibile.',
        NO_CONSULTATIONHOURS_AVAILABLE: 'Nessuna ora di consultazione disponibile.',
        ALREADY_ACTIVE_USER: 'Utente già attivo.',
        COMPANY_HAS_NO_SURVEYS: 'L\'Azienda non ha autovalutazioni.',
        NOT_ENOUGH_BALANCES_FOR_COMPANY_SCORE: 'Bilanci insufficienti per il punteggio dell\'azienda.',
        CANNOT_SEARCHES_YOUR_COMPANY:'Non puoi ricercare la tua stessa azienda.',
        VAT_NUMBER_NOT_FOUND:'Partita IVA non trovata',
        CANNOT_ADD_SAME_COMPANY: 'Non puoi aggiungere la stessa azienda come competitor',
        COMPETITOR_ALREADY_ADDED:'La compagnia che vuoi aggiungere alla lista dei competitor è già presente',
        NOTIFICATION_ALREADY_SENT:'La notifica è stata già inviata.',
        EMAIL_ALREADY_EXISTS: 'Attenzione, la mail inserita appartiene ad un utente esistente associato ad un’azienda diversa',
        failed_authentication:'Credenziali errate',
        user_not_activated:'L\'utente {{user}} non è stato attivato.',
        error_generic: 'Qualcosa è andato storto.',
        existing_device_imei: 'Questo dispositivo esiste già. Controlla l\'IMEI inserito.',
        existing_plan: 'Questo piano esiste già.',
        insufficient_credits: 'Non ci sono sufficienti crediti.',
    },
    components: {
        business_score: {
            title: 'Business Score',
            error: 'Compilare il Self Assessment per vedere il Business score e Hykee score',
            error_data_incomplete: "Non sono disponibili dati contabili per poter calcolare il Financial Score",
            error_search: 'Questo campo è oscurato per motivi di privacy o questa compagnia non ha completato il Self Assessment',
            send_request_SA:'Questa Azienda non ha compilato il Self Assessment, invia una notifica all\'Azienda.',
            send_request: 'Richiedi collegamento',
            show_state_request: 'Richiesta inviata, vedi stato',
            send_notify:'Vuoi inviare una notifica di invito a compilare il Self Assessment?',
            send_notify_success:'Notifica inviata con successo',
            info: "Il Business Score esprime la solidità del modello di business e l'attitudine a competere sul proprio mercato con agilità e velocità. È il risultato di algoritmi proprietari che prendono come riferimento le seguenti aree indagate dal Self-Assessment\n- Vision, mission e cultura aziendale\n- Innovazione di prodotto e di processo\n- Business model\n- Posizionamento di brand\n- Controllo di gestione",
            meaning: "Cosa Significa?"
        },
        hykee_score: {
            info: "L'Hykee Score esprime la valutazione complessiva dell'azienda correlando analisi quantitative ad analisi qualitative. Le prime rappresentate dal Financial Rating esprimono la forza economico-finanziaria dell'azienda, le seconde indagano la solidità del business model e la capacità di competere sul mercato con agilità, velocità di innovazione e attitudine alla crescita.",
            meaning: "Cosa Significa?",
        },
        hykeeFilter: {
            filter_category: "Scegli il filtro",
            filter_order: "Scegli il tipo di ordinamento"
        },
        hykeeList: {
            nextButton: "Avanti",
            previousButton: "Indietro"
        },
        financial_score:{
            title:'Financial Score',
            unverified:'  Non Verificato',
            verified:'  Verificato',
            show_data:'Vedi dati',
            enter_data:'Inserisci dati',
            incomplete_insert: 'I dati per calcolare lo score non sono completi, vuoi inserire i dati mancanti?',
            incomplete: 'I dati per calcolare lo score non sono completi.',
            info: "Il Financial Score esprime la forza economico-finanziaria dell'azienda ed è il risultato di algoritmi proprietari che prendono come riferimento le seguenti valutazioni:\n(i) condizioni di redditività aziendale\n(ii) solidità ed equilibrio patrimoniale\n(iii) capacità di creazione di valore correlando la redditività aziendale con la gestione del capitale investito\n(iv) capacità di onorare agli impegni finanziari di breve e medio-lungo termine",
            meaning: "Cosa Significa?"
        },
        link_companies_data: {
            status: 'Stato richiesta: ',
            button_deleted: 'Cancella',
            button_detail: 'Dettagli',
            button_accepted: 'Accetta',
            role: 'Ruolo offerto: ',
            title_pending: 'Richieste in attesa',
            title_accepted: 'Richieste accettate',
            title_rejected: 'Richieste rifiutate',
            no_pending: 'Nessuna richiesta in attesa',
            no_accepted: 'Nessuna richiesta accettata',
            no_rejected: 'Nessuna richiesta rifiutata',
            ACCEPTED: 'Accettata',
            REJECTED: 'Rifiutata',
            PENDING: 'In attesa',
            send_invitation_partner_program: "Invita al Partner Program"
        },
        partner_data: {
            status: 'Stato richiesta: ',
            request_pending: 'ha chiesto di collegarsi alla tua azienda',
            button_accepted: 'Accetta',
            button_rejected: 'Rifiuta',
            button_detail: 'spendi 1 ricerca per i dettagli',
        },
        partner_program:{
            description: 'Descrizione programma',
            program_description: 'Descrizione generale offerta di modulo plus del Partner per le aziende che attivano il modulo Partner',
            notice_list: 'Elenco comunicazioni',
            empty_list: 'Non ci sono comunicazioni salvate come bozza.',
            add_new: 'Aggiungi nuovo',
            draft: "Bozze",
            sent: "Inviate",
            date_of_publication: "Data di pubblicazione",
            filters: {
                date: "Data",
                ascending: "Crescente",
                descending: "Decrescente",
                city: "Città",
                bologna: "Bologna",
                milano: "Milano",
                roma: "Roma",
                torino: "Torino",
                genova: "Genova"
            },
            success_modal_text: 'Salvataggio effettuato con successo',
        },
        add_new_partner_program:{
           title: 'Aggiungi nuovo',
           first_paragraph: 'Titolo',
           title_placeholder: 'Titolo campo testo',
           description: 'Descrizione/Contenuto',
           description_placeholder: 'Descrizione servizio offerto',
           recipients: 'Destinatari',
           button_text_save: 'Salva',
           button_text_save_send: 'Salva e invia',
           button_text_delete: 'Elimina',
           text_modal_saved: 'Salvato in bozze con successo',
           text_modal_send: 'Salvato e inviato',
        },
        headers: {
            explore: 'Esplora prospetti di bilancio',
            market: 'Market & Competitors',
            business_rating: 'Valutazione Aziendale',
            strategic_positioning: 'Posizionamento strategico',
            digital_positioning: 'Posizionamento digitale',
            chamber_data: 'Dati Camerali',
        },
        strategic_positioning:{
            coming_soon:'Prossimamente...',
        },
        market_competitors: {
            info_plan: 'Tieni sempre sotto controllo le prestazioni dei tuoi concorrenti!',
            info_add_competitors: 'Aggiungi i tuoi competitor per tenerli monitorati!',
            button_plan: 'Attiva piano Plus',
            button_add: 'Aggiungi concorrente',
            confirm_add: 'Vuoi spendere 1 ricerca per aggiungere l\'azienda ai tuoi concorrenti?',
            error_company: 'L\' azienda scelta come concorrente è una delle tue aziende e non puoi aggiungerla come concorrente',
            company:'Competitors',
            revenue:'Ricavi',
            ebitda :'Ebitda %',
            leva:'Leva',
            financial_score:'Financial Score',
            manage_competitors: "Gestisci Competitors",
        },
        insight: {
            title: 'Comunicazioni',
            see_all_the_news: 'Vedi tutte le news dei Partner',
            no_insight: 'Nessuna comunicazione'
        },
        market: {
            title: 'Novità di mercato',
            data: '21 Ottobre 2021',
            infoTitle1: 'Ferrero acquista Rocher per 1.8M',
            info1: 'Sit lectus dictumst in vitae enim sed sed quam. Sit lectus dictumst in vitae enim sed sed quam.',
            infoTitle2: 'Produzione industriale in calo del 5%',
            info2: 'Sit lectus dictumst in vitae enim sed sed quam. Sit lectus dictumst in vitae enim sed sed quam.',
        },
        reservation_details: {
            mail_address: 'Indirizzo email',
            name: 'Nome',
            mobile: 'Cellulare',
            all_accommodations: 'Tutti gli Alloggi',
            selected_accommodations: '{{accommodations}} Alloggi',
        },
        plan_selection: {
            title: 'Piano',
            select_plan: 'Seleziona il piano',
            plan_label: '{{name}} {{credits}} crediti al mese',
            autorenew: 'Auto Rinnovo',
            service_expiry_date: 'Data di scadenza',
        },
        wallet: {
            wallet: 'Portafoglio',
            plan: 'Piano',
            active: 'attivo'
        },
        credits: {
            credits: 'Crediti',
            no_credit_available: 'Nessun credito disponibile',
            download_chamber_data: 'Vuoi scaricare dati camerali dei tuoi competitor, clienti e fornitori?',
            try_the_platform_features: 'Prova ora le funzionalità della piattaforma e utilizza 20 crediti per i tuoi download.',
            buttons:{
                active_the_easy_plan: 'Attiva il piano Easy'
            }
        },
        research: {
            research: 'Ricerche',
            no_research_available: 'Nessuna ricerca disponibile',
            active_the_plus_plan: 'Attiva il piano PLUS per poter usufruire di 10 ricerche mensili per analizzare i tuoi competitor, clienti e fornitori.',
            try_easy_plan: 'Vuoi esplorare Hykee? Prova ora la piattaforma con il piano EASY ed esplora tutte le sue funzionalità!',
            buttons:{
                active_the_easy_plan: 'Attiva il piano Easy',
                active_the_plus_plan: 'Attiva il piano Plus'
            }
        },
        chamber_data: {
            administrators: 'Amministratori',
            shares: 'Partecipazioni',
            partners: 'Soci',
            administrators_credits: '2 Crediti',
            showTab:'Mostra',
            shares_credits: '3 Crediti',
            partners_credits: '3 Crediti',
            confirm_add: 'Vuoi spendere {{credits}} per scaricare i dati camerali?',
        },
        notification_page: {
            title: 'Notifiche',
            self_assestment: 'Richiesta di compilare il questionario',
            link_request: 'Richiesta di collegamento',
            insight: 'Nuova comunicazione',
            from: 'di',
            by: 'da parte di'
        }
    },
    modal: {
        success_website: 'Sito aggiunto\n con successo',
        success_registration: 'Registrazione completata con successo, controlla la mail per completare l\'attivazione',
        remove_user: 'Vuoi rimuovere l\'utente ',
        remove_user2: ' dai tuoi Consulenti?',
    },
    sidebar: {
        dashboard: 'Dashboard',
        houses: 'Case',
        reservations: 'Prenotazioni',
        account: 'Account',
        doors: 'Porte',
        support: 'Supporto',
        credits: 'Crediti',
    },
    services: {
        public: 'Public',
        residential: 'Residential',
    },
    guests: {
        client: 'Cliente',
        collaborator: 'Collaboratore',
    },
    rows: {
        house: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        house_selection: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        accommodation: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        accommodations_selection: {
            caption: 'Seleziona Alloggio in',
        },
        door: {
            
            doors: 'Porte',
            credits_plan: 'Plan',
            add_residential_door: 'Aggiungi Porta Residential',
            add_public_door: 'Aggiungi Porta Public',
        },
        recap: {
            all_accommodations: 'Tutti gli alloggi',
            selected_accommodations: '{{accommodations}} Alloggi',
            from_date: 'Da',
            to_date: 'A',
        },
        credits: {
            credits: 'Crediti',
        },
    },
    busines_rating:{
        area:{
            area:'Area',
            product:'Prodotto',
            customer:'Cliente',
            businessmodel:'Modello di Business',
            culture:'Cultura',
            goalsvision:'Visione e Obbiettivi',
            market:'Mercato',
            financecheck:'Controllo Finanze',
            result:'Risultato',
        },
        agility:{
            agility:'Agilità',
            focus:'Focus',
            flexibility:'Flessibilità',
            velocity:'Velocità',
        },
        growth:{
            growth:'Crescita',
            asis:'So chi sono',
            tobe:'So chi vorrei essere',
            howto:'So come arrivarci',
        },
        attitude:{
            attitude:'Attitudine',
            operationalexcellence:'Eccellenza operativa',
            customerintimacy:'Confidenza con il cliente',
            productinnovation:'Innovazione del prodotto',
        }
    },
    screens: {
        balance_sheet: {
            tooltip: {
                ceHykeeServizi: "Il valore è comprensivo di \"costo per godimento beni di terzi\" (B8 Conto Economico)",
                ceHykeeMateriePrime: "Il valore è comprensivo della \"Variazione di rimanenze di materie prime, sussidiarie, di consumo e merci\" (B11 Conto Economico)",
                adjustments: "La sezione Adjustments presenta i valori della redditività aziendale in termini di EBITDA (EBITDA Adj.) ed EBIT (EBIT Adj.) al netto di voci di ricavo e costo normalmente non afferenti alla gestione caratteristica",
                spHykeeAltriCrediti: "Il valore è comprensivo dei:\n- crediti v/imprese collegate/controllate/controllanti/sottoposte al controllo delle controllanti\n- crediti tributari e previdenziali\n- crediti per imposte anticipate\n- ratei e risconti attivi",
                spHykeeAltriDebiti: "Il valore è comprensivo dei:\n- debiti per acconti\n- debiti v/imprese collegate/controllate/controllanti/sottoposte al controllo delle controllanti\n- debiti tributari\n- debiti v/istituti previdenziali\n- ratei e risconti passivi",
                spHykeeDebitiFinanziari: "Il valore è comprensivo di debiti finanziari a breve e medio-lungo termine così composti\n- debiti obbligazionari\n- debiti v/soci per finanziamenti\n- debiti v/banche\n- debiti v/altri finanziatori\n- Debiti rappresentati da titoli di credito",
                ratioHykeeROCE: "Indicatore che misura la capacità di creazione di valore economico dell'azienda. Ottenuto mediante rapporto tra EBIT e il Capitale Investito Netto (CIN). Lo scenario di ROCE pari a MAX riguarda i casi di aziende con un CIN negativo che esprime bassa capitalizzazione, ciclo commerciale molto positivo, bassa esposizione finanziaria o disponibilità liquide molto elevate.",
                rfHykeeVarCCON: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarAltriCrediti: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarAltriDebiti: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarFondiETFR: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarPatrimonioNetto: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarDebitiFinanziari: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
                rfHykeeVarAttivitaFinCorrenti: "In assenza di dato potrebbe essere necessario scaricare i bilanci degli anni precedenti per scoprire il Delta",
            },
            contoEconomico: "Conto Economico",
            statoPatrimoniale: "Stato Patrimoniale",
            rendicontoFinanziario: "Rendiconto Finanziario",
            ratio: "Ratios",
            adjustments: "Adjustments",
            days: "gg",
            no_balance_sheets: "Nessun risultato. Per la società cercata non risultano dati contabili disponibili.",
            confirm_download: 'Vuoi spendere 1 ricerca per scaricare altri bilanci?',
            no_credits:"Credito Insufficiente",
            no_balance:'Non esistono altri bilanci',
            loading: "Sto caricando i dati...",
            financial_statements: "Voce di bilancio",
            header_values_euros: "Valori in €",
            download_more: "Scarica altri bilanci",
            ceHykeeRicavi: "Ricavi",
            ceHykeeValoreDellaProduzione: "Valore della Produzione",
            ceHykeeMateriePrime: "Materie Prime",
            ceHykeeServizi: "Servizi",
            ceHykeePersonale: "Personale",
            ceHykeeOneriDiversiDiGestione: "Oneri diversi di gestione",
            ceHykeeEBITDA: "EBITDA",
            ceHykeeEBITDAPERCENTAGE: "EBITDA %",
            ceHykeeDA: "Ammortamenti, Acc. & Sval.",
            ceHykeeEBIT: "EBIT",
            ceHykeeOneriEProventiFinanziari: "Oneri e proventi Finanziari",
            ceHykeeRettificheFinanziarie: "Rettifiche Finanziarie",
            totaleRettificheValoreAttivitaPassivitaFinanziarie: "Rettifiche Finanziarie",
            ceHykeeEBT: "EBT",
            ceHykeeImposte: "Imposte",
            ceHykeeRisultatoEsercizio: "Risultato d'esercizio",
            ceAdjHykeeEBITDA: "EBITDA",
            ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione: "Adj. Saldo Altri Ricavi / Oneri diversi di gestione",
            ceAdjHykeeEBITDAAdj: "EBITDA Adj.",
            ceAdjHykeeDA: "Ammortamenti, Acc. & Sval.",
            ceAdjHykeeEBITAdj: "EBIT Adj.",
            spHykeeImmobilizzazioniImmateriali: "Imm. Immateriali",
            spHykeeImmobilizzazioniMateriali: "Imm. Materiali",
            spHykeeImmobilizzazioniFinanziarie: "Imm. Finanziarie",
            spHykeeAttivoFisso: "Attivo Fisso",
            spHykeeCreditiCommerciali: "Crediti Commerciali",
            spHykeeDebitiCommerciali: "Debiti Commerciali",
            spHykeeRimanenze: "Rimanenze",
            creditiEsigibiliEntroEsercizioSuccessivo: "Crediti Esigibili entro l'Esercizio Successivo",
            creditiEsigibiliOltreEsercizioSuccessivo: "Crediti Esigibili oltre l'Esercizio Successivo",
            totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni: "Totale Attività Finanziarie che non costituiscono Immobilizzazioni",
            spHykeeCCON: "CCON",
            spHykeeAltriCrediti: "Altri Crediti",
            spHykeeAltriDebiti: "Altri Debiti",
            spHykeeFondiETFR: "Fondi e TFR",
            debitiEsigibiliEntroEsercizioSuccessivoNegative: "Debiti Esigibili entro l'Esercizio Successivo",
            debitiEsigibiliOltreEsercizioSuccessivoNegative: "Debiti Esigibili oltre l'Esercizio Successivo",
            totalePassivo: "Totale Passivo",
            spHykeeCIN: "CIN",
            spHykeePatrimonioNetto: "Patrimonio Netto",
            spHykeeCapitaleSociale: "di cui capitale sociale",
            spHykeeDebitiFinanziari: "Debiti Finanziari",
            spHykeeLiquidita: "Disponibilità Liquide",
            totaleAttivo: "Totale Attivo",
            spHykeeAttivitaFinanziarie: "Attività Finanziarie Correnti",
            spHykeePFN: "PFN",
            spHykeeEplusPFN: "E+PFN",
            rfHykeeEBIT: "EBIT",
            rfHykeeAmmortamentiESvalutazioni: "Ammortamenti, Acc. & Sval.",
            rfHykeeRettificheAttivitaFinanziarie: "Rettifiche Attività Finanziarie",
            rfHykeeImposte: "Imposte",
            rfHykeeFundsFromOperationFFO: "Funds from Operation (FFO)",
            rfHykeeVarCCON: "Var. CCON",
            rfHykeeVarAltriCrediti: "Var. Altri Crediti",
            rfHykeeVarAltriDebiti: "Var. Altri Debiti",
            rfHykeeVarFondiETFR: "Var. Fondi e TFR",
            rfHykeeCashFromOperationCFO: "Cash From Operation (CFO)",
            rfHykeeCapex: "Investimenti / Disinvestimenti (Capex)",
            rfHykeeInvestimentiOnDisinvestimentiFinanziari: "Investimenti / Disinvestimenti Finanziari",
            rfHykeeFCFO: "Free Cash Flow from Operation (FCFO)",
            rfHykeeProventiEOneriFinanziari: "Proventi e Oneri Finanziari",
            rfHykeeFreeCashFlowToEquityFCFE: "Free Cash Flow to Equity (FCFE)",
            rfHykeeVarPatrimonioNetto: "Var. Patrimonio Netto",
            rfHykeeFCFEAdjustedDeltaPFN: "FCFE Adjusted (∆ PFN)",
            rfHykeeVarDebitiFinanziari: "Variazione Debiti Finanziari",
            rfHykeeVarAttivitaFinCorrenti: "Var. Attività Fin. Correnti",
            rfHykeeFreeCashFlowDeltaDisponibilitaLiquide: "Free Cash Flow (∆ Disponibilità Liquide)",
            ratioHykee2YoYRicavi: "2 YoY Ricavi %",
            ratioHykee2YoYVP: "2 YoY VP %",
            ratioHykeeEbitdaPERCENTAGE: "EBITDA %",
            ratioHykeeEbitPERCENTAGE: "EBIT %",
            ratioHykeeEbitdaAdjPERCENTAGE: "EBITDA Adj. %",
            ratioHykeeEbitAdjPERCENTAGE: "EBIT Adj. %",
            ratioHykeeUtileOnPerditaPERCENTAGE: "Risultato d'esercizio %",
            ratioHykeeCapexOnRicavi: "Capex / Ricavi %",
            ratioHykeeROCE: "ROCE %",
            ratioHykeePFNOnEBITDA: "PFN / EBITDA",
            ratioHykeePFNOnEBITDAAdj: "PFN / EBITDA Adj.",
            ratioHykeeGrossDebtOnPN: "Debito Lordo / Patrimonio Netto",
            ratioHykeeCCONOnRICAVIPERCENTAGE: "CCON / RICAVI %",
            ratioHykeeDSO: "DSO\n(giorni)",
            ratioHykeeDPOWithoutCapex: "DPO w/o Capex\n(giorni)",
            ratioHykeeDSI: "DSI\n(giorni)",
            ratioHykeeFCCR: "FCCR",
            ratioHykeeDSCR: "DSCR"
        },
        administrator_screen: {
            title: "Amministratori",
            name: "Nome",
            surname: "Cognome",
            qualification: "Qualifica",
            taxCode: "Codice Fiscale",
            quote: "Quote",
            error_credits:'Credito Insufficiente'
        },
        partner_screen: {
            title: "Partner",
            nameSurnamePartner: "Nome e Cognome Socio",
            taxCodePartner: "Codice Fiscale Socio",
            quotaPercentage: "Percentuale Quota",
            companyName: "Ragione Sociale Azienda",
            companyTaxCode: "Partita IVA",
        },
        shares_screen: {
            title: "Partecipazioni",
            taxCode: "Partita IVA",
            companyType: "Forma Giuridica",
            companyName: "Ragione Sociale",
            fulfillmentType: "Tipo Adempimento",
            quote: "Quota %"
        },
        consultancies_data:{
            hour_consultancies :'Consulenza {{hour}}h \ninclusa nel piano PLUS',
            title:'Consulenze',
            reserve: 'Da Prenotare',
            ask_advice: 'Richiedi consulenza',
            reserved: 'Effettuata',
            spam_advisory: 'Desideri attivare un piano di consulenza più esteso? \nAcquista il modulo ADVISORY e ottieni 20 h di consulenza ad un prezzo agevolato!',
            spam_plus: 'Attiva il piano PLUS per poter usufruire di 2h di consulenza per valutare e migliorare il tuo HYKEE Score. Scopri tutti i vantaggi del piano PLUS',
            active_advisory:'Attiva piano ADVISORY',
            active_plus:'Attiva piano PLUS',
            time_left_advisory:'{{hour}}/20h disponibili',
            time_left_plus:'{{hour}}/2h disponibili',
            advisory_module:'Consulenza \nModulo ADVISORY',
            reserve_plus: 'Prenota ora la tua sessione di {{hour}}h di consulenza dedicata',
            expiration:'Scadenza: {{date}} (12 12 mesi dall\'acquisto).',
            book_now:'Prenota ora la tua consulenza dedicata!',
            plan:'Piano {{plan}}',
            active:'Attivo',
        },
        profile_menu: {
            profile_data: 'Dati Profilo',
            company_data: 'Dati Azienda',
            user_management: 'Gestione utenti',
            plans: 'Piani',
            wallets: 'Portafoglio',
            consultancies: 'Consulenze',
            partners: 'Partner Collegati',
            business_rating: 'Business Rating (questionario SA)',
            linked_companies: 'Aziende collegate',
            request_pending: 'Richieste in attesa',
            partner_program: 'Partner Program',
            logout: 'Logout',
            notices: 'Comunicazioni',
            notices_detail: 'Comunicazioni',
            interesting: 'Interessante',
            uninteresting: 'Poco interessante'
        },
        self_assestment: {
            title: 'Autovalutazione'
        },
        survey: {
            title: 'Questionari'
        },
        saved_companies: {
            header_name: 'Nome',
            header_date: 'Data',
            no_company_saved: 'Nessuna azienda salvata',
            header_financial: 'F.Score',
            header_business: 'B.Score',
            header_hykee: 'H.Score',
            title_previous: 'Ricerche Precedenti',
            title: 'Aziende Salvate',
            title_notify: 'Notifiche recenti',
            notification1: 'Luca Ferrero ha completato l\'Autovalutazione. clicca per andare alla pagina Ferrero.',
            notification2: 'Luca Ferrero ha completato l\'Autovalutazione.',
            no_companies_saved: 'Nessuna compagnia salvata',
            p_iva:'P.I.',
            p_iva_partners:'Partita IVA'
        },
        research: {
            companies: 'aziende',
            title: 'Ricerche Precedenti',
            title_custom: 'Ricerca personalizzata',
            placeholder_nace_code: 'Inserisci Nace Code',
            placeholder_province: 'Aggiungi Provincia o Regione',
            placeholder_year: 'Aggiungi l\'anno dell\'ultimo budget',
            title_ateco: 'Codice Ateco:',
            title_province: 'Provincia:',
            title_year: 'Anno:',
            no_companies_searched: 'Nessuna compagnia ricercata',
            used_research: 'Ricerche usati',
            available_research: 'Ricerche disponibili',
            research: 'Ricerche',
            available_part1: 'Hai ancora',
            avaiable_part2: 'Ricerche a disposizione',
            used_part1: 'Hai usato',
            used_part2: 'ricerche questo mese',
        },
        add_profiles: {
            cancel: 'Elimina',
            add: 'Aggiungi',
            title: 'Gestisci profili',
            header_name: 'Nome',
            header_email: 'Email',
            header_role: 'Ruolo',
            header_action: 'Azioni',
            no_consultant: 'Nessun consulente trovato',
        },
        highlights: {
            title: 'In Evidenza',
            success: 'Sopra la media del settore',
            warning: 'Inferiore alla media del settore',
        },
        outlook: {
            title: 'Prospettiva'
        },
        credits: {
            used_credits: 'Crediti usati',
            available_credits: 'Crediti disponibili',
            credits: 'Crediti',
            available_part1: 'Hai ancora',
            avaiable_part2: 'crediti a disposizione',
            used_part1: 'Hai usato',
            used_part2: 'crediti questo mese',
        },
        search: {
            info: 'Le aziende non sono fatte solo di numeri, ma di persone, processi, decisioni e strategie. L\'IA disciplinata mette in correlazione questi dati e fornisce informazioni sulla sua possibile evoluzione futura. Inserisci il nome di un\'azienda di seguito per iniziare.',
            credits: 'Crediti disponibili',
            search: 'Ricerche disponibili',
            send_invitation: "Inserisci la mail dell' azienda per invitarla su Hykee"
        },
        splash: {
            continue: 'Continua su Hykee come',
        },
        login: {
            title: 'Accedi al profilo',
            description: 'La tua email è già registrata. Inserisci la password per accedere.',
            registration_ask: 'Non hai ancora un account? ',
            registration: 'Registrati',
            forgot_password: 'Password dimenticata?',
            forgot_password_success: 'Controlla le tue email per resettare la tua password. Un link è stato inviato a {{email}}.',
            send_mail: 'Invia mail',
            check_mail_reset_password: 'Controlla la tua mail, abbiamo inviato un link per il reset della tua password',
            reset_password: 'Cambia password',            
            confirm_password_error: 'Le password non conicidono',
            password_changed: 'Perfetto!! Hai cambiato la password, torna all\'app.'
        },
        signup: {
            title: 'Crea nuovo account',
            phone: 'Telefono',
            login_ask: 'Hai già un account?',
            login: 'Accedi',
            activation_code: 'Codice di attivazione',
            mail_address: 'Email',
            password: 'Password',
            confirm_password: 'Conferma Password',
            confirmPassword: 'Ripeti Password',
            first_name: 'Nome',
            last_name: 'Cognome',
            mobile: 'Cellulare',
            country: 'Paese',
            address: 'Indirizzo',
            town_city: 'Città',
            zip: 'Cap',
            province: 'Provincia',
            language: 'Lingua',
            fiscal_code: 'Codice Fiscale',
            insert_company_data: 'Desidero inserire i dati aziendali',
            company_name: 'Ragione sociale',
            company_country: 'Paese azienda',
            company_vat: 'Partita Iva',
            sector: 'Settore',
            company_address: 'Indirizzo Sede',
            company_town_city: 'Città sede',
            company_pec_mail: 'PEC aziendale',
            company_sdi_code: 'Codice destinatario (SDI)',
            usage_threshold: 'Soglia di utilizzo',
            agree_privacy_policy: 'Continuando accetti le condizioni d\'uso e la privacy policy',
            activation_account: 'Il tuo account Hykee è stato correttamente creato'
        },
        business_rating: {
            strength: 'Punti di forza',
            info_strength: 'Approccio orientato al cliente',
            weakness: 'Debolezze',
            info_weakness: '- Prodotto\n- Modello di business',
            label_chart: 'Poca prosperità per muoversi velocemente',
            section_area: 'Visualizza dettagli della sezione Area',
            section_agility: 'Visualizza dettagli della sezione Agilità',
            section_attitude: 'Visualizza dettagli della sezione Attitudine',
            section_growth: 'Visualizza dettagli della sezione Crescita',
            critical: 'Critica',
            weak: 'Debole',
            good: 'Buona',
        },
        company_data: {
            address: 'Indirizzo',
            city: 'Città',
            activity: 'Attività',
            last_balance: 'Ultimo Bilancio',
            revenues: 'Ricavi',
            ateco: 'Ateco'
        },
        profile: {
            logout: 'Disconnetti',
            phone: 'Telefono',
            email: 'Email',
            add_credits: 'Aggiungi Crediti',
            add_profiles: 'Aggiungi Profili',
            save: 'salva',
            edit: 'modifica',
            title: 'Profilo',
            mail_address: 'Indirizzo email',
            devices_code: 'Codice passepartout',
            password: 'Password',
            firstName: 'Nome',
            lastName: 'Cognome',
            mobile: 'Cellulare',
            country: 'Paese',
            address: 'Indirizzo',
            town_city: 'Città',
            zip: 'Codice Postale',
            postal_code: 'Cap',
            language: 'Lingua',
            fiscal_code: 'Codice Fiscale',
            insert_company_data: 'Desidero inserire i dati aziendali',
            company_name: 'Ragione sociale',
            company_country: 'Paese azienda',
            company_vat: 'Partita Iva',
            sector: 'Settore',
            company_address: 'Indirizzo Sede',
            company_town_city: 'Città sede',
            company_pec_mail: 'PEC aziendale',
            company_sdi_code: 'Codice destinatario (SDI)',
            usage_threshold: 'Soglia di utilizzo',
            current_password: 'Password corrente',
            new_password: 'Nuova password',
            new_password_confirm: 'Conferma password',
            password_error: {
                error: 'Errore',
                success: 'Successo',
                fill_all_fields: 'Compila tutti i campi',
                validation_error: 'La password non e\' valida',
                passwords_not_equal: 'Le password non sono uguali',
                password_changed: 'Password cambiata'
            },
        },
        new_house: {
            title: 'Nuova casa',
            house_name: 'Nome Casa*',
            house_address: 'Indirizzo completo casa*',
            search_address: 'Cerca',
            house_position: 'Posizione casa',
            contact: 'Contact {{index}}',
            add_contact: 'Aggiungi numero di telefono per le notifiche',
            max_contacts_alert: 'Puoi indicare al massimo {{contacts_number}} numeri.',
        },
        new_house_done: {
            title: '',
            its_done_1: 'E\'',
            its_done_2: 'fatta!',
            you_can_install_keypad: 'Ora che hai una casa puoi installare e collegare un keypad.',
            is_one_of_your_houses: 'ora è tra le tue case',
        },
        email_verification: {
            title: '',
            description: 'Per verificare la tua E-mail, inserisci il codice di 4 cifre che ti abbiamo inviato',
            mail_address: 'Indirizzo email',
            code: 'Digita il codice',
            resend: 'Reinvia',
        },
        phone_verification: {
            title: '',
            description: 'Per verificare il tuo numero di telefono, inserisci il codice di 4 cifre che ti abbiamo inviato',
            phone: 'Telefono',
            code: 'Digita il codice',
            resend: 'Reinvia',
        },
        house_detail: {
            house_name: 'Nome Casa*',
            house_address: 'Indirizzo completo casa*',
            search_address: 'Cerca',
            house_position: 'Posizione casa',
            add_accommodation: 'Aggiungi alloggio',
            accommodations_list: 'Lista alloggi',
            doors_list: 'Lista porte',
            add_contact: 'Aggiungi numero di telefono per le notifiche',
            contact: 'Contact {{index}}',
            max_contacts_alert: 'Puoi indicare al massimo {{contacts_number}} numeri.',
        },
        door_detail: {
            title: 'Porta',
            door_name: 'Nome Porta*',
            type: 'Tipo',
            door_serial: 'Seriale',
            door_description: 'Descrizione porta',
            advanced_settings: 'Opzioni avanzate',
            device_serial: 'Serial number',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Versione Firmware',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Abilita aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            credits_plan: 'Plan',
            scan_imei: 'Scansiona Imei',
            umount_device: 'Smonta dispositivo',
        },
        new_door: {
            title: 'Nuova porta',
            door_name: 'Nome Porta*',
            type: 'Tipo',
            door_serial: 'Seriale*',
            door_description: 'Descrizione porta',
            advanced_settings: 'Opzioni avanzate',
            device_serial: 'Serial number',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Versione Firmware',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Abilita aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            scan_imei: 'Scansiona Imei',
        },
        door_actions: {
            edit: 'Modifica',
            manage_plan: 'Gestisci piano',
            missing_device: 'Nessun device trovato per questa porta.',
            open: 'Apri',
            unlock_success_alert: 'Porta aperta con successo',
        },
        door_plan: {
            save: 'Salva',
            stop_plan: 'Interrompi abbonamento',
            bound_credits: 'Crediti vincolati',
            free_credits: 'Crediti liberi',
        },
        new_accommodation: {
            title: 'Nuovo Alloggio',
            accommodation_name: 'Nome Alloggio*',
            type: 'Tipo',
            add_button: 'Aggiungi Porta',
        },
        accommodation_detail: {
            title: 'Nuovo Alloggio',
            accommodation_name: 'Nome Alloggio*',
            type: 'Tipo',
        },
        door_filters: {
            title: 'Filtra le tue porte',
        },
        reservation_filters: {
            title: 'Filtra le tue prenotazioni',
        },
        dashboard: {
            title: 'Dashboard',
            buttons: {
                company: 'Azienda',
                consultants: 'Intermediari / Consulenti',
                update_accounting_data: "Aggiorna dati contabili"
            },
            blocks: {
                reservations: {
                    add_button: 'Aggiungi prenotazione',
                    reservations: 'Prenotazioni',
                    checked_in: 'Check in',
                    confirmed: 'Confermati',
                    pending: 'In attesa',
                },
                houses: {
                    add_button: 'Aggiungi casa',
                    doors: 'Porte',
                    active_doors: 'Attivi',
                    inactive_doors: 'Inattivi',
                },
                credits: {
                    add_button: 'Aggiungi crediti',
                    remaining_credits: 'Crediti rimanenti',
                    active_devices: 'Device attivi',
                    current_monthly_consumption: 'Consumo mensile attuale',
                    remaining_months: 'Mesi rimanenti',
                },
                accommodations: {
                    add_button: 'Aggiungi alloggio',
                    doors: 'Porte',
                    active_doors: 'Porte attive',
                    inactive_doors: 'Porte inattive',
                },
            },
            tabs: {
                reservations: 'Prenotazioni',
                houses: 'Case',
                credits: 'Crediti',
                events: 'Notifiche',
            },
        },
        your_houses: {
            title: 'Le Tue Case',
        },
        events: {
            title: 'Notifiche',
        },
        doors: {
            title: 'Porte',
            add_button: 'Aggiungi Porta',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri',
        },
        reservations: {
            title: 'Le tue Prenotazioni',
            add_button: 'Aggiungi Prenotazione',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri',
        },
        accommodations: {
            title: 'Alloggi',
            subtitle: 'Alloggi'
        },
        intro_reservation: {
            title: 'Aggiungi Prenotazione',
            subtitle: 'Inizia ricercando l\'email del cliente a cui vuoi assegnare la prenotazione',
            search: 'Cerca',
        },
        intro_reservation_new_user: {
            title: 'Crea Prenotazione',
            subtitle_1: 'L\'email che hai cercato non è associata ad alcun utente di B & Free.',
            subtitle_2: 'Continuando puoi creare la prenotazione e inviare all\'utente il codice personale valido per il periodo indicato',
        },
        new_reservation_guest: {
            title: 'Crea Prenotazione',
            first_name: 'Nome*',
            type: 'Tipo',
            last_name: 'Cognome*',
            mail: 'Indirizzo email*',
            mobile: 'Telefono*',
        },
        new_reservation_period: {
            title: 'Crea Prenotazione',
            section_title: 'Seleziona il periodo per la prenotazione',
            from_date: 'Da',
            to_date: 'A',
        },
        new_reservation_houses: {
            title: 'Crea Prenotazione',
            subtitle: 'Scegli la casa',
        },
        new_reservation_accommodations: {
            title: 'Crea Prenotazione',
        },
        new_reservation_done: {
            title: '',
            its_done_1: 'E\'',
            its_done_2: 'fatta!',
            recap: 'Riassunto della prenotazione',
        },
        reservation_detail: {
            title: 'Prenotazione',
            wait_button: 'In attesa dell\'ospite',
            confirm_button: 'Conferma Prenotazione',
            checkin_button: 'Effettua Check-in',
            reservation_state: 'Stato:',
            alert_resend_message: 'Vuoi inviare nuovamente il codice di verifica?',
            resend_codes_button: 'Reinvia codici',
        },
        support_report: {
            title: 'Supporto',
            header: 'Come possiamo aiutarti?',
            subheader: 'Un operatore vi risponderà via mail il prima possibile',
            select_problem: 'Seleziona il problema',
            describe: 'E descrivi il problema',
            ok_title: 'Ok',
            ok_message: 'Segnalazione inviata con successo',
            app_version: 'Versione app: {{app_version}}'
        },

        get_credits: {
            title: 'Acquista Crediti',
            stripe_ko_message: 'Impossibile completare il pagamento.',
            stripe_ok_message: 'Pagamento completato con successo. I crediti saranno aggiunti presto al tuo borsellino.',
            go_to_buy: 'Apri pagina di acquisto',
        },
        CompanyMicro: {
            loading: "Caricamento dati...",
            title: 'Financial Score',
            firstText: 'Cliccando su OK si viene ricondotti alla maschera in cui inserire i dati mancanti degli ultimi due anni',
            euroText: 'Inserire i valori in unità di euro',
            commercialCreditText: 'Crediti commerciali',
            commercialDebitText: 'Debiti commerciali',
            financialDebitText: 'Debiti finanziari',
            save_and_confirm: 'Salva tutto e conferma invio dati',
            placeholderText: 'Inserisci qui il valore',
            success: 'Salvato con successo'
        }
    },
    survey_screen: {
        dashboard: {
            title: 'Questionario',
            update:'Aggiornato: ',
        },

    },
    error: 'Errore',
    errors: {
        something_went_wrong: 'Qualcosa è andato storto.',
        invalid_email: 'Email non valida',
        empty_password: 'Password vuota',
        invalid_password: 'La password deve essere lunga almeno 8 caratteri.',
        invalid_activation_code: 'Codice di attivazione non valido.',
        invalid_fiscal_code: 'Codice fiscale non valido',
        wrong_credentials_error: 'Credenziali errate.',
        house_data_incomplete: 'Dati incompleti',
        house_data_unchanged: 'I dati non sono stati modificati',
        door_data_incomplete: 'Dati incompleti',
        door_data_unchanged: 'I dati non sono stati modificati',
        accommodation_data_incomplete: 'Dati incompleti',
        accommodation_data_unchanged: 'I dati non sono stati modificati',
        at_least_one_residential: 'Almeno una porta residential deve essere selezionata.',
        you_must_fill_field: 'Il campo "{{field}}" è obbligatorio',
        reservation_data_incomplete: 'Dati incompleti',
        reservation_accommodations_data_incomplete: 'Seleziona almeno un alloggio per ogni casa.',
        reservation_houses_has_no_accommodations: 'La casa selezionata non ha alloggi.',
        support_report_data_incomplete: 'Dati incompleti',
        company_not_found: 'Azienda non trovata',
        validation: {
            registration: {
                INVALID_SIZE: "Lunghezza non valida",
                NOT_BLANK: "Il campo non può essere vuoto",
                INVALID_EMAIL: "Il campo deve essere una mail valida"
            }
        }

    },
    alerts: {
        attention: 'Attenzione',
        are_you_sure: 'Sei sicuro?',
        success: 'Successo',
        yes: 'Si',
        no: 'No',
        replace: 'Cambia',
        delete: 'Cancella',
        cancel: 'Annulla',
        want_replace_image: 'Vuoi cambiare l\'immagine?',
        want_delete_image: 'Vuoi cancellare l\'immagine?',
        which_action_image: 'Quale azione vuoi svolgere?',
        ok: 'Ok',
        financial_score_title: 'Per ottenere lo Score serviranno i Crediti Commerciali, Debiti Commerciali e Debiti Finanziari degli ultimi due anni',
        financial_score_message: 'I dati inseriti saranno visibili in app, così come il Financial Score ottenuto da questi calcoli aggiornati'

    },
    inputs: {
        mail_address: 'Indirizzo email',
        password: 'Password',
    },
    buttons: {
        add: 'Aggiungi',
        add_plus: '+',
        see_more: 'Estendi',
        see_less: 'Riduci',
        search: 'Avvia Ricerca',
        placeholder_web_site: 'Inserire sito internet',
        placeholder_search: 'Inserire partita IVA',
        placeholder_send_invitation: "Inserire la mail",
        continue: 'Continua',
        close: 'Chiudi',
        confirm: 'Conferma',
        login: 'Accedi',
        signup: 'Salva',
        new_house: 'Salva',
        house_detail: 'Salva',
        new_door: 'Salva',
        new_accommodation: 'Salva',
        profile: 'Salva',
        door_filters: 'Applica filtri',
        reservation_filters: 'Applica filtri',
        connect_first_keypad: 'Connetti il keypad',
        email_verification: 'Continua',
        phone_verification: 'Continua',
        intro_reservation: 'Cerca',
        intro_reservation_new_user: 'Continua',
        new_reservation_guest: 'Continua',
        new_reservation_period: 'Continua',
        new_reservation_houses_first: 'Seleziona alloggio',
        new_reservation_houses_second: 'Tutti gli alloggi',
        new_reservation_accommodations: 'Continua',
        new_reservation_done: 'Salva',
        support_report: 'Invia',
    },
    common: {
        online: 'Online',
        offline: 'Offline',
        active: 'Active',
        expiring: 'Scadenza',
        expired: 'Scaduto',
        no_data: 'Nessun elemento',
        type: 'Tipo',
        service_status: 'Service status',
        status: 'Status',
        connection_status: 'Connessione',
        houses: 'Case',
        user_data: 'Dati utente',
        accommodation: 'Alloggio',
        period: 'Periodo',
        PENDING: 'In attesa',
        CHECKEDIN: 'Check In',
        CHECKEDOUT: 'Check Out',
        CANCELED: 'Cancellato',
        CONFIRMED: 'Confermato',
        search_by_email: 'Cerca per email',
        sort_by: 'Ordina per',
        from_date: 'Da',
        to_date: 'A',
        SUPPORT_TYPE_OTHER: 'Altro',
    },
};