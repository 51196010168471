/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyBalanceSheet,
    CompanyBalanceSheetFromJSON,
    CompanyBalanceSheetToJSON,
    CompanyYearDTO,
    CompanyYearDTOFromJSON,
    CompanyYearDTOToJSON,
} from '../models';

export interface GetCompanyBalanceSheetByCompanyAndYearRequest {
    companyYearDTO: CompanyYearDTO;
}

export interface GetCompanyBalanceSheetsRequest {
    companyId: number;
}

export interface ImportBalanceSheetByYearRequest {
    companyBalanceSheet: CompanyBalanceSheet;
}

export interface UpdateCompanyBalanceSheetMoreFieldsRequest {
    id: number;
    companyBalanceSheet: CompanyBalanceSheet;
}

/**
 * 
 */
export class CustomCompanyBalanceSheetResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCompanyBalanceSheetByCompanyAndYearRaw(requestParameters: GetCompanyBalanceSheetByCompanyAndYearRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.companyYearDTO === null || requestParameters.companyYearDTO === undefined) {
            throw new runtime.RequiredError('companyYearDTO','Required parameter requestParameters.companyYearDTO was null or undefined when calling getCompanyBalanceSheetByCompanyAndYear.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-balance-sheets/company/balance-by-year`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyYearDTOToJSON(requestParameters.companyYearDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyBalanceSheetByCompanyAndYear(requestParameters: GetCompanyBalanceSheetByCompanyAndYearRequest): Promise<CompanyBalanceSheet> {
        const response = await this.getCompanyBalanceSheetByCompanyAndYearRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyBalanceSheetsRaw(requestParameters: GetCompanyBalanceSheetsRequest): Promise<runtime.ApiResponse<Array<CompanyBalanceSheet>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyBalanceSheets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-balance-sheets/company/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyBalanceSheetFromJSON));
    }

    /**
     */
    async getCompanyBalanceSheets(requestParameters: GetCompanyBalanceSheetsRequest): Promise<Array<CompanyBalanceSheet>> {
        const response = await this.getCompanyBalanceSheetsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async importBalanceSheetByYearRaw(requestParameters: ImportBalanceSheetByYearRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.companyBalanceSheet === null || requestParameters.companyBalanceSheet === undefined) {
            throw new runtime.RequiredError('companyBalanceSheet','Required parameter requestParameters.companyBalanceSheet was null or undefined when calling importBalanceSheetByYear.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-balance-sheets/company/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBalanceSheetToJSON(requestParameters.companyBalanceSheet),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async importBalanceSheetByYear(requestParameters: ImportBalanceSheetByYearRequest): Promise<CompanyBalanceSheet> {
        const response = await this.importBalanceSheetByYearRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyBalanceSheetMoreFieldsRaw(requestParameters: UpdateCompanyBalanceSheetMoreFieldsRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyBalanceSheetMoreFields.');
        }

        if (requestParameters.companyBalanceSheet === null || requestParameters.companyBalanceSheet === undefined) {
            throw new runtime.RequiredError('companyBalanceSheet','Required parameter requestParameters.companyBalanceSheet was null or undefined when calling updateCompanyBalanceSheetMoreFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-balance-sheets/moreFields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBalanceSheetToJSON(requestParameters.companyBalanceSheet),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyBalanceSheetMoreFields(requestParameters: UpdateCompanyBalanceSheetMoreFieldsRequest): Promise<CompanyBalanceSheet> {
        const response = await this.updateCompanyBalanceSheetMoreFieldsRaw(requestParameters);
        return await response.value();
    }

}
