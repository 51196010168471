/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CapitaleInvestito,
    CapitaleInvestitoFromJSON,
    CapitaleInvestitoFromJSONTyped,
    CapitaleInvestitoToJSON,
    CapitaleSociale,
    CapitaleSocialeFromJSON,
    CapitaleSocialeFromJSONTyped,
    CapitaleSocialeToJSON,
    DatiBilancio,
    DatiBilancioFromJSON,
    DatiBilancioFromJSONTyped,
    DatiBilancioToJSON,
    FondoConsortile,
    FondoConsortileFromJSON,
    FondoConsortileFromJSONTyped,
    FondoConsortileToJSON,
    PraticheAnno,
    PraticheAnnoFromJSON,
    PraticheAnnoFromJSONTyped,
    PraticheAnnoToJSON,
    ValoreNominaleConferimenti,
    ValoreNominaleConferimentiFromJSON,
    ValoreNominaleConferimentiFromJSONTyped,
    ValoreNominaleConferimentiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SintesiCifreImpresa
 */
export interface SintesiCifreImpresa {
    /**
     * 
     * @type {PraticheAnno}
     * @memberof SintesiCifreImpresa
     */
    praticheAnno?: PraticheAnno;
    /**
     * 
     * @type {CapitaleInvestito}
     * @memberof SintesiCifreImpresa
     */
    capitaleInvestito?: CapitaleInvestito;
    /**
     * 
     * @type {FondoConsortile}
     * @memberof SintesiCifreImpresa
     */
    fondoConsortile?: FondoConsortile;
    /**
     * 
     * @type {ValoreNominaleConferimenti}
     * @memberof SintesiCifreImpresa
     */
    valoreNominaleConferimenti?: ValoreNominaleConferimenti;
    /**
     * 
     * @type {CapitaleSociale}
     * @memberof SintesiCifreImpresa
     */
    capitaleSociale?: CapitaleSociale;
    /**
     * 
     * @type {DatiBilancio}
     * @memberof SintesiCifreImpresa
     */
    datiBilancio?: DatiBilancio;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    dtAddetti?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    ntrasferimentiSede?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    nprotocolliAperti?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    ntitolariCariche?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    fpartecipazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    ntrasferimentiQuote?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    naddetti?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    nsindaci?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    namministratori?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    nlocalizzazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiCifreImpresa
     */
    nsoci?: string;
}

export function SintesiCifreImpresaFromJSON(json: any): SintesiCifreImpresa {
    return SintesiCifreImpresaFromJSONTyped(json, false);
}

export function SintesiCifreImpresaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SintesiCifreImpresa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'praticheAnno': !exists(json, 'praticheAnno') ? undefined : PraticheAnnoFromJSON(json['praticheAnno']),
        'capitaleInvestito': !exists(json, 'capitaleInvestito') ? undefined : CapitaleInvestitoFromJSON(json['capitaleInvestito']),
        'fondoConsortile': !exists(json, 'fondoConsortile') ? undefined : FondoConsortileFromJSON(json['fondoConsortile']),
        'valoreNominaleConferimenti': !exists(json, 'valoreNominaleConferimenti') ? undefined : ValoreNominaleConferimentiFromJSON(json['valoreNominaleConferimenti']),
        'capitaleSociale': !exists(json, 'capitaleSociale') ? undefined : CapitaleSocialeFromJSON(json['capitaleSociale']),
        'datiBilancio': !exists(json, 'datiBilancio') ? undefined : DatiBilancioFromJSON(json['datiBilancio']),
        'dtAddetti': !exists(json, 'dtAddetti') ? undefined : json['dtAddetti'],
        'ntrasferimentiSede': !exists(json, 'ntrasferimentiSede') ? undefined : json['ntrasferimentiSede'],
        'nprotocolliAperti': !exists(json, 'nprotocolliAperti') ? undefined : json['nprotocolliAperti'],
        'ntitolariCariche': !exists(json, 'ntitolariCariche') ? undefined : json['ntitolariCariche'],
        'fpartecipazioni': !exists(json, 'fpartecipazioni') ? undefined : json['fpartecipazioni'],
        'ntrasferimentiQuote': !exists(json, 'ntrasferimentiQuote') ? undefined : json['ntrasferimentiQuote'],
        'naddetti': !exists(json, 'naddetti') ? undefined : json['naddetti'],
        'nsindaci': !exists(json, 'nsindaci') ? undefined : json['nsindaci'],
        'namministratori': !exists(json, 'namministratori') ? undefined : json['namministratori'],
        'nlocalizzazioni': !exists(json, 'nlocalizzazioni') ? undefined : json['nlocalizzazioni'],
        'nsoci': !exists(json, 'nsoci') ? undefined : json['nsoci'],
    };
}

export function SintesiCifreImpresaToJSON(value?: SintesiCifreImpresa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'praticheAnno': PraticheAnnoToJSON(value.praticheAnno),
        'capitaleInvestito': CapitaleInvestitoToJSON(value.capitaleInvestito),
        'fondoConsortile': FondoConsortileToJSON(value.fondoConsortile),
        'valoreNominaleConferimenti': ValoreNominaleConferimentiToJSON(value.valoreNominaleConferimenti),
        'capitaleSociale': CapitaleSocialeToJSON(value.capitaleSociale),
        'datiBilancio': DatiBilancioToJSON(value.datiBilancio),
        'dtAddetti': value.dtAddetti,
        'ntrasferimentiSede': value.ntrasferimentiSede,
        'nprotocolliAperti': value.nprotocolliAperti,
        'ntitolariCariche': value.ntitolariCariche,
        'fpartecipazioni': value.fpartecipazioni,
        'ntrasferimentiQuote': value.ntrasferimentiQuote,
        'naddetti': value.naddetti,
        'nsindaci': value.nsindaci,
        'namministratori': value.namministratori,
        'nlocalizzazioni': value.nlocalizzazioni,
        'nsoci': value.nsoci,
    };
}


