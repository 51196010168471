/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuotaDiritto
 */
export interface QuotaDiritto {
    /**
     * 
     * @type {string}
     * @memberof QuotaDiritto
     */
    tipoDiritto?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaDiritto
     */
    valoreNominale?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaDiritto
     */
    percentualeCapitale?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaDiritto
     */
    ctipoDiritto?: string;
    /**
     * 
     * @type {string}
     * @memberof QuotaDiritto
     */
    nazioni?: string;
}

export function QuotaDirittoFromJSON(json: any): QuotaDiritto {
    return QuotaDirittoFromJSONTyped(json, false);
}

export function QuotaDirittoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuotaDiritto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoDiritto': !exists(json, 'tipoDiritto') ? undefined : json['tipoDiritto'],
        'valoreNominale': !exists(json, 'valoreNominale') ? undefined : json['valoreNominale'],
        'percentualeCapitale': !exists(json, 'percentualeCapitale') ? undefined : json['percentualeCapitale'],
        'ctipoDiritto': !exists(json, 'ctipoDiritto') ? undefined : json['ctipoDiritto'],
        'nazioni': !exists(json, 'nazioni') ? undefined : json['nazioni'],
    };
}

export function QuotaDirittoToJSON(value?: QuotaDiritto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoDiritto': value.tipoDiritto,
        'valoreNominale': value.valoreNominale,
        'percentualeCapitale': value.percentualeCapitale,
        'ctipoDiritto': value.ctipoDiritto,
        'nazioni': value.nazioni,
    };
}


