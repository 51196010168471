// 
// 
//
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Platform, ScrollView } from 'react-native';
import { TabRouter } from 'react-navigation';
import { RootStackParamList, State } from '../../../../types';
import { i18n } from '../../../i18n/i18n'

import Icon from '../../Tools/Icon';
import FilterResearch from './FilterResearch';
import ResultResearch from './ResultResearch';



export default function Research(props: {
  myroute: State,
  research: {
    nace: string,
    province: string[],
    year: string
  },
  onSearch: ((seeResearch: boolean | null) => void) | undefined,
  navigation: NativeStackNavigationProp<RootStackParamList, "ResearchScreen">
}) {


  return (
    <LinearGradient
      colors={['rgba(34, 34, 34, 1) ', 'rgba(65, 65, 65, 1)']}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <ScrollView style={styles.container}>
        <View style={styles.labelAzienda}>
          <TouchableOpacity
            style={styles.goBack}
            onPress={() => { props.onSearch ? props.onSearch(false) : null }}
          >
            <Icon name={'angle-left'} size={24} color={'#00CC83'} />
          </TouchableOpacity>
          <Text style={styles.title}>
            {i18n.t('screens.research.title_custom')}
          </Text>
        </View>
        <View style={styles.containerInput}>
          <TextInput
            placeholder={i18n.t('buttons.placeholder_search')}
            placeholderTextColor='#737373'
            onChangeText={(key) => props.myroute.key_search = key}
            style={styles.textInput}
          />
          <TouchableOpacity
            style={styles.button}
            activeOpacity={0.5}
          >
            <Text style={styles.text}>{i18n.t('buttons.search')}</Text>
          </TouchableOpacity>
        </View>
        <FilterResearch research={props.research} />
        <ResultResearch />
      </ScrollView>
    </LinearGradient>
  );
}


const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins',
  },
  containerResearch: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  labelAzienda: {
    padding: 10,
    flex: 1,
    marginVertical: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  goBack: {
    marginRight: 20
  },
  button: {
    flex: 0.3,
    backgroundColor: '#222222',
    padding: 5,
    marginVertical: 10,
    justifyContent: 'center',
    borderRadius: 5,
    alignItems: 'center'
  },
  titleResearch: {
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 8,
    fontFamily: 'poppins',
    color: 'white'
  },
  textInput: {
    flex: 0.7,
    backgroundColor: '#464646',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    marginVertical: 10,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 0.2,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    textAlign: 'left',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
