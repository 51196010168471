/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoAmministratori
 */
export interface InfoAmministratori {
    /**
     * 
     * @type {string}
     * @memberof InfoAmministratori
     */
    fpresenzaInfo?: string;
}

export function InfoAmministratoriFromJSON(json: any): InfoAmministratori {
    return InfoAmministratoriFromJSONTyped(json, false);
}

export function InfoAmministratoriFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoAmministratori {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function InfoAmministratoriToJSON(value?: InfoAmministratori | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


