import React from "react";
import SharedContext from "../../../../navigation/SharedContext";
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import hykeeStyle from "@components/hykeeStyle";
import Icon from "@screens/Tools/Icon";
import { BackgroundColorButtonGreen, ColorTextLightGreen } from "@components/ColorTheme";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import { Preference as InsightLike, RootStackScreenProps } from "../../../../../types";
import Colors from "../../../../../constants/Colors";
import { LinearGradient } from "expo-linear-gradient";
import { Insight, InsightLikeLikeStateEnum, UserExtendedTypeEnum } from "@services/apis/generated";
import { customInsightLikeResourceApi, customInsightResourceApi } from "@services/apis/ApiConfiguration";



export default function InsightDetailComponent({ navigation, route }: RootStackScreenProps<'InsightDetail'>) {

    const sharedContext = React.useContext(SharedContext);
    const dateOfPublication = Intl.DateTimeFormat().format(route.params?.insight.dateOfPublication);
    const [likes, setLikes] = React.useState<number>(route.params?.insight.likeCount);
    const [dislikes, setDislikes] = React.useState<number>(route.params?.insight.dislikeCount);

    function getLikes() {
        customInsightResourceApi.getInsightById({ id: route.params?.insight.id })
            .then((insight) => {
                setLikes(insight.likeCount!);
                setDislikes(insight.dislikeCount!);
            }).catch((e) => {
                console.log(e)
            })
    }

    const setPreference = (preference: InsightLikeLikeStateEnum, insight: Insight): void => {
        customInsightLikeResourceApi.likeOrDislikePost({
            insightLike: {
                insight: insight,
                likeState: preference
            }
        }).then(() => {
            getLikes();
        }).catch((error) => {
            console.log(error)
        })
    }

    return (

        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={hykeeStyle.containerBack}
        >
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>
                    {i18n.t("screens.profile_menu.notices")}</Text>
            </View>

            {route.params?.insight &&
                <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
                    {/**SECTOR - IMPORTANT */}
                    {/* <View style={styles.insightSector}>
                        {route.params?.insight.sector && <Text style={styles.insightSectorText}> {route.params?.insight.sector} </Text>}
                    </View> */}

                    {/** TITLE */}
                    <View style={styles.insightTitle}>
                        {route.params?.insight.title && <Text style={styles.insightTitleText}> {route.params?.insight.title} </Text>}
                    </View>

                    {/** DESCRIPTION */}
                    <View style={styles.insightDescription}>
                        {route.params?.insight.description && <Text style={styles.insightDescriptionText}> {route.params?.insight.description} </Text>}
                    </View>

                    <View style={styles.dataPreferenceContainer}>
                        {/** DATA */}
                        <View style={styles.insightData}>
                            {route.params?.insight.dateOfPublication && <Text style={styles.insightDataText}>{i18n.t("components.partner_program.date_of_publication")}:{"\n"}{dateOfPublication} </Text>}
                        </View>
                        {/** LIKE - UNLIKE */}
                        <View style={styles.insightLike}>
                            {route.params?.insight.dateOfPublication &&
                                <TouchableOpacity onPress={() => setPreference(InsightLikeLikeStateEnum.Like, route.params?.insight)} disabled={sharedContext.who == UserExtendedTypeEnum.Consultant}>
                                    <Text style={styles.insightLikeText}>
                                        <Icon name={"thumbs-up"} color={route.params?.insight.preference == InsightLike.Like ? BackgroundColorButtonGreen() : "#737373"}></Icon>{likes}
                                    </Text>
                                </TouchableOpacity>}
                            {route.params?.insight.dateOfPublication &&
                                <TouchableOpacity onPress={() => setPreference(InsightLikeLikeStateEnum.Dislike, route.params?.insight)} disabled={sharedContext.who == UserExtendedTypeEnum.Consultant}>
                                    <Text style={styles.insightLikeText}>
                                        <Icon name={"thumbs-down"} color={route.params?.insight.preference == InsightLike.Dislike ? "#FF0000" : "#737373"}></Icon>{dislikes}
                                    </Text>
                                </TouchableOpacity>}
                        </View>
                    </View>

                    {/**PREFERENCE BUTTON */}
                    {sharedContext.who == UserExtendedTypeEnum.Company &&
                        <View style={styles.preferenceContainer}>
                            <TouchableOpacity style={styles.preferenceButton} onPress={() => setPreference(InsightLikeLikeStateEnum.Like, route.params?.insight)}>
                                <Icon size={30} name={"thumbs-up"} color={'#737373'}></Icon>
                                <Text style={[styles.insightLikeText, { fontSize: 10 }]}>{i18n.t("screens.profile_menu.interesting")}</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={styles.preferenceButton} onPress={() => setPreference(InsightLikeLikeStateEnum.Dislike, route.params?.insight)}>
                                <Icon size={30} name={"thumbs-down"} color={'#737373'}></Icon>
                                <Text style={[styles.insightLikeText, { fontSize: 10 }]}>{i18n.t("screens.profile_menu.uninteresting")}</Text>
                            </TouchableOpacity>
                        </View>
                    }



                </ScrollView>
            }

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    insightContainer: {
        flexDirection: 'column',
        padding: 10
    },
    insightSector: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 32
    },
    insightSectorText: {
        color: 'white',
        fontSize: 16,
        fontFamily: 'poppins'
    },
    insightTitle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: 16
    },
    insightTitleText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: 'poppins'
    },
    insightDescription: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: 48,
    },
    insightDescriptionText: {
        color: 'white',
        fontSize: 16,
        fontFamily: 'poppins'
    },
    dataPreferenceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        paddingBottom: 80
    },
    insightData: {
        flexDirection: 'row',
        alignSelf: 'center'
    },
    insightDataText: {
        color: 'white',
        fontSize: 16,
        fontFamily: 'poppins'
    },
    insightLike: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    insightLikeText: {
        color: 'white',
        fontSize: 16,
        padding: 8,
        fontFamily: 'poppins'
    },
    read: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white'
    },
    unread: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: BackgroundColorButtonGreen()
    },

    preferenceContainer: {
        flexDirection: 'row',
        fontFamily: 'poppins',
        justifyContent: 'center',
        alignItems: 'center'
    },
    preferenceButton: {
        flexDirection: 'row',
        borderStyle: 'solid',
        borderColor: '#737373',
        borderWidth: 1,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: 160
    }
});


