/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrascrizioneRi,
    TrascrizioneRiFromJSON,
    TrascrizioneRiFromJSONTyped,
    TrascrizioneRiToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrascrizioniRi
 */
export interface TrascrizioniRi {
    /**
     * 
     * @type {Array<TrascrizioneRi>}
     * @memberof TrascrizioniRi
     */
    trascrizioneRi: Array<TrascrizioneRi>;
}

export function TrascrizioniRiFromJSON(json: any): TrascrizioniRi {
    return TrascrizioniRiFromJSONTyped(json, false);
}

export function TrascrizioniRiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrascrizioniRi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trascrizioneRi': ((json['trascrizioneRi'] as Array<any>).map(TrascrizioneRiFromJSON)),
    };
}

export function TrascrizioniRiToJSON(value?: TrascrizioniRi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trascrizioneRi': ((value.trascrizioneRi as Array<any>).map(TrascrizioneRiToJSON)),
    };
}


