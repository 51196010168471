/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiUdienza
 */
export interface EstremiUdienza {
    /**
     * 
     * @type {string}
     * @memberof EstremiUdienza
     */
    dtUdienza?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiUdienza
     */
    dtTermine?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiUdienza
     */
    luogo?: string;
}

export function EstremiUdienzaFromJSON(json: any): EstremiUdienza {
    return EstremiUdienzaFromJSONTyped(json, false);
}

export function EstremiUdienzaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiUdienza {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtUdienza': !exists(json, 'dtUdienza') ? undefined : json['dtUdienza'],
        'dtTermine': !exists(json, 'dtTermine') ? undefined : json['dtTermine'],
        'luogo': !exists(json, 'luogo') ? undefined : json['luogo'],
    };
}

export function EstremiUdienzaToJSON(value?: EstremiUdienza | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtUdienza': value.dtUdienza,
        'dtTermine': value.dtTermine,
        'luogo': value.luogo,
    };
}


