/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatiBilancio
 */
export interface DatiBilancio {
    /**
     * 
     * @type {string}
     * @memberof DatiBilancio
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiBilancio
     */
    utilePerdite?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiBilancio
     */
    ricavi?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiBilancio
     */
    valoreProduzione?: string;
}

export function DatiBilancioFromJSON(json: any): DatiBilancio {
    return DatiBilancioFromJSONTyped(json, false);
}

export function DatiBilancioFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatiBilancio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'utilePerdite': !exists(json, 'utilePerdite') ? undefined : json['utilePerdite'],
        'ricavi': !exists(json, 'ricavi') ? undefined : json['ricavi'],
        'valoreProduzione': !exists(json, 'valoreProduzione') ? undefined : json['valoreProduzione'],
    };
}

export function DatiBilancioToJSON(value?: DatiBilancio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anno': value.anno,
        'utilePerdite': value.utilePerdite,
        'ricavi': value.ricavi,
        'valoreProduzione': value.valoreProduzione,
    };
}


