/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanySurvey,
    CompanySurveyFromJSON,
    CompanySurveyToJSON,
} from '../models';

export interface CountCompanySurveysRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    updateDateGreaterThan?: Date;
    updateDateLessThan?: Date;
    updateDateGreaterThanOrEqual?: Date;
    updateDateLessThanOrEqual?: Date;
    updateDateEquals?: Date;
    updateDateNotEquals?: Date;
    updateDateSpecified?: boolean;
    updateDateIn?: Array<Date>;
    updateDateNotIn?: Array<Date>;
    validUntilGreaterThan?: Date;
    validUntilLessThan?: Date;
    validUntilGreaterThanOrEqual?: Date;
    validUntilLessThanOrEqual?: Date;
    validUntilEquals?: Date;
    validUntilNotEquals?: Date;
    validUntilSpecified?: boolean;
    validUntilIn?: Array<Date>;
    validUntilNotIn?: Array<Date>;
    responseContains?: string;
    responseDoesNotContain?: string;
    responseEquals?: string;
    responseNotEquals?: string;
    responseSpecified?: boolean;
    responseIn?: Array<string>;
    responseNotIn?: Array<string>;
    businessScoreGreaterThan?: number;
    businessScoreLessThan?: number;
    businessScoreGreaterThanOrEqual?: number;
    businessScoreLessThanOrEqual?: number;
    businessScoreEquals?: number;
    businessScoreNotEquals?: number;
    businessScoreSpecified?: boolean;
    businessScoreIn?: Array<number>;
    businessScoreNotIn?: Array<number>;
    completedEquals?: CountCompanySurveysCompletedEqualsEnum;
    completedNotEquals?: CountCompanySurveysCompletedNotEqualsEnum;
    completedSpecified?: boolean;
    completedIn?: Array<CountCompanySurveysCompletedInEnum>;
    completedNotIn?: Array<CountCompanySurveysCompletedNotInEnum>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    surveyIdGreaterThan?: number;
    surveyIdLessThan?: number;
    surveyIdGreaterThanOrEqual?: number;
    surveyIdLessThanOrEqual?: number;
    surveyIdEquals?: number;
    surveyIdNotEquals?: number;
    surveyIdSpecified?: boolean;
    surveyIdIn?: Array<number>;
    surveyIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanySurveyRequest {
    companySurvey: CompanySurvey;
}

export interface DeleteCompanySurveyRequest {
    id: number;
}

export interface GetAllCompanySurveysRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    creationDateGreaterThan?: Date;
    creationDateLessThan?: Date;
    creationDateGreaterThanOrEqual?: Date;
    creationDateLessThanOrEqual?: Date;
    creationDateEquals?: Date;
    creationDateNotEquals?: Date;
    creationDateSpecified?: boolean;
    creationDateIn?: Array<Date>;
    creationDateNotIn?: Array<Date>;
    updateDateGreaterThan?: Date;
    updateDateLessThan?: Date;
    updateDateGreaterThanOrEqual?: Date;
    updateDateLessThanOrEqual?: Date;
    updateDateEquals?: Date;
    updateDateNotEquals?: Date;
    updateDateSpecified?: boolean;
    updateDateIn?: Array<Date>;
    updateDateNotIn?: Array<Date>;
    validUntilGreaterThan?: Date;
    validUntilLessThan?: Date;
    validUntilGreaterThanOrEqual?: Date;
    validUntilLessThanOrEqual?: Date;
    validUntilEquals?: Date;
    validUntilNotEquals?: Date;
    validUntilSpecified?: boolean;
    validUntilIn?: Array<Date>;
    validUntilNotIn?: Array<Date>;
    responseContains?: string;
    responseDoesNotContain?: string;
    responseEquals?: string;
    responseNotEquals?: string;
    responseSpecified?: boolean;
    responseIn?: Array<string>;
    responseNotIn?: Array<string>;
    businessScoreGreaterThan?: number;
    businessScoreLessThan?: number;
    businessScoreGreaterThanOrEqual?: number;
    businessScoreLessThanOrEqual?: number;
    businessScoreEquals?: number;
    businessScoreNotEquals?: number;
    businessScoreSpecified?: boolean;
    businessScoreIn?: Array<number>;
    businessScoreNotIn?: Array<number>;
    completedEquals?: GetAllCompanySurveysCompletedEqualsEnum;
    completedNotEquals?: GetAllCompanySurveysCompletedNotEqualsEnum;
    completedSpecified?: boolean;
    completedIn?: Array<GetAllCompanySurveysCompletedInEnum>;
    completedNotIn?: Array<GetAllCompanySurveysCompletedNotInEnum>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    surveyIdGreaterThan?: number;
    surveyIdLessThan?: number;
    surveyIdGreaterThanOrEqual?: number;
    surveyIdLessThanOrEqual?: number;
    surveyIdEquals?: number;
    surveyIdNotEquals?: number;
    surveyIdSpecified?: boolean;
    surveyIdIn?: Array<number>;
    surveyIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanySurveyRequest {
    id: number;
}

export interface PartialUpdateCompanySurveyRequest {
    id: number;
    companySurvey: CompanySurvey;
}

export interface UpdateCompanySurveyRequest {
    id: number;
    companySurvey: CompanySurvey;
}

/**
 * 
 */
export class CompanySurveyResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanySurveysRaw(requestParameters: CountCompanySurveysRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.updateDateGreaterThan !== undefined) {
            queryParameters['updateDate.greaterThan'] = (requestParameters.updateDateGreaterThan as any).toISOString();
        }

        if (requestParameters.updateDateLessThan !== undefined) {
            queryParameters['updateDate.lessThan'] = (requestParameters.updateDateLessThan as any).toISOString();
        }

        if (requestParameters.updateDateGreaterThanOrEqual !== undefined) {
            queryParameters['updateDate.greaterThanOrEqual'] = (requestParameters.updateDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.updateDateLessThanOrEqual !== undefined) {
            queryParameters['updateDate.lessThanOrEqual'] = (requestParameters.updateDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.updateDateEquals !== undefined) {
            queryParameters['updateDate.equals'] = (requestParameters.updateDateEquals as any).toISOString();
        }

        if (requestParameters.updateDateNotEquals !== undefined) {
            queryParameters['updateDate.notEquals'] = (requestParameters.updateDateNotEquals as any).toISOString();
        }

        if (requestParameters.updateDateSpecified !== undefined) {
            queryParameters['updateDate.specified'] = requestParameters.updateDateSpecified;
        }

        if (requestParameters.updateDateIn) {
            queryParameters['updateDate.in'] = requestParameters.updateDateIn;
        }

        if (requestParameters.updateDateNotIn) {
            queryParameters['updateDate.notIn'] = requestParameters.updateDateNotIn;
        }

        if (requestParameters.validUntilGreaterThan !== undefined) {
            queryParameters['validUntil.greaterThan'] = (requestParameters.validUntilGreaterThan as any).toISOString();
        }

        if (requestParameters.validUntilLessThan !== undefined) {
            queryParameters['validUntil.lessThan'] = (requestParameters.validUntilLessThan as any).toISOString();
        }

        if (requestParameters.validUntilGreaterThanOrEqual !== undefined) {
            queryParameters['validUntil.greaterThanOrEqual'] = (requestParameters.validUntilGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.validUntilLessThanOrEqual !== undefined) {
            queryParameters['validUntil.lessThanOrEqual'] = (requestParameters.validUntilLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.validUntilEquals !== undefined) {
            queryParameters['validUntil.equals'] = (requestParameters.validUntilEquals as any).toISOString();
        }

        if (requestParameters.validUntilNotEquals !== undefined) {
            queryParameters['validUntil.notEquals'] = (requestParameters.validUntilNotEquals as any).toISOString();
        }

        if (requestParameters.validUntilSpecified !== undefined) {
            queryParameters['validUntil.specified'] = requestParameters.validUntilSpecified;
        }

        if (requestParameters.validUntilIn) {
            queryParameters['validUntil.in'] = requestParameters.validUntilIn;
        }

        if (requestParameters.validUntilNotIn) {
            queryParameters['validUntil.notIn'] = requestParameters.validUntilNotIn;
        }

        if (requestParameters.responseContains !== undefined) {
            queryParameters['response.contains'] = requestParameters.responseContains;
        }

        if (requestParameters.responseDoesNotContain !== undefined) {
            queryParameters['response.doesNotContain'] = requestParameters.responseDoesNotContain;
        }

        if (requestParameters.responseEquals !== undefined) {
            queryParameters['response.equals'] = requestParameters.responseEquals;
        }

        if (requestParameters.responseNotEquals !== undefined) {
            queryParameters['response.notEquals'] = requestParameters.responseNotEquals;
        }

        if (requestParameters.responseSpecified !== undefined) {
            queryParameters['response.specified'] = requestParameters.responseSpecified;
        }

        if (requestParameters.responseIn) {
            queryParameters['response.in'] = requestParameters.responseIn;
        }

        if (requestParameters.responseNotIn) {
            queryParameters['response.notIn'] = requestParameters.responseNotIn;
        }

        if (requestParameters.businessScoreGreaterThan !== undefined) {
            queryParameters['businessScore.greaterThan'] = requestParameters.businessScoreGreaterThan;
        }

        if (requestParameters.businessScoreLessThan !== undefined) {
            queryParameters['businessScore.lessThan'] = requestParameters.businessScoreLessThan;
        }

        if (requestParameters.businessScoreGreaterThanOrEqual !== undefined) {
            queryParameters['businessScore.greaterThanOrEqual'] = requestParameters.businessScoreGreaterThanOrEqual;
        }

        if (requestParameters.businessScoreLessThanOrEqual !== undefined) {
            queryParameters['businessScore.lessThanOrEqual'] = requestParameters.businessScoreLessThanOrEqual;
        }

        if (requestParameters.businessScoreEquals !== undefined) {
            queryParameters['businessScore.equals'] = requestParameters.businessScoreEquals;
        }

        if (requestParameters.businessScoreNotEquals !== undefined) {
            queryParameters['businessScore.notEquals'] = requestParameters.businessScoreNotEquals;
        }

        if (requestParameters.businessScoreSpecified !== undefined) {
            queryParameters['businessScore.specified'] = requestParameters.businessScoreSpecified;
        }

        if (requestParameters.businessScoreIn) {
            queryParameters['businessScore.in'] = requestParameters.businessScoreIn;
        }

        if (requestParameters.businessScoreNotIn) {
            queryParameters['businessScore.notIn'] = requestParameters.businessScoreNotIn;
        }

        if (requestParameters.completedEquals !== undefined) {
            queryParameters['completed.equals'] = requestParameters.completedEquals;
        }

        if (requestParameters.completedNotEquals !== undefined) {
            queryParameters['completed.notEquals'] = requestParameters.completedNotEquals;
        }

        if (requestParameters.completedSpecified !== undefined) {
            queryParameters['completed.specified'] = requestParameters.completedSpecified;
        }

        if (requestParameters.completedIn) {
            queryParameters['completed.in'] = requestParameters.completedIn;
        }

        if (requestParameters.completedNotIn) {
            queryParameters['completed.notIn'] = requestParameters.completedNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.surveyIdGreaterThan !== undefined) {
            queryParameters['surveyId.greaterThan'] = requestParameters.surveyIdGreaterThan;
        }

        if (requestParameters.surveyIdLessThan !== undefined) {
            queryParameters['surveyId.lessThan'] = requestParameters.surveyIdLessThan;
        }

        if (requestParameters.surveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyId.greaterThanOrEqual'] = requestParameters.surveyIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyIdLessThanOrEqual !== undefined) {
            queryParameters['surveyId.lessThanOrEqual'] = requestParameters.surveyIdLessThanOrEqual;
        }

        if (requestParameters.surveyIdEquals !== undefined) {
            queryParameters['surveyId.equals'] = requestParameters.surveyIdEquals;
        }

        if (requestParameters.surveyIdNotEquals !== undefined) {
            queryParameters['surveyId.notEquals'] = requestParameters.surveyIdNotEquals;
        }

        if (requestParameters.surveyIdSpecified !== undefined) {
            queryParameters['surveyId.specified'] = requestParameters.surveyIdSpecified;
        }

        if (requestParameters.surveyIdIn) {
            queryParameters['surveyId.in'] = requestParameters.surveyIdIn;
        }

        if (requestParameters.surveyIdNotIn) {
            queryParameters['surveyId.notIn'] = requestParameters.surveyIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-surveys/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanySurveys(requestParameters: CountCompanySurveysRequest): Promise<number> {
        const response = await this.countCompanySurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanySurveyRaw(requestParameters: CreateCompanySurveyRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.companySurvey === null || requestParameters.companySurvey === undefined) {
            throw new runtime.RequiredError('companySurvey','Required parameter requestParameters.companySurvey was null or undefined when calling createCompanySurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-surveys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyToJSON(requestParameters.companySurvey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async createCompanySurvey(requestParameters: CreateCompanySurveyRequest): Promise<CompanySurvey> {
        const response = await this.createCompanySurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanySurveyRaw(requestParameters: DeleteCompanySurveyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanySurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanySurvey(requestParameters: DeleteCompanySurveyRequest): Promise<void> {
        await this.deleteCompanySurveyRaw(requestParameters);
    }

    /**
     */
    async getAllCompanySurveysRaw(requestParameters: GetAllCompanySurveysRequest): Promise<runtime.ApiResponse<Array<CompanySurvey>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.creationDateGreaterThan !== undefined) {
            queryParameters['creationDate.greaterThan'] = (requestParameters.creationDateGreaterThan as any).toISOString();
        }

        if (requestParameters.creationDateLessThan !== undefined) {
            queryParameters['creationDate.lessThan'] = (requestParameters.creationDateLessThan as any).toISOString();
        }

        if (requestParameters.creationDateGreaterThanOrEqual !== undefined) {
            queryParameters['creationDate.greaterThanOrEqual'] = (requestParameters.creationDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateLessThanOrEqual !== undefined) {
            queryParameters['creationDate.lessThanOrEqual'] = (requestParameters.creationDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.creationDateEquals !== undefined) {
            queryParameters['creationDate.equals'] = (requestParameters.creationDateEquals as any).toISOString();
        }

        if (requestParameters.creationDateNotEquals !== undefined) {
            queryParameters['creationDate.notEquals'] = (requestParameters.creationDateNotEquals as any).toISOString();
        }

        if (requestParameters.creationDateSpecified !== undefined) {
            queryParameters['creationDate.specified'] = requestParameters.creationDateSpecified;
        }

        if (requestParameters.creationDateIn) {
            queryParameters['creationDate.in'] = requestParameters.creationDateIn;
        }

        if (requestParameters.creationDateNotIn) {
            queryParameters['creationDate.notIn'] = requestParameters.creationDateNotIn;
        }

        if (requestParameters.updateDateGreaterThan !== undefined) {
            queryParameters['updateDate.greaterThan'] = (requestParameters.updateDateGreaterThan as any).toISOString();
        }

        if (requestParameters.updateDateLessThan !== undefined) {
            queryParameters['updateDate.lessThan'] = (requestParameters.updateDateLessThan as any).toISOString();
        }

        if (requestParameters.updateDateGreaterThanOrEqual !== undefined) {
            queryParameters['updateDate.greaterThanOrEqual'] = (requestParameters.updateDateGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.updateDateLessThanOrEqual !== undefined) {
            queryParameters['updateDate.lessThanOrEqual'] = (requestParameters.updateDateLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.updateDateEquals !== undefined) {
            queryParameters['updateDate.equals'] = (requestParameters.updateDateEquals as any).toISOString();
        }

        if (requestParameters.updateDateNotEquals !== undefined) {
            queryParameters['updateDate.notEquals'] = (requestParameters.updateDateNotEquals as any).toISOString();
        }

        if (requestParameters.updateDateSpecified !== undefined) {
            queryParameters['updateDate.specified'] = requestParameters.updateDateSpecified;
        }

        if (requestParameters.updateDateIn) {
            queryParameters['updateDate.in'] = requestParameters.updateDateIn;
        }

        if (requestParameters.updateDateNotIn) {
            queryParameters['updateDate.notIn'] = requestParameters.updateDateNotIn;
        }

        if (requestParameters.validUntilGreaterThan !== undefined) {
            queryParameters['validUntil.greaterThan'] = (requestParameters.validUntilGreaterThan as any).toISOString();
        }

        if (requestParameters.validUntilLessThan !== undefined) {
            queryParameters['validUntil.lessThan'] = (requestParameters.validUntilLessThan as any).toISOString();
        }

        if (requestParameters.validUntilGreaterThanOrEqual !== undefined) {
            queryParameters['validUntil.greaterThanOrEqual'] = (requestParameters.validUntilGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.validUntilLessThanOrEqual !== undefined) {
            queryParameters['validUntil.lessThanOrEqual'] = (requestParameters.validUntilLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.validUntilEquals !== undefined) {
            queryParameters['validUntil.equals'] = (requestParameters.validUntilEquals as any).toISOString();
        }

        if (requestParameters.validUntilNotEquals !== undefined) {
            queryParameters['validUntil.notEquals'] = (requestParameters.validUntilNotEquals as any).toISOString();
        }

        if (requestParameters.validUntilSpecified !== undefined) {
            queryParameters['validUntil.specified'] = requestParameters.validUntilSpecified;
        }

        if (requestParameters.validUntilIn) {
            queryParameters['validUntil.in'] = requestParameters.validUntilIn;
        }

        if (requestParameters.validUntilNotIn) {
            queryParameters['validUntil.notIn'] = requestParameters.validUntilNotIn;
        }

        if (requestParameters.responseContains !== undefined) {
            queryParameters['response.contains'] = requestParameters.responseContains;
        }

        if (requestParameters.responseDoesNotContain !== undefined) {
            queryParameters['response.doesNotContain'] = requestParameters.responseDoesNotContain;
        }

        if (requestParameters.responseEquals !== undefined) {
            queryParameters['response.equals'] = requestParameters.responseEquals;
        }

        if (requestParameters.responseNotEquals !== undefined) {
            queryParameters['response.notEquals'] = requestParameters.responseNotEquals;
        }

        if (requestParameters.responseSpecified !== undefined) {
            queryParameters['response.specified'] = requestParameters.responseSpecified;
        }

        if (requestParameters.responseIn) {
            queryParameters['response.in'] = requestParameters.responseIn;
        }

        if (requestParameters.responseNotIn) {
            queryParameters['response.notIn'] = requestParameters.responseNotIn;
        }

        if (requestParameters.businessScoreGreaterThan !== undefined) {
            queryParameters['businessScore.greaterThan'] = requestParameters.businessScoreGreaterThan;
        }

        if (requestParameters.businessScoreLessThan !== undefined) {
            queryParameters['businessScore.lessThan'] = requestParameters.businessScoreLessThan;
        }

        if (requestParameters.businessScoreGreaterThanOrEqual !== undefined) {
            queryParameters['businessScore.greaterThanOrEqual'] = requestParameters.businessScoreGreaterThanOrEqual;
        }

        if (requestParameters.businessScoreLessThanOrEqual !== undefined) {
            queryParameters['businessScore.lessThanOrEqual'] = requestParameters.businessScoreLessThanOrEqual;
        }

        if (requestParameters.businessScoreEquals !== undefined) {
            queryParameters['businessScore.equals'] = requestParameters.businessScoreEquals;
        }

        if (requestParameters.businessScoreNotEquals !== undefined) {
            queryParameters['businessScore.notEquals'] = requestParameters.businessScoreNotEquals;
        }

        if (requestParameters.businessScoreSpecified !== undefined) {
            queryParameters['businessScore.specified'] = requestParameters.businessScoreSpecified;
        }

        if (requestParameters.businessScoreIn) {
            queryParameters['businessScore.in'] = requestParameters.businessScoreIn;
        }

        if (requestParameters.businessScoreNotIn) {
            queryParameters['businessScore.notIn'] = requestParameters.businessScoreNotIn;
        }

        if (requestParameters.completedEquals !== undefined) {
            queryParameters['completed.equals'] = requestParameters.completedEquals;
        }

        if (requestParameters.completedNotEquals !== undefined) {
            queryParameters['completed.notEquals'] = requestParameters.completedNotEquals;
        }

        if (requestParameters.completedSpecified !== undefined) {
            queryParameters['completed.specified'] = requestParameters.completedSpecified;
        }

        if (requestParameters.completedIn) {
            queryParameters['completed.in'] = requestParameters.completedIn;
        }

        if (requestParameters.completedNotIn) {
            queryParameters['completed.notIn'] = requestParameters.completedNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.surveyIdGreaterThan !== undefined) {
            queryParameters['surveyId.greaterThan'] = requestParameters.surveyIdGreaterThan;
        }

        if (requestParameters.surveyIdLessThan !== undefined) {
            queryParameters['surveyId.lessThan'] = requestParameters.surveyIdLessThan;
        }

        if (requestParameters.surveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyId.greaterThanOrEqual'] = requestParameters.surveyIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyIdLessThanOrEqual !== undefined) {
            queryParameters['surveyId.lessThanOrEqual'] = requestParameters.surveyIdLessThanOrEqual;
        }

        if (requestParameters.surveyIdEquals !== undefined) {
            queryParameters['surveyId.equals'] = requestParameters.surveyIdEquals;
        }

        if (requestParameters.surveyIdNotEquals !== undefined) {
            queryParameters['surveyId.notEquals'] = requestParameters.surveyIdNotEquals;
        }

        if (requestParameters.surveyIdSpecified !== undefined) {
            queryParameters['surveyId.specified'] = requestParameters.surveyIdSpecified;
        }

        if (requestParameters.surveyIdIn) {
            queryParameters['surveyId.in'] = requestParameters.surveyIdIn;
        }

        if (requestParameters.surveyIdNotIn) {
            queryParameters['surveyId.notIn'] = requestParameters.surveyIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurveyFromJSON));
    }

    /**
     */
    async getAllCompanySurveys(requestParameters: GetAllCompanySurveysRequest): Promise<Array<CompanySurvey>> {
        const response = await this.getAllCompanySurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanySurveyRaw(requestParameters: GetCompanySurveyRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanySurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async getCompanySurvey(requestParameters: GetCompanySurveyRequest): Promise<CompanySurvey> {
        const response = await this.getCompanySurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanySurveyRaw(requestParameters: PartialUpdateCompanySurveyRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanySurvey.');
        }

        if (requestParameters.companySurvey === null || requestParameters.companySurvey === undefined) {
            throw new runtime.RequiredError('companySurvey','Required parameter requestParameters.companySurvey was null or undefined when calling partialUpdateCompanySurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyToJSON(requestParameters.companySurvey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanySurvey(requestParameters: PartialUpdateCompanySurveyRequest): Promise<CompanySurvey> {
        const response = await this.partialUpdateCompanySurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanySurveyRaw(requestParameters: UpdateCompanySurveyRequest): Promise<runtime.ApiResponse<CompanySurvey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanySurvey.');
        }

        if (requestParameters.companySurvey === null || requestParameters.companySurvey === undefined) {
            throw new runtime.RequiredError('companySurvey','Required parameter requestParameters.companySurvey was null or undefined when calling updateCompanySurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyToJSON(requestParameters.companySurvey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanySurvey(requestParameters: UpdateCompanySurveyRequest): Promise<CompanySurvey> {
        const response = await this.updateCompanySurveyRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountCompanySurveysCompletedEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanySurveysCompletedNotEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanySurveysCompletedInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanySurveysCompletedNotInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanySurveysCompletedEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanySurveysCompletedNotEqualsEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanySurveysCompletedInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanySurveysCompletedNotInEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}
