/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bilancio,
    BilancioFromJSON,
    BilancioFromJSONTyped,
    BilancioToJSON,
} from './';

/**
 * 
 * @export
 * @interface Bilanci
 */
export interface Bilanci {
    /**
     * 
     * @type {Array<Bilancio>}
     * @memberof Bilanci
     */
    bilancio: Array<Bilancio>;
}

export function BilanciFromJSON(json: any): Bilanci {
    return BilanciFromJSONTyped(json, false);
}

export function BilanciFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bilanci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bilancio': ((json['bilancio'] as Array<any>).map(BilancioFromJSON)),
    };
}

export function BilanciToJSON(value?: Bilanci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bilancio': ((value.bilancio as Array<any>).map(BilancioToJSON)),
    };
}


