/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolloRi,
    ProtocolloRiFromJSON,
    ProtocolloRiFromJSONTyped,
    ProtocolloRiToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolliRi
 */
export interface ProtocolliRi {
    /**
     * 
     * @type {Array<ProtocolloRi>}
     * @memberof ProtocolliRi
     */
    protocolloRi: Array<ProtocolloRi>;
}

export function ProtocolliRiFromJSON(json: any): ProtocolliRi {
    return ProtocolliRiFromJSONTyped(json, false);
}

export function ProtocolliRiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolliRi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolloRi': ((json['protocolloRi'] as Array<any>).map(ProtocolloRiFromJSON)),
    };
}

export function ProtocolliRiToJSON(value?: ProtocolliRi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolloRi': ((value.protocolloRi as Array<any>).map(ProtocolloRiToJSON)),
    };
}


