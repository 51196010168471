/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NoteElencoSoci,
    NoteElencoSociFromJSON,
    NoteElencoSociFromJSONTyped,
    NoteElencoSociToJSON,
    Soci,
    SociFromJSON,
    SociFromJSONTyped,
    SociToJSON,
} from './';

/**
 * 
 * @export
 * @interface TabellaElencoSoci
 */
export interface TabellaElencoSoci {
    /**
     * 
     * @type {Soci}
     * @memberof TabellaElencoSoci
     */
    soci?: Soci;
    /**
     * 
     * @type {NoteElencoSoci}
     * @memberof TabellaElencoSoci
     */
    noteElencoSoci?: NoteElencoSoci;
}

export function TabellaElencoSociFromJSON(json: any): TabellaElencoSoci {
    return TabellaElencoSociFromJSONTyped(json, false);
}

export function TabellaElencoSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): TabellaElencoSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'soci': !exists(json, 'soci') ? undefined : SociFromJSON(json['soci']),
        'noteElencoSoci': !exists(json, 'noteElencoSoci') ? undefined : NoteElencoSociFromJSON(json['noteElencoSoci']),
    };
}

export function TabellaElencoSociToJSON(value?: TabellaElencoSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'soci': SociToJSON(value.soci),
        'noteElencoSoci': NoteElencoSociToJSON(value.noteElencoSoci),
    };
}


