import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { AlertHelper } from "@helpers/AlertHelper";
import { ErrorHelper } from "@helpers/ErrorHelper";
import { PlatformHelper } from "@helpers/PlatformHelper";
import ModalSimpleConfirmCancel from "@screens/Modal/ModalSimpleConfirmCancel";
import ModalSimpleError from "@screens/Modal/ModalSimpleError";
import { customCompanyDataResourceApi, customCompanyResourceApi, customMarketCompetitorResourceApi, } from "@services/apis/ApiConfiguration";
import { InstantFilterToJSON, MarketCompetitor, MarketCompetitorResponseDTO } from "@services/apis/generated";
import { REMINDERS } from "expo-permissions";
import React, { useContext } from "react";
import { StyleSheet, ScrollView, TextInput, TouchableOpacity, View, Text, StatusBar, Platform } from "react-native";
import Collapsible from "react-native-collapsible";
import { CompetitorsList } from "../../../../../types";
import { i18n } from "../../../../i18n/i18n";
import AppContext from "../../../../navigation/AppContext";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import { Company } from "../../../../services/apis/generated";



export default function AddNewMarketCompetitor(props: { company: Company, competitors?: MarketCompetitorResponseDTO[] }) {

    const sharedContext = useContext(SharedContext);
    const [buttonVisible, setButtonVisible] = React.useState<boolean>(true);
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const [vatNumber, setVatNumber] = React.useState<string>();
    const appContext = React.useContext(AppContext);

    var TxtInp: TextInput | null;
    let newMarketCompetitor: MarketCompetitor;

    React.useEffect(() => {
        if(props.competitors){
            setButtonVisible(props.competitors.length == 0)
        }
    
      return () => {}
    }, [props.competitors])
    

    const searchAndAdd = () => {
        setModalVisible(false)
        customMarketCompetitorResourceApi.createMarketCompetitorForLoggedUser({
            marketCompetitorDTO: { vatNumberCompetitor: vatNumber, reference: props.company }
        }).then((marketCompetitor) => {
            customMarketCompetitorResourceApi.getAllMyMarketCompetitors().then((myMarketCompetitors) => {
                if (myMarketCompetitors)
                    sharedContext.setMyMarketCompetitors(myMarketCompetitors);
            }).catch(async (error) => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error)
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
            })
        }).catch(async (error) => {
            let errorMessage = await ErrorHelper.getServerMessageFromJson(error)
            let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
            appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
        })
        TxtInp?.clear()
    }

    const marketCompetitorOnMyCompanies = () => {
        return sharedContext.myCompanies?.find((company) => company.vatNumber == vatNumber) != undefined
    }

    return (
        <>
            {buttonVisible && //Visualizza il bottone Aggiungi
                <View style={styles.containerCollapse}>
                    <Text style={styles.labelInfoAdd}>
                        {i18n.t('components.market_competitors.info_add_competitors')}
                    </Text>
                    <TouchableOpacity
                        style={styles.buttonAdd}
                        onPress={() => { setButtonVisible(!buttonVisible) }}
                    >
                        <Text style={styles.labelInfoAdd}>
                            {i18n.t('components.market_competitors.button_add')}
                        </Text>
                    </TouchableOpacity>
                </View>}
            {!buttonVisible && //Visualizza i campi di input e aggiunta impresa
                <View style={{ flexDirection: "row" }}>
                    <TextInput
                        ref={(ref) => { TxtInp = ref }}
                        placeholder={i18n.t('buttons.placeholder_search')}
                        placeholderTextColor='#737373'
                        onEndEditing={(vat) => { PlatformHelper.isAndroid() ? setVatNumber(vat.nativeEvent.text) : null }}
                        onBlur={(vat) => { !PlatformHelper.isAndroid() ? setVatNumber(vat.nativeEvent.text) : null }}
                        style={styles.textInputRow}
                    />
                    <TouchableOpacity
                        style={styles.buttonSave}
                        activeOpacity={0.5}

                        onPress={() => { vatNumber != undefined ? setModalVisible(true) : null }
                        }
                    >
                        <Text style={styles.textAdd}>
                            {i18n.t('buttons.add')}
                        </Text>
                    </TouchableOpacity>
                </View>}
            {modalVisible && //Logica di visualizzazione del modale di conferma di spendere una ricerca
                <ModalSimpleConfirmCancel
                    msg={i18n.t('components.market_competitors.confirm_add')}
                    visible={(visible) => { setModalVisible(visible) }}
                    action={() => { searchAndAdd() }}
                />}
        </>
    )
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginLeft: 15,
        paddingVertical: 5,
        justifyContent: 'space-between'
    },
    textStart: {
        color: 'white',
        paddingVertical: 5
    },
    containerCollapse: {
        marginVertical: 20,
        flexDirection: 'column',
        alignItems: 'center'
    },
    labelInfoAdd: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30,
    },
    buttonAdd: {
        marginVertical: 7,
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius: 5,
        alignItems: 'center',
    },
    data: {
        color: 'white',
        fontSize: 11,
        paddingVertical: 5,
        alignSelf: 'center'
    },
    textInputRow: {
        flex: 0.7,
        backgroundColor: '#464646',
        padding: 8,
        borderRadius: 5,
        fontSize: 12,
        color: 'white',
        margin: 15,
        fontFamily: 'poppins',
    },
    buttonSave: {
        flex: 0.25,
        backgroundColor: '#222222',
        padding: 8,
        marginVertical: 15,
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    textAdd: {
        flex: 1,
        textAlignVertical: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 12,
        color: 'white',
    },
    deleteIcon: {
        justifyContent: 'flex-end'
    },
})