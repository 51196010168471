import hykeeStyle from '@components/hykeeStyle';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { UserExtendedTypeEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { Component, useEffect, useState } from 'react';
import { RootStackScreenProps } from '../../../types';
import SharedContext from '../../navigation/SharedContext';
import SavedCompanies from './ComponentSavedCompanies/SavedCompanies';
import SavedPartner from './ComponentSavedCompanies/SavedPartner';
import DashboardCompanySearched from './DashboardCompanySearched';
import DashboardSavedCompany from './DashboardSavedCompany';
import DashboardScreen from './DashboardScreen';
import Colors from "../../../constants/Colors";
import SharesScreen from './Component/FinancialStatements/SharesScreen';
import AdministratorScreen from './Component/FinancialStatements/AdministratorScreen';
import PartnerScreen from './Component/FinancialStatements/PartnerScreen';
import CompanyMicroScreen from './CompanyMicroScreen';

export default function SavedCompaniesScreen({ navigation, route }: RootStackScreenProps<'SavedCompaniesScreen'>) {

  const Stack = createNativeStackNavigator();
  const sharedContext = React.useContext(SharedContext);

  return (

    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={hykeeStyle.containerBack}
    >
      <StatusBar style="inverted" />
      {sharedContext.who &&
        <Stack.Navigator
          initialRouteName={sharedContext.who == UserExtendedTypeEnum.Company ? "SavedCompanies" : "SavedPartner"}>
          <Stack.Screen name="SavedCompanies" component={SavedCompanies} options={{ headerShown: false }} />
          <Stack.Screen name="SavedPartner" component={SavedPartner} options={{ headerShown: false }} />
          <Stack.Screen name="DashboardSavedCompany" component={DashboardSavedCompany} options={{ headerShown: false }} />
          <Stack.Screen name="DashboardCompanySearched" component={DashboardCompanySearched} options={{ headerShown: false }} />
          <Stack.Screen name="SharesScreen" component={SharesScreen} options={{ headerShown: false }} />
          <Stack.Screen name="AdministratorScreen" component={AdministratorScreen} options={{ headerShown: false }} />
          <Stack.Screen name="PartnerScreen" component={PartnerScreen} options={{ headerShown: false }} />
          <Stack.Screen name="CompanyMicro" component={CompanyMicroScreen} options={{ headerShown: false }} />
        </Stack.Navigator>}
    </LinearGradient>
  );
}
