import React from "react";
import { StyleSheet, Modal, TouchableOpacity, View, Text, Dimensions } from "react-native";
import SuccessModalIcon from "../../../assets/svg/SuccessModalIcon";


export default function ModalSimpleInfoText(props: { setVisible?: ((visible: boolean | null) => void) | undefined, message?: string }) {
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={true}
            onRequestClose={() => {
                props.setVisible ? props.setVisible(false) : null;
            }}
        >
            <View style={styles.modalView}>

                <View style={styles.modalText}>
                    <Text style={styles.textStyle}>
                        {props.message}
                    </Text>
                </View>
                <TouchableOpacity
                    style={[styles.button, styles.buttonClose]}
                    onPress={() => (props.setVisible ? props.setVisible(false) : null)
                    }
                >
                    <Text style={styles.buttonText}>X</Text>
                </TouchableOpacity>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({

    modalView: {
        alignSelf: 'center',
        backgroundColor: "#494949",
        marginTop: Dimensions.get('window').height / 2 - 100,
        borderRadius: 20,
        padding: 20,
        width: '85%',
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 100,
        padding: 5,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#00CC83",
        paddingHorizontal: 10,
        alignSelf: 'center',
    },
    buttonText: {
        fontSize: 18,
        color: "white",
        fontFamily: 'poppins',
        textAlign: "center",
    },
    textStyle: {
        color: "white",
        padding: 10,
        fontSize: 13,
        fontFamily: 'poppins',
        textAlign: "left"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
});