/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Certificazione,
    CertificazioneFromJSON,
    CertificazioneFromJSONTyped,
    CertificazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Certificazioni
 */
export interface Certificazioni {
    /**
     * 
     * @type {Array<Certificazione>}
     * @memberof Certificazioni
     */
    certificazione: Array<Certificazione>;
    /**
     * 
     * @type {string}
     * @memberof Certificazioni
     */
    dtUltimaModifica?: string;
}

export function CertificazioniFromJSON(json: any): Certificazioni {
    return CertificazioniFromJSONTyped(json, false);
}

export function CertificazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Certificazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificazione': ((json['certificazione'] as Array<any>).map(CertificazioneFromJSON)),
        'dtUltimaModifica': !exists(json, 'dtUltimaModifica') ? undefined : json['dtUltimaModifica'],
    };
}

export function CertificazioniToJSON(value?: Certificazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificazione': ((value.certificazione as Array<any>).map(CertificazioneToJSON)),
        'dtUltimaModifica': value.dtUltimaModifica,
    };
}


