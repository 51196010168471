import hykeeStyle from '@components/hykeeStyle';
import {NavigationHelper} from '@helpers/NavigationHelper';
import {i18n} from '@i18n/i18n';
import {useFocusEffect} from '@react-navigation/native';
import {customCompanySavedResourceApi, customUserResourceApi} from '@services/apis/ApiConfiguration';
import {CompanySaved} from '@services/apis/generated';
import {LinearGradient} from 'expo-linear-gradient';
import {StatusBar} from 'expo-status-bar';
import React, {useCallback} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {RootStackScreenProps} from '../../../../types';
import SharedContext from '../../../navigation/SharedContext';
import FavoriteComponent from './FavoriteComponent';
import Colors from "../../../../constants/Colors";
import {BaseAPIManager} from "../../../services/model/BaseAPIManager";


export default function SavedCompany({ navigation, route }: RootStackScreenProps<'SavedCompanies'>) {

    const sharedContext = React.useContext(SharedContext);

    function listCallback(favoriteElement: any) {
        customCompanySavedResourceApi.deleteCompanySavedForLoggedUser({ id: favoriteElement?.favorite?.id! }).then(res => {
            customCompanySavedResourceApi.getAllCompaniesSavedFilter({ customCompanySavedCriteria: { userId: { equals: favoriteElement.favorite.user?.id } } }).then((res) => {
                setCompaniesSaved(res);
                sharedContext.setCompaniesSaved(res);
            })
        })
    };

    const [companiesSaved, setCompaniesSaved] = React.useState<CompanySaved[]>([]);

    useFocusEffect(
        useCallback(() => {
            BaseAPIManager.contextLoadingCallback(true);
            customUserResourceApi.getMe().then((res) => {
                customCompanySavedResourceApi.getAllCompaniesSavedFilter({ customCompanySavedCriteria: { userId: { equals: res.id } } }).then((res) => {
                    setCompaniesSaved(res);
                    sharedContext.setCompaniesSaved(res);
                    BaseAPIManager.contextLoadingCallback(false);
                })
            })
        }, [])
    );

    const goToCompanySaved = (companySaved: CompanySaved) => {
        sharedContext.setSelectedCompany(companySaved.company)
        sharedContext.setSelectedCompanyLastData(companySaved.companyData)
        NavigationHelper.navigateToDashboardSavedCompany(navigation, companySaved.company?.id!);
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={hykeeStyle.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={hykeeStyle.container}>

                <Text style={hykeeStyle.title}>
                    {i18n.t('screens.saved_companies.title')}
                </Text>
            </View>
            <ScrollView style={hykeeStyle.container}>

                {sharedContext.companiesSaved?.length == 0 &&
                    <View>
                        <Text style={hykeeStyle.simplyText}>
                            {i18n.t('screens.saved_companies.no_company_saved')}
                        </Text>
                    </View>
                }
                {sharedContext.companiesSaved?.length! > 0 && sharedContext.companiesSaved?.map((favorite) => {
                    return (<FavoriteComponent
                        key={favorite.id}
                        passToParent={listCallback}
                        favorite={favorite}
                        goToCompanySearch={(companySaved) => companySaved ? goToCompanySaved(companySaved) : null}
                    />)
                })
                }

            </ScrollView>
        </LinearGradient>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    containerTitle: {
        marginTop: 50,
        marginHorizontal: 20
    },
    title: {
        alignSelf: 'flex-start',
        color: '#00CC83',
        fontSize: 20,
        fontFamily: 'poppins',
    },
});

