/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreditsBalance,
    CreditsBalanceFromJSON,
    CreditsBalanceToJSON,
} from '../models';

export interface CreateCreditsBalanceRequest {
    creditsBalance: CreditsBalance;
}

export interface DeleteCreditsBalanceRequest {
    id: number;
}

export interface GetCreditsBalanceRequest {
    id: number;
}

export interface PartialUpdateCreditsBalanceRequest {
    id: number;
    creditsBalance: CreditsBalance;
}

export interface UpdateCreditsBalanceRequest {
    id: number;
    creditsBalance: CreditsBalance;
}

/**
 * 
 */
export class CreditsBalanceResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCreditsBalanceRaw(requestParameters: CreateCreditsBalanceRequest): Promise<runtime.ApiResponse<CreditsBalance>> {
        if (requestParameters.creditsBalance === null || requestParameters.creditsBalance === undefined) {
            throw new runtime.RequiredError('creditsBalance','Required parameter requestParameters.creditsBalance was null or undefined when calling createCreditsBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/credits-balances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreditsBalanceToJSON(requestParameters.creditsBalance),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditsBalanceFromJSON(jsonValue));
    }

    /**
     */
    async createCreditsBalance(requestParameters: CreateCreditsBalanceRequest): Promise<CreditsBalance> {
        const response = await this.createCreditsBalanceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCreditsBalanceRaw(requestParameters: DeleteCreditsBalanceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCreditsBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/credits-balances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCreditsBalance(requestParameters: DeleteCreditsBalanceRequest): Promise<void> {
        await this.deleteCreditsBalanceRaw(requestParameters);
    }

    /**
     */
    async getAllCreditsBalancesRaw(): Promise<runtime.ApiResponse<Array<CreditsBalance>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/credits-balances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditsBalanceFromJSON));
    }

    /**
     */
    async getAllCreditsBalances(): Promise<Array<CreditsBalance>> {
        const response = await this.getAllCreditsBalancesRaw();
        return await response.value();
    }

    /**
     */
    async getCreditsBalanceRaw(requestParameters: GetCreditsBalanceRequest): Promise<runtime.ApiResponse<CreditsBalance>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCreditsBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/credits-balances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditsBalanceFromJSON(jsonValue));
    }

    /**
     */
    async getCreditsBalance(requestParameters: GetCreditsBalanceRequest): Promise<CreditsBalance> {
        const response = await this.getCreditsBalanceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCreditsBalanceRaw(requestParameters: PartialUpdateCreditsBalanceRequest): Promise<runtime.ApiResponse<CreditsBalance>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCreditsBalance.');
        }

        if (requestParameters.creditsBalance === null || requestParameters.creditsBalance === undefined) {
            throw new runtime.RequiredError('creditsBalance','Required parameter requestParameters.creditsBalance was null or undefined when calling partialUpdateCreditsBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/credits-balances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreditsBalanceToJSON(requestParameters.creditsBalance),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditsBalanceFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCreditsBalance(requestParameters: PartialUpdateCreditsBalanceRequest): Promise<CreditsBalance> {
        const response = await this.partialUpdateCreditsBalanceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCreditsBalanceRaw(requestParameters: UpdateCreditsBalanceRequest): Promise<runtime.ApiResponse<CreditsBalance>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCreditsBalance.');
        }

        if (requestParameters.creditsBalance === null || requestParameters.creditsBalance === undefined) {
            throw new runtime.RequiredError('creditsBalance','Required parameter requestParameters.creditsBalance was null or undefined when calling updateCreditsBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/credits-balances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreditsBalanceToJSON(requestParameters.creditsBalance),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditsBalanceFromJSON(jsonValue));
    }

    /**
     */
    async updateCreditsBalance(requestParameters: UpdateCreditsBalanceRequest): Promise<CreditsBalance> {
        const response = await this.updateCreditsBalanceRaw(requestParameters);
        return await response.value();
    }

}
