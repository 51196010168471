import { AlertHelper } from "@helpers/AlertHelper";
import { useFocusEffect } from "@react-navigation/native";
import { customCompanySurveyResourceApi } from "@services/apis/ApiConfiguration";
import { BusinessRatingDTO, BusinessRatingTableDTOSectionTableEnum, Company, CompanySurvey, CompanySurveyResourceApi } from "@services/apis/generated";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyleSheet, ScrollView, TouchableOpacity, View, Text, StatusBar, LayoutAnimation, Platform, UIManager } from "react-native";
import Collapsible from "react-native-collapsible";
import WarningIcon from "../../../../../assets/svg/WarningIcon";
import { i18n } from "../../../../i18n/i18n";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import { BusinessRatingTable } from "../SelfAssestment/BusinessRatingScreen";
import AgilityChart from "./AgilityChart";
import StrengthsRating from "./StrengthsRating";
import TableBusinessRating from "./TableBusinessRating";
import WeaknessesRating from "./WeaknessesRating";


let index = 4000;


export default function BusinessRating(props: { onMyCompanies: boolean, goToSurvey?: (() => void) | undefined }) {

    const sharedContext = React.useContext(SharedContext);

    const [seeMore, setSeeMore] = React.useState(true);
    const [companySurvey, setCompanySurvey] = useState<CompanySurvey>();
    const [agilityChartValue, setAgilityChatValue] = useState<Number>();
    const [growthChart, setGrowthChart] = useState<Number>();
    const [attitudeChart, setAttitudeChart] = useState<Number>()
    const [businessRatingTables, setBusinessRatingTables] = useState<BusinessRatingTable[]>();
    const [collapsed, setCollapsed] = useState<boolean>(true);

    useFocusEffect(
        useCallback(() => {
            getBusinessRatingTable();
        }, [])
    );

    const getBusinessRatingTable = () => {
        customCompanySurveyResourceApi.getAllCompanySurveysFilter({ customCompanySurveyCriteria: { companyId: { equals: sharedContext.selectedCompany?.id } } }).then((res) => {
            if (res.length > 0) {
                let companySurvey = res[0];
                setCompanySurvey(companySurvey)
                customCompanySurveyResourceApi.getBusinessRatingTable({ companySurvey: companySurvey! }).then((res) => {
                    if (res) {
                        setAgilityChatValue((res.agilityPerc! * 100));
                        setGrowthChart((res.growthPerc! * 100));
                        setAttitudeChart((res.attitudePerc! * 100));
                        createTableBusinessRating(res);
                    }
                }).catch(error => { console.log(error), AlertHelper.showSimpleErrorAlert() });
            }
        }).catch(error => { console.log(error), AlertHelper.showSimpleErrorAlert() });
    }

    const createTableBusinessRating = (businessRatingDTO: BusinessRatingDTO) => {
        let businessRatingTables: BusinessRatingTable[] = [];
        businessRatingDTO.tables?.map((tab) => {
            let table: BusinessRatingTable = { sectionTable: '', dataTable: [] }
            table.sectionTable = tab.sectionTable!;
            tab.dataTable!.map((dataRow) => {
                let result = "Critical";
                if (dataRow.score! / dataRow.maxScore! > 0.33) {
                    result = "Weak";
                }
                if (dataRow.score! / dataRow.maxScore! > 0.66) {
                    result = "Good";
                }
                let data = [dataRow.rowLabel, ((dataRow.score! / dataRow.maxScore!) * 100).toFixed(2) + "%", result];
                table.dataTable.push(data);
            })
            businessRatingTables.push(table);
        })
        setBusinessRatingTables(businessRatingTables);
    }

    const toggleExpanded = () => {
        setCollapsed(!collapsed);
        if (!seeMore) {
            setSeeMore(!seeMore)
        }
    }

    function seeMoreChart(tab: any) {
        index++;
        return (
            <View key={index.toString()} style={styles.table}>
                <TableBusinessRating tab={tab} />
            </View>
        )
    }



    return (
        <View style={[styles.label, { backgroundColor: (collapsed ? '#222222' : '#292929') }]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style={styles.textLabel}>
                        {i18n.t('components.headers.business_rating')}
                    </Text>
                    <Icon name={collapsed ? "angle-down" : "angle-up"} color="white" />
                </View>
            </TouchableOpacity>

            <Collapsible
                duration={1000}
                collapsed={collapsed}
                style={styles.contentAccordion}
            >
                {sharedContext.selectedCompanyLastData?.businessScore != undefined &&
                    <>
                        {/* <View style={{ marginBottom: 10, borderBottomColor: '#494949', borderBottomWidth: 1, }} /> */}
                        <View style={styles.col}>
                            {/* <View style={styles.row}>
                                <StrengthsRating />
                                <WeaknessesRating />
                            </View> */}
                            <Collapsible
                                duration={1000}
                                collapsed={seeMore}
                                style={styles.contentAccordion}
                            >
                                {seeMoreChart(businessRatingTables?.find((table) => {
                                    return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Area
                                }))}
                            </Collapsible>
                            <View
                                style={{ borderBottomColor: '#494949', borderBottomWidth: 1, }}
                            />
                            <View>
                                <AgilityChart value={agilityChartValue!} text={i18n.t("busines_rating.agility.agility")} />
                            </View>
                            <Collapsible
                                duration={1000}
                                collapsed={seeMore}
                                style={styles.contentAccordion}
                            >
                                <View style={styles.containerTable}>
                                    {seeMoreChart(businessRatingTables?.find((table) => {
                                        return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Agility
                                    }))}
                                </View>
                            </Collapsible>
                            <View style={styles.delimiter} />

                            <View>
                                <AgilityChart value={growthChart!} text={i18n.t("busines_rating.growth.growth")} />
                            </View>
                            <Collapsible
                                duration={1000}
                                collapsed={seeMore}
                                style={styles.contentAccordion}
                            >

                                <View style={styles.containerTable}>
                                    {seeMoreChart(businessRatingTables?.find((table) => {
                                        return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Growth
                                    }))}
                                </View>
                            </Collapsible>
                            <View style={styles.delimiter} />

                            <View>
                                <AgilityChart value={attitudeChart!} text={i18n.t("busines_rating.attitude.attitude")} />
                            </View>
                            <Collapsible
                                duration={1000}
                                collapsed={seeMore}
                                style={styles.contentAccordion}
                            >

                                <View style={styles.containerTable}>
                                    {seeMoreChart(businessRatingTables?.find((table) => {
                                        return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Attitude
                                    }))}
                                </View>
                            </Collapsible>
                            <View style={styles.delimiter} />
                        </View>
                        <TouchableOpacity
                            style={styles.buttonSeeMore}
                            onPress={() => { setSeeMore(!seeMore) }}
                        >
                            <Text style={styles.textSeeMore}>
                                {!seeMore ? i18n.t('buttons.see_less') : i18n.t('buttons.see_more')}
                            </Text>
                        </TouchableOpacity>
                    </>
                }
                {sharedContext.selectedCompanyLastData?.businessScore == undefined &&
                    <TouchableOpacity
                        activeOpacity={0.5}
                        onPress={() => props.goToSurvey ? props.goToSurvey() : null}
                    >
                        <View style={styles.containerError}>
                            <View style={styles.containerIconError}>
                                <WarningIcon width={70} height={70} />
                            </View>
                            <View style={[styles.containerIconError]}>
                                <Text style={styles.labelError}>
                                    {props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')}
                                </Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                }
            </Collapsible>

        </View>
    );
}

const styles = StyleSheet.create({

    header: {
        flexDirection: "row",
        flex: 1,
        alignItems: 'center',
    },
    labelError: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 15,
        marginLeft: 15,
        textAlign: 'center',
        padding: 5
    },
    container: {
        flexDirection: "column"
    },
    containerError: {
        flexDirection: "column",
    },
    containerIconError: {
        flexDirection: "column",
        alignSelf: 'center',
        justifyContent: 'center'
    },
    table: {
        flex: 1,

    },
    delimiter: {
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#505050',
    },
    col: {
        flexDirection: 'column',
        height: 'auto'
    },
    row: {
        flexDirection: 'row',
        flex: 1,
    },
    contentAccordion: {
        backgroundColor: "#292929",
        flexDirection: "column",
        height: 'auto'
    },
    containerTable: {
        marginBottom: 15
    },
    label: {
        marginBottom: 10,
        borderRadius: 5,
        display: "flex",
    },
    textLabel: {
        paddingLeft: 15,
        paddingVertical: 10,
        flex: 0.95,
        alignSelf: "flex-start",
        color: "white",
        textAlign: 'left',
        fontWeight: 'bold',
    },
    bodyAccordion: {
        backgroundColor: "white",
        flex: 1,
    },
    buttonSeeMore: {
        alignSelf: 'center'
    },
    textSeeMore: {
        color: '#00CC83',
        padding: 15,
        fontFamily: 'poppins',
        fontSize: 16,
        textDecorationLine: 'underline',
    },
});