import React from "react";
import {StyleSheet,Alert, Modal, Image,ScrollView, TouchableOpacity, View,Text, StatusBar, TextInput, Dimensions } from "react-native";
import SuccessModalIcon from "../../../assets/svg/SuccessModalIcon";
import { i18n } from "../../i18n/i18n";


export default function ModalInfo(props:{setVisible?:((visible:boolean|null) => void) | undefined, message?:string}) {
    const message = props.message=='website'?i18n.t('modal.success_website'):
                    props.message=='registration'?i18n.t('modal.success_registration'):null
    return( 
            <Modal
                animationType="fade"
                transparent={true}
                visible={true}
                onRequestClose={() => {
                props.setVisible?props.setVisible(false):null;
                }}
            > 
                <View style={styles.modalView}>
                    <View style={{padding:5}}>
                        <SuccessModalIcon width={250} height={80}/>
                    </View>
                    
                    <View style={styles.modalText}>
                        <Text style={styles.textStyle}>
                        {message}
                        </Text>
                    </View>
                <TouchableOpacity
                    style={[styles.button, styles.buttonClose]}
                    onPress={() => (props.setVisible?props.setVisible(false):null)
                    }
                >
                    <Text style={styles.buttonText}>OK</Text>
                </TouchableOpacity>
                </View>
            </Modal>
    );
}

const styles = StyleSheet.create({    
    
    modalView: {
        alignSelf:'center',
        backgroundColor: "white",
        marginTop:Dimensions.get('window').height/2-100,
        borderRadius: 20,
        padding: 35,
        width:'85%',
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 10,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#00CC83",
        paddingHorizontal:40,
        alignSelf:'center',
    },
    buttonText: {
        fontSize:18,
        color: "white",
        fontFamily:'poppins',
        textAlign: "center",
    },
    textStyle: {
        color: "black",
        padding:10,
        fontSize:20,
        fontFamily:'poppins',
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
});