/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpresaSubentrante
 */
export interface ImpresaSubentrante {
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    titoloSubentro?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    ctitoloSubentro?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    nri?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSubentrante
     */
    nrd?: string;
}

export function ImpresaSubentranteFromJSON(json: any): ImpresaSubentrante {
    return ImpresaSubentranteFromJSONTyped(json, false);
}

export function ImpresaSubentranteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpresaSubentrante {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'titoloSubentro': !exists(json, 'titoloSubentro') ? undefined : json['titoloSubentro'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'ctitoloSubentro': !exists(json, 'ctitoloSubentro') ? undefined : json['ctitoloSubentro'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nri': !exists(json, 'nri') ? undefined : json['nri'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
    };
}

export function ImpresaSubentranteToJSON(value?: ImpresaSubentrante | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominazione': value.denominazione,
        'cciaa': value.cciaa,
        'titoloSubentro': value.titoloSubentro,
        'cfiscale': value.cfiscale,
        'ctitoloSubentro': value.ctitoloSubentro,
        'nrea': value.nrea,
        'nri': value.nri,
        'nrd': value.nrd,
    };
}


