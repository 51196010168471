/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TipoProceduraConcorsuale,
    TipoProceduraConcorsualeFromJSON,
    TipoProceduraConcorsualeFromJSONTyped,
    TipoProceduraConcorsualeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TipiProcedureConcorsuali
 */
export interface TipiProcedureConcorsuali {
    /**
     * 
     * @type {Array<TipoProceduraConcorsuale>}
     * @memberof TipiProcedureConcorsuali
     */
    tipoProceduraConcorsuale?: Array<TipoProceduraConcorsuale>;
    /**
     * 
     * @type {string}
     * @memberof TipiProcedureConcorsuali
     */
    cciaaFuoriProvincia?: string;
}

export function TipiProcedureConcorsualiFromJSON(json: any): TipiProcedureConcorsuali {
    return TipiProcedureConcorsualiFromJSONTyped(json, false);
}

export function TipiProcedureConcorsualiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipiProcedureConcorsuali {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoProceduraConcorsuale': !exists(json, 'tipoProceduraConcorsuale') ? undefined : ((json['tipoProceduraConcorsuale'] as Array<any>).map(TipoProceduraConcorsualeFromJSON)),
        'cciaaFuoriProvincia': !exists(json, 'cciaaFuoriProvincia') ? undefined : json['cciaaFuoriProvincia'],
    };
}

export function TipiProcedureConcorsualiToJSON(value?: TipiProcedureConcorsuali | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoProceduraConcorsuale': value.tipoProceduraConcorsuale === undefined ? undefined : ((value.tipoProceduraConcorsuale as Array<any>).map(TipoProceduraConcorsualeToJSON)),
        'cciaaFuoriProvincia': value.cciaaFuoriProvincia,
    };
}


