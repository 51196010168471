/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CapitaleInvestito,
    CapitaleInvestitoFromJSON,
    CapitaleInvestitoFromJSONTyped,
    CapitaleInvestitoToJSON,
    CapitaleSociale,
    CapitaleSocialeFromJSON,
    CapitaleSocialeFromJSONTyped,
    CapitaleSocialeToJSON,
    ComposizioneQuote,
    ComposizioneQuoteFromJSON,
    ComposizioneQuoteFromJSONTyped,
    ComposizioneQuoteToJSON,
    ConferimentiBenefici,
    ConferimentiBeneficiFromJSON,
    ConferimentiBeneficiFromJSONTyped,
    ConferimentiBeneficiToJSON,
    DatiBilanci,
    DatiBilanciFromJSON,
    DatiBilanciFromJSONTyped,
    DatiBilanciToJSON,
    FinanziamentoSpecificoAffare,
    FinanziamentoSpecificoAffareFromJSON,
    FinanziamentoSpecificoAffareFromJSONTyped,
    FinanziamentoSpecificoAffareToJSON,
    FondoConsortile,
    FondoConsortileFromJSON,
    FondoConsortileFromJSONTyped,
    FondoConsortileToJSON,
    PatrimonioSpecificoAffare,
    PatrimonioSpecificoAffareFromJSON,
    PatrimonioSpecificoAffareFromJSONTyped,
    PatrimonioSpecificoAffareToJSON,
    StrumentiFinanziari,
    StrumentiFinanziariFromJSON,
    StrumentiFinanziariFromJSONTyped,
    StrumentiFinanziariToJSON,
    ValoreNominaleConferimenti,
    ValoreNominaleConferimentiFromJSON,
    ValoreNominaleConferimentiFromJSONTyped,
    ValoreNominaleConferimentiToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoPatrimonialiFinanziarie
 */
export interface InfoPatrimonialiFinanziarie {
    /**
     * 
     * @type {CapitaleInvestito}
     * @memberof InfoPatrimonialiFinanziarie
     */
    capitaleInvestito?: CapitaleInvestito;
    /**
     * 
     * @type {FondoConsortile}
     * @memberof InfoPatrimonialiFinanziarie
     */
    fondoConsortile?: FondoConsortile;
    /**
     * 
     * @type {ValoreNominaleConferimenti}
     * @memberof InfoPatrimonialiFinanziarie
     */
    valoreNominaleConferimenti?: ValoreNominaleConferimenti;
    /**
     * 
     * @type {CapitaleSociale}
     * @memberof InfoPatrimonialiFinanziarie
     */
    capitaleSociale?: CapitaleSociale;
    /**
     * 
     * @type {ComposizioneQuote}
     * @memberof InfoPatrimonialiFinanziarie
     */
    composizioneQuote?: ComposizioneQuote;
    /**
     * 
     * @type {ConferimentiBenefici}
     * @memberof InfoPatrimonialiFinanziarie
     */
    conferimentiBenefici?: ConferimentiBenefici;
    /**
     * 
     * @type {StrumentiFinanziari}
     * @memberof InfoPatrimonialiFinanziarie
     */
    strumentiFinanziari?: StrumentiFinanziari;
    /**
     * 
     * @type {string}
     * @memberof InfoPatrimonialiFinanziarie
     */
    diminuzioneCapitale?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoPatrimonialiFinanziarie
     */
    offertaAzioni?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoPatrimonialiFinanziarie
     */
    anticipataConversione?: string;
    /**
     * 
     * @type {PatrimonioSpecificoAffare}
     * @memberof InfoPatrimonialiFinanziarie
     */
    patrimonioSpecificoAffare?: PatrimonioSpecificoAffare;
    /**
     * 
     * @type {FinanziamentoSpecificoAffare}
     * @memberof InfoPatrimonialiFinanziarie
     */
    finanziamentoSpecificoAffare?: FinanziamentoSpecificoAffare;
    /**
     * 
     * @type {DatiBilanci}
     * @memberof InfoPatrimonialiFinanziarie
     */
    datiBilanci?: DatiBilanci;
    /**
     * 
     * @type {string}
     * @memberof InfoPatrimonialiFinanziarie
     */
    fpresenzaInfo?: string;
}

export function InfoPatrimonialiFinanziarieFromJSON(json: any): InfoPatrimonialiFinanziarie {
    return InfoPatrimonialiFinanziarieFromJSONTyped(json, false);
}

export function InfoPatrimonialiFinanziarieFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoPatrimonialiFinanziarie {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'capitaleInvestito': !exists(json, 'capitaleInvestito') ? undefined : CapitaleInvestitoFromJSON(json['capitaleInvestito']),
        'fondoConsortile': !exists(json, 'fondoConsortile') ? undefined : FondoConsortileFromJSON(json['fondoConsortile']),
        'valoreNominaleConferimenti': !exists(json, 'valoreNominaleConferimenti') ? undefined : ValoreNominaleConferimentiFromJSON(json['valoreNominaleConferimenti']),
        'capitaleSociale': !exists(json, 'capitaleSociale') ? undefined : CapitaleSocialeFromJSON(json['capitaleSociale']),
        'composizioneQuote': !exists(json, 'composizioneQuote') ? undefined : ComposizioneQuoteFromJSON(json['composizioneQuote']),
        'conferimentiBenefici': !exists(json, 'conferimentiBenefici') ? undefined : ConferimentiBeneficiFromJSON(json['conferimentiBenefici']),
        'strumentiFinanziari': !exists(json, 'strumentiFinanziari') ? undefined : StrumentiFinanziariFromJSON(json['strumentiFinanziari']),
        'diminuzioneCapitale': !exists(json, 'diminuzioneCapitale') ? undefined : json['diminuzioneCapitale'],
        'offertaAzioni': !exists(json, 'offertaAzioni') ? undefined : json['offertaAzioni'],
        'anticipataConversione': !exists(json, 'anticipataConversione') ? undefined : json['anticipataConversione'],
        'patrimonioSpecificoAffare': !exists(json, 'patrimonioSpecificoAffare') ? undefined : PatrimonioSpecificoAffareFromJSON(json['patrimonioSpecificoAffare']),
        'finanziamentoSpecificoAffare': !exists(json, 'finanziamentoSpecificoAffare') ? undefined : FinanziamentoSpecificoAffareFromJSON(json['finanziamentoSpecificoAffare']),
        'datiBilanci': !exists(json, 'datiBilanci') ? undefined : DatiBilanciFromJSON(json['datiBilanci']),
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function InfoPatrimonialiFinanziarieToJSON(value?: InfoPatrimonialiFinanziarie | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'capitaleInvestito': CapitaleInvestitoToJSON(value.capitaleInvestito),
        'fondoConsortile': FondoConsortileToJSON(value.fondoConsortile),
        'valoreNominaleConferimenti': ValoreNominaleConferimentiToJSON(value.valoreNominaleConferimenti),
        'capitaleSociale': CapitaleSocialeToJSON(value.capitaleSociale),
        'composizioneQuote': ComposizioneQuoteToJSON(value.composizioneQuote),
        'conferimentiBenefici': ConferimentiBeneficiToJSON(value.conferimentiBenefici),
        'strumentiFinanziari': StrumentiFinanziariToJSON(value.strumentiFinanziari),
        'diminuzioneCapitale': value.diminuzioneCapitale,
        'offertaAzioni': value.offertaAzioni,
        'anticipataConversione': value.anticipataConversione,
        'patrimonioSpecificoAffare': PatrimonioSpecificoAffareToJSON(value.patrimonioSpecificoAffare),
        'finanziamentoSpecificoAffare': FinanziamentoSpecificoAffareToJSON(value.finanziamentoSpecificoAffare),
        'datiBilanci': DatiBilanciToJSON(value.datiBilanci),
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


