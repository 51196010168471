import React, { useCallback } from "react";
import SharedContext from "../../../../navigation/SharedContext";
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import hykeeStyle from "@components/hykeeStyle";
import Icon from "@screens/Tools/Icon";
import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { InsightEnum } from "../../../../../types";
import Colors from "../../../../../constants/Colors";
import { LinearGradient } from "expo-linear-gradient";
import { Insight, InsightCurrentInsightStateEnum, InsightLikeLikeStateEnum, UserExtendedTypeEnum } from "@services/apis/generated";
import { customInsightLikeResourceApi, customInsightResourceApi } from "@services/apis/ApiConfiguration";
import { useFocusEffect } from "@react-navigation/native";



export default function InsightComponent(props: { insight: Insight, navigation?: any, type?: InsightEnum }) {

    const [likes, setLikes] = React.useState<number>(props.insight.likeCount!);
    const [dislikes, setDislikes] = React.useState<number>(props.insight.dislikeCount!);

    const sharedContext = React.useContext(SharedContext);
    const dateOfPublication = Intl.DateTimeFormat().format(props.insight.dateOfPublication);
    const [likeDislike, setLikeDislike] = React.useState<InsightLikeLikeStateEnum>();

    //TODO: go to detail
    const goToDetailOrEdit = () => {
        if (props.type == InsightEnum.Draft) {
            NavigationHelper.navigateGoToEditInsight(props.navigation, { ...props.insight, likeCount: likes, dislikeCount: dislikes });
            return;
        }

        NavigationHelper.navigateToInsightDetail(props.navigation, { ...props.insight, likeCount: likes, dislikeCount: dislikes });
    }

    React.useEffect(() => {
        setLikes(props.insight.likeCount!);
        setDislikes(props.insight.dislikeCount!);
    }, [props.insight])

    useFocusEffect(
        useCallback(() => {
            getLikes();
            getMyLikes();
        }, []))

    function getMyLikes() {
        if (props.insight.id) {
            customInsightLikeResourceApi.getMyLikesForInsight({ id: props.insight.id })
                .then((like) => {
                    if (like.id) {
                        if (like.likeState == InsightLikeLikeStateEnum.Like) {
                            setLikeDislike(InsightLikeLikeStateEnum.Like);
                        } else if (like.likeState == InsightLikeLikeStateEnum.Dislike) {
                            setLikeDislike(InsightLikeLikeStateEnum.Dislike);
                        }
                    }

                }).catch((e) => {
                    console.log(e);
                })
        }
    }

    function getLikes() {
        if (props.insight.id) {
            customInsightResourceApi.getInsightById({ id: props.insight.id })
                .then((insight) => {
                    setLikes(insight.likeCount!);
                    setDislikes(insight.dislikeCount!);
                }).catch((e) => {
                    console.log(e)
                })
        }
    }

    const setPreference = (preference: InsightLikeLikeStateEnum): void => {
        customInsightLikeResourceApi.likeOrDislikePost({
            insightLike: {
                insight: props.insight,
                likeState: preference
            }
        }).then(() => {
            getLikes();
            getMyLikes();
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={hykeeStyle.containerBack}
        >

            {props.insight &&
                <TouchableOpacity
                    style={[styles.insightContainer, !props.type ? styles.unread : styles.read]}
                    onPress={goToDetailOrEdit}>
                    {/**SECTOR - IMPORTANT */}
                    {/* <View style={styles.insightSector}>
                        {props.insight.sector && <Text style={styles.insightSectorText}> {props.insight.sector} </Text>}
                        {(!props.insight.read && !props.type) && <Icon name={"circle"} color={"#FF0000"}></Icon>}
                    </View> */}
                    {/** TITLE */}
                    <View style={styles.insightTitle}>
                        {props.insight.title && <Text style={styles.insightTitleText}> {props.insight.title} </Text>}
                    </View>
                    {/** DESCRIPTION */}
                    <View style={styles.insightDescription}>
                        {props.insight.description && <Text numberOfLines={3} style={styles.insightDescriptionText}> {props.insight.description} </Text>}
                    </View>
                    <View style={styles.dataPreferenceContainer}>
                        {/** DATA */}
                        <View style={styles.insightData}>
                            {props.insight.dateOfPublication && <Text style={styles.insightDataText}> {dateOfPublication} </Text>}
                        </View>
                        {/** LIKE - DISLIKE */}
                        {props.insight.currentInsightState == InsightCurrentInsightStateEnum.Published &&
                            <View style={styles.insightLike}>
                                {props.insight.currentInsightState == InsightCurrentInsightStateEnum.Published &&
                                    <TouchableOpacity onPress={() => setPreference(InsightLikeLikeStateEnum.Like)} disabled={sharedContext.who == UserExtendedTypeEnum.Consultant}>
                                        <Text style={styles.insightLikeText}>
                                            <Icon name={"thumbs-up"} color={likeDislike == InsightLikeLikeStateEnum.Like ? BackgroundColorButtonGreen() : "#737373"}></Icon>{likes}
                                        </Text>
                                    </TouchableOpacity>}
                                {props.insight.currentInsightState == InsightCurrentInsightStateEnum.Published &&
                                    <TouchableOpacity onPress={() => setPreference(InsightLikeLikeStateEnum.Dislike)} disabled={sharedContext.who == UserExtendedTypeEnum.Consultant}>
                                        <Text style={styles.insightLikeText}>
                                            <Icon name={"thumbs-down"} color={likeDislike == InsightLikeLikeStateEnum.Dislike ? "#FF0000" : "#737373"}></Icon>{dislikes}
                                        </Text>
                                    </TouchableOpacity>}
                            </View>
                        }

                    </View>
                </TouchableOpacity>
            }

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    insightContainer: {
        flexDirection: 'column',
        padding: 10
    },
    insightSector: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 8
    },
    insightSectorText: {
        color: 'white',
        fontSize: 13,
        fontFamily: 'poppins'
    },
    insightTitle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: 16
    },
    insightTitleText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 13,
        fontFamily: 'poppins'
    },
    insightDescription: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: 12,
    },
    insightDescriptionText: {
        color: 'white',
        fontSize: 13,
        fontFamily: 'poppins'
    },
    dataPreferenceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    insightData: {
        flexDirection: 'row',
        alignSelf: 'center'
    },
    insightDataText: {
        color: 'white',
        fontSize: 13,
        fontFamily: 'poppins'
    },
    insightLike: {
        flexDirection: 'row',
        alignSelf: 'center'
    },
    insightLikeText: {
        color: 'white',
        fontSize: 13,
        padding: 8,
        fontFamily: 'poppins'
    },
    read: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white'
    },
    unread: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: BackgroundColorButtonGreen()
    }
});


