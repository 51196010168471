/*
 * Copyright (C) Hykee - All Rights Reserved
 *
 * @project    hykee-react.nosync
 * @file       NotificationHelper.tsx
 * @author     Christian Ascone
 * @date       03/05/2022, 12:12
 */

import React from 'react';
import {Platform} from "react-native";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import {customPushTokenResourceApi} from "../services/apis/ApiConfiguration";
import {Logger} from "./Logger";
import {PushToken, PushTokenOperatingSystemEnum} from "../services/apis/generated";
import {PlatformHelper} from "./PlatformHelper";
import {readPushToken, storePushToken} from "@helpers/StorageHelper";

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
    }),
});

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
    }),
});

export class NotificationHelper {
    protected static readonly GRANTED = 'granted';

    static registerForPushNotificationsAsync = async () => {
        if (Device.isDevice && !PlatformHelper.isWeb()) {
            const {status: existingStatus} = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== this.GRANTED) {
                const {status} = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== this.GRANTED) {
                Logger.warn(NotificationHelper.name, 'Failed to get push token for push notification!');
                return;
            }
            const token = (await Notifications.getExpoPushTokenAsync()).data;
            console.log(token);
            let platform: PushTokenOperatingSystemEnum = PlatformHelper.isIos() ? PushTokenOperatingSystemEnum.Ios : PushTokenOperatingSystemEnum.Android;
            let pushToken: PushToken = {
                token: token,
                operatingSystem: platform
            };
            customPushTokenResourceApi.createMyPushToken({pushToken: pushToken}).then(res => {
                Logger.log(NotificationHelper.name, res);
                storePushToken(pushToken.token!)
            }).catch(error => Logger.warn(NotificationHelper.name, "Cannot create push token"));
        } else {
            Logger.warn(NotificationHelper.name, 'Must use physical device for Push Notifications');
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }
    };
}