/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionFromJSONTyped,
    SurveyQuestionToJSON,
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionResponseDTO
 */
export interface QuestionResponseDTO {
    /**
     * 
     * @type {SurveyQuestion}
     * @memberof QuestionResponseDTO
     */
    surveyQuestion?: SurveyQuestion;
    /**
     * 
     * @type {Array<SurveyQuestionResponse>}
     * @memberof QuestionResponseDTO
     */
    surveyQuestionResponses?: Array<SurveyQuestionResponse>;
}

export function QuestionResponseDTOFromJSON(json: any): QuestionResponseDTO {
    return QuestionResponseDTOFromJSONTyped(json, false);
}

export function QuestionResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyQuestion': !exists(json, 'surveyQuestion') ? undefined : SurveyQuestionFromJSON(json['surveyQuestion']),
        'surveyQuestionResponses': !exists(json, 'surveyQuestionResponses') ? undefined : ((json['surveyQuestionResponses'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
    };
}

export function QuestionResponseDTOToJSON(value?: QuestionResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyQuestion': SurveyQuestionToJSON(value.surveyQuestion),
        'surveyQuestionResponses': value.surveyQuestionResponses === undefined ? undefined : ((value.surveyQuestionResponses as Array<any>).map(SurveyQuestionResponseToJSON)),
    };
}


