/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotaPartecipazione,
    NotaPartecipazioneFromJSON,
    NotaPartecipazioneFromJSONTyped,
    NotaPartecipazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotePartecipazione
 */
export interface NotePartecipazione {
    /**
     * 
     * @type {Array<NotaPartecipazione>}
     * @memberof NotePartecipazione
     */
    notaPartecipazione: Array<NotaPartecipazione>;
}

export function NotePartecipazioneFromJSON(json: any): NotePartecipazione {
    return NotePartecipazioneFromJSONTyped(json, false);
}

export function NotePartecipazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotePartecipazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notaPartecipazione': ((json['notaPartecipazione'] as Array<any>).map(NotaPartecipazioneFromJSON)),
    };
}

export function NotePartecipazioneToJSON(value?: NotePartecipazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notaPartecipazione': ((value.notaPartecipazione as Array<any>).map(NotaPartecipazioneToJSON)),
    };
}


