/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserConsultantInvite,
    UserConsultantInviteFromJSON,
    UserConsultantInviteToJSON,
} from '../models';

export interface CreateUserConsultantInviteRequest {
    userConsultantInvite: UserConsultantInvite;
}

export interface DeleteUserConsultantInviteRequest {
    id: number;
}

export interface GetUserConsultantInviteRequest {
    id: number;
}

export interface PartialUpdateUserConsultantInviteRequest {
    id: number;
    userConsultantInvite: UserConsultantInvite;
}

export interface UpdateUserConsultantInviteRequest {
    id: number;
    userConsultantInvite: UserConsultantInvite;
}

/**
 * 
 */
export class UserConsultantInviteResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserConsultantInviteRaw(requestParameters: CreateUserConsultantInviteRequest): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.userConsultantInvite === null || requestParameters.userConsultantInvite === undefined) {
            throw new runtime.RequiredError('userConsultantInvite','Required parameter requestParameters.userConsultantInvite was null or undefined when calling createUserConsultantInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteToJSON(requestParameters.userConsultantInvite),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async createUserConsultantInvite(requestParameters: CreateUserConsultantInviteRequest): Promise<UserConsultantInvite> {
        const response = await this.createUserConsultantInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserConsultantInviteRaw(requestParameters: DeleteUserConsultantInviteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConsultantInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserConsultantInvite(requestParameters: DeleteUserConsultantInviteRequest): Promise<void> {
        await this.deleteUserConsultantInviteRaw(requestParameters);
    }

    /**
     */
    async getAllUserConsultantInvitesRaw(): Promise<runtime.ApiResponse<Array<UserConsultantInvite>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantInviteFromJSON));
    }

    /**
     */
    async getAllUserConsultantInvites(): Promise<Array<UserConsultantInvite>> {
        const response = await this.getAllUserConsultantInvitesRaw();
        return await response.value();
    }

    /**
     */
    async getUserConsultantInviteRaw(requestParameters: GetUserConsultantInviteRequest): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserConsultantInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async getUserConsultantInvite(requestParameters: GetUserConsultantInviteRequest): Promise<UserConsultantInvite> {
        const response = await this.getUserConsultantInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserConsultantInviteRaw(requestParameters: PartialUpdateUserConsultantInviteRequest): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserConsultantInvite.');
        }

        if (requestParameters.userConsultantInvite === null || requestParameters.userConsultantInvite === undefined) {
            throw new runtime.RequiredError('userConsultantInvite','Required parameter requestParameters.userConsultantInvite was null or undefined when calling partialUpdateUserConsultantInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteToJSON(requestParameters.userConsultantInvite),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserConsultantInvite(requestParameters: PartialUpdateUserConsultantInviteRequest): Promise<UserConsultantInvite> {
        const response = await this.partialUpdateUserConsultantInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserConsultantInviteRaw(requestParameters: UpdateUserConsultantInviteRequest): Promise<runtime.ApiResponse<UserConsultantInvite>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserConsultantInvite.');
        }

        if (requestParameters.userConsultantInvite === null || requestParameters.userConsultantInvite === undefined) {
            throw new runtime.RequiredError('userConsultantInvite','Required parameter requestParameters.userConsultantInvite was null or undefined when calling updateUserConsultantInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteToJSON(requestParameters.userConsultantInvite),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteFromJSON(jsonValue));
    }

    /**
     */
    async updateUserConsultantInvite(requestParameters: UpdateUserConsultantInviteRequest): Promise<UserConsultantInvite> {
        const response = await this.updateUserConsultantInviteRaw(requestParameters);
        return await response.value();
    }

}
