import hykeeStyle from '@components/hykeeStyle';
import { i18n } from '@i18n/i18n';
import Icon from '@screens/Tools/Icon';
import { Company, CompanySaved, User } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Image, Text } from 'react-native';

//Todo: date -- parse
export default function FavoriteComponent(props: { favorite: CompanySaved, passToParent: any, goToCompanySearch: (comapnySaved: CompanySaved | undefined) => void }) {

    return (
        <>
            <LinearGradient
                colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                locations={[0.4, 0.8]}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.borderGradient}
            >
                <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                >
                    <View style={hykeeStyle.row} >
                        <TouchableOpacity style={[hykeeStyle.col,{flex:0.8}]}
                            onPress={() => {
                                props.goToCompanySearch(props.favorite ? props.favorite : undefined)
                            }}>
                            <Text style={hykeeStyle.textLabel}>
                                {props.favorite.company?.name}
                            </Text>
                            <View style={styles.contentLocation}>
                                <Icon name={'map-marker'} color={'#00CC83'} />
                                <Text style={[styles.textUnderTable,{marginRight:20}]}>
                                    {props.favorite.companyData?.mainAddress}
                                </Text>
                                <Icon name={'calendar'} color={'#00CC83'} />
                                <Text style={[styles.textUnderTable,{marginRight:10}]}>
                                    {moment(props.favorite.date).format("DD/MM/yyyy")}
                                </Text>
                                <Text style={[styles.textUnderTable,{color:'#00CC83',fontWeight:'bold',fontSize:13}]}>
                                    {i18n.t('screens.saved_companies.p_iva')}
                                </Text>
                                <Text style={[styles.textUnderTable,{marginLeft:-5}]}>
                                    {props.favorite.company?.vatNumber}
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.contentImg}
                            onPress={() => {
                                props.passToParent({ favorite: props.favorite })
                            }}>

                            <Image
                                source={require('../../../../assets/images/star.png')}
                                style={{ width: 30, height: 30, flex: 1, alignSelf: 'flex-end', marginRight: 10, resizeMode: 'contain' }}
                            />

                        </TouchableOpacity>
                    </View>

                </LinearGradient>
            </LinearGradient>
        </>
    );
}

const styles = StyleSheet.create({
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
        overflow: 'hidden'
    },
    contentLocation: {
        flexDirection: 'row',
        alignContent: 'center',
        padding: 5,
    },
    textUnderTable: {
        fontSize: 11,
        paddingHorizontal: 5,
        fontFamily: 'poppins',
        color: 'white',
    },
    contentImg: {
        flex:0.2
    },
})