/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Clausole,
    ClausoleFromJSON,
    ClausoleFromJSONTyped,
    ClausoleToJSON,
    DichiarazioniIncubatore,
    DichiarazioniIncubatoreFromJSON,
    DichiarazioniIncubatoreFromJSONTyped,
    DichiarazioniIncubatoreToJSON,
    DichiarazioniPmi,
    DichiarazioniPmiFromJSON,
    DichiarazioniPmiFromJSONTyped,
    DichiarazioniPmiToJSON,
    DichiarazioniScuolaLavoro,
    DichiarazioniScuolaLavoroFromJSON,
    DichiarazioniScuolaLavoroFromJSONTyped,
    DichiarazioniScuolaLavoroToJSON,
    DichiarazioniStartUp,
    DichiarazioniStartUpFromJSON,
    DichiarazioniStartUpFromJSONTyped,
    DichiarazioniStartUpToJSON,
} from './';

/**
 * 
 * @export
 * @interface Riferimenti
 */
export interface Riferimenti {
    /**
     * 
     * @type {Clausole}
     * @memberof Riferimenti
     */
    clausole?: Clausole;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    modificheStatutarie?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    depositoStatuto?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    modificaStatuto?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    aggregazioneImprese?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    provvedimentiGiudice?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    effettiDifferiti?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    arbitrato?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    condizioniSospensive?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    provvedimentiConservatore?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    provvedimentiAutorita?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    gruppiSocietari?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    accordiPartecipazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    contrattoRete?: string;
    /**
     * 
     * @type {string}
     * @memberof Riferimenti
     */
    attiTradotti?: string;
    /**
     * 
     * @type {DichiarazioniStartUp}
     * @memberof Riferimenti
     */
    dichiarazioniStartUp?: DichiarazioniStartUp;
    /**
     * 
     * @type {DichiarazioniIncubatore}
     * @memberof Riferimenti
     */
    dichiarazioniIncubatore?: DichiarazioniIncubatore;
    /**
     * 
     * @type {DichiarazioniPmi}
     * @memberof Riferimenti
     */
    dichiarazioniPmi?: DichiarazioniPmi;
    /**
     * 
     * @type {DichiarazioniScuolaLavoro}
     * @memberof Riferimenti
     */
    dichiarazioniScuolaLavoro?: DichiarazioniScuolaLavoro;
}

export function RiferimentiFromJSON(json: any): Riferimenti {
    return RiferimentiFromJSONTyped(json, false);
}

export function RiferimentiFromJSONTyped(json: any, ignoreDiscriminator: boolean): Riferimenti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clausole': !exists(json, 'clausole') ? undefined : ClausoleFromJSON(json['clausole']),
        'modificheStatutarie': !exists(json, 'modificheStatutarie') ? undefined : json['modificheStatutarie'],
        'depositoStatuto': !exists(json, 'depositoStatuto') ? undefined : json['depositoStatuto'],
        'modificaStatuto': !exists(json, 'modificaStatuto') ? undefined : json['modificaStatuto'],
        'aggregazioneImprese': !exists(json, 'aggregazioneImprese') ? undefined : json['aggregazioneImprese'],
        'provvedimentiGiudice': !exists(json, 'provvedimentiGiudice') ? undefined : json['provvedimentiGiudice'],
        'effettiDifferiti': !exists(json, 'effettiDifferiti') ? undefined : json['effettiDifferiti'],
        'arbitrato': !exists(json, 'arbitrato') ? undefined : json['arbitrato'],
        'condizioniSospensive': !exists(json, 'condizioniSospensive') ? undefined : json['condizioniSospensive'],
        'provvedimentiConservatore': !exists(json, 'provvedimentiConservatore') ? undefined : json['provvedimentiConservatore'],
        'provvedimentiAutorita': !exists(json, 'provvedimentiAutorita') ? undefined : json['provvedimentiAutorita'],
        'gruppiSocietari': !exists(json, 'gruppiSocietari') ? undefined : json['gruppiSocietari'],
        'accordiPartecipazione': !exists(json, 'accordiPartecipazione') ? undefined : json['accordiPartecipazione'],
        'contrattoRete': !exists(json, 'contrattoRete') ? undefined : json['contrattoRete'],
        'attiTradotti': !exists(json, 'attiTradotti') ? undefined : json['attiTradotti'],
        'dichiarazioniStartUp': !exists(json, 'dichiarazioniStartUp') ? undefined : DichiarazioniStartUpFromJSON(json['dichiarazioniStartUp']),
        'dichiarazioniIncubatore': !exists(json, 'dichiarazioniIncubatore') ? undefined : DichiarazioniIncubatoreFromJSON(json['dichiarazioniIncubatore']),
        'dichiarazioniPmi': !exists(json, 'dichiarazioniPmi') ? undefined : DichiarazioniPmiFromJSON(json['dichiarazioniPmi']),
        'dichiarazioniScuolaLavoro': !exists(json, 'dichiarazioniScuolaLavoro') ? undefined : DichiarazioniScuolaLavoroFromJSON(json['dichiarazioniScuolaLavoro']),
    };
}

export function RiferimentiToJSON(value?: Riferimenti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clausole': ClausoleToJSON(value.clausole),
        'modificheStatutarie': value.modificheStatutarie,
        'depositoStatuto': value.depositoStatuto,
        'modificaStatuto': value.modificaStatuto,
        'aggregazioneImprese': value.aggregazioneImprese,
        'provvedimentiGiudice': value.provvedimentiGiudice,
        'effettiDifferiti': value.effettiDifferiti,
        'arbitrato': value.arbitrato,
        'condizioniSospensive': value.condizioniSospensive,
        'provvedimentiConservatore': value.provvedimentiConservatore,
        'provvedimentiAutorita': value.provvedimentiAutorita,
        'gruppiSocietari': value.gruppiSocietari,
        'accordiPartecipazione': value.accordiPartecipazione,
        'contrattoRete': value.contrattoRete,
        'attiTradotti': value.attiTradotti,
        'dichiarazioniStartUp': DichiarazioniStartUpToJSON(value.dichiarazioniStartUp),
        'dichiarazioniIncubatore': DichiarazioniIncubatoreToJSON(value.dichiarazioniIncubatore),
        'dichiarazioniPmi': DichiarazioniPmiToJSON(value.dichiarazioniPmi),
        'dichiarazioniScuolaLavoro': DichiarazioniScuolaLavoroToJSON(value.dichiarazioniScuolaLavoro),
    };
}


