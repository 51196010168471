/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollegioSindacale
 */
export interface CollegioSindacale {
    /**
     * 
     * @type {string}
     * @memberof CollegioSindacale
     */
    neffettivi?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegioSindacale
     */
    nsupplenti?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegioSindacale
     */
    nmin?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegioSindacale
     */
    nmax?: string;
}

export function CollegioSindacaleFromJSON(json: any): CollegioSindacale {
    return CollegioSindacaleFromJSONTyped(json, false);
}

export function CollegioSindacaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollegioSindacale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'neffettivi': !exists(json, 'neffettivi') ? undefined : json['neffettivi'],
        'nsupplenti': !exists(json, 'nsupplenti') ? undefined : json['nsupplenti'],
        'nmin': !exists(json, 'nmin') ? undefined : json['nmin'],
        'nmax': !exists(json, 'nmax') ? undefined : json['nmax'],
    };
}

export function CollegioSindacaleToJSON(value?: CollegioSindacale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'neffettivi': value.neffettivi,
        'nsupplenti': value.nsupplenti,
        'nmin': value.nmin,
        'nmax': value.nmax,
    };
}


