import { FontAwesome } from '@expo/vector-icons';
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { customCompanyResourceApi, customUserExtendedResourceApi } from '@services/apis/ApiConfiguration';
import { Company, UserExtended } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, KeyboardAvoidingView, Platform, Keyboard } from 'react-native';

import SearchImageSvg from '../../../../assets/svg/SearchImageSvg';
import Colors from '../../../../constants/Colors';
import { RootStackScreenProps, State } from '../../../../types';
import {useContext, useEffect} from "react";
import AppContext from "../../../navigation/AppContext";
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";


function Search({ navigation, route }: RootStackScreenProps<'Search'>) {
  const appContext = useContext(AppContext);

  const [textInput, setTextInput] = React.useState<TextInput>();
  const [disabledSearch, setDisabledSearch] = React.useState<boolean>();
  const [userExtendedMe, setUserExtendedMe] = React.useState<UserExtended>();

  const [infoTextVisible, setInfoTextVisible] = React.useState<boolean>(true);

  const [companySearched, setCompanySearched] = React.useState<Company>();
  const [vatNum, setVatNum] = React.useState<string>('');

  const myroute: State = {
    profiles: route.params?.profiles,
    profile: route.params?.profile,
    key_search: route.params?.key_search ? route.params.key_search : '',
    userRole: route.params?.userRole,
    availableCredits: route.params?.availableCredits,
    listFavorite: route.params?.listFavorite,
  }

  useEffect(() => {
    Keyboard.dismiss
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getUserExtendedMe();
    }, [])
  );

  React.useEffect(() => {
    /*
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setInfoTextVisible(false);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setInfoTextVisible(true);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
     */
  }, []);

  /**
   *  The function gets all the information about the user logged. 
   */
  const getUserExtendedMe = (): void => {
    customUserExtendedResourceApi.getUserExtendedMe().then(userLogged => {
      setUserExtendedMe(userLogged);
      checkUserCredits(userLogged);
    }).catch((error) => {
      AlertHelper.showSimpleErrorAlert();
    })
  }

  /**
   * The function provides to check the user's credit and enable the search button. 
   */
  const checkUserCredits = (userExtended: UserExtended) => {
    userExtended?.availableSearches! > 0 ? setDisabledSearch(false) : setDisabledSearch(true);
  }

  function press() {
    Keyboard.dismiss()
    searchCompany()
  }

  const searchCompany = (): void => {
    //let vatNumber: any = vatNum; // myroute.key_search;
    if (vatNum) {
      customCompanyResourceApi.searchCompanyFull({ searchCompanyFullDTO: { vatNumber: vatNum } }).then(company => {
        if (company != undefined) {
          Keyboard.dismiss()
          setCompanySearched(company);
          NavigationHelper.navigateToDashboardCompanySearched(navigation, company);

        } else {
          AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.company_not_found'));
        }
      }).catch(async (error) => {
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
      })
    }
    textInput?.clear()
  }



  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >


      {<View style={[styles.container]}>
        <View style={styles.containerLogo}>
          <SearchImageSvg width={421} height={329.64} />
        </View>

        <View style={styles.info}>
          {infoTextVisible &&
            <>
              <Icon name="info-circle" color={"#00CC83"} />
              <Text style={styles.infoText}>{i18n.t('screens.search.info')}</Text>
            </>
          }
        </View>

        <View style={styles.containerInput}>
          <TextInput
            placeholder={i18n.t('buttons.placeholder_search')}
            placeholderTextColor='#737373'
            onChangeText={(key) => setVatNum(key)}
            ref={(ref) => { ref != null ? setTextInput(ref) : null }}
            editable={!disabledSearch}
            style={styles.textInput}
          />
          <TouchableOpacity
            style={styles.button}
            activeOpacity={0.5}
            disabled={disabledSearch}
            onPress={() => press()}
          //onPress={() =>{navigation.navigate('Dashboard',myroute)}}
          >
            <Text style={styles.text}>{i18n.t('buttons.search')}</Text>
          </TouchableOpacity>
          <View style={styles.divCredits} >
            <Text style={styles.textCredits}>
              {userExtendedMe?.availableSearches + ' ' + i18n.t('screens.search.search')}
            </Text>
          </View>

        </View>


      </View>}
    </LinearGradient>


  );
}
export default Search;

function Icon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={24} style={{ marginBottom: -3 }} {...props} />;
}

const styles = StyleSheet.create({
  divCredits: {
    alignSelf: 'flex-start',
    paddingVertical: 10
  },
  textCredits: {
    color: 'white',

    textAlign: 'left',
    fontFamily: 'poppins',
    fontSize: 11,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    bottom: 30,
    marginHorizontal: 20,
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  infoText: {
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'justify',
    color: 'white',
    fontFamily: 'poppins',
    marginLeft: 3,
    marginBottom: 30
  },
  info: {
    display: 'flex',
    flex: 0.3,
    alignSelf: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 10,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 20
  },
  containerLogo: {
    top: 50,
    left: -15,
    flex: 0.6,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  containerInput: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 0.2,
  },
  button: {
    marginTop: 10,
    backgroundColor: '#272727',
    padding: 20,
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  textInput: {
    backgroundColor: '#464646',
    padding: 13,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  buttonGoback: {
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    marginTop: 40,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
    marginLeft: 6,
  },
});