/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Modifica
 */
export interface Modifica {
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    dtEffetto?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    descrizioneCModifica?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    pmodifica?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    cmodifica?: string;
    /**
     * 
     * @type {string}
     * @memberof Modifica
     */
    ctipo?: string;
}

export function ModificaFromJSON(json: any): Modifica {
    return ModificaFromJSONTyped(json, false);
}

export function ModificaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Modifica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'dtEffetto': !exists(json, 'dtEffetto') ? undefined : json['dtEffetto'],
        'descrizioneCModifica': !exists(json, 'descrizioneCModifica') ? undefined : json['descrizioneCModifica'],
        'pmodifica': !exists(json, 'pmodifica') ? undefined : json['pmodifica'],
        'cmodifica': !exists(json, 'cmodifica') ? undefined : json['cmodifica'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function ModificaToJSON(value?: Modifica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'tipo': value.tipo,
        'dtEffetto': value.dtEffetto,
        'descrizioneCModifica': value.descrizioneCModifica,
        'pmodifica': value.pmodifica,
        'cmodifica': value.cmodifica,
        'ctipo': value.ctipo,
    };
}


