/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ElencoSoci
 */
export interface ElencoSoci {
    /**
     * 
     * @type {Array<object>}
     * @memberof ElencoSoci
     */
    estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    dtSociTitolariDal?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    dtSociTitolariAl?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    riferimentoLegge?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    fconsorzio?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    fultimoElencoSoci?: string;
    /**
     * 
     * @type {string}
     * @memberof ElencoSoci
     */
    criferimentoLegge?: string;
}

export function ElencoSociFromJSON(json: any): ElencoSoci {
    return ElencoSociFromJSONTyped(json, false);
}

export function ElencoSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElencoSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale': !exists(json, 'estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale') ? undefined : json['estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale'],
        'dtSociTitolariDal': !exists(json, 'dtSociTitolariDal') ? undefined : json['dtSociTitolariDal'],
        'dtSociTitolariAl': !exists(json, 'dtSociTitolariAl') ? undefined : json['dtSociTitolariAl'],
        'riferimentoLegge': !exists(json, 'riferimentoLegge') ? undefined : json['riferimentoLegge'],
        'fconsorzio': !exists(json, 'fconsorzio') ? undefined : json['fconsorzio'],
        'fultimoElencoSoci': !exists(json, 'fultimoElencoSoci') ? undefined : json['fultimoElencoSoci'],
        'criferimentoLegge': !exists(json, 'criferimentoLegge') ? undefined : json['criferimentoLegge'],
    };
}

export function ElencoSociToJSON(value?: ElencoSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale': value.estremiPraticaOrEstremiPraticaRiconfermataOrCapitaleSociale,
        'dtSociTitolariDal': value.dtSociTitolariDal,
        'dtSociTitolariAl': value.dtSociTitolariAl,
        'riferimentoLegge': value.riferimentoLegge,
        'fconsorzio': value.fconsorzio,
        'fultimoElencoSoci': value.fultimoElencoSoci,
        'criferimentoLegge': value.criferimentoLegge,
    };
}


