/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FamiliarePartecipe
 */
export interface FamiliarePartecipe {
    /**
     * 
     * @type {string}
     * @memberof FamiliarePartecipe
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliarePartecipe
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliarePartecipe
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliarePartecipe
     */
    fcoltivatoreDiretto?: string;
}

export function FamiliarePartecipeFromJSON(json: any): FamiliarePartecipe {
    return FamiliarePartecipeFromJSONTyped(json, false);
}

export function FamiliarePartecipeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FamiliarePartecipe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'fcoltivatoreDiretto': !exists(json, 'fcoltivatoreDiretto') ? undefined : json['fcoltivatoreDiretto'],
    };
}

export function FamiliarePartecipeToJSON(value?: FamiliarePartecipe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cognome': value.cognome,
        'nome': value.nome,
        'cfiscale': value.cfiscale,
        'fcoltivatoreDiretto': value.fcoltivatoreDiretto,
    };
}


