/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnotazioneLibroSoci,
    AnnotazioneLibroSociFromJSON,
    AnnotazioneLibroSociFromJSONTyped,
    AnnotazioneLibroSociToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotazioniLibroSoci
 */
export interface AnnotazioniLibroSoci {
    /**
     * 
     * @type {Array<AnnotazioneLibroSoci>}
     * @memberof AnnotazioniLibroSoci
     */
    annotazioneLibroSoci: Array<AnnotazioneLibroSoci>;
}

export function AnnotazioniLibroSociFromJSON(json: any): AnnotazioniLibroSoci {
    return AnnotazioniLibroSociFromJSONTyped(json, false);
}

export function AnnotazioniLibroSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotazioniLibroSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotazioneLibroSoci': ((json['annotazioneLibroSoci'] as Array<any>).map(AnnotazioneLibroSociFromJSON)),
    };
}

export function AnnotazioniLibroSociToJSON(value?: AnnotazioniLibroSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotazioneLibroSoci': ((value.annotazioneLibroSoci as Array<any>).map(AnnotazioneLibroSociToJSON)),
    };
}


