/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface MarketCompetitor
 */
export interface MarketCompetitor {
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitor
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof MarketCompetitor
     */
    date?: Date;
    /**
     * 
     * @type {User}
     * @memberof MarketCompetitor
     */
    user?: User;
    /**
     * 
     * @type {Company}
     * @memberof MarketCompetitor
     */
    reference?: Company;
    /**
     * 
     * @type {Company}
     * @memberof MarketCompetitor
     */
    competitor?: Company;
    /**
     * 
     * @type {CompanyData}
     * @memberof MarketCompetitor
     */
    companyData?: CompanyData;
}

export function MarketCompetitorFromJSON(json: any): MarketCompetitor {
    return MarketCompetitorFromJSONTyped(json, false);
}

export function MarketCompetitorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketCompetitor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'reference': !exists(json, 'reference') ? undefined : CompanyFromJSON(json['reference']),
        'competitor': !exists(json, 'competitor') ? undefined : CompanyFromJSON(json['competitor']),
        'companyData': !exists(json, 'companyData') ? undefined : CompanyDataFromJSON(json['companyData']),
    };
}

export function MarketCompetitorToJSON(value?: MarketCompetitor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'user': UserToJSON(value.user),
        'reference': CompanyToJSON(value.reference),
        'competitor': CompanyToJSON(value.competitor),
        'companyData': CompanyDataToJSON(value.companyData),
    };
}


