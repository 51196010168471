/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Collaboratori,
    CollaboratoriFromJSON,
    CollaboratoriFromJSONTyped,
    CollaboratoriToJSON,
    DistribuzioneDipendenti,
    DistribuzioneDipendentiFromJSON,
    DistribuzioneDipendentiFromJSONTyped,
    DistribuzioneDipendentiToJSON,
    InfoMesi,
    InfoMesiFromJSON,
    InfoMesiFromJSONTyped,
    InfoMesiToJSON,
    ValoriMedi,
    ValoriMediFromJSON,
    ValoriMediFromJSONTyped,
    ValoriMediToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddettiImpresa
 */
export interface AddettiImpresa {
    /**
     * 
     * @type {InfoMesi}
     * @memberof AddettiImpresa
     */
    infoMesi?: InfoMesi;
    /**
     * 
     * @type {ValoriMedi}
     * @memberof AddettiImpresa
     */
    valoriMedi?: ValoriMedi;
    /**
     * 
     * @type {Collaboratori}
     * @memberof AddettiImpresa
     */
    collaboratori?: Collaboratori;
    /**
     * 
     * @type {DistribuzioneDipendenti}
     * @memberof AddettiImpresa
     */
    distribuzioneDipendenti?: DistribuzioneDipendenti;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    tipoInformazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    dtDichiarazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    dtRilevazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    ctipoInformazione?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    ndipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    nindipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    crilevazioneDipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiImpresa
     */
    ntotale?: string;
}

export function AddettiImpresaFromJSON(json: any): AddettiImpresa {
    return AddettiImpresaFromJSONTyped(json, false);
}

export function AddettiImpresaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddettiImpresa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoMesi': !exists(json, 'infoMesi') ? undefined : InfoMesiFromJSON(json['infoMesi']),
        'valoriMedi': !exists(json, 'valoriMedi') ? undefined : ValoriMediFromJSON(json['valoriMedi']),
        'collaboratori': !exists(json, 'collaboratori') ? undefined : CollaboratoriFromJSON(json['collaboratori']),
        'distribuzioneDipendenti': !exists(json, 'distribuzioneDipendenti') ? undefined : DistribuzioneDipendentiFromJSON(json['distribuzioneDipendenti']),
        'tipoInformazione': !exists(json, 'tipoInformazione') ? undefined : json['tipoInformazione'],
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'dtDichiarazione': !exists(json, 'dtDichiarazione') ? undefined : json['dtDichiarazione'],
        'dtRilevazione': !exists(json, 'dtRilevazione') ? undefined : json['dtRilevazione'],
        'ctipoInformazione': !exists(json, 'ctipoInformazione') ? undefined : json['ctipoInformazione'],
        'ndipendenti': !exists(json, 'ndipendenti') ? undefined : json['ndipendenti'],
        'nindipendenti': !exists(json, 'nindipendenti') ? undefined : json['nindipendenti'],
        'crilevazioneDipendenti': !exists(json, 'crilevazioneDipendenti') ? undefined : json['crilevazioneDipendenti'],
        'ntotale': !exists(json, 'ntotale') ? undefined : json['ntotale'],
    };
}

export function AddettiImpresaToJSON(value?: AddettiImpresa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoMesi': InfoMesiToJSON(value.infoMesi),
        'valoriMedi': ValoriMediToJSON(value.valoriMedi),
        'collaboratori': CollaboratoriToJSON(value.collaboratori),
        'distribuzioneDipendenti': DistribuzioneDipendentiToJSON(value.distribuzioneDipendenti),
        'tipoInformazione': value.tipoInformazione,
        'anno': value.anno,
        'dtDichiarazione': value.dtDichiarazione,
        'dtRilevazione': value.dtRilevazione,
        'ctipoInformazione': value.ctipoInformazione,
        'ndipendenti': value.ndipendenti,
        'nindipendenti': value.nindipendenti,
        'crilevazioneDipendenti': value.crilevazioneDipendenti,
        'ntotale': value.ntotale,
    };
}


