/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface MarketCompetitorDTO
 */
export interface MarketCompetitorDTO {
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitorDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketCompetitorDTO
     */
    vatNumberCompetitor?: string;
    /**
     * 
     * @type {Company}
     * @memberof MarketCompetitorDTO
     */
    reference?: Company;
}

export function MarketCompetitorDTOFromJSON(json: any): MarketCompetitorDTO {
    return MarketCompetitorDTOFromJSONTyped(json, false);
}

export function MarketCompetitorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketCompetitorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'vatNumberCompetitor': !exists(json, 'vatNumberCompetitor') ? undefined : json['vatNumberCompetitor'],
        'reference': !exists(json, 'reference') ? undefined : CompanyFromJSON(json['reference']),
    };
}

export function MarketCompetitorDTOToJSON(value?: MarketCompetitorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vatNumberCompetitor': value.vatNumberCompetitor,
        'reference': CompanyToJSON(value.reference),
    };
}


