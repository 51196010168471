/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrasferimentoSede
 */
export interface TrasferimentoSede {
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    comune?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    toponimo?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    via?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    ctoponimo?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    nrd?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    naa?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoSede
     */
    ncivico?: string;
}

export function TrasferimentoSedeFromJSON(json: any): TrasferimentoSede {
    return TrasferimentoSedeFromJSONTyped(json, false);
}

export function TrasferimentoSedeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentoSede {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comune': !exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'toponimo': !exists(json, 'toponimo') ? undefined : json['toponimo'],
        'via': !exists(json, 'via') ? undefined : json['via'],
        'ctoponimo': !exists(json, 'ctoponimo') ? undefined : json['ctoponimo'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
        'naa': !exists(json, 'naa') ? undefined : json['naa'],
        'ncivico': !exists(json, 'ncivico') ? undefined : json['ncivico'],
    };
}

export function TrasferimentoSedeToJSON(value?: TrasferimentoSede | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comune': value.comune,
        'provincia': value.provincia,
        'toponimo': value.toponimo,
        'via': value.via,
        'ctoponimo': value.ctoponimo,
        'nrea': value.nrea,
        'nrd': value.nrd,
        'naa': value.naa,
        'ncivico': value.ncivico,
    };
}


