/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DichiarazioneIncubatore,
    DichiarazioneIncubatoreFromJSON,
    DichiarazioneIncubatoreFromJSONTyped,
    DichiarazioneIncubatoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface DichiarazioniIncubatore
 */
export interface DichiarazioniIncubatore {
    /**
     * 
     * @type {Array<DichiarazioneIncubatore>}
     * @memberof DichiarazioniIncubatore
     */
    dichiarazioneIncubatore: Array<DichiarazioneIncubatore>;
}

export function DichiarazioniIncubatoreFromJSON(json: any): DichiarazioniIncubatore {
    return DichiarazioniIncubatoreFromJSONTyped(json, false);
}

export function DichiarazioniIncubatoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): DichiarazioniIncubatore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dichiarazioneIncubatore': ((json['dichiarazioneIncubatore'] as Array<any>).map(DichiarazioneIncubatoreFromJSON)),
    };
}

export function DichiarazioniIncubatoreToJSON(value?: DichiarazioniIncubatore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dichiarazioneIncubatore': ((value.dichiarazioneIncubatore as Array<any>).map(DichiarazioneIncubatoreToJSON)),
    };
}


