/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketCompetitor,
    MarketCompetitorFromJSON,
    MarketCompetitorFromJSONTyped,
    MarketCompetitorToJSON,
} from './';

/**
 * 
 * @export
 * @interface MarketCompetitorResponseDTO
 */
export interface MarketCompetitorResponseDTO {
    /**
     * 
     * @type {MarketCompetitor}
     * @memberof MarketCompetitorResponseDTO
     */
    marketCompetitor?: MarketCompetitor;
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitorResponseDTO
     */
    revenue?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitorResponseDTO
     */
    ebitda?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitorResponseDTO
     */
    leva?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketCompetitorResponseDTO
     */
    financialScore?: number;
}

export function MarketCompetitorResponseDTOFromJSON(json: any): MarketCompetitorResponseDTO {
    return MarketCompetitorResponseDTOFromJSONTyped(json, false);
}

export function MarketCompetitorResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketCompetitorResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketCompetitor': !exists(json, 'marketCompetitor') ? undefined : MarketCompetitorFromJSON(json['marketCompetitor']),
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'ebitda': !exists(json, 'ebitda') ? undefined : json['ebitda'],
        'leva': !exists(json, 'leva') ? undefined : json['leva'],
        'financialScore': !exists(json, 'financialScore') ? undefined : json['financialScore'],
    };
}

export function MarketCompetitorResponseDTOToJSON(value?: MarketCompetitorResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketCompetitor': MarketCompetitorToJSON(value.marketCompetitor),
        'revenue': value.revenue,
        'ebitda': value.ebitda,
        'leva': value.leva,
        'financialScore': value.financialScore,
    };
}


