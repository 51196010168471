/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserConsultantInviteState,
    UserConsultantInviteStateFromJSON,
    UserConsultantInviteStateFromJSONTyped,
    UserConsultantInviteStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserConsultantInvite
 */
export interface UserConsultantInvite {
    /**
     * 
     * @type {number}
     * @memberof UserConsultantInvite
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserConsultantInvite
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInvite
     */
    currentState?: UserConsultantInviteCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInvite
     */
    role?: UserConsultantInviteRoleEnum;
    /**
     * 
     * @type {User}
     * @memberof UserConsultantInvite
     */
    user?: User;
    /**
     * 
     * @type {Company}
     * @memberof UserConsultantInvite
     */
    consultant?: Company;
    /**
     * 
     * @type {Set<UserConsultantInviteState>}
     * @memberof UserConsultantInvite
     */
    userConsultantInviteStates?: Set<UserConsultantInviteState>;
}

/**
* @export
* @enum {string}
*/
export enum UserConsultantInviteCurrentStateEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}/**
* @export
* @enum {string}
*/
export enum UserConsultantInviteRoleEnum {
    Administrator = 'ADMINISTRATOR',
    Sales = 'SALES',
    Analyst = 'ANALYST'
}

export function UserConsultantInviteFromJSON(json: any): UserConsultantInvite {
    return UserConsultantInviteFromJSONTyped(json, false);
}

export function UserConsultantInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConsultantInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'consultant': !exists(json, 'consultant') ? undefined : CompanyFromJSON(json['consultant']),
        'userConsultantInviteStates': !exists(json, 'userConsultantInviteStates') ? undefined : ((json['userConsultantInviteStates'] as Array<any>).map(UserConsultantInviteStateFromJSON)),
    };
}

export function UserConsultantInviteToJSON(value?: UserConsultantInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'currentState': value.currentState,
        'role': value.role,
        'user': UserToJSON(value.user),
        'consultant': CompanyToJSON(value.consultant),
        'userConsultantInviteStates': value.userConsultantInviteStates === undefined ? undefined : ((value.userConsultantInviteStates as Array<any>).map(UserConsultantInviteStateToJSON)),
    };
}


