/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    hykee-app
 * @file       GenericHelper.ts
 * @author     Christian Ascone
 * @date       9/29/19 6:28 PM
 */

import {
    Company,
    CompanyAddressResourceApi,
    CompanyData,
    CompanySurvey,
    Insight,
    User,
    UserExtended,
    UserExtendedTypeEnum
} from "../services/apis/generated";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { CompanyType, Profile, RootStackParamList, UserExtendedType } from "../../types";

export class NavigationHelper {
    /**
     * Navigate to dashboard
     * @param navigation
     * @param type
     */
    static navigateToBottomTab(navigation: NativeStackNavigationProp<RootStackParamList>, userExtendedMe: UserExtended,) {
        navigation.navigate('BottomTabNavigator', userExtendedMe);
    }

    /**
  * Navigate to reset password screen
  * @param navigation
  */
    static navigateToResetPassword(navigation: NativeStackNavigationProp<RootStackParamList>, token?: string) {
        navigation.navigate('ResetPassword', token);
    }


    /**
  * Navigate to activate account screen
  * @param navigation
  */
    static navigateToActivateAccount(navigation: NativeStackNavigationProp<RootStackParamList>, key?: string, token?: string | null) {
        navigation.navigate('ActivateAccount', { key, token });
    }

    /**
   * Navigate to dashboard
   * @param navigation
   * @param type
   */
    static navigateToDashboardCompany(navigation: NativeStackNavigationProp<RootStackParamList>, company: Company) {
        navigation.navigate('DashboardCompany', { company: company } as UserExtended & CompanyType);
    }

    /**
   * Navigate to dashboard
   * @param navigation
   * @param type
   */
    static navigateToDashboardConsultant(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('Search');
    }

    /**
   * Navigate to dashboard Company saved
   * @param navigation
   * @param type
   */
    static navigateToDashboardSavedCompany(navigation: NativeStackNavigationProp<RootStackParamList>, company: Number) {
        navigation.navigate('DashboardSavedCompany', company)
    }


    /**
   * Navigate to dashboard Company searched
   * @param navigation
   * @param type
   */
    static navigateToDashboardCompanySearched(navigation: NativeStackNavigationProp<RootStackParamList>, company: Company) {
        navigation.navigate('DashboardCompanySearched', company);
    }

    /**
    * Navigate to dashboard
    * @param navigation
    * @param type
    */
    static navigateToSearch(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('Search');
    }


    /**
         * Navigate to SelfAssessment
         * @param navigation
         * @param type
         */
    static navigateToSelfAssessment(navigation: NativeStackNavigationProp<RootStackParamList>, companySurvey: CompanySurvey) {
        navigation.navigate('SelfAssessment', companySurvey);
    }

    /**
        * Navigate to UserManagement
        * @param navigation
        * @param type
        */
    static navigateToUserManagement(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('UserManagement');
    }

    /**
     * Navigate to Survey
     * @param navigation
     * @param type
     */
    static navigateToSurvey(navigation: NativeStackNavigationProp<RootStackParamList>, company?: Company, companySurvey?: CompanySurvey) {
        if (companySurvey) {
            navigation.navigate('Survey', companySurvey);
        } else {
            navigation.navigate('Survey', company);
        }
    }

    /**
    * Navigate to initial screen
    * @param navigation
    * @param type
    */
    static navigateToBusinessRating(navigation: NativeStackNavigationProp<RootStackParamList>, companySurvey?: CompanySurvey) {
        if (companySurvey) {
            navigation.navigate('BusinessRating', companySurvey);
        } else {
            navigation.navigate('BusinessRating');
        }
    }

    /**
     * Navigate to initial screen
     * @param navigation
     * @param type
     */
    static navigateToSplash(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('Root');
    }

    /**
   * Navigate to initial screen
   * @param navigation
   * @param type
   */
    static navigateToPlanCredits(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('PlanCredits');
    }

    /**
   * Navigate to initial screen
   * @param navigation
   * @param type
   */
    static navigateToPlanResearch(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('PlanResearch');
    }

    /**
     * Navigate back
     * @param navigation
     * @param type
     */
    static navigateToGoBack(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.goBack();
    }

    /** Navigate to profile data screen
     * @param navigation
     */
    static navigateToProfileData(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile, user?: User) {
        navigation.navigate('ProfileData', { profile: profile, user: user });
    }

    /** Navigate to Notification Page screen
     * @param navigation
     */
    static navigateToNotificationPage(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile, user?: User, company?: Company) {
        navigation.navigate('NotificationPage', { profile: profile, user: user, company: company });
    }

    /** Navigate to company data screen
     * @param navigation
     * @param type
     */
    static navigateToCompanyData(navigation: NativeStackNavigationProp<RootStackParamList>, company?: Company) {
        navigation.navigate('CompanyData', company);
    }

    /** Navigate to plans data screen
     * @param navigation
     * @param type
     */
    static navigateToPlansData(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile) {
        navigation.navigate('PlansData', profile);
    }

    /**
     * Navigate to credits screen
     * @param navigation
     * @param type
     * @param boolean
     */
    static navigateToCredits(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile, arrowBack: boolean) {
        navigation.navigate("CreditsNavigator", { profile: profile, arrowBack: arrowBack });
    }

    /**
     * Navigate to PartnerProgram screen
     * @param navigation
     * @param type
     * @param boolean
     */
    static navigateToPartnerProgram(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate("PartnerProgramScreen");
    }

    /**
     * Navigate to PartnerProgram screen
     * @param navigation
     * @param type
     * @param boolean
     */
    static navigateToAddNewPartnerProgramScreen(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate("AddNewPartnerProgram");
    }

    /**
     * Navigate to consultancy data screen
     * @param navigation
     * @param type
     */
    static navigateToConsultancyData(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile) {
        navigation.navigate('ConsultancyData', profile);
    }

    /**
     * Navigate to network partners
     * @param navigation
     * @param type
     */
    static navigateToPartnerData(navigation: NativeStackNavigationProp<RootStackParamList>, profile: Profile) {
        navigation.navigate('PartnerData', profile);
    }

    /**
     * Navigate to network partners
     * @param navigation
     * @param type
     */
    static navigateToLinkedCompaniesData(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('LinkedCompaniesData');
    }

    /**
     * Navigate to administrator screen
     * @param navigation
     * @param type
     */
    static navigateToAdministratorScreen(navigation: NativeStackNavigationProp<RootStackParamList>, companyDataId: number) {
        if (companyDataId) {
            navigation.navigate('AdministratorScreen', { companyDataId: companyDataId });
        } else {
            navigation.navigate('AdministratorScreen')
        }
    }

    /**
     * Navigate to shares screen
     * @param navigation
     * @param type
     */
    static navigateToSharesScreen(navigation: NativeStackNavigationProp<RootStackParamList>, companyDataId: number) {
        if (companyDataId) {
            navigation.navigate('SharesScreen', { companyDataId: companyDataId });
        } else {
            navigation.navigate('SharesScreen')
        }
    }

    /**
     * Navigate to partner screen
     * @param navigation
     * @param type
     */
    static navigateToPartnerScreen(navigation: NativeStackNavigationProp<RootStackParamList>, companyDataId: number) {
        if (companyDataId) {
            navigation.navigate('PartnerScreen', { companyDataId: companyDataId });
        } else {
            navigation.navigate('PartnerScreen')
        }
    }

    /**
     * Navigate to Pending Request
     * @param navigation
     * @param type
     */
    static navigateToPendingRequest(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('PendingRequest');
    }

    /** Navigate to profile data screen
     * @param navigation
     */
    static navigateToCompanyMicro(navigation: NativeStackNavigationProp<RootStackParamList>, company: any) {
        navigation.navigate('CompanyMicro', company);
    }

    /** Navigate to insight screen
    * @param navigation
    */
    static navigateToInsightHome(navigation: NativeStackNavigationProp<RootStackParamList>) {
        navigation.navigate('InsightHome');
    }

    /** Navigate to insight screen
    * @param navigation
    */
    static navigateToInsightDetail(navigation: NativeStackNavigationProp<RootStackParamList>, insight: Insight) {
        navigation.navigate('InsightDetail', { insight: insight });
    }


    /** Navigate to insight screen
    * @param navigation
    */
    static navigateGoToEditInsight(navigation: NativeStackNavigationProp<RootStackParamList>, insight: Insight) {
        navigation.navigate('AddNewPartnerProgram', { insight: insight });
    }
}
