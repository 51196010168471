/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResToJSON,
    CustomCompanySurveyQuestionResCriteria,
    CustomCompanySurveyQuestionResCriteriaFromJSON,
    CustomCompanySurveyQuestionResCriteriaToJSON,
} from '../models';

export interface GetAllCompanySurveyQuestionResFilterRequest {
    customCompanySurveyQuestionResCriteria: CustomCompanySurveyQuestionResCriteria;
}

/**
 * 
 */
export class CustomCompanySurveyQuestionResResourceApi extends runtime.BaseAPI {

    /**
     */
    async getAllCompanySurveyQuestionResFilterRaw(requestParameters: GetAllCompanySurveyQuestionResFilterRequest): Promise<runtime.ApiResponse<Array<CompanySurveyQuestionRes>>> {
        if (requestParameters.customCompanySurveyQuestionResCriteria === null || requestParameters.customCompanySurveyQuestionResCriteria === undefined) {
            throw new runtime.RequiredError('customCompanySurveyQuestionResCriteria','Required parameter requestParameters.customCompanySurveyQuestionResCriteria was null or undefined when calling getAllCompanySurveyQuestionResFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-survey-question-res/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomCompanySurveyQuestionResCriteriaToJSON(requestParameters.customCompanySurveyQuestionResCriteria),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurveyQuestionResFromJSON));
    }

    /**
     */
    async getAllCompanySurveyQuestionResFilter(requestParameters: GetAllCompanySurveyQuestionResFilterRequest): Promise<Array<CompanySurveyQuestionRes>> {
        const response = await this.getAllCompanySurveyQuestionResFilterRaw(requestParameters);
        return await response.value();
    }

}
