/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComposizioneQuote,
    ComposizioneQuoteFromJSON,
    ComposizioneQuoteFromJSONTyped,
    ComposizioneQuoteToJSON,
    TipoAtto,
    TipoAttoFromJSON,
    TipoAttoFromJSONTyped,
    TipoAttoToJSON,
    Titolari,
    TitolariFromJSON,
    TitolariFromJSONTyped,
    TitolariToJSON,
} from './';

/**
 * 
 * @export
 * @interface RiquadroTrasferimento
 */
export interface RiquadroTrasferimento {
    /**
     * 
     * @type {TipoAtto}
     * @memberof RiquadroTrasferimento
     */
    tipoAtto?: TipoAtto;
    /**
     * 
     * @type {ComposizioneQuote}
     * @memberof RiquadroTrasferimento
     */
    composizioneQuote?: ComposizioneQuote;
    /**
     * 
     * @type {Array<string>}
     * @memberof RiquadroTrasferimento
     */
    vincoliQuote?: Array<string>;
    /**
     * 
     * @type {Titolari}
     * @memberof RiquadroTrasferimento
     */
    titolari?: Titolari;
    /**
     * 
     * @type {Array<string>}
     * @memberof RiquadroTrasferimento
     */
    note?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RiquadroTrasferimento
     */
    dtAnnotazione?: string;
    /**
     * 
     * @type {string}
     * @memberof RiquadroTrasferimento
     */
    dtEvento?: string;
}

export function RiquadroTrasferimentoFromJSON(json: any): RiquadroTrasferimento {
    return RiquadroTrasferimentoFromJSONTyped(json, false);
}

export function RiquadroTrasferimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiquadroTrasferimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoAtto': !exists(json, 'tipoAtto') ? undefined : TipoAttoFromJSON(json['tipoAtto']),
        'composizioneQuote': !exists(json, 'composizioneQuote') ? undefined : ComposizioneQuoteFromJSON(json['composizioneQuote']),
        'vincoliQuote': !exists(json, 'vincoliQuote') ? undefined : json['vincoliQuote'],
        'titolari': !exists(json, 'titolari') ? undefined : TitolariFromJSON(json['titolari']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'dtAnnotazione': !exists(json, 'dtAnnotazione') ? undefined : json['dtAnnotazione'],
        'dtEvento': !exists(json, 'dtEvento') ? undefined : json['dtEvento'],
    };
}

export function RiquadroTrasferimentoToJSON(value?: RiquadroTrasferimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoAtto': TipoAttoToJSON(value.tipoAtto),
        'composizioneQuote': ComposizioneQuoteToJSON(value.composizioneQuote),
        'vincoliQuote': value.vincoliQuote,
        'titolari': TitolariToJSON(value.titolari),
        'note': value.note,
        'dtAnnotazione': value.dtAnnotazione,
        'dtEvento': value.dtEvento,
    };
}


