/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    InsightLike,
    InsightLikeFromJSON,
    InsightLikeFromJSONTyped,
    InsightLikeToJSON,
    InsightState,
    InsightStateFromJSON,
    InsightStateFromJSONTyped,
    InsightStateToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Insight
 */
export interface Insight {
    /**
     * 
     * @type {number}
     * @memberof Insight
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Insight
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Insight
     */
    likeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Insight
     */
    dislikeCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Insight
     */
    dateOfPublication?: Date;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    currentInsightState?: InsightCurrentInsightStateEnum;
    /**
     * 
     * @type {Set<InsightLike>}
     * @memberof Insight
     */
    insightLikes?: Set<InsightLike>;
    /**
     * 
     * @type {User}
     * @memberof Insight
     */
    author?: User;
    /**
     * 
     * @type {Company}
     * @memberof Insight
     */
    publisher?: Company;
    /**
     * 
     * @type {Set<Company>}
     * @memberof Insight
     */
    recipients?: Set<Company>;
    /**
     * 
     * @type {Set<InsightState>}
     * @memberof Insight
     */
    insightStates?: Set<InsightState>;
}

/**
* @export
* @enum {string}
*/
export enum InsightCurrentInsightStateEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}

export function InsightFromJSON(json: any): Insight {
    return InsightFromJSONTyped(json, false);
}

export function InsightFromJSONTyped(json: any, ignoreDiscriminator: boolean): Insight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'likeCount': !exists(json, 'likeCount') ? undefined : json['likeCount'],
        'dislikeCount': !exists(json, 'dislikeCount') ? undefined : json['dislikeCount'],
        'dateOfPublication': !exists(json, 'dateOfPublication') ? undefined : (new Date(json['dateOfPublication'])),
        'currentInsightState': !exists(json, 'currentInsightState') ? undefined : json['currentInsightState'],
        'insightLikes': !exists(json, 'insightLikes') ? undefined : ((json['insightLikes'] as Array<any>).map(InsightLikeFromJSON)),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
        'publisher': !exists(json, 'publisher') ? undefined : CompanyFromJSON(json['publisher']),
        'recipients': !exists(json, 'recipients') ? undefined : ((json['recipients'] as Array<any>).map(CompanyFromJSON)),
        'insightStates': !exists(json, 'insightStates') ? undefined : ((json['insightStates'] as Array<any>).map(InsightStateFromJSON)),
    };
}

export function InsightToJSON(value?: Insight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'likeCount': value.likeCount,
        'dislikeCount': value.dislikeCount,
        'dateOfPublication': value.dateOfPublication === undefined ? undefined : (value.dateOfPublication.toISOString()),
        'currentInsightState': value.currentInsightState,
        'insightLikes': value.insightLikes === undefined ? undefined : ((value.insightLikes as Array<any>).map(InsightLikeToJSON)),
        'author': UserToJSON(value.author),
        'publisher': CompanyToJSON(value.publisher),
        'recipients': value.recipients === undefined ? undefined : ((value.recipients as Array<any>).map(CompanyToJSON)),
        'insightStates': value.insightStates === undefined ? undefined : ((value.insightStates as Array<any>).map(InsightStateToJSON)),
    };
}


