/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResToJSON,
} from '../models';

export interface CountCompanySurveyQuestionResRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    surveyQuestionResponseIdGreaterThan?: number;
    surveyQuestionResponseIdLessThan?: number;
    surveyQuestionResponseIdGreaterThanOrEqual?: number;
    surveyQuestionResponseIdLessThanOrEqual?: number;
    surveyQuestionResponseIdEquals?: number;
    surveyQuestionResponseIdNotEquals?: number;
    surveyQuestionResponseIdSpecified?: boolean;
    surveyQuestionResponseIdIn?: Array<number>;
    surveyQuestionResponseIdNotIn?: Array<number>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanySurveyQuestionResRequest {
    companySurveyQuestionRes: CompanySurveyQuestionRes;
}

export interface DeleteCompanySurveyQuestionResRequest {
    id: number;
}

export interface GetAllCompanySurveyQuestionResRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    surveyQuestionResponseIdGreaterThan?: number;
    surveyQuestionResponseIdLessThan?: number;
    surveyQuestionResponseIdGreaterThanOrEqual?: number;
    surveyQuestionResponseIdLessThanOrEqual?: number;
    surveyQuestionResponseIdEquals?: number;
    surveyQuestionResponseIdNotEquals?: number;
    surveyQuestionResponseIdSpecified?: boolean;
    surveyQuestionResponseIdIn?: Array<number>;
    surveyQuestionResponseIdNotIn?: Array<number>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanySurveyQuestionResRequest {
    id: number;
}

export interface PartialUpdateCompanySurveyQuestionResRequest {
    id: number;
    companySurveyQuestionRes: CompanySurveyQuestionRes;
}

export interface UpdateCompanySurveyQuestionResRequest {
    id: number;
    companySurveyQuestionRes: CompanySurveyQuestionRes;
}

/**
 * 
 */
export class CompanySurveyQuestionResResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanySurveyQuestionResRaw(requestParameters: CountCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThan'] = requestParameters.surveyQuestionResponseIdGreaterThan;
        }

        if (requestParameters.surveyQuestionResponseIdLessThan !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThan'] = requestParameters.surveyQuestionResponseIdLessThan;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThanOrEqual'] = requestParameters.surveyQuestionResponseIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThanOrEqual'] = requestParameters.surveyQuestionResponseIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.equals'] = requestParameters.surveyQuestionResponseIdEquals;
        }

        if (requestParameters.surveyQuestionResponseIdNotEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.notEquals'] = requestParameters.surveyQuestionResponseIdNotEquals;
        }

        if (requestParameters.surveyQuestionResponseIdSpecified !== undefined) {
            queryParameters['surveyQuestionResponseId.specified'] = requestParameters.surveyQuestionResponseIdSpecified;
        }

        if (requestParameters.surveyQuestionResponseIdIn) {
            queryParameters['surveyQuestionResponseId.in'] = requestParameters.surveyQuestionResponseIdIn;
        }

        if (requestParameters.surveyQuestionResponseIdNotIn) {
            queryParameters['surveyQuestionResponseId.notIn'] = requestParameters.surveyQuestionResponseIdNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-survey-question-res/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanySurveyQuestionRes(requestParameters: CountCompanySurveyQuestionResRequest): Promise<number> {
        const response = await this.countCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanySurveyQuestionResRaw(requestParameters: CreateCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<CompanySurveyQuestionRes>> {
        if (requestParameters.companySurveyQuestionRes === null || requestParameters.companySurveyQuestionRes === undefined) {
            throw new runtime.RequiredError('companySurveyQuestionRes','Required parameter requestParameters.companySurveyQuestionRes was null or undefined when calling createCompanySurveyQuestionRes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-survey-question-res`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyQuestionResToJSON(requestParameters.companySurveyQuestionRes),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyQuestionResFromJSON(jsonValue));
    }

    /**
     */
    async createCompanySurveyQuestionRes(requestParameters: CreateCompanySurveyQuestionResRequest): Promise<CompanySurveyQuestionRes> {
        const response = await this.createCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanySurveyQuestionResRaw(requestParameters: DeleteCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanySurveyQuestionRes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-survey-question-res/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanySurveyQuestionRes(requestParameters: DeleteCompanySurveyQuestionResRequest): Promise<void> {
        await this.deleteCompanySurveyQuestionResRaw(requestParameters);
    }

    /**
     */
    async getAllCompanySurveyQuestionResRaw(requestParameters: GetAllCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<Array<CompanySurveyQuestionRes>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThan'] = requestParameters.surveyQuestionResponseIdGreaterThan;
        }

        if (requestParameters.surveyQuestionResponseIdLessThan !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThan'] = requestParameters.surveyQuestionResponseIdLessThan;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThanOrEqual'] = requestParameters.surveyQuestionResponseIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThanOrEqual'] = requestParameters.surveyQuestionResponseIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.equals'] = requestParameters.surveyQuestionResponseIdEquals;
        }

        if (requestParameters.surveyQuestionResponseIdNotEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.notEquals'] = requestParameters.surveyQuestionResponseIdNotEquals;
        }

        if (requestParameters.surveyQuestionResponseIdSpecified !== undefined) {
            queryParameters['surveyQuestionResponseId.specified'] = requestParameters.surveyQuestionResponseIdSpecified;
        }

        if (requestParameters.surveyQuestionResponseIdIn) {
            queryParameters['surveyQuestionResponseId.in'] = requestParameters.surveyQuestionResponseIdIn;
        }

        if (requestParameters.surveyQuestionResponseIdNotIn) {
            queryParameters['surveyQuestionResponseId.notIn'] = requestParameters.surveyQuestionResponseIdNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-survey-question-res`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanySurveyQuestionResFromJSON));
    }

    /**
     */
    async getAllCompanySurveyQuestionRes(requestParameters: GetAllCompanySurveyQuestionResRequest): Promise<Array<CompanySurveyQuestionRes>> {
        const response = await this.getAllCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanySurveyQuestionResRaw(requestParameters: GetCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<CompanySurveyQuestionRes>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanySurveyQuestionRes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-survey-question-res/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyQuestionResFromJSON(jsonValue));
    }

    /**
     */
    async getCompanySurveyQuestionRes(requestParameters: GetCompanySurveyQuestionResRequest): Promise<CompanySurveyQuestionRes> {
        const response = await this.getCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanySurveyQuestionResRaw(requestParameters: PartialUpdateCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<CompanySurveyQuestionRes>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanySurveyQuestionRes.');
        }

        if (requestParameters.companySurveyQuestionRes === null || requestParameters.companySurveyQuestionRes === undefined) {
            throw new runtime.RequiredError('companySurveyQuestionRes','Required parameter requestParameters.companySurveyQuestionRes was null or undefined when calling partialUpdateCompanySurveyQuestionRes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-survey-question-res/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyQuestionResToJSON(requestParameters.companySurveyQuestionRes),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyQuestionResFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanySurveyQuestionRes(requestParameters: PartialUpdateCompanySurveyQuestionResRequest): Promise<CompanySurveyQuestionRes> {
        const response = await this.partialUpdateCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanySurveyQuestionResRaw(requestParameters: UpdateCompanySurveyQuestionResRequest): Promise<runtime.ApiResponse<CompanySurveyQuestionRes>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanySurveyQuestionRes.');
        }

        if (requestParameters.companySurveyQuestionRes === null || requestParameters.companySurveyQuestionRes === undefined) {
            throw new runtime.RequiredError('companySurveyQuestionRes','Required parameter requestParameters.companySurveyQuestionRes was null or undefined when calling updateCompanySurveyQuestionRes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-survey-question-res/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySurveyQuestionResToJSON(requestParameters.companySurveyQuestionRes),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySurveyQuestionResFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanySurveyQuestionRes(requestParameters: UpdateCompanySurveyQuestionResRequest): Promise<CompanySurveyQuestionRes> {
        const response = await this.updateCompanySurveyQuestionResRaw(requestParameters);
        return await response.value();
    }

}
