/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassificazioniAteco,
    ClassificazioniAtecoFromJSON,
    ClassificazioniAtecoFromJSONTyped,
    ClassificazioniAtecoToJSON,
    ClassificazioniAteco2002,
    ClassificazioniAteco2002FromJSON,
    ClassificazioniAteco2002FromJSONTyped,
    ClassificazioniAteco2002ToJSON,
} from './';

/**
 * 
 * @export
 * @interface StoriaAttivita
 */
export interface StoriaAttivita {
    /**
     * 
     * @type {ClassificazioniAteco2002}
     * @memberof StoriaAttivita
     */
    classificazioniAteco2002?: ClassificazioniAteco2002;
    /**
     * 
     * @type {Array<ClassificazioniAteco>}
     * @memberof StoriaAttivita
     */
    classificazioniAteco?: Array<ClassificazioniAteco>;
}

export function StoriaAttivitaFromJSON(json: any): StoriaAttivita {
    return StoriaAttivitaFromJSONTyped(json, false);
}

export function StoriaAttivitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoriaAttivita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classificazioniAteco2002': !exists(json, 'classificazioniAteco2002') ? undefined : ClassificazioniAteco2002FromJSON(json['classificazioniAteco2002']),
        'classificazioniAteco': !exists(json, 'classificazioniAteco') ? undefined : ((json['classificazioniAteco'] as Array<any>).map(ClassificazioniAtecoFromJSON)),
    };
}

export function StoriaAttivitaToJSON(value?: StoriaAttivita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classificazioniAteco2002': ClassificazioniAteco2002ToJSON(value.classificazioniAteco2002),
        'classificazioniAteco': value.classificazioniAteco === undefined ? undefined : ((value.classificazioniAteco as Array<any>).map(ClassificazioniAtecoToJSON)),
    };
}


