/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Settori,
    SettoriFromJSON,
    SettoriFromJSONTyped,
    SettoriToJSON,
} from './';

/**
 * 
 * @export
 * @interface Certificazione
 */
export interface Certificazione {
    /**
     * 
     * @type {Settori}
     * @memberof Certificazione
     */
    settori?: Settori;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    schemaAccreditamento?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    normaRiferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    nota?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    dtEmissione?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    denominazioneOdc?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    ncertificato?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    cschemaAccreditamento?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificazione
     */
    cfiscaleOdc?: string;
}

export function CertificazioneFromJSON(json: any): Certificazione {
    return CertificazioneFromJSONTyped(json, false);
}

export function CertificazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Certificazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settori': !exists(json, 'settori') ? undefined : SettoriFromJSON(json['settori']),
        'schemaAccreditamento': !exists(json, 'schemaAccreditamento') ? undefined : json['schemaAccreditamento'],
        'normaRiferimento': !exists(json, 'normaRiferimento') ? undefined : json['normaRiferimento'],
        'nota': !exists(json, 'nota') ? undefined : json['nota'],
        'dtEmissione': !exists(json, 'dtEmissione') ? undefined : json['dtEmissione'],
        'denominazioneOdc': !exists(json, 'denominazioneOdc') ? undefined : json['denominazioneOdc'],
        'ncertificato': !exists(json, 'ncertificato') ? undefined : json['ncertificato'],
        'cschemaAccreditamento': !exists(json, 'cschemaAccreditamento') ? undefined : json['cschemaAccreditamento'],
        'cfiscaleOdc': !exists(json, 'cfiscaleOdc') ? undefined : json['cfiscaleOdc'],
    };
}

export function CertificazioneToJSON(value?: Certificazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settori': SettoriToJSON(value.settori),
        'schemaAccreditamento': value.schemaAccreditamento,
        'normaRiferimento': value.normaRiferimento,
        'nota': value.nota,
        'dtEmissione': value.dtEmissione,
        'denominazioneOdc': value.denominazioneOdc,
        'ncertificato': value.ncertificato,
        'cschemaAccreditamento': value.cschemaAccreditamento,
        'cfiscaleOdc': value.cfiscaleOdc,
    };
}


