/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaricheRappresentante,
    CaricheRappresentanteFromJSON,
    CaricheRappresentanteFromJSONTyped,
    CaricheRappresentanteToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonaRappresentante
 */
export interface PersonaRappresentante {
    /**
     * 
     * @type {CaricheRappresentante}
     * @memberof PersonaRappresentante
     */
    caricheRappresentante?: CaricheRappresentante;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    carica?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    frappresentanteRea?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    frappresentanteRi?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaRappresentante
     */
    ftitolare?: string;
}

export function PersonaRappresentanteFromJSON(json: any): PersonaRappresentante {
    return PersonaRappresentanteFromJSONTyped(json, false);
}

export function PersonaRappresentanteFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaRappresentante {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caricheRappresentante': !exists(json, 'caricheRappresentante') ? undefined : CaricheRappresentanteFromJSON(json['caricheRappresentante']),
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'carica': !exists(json, 'carica') ? undefined : json['carica'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'frappresentanteRea': !exists(json, 'frappresentanteRea') ? undefined : json['frappresentanteRea'],
        'frappresentanteRi': !exists(json, 'frappresentanteRi') ? undefined : json['frappresentanteRi'],
        'ftitolare': !exists(json, 'ftitolare') ? undefined : json['ftitolare'],
    };
}

export function PersonaRappresentanteToJSON(value?: PersonaRappresentante | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caricheRappresentante': CaricheRappresentanteToJSON(value.caricheRappresentante),
        'cognome': value.cognome,
        'nome': value.nome,
        'denominazione': value.denominazione,
        'carica': value.carica,
        'cfiscale': value.cfiscale,
        'frappresentanteRea': value.frappresentanteRea,
        'frappresentanteRi': value.frappresentanteRi,
        'ftitolare': value.ftitolare,
    };
}


