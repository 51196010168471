import { AlertHelper } from "@helpers/AlertHelper";
import { customCompanySurveyResourceApi } from "@services/apis/ApiConfiguration";
import { BusinessRatingTableDTOSectionTableEnum, CompanySurvey, CompanySurveyResourceApi } from "@services/apis/generated";
import React, { useEffect, useRef } from "react";
import {StyleSheet, ScrollView, TouchableOpacity, View,Text, StatusBar, LayoutAnimation, Platform, UIManager} from "react-native";
import Collapsible from "react-native-collapsible";
import { i18n } from "../../../../i18n/i18n";
import Icon from "../../../Tools/Icon";
import { BusinessRatingTable } from "../SelfAssestment/BusinessRatingScreen";
import AgilityChart from "./AgilityChart";
import StrengthsRating from "./StrengthsRating";
import TableBusinessRating from "./TableBusinessRating";
import WeaknessesRating from "./WeaknessesRating";

export default function CollapsedBusinessRating(props:{tab:BusinessRatingTable|undefined}) {
    const [collapsed, setCollapsed] = React.useState(true);
    
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
      }

    return(
        <View style = {[styles.label, {backgroundColor:(collapsed?'#222222':'#292929')}]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style = {styles.textLabel}>
                    {props.tab?.sectionTable==BusinessRatingTableDTOSectionTableEnum.Area?i18n.t('screens.business_rating.section_area') 
                        :props.tab?.sectionTable==BusinessRatingTableDTOSectionTableEnum.Agility?i18n.t('screens.business_rating.section_agility')
                        :props.tab?.sectionTable==BusinessRatingTableDTOSectionTableEnum.Attitude?i18n.t('screens.business_rating.section_attitude') 
                        :props.tab?.sectionTable==BusinessRatingTableDTOSectionTableEnum.Growth?i18n.t('screens.business_rating.section_growth')
                        :''
                    }
                    </Text> 
                    <Icon  name="angle-down" color="white"/>
                </View>
            </TouchableOpacity>
            <Collapsible 
                duration={1000} 
                collapsed={collapsed}
                style={styles.contentAccordion}
            >
               <TableBusinessRating tab={props.tab}/>            
            </Collapsible>
        </View> 
    );
}

const styles = StyleSheet.create({
    
    header:{
        flexDirection:"row",
        flex:1,
        alignItems:'center',
    },
    table:{
        flex:1,

    },
    delimiter:{
      borderWidth:1,
      borderRadius:20,
      borderColor:'#505050',
    },
    col:{
        flexDirection:'column',
        height:'auto'
    },
    row:{
        flexDirection:'row',
        flex:1,
    },
    contentAccordion:{
        backgroundColor:"#292929",
        flexDirection:"column",
        height:'auto'
    },
    label:{
        marginBottom:10,
        borderRadius:5,
        display:"flex",
    },
    textLabel:{
        paddingLeft:15,
        paddingVertical:10,
        flex:0.95,
        alignSelf:"flex-start",
        color:"white",
        textAlign:'left',
        fontWeight: 'bold',
    },
    bodyAccordion:{
        backgroundColor:"white",
        flex:1,
    },
    buttonSeeMore:{
        alignSelf:'center'
    },
    textSeeMore:{
        color:'#00CC83',
        padding:15,
        fontFamily:'poppins',
        fontSize:16,
        textDecorationLine:'underline',
    },
  });