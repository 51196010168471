/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DipendentiQualifica,
    DipendentiQualificaFromJSON,
    DipendentiQualificaFromJSONTyped,
    DipendentiQualificaToJSON,
} from './';

/**
 * 
 * @export
 * @interface DipendentiQualifiche
 */
export interface DipendentiQualifiche {
    /**
     * 
     * @type {Array<DipendentiQualifica>}
     * @memberof DipendentiQualifiche
     */
    dipendentiQualifica: Array<DipendentiQualifica>;
}

export function DipendentiQualificheFromJSON(json: any): DipendentiQualifiche {
    return DipendentiQualificheFromJSONTyped(json, false);
}

export function DipendentiQualificheFromJSONTyped(json: any, ignoreDiscriminator: boolean): DipendentiQualifiche {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dipendentiQualifica': ((json['dipendentiQualifica'] as Array<any>).map(DipendentiQualificaFromJSON)),
    };
}

export function DipendentiQualificheToJSON(value?: DipendentiQualifiche | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dipendentiQualifica': ((value.dipendentiQualifica as Array<any>).map(DipendentiQualificaToJSON)),
    };
}


