/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MestiereAa,
    MestiereAaFromJSON,
    MestiereAaFromJSONTyped,
    MestiereAaToJSON,
} from './';

/**
 * 
 * @export
 * @interface MestieriAa
 */
export interface MestieriAa {
    /**
     * 
     * @type {Array<MestiereAa>}
     * @memberof MestieriAa
     */
    mestiereAa: Array<MestiereAa>;
}

export function MestieriAaFromJSON(json: any): MestieriAa {
    return MestieriAaFromJSONTyped(json, false);
}

export function MestieriAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MestieriAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mestiereAa': ((json['mestiereAa'] as Array<any>).map(MestiereAaFromJSON)),
    };
}

export function MestieriAaToJSON(value?: MestieriAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mestiereAa': ((value.mestiereAa as Array<any>).map(MestiereAaToJSON)),
    };
}


