/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndirizzoSedeEstero
 */
export interface IndirizzoSedeEstero {
    /**
     * 
     * @type {string}
     * @memberof IndirizzoSedeEstero
     */
    infoTrasferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoSedeEstero
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoSedeEstero
     */
    indirizzo?: string;
    /**
     * 
     * @type {string}
     * @memberof IndirizzoSedeEstero
     */
    cstato?: string;
}

export function IndirizzoSedeEsteroFromJSON(json: any): IndirizzoSedeEstero {
    return IndirizzoSedeEsteroFromJSONTyped(json, false);
}

export function IndirizzoSedeEsteroFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndirizzoSedeEstero {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoTrasferimento': !exists(json, 'infoTrasferimento') ? undefined : json['infoTrasferimento'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'indirizzo': !exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
    };
}

export function IndirizzoSedeEsteroToJSON(value?: IndirizzoSedeEstero | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoTrasferimento': value.infoTrasferimento,
        'stato': value.stato,
        'indirizzo': value.indirizzo,
        'cstato': value.cstato,
    };
}


