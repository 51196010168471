/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Modifiche,
    ModificheFromJSON,
    ModificheFromJSONTyped,
    ModificheToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessioneRdRea
 */
export interface SessioneRdRea {
    /**
     * 
     * @type {Modifiche}
     * @memberof SessioneRdRea
     */
    modifiche: Modifiche;
    /**
     * 
     * @type {string}
     * @memberof SessioneRdRea
     */
    movimentazione?: string;
    /**
     * 
     * @type {string}
     * @memberof SessioneRdRea
     */
    dtDenuncia?: string;
    /**
     * 
     * @type {string}
     * @memberof SessioneRdRea
     */
    cmovimentazione?: string;
}

export function SessioneRdReaFromJSON(json: any): SessioneRdRea {
    return SessioneRdReaFromJSONTyped(json, false);
}

export function SessioneRdReaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessioneRdRea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modifiche': ModificheFromJSON(json['modifiche']),
        'movimentazione': !exists(json, 'movimentazione') ? undefined : json['movimentazione'],
        'dtDenuncia': !exists(json, 'dtDenuncia') ? undefined : json['dtDenuncia'],
        'cmovimentazione': !exists(json, 'cmovimentazione') ? undefined : json['cmovimentazione'],
    };
}

export function SessioneRdReaToJSON(value?: SessioneRdRea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modifiche': ModificheToJSON(value.modifiche),
        'movimentazione': value.movimentazione,
        'dtDenuncia': value.dtDenuncia,
        'cmovimentazione': value.cmovimentazione,
    };
}


