/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PraticheAnno
 */
export interface PraticheAnno {
    /**
     * 
     * @type {string}
     * @memberof PraticheAnno
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof PraticheAnno
     */
    n?: string;
}

export function PraticheAnnoFromJSON(json: any): PraticheAnno {
    return PraticheAnnoFromJSONTyped(json, false);
}

export function PraticheAnnoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PraticheAnno {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'n': !exists(json, 'n') ? undefined : json['n'],
    };
}

export function PraticheAnnoToJSON(value?: PraticheAnno | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtInizio': value.dtInizio,
        'n': value.n,
    };
}


