
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import hykeeStyle from "@components/hykeeStyle";
import DashboardCompany from "./DashboardCompany";
import SharedContext from "../../navigation/SharedContext";
import { UserExtendedTypeEnum } from "@services/apis/generated";
import React from "react";
import { RootStackScreenProps } from "../../../types";
import { LinearGradient } from "expo-linear-gradient";
import { StatusBar } from "expo-status-bar";
import SearchScreen from "./SearchScreen";
import DashboardSavedCompany from './DashboardSavedCompany';
import DashboardCompanySearched from './DashboardCompanySearched';
import Colors from "../../../constants/Colors";
import AdministratorScreen from './Component/FinancialStatements/AdministratorScreen';
import PartnerScreen from './Component/FinancialStatements/PartnerScreen';
import SharesScreen from './Component/FinancialStatements/SharesScreen';
import SurveyScreen from './Component/SelfAssestment/SurveyScreen';
import SelfAssessmentScreen from './Component/SelfAssestment/SelfAssessmentScreen';
import BusinessRatingScreen from './Component/SelfAssestment/BusinessRatingScreen';
import { Text } from 'react-native';
import CompanyMicroScreen from "@screens/Dashboard/CompanyMicroScreen";
import InsightHome from './ComponentProfile/Insights/InsightHome';
import InsightDetailComponent from './ComponentProfile/Insights/InsightDetailComponent';
import NoticeHome from './ComponentProfile/Notices/NoticeHome';
import NoticeDetailComponent from './ComponentProfile/Notices/NoticeDetailComponent';
import NotificationPage from "@screens/Dashboard/ComponentProfile/NotificationPage";


export default function DashboardScreen({ route, navigation }: RootStackScreenProps<'DashboardScreen'>) {
  const Stack = createNativeStackNavigator();
  const sharedContext = React.useContext(SharedContext);


  return (

    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={hykeeStyle.containerBack}
    >
      <StatusBar style="inverted" />

      {sharedContext.who &&
        <Stack.Navigator
          initialRouteName={sharedContext.who == UserExtendedTypeEnum.Company ? "DashboardCompany" : "SearchScreen"}>
          <Stack.Screen name="DashboardCompany" component={DashboardCompany} options={{ headerShown: false }} />
          <Stack.Screen name="DashboardSavedCompany" component={DashboardSavedCompany} options={{ headerShown: false }} />
          <Stack.Screen name="DashboardCompanySearched" component={DashboardCompanySearched} options={{ headerShown: false }} />
          <Stack.Screen name="SearchScreen" component={SearchScreen} options={{ headerShown: false }} />
          <Stack.Screen name="SharesScreen" component={SharesScreen} options={{ headerShown: false }} />
          <Stack.Screen name="AdministratorScreen" component={AdministratorScreen} options={{ headerShown: false }} />
          <Stack.Screen name="PartnerScreen" component={PartnerScreen} options={{ headerShown: false }} />
          <Stack.Screen name="SelfAssessment" component={SelfAssessmentScreen} options={{ headerShown: false }} />
          <Stack.Screen name="BusinessRating" component={BusinessRatingScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Survey" component={SurveyScreen} options={{ headerShown: false }} />
          <Stack.Screen name="CompanyMicro" component={CompanyMicroScreen} options={{ headerShown: false }} />
          <Stack.Screen name="InsightHome" component={InsightHome} options={{ headerShown: false }} />
          <Stack.Screen name="InsightDetail" component={InsightDetailComponent} options={{ headerShown: false }} />
        </Stack.Navigator>
      }

    </LinearGradient>
  );
}