/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cancellazione,
    CancellazioneFromJSON,
    CancellazioneFromJSONTyped,
    CancellazioneToJSON,
    TrasferimentoAltroRegistro,
    TrasferimentoAltroRegistroFromJSON,
    TrasferimentoAltroRegistroFromJSONTyped,
    TrasferimentoAltroRegistroToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoAltroRegistro
 */
export interface InfoAltroRegistro {
    /**
     * 
     * @type {TrasferimentoAltroRegistro}
     * @memberof InfoAltroRegistro
     */
    trasferimentoAltroRegistro?: TrasferimentoAltroRegistro;
    /**
     * 
     * @type {Cancellazione}
     * @memberof InfoAltroRegistro
     */
    cancellazione?: Cancellazione;
    /**
     * 
     * @type {string}
     * @memberof InfoAltroRegistro
     */
    cciaa?: string;
}

export function InfoAltroRegistroFromJSON(json: any): InfoAltroRegistro {
    return InfoAltroRegistroFromJSONTyped(json, false);
}

export function InfoAltroRegistroFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoAltroRegistro {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trasferimentoAltroRegistro': !exists(json, 'trasferimentoAltroRegistro') ? undefined : TrasferimentoAltroRegistroFromJSON(json['trasferimentoAltroRegistro']),
        'cancellazione': !exists(json, 'cancellazione') ? undefined : CancellazioneFromJSON(json['cancellazione']),
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
    };
}

export function InfoAltroRegistroToJSON(value?: InfoAltroRegistro | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trasferimentoAltroRegistro': TrasferimentoAltroRegistroToJSON(value.trasferimentoAltroRegistro),
        'cancellazione': CancellazioneToJSON(value.cancellazione),
        'cciaa': value.cciaa,
    };
}


