/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddettiImpresa,
    AddettiImpresaFromJSON,
    AddettiImpresaFromJSONTyped,
    AddettiImpresaToJSON,
} from './';

/**
 * 
 * @export
 * @interface StoriaAddetti
 */
export interface StoriaAddetti {
    /**
     * 
     * @type {Array<AddettiImpresa>}
     * @memberof StoriaAddetti
     */
    addettiImpresa?: Array<AddettiImpresa>;
}

export function StoriaAddettiFromJSON(json: any): StoriaAddetti {
    return StoriaAddettiFromJSONTyped(json, false);
}

export function StoriaAddettiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoriaAddetti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addettiImpresa': !exists(json, 'addettiImpresa') ? undefined : ((json['addettiImpresa'] as Array<any>).map(AddettiImpresaFromJSON)),
    };
}

export function StoriaAddettiToJSON(value?: StoriaAddetti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addettiImpresa': value.addettiImpresa === undefined ? undefined : ((value.addettiImpresa as Array<any>).map(AddettiImpresaToJSON)),
    };
}


