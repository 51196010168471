/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiNascita,
    EstremiNascitaFromJSON,
    EstremiNascitaFromJSONTyped,
    EstremiNascitaToJSON,
} from './';

/**
 * 
 * @export
 * @interface FallimentoInProprio
 */
export interface FallimentoInProprio {
    /**
     * 
     * @type {EstremiNascita}
     * @memberof FallimentoInProprio
     */
    estremiNascita?: EstremiNascita;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    dtFallimento?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    tribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    provinciaTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    dtSentenza?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    curatore?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    dtChiusura?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    organoGiudiziario?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    sesso?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    nfallimento?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    nsentenza?: string;
    /**
     * 
     * @type {string}
     * @memberof FallimentoInProprio
     */
    corganoGiudiziario?: string;
}

export function FallimentoInProprioFromJSON(json: any): FallimentoInProprio {
    return FallimentoInProprioFromJSONTyped(json, false);
}

export function FallimentoInProprioFromJSONTyped(json: any, ignoreDiscriminator: boolean): FallimentoInProprio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiNascita': !exists(json, 'estremiNascita') ? undefined : EstremiNascitaFromJSON(json['estremiNascita']),
        'dtFallimento': !exists(json, 'dtFallimento') ? undefined : json['dtFallimento'],
        'tribunale': !exists(json, 'tribunale') ? undefined : json['tribunale'],
        'provinciaTribunale': !exists(json, 'provinciaTribunale') ? undefined : json['provinciaTribunale'],
        'dtSentenza': !exists(json, 'dtSentenza') ? undefined : json['dtSentenza'],
        'curatore': !exists(json, 'curatore') ? undefined : json['curatore'],
        'dtChiusura': !exists(json, 'dtChiusura') ? undefined : json['dtChiusura'],
        'organoGiudiziario': !exists(json, 'organoGiudiziario') ? undefined : json['organoGiudiziario'],
        'sesso': !exists(json, 'sesso') ? undefined : json['sesso'],
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'nfallimento': !exists(json, 'nfallimento') ? undefined : json['nfallimento'],
        'nsentenza': !exists(json, 'nsentenza') ? undefined : json['nsentenza'],
        'corganoGiudiziario': !exists(json, 'corganoGiudiziario') ? undefined : json['corganoGiudiziario'],
    };
}

export function FallimentoInProprioToJSON(value?: FallimentoInProprio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiNascita': EstremiNascitaToJSON(value.estremiNascita),
        'dtFallimento': value.dtFallimento,
        'tribunale': value.tribunale,
        'provinciaTribunale': value.provinciaTribunale,
        'dtSentenza': value.dtSentenza,
        'curatore': value.curatore,
        'dtChiusura': value.dtChiusura,
        'organoGiudiziario': value.organoGiudiziario,
        'sesso': value.sesso,
        'cognome': value.cognome,
        'nome': value.nome,
        'cfiscale': value.cfiscale,
        'nfallimento': value.nfallimento,
        'nsentenza': value.nsentenza,
        'corganoGiudiziario': value.corganoGiudiziario,
    };
}


