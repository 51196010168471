/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserExtended,
    UserExtendedFromJSON,
    UserExtendedToJSON,
} from '../models';

export interface CreateUserExtendedRequest {
    userExtended: UserExtended;
}

export interface DeleteUserExtendedRequest {
    id: number;
}

export interface GetUserExtendedRequest {
    id: number;
}

export interface PartialUpdateUserExtendedRequest {
    id: number;
    userExtended: UserExtended;
}

export interface UpdateUserExtendedRequest {
    id: number;
    userExtended: UserExtended;
}

/**
 * 
 */
export class UserExtendedResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserExtendedRaw(requestParameters: CreateUserExtendedRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling createUserExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-extendeds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async createUserExtended(requestParameters: CreateUserExtendedRequest): Promise<UserExtended> {
        const response = await this.createUserExtendedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserExtendedRaw(requestParameters: DeleteUserExtendedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserExtended(requestParameters: DeleteUserExtendedRequest): Promise<void> {
        await this.deleteUserExtendedRaw(requestParameters);
    }

    /**
     */
    async getAllUserExtendedsRaw(): Promise<runtime.ApiResponse<Array<UserExtended>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-extendeds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserExtendedFromJSON));
    }

    /**
     */
    async getAllUserExtendeds(): Promise<Array<UserExtended>> {
        const response = await this.getAllUserExtendedsRaw();
        return await response.value();
    }

    /**
     */
    async getUserExtendedRaw(requestParameters: GetUserExtendedRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async getUserExtended(requestParameters: GetUserExtendedRequest): Promise<UserExtended> {
        const response = await this.getUserExtendedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserExtendedRaw(requestParameters: PartialUpdateUserExtendedRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserExtended.');
        }

        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling partialUpdateUserExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserExtended(requestParameters: PartialUpdateUserExtendedRequest): Promise<UserExtended> {
        const response = await this.partialUpdateUserExtendedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserExtendedRaw(requestParameters: UpdateUserExtendedRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserExtended.');
        }

        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling updateUserExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async updateUserExtended(requestParameters: UpdateUserExtendedRequest): Promise<UserExtended> {
        const response = await this.updateUserExtendedRaw(requestParameters);
        return await response.value();
    }

}
