/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PasswordResetDTO,
    PasswordResetDTOFromJSON,
    PasswordResetDTOToJSON,
    RegistrationDTO,
    RegistrationDTOFromJSON,
    RegistrationDTOToJSON,
} from '../models';

export interface ActivateAccountCustomRequest {
    key: string;
}

export interface RegisterAccountCustomRequest {
    registrationDTO: RegistrationDTO;
}

export interface RequestPasswordResetRequest {
    passwordResetDTO: PasswordResetDTO;
}

/**
 * 
 */
export class CustomAccountResourceApi extends runtime.BaseAPI {

    /**
     */
    async activateAccountCustomRaw(requestParameters: ActivateAccountCustomRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling activateAccountCustom.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/activate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateAccountCustom(requestParameters: ActivateAccountCustomRequest): Promise<void> {
        await this.activateAccountCustomRaw(requestParameters);
    }

    /**
     */
    async registerAccountCustomRaw(requestParameters: RegisterAccountCustomRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.registrationDTO === null || requestParameters.registrationDTO === undefined) {
            throw new runtime.RequiredError('registrationDTO','Required parameter requestParameters.registrationDTO was null or undefined when calling registerAccountCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationDTOToJSON(requestParameters.registrationDTO),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async registerAccountCustom(requestParameters: RegisterAccountCustomRequest): Promise<string> {
        const response = await this.registerAccountCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async requestPasswordResetRaw(requestParameters: RequestPasswordResetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordResetDTO === null || requestParameters.passwordResetDTO === undefined) {
            throw new runtime.RequiredError('passwordResetDTO','Required parameter requestParameters.passwordResetDTO was null or undefined when calling requestPasswordReset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/account/reset-password/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetDTOToJSON(requestParameters.passwordResetDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestPasswordReset(requestParameters: RequestPasswordResetRequest): Promise<void> {
        await this.requestPasswordResetRaw(requestParameters);
    }

}
