/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SintesiQuotata
 */
export interface SintesiQuotata {
    /**
     * 
     * @type {string}
     * @memberof SintesiQuotata
     */
    annoAl?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiQuotata
     */
    fonte?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiQuotata
     */
    cfonte?: string;
}

export function SintesiQuotataFromJSON(json: any): SintesiQuotata {
    return SintesiQuotataFromJSONTyped(json, false);
}

export function SintesiQuotataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SintesiQuotata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annoAl': !exists(json, 'annoAl') ? undefined : json['annoAl'],
        'fonte': !exists(json, 'fonte') ? undefined : json['fonte'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function SintesiQuotataToJSON(value?: SintesiQuotata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annoAl': value.annoAl,
        'fonte': value.fonte,
        'cfonte': value.cfonte,
    };
}


