/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SettoreAttivita,
    SettoreAttivitaFromJSON,
    SettoreAttivitaFromJSONTyped,
    SettoreAttivitaToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettoriAttivita
 */
export interface SettoriAttivita {
    /**
     * 
     * @type {Array<SettoreAttivita>}
     * @memberof SettoriAttivita
     */
    settoreAttivita: Array<SettoreAttivita>;
}

export function SettoriAttivitaFromJSON(json: any): SettoriAttivita {
    return SettoriAttivitaFromJSONTyped(json, false);
}

export function SettoriAttivitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettoriAttivita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settoreAttivita': ((json['settoreAttivita'] as Array<any>).map(SettoreAttivitaFromJSON)),
    };
}

export function SettoriAttivitaToJSON(value?: SettoriAttivita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settoreAttivita': ((value.settoreAttivita as Array<any>).map(SettoreAttivitaToJSON)),
    };
}


