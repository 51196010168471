/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Persona,
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Persone
 */
export interface Persone {
    /**
     * 
     * @type {Array<Persona>}
     * @memberof Persone
     */
    persona: Array<Persona>;
}

export function PersoneFromJSON(json: any): Persone {
    return PersoneFromJSONTyped(json, false);
}

export function PersoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Persone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'persona': ((json['persona'] as Array<any>).map(PersonaFromJSON)),
    };
}

export function PersoneToJSON(value?: Persone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona': ((value.persona as Array<any>).map(PersonaToJSON)),
    };
}


