/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Settore,
    SettoreFromJSON,
    SettoreFromJSONTyped,
    SettoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface Settori
 */
export interface Settori {
    /**
     * 
     * @type {Array<Settore>}
     * @memberof Settori
     */
    settore: Array<Settore>;
}

export function SettoriFromJSON(json: any): Settori {
    return SettoriFromJSONTyped(json, false);
}

export function SettoriFromJSONTyped(json: any, ignoreDiscriminator: boolean): Settori {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settore': ((json['settore'] as Array<any>).map(SettoreFromJSON)),
    };
}

export function SettoriToJSON(value?: Settori | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settore': ((value.settore as Array<any>).map(SettoreToJSON)),
    };
}


