/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyLinkRequestState,
    CompanyLinkRequestStateFromJSON,
    CompanyLinkRequestStateFromJSONTyped,
    CompanyLinkRequestStateToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyLinkRequest
 */
export interface CompanyLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyLinkRequest
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyLinkRequest
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyLinkRequest
     */
    currentState?: CompanyLinkRequestCurrentStateEnum;
    /**
     * 
     * @type {Company}
     * @memberof CompanyLinkRequest
     */
    company?: Company;
    /**
     * 
     * @type {Company}
     * @memberof CompanyLinkRequest
     */
    consultant?: Company;
    /**
     * 
     * @type {User}
     * @memberof CompanyLinkRequest
     */
    referral?: User;
    /**
     * 
     * @type {Set<CompanyLinkRequestState>}
     * @memberof CompanyLinkRequest
     */
    companyLinkRequestStates?: Set<CompanyLinkRequestState>;
}

/**
* @export
* @enum {string}
*/
export enum CompanyLinkRequestCurrentStateEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}

export function CompanyLinkRequestFromJSON(json: any): CompanyLinkRequest {
    return CompanyLinkRequestFromJSONTyped(json, false);
}

export function CompanyLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'consultant': !exists(json, 'consultant') ? undefined : CompanyFromJSON(json['consultant']),
        'referral': !exists(json, 'referral') ? undefined : UserFromJSON(json['referral']),
        'companyLinkRequestStates': !exists(json, 'companyLinkRequestStates') ? undefined : ((json['companyLinkRequestStates'] as Array<any>).map(CompanyLinkRequestStateFromJSON)),
    };
}

export function CompanyLinkRequestToJSON(value?: CompanyLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'currentState': value.currentState,
        'company': CompanyToJSON(value.company),
        'consultant': CompanyToJSON(value.consultant),
        'referral': UserToJSON(value.referral),
        'companyLinkRequestStates': value.companyLinkRequestStates === undefined ? undefined : ((value.companyLinkRequestStates as Array<any>).map(CompanyLinkRequestStateToJSON)),
    };
}


