/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SessioneRdRea,
    SessioneRdReaFromJSON,
    SessioneRdReaFromJSONTyped,
    SessioneRdReaToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessioniRdRea
 */
export interface SessioniRdRea {
    /**
     * 
     * @type {Array<SessioneRdRea>}
     * @memberof SessioniRdRea
     */
    sessioneRdRea: Array<SessioneRdRea>;
}

export function SessioniRdReaFromJSON(json: any): SessioniRdRea {
    return SessioniRdReaFromJSONTyped(json, false);
}

export function SessioniRdReaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessioniRdRea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessioneRdRea': ((json['sessioneRdRea'] as Array<any>).map(SessioneRdReaFromJSON)),
    };
}

export function SessioniRdReaToJSON(value?: SessioniRdRea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessioneRdRea': ((value.sessioneRdRea as Array<any>).map(SessioneRdReaToJSON)),
    };
}


