/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyAteco,
    CompanyAtecoFromJSON,
    CompanyAtecoFromJSONTyped,
    CompanyAtecoToJSON,
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyAddress
 */
export interface CompanyAddress {
    /**
     * 
     * @type {number}
     * @memberof CompanyAddress
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyAddress
     */
    progressiveNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    streetToponymy?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    codComOffices?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    legalStatus?: CompanyAddressLegalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    activityStatus?: CompanyAddressActivityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    emailPEC?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    nreaCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    cciaaCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    legalNature?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    source?: CompanyAddressSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddress
     */
    website?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyAddress
     */
    isMainAddress?: boolean;
    /**
     * 
     * @type {Set<CompanyAteco>}
     * @memberof CompanyAddress
     */
    companyAtecos?: Set<CompanyAteco>;
    /**
     * 
     * @type {CompanyData}
     * @memberof CompanyAddress
     */
    companyData?: CompanyData;
}

/**
* @export
* @enum {string}
*/
export enum CompanyAddressLegalStatusEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}/**
* @export
* @enum {string}
*/
export enum CompanyAddressActivityStatusEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}/**
* @export
* @enum {string}
*/
export enum CompanyAddressSourceEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}

export function CompanyAddressFromJSON(json: any): CompanyAddress {
    return CompanyAddressFromJSONTyped(json, false);
}

export function CompanyAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'progressiveNumber': !exists(json, 'progressiveNumber') ? undefined : json['progressiveNumber'],
        'streetToponymy': !exists(json, 'streetToponymy') ? undefined : json['streetToponymy'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'codComOffices': !exists(json, 'codComOffices') ? undefined : json['codComOffices'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'legalStatus': !exists(json, 'legalStatus') ? undefined : json['legalStatus'],
        'activityStatus': !exists(json, 'activityStatus') ? undefined : json['activityStatus'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailPEC': !exists(json, 'emailPEC') ? undefined : json['emailPEC'],
        'nreaCode': !exists(json, 'nreaCode') ? undefined : json['nreaCode'],
        'cciaaCode': !exists(json, 'cciaaCode') ? undefined : json['cciaaCode'],
        'legalNature': !exists(json, 'legalNature') ? undefined : json['legalNature'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'isMainAddress': !exists(json, 'isMainAddress') ? undefined : json['isMainAddress'],
        'companyAtecos': !exists(json, 'companyAtecos') ? undefined : ((json['companyAtecos'] as Array<any>).map(CompanyAtecoFromJSON)),
        'companyData': !exists(json, 'companyData') ? undefined : CompanyDataFromJSON(json['companyData']),
    };
}

export function CompanyAddressToJSON(value?: CompanyAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'progressiveNumber': value.progressiveNumber,
        'streetToponymy': value.streetToponymy,
        'street': value.street,
        'streetNumber': value.streetNumber,
        'codComOffices': value.codComOffices,
        'city': value.city,
        'province': value.province,
        'region': value.region,
        'country': value.country,
        'zipCode': value.zipCode,
        'legalStatus': value.legalStatus,
        'activityStatus': value.activityStatus,
        'sector': value.sector,
        'telephone': value.telephone,
        'email': value.email,
        'emailPEC': value.emailPEC,
        'nreaCode': value.nreaCode,
        'cciaaCode': value.cciaaCode,
        'legalNature': value.legalNature,
        'source': value.source,
        'website': value.website,
        'isMainAddress': value.isMainAddress,
        'companyAtecos': value.companyAtecos === undefined ? undefined : ((value.companyAtecos as Array<any>).map(CompanyAtecoToJSON)),
        'companyData': CompanyDataToJSON(value.companyData),
    };
}


