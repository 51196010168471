/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Carica,
    CaricaFromJSON,
    CaricaFromJSONTyped,
    CaricaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cariche
 */
export interface Cariche {
    /**
     * 
     * @type {Array<Carica>}
     * @memberof Cariche
     */
    carica: Array<Carica>;
}

export function CaricheFromJSON(json: any): Cariche {
    return CaricheFromJSONTyped(json, false);
}

export function CaricheFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cariche {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carica': ((json['carica'] as Array<any>).map(CaricaFromJSON)),
    };
}

export function CaricheToJSON(value?: Cariche | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carica': ((value.carica as Array<any>).map(CaricaToJSON)),
    };
}


