/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserConsultant,
    UserConsultantFromJSON,
    UserConsultantToJSON,
} from '../models';

export interface DeleteUserConsultantForLoggedUserRequest {
    id: number;
}

export interface GetAllUserByConsultantIdRequest {
    consultantId: number;
}

export interface GetConsultantByUserIdRequest {
    userId: number;
}

/**
 * 
 */
export class CustomUserConsultantResourceApi extends runtime.BaseAPI {

    /**
     */
    async deleteUserConsultantForLoggedUserRaw(requestParameters: DeleteUserConsultantForLoggedUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConsultantForLoggedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserConsultantForLoggedUser(requestParameters: DeleteUserConsultantForLoggedUserRequest): Promise<void> {
        await this.deleteUserConsultantForLoggedUserRaw(requestParameters);
    }

    /**
     */
    async getAllUserByConsultantIdRaw(requestParameters: GetAllUserByConsultantIdRequest): Promise<runtime.ApiResponse<Array<UserConsultant>>> {
        if (requestParameters.consultantId === null || requestParameters.consultantId === undefined) {
            throw new runtime.RequiredError('consultantId','Required parameter requestParameters.consultantId was null or undefined when calling getAllUserByConsultantId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultants/{consultantId}`.replace(`{${"consultantId"}}`, encodeURIComponent(String(requestParameters.consultantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantFromJSON));
    }

    /**
     */
    async getAllUserByConsultantId(requestParameters: GetAllUserByConsultantIdRequest): Promise<Array<UserConsultant>> {
        const response = await this.getAllUserByConsultantIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getConsultantByUserIdRaw(requestParameters: GetConsultantByUserIdRequest): Promise<runtime.ApiResponse<Array<UserConsultant>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getConsultantByUserId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-consultants/filter/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantFromJSON));
    }

    /**
     */
    async getConsultantByUserId(requestParameters: GetConsultantByUserIdRequest): Promise<Array<UserConsultant>> {
        const response = await this.getConsultantByUserIdRaw(requestParameters);
        return await response.value();
    }

}
