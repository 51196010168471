/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiPratica
 */
export interface EstremiPratica {
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    tipoAdempimento?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    dtAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    dtProtocollo?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    dtDeposito?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    dtDichiarazione?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    tipoElenco?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    tipoRichiesta?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    cpratica?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    ctipoAdempimento?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    friconferma?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    ctipoElenco?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPratica
     */
    ctipoRichiesta?: string;
}

export function EstremiPraticaFromJSON(json: any): EstremiPratica {
    return EstremiPraticaFromJSONTyped(json, false);
}

export function EstremiPraticaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiPratica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoAdempimento': !exists(json, 'tipoAdempimento') ? undefined : json['tipoAdempimento'],
        'dtAtto': !exists(json, 'dtAtto') ? undefined : json['dtAtto'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtProtocollo': !exists(json, 'dtProtocollo') ? undefined : json['dtProtocollo'],
        'dtDeposito': !exists(json, 'dtDeposito') ? undefined : json['dtDeposito'],
        'dtDichiarazione': !exists(json, 'dtDichiarazione') ? undefined : json['dtDichiarazione'],
        'tipoElenco': !exists(json, 'tipoElenco') ? undefined : json['tipoElenco'],
        'tipoRichiesta': !exists(json, 'tipoRichiesta') ? undefined : json['tipoRichiesta'],
        'cpratica': !exists(json, 'cpratica') ? undefined : json['cpratica'],
        'ctipoAdempimento': !exists(json, 'ctipoAdempimento') ? undefined : json['ctipoAdempimento'],
        'friconferma': !exists(json, 'friconferma') ? undefined : json['friconferma'],
        'ctipoElenco': !exists(json, 'ctipoElenco') ? undefined : json['ctipoElenco'],
        'ctipoRichiesta': !exists(json, 'ctipoRichiesta') ? undefined : json['ctipoRichiesta'],
    };
}

export function EstremiPraticaToJSON(value?: EstremiPratica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoAdempimento': value.tipoAdempimento,
        'dtAtto': value.dtAtto,
        'cciaa': value.cciaa,
        'anno': value.anno,
        'n': value.n,
        'dtProtocollo': value.dtProtocollo,
        'dtDeposito': value.dtDeposito,
        'dtDichiarazione': value.dtDichiarazione,
        'tipoElenco': value.tipoElenco,
        'tipoRichiesta': value.tipoRichiesta,
        'cpratica': value.cpratica,
        'ctipoAdempimento': value.ctipoAdempimento,
        'friconferma': value.friconferma,
        'ctipoElenco': value.ctipoElenco,
        'ctipoRichiesta': value.ctipoRichiesta,
    };
}


