/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlbiRuoliLicenze
 */
export interface AlbiRuoliLicenze {
    /**
     * 
     * @type {Array<object>}
     * @memberof AlbiRuoliLicenze
     */
    datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti?: Array<object>;
}

export function AlbiRuoliLicenzeFromJSON(json: any): AlbiRuoliLicenze {
    return AlbiRuoliLicenzeFromJSONTyped(json, false);
}

export function AlbiRuoliLicenzeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlbiRuoliLicenze {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti': !exists(json, 'datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti') ? undefined : json['datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti'],
    };
}

export function AlbiRuoliLicenzeToJSON(value?: AlbiRuoliLicenze | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti': value.datiArtigianiOrRiconoscimentiProfessionaliOrAbilitazioniImpiantisti,
    };
}


