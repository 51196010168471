// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { PartialUpdateUserExtendedRequest } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Profile, RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import Icon from '../../Tools/Icon';
import Colors from "../../../../constants/Colors";
import { ColorTextLightGreen } from '@components/ColorTheme';
import {customUserExtendedResourceApi} from "../../../services/apis/ApiConfiguration";



export default function CompanyData({ navigation, route }: RootStackScreenProps<'CompanyData'>, props: { profile: Profile | null }) {

  const [editable, setEditable] = React.useState<{ [key in Fields]: boolean }>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false
  })

  const [profileState, setProfileState] = React.useState<Profile>({})

  const [profileEditState, setProfileEditState] = React.useState<Profile>({})

  /*  There are two states for the profile:
  *   profileState, which holds the effective profile data to send in the api call
  *   profileEditState, which holds the values of the different text boxes
  *   When the data is saved (save()), the profileState gets modified with the data to
  *   send to the API.
  */

  enum Fields {
    firstName = "firstName",
    lastName = "lastName",
    email = "email",
    phone = "phone"
  }

  // Populate the profile states on creation
  useEffect(() => {
    console.log(navigation, route);
    if (route.params) {
      setProfileState({
        id: route.params.id,
        firstName: route.params?.firstName,
        lastName: route.params?.lastName,
        email: route.params.email,
        phone: route.params?.phone
      })
      setProfileEditState({
        id: route.params.id,
        firstName: route.params?.firstName,
        lastName: route.params?.lastName,
        email: route.params.email,
        phone: route.params?.phone
      })
    }
  }, [route.params])

  const toggleSave = (key: Fields) => {
    editable[key] = !editable[key];
    // Creating clone so React knows object has changes
    setEditable(Object.assign({}, editable));

    if (!editable[key]) {
      save(key);
    }
  }

  const save = (key: Fields) => {
    let input: PartialUpdateUserExtendedRequest = {
      id: route.params?.id!,
      userExtended: {
        id: route.params?.id
      }
    }
    switch (key) {
      case Fields.firstName:
        input.userExtended.firstName = profileEditState.firstName
        break;
      case Fields.lastName:
        input.userExtended.lastName = profileEditState.lastName;
        break;
      case Fields.phone:
        input.userExtended.telephone = profileEditState.phone
        break;

    }
    customUserExtendedResourceApi.partialUpdateUserExtendedMe(input).then(() => {
      profileState[key] = profileEditState[key];
      setProfileState({ ...profileState });

    }).catch((e) => {
      console.error(e);
      AlertHelper.showSimpleErrorAlert();
    });
  }

  const handleChangeText = (key: Fields, text: string): void => {
    profileEditState[key] = text;
    setProfileEditState({ ...profileEditState });
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.company_data")}</Text>
      </View>

      <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.company_name')}
            </Text>
            <Text style={styles.value}>
              {route.params?.companyName}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
        
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.address')}
            </Text>
            <Text style={styles.value}>
              {route.params?.address}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.town_city')}
            </Text>
            <Text style={styles.value}>
              {route.params?.city}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.zip')}
            </Text>
            <Text style={styles.value}>
              {route.params?.postalCode}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.company_vat')}
            </Text>
            <Text style={styles.value}>
              {route.params?.vatNumber}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.sector')}
            </Text>
            <Text style={styles.value}>
              {route.params?.sector}
            </Text>
          </View>
          <View style={hykeeStyle.icon}>
            <Icon name={'edit'} color={ColorTextLightGreen()} />
            <Text style={styles.edit}>
              {i18n.t('screens.profile.edit')}
            </Text>
          </View>
        </View>
      </ScrollView>

    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    color: ColorTextLightGreen(),
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    padding: 15
  },
  col: {
    flex: 0.8
  },
  value: {
    fontFamily: 'poppins',
    color: '#737373',
    fontSize: 12
  },
  label: {
    fontFamily: 'poppins',
    color: 'white',
    fontSize: 16
  },
  edit: {
    marginLeft: 5,
    fontSize: 10,
    alignSelf: 'center',
    color: ColorTextLightGreen()
  },
});


