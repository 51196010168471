import { BalanceXbrlDTO, BalanceXbrlDTOCurrentMoreFieldsStateEnum, BalanceXbrlDTOTypeEnum, CompanyBalanceSheet, CompanyBalanceSheetTypeEnum } from "@services/apis/generated";
import { StyleSheet, View, Text, ScrollView, Dimensions, ViewStyle, TouchableOpacity, Share } from "react-native";
import { Balance, BalanceData, BalanceObj, DownloadedBalance, FieldsToBeShownObjectType, RowObject } from "../../../../../types";
import { Table, Row } from 'react-native-table-component';
import { customScoreResourceApi } from "@services/apis/ApiConfiguration";
import React, { useCallback, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { i18n } from "@i18n/i18n";
import * as Localization from 'expo-localization';
import { fieldsToBeShownMicroObject, fieldsToBeShownObject, fieldsToBeShownWhenCollapsedObject } from "./DataTableFields";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "@screens/Tools/Icon";
import { ErrorHelper } from "@helpers/ErrorHelper";
import AppContext from "../../../../navigation/AppContext";
import { Tooltip } from 'react-native-tooltip-mroads';

export default function BalanceSheetDataTable(props: { balanceListId: any, animationEnd: boolean }) {

    const CE_PREFIX = "ce";
    const SP_PREFIX = "sp";
    const RF_PREFIX = "rf";
    const RATIO_PREFIX = "ratio";
    const ADJUSTMENTS = "adjustments"

    let references: { [key: string]: any } = {}

    let numberOfbalances = 0;

    let fieldsCollapsed = fieldsToBeShownWhenCollapsedObject.map((field) => field.key)

    let fieldsFull = fieldsToBeShownObject.map((field) => field.key)

    let fieldsMicro = fieldsToBeShownMicroObject.map((field) => field.key)

    useFocusEffect(
        useCallback(() => {
            getAndParseBalances()

            return () => {
            };
        }, [props.balanceListId])
    )


    useFocusEffect(
        useCallback(() => {
            if (props.animationEnd) bottomRef.current?.scrollToEnd()
        }, [props.animationEnd])
    )
    // ***TYPES***

    type TableDefinitionObject = {
        columnTitle: any[],
        rowTitle: any[],
        rowTitleCollapsed: any[],
        rowData: any[],
        rowDataCollapsed: any[],
        colWidth: number[],
        rowWidth: number[],
        dataWidth: number[],
        loading: boolean
    }

    // ***STATE***

    let tableDefinitionInit: TableDefinitionObject = {
        columnTitle: [],
        rowTitle: [],
        rowTitleCollapsed: [],
        rowData: [],
        rowDataCollapsed: [],
        colWidth: [],
        rowWidth: [],
        dataWidth: [],
        loading: true
    }

    const [tableDefinition, setTableDefinition] = React.useState<TableDefinitionObject>(tableDefinitionInit);
    const [seeMore, setSeeMore] = React.useState<boolean>(false);

    const topRef = React.useRef<ScrollView>(null);
    const bottomRef = React.useRef<ScrollView>(null);

    let microBalance: boolean = false;
    let balancesState: BalanceXbrlDTOCurrentMoreFieldsStateEnum | undefined = undefined

    let win = Dimensions.get('window')
    const balanceHeight = win.height - (win.height * 0.45)

    const sharedContext = React.useContext(SharedContext);

    // ***FUNCTIONS***

    // Sort rowTableArray based on fieldsToBeShownObject
    function sortArray(rowArrayTable: BalanceData[][]) {
        let orderedArray = Object.values(fieldsToBeShownObject).map((e) => e.key)
        rowArrayTable.map((obj) => {
            obj.sort((a, b) => {
                return orderedArray.indexOf(a.key) - orderedArray.indexOf(b.key);
            })
        })
    }

    // Sort rowTableArray based on fieldsToBeShownMicroObject
    function sortMicroArray(rowArrayTable: BalanceData[][]) {
        let orderedArray = Object.values(fieldsToBeShownMicroObject).map((e) => e.key)
        rowArrayTable.map((obj) => {
            obj.sort((a, b) => {
                return orderedArray.indexOf(a.key) - orderedArray.indexOf(b.key);
            })
        })
    }

    function determineSpecialValue(value: any) {
        if (value == 0) {
            return "-"
        }
        if (value == -0) {
            return "-"
        }
        if (value == "NaN") {
            return "-"
        }
        if (value == references["maxReference"]) {
            return "MAX"
        }
        if (value == references["cashReference"]) {
            return "CASH"
        }
        if (value == references["positiveReference"]) {
            return "POS"
        }
        if (value == references["negativeReference"]) {
            return "NEG"
        }
        return null
    }


    function parseAndFormatData(value: any, field: FieldsToBeShownObjectType) {
        let specialValue: any = determineSpecialValue(value);

        let stringValue = value.toString()

        let formatter
        if (field.isPercentage || field.hasDecimal) {
            formatter = new Intl.NumberFormat(Localization.locale, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            })
        } else {
            formatter = new Intl.NumberFormat(Localization.locale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })
        }

        if (field.hasDays) {
            formatter = new Intl.NumberFormat(Localization.locale, {
                maximumFractionDigits: 0,
            })
        }

        let parsedValue

        if (field.isPercentage) {
            parsedValue = (value * 100)
        } else {
            parsedValue = (value * 1)
        }

        let formattedString = formatter.format(parsedValue)

        if (field.isPercentage && !specialValue) {
            formattedString = formattedString + " %"
        }

        let bold = {}
        if (field.bold) {
            bold = { fontWeight: "bold" }
        }
        let italic = {}
        if (field.italic) {
            italic = { fontStyle: "italic" }
        }
        let wideRow
        if (field.wideRow) {
            wideRow = "wideRow"
        }
        if (field.hasXIndicator && !specialValue) {
            formattedString = formattedString + " x"
        }
        if (field.hasDays && !specialValue) {
            formattedString = formattedString + " " + i18n.t("screens.balance_sheet.days")
        }
        if (specialValue) {
            return (
                <Text key={wideRow} style={[styles.text, bold, italic]}>{specialValue}</Text>
            )
        }
        if (stringValue.startsWith("-")) {
            return (
                <Text key={wideRow} style={[styles.textNegative, bold, italic]}>{formattedString}</Text>
            );
        } else {
            return (
                <Text key={wideRow} style={[styles.text, bold, italic]}>{formattedString}</Text>
            );
        }

    }

    function setupTableDefinition(rowArrayData: any) {

        let tableStructure: TableDefinitionObject = {
            columnTitle: [],
            rowTitle: [],
            rowTitleCollapsed: [],
            rowData: [],
            rowDataCollapsed: [],
            colWidth: [],
            rowWidth: [],
            dataWidth: [],
            loading: true
        };

        let headers: string[] = []
        if (rowArrayData) {
            rowArrayData.forEach((data: any) => {
                tableStructure = {
                    columnTitle: [],
                    rowTitle: [],
                    rowTitleCollapsed: [],
                    rowData: [],
                    rowDataCollapsed: [],
                    colWidth: [],
                    rowWidth: [],
                    dataWidth: [],
                    loading: true
                };


                let previousSection: string;

                data.forEach((rowData: any) => {

                    //It defines the columns title
                    if (rowData.key == 'anno') {
                        rowData.data.forEach((columnDef: any) => {
                            let column = Object.values(columnDef).toString();
                            headers.push(column);
                        });
                    }
                    else {
                        if (microBalance) {
                            if (balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Verified ||
                                balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Warning ||
                                balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Insecure) {
                                if (fieldsFull.includes(rowData.key)) {
                                    if (!rowData.key.startsWith(previousSection)) {
                                        if (rowData.key.startsWith(CE_PREFIX)) {
                                            createHeaderRow(CE_PREFIX)
                                            previousSection = CE_PREFIX
                                        }
                                        if (rowData.key.startsWith(SP_PREFIX)) {
                                            createHeaderRow(SP_PREFIX)
                                            previousSection = SP_PREFIX
                                        }

                                        if (rowData.key.startsWith(RF_PREFIX)) {
                                            createHeaderRow(RF_PREFIX)
                                            previousSection = RF_PREFIX
                                        }

                                        if (rowData.key.startsWith(RATIO_PREFIX)) {
                                            createHeaderRow(RATIO_PREFIX)
                                            previousSection = RATIO_PREFIX
                                        }
                                    }
                                    if (rowData.key == "ceHykeeRisultatoEsercizio") {
                                        createRow(rowData)
                                        createBlankRow()
                                        return
                                    }
                                    if (rowData.key == "ceAdjHykeeEBITAdj") {
                                        createRow(rowData)
                                        createBlankRow();
                                        return
                                    }
                                    if (rowData.key == "rfHykeeFreeCashFlowDeltaDisponibilitaLiquide") {
                                        createRow(rowData)
                                        createBlankRow()
                                        return
                                    }
                                    if (rowData.key == "spHykeeEplusPFN") {
                                        createRow(rowData)
                                        createBlankRow();
                                        return
                                    }
                                    if (rowData.key == "ceAdjHykeeEBITDA") {
                                        createHeaderRow(ADJUSTMENTS, true)
                                    }
                                    createRow(rowData)
                                }
                            } else {
                                if (fieldsMicro.includes(rowData.key)) {
                                    if (!rowData.key.startsWith(previousSection)) {
                                        if (rowData.key.startsWith(CE_PREFIX)) {
                                            createHeaderRow(CE_PREFIX)
                                            previousSection = CE_PREFIX
                                        }
                                        if (rowData.key.startsWith(SP_PREFIX)) {
                                            createHeaderRow(SP_PREFIX)
                                            previousSection = SP_PREFIX
                                        }
                                        if (rowData.key.startsWith(RF_PREFIX)) {
                                            createHeaderRow(RF_PREFIX)
                                            previousSection = RF_PREFIX
                                        }
                                        if (rowData.key.startsWith(RATIO_PREFIX)) {
                                            createHeaderRow(RATIO_PREFIX)
                                            previousSection = RATIO_PREFIX
                                        }
                                    }
                                    if (rowData.key == "ceHykeeRisultatoEsercizio") {
                                        createMicroRow(rowData)
                                        createBlankRow()
                                        return
                                    }
                                    if (rowData.key == "ceAdjHykeeEBITAdj") {
                                        createMicroRow(rowData)
                                        createBlankRow();
                                        return
                                    }
                                    if (rowData.key == "totalePassivo") {
                                        createMicroRow(rowData)
                                        createBlankRow();
                                        return
                                    }
                                    if (rowData.key == "ceAdjHykeeEBITDA") {
                                        createHeaderRow(ADJUSTMENTS, true)
                                    }
                                    createMicroRow(rowData)
                                }

                            }


                        }

                        if (!microBalance && fieldsFull.includes(rowData.key)) {
                            if (!rowData.key.startsWith(previousSection)) {
                                if (rowData.key.startsWith(CE_PREFIX)) {
                                    createHeaderRow(CE_PREFIX)
                                    previousSection = CE_PREFIX
                                }
                                if (rowData.key.startsWith(SP_PREFIX)) {
                                    createHeaderRow(SP_PREFIX)
                                    previousSection = SP_PREFIX
                                }
                                if (rowData.key.startsWith(RF_PREFIX)) {
                                    createHeaderRow(RF_PREFIX)
                                    previousSection = RF_PREFIX
                                }
                                if (rowData.key.startsWith(RATIO_PREFIX)) {
                                    createHeaderRow(RATIO_PREFIX)
                                    previousSection = RATIO_PREFIX
                                }
                            }
                            if (rowData.key == "ceHykeeRisultatoEsercizio") {
                                createRow(rowData)
                                createBlankRow()
                                return
                            }
                            if (rowData.key == "ceAdjHykeeEBITAdj") {
                                createRow(rowData)
                                createBlankRow();
                                return
                            }
                            if (rowData.key == "rfHykeeFreeCashFlowDeltaDisponibilitaLiquide") {
                                createRow(rowData)
                                createBlankRow()
                                return
                            }
                            if (rowData.key == "spHykeeEplusPFN") {
                                createRow(rowData)
                                createBlankRow();
                                return
                            }
                            if (rowData.key == "ceAdjHykeeEBITDA") {
                                createHeaderRow(ADJUSTMENTS, true)
                            }
                            createRow(rowData)
                        }
                        if (fieldsCollapsed.includes(rowData.key)) {
                            createRowCollapsed(rowData)
                        }

                    }
                });
            });

        }


        //Remove duplicates from headers
        headers = headers.filter((c, index) => {
            return headers.indexOf(c) === index;
        });


        tableStructure.columnTitle.push(<Text style={rowStyles.titleHeader}>{i18n.t("screens.balance_sheet.financial_statements")}</Text>);
        tableStructure.colWidth.push(120);
        headers.forEach((header: string) => {
            tableStructure.columnTitle.push(<Text style={rowStyles.yearHeader}>{header}</Text>);
            tableStructure.dataWidth.push(100)
            tableStructure.colWidth.push(100);
        })
        tableStructure.loading = false;
        setTableDefinition(tableStructure)

        function createMicroRow(rowData: any, full?: boolean) {
            let field: FieldsToBeShownObjectType;
            if (full) {
                field = fieldsToBeShownObject.find((object) => object.key == rowData.key)!
            } else {
                field = fieldsToBeShownMicroObject.find((object) => object.key == rowData.key)!
            }
            let row: JSX.Element[] = [];
            let titleElement = createTitleElement(rowData, field);

            let recordTitle: JSX.Element[] = []
            recordTitle.push(titleElement)
            tableStructure.rowTitle.push(recordTitle)
            rowData.data.forEach((element: any) => {
                row.push(parseAndFormatData(Object.values(element), field));
            });

            tableStructure.rowData.push(row);
            tableStructure.rowWidth.push(100);
        }

        function createRow(rowData: any) {
            let field: FieldsToBeShownObjectType = fieldsToBeShownObject.find((object) => object.key == rowData.key)!
            let row: JSX.Element[] = [];
            let titleElement = createTitleElement(rowData, field);

            let recordTitle: JSX.Element[] = []
            recordTitle.push(titleElement)
            tableStructure.rowTitle.push(recordTitle)
            rowData.data.forEach((element: any) => {
                row.push(parseAndFormatData(Object.values(element), field));
            });

            tableStructure.rowData.push(row);
            tableStructure.rowWidth.push(100);
        }

        function createRowCollapsed(rowData: any) {
            let field: FieldsToBeShownObjectType = fieldsToBeShownWhenCollapsedObject.find((object) => object.key == rowData.key)!

            let row: JSX.Element[] = [];
            let titleElement = createTitleElement(rowData, field);

            let recordTitle: JSX.Element[] = []
            recordTitle.push(titleElement)
            tableStructure.rowTitleCollapsed.push(recordTitle)
            rowData.data.forEach((element: any) => {
                row.push(parseAndFormatData(Object.values(element), field));
            });

            tableStructure.rowDataCollapsed.push(row);
        }

        function createTitleElement(rowData: any, field: FieldsToBeShownObjectType) {
            let bold = {}
            if (field.bold) {
                bold = { fontWeight: "bold" }
            }
            let italic = {}
            if (field.italic) {
                italic = { fontStyle: "italic" }
            }
            let wideRow
            if (field.wideRow) {
                wideRow = "wideRow"
            }
            if (field.hasTooltip) {
                return (
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 0.7 }}>
                            <Text numberOfLines={4} adjustsFontSizeToFit key={wideRow} style={[rowStyles.record, bold, italic]}>{i18n.t("screens.balance_sheet." + rowData.key)}</Text>
                        </View>
                        <View style={{ justifyContent: "center", alignItems: "center", flex: 0.3 }}>
                            <Tooltip content={renderTooltip(rowData.key)} direction="right">
                                <View style={{ padding: 10 }}>
                                    <Icon name="info-circle" size={12} color={"white"} />
                                </View>
                            </Tooltip>
                        </View>
                    </View>
                )
            }
            return <Text numberOfLines={4} adjustsFontSizeToFit key={wideRow} style={[rowStyles.record, bold, italic]}>{i18n.t("screens.balance_sheet." + rowData.key)}</Text>;
        }

        function renderTooltip(key: string) {
            return (
                <View style={{ backgroundColor: "#494949", width: "50%", borderRadius: 5 }}>
                    <Text style={[styles.text, { fontSize: 13 }]}>
                        {i18n.t("screens.balance_sheet.tooltip." + key)}
                    </Text>
                </View>
            )
        }

        function createHeaderRow(type: string, hasTooltip?: boolean) {
            let key = ""
            switch (type) {
                case CE_PREFIX:
                    key = "contoEconomico"
                    break;
                case SP_PREFIX:
                    key = "statoPatrimoniale"
                    break;
                case RF_PREFIX:
                    key = "rendicontoFinanziario"
                    break;
                case RATIO_PREFIX:
                    key = "ratio"
                    break;
                case ADJUSTMENTS:
                    key = "adjustments";
                    break;
            }
            let row: JSX.Element[] = [];
            let titleElement;
            if (hasTooltip) {
                titleElement =
                    <View style={{ flexDirection: "row" }}>
                        {/* Don't change the order of the Text element and the View element, it will break the logic in the rendering */}
                        <Text adjustsFontSizeToFit style={[rowStyles.record, { fontWeight: "bold" }]}>{i18n.t("screens.balance_sheet." + key)}</Text>
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <Tooltip content={renderTooltip(key)} direction="right">
                                <View style={{ padding: 10, marginLeft: -5 }}>
                                    <Icon name="info-circle" size={12} color={"white"} />
                                </View>
                            </Tooltip>
                        </View>
                    </View>
                // titleElement = <Text style={[rowStyles.record, { fontWeight: "bold" }]}>{i18n.t("screens.balance_sheet." + key)}</Text>;
            } else {
                titleElement = <Text style={[rowStyles.record, { fontWeight: "bold" }]}>{i18n.t("screens.balance_sheet." + key)}</Text>;
            }
            let recordTitle: JSX.Element[] = []
            recordTitle.push(titleElement)
            if (numberOfbalances) {
                for (let i = 0; i < numberOfbalances; i++) {
                    // Empty element to draw a view with solid background
                    row.push(<Text key="header"></Text>)
                }
            }

            tableStructure.rowTitle.push(recordTitle)
            tableStructure.rowData.push(row);
            tableStructure.rowWidth.push(100);
        }

        function createBlankRow() {

            let row: JSX.Element[] = [];
            let titleElement = <Text key="blank"></Text>;

            let recordTitle: JSX.Element[] = []
            recordTitle.push(titleElement)
            tableStructure.rowTitle.push(recordTitle)
            if (numberOfbalances) {
                for (let i = 0; i < numberOfbalances; i++) {
                    row.push(<Text key="blank"></Text>)
                }
            }

            tableStructure.rowData.push(row);
            tableStructure.rowWidth.push(100);
        }
    }

    function addDownloadedBalancesToSharedContext(balanceList: BalanceXbrlDTO[]) {
        let downloadedBalances: DownloadedBalance[] = []
        balanceList.forEach((balance) => {
            let downloaded: DownloadedBalance = {
                anno: balance.anno!,
                type: balance.type!,
                currentMoreFieldsState: balance.currentMoreFieldsState,
                spHykeeCreditiCommerciali: balance.spHykeeCreditiCommerciali,
                spHykeeDebitiCommerciali: balance.spHykeeDebitiCommerciali,
                spHykeeDebitiFinanziari: balance.spHykeeDebitiFinanziari
            }
            downloadedBalances.push(downloaded)
        })
        sharedContext.setDownloadedBalances(downloadedBalances)
    }

    function determineMicroBalance(balanceList: BalanceXbrlDTO[]) {
        let balance = balanceList[balanceList.length - 1]
        if (balance.type == BalanceXbrlDTOTypeEnum.BilancioMicroimpresa
            || balance.type == BalanceXbrlDTOTypeEnum.BilancioAbbreviatoEsercizio) {
            microBalance = true
        } else {
            microBalance = false
        }
        /*
        let isMicro: boolean = false;
        if (balanceList.length >= 2) {
            let lastTwoElements = balanceList.slice(-2)
            lastTwoElements.forEach((balance) => {
                if (balance.type == BalanceXbrlDTOTypeEnum.BilancioMicroimpresa
                    || balance.type == BalanceXbrlDTOTypeEnum.BilancioAbbreviatoEsercizio) {
                    isMicro = true;
                }
            })
        } else {
            balanceList.forEach((balance) => {
                if (balance.type == BalanceXbrlDTOTypeEnum.BilancioMicroimpresa
                    || balance.type == BalanceXbrlDTOTypeEnum.BilancioAbbreviatoEsercizio) {
                    isMicro = true;
                }
            })
        }
        microBalance = isMicro
        */

    }

    function determineStateOfLastBalances(balanceList: BalanceXbrlDTO[]) {
        balanceList.forEach((balance: BalanceXbrlDTO) => {
            console.log(balance.currentMoreFieldsState, 'balance.currentMoreFieldsState')
            switch (balance.currentMoreFieldsState) {
                case BalanceXbrlDTOCurrentMoreFieldsStateEnum.Missing:
                    balancesState = BalanceXbrlDTOCurrentMoreFieldsStateEnum.Missing
                    break
                case BalanceXbrlDTOCurrentMoreFieldsStateEnum.Verified:
                    balancesState = BalanceXbrlDTOCurrentMoreFieldsStateEnum.Verified
                    break
                case BalanceXbrlDTOCurrentMoreFieldsStateEnum.Warning:
                    balancesState = BalanceXbrlDTOCurrentMoreFieldsStateEnum.Warning
                    break
                case BalanceXbrlDTOCurrentMoreFieldsStateEnum.Insecure:
                    balancesState = BalanceXbrlDTOCurrentMoreFieldsStateEnum.Insecure
                    break
                default:
                    balancesState = undefined
            }
        })
    }

    function setupReferences(list: BalanceXbrlDTO[]) {
        let balance = list[0]
        let obj: { [key: string]: any } = {}
        obj["maxReference"] = balance.maxReference
        obj["cashReference"] = balance.cashReference
        obj["positiveReference"] = balance.positiveReference
        obj["negativeReference"] = balance.negativeReference
        references = obj
    }

    async function getAndParseBalances() {
        if (props.balanceListId.length > 0) {
            const rowArrayTable: BalanceData[][] = [];
            await customScoreResourceApi.getFinancialDataByIdList({ requestBody: props.balanceListId }).then((list: BalanceXbrlDTO[]) => {
                numberOfbalances = list.length
                setupReferences(list)
                determineMicroBalance(list);
                addDownloadedBalancesToSharedContext(list);
                determineStateOfLastBalances(list);

                let dataArray: Balance[] = [];
                list.sort((a: BalanceXbrlDTO, b: BalanceXbrlDTO) => {
                    return (a.anno! > b.anno!) ? 1 : -1;
                })
                list.forEach((balanceData: BalanceXbrlDTO) => {


                    let dataRow: BalanceData[] = [];
                    dataArray.push({
                        year: balanceData.anno!,
                        data: balanceData
                    });

                    let keys = Object.keys(dataArray[0].data);

                    keys.forEach((key: string) => {
                        let row: BalanceData = { key: key as keyof BalanceXbrlDTO, data: [], state: balanceData.currentMoreFieldsState }
                        dataArray.map((responseInner) => {
                            let obj: RowObject = {
                                [responseInner.year]: responseInner.data[key as keyof BalanceXbrlDTO]
                            }
                            row.data.push(obj)
                        })
                        dataRow.push(row);
                    })
                    rowArrayTable.push(dataRow);
                })
                sortArray(rowArrayTable);
                if (microBalance) {
                    if (balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Verified ||
                        balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Warning ||
                        balancesState == BalanceXbrlDTOCurrentMoreFieldsStateEnum.Insecure) {
                        sortArray(rowArrayTable)
                    } else {
                        sortMicroArray(rowArrayTable)
                    }
                }
            }).then(() => {
                setupTableDefinition(rowArrayTable);
            })
        }
    }

    return (
        <View>
            {tableDefinition.loading &&
                <View>
                    <Text style={{ textAlign: 'center', color: "white", margin: 10, fontFamily: 'poppins' }}>
                        {i18n.t("screens.balance_sheet.loading")}
                    </Text>
                </View>}
            {!tableDefinition.loading &&
                <View>
                    <View style={[styles.header, { height: 30, justifyContent: 'center', alignItems: 'flex-start' }]}>
                        <Text style={{ color: 'white', fontSize: 13, fontFamily: 'poppins', paddingLeft: 10 }}>
                            {i18n.t("screens.balance_sheet.header_values_euros")}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Table>
                            <Row data={[tableDefinition.columnTitle[0]]} height={70} widthArr={[120]} style={StyleProp<ViewStyle>('header')}
                                textStyle={{ fontFamily: 'poppins' }} />
                        </Table>
                        <ScrollView
                            scrollEnabled={false}
                            ref={topRef}
                            showsHorizontalScrollIndicator={false}
                            horizontal={true}
                        >


                            <Table>
                                <Row data={tableDefinition.columnTitle.slice(1)} height={70} widthArr={tableDefinition.colWidth.slice(1)} style={StyleProp<ViewStyle>('header')}
                                    textStyle={{ fontFamily: 'poppins' }} />
                            </Table>

                        </ScrollView>

                    </View>


                    <ScrollView ref={() => {
                        // Timeout necessary: https://stackoverflow.com/a/46370418
                        setTimeout(() => {
                            bottomRef.current?.scrollToEnd()
                        }, 50)
                        return bottomRef
                    }} nestedScrollEnabled contentContainerStyle={{ flexDirection: "row" }} style={{ height: balanceHeight }}>
                        <View>
                            <Table>
                                <>
                                    {seeMore && tableDefinition.rowTitle.map((rowData, index) => {
                                        console.log(rowData[0].props.children, "children")
                                        if ((rowData[0].props.children == i18n.t("screens.balance_sheet.contoEconomico") ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.statoPatrimoniale")) ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.rendicontoFinanziario")) ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.ratio")) ||
                                            (Array.isArray(rowData[0].props.children) ? rowData[0].props.children[0].props.children == i18n.t("screens.balance_sheet.adjustments") : rowData[0].props.children == i18n.t("screens.balance_sheet.adjustments")))) {

                                            return drawSectionRow(index, rowData, [120])
                                        }
                                        if (Array.isArray(rowData[0].props.children) ? (Array.isArray(rowData[0].props.children) ? rowData[0].props.children[0].props.children.key == "wideRow" : rowData[0].props.children[0].key == "wideRow" ) : rowData[0].key == "wideRow") {
                                            return drawWideRow(index, rowData, [120])
                                        }
                                        if (rowData[0].key == "blank") {
                                            return drawBlankRow(index, rowData, [120])
                                        }

                                        return drawNormalRow(index, rowData, [120])
                                    })}
                                    {!seeMore && tableDefinition.rowTitleCollapsed.map((rowData, index) => {

                                        if ((rowData[0].props.children == i18n.t("screens.balance_sheet.contoEconomico") ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.statoPatrimoniale")) ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.rendicontoFinanziario")) ||
                                            (rowData[0].props.children == i18n.t("screens.balance_sheet.ratio")) ||
                                            (Array.isArray(rowData[0].props.children) ? rowData[0].props.children[0].props.children == i18n.t("screens.balance_sheet.adjustments") : rowData[0].props.children == i18n.t("screens.balance_sheet.adjustments")))) {

                                            return drawSectionRow(index, rowData, [120])
                                        }
                                        if (Array.isArray(rowData[0].props.children) ? (Array.isArray(rowData[0].props.children) ? rowData[0].props.children[0].props.children.key == "wideRow" : rowData[0].props.children[0].key == "wideRow" ) : rowData[0].key == "wideRow") {
                                            return drawWideRow(index, rowData, [120])
                                        }
                                        if (rowData[0].key == "blank") {
                                            return drawBlankRow(index, rowData, [120])
                                        }

                                        return drawNormalRow(index, rowData, [120])
                                    })}
                                </>

                            </Table>
                        </View>
                        <ScrollView
                            horizontal
                            ref={bottomRef}
                            scrollEventThrottle={16}
                            showsHorizontalScrollIndicator={false}
                            onScroll={
                                (e) => {
                                    const { x } = e.nativeEvent.contentOffset;
                                    topRef.current?.scrollTo({ x, animated: false });
                                }
                            }>

                            <Table>
                                <>
                                    {seeMore && tableDefinition.rowData.map((rowData, index) => {
                                        if (rowData[0].key == "wideRow") {
                                            return drawWideRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (rowData[0].key == "blank") {
                                            return drawBlankRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (rowData[0].key == "header") {
                                            return drawSectionRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (!rowData[0].props.children) {
                                            return drawSectionRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        return drawNormalRow(index, rowData, tableDefinition.colWidth.slice(1))
                                    })
                                    }
                                    {!seeMore && tableDefinition.rowDataCollapsed.map((rowData, index) => {
                                        if (rowData[0].key == "wideRow") {
                                            return drawWideRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (rowData[0].key == "blank") {
                                            return drawBlankRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (rowData[0].key == "header") {
                                            return drawSectionRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        if (!rowData[0].props.children) {
                                            return drawSectionRow(index, rowData, tableDefinition.colWidth.slice(1))
                                        }
                                        return drawNormalRow(index, rowData, tableDefinition.colWidth.slice(1))
                                    })
                                    }
                                </>

                            </Table>


                        </ScrollView>



                    </ScrollView>
                    {seeMore &&
                        <TouchableOpacity
                            style={styles.buttonSeeMore}
                            onPress={() => { setSeeMore(!seeMore) }}
                        >
                            <Text style={styles.textSeeMore}>
                                {seeMore ? i18n.t('buttons.see_less') : i18n.t('buttons.see_more')}
                            </Text>
                        </TouchableOpacity>
                    }

                    {!seeMore &&
                        <TouchableOpacity
                            style={styles.buttonSeeMore}
                            onPress={() => { setSeeMore(!seeMore) }}
                        >
                            <Text style={styles.textSeeMore}>
                                {seeMore ? i18n.t('buttons.see_less') : i18n.t('buttons.see_more')}
                            </Text>
                        </TouchableOpacity>
                    }
                </View>
            }
        </View>
    )
}

function drawSectionRow(index: number, rowData: any, widthArr: number[]) {
    return (
        <Row
            key={index}
            data={rowData}
            widthArr={widthArr}
            height={70}
            style={[{ backgroundColor: "#494949", borderTopWidth: 2, borderTopColor: "#292929" }]}
            textStyle={{ fontFamily: 'poppins' }}
        />
    )
}

function drawWideRow(index: number, rowData: any, widthArr: number[]) {
    return (
        <Row
            key={index}
            data={rowData}
            widthArr={widthArr}
            height={100}
            style={[{ borderRightWidth: 1, borderRightColor: "#494949" }, index % 2 == 0 ? StyleProp<ViewStyle>('row1') : StyleProp<ViewStyle>('row2')]}
            textStyle={{ fontFamily: 'poppins' }}
        />
    )
}

function drawBlankRow(index: number, rowData: any, widthArr: number[]) {
    return (
        <Row
            key={index}
            data={rowData}
            widthArr={widthArr}
            height={40}
            style={[{ borderRightWidth: 1, borderRightColor: "#494949" }, index % 2 == 0 ? StyleProp<ViewStyle>('row1') : StyleProp<ViewStyle>('row2')]}
            textStyle={{ fontFamily: 'poppins' }}
        />
    )
}

function drawNormalRow(index: number, rowData: any, widthArr: number[]) {
    return (
        <Row
            key={index}
            data={rowData}
            widthArr={widthArr}
            height={60}
            style={[{ borderRightWidth: 1, borderRightColor: "#494949" }, index % 2 == 0 ? StyleProp<ViewStyle>('row1') : StyleProp<ViewStyle>('row2')]}
            textStyle={{ fontFamily: 'poppins' }}
        />
    )
}

const styleRowHeader: ViewStyle = {
    height: 70, backgroundColor: '#494949',
}
const styleRowData1: ViewStyle = {
    backgroundColor: '#292929',
}
const styleRowData2: ViewStyle = {
    backgroundColor: '#343434',
}

const styles = StyleSheet.create({
    buttonSeeMore: { alignSelf: 'center' },
    textSeeMore: { color: '#00CC83', padding: 15, fontFamily: 'poppins', fontSize: 16, textDecorationLine: 'underline' },
    container: { flex: 1, backgroundColor: 'rgb(42, 42, 42)' },
    head: { height: 40, backgroundColor: 'rgb(42, 42, 42)' },
    wrapper: { flexDirection: 'row' },
    title: { flex: 1, height: 40, backgroundColor: 'rgb(42, 42, 42)' },
    row: { height: 40 },
    textHead: { textAlign: 'center', color: 'green', fontWeight: 'bold' },
    textNegative: { fontFamily: "poppins", margin: 5, fontSize: 12, fontWeight: '300', textAlign: 'center', color: '#CA3838' },
    textFirstCol: { fontFamily: "poppins", textAlign: 'center', color: 'white', fontWeight: 'bold' },
    containerTable: { flex: 0.95 },
    header: { height: 70, backgroundColor: '#494949' },
    text: { fontFamily: "poppins", margin: 5, fontSize: 12, textAlign: 'center', fontWeight: '300', color: "white" },
    dataWrapper: { marginTop: -1 },
});

const rowStyles = StyleSheet.create({
    titleHeader: { margin: 10, color: "white", fontWeight: "bold", fontSize: 13, textAlign: "left" },
    yearHeader: { margin: 10, textAlign: 'center', color: "white", fontWeight: "bold", fontSize: 13 },
    record: { margin: 10, fontSize: 12, fontFamily: 'poppins', color: "white", textAlign: "left" },
})


function StyleProp<T>(value: string): import("react-native").StyleProp<import("react-native").ViewStyle> {
    let res;
    switch (value) {
        case 'header':
            res = styleRowHeader
            break;
        case 'row1':
            res = styleRowData1
            break;
        case 'row2':
            res = styleRowData2
            break;
        case 'row':
            res
            break;
    }
    return res

}
