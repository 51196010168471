import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Platform, ScrollView } from 'react-native';
import { DownloadedBalance, RootStackScreenProps } from '../../../types';
import Colors from "../../../constants/Colors";
import { StatusBar } from "expo-status-bar";
import { i18n } from "@i18n/i18n";
import { LinearGradient } from "expo-linear-gradient";
import SharedContext from "../../navigation/SharedContext";
import hykeeStyle from "@components/hykeeStyle";
import { NavigationHelper } from "@helpers/NavigationHelper";
import Icon from "@screens/Tools/Icon";
import { ColorTextLightGreen } from "@components/ColorTheme";
import { BalanceXbrlDTO, Company, CompanyBalanceSheet } from '@services/apis/generated';
import { customCompanyBalanceSheetResourceApi, customScoreResourceApi } from '@services/apis/ApiConfiguration';
import { ErrorHelper } from '@helpers/ErrorHelper';
import AppContext from '../../navigation/AppContext';
import ModalSimpleSuccess from '@screens/Modal/ModalSimpleSuccess';

export default function CompanyMicroScreen({ navigation, route }: RootStackScreenProps<'CompanyMicro'>) {
    const sharedContext = React.useContext(SharedContext);
    const appContext = useContext(AppContext);
    const [selectedYear, setSelectedYear] = React.useState<FinancialStructureByYear[]>([]);
    const [downloadedBalances, setDownloadedBalances] = React.useState<DownloadedBalance[]>();
    const [yearIndex, setYearIndex] = React.useState<number>();
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    type FinancialStructureByYear = {
        year: number,
        commercialCredit?: number | undefined,
        commercialDebit?: number | undefined,
        financialDebit?: number | undefined
        selected: boolean,
    }

    /**
     * Fields used as keys
     */
    enum Fields {
        commercialCredit = "commercialCredit",
        commercialDebit = "commercialDebit",
        financialDebit = "financialDebit"
    }

    useEffect(() => {
        getBalanceData()
    }, [route.params?.id]);

    useEffect(() => {
        setYearIndex(0);
        setLastFinancialYears();
    }, [downloadedBalances]);

    function getBalanceData() {
        if (route.params) {
            customCompanyBalanceSheetResourceApi.getCompanyBalanceSheets({ companyId: route.params.id }).then((balances: CompanyBalanceSheet[]) => {
                let list: number[] = []
                balances.forEach((balance: CompanyBalanceSheet) => {
                    list.push(balance.id!)
                })
                customScoreResourceApi.getFinancialDataByIdList({ requestBody: list! }).then((list: BalanceXbrlDTO[]) => {
                    let downloadedBalances: DownloadedBalance[] = []
                    list.forEach((balance) => {
                        let downloaded: DownloadedBalance = {
                            anno: balance.anno!,
                            type: balance.type!,
                            currentMoreFieldsState: balance.currentMoreFieldsState,
                            spHykeeCreditiCommerciali: balance.spHykeeCreditiCommerciali,
                            spHykeeDebitiCommerciali: balance.spHykeeDebitiCommerciali,
                            spHykeeDebitiFinanziari: balance.spHykeeDebitiFinanziari
                        }
                        downloadedBalances.push(downloaded)
                    })
                    console.log(downloadedBalances, "downloadedBalances")
                    setDownloadedBalances(downloadedBalances)
                    setLoading(false);
                }).catch((error) => console.log(error))
            }).catch((error) => console.log(error))
        }
    }

    function setLastFinancialYears() {
        let financialList: FinancialStructureByYear[] = [];
        if (downloadedBalances && downloadedBalances.length > 0) {
            for (let balance of downloadedBalances) {
                let financialObj: FinancialStructureByYear = {
                    year: balance.anno,
                    commercialCredit: balance.spHykeeCreditiCommerciali,
                    commercialDebit: balance.spHykeeDebitiCommerciali,
                    financialDebit: balance.spHykeeDebitiFinanziari,
                    selected: false
                };
                financialList.push(financialObj);
            }
            financialList[0].selected = true;
            setSelectedYear(financialList);
        }
    }

    function changeSelectedYear(year: number) {
        selectedYear.forEach((data, index) => {
            if (data.year == year) {
                data.selected = true;
                setYearIndex(index);
            } else {
                data.selected = false;
            }
        })


        setSelectedYear([...selectedYear]);
    }

    function updateCompanyBalanceSheet() {
        selectedYear.forEach((balance, index) => {
            customCompanyBalanceSheetResourceApi.getCompanyBalanceSheetByCompanyAndYear({ companyYearDTO: { year: balance.year, company: sharedContext.selectedCompany! } })
                .then((companyBalanceSheet) => {
                    if (companyBalanceSheet) {
                        let newCompanyBalanceSheet: CompanyBalanceSheet = companyBalanceSheet;
                        newCompanyBalanceSheet.spHykeeCreditiCommerciali = balance.commercialCredit ? Math.abs(balance.commercialCredit) : 0
                        newCompanyBalanceSheet.spHykeeDebitiCommerciali = balance.commercialDebit ? Math.abs(balance.commercialDebit) : 0
                        newCompanyBalanceSheet.spHykeeDebitiFinanziari = balance.financialDebit ? Math.abs(balance.financialDebit) : 0
                        customCompanyBalanceSheetResourceApi.updateCompanyBalanceSheetMoreFields({ id: companyBalanceSheet.id!, companyBalanceSheet: newCompanyBalanceSheet })
                            .then((res) => {
                                setModalSuccess(true);
                            }).catch(async (error) => {
                                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
                            })
                    }
                }).catch(async (error) => {
                    let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                    let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                    appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
                })
        })
    }

    /**
     * The function is used to update the Form
     * @param key 
     * @param value 
     */
    const handleChangeText = (key: Fields, value: any): void => {
        if (isNaN(value)) value = null
        switch (key) {
            case Fields.commercialCredit:
                selectedYear[yearIndex!].commercialCredit = value ? Number.parseInt(value) : undefined
                break;
            case Fields.commercialDebit:
                selectedYear[yearIndex!].commercialDebit = value ? Number.parseInt(value) : undefined
                break;
            case Fields.financialDebit:
                selectedYear[yearIndex!].financialDebit = value ? Number.parseInt(value) : undefined
                break;
        }
        setSelectedYear([...selectedYear]);
    }


    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />

            {/* go back button */}
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t("screens.CompanyMicro.title")}</Text>
            </View>

            {loading &&
                <View style={styles.loadingView}>
                    <Text style={styles.loadingText}>
                    {i18n.t("screens.CompanyMicro.loading")}
                    </Text>
                </View>
            }

            {!loading &&
                <ScrollView style={styles.container}>

                    {/* Year button */}
                    <ScrollView horizontal={true}>
                        {selectedYear.length > 0 && selectedYear.map((data, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    style={data.selected ? styles.yearButtonClicked : styles.yearButton}
                                    activeOpacity={0.5}
                                    onPress={() => { changeSelectedYear(data.year) }}
                                >
                                    <Text style={styles.text}>{data.year}</Text>
                                </TouchableOpacity>
                            )
                        })
                        }
                    </ScrollView>

                    {/* Form */}
                    <View>

                        {/* Form title*/}
                        <Text style={hykeeStyle.valueText}>{i18n.t("screens.CompanyMicro.euroText")}</Text>

                        <Text style={hykeeStyle.valueText}>{i18n.t("screens.CompanyMicro.commercialCreditText") + ": "}</Text>

                        {/* Commercial credit */}
                        {selectedYear.length > 0 &&
                            <TextInput
                                placeholder={i18n.t("screens.CompanyMicro.placeholderText")}
                                placeholderTextColor='#737373'
                                onChangeText={(text) => handleChangeText(Fields.commercialCredit, parseInt(text))}
                                style={hykeeStyle.textInput}
                                keyboardType='numeric'
                                value={selectedYear[yearIndex!].commercialCredit ? Math.abs(selectedYear[yearIndex!].commercialCredit!).toString() : ''}
                            />
                        }
                        <Text style={hykeeStyle.valueText}>{i18n.t("screens.CompanyMicro.commercialDebitText") + ": "}</Text>

                        {/* Commercial debts */}
                        {selectedYear.length > 0 &&
                            <TextInput
                                placeholder={i18n.t("screens.CompanyMicro.placeholderText")}
                                placeholderTextColor='#737373'
                                onChangeText={(text) => handleChangeText(Fields.commercialDebit, parseInt(text))}
                                style={hykeeStyle.textInput}
                                keyboardType='numeric'
                                value={selectedYear[yearIndex!].commercialDebit ? Math.abs(selectedYear[yearIndex!].commercialDebit!).toString() : ''}
                            />
                        }

                        <Text style={hykeeStyle.valueText}>{i18n.t("screens.CompanyMicro.financialDebitText") + ": "}</Text>

                        {/* Financial debts */}
                        {selectedYear.length > 0 &&
                            <TextInput
                                placeholder={i18n.t("screens.CompanyMicro.placeholderText")}
                                placeholderTextColor='#737373'
                                onChangeText={(text) => handleChangeText(Fields.financialDebit, parseInt(text))}
                                style={hykeeStyle.textInput}
                                keyboardType='numeric'
                                value={selectedYear[yearIndex!].financialDebit ? Math.abs(selectedYear[yearIndex!].financialDebit!).toString() : ''}
                            />
                        }

                        {/* Save all and confirm data sending */}
                        <TouchableOpacity
                            style={styles.button}
                            activeOpacity={0.5}
                            onPress={() => { updateCompanyBalanceSheet() }}
                        >
                            <Text style={styles.text}>{i18n.t("screens.CompanyMicro.save_and_confirm")}</Text>
                        </TouchableOpacity>

                    </View>

                </ScrollView>
            }

            {/* Modal */}
            {modalSuccess &&
                <ModalSimpleSuccess
                    msg={i18n.t("screens.CompanyMicro.success")}
                    visible={(visible) => setModalSuccess(visible)} />
            }
        </LinearGradient>
    );
}


const styles = StyleSheet.create({
    contentLabel: {
        flex: Platform.OS == 'ios' ? 0.3 : 0.25,
        marginBottom: 10
    },
    text: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 13,
        color: 'white',
        fontFamily: 'poppins',
    },
    button: {
        flex: 0.3,
        backgroundColor: '#00CC83',
        padding: 15,
        marginVertical: 25,
        justifyContent: 'center',
        borderRadius: 5,
        alignItems: 'center'
    },
    contentTitle: {
        height: '10%',
        flexDirection: 'row',
    },
    yearButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 20,
    },
    containerInput: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 20,
    },
    container: {
        flex: 1,
        // marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    title: {
        alignSelf: 'flex-start',
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        marginVertical: 30,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        justifyContent: 'flex-start'
    },
    yearButton: {
        minWidth: 100,
        backgroundColor: "#A5A5A5",
        padding: 15,
        marginVertical: 10,
        justifyContent: 'center',
        borderRadius: 5,
        alignItems: 'center',
        marginRight: 30,
        marginTop: 30,
        marginBottom: 10
    },
    yearButtonClicked: {
        minWidth: 100,
        backgroundColor: '#00CC83',
        padding: 15,
        marginVertical: 10,
        justifyContent: 'center',
        borderRadius: 5,
        alignItems: 'center',
        marginRight: 30,
        marginTop: 30
    },
    loadingView: {
        alignContent: "center",
        padding: 30
    },
    loadingText: {
        textAlign: "center",
        fontFamily: "poppins",
        fontSize: 20,
        color: "white",
    }
});