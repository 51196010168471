import React, {useCallback, useContext, useEffect, useState} from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import Icon from "../../../Tools/Icon";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { RootStackParamList } from "../../../../../types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { CompanyDataBlockTypeEnum, CustomCompanyDataBlockResourceApi } from "@services/apis/generated";
import { i18n } from "../../../../i18n/i18n";
import ModalSimpleConfirmCancel from "@screens/Modal/ModalSimpleConfirmCancel";
import SharedContext from "../../../../navigation/SharedContext";
import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { getActiveChildNavigationOptions } from "react-navigation";
import { useFocusEffect } from "@react-navigation/native";
import { customDataBlockUserResourceApi } from "@services/apis/ApiConfiguration";
import {FontAwesome, Fontisto} from '@expo/vector-icons';


export default function ChamberCollapsible(props: { navigation: NativeStackNavigationProp<RootStackParamList, "DashboardCompany" | "DashboardSavedCompany" | "DashboardCompanySearched">, companyDataId?: number }) {
    const sharedContext = useContext(SharedContext)

    enum ChamberFields {
        administrators = "administrators",
        shares = "shares",
        partners = "partners"
    };

    const [modalVisible, setModalVisible] = React.useState<{ [key in ChamberFields]: boolean }>({
        administrators: false,
        shares: false,
        partners: false,
    })

    var v: {} | null | undefined;
    useFocusEffect(useCallback(()=>{
        customDataBlockUserResourceApi.getAllDataBlockUsersByLoggedUser().then((myDataBlocks)=>{
            sharedContext.setMyDataBlock(myDataBlocks);
        })
    }, []))

    const ChamberTab = [
        {
            role: ChamberFields.administrators,
            credits: 'administrators_credits',
            navigationFunction: NavigationHelper.navigateToAdministratorScreen,
        },
        {
            role: ChamberFields.shares,
            credits: 'shares_credits',
            navigationFunction: NavigationHelper.navigateToSharesScreen,
        },
        {
            role: ChamberFields.partners,
            credits: 'partners_credits',
            navigationFunction: NavigationHelper.navigateToPartnerScreen,
        },
    ]



    return (
        <>
            {ChamberTab.map((chamber, index: number) => {
                index++
                return (
                    <View key={index.toString()} style={[styles.row, { backgroundColor: index % 2 == 0 ? '#343434' : '#292929' }]}>
                        <View style={styles.contentRole}>
                            <Text style={styles.textRole}>
                                {i18n.t('components.chamber_data.' + chamber.role)}
                            </Text>
                        </View>
                        <View style={styles.contentCredits}>
                            <Text style={styles.textCredits}>
                                {i18n.t('components.chamber_data.' + chamber.credits)}
                            </Text>
                        </View>
                        <View style={styles.contentIcon}>
                            {sharedContext.myDataBlock?.find((dataBlock) => dataBlock.companyDataBlock?.companyData?.id == sharedContext.selectedCompanyLastData?.id 
                            && dataBlock.companyDataBlock?.companyDataBlock?.type?.toLowerCase()== chamber.role) != undefined && // controlla se ho già scaricato questo file
                                <TouchableOpacity
                                    style={styles.buttonPreview}
                                    onPress={() => chamber.navigationFunction(props.navigation, props.companyDataId!)}>
                                    <Text numberOfLines={1} style={{color: "white", marginBottom: 6,  fontSize: 12}}> {sharedContext.myDataBlock?.find((dataBlock) => dataBlock.companyDataBlock?.companyData?.id == sharedContext.selectedCompanyLastData?.id)?.dataBlockUser?.date?.toLocaleDateString()} </Text>
                                    <Fontisto name="preview" size={16} color={'#00CC83'} style={{alignSelf:'center'}} />
                                </TouchableOpacity>
                                }
                            {sharedContext.myDataBlock?.find((dataBlock) => dataBlock.companyDataBlock?.companyData?.id == sharedContext.selectedCompanyLastData?.id 
                            && dataBlock.companyDataBlock?.companyDataBlock?.type?.toLowerCase()== chamber.role) == undefined &&
                                <TouchableOpacity style={styles.buttonDownlaod} onPress={() => { modalVisible[chamber.role] = !modalVisible[chamber.role], setModalVisible(Object.assign({}, modalVisible)) }}>
                                    <Icon name={'download'} color={'#00CC83'} />
                                </TouchableOpacity>}

                        </View>
                        {modalVisible[chamber.role] &&
                            <ModalSimpleConfirmCancel
                                msg={i18n.t('components.chamber_data.confirm_add', { credits: i18n.t('components.chamber_data.' + chamber.credits) })}
                                action={() => { chamber.navigationFunction(props.navigation, props.companyDataId!) }}
                                visible={(visible) => { modalVisible[chamber.role] = visible, setModalVisible(Object.assign({}, modalVisible)) }}
                            />
                        }
                    </View>
                )
            })}
        </>
    )


}
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        paddingVertical: 8,
        justifyContent:'space-between',
        alignItems:'center',
        paddingHorizontal: 15,
    },
    buttonShow:{
        paddingVertical:5,
        paddingHorizontal:10,
        backgroundColor:BackgroundColorButtonGreen(),
        borderRadius:5,
        alignSelf:'center'
    },
    buttonDownlaod:{
        padding: 10,
        alignSelf:'center'
    },
    textShow:{
        
        color: 'white',
        fontFamily: 'poppins'
    },
    contentRole: {
        flex:0.4
    },
    textRole: {
        color: 'white',
        fontFamily: 'poppins'
    },
    textCredits: {
        color: 'white',
        fontFamily: 'poppins'
    },
    contentCredits: {
        flex:0.3,
    },
    contentIcon: {
        flex:0.3,
    },
    buttonPreview:{
        flexDirection: 'column',
        padding: 10,
        alignSelf:'center'
    },
});