/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Protocolli,
    ProtocolliFromJSON,
    ProtocolliFromJSONTyped,
    ProtocolliToJSON,
    TrascrizioniProtCompleti,
    TrascrizioniProtCompletiFromJSON,
    TrascrizioniProtCompletiFromJSONTyped,
    TrascrizioniProtCompletiToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolliCompleti
 */
export interface ProtocolliCompleti {
    /**
     * 
     * @type {Protocolli}
     * @memberof ProtocolliCompleti
     */
    protocolli?: Protocolli;
    /**
     * 
     * @type {TrascrizioniProtCompleti}
     * @memberof ProtocolliCompleti
     */
    trascrizioniProtCompleti?: TrascrizioniProtCompleti;
    /**
     * 
     * @type {string}
     * @memberof ProtocolliCompleti
     */
    dtEstrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolliCompleti
     */
    dtLunediPrecedente?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolliCompleti
     */
    dtDomenicaPrecedente?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolliCompleti
     */
    fpresenzaInfo?: string;
}

export function ProtocolliCompletiFromJSON(json: any): ProtocolliCompleti {
    return ProtocolliCompletiFromJSONTyped(json, false);
}

export function ProtocolliCompletiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolliCompleti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolli': !exists(json, 'protocolli') ? undefined : ProtocolliFromJSON(json['protocolli']),
        'trascrizioniProtCompleti': !exists(json, 'trascrizioniProtCompleti') ? undefined : TrascrizioniProtCompletiFromJSON(json['trascrizioniProtCompleti']),
        'dtEstrazione': !exists(json, 'dtEstrazione') ? undefined : json['dtEstrazione'],
        'dtLunediPrecedente': !exists(json, 'dtLunediPrecedente') ? undefined : json['dtLunediPrecedente'],
        'dtDomenicaPrecedente': !exists(json, 'dtDomenicaPrecedente') ? undefined : json['dtDomenicaPrecedente'],
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function ProtocolliCompletiToJSON(value?: ProtocolliCompleti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolli': ProtocolliToJSON(value.protocolli),
        'trascrizioniProtCompleti': TrascrizioniProtCompletiToJSON(value.trascrizioniProtCompleti),
        'dtEstrazione': value.dtEstrazione,
        'dtLunediPrecedente': value.dtLunediPrecedente,
        'dtDomenicaPrecedente': value.dtDomenicaPrecedente,
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


