import { FieldsToBeShownObjectType } from "../../../../../types";

export const fieldsToBeShownWhenCollapsedObject: FieldsToBeShownObjectType[] = [
    { key: "ceHykeeRicavi", hasEuroSign: true },
    { key: "ceHykeeValoreDellaProduzione", hasEuroSign: true },
    { key: "ceHykeeMateriePrime", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeeServizi", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeePersonale", hasEuroSign: true },
    { key: "ceHykeeOneriDiversiDiGestione", hasEuroSign: true },
    { key: "ceHykeeEBITDA", hasEuroSign: true, bold: true },
]

// Attention: the order of the elements of the following object is important for the sorting function
export const fieldsToBeShownObject: FieldsToBeShownObjectType[] = [
    { key: "ceHykeeRicavi", hasEuroSign: true },
    { key: "ceHykeeValoreDellaProduzione", hasEuroSign: true },
    { key: "ceHykeeMateriePrime", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeeServizi", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeePersonale", hasEuroSign: true },
    { key: "ceHykeeOneriDiversiDiGestione", hasEuroSign: true },
    { key: "ceHykeeEBITDA", hasEuroSign: true, bold: true },
    { key: "ceHykeeEBITDAPERCENTAGE", isPercentage: true, italic: true },
    { key: "ceHykeeDA", hasEuroSign: true },
    { key: "ceHykeeEBIT", hasEuroSign: true, bold: true },
    { key: "ceHykeeOneriEProventiFinanziari", hasEuroSign: true },
    { key: "ceHykeeRettificheFinanziarie", hasEuroSign: true },
    { key: "ceHykeeEBT", hasEuroSign: true, bold: true },
    { key: "ceHykeeImposte", hasEuroSign: true },
    { key: "ceHykeeRisultatoEsercizio", hasEuroSign: true, bold: true },
    { key: "ceAdjHykeeEBITDA", hasEuroSign: true },
    { key: "ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione", hasEuroSign: true, wideRow: true },
    { key: "ceAdjHykeeEBITDAAdj", hasEuroSign: true, bold: true },
    { key: "ceAdjHykeeDA", hasEuroSign: true },
    { key: "ceAdjHykeeEBITAdj", hasEuroSign: true, bold: true },
    { key: "spHykeeImmobilizzazioniImmateriali", hasEuroSign: true },
    { key: "spHykeeImmobilizzazioniMateriali", hasEuroSign: true },
    { key: "spHykeeImmobilizzazioniFinanziarie", hasEuroSign: true },
    { key: "spHykeeAttivoFisso", hasEuroSign: true, bold: true },
    { key: "spHykeeCreditiCommerciali", hasEuroSign: true },
    { key: "spHykeeDebitiCommerciali", hasEuroSign: true },
    { key: "spHykeeRimanenze", hasEuroSign: true },
    { key: "spHykeeCCON", hasEuroSign: true, bold: true },
    { key: "spHykeeAltriCrediti", hasEuroSign: true, hasTooltip: true },
    { key: "spHykeeAltriDebiti", hasEuroSign: true, hasTooltip: true },
    { key: "spHykeeFondiETFR", hasEuroSign: true, wideRow: true },
    { key: "spHykeeCIN", hasEuroSign: true, bold: true },
    { key: "spHykeePatrimonioNetto", hasEuroSign: true, bold: true },
    { key: "spHykeeCapitaleSociale", hasEuroSign: true, italic: true },
    { key: "spHykeeDebitiFinanziari", hasEuroSign: true, wideRow: true, hasTooltip: true },
    { key: "spHykeeLiquidita", hasEuroSign: true },
    { key: "spHykeeAttivitaFinanziarie", hasEuroSign: true, wideRow: true },
    { key: "spHykeePFN", hasEuroSign: true, bold: true },
    { key: "spHykeeEplusPFN", hasEuroSign: true, bold: true },
    { key: "rfHykeeEBIT", hasEuroSign: true, bold: true },
    { key: "rfHykeeAmmortamentiESvalutazioni", hasEuroSign: true, wideRow: true },
    { key: "rfHykeeRettificheAttivitaFinanziarie", hasEuroSign: true, wideRow: true },
    { key: "rfHykeeImposte", hasEuroSign: true },
    { key: "rfHykeeFundsFromOperationFFO", hasEuroSign: true, bold: true },
    { key: "rfHykeeVarCCON", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeVarAltriCrediti", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeVarAltriDebiti", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeVarFondiETFR", hasEuroSign: true, wideRow: true, hasTooltip: true },
    { key: "rfHykeeCashFromOperationCFO", hasEuroSign: true, bold: true },
    { key: "rfHykeeCapex", hasEuroSign: true, wideRow: true },
    { key: "rfHykeeInvestimentiOnDisinvestimentiFinanziari", hasEuroSign: true, wideRow: true },
    { key: "rfHykeeFCFO", hasEuroSign: true, bold: true, wideRow: true },
    { key: "rfHykeeProventiEOneriFinanziari", hasEuroSign: true, wideRow: true },
    { key: "rfHykeeFreeCashFlowToEquityFCFE", hasEuroSign: true, bold: true, wideRow: true },
    { key: "rfHykeeVarPatrimonioNetto", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeFCFEAdjustedDeltaPFN", hasEuroSign: true, bold: true },
    { key: "rfHykeeVarDebitiFinanziari", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeVarAttivitaFinCorrenti", hasEuroSign: true, hasTooltip: true },
    { key: "rfHykeeFreeCashFlowDeltaDisponibilitaLiquide", hasEuroSign: true, bold: true, wideRow: true },
    { key: "ratioHykee2YoYRicavi", isPercentage: true, italic: true },
    { key: "ratioHykee2YoYVP", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitdaPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitdaAdjPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitAdjPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeUtileOnPerditaPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeCapexOnRicavi", italic: true, isPercentage: true },
    { key: "ratioHykeeROCE", italic: true, isPercentage: true, hasTooltip: true },
    { key: "ratioHykeePFNOnEBITDA", hasXIndicator: true, hasDecimal: true },
    { key: "ratioHykeePFNOnEBITDAAdj", hasXIndicator: true, hasDecimal: true },
    { key: "ratioHykeeGrossDebtOnPN", hasXIndicator: true, wideRow: true, hasDecimal: true },
    { key: "ratioHykeeCCONOnRICAVIPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeDSO", hasDays: true },
    { key: "ratioHykeeDPOWithoutCapex", hasDays: true },
    { key: "ratioHykeeDSI", hasDays: true },
    { key: "ratioHykeeFCCR", hasXIndicator: true, hasDecimal: true },
]

export const fieldsToBeShownMicroObject: FieldsToBeShownObjectType[] = [
    { key: "ceHykeeRicavi", hasEuroSign: true },
    { key: "ceHykeeValoreDellaProduzione", hasEuroSign: true },
    { key: "ceHykeeMateriePrime", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeeServizi", hasEuroSign: true, hasTooltip: true },
    { key: "ceHykeePersonale", hasEuroSign: true },
    { key: "ceHykeeOneriDiversiDiGestione", hasEuroSign: true },
    { key: "ceHykeeEBITDA", hasEuroSign: true, bold: true },
    { key: "ceHykeeEBITDAPERCENTAGE", isPercentage: true, italic: true },
    { key: "ceHykeeDA", hasEuroSign: true },
    { key: "ceHykeeEBIT", hasEuroSign: true, bold: true },
    { key: "ceHykeeOneriEProventiFinanziari", hasEuroSign: true },
    { key: "ceHykeeRettificheFinanziarie", hasEuroSign: true },
    { key: "ceHykeeEBT", hasEuroSign: true, bold: true },
    { key: "ceHykeeImposte", hasEuroSign: true },
    { key: "ceHykeeRisultatoEsercizio", hasEuroSign: true, bold: true },
    { key: "ceAdjHykeeEBITDA", hasEuroSign: true },
    { key: "ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione", hasEuroSign: true, wideRow: true },
    { key: "ceAdjHykeeEBITDAAdj", hasEuroSign: true, bold: true },
    { key: "ceAdjHykeeDA", hasEuroSign: true },
    { key: "ceAdjHykeeEBITAdj", hasEuroSign: true, bold: true },
    { key: "spHykeeImmobilizzazioniImmateriali", hasEuroSign: true },
    { key: "spHykeeImmobilizzazioniMateriali", hasEuroSign: true },
    { key: "spHykeeImmobilizzazioniFinanziarie", hasEuroSign: true },
    { key: "spHykeeAttivoFisso", hasEuroSign: true, bold: true },
    { key: "spHykeeRimanenze", hasEuroSign: true },
    { key: "creditiEsigibiliEntroEsercizioSuccessivo", hasEuroSign: true, wideRow: true },
    { key: "creditiEsigibiliOltreEsercizioSuccessivo", hasEuroSign: true, wideRow: true },
    { key: "totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni", hasEuroSign: true, wideRow: true },
    { key: "spHykeeLiquidita", hasEuroSign: true },
    { key: "totaleAttivo", hasEuroSign: true, bold: true },
    { key: "spHykeePatrimonioNetto", hasEuroSign: true, bold: true },
    { key: "spHykeeCapitaleSociale", hasEuroSign: true, italic: true },
    { key: "spHykeeFondiETFR", hasEuroSign: true, wideRow: true },
    { key: "debitiEsigibiliEntroEsercizioSuccessivoNegative", hasEuroSign: true },
    { key: "debitiEsigibiliOltreEsercizioSuccessivoNegative", hasEuroSign: true },
    { key: "totalePassivo", hasEuroSign: true, bold: true },
    { key: "ratioHykee2YoYRicavi", isPercentage: true, italic: true },
    { key: "ratioHykee2YoYVP", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitdaPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitdaAdjPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeEbitAdjPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeUtileOnPerditaPERCENTAGE", isPercentage: true, italic: true },
    { key: "ratioHykeeCapexOnRicavi", italic: true, isPercentage: true },
    { key: "ratioHykeePFNOnEBITDA", hasXIndicator: true },
    { key: "ratioHykeePFNOnEBITDAAdj", hasXIndicator: true },
    { key: "ratioHykeeDSI", hasDays: true },
]
