/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoriaOpere
 */
export interface CategoriaOpere {
    /**
     * 
     * @type {string}
     * @memberof CategoriaOpere
     */
    categoria?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriaOpere
     */
    classificazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriaOpere
     */
    cclassificazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriaOpere
     */
    ccategoria?: string;
}

export function CategoriaOpereFromJSON(json: any): CategoriaOpere {
    return CategoriaOpereFromJSONTyped(json, false);
}

export function CategoriaOpereFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoriaOpere {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoria': !exists(json, 'categoria') ? undefined : json['categoria'],
        'classificazione': !exists(json, 'classificazione') ? undefined : json['classificazione'],
        'cclassificazione': !exists(json, 'cclassificazione') ? undefined : json['cclassificazione'],
        'ccategoria': !exists(json, 'ccategoria') ? undefined : json['ccategoria'],
    };
}

export function CategoriaOpereToJSON(value?: CategoriaOpere | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoria': value.categoria,
        'classificazione': value.classificazione,
        'cclassificazione': value.cclassificazione,
        'ccategoria': value.ccategoria,
    };
}


