/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyRegisteredDTO
 */
export interface CompanyRegisteredDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyRegisteredDTO
     */
    registered?: boolean;
}

export function CompanyRegisteredDTOFromJSON(json: any): CompanyRegisteredDTO {
    return CompanyRegisteredDTOFromJSONTyped(json, false);
}

export function CompanyRegisteredDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRegisteredDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
    };
}

export function CompanyRegisteredDTOToJSON(value?: CompanyRegisteredDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registered': value.registered,
    };
}


