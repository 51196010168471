/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyAddress,
    CompanyAddressFromJSON,
    CompanyAddressFromJSONTyped,
    CompanyAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyAteco
 */
export interface CompanyAteco {
    /**
     * 
     * @type {number}
     * @memberof CompanyAteco
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyAteco
     */
    atecoCodingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAteco
     */
    atecoActivityCode?: string;
    /**
     * 
     * @type {CompanyAddress}
     * @memberof CompanyAteco
     */
    companyAddress?: CompanyAddress;
}

export function CompanyAtecoFromJSON(json: any): CompanyAteco {
    return CompanyAtecoFromJSONTyped(json, false);
}

export function CompanyAtecoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAteco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'atecoCodingCode': !exists(json, 'atecoCodingCode') ? undefined : json['atecoCodingCode'],
        'atecoActivityCode': !exists(json, 'atecoActivityCode') ? undefined : json['atecoActivityCode'],
        'companyAddress': !exists(json, 'companyAddress') ? undefined : CompanyAddressFromJSON(json['companyAddress']),
    };
}

export function CompanyAtecoToJSON(value?: CompanyAteco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'atecoCodingCode': value.atecoCodingCode,
        'atecoActivityCode': value.atecoActivityCode,
        'companyAddress': CompanyAddressToJSON(value.companyAddress),
    };
}


