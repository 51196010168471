import React from "react";
import {StyleSheet, Image,ScrollView, TouchableOpacity, View,Text, StatusBar, TextInput } from "react-native";
import Collapsible from "react-native-collapsible";
import { i18n } from "../../../../i18n/i18n";
import Icon from "../../../Tools/Icon";
import CollapsibleNoWebSite from "./CollapsibleNoWebSite";
import { State } from "../../../../../types";
import ModalInfo from "../../../Modal/ModalSetWebSite";
import CollapsibleDataWebSite from "./CollapsibleDataWebSite";




export default function DigitalPositioning(props:{ myroute?:State}) {
    const [collapsed, setCollapsed] = React.useState(true);
    
    
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
      };
    return(
        <View style = {[styles.label,{backgroundColor:(collapsed?'#222222':'#292929')}]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style = {styles.textLabel}>
                    {i18n.t('components.headers.digital_positioning')}
                    </Text> 
                    <Icon name={collapsed ? "angle-down" : "angle-up"} color="white" />
                </View>
            </TouchableOpacity>
            <Collapsible 
                duration={1000} 
                collapsed={(collapsed)}
                style={styles.contentAccordion}
            >
                <View style = {{marginVertical:20, alignItems:'center'}}>
                    <Text style = {styles.textLabelComingSoon}>
                        {i18n.t('components.strategic_positioning.coming_soon')}
                    </Text>
                </View>
            </Collapsible>
         </View> 
    );
}

const styles = StyleSheet.create({
    
    header:{
        flexDirection:"row",
        flex:1,
        alignItems:'center',
    },
    contentAccordion:{
        flex:1,

        flexDirection:"column",
        height:'auto',
    },
    textNoWebSite:{
        textAlign:'center',
        fontFamily:'poppins',
        fontSize:17,
        color:'white',
    },
    label:{
        marginBottom:10,
        borderRadius:5,
        display:"flex",
    },
    textLabel:{
        paddingLeft:15,
        paddingVertical:10,
        flex:0.95,
        alignSelf:"flex-start",
        color:"white",
        textAlign:'left',
        fontWeight: 'bold',
    },
    textLabelComingSoon:{
        paddingVertical:10,
        color:"white",
        fontWeight: 'bold',
    },
    textInputRow:{
        flex:0.7,
        backgroundColor:'#464646',
        padding: 5, 
        borderRadius:5,
        fontSize:12,
        color:'white',
        margin:15,
        fontFamily:'poppins',
    },
    icon:{
        flex:0.2,
        alignSelf:'center',
        padding:5
    },
    buttonAdd:{
        flex:0.25,
        backgroundColor: '#222222',
        padding:8,
        marginVertical:15,
        borderRadius:5,
        fontFamily:'poppins', 
    },
    textAdd:{
        flex:1,
        textAlignVertical:'center',
        textAlign:'center',
        fontWeight: 'bold',
        fontSize : 12,
        color:'white',
    },
  });