/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BalanceMoreFieldsState,
    BalanceMoreFieldsStateFromJSON,
    BalanceMoreFieldsStateToJSON,
} from '../models';

export interface CreateBalanceMoreFieldsStateRequest {
    balanceMoreFieldsState: BalanceMoreFieldsState;
}

export interface DeleteBalanceMoreFieldsStateRequest {
    id: number;
}

export interface GetBalanceMoreFieldsStateRequest {
    id: number;
}

export interface PartialUpdateBalanceMoreFieldsStateRequest {
    id: number;
    balanceMoreFieldsState: BalanceMoreFieldsState;
}

export interface UpdateBalanceMoreFieldsStateRequest {
    id: number;
    balanceMoreFieldsState: BalanceMoreFieldsState;
}

/**
 * 
 */
export class BalanceMoreFieldsStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createBalanceMoreFieldsStateRaw(requestParameters: CreateBalanceMoreFieldsStateRequest): Promise<runtime.ApiResponse<BalanceMoreFieldsState>> {
        if (requestParameters.balanceMoreFieldsState === null || requestParameters.balanceMoreFieldsState === undefined) {
            throw new runtime.RequiredError('balanceMoreFieldsState','Required parameter requestParameters.balanceMoreFieldsState was null or undefined when calling createBalanceMoreFieldsState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/balance-more-fields-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceMoreFieldsStateToJSON(requestParameters.balanceMoreFieldsState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceMoreFieldsStateFromJSON(jsonValue));
    }

    /**
     */
    async createBalanceMoreFieldsState(requestParameters: CreateBalanceMoreFieldsStateRequest): Promise<BalanceMoreFieldsState> {
        const response = await this.createBalanceMoreFieldsStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteBalanceMoreFieldsStateRaw(requestParameters: DeleteBalanceMoreFieldsStateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBalanceMoreFieldsState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/balance-more-fields-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBalanceMoreFieldsState(requestParameters: DeleteBalanceMoreFieldsStateRequest): Promise<void> {
        await this.deleteBalanceMoreFieldsStateRaw(requestParameters);
    }

    /**
     */
    async getAllBalanceMoreFieldsStatesRaw(): Promise<runtime.ApiResponse<Array<BalanceMoreFieldsState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/balance-more-fields-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BalanceMoreFieldsStateFromJSON));
    }

    /**
     */
    async getAllBalanceMoreFieldsStates(): Promise<Array<BalanceMoreFieldsState>> {
        const response = await this.getAllBalanceMoreFieldsStatesRaw();
        return await response.value();
    }

    /**
     */
    async getBalanceMoreFieldsStateRaw(requestParameters: GetBalanceMoreFieldsStateRequest): Promise<runtime.ApiResponse<BalanceMoreFieldsState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBalanceMoreFieldsState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/balance-more-fields-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceMoreFieldsStateFromJSON(jsonValue));
    }

    /**
     */
    async getBalanceMoreFieldsState(requestParameters: GetBalanceMoreFieldsStateRequest): Promise<BalanceMoreFieldsState> {
        const response = await this.getBalanceMoreFieldsStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateBalanceMoreFieldsStateRaw(requestParameters: PartialUpdateBalanceMoreFieldsStateRequest): Promise<runtime.ApiResponse<BalanceMoreFieldsState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateBalanceMoreFieldsState.');
        }

        if (requestParameters.balanceMoreFieldsState === null || requestParameters.balanceMoreFieldsState === undefined) {
            throw new runtime.RequiredError('balanceMoreFieldsState','Required parameter requestParameters.balanceMoreFieldsState was null or undefined when calling partialUpdateBalanceMoreFieldsState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/balance-more-fields-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceMoreFieldsStateToJSON(requestParameters.balanceMoreFieldsState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceMoreFieldsStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateBalanceMoreFieldsState(requestParameters: PartialUpdateBalanceMoreFieldsStateRequest): Promise<BalanceMoreFieldsState> {
        const response = await this.partialUpdateBalanceMoreFieldsStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateBalanceMoreFieldsStateRaw(requestParameters: UpdateBalanceMoreFieldsStateRequest): Promise<runtime.ApiResponse<BalanceMoreFieldsState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBalanceMoreFieldsState.');
        }

        if (requestParameters.balanceMoreFieldsState === null || requestParameters.balanceMoreFieldsState === undefined) {
            throw new runtime.RequiredError('balanceMoreFieldsState','Required parameter requestParameters.balanceMoreFieldsState was null or undefined when calling updateBalanceMoreFieldsState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/balance-more-fields-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceMoreFieldsStateToJSON(requestParameters.balanceMoreFieldsState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceMoreFieldsStateFromJSON(jsonValue));
    }

    /**
     */
    async updateBalanceMoreFieldsState(requestParameters: UpdateBalanceMoreFieldsStateRequest): Promise<BalanceMoreFieldsState> {
        const response = await this.updateBalanceMoreFieldsStateRaw(requestParameters);
        return await response.value();
    }

}
