import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useState } from "react";
import { StatusBar } from 'expo-status-bar';
import { ScrollView, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { RootStackScreenProps, State } from "../../../types";
import Icon from "../Tools/Icon";
import BusinessRating from "./Component/BusinessRating/BusinessRating";
import BusinessData from "./Component/BusinessData";
import HykeeScore from "./Component/HykeeScore";
import ChamberData from "./Component/ChamberData/ChamberData";
import FinancialStatements from "./Component/FinancialStatements/FinancialStatements";
import MarketCompetitors from "./Component/MarketCompetitors/MarketCompetitors";

import FinancialScore from "./Component/FinancialScore";
import { Company, CompanyData, CompanyLinkRequestCurrentStateEnum, CompanySaved, CompanySurvey, UserExtendedTypeEnum } from "../../services/apis/generated";
import {
  customCompanyDataResourceApi,
  customCompanyLinkRequestResourceApi,
  customCompanyResourceApi,
  customCompanySavedResourceApi,
  customCompanySurveyResourceApi,
  customSurveyResourceApi,
  customUserExtendedResourceApi,
  customUserResourceApi,
} from "../../services/apis/ApiConfiguration";
import { AlertHelper } from "../../helpers/AlertHelper";
import { useFocusEffect } from "@react-navigation/native";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import SharedContext from "../../navigation/SharedContext";
import { BackgroundColorButtonDarkLight, BackgroundColorButtonGreen, ColorTextLightGreen } from "@components/ColorTheme";
import BusinessScore from "./Component/BusinessScore";
import moment from "moment";
import Colors from "../../../constants/Colors";
import hykeeStyle from "@components/hykeeStyle";
import {BaseAPIManager} from "../../services/model/BaseAPIManager";
import DigitalPositioning from "./Component/StrategicPositioning/DigitalPositioning";


export default function DashboardSavedCompany({ route, navigation }: RootStackScreenProps<'DashboardSavedCompany'>) {

  const [onMyCompanies, setOnMyCompanies] = useState<boolean>();
  const [selectedCompanyLastData, setSelectedCompanyLastData] = useState<CompanyData>({});
  const [balanceObj, setBalanceObj] = useState<any>();
  const [corporateName, setCorporateName] = useState<string>('');
  const [favorite, setFavorite] = React.useState<boolean>()
  const [foundFavorite, setFoundFavorite] = React.useState<boolean>();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [updateCompanyData, setUpdateCompanyData] = React.useState<boolean>(false);
  const sharedContext = React.useContext(SharedContext);
  const [surveyDate, setSurveyDate] = useState<any>()

  const [company, setCompany] = React.useState<Company>();

  useFocusEffect(
    useCallback(() => {
      let companyId = route.params.valueOf();
      if (!sharedContext.who && sharedContext.selectedCompany?.id != companyId)
        return;

      BaseAPIManager.contextLoadingCallback(true);
      customCompanyDataResourceApi.getLastCompanyDataByCompany({ companyId: companyId }).then((companyData) => {
        let companyDataFound = companyData ? companyData : undefined;


        let corporateName = sharedContext.selectedCompany?.name || '';

        setCorporateName(corporateName);
        setFavorite(alreadyFavorite(corporateName));

        setCompany(sharedContext.selectedCompany);
        

        if (companyDataFound != undefined) {
          setupSaved(sharedContext.selectedCompany!, companyDataFound as CompanyData);
        }
        BaseAPIManager.contextLoadingCallback(false);
      })

      return () => { // This is the cleanup function, called when the component is unmounted.
      }

    }, [route, navigation, sharedContext.who])
  );

  const setupSaved = (company: Company, companyData: CompanyData): void => {
    let update = moment().isAfter(companyData.validUntil);
    setUpdateCompanyData(update);
    checkMyCompanies(company.vatNumber!, sharedContext.myCompanies!);
    sharedContext.setSelectedCompany(company);
    setCorporateName(company.name!)
    sharedContext.setSelectedCompanyLastData(companyData);
    setSelectedCompanyLastData(companyData);
    checkFavorite(companyData);
    if (sharedContext.who == UserExtendedTypeEnum.Consultant) {
      getMyCompanyConsulted(sharedContext.myCompanySelected?.id!);
    }
    const getCompanySurvey = (): void => {
      if(company?.id){
        customCompanySurveyResourceApi.getAllCompanySurveysFilter({ customCompanySurveyCriteria: { companyId: { equals: company.id } } }).then((res) => {
          // setCompanySurveys(res);
          getSurvey(res);
          // setSurveyDate(moment(res[0].updateDate).format("DD/MM/yyyy"));
        })
      }
    }
    // getCompanySurvey()
  }

  function getSurvey(companySurvey: Array<CompanySurvey>) {
    customSurveyResourceApi.getAllSurveysFiltered({ customSurveyCriteria: {} }).then((surveyList) => {
      let surveyCompletedList: any[] = surveyList;
      for (let survey of surveyCompletedList) {
        for (let company of companySurvey) {
          if (survey.id == company.survey?.id) {
            survey.completed = company.completed;
            if (company.updateDate != undefined) {
              setSurveyDate(moment(company.updateDate).format("DD/MM/yyyy"));
            }
          }
        }
      }
    })
  }

  /**
   * function is used to set the company consulted
   * @param id 
   */
  const getMyCompanyConsulted = (id: number): void => {
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByConsultant({ id: id }).then(res => {
      sharedContext.setMyCompaniesConsultedRequest(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }
  /**
  * Method is used to check if the Company is present in the favorite list.
  * @param companyInformation shows all information on the loaded company;
  */
  const checkFavorite = (companyInformation: CompanyData,): void => {
    customUserResourceApi.getMe().then((res) => {
      customCompanySavedResourceApi.getAllCompaniesSavedFilter({ customCompanySavedCriteria: { userId: { equals: res.id } } }).then((csaved: CompanySaved[]) => {
        sharedContext.setCompaniesSaved(csaved);
        let companyDataId = companyInformation.id
        let foundCompanySaved = csaved.find((favorite) => favorite.companyData?.id == companyDataId);
        sharedContext.setCompanyFavorite(foundCompanySaved);
        foundCompanySaved ? setFoundFavorite(true) : setFoundFavorite(false);
      }).catch(error => { AlertHelper.showSimpleErrorAlert() });
    }).catch(error => { AlertHelper.showSimpleErrorAlert() });
  }

  const editFavorite = (): void => {
    if (sharedContext.companyFavorite != undefined) {
      customCompanySavedResourceApi.deleteCompanySavedForLoggedUser({ id: sharedContext.companyFavorite.id! }).then(res => {
        sharedContext.setCompanyFavorite(undefined);
        setFoundFavorite(false);
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    } else {
      customUserResourceApi.getMe().then((res) => {
        customCompanySavedResourceApi.createCompanySavedForLoggedUser({
          companySaved: {
            date: new Date(Date.now()), user: res, company: sharedContext.selectedCompany, companyData: sharedContext.selectedCompanyLastData
          }
        }).then((res) => {
          setFavorite(true);
          checkFavorite(res.companyData!);
        }).catch(error => AlertHelper.showSimpleErrorAlert());
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    }
  }

  /**
   * Controlla se la compagnia salvata fa parte delle mie compagnie
   * @param vatNumber 
   * @param allMyCompanies 
   * @returns 
   */
  const checkMyCompanies = (vatNumber: string, allMyCompanies: Company[]): void => {
    for (let company of allMyCompanies) {
      if (company.vatNumber == vatNumber)
        return setOnMyCompanies(true);
    }
    return setOnMyCompanies(false);
  }

  const goToSurvey = (): void => {
    NavigationHelper.navigateToSurvey(navigation, sharedContext.selectedCompany);
  }

  const myroute: State = {
    webSite: null
  };

  const onMyLinkedCompanies = (): boolean => {
    return sharedContext.who == UserExtendedTypeEnum.Consultant
      && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
        && companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted) != undefined
  }

  function alreadyFavorite(name: string) {
    let res = myroute.listFavorite?.find(fav => fav.name == name)
    if (myroute.listFavorite?.find(fav => fav.name == name) != undefined) {
      return true
    } else {
      return false
    }
  }

  /**
   * The function is used to check the validity of the company data.
   * setUpdateCompanyData (button "update accounting data")
   */
  const checkLastCompanyDataValid = () => {
    if (sharedContext.selectedCompany) {
      customCompanyDataResourceApi.getLastCompanyDataByCompany({ companyId: sharedContext.selectedCompany?.id! }).then((companyData) => {

        let update = moment().isAfter(companyData.validUntil);
        setUpdateCompanyData(update);
      })
    }
  }

  /**
   * The function is used to update the accounting data. Called by "onPress" function
   */
  const updateAccountingData = (): void => {//IMPLEMENTARE QUESTA LOGICA A BACK( se il companyData aggiornato già esiste allora scalo il credito ma non facico la chiamata ad infocamere)
    customCompanyResourceApi.searchCompanyFull({ searchCompanyFullDTO: { vatNumber: sharedContext.selectedCompany?.vatNumber } }).then(company => {
      if (company != undefined) {
        customUserExtendedResourceApi.getUserExtendedMe().then(res => {
          sharedContext.setUserExtendedMe(res);
          NavigationHelper.navigateToDashboardCompanySearched(navigation, company);
        }).catch(error => AlertHelper.showSimpleErrorAlert());
      }
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }


  function setLastBalanceCallback(financialStatementProps: any): void {
    setBalanceObj(financialStatementProps);
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        {!onMyCompanies &&
          <View style={{ alignSelf: 'center' }}>
            <TouchableOpacity
              style={hykeeStyle.goBack}
              onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
              <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
            </TouchableOpacity>
          </View>}

        <View style={{ alignSelf: 'center',flexShrink:1 }}>
          <Text style={[hykeeStyle.title]}>
            {corporateName + "  "}
          </Text>
        </View>

        {!onMyCompanies &&
          <View style={{ alignSelf: 'center' }}>
            <TouchableOpacity
              style={hykeeStyle.paddingIconStar}
              onPress={() => editFavorite()}
            >
              {foundFavorite ? <Icon name={"star"} color={"#00CC83"} /> : <Icon name={"star-o"} color={"#00CC83"} />}
            </TouchableOpacity>
          </View>}
      </View>
      <ScrollView style={hykeeStyle.containerScroll}>

        {updateCompanyData &&
          <TouchableOpacity style={styles.refreshCompanyData}>
            <Text onPress={updateAccountingData}>{i18n.t('screens.dashboard.buttons.update_accounting_data')}</Text>
          </TouchableOpacity>
        }

        <BusinessData lastCompanyData={selectedCompanyLastData} balanceObject={balanceObj}/>
        {sharedContext.selectedCompany &&
          <>
            <HykeeScore onMyCompanies={onMyCompanies!} navigation={navigation} lastCompanyData={selectedCompanyLastData}/>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <BusinessScore surveyDate={surveyDate} onMyCompanies={onMyCompanies!} navigation={navigation} />
              <FinancialScore company={company!} onMyCompanies={onMyCompanies!} navigation={navigation} lastCompanyData={selectedCompanyLastData}  balanceObject={balanceObj}/>
            </View>
            <View>
              {//who == UserExtendedTypeEnum.Company && <MarketNews />
              }
              {//who == UserExtendedTypeEnum.Company && <InsightsNews />
              }
              {/* {(onMyCompanies || onMyLinkedCompanies()) && <Highlights />}
              {(onMyCompanies || onMyLinkedCompanies()) && <Outlook />} */}
              <FinancialStatements company={sharedContext.selectedCompany} callback={setLastBalanceCallback}/>
              {(onMyCompanies || onMyLinkedCompanies()) && <BusinessRating onMyCompanies={onMyCompanies!} goToSurvey={() => onMyCompanies ? goToSurvey() : null} />}
              {<MarketCompetitors company={sharedContext.selectedCompany} competitors={sharedContext.myMarketCompetitors} goToCompany={(company) => NavigationHelper.navigateToDashboardSavedCompany(navigation, company.id!)} />}
              {//(onMyCompanies || onMyLinkedCompanies()) && <StrategicPositioning myroute={myroute} />
              }
              {(onMyCompanies || onMyLinkedCompanies()) && <DigitalPositioning />}
              <ChamberData navigation={navigation} companyDataId={sharedContext.selectedCompanyLastData?.id} />
            </View>
          </>}
      </ScrollView>
    </LinearGradient>
  );

}


const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    marginTop: 5
  },
  goBack: {
    marginRight: 20
  },
  header: {
    padding: 20,
    backgroundColor: '#fff',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  refreshCompanyData: {
    backgroundColor: BackgroundColorButtonGreen(),
    color: 'white',
    padding: 10,
    minWidth: 150,
    textAlign: 'center'
  },
  contentAccordion: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  labelAzienda: {
    marginTop: 50,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  label: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  textSearch: {
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  iconCollapseContainer: {
  },
  containerTextCollapse: {
    backgroundColor: BackgroundColorButtonDarkLight(),
    marginBottom: 10,
    borderRadius: 5,
  },
  containerCollapse: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  textCollapse: {
    textAlign: 'left',
    fontSize: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  textLabel: {
    textAlign: 'left',
    marginLeft: 15,
    fontFamily: 'poppins',
  },
  containerSA: {
    flex: 1,
    marginHorizontal: 20,
  },
  containerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 50,
    padding: 20,
    borderRadius: 13,
  },
  imageButton: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 30,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
  },
});



