/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataToJSON,
} from '../models';

export interface CreateCompanyDataRequest {
    companyData: CompanyData;
}

export interface DeleteCompanyDataRequest {
    id: number;
}

export interface GetCompanyDataRequest {
    id: number;
}

export interface PartialUpdateCompanyDataRequest {
    id: number;
    companyData: CompanyData;
}

export interface UpdateCompanyDataRequest {
    id: number;
    companyData: CompanyData;
}

/**
 * 
 */
export class CompanyDataResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyDataRaw(requestParameters: CreateCompanyDataRequest): Promise<runtime.ApiResponse<CompanyData>> {
        if (requestParameters.companyData === null || requestParameters.companyData === undefined) {
            throw new runtime.RequiredError('companyData','Required parameter requestParameters.companyData was null or undefined when calling createCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataToJSON(requestParameters.companyData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyData(requestParameters: CreateCompanyDataRequest): Promise<CompanyData> {
        const response = await this.createCompanyDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyDataRaw(requestParameters: DeleteCompanyDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyData(requestParameters: DeleteCompanyDataRequest): Promise<void> {
        await this.deleteCompanyDataRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyDataRaw(): Promise<runtime.ApiResponse<Array<CompanyData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDataFromJSON));
    }

    /**
     */
    async getAllCompanyData(): Promise<Array<CompanyData>> {
        const response = await this.getAllCompanyDataRaw();
        return await response.value();
    }

    /**
     */
    async getCompanyDataRaw(requestParameters: GetCompanyDataRequest): Promise<runtime.ApiResponse<CompanyData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyData(requestParameters: GetCompanyDataRequest): Promise<CompanyData> {
        const response = await this.getCompanyDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyDataRaw(requestParameters: PartialUpdateCompanyDataRequest): Promise<runtime.ApiResponse<CompanyData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyData.');
        }

        if (requestParameters.companyData === null || requestParameters.companyData === undefined) {
            throw new runtime.RequiredError('companyData','Required parameter requestParameters.companyData was null or undefined when calling partialUpdateCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataToJSON(requestParameters.companyData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyData(requestParameters: PartialUpdateCompanyDataRequest): Promise<CompanyData> {
        const response = await this.partialUpdateCompanyDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyDataRaw(requestParameters: UpdateCompanyDataRequest): Promise<runtime.ApiResponse<CompanyData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyData.');
        }

        if (requestParameters.companyData === null || requestParameters.companyData === undefined) {
            throw new runtime.RequiredError('companyData','Required parameter requestParameters.companyData was null or undefined when calling updateCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataToJSON(requestParameters.companyData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyData(requestParameters: UpdateCompanyDataRequest): Promise<CompanyData> {
        const response = await this.updateCompanyDataRaw(requestParameters);
        return await response.value();
    }

}
