/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Titolare,
    TitolareFromJSON,
    TitolareFromJSONTyped,
    TitolareToJSON,
} from './';

/**
 * 
 * @export
 * @interface Titolari
 */
export interface Titolari {
    /**
     * 
     * @type {Array<Titolare>}
     * @memberof Titolari
     */
    titolare: Array<Titolare>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Titolari
     */
    note?: Array<string>;
}

export function TitolariFromJSON(json: any): Titolari {
    return TitolariFromJSONTyped(json, false);
}

export function TitolariFromJSONTyped(json: any, ignoreDiscriminator: boolean): Titolari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titolare': ((json['titolare'] as Array<any>).map(TitolareFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function TitolariToJSON(value?: Titolari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'titolare': ((value.titolare as Array<any>).map(TitolareToJSON)),
        'note': value.note,
    };
}


