/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DipendentiOrarioLavoro,
    DipendentiOrarioLavoroFromJSON,
    DipendentiOrarioLavoroFromJSONTyped,
    DipendentiOrarioLavoroToJSON,
} from './';

/**
 * 
 * @export
 * @interface DipendentiOrariLavoro
 */
export interface DipendentiOrariLavoro {
    /**
     * 
     * @type {Array<DipendentiOrarioLavoro>}
     * @memberof DipendentiOrariLavoro
     */
    dipendentiOrarioLavoro: Array<DipendentiOrarioLavoro>;
}

export function DipendentiOrariLavoroFromJSON(json: any): DipendentiOrariLavoro {
    return DipendentiOrariLavoroFromJSONTyped(json, false);
}

export function DipendentiOrariLavoroFromJSONTyped(json: any, ignoreDiscriminator: boolean): DipendentiOrariLavoro {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dipendentiOrarioLavoro': ((json['dipendentiOrarioLavoro'] as Array<any>).map(DipendentiOrarioLavoroFromJSON)),
    };
}

export function DipendentiOrariLavoroToJSON(value?: DipendentiOrariLavoro | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dipendentiOrarioLavoro': ((value.dipendentiOrarioLavoro as Array<any>).map(DipendentiOrarioLavoroToJSON)),
    };
}


