/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Activity,
    ActivityFromJSON,
    ActivityToJSON,
} from '../models';

export interface CreateActivityRequest {
    activity: Activity;
}

export interface DeleteActivityRequest {
    id: number;
}

export interface GetActivityRequest {
    id: number;
}

export interface PartialUpdateActivityRequest {
    id: number;
    activity: Activity;
}

export interface UpdateActivityRequest {
    id: number;
    activity: Activity;
}

/**
 * 
 */
export class ActivityResourceApi extends runtime.BaseAPI {

    /**
     */
    async createActivityRaw(requestParameters: CreateActivityRequest): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.activity === null || requestParameters.activity === undefined) {
            throw new runtime.RequiredError('activity','Required parameter requestParameters.activity was null or undefined when calling createActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/activities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityToJSON(requestParameters.activity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async createActivity(requestParameters: CreateActivityRequest): Promise<Activity> {
        const response = await this.createActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteActivityRaw(requestParameters: DeleteActivityRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/activities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteActivity(requestParameters: DeleteActivityRequest): Promise<void> {
        await this.deleteActivityRaw(requestParameters);
    }

    /**
     */
    async getActivityRaw(requestParameters: GetActivityRequest): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/activities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async getActivity(requestParameters: GetActivityRequest): Promise<Activity> {
        const response = await this.getActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllActivitiesRaw(): Promise<runtime.ApiResponse<Array<Activity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/activities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFromJSON));
    }

    /**
     */
    async getAllActivities(): Promise<Array<Activity>> {
        const response = await this.getAllActivitiesRaw();
        return await response.value();
    }

    /**
     */
    async partialUpdateActivityRaw(requestParameters: PartialUpdateActivityRequest): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateActivity.');
        }

        if (requestParameters.activity === null || requestParameters.activity === undefined) {
            throw new runtime.RequiredError('activity','Required parameter requestParameters.activity was null or undefined when calling partialUpdateActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/activities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityToJSON(requestParameters.activity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateActivity(requestParameters: PartialUpdateActivityRequest): Promise<Activity> {
        const response = await this.partialUpdateActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateActivityRaw(requestParameters: UpdateActivityRequest): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateActivity.');
        }

        if (requestParameters.activity === null || requestParameters.activity === undefined) {
            throw new runtime.RequiredError('activity','Required parameter requestParameters.activity was null or undefined when calling updateActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/activities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityToJSON(requestParameters.activity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async updateActivity(requestParameters: UpdateActivityRequest): Promise<Activity> {
        const response = await this.updateActivityRaw(requestParameters);
        return await response.value();
    }

}
