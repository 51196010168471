/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoMesi,
    InfoMesiFromJSON,
    InfoMesiFromJSONTyped,
    InfoMesiToJSON,
    ValoriMedi,
    ValoriMediFromJSON,
    ValoriMediFromJSONTyped,
    ValoriMediToJSON,
} from './';

/**
 * 
 * @export
 * @interface Collaboratori
 */
export interface Collaboratori {
    /**
     * 
     * @type {InfoMesi}
     * @memberof Collaboratori
     */
    infoMesi?: InfoMesi;
    /**
     * 
     * @type {ValoriMedi}
     * @memberof Collaboratori
     */
    valoriMedi?: ValoriMedi;
}

export function CollaboratoriFromJSON(json: any): Collaboratori {
    return CollaboratoriFromJSONTyped(json, false);
}

export function CollaboratoriFromJSONTyped(json: any, ignoreDiscriminator: boolean): Collaboratori {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoMesi': !exists(json, 'infoMesi') ? undefined : InfoMesiFromJSON(json['infoMesi']),
        'valoriMedi': !exists(json, 'valoriMedi') ? undefined : ValoriMediFromJSON(json['valoriMedi']),
    };
}

export function CollaboratoriToJSON(value?: Collaboratori | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoMesi': InfoMesiToJSON(value.infoMesi),
        'valoriMedi': ValoriMediToJSON(value.valoriMedi),
    };
}


