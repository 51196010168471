/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassificazioneAteco2002,
    ClassificazioneAteco2002FromJSON,
    ClassificazioneAteco2002FromJSONTyped,
    ClassificazioneAteco2002ToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassificazioniAteco2002
 */
export interface ClassificazioniAteco2002 {
    /**
     * 
     * @type {Array<ClassificazioneAteco2002>}
     * @memberof ClassificazioniAteco2002
     */
    classificazioneAteco2002: Array<ClassificazioneAteco2002>;
}

export function ClassificazioniAteco2002FromJSON(json: any): ClassificazioniAteco2002 {
    return ClassificazioniAteco2002FromJSONTyped(json, false);
}

export function ClassificazioniAteco2002FromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassificazioniAteco2002 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classificazioneAteco2002': ((json['classificazioneAteco2002'] as Array<any>).map(ClassificazioneAteco2002FromJSON)),
    };
}

export function ClassificazioniAteco2002ToJSON(value?: ClassificazioniAteco2002 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classificazioneAteco2002': ((value.classificazioneAteco2002 as Array<any>).map(ClassificazioneAteco2002ToJSON)),
    };
}


