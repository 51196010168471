/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GruppoIva
 */
export interface GruppoIva {
    /**
     * 
     * @type {string}
     * @memberof GruppoIva
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof GruppoIva
     */
    dtUltimoAggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof GruppoIva
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof GruppoIva
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof GruppoIva
     */
    cfonte?: string;
}

export function GruppoIvaFromJSON(json: any): GruppoIva {
    return GruppoIvaFromJSONTyped(json, false);
}

export function GruppoIvaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GruppoIva {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'dtUltimoAggiornamento': !exists(json, 'dtUltimoAggiornamento') ? undefined : json['dtUltimoAggiornamento'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function GruppoIvaToJSON(value?: GruppoIva | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'dtUltimoAggiornamento': value.dtUltimoAggiornamento,
        'denominazione': value.denominazione,
        'dtInizio': value.dtInizio,
        'cfonte': value.cfonte,
    };
}


