/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attestazione,
    AttestazioneFromJSON,
    AttestazioneFromJSONTyped,
    AttestazioneToJSON,
    CategorieOpere,
    CategorieOpereFromJSON,
    CategorieOpereFromJSONTyped,
    CategorieOpereToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttestazioneQualificazioni
 */
export interface AttestazioneQualificazioni {
    /**
     * 
     * @type {CategorieOpere}
     * @memberof AttestazioneQualificazioni
     */
    categorieOpere?: CategorieOpere;
    /**
     * 
     * @type {Attestazione}
     * @memberof AttestazioneQualificazioni
     */
    attestazione?: Attestazione;
}

export function AttestazioneQualificazioniFromJSON(json: any): AttestazioneQualificazioni {
    return AttestazioneQualificazioniFromJSONTyped(json, false);
}

export function AttestazioneQualificazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttestazioneQualificazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categorieOpere': !exists(json, 'categorieOpere') ? undefined : CategorieOpereFromJSON(json['categorieOpere']),
        'attestazione': !exists(json, 'attestazione') ? undefined : AttestazioneFromJSON(json['attestazione']),
    };
}

export function AttestazioneQualificazioniToJSON(value?: AttestazioneQualificazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categorieOpere': CategorieOpereToJSON(value.categorieOpere),
        'attestazione': AttestazioneToJSON(value.attestazione),
    };
}


