/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BalanceXbrlDTO,
    BalanceXbrlDTOFromJSON,
    BalanceXbrlDTOToJSON,
    CompanyScoreDTO,
    CompanyScoreDTOFromJSON,
    CompanyScoreDTOToJSON,
} from '../models';

export interface GetCompanyScoreDataRequest {
    id: number;
}

export interface GetFinancialDataRequest {
    id: number;
}

export interface GetFinancialDataByIdListRequest {
    requestBody: Array<number>;
}

/**
 * 
 */
export class CustomScoreResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCompanyScoreDataRaw(requestParameters: GetCompanyScoreDataRequest): Promise<runtime.ApiResponse<CompanyScoreDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyScoreData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/score/company/{id}/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyScoreDTOFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyScoreData(requestParameters: GetCompanyScoreDataRequest): Promise<CompanyScoreDTO> {
        const response = await this.getCompanyScoreDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getFinancialDataRaw(requestParameters: GetFinancialDataRequest): Promise<runtime.ApiResponse<BalanceXbrlDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFinancialData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/score/company-balance-sheet/{id}/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceXbrlDTOFromJSON(jsonValue));
    }

    /**
     */
    async getFinancialData(requestParameters: GetFinancialDataRequest): Promise<BalanceXbrlDTO> {
        const response = await this.getFinancialDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getFinancialDataByIdListRaw(requestParameters: GetFinancialDataByIdListRequest): Promise<runtime.ApiResponse<Array<BalanceXbrlDTO>>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling getFinancialDataByIdList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/score/company-balance-sheet/list/data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BalanceXbrlDTOFromJSON));
    }

    /**
     */
    async getFinancialDataByIdList(requestParameters: GetFinancialDataByIdListRequest): Promise<Array<BalanceXbrlDTO>> {
        const response = await this.getFinancialDataByIdListRaw(requestParameters);
        return await response.value();
    }

}
