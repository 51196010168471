/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomSurveyCriteria,
    CustomSurveyCriteriaFromJSON,
    CustomSurveyCriteriaToJSON,
    Survey,
    SurveyFromJSON,
    SurveyToJSON,
    SurveyQuestionResponseDTO,
    SurveyQuestionResponseDTOFromJSON,
    SurveyQuestionResponseDTOToJSON,
} from '../models';

export interface GetAllSurveysFilteredRequest {
    customSurveyCriteria: CustomSurveyCriteria;
}

export interface GetAllSurveysQuestionResponseBySurveyIdRequest {
    id: number;
}

/**
 * 
 */
export class CustomSurveyResourceApi extends runtime.BaseAPI {

    /**
     */
    async getAllSurveysFilteredRaw(requestParameters: GetAllSurveysFilteredRequest): Promise<runtime.ApiResponse<Array<Survey>>> {
        if (requestParameters.customSurveyCriteria === null || requestParameters.customSurveyCriteria === undefined) {
            throw new runtime.RequiredError('customSurveyCriteria','Required parameter requestParameters.customSurveyCriteria was null or undefined when calling getAllSurveysFiltered.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/surveys/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomSurveyCriteriaToJSON(requestParameters.customSurveyCriteria),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyFromJSON));
    }

    /**
     */
    async getAllSurveysFiltered(requestParameters: GetAllSurveysFilteredRequest): Promise<Array<Survey>> {
        const response = await this.getAllSurveysFilteredRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllSurveysQuestionResponseBySurveyIdRaw(requestParameters: GetAllSurveysQuestionResponseBySurveyIdRequest): Promise<runtime.ApiResponse<SurveyQuestionResponseDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllSurveysQuestionResponseBySurveyId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async getAllSurveysQuestionResponseBySurveyId(requestParameters: GetAllSurveysQuestionResponseBySurveyIdRequest): Promise<SurveyQuestionResponseDTO> {
        const response = await this.getAllSurveysQuestionResponseBySurveyIdRaw(requestParameters);
        return await response.value();
    }

}
