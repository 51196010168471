import React from 'react';
import { ReactNode } from 'react';
import {StyleSheet,Image, View,Text, Dimensions} from "react-native";
import { i18n } from '../i18n/i18n';
import Logo from '../../assets/svg/Logo'

export function SplashLogoScreen(props:{welcome?:Boolean, registration?:Boolean, splash?:Boolean,children?: ReactNode} ){

  return (
      <View style={props.splash? styles.containerSplash : props.registration? styles.containerRegistration:styles.containerLogin
      }>
        <Logo width={270} height={59.79}/>
        {props.welcome && <Text style={styles.labelWelcome}>{i18n.t('label.welcome')}
        </Text>}
        {props.registration && <Text style={styles.labelWelcome}>{i18n.t('screens.signup.title')}
        </Text>}
      </View>
  );
}
const styles = StyleSheet.create({
    
  labelWelcome:{
    alignSelf:'center',
    justifyContent:'flex-start',
    color:'white',
    fontSize: 18,
    flex:0.5,
    marginTop:15,
    fontFamily:'gotham',
  },
  containerSplash:{
    alignSelf:'center',
    position:'absolute',
    top:(Dimensions.get('window').height)/2,
    fontFamily:'poppins',
  },
  containerRegistration:{
    marginBottom:100,
    flex:0.1,
    position:'relative',
    top:70,
    alignSelf:'center',
    fontFamily:'poppins',
  },
  containerLogin:{
    marginTop:40,
    marginBottom:30,
    alignSelf:'center',
    justifyContent:'flex-end',
    flex:0.2,
    fontFamily:'poppins',
  },
})