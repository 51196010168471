/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Telefono
 */
export interface Telefono {
    /**
     * 
     * @type {string}
     * @memberof Telefono
     */
    prefisso?: string;
    /**
     * 
     * @type {string}
     * @memberof Telefono
     */
    n?: string;
}

export function TelefonoFromJSON(json: any): Telefono {
    return TelefonoFromJSONTyped(json, false);
}

export function TelefonoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Telefono {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefisso': !exists(json, 'prefisso') ? undefined : json['prefisso'],
        'n': !exists(json, 'n') ? undefined : json['n'],
    };
}

export function TelefonoToJSON(value?: Telefono | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefisso': value.prefisso,
        'n': value.n,
    };
}


