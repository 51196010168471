/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormaAmministrativa,
    FormaAmministrativaFromJSON,
    FormaAmministrativaFromJSONTyped,
    FormaAmministrativaToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormeAmministrative
 */
export interface FormeAmministrative {
    /**
     * 
     * @type {Array<FormaAmministrativa>}
     * @memberof FormeAmministrative
     */
    formaAmministrativa: Array<FormaAmministrativa>;
}

export function FormeAmministrativeFromJSON(json: any): FormeAmministrative {
    return FormeAmministrativeFromJSONTyped(json, false);
}

export function FormeAmministrativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormeAmministrative {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formaAmministrativa': ((json['formaAmministrativa'] as Array<any>).map(FormaAmministrativaFromJSON)),
    };
}

export function FormeAmministrativeToJSON(value?: FormeAmministrative | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formaAmministrativa': ((value.formaAmministrativa as Array<any>).map(FormaAmministrativaToJSON)),
    };
}


