/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditamentoOdc,
    AccreditamentoOdcFromJSON,
    AccreditamentoOdcFromJSONTyped,
    AccreditamentoOdcToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccreditamentiOdc
 */
export interface AccreditamentiOdc {
    /**
     * 
     * @type {Array<AccreditamentoOdc>}
     * @memberof AccreditamentiOdc
     */
    accreditamentoOdc: Array<AccreditamentoOdc>;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentiOdc
     */
    dtUltimaModifica?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentiOdc
     */
    sitoInternet?: string;
}

export function AccreditamentiOdcFromJSON(json: any): AccreditamentiOdc {
    return AccreditamentiOdcFromJSONTyped(json, false);
}

export function AccreditamentiOdcFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccreditamentiOdc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditamentoOdc': ((json['accreditamentoOdc'] as Array<any>).map(AccreditamentoOdcFromJSON)),
        'dtUltimaModifica': !exists(json, 'dtUltimaModifica') ? undefined : json['dtUltimaModifica'],
        'sitoInternet': !exists(json, 'sitoInternet') ? undefined : json['sitoInternet'],
    };
}

export function AccreditamentiOdcToJSON(value?: AccreditamentiOdc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditamentoOdc': ((value.accreditamentoOdc as Array<any>).map(AccreditamentoOdcToJSON)),
        'dtUltimaModifica': value.dtUltimaModifica,
        'sitoInternet': value.sitoInternet,
    };
}


