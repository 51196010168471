// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import {customCompanyDataResourceApi, customCompanyLinkRequestResourceApi} from '@services/apis/ApiConfiguration';
import { Company, CompanyLinkRequest, CompanyLinkRequestCurrentStateEnum, CompanyLinkRequestStateStateEnum, UserExtendedTypeEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import React, {useEffect, useState} from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';
import Icon from "@screens/Tools/Icon";
import ModalSimpleInfoText from "@screens/Modal/ModalSimpleInfoText";
import {Logger} from "@helpers/Logger";


export default function CompaniesConsultantPendingList(props: { goToCompany: (company: Company) => void }) {

  const sharedContext = React.useContext(SharedContext)
  const [infoTextVisible, setInfoTextVisible] = React.useState<boolean>(false);
  const [partnerText, setPartnerText] = useState<string>('Il Partner Program di Alpha Bank consente di accedere in via esclusiva ad aggiornamenti e informative real-time altamente profilate rispetto ai nuovi bandi di finanza agevolata su territorio nazionale ed europeo. Inoltre, gli abbonati al partner program avranno un consulente dedicato che risponderà via email o via chat alle richieste di approfondimento dei clienti entro massimo 60 minuti dalla richiesta.');

  const AcceptRequest = (companyLinkRequest: CompanyLinkRequest) => {
    customCompanyLinkRequestResourceApi.updateCompanyLinkRequestState1({ id: companyLinkRequest.id!, companyLinkRequestState: { state: CompanyLinkRequestStateStateEnum.Accepted } })
      .then((response) => {
        if (response)
          customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: sharedContext.myCompanySelected?.id! })
            .then((res) => {
              sharedContext.setMyCompaniesConsultantRequest(res);
            })
            .catch(error => AlertHelper.showSimpleErrorAlert());
      })
      .catch(error => AlertHelper.showSimpleErrorAlert());
  }
  const RejectRequest = (companyLinkRequest: CompanyLinkRequest) => {
    customCompanyLinkRequestResourceApi.updateCompanyLinkRequestState1({ id: companyLinkRequest.id!, companyLinkRequestState: { state: CompanyLinkRequestStateStateEnum.Rejected } })
      .then((response) => {
        if (response)
          customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: sharedContext.myCompanySelected?.id! })
            .then((res) => {
              sharedContext.setMyCompaniesConsultantRequest(res);
            })
            .catch(error => AlertHelper.showSimpleErrorAlert());
      })
      .catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const getMyCompanyConsultant = (id: number): void => {
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: id }).then(res => {
      sharedContext.setMyCompaniesConsultedRequest(res)
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  useEffect(() => {
    getMyCompanyConsultant(sharedContext.myCompanySelected?.id!);
  }, []);

  return (
    <>
      {sharedContext.myCompaniesConsultantRequest
        && sharedContext.who == UserExtendedTypeEnum.Company
        && sharedContext.myCompaniesConsultantRequest.length > 0
        && sharedContext.myCompaniesConsultantRequest.find(
          (companyLinkRequest) => companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Pending) != undefined ?
        sharedContext.myCompaniesConsultantRequest.map((companyLinkRequest, key) => {
          return (
            <View key={key}>
              {companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Pending &&
                <LinearGradient
                  key={companyLinkRequest.consultant?.id}
                  colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                  locations={[0.4, 0.8]}
                  start={{ x: 1, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  style={styles.borderGradient}
                >
                  <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                  >
                    <View style={hykeeStyle.col} >
                      <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: "center", alignSelf: "center", alignContent: "center" }}>
                        <Text style={{fontFamily:'poppins', color:'white', fontSize:18, alignSelf:'flex-end',}}>
                          {companyLinkRequest.consultant?.name + ' ' + i18n.t('components.partner_data.request_pending')}
                        </Text>
                        <View style={{  marginLeft: -13, marginTop: 25}}>
                          <TouchableOpacity style={companyLinkRequest.consultant?.partnerProgramDescriptionText == null || companyLinkRequest.consultant?.partnerProgramDescriptionText == '' ? {display: 'none'} : {}} onPress={() => setInfoTextVisible(true)}>
                            <Icon name="info-circle" size={12} color={"white"} />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={styles.row} >
                        <TouchableOpacity style={[hykeeStyle.buttonAccept, { flex: 0.3 }]}
                          onPress={() => {
                            AcceptRequest(companyLinkRequest)
                          }}>
                          <Text style={styles.textButton}>
                            {i18n.t('components.partner_data.button_accepted')}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[hykeeStyle.buttonReject, { flex: 0.3 }]}
                          onPress={() => {
                            RejectRequest(companyLinkRequest)
                          }}>
                          <Text style={styles.textButton}>
                            {i18n.t('components.partner_data.button_rejected')}
                          </Text>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={[hykeeStyle.buttonDetails,{flex:0.3}]}
                          onPress={() => {
                            props.goToCompany(companyLinkRequest.consultant!)
                          }}>
                          <Text style={styles.textButton}>
                            {i18n.t('components.partner_data.button_detail')}
                          </Text>
                        </TouchableOpacity> */}
                      </View>
                      {infoTextVisible && <ModalSimpleInfoText message={companyLinkRequest.consultant?.partnerProgramDescriptionText} setVisible={(infoText) => (infoText != null ? setInfoTextVisible(infoText) : null)} />}

                    </View>
                  </LinearGradient>
                </LinearGradient>}
            </View>)
        })
        :
        <View style={styles.containerList}>
          <Text style={styles.textLabel}>

            {i18n.t('components.link_companies_data.no_pending')}
          </Text>
        </View>

      }
    </>
  );
}

const styles = StyleSheet.create({
  borderGradient: {
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 10,
  },
  containerList: {
    marginBottom: 20,
  },
  textButton: {
    color: "white",
    fontFamily: "poppins",
    fontSize: 13,
    textAlign: 'center',
    padding: 5,
    paddingVertical: 10,
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  containerBack: {
    fontFamily: 'poppins',
    padding: 8,
    margin: 1,
    borderRadius: 13,
    overflow: 'hidden'
  },
  contentLocation: {
    flexDirection: 'row',
    alignContent: 'center',
    padding: 5,
  },
  textUnderTable: {
    alignSelf: 'flex-start',
    fontSize: 13,
    flex: 0.5,
    paddingHorizontal: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  contentImg: {
    flex: 0.3,
  },
});



