/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComunicazioniCuratore,
    ComunicazioniCuratoreFromJSON,
    ComunicazioniCuratoreFromJSONTyped,
    ComunicazioniCuratoreToJSON,
    EstremiAtto,
    EstremiAttoFromJSON,
    EstremiAttoFromJSONTyped,
    EstremiAttoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProceduraConcorsuale
 */
export interface ProceduraConcorsuale {
    /**
     * 
     * @type {EstremiAtto}
     * @memberof ProceduraConcorsuale
     */
    estremiAtto?: EstremiAtto;
    /**
     * 
     * @type {ComunicazioniCuratore}
     * @memberof ProceduraConcorsuale
     */
    comunicazioniCuratore?: ComunicazioniCuratore;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtIscrizioneProcedura?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtProvvedimento?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtTermine?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtOmologazione?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtChiusura?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtEsecuzione?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtRevoca?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    dtUdienza?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceduraConcorsuale
     */
    ctipo?: string;
}

export function ProceduraConcorsualeFromJSON(json: any): ProceduraConcorsuale {
    return ProceduraConcorsualeFromJSONTyped(json, false);
}

export function ProceduraConcorsualeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProceduraConcorsuale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiAtto': !exists(json, 'estremiAtto') ? undefined : EstremiAttoFromJSON(json['estremiAtto']),
        'comunicazioniCuratore': !exists(json, 'comunicazioniCuratore') ? undefined : ComunicazioniCuratoreFromJSON(json['comunicazioniCuratore']),
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'dtIscrizioneProcedura': !exists(json, 'dtIscrizioneProcedura') ? undefined : json['dtIscrizioneProcedura'],
        'dtProvvedimento': !exists(json, 'dtProvvedimento') ? undefined : json['dtProvvedimento'],
        'dtAtto': !exists(json, 'dtAtto') ? undefined : json['dtAtto'],
        'dtTermine': !exists(json, 'dtTermine') ? undefined : json['dtTermine'],
        'dtOmologazione': !exists(json, 'dtOmologazione') ? undefined : json['dtOmologazione'],
        'dtChiusura': !exists(json, 'dtChiusura') ? undefined : json['dtChiusura'],
        'dtEsecuzione': !exists(json, 'dtEsecuzione') ? undefined : json['dtEsecuzione'],
        'dtRevoca': !exists(json, 'dtRevoca') ? undefined : json['dtRevoca'],
        'dtUdienza': !exists(json, 'dtUdienza') ? undefined : json['dtUdienza'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function ProceduraConcorsualeToJSON(value?: ProceduraConcorsuale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiAtto': EstremiAttoToJSON(value.estremiAtto),
        'comunicazioniCuratore': ComunicazioniCuratoreToJSON(value.comunicazioniCuratore),
        'tipo': value.tipo,
        'dtIscrizioneProcedura': value.dtIscrizioneProcedura,
        'dtProvvedimento': value.dtProvvedimento,
        'dtAtto': value.dtAtto,
        'dtTermine': value.dtTermine,
        'dtOmologazione': value.dtOmologazione,
        'dtChiusura': value.dtChiusura,
        'dtEsecuzione': value.dtEsecuzione,
        'dtRevoca': value.dtRevoca,
        'dtUdienza': value.dtUdienza,
        'ctipo': value.ctipo,
    };
}


