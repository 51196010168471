/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaricaRappresentante,
    CaricaRappresentanteFromJSON,
    CaricaRappresentanteFromJSONTyped,
    CaricaRappresentanteToJSON,
} from './';

/**
 * 
 * @export
 * @interface CaricheRappresentante
 */
export interface CaricheRappresentante {
    /**
     * 
     * @type {Array<CaricaRappresentante>}
     * @memberof CaricheRappresentante
     */
    caricaRappresentante: Array<CaricaRappresentante>;
}

export function CaricheRappresentanteFromJSON(json: any): CaricheRappresentante {
    return CaricheRappresentanteFromJSONTyped(json, false);
}

export function CaricheRappresentanteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaricheRappresentante {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caricaRappresentante': ((json['caricaRappresentante'] as Array<any>).map(CaricaRappresentanteFromJSON)),
    };
}

export function CaricheRappresentanteToJSON(value?: CaricheRappresentante | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caricaRappresentante': ((value.caricaRappresentante as Array<any>).map(CaricaRappresentanteToJSON)),
    };
}


