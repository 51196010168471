/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrasferimentoLocalizzazione
 */
export interface TrasferimentoLocalizzazione {
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoLocalizzazione
     */
    comune?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoLocalizzazione
     */
    provincia?: string;
}

export function TrasferimentoLocalizzazioneFromJSON(json: any): TrasferimentoLocalizzazione {
    return TrasferimentoLocalizzazioneFromJSONTyped(json, false);
}

export function TrasferimentoLocalizzazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentoLocalizzazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comune': !exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
    };
}

export function TrasferimentoLocalizzazioneToJSON(value?: TrasferimentoLocalizzazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comune': value.comune,
        'provincia': value.provincia,
    };
}


