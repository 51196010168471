/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserConsultant,
    UserConsultantFromJSON,
    UserConsultantToJSON,
} from '../models';

export interface CreateUserConsultantRequest {
    userConsultant: UserConsultant;
}

export interface DeleteUserConsultantRequest {
    id: number;
}

export interface GetUserConsultantRequest {
    id: number;
}

export interface PartialUpdateUserConsultantRequest {
    id: number;
    userConsultant: UserConsultant;
}

export interface UpdateUserConsultantRequest {
    id: number;
    userConsultant: UserConsultant;
}

/**
 * 
 */
export class UserConsultantResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserConsultantRaw(requestParameters: CreateUserConsultantRequest): Promise<runtime.ApiResponse<UserConsultant>> {
        if (requestParameters.userConsultant === null || requestParameters.userConsultant === undefined) {
            throw new runtime.RequiredError('userConsultant','Required parameter requestParameters.userConsultant was null or undefined when calling createUserConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantToJSON(requestParameters.userConsultant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantFromJSON(jsonValue));
    }

    /**
     */
    async createUserConsultant(requestParameters: CreateUserConsultantRequest): Promise<UserConsultant> {
        const response = await this.createUserConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserConsultantRaw(requestParameters: DeleteUserConsultantRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserConsultant(requestParameters: DeleteUserConsultantRequest): Promise<void> {
        await this.deleteUserConsultantRaw(requestParameters);
    }

    /**
     */
    async getAllUserConsultantsRaw(): Promise<runtime.ApiResponse<Array<UserConsultant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantFromJSON));
    }

    /**
     */
    async getAllUserConsultants(): Promise<Array<UserConsultant>> {
        const response = await this.getAllUserConsultantsRaw();
        return await response.value();
    }

    /**
     */
    async getUserConsultantRaw(requestParameters: GetUserConsultantRequest): Promise<runtime.ApiResponse<UserConsultant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantFromJSON(jsonValue));
    }

    /**
     */
    async getUserConsultant(requestParameters: GetUserConsultantRequest): Promise<UserConsultant> {
        const response = await this.getUserConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserConsultantRaw(requestParameters: PartialUpdateUserConsultantRequest): Promise<runtime.ApiResponse<UserConsultant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserConsultant.');
        }

        if (requestParameters.userConsultant === null || requestParameters.userConsultant === undefined) {
            throw new runtime.RequiredError('userConsultant','Required parameter requestParameters.userConsultant was null or undefined when calling partialUpdateUserConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantToJSON(requestParameters.userConsultant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserConsultant(requestParameters: PartialUpdateUserConsultantRequest): Promise<UserConsultant> {
        const response = await this.partialUpdateUserConsultantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserConsultantRaw(requestParameters: UpdateUserConsultantRequest): Promise<runtime.ApiResponse<UserConsultant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserConsultant.');
        }

        if (requestParameters.userConsultant === null || requestParameters.userConsultant === undefined) {
            throw new runtime.RequiredError('userConsultant','Required parameter requestParameters.userConsultant was null or undefined when calling updateUserConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantToJSON(requestParameters.userConsultant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantFromJSON(jsonValue));
    }

    /**
     */
    async updateUserConsultant(requestParameters: UpdateUserConsultantRequest): Promise<UserConsultant> {
        const response = await this.updateUserConsultantRaw(requestParameters);
        return await response.value();
    }

}
