/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche,
    InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSON,
    InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSONTyped,
    InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheToJSON,
} from './';

/**
 * 
 * @export
 * @interface InformazioniSupplementari
 */
export interface InformazioniSupplementari {
    /**
     * 
     * @type {Array<InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche>}
     * @memberof InformazioniSupplementari
     */
    poteriCongiuntiOrInfoVisuraOrInfoGeneriche?: Array<InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche>;
}

export function InformazioniSupplementariFromJSON(json: any): InformazioniSupplementari {
    return InformazioniSupplementariFromJSONTyped(json, false);
}

export function InformazioniSupplementariFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformazioniSupplementari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'poteriCongiuntiOrInfoVisuraOrInfoGeneriche': !exists(json, 'poteriCongiuntiOrInfoVisuraOrInfoGeneriche') ? undefined : ((json['poteriCongiuntiOrInfoVisuraOrInfoGeneriche'] as Array<any>).map(InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSON)),
    };
}

export function InformazioniSupplementariToJSON(value?: InformazioniSupplementari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'poteriCongiuntiOrInfoVisuraOrInfoGeneriche': value.poteriCongiuntiOrInfoVisuraOrInfoGeneriche === undefined ? undefined : ((value.poteriCongiuntiOrInfoVisuraOrInfoGeneriche as Array<any>).map(InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheToJSON)),
    };
}


