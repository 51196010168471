import { NavigationHelper } from '@helpers/NavigationHelper';
import { customCompanyDataBlockResourceApi } from '@services/apis/ApiConfiguration';
import { CompanyDataBlockTypeEnum, ImportCompanyDataBlockRequest, Persona } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../../../../../types';
import { i18n } from '../../../../i18n/i18n'
import Icon from '../../../Tools/Icon';
import Colors from "../../../../../constants/Colors";
import { Table, Row } from 'react-native-table-component';
import hykeeStyle from '@components/hykeeStyle';
import { ErrorHelper } from '@helpers/ErrorHelper';
import AppContext from '../../../../navigation/AppContext';
import { BaseAPIManager } from "../../../../services/model/BaseAPIManager";


export default function AdministratorScreen({ navigation, route }: RootStackScreenProps<'AdministratorScreen'>) {
  const appContext = React.useContext(AppContext);
  const [rows, setRows] = React.useState<string[][]>([]);
  const [widthArr, setWidthArr] = React.useState<number[]>([])
  const [header, setHeader] = React.useState<JSX.Element[]>([])
  const [loading, setLoading] = React.useState<boolean>(true);

  function createHeader() {
    function createHeaderElement(value: string) {
      return <Text style={styles.textHead}>{value}</Text>
    }

    header.push(createHeaderElement(i18n.t("screens.administrator_screen.name")));
    header.push(createHeaderElement(i18n.t("screens.administrator_screen.surname")));
    header.push(createHeaderElement(i18n.t("screens.administrator_screen.taxCode")));
    header.push(createHeaderElement(i18n.t("screens.administrator_screen.qualification")));
    header.push(createHeaderElement(i18n.t("screens.administrator_screen.quote")));

    setHeader([...header])

    setWidthArr([130, 130, 150, 150, 100])
  }

  function parseRoles(persona: Persona): string[] {
    function createRoleElement(value: string, index: number) {
      return (
        <View key={index}>
          <Text style={[styles.text, hykeeStyle.mainFont]}>
            {value}
          </Text>
        </View>
      )
    }

    let qualifiche: any[] = []
    persona.attiConferimentoCariche?.attoConferimentoCariche.map((atto, index: number) => {
      atto.cariche?.carica!.map((carica) => {
        qualifiche.push(createRoleElement(carica.value!, index))
      })
    })
    return qualifiche
  }

  React.useEffect(() => {
    createHeader()
    let requestParameters = { companyDataBlock: { companyData: { id: route.params?.companyDataId }, type: CompanyDataBlockTypeEnum.Administrators } };
    BaseAPIManager.contextLoadingCallback(true);
    customCompanyDataBlockResourceApi.importCompanyDataBlock(requestParameters as ImportCompanyDataBlockRequest)
      .then((res) => {
        if (!res) {
          appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage("BALANCE_DOES_NOT_EXIST") })
          BaseAPIManager.contextLoadingCallback(false);
          return;
        }
        customCompanyDataBlockResourceApi.getCompanyDataBlockData({ id: res.id! }).then((res) => {
          res.personeSede?.persona?.map(async (persona, index: number) => {
            let row: any = []

            row.push(persona.personaFisica?.nome! ? persona.personaFisica?.nome! : "N/A")
            row.push(persona.personaFisica?.cognome! ? persona.personaFisica?.cognome! : "N/A")
            row.push(persona.personaFisica?.cfiscale! ? persona.personaFisica?.cfiscale! : "N/A")
            row.push(<View key={index} style={{ flexDirection: "column" }}>{parseRoles(persona)}</View>)
            row.push(persona.quota?.percentuale! ? persona.quota?.percentuale! : "N/A")

            rows.push(row)

            await setRows([...rows])
            setLoading(false)
            BaseAPIManager.contextLoadingCallback(false);
          })
        }).catch(async (error) => {
          setLoading(false)
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
        })

        setLoading(false)
      }).catch(async (error) => {
        setLoading(false)
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
      })
  }, [])

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />

      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={'#00CC83'} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t("screens.administrator_screen.title")}</Text>
      </View>



      <View style={styles.containerTable}>
        {loading &&
          <View style={styles.loadingView}>
            <Text style={styles.textHeader} >{i18n.t("screens.balance_sheet.loading")}</Text>
          </View>
        }
        <ScrollView horizontal={true}>
          <View>
            <Table>
              <Row
                data={header}
                widthArr={widthArr}
                style={{ height: 50, padding: 10, backgroundColor: '#494949' }}
                textStyle={[{ margin: 5, fontSize: 12, textAlign: 'left', fontWeight: '100', color: "white" }, hykeeStyle.mainFont]} />
            </Table>
            <ScrollView style={styles.dataWrapper}>
              <Table>
                {
                  rows && rows.map((rowData, index) => {
                    return (
                      <Row
                        key={index}
                        data={rowData}
                        widthArr={widthArr}
                        style={[index % 2 == 0 ? { backgroundColor: '#292929' } : { backgroundColor: '#343434' }, index == rows.length - 1 ? { marginBottom: 40 } : null]}
                        textStyle={[{ margin: 5, padding: 10, fontSize: 12, textAlign: 'left', fontWeight: '100', color: "white" }, hykeeStyle.mainFont]}
                      />
                    )
                  })
                }
              </Table>
            </ScrollView>
          </View>
        </ScrollView>
      </View>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  data: {
    padding: 3,
    marginLeft: 10,
    alignSelf: 'center'
  },
  textHeader: {
    marginVertical: 3,
    fontSize: 14,
    fontWeight: "bold",
    color: 'white',
    fontFamily: "poppins",
  },
  tableHeaderCol: {
    padding: 3,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#494949",
    justifyContent: "center"
  },
  head: { height: 40, backgroundColor: 'rgb(42, 42, 42)' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, height: 40, backgroundColor: 'rgb(42, 42, 42)' },
  textHead: { margin: 5, fontWeight: "bold", fontSize: 13, textAlign: "left", color: "white" },
  textNegative: { margin: 5, fontSize: 12, fontWeight: '100', textAlign: 'center', color: '#CA3838' },
  textFirstCol: { textAlign: 'center', color: 'white', fontWeight: 'bold' },
  containerTable: { flex: 1, marginHorizontal: 20, marginBottom: 30 },
  header: { height: 50, backgroundColor: '#494949' },
  text: { margin: 5, fontSize: 12, textAlign: 'left', fontWeight: '100', color: "white" },
  dataWrapper: { marginTop: -1 },
  rowTable: { height: 60, backgroundColor: '#2B2B2B' },
  loadingView: { alignContent: "center", alignSelf: "center", margin: 20 },
});




