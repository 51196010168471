import React from "react";
import {View, Text} from "react-native"
import Svg, {Path} from "react-native-svg"


interface LogoProps{
    width?: number,
    height?: number,
}

const Logo = (props:LogoProps) => {
    return(
        <Svg width={props.width} height={props.height} viewBox="0 0 109 35" fill="none" >
            <Path d="M81.2898 28.795C80.9057 28.795 80.5944 28.4837 80.5944 28.0996V25.6877C80.5944 25.3037 80.9057 24.9924 81.2898 24.9924H87.3405C87.7246 24.9924 88.0359 24.681 88.0359 24.297V20.6722C88.0359 20.2881 87.7246 19.9768 87.3405 19.9768H81.2898C80.9057 19.9768 80.5944 19.6655 80.5944 19.2814V17.1318C80.5944 16.7477 80.9057 16.4364 81.2898 16.4364H88.385C88.769 16.4364 89.0803 16.1251 89.0803 15.741V11.8212C89.0803 11.4371 88.769 11.1258 88.385 11.1258H75.7086C75.3246 11.1258 75.0132 11.4371 75.0132 11.8212V33.3775C75.0132 33.7615 75.3246 34.0728 75.7086 34.0728H88.6787C89.0628 34.0728 89.3741 33.7615 89.3741 33.3775V29.4904C89.3741 29.1063 89.0628 28.795 88.6787 28.795H81.2898Z" fill="white"/>
            <Path d="M100.916 28.795C100.532 28.795 100.22 28.4837 100.22 28.0996V25.6877C100.22 25.3037 100.532 24.9924 100.916 24.9924H106.966C107.35 24.9924 107.662 24.681 107.662 24.297V20.6722C107.662 20.2881 107.35 19.9768 106.966 19.9768H100.916C100.532 19.9768 100.22 19.6655 100.22 19.2814V17.1318C100.22 16.7477 100.532 16.4364 100.916 16.4364H108.011C108.395 16.4364 108.706 16.1251 108.706 15.741V11.8212C108.706 11.4371 108.395 11.1258 108.011 11.1258H95.3345C94.9505 11.1258 94.6392 11.4371 94.6392 11.8212V33.3775C94.6392 33.7615 94.9505 34.0728 95.3345 34.0728H108.305C108.689 34.0728 109 33.7615 109 33.3775V29.4904C109 29.1063 108.689 28.795 108.305 28.795H100.916Z" fill="white"/>
            <Path d="M67.3822 34.0728C67.9674 34.0728 68.2909 33.3941 67.9224 32.9396L60.2422 23.4652C60.1102 23.3024 59.9117 23.2078 59.702 23.2078H54.8021C54.6039 23.2078 54.4151 23.1232 54.2831 22.9753L49.8898 18.05C49.4645 17.5733 48.6755 17.8741 48.6755 18.5129V33.3775C48.6755 33.7615 48.9868 34.0728 49.3709 34.0728H53.796C54.1797 34.0728 54.4909 33.762 54.4913 33.3783L54.4979 27.6628C54.4987 27.0298 55.2761 26.7269 55.7049 27.1926L61.8333 33.8485C61.9649 33.9915 62.1504 34.0728 62.3448 34.0728H67.3822Z" fill="white"/>
            <Path d="M37.599 25.6303C37.599 25.5129 37.5693 25.3974 37.5126 25.2945L29.8973 11.4854C29.775 11.2635 29.5417 11.1258 29.2884 11.1258H24.8485C24.3129 11.1258 23.9784 11.706 24.2468 12.1696L31.8438 25.2896C31.9051 25.3955 31.9374 25.5157 31.9374 25.6381V33.3775C31.9374 33.7615 32.2487 34.0728 32.6327 34.0728H36.9037C37.2877 34.0728 37.599 33.7615 37.599 33.3775V25.6303Z" fill="white"/>
            <Path d="M15.0752 11.1258C14.6911 11.1258 14.3798 11.4371 14.3798 11.8212V19.4145C14.3798 19.7986 14.0685 20.1099 13.6844 20.1099H6.48112C6.09708 20.1099 5.78576 19.7986 5.78576 19.4145V11.8212C5.78576 11.4371 5.47443 11.1258 5.09039 11.1258H0.695365C0.311326 11.1258 0 11.4371 0 11.8212V34.0728C0 34.4569 0.311325 34.7682 0.695364 34.7682H5.09039C5.47443 34.7682 5.78576 34.4569 5.78576 34.0728V26.2768C5.78576 25.8927 6.09708 25.5814 6.48112 25.5814H13.6844C14.0685 25.5814 14.3798 25.8928 14.3798 26.2768V34.0728C14.3798 34.4569 14.6911 34.7682 15.0752 34.7682H19.4702C19.8542 34.7682 20.1656 34.4569 20.1656 34.0728V11.8212C20.1656 11.4371 19.8542 11.1258 19.4702 11.1258H15.0752Z" fill="white"/>
            <Path d="M77.1851 2.28105C77.1957 2.88261 76.9713 3.46481 76.5591 3.90498L60.2246 20.1591C59.787 20.5939 59.1939 20.8382 58.5755 20.8382C57.9572 20.8382 57.3641 20.5939 56.9265 20.1591L49.2415 12.5119L40.7786 20.9331C40.3363 21.3433 39.7512 21.5665 39.1467 21.5559C38.5421 21.5453 37.9653 21.3016 37.5378 20.8762C37.1102 20.4508 36.8653 19.8768 36.8547 19.2752C36.844 18.6737 37.0684 18.0915 37.4806 17.6513L47.5925 7.58925C48.03 7.15441 48.6231 6.91017 49.2415 6.91017C49.8599 6.91017 50.453 7.15441 50.8905 7.58925L58.5755 15.2364L73.2611 0.623203C73.7035 0.213043 74.2886 -0.0102521 74.8931 0.000361761C75.4976 0.0109756 76.0744 0.254669 76.502 0.680103C76.9295 1.10554 77.1744 1.67949 77.1851 2.28105Z" fill="#00CD78"/>
        </Svg>
    )
}
export default Logo