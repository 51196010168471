import hykeeStyle from '@components/hykeeStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import { useFocusEffect } from '@react-navigation/native';
import {customCompanyResourceApi, customCompanySavedResourceApi} from '@services/apis/ApiConfiguration';
import {
    Company,
    CompanyLinkRequest,
    CompanySaved,
    UserConsultantInviteCurrentStateEnum
} from '@services/apis/generated';
import React, { useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';

export default function FavoriteCompanies(props: { goToCompany: (companySaved: CompanySaved) => void }) {
    const sharedContext = React.useContext(SharedContext);


    useFocusEffect(
        useCallback(() => {
            checkFavorite();
        }, [])
    );

    /**
    * Method is used to check if the Company is present in the favorite list.
    */
    const checkFavorite = (): void => {
        customCompanySavedResourceApi.getAllCompaniesSavedFilter({ customCompanySavedCriteria: { userId: { equals: sharedContext.userMe?.id } } }).then((csaved: CompanySaved[]) => {
            sharedContext.setCompaniesSaved(csaved);
        }).catch(error => AlertHelper.showSimpleErrorAlert());
    }

    function scoreVisibility(companySaved: CompanySaved) {

        let companySav =  sharedContext.myConsultedsForConsultant?.find(x => x.id == companySaved.company?.id);
        if (companySav == null) {
            companySaved.companyData?.businessScore ? companySaved.companyData.businessScore = -1 : null;
            companySaved.companyData?.hykeeScore! ? companySaved.companyData.hykeeScore = -1 : null;
            return companySaved;
        }

        return companySaved
    }

    const hideScore = (companySaved: CompanySaved): CompanySaved => {
        let companySav: CompanyLinkRequest = sharedContext.myCompaniesConsultedRequest?.find((req) => req.company?.id == companySaved.company?.id) as CompanySaved;
        if (!companySav || companySav.currentState != UserConsultantInviteCurrentStateEnum.Accepted as string) {
            companySaved.companyData?.businessScore ? companySaved.companyData.businessScore = -1 : null;
            companySaved.companyData?.hykeeScore! ? companySaved.companyData.hykeeScore = -1 : null;
            return companySaved;
        }
        return companySaved
    }
    
    function truncate (word: string, length: number) {
        return word.length <= length ? word : word.substring(0, length) + "..."
    }

    return (

        <ScrollView horizontal={true}>
        <View style={styles.col}>
            <Text style={styles.title}>
                {i18n.t('screens.saved_companies.title')}
            </Text>
            <View style={styles.header}>
                <Text style={styles.textheaderName}>
                    {i18n.t('screens.saved_companies.header_name')}
                </Text>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.p_iva_partners')}
                </Text>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.header_financial')}
                </Text>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.header_business')}
                </Text>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.header_hykee')}
                </Text>
            </View>
            {sharedContext.companiesSaved?.length == 0 &&
                <View style={styles.row}>
                    <Text style={styles.textheaderNoCompaniesSearched}>
                        {i18n.t('screens.saved_companies.no_companies_saved')}
                    </Text>
                </View>
            }

            <ScrollView nestedScrollEnabled style={hykeeStyle.scrollViewMH}>
                {sharedContext.companiesSaved &&
                    sharedContext.companiesSaved?.map((companySaved, index: number) => {
                        let companySavedChecked = scoreVisibility(companySaved);
                        return (
                            <TouchableOpacity
                                key={index.toString()}
                                style={index % 2 == 1 ? [styles.row, { backgroundColor: '#292929' }] : [styles.row, { backgroundColor: '#303030' }]}
                                onPress={() => {
                                    props.goToCompany(companySaved)
                                }}
                            >
                                <Text numberOfLines={1} style={styles.textheaderName2}>
                                    {truncate(String(companySaved.company?.name), 15)}
                                </Text>
                                <Text numberOfLines={1} adjustsFontSizeToFit style={styles.textheaderIva}>
                                    {companySavedChecked.companyData?.fiscalCode}
                                </Text>
                                <Text numberOfLines={1} style={styles.textheader}>
                                    {companySavedChecked.companyData?.financialScore == undefined
                                        ? '--' : companySavedChecked.companyData?.financialScore + '%'}
                                </Text>
                                <Text numberOfLines={1} style={styles.textheader}>
                                    {companySavedChecked.companyData?.businessScore == -1 || companySavedChecked.companyData?.businessScore == undefined
                                        ? '--' : companySavedChecked.companyData?.businessScore?.toFixed(1) + '%'}
                                </Text>
                                <Text numberOfLines={1} style={styles.textheader}>
                                    {companySavedChecked.companyData?.hykeeScore == -1 || companySavedChecked.companyData?.hykeeScore == undefined
                                        ? '--' : companySavedChecked.companyData?.hykeeScore + '%'}
                                </Text>

                            </TouchableOpacity>
                        )
                    })
                }
            </ScrollView>
        </View>
        </ScrollView>
    );



}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        backgroundColor: '#292929',
        alignItems: 'center'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#404040',
    },
    textheaderName: {
        flex: 0.4,
        textAlign: 'center',
        fontSize: 12,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        width: 85,
        marginRight: 10
    },
    textheaderName2: {
        flex: 0.4,
        textAlign: 'center',
        fontSize: 11,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        width: 75,
        marginRight: 10
    },
    textheader: {
        flex: 0.2,
        textAlign: 'center',
        fontSize: 12,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        width: 80,
    },
    textheaderIva: {
        flex: 0.2,
        textAlign: 'center',
        fontSize: 10,
        padding: 8,
        width: 80,
        // marginLeft: 10,
        fontFamily: 'poppins',
        color: 'white',

    },
    textheaderNoCompaniesSearched: {
        flex: 1,
        textAlign: 'center',
        fontSize: 14,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white'
    },
    title: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
    },
    textUnderTable: {
        alignSelf: 'flex-start',
        fontSize: 13,
        paddingHorizontal: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    textLabel: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    col: {
        flexDirection: 'column',
        marginBottom: 30
    },
})