/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComposizioneQuote
 */
export interface ComposizioneQuote {
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    valuta?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    valoreNominale?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    valoreUnitario?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    ammontareConvertitoInEuro?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    valoreVersato?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    cvaluta?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    nazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    nquote?: string;
    /**
     * 
     * @type {string}
     * @memberof ComposizioneQuote
     */
    ctipo?: string;
}

export function ComposizioneQuoteFromJSON(json: any): ComposizioneQuote {
    return ComposizioneQuoteFromJSONTyped(json, false);
}

export function ComposizioneQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComposizioneQuote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'valuta': !exists(json, 'valuta') ? undefined : json['valuta'],
        'valoreNominale': !exists(json, 'valoreNominale') ? undefined : json['valoreNominale'],
        'valoreUnitario': !exists(json, 'valoreUnitario') ? undefined : json['valoreUnitario'],
        'ammontareConvertitoInEuro': !exists(json, 'ammontareConvertitoInEuro') ? undefined : json['ammontareConvertitoInEuro'],
        'valoreVersato': !exists(json, 'valoreVersato') ? undefined : json['valoreVersato'],
        'cvaluta': !exists(json, 'cvaluta') ? undefined : json['cvaluta'],
        'nazioni': !exists(json, 'nazioni') ? undefined : json['nazioni'],
        'nquote': !exists(json, 'nquote') ? undefined : json['nquote'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function ComposizioneQuoteToJSON(value?: ComposizioneQuote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'n': value.n,
        'valuta': value.valuta,
        'valoreNominale': value.valoreNominale,
        'valoreUnitario': value.valoreUnitario,
        'ammontareConvertitoInEuro': value.ammontareConvertitoInEuro,
        'valoreVersato': value.valoreVersato,
        'cvaluta': value.cvaluta,
        'nazioni': value.nazioni,
        'nquote': value.nquote,
        'ctipo': value.ctipo,
    };
}


