// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import { customUserConsultantInviteResourceApi } from '@services/apis/ApiConfiguration';
import { UserConsultantInvite, UserConsultantInviteCurrentStateEnum, UserConsultantInviteStateStateEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import React, { } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { i18n } from '../../../i18n/i18n'
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';



export default function RequestComponent(props: { request: UserConsultantInvite, refreshRequestList: () => void, isUser: boolean, }) {


  const sharedContext = React.useContext(SharedContext)

  const rejectRequest = (userConsultantInvite: UserConsultantInvite) => {
    customUserConsultantInviteResourceApi.updateUserConsultantInviteState1({ id: userConsultantInvite.id!, userConsultantInviteState: { state: UserConsultantInviteStateStateEnum.Rejected } })
      .then((response) => {
        if (response)
          props.refreshRequestList()
      })
      .catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const acceptRequest = (userConsultantInvite: UserConsultantInvite) => {
    customUserConsultantInviteResourceApi.updateUserConsultantInviteState1({ id: userConsultantInvite.id!, userConsultantInviteState: { state: UserConsultantInviteStateStateEnum.Accepted } })
      .then((response) => {
        if (response)
          props.refreshRequestList()
      })
      .catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const removeRequest = () => {
    customUserConsultantInviteResourceApi.deleteUserConsultantInviteForLoggedUser({ id: props.request.id! }).then((res) => {
      props.refreshRequestList();
    })
  }


  return (
    <LinearGradient
      key={props.request.consultant?.id}
      colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
      locations={[0.4, 0.8]}
      start={{ x: 1, y: 1 }}
      end={{ x: 0, y: 0 }}
      style={styles.borderGradient}
    >
      <LinearGradient
        colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
        start={{ x: 1, y: 1 }}
        end={{ x: 0, y: 0 }}
        style={styles.containerBack}
      >

        <View style={hykeeStyle.col} >
          <View style={hykeeStyle.col}>
            <View style={hykeeStyle.row}>
              <Text style={[hykeeStyle.textLabel, { flex: 0.5 }]}>
                {!props.isUser ? (props.request.user?.firstName + ' ' + props.request.user?.lastName) : props.request.consultant?.name}
              </Text>
              <Text style={[hykeeStyle.textLabel, { flex: 0.5 }]}>
                {i18n.t('components.link_companies_data.role') + props.request.role}
              </Text>
              {/* <View style={styles.crossIcon}>
                <TouchableOpacity
                onPress={()=>removeRequest()}
                >
                  <Icon name={'remove'} color={'white'} />
                </TouchableOpacity>
              </View> */}
            </View>
            <View style={styles.contentLocation}>
              <Icon name={'calendar'} color={'#00CC83'} />
              <Text style={styles.textUnderTable}>
                {props.request.date?.toDateString()}
              </Text>
              <Icon name={'link'} color={'#00CC83'} />
              <Text style={styles.textUnderTable}>
                {i18n.t('components.link_companies_data.status') + props.request.currentState}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            {props.isUser && props.request.currentState == UserConsultantInviteCurrentStateEnum.Pending &&
              <TouchableOpacity style={[hykeeStyle.buttonAccept, { flex: 0.45 }]}
                onPress={() => {
                  acceptRequest(props.request)
                }}>
                <Text style={styles.textButton}>
                  {i18n.t('components.link_companies_data.button_accepted')}
                </Text>
              </TouchableOpacity>}
            {props.request.currentState == UserConsultantInviteCurrentStateEnum.Pending &&
              <TouchableOpacity style={[hykeeStyle.buttonReject, props.isUser ? { flex: 0.45 } : { flex: 0.9 }]}
                onPress={() => {
                  rejectRequest(props.request)
                }}>
                <Text style={styles.textButton}>
                  {i18n.t('components.link_companies_data.button_deleted')}
                </Text>
              </TouchableOpacity>}
          </View>
        </View>
      </LinearGradient>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  borderGradient: {
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  containerList: {
    marginBottom: 20,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 10,
  },
  textButton: {
    color: "white",
    fontFamily: "poppins",
    fontSize: 13,
    textAlign: 'center',
    padding: 5,
    paddingVertical: 10,
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  containerBack: {
    fontFamily: 'poppins',
    padding: 8,
    margin: 1,
    borderRadius: 13,
    overflow: 'hidden'
  },
  crossIcon: {
    position: 'relative',
    top: 5,
    right: 5,
  },
  contentLocation: {
    flexDirection: 'row',
    alignContent: 'center',
    padding: 5,
  },
  textUnderTable: {
    alignSelf: 'flex-start',
    fontSize: 13,
    flex: 0.5,
    paddingHorizontal: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  contentImg: {
    flex: 0.3,
  },
});


