/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StrumentiFinanziari
 */
export interface StrumentiFinanziari {
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    azioniOrdinarie?: string;
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    altreAzioni?: string;
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    obbligazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    obbligazioniConvertibili?: string;
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    titoliDebito?: string;
    /**
     * 
     * @type {string}
     * @memberof StrumentiFinanziari
     */
    altriStrumenti?: string;
}

export function StrumentiFinanziariFromJSON(json: any): StrumentiFinanziari {
    return StrumentiFinanziariFromJSONTyped(json, false);
}

export function StrumentiFinanziariFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrumentiFinanziari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'azioniOrdinarie': !exists(json, 'azioniOrdinarie') ? undefined : json['azioniOrdinarie'],
        'altreAzioni': !exists(json, 'altreAzioni') ? undefined : json['altreAzioni'],
        'obbligazioni': !exists(json, 'obbligazioni') ? undefined : json['obbligazioni'],
        'obbligazioniConvertibili': !exists(json, 'obbligazioniConvertibili') ? undefined : json['obbligazioniConvertibili'],
        'titoliDebito': !exists(json, 'titoliDebito') ? undefined : json['titoliDebito'],
        'altriStrumenti': !exists(json, 'altriStrumenti') ? undefined : json['altriStrumenti'],
    };
}

export function StrumentiFinanziariToJSON(value?: StrumentiFinanziari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'azioniOrdinarie': value.azioniOrdinarie,
        'altreAzioni': value.altreAzioni,
        'obbligazioni': value.obbligazioni,
        'obbligazioniConvertibili': value.obbligazioniConvertibili,
        'titoliDebito': value.titoliDebito,
        'altriStrumenti': value.altriStrumenti,
    };
}


