/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserConsultantInvite,
    UserConsultantInviteFromJSON,
    UserConsultantInviteFromJSONTyped,
    UserConsultantInviteToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserConsultantInviteState
 */
export interface UserConsultantInviteState {
    /**
     * 
     * @type {number}
     * @memberof UserConsultantInviteState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserConsultantInviteState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInviteState
     */
    state?: UserConsultantInviteStateStateEnum;
    /**
     * 
     * @type {UserConsultantInvite}
     * @memberof UserConsultantInviteState
     */
    userConsultantInvite?: UserConsultantInvite;
}

/**
* @export
* @enum {string}
*/
export enum UserConsultantInviteStateStateEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}

export function UserConsultantInviteStateFromJSON(json: any): UserConsultantInviteState {
    return UserConsultantInviteStateFromJSONTyped(json, false);
}

export function UserConsultantInviteStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConsultantInviteState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'userConsultantInvite': !exists(json, 'userConsultantInvite') ? undefined : UserConsultantInviteFromJSON(json['userConsultantInvite']),
    };
}

export function UserConsultantInviteStateToJSON(value?: UserConsultantInviteState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'userConsultantInvite': UserConsultantInviteToJSON(value.userConsultantInvite),
    };
}


