/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { StatusBar } from 'expo-status-bar';
import { Dimensions, StyleSheet, View, } from 'react-native';
import { DownloadedBalance, RootStackScreenProps, State } from '../../types';
import SearchScreen from '../screens/Dashboard/SearchScreen';
import { LinearGradient } from 'expo-linear-gradient';
import SavedCompaniesScreen from '../screens/Dashboard/SavedCompaniesScreen';
import CreditsNavigator from '../screens/Dashboard/CreditsNavigator';
import DashboardScreen from '../screens/Dashboard/DashboardScreen';
import ResearchScreen from '../screens/Dashboard/ResearchScreen';
import SearchIcon from '../../assets/svg/SearchIcon';
import {
  Company,
  CompanyBalanceSheet,
  CompanyData,
  CompanyLinkRequest,
  CompanySaved,
  CustomDataBlockUserResourceApi,
  DataBlockUser,
  DataBlockUserDTO,
  GetAllCompanyLinkRequestsByCompanyRequest,
  MarketCompetitor,
  MarketCompetitorResponseDTO,
  User,
  UserExtended,
  UserExtendedTypeEnum,
  UserPlan
} from '@services/apis/generated';
import ProfileScreen from '../screens/Dashboard/ProfileScreen';
import SharedContext, { SharedContextType } from './SharedContext';
import {
  customCompanyDataResourceApi,
  customCompanyLinkRequestResourceApi, customCompanyResourceApi,
  customCompanySavedResourceApi,
  customDataBlockUserResourceApi,
  customMarketCompetitorResourceApi,
  customNotificationResourceApi,
  customUserCompanyResourceApi,
  customUserConsultantResourceApi,
  customUserExtendedResourceApi,
  customUserPlanResourceApi,
  customUserResourceApi
} from '@services/apis/ApiConfiguration';
import { AlertHelper } from '@helpers/AlertHelper';
import { useCallback, useEffect, useState } from 'react';
import { Logger } from '@helpers/Logger';
import { useFocusEffect } from '@react-navigation/native';
import Colors from "../../constants/Colors";
import Icon from '@screens/Tools/Icon';
import { Circle } from 'react-native-svg';
import { BackgroundColorButtonGreyLight } from '@components/ColorTheme';
import { PlatformHelper } from '@helpers/PlatformHelper';
import Navigation from './Navigation';
import { NavigationHelper } from '@helpers/NavigationHelper';
import NotificationPage from "@screens/Dashboard/ComponentProfile/NotificationPage";
import * as StorageHelper from "@helpers/StorageHelper";
import {delay} from "@helpers/Sleep";
import {BaseAPIManager} from "@services/model/BaseAPIManager";


const BottomTabIntermdiaries = createBottomTabNavigator();

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
export default function BottomTabNavigator({ navigation, route }: RootStackScreenProps<'BottomTabNavigator'>) {

  const userExtended = route.params as UserExtended;
  const sharedContext = React.useContext(SharedContext);

  const [userExtendedMe, setUserExtendedMe] = useState<UserExtended>({});
  const [myCompanies, setMyCompanies] = useState<Company[]>([]);
  const [myCompanySelected, setMyCompanySelected] = useState<Company>();
  const [mySelectedCompanyLastData, setMySelectedCompanyLastData] = useState<CompanyData>();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [selectedCompanyLastData, setSelectedCompanyLastData] = useState<CompanyData>();
  const [who, setWho] = useState<UserExtendedTypeEnum>();
  const [companiesSaved, setCompaniesSaved] = React.useState<CompanySaved[]>([]);
  const [companyFavorite, setCompanyFavorite] = React.useState<CompanySaved>();
  const [myCompaniesConsultedRequest, setMyCompaniesConsultedRequest] = useState<CompanyLinkRequest[]>([]);
  const [myCompaniesConsultantRequest, setMyCompaniesConsultantRequest,] = useState<CompanyLinkRequest[]>([]);
  const [myMarketCompetitors, setMyMarketCompetitors] = useState<MarketCompetitorResponseDTO[]>([]);
  const [myUserPlans, setMyUserPlans] = useState<UserPlan[]>([]);
  const [userMe, setUserMe] = useState<User>();
  const [lastBalance, setLastBalance] = useState<CompanyBalanceSheet>({});
  const [myDataBlock, setMyDataBlock] = useState<DataBlockUserDTO[]>([]);
  const [revenue, setRevenue] = useState<number>(0);
  const [downloadedBalances, setDownloadedBalances] = useState<DownloadedBalance[]>([]);
  const [notificationCheck, setNotificationCheck] = useState<Boolean>(false);
  const [linkRequestCheck, setLinkRequestCheck] = useState<Boolean>(false);
  const [linkButton, setLinkButton] = useState<Boolean>(false);
  const [notifications, setNotifications] = useState<boolean>(false);
  const [myConsultedsForConsultant, setMyConsultedsForConsultant] = useState<Company[]>([]);

  useEffect(() => {
    if (who != UserExtendedTypeEnum.Consultant) {
      const interval = setInterval(() => {
        checkNotifications()
        checkLinkRequest(myCompanies[0]?.id!)
      }, time);
      return () => clearInterval(interval);
    }
  }, [who, myCompanies]);

  useEffect(() => {
    if (who)
      setup();
    setupUser();
  }, [who]);

  useEffect(() => {
    checkNotifications()
  }, []);

  const time = 10000;

  function checkNotifications(this: any) {
    customNotificationResourceApi.getNotificationCount().then((res) => {
      res > 0 ? setNotificationCheck(true) : setNotificationCheck(false)
    })
  }

  function checkLinkRequest(companyId: number) {
    let getAllCompanyLinkRequestsByCompanyRequest: GetAllCompanyLinkRequestsByCompanyRequest = {
      id: companyId,
    }
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany(getAllCompanyLinkRequestsByCompanyRequest).then((res) => {
      //res.length > 0 ? setLinkRequestCheck(true) : setLinkRequestCheck(false)
      let linkNum = 0
      for (let i = 0; i < res.length; i++) {
        if (res[i].currentState == 'PENDING') {
          linkNum++
        }
      }
      linkNum > 0 ? setLinkRequestCheck(true) : setLinkRequestCheck(false)
    })
  }

  const setupUser = () => {
    customUserResourceApi.getMe().then((userMeRes) => {
      setUserMe(userMeRes);
      if (userMeRes.userExtended) {
        setUserExtendedMe(userMeRes.userExtended!);
        setWho(userMeRes.userExtended!.type);
      }
      customUserPlanResourceApi.getUserPlansByLoggedUser().then((myUserPlans) => {
        setMyUserPlans(myUserPlans);

        customDataBlockUserResourceApi.getAllDataBlockUsersByLoggedUser().then((myDataBlocks) => {
          setMyDataBlock(myDataBlocks);
        })
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const myroute: State = {
    listFavorite: [
    ], availableCredits: "0",
    usedCredits: "0"
  }

  const setup = (id?: number): void => {
    if (who == UserExtendedTypeEnum.Company) {
      customUserCompanyResourceApi.getAllMyUserCompanies().then(response => {
        let mappedCompanies = response.map(uc => uc.company!);
        setMyCompanies(mappedCompanies);
        if (mappedCompanies[0] != undefined) {

          if (id) {
            getLastCompanyData(id, mappedCompanies, true);
          } else {
            let company = mappedCompanies[0];
            checkLinkRequest(company.id!)
            getLastCompanyData(company.id!, mappedCompanies);
            getMyCompanyConsultant(company.id!);
            getMyMarketCompetitors(company.id!);

          }
        }

      }).catch(error => AlertHelper.showSimpleErrorAlert());
    }
    else {
      customUserResourceApi.getMe().then((userMe) => {
        customUserConsultantResourceApi.getConsultantByUserId({ userId: userMe.id! }).then(userConsultant => {
          let mappedCompanies = userConsultant.map(uc => uc.consultant!)
          setMyCompanies(mappedCompanies);

          if (mappedCompanies[0] != undefined) {
            if (id) {
              getLastCompanyData(id, mappedCompanies, true);
            } else {
              let company = mappedCompanies[0];
              getLastCompanyData(company.id!, mappedCompanies);
              getMyCompanyConsulted(company.id!);
              getMyMarketCompetitors(company.id!);
              getLinkedCompanies(company);
            }
          }
        }).catch(error => AlertHelper.showSimpleErrorAlert());
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    }
  }

  const getLastCompanyData = (id: number, allMyCompanies: Company[], search?: boolean): void => {
    customCompanyDataResourceApi.getLastCompanyDataByCompany({ companyId: id }).then(res => {
      setSelectedCompany(res.company);
      setMyCompanySelected(res.company);
      setMySelectedCompanyLastData(res as CompanyData);
      checkFavorite(res as CompanyData);
      //getLinkedCompanies(res.company!)
    }).catch(error => Logger.warn('Dashboard', `Company data not found for company ${id}`));
  }

  const getMyCompanyConsulted = (id: number): void => {
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByConsultant({ id: id }).then(res => {
      setMyCompaniesConsultedRequest(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());


  }

  const getMyCompanyConsultant = (id: number): void => {
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: id }).then(res => {
      setMyCompaniesConsultantRequest(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const getLinkedCompanies = (company: Company): void => {
    customCompanyResourceApi.getAllCompaniesConsultedsByConsultant({ company: company }).then(res => {
      setMyConsultedsForConsultant(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const getMyMarketCompetitors = (companyId: number): void => {
    customMarketCompetitorResourceApi.getAllMyMarketCompetitors().then((myMarketCompetitors) => {
      console.log(myMarketCompetitors)
      if (myMarketCompetitors)
        setMyMarketCompetitors(myMarketCompetitors);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }


  /**
   * Method is used to check if the Company is present in the favorite list.
   * @param companyInformation shows all information on the loaded company;
   */
  const checkFavorite = (companyInformation: CompanyData): void => {
    customCompanySavedResourceApi.getAllCompaniesSavedFilter({ customCompanySavedCriteria: { userId: { equals: userMe?.id } } }).then((csaved: CompanySaved[]) => {
      setCompaniesSaved(csaved);
      let companyVatNumber = companyInformation.company?.vatNumber;
      let foundCompanySaved = csaved.find((favorite) => favorite.company?.vatNumber == companyVatNumber);
      setCompanyFavorite(foundCompanySaved);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  function circleVisible() {
    if (notificationCheck || linkRequestCheck) {
      return true
    } else {
      return false
    }
  }

  return (
      <SharedContext.Provider value={{
        userMe, setUserMe,
        userExtendedMe, setUserExtendedMe,
        myCompanies, setMyCompanies,
        myCompanySelected, setMyCompanySelected,
        mySelectedCompanyLastData, setMySelectedCompanyLastData,
        myCompaniesConsultedRequest, setMyCompaniesConsultedRequest,
        myCompaniesConsultantRequest, setMyCompaniesConsultantRequest,
        selectedCompany, setSelectedCompany,
        selectedCompanyLastData, setSelectedCompanyLastData,
        companiesSaved, setCompaniesSaved,
        companyFavorite, setCompanyFavorite,
        who, setWho,
        myMarketCompetitors, setMyMarketCompetitors,
        myUserPlans, setMyUserPlans,
        lastBalance, setLastBalance,
        revenue, setRevenue,
        myDataBlock, setMyDataBlock,
        downloadedBalances, setDownloadedBalances,
        notificationCheck, setNotificationCheck,
        myConsultedsForConsultant, setMyConsultedsForConsultant,
        linkRequestCheck, setLinkRequestCheck,
        linkButton, setLinkButton,
      }}>
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
          <StatusBar style="inverted" />
          <BottomTabIntermdiaries.Navigator
              initialRouteName={'DashboardScreen'}
              //tabBar={()=>(<TabBar/>)}

              screenOptions={{
                tabBarStyle: styles.tabBar,
              }}>
            <BottomTabIntermdiaries.Screen
                name={'DashboardScreen'}
                component={DashboardScreen}
                initialParams={{ who }}
                listeners={{
                  tabPress:()=>{
                    navigation.popToTop()
                  }
                }}
                options={{
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarIcon: (event: any) => <TabBarIcon name="home" color={event.focused? "#00CC83" :"#CDCDCD"}/>,

                }}
            />
            <BottomTabIntermdiaries.Screen
                name="Credits"
                component={CreditsNavigator}
                initialParams={myroute}
                listeners={{
                  tabPress:()=>{
                    navigation.popToTop()
                  }
                }}
                options={{
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarIcon: (event: any) => <Icon name="wallet" size={25} type="FontAwesome5" color={event.focused? "#00CC83" :"#CDCDCD"} />,
                }}
            />
            <BottomTabIntermdiaries.Screen
                name="SearchScreen"
                component={SearchScreen}
                initialParams={myroute}
                options={() => (
                    {
                      headerShown: false,
                      tabBarShowLabel: false,
                      tabBarHideOnKeyboard: true,
                      tabBarIcon: (event: any) => <TabBarIcon search={true} name="search" color={event.focused? "#00CC83" :"#CDCDCD"} />,
                    })}
            />
            <BottomTabIntermdiaries.Screen
                name="SavedCompaniesScreen"
                component={SavedCompaniesScreen}
                initialParams={{ userType: who }}
                listeners={{
                  tabPress:()=>{
                    navigation.popToTop()
                  }
                }}
                options={{
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarIcon: (event: any) => <TabBarIcon name="star" color={event.focused? "#00CC83" :"#CDCDCD"} />,
                }}
            />
            <BottomTabIntermdiaries.Screen
                name="ProfileScreen"
                component={ProfileScreen}
                initialParams={myroute}
                listeners={{
                  tabPress:()=>{
                    navigation.popToTop()
                  }
                }}
                options={{
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarIcon: (event: any) => <TabBarIcon name="user" color={event.focused? "#00CC83" :"#CDCDCD"} notifications={circleVisible()} />,
                }}
            />
          </BottomTabIntermdiaries.Navigator>
        </LinearGradient>
      </SharedContext.Provider>
  );
}



/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
  search?: Boolean;
  notifications?: Boolean;
}) {
  return (
      <>
        {!props.search && (<FontAwesome size={30} style={{ marginBottom: -3, position: 'absolute' }} {...props} />)}
        {!props.search && props.notifications && (<FontAwesome name="circle" size={12} color="red" style={{marginLeft: 10, marginBottom: 15, zIndex: 99999}}/>)}
        {props.search && (
            <View style={{ position: 'absolute', bottom: 15 }}>
              <SearchIcon width={80} height={68} />
            </View>
        )}
      </>
  )
}

const styles = StyleSheet.create({
  containerSA: {
    display: 'flex',
    flex: 1,
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  tabBar: {
    backgroundColor: '#222222',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25
  },
  global: {
    fontFamily: 'poppins',
  },
});


