import { BackgroundColorButtonGreen } from "@components/ColorTheme";
import { AlertHelper } from "@helpers/AlertHelper";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
    customCompanyLinkRequestResourceApi,
    customCompanyResourceApi,
    customNotificationResourceApi
} from "@services/apis/ApiConfiguration";
import {
    Company,
    CompanyData,
    CompanyLinkRequest,
    CompanyLinkRequestCurrentStateEnum,
    Notification, NotificationTypeEnum,
    UserExtendedTypeEnum
} from "@services/apis/generated";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Speedometer from "react-native-speedometer"
import WarningIcon from "../../../../assets/svg/WarningIcon";
import { RootStackParamList } from "../../../../types";
import SharedContext from "../../../navigation/SharedContext";
import { ErrorHelper } from "@helpers/ErrorHelper";
import AppContext from "../../../navigation/AppContext";
import ModalSimpleConfirmCancel from "@screens/Modal/ModalSimpleConfirmCancel";
import ModalSimpleSendInvitation from "@screens/Modal/ModalSimpleSendInvitation";
import ModalSimpleSuccess from "@screens/Modal/ModalSimpleSuccess";
import ModalSimpleInfoText from "@screens/Modal/ModalSimpleInfoText";
import Icon from "@screens/Tools/Icon";
import {delay} from "@helpers/Sleep";

//Speedometer documenation https://www.npmjs.com/package/react-native-speedometer#properties

export default function HykeeScore(props: {
    navigation: NativeStackNavigationProp<RootStackParamList, "DashboardCompany" | "DashboardSavedCompany" | "DashboardCompanySearched">,
    onMyCompanies: boolean,
    lastCompanyData: CompanyData
}): JSX.Element {

    const sharedContext = React.useContext(SharedContext);
    const appContext = useContext(AppContext);

    const [companiesConsulted, setCompaniesConsulted] = useState<CompanyLinkRequest[]>([])
    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [modalSuccessSendNotification, setModalSuccessSendNotification] = useState<boolean>(false)
    const [modaleSendNotification, setModaleSendNotification] = useState<boolean>(false)
    const [infoTextVisible, setInfoTextVisible] = React.useState<boolean>(false);
    const [infoText, setInfoText] = React.useState<string>()


    const checkRequestSent = (): boolean => {
        return sharedContext.myCompaniesConsultedRequest != undefined
            && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
                && companyLinkRequest.currentState != CompanyLinkRequestCurrentStateEnum.Rejected) != undefined
    };

    function changeColorButton() {
        if (checkRequestSent() && !checkRequestAccepted()) {
            sharedContext.setLinkButton(true)
        }
    }

    function sendNotificationSelfAssessment() {
        let notification: Notification = {
            type: NotificationTypeEnum.SelfAssessmentCompile,
            company: sharedContext.selectedCompany,
            consultant: sharedContext.myCompanySelected,
        };
        customNotificationResourceApi.createNotificationCustom({ notification: notification }).then((res) => {
            if (res.length > 0) {
                setModalSuccessSendNotification(true)
            } else {
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage('NOTIFICATION_ALREADY_SENT') })
            }

        })
            .catch(async (error) => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
            })
    }

    function checkCompanyRegistered() {
        if (sharedContext.who == UserExtendedTypeEnum.Consultant) {
            customCompanyResourceApi.checkIfCompanyIsRegistered({ company: sharedContext.selectedCompany! }).then((exist) => {
                if (!exist.registered) {
                    setModalVisible(true);
                }
                else {
                    sendRequest();
                }
            })
        }
    }

    function sendRequest(email?: string) {
        customCompanyLinkRequestResourceApi.createCompanyLinkRequestWithDefaultState({ invitationDTO: { companyLinkRequest: { company: sharedContext.selectedCompany, consultant: sharedContext.myCompanySelected }, email: email } }).then((res) => {
            let companyLinkRequest: CompanyLinkRequest[] = sharedContext.myCompaniesConsultedRequest!;
            companyLinkRequest.push(res);
            sharedContext.setMyCompaniesConsultedRequest(companyLinkRequest);
            setCompaniesConsulted(companyLinkRequest);
            changeColorButton()
            //NavigationHelper.navigateToDashboardCompanySearched(props.navigation, sharedContext.selectedCompany!);
        }).catch(async (error) => {
            let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
            let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
            appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) })
        })
        //}).catch(error => AlertHelper.showSimpleErrorAlert());

    };


    function surveyCompile() {
        if (sharedContext.selectedCompany) {
            NavigationHelper.navigateToSurvey(props.navigation, sharedContext.selectedCompany);
        }
    }

    const checkRequestInviated = (): boolean => {
        return sharedContext.myCompaniesConsultedRequest != undefined
            && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
                && companyLinkRequest.currentState != CompanyLinkRequestCurrentStateEnum.Rejected) != undefined
    };

    const checkRequestAccepted = (): boolean => {
        return sharedContext.myCompaniesConsultedRequest != undefined
            && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
                && companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted) != undefined

    }

    const goToLinkedCompanies = (): void => {
        NavigationHelper.navigateToLinkedCompaniesData(props.navigation);
    };

    /**
     * Function checks if the financial score and the business score are present.
     * @returns boolean
     */
    const checkFinancialScoreAndBusinessScore = (): boolean => {
        if (!props.lastCompanyData?.financialScore || !props.lastCompanyData?.businessScore)
            return false;

        return true;
    }

    function drawHykeeScoreInfo(): React.ReactNode {
        return (
            <View>
                <TouchableOpacity onPress={() => {
                    setInfoText(i18n.t("components.hykee_score.info"))
                    setInfoTextVisible(!infoTextVisible)
                }}>
                    <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: "center", alignSelf: "center", alignContent: "center" }}>
                        <Text style={{ fontStyle: "italic", fontFamily: "poppins", color: "white", fontSize: 11 }}>
                            {i18n.t("components.hykee_score.meaning")}
                        </Text>
                        <View style={{ padding: 10, marginLeft: -5 }}>
                            <Icon name="info-circle" size={12} color={"white"} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }


    return (
        <LinearGradient
            colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
            locations={[0.4, 0.8]}
            start={{ x: 1, y: 1 }}
            end={{ x: 0, y: 0 }}
            style={styles.borderGradient}
        >
            <LinearGradient
                colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.containerBack}
            >
                {sharedContext.selectedCompanyLastData?.hykeeScore != undefined &&
                    <View style={styles.container}>
                        <View style={styles.containerLabel}>
                            <Text style={styles.label}>
                                Hykee Score
                            </Text>
                            {drawHykeeScoreInfo()}
                        </View>
                        {(props.onMyCompanies || checkRequestAccepted()) && checkFinancialScoreAndBusinessScore() ?
                            <View style={styles.containerSpeed}>
                                {sharedContext.selectedCompanyLastData?.hykeeScore &&
                                    <>
                                        <Speedometer
                                            value={sharedContext.selectedCompanyLastData?.hykeeScore}
                                            labelStyle={{ opacity: 0, height: 0 }}
                                            labelNoteStyle={{ opacity: 0, height: 0 }}
                                            needleImage={require('../../../../assets/images/needle.png')}
                                            size={250}
                                        />
                                        <Text style={styles.textLabelScore} >{sharedContext.selectedCompanyLastData?.hykeeScore}%</Text>
                                    </>
                                }
                            </View> :
                            <>
                                <View style={styles.containerIconError}>
                                    <WarningIcon width={100} height={100} />
                                </View>
                                <View style={[styles.containerIconError]}>
                                    <Text style={styles.labelError}>
                                        {props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')}
                                    </Text>
                                </View>
                            </>
                        }
                    </View>
                }
                {sharedContext.selectedCompanyLastData?.hykeeScore == undefined &&
                    <TouchableOpacity
                        activeOpacity={0.5}
                        onPress={() => { props.onMyCompanies ? surveyCompile() : null }}
                    >
                        <View style={styles.containerError}>
                            <View style={styles.containerLabel}>
                                <Text style={styles.label}>
                                    Hykee Score
                                </Text>
                                {drawHykeeScoreInfo()}
                            </View>

                            <View style={styles.containerIconError}>
                                <WarningIcon width={90} height={90} />
                            </View>
                            <View style={[styles.containerIconError]}>
                                <Text style={styles.labelError}>
                                    {props.onMyCompanies ? i18n.t('components.business_score.error') : i18n.t('components.business_score.error_search')}
                                </Text>
                            </View>
                        </View>
                    </TouchableOpacity>}
                {sharedContext.who == UserExtendedTypeEnum.Consultant && !checkRequestSent() &&
                    <TouchableOpacity
                        style={styles.containerRequest}
                        onPress={() => checkCompanyRegistered()}>
                        <Text style={styles.labelRequest}>
                            {i18n.t('components.business_score.send_request')}
                        </Text>
                    </TouchableOpacity>}
                {sharedContext.who == UserExtendedTypeEnum.Consultant && checkRequestSent() && !checkRequestAccepted() &&
                    <TouchableOpacity
                        style={styles.containerRequestSended}
                        onPress={() => goToLinkedCompanies()}>
                        <Text style={styles.labelRequest}>
                            {i18n.t('components.business_score.show_state_request')}
                        </Text>
                    </TouchableOpacity>}
                {modaleSendNotification &&
                    <ModalSimpleConfirmCancel
                        msg={i18n.t('components.business_score.send_notify')}
                        action={() => sendNotificationSelfAssessment()}
                        visible={(visible) => { setModaleSendNotification(visible) }}
                    />}

                {modalVisible &&
                    <ModalSimpleSendInvitation
                        msg={i18n.t('screens.search.send_invitation')}
                        companySearched={sharedContext.selectedCompany}
                        action={(email) => sendRequest(email)}
                        visible={(visible) => { setModalVisible(visible) }}
                    />
                }
                {modalSuccessSendNotification &&
                    <ModalSimpleSuccess
                        msg={i18n.t('components.business_score.send_notify_success')}
                        visible={(visible) => { setModalSuccessSendNotification(visible) }}
                    />
                }
            </LinearGradient>
            {infoTextVisible && <ModalSimpleInfoText message={infoText} setVisible={(infoText) => (infoText != null ? setInfoTextVisible(infoText) : null)} />}
        </LinearGradient>

    );
}
const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerRequest: {
        marginVertical: 7,
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius: 5,
        alignItems: 'center',

    },
    containerRequestSended: {
        marginVertical: 7,
        backgroundColor: '#009dff',
        borderRadius: 5,
        alignItems: 'center',
    },
    labelRequest: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        padding: 5,
    },
    containerError: {
        flexDirection: "column",
    },
    containerIconError: {
        flexDirection: "column",
        alignSelf: 'center',
        justifyContent: 'center'
    },
    containerBack: {
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
    },
    container: {
        flexDirection: "column",

    },
    containerSpeed: {
        alignSelf: 'center',

    },
    textLabelScore: {
        textAlign: 'center',
        marginTop: 5,
        fontSize: 20,
        color: 'white',
        fontFamily: 'poppins',

    },
    containerLabel: {
    },
    label: {
        textAlign: "left",
        color: "white",
        fontFamily: "poppins",
        fontSize: 17,
        marginLeft: 15
    },
    labelError: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 15,
        marginLeft: 15,
        textAlign: 'center',
        padding: 5
    }
});

