/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Altre,
    AltreFromJSON,
    AltreFromJSONTyped,
    AltreToJSON,
    Compromissorie,
    CompromissorieFromJSON,
    CompromissorieFromJSONTyped,
    CompromissorieToJSON,
    Esclusione,
    EsclusioneFromJSON,
    EsclusioneFromJSONTyped,
    EsclusioneToJSON,
    Gradimento,
    GradimentoFromJSON,
    GradimentoFromJSONTyped,
    GradimentoToJSON,
    Limitazione,
    LimitazioneFromJSON,
    LimitazioneFromJSONTyped,
    LimitazioneToJSON,
    Prelazione,
    PrelazioneFromJSON,
    PrelazioneFromJSONTyped,
    PrelazioneToJSON,
    Recesso,
    RecessoFromJSON,
    RecessoFromJSONTyped,
    RecessoToJSON,
} from './';

/**
 * 
 * @export
 * @interface Clausole
 */
export interface Clausole {
    /**
     * 
     * @type {Recesso}
     * @memberof Clausole
     */
    recesso?: Recesso;
    /**
     * 
     * @type {Esclusione}
     * @memberof Clausole
     */
    esclusione?: Esclusione;
    /**
     * 
     * @type {Gradimento}
     * @memberof Clausole
     */
    gradimento?: Gradimento;
    /**
     * 
     * @type {Prelazione}
     * @memberof Clausole
     */
    prelazione?: Prelazione;
    /**
     * 
     * @type {Limitazione}
     * @memberof Clausole
     */
    limitazione?: Limitazione;
    /**
     * 
     * @type {Compromissorie}
     * @memberof Clausole
     */
    compromissorie?: Compromissorie;
    /**
     * 
     * @type {Altre}
     * @memberof Clausole
     */
    altre?: Altre;
}

export function ClausoleFromJSON(json: any): Clausole {
    return ClausoleFromJSONTyped(json, false);
}

export function ClausoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Clausole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recesso': !exists(json, 'recesso') ? undefined : RecessoFromJSON(json['recesso']),
        'esclusione': !exists(json, 'esclusione') ? undefined : EsclusioneFromJSON(json['esclusione']),
        'gradimento': !exists(json, 'gradimento') ? undefined : GradimentoFromJSON(json['gradimento']),
        'prelazione': !exists(json, 'prelazione') ? undefined : PrelazioneFromJSON(json['prelazione']),
        'limitazione': !exists(json, 'limitazione') ? undefined : LimitazioneFromJSON(json['limitazione']),
        'compromissorie': !exists(json, 'compromissorie') ? undefined : CompromissorieFromJSON(json['compromissorie']),
        'altre': !exists(json, 'altre') ? undefined : AltreFromJSON(json['altre']),
    };
}

export function ClausoleToJSON(value?: Clausole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recesso': RecessoToJSON(value.recesso),
        'esclusione': EsclusioneToJSON(value.esclusione),
        'gradimento': GradimentoToJSON(value.gradimento),
        'prelazione': PrelazioneToJSON(value.prelazione),
        'limitazione': LimitazioneToJSON(value.limitazione),
        'compromissorie': CompromissorieToJSON(value.compromissorie),
        'altre': AltreToJSON(value.altre),
    };
}


