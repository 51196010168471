/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyAteco,
    CompanyAtecoFromJSON,
    CompanyAtecoToJSON,
} from '../models';

export interface CountCompanyAtecosRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    atecoCodingCodeContains?: string;
    atecoCodingCodeDoesNotContain?: string;
    atecoCodingCodeEquals?: string;
    atecoCodingCodeNotEquals?: string;
    atecoCodingCodeSpecified?: boolean;
    atecoCodingCodeIn?: Array<string>;
    atecoCodingCodeNotIn?: Array<string>;
    atecoActivityCodeContains?: string;
    atecoActivityCodeDoesNotContain?: string;
    atecoActivityCodeEquals?: string;
    atecoActivityCodeNotEquals?: string;
    atecoActivityCodeSpecified?: boolean;
    atecoActivityCodeIn?: Array<string>;
    atecoActivityCodeNotIn?: Array<string>;
    companyAddressIdGreaterThan?: number;
    companyAddressIdLessThan?: number;
    companyAddressIdGreaterThanOrEqual?: number;
    companyAddressIdLessThanOrEqual?: number;
    companyAddressIdEquals?: number;
    companyAddressIdNotEquals?: number;
    companyAddressIdSpecified?: boolean;
    companyAddressIdIn?: Array<number>;
    companyAddressIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanyAtecoRequest {
    companyAteco: CompanyAteco;
}

export interface DeleteCompanyAtecoRequest {
    id: number;
}

export interface GetAllCompanyAtecosRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    atecoCodingCodeContains?: string;
    atecoCodingCodeDoesNotContain?: string;
    atecoCodingCodeEquals?: string;
    atecoCodingCodeNotEquals?: string;
    atecoCodingCodeSpecified?: boolean;
    atecoCodingCodeIn?: Array<string>;
    atecoCodingCodeNotIn?: Array<string>;
    atecoActivityCodeContains?: string;
    atecoActivityCodeDoesNotContain?: string;
    atecoActivityCodeEquals?: string;
    atecoActivityCodeNotEquals?: string;
    atecoActivityCodeSpecified?: boolean;
    atecoActivityCodeIn?: Array<string>;
    atecoActivityCodeNotIn?: Array<string>;
    companyAddressIdGreaterThan?: number;
    companyAddressIdLessThan?: number;
    companyAddressIdGreaterThanOrEqual?: number;
    companyAddressIdLessThanOrEqual?: number;
    companyAddressIdEquals?: number;
    companyAddressIdNotEquals?: number;
    companyAddressIdSpecified?: boolean;
    companyAddressIdIn?: Array<number>;
    companyAddressIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanyAtecoRequest {
    id: number;
}

export interface PartialUpdateCompanyAtecoRequest {
    id: number;
    companyAteco: CompanyAteco;
}

export interface UpdateCompanyAtecoRequest {
    id: number;
    companyAteco: CompanyAteco;
}

/**
 * 
 */
export class CompanyAtecoResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanyAtecosRaw(requestParameters: CountCompanyAtecosRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.atecoCodingCodeContains !== undefined) {
            queryParameters['atecoCodingCode.contains'] = requestParameters.atecoCodingCodeContains;
        }

        if (requestParameters.atecoCodingCodeDoesNotContain !== undefined) {
            queryParameters['atecoCodingCode.doesNotContain'] = requestParameters.atecoCodingCodeDoesNotContain;
        }

        if (requestParameters.atecoCodingCodeEquals !== undefined) {
            queryParameters['atecoCodingCode.equals'] = requestParameters.atecoCodingCodeEquals;
        }

        if (requestParameters.atecoCodingCodeNotEquals !== undefined) {
            queryParameters['atecoCodingCode.notEquals'] = requestParameters.atecoCodingCodeNotEquals;
        }

        if (requestParameters.atecoCodingCodeSpecified !== undefined) {
            queryParameters['atecoCodingCode.specified'] = requestParameters.atecoCodingCodeSpecified;
        }

        if (requestParameters.atecoCodingCodeIn) {
            queryParameters['atecoCodingCode.in'] = requestParameters.atecoCodingCodeIn;
        }

        if (requestParameters.atecoCodingCodeNotIn) {
            queryParameters['atecoCodingCode.notIn'] = requestParameters.atecoCodingCodeNotIn;
        }

        if (requestParameters.atecoActivityCodeContains !== undefined) {
            queryParameters['atecoActivityCode.contains'] = requestParameters.atecoActivityCodeContains;
        }

        if (requestParameters.atecoActivityCodeDoesNotContain !== undefined) {
            queryParameters['atecoActivityCode.doesNotContain'] = requestParameters.atecoActivityCodeDoesNotContain;
        }

        if (requestParameters.atecoActivityCodeEquals !== undefined) {
            queryParameters['atecoActivityCode.equals'] = requestParameters.atecoActivityCodeEquals;
        }

        if (requestParameters.atecoActivityCodeNotEquals !== undefined) {
            queryParameters['atecoActivityCode.notEquals'] = requestParameters.atecoActivityCodeNotEquals;
        }

        if (requestParameters.atecoActivityCodeSpecified !== undefined) {
            queryParameters['atecoActivityCode.specified'] = requestParameters.atecoActivityCodeSpecified;
        }

        if (requestParameters.atecoActivityCodeIn) {
            queryParameters['atecoActivityCode.in'] = requestParameters.atecoActivityCodeIn;
        }

        if (requestParameters.atecoActivityCodeNotIn) {
            queryParameters['atecoActivityCode.notIn'] = requestParameters.atecoActivityCodeNotIn;
        }

        if (requestParameters.companyAddressIdGreaterThan !== undefined) {
            queryParameters['companyAddressId.greaterThan'] = requestParameters.companyAddressIdGreaterThan;
        }

        if (requestParameters.companyAddressIdLessThan !== undefined) {
            queryParameters['companyAddressId.lessThan'] = requestParameters.companyAddressIdLessThan;
        }

        if (requestParameters.companyAddressIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAddressId.greaterThanOrEqual'] = requestParameters.companyAddressIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAddressIdLessThanOrEqual !== undefined) {
            queryParameters['companyAddressId.lessThanOrEqual'] = requestParameters.companyAddressIdLessThanOrEqual;
        }

        if (requestParameters.companyAddressIdEquals !== undefined) {
            queryParameters['companyAddressId.equals'] = requestParameters.companyAddressIdEquals;
        }

        if (requestParameters.companyAddressIdNotEquals !== undefined) {
            queryParameters['companyAddressId.notEquals'] = requestParameters.companyAddressIdNotEquals;
        }

        if (requestParameters.companyAddressIdSpecified !== undefined) {
            queryParameters['companyAddressId.specified'] = requestParameters.companyAddressIdSpecified;
        }

        if (requestParameters.companyAddressIdIn) {
            queryParameters['companyAddressId.in'] = requestParameters.companyAddressIdIn;
        }

        if (requestParameters.companyAddressIdNotIn) {
            queryParameters['companyAddressId.notIn'] = requestParameters.companyAddressIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-atecos/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanyAtecos(requestParameters: CountCompanyAtecosRequest): Promise<number> {
        const response = await this.countCompanyAtecosRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanyAtecoRaw(requestParameters: CreateCompanyAtecoRequest): Promise<runtime.ApiResponse<CompanyAteco>> {
        if (requestParameters.companyAteco === null || requestParameters.companyAteco === undefined) {
            throw new runtime.RequiredError('companyAteco','Required parameter requestParameters.companyAteco was null or undefined when calling createCompanyAteco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-atecos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAtecoToJSON(requestParameters.companyAteco),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAtecoFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyAteco(requestParameters: CreateCompanyAtecoRequest): Promise<CompanyAteco> {
        const response = await this.createCompanyAtecoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyAtecoRaw(requestParameters: DeleteCompanyAtecoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyAteco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-atecos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyAteco(requestParameters: DeleteCompanyAtecoRequest): Promise<void> {
        await this.deleteCompanyAtecoRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyAtecosRaw(requestParameters: GetAllCompanyAtecosRequest): Promise<runtime.ApiResponse<Array<CompanyAteco>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.atecoCodingCodeContains !== undefined) {
            queryParameters['atecoCodingCode.contains'] = requestParameters.atecoCodingCodeContains;
        }

        if (requestParameters.atecoCodingCodeDoesNotContain !== undefined) {
            queryParameters['atecoCodingCode.doesNotContain'] = requestParameters.atecoCodingCodeDoesNotContain;
        }

        if (requestParameters.atecoCodingCodeEquals !== undefined) {
            queryParameters['atecoCodingCode.equals'] = requestParameters.atecoCodingCodeEquals;
        }

        if (requestParameters.atecoCodingCodeNotEquals !== undefined) {
            queryParameters['atecoCodingCode.notEquals'] = requestParameters.atecoCodingCodeNotEquals;
        }

        if (requestParameters.atecoCodingCodeSpecified !== undefined) {
            queryParameters['atecoCodingCode.specified'] = requestParameters.atecoCodingCodeSpecified;
        }

        if (requestParameters.atecoCodingCodeIn) {
            queryParameters['atecoCodingCode.in'] = requestParameters.atecoCodingCodeIn;
        }

        if (requestParameters.atecoCodingCodeNotIn) {
            queryParameters['atecoCodingCode.notIn'] = requestParameters.atecoCodingCodeNotIn;
        }

        if (requestParameters.atecoActivityCodeContains !== undefined) {
            queryParameters['atecoActivityCode.contains'] = requestParameters.atecoActivityCodeContains;
        }

        if (requestParameters.atecoActivityCodeDoesNotContain !== undefined) {
            queryParameters['atecoActivityCode.doesNotContain'] = requestParameters.atecoActivityCodeDoesNotContain;
        }

        if (requestParameters.atecoActivityCodeEquals !== undefined) {
            queryParameters['atecoActivityCode.equals'] = requestParameters.atecoActivityCodeEquals;
        }

        if (requestParameters.atecoActivityCodeNotEquals !== undefined) {
            queryParameters['atecoActivityCode.notEquals'] = requestParameters.atecoActivityCodeNotEquals;
        }

        if (requestParameters.atecoActivityCodeSpecified !== undefined) {
            queryParameters['atecoActivityCode.specified'] = requestParameters.atecoActivityCodeSpecified;
        }

        if (requestParameters.atecoActivityCodeIn) {
            queryParameters['atecoActivityCode.in'] = requestParameters.atecoActivityCodeIn;
        }

        if (requestParameters.atecoActivityCodeNotIn) {
            queryParameters['atecoActivityCode.notIn'] = requestParameters.atecoActivityCodeNotIn;
        }

        if (requestParameters.companyAddressIdGreaterThan !== undefined) {
            queryParameters['companyAddressId.greaterThan'] = requestParameters.companyAddressIdGreaterThan;
        }

        if (requestParameters.companyAddressIdLessThan !== undefined) {
            queryParameters['companyAddressId.lessThan'] = requestParameters.companyAddressIdLessThan;
        }

        if (requestParameters.companyAddressIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAddressId.greaterThanOrEqual'] = requestParameters.companyAddressIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAddressIdLessThanOrEqual !== undefined) {
            queryParameters['companyAddressId.lessThanOrEqual'] = requestParameters.companyAddressIdLessThanOrEqual;
        }

        if (requestParameters.companyAddressIdEquals !== undefined) {
            queryParameters['companyAddressId.equals'] = requestParameters.companyAddressIdEquals;
        }

        if (requestParameters.companyAddressIdNotEquals !== undefined) {
            queryParameters['companyAddressId.notEquals'] = requestParameters.companyAddressIdNotEquals;
        }

        if (requestParameters.companyAddressIdSpecified !== undefined) {
            queryParameters['companyAddressId.specified'] = requestParameters.companyAddressIdSpecified;
        }

        if (requestParameters.companyAddressIdIn) {
            queryParameters['companyAddressId.in'] = requestParameters.companyAddressIdIn;
        }

        if (requestParameters.companyAddressIdNotIn) {
            queryParameters['companyAddressId.notIn'] = requestParameters.companyAddressIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-atecos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyAtecoFromJSON));
    }

    /**
     */
    async getAllCompanyAtecos(requestParameters: GetAllCompanyAtecosRequest): Promise<Array<CompanyAteco>> {
        const response = await this.getAllCompanyAtecosRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyAtecoRaw(requestParameters: GetCompanyAtecoRequest): Promise<runtime.ApiResponse<CompanyAteco>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyAteco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-atecos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAtecoFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyAteco(requestParameters: GetCompanyAtecoRequest): Promise<CompanyAteco> {
        const response = await this.getCompanyAtecoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyAtecoRaw(requestParameters: PartialUpdateCompanyAtecoRequest): Promise<runtime.ApiResponse<CompanyAteco>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyAteco.');
        }

        if (requestParameters.companyAteco === null || requestParameters.companyAteco === undefined) {
            throw new runtime.RequiredError('companyAteco','Required parameter requestParameters.companyAteco was null or undefined when calling partialUpdateCompanyAteco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-atecos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAtecoToJSON(requestParameters.companyAteco),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAtecoFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyAteco(requestParameters: PartialUpdateCompanyAtecoRequest): Promise<CompanyAteco> {
        const response = await this.partialUpdateCompanyAtecoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyAtecoRaw(requestParameters: UpdateCompanyAtecoRequest): Promise<runtime.ApiResponse<CompanyAteco>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyAteco.');
        }

        if (requestParameters.companyAteco === null || requestParameters.companyAteco === undefined) {
            throw new runtime.RequiredError('companyAteco','Required parameter requestParameters.companyAteco was null or undefined when calling updateCompanyAteco.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-atecos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAtecoToJSON(requestParameters.companyAteco),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAtecoFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyAteco(requestParameters: UpdateCompanyAtecoRequest): Promise<CompanyAteco> {
        const response = await this.updateCompanyAtecoRaw(requestParameters);
        return await response.value();
    }

}
