/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LavoroPrestatoFamiliariPart
 */
export interface LavoroPrestatoFamiliariPart {
    /**
     * 
     * @type {string}
     * @memberof LavoroPrestatoFamiliariPart
     */
    nlavoratoriTempoIndeter?: string;
    /**
     * 
     * @type {string}
     * @memberof LavoroPrestatoFamiliariPart
     */
    ngiornate?: string;
}

export function LavoroPrestatoFamiliariPartFromJSON(json: any): LavoroPrestatoFamiliariPart {
    return LavoroPrestatoFamiliariPartFromJSONTyped(json, false);
}

export function LavoroPrestatoFamiliariPartFromJSONTyped(json: any, ignoreDiscriminator: boolean): LavoroPrestatoFamiliariPart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nlavoratoriTempoIndeter': !exists(json, 'nlavoratoriTempoIndeter') ? undefined : json['nlavoratoriTempoIndeter'],
        'ngiornate': !exists(json, 'ngiornate') ? undefined : json['ngiornate'],
    };
}

export function LavoroPrestatoFamiliariPartToJSON(value?: LavoroPrestatoFamiliariPart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nlavoratoriTempoIndeter': value.nlavoratoriTempoIndeter,
        'ngiornate': value.ngiornate,
    };
}


