/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Riquadri,
    RiquadriFromJSON,
    RiquadriFromJSONTyped,
    RiquadriToJSON,
} from './';

/**
 * 
 * @export
 * @interface Modello
 */
export interface Modello {
    /**
     * 
     * @type {Array<Riquadri>}
     * @memberof Modello
     */
    riquadri?: Array<Riquadri>;
    /**
     * 
     * @type {string}
     * @memberof Modello
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof Modello
     */
    nricorrenze?: string;
}

export function ModelloFromJSON(json: any): Modello {
    return ModelloFromJSONTyped(json, false);
}

export function ModelloFromJSONTyped(json: any, ignoreDiscriminator: boolean): Modello {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riquadri': !exists(json, 'riquadri') ? undefined : ((json['riquadri'] as Array<any>).map(RiquadriFromJSON)),
        'c': !exists(json, 'c') ? undefined : json['c'],
        'nricorrenze': !exists(json, 'nricorrenze') ? undefined : json['nricorrenze'],
    };
}

export function ModelloToJSON(value?: Modello | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riquadri': value.riquadri === undefined ? undefined : ((value.riquadri as Array<any>).map(RiquadriToJSON)),
        'c': value.c,
        'nricorrenze': value.nricorrenze,
    };
}


