/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlbiRuoliLicenze,
    AlbiRuoliLicenzeFromJSON,
    AlbiRuoliLicenzeFromJSONTyped,
    AlbiRuoliLicenzeToJSON,
    AmministrazioneControllo,
    AmministrazioneControlloFromJSON,
    AmministrazioneControlloFromJSONTyped,
    AmministrazioneControlloToJSON,
    AnnotazioniLibroSoci,
    AnnotazioniLibroSociFromJSON,
    AnnotazioniLibroSociFromJSONTyped,
    AnnotazioniLibroSociToJSON,
    CancellazioneTrasferimento,
    CancellazioneTrasferimentoFromJSON,
    CancellazioneTrasferimentoFromJSONTyped,
    CancellazioneTrasferimentoToJSON,
    DatiIdentificativi,
    DatiIdentificativiFromJSON,
    DatiIdentificativiFromJSONTyped,
    DatiIdentificativiToJSON,
    DocConsultabili,
    DocConsultabiliFromJSON,
    DocConsultabiliFromJSONTyped,
    DocConsultabiliToJSON,
    ElencoSoci,
    ElencoSociFromJSON,
    ElencoSociFromJSONTyped,
    ElencoSociToJSON,
    EstremiAttoCostituzione,
    EstremiAttoCostituzioneFromJSON,
    EstremiAttoCostituzioneFromJSONTyped,
    EstremiAttoCostituzioneToJSON,
    InfoAttivita,
    InfoAttivitaFromJSON,
    InfoAttivitaFromJSONTyped,
    InfoAttivitaToJSON,
    InfoPatrimonialiFinanziarie,
    InfoPatrimonialiFinanziarieFromJSON,
    InfoPatrimonialiFinanziarieFromJSONTyped,
    InfoPatrimonialiFinanziarieToJSON,
    InfoSede,
    InfoSedeFromJSON,
    InfoSedeFromJSONTyped,
    InfoSedeToJSON,
    InfoStatuto,
    InfoStatutoFromJSON,
    InfoStatutoFromJSONTyped,
    InfoStatutoToJSON,
    InfoSupplementariStoriche,
    InfoSupplementariStoricheFromJSON,
    InfoSupplementariStoricheFromJSONTyped,
    InfoSupplementariStoricheToJSON,
    IscrizioneRi,
    IscrizioneRiFromJSON,
    IscrizioneRiFromJSONTyped,
    IscrizioneRiToJSON,
    Localizzazioni,
    LocalizzazioniFromJSON,
    LocalizzazioniFromJSONTyped,
    LocalizzazioniToJSON,
    Mad,
    MadFromJSON,
    MadFromJSONTyped,
    MadToJSON,
    PartecipazioniSocieta,
    PartecipazioniSocietaFromJSON,
    PartecipazioniSocietaFromJSONTyped,
    PartecipazioniSocietaToJSON,
    PattiParasociali,
    PattiParasocialiFromJSON,
    PattiParasocialiFromJSONTyped,
    PattiParasocialiToJSON,
    PersoneSede,
    PersoneSedeFromJSON,
    PersoneSedeFromJSONTyped,
    PersoneSedeToJSON,
    PraticheSoggettiControllanti,
    PraticheSoggettiControllantiFromJSON,
    PraticheSoggettiControllantiFromJSONTyped,
    PraticheSoggettiControllantiToJSON,
    ProcedureConcorsuali,
    ProcedureConcorsualiFromJSON,
    ProcedureConcorsualiFromJSONTyped,
    ProcedureConcorsualiToJSON,
    ProtocolliAperti,
    ProtocolliApertiFromJSON,
    ProtocolliApertiFromJSONTyped,
    ProtocolliApertiToJSON,
    ProtocolliCompleti,
    ProtocolliCompletiFromJSON,
    ProtocolliCompletiFromJSONTyped,
    ProtocolliCompletiToJSON,
    RetiImprese,
    RetiImpreseFromJSON,
    RetiImpreseFromJSONTyped,
    RetiImpreseToJSON,
    SintesiAttivita,
    SintesiAttivitaFromJSON,
    SintesiAttivitaFromJSONTyped,
    SintesiAttivitaToJSON,
    SintesiCifreImpresa,
    SintesiCifreImpresaFromJSON,
    SintesiCifreImpresaFromJSONTyped,
    SintesiCifreImpresaToJSON,
    SociTrasferimentoQuote,
    SociTrasferimentoQuoteFromJSON,
    SociTrasferimentoQuoteFromJSONTyped,
    SociTrasferimentoQuoteToJSON,
    SocietaQuotata,
    SocietaQuotataFromJSON,
    SocietaQuotataFromJSONTyped,
    SocietaQuotataToJSON,
    StoriaAddetti,
    StoriaAddettiFromJSON,
    StoriaAddettiFromJSONTyped,
    StoriaAddettiToJSON,
    StoriaAttivita,
    StoriaAttivitaFromJSON,
    StoriaAttivitaFromJSONTyped,
    StoriaAttivitaToJSON,
    StoriaCciaaProvenienza,
    StoriaCciaaProvenienzaFromJSON,
    StoriaCciaaProvenienzaFromJSONTyped,
    StoriaCciaaProvenienzaToJSON,
    StoriaSediPrecedenti,
    StoriaSediPrecedentiFromJSON,
    StoriaSediPrecedentiFromJSONTyped,
    StoriaSediPrecedentiToJSON,
    TaFusioniScissioniSubentri,
    TaFusioniScissioniSubentriFromJSON,
    TaFusioniScissioniSubentriFromJSONTyped,
    TaFusioniScissioniSubentriToJSON,
    TabellaElencoSoci,
    TabellaElencoSociFromJSON,
    TabellaElencoSociFromJSONTyped,
    TabellaElencoSociToJSON,
    TabellaPartecipateImpresa,
    TabellaPartecipateImpresaFromJSON,
    TabellaPartecipateImpresaFromJSONTyped,
    TabellaPartecipateImpresaToJSON,
    Trascrizioni,
    TrascrizioniFromJSON,
    TrascrizioniFromJSONTyped,
    TrascrizioniToJSON,
    TrasferimentiQuote,
    TrasferimentiQuoteFromJSON,
    TrasferimentiQuoteFromJSONTyped,
    TrasferimentiQuoteToJSON,
    TrasferimentoQuote,
    TrasferimentoQuoteFromJSON,
    TrasferimentoQuoteFromJSONTyped,
    TrasferimentoQuoteToJSON,
    VariazioniFormaGiuridica,
    VariazioniFormaGiuridicaFromJSON,
    VariazioniFormaGiuridicaFromJSONTyped,
    VariazioniFormaGiuridicaToJSON,
} from './';

/**
 * 
 * @export
 * @interface BlocchiImpresa
 */
export interface BlocchiImpresa {
    /**
     * 
     * @type {DatiIdentificativi}
     * @memberof BlocchiImpresa
     */
    datiIdentificativi?: DatiIdentificativi;
    /**
     * 
     * @type {SintesiAttivita}
     * @memberof BlocchiImpresa
     */
    sintesiAttivita?: SintesiAttivita;
    /**
     * 
     * @type {SintesiCifreImpresa}
     * @memberof BlocchiImpresa
     */
    sintesiCifreImpresa?: SintesiCifreImpresa;
    /**
     * 
     * @type {DocConsultabili}
     * @memberof BlocchiImpresa
     */
    docConsultabili?: DocConsultabili;
    /**
     * 
     * @type {InfoSede}
     * @memberof BlocchiImpresa
     */
    infoSede?: InfoSede;
    /**
     * 
     * @type {EstremiAttoCostituzione}
     * @memberof BlocchiImpresa
     */
    estremiAttoCostituzione?: EstremiAttoCostituzione;
    /**
     * 
     * @type {CancellazioneTrasferimento}
     * @memberof BlocchiImpresa
     */
    cancellazioneTrasferimento?: CancellazioneTrasferimento;
    /**
     * 
     * @type {InfoAttivita}
     * @memberof BlocchiImpresa
     */
    infoAttivita?: InfoAttivita;
    /**
     * 
     * @type {StoriaAttivita}
     * @memberof BlocchiImpresa
     */
    storiaAttivita?: StoriaAttivita;
    /**
     * 
     * @type {StoriaAddetti}
     * @memberof BlocchiImpresa
     */
    storiaAddetti?: StoriaAddetti;
    /**
     * 
     * @type {AlbiRuoliLicenze}
     * @memberof BlocchiImpresa
     */
    albiRuoliLicenze?: AlbiRuoliLicenze;
    /**
     * 
     * @type {PersoneSede}
     * @memberof BlocchiImpresa
     */
    personeSede?: PersoneSede;
    /**
     * 
     * @type {Localizzazioni}
     * @memberof BlocchiImpresa
     */
    localizzazioni?: Localizzazioni;
    /**
     * 
     * @type {ProtocolliAperti}
     * @memberof BlocchiImpresa
     */
    protocolliAperti?: ProtocolliAperti;
    /**
     * 
     * @type {ProtocolliCompleti}
     * @memberof BlocchiImpresa
     */
    protocolliCompleti?: ProtocolliCompleti;
    /**
     * 
     * @type {SocietaQuotata}
     * @memberof BlocchiImpresa
     */
    societaQuotata?: SocietaQuotata;
    /**
     * 
     * @type {ElencoSoci}
     * @memberof BlocchiImpresa
     */
    elencoSoci?: ElencoSoci;
    /**
     * 
     * @type {TabellaElencoSoci}
     * @memberof BlocchiImpresa
     */
    tabellaElencoSoci?: TabellaElencoSoci;
    /**
     * 
     * @type {AnnotazioniLibroSoci}
     * @memberof BlocchiImpresa
     */
    annotazioniLibroSoci?: AnnotazioniLibroSoci;
    /**
     * 
     * @type {TrasferimentiQuote}
     * @memberof BlocchiImpresa
     */
    trasferimentiQuote?: TrasferimentiQuote;
    /**
     * 
     * @type {TrasferimentoQuote}
     * @memberof BlocchiImpresa
     */
    trasferimentoQuote?: TrasferimentoQuote;
    /**
     * 
     * @type {SociTrasferimentoQuote}
     * @memberof BlocchiImpresa
     */
    sociTrasferimentoQuote?: SociTrasferimentoQuote;
    /**
     * 
     * @type {PraticheSoggettiControllanti}
     * @memberof BlocchiImpresa
     */
    praticheSoggettiControllanti?: PraticheSoggettiControllanti;
    /**
     * 
     * @type {PartecipazioniSocieta}
     * @memberof BlocchiImpresa
     */
    partecipazioniSocieta?: PartecipazioniSocieta;
    /**
     * 
     * @type {TabellaPartecipateImpresa}
     * @memberof BlocchiImpresa
     */
    tabellaPartecipateImpresa?: TabellaPartecipateImpresa;
    /**
     * 
     * @type {Mad}
     * @memberof BlocchiImpresa
     */
    mad?: Mad;
    /**
     * 
     * @type {Trascrizioni}
     * @memberof BlocchiImpresa
     */
    trascrizioni?: Trascrizioni;
    /**
     * 
     * @type {StoriaCciaaProvenienza}
     * @memberof BlocchiImpresa
     */
    storiaCciaaProvenienza?: StoriaCciaaProvenienza;
    /**
     * 
     * @type {StoriaSediPrecedenti}
     * @memberof BlocchiImpresa
     */
    storiaSediPrecedenti?: StoriaSediPrecedenti;
    /**
     * 
     * @type {IscrizioneRi}
     * @memberof BlocchiImpresa
     */
    iscrizioneRi?: IscrizioneRi;
    /**
     * 
     * @type {InfoStatuto}
     * @memberof BlocchiImpresa
     */
    infoStatuto?: InfoStatuto;
    /**
     * 
     * @type {RetiImprese}
     * @memberof BlocchiImpresa
     */
    retiImprese?: RetiImprese;
    /**
     * 
     * @type {AmministrazioneControllo}
     * @memberof BlocchiImpresa
     */
    amministrazioneControllo?: AmministrazioneControllo;
    /**
     * 
     * @type {InfoPatrimonialiFinanziarie}
     * @memberof BlocchiImpresa
     */
    infoPatrimonialiFinanziarie?: InfoPatrimonialiFinanziarie;
    /**
     * 
     * @type {PattiParasociali}
     * @memberof BlocchiImpresa
     */
    pattiParasociali?: PattiParasociali;
    /**
     * 
     * @type {ProcedureConcorsuali}
     * @memberof BlocchiImpresa
     */
    procedureConcorsuali?: ProcedureConcorsuali;
    /**
     * 
     * @type {VariazioniFormaGiuridica}
     * @memberof BlocchiImpresa
     */
    variazioniFormaGiuridica?: VariazioniFormaGiuridica;
    /**
     * 
     * @type {InfoSupplementariStoriche}
     * @memberof BlocchiImpresa
     */
    infoSupplementariStoriche?: InfoSupplementariStoriche;
    /**
     * 
     * @type {TaFusioniScissioniSubentri}
     * @memberof BlocchiImpresa
     */
    taFusioniScissioniSubentri?: TaFusioniScissioniSubentri;
}

export function BlocchiImpresaFromJSON(json: any): BlocchiImpresa {
    return BlocchiImpresaFromJSONTyped(json, false);
}

export function BlocchiImpresaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlocchiImpresa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datiIdentificativi': !exists(json, 'datiIdentificativi') ? undefined : DatiIdentificativiFromJSON(json['datiIdentificativi']),
        'sintesiAttivita': !exists(json, 'sintesiAttivita') ? undefined : SintesiAttivitaFromJSON(json['sintesiAttivita']),
        'sintesiCifreImpresa': !exists(json, 'sintesiCifreImpresa') ? undefined : SintesiCifreImpresaFromJSON(json['sintesiCifreImpresa']),
        'docConsultabili': !exists(json, 'docConsultabili') ? undefined : DocConsultabiliFromJSON(json['docConsultabili']),
        'infoSede': !exists(json, 'infoSede') ? undefined : InfoSedeFromJSON(json['infoSede']),
        'estremiAttoCostituzione': !exists(json, 'estremiAttoCostituzione') ? undefined : EstremiAttoCostituzioneFromJSON(json['estremiAttoCostituzione']),
        'cancellazioneTrasferimento': !exists(json, 'cancellazioneTrasferimento') ? undefined : CancellazioneTrasferimentoFromJSON(json['cancellazioneTrasferimento']),
        'infoAttivita': !exists(json, 'infoAttivita') ? undefined : InfoAttivitaFromJSON(json['infoAttivita']),
        'storiaAttivita': !exists(json, 'storiaAttivita') ? undefined : StoriaAttivitaFromJSON(json['storiaAttivita']),
        'storiaAddetti': !exists(json, 'storiaAddetti') ? undefined : StoriaAddettiFromJSON(json['storiaAddetti']),
        'albiRuoliLicenze': !exists(json, 'albiRuoliLicenze') ? undefined : AlbiRuoliLicenzeFromJSON(json['albiRuoliLicenze']),
        'personeSede': !exists(json, 'personeSede') ? undefined : PersoneSedeFromJSON(json['personeSede']),
        'localizzazioni': !exists(json, 'localizzazioni') ? undefined : LocalizzazioniFromJSON(json['localizzazioni']),
        'protocolliAperti': !exists(json, 'protocolliAperti') ? undefined : ProtocolliApertiFromJSON(json['protocolliAperti']),
        'protocolliCompleti': !exists(json, 'protocolliCompleti') ? undefined : ProtocolliCompletiFromJSON(json['protocolliCompleti']),
        'societaQuotata': !exists(json, 'societaQuotata') ? undefined : SocietaQuotataFromJSON(json['societaQuotata']),
        'elencoSoci': !exists(json, 'elencoSoci') ? undefined : ElencoSociFromJSON(json['elencoSoci']),
        'tabellaElencoSoci': !exists(json, 'tabellaElencoSoci') ? undefined : TabellaElencoSociFromJSON(json['tabellaElencoSoci']),
        'annotazioniLibroSoci': !exists(json, 'annotazioniLibroSoci') ? undefined : AnnotazioniLibroSociFromJSON(json['annotazioniLibroSoci']),
        'trasferimentiQuote': !exists(json, 'trasferimentiQuote') ? undefined : TrasferimentiQuoteFromJSON(json['trasferimentiQuote']),
        'trasferimentoQuote': !exists(json, 'trasferimentoQuote') ? undefined : TrasferimentoQuoteFromJSON(json['trasferimentoQuote']),
        'sociTrasferimentoQuote': !exists(json, 'sociTrasferimentoQuote') ? undefined : SociTrasferimentoQuoteFromJSON(json['sociTrasferimentoQuote']),
        'praticheSoggettiControllanti': !exists(json, 'praticheSoggettiControllanti') ? undefined : PraticheSoggettiControllantiFromJSON(json['praticheSoggettiControllanti']),
        'partecipazioniSocieta': !exists(json, 'partecipazioniSocieta') ? undefined : PartecipazioniSocietaFromJSON(json['partecipazioniSocieta']),
        'tabellaPartecipateImpresa': !exists(json, 'tabellaPartecipateImpresa') ? undefined : TabellaPartecipateImpresaFromJSON(json['tabellaPartecipateImpresa']),
        'mad': !exists(json, 'mad') ? undefined : MadFromJSON(json['mad']),
        'trascrizioni': !exists(json, 'trascrizioni') ? undefined : TrascrizioniFromJSON(json['trascrizioni']),
        'storiaCciaaProvenienza': !exists(json, 'storiaCciaaProvenienza') ? undefined : StoriaCciaaProvenienzaFromJSON(json['storiaCciaaProvenienza']),
        'storiaSediPrecedenti': !exists(json, 'storiaSediPrecedenti') ? undefined : StoriaSediPrecedentiFromJSON(json['storiaSediPrecedenti']),
        'iscrizioneRi': !exists(json, 'iscrizioneRi') ? undefined : IscrizioneRiFromJSON(json['iscrizioneRi']),
        'infoStatuto': !exists(json, 'infoStatuto') ? undefined : InfoStatutoFromJSON(json['infoStatuto']),
        'retiImprese': !exists(json, 'retiImprese') ? undefined : RetiImpreseFromJSON(json['retiImprese']),
        'amministrazioneControllo': !exists(json, 'amministrazioneControllo') ? undefined : AmministrazioneControlloFromJSON(json['amministrazioneControllo']),
        'infoPatrimonialiFinanziarie': !exists(json, 'infoPatrimonialiFinanziarie') ? undefined : InfoPatrimonialiFinanziarieFromJSON(json['infoPatrimonialiFinanziarie']),
        'pattiParasociali': !exists(json, 'pattiParasociali') ? undefined : PattiParasocialiFromJSON(json['pattiParasociali']),
        'procedureConcorsuali': !exists(json, 'procedureConcorsuali') ? undefined : ProcedureConcorsualiFromJSON(json['procedureConcorsuali']),
        'variazioniFormaGiuridica': !exists(json, 'variazioniFormaGiuridica') ? undefined : VariazioniFormaGiuridicaFromJSON(json['variazioniFormaGiuridica']),
        'infoSupplementariStoriche': !exists(json, 'infoSupplementariStoriche') ? undefined : InfoSupplementariStoricheFromJSON(json['infoSupplementariStoriche']),
        'taFusioniScissioniSubentri': !exists(json, 'taFusioniScissioniSubentri') ? undefined : TaFusioniScissioniSubentriFromJSON(json['taFusioniScissioniSubentri']),
    };
}

export function BlocchiImpresaToJSON(value?: BlocchiImpresa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datiIdentificativi': DatiIdentificativiToJSON(value.datiIdentificativi),
        'sintesiAttivita': SintesiAttivitaToJSON(value.sintesiAttivita),
        'sintesiCifreImpresa': SintesiCifreImpresaToJSON(value.sintesiCifreImpresa),
        'docConsultabili': DocConsultabiliToJSON(value.docConsultabili),
        'infoSede': InfoSedeToJSON(value.infoSede),
        'estremiAttoCostituzione': EstremiAttoCostituzioneToJSON(value.estremiAttoCostituzione),
        'cancellazioneTrasferimento': CancellazioneTrasferimentoToJSON(value.cancellazioneTrasferimento),
        'infoAttivita': InfoAttivitaToJSON(value.infoAttivita),
        'storiaAttivita': StoriaAttivitaToJSON(value.storiaAttivita),
        'storiaAddetti': StoriaAddettiToJSON(value.storiaAddetti),
        'albiRuoliLicenze': AlbiRuoliLicenzeToJSON(value.albiRuoliLicenze),
        'personeSede': PersoneSedeToJSON(value.personeSede),
        'localizzazioni': LocalizzazioniToJSON(value.localizzazioni),
        'protocolliAperti': ProtocolliApertiToJSON(value.protocolliAperti),
        'protocolliCompleti': ProtocolliCompletiToJSON(value.protocolliCompleti),
        'societaQuotata': SocietaQuotataToJSON(value.societaQuotata),
        'elencoSoci': ElencoSociToJSON(value.elencoSoci),
        'tabellaElencoSoci': TabellaElencoSociToJSON(value.tabellaElencoSoci),
        'annotazioniLibroSoci': AnnotazioniLibroSociToJSON(value.annotazioniLibroSoci),
        'trasferimentiQuote': TrasferimentiQuoteToJSON(value.trasferimentiQuote),
        'trasferimentoQuote': TrasferimentoQuoteToJSON(value.trasferimentoQuote),
        'sociTrasferimentoQuote': SociTrasferimentoQuoteToJSON(value.sociTrasferimentoQuote),
        'praticheSoggettiControllanti': PraticheSoggettiControllantiToJSON(value.praticheSoggettiControllanti),
        'partecipazioniSocieta': PartecipazioniSocietaToJSON(value.partecipazioniSocieta),
        'tabellaPartecipateImpresa': TabellaPartecipateImpresaToJSON(value.tabellaPartecipateImpresa),
        'mad': MadToJSON(value.mad),
        'trascrizioni': TrascrizioniToJSON(value.trascrizioni),
        'storiaCciaaProvenienza': StoriaCciaaProvenienzaToJSON(value.storiaCciaaProvenienza),
        'storiaSediPrecedenti': StoriaSediPrecedentiToJSON(value.storiaSediPrecedenti),
        'iscrizioneRi': IscrizioneRiToJSON(value.iscrizioneRi),
        'infoStatuto': InfoStatutoToJSON(value.infoStatuto),
        'retiImprese': RetiImpreseToJSON(value.retiImprese),
        'amministrazioneControllo': AmministrazioneControlloToJSON(value.amministrazioneControllo),
        'infoPatrimonialiFinanziarie': InfoPatrimonialiFinanziarieToJSON(value.infoPatrimonialiFinanziarie),
        'pattiParasociali': PattiParasocialiToJSON(value.pattiParasociali),
        'procedureConcorsuali': ProcedureConcorsualiToJSON(value.procedureConcorsuali),
        'variazioniFormaGiuridica': VariazioniFormaGiuridicaToJSON(value.variazioniFormaGiuridica),
        'infoSupplementariStoriche': InfoSupplementariStoricheToJSON(value.infoSupplementariStoriche),
        'taFusioniScissioniSubentri': TaFusioniScissioniSubentriToJSON(value.taFusioniScissioniSubentri),
    };
}


