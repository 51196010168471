import * as React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, } from 'react-native';
import { RootStackScreenProps, UserExtendedType } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import { SplashLogoScreen } from '../../../navigation/SplashLogoScreen';
import { ScrollView } from 'react-native';
import { AlertHelper } from '../../../helpers/AlertHelper'
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome } from '@expo/vector-icons';

import ModalInfo from '../../Modal/ModalSetWebSite';
import { customAccountResourceApi } from '../../../services/apis/ApiConfiguration';
import { RegistrationDTO, RegistrationDTOCompanyRoleEnum, RegistrationDTOTypeEnum, UserExtendedTypeEnum } from '../../../services/apis/generated';
import { BackgroundColorButtonDarkLight, BackgroundColorInputText, ColorLabel, ColorPlaceHolder, ColorTextInput, ColorTextLightGreen } from '../../../components/ColorTheme';
import Colors from "../../../../constants/Colors";
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";
import { ErrorHelper } from '@helpers/ErrorHelper';
import AppContext from '../../../navigation/AppContext';
import {useState} from "react";
import ModalSimpleError from "@screens/Modal/ModalSimpleError";
import {delay} from "@helpers/Sleep";


export default function RegistrationScreen({ navigation, route }: RootStackScreenProps<'Registration'>) {

  enum ValidationFields {
    firstName = "firstName",
    lastName = "lastName",
    companyName = "companyName",
    // street = "street",
    // city = "city",
    // zipCode = "zipCode",
    vatNumber = "vatNumber",
    sector = "sector",
    email = "email",
    login = "login",
    password = "password",
    telephone = "telephone",
  }

  let initErrorFields: { [key in ValidationFields]: [] } = {
    firstName: [],
    lastName: [],
    companyName: [],
    // street: [],
    // city: [],
    // zipCode: [],
    vatNumber: [],
    sector: [],
    email: [],
    login: [],
    password: [],
    telephone: [],
  }

  const [errorFields, setErrorFields] = React.useState(initErrorFields);
  const appContext = React.useContext(AppContext);
  const [modalError, setModalError] = React.useState<{ visible: boolean, msg: string }>({ visible: false, msg: "" });

  const [form, setForm] = React.useState<RegistrationDTO>({
    type: route.params.userType == UserExtendedTypeEnum.Company ? RegistrationDTOTypeEnum.Company : RegistrationDTOTypeEnum.Consultant,
    companyRole: RegistrationDTOCompanyRoleEnum.Administrator,
    email: '',
    login: '',
    firstName: '',
    password: '',
    lastName: '',
    telephone: '',
    langKey: 'it',
    street: '',
    city: '',
    zipCode: '',
    companyName: '',
    vatNumber: '',
    sector: '',
  });

  function handleChange(key: any) {

    return (event: any) => {
      const value = event;
      if (key == ValidationFields.email)
        setForm((form: any) => ({ ...form, login: value }));
      setForm((form: any) => ({ ...form, [key]: value }));
    }
  }

  function isPasswordValid(newPassword: string): boolean {
    var passw = /^(?=.{8,}$)(?=(?:.*?[A-Z]){1})(?=.*?[a-z])(?=(?:.*?[0-9]){1}).*$/;
    if (newPassword.match(passw)) {
      return true;
    }
    else {
      return false;
    }
  }

  const userType: UserExtendedType = {
    userType: route.params.userType,
  }
  const [show, setShow] = React.useState(true);
  const [iconState, setIcon] = React.useState('eye-slash');
  const [modalVisible, setModalVisible] = React.useState(false);

  function parseErrors(error: any) {
    let errorArray = error.fieldErrors

    let validationArray: { [key in ValidationFields]: [] } = {
      firstName: [],
      lastName: [],
      companyName: [],
      // street: [],
      // city: [],
      // zipCode: [],
      vatNumber: [],
      sector: [],
      email: [],
      login: [],
      password: [],
      telephone: [],
    }

    for (let index = 0; index < errorArray.length; index++) {
      let errorField = errorArray[index];

      for (let field in ValidationFields) {
        if (field == errorField.field) {
          console.log("validationFields", validationArray[field as ValidationFields])
          // Add only if doesn't exists already
          if (!(validationArray[field as ValidationFields].filter(e => e === field).length > 0)) {
            validationArray[field as ValidationFields].push(errorField.message as never)
          }
        }
      }
    }

    setErrorFields({ ...validationArray })
    console.log(validationArray)
  }

  function registrationSubmit() {
    setErrorFields(initErrorFields);
    BaseAPIManager.contextLoadingCallback(true);
    customAccountResourceApi.registerAccountCustom({ registrationDTO: form }).then((res) => {
      BaseAPIManager.contextLoadingCallback(false);
      setModalVisible(true);
      console.log('THEN')
    }).catch(async (error) => {
      await delay(1000);
      setModalError({ visible: true, msg: i18n.t("server_errors.USER_ALREADY_REGISTERED_TO_COMPANY") })
      console.log('CATCH')
      let errorJson = await ErrorHelper.getServerMessageFromJson(error);
      parseErrors(errorJson)
      if (errorJson.detail){
        appContext.setCustomModalError({ visible: true, msg: ErrorHelper.getTranslatedServerErrorMessage(errorJson) });
      }      
    })
  }

  function drawValidationErrors(field: ValidationFields): React.ReactNode {
    let validationErrors: any = [];

    for (const [key, value] of Object.entries(errorFields)) {
      if (key == field) {
        value.forEach((element) => {
          validationErrors.push(<Text style={styles.error} key={element} >{i18n.t("errors.validation.registration." + element)}</Text>)
        })
      }
    }

    if (validationErrors.length > 0) {
      return validationErrors
    } else {
      return null
    }
  }

  function modalExit(modalVisible: boolean) {
    setModalVisible(modalVisible);
    navigation.navigate('Login', userType)
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <ScrollView style={styles.container}>
        <View style={{ marginHorizontal: 20 }}>
          <SplashLogoScreen registration={true} />
          <View style={{ marginTop: 20 }}>
            <View style={styles.containerRow}>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.first_name')} *</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.first_name')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.firstName)}
                  style={styles.textInputRow}
                />
                {errorFields.firstName.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.firstName)}</View>}
              </View>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.last_name')} *</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.last_name')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.lastName)}
                  style={styles.textInputRow}
                />
                {errorFields.lastName.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.lastName)}</View>}
              </View>
            </View>
            <Text style={styles.labelTextInput}>{i18n.t('screens.signup.company_name')} *</Text>
            <TextInput
              placeholder={i18n.t('screens.signup.company_name')}
              placeholderTextColor={ColorPlaceHolder()}
              onChangeText={handleChange(ValidationFields.companyName)}
              style={styles.textInput}
            />
            {errorFields.companyName.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.companyName)}</View>}
            
            {/* <Text style={styles.labelTextInput}>{i18n.t('screens.signup.address')}</Text>
            <TextInput
              placeholder={i18n.t('screens.signup.address')}
              placeholderTextColor={ColorPlaceHolder()}
              onChangeText={handleChange(ValidationFields.street)}
              style={styles.textInput}
            />
            {errorFields.street.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.street)}</View>} */}
            
            {/* <View style={styles.containerRow}>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.town_city')}</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.town_city')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.city)}
                  style={styles.textInputRow}
                />
                {errorFields.city.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.city)}</View>}
              </View>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.postal_code')}</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.postal_code')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.zipCode)}
                  style={styles.textInputRow}
                />
                {errorFields.zipCode.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.zipCode)}</View>}
              </View>
            </View> */}

            <View style={styles.containerRow}>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.company_vat')} *</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.company_vat')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.vatNumber)}
                  style={styles.textInputRow}
                />
                {errorFields.vatNumber.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.vatNumber)}</View>}
              </View>
              <View style={{ flex: 0.48 }}>
                <Text style={styles.labelTextInput}>{i18n.t('screens.signup.sector')} *</Text>
                <TextInput
                  placeholder={i18n.t('screens.signup.sector')}
                  placeholderTextColor={ColorPlaceHolder()}
                  onChangeText={handleChange(ValidationFields.sector)}
                  style={styles.textInputRow}
                />
                {errorFields.sector.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.sector)}</View>}
              </View>
            </View>
            <Text style={styles.labelTextInput}>{i18n.t('screens.signup.mail_address')} *</Text>
            <TextInput
              placeholder={i18n.t('screens.signup.mail_address')}
              placeholderTextColor={ColorPlaceHolder()}
              onChangeText={handleChange(ValidationFields.email)}
              style={styles.textInput}
              autoCapitalize="none"
              autoCorrect={false}
            />
            {errorFields.email.length > 0 && drawValidationErrors(ValidationFields.email)}

            <Text style={styles.labelTextInput}>{i18n.t('login.password')} *</Text>
            <View
              style={[styles.textInput, { flexDirection: 'row', padding: 13 }]}>
              <TextInput
                placeholder={i18n.t('login.password')}
                placeholderTextColor={ColorPlaceHolder()}
                autoCorrect={false}
                onChangeText={handleChange(ValidationFields.password)}
                secureTextEntry={show}
                style={{ alignSelf: 'flex-start', color: 'white', flex: 0.9 }}
              >
              </TextInput>
              <TouchableOpacity
                style={styles.buttonShowHide}
                activeOpacity={0.5}
                onPressIn={() => { setShow(false), setIcon('eye') }}
                onPressOut={() => { setShow(true), setIcon('eye-slash') }}
              >
                <Icon name={iconState} color={ColorPlaceHolder()} />
              </TouchableOpacity>
            </View>
            {errorFields.password.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.password)}</View>}
            <Text style={styles.labelTextInput}>{i18n.t('screens.signup.phone')}</Text>
            <TextInput
              placeholder={i18n.t('screens.signup.phone')}
              placeholderTextColor={ColorPlaceHolder()}
              onChangeText={handleChange(ValidationFields.telephone)}
              style={styles.textInput}
            />
            {errorFields.telephone.length > 0 && <View style={styles.errorBox}>{drawValidationErrors(ValidationFields.telephone)}</View>}
            <TouchableOpacity
              style={styles.button}
              activeOpacity={0.5}
              onPress={() => { registrationSubmit() }}
            >
              <Text style={styles.text}>{i18n.t('screens.login.registration')}</Text>
            </TouchableOpacity>
            {/* {<TouchableOpacity
              style={styles.button}
              activeOpacity={0.5}
              onPress={() => { console.log(form) }}
            >
              <Text style={styles.text}>Stampa stato</Text>
            </TouchableOpacity>} */}
          </View>
        </View>
        <View style={styles.viewLogin}>
          <Text
            style={styles.textLogin}
          >
            {i18n.t('screens.signup.login_ask')}
            <Text
              style={[styles.textLogin, { fontWeight: 'bold', color: ColorTextLightGreen() }
              ]}
              onPress={() => navigation.navigate('Login', userType)}
            >
              {' ' + i18n.t('screens.signup.login')}</Text>
          </Text>
        </View>

      </ScrollView>
      {modalError.visible && <ModalSimpleError msg="errore" visible={(visible) => setModalError({ visible: visible, msg: '' })} />}
      {modalVisible && <ModalInfo message={"registration"} setVisible={(modalVisible) => (modalVisible != null ? modalExit(modalVisible) : null)} />}
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  containerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  labelTextInput: {
    alignSelf: 'flex-start',
    color: ColorLabel(),
    marginBottom: 5,
    fontFamily: 'poppins',
  },
  textInput: {
    backgroundColor: BackgroundColorInputText(),
    padding: 13,
    color: ColorTextInput(),
    borderRadius: 5,
    marginBottom: 15,
    fontFamily: 'poppins',
  },
  error: {
    color: "#ff3333",
    fontSize: 12,
    marginHorizontal: 4
  },
  errorBox: {
    marginBottom: 10
  },
  buttonShowHide: {
    alignSelf: 'center',
    flex: 0.1,
  },
  textInputRow: {
    flex: 0.49,
    backgroundColor: BackgroundColorInputText(),
    padding: 13,
    borderRadius: 5,
    marginBottom: 15,
    color: ColorTextInput(),
    fontFamily: 'poppins',
  },
  button: {
    marginTop: 30,
    backgroundColor: BackgroundColorButtonDarkLight(),
    padding: 20,
    borderRadius: 5,
    fontFamily: 'poppins',
    marginBottom: 30
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: ColorTextInput(),
  },
  viewLogin: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    flex: 0.1,
    marginBottom: 10
  },
  textLogin: {
    color: 'white',
    fontFamily: 'poppins',
  }
});

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function Icon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'] | any;
  color: string;
}) {
  return <FontAwesome size={16} style={{ justifyContent: 'flex-start' }} {...props} />;
}


