/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoAmministratori,
    InfoAmministratoriFromJSON,
    InfoAmministratoriFromJSONTyped,
    InfoAmministratoriToJSON,
    InfoPersone,
    InfoPersoneFromJSON,
    InfoPersoneFromJSONTyped,
    InfoPersoneToJSON,
    InfoSindaci,
    InfoSindaciFromJSON,
    InfoSindaciFromJSONTyped,
    InfoSindaciToJSON,
    Persona,
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersoneSede
 */
export interface PersoneSede {
    /**
     * 
     * @type {InfoAmministratori}
     * @memberof PersoneSede
     */
    infoAmministratori?: InfoAmministratori;
    /**
     * 
     * @type {InfoSindaci}
     * @memberof PersoneSede
     */
    infoSindaci?: InfoSindaci;
    /**
     * 
     * @type {InfoPersone}
     * @memberof PersoneSede
     */
    infoPersone?: InfoPersone;
    /**
     * 
     * @type {Array<Persona>}
     * @memberof PersoneSede
     */
    persona?: Array<Persona>;
}

export function PersoneSedeFromJSON(json: any): PersoneSede {
    return PersoneSedeFromJSONTyped(json, false);
}

export function PersoneSedeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersoneSede {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoAmministratori': !exists(json, 'infoAmministratori') ? undefined : InfoAmministratoriFromJSON(json['infoAmministratori']),
        'infoSindaci': !exists(json, 'infoSindaci') ? undefined : InfoSindaciFromJSON(json['infoSindaci']),
        'infoPersone': !exists(json, 'infoPersone') ? undefined : InfoPersoneFromJSON(json['infoPersone']),
        'persona': !exists(json, 'persona') ? undefined : ((json['persona'] as Array<any>).map(PersonaFromJSON)),
    };
}

export function PersoneSedeToJSON(value?: PersoneSede | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoAmministratori': InfoAmministratoriToJSON(value.infoAmministratori),
        'infoSindaci': InfoSindaciToJSON(value.infoSindaci),
        'infoPersone': InfoPersoneToJSON(value.infoPersone),
        'persona': value.persona === undefined ? undefined : ((value.persona as Array<any>).map(PersonaToJSON)),
    };
}


