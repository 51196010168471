/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PersonaRappresentante,
    PersonaRappresentanteFromJSON,
    PersonaRappresentanteFromJSONTyped,
    PersonaRappresentanteToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersoneRappresentanti
 */
export interface PersoneRappresentanti {
    /**
     * 
     * @type {Array<PersonaRappresentante>}
     * @memberof PersoneRappresentanti
     */
    personaRappresentante?: Array<PersonaRappresentante>;
    /**
     * 
     * @type {string}
     * @memberof PersoneRappresentanti
     */
    fpiuPersone?: string;
}

export function PersoneRappresentantiFromJSON(json: any): PersoneRappresentanti {
    return PersoneRappresentantiFromJSONTyped(json, false);
}

export function PersoneRappresentantiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersoneRappresentanti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personaRappresentante': !exists(json, 'personaRappresentante') ? undefined : ((json['personaRappresentante'] as Array<any>).map(PersonaRappresentanteFromJSON)),
        'fpiuPersone': !exists(json, 'fpiuPersone') ? undefined : json['fpiuPersone'],
    };
}

export function PersoneRappresentantiToJSON(value?: PersoneRappresentanti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personaRappresentante': value.personaRappresentante === undefined ? undefined : ((value.personaRappresentante as Array<any>).map(PersonaRappresentanteToJSON)),
        'fpiuPersone': value.fpiuPersone,
    };
}


