/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionSurveyScoreDTO,
    SectionSurveyScoreDTOFromJSON,
    SectionSurveyScoreDTOFromJSONTyped,
    SectionSurveyScoreDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessRatingTableDTO
 */
export interface BusinessRatingTableDTO {
    /**
     * 
     * @type {string}
     * @memberof BusinessRatingTableDTO
     */
    sectionTable?: BusinessRatingTableDTOSectionTableEnum;
    /**
     * 
     * @type {Array<SectionSurveyScoreDTO>}
     * @memberof BusinessRatingTableDTO
     */
    dataTable?: Array<SectionSurveyScoreDTO>;
}

/**
* @export
* @enum {string}
*/
export enum BusinessRatingTableDTOSectionTableEnum {
    Area = 'AREA',
    Agility = 'AGILITY',
    Growth = 'GROWTH',
    Attitude = 'ATTITUDE',
    Lean = 'LEAN'
}

export function BusinessRatingTableDTOFromJSON(json: any): BusinessRatingTableDTO {
    return BusinessRatingTableDTOFromJSONTyped(json, false);
}

export function BusinessRatingTableDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessRatingTableDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionTable': !exists(json, 'sectionTable') ? undefined : json['sectionTable'],
        'dataTable': !exists(json, 'dataTable') ? undefined : ((json['dataTable'] as Array<any>).map(SectionSurveyScoreDTOFromJSON)),
    };
}

export function BusinessRatingTableDTOToJSON(value?: BusinessRatingTableDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sectionTable': value.sectionTable,
        'dataTable': value.dataTable === undefined ? undefined : ((value.dataTable as Array<any>).map(SectionSurveyScoreDTOToJSON)),
    };
}


