/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnagraficaTitolare,
    AnagraficaTitolareFromJSON,
    AnagraficaTitolareFromJSONTyped,
    AnagraficaTitolareToJSON,
    QuoteDiritti,
    QuoteDirittiFromJSON,
    QuoteDirittiFromJSONTyped,
    QuoteDirittiToJSON,
} from './';

/**
 * 
 * @export
 * @interface Socio
 */
export interface Socio {
    /**
     * 
     * @type {AnagraficaTitolare}
     * @memberof Socio
     */
    anagraficaTitolare?: AnagraficaTitolare;
    /**
     * 
     * @type {QuoteDiritti}
     * @memberof Socio
     */
    quoteDiritti?: QuoteDiritti;
}

export function SocioFromJSON(json: any): Socio {
    return SocioFromJSONTyped(json, false);
}

export function SocioFromJSONTyped(json: any, ignoreDiscriminator: boolean): Socio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anagraficaTitolare': !exists(json, 'anagraficaTitolare') ? undefined : AnagraficaTitolareFromJSON(json['anagraficaTitolare']),
        'quoteDiritti': !exists(json, 'quoteDiritti') ? undefined : QuoteDirittiFromJSON(json['quoteDiritti']),
    };
}

export function SocioToJSON(value?: Socio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anagraficaTitolare': AnagraficaTitolareToJSON(value.anagraficaTitolare),
        'quoteDiritti': QuoteDirittiToJSON(value.quoteDiritti),
    };
}


