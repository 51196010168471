/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MestiereAa
 */
export interface MestiereAa {
    /**
     * 
     * @type {string}
     * @memberof MestiereAa
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof MestiereAa
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof MestiereAa
     */
    descrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof MestiereAa
     */
    ulterioreDescrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof MestiereAa
     */
    dtInizioAttivita?: string;
}

export function MestiereAaFromJSON(json: any): MestiereAa {
    return MestiereAaFromJSONTyped(json, false);
}

export function MestiereAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MestiereAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'ulterioreDescrizione': !exists(json, 'ulterioreDescrizione') ? undefined : json['ulterioreDescrizione'],
        'dtInizioAttivita': !exists(json, 'dtInizioAttivita') ? undefined : json['dtInizioAttivita'],
    };
}

export function MestiereAaToJSON(value?: MestiereAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'c': value.c,
        'descrizione': value.descrizione,
        'ulterioreDescrizione': value.ulterioreDescrizione,
        'dtInizioAttivita': value.dtInizioAttivita,
    };
}


