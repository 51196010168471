/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassificazioneAteco,
    ClassificazioneAtecoFromJSON,
    ClassificazioneAtecoFromJSONTyped,
    ClassificazioneAtecoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SintesiAttivita
 */
export interface SintesiAttivita {
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    attivitaPrevalenteR?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    attivitaEsercitataR?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    attivitaAgricolaR?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    attivitaAaR?: string;
    /**
     * 
     * @type {ClassificazioneAteco}
     * @memberof SintesiAttivita
     */
    classificazioneAteco?: ClassificazioneAteco;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    punteggioRatingLegalita?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    fimportExport?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    falbiRegistriAmbientali?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    fcertificazioniQualita?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    fattestazioniSoa?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    fcontrattoRete?: string;
    /**
     * 
     * @type {string}
     * @memberof SintesiAttivita
     */
    falbiRuoliLicenze?: string;
}

export function SintesiAttivitaFromJSON(json: any): SintesiAttivita {
    return SintesiAttivitaFromJSONTyped(json, false);
}

export function SintesiAttivitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SintesiAttivita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attivitaPrevalenteR': !exists(json, 'attivitaPrevalenteR') ? undefined : json['attivitaPrevalenteR'],
        'attivitaEsercitataR': !exists(json, 'attivitaEsercitataR') ? undefined : json['attivitaEsercitataR'],
        'attivitaAgricolaR': !exists(json, 'attivitaAgricolaR') ? undefined : json['attivitaAgricolaR'],
        'attivitaAaR': !exists(json, 'attivitaAaR') ? undefined : json['attivitaAaR'],
        'classificazioneAteco': !exists(json, 'classificazioneAteco') ? undefined : ClassificazioneAtecoFromJSON(json['classificazioneAteco']),
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'punteggioRatingLegalita': !exists(json, 'punteggioRatingLegalita') ? undefined : json['punteggioRatingLegalita'],
        'fimportExport': !exists(json, 'fimportExport') ? undefined : json['fimportExport'],
        'falbiRegistriAmbientali': !exists(json, 'falbiRegistriAmbientali') ? undefined : json['falbiRegistriAmbientali'],
        'fcertificazioniQualita': !exists(json, 'fcertificazioniQualita') ? undefined : json['fcertificazioniQualita'],
        'fattestazioniSoa': !exists(json, 'fattestazioniSoa') ? undefined : json['fattestazioniSoa'],
        'fcontrattoRete': !exists(json, 'fcontrattoRete') ? undefined : json['fcontrattoRete'],
        'falbiRuoliLicenze': !exists(json, 'falbiRuoliLicenze') ? undefined : json['falbiRuoliLicenze'],
    };
}

export function SintesiAttivitaToJSON(value?: SintesiAttivita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attivitaPrevalenteR': value.attivitaPrevalenteR,
        'attivitaEsercitataR': value.attivitaEsercitataR,
        'attivitaAgricolaR': value.attivitaAgricolaR,
        'attivitaAaR': value.attivitaAaR,
        'classificazioneAteco': ClassificazioneAtecoToJSON(value.classificazioneAteco),
        'dtInizio': value.dtInizio,
        'punteggioRatingLegalita': value.punteggioRatingLegalita,
        'fimportExport': value.fimportExport,
        'falbiRegistriAmbientali': value.falbiRegistriAmbientali,
        'fcertificazioniQualita': value.fcertificazioniQualita,
        'fattestazioniSoa': value.fattestazioniSoa,
        'fcontrattoRete': value.fcontrattoRete,
        'falbiRuoliLicenze': value.falbiRuoliLicenze,
    };
}


