/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PotenzeCerealiMacchinari,
    PotenzeCerealiMacchinariFromJSON,
    PotenzeCerealiMacchinariFromJSONTyped,
    PotenzeCerealiMacchinariToJSON,
    Stoccaggio,
    StoccaggioFromJSON,
    StoccaggioFromJSONTyped,
    StoccaggioToJSON,
} from './';

/**
 * 
 * @export
 * @interface Molini
 */
export interface Molini {
    /**
     * 
     * @type {PotenzeCerealiMacchinari}
     * @memberof Molini
     */
    potenzeCerealiMacchinari?: PotenzeCerealiMacchinari;
    /**
     * 
     * @type {Stoccaggio}
     * @memberof Molini
     */
    stoccaggio?: Stoccaggio;
    /**
     * 
     * @type {string}
     * @memberof Molini
     */
    categoria?: string;
}

export function MoliniFromJSON(json: any): Molini {
    return MoliniFromJSONTyped(json, false);
}

export function MoliniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Molini {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'potenzeCerealiMacchinari': !exists(json, 'potenzeCerealiMacchinari') ? undefined : PotenzeCerealiMacchinariFromJSON(json['potenzeCerealiMacchinari']),
        'stoccaggio': !exists(json, 'stoccaggio') ? undefined : StoccaggioFromJSON(json['stoccaggio']),
        'categoria': !exists(json, 'categoria') ? undefined : json['categoria'],
    };
}

export function MoliniToJSON(value?: Molini | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'potenzeCerealiMacchinari': PotenzeCerealiMacchinariToJSON(value.potenzeCerealiMacchinari),
        'stoccaggio': StoccaggioToJSON(value.stoccaggio),
        'categoria': value.categoria,
    };
}


