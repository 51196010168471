// 
// 
//
import { BackgroundColorButtonGreyLight, ColorTextLightGreen } from '@components/ColorTheme';
import { AlertHelper } from '@helpers/AlertHelper';
import { useFocusEffect } from '@react-navigation/native';
import ModalDeleteUser from '@screens/Modal/ModalDeleteUser';
import { customUserConsultantInviteResourceApi, customUserConsultantResourceApi } from '@services/apis/ApiConfiguration';
import { UserConsultant } from '@services/apis/generated/models/UserConsultant';
import { UserConsultantInvite, UserConsultantInviteCurrentStateEnum } from '@services/apis/generated/models/UserConsultantInvite';
import { UserConsultantInviteDTORoleEnum } from '@services/apis/generated/models/UserConsultantInviteDTO';
import { UserConsultantInviteStateStateEnum } from '@services/apis/generated/models/UserConsultantInviteState';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View, ScrollView } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { RootStackScreenProps, } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';
import RequestComponent from './RequestComponent';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";

interface State {
    firstName?: string,
    corporateRole?: UserConsultantInviteDTORoleEnum,
    email?: string
}
export default function UserManagement({ navigation, route }: RootStackScreenProps<'UserManagement'>) {

    const [myUserConsultant, setMyUserConsultant] = React.useState<UserConsultant[]>()
    const [myUserConsultantInvite, setMyUserConsultantInvite] = React.useState<UserConsultantInvite[]>()
    const sharedContext = React.useContext(SharedContext);
    const [modal, setModal] = React.useState<boolean>(false);
    const [collapsed, setCollapsed] = React.useState<boolean>(true);
    const [role, setRole] = React.useState<String>("Role");
    const [state, setState] = React.useState<State>({})

    const [userConsultantInvite, setUserConsultantInvite] = React.useState<UserConsultantInvite[]>();

    useFocusEffect(
        useCallback(() => {
            if (sharedContext.myCompanySelected) {
                customUserConsultantResourceApi.getAllUserByConsultantId({ consultantId: sharedContext.myCompanySelected?.id! }).then((res) => {
                    setMyUserConsultant(res);
                }).catch(error => AlertHelper.showSimpleErrorAlert());
                requestList()
            }
        }, [])
    );

    const requestList = () => {
        BaseAPIManager.contextLoadingCallback(true);
        customUserConsultantInviteResourceApi.getAllUserConsultantInviteByConsultant({ id: sharedContext.myCompanySelected?.id! }).then(res => {
            setMyUserConsultantInvite(res);
            BaseAPIManager.contextLoadingCallback(false);
        }).catch(error => AlertHelper.showSimpleErrorAlert());
    }


    let refName: TextInput | null
    let refEmail: TextInput | null

    function toggleSave() {
        setRole('Role')
        refName?.clear()
        refEmail?.clear()
        if (state.firstName && state.corporateRole && state.corporateRole) {
            customUserConsultantInviteResourceApi.createUserConsultantInviteWithDefaultState({
                userConsultantInviteDTO: {
                    name: state.firstName,
                    email: state.email,
                    consultant: sharedContext.myCompanySelected,
                    role: state.corporateRole
                }
            }).then((userConsultantInvite) => {
                requestList()
            }).catch(error => AlertHelper.showSimpleErrorAlert());
        }
    }

    function toggleDelete(userConsultant: UserConsultant) {
        setModal(true)
    }
    const removeUser = (userConsultant: UserConsultant) => {
        customUserConsultantResourceApi.deleteUserConsultantForLoggedUser({ id: userConsultant.id! }).then(() => {
            customUserConsultantResourceApi.getAllUserByConsultantId({ consultantId: sharedContext.myCompanySelected?.id! }).then((res) => {
                setMyUserConsultant(res);
            }).catch(error => AlertHelper.showSimpleErrorAlert());
            customUserConsultantInviteResourceApi.getAllUserConsultantInviteByConsultant({ id: userConsultant.consultant?.id! }).then((userConsultantInvites) => {
                let userConsultantInvite = userConsultantInvites.find((invitesUserConsultant) => invitesUserConsultant.user?.id == userConsultant.user?.id
                    && invitesUserConsultant.currentState != UserConsultantInviteCurrentStateEnum.Rejected)
                customUserConsultantInviteResourceApi.updateUserConsultantInviteState1({ id: userConsultantInvite?.id!, userConsultantInviteState: { state: UserConsultantInviteStateStateEnum.Rejected } })
                    .then(() => {
                        requestList()
                    }).catch(error => AlertHelper.showSimpleErrorAlert());
            }).catch(error => AlertHelper.showSimpleErrorAlert());
        }).catch(error => AlertHelper.showSimpleErrorAlert());
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => {
                        navigation.goBack()
                    }}
                >
                    <Icon name={'angle-left'} size={24} color={'#00CC83'} />
                </TouchableOpacity>
                <Text style={[hykeeStyle.title, { marginVertical: 20 }]}>{i18n.t('screens.profile.add_profiles')}</Text>
            </View>
            <ScrollView style={styles.containerScroll}>
                <View style={styles.col}>
                    <Text style={styles.titleManage}>
                        {i18n.t('screens.add_profiles.title')}
                    </Text>
                    <View style={styles.header}>
                        <Text style={styles.textheader}>
                            {i18n.t('screens.add_profiles.header_name')}
                        </Text>
                        <Text style={styles.textheader}>
                            {i18n.t('screens.add_profiles.header_email')}
                        </Text>
                        <Text style={styles.textheader}>
                            {i18n.t('screens.add_profiles.header_role')}
                        </Text>
                        <Text style={styles.textheader}>
                            {i18n.t('screens.add_profiles.header_action')}
                        </Text>
                    </View>
                    {
                        myUserConsultant && myUserConsultant.length > 1 && myUserConsultant?.map((userConsultant, index: number) => {
                            return (
                                <View key={userConsultant.id}>
                                    {userConsultant.user?.userExtended?.id != sharedContext.userExtendedMe?.id &&
                                        <View style={index % 2 == 1 ? [styles.row, { backgroundColor: '#292929' }] : [styles.row, { backgroundColor: '#303030' }]}>
                                            <Text style={styles.textheader}>
                                                {userConsultant.user?.firstName + ' ' + userConsultant.user?.lastName}
                                            </Text>
                                            <Text style={styles.textheader}>
                                                {userConsultant.user?.email}
                                            </Text>
                                            <Text style={styles.textheader}>
                                                {userConsultant.role}
                                            </Text>
                                            <TouchableOpacity
                                                style={styles.textheader}
                                                onPress={() => {
                                                    toggleDelete(userConsultant)
                                                }}
                                            >
                                                <Text style={styles.textbutton}>
                                                    {i18n.t('screens.add_profiles.cancel')}
                                                </Text>
                                            </TouchableOpacity>
                                            {modal &&
                                                <ModalDeleteUser
                                                    username={userConsultant.user?.firstName!}
                                                    action={(remove) => {
                                                        if (remove) {
                                                            removeUser(userConsultant);
                                                            setModal(false)
                                                        } else {
                                                            setModal(false)
                                                        }
                                                    }}
                                                />}
                                        </View>}
                                </View>
                            )
                        })
                    }
                    {
                        myUserConsultant && myUserConsultant.length == 1 &&
                        <View style={[styles.row, { backgroundColor: '#292929' }]}>
                            <Text style={styles.textNoConsultant}>
                                {i18n.t('screens.add_profiles.no_consultant')}
                            </Text>
                        </View>
                    }

                    <View style={styles.inputCol}>
                        <View style={[styles.inputRow]}>
                            <View style={{ flex: 0.49 }}>
                                <TextInput
                                    placeholder={i18n.t('screens.add_profiles.header_name')}
                                    placeholderTextColor='#737373'
                                    ref={(ref) => {
                                        refName = ref
                                    }}
                                    onChangeText={(name) => {
                                        state.firstName = name
                                    }}
                                    style={styles.textInputRow}
                                />
                            </View>

                            <View style={{ flex: 0.49 }}>
                                <TouchableOpacity
                                    style={[styles.inputCollapsible, { flexDirection: 'row', alignItems: 'center' }]}
                                    onPress={() => setCollapsed(!collapsed)}>
                                    <Text style={role != 'Role' ? styles.textRow : styles.textRowPlaceholder}>{role}</Text>
                                    <Icon name="angle-down" size={24} color={'#737373'} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={[styles.inputRow, { flex: 1, justifyContent: 'flex-end' }]}>
                            <View style={{ flex: 0.49, marginBottom: 10, }}>
                                <Collapsible
                                    duration={500}
                                    easing={'easeInBounce'}
                                    collapsed={collapsed}
                                    style={styles.containerCollapse}
                                >
                                    {[UserConsultantInviteDTORoleEnum.Analyst, UserConsultantInviteDTORoleEnum.Sales].map((roleSelected, key) => {
                                        return (
                                            <TouchableOpacity
                                                key={key}
                                                style={styles.containerTextCollapse}
                                                onPress={() => {
                                                    setState({ firstName: state.firstName, email: state.email, corporateRole: roleSelected })
                                                    setCollapsed(!collapsed)
                                                    setRole(roleSelected)
                                                }}>
                                                <Text style={styles.textInputRow}>
                                                    {roleSelected}
                                                </Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </Collapsible>
                            </View>
                        </View>
                        <View style={[styles.inputRow]}>
                            <View style={{ flex: 0.74 }}>
                                <TextInput
                                    placeholder={i18n.t('screens.add_profiles.header_email')}
                                    placeholderTextColor='#737373'
                                    ref={(ref) => {
                                        refEmail = ref
                                    }}
                                    onChangeText={(email) => {
                                        state.email = email
                                    }}
                                    style={styles.textInputRow}
                                />
                            </View>
                            <View style={{ flex: 0.24 }}>
                                <TouchableOpacity
                                    style={styles.buttonAdd}
                                    activeOpacity={0.5}
                                    onPress={() => {
                                        toggleSave()
                                    }}
                                >
                                    <Icon name={'send'} size={22} color={ColorTextLightGreen()} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>

                <View>
                    <View style={styles.col}>
                        <Text style={styles.titleManage}>

                            {i18n.t('components.link_companies_data.title_pending')}
                        </Text>
                    </View>
                    {myUserConsultantInvite
                        && myUserConsultantInvite.length > 0
                        && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Pending) != undefined ?
                        myUserConsultantInvite?.map((userConsultantInvite, key) => {
                            return (
                                <View key={key}>
                                    {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Pending &&
                                        <RequestComponent request={userConsultantInvite} isUser={false} refreshRequestList={() => { requestList() }} />}
                                </View>
                            )
                        })
                        :
                        <View style={styles.containerList}>
                            <Text style={styles.textLabel}>

                                {i18n.t('components.link_companies_data.no_pending')}
                            </Text>
                        </View>
                    }
                </View>
                <View>
                    <View style={styles.col}>
                        <Text style={styles.titleManage}>

                            {i18n.t('components.link_companies_data.title_accepted')}
                        </Text>
                    </View>
                    {myUserConsultantInvite
                        && myUserConsultantInvite.length > 0
                        && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Accepted) != undefined ?
                        myUserConsultantInvite?.map((userConsultantInvite, key) => {
                            return (
                                <View key={key}>
                                    {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Accepted &&
                                        <RequestComponent request={userConsultantInvite} isUser={false} refreshRequestList={() => { requestList() }} />}
                                </View>
                            )
                        })
                        :
                        <View style={styles.containerList}>
                            <Text style={styles.textLabel}>

                                {i18n.t('components.link_companies_data.no_accepted')}
                            </Text>
                        </View>
                    }
                </View>
                {/* <View>
                    <View style={styles.col}>
                        <Text style={styles.titleManage}>

                            {i18n.t('components.link_companies_data.title_rejected')}
                        </Text>
                    </View>
                    {myUserConsultantInvite
                        && myUserConsultantInvite.length > 0
                        && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Rejected) != undefined ?
                        myUserConsultantInvite?.map((userConsultantInvite, key) => {
                            return (
                                <View key={key}>
                                    {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Rejected &&
                                        <RequestComponent request={userConsultantInvite} isUser={false} refreshRequestList={() => { requestList() }} />}
                                </View>
                            )
                        })
                        :
                        <View style={styles.containerList}>
                            <Text style={styles.textLabel}>

                                {i18n.t('components.link_companies_data.no_rejected')}
                            </Text>
                        </View>
                    }
                </View> */}

            </ScrollView>

        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 0.06,
        marginTop: 50,
        flexDirection: 'row',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    containerList: {
        marginBottom: 20,
    },
    textLabel: {
        marginTop: 10,
        marginHorizontal: 15,
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    containerScroll: {
        flex: 0.9,
        marginTop: 20,
        marginBottom: 40,
        marginHorizontal: 15,
    },
    textNoConsultant: {
        textAlign: 'center',
        fontSize: 15,
        flex: 1,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    header: {
        flexDirection: 'row',
        backgroundColor: '#404040',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textbutton: {
        textAlign: 'center',
        fontSize: 12,
        fontFamily: 'poppins',
        color: '#FF3C3C'
    },
    textInputRow: {
        backgroundColor: '#464646',
        borderRadius: 5,
        fontSize: 12,
        padding: 10,
        color: 'white',
        fontFamily: 'poppins',
    },
    textAdd: {
        fontSize: 12,
        textAlign: 'center',
        color: ColorTextLightGreen(),
    },
    buttonAdd: {
        backgroundColor: '#222222',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'poppins',
        flex: 1
    },
    inputCollapsible: {
        borderRadius: 5,
        flex: 1,
        padding: 7,
        backgroundColor: BackgroundColorButtonGreyLight(),
    },
    textRow: {
        paddingLeft: 10,
        flex: 0.9,
        fontSize: 12,
        color: 'white',
        fontFamily: 'poppins',
    },
    textRowPlaceholder: {
        paddingLeft: 10,
        flex: 0.9,
        fontSize: 12,
        color: '#737373',
        fontFamily: 'poppins',
    },
    containerCollapse: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        height: 'auto',
    },
    containerTextCollapse: {
        backgroundColor: BackgroundColorButtonGreyLight(),
    },
    textheader: {
        flex: 0.25,
        textAlign: 'center',
        fontSize: 15,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    titleManage: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        marginBottom: 10,
    },
    title: {
        color: '#00CC83',
        fontSize: 20,
        padding: 10,
        fontFamily: 'poppins',
    },
    goBack: {
        padding: 10,
        marginRight: 10
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    col: {
        flexDirection: 'column'
    },
    inputCol: {
        flexDirection: 'column',
        marginVertical: 20,
    },
});
