/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Versato
 */
export interface Versato {
    /**
     * 
     * @type {string}
     * @memberof Versato
     */
    ammontare?: string;
    /**
     * 
     * @type {string}
     * @memberof Versato
     */
    ammontareConvertitoInEuro?: string;
}

export function VersatoFromJSON(json: any): Versato {
    return VersatoFromJSONTyped(json, false);
}

export function VersatoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Versato {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ammontare': !exists(json, 'ammontare') ? undefined : json['ammontare'],
        'ammontareConvertitoInEuro': !exists(json, 'ammontareConvertitoInEuro') ? undefined : json['ammontareConvertitoInEuro'],
    };
}

export function VersatoToJSON(value?: Versato | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ammontare': value.ammontare,
        'ammontareConvertitoInEuro': value.ammontareConvertitoInEuro,
    };
}


