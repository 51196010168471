/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Mad,
    MadFromJSON,
    MadFromJSONTyped,
    MadToJSON,
    Trascrizioni,
    TrascrizioniFromJSON,
    TrascrizioniFromJSONTyped,
    TrascrizioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface StoriaCciaaProvenienza
 */
export interface StoriaCciaaProvenienza {
    /**
     * 
     * @type {Mad}
     * @memberof StoriaCciaaProvenienza
     */
    mad?: Mad;
    /**
     * 
     * @type {Trascrizioni}
     * @memberof StoriaCciaaProvenienza
     */
    trascrizioni?: Trascrizioni;
    /**
     * 
     * @type {string}
     * @memberof StoriaCciaaProvenienza
     */
    cciaa?: string;
}

export function StoriaCciaaProvenienzaFromJSON(json: any): StoriaCciaaProvenienza {
    return StoriaCciaaProvenienzaFromJSONTyped(json, false);
}

export function StoriaCciaaProvenienzaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoriaCciaaProvenienza {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mad': !exists(json, 'mad') ? undefined : MadFromJSON(json['mad']),
        'trascrizioni': !exists(json, 'trascrizioni') ? undefined : TrascrizioniFromJSON(json['trascrizioni']),
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
    };
}

export function StoriaCciaaProvenienzaToJSON(value?: StoriaCciaaProvenienza | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mad': MadToJSON(value.mad),
        'trascrizioni': TrascrizioniToJSON(value.trascrizioni),
        'cciaa': value.cciaa,
    };
}


