/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolliRi,
    ProtocolliRiFromJSON,
    ProtocolliRiFromJSONTyped,
    ProtocolliRiToJSON,
    ProtocolliRs,
    ProtocolliRsFromJSON,
    ProtocolliRsFromJSONTyped,
    ProtocolliRsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Trascrizioni
 */
export interface Trascrizioni {
    /**
     * 
     * @type {ProtocolliRs}
     * @memberof Trascrizioni
     */
    protocolliRs?: ProtocolliRs;
    /**
     * 
     * @type {ProtocolliRi}
     * @memberof Trascrizioni
     */
    protocolliRi?: ProtocolliRi;
}

export function TrascrizioniFromJSON(json: any): Trascrizioni {
    return TrascrizioniFromJSONTyped(json, false);
}

export function TrascrizioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trascrizioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolliRs': !exists(json, 'protocolliRs') ? undefined : ProtocolliRsFromJSON(json['protocolliRs']),
        'protocolliRi': !exists(json, 'protocolliRi') ? undefined : ProtocolliRiFromJSON(json['protocolliRi']),
    };
}

export function TrascrizioniToJSON(value?: Trascrizioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolliRs': ProtocolliRsToJSON(value.protocolliRs),
        'protocolliRi': ProtocolliRiToJSON(value.protocolliRi),
    };
}


