import React from "react";
import {View, Text} from "react-native"
import Svg, {Path, Circle} from "react-native-svg"


interface SuccessModalIconProps{
    width?: number,
    height?: number,
}

const SuccessModalIcon = (props:SuccessModalIconProps) => {
    return(
        <Svg width={props.width} height={props.height}  viewBox="0 0 30.71 12.36">
            <Path fill='#00cc83' d="M20.46,15.78a.31.31,0,0,0-.44,0l-3.73,3.71L15,18a.32.32,0,0,0-.44,0,.31.31,0,0,0,0,.44l1.56,1.7a.33.33,0,0,0,.23.1h0a.31.31,0,0,0,.22-.09l4-3.94A.31.31,0,0,0,20.46,15.78Z" transform="translate(-1.64 -11.11)"/>
            <Path fill='#00cc83' d="M20.38,18.46a.31.31,0,0,0-.31.31,3.5,3.5,0,1,1-3.5-3.5.31.31,0,1,0,0-.62,4.12,4.12,0,1,0,4.12,4.12A.31.31,0,0,0,20.38,18.46Z" transform="translate(-1.64 -11.11)"/>
            <Circle fill='#bccbd6' fillOpacity='0.48' cx="0.26" cy="2.82" r="0.26"/>
            <Circle fill='#bccbd6' fillOpacity='0.48' cx="2.6" cy="1.65" r="0.26"/>
            <Path fill='#37c699' d="M4.31,14.7H3.82v.5H3.63v-.5H3.14v-.17h.49V14h.19v.5h.49Z" transform="translate(-1.64 -11.11)"/>
            <Circle fill='#bccbd6' fillOpacity='0.48' cx="28.06" cy="8.25" r="0.32"/>
            <Circle fill='#37c699' cx="28.28" cy="11.22" r="0.32"/>
            <Path fill='#e8e8e8' d="M32.36,22.56l-.47.31.31.47-.18.13L31.71,23l-.47.3-.11-.16.46-.31-.31-.47.18-.13.32.48.46-.31Z" transform="translate(-1.64 -11.11)"/>
            <Circle fill='#e8e8e8' cx="23.08" cy="2.39" r="0.31"/>
            <Circle fill='#bccbd6' fillOpacity='0.48' cx="23.88" cy="0.49" r="0.49"/>
            <Path fill='#77d79a' d="M27.77,13.21h-.63v.64h-.25v-.64h-.62V13h.62v-.64h.25V13h.63Z" transform="translate(-1.64 -11.11)"/>
        </Svg>

    )
}
export default SuccessModalIcon