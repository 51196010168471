/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Descrizioni,
    DescrizioniFromJSON,
    DescrizioniFromJSONTyped,
    DescrizioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface FondoConsortile
 */
export interface FondoConsortile {
    /**
     * 
     * @type {Descrizioni}
     * @memberof FondoConsortile
     */
    descrizioni?: Descrizioni;
    /**
     * 
     * @type {string}
     * @memberof FondoConsortile
     */
    valuta?: string;
    /**
     * 
     * @type {string}
     * @memberof FondoConsortile
     */
    ammontare?: string;
    /**
     * 
     * @type {string}
     * @memberof FondoConsortile
     */
    ammontareConvertitoInEuro?: string;
    /**
     * 
     * @type {string}
     * @memberof FondoConsortile
     */
    cvaluta?: string;
}

export function FondoConsortileFromJSON(json: any): FondoConsortile {
    return FondoConsortileFromJSONTyped(json, false);
}

export function FondoConsortileFromJSONTyped(json: any, ignoreDiscriminator: boolean): FondoConsortile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'descrizioni': !exists(json, 'descrizioni') ? undefined : DescrizioniFromJSON(json['descrizioni']),
        'valuta': !exists(json, 'valuta') ? undefined : json['valuta'],
        'ammontare': !exists(json, 'ammontare') ? undefined : json['ammontare'],
        'ammontareConvertitoInEuro': !exists(json, 'ammontareConvertitoInEuro') ? undefined : json['ammontareConvertitoInEuro'],
        'cvaluta': !exists(json, 'cvaluta') ? undefined : json['cvaluta'],
    };
}

export function FondoConsortileToJSON(value?: FondoConsortile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'descrizioni': DescrizioniToJSON(value.descrizioni),
        'valuta': value.valuta,
        'ammontare': value.ammontare,
        'ammontareConvertitoInEuro': value.ammontareConvertitoInEuro,
        'cvaluta': value.cvaluta,
    };
}


