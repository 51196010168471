/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CancellazioneAa,
    CancellazioneAaFromJSON,
    CancellazioneAaFromJSONTyped,
    CancellazioneAaToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttivitaNoAa
 */
export interface AttivitaNoAa {
    /**
     * 
     * @type {Array<string>}
     * @memberof AttivitaNoAa
     */
    descrizione?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AttivitaNoAa
     */
    informazioniSupplementariAa?: string;
    /**
     * 
     * @type {CancellazioneAa}
     * @memberof AttivitaNoAa
     */
    cancellazioneAa?: CancellazioneAa;
    /**
     * 
     * @type {string}
     * @memberof AttivitaNoAa
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof AttivitaNoAa
     */
    categoria?: string;
    /**
     * 
     * @type {string}
     * @memberof AttivitaNoAa
     */
    ccategoria?: string;
}

export function AttivitaNoAaFromJSON(json: any): AttivitaNoAa {
    return AttivitaNoAaFromJSONTyped(json, false);
}

export function AttivitaNoAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttivitaNoAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'informazioniSupplementariAa': !exists(json, 'informazioniSupplementariAa') ? undefined : json['informazioniSupplementariAa'],
        'cancellazioneAa': !exists(json, 'cancellazioneAa') ? undefined : CancellazioneAaFromJSON(json['cancellazioneAa']),
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'categoria': !exists(json, 'categoria') ? undefined : json['categoria'],
        'ccategoria': !exists(json, 'ccategoria') ? undefined : json['ccategoria'],
    };
}

export function AttivitaNoAaToJSON(value?: AttivitaNoAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'descrizione': value.descrizione,
        'informazioniSupplementariAa': value.informazioniSupplementariAa,
        'cancellazioneAa': CancellazioneAaToJSON(value.cancellazioneAa),
        'dtInizio': value.dtInizio,
        'categoria': value.categoria,
        'ccategoria': value.ccategoria,
    };
}


