import React from "react";
import {View, Text} from "react-native"
import Svg, {Path} from "react-native-svg"


interface SearchImageSvgProps{
    width?: number,
    height?: number,
}

const SearchImageSvg = (props:SearchImageSvgProps) => {
    return(
        <Svg width={props.width} height={props.height} viewBox="0 0 341 267" fill="none" >
            <Path d="M191.21 33.6846H229.849C229.849 33.6846 226.435 27.4739 216.486 28.3612C206.991 29.2218 206.507 25.3091 201.675 24.8122C196.843 24.3154 192.82 28.0595 191.21 33.6846Z" fill="#545454"/>
            <Path d="M264.231 230.635C255.419 218.091 258.252 201.773 263.662 188.14C264.564 185.864 265.186 183.351 267 181.668C268.814 179.986 271.852 179.587 273.559 181.302C274.975 182.714 275.008 184.979 275.362 186.964C275.85 189.62 277.074 192.084 278.893 194.072C280.074 195.366 281.716 196.477 283.444 196.229C285.816 195.841 287.029 193.187 287.845 190.836L292.353 178.001C293.039 175.662 294.094 173.448 295.477 171.444C296.169 170.444 297.092 169.628 298.167 169.064C299.242 168.501 300.436 168.207 301.648 168.208C304.031 168.391 306.264 170.613 305.942 173.029C305.69 174.006 305.371 174.965 304.987 175.898C304.801 176.369 304.758 176.884 304.863 177.379C304.968 177.874 305.216 178.327 305.577 178.681C306.029 178.906 306.531 179.01 307.035 178.983C307.539 178.957 308.027 178.801 308.453 178.53C311.58 176.913 314.42 174.788 316.858 172.242C319.299 169.702 322.03 167.461 324.994 165.565C328.032 163.807 331.702 162.88 335.03 163.883C338.357 164.886 341.116 168.197 340.783 171.681C340.45 175.165 337.402 177.807 334.429 179.684C330.013 182.513 325.211 184.682 320.175 186.123C319.243 186.279 318.384 186.724 317.717 187.395C316.643 188.744 317.566 190.782 318.887 191.828C320.379 193.015 322.289 193.5 323.996 194.363C325.702 195.226 327.334 196.693 327.409 198.677C327.495 201.352 324.672 203.099 322.171 204.016C317.174 205.842 311.903 206.794 306.586 206.831C304.686 206.684 302.776 206.888 300.951 207.435C299.18 208.125 297.623 209.894 297.859 211.749C298.096 213.604 300.006 214.985 301.884 215.244C303.784 215.369 305.691 215.333 307.584 215.136C311.137 215.082 315.098 216.765 316.171 220.205C316.448 221.365 316.454 222.573 316.187 223.735C315.921 224.898 315.39 225.982 314.636 226.903C313.123 228.728 311.257 230.225 309.151 231.303C302.043 235.511 293.949 237.745 285.698 237.775C277.519 237.678 270.542 235.92 264.231 230.656" fill="#545454"/>
            <Path d="M200.526 19.7088L137.427 19.6113L137.119 219.561L200.218 219.658L200.526 19.7088Z" fill="#484848"/>
            <Path d="M200.523 19.723L168.973 19.6743L168.666 219.085L200.215 219.134L200.523 19.723Z" fill="#848484"/>
            <Path d="M166.949 0.0344061L144.665 0L144.635 19.6246L166.919 19.659L166.949 0.0344061Z" fill="#484848"/>
            <Path d="M166.943 0.0419025L161.625 0.0336914L161.595 19.6583L166.913 19.6665L166.943 0.0419025Z" fill="#848484"/>
            <Path d="M132.186 19.5799L132.191 18.4439L202.386 18.5481L202.382 19.684" fill="#525252"/>
            <Path d="M155.192 28.2075L149.857 28.1992L149.84 39.4553L155.174 39.4635L155.192 28.2075Z" fill="#7C7C7C"/>
            <Path d="M168.946 43.9629C168.921 44.0743 161.879 44.1657 153.171 44.1511C144.464 44.1365 137.415 43.9589 137.383 43.9518C137.352 43.9447 144.451 43.7489 153.158 43.7636C161.865 43.7782 168.957 43.7647 168.946 43.9629Z" fill="#242424"/>
            <Path d="M187.071 28.1948L181.736 28.1875L181.721 39.4435L187.055 39.4509L187.071 28.1948Z" fill="#CECECE"/>
            <Path d="M200.785 43.9356C200.76 44.0471 193.718 44.1385 185.01 44.1239C176.303 44.1092 169.236 44.0113 169.222 43.9246C169.208 43.8378 176.29 43.7217 184.997 43.7363C193.704 43.751 200.778 43.8171 200.785 43.9356Z" fill="#242424"/>
            <Path d="M187.035 50.5576L181.701 50.5503L181.685 61.8063L187.02 61.8136L187.035 50.5576Z" fill="#CECECE"/>
            <Path d="M200.749 66.2971C200.724 66.4085 193.681 66.5 184.974 66.4854C176.267 66.4707 169.218 66.2932 169.186 66.2861C169.154 66.2789 176.253 66.0832 184.961 66.0978C193.668 66.1125 200.749 66.1467 200.749 66.2971Z" fill="#242424"/>
            <Path d="M187.007 73.5893L181.673 73.582L181.658 84.8381L186.992 84.8454L187.007 73.5893Z" fill="#CECECE"/>
            <Path d="M200.713 89.328C200.688 89.4394 193.645 89.5309 184.938 89.5162C176.231 89.5016 169.182 89.3241 169.15 89.3169C169.118 89.3098 176.218 89.114 184.925 89.1287C193.632 89.1433 200.71 89.1935 200.713 89.328Z" fill="#242424"/>
            <Path d="M186.98 96.6245L181.646 96.6172L181.63 107.873L186.965 107.881L186.98 96.6245Z" fill="#CECECE"/>
            <Path d="M200.677 112.358C200.652 112.47 193.61 112.561 184.903 112.547C176.195 112.532 169.147 112.355 169.115 112.347C169.083 112.34 176.182 112.145 184.889 112.159C193.597 112.174 200.671 112.24 200.677 112.358Z" fill="#242424"/>
            <Path d="M186.874 119.058L181.54 119.05L181.523 130.306L186.857 130.314L186.874 119.058Z" fill="#CECECE"/>
            <Path d="M200.652 134.672C200.627 134.784 193.6 134.879 184.877 134.86C176.154 134.842 169.103 134.748 169.105 134.665C169.107 134.581 176.157 134.458 184.88 134.476C193.603 134.495 200.636 134.669 200.652 134.672Z" fill="#242424"/>
            <Path d="M186.925 141.461L181.591 141.454L181.576 152.71L186.91 152.717L186.925 141.461Z" fill="#CECECE"/>
            <Path d="M186.81 164.27L181.476 164.262L181.458 175.518L186.793 175.526L186.81 164.27Z" fill="#CECECE"/>
            <Path d="M200.616 157.185C200.591 157.296 193.548 157.387 184.837 157.389C176.127 157.39 169.081 157.197 169.049 157.189C169.018 157.182 176.117 156.987 184.824 157.001C193.531 157.016 200.609 157.066 200.616 157.185Z" fill="#242424"/>
            <Path d="M155.222 50.8686L149.888 50.8613L149.872 62.1174L155.207 62.1247L155.222 50.8686Z" fill="#7C7C7C"/>
            <Path d="M168.92 66.5776C168.895 66.689 161.869 66.784 153.145 66.7658C144.422 66.7476 137.371 66.6532 137.373 66.5701C137.375 66.4869 144.425 66.3636 153.148 66.3818C161.871 66.4001 168.929 66.4626 168.92 66.5776Z" fill="#242424"/>
            <Path d="M155.185 73.5561L149.85 73.5488L149.835 84.8049L155.169 84.8122L155.185 73.5561Z" fill="#7C7C7C"/>
            <Path d="M168.892 89.2753C168.867 89.3868 161.825 89.4782 153.114 89.4795C144.403 89.4808 137.358 89.2873 137.326 89.2802C137.294 89.2731 144.393 89.0773 153.1 89.092C161.808 89.1066 168.899 89.0931 168.892 89.2753Z" fill="#242424"/>
            <Path d="M155.143 96.2632L149.809 96.2559L149.793 107.512L155.128 107.519L155.143 96.2632Z" fill="#7C7C7C"/>
            <Path d="M168.86 111.989C168.835 112.101 161.793 112.192 153.086 112.177C144.379 112.163 137.33 111.985 137.298 111.978C137.266 111.971 144.365 111.775 153.072 111.79C161.78 111.804 168.857 111.855 168.86 111.989Z" fill="#242424"/>
            <Path d="M155.07 118.991L149.735 118.982L149.718 130.238L155.052 130.247L155.07 118.991Z" fill="#7C7C7C"/>
            <Path d="M168.817 134.684C168.792 134.795 161.765 134.89 153.058 134.875C144.351 134.861 137.286 134.68 137.27 134.676C137.254 134.672 144.321 134.47 153.045 134.488C161.768 134.506 168.826 134.569 168.817 134.684Z" fill="#242424"/>
            <Path d="M155.1 141.645L149.765 141.638L149.75 152.894L155.084 152.902L155.1 141.645Z" fill="#7C7C7C"/>
            <Path d="M168.789 157.381C168.764 157.493 161.721 157.584 153.014 157.57C144.307 157.555 137.24 157.457 137.242 157.374C137.244 157.291 144.293 157.167 153.001 157.182C161.708 157.197 168.782 157.263 168.789 157.381Z" fill="#242424"/>
            <Path d="M155.058 164.353L149.724 164.345L149.708 175.601L155.043 175.609L155.058 164.353Z" fill="#7C7C7C"/>
            <Path d="M283.355 110.6L191.252 110.476L191.088 231.96L283.192 232.083L283.355 110.6Z" fill="#484848"/>
            <Path d="M283.363 110.588L240.182 110.53L240.019 232.013L283.2 232.071L283.363 110.588Z" fill="#848484"/>
            <Path d="M218.838 126.954L210.551 126.942L210.524 147.056L218.811 147.068L218.838 126.954Z" fill="#CECECE"/>
            <Path d="M218.729 205.717L210.442 205.706L210.415 225.82L218.702 225.831L218.729 205.717Z" fill="#CECECE"/>
            <Path d="M240.117 154.998C240.095 155.226 229.164 155.393 215.66 155.377C202.156 155.36 191.21 155.166 191.119 154.927C191.027 154.689 202.073 154.532 215.576 154.549C229.08 154.566 240.123 154.769 240.117 154.998Z" fill="#CECECE"/>
            <Path d="M218.783 166.627L210.496 166.616L210.469 186.73L218.756 186.741L218.783 166.627Z" fill="#CECECE"/>
            <Path d="M240.067 194.674C240.045 194.901 229.113 195.069 215.609 195.052C202.106 195.035 191.143 194.839 191.068 194.603C190.993 194.366 202.022 194.208 215.526 194.224C229.029 194.241 240.073 194.445 240.067 194.674Z" fill="#CECECE"/>
            <Path d="M257.623 127.008L249.336 126.997L249.309 147.111L257.596 147.122L257.623 127.008Z" fill="#CECECE"/>
            <Path d="M275.566 127.046L267.279 127.035L267.252 147.149L275.539 147.16L275.566 127.046Z" fill="#CECECE"/>
            <Path d="M257.553 165.191L249.266 165.18L249.239 185.294L257.526 185.305L257.553 165.191Z" fill="#CECECE"/>
            <Path d="M275.531 165.2L267.244 165.189L267.217 185.303L275.504 185.314L275.531 165.2Z" fill="#CECECE"/>
            <Path d="M257.501 203.377L249.214 203.366L249.187 223.48L257.474 223.491L257.501 203.377Z" fill="#CECECE"/>
            <Path d="M275.48 203.384L267.193 203.373L267.166 223.487L275.453 223.498L275.48 203.384Z" fill="#CECECE"/>
            <Path d="M290.285 106.382L184.331 106.239L184.325 110.627L290.279 110.77L290.285 106.382Z" fill="#525252"/>
            <Path d="M147.73 231.981L54.886 233.177L52.6056 86.4669L145.434 85.2646L147.73 231.981Z" fill="#484848"/>
            <Path d="M145.417 85.2722L98.9934 85.8662L100.871 232.591L147.294 231.997L145.417 85.2722Z" fill="#848484"/>
            <Path d="M95.5954 57.0361L62.8088 57.4556L63.1784 86.3437L95.965 85.9242L95.5954 57.0361Z" fill="#484848"/>
            <Path d="M95.6007 57.0338L87.7874 57.1338L88.157 86.0219L95.9703 85.9219L95.6007 57.0338Z" fill="#848484"/>
            <Path d="M148.118 83.5676L44.8317 84.8892L44.8532 86.5693L148.139 85.2477L148.118 83.5676Z" fill="#525252"/>
            <Path d="M78.9142 98.7295L71.0519 98.8301L71.2639 115.403L79.1262 115.302L78.9142 98.7295Z" fill="#CECECE"/>
            <Path d="M99.4435 121.598C99.4545 121.78 89.049 122.058 76.2464 122.218C63.4438 122.378 53.1044 122.418 53.0183 122.204C52.9323 121.99 63.4064 121.759 76.2304 121.591C89.0544 121.422 99.4731 121.363 99.4435 121.598Z" fill="#999999"/>
            <Path d="M125.745 98.1372L117.882 98.2378L118.094 114.811L125.957 114.71L125.745 98.1372Z" fill="#CECECE"/>
            <Path d="M146.303 120.93C146.239 121.08 135.915 121.375 123.091 121.543C110.267 121.711 99.9558 121.728 99.8848 121.52C99.8137 121.313 110.273 121.075 123.097 120.907C135.921 120.739 146.292 120.748 146.303 120.93Z" fill="#999999"/>
            <Path d="M126.164 131.019L118.302 131.119L118.514 147.692L126.376 147.591L126.164 131.019Z" fill="#CECECE"/>
            <Path d="M146.72 153.831C146.656 153.981 136.332 154.276 123.508 154.444C110.684 154.612 100.372 154.629 100.301 154.421C100.23 154.214 110.689 153.976 123.513 153.808C136.337 153.64 146.724 153.655 146.72 153.831Z" fill="#999999"/>
            <Path d="M126.606 164.928L118.744 165.029L118.956 181.602L126.818 181.501L126.606 164.928Z" fill="#CECECE"/>
            <Path d="M147.16 187.717C147.096 187.867 136.772 188.162 123.948 188.33C111.124 188.499 100.737 188.483 100.741 188.307C100.745 188.132 111.129 187.862 123.953 187.694C136.777 187.526 147.149 187.535 147.16 187.717Z" fill="#999999"/>
            <Path d="M127.016 198.815L119.154 198.916L119.366 215.489L127.228 215.388L127.016 198.815Z" fill="#CECECE"/>
            <Path d="M147.6 221.603C147.536 221.753 137.205 222.063 124.388 222.216C111.57 222.369 101.177 222.369 101.175 222.208C101.172 222.047 111.569 221.748 124.387 221.595C137.204 221.441 147.589 221.421 147.6 221.603Z" fill="#999999"/>
            <Path d="M79.3557 132.129L71.4935 132.229L71.7055 148.802L79.5678 148.702L79.3557 132.129Z" fill="#CECECE"/>
            <Path d="M99.9079 154.927C99.8439 155.077 89.5199 155.372 76.6959 155.541C63.8719 155.709 53.5602 155.725 53.4828 155.533C53.4054 155.34 63.8773 155.073 76.6949 154.92C89.5125 154.766 99.9119 154.752 99.9079 154.927Z" fill="#999999"/>
            <Path d="M79.7593 165.537L71.897 165.638L72.1091 182.21L79.9713 182.11L79.7593 165.537Z" fill="#CECECE"/>
            <Path d="M100.341 188.331C100.277 188.481 89.9527 188.776 77.1287 188.944C64.3047 189.113 53.918 189.097 53.922 188.922C53.926 188.746 64.3101 188.477 77.1191 188.302C89.9281 188.127 100.33 188.149 100.341 188.331Z" fill="#999999"/>
            <Path d="M80.1943 198.952L72.332 199.052L72.5441 215.625L80.4063 215.524L80.1943 198.952Z" fill="#CECECE"/>
            <Path d="M100.758 221.729C100.694 221.879 90.3852 222.18 77.5612 222.349C64.7372 222.517 54.3505 222.502 54.3545 222.326C54.3585 222.15 64.7276 221.875 77.5516 221.706C90.3756 221.538 100.788 221.493 100.758 221.729Z" fill="#999999"/>
            <Path d="M24.7682 52.5084H80.2489C80.2489 52.5084 75.3468 43.5374 61.0617 44.8189C47.4277 46.0621 46.732 40.4103 39.7937 39.6927C32.8554 38.975 27.0788 44.3832 24.7682 52.5084Z" fill="#545454"/>
            <Path d="M241.738 91.1471H319.014C319.014 91.1471 312.186 78.7257 292.289 80.5002C273.299 82.2214 272.33 74.396 262.666 73.4023C253.002 72.4085 244.956 79.8969 241.738 91.1471Z" fill="#545454"/>
        </Svg>

    )
}
export default SearchImageSvg