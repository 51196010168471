/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Riquadro,
    RiquadroFromJSON,
    RiquadroFromJSONTyped,
    RiquadroToJSON,
} from './';

/**
 * 
 * @export
 * @interface Riquadri
 */
export interface Riquadri {
    /**
     * 
     * @type {Array<Riquadro>}
     * @memberof Riquadri
     */
    riquadro: Array<Riquadro>;
}

export function RiquadriFromJSON(json: any): Riquadri {
    return RiquadriFromJSONTyped(json, false);
}

export function RiquadriFromJSONTyped(json: any, ignoreDiscriminator: boolean): Riquadri {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riquadro': ((json['riquadro'] as Array<any>).map(RiquadroFromJSON)),
    };
}

export function RiquadriToJSON(value?: Riquadri | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riquadro': ((value.riquadro as Array<any>).map(RiquadroToJSON)),
    };
}


