/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuoloPartecipazione,
    RuoloPartecipazioneFromJSON,
    RuoloPartecipazioneFromJSONTyped,
    RuoloPartecipazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface DirittoPartecipazione
 */
export interface DirittoPartecipazione {
    /**
     * 
     * @type {Array<RuoloPartecipazione>}
     * @memberof DirittoPartecipazione
     */
    ruoloPartecipazione?: Array<RuoloPartecipazione>;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    frazioneNumeratore?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    frazioneDenominatore?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    percentuale?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    valuta?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    valore?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    cvaluta?: string;
    /**
     * 
     * @type {string}
     * @memberof DirittoPartecipazione
     */
    ctipo?: string;
}

export function DirittoPartecipazioneFromJSON(json: any): DirittoPartecipazione {
    return DirittoPartecipazioneFromJSONTyped(json, false);
}

export function DirittoPartecipazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirittoPartecipazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruoloPartecipazione': !exists(json, 'ruoloPartecipazione') ? undefined : ((json['ruoloPartecipazione'] as Array<any>).map(RuoloPartecipazioneFromJSON)),
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'frazioneNumeratore': !exists(json, 'frazioneNumeratore') ? undefined : json['frazioneNumeratore'],
        'frazioneDenominatore': !exists(json, 'frazioneDenominatore') ? undefined : json['frazioneDenominatore'],
        'percentuale': !exists(json, 'percentuale') ? undefined : json['percentuale'],
        'valuta': !exists(json, 'valuta') ? undefined : json['valuta'],
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
        'cvaluta': !exists(json, 'cvaluta') ? undefined : json['cvaluta'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function DirittoPartecipazioneToJSON(value?: DirittoPartecipazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruoloPartecipazione': value.ruoloPartecipazione === undefined ? undefined : ((value.ruoloPartecipazione as Array<any>).map(RuoloPartecipazioneToJSON)),
        'tipo': value.tipo,
        'frazioneNumeratore': value.frazioneNumeratore,
        'frazioneDenominatore': value.frazioneDenominatore,
        'percentuale': value.percentuale,
        'valuta': value.valuta,
        'valore': value.valore,
        'cvaluta': value.cvaluta,
        'ctipo': value.ctipo,
    };
}


