// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import CreditsSvg from '../../../../assets/svg/CreditsSvg';
import { RootStackParamList } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import CreditsContext from './CreditsContext';


export default function AvailableCredits() {

    const context = React.useContext(CreditsContext);
    
    return (
        <>
            <Text style={styles.label}>
                {i18n.t('components.credits.credits')}
            </Text>
            <LinearGradient
                colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                locations={[0.4, 0.8]}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.borderGradient}
            >
                <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                >
                    <View style={hykeeStyle.directionRow}>
                        {(context.extendedMe?.availableCredits == 0  || context.extendedMe?.availableCredits == undefined) &&
                            <View style={styles.col}>
                                <Text style={styles.textLabel}>
                                    {i18n.t('components.credits.no_credit_available')}
                                </Text>
                            </View>}
                        {context.extendedMe?.availableCredits! > 0 &&
                            <View style={styles.col}>
                                <Text style={styles.textLabel}>
                                    {context.extendedMe?.availableCredits + ' ' + i18n.t('screens.credits.credits')}
                                </Text>
                                <Text style={styles.textUnderTable}>
                                    {i18n.t('screens.credits.available_part1') + ' ' + context.extendedMe?.availableCredits + ' ' + i18n.t('screens.credits.avaiable_part2')}
                                </Text>
                            </View>
                        }
                        <View style={styles.contentImg}>
                            <CreditsSvg width={50} height={100} />
                        </View>
                    </View>

                </LinearGradient>
            </LinearGradient>
        </>
    );


}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
    },
    textUnderTable: {
        alignSelf: 'flex-start',
        fontSize: 13,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    textLabel: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    col: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentImg: {
        flex: 0.3,
        alignItems: 'center'

    },
    label: {
        color: 'white',
        fontSize: 17,
        paddingVertical: 5,
        fontFamily: 'poppins',
    },
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
    },
})