/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserExtended,
    UserExtendedFromJSON,
    UserExtendedToJSON,
} from '../models';

export interface DownloadFileRequest {
    id: number;
}

export interface PartialUpdateUserExtended1Request {
    id: number;
    userExtended: UserExtended;
}

export interface PartialUpdateUserExtendedMeRequest {
    userExtended: UserExtended;
}

export interface UpdateUserExtendedWithDbUserRequest {
    id: number;
    userExtended: UserExtended;
}

export interface UploadMyAvatarRequest {
    id: number;
    avatar: Blob;
}

/**
 * 
 */
export class CustomUserExtendedResourceApi extends runtime.BaseAPI {

    /**
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}/download/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async downloadFile(requestParameters: DownloadFileRequest): Promise<string> {
        const response = await this.downloadFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserExtendedMeRaw(): Promise<runtime.ApiResponse<UserExtended>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/user-extendeds/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async getUserExtendedMe(): Promise<UserExtended> {
        const response = await this.getUserExtendedMeRaw();
        return await response.value();
    }

    /**
     */
    async partialUpdateUserExtended1Raw(requestParameters: PartialUpdateUserExtended1Request): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserExtended1.');
        }

        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling partialUpdateUserExtended1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserExtended1(requestParameters: PartialUpdateUserExtended1Request): Promise<UserExtended> {
        const response = await this.partialUpdateUserExtended1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserExtendedMeRaw(requestParameters: PartialUpdateUserExtendedMeRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling partialUpdateUserExtendedMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-extendeds/partial/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserExtendedMe(requestParameters: PartialUpdateUserExtendedMeRequest): Promise<UserExtended> {
        const response = await this.partialUpdateUserExtendedMeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserExtendedWithDbUserRaw(requestParameters: UpdateUserExtendedWithDbUserRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserExtendedWithDbUser.');
        }

        if (requestParameters.userExtended === null || requestParameters.userExtended === undefined) {
            throw new runtime.RequiredError('userExtended','Required parameter requestParameters.userExtended was null or undefined when calling updateUserExtendedWithDbUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserExtendedToJSON(requestParameters.userExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async updateUserExtendedWithDbUser(requestParameters: UpdateUserExtendedWithDbUserRequest): Promise<UserExtended> {
        const response = await this.updateUserExtendedWithDbUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadMyAvatarRaw(requestParameters: UploadMyAvatarRequest): Promise<runtime.ApiResponse<UserExtended>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadMyAvatar.');
        }

        if (requestParameters.avatar === null || requestParameters.avatar === undefined) {
            throw new runtime.RequiredError('avatar','Required parameter requestParameters.avatar was null or undefined when calling uploadMyAvatar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/octet-stream' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.avatar !== undefined) {
            formParams.append('avatar', requestParameters.avatar as any);
        }

        const response = await this.request({
            path: `/api/custom/user-extendeds/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExtendedFromJSON(jsonValue));
    }

    /**
     */
    async uploadMyAvatar(requestParameters: UploadMyAvatarRequest): Promise<UserExtended> {
        const response = await this.uploadMyAvatarRaw(requestParameters);
        return await response.value();
    }

}
