/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResFromJSONTyped,
    CompanySurveyQuestionResToJSON,
    Survey,
    SurveyFromJSON,
    SurveyFromJSONTyped,
    SurveyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySurvey
 */
export interface CompanySurvey {
    /**
     * 
     * @type {number}
     * @memberof CompanySurvey
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanySurvey
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanySurvey
     */
    updateDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanySurvey
     */
    validUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanySurvey
     */
    response?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanySurvey
     */
    businessScore?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySurvey
     */
    completed?: CompanySurveyCompletedEnum;
    /**
     * 
     * @type {Set<CompanySurveyQuestionRes>}
     * @memberof CompanySurvey
     */
    companySurveyQuestionRes?: Set<CompanySurveyQuestionRes>;
    /**
     * 
     * @type {Company}
     * @memberof CompanySurvey
     */
    company?: Company;
    /**
     * 
     * @type {Survey}
     * @memberof CompanySurvey
     */
    survey?: Survey;
}

/**
* @export
* @enum {string}
*/
export enum CompanySurveyCompletedEnum {
    Completed = 'COMPLETED',
    Uncompleted = 'UNCOMPLETED',
    Notstarted = 'NOTSTARTED'
}

export function CompanySurveyFromJSON(json: any): CompanySurvey {
    return CompanySurveyFromJSONTyped(json, false);
}

export function CompanySurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySurvey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'updateDate': !exists(json, 'updateDate') ? undefined : (new Date(json['updateDate'])),
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'response': !exists(json, 'response') ? undefined : json['response'],
        'businessScore': !exists(json, 'businessScore') ? undefined : json['businessScore'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'companySurveyQuestionRes': !exists(json, 'companySurveyQuestionRes') ? undefined : ((json['companySurveyQuestionRes'] as Array<any>).map(CompanySurveyQuestionResFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'survey': !exists(json, 'survey') ? undefined : SurveyFromJSON(json['survey']),
    };
}

export function CompanySurveyToJSON(value?: CompanySurvey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'updateDate': value.updateDate === undefined ? undefined : (value.updateDate.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'response': value.response,
        'businessScore': value.businessScore,
        'completed': value.completed,
        'companySurveyQuestionRes': value.companySurveyQuestionRes === undefined ? undefined : ((value.companySurveyQuestionRes as Array<any>).map(CompanySurveyQuestionResToJSON)),
        'company': CompanyToJSON(value.company),
        'survey': SurveyToJSON(value.survey),
    };
}


