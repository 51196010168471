/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Molini,
    MoliniFromJSON,
    MoliniFromJSONTyped,
    MoliniToJSON,
    Panificatori,
    PanificatoriFromJSON,
    PanificatoriFromJSONTyped,
    PanificatoriToJSON,
} from './';

/**
 * 
 * @export
 * @interface MoliniPanificatori
 */
export interface MoliniPanificatori {
    /**
     * 
     * @type {Panificatori}
     * @memberof MoliniPanificatori
     */
    panificatori?: Panificatori;
    /**
     * 
     * @type {Molini}
     * @memberof MoliniPanificatori
     */
    molini?: Molini;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    conduzione?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    dtInizioRapporto?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    dtFineRapporto?: string;
    /**
     * 
     * @type {string}
     * @memberof MoliniPanificatori
     */
    ctipo?: string;
}

export function MoliniPanificatoriFromJSON(json: any): MoliniPanificatori {
    return MoliniPanificatoriFromJSONTyped(json, false);
}

export function MoliniPanificatoriFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoliniPanificatori {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'panificatori': !exists(json, 'panificatori') ? undefined : PanificatoriFromJSON(json['panificatori']),
        'molini': !exists(json, 'molini') ? undefined : MoliniFromJSON(json['molini']),
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'conduzione': !exists(json, 'conduzione') ? undefined : json['conduzione'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtInizioRapporto': !exists(json, 'dtInizioRapporto') ? undefined : json['dtInizioRapporto'],
        'dtFineRapporto': !exists(json, 'dtFineRapporto') ? undefined : json['dtFineRapporto'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function MoliniPanificatoriToJSON(value?: MoliniPanificatori | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'panificatori': PanificatoriToJSON(value.panificatori),
        'molini': MoliniToJSON(value.molini),
        'tipo': value.tipo,
        'n': value.n,
        'dtIscrizione': value.dtIscrizione,
        'stato': value.stato,
        'conduzione': value.conduzione,
        'denominazione': value.denominazione,
        'dtInizioRapporto': value.dtInizioRapporto,
        'dtFineRapporto': value.dtFineRapporto,
        'ctipo': value.ctipo,
    };
}


