/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Protocollo,
    ProtocolloFromJSON,
    ProtocolloFromJSONTyped,
    ProtocolloToJSON,
} from './';

/**
 * 
 * @export
 * @interface Protocolli
 */
export interface Protocolli {
    /**
     * 
     * @type {Array<Protocollo>}
     * @memberof Protocolli
     */
    protocollo: Array<Protocollo>;
}

export function ProtocolliFromJSON(json: any): Protocolli {
    return ProtocolliFromJSONTyped(json, false);
}

export function ProtocolliFromJSONTyped(json: any, ignoreDiscriminator: boolean): Protocolli {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocollo': ((json['protocollo'] as Array<any>).map(ProtocolloFromJSON)),
    };
}

export function ProtocolliToJSON(value?: Protocolli | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocollo': ((value.protocollo as Array<any>).map(ProtocolloToJSON)),
    };
}


