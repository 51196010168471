// 
// 
//
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Platform, ScrollView } from 'react-native';
import Colors from '../../../../constants/Colors';
import { RootStackParamList, State } from '../../../../types';
import { i18n } from '../../../i18n/i18n'

import PreviousSearches from '../ComponentSavedCompanies/PreviousSearches';
import InputCustomSearches from './InputCustomSearches';



export default function Research(props: {
  route: State,
  onSearch: ((seeResearch: boolean | null, route: State,
    research: {
      nace: string,
      province: string[],
      year: string
    }) => void) | undefined,
  navigation: NativeStackNavigationProp<RootStackParamList, "ResearchScreen">
}) {

  let myroute: State = props.route

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <ScrollView style={styles.container}>
        <Text style={styles.title}>
          {i18n.t('screens.research.title')}
        </Text>
        <View style={styles.containerInput}>
          <TextInput
            placeholder={i18n.t('buttons.placeholder_search')}
            placeholderTextColor='#737373'
            onChangeText={(key) => myroute.key_search = key}
            style={styles.textInput}
          />
          <TouchableOpacity
            style={styles.button}
            activeOpacity={0.5}
            onPress={() => { props.navigation.navigate('Dashboard', myroute) }}
          >
            <Text style={styles.text}>{i18n.t('buttons.search')}</Text>
          </TouchableOpacity>
        </View>
        <PreviousSearches />
        <InputCustomSearches
          route={myroute}
          onSearch={(seeResearch, route, research) => {
            myroute = route;
            console.log(research, 'research');
            seeResearch == null ? null : props.onSearch ? props.onSearch(seeResearch, myroute, research) : null
          }}
          navigation={props.navigation}
        />
      </ScrollView>
    </LinearGradient>
  );
}


const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins',
  },
  button: {
    flex: 0.3,
    backgroundColor: '#222222',
    padding: 5,
    marginVertical: 10,
    justifyContent: 'center',
    borderRadius: 5,
    alignItems: 'center'
  },
  textInput: {
    flex: 0.7,
    backgroundColor: '#464646',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    marginVertical: 10,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 0.2,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    alignSelf: 'flex-start',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    marginVertical: 30,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
