/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationDTO
 */
export interface RegistrationDTO {
    /**
     * 
     * @type {number}
     * @memberof RegistrationDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationDTO
     */
    activated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    langKey?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationDTO
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof RegistrationDTO
     */
    authorities?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    type?: RegistrationDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyRole?: RegistrationDTOCompanyRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    vatNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    sector: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationDTO
     */
    pconsultantRole?: RegistrationDTOPconsultantRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum RegistrationDTOTypeEnum {
    Company = 'COMPANY',
    Consultant = 'CONSULTANT'
}/**
* @export
* @enum {string}
*/
export enum RegistrationDTOCompanyRoleEnum {
    Administrator = 'ADMINISTRATOR',
    Employee = 'EMPLOYEE'
}/**
* @export
* @enum {string}
*/
export enum RegistrationDTOPconsultantRoleEnum {
    Administrator = 'ADMINISTRATOR',
    Sales = 'SALES',
    Analyst = 'ANALYST'
}

export function RegistrationDTOFromJSON(json: any): RegistrationDTO {
    return RegistrationDTOFromJSONTyped(json, false);
}

export function RegistrationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'login': json['login'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'langKey': !exists(json, 'langKey') ? undefined : json['langKey'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (new Date(json['lastModifiedDate'])),
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'companyRole': !exists(json, 'companyRole') ? undefined : json['companyRole'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'companyName': json['companyName'],
        'vatNumber': json['vatNumber'],
        'sector': json['sector'],
        'pconsultantRole': !exists(json, 'pconsultantRole') ? undefined : json['pconsultantRole'],
    };
}

export function RegistrationDTOToJSON(value?: RegistrationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'login': value.login,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'imageUrl': value.imageUrl,
        'activated': value.activated,
        'langKey': value.langKey,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate.toISOString()),
        'authorities': value.authorities,
        'password': value.password,
        'type': value.type,
        'companyRole': value.companyRole,
        'telephone': value.telephone,
        'street': value.street,
        'city': value.city,
        'zipCode': value.zipCode,
        'companyName': value.companyName,
        'vatNumber': value.vatNumber,
        'sector': value.sector,
        'pconsultantRole': value.pconsultantRole,
    };
}


