/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyScoreDTO
 */
export interface CompanyScoreDTO {
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getCCONOnTREND4y?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    get4YCAGRRicavi?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    get4YTrendEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightAllRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreAllRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValore2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggio2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScore2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScore2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeight2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScore2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnly2YCapexOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreCCONOnREVENUES?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyCCONOnRevenues?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyNetDebtOnEbitda?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyFCFOOnDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreRecoveryRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioRecoveryRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightRecoveryRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreRecoveryRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedMaxPossibleRecoveryRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreOperatingLeverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioOperatingLeverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightOperatingLeverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreOperatingLeverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedMaxPossibleOperatingLeverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleScoreBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreBusinessQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyRevenueTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getMaxPossibleEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyEBITDATrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getValoreNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getPunteggioNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedMaxPossibleNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getScoreNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getWeightedScoreFinOnlyNWOCTrend?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getFINANCIALSCORE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    financialScorePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getOUTLOOK?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getBUSINESSSCORE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getRBHQSCORE?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    rbhqScorePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getCheckScoreAll?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyScoreDTO
     */
    getCheckScoreFin?: number;
}

export function CompanyScoreDTOFromJSON(json: any): CompanyScoreDTO {
    return CompanyScoreDTOFromJSONTyped(json, false);
}

export function CompanyScoreDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyScoreDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'getCCONOnTREND4y': !exists(json, 'get___CCON__on__TREND_4y') ? undefined : json['get___CCON__on__TREND_4y'],
        'get4YCAGRRicavi': !exists(json, 'get___4Y_CAGR_Ricavi') ? undefined : json['get___4Y_CAGR_Ricavi'],
        'get4YTrendEBITDA': !exists(json, 'get___4Y_Trend_EBITDA') ? undefined : json['get___4Y_Trend_EBITDA'],
        'getValoreRevenues': !exists(json, 'get___Valore_Revenues') ? undefined : json['get___Valore_Revenues'],
        'getPunteggioRevenues': !exists(json, 'get___Punteggio_Revenues') ? undefined : json['get___Punteggio_Revenues'],
        'getMaxPossibleScoreRevenues': !exists(json, 'get___Max_Possible_Score_Revenues') ? undefined : json['get___Max_Possible_Score_Revenues'],
        'getScoreRevenues': !exists(json, 'get___Score_Revenues') ? undefined : json['get___Score_Revenues'],
        'getWeightAllRevenues': !exists(json, 'get___Weight_All_Revenues') ? undefined : json['get___Weight_All_Revenues'],
        'getWeightedScoreAllRevenues': !exists(json, 'get___Weighted_Score_All_Revenues') ? undefined : json['get___Weighted_Score_All_Revenues'],
        'getWeightedScoreFinOnlyRevenues': !exists(json, 'get___Weighted_Score_Fin__Only_Revenues') ? undefined : json['get___Weighted_Score_Fin__Only_Revenues'],
        'getValoreEBITDAPERCENTAGE': !exists(json, 'get___Valore_EBITDA_PERCENTAGE') ? undefined : json['get___Valore_EBITDA_PERCENTAGE'],
        'getPunteggioEBITDAPERCENTAGE': !exists(json, 'get___Punteggio_EBITDA_PERCENTAGE') ? undefined : json['get___Punteggio_EBITDA_PERCENTAGE'],
        'getMaxPossibleScoreEBITDAPERCENTAGE': !exists(json, 'get___Max_Possible_Score_EBITDA_PERCENTAGE') ? undefined : json['get___Max_Possible_Score_EBITDA_PERCENTAGE'],
        'getScoreEBITDAPERCENTAGE': !exists(json, 'get___Score_EBITDA_PERCENTAGE') ? undefined : json['get___Score_EBITDA_PERCENTAGE'],
        'getWeightEBITDAPERCENTAGE': !exists(json, 'get___Weight_EBITDA_PERCENTAGE') ? undefined : json['get___Weight_EBITDA_PERCENTAGE'],
        'getWeightedScoreEBITDAPERCENTAGE': !exists(json, 'get___Weighted_Score_EBITDA_PERCENTAGE') ? undefined : json['get___Weighted_Score_EBITDA_PERCENTAGE'],
        'getWeightedScoreFinOnlyEBITDA': !exists(json, 'get___Weighted_Score_Fin__Only_EBITDA') ? undefined : json['get___Weighted_Score_Fin__Only_EBITDA'],
        'getValore2YCapexOnRevenues': !exists(json, 'get___Valore_2Y_Capex__on__Revenues') ? undefined : json['get___Valore_2Y_Capex__on__Revenues'],
        'getPunteggio2YCapexOnRevenues': !exists(json, 'get___Punteggio_2Y_Capex__on__Revenues') ? undefined : json['get___Punteggio_2Y_Capex__on__Revenues'],
        'getMaxPossibleScore2YCapexOnRevenues': !exists(json, 'get___Max_Possible_Score_2Y_Capex__on__Revenues') ? undefined : json['get___Max_Possible_Score_2Y_Capex__on__Revenues'],
        'getScore2YCapexOnRevenues': !exists(json, 'get___Score_2Y_Capex__on__Revenues') ? undefined : json['get___Score_2Y_Capex__on__Revenues'],
        'getWeight2YCapexOnRevenues': !exists(json, 'get___Weight_2Y_Capex__on__Revenues') ? undefined : json['get___Weight_2Y_Capex__on__Revenues'],
        'getWeightedScore2YCapexOnRevenues': !exists(json, 'get___Weighted_Score_2Y_Capex__on__Revenues') ? undefined : json['get___Weighted_Score_2Y_Capex__on__Revenues'],
        'getWeightedScoreFinOnly2YCapexOnRevenues': !exists(json, 'get___Weighted_Score_Fin__Only_2YCapex__on__Revenues') ? undefined : json['get___Weighted_Score_Fin__Only_2YCapex__on__Revenues'],
        'getValoreCCONOnREVENUES': !exists(json, 'get___Valore_CCON__on__REVENUES') ? undefined : json['get___Valore_CCON__on__REVENUES'],
        'getPunteggioCCONOnREVENUES': !exists(json, 'get___Punteggio_CCON__on__REVENUES') ? undefined : json['get___Punteggio_CCON__on__REVENUES'],
        'getMaxPossibleScoreCCONOnREVENUES': !exists(json, 'get___Max_Possible_Score_CCON__on__REVENUES') ? undefined : json['get___Max_Possible_Score_CCON__on__REVENUES'],
        'getScoreCCONOnREVENUES': !exists(json, 'get___Score_CCON__on__REVENUES') ? undefined : json['get___Score_CCON__on__REVENUES'],
        'getWeightCCONOnREVENUES': !exists(json, 'get___Weight_CCON__on__REVENUES') ? undefined : json['get___Weight_CCON__on__REVENUES'],
        'getWeightedScoreCCONOnREVENUES': !exists(json, 'get___Weighted_Score_CCON__on__REVENUES') ? undefined : json['get___Weighted_Score_CCON__on__REVENUES'],
        'getWeightedScoreFinOnlyCCONOnRevenues': !exists(json, 'get___Weighted_Score_Fin__Only_CCON__on__Revenues') ? undefined : json['get___Weighted_Score_Fin__Only_CCON__on__Revenues'],
        'getValoreNetDebtOnEbitda': !exists(json, 'get___Valore_Net_Debt__on__Ebitda') ? undefined : json['get___Valore_Net_Debt__on__Ebitda'],
        'getPunteggioNetDebtOnEbitda': !exists(json, 'get___Punteggio_Net_Debt__on__Ebitda') ? undefined : json['get___Punteggio_Net_Debt__on__Ebitda'],
        'getMaxPossibleScoreNetDebtOnEbitda': !exists(json, 'get___Max_Possible_Score_Net_Debt__on__Ebitda') ? undefined : json['get___Max_Possible_Score_Net_Debt__on__Ebitda'],
        'getScoreNetDebtOnEbitda': !exists(json, 'get___Score_Net_Debt__on__Ebitda') ? undefined : json['get___Score_Net_Debt__on__Ebitda'],
        'getWeightNetDebtOnEbitda': !exists(json, 'get___Weight_Net_Debt__on__Ebitda') ? undefined : json['get___Weight_Net_Debt__on__Ebitda'],
        'getWeightedScoreNetDebtOnEbitda': !exists(json, 'get___Weighted_Score_Net_Debt__on__Ebitda') ? undefined : json['get___Weighted_Score_Net_Debt__on__Ebitda'],
        'getWeightedScoreFinOnlyNetDebtOnEbitda': !exists(json, 'get___Weighted_Score_Fin__Only_Net_Debt__on__Ebitda') ? undefined : json['get___Weighted_Score_Fin__Only_Net_Debt__on__Ebitda'],
        'getValoreFCFOOnDEBT': !exists(json, 'get___Valore_FCFO__on__DEBT') ? undefined : json['get___Valore_FCFO__on__DEBT'],
        'getPunteggioFCFOOnDEBT': !exists(json, 'get___Punteggio_FCFO__on__DEBT') ? undefined : json['get___Punteggio_FCFO__on__DEBT'],
        'getMaxPossibleScoreFCFOOnDEBT': !exists(json, 'get___Max_Possible_Score_FCFO__on__DEBT') ? undefined : json['get___Max_Possible_Score_FCFO__on__DEBT'],
        'getScoreFCFOOnDEBT': !exists(json, 'get___Score_FCFO__on__DEBT') ? undefined : json['get___Score_FCFO__on__DEBT'],
        'getWeightFCFOOnDEBT': !exists(json, 'get___Weight_FCFO__on__DEBT') ? undefined : json['get___Weight_FCFO__on__DEBT'],
        'getWeightedScoreFCFOOnDEBT': !exists(json, 'get___Weighted_Score_FCFO__on__DEBT') ? undefined : json['get___Weighted_Score_FCFO__on__DEBT'],
        'getWeightedScoreFinOnlyFCFOOnDEBT': !exists(json, 'get___Weighted_Score_Fin__Only_FCFO__on__DEBT') ? undefined : json['get___Weighted_Score_Fin__Only_FCFO__on__DEBT'],
        'getValoreGrossDebtOnPN': !exists(json, 'get___Valore_Gross_Debt__on__PN') ? undefined : json['get___Valore_Gross_Debt__on__PN'],
        'getPunteggioGrossDebtOnPN': !exists(json, 'get___Punteggio_Gross_Debt__on__PN') ? undefined : json['get___Punteggio_Gross_Debt__on__PN'],
        'getMaxPossibleScoreGrossDebtOnPN': !exists(json, 'get___Max_Possible_Score_Gross_Debt__on__PN') ? undefined : json['get___Max_Possible_Score_Gross_Debt__on__PN'],
        'getScoreGrossDebtOnPN': !exists(json, 'get___Score_Gross_Debt__on__PN') ? undefined : json['get___Score_Gross_Debt__on__PN'],
        'getWeightGrossDebtOnPN': !exists(json, 'get___Weight_Gross_Debt__on__PN') ? undefined : json['get___Weight_Gross_Debt__on__PN'],
        'getWeightedScoreGrossDebtOnPN': !exists(json, 'get___Weighted_Score_Gross_Debt__on__PN') ? undefined : json['get___Weighted_Score_Gross_Debt__on__PN'],
        'getWeightedScoreFinOnlyGrossDebtOnPN': !exists(json, 'get___Weighted_Score_Fin__Only_Gross_Debt__on__PN') ? undefined : json['get___Weighted_Score_Fin__Only_Gross_Debt__on__PN'],
        'getValoreROCE': !exists(json, 'get___Valore_ROCE') ? undefined : json['get___Valore_ROCE'],
        'getPunteggioROCE': !exists(json, 'get___Punteggio_ROCE') ? undefined : json['get___Punteggio_ROCE'],
        'getMaxPossibleScoreROCE': !exists(json, 'get___Max_Possible_Score_ROCE') ? undefined : json['get___Max_Possible_Score_ROCE'],
        'getScoreROCE': !exists(json, 'get___Score_ROCE') ? undefined : json['get___Score_ROCE'],
        'getWeightROCE': !exists(json, 'get___Weight_ROCE') ? undefined : json['get___Weight_ROCE'],
        'getWeightedScoreROCE': !exists(json, 'get___Weighted_Score_ROCE') ? undefined : json['get___Weighted_Score_ROCE'],
        'getWeightedScoreFinOnlyROCE': !exists(json, 'get___Weighted_Score_Fin__Only_ROCE') ? undefined : json['get___Weighted_Score_Fin__Only_ROCE'],
        'getValoreRecoveryRatio': !exists(json, 'get___Valore_Recovery_Ratio') ? undefined : json['get___Valore_Recovery_Ratio'],
        'getPunteggioRecoveryRatio': !exists(json, 'get___Punteggio_Recovery_Ratio') ? undefined : json['get___Punteggio_Recovery_Ratio'],
        'getWeightRecoveryRatio': !exists(json, 'get___Weight_Recovery_Ratio') ? undefined : json['get___Weight_Recovery_Ratio'],
        'getWeightedScoreRecoveryRatio': !exists(json, 'get___Weighted_Score_Recovery_Ratio') ? undefined : json['get___Weighted_Score_Recovery_Ratio'],
        'getWeightedMaxPossibleRecoveryRatio': !exists(json, 'get___Weighted_Max_Possible_Recovery_Ratio') ? undefined : json['get___Weighted_Max_Possible_Recovery_Ratio'],
        'getValoreOperatingLeverage': !exists(json, 'get___Valore_Operating_Leverage') ? undefined : json['get___Valore_Operating_Leverage'],
        'getPunteggioOperatingLeverage': !exists(json, 'get___Punteggio_Operating_Leverage') ? undefined : json['get___Punteggio_Operating_Leverage'],
        'getWeightOperatingLeverage': !exists(json, 'get___Weight_Operating_Leverage') ? undefined : json['get___Weight_Operating_Leverage'],
        'getWeightedScoreOperatingLeverage': !exists(json, 'get___Weighted_Score_Operating_Leverage') ? undefined : json['get___Weighted_Score_Operating_Leverage'],
        'getWeightedMaxPossibleOperatingLeverage': !exists(json, 'get___Weighted_Max_Possible_Operating_Leverage') ? undefined : json['get___Weighted_Max_Possible_Operating_Leverage'],
        'getValoreBusinessQuestions': !exists(json, 'get___Valore_Business_Questions') ? undefined : json['get___Valore_Business_Questions'],
        'getPunteggioBusinessQuestions': !exists(json, 'get___Punteggio_Business_Questions') ? undefined : json['get___Punteggio_Business_Questions'],
        'getMaxPossibleScoreBusinessQuestions': !exists(json, 'get___Max_Possible_Score_Business_Questions') ? undefined : json['get___Max_Possible_Score_Business_Questions'],
        'getScoreBusinessQuestions': !exists(json, 'get___Score_Business_Questions') ? undefined : json['get___Score_Business_Questions'],
        'getWeightBusinessQuestions': !exists(json, 'get___Weight_Business_Questions') ? undefined : json['get___Weight_Business_Questions'],
        'getWeightedScoreBusinessQuestions': !exists(json, 'get___Weighted_Score_Business_Questions') ? undefined : json['get___Weighted_Score_Business_Questions'],
        'getValoreRevenueTrend': !exists(json, 'get___Valore_Revenue_Trend') ? undefined : json['get___Valore_Revenue_Trend'],
        'getPunteggioRevenueTrend': !exists(json, 'get___Punteggio_Revenue_Trend') ? undefined : json['get___Punteggio_Revenue_Trend'],
        'getMaxPossibleRevenueTrend': !exists(json, 'get___Max_Possible_Revenue_Trend') ? undefined : json['get___Max_Possible_Revenue_Trend'],
        'getScoreRevenueTrend': !exists(json, 'get___Score_Revenue_Trend') ? undefined : json['get___Score_Revenue_Trend'],
        'getWeightRevenueTrend': !exists(json, 'get___Weight_Revenue_Trend') ? undefined : json['get___Weight_Revenue_Trend'],
        'getWeightedScoreRevenueTrend': !exists(json, 'get___Weighted_Score_Revenue_Trend') ? undefined : json['get___Weighted_Score_Revenue_Trend'],
        'getWeightedScoreFinOnlyRevenueTrend': !exists(json, 'get___Weighted_Score_Fin__Only_Revenue_Trend') ? undefined : json['get___Weighted_Score_Fin__Only_Revenue_Trend'],
        'getValoreEBITDATrend': !exists(json, 'get___Valore_EBITDA_Trend') ? undefined : json['get___Valore_EBITDA_Trend'],
        'getPunteggioEBITDATrend': !exists(json, 'get___Punteggio_EBITDA_Trend') ? undefined : json['get___Punteggio_EBITDA_Trend'],
        'getMaxPossibleEBITDATrend': !exists(json, 'get___Max_Possible_EBITDA_Trend') ? undefined : json['get___Max_Possible_EBITDA_Trend'],
        'getScoreEBITDATrend': !exists(json, 'get___Score_EBITDA_Trend') ? undefined : json['get___Score_EBITDA_Trend'],
        'getWeightEBITDATrend': !exists(json, 'get___Weight_EBITDA_Trend') ? undefined : json['get___Weight_EBITDA_Trend'],
        'getWeightedScoreEBITDATrend': !exists(json, 'get___Weighted_Score_EBITDA_Trend') ? undefined : json['get___Weighted_Score_EBITDA_Trend'],
        'getWeightedScoreFinOnlyEBITDATrend': !exists(json, 'get___Weighted_Score_Fin__Only_EBITDA_Trend') ? undefined : json['get___Weighted_Score_Fin__Only_EBITDA_Trend'],
        'getValoreNWOCTrend': !exists(json, 'get___Valore_NWOC_Trend') ? undefined : json['get___Valore_NWOC_Trend'],
        'getPunteggioNWOCTrend': !exists(json, 'get___Punteggio_NWOC_Trend') ? undefined : json['get___Punteggio_NWOC_Trend'],
        'getWeightedMaxPossibleNWOCTrend': !exists(json, 'get___Weighted_Max_Possible_NWOC_Trend') ? undefined : json['get___Weighted_Max_Possible_NWOC_Trend'],
        'getScoreNWOCTrend': !exists(json, 'get___Score_NWOC_Trend') ? undefined : json['get___Score_NWOC_Trend'],
        'getWeightNWOCTrend': !exists(json, 'get___Weight_NWOC_Trend') ? undefined : json['get___Weight_NWOC_Trend'],
        'getWeightedScoreNWOCTrend': !exists(json, 'get___Weighted_Score_NWOC_Trend') ? undefined : json['get___Weighted_Score_NWOC_Trend'],
        'getWeightedScoreFinOnlyNWOCTrend': !exists(json, 'get___Weighted_Score_Fin__Only_NWOC_Trend') ? undefined : json['get___Weighted_Score_Fin__Only_NWOC_Trend'],
        'getFINANCIALSCORE': !exists(json, 'get___FINANCIAL_SCORE') ? undefined : json['get___FINANCIAL_SCORE'],
        'financialScorePercentage': !exists(json, 'financialScorePercentage') ? undefined : json['financialScorePercentage'],
        'getOUTLOOK': !exists(json, 'get___OUTLOOK') ? undefined : json['get___OUTLOOK'],
        'getBUSINESSSCORE': !exists(json, 'get___BUSINESS_SCORE') ? undefined : json['get___BUSINESS_SCORE'],
        'getRBHQSCORE': !exists(json, 'get___RBHQ_SCORE') ? undefined : json['get___RBHQ_SCORE'],
        'rbhqScorePercentage': !exists(json, 'rbhqScorePercentage') ? undefined : json['rbhqScorePercentage'],
        'getCheckScoreAll': !exists(json, 'get___Check_Score_All') ? undefined : json['get___Check_Score_All'],
        'getCheckScoreFin': !exists(json, 'get___Check_Score_Fin') ? undefined : json['get___Check_Score_Fin'],
    };
}

export function CompanyScoreDTOToJSON(value?: CompanyScoreDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'get___CCON__on__TREND_4y': value.getCCONOnTREND4y,
        'get___4Y_CAGR_Ricavi': value.get4YCAGRRicavi,
        'get___4Y_Trend_EBITDA': value.get4YTrendEBITDA,
        'get___Valore_Revenues': value.getValoreRevenues,
        'get___Punteggio_Revenues': value.getPunteggioRevenues,
        'get___Max_Possible_Score_Revenues': value.getMaxPossibleScoreRevenues,
        'get___Score_Revenues': value.getScoreRevenues,
        'get___Weight_All_Revenues': value.getWeightAllRevenues,
        'get___Weighted_Score_All_Revenues': value.getWeightedScoreAllRevenues,
        'get___Weighted_Score_Fin__Only_Revenues': value.getWeightedScoreFinOnlyRevenues,
        'get___Valore_EBITDA_PERCENTAGE': value.getValoreEBITDAPERCENTAGE,
        'get___Punteggio_EBITDA_PERCENTAGE': value.getPunteggioEBITDAPERCENTAGE,
        'get___Max_Possible_Score_EBITDA_PERCENTAGE': value.getMaxPossibleScoreEBITDAPERCENTAGE,
        'get___Score_EBITDA_PERCENTAGE': value.getScoreEBITDAPERCENTAGE,
        'get___Weight_EBITDA_PERCENTAGE': value.getWeightEBITDAPERCENTAGE,
        'get___Weighted_Score_EBITDA_PERCENTAGE': value.getWeightedScoreEBITDAPERCENTAGE,
        'get___Weighted_Score_Fin__Only_EBITDA': value.getWeightedScoreFinOnlyEBITDA,
        'get___Valore_2Y_Capex__on__Revenues': value.getValore2YCapexOnRevenues,
        'get___Punteggio_2Y_Capex__on__Revenues': value.getPunteggio2YCapexOnRevenues,
        'get___Max_Possible_Score_2Y_Capex__on__Revenues': value.getMaxPossibleScore2YCapexOnRevenues,
        'get___Score_2Y_Capex__on__Revenues': value.getScore2YCapexOnRevenues,
        'get___Weight_2Y_Capex__on__Revenues': value.getWeight2YCapexOnRevenues,
        'get___Weighted_Score_2Y_Capex__on__Revenues': value.getWeightedScore2YCapexOnRevenues,
        'get___Weighted_Score_Fin__Only_2YCapex__on__Revenues': value.getWeightedScoreFinOnly2YCapexOnRevenues,
        'get___Valore_CCON__on__REVENUES': value.getValoreCCONOnREVENUES,
        'get___Punteggio_CCON__on__REVENUES': value.getPunteggioCCONOnREVENUES,
        'get___Max_Possible_Score_CCON__on__REVENUES': value.getMaxPossibleScoreCCONOnREVENUES,
        'get___Score_CCON__on__REVENUES': value.getScoreCCONOnREVENUES,
        'get___Weight_CCON__on__REVENUES': value.getWeightCCONOnREVENUES,
        'get___Weighted_Score_CCON__on__REVENUES': value.getWeightedScoreCCONOnREVENUES,
        'get___Weighted_Score_Fin__Only_CCON__on__Revenues': value.getWeightedScoreFinOnlyCCONOnRevenues,
        'get___Valore_Net_Debt__on__Ebitda': value.getValoreNetDebtOnEbitda,
        'get___Punteggio_Net_Debt__on__Ebitda': value.getPunteggioNetDebtOnEbitda,
        'get___Max_Possible_Score_Net_Debt__on__Ebitda': value.getMaxPossibleScoreNetDebtOnEbitda,
        'get___Score_Net_Debt__on__Ebitda': value.getScoreNetDebtOnEbitda,
        'get___Weight_Net_Debt__on__Ebitda': value.getWeightNetDebtOnEbitda,
        'get___Weighted_Score_Net_Debt__on__Ebitda': value.getWeightedScoreNetDebtOnEbitda,
        'get___Weighted_Score_Fin__Only_Net_Debt__on__Ebitda': value.getWeightedScoreFinOnlyNetDebtOnEbitda,
        'get___Valore_FCFO__on__DEBT': value.getValoreFCFOOnDEBT,
        'get___Punteggio_FCFO__on__DEBT': value.getPunteggioFCFOOnDEBT,
        'get___Max_Possible_Score_FCFO__on__DEBT': value.getMaxPossibleScoreFCFOOnDEBT,
        'get___Score_FCFO__on__DEBT': value.getScoreFCFOOnDEBT,
        'get___Weight_FCFO__on__DEBT': value.getWeightFCFOOnDEBT,
        'get___Weighted_Score_FCFO__on__DEBT': value.getWeightedScoreFCFOOnDEBT,
        'get___Weighted_Score_Fin__Only_FCFO__on__DEBT': value.getWeightedScoreFinOnlyFCFOOnDEBT,
        'get___Valore_Gross_Debt__on__PN': value.getValoreGrossDebtOnPN,
        'get___Punteggio_Gross_Debt__on__PN': value.getPunteggioGrossDebtOnPN,
        'get___Max_Possible_Score_Gross_Debt__on__PN': value.getMaxPossibleScoreGrossDebtOnPN,
        'get___Score_Gross_Debt__on__PN': value.getScoreGrossDebtOnPN,
        'get___Weight_Gross_Debt__on__PN': value.getWeightGrossDebtOnPN,
        'get___Weighted_Score_Gross_Debt__on__PN': value.getWeightedScoreGrossDebtOnPN,
        'get___Weighted_Score_Fin__Only_Gross_Debt__on__PN': value.getWeightedScoreFinOnlyGrossDebtOnPN,
        'get___Valore_ROCE': value.getValoreROCE,
        'get___Punteggio_ROCE': value.getPunteggioROCE,
        'get___Max_Possible_Score_ROCE': value.getMaxPossibleScoreROCE,
        'get___Score_ROCE': value.getScoreROCE,
        'get___Weight_ROCE': value.getWeightROCE,
        'get___Weighted_Score_ROCE': value.getWeightedScoreROCE,
        'get___Weighted_Score_Fin__Only_ROCE': value.getWeightedScoreFinOnlyROCE,
        'get___Valore_Recovery_Ratio': value.getValoreRecoveryRatio,
        'get___Punteggio_Recovery_Ratio': value.getPunteggioRecoveryRatio,
        'get___Weight_Recovery_Ratio': value.getWeightRecoveryRatio,
        'get___Weighted_Score_Recovery_Ratio': value.getWeightedScoreRecoveryRatio,
        'get___Weighted_Max_Possible_Recovery_Ratio': value.getWeightedMaxPossibleRecoveryRatio,
        'get___Valore_Operating_Leverage': value.getValoreOperatingLeverage,
        'get___Punteggio_Operating_Leverage': value.getPunteggioOperatingLeverage,
        'get___Weight_Operating_Leverage': value.getWeightOperatingLeverage,
        'get___Weighted_Score_Operating_Leverage': value.getWeightedScoreOperatingLeverage,
        'get___Weighted_Max_Possible_Operating_Leverage': value.getWeightedMaxPossibleOperatingLeverage,
        'get___Valore_Business_Questions': value.getValoreBusinessQuestions,
        'get___Punteggio_Business_Questions': value.getPunteggioBusinessQuestions,
        'get___Max_Possible_Score_Business_Questions': value.getMaxPossibleScoreBusinessQuestions,
        'get___Score_Business_Questions': value.getScoreBusinessQuestions,
        'get___Weight_Business_Questions': value.getWeightBusinessQuestions,
        'get___Weighted_Score_Business_Questions': value.getWeightedScoreBusinessQuestions,
        'get___Valore_Revenue_Trend': value.getValoreRevenueTrend,
        'get___Punteggio_Revenue_Trend': value.getPunteggioRevenueTrend,
        'get___Max_Possible_Revenue_Trend': value.getMaxPossibleRevenueTrend,
        'get___Score_Revenue_Trend': value.getScoreRevenueTrend,
        'get___Weight_Revenue_Trend': value.getWeightRevenueTrend,
        'get___Weighted_Score_Revenue_Trend': value.getWeightedScoreRevenueTrend,
        'get___Weighted_Score_Fin__Only_Revenue_Trend': value.getWeightedScoreFinOnlyRevenueTrend,
        'get___Valore_EBITDA_Trend': value.getValoreEBITDATrend,
        'get___Punteggio_EBITDA_Trend': value.getPunteggioEBITDATrend,
        'get___Max_Possible_EBITDA_Trend': value.getMaxPossibleEBITDATrend,
        'get___Score_EBITDA_Trend': value.getScoreEBITDATrend,
        'get___Weight_EBITDA_Trend': value.getWeightEBITDATrend,
        'get___Weighted_Score_EBITDA_Trend': value.getWeightedScoreEBITDATrend,
        'get___Weighted_Score_Fin__Only_EBITDA_Trend': value.getWeightedScoreFinOnlyEBITDATrend,
        'get___Valore_NWOC_Trend': value.getValoreNWOCTrend,
        'get___Punteggio_NWOC_Trend': value.getPunteggioNWOCTrend,
        'get___Weighted_Max_Possible_NWOC_Trend': value.getWeightedMaxPossibleNWOCTrend,
        'get___Score_NWOC_Trend': value.getScoreNWOCTrend,
        'get___Weight_NWOC_Trend': value.getWeightNWOCTrend,
        'get___Weighted_Score_NWOC_Trend': value.getWeightedScoreNWOCTrend,
        'get___Weighted_Score_Fin__Only_NWOC_Trend': value.getWeightedScoreFinOnlyNWOCTrend,
        'get___FINANCIAL_SCORE': value.getFINANCIALSCORE,
        'financialScorePercentage': value.financialScorePercentage,
        'get___OUTLOOK': value.getOUTLOOK,
        'get___BUSINESS_SCORE': value.getBUSINESSSCORE,
        'get___RBHQ_SCORE': value.getRBHQSCORE,
        'rbhqScorePercentage': value.rbhqScorePercentage,
        'get___Check_Score_All': value.getCheckScoreAll,
        'get___Check_Score_Fin': value.getCheckScoreFin,
    };
}


