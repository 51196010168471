/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyBalanceSheet,
    CompanyBalanceSheetFromJSON,
    CompanyBalanceSheetFromJSONTyped,
    CompanyBalanceSheetToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface BalanceMoreFieldsState
 */
export interface BalanceMoreFieldsState {
    /**
     * 
     * @type {number}
     * @memberof BalanceMoreFieldsState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof BalanceMoreFieldsState
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof BalanceMoreFieldsState
     */
    state?: BalanceMoreFieldsStateStateEnum;
    /**
     * 
     * @type {number}
     * @memberof BalanceMoreFieldsState
     */
    spHykeeCreditiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceMoreFieldsState
     */
    spHykeeDebitiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceMoreFieldsState
     */
    spHykeeDebitiFinanziari?: number;
    /**
     * 
     * @type {CompanyBalanceSheet}
     * @memberof BalanceMoreFieldsState
     */
    companyBalanceSheet?: CompanyBalanceSheet;
    /**
     * 
     * @type {User}
     * @memberof BalanceMoreFieldsState
     */
    author?: User;
}

/**
* @export
* @enum {string}
*/
export enum BalanceMoreFieldsStateStateEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}

export function BalanceMoreFieldsStateFromJSON(json: any): BalanceMoreFieldsState {
    return BalanceMoreFieldsStateFromJSONTyped(json, false);
}

export function BalanceMoreFieldsStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceMoreFieldsState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'spHykeeCreditiCommerciali': !exists(json, 'spHykeeCreditiCommerciali') ? undefined : json['spHykeeCreditiCommerciali'],
        'spHykeeDebitiCommerciali': !exists(json, 'spHykeeDebitiCommerciali') ? undefined : json['spHykeeDebitiCommerciali'],
        'spHykeeDebitiFinanziari': !exists(json, 'spHykeeDebitiFinanziari') ? undefined : json['spHykeeDebitiFinanziari'],
        'companyBalanceSheet': !exists(json, 'companyBalanceSheet') ? undefined : CompanyBalanceSheetFromJSON(json['companyBalanceSheet']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function BalanceMoreFieldsStateToJSON(value?: BalanceMoreFieldsState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'state': value.state,
        'spHykeeCreditiCommerciali': value.spHykeeCreditiCommerciali,
        'spHykeeDebitiCommerciali': value.spHykeeDebitiCommerciali,
        'spHykeeDebitiFinanziari': value.spHykeeDebitiFinanziari,
        'companyBalanceSheet': CompanyBalanceSheetToJSON(value.companyBalanceSheet),
        'author': UserToJSON(value.author),
    };
}


