/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Post,
    PostFromJSON,
    PostToJSON,
} from '../models';

export interface CreatePostRequest {
    post: Post;
}

export interface DeletePostRequest {
    id: number;
}

export interface GetAllPostsRequest {
    eagerload?: boolean;
}

export interface GetPostRequest {
    id: number;
}

export interface PartialUpdatePostRequest {
    id: number;
    post: Post;
}

export interface UpdatePostRequest {
    id: number;
    post: Post;
}

/**
 * 
 */
export class PostResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPostRaw(requestParameters: CreatePostRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.post === null || requestParameters.post === undefined) {
            throw new runtime.RequiredError('post','Required parameter requestParameters.post was null or undefined when calling createPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/posts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostToJSON(requestParameters.post),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     */
    async createPost(requestParameters: CreatePostRequest): Promise<Post> {
        const response = await this.createPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePostRaw(requestParameters: DeletePostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePost(requestParameters: DeletePostRequest): Promise<void> {
        await this.deletePostRaw(requestParameters);
    }

    /**
     */
    async getAllPostsRaw(requestParameters: GetAllPostsRequest): Promise<runtime.ApiResponse<Array<Post>>> {
        const queryParameters: any = {};

        if (requestParameters.eagerload !== undefined) {
            queryParameters['eagerload'] = requestParameters.eagerload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostFromJSON));
    }

    /**
     */
    async getAllPosts(requestParameters: GetAllPostsRequest): Promise<Array<Post>> {
        const response = await this.getAllPostsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPostRaw(requestParameters: GetPostRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     */
    async getPost(requestParameters: GetPostRequest): Promise<Post> {
        const response = await this.getPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdatePostRaw(requestParameters: PartialUpdatePostRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePost.');
        }

        if (requestParameters.post === null || requestParameters.post === undefined) {
            throw new runtime.RequiredError('post','Required parameter requestParameters.post was null or undefined when calling partialUpdatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PostToJSON(requestParameters.post),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePost(requestParameters: PartialUpdatePostRequest): Promise<Post> {
        const response = await this.partialUpdatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePostRaw(requestParameters: UpdatePostRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePost.');
        }

        if (requestParameters.post === null || requestParameters.post === undefined) {
            throw new runtime.RequiredError('post','Required parameter requestParameters.post was null or undefined when calling updatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostToJSON(requestParameters.post),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     */
    async updatePost(requestParameters: UpdatePostRequest): Promise<Post> {
        const response = await this.updatePostRaw(requestParameters);
        return await response.value();
    }

}
