/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrasferimentoQuote,
    TrasferimentoQuoteFromJSON,
    TrasferimentoQuoteFromJSONTyped,
    TrasferimentoQuoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrasferimentiQuote
 */
export interface TrasferimentiQuote {
    /**
     * 
     * @type {Array<TrasferimentoQuote>}
     * @memberof TrasferimentiQuote
     */
    trasferimentoQuote: Array<TrasferimentoQuote>;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentiQuote
     */
    fpresenzaInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentiQuote
     */
    fsuccessiviUltimoEs?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentiQuote
     */
    ftutti?: string;
}

export function TrasferimentiQuoteFromJSON(json: any): TrasferimentiQuote {
    return TrasferimentiQuoteFromJSONTyped(json, false);
}

export function TrasferimentiQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentiQuote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trasferimentoQuote': ((json['trasferimentoQuote'] as Array<any>).map(TrasferimentoQuoteFromJSON)),
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
        'fsuccessiviUltimoEs': !exists(json, 'fsuccessiviUltimoEs') ? undefined : json['fsuccessiviUltimoEs'],
        'ftutti': !exists(json, 'ftutti') ? undefined : json['ftutti'],
    };
}

export function TrasferimentiQuoteToJSON(value?: TrasferimentiQuote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trasferimentoQuote': ((value.trasferimentoQuote as Array<any>).map(TrasferimentoQuoteToJSON)),
        'fpresenzaInfo': value.fpresenzaInfo,
        'fsuccessiviUltimoEs': value.fsuccessiviUltimoEs,
        'ftutti': value.ftutti,
    };
}


