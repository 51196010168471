import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { RootStackScreenProps, State } from '../../../types';
import { customUserExtendedResourceApi } from "../../services/apis/ApiConfiguration";
import { AlertHelper } from "../../helpers/AlertHelper";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Wallet from './ComponentCredits/Wallet';
import PlanCredits from './ComponentCredits/PlanCredits';
import PlanResearch from './ComponentCredits/PlanResearch';
import CreditsContext from './ComponentCredits/CreditsContext';
import { UserExtended } from '@services/apis/generated';
import { useFocusEffect } from '@react-navigation/native';
import SharedContext from '../../navigation/SharedContext';
import Colors from "../../../constants/Colors";
import {BaseAPIManager} from "../../services/model/BaseAPIManager";


export default function CreditsNavigator({ navigation, route }: RootStackScreenProps<'Credits'>) {

    const Stack = createNativeStackNavigator();
    const sharedContext = useContext(SharedContext);
    const [extendedMe, setExtendedMe] = useState<UserExtended>();

    const arrowBack = route.params?.arrowBack;

    useFocusEffect(
        useCallback(() => {
            BaseAPIManager.contextLoadingCallback(true);
            customUserExtendedResourceApi.getUserExtendedMe().then(extendedMe => {
                setExtendedMe(extendedMe);
                sharedContext.setUserExtendedMe(extendedMe);
                BaseAPIManager.contextLoadingCallback(false);
            }).catch(AlertHelper.showSimpleErrorAlert);
        }, [])
    );

    return (
        <CreditsContext.Provider value={{ extendedMe, setExtendedMe }}>
            <LinearGradient
                colors={Colors.gradient.baseGradient}
                style={styles.containerBack}
            >
                <StatusBar style="inverted" />
                <Stack.Navigator
                    initialRouteName="Wallet">
                    <Stack.Screen name="Wallet" component={Wallet} initialParams={{ arrowBack: arrowBack }} options={{ headerShown: false }} />
                    <Stack.Screen name="PlanCredits" component={PlanCredits} options={{ headerShown: false }} />
                    <Stack.Screen name="PlanResearch" component={PlanResearch} options={{ headerShown: false }} />
                </Stack.Navigator>
            </LinearGradient>
        </CreditsContext.Provider>
    );
}

const styles = StyleSheet.create({
    contentLabel: {
        flex: Platform.OS == 'ios' ? 0.3 : 0.25,
        marginBottom: 10
    },
    contentTitle: {
        height: '10%',
        flexDirection: 'row',
    },
    container: {
        flex: 1,
        marginTop: 25,
        flexDirection: 'column',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    title: {
        alignSelf: 'center',
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        justifyContent: 'flex-start'
    },
});

