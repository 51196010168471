/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancellazioneAa
 */
export interface CancellazioneAa {
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAa
     */
    causale?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAa
     */
    dtDomandaAccertamento?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAa
     */
    dtDelibera?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAa
     */
    dtCessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellazioneAa
     */
    ccausale?: string;
}

export function CancellazioneAaFromJSON(json: any): CancellazioneAa {
    return CancellazioneAaFromJSONTyped(json, false);
}

export function CancellazioneAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellazioneAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causale': !exists(json, 'causale') ? undefined : json['causale'],
        'dtDomandaAccertamento': !exists(json, 'dtDomandaAccertamento') ? undefined : json['dtDomandaAccertamento'],
        'dtDelibera': !exists(json, 'dtDelibera') ? undefined : json['dtDelibera'],
        'dtCessazione': !exists(json, 'dtCessazione') ? undefined : json['dtCessazione'],
        'ccausale': !exists(json, 'ccausale') ? undefined : json['ccausale'],
    };
}

export function CancellazioneAaToJSON(value?: CancellazioneAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causale': value.causale,
        'dtDomandaAccertamento': value.dtDomandaAccertamento,
        'dtDelibera': value.dtDelibera,
        'dtCessazione': value.dtCessazione,
        'ccausale': value.ccausale,
    };
}


