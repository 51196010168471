/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuotaDiritto,
    QuotaDirittoFromJSON,
    QuotaDirittoFromJSONTyped,
    QuotaDirittoToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuoteDiritti
 */
export interface QuoteDiritti {
    /**
     * 
     * @type {Array<QuotaDiritto>}
     * @memberof QuoteDiritti
     */
    quotaDiritto: Array<QuotaDiritto>;
}

export function QuoteDirittiFromJSON(json: any): QuoteDiritti {
    return QuoteDirittiFromJSONTyped(json, false);
}

export function QuoteDirittiFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteDiritti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quotaDiritto': ((json['quotaDiritto'] as Array<any>).map(QuotaDirittoFromJSON)),
    };
}

export function QuoteDirittiToJSON(value?: QuoteDiritti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quotaDiritto': ((value.quotaDiritto as Array<any>).map(QuotaDirittoToJSON)),
    };
}


