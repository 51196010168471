/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormaAmministrativaInCarica
 */
export interface FormaAmministrativaInCarica {
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    anniDurata?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    durata?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    dtInizioCarica?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    dtFineCarica?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    namministratoriInCarica?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativaInCarica
     */
    cdurata?: string;
}

export function FormaAmministrativaInCaricaFromJSON(json: any): FormaAmministrativaInCarica {
    return FormaAmministrativaInCaricaFromJSONTyped(json, false);
}

export function FormaAmministrativaInCaricaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormaAmministrativaInCarica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'anniDurata': !exists(json, 'anniDurata') ? undefined : json['anniDurata'],
        'durata': !exists(json, 'durata') ? undefined : json['durata'],
        'dtInizioCarica': !exists(json, 'dtInizioCarica') ? undefined : json['dtInizioCarica'],
        'dtFineCarica': !exists(json, 'dtFineCarica') ? undefined : json['dtFineCarica'],
        'namministratoriInCarica': !exists(json, 'namministratoriInCarica') ? undefined : json['namministratoriInCarica'],
        'cdurata': !exists(json, 'cdurata') ? undefined : json['cdurata'],
    };
}

export function FormaAmministrativaInCaricaToJSON(value?: FormaAmministrativaInCarica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'c': value.c,
        'anniDurata': value.anniDurata,
        'durata': value.durata,
        'dtInizioCarica': value.dtInizioCarica,
        'dtFineCarica': value.dtFineCarica,
        'namministratoriInCarica': value.namministratoriInCarica,
        'cdurata': value.cdurata,
    };
}


