import { FontAwesome } from '@expo/vector-icons';
import { BottomTabBar } from '@react-navigation/bottom-tabs';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { UserInterfaceIdiom } from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import { EmitterSubscription, StyleSheet, View,Image, TextInput,TouchableOpacity ,Text, Platform, Dimensions, Keyboard} from 'react-native';
import { ScrollView } from 'react-native';
import { RootStackParamList, RootStackScreenProps, State } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import navigation from '../../../navigation';

export default function InputCustomSearches( props:{route:State,
  onSearch:((seeResearch:boolean|null, route:State,
    research:{
      nace:string,
      province:string[],
      year:string
  }) => void) | undefined, 
  navigation: NativeStackNavigationProp<RootStackParamList, "ResearchScreen">} ) {
  
    const myroute:State = {
        profiles:props.route.profiles,
        profile:props.route.profile,
        key_search:props.route.key_search? props.route.key_search : '',
        userRole:props.route.userRole,
        availableCredits: props.route.availableCredits,
        listFavorite: props.route.listFavorite,
      }
    let research={
      nace:'',
      province:[''],
      year:'',
    }
 
  return (
  <View style={styles.container}>
    
          <Text style={styles.title}>
              {i18n.t('screens.research.title_custom')}
          </Text>
          <View style={styles.containerInput}>
            <TextInput
              placeholder={i18n.t('screens.research.placeholder_nace_code')}
              placeholderTextColor='#737373'
              onChangeText={(nace)=>research.nace=nace}
              style={styles.textInput}
            />
            <TextInput
              placeholder={i18n.t('screens.research.placeholder_province')}
              placeholderTextColor='#737373'
              onEndEditing={(province)=>
                province?.nativeEvent?.text?.split(';').map(p=>research.province.push(p.trim()))
              }
              style={styles.textInput}
            />
            <TextInput
              placeholder={i18n.t('screens.research.placeholder_year')}
              placeholderTextColor='#737373'
              onChangeText={(year)=>research.year=year}
              style={styles.textInput}
            />
            <TouchableOpacity 
              style={styles.button} 
              activeOpacity={0.5}
              onPress={() =>{console.log(research,'research');props.onSearch?props.onSearch(true, myroute,research):null}}
            >
              <Text style={styles.text}>{i18n.t('buttons.search')}</Text>
            </TouchableOpacity>
          </View>
      </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    display:'flex',
    flexDirection: 'column',
  },
  title:{
      alignSelf:'flex-start',
      fontSize:18,
      padding:8,
      fontFamily:'poppins',
      color:'white'
  },
  containerInput:{
    flexDirection: 'column',
    justifyContent:'center',
    flex:1,
  },
  button:{
    marginTop:10,
    backgroundColor: '#272727',
    padding: 15, 
    marginVertical:5,
    borderRadius:5,
    fontFamily:'poppins',     
  },
  textInput:{
    flex:1,
    backgroundColor:'#464646',
    padding: 8, 
    marginVertical:5,
    color:'white',
    borderRadius:5,
    fontSize:13,
    fontFamily:'poppins',
  },
  text:{
    textAlign:'center',
    fontWeight: 'bold',
    fontSize : 13,
    color:'white',
  },
});