/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserCompany,
    UserCompanyFromJSON,
    UserCompanyToJSON,
} from '../models';

export interface CreateUserCompanyRequest {
    userCompany: UserCompany;
}

export interface DeleteUserCompanyRequest {
    id: number;
}

export interface GetUserCompanyRequest {
    id: number;
}

export interface PartialUpdateUserCompanyRequest {
    id: number;
    userCompany: UserCompany;
}

export interface UpdateUserCompanyRequest {
    id: number;
    userCompany: UserCompany;
}

/**
 * 
 */
export class UserCompanyResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserCompanyRaw(requestParameters: CreateUserCompanyRequest): Promise<runtime.ApiResponse<UserCompany>> {
        if (requestParameters.userCompany === null || requestParameters.userCompany === undefined) {
            throw new runtime.RequiredError('userCompany','Required parameter requestParameters.userCompany was null or undefined when calling createUserCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyToJSON(requestParameters.userCompany),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyFromJSON(jsonValue));
    }

    /**
     */
    async createUserCompany(requestParameters: CreateUserCompanyRequest): Promise<UserCompany> {
        const response = await this.createUserCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserCompanyRaw(requestParameters: DeleteUserCompanyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserCompany(requestParameters: DeleteUserCompanyRequest): Promise<void> {
        await this.deleteUserCompanyRaw(requestParameters);
    }

    /**
     */
    async getAllUserCompaniesRaw(): Promise<runtime.ApiResponse<Array<UserCompany>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCompanyFromJSON));
    }

    /**
     */
    async getAllUserCompanies(): Promise<Array<UserCompany>> {
        const response = await this.getAllUserCompaniesRaw();
        return await response.value();
    }

    /**
     */
    async getUserCompanyRaw(requestParameters: GetUserCompanyRequest): Promise<runtime.ApiResponse<UserCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyFromJSON(jsonValue));
    }

    /**
     */
    async getUserCompany(requestParameters: GetUserCompanyRequest): Promise<UserCompany> {
        const response = await this.getUserCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserCompanyRaw(requestParameters: PartialUpdateUserCompanyRequest): Promise<runtime.ApiResponse<UserCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserCompany.');
        }

        if (requestParameters.userCompany === null || requestParameters.userCompany === undefined) {
            throw new runtime.RequiredError('userCompany','Required parameter requestParameters.userCompany was null or undefined when calling partialUpdateUserCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyToJSON(requestParameters.userCompany),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserCompany(requestParameters: PartialUpdateUserCompanyRequest): Promise<UserCompany> {
        const response = await this.partialUpdateUserCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserCompanyRaw(requestParameters: UpdateUserCompanyRequest): Promise<runtime.ApiResponse<UserCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserCompany.');
        }

        if (requestParameters.userCompany === null || requestParameters.userCompany === undefined) {
            throw new runtime.RequiredError('userCompany','Required parameter requestParameters.userCompany was null or undefined when calling updateUserCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserCompanyToJSON(requestParameters.userCompany),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompanyFromJSON(jsonValue));
    }

    /**
     */
    async updateUserCompany(requestParameters: UpdateUserCompanyRequest): Promise<UserCompany> {
        const response = await this.updateUserCompanyRaw(requestParameters);
        return await response.value();
    }

}
