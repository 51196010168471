/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImpresaRiferimento,
    ImpresaRiferimentoFromJSON,
    ImpresaRiferimentoFromJSONTyped,
    ImpresaRiferimentoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReteImprese
 */
export interface ReteImprese {
    /**
     * 
     * @type {ImpresaRiferimento}
     * @memberof ReteImprese
     */
    impresaRiferimento?: ImpresaRiferimento;
    /**
     * 
     * @type {string}
     * @memberof ReteImprese
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof ReteImprese
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof ReteImprese
     */
    nrepertorio?: string;
    /**
     * 
     * @type {string}
     * @memberof ReteImprese
     */
    nregistrazione?: string;
}

export function ReteImpreseFromJSON(json: any): ReteImprese {
    return ReteImpreseFromJSONTyped(json, false);
}

export function ReteImpreseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReteImprese {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'impresaRiferimento': !exists(json, 'impresaRiferimento') ? undefined : ImpresaRiferimentoFromJSON(json['impresaRiferimento']),
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'nrepertorio': !exists(json, 'nrepertorio') ? undefined : json['nrepertorio'],
        'nregistrazione': !exists(json, 'nregistrazione') ? undefined : json['nregistrazione'],
    };
}

export function ReteImpreseToJSON(value?: ReteImprese | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'impresaRiferimento': ImpresaRiferimentoToJSON(value.impresaRiferimento),
        'denominazione': value.denominazione,
        'cfiscale': value.cfiscale,
        'nrepertorio': value.nrepertorio,
        'nregistrazione': value.nregistrazione,
    };
}


