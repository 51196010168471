import React from "react";
import {View, Text} from "react-native"
import Svg, {Path, Circle} from "react-native-svg"


interface WarningIconProps{
    width?: number,
    height?: number,
}

const WarningIcon2 = (props:WarningIconProps) => {
    return(
        <Svg width={props.width} height={props.height} viewBox="0 0 20 20" fill="none" >
            <Path d="M8.4822 13.0102C8.4822 13.2862 8.58943 13.551 8.78031 13.7462C8.97118 13.9414 9.23006 14.051 9.5 14.051C9.76994 14.051 10.0288 13.9414 10.2197 13.7462C10.4106 13.551 10.5178 13.2862 10.5178 13.0102C10.5178 12.7342 10.4106 12.4694 10.2197 12.2742C10.0288 12.079 9.76994 11.9694 9.5 11.9694C9.23006 11.9694 8.97118 12.079 8.78031 12.2742C8.58943 12.4694 8.4822 12.7342 8.4822 13.0102ZM8.82147 6.41837V10.4082C8.82147 10.5036 8.8978 10.5816 8.9911 10.5816H10.0089C10.1022 10.5816 10.1785 10.5036 10.1785 10.4082V6.41837C10.1785 6.32296 10.1022 6.2449 10.0089 6.2449H8.9911C8.8978 6.2449 8.82147 6.32296 8.82147 6.41837ZM18.9083 15.9592L10.0874 0.346939C9.95589 0.114923 9.729 0 9.5 0C9.271 0 9.04199 0.114923 8.91264 0.346939L0.0917152 15.9592C-0.169096 16.4232 0.157448 17 0.67907 17H18.3209C18.8426 17 19.1691 16.4232 18.9083 15.9592ZM2.29483 15.3542L9.5 2.59987L16.7052 15.3542H2.29483Z" fill="#FF4C37"/>
        </Svg>

    )
}
export default WarningIcon2