/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InformazioniSupplementariName,
    InformazioniSupplementariNameFromJSON,
    InformazioniSupplementariNameFromJSONTyped,
    InformazioniSupplementariNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
 */
export interface InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche {
    /**
     * 
     * @type {InformazioniSupplementariName}
     * @memberof InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
     */
    name?: InformazioniSupplementariName;
    /**
     * 
     * @type {string}
     * @memberof InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
     */
    nil?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
     */
    globalScope?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche
     */
    typeSubstituted?: boolean;
}

export function InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSON(json: any): InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche {
    return InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSONTyped(json, false);
}

export function InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : InformazioniSupplementariNameFromJSON(json['name']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'nil': !exists(json, 'nil') ? undefined : json['nil'],
        'globalScope': !exists(json, 'globalScope') ? undefined : json['globalScope'],
        'typeSubstituted': !exists(json, 'typeSubstituted') ? undefined : json['typeSubstituted'],
    };
}

export function InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGenericheToJSON(value?: InformazioniSupplementariPoteriCongiuntiOrInfoVisuraOrInfoGeneriche | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': InformazioniSupplementariNameToJSON(value.name),
        'value': value.value,
        'nil': value.nil,
        'globalScope': value.globalScope,
        'typeSubstituted': value.typeSubstituted,
    };
}


