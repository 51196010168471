import { NavigationHelper } from '@helpers/NavigationHelper';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, TextInput, Share } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { CriteriaSelected, CriteriaType, RootStackScreenProps } from '../../../../../types';
import { i18n } from '../../../../i18n/i18n';
import Icon from '../../../Tools/Icon';
import Colors from "../../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import ModalSimpleSuccess from "@screens/Modal/ModalSimpleSuccess";
import HykeeFilter from '@screens/ComponentShared/HykeeFilter';
import { customInsightResourceApi } from '@services/apis/ApiConfiguration';
import { Company, Insight, InsightCurrentInsightStateEnum } from '@services/apis/generated';
import SharedContext from '../../../../navigation/SharedContext';
import { CompanyCriteria } from '@services/apis/generated/models/CompanyCriteria';
import ModalSimpleError from "@screens/Modal/ModalSimpleError";
import {delay} from "@helpers/Sleep";

export type InsightForm = {
    id: number | undefined,
    title: string,
    description: string,
    publisher: Company
}


export default function AddNewPartnerProgramScreen({ navigation, route }: RootStackScreenProps<'AddNewPartnerProgram'>) {

    const sharedContext = React.useContext(SharedContext);

    const [successSaveModalVisible, setSuccessSaveModalVisible] = useState<boolean>(false);
    const [successSendModalVisible, setSuccessSendModalVisible] = useState<boolean>(false);
    const [saveModalError, setSaveModalError] = useState<boolean>(false);


    const [isDraft, setIsDraft] = useState<boolean>(route.params?.insight.currentInsightState == InsightCurrentInsightStateEnum.Draft);

    const criteria: CriteriaType[] = [
        {
            nameCriteria: 'Città',
            dataCriteria: ['Bologna', 'Milano', 'Roma', "Torino", "Genova"],
            multipleSelect: false,
        },
        {
            nameCriteria: 'Regione',
            dataCriteria: ['Emilia Romagna', 'Lombardia', 'Toscana', 'Campania'],
            multipleSelect: true,
        },
        {
            nameCriteria: 'Settore di attività',
            dataCriteria: ['Informatica', 'Meccanica', 'Finanza'],
            multipleSelect: true,
        },
        {
            nameCriteria: 'N. dipendenti',
            dataCriteria: ['<10', '10-50', '50-200', '200+'],
            multipleSelect: true,
        },
    ]

    enum Fields {
        title = "title",
        description = "description",
    }

    const [form, setForm] = React.useState<Insight>({
        id: undefined,
        title: '',
        description: '',
        publisher: sharedContext.myCompanySelected
    })

    useEffect(() => {
        if (route.params && route.params.insight) {
            form.id = route.params?.insight.id;
            form.title = route.params?.insight.title;
            form.description = route.params?.insight.description;
            setForm({ ...form });
        }

        return () => {
        }
    }, [route.params])

    function handleChange(key: any) {
        return (event: any) => {
            const value = event;
            setForm((form: any) => ({ ...form, [key]: value }));
        }
    }

    const saveInsightAndPublish = (publish?: boolean) => {
        // save insight (check if existing or not)
        if (publish) {
            form.currentInsightState = InsightCurrentInsightStateEnum.Published;
            setForm({ ...form })
        }
        if (route.params?.insight.id) {
            customInsightResourceApi.editInsightCustom({ insightDTO: { insight: form } }).then(() => {
                setSuccessSaveModalVisible(true);
                if (!successSaveModalVisible) {
                    NavigationHelper.navigateToGoBack(navigation);
                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            customInsightResourceApi.createInsightCustom({ insightDTO: { insight: form } }).then(() => {
                setSuccessSaveModalVisible(true);
                if (!successSaveModalVisible) {
                    NavigationHelper.navigateToGoBack(navigation);
                }
            }).catch((e) => {
                setSaveModalError(true)
                console.log(e)
            })
        }

    }

    const saveInsight = () => {
        saveInsightAndPublish(false);
    }

    const submitForm = () => {
        saveInsightAndPublish(true);
    }

    const deleteInsight = () => {
        console.log("delete insight " + form.id)
        customInsightResourceApi.deleteInsightCustom({ id: form.id! })
            .then(() => {
                NavigationHelper.navigateToGoBack(navigation);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const filterSelected = (data: CriteriaSelected[]) => {
        console.log(data)
    }


    return (

        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={'#00CC83'} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t("components.add_new_partner_program.title")}</Text>
            </View>

            <ScrollView style={hykeeStyle.containerScroll}>

                {/**TITLE */}
                <View>
                    <Text style={hykeeStyle.textLabel}>
                        {i18n.t("components.add_new_partner_program.first_paragraph")}
                    </Text>
                </View>
                <View>
                    <TextInput
                        style={hykeeStyle.textInput}
                        placeholder={i18n.t("components.add_new_partner_program.title_placeholder")}
                        onChangeText={handleChange(Fields.title)}
                        value={form.title ? form.title : ''} />
                </View>

                {/**DESCRIPTION/ CONTENT */}
                <View>
                    <Text style={hykeeStyle.textLabel}>
                        {i18n.t("components.add_new_partner_program.description")}
                    </Text>
                </View>
                <View style={hykeeStyle.textAreaInputContainer}>
                    <TextInput
                        style={hykeeStyle.textInput}
                        multiline
                        placeholder={i18n.t("components.add_new_partner_program.description_placeholder")}
                        onChangeText={handleChange(Fields.description)}
                        value={form.description ? form.description : ''} />
                </View>

                {/**ADDRESSEE */}
                {/* <View>
                    <Text style={hykeeStyle.textLabel}>
                        {i18n.t("components.add_new_partner_program.recipients")}
                    </Text>
                    <HykeeFilter criteria={criteria} callbackCriteria={filterSelected} isMultiple={true} showSelected={true} />
                </View> */}

                {/**SAVE BUTTONS */}
                <View style={styles.saveButtonContainer}>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={saveInsight}
                    >
                        <Text style={hykeeStyle.buttonText}>{i18n.t("components.add_new_partner_program.button_text_save")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={submitForm}
                    >
                        <Text style={hykeeStyle.buttonText}>{i18n.t("components.add_new_partner_program.button_text_save_send")}</Text>
                    </TouchableOpacity>
                    {isDraft &&
                        <TouchableOpacity
                            style={[styles.button, { backgroundColor: "#DC3545" }]}
                            onPress={deleteInsight}
                        >
                            <Text style={hykeeStyle.buttonText}>{i18n.t("components.add_new_partner_program.button_text_delete")}</Text>
                        </TouchableOpacity>
                    }

                </View>


            </ScrollView>
            {successSaveModalVisible &&
                <ModalSimpleSuccess
                    msg={i18n.t("components.add_new_partner_program.text_modal_saved")}
                    visible={(visible) => setSuccessSaveModalVisible(visible)}
                />
            }
            { saveModalError &&
                <ModalSimpleError
                    msg={"errore"}
                    visible={(visible) => setSaveModalError(visible)}
                />
            }
            {successSendModalVisible &&
                <ModalSimpleSuccess
                    msg={i18n.t("components.add_new_partner_program.text_modal_send")}
                    visible={(visible) => setSuccessSendModalVisible(visible)}
                />
            }

        </LinearGradient>

    )
}

const styles = StyleSheet.create({
    titleText: {
        marginHorizontal: 15,
        marginTop: 5,
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        fontFamily: 'poppins',
    },
    containerSubtitle: {
        marginBottom: 20,
    },
    containerList: {
        marginBottom: 20,
    },
    saveButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 30,
        marginBottom: 30,
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00CC83',
        color: 'white',
        padding: 15,
        borderRadius: 5,
        margin: 5
    },
    titleManage: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        marginBottom: 20,
    },
    container: {
        flex: 0.15,
        marginTop: 50,
        marginBottom: 20,
        flexDirection: 'row',
        marginHorizontal: 20,
        fontFamily: 'poppins',
        alignItems: "center",
        justifyContent: "flex-start"
    },
    containerScroll: {
        marginBottom: 40,
        marginHorizontal: 15,
    },
    delimiter: {
        borderWidth: 1,
        borderColor: '#505050',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        padding: 15,
    },
    col: {
        flex: 0.8
    }
});