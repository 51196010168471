/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface PushToken
 */
export interface PushToken {
    /**
     * 
     * @type {number}
     * @memberof PushToken
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PushToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof PushToken
     */
    operatingSystem?: PushTokenOperatingSystemEnum;
    /**
     * 
     * @type {Date}
     * @memberof PushToken
     */
    creationDate?: Date;
    /**
     * 
     * @type {User}
     * @memberof PushToken
     */
    user?: User;
}

/**
* @export
* @enum {string}
*/
export enum PushTokenOperatingSystemEnum {
    Ios = 'IOS',
    Android = 'ANDROID'
}

export function PushTokenFromJSON(json: any): PushToken {
    return PushTokenFromJSONTyped(json, false);
}

export function PushTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'operatingSystem': !exists(json, 'operatingSystem') ? undefined : json['operatingSystem'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function PushTokenToJSON(value?: PushToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'operatingSystem': value.operatingSystem,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'user': UserToJSON(value.user),
    };
}


