/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sezione,
    SezioneFromJSON,
    SezioneFromJSONTyped,
    SezioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sezioni
 */
export interface Sezioni {
    /**
     * 
     * @type {Array<Sezione>}
     * @memberof Sezioni
     */
    sezione: Array<Sezione>;
}

export function SezioniFromJSON(json: any): Sezioni {
    return SezioniFromJSONTyped(json, false);
}

export function SezioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sezioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sezione': ((json['sezione'] as Array<any>).map(SezioneFromJSON)),
    };
}

export function SezioniToJSON(value?: Sezioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sezione': ((value.sezione as Array<any>).map(SezioneToJSON)),
    };
}


