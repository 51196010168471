/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessRatingTableDTO,
    BusinessRatingTableDTOFromJSON,
    BusinessRatingTableDTOFromJSONTyped,
    BusinessRatingTableDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessRatingDTO
 */
export interface BusinessRatingDTO {
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    leanNumQuestion?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    areaScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    agilityScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    growthScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    attitudeScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    leanScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    areaMaxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    agilityMaxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    growthMaxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    attitudeMaxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    leanMaxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    areaPerc?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    agilityPerc?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    growthPerc?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    attitudePerc?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    leanPerc?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessRatingDTO
     */
    businessScore?: number;
    /**
     * 
     * @type {Array<BusinessRatingTableDTO>}
     * @memberof BusinessRatingDTO
     */
    tables?: Array<BusinessRatingTableDTO>;
}

export function BusinessRatingDTOFromJSON(json: any): BusinessRatingDTO {
    return BusinessRatingDTOFromJSONTyped(json, false);
}

export function BusinessRatingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessRatingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leanNumQuestion': !exists(json, 'leanNumQuestion') ? undefined : json['leanNumQuestion'],
        'areaScore': !exists(json, 'areaScore') ? undefined : json['areaScore'],
        'agilityScore': !exists(json, 'agilityScore') ? undefined : json['agilityScore'],
        'growthScore': !exists(json, 'growthScore') ? undefined : json['growthScore'],
        'attitudeScore': !exists(json, 'attitudeScore') ? undefined : json['attitudeScore'],
        'leanScore': !exists(json, 'leanScore') ? undefined : json['leanScore'],
        'areaMaxScore': !exists(json, 'areaMaxScore') ? undefined : json['areaMaxScore'],
        'agilityMaxScore': !exists(json, 'agilityMaxScore') ? undefined : json['agilityMaxScore'],
        'growthMaxScore': !exists(json, 'growthMaxScore') ? undefined : json['growthMaxScore'],
        'attitudeMaxScore': !exists(json, 'attitudeMaxScore') ? undefined : json['attitudeMaxScore'],
        'leanMaxScore': !exists(json, 'leanMaxScore') ? undefined : json['leanMaxScore'],
        'areaPerc': !exists(json, 'areaPerc') ? undefined : json['areaPerc'],
        'agilityPerc': !exists(json, 'agilityPerc') ? undefined : json['agilityPerc'],
        'growthPerc': !exists(json, 'growthPerc') ? undefined : json['growthPerc'],
        'attitudePerc': !exists(json, 'attitudePerc') ? undefined : json['attitudePerc'],
        'leanPerc': !exists(json, 'leanPerc') ? undefined : json['leanPerc'],
        'businessScore': !exists(json, 'businessScore') ? undefined : json['businessScore'],
        'tables': !exists(json, 'tables') ? undefined : ((json['tables'] as Array<any>).map(BusinessRatingTableDTOFromJSON)),
    };
}

export function BusinessRatingDTOToJSON(value?: BusinessRatingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leanNumQuestion': value.leanNumQuestion,
        'areaScore': value.areaScore,
        'agilityScore': value.agilityScore,
        'growthScore': value.growthScore,
        'attitudeScore': value.attitudeScore,
        'leanScore': value.leanScore,
        'areaMaxScore': value.areaMaxScore,
        'agilityMaxScore': value.agilityMaxScore,
        'growthMaxScore': value.growthMaxScore,
        'attitudeMaxScore': value.attitudeMaxScore,
        'leanMaxScore': value.leanMaxScore,
        'areaPerc': value.areaPerc,
        'agilityPerc': value.agilityPerc,
        'growthPerc': value.growthPerc,
        'attitudePerc': value.attitudePerc,
        'leanPerc': value.leanPerc,
        'businessScore': value.businessScore,
        'tables': value.tables === undefined ? undefined : ((value.tables as Array<any>).map(BusinessRatingTableDTOToJSON)),
    };
}


