/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiUdienza,
    EstremiUdienzaFromJSON,
    EstremiUdienzaFromJSONTyped,
    EstremiUdienzaToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComunicazioneCuratore
 */
export interface ComunicazioneCuratore {
    /**
     * 
     * @type {EstremiUdienza}
     * @memberof ComunicazioneCuratore
     */
    estremiUdienza?: EstremiUdienza;
    /**
     * 
     * @type {string}
     * @memberof ComunicazioneCuratore
     */
    tribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof ComunicazioneCuratore
     */
    dtProvvedimento?: string;
    /**
     * 
     * @type {string}
     * @memberof ComunicazioneCuratore
     */
    nomeGiudice?: string;
    /**
     * 
     * @type {string}
     * @memberof ComunicazioneCuratore
     */
    cognomeGiudice?: string;
    /**
     * 
     * @type {string}
     * @memberof ComunicazioneCuratore
     */
    nprovvedimento?: string;
}

export function ComunicazioneCuratoreFromJSON(json: any): ComunicazioneCuratore {
    return ComunicazioneCuratoreFromJSONTyped(json, false);
}

export function ComunicazioneCuratoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComunicazioneCuratore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiUdienza': !exists(json, 'estremiUdienza') ? undefined : EstremiUdienzaFromJSON(json['estremiUdienza']),
        'tribunale': !exists(json, 'tribunale') ? undefined : json['tribunale'],
        'dtProvvedimento': !exists(json, 'dtProvvedimento') ? undefined : json['dtProvvedimento'],
        'nomeGiudice': !exists(json, 'nomeGiudice') ? undefined : json['nomeGiudice'],
        'cognomeGiudice': !exists(json, 'cognomeGiudice') ? undefined : json['cognomeGiudice'],
        'nprovvedimento': !exists(json, 'nprovvedimento') ? undefined : json['nprovvedimento'],
    };
}

export function ComunicazioneCuratoreToJSON(value?: ComunicazioneCuratore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiUdienza': EstremiUdienzaToJSON(value.estremiUdienza),
        'tribunale': value.tribunale,
        'dtProvvedimento': value.dtProvvedimento,
        'nomeGiudice': value.nomeGiudice,
        'cognomeGiudice': value.cognomeGiudice,
        'nprovvedimento': value.nprovvedimento,
    };
}


