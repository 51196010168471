import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { StatusBar } from 'expo-status-bar';
import { Platform, ScrollView, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { RootStackScreenProps } from "../../../../../types";
import { i18n } from "@i18n/i18n";
import Icon from "@screens/Tools/Icon";
import {  customCompanySurveyQuestionResResourceApi, customCompanySurveyResourceApi, customSurveyResourceApi, } from "@services/apis/ApiConfiguration";
import { CompanySurvey, CompanySurveyQuestionRes, CompanySurveyQuestionResResourceApi, CustomCompanySurveyQuestionResResourceApi, mapValues, QuestionResponseDTO, Survey, SurveyQuestionResponse, SurveyQuestionResponseDTO } from "@services/apis/generated";
import { AlertHelper } from "@helpers/AlertHelper";
import HykeeList, { ConfigList } from "@screens/ComponentShared/HykeeList";
import { NavigationHelper } from "@helpers/NavigationHelper";
import Colors from "../../../../../constants/Colors";
import { ColorTextLightGreen } from "@components/ColorTheme";
import hykeeStyle from "@components/hykeeStyle";
import {BaseAPIManager} from "../../../../services/model/BaseAPIManager";


export default function SelfAssessmentScreen({ route, navigation }: RootStackScreenProps<"SelfAssessment">) {

  const [arrayHykeeList, setArrayHykeeList] = React.useState<any>([]);
  const [configList, setConfigList] = React.useState<ConfigList>({ listTitle: "", key: "", type: '' });
  const [arrayQuestions, setArrayQuestions] = React.useState<QuestionResponseDTO[]>([]);
  const [arrayCompanySurveyQuestRes, setArrayCompanySurveyQuestRes] = React.useState<CompanySurveyQuestionRes[]>([]);
  const [arrayResponse, setArrayResponse] = React.useState<SurveyQuestionResponse[]>([]);
  const [companySurvey, setCompanySurvey] = React.useState<CompanySurvey>(route.params as CompanySurvey)

  const [surveyPoint, setSurveyPoint] = React.useState<number>(0);
  let firstElement = false;

  useEffect(() => {
    getAnswers(route.params as CompanySurvey);
  }, []);


  const listCallback = (answer: any): void => {

    //Back button
    if (answer.type == 'lastElement' && answer.item == undefined) {
      navigateToSurveyList('lastElement');
    }
    else {
      if (answer.type == 'back') {
        surveyPoint != 0 ? previousQuestion(1) : '';
        if (surveyPoint == 1) {
          firstElement = true;
        }
      }
      //Next button
      else if (answer.type == 'next') {
        if (surveyPoint < arrayQuestions.length - 1) {
          surveyPoint == arrayQuestions.length ? nextQuestion(0) : nextQuestion(1);
        }
      }
      else {
        let found;
        if (arrayResponse.length > 0) {
          found = arrayResponse.findIndex(val => val.surveyQuestion?.id == answer.item.surveyQuestion.id);
        }
        if (found != -1 && found != undefined) {
          arrayResponse[found] = answer.item;
        }
        else {
          arrayResponse.push(answer.item);
        }
        submitSurveyAnswer(answer);
      }
    }
  }

  /**
  * Submit answer or survey 
  */
  function submitSurveyAnswer(value?: any) {
    BaseAPIManager.contextLoadingCallback(true);
    customCompanySurveyResourceApi.submitSurvey({ companyDataQuestionResponseDTO: { companySurvey: companySurvey, surveyQuestionResponses: arrayResponse } }).then((res) => {
      BaseAPIManager.contextLoadingCallback(false);
      setArrayCompanySurveyQuestRes(res);

      // if (value.type == 'lastElement') {
      //   navigateToSurveyList('lastElement');
      // }
      // if (surveyPoint < arrayQuestions.length - 1) {
      //   surveyPoint == arrayQuestions.length ? nextQuestion(0) : nextQuestion(1);
      // }
    }).catch(err => console.log(err));
  }

  const getAnswers = (item: CompanySurvey): void => {
    if (item.survey?.id) {
      firstElement = true;
      customSurveyResourceApi.getAllSurveysQuestionResponseBySurveyId({ id: item.survey.id }).then((res) => {
        customCompanySurveyQuestionResResourceApi.getAllCompanySurveyQuestionResFilter({ customCompanySurveyQuestionResCriteria: { companySurveyId: { equals: item.id } } }).then((resCompleted) => {
          if (res.questionResponseDTO) {
            setArrayQuestions(res.questionResponseDTO);
            setArrayCompanySurveyQuestRes(resCompleted);
            if (res.questionResponseDTO[surveyPoint]) {
              let idResChecked = resCompleted.find((resChecked) => resChecked.surveyQuestion?.id == res.questionResponseDTO![surveyPoint].surveyQuestion?.id)
              setNewQuestion(res.questionResponseDTO![surveyPoint], idResChecked?.surveyQuestionResponse?.id);
            }
          }
        }).catch(error => {
          return AlertHelper.showSimpleErrorAlert();
        })
      }).catch(error => {
        console.error("second")
        return AlertHelper.showSimpleErrorAlert();
      })
    }
  }

  const nextQuestion = (index: number): void => {
    setSurveyPoint(surveyPoint + index);
    let idResChecked = arrayCompanySurveyQuestRes.find((resChecked) => resChecked.surveyQuestion?.id == arrayQuestions[surveyPoint + index]?.surveyQuestion?.id)
    setNewQuestion(arrayQuestions[surveyPoint + index], idResChecked?.surveyQuestionResponse?.id);
  }

  const previousQuestion = (index: number): void => {
    setSurveyPoint(surveyPoint - index);
    let idResChecked = arrayCompanySurveyQuestRes.find((resChecked) => resChecked.surveyQuestion?.id == arrayQuestions[surveyPoint - index]?.surveyQuestion?.id)
    setNewQuestion(arrayQuestions[surveyPoint - index], idResChecked?.surveyQuestionResponse?.id);
  }

  /**
   * Navigate to list if the Survey is completed
   */
  const navigateToSurveyList = (value?: string): void => {
    if (arrayResponse.length == arrayQuestions.length || value == 'lastElement') {
      NavigationHelper.navigateToSurvey(navigation, companySurvey);
    }
  }

  const setNewQuestion = (item: QuestionResponseDTO | undefined, idResChecked?: number | undefined): void => {
    if (item) {
      setArrayHykeeList(item.surveyQuestionResponses);
      let configHykeeList: ConfigList = { listTitle: item.surveyQuestion?.questionIt, key: 'responseIt', type: 'answer', idResChecked: idResChecked };

      if (arrayQuestions.length - 2 == surveyPoint) {
        configHykeeList.type = 'lastElement';
      }
      else if (firstElement) {
        configHykeeList.type = 'firstElement';
        firstElement = false;
      } else {
        configHykeeList.type = 'moreElement';
      }
      setConfigList(configHykeeList);
    }
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={styles.title}>
          {i18n.t('screens.self_assestment.title')}
        </Text>
      </View>
      <ScrollView style={styles.containerList} nestedScrollEnabled={true}>
        <HykeeList
          list={arrayHykeeList}
          config={configList}
          passToParent={listCallback}
          navigate={undefined} />
      </ScrollView>
    </LinearGradient>
  );

}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 50,
    bottom: 20,
    left: 0,
    right: 0,
  },
  containerList: {
    marginHorizontal: 20,
  },
  containerBackGradient: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    padding: 8,
    margin: 0.5,
    borderRadius: 13,
    overflow: 'hidden'
  },
  col: {
    flex: 0.7,
    flexDirection: 'column'
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  borderGradient: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  containerTitle: {
    marginTop: 50,
    marginLeft: 20,
    flexDirection: "row",
    alignItems: 'center',
  },
  goBack: {
    marginRight: 20
  },
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  contentTitle: {
    flexDirection: 'row',
  },
  title: {
    alignSelf: 'center',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
  buttonShowHide: {
    alignSelf: 'center',
    flex: 0.1,
  },
  containerTextInput: {
    flex: 0.7,
    justifyContent: 'flex-start',
    fontFamily: 'poppins',
  },
  textUnderTable: {
    alignSelf: 'flex-start',
    fontSize: 13,
    padding: 10,
    fontFamily: 'poppins',
    color: 'white'
  },
  labelTextInput: {
    alignSelf: 'flex-start',
    color: 'white',
    marginBottom: 5,
    fontFamily: 'poppins',
  },
  textInput: {
    backgroundColor: '#464646',
    padding: 13,
    color: 'white',
    borderRadius: 5,
    marginBottom: 15,
    fontFamily: 'poppins',
  },
  viewForgot: {
    alignSelf: 'flex-end',
    fontFamily: 'poppins',
  },
  textForgot: {
    color: '#00CC83',
    fontFamily: 'poppins',
  },
  buttonLogin: {
    marginTop: 40,
    backgroundColor: '#222222',
    padding: 20,
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  textLogin: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
    fontFamily: 'poppins',
  },
  viewRegistration: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 20,
    flex: 0.1
  },
  textRegistration: {
    color: 'white',
    fontFamily: 'poppins',
  },
  header: {
    padding: 20,
    backgroundColor: '#fff',
  },
  contentAccordion: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  labelAzienda: {
    padding: 10,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  label: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  textSearch: {
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  questionText: {
    textAlign: 'left',
    marginLeft: 15,
    fontFamily: 'poppins',
  },
  containerSA: {
    flex: 1,
    marginHorizontal: 20,
  },
  containerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 50,
    padding: 20,
    borderRadius: 13,
  },
  imageButton: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 30,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
  },
});