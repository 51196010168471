/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReteImprese,
    ReteImpreseFromJSON,
    ReteImpreseFromJSONTyped,
    ReteImpreseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RetiImprese
 */
export interface RetiImprese {
    /**
     * 
     * @type {Array<ReteImprese>}
     * @memberof RetiImprese
     */
    reteImprese: Array<ReteImprese>;
}

export function RetiImpreseFromJSON(json: any): RetiImprese {
    return RetiImpreseFromJSONTyped(json, false);
}

export function RetiImpreseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetiImprese {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reteImprese': ((json['reteImprese'] as Array<any>).map(ReteImpreseFromJSON)),
    };
}

export function RetiImpreseToJSON(value?: RetiImprese | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reteImprese': ((value.reteImprese as Array<any>).map(ReteImpreseToJSON)),
    };
}


