/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyToJSON,
    Insight,
    InsightFromJSON,
    InsightToJSON,
    InsightDTO,
    InsightDTOFromJSON,
    InsightDTOToJSON,
} from '../models';

export interface CreateInsightCustomRequest {
    insightDTO: InsightDTO;
}

export interface DeleteInsightCustomRequest {
    id: number;
}

export interface EditInsightCustomRequest {
    insightDTO: InsightDTO;
}

export interface GetAllInsightForLoggedUserRequest {
    company: Company;
}

export interface GetInsightByIdRequest {
    id: number;
}

export interface PublishInsightRequest {
    insight: Insight;
}

/**
 * 
 */
export class CustomInsightResourceApi extends runtime.BaseAPI {

    /**
     */
    async createInsightCustomRaw(requestParameters: CreateInsightCustomRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.insightDTO === null || requestParameters.insightDTO === undefined) {
            throw new runtime.RequiredError('insightDTO','Required parameter requestParameters.insightDTO was null or undefined when calling createInsightCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/insight/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightDTOToJSON(requestParameters.insightDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async createInsightCustom(requestParameters: CreateInsightCustomRequest): Promise<Insight> {
        const response = await this.createInsightCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteInsightCustomRaw(requestParameters: DeleteInsightCustomRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInsightCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/insight/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteInsightCustom(requestParameters: DeleteInsightCustomRequest): Promise<void> {
        await this.deleteInsightCustomRaw(requestParameters);
    }

    /**
     */
    async editInsightCustomRaw(requestParameters: EditInsightCustomRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.insightDTO === null || requestParameters.insightDTO === undefined) {
            throw new runtime.RequiredError('insightDTO','Required parameter requestParameters.insightDTO was null or undefined when calling editInsightCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/insight/edit`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InsightDTOToJSON(requestParameters.insightDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async editInsightCustom(requestParameters: EditInsightCustomRequest): Promise<Insight> {
        const response = await this.editInsightCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllInsightForLoggedUserRaw(requestParameters: GetAllInsightForLoggedUserRequest): Promise<runtime.ApiResponse<Array<Insight>>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling getAllInsightForLoggedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/insight/insights`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightFromJSON));
    }

    /**
     */
    async getAllInsightForLoggedUser(requestParameters: GetAllInsightForLoggedUserRequest): Promise<Array<Insight>> {
        const response = await this.getAllInsightForLoggedUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllInsightsCreatedByLoggedUserRaw(): Promise<runtime.ApiResponse<Array<Insight>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/insight/my-insights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightFromJSON));
    }

    /**
     */
    async getAllInsightsCreatedByLoggedUser(): Promise<Array<Insight>> {
        const response = await this.getAllInsightsCreatedByLoggedUserRaw();
        return await response.value();
    }

    /**
     */
    async getInsightByIdRaw(requestParameters: GetInsightByIdRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInsightById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/insight/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async getInsightById(requestParameters: GetInsightByIdRequest): Promise<Insight> {
        const response = await this.getInsightByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async publishInsightRaw(requestParameters: PublishInsightRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.insight === null || requestParameters.insight === undefined) {
            throw new runtime.RequiredError('insight','Required parameter requestParameters.insight was null or undefined when calling publishInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/insight/publish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightToJSON(requestParameters.insight),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async publishInsight(requestParameters: PublishInsightRequest): Promise<Insight> {
        const response = await this.publishInsightRaw(requestParameters);
        return await response.value();
    }

}
