import { BackgroundColorButtonBlueSky, BackgroundColorButtonDarkRed, BackgroundColorButtonGreen } from "@components/ColorTheme";
import hykeeStyle from "@components/hykeeStyle";
import React from "react";
import { StyleSheet, Alert, Modal, Image, ScrollView, TouchableOpacity, View, Text, StatusBar, TextInput, Dimensions } from "react-native";
import SuccessModalIcon from "../../../assets/svg/SuccessModalIcon";
import WarningIcon2 from "../../../assets/svg/Warning2Icon";
import WarningIcon from "../../../assets/svg/WarningIcon";
import { i18n } from "../../i18n/i18n";


export default function ModalSimpleError(props: { msg: string,  visible: (visible: boolean) => void }) {
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={true}
            onRequestClose={() => {
                props.visible ? props.visible(false) : null;
            }}
        >
            <View style={styles.modalView}>
                <View style={{ alignItems: 'center' }}>
                    <WarningIcon2 width={250} height={80} />
                </View>
                <View style={styles.modalText}>
                    <Text style={styles.textStyle}>
                        {props.msg}
                    </Text>
                </View>
                <View style={[hykeeStyle.row, { justifyContent: 'space-evenly', alignItems: 'center' }]}>
                    <TouchableOpacity
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => props.visible ? props.visible(false) : null
                        }
                    >
                        <Text style={styles.buttonText}>{i18n.t('button.ok') }</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({

    modalView: {
        alignSelf: 'center',
        backgroundColor: "white",
        position:'absolute',
        top: Dimensions.get('window').height>700? Dimensions.get('window').height/2-100 : 100,
        borderRadius: 20,
        padding: 35,
        width: '85%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    button: {
        borderRadius: 10,
        padding: 10,
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: BackgroundColorButtonBlueSky(),
        paddingHorizontal: 40
    },
    buttonConfirm: {
        backgroundColor: BackgroundColorButtonGreen(),
        paddingHorizontal: 40
    },
    buttonText: {
        fontSize: 18,
        color: "white",
        fontFamily: 'poppins',
        textAlign: "center",
    },
    textStyle: {
        color: "black",
        padding: 10,
        fontSize: 20,
        fontFamily: 'poppins',
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
});