/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Partecipata,
    PartecipataFromJSON,
    PartecipataFromJSONTyped,
    PartecipataToJSON,
} from './';

/**
 * 
 * @export
 * @interface TabellaPartecipateImpresa
 */
export interface TabellaPartecipateImpresa {
    /**
     * 
     * @type {Array<Partecipata>}
     * @memberof TabellaPartecipateImpresa
     */
    partecipata: Array<Partecipata>;
    /**
     * 
     * @type {string}
     * @memberof TabellaPartecipateImpresa
     */
    tipoPartecipate?: string;
    /**
     * 
     * @type {string}
     * @memberof TabellaPartecipateImpresa
     */
    ctipoPartecipate?: string;
}

export function TabellaPartecipateImpresaFromJSON(json: any): TabellaPartecipateImpresa {
    return TabellaPartecipateImpresaFromJSONTyped(json, false);
}

export function TabellaPartecipateImpresaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TabellaPartecipateImpresa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partecipata': ((json['partecipata'] as Array<any>).map(PartecipataFromJSON)),
        'tipoPartecipate': !exists(json, 'tipoPartecipate') ? undefined : json['tipoPartecipate'],
        'ctipoPartecipate': !exists(json, 'ctipoPartecipate') ? undefined : json['ctipoPartecipate'],
    };
}

export function TabellaPartecipateImpresaToJSON(value?: TabellaPartecipateImpresa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partecipata': ((value.partecipata as Array<any>).map(PartecipataToJSON)),
        'tipoPartecipate': value.tipoPartecipate,
        'ctipoPartecipate': value.ctipoPartecipate,
    };
}


