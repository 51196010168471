// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import CreditsSvg from '../../../../assets/svg/CreditsSvg';
import SearchIcon from '../../../../assets/svg/SearchIcon';
import SearchImageSvg from '../../../../assets/svg/SearchImageSvg';
import { i18n } from '../../../i18n/i18n';
import CreditsContext from './CreditsContext';


export default function AvaiableResearch() {

    const context = React.useContext(CreditsContext);
  
    return (
        <>
            <Text style={styles.label}>
                {i18n.t('components.research.research')}
            </Text>
            <LinearGradient
                colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                locations={[0.4, 0.8]}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.borderGradient}
            >
                <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                >
                    <View style={hykeeStyle.directionRow}>
                        {(context.extendedMe?.availableSearches == 0 || context.extendedMe?.availableSearches == undefined) &&
                            <View style={styles.col}>
                                <Text style={styles.textLabel}>
                                    {i18n.t('components.research.no_research_available')}
                                </Text>
                            </View>
                        }
                        {context.extendedMe?.availableSearches! > 0 &&
                            <View style={styles.col}>
                                <Text style={styles.textLabel}>
                                    {context.extendedMe?.availableSearches + ' ' + i18n.t('screens.research.research')}
                                </Text>
                                <Text style={styles.textUnderTable}>
                                    {i18n.t('screens.research.available_part1') + ' ' + context.extendedMe?.availableSearches + ' ' + i18n.t('screens.research.used_part2')}
                                </Text>
                            </View>
                        }
                        <View style={styles.contentImg}>
                            <SearchIcon width={50} height={100} />
                        </View>
                    </View>

                </LinearGradient>
            </LinearGradient>
        </>
    );


}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        flex: 1,
    },
    textUnderTable: {
        alignSelf: 'flex-start',
        fontSize: 13,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    textLabel: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 10,
        fontFamily: 'poppins',
        color: 'white'
    },
    col: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentImg: {
        flex: 0.3,

        alignItems: 'center'
    },
    label: {
        color: 'white',
        fontSize: 17,
        paddingVertical: 5,
        fontFamily: 'poppins',
    },
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
        overflow: 'hidden'
    },
})