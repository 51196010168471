/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbilitazioneImpiantisti
 */
export interface AbilitazioneImpiantisti {
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    qualifica?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    lettera?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    descrizioneLettera?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    lettere?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    limitazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    dtAccertamento?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    enteRilascio?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    cqualifica?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    ftutteAttivitaImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioneImpiantisti
     */
    centeRilascio?: string;
}

export function AbilitazioneImpiantistiFromJSON(json: any): AbilitazioneImpiantisti {
    return AbilitazioneImpiantistiFromJSONTyped(json, false);
}

export function AbilitazioneImpiantistiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbilitazioneImpiantisti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qualifica': !exists(json, 'qualifica') ? undefined : json['qualifica'],
        'lettera': !exists(json, 'lettera') ? undefined : json['lettera'],
        'descrizioneLettera': !exists(json, 'descrizioneLettera') ? undefined : json['descrizioneLettera'],
        'lettere': !exists(json, 'lettere') ? undefined : json['lettere'],
        'limitazioni': !exists(json, 'limitazioni') ? undefined : json['limitazioni'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtAccertamento': !exists(json, 'dtAccertamento') ? undefined : json['dtAccertamento'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'enteRilascio': !exists(json, 'enteRilascio') ? undefined : json['enteRilascio'],
        'cqualifica': !exists(json, 'cqualifica') ? undefined : json['cqualifica'],
        'ftutteAttivitaImpresa': !exists(json, 'ftutteAttivitaImpresa') ? undefined : json['ftutteAttivitaImpresa'],
        'centeRilascio': !exists(json, 'centeRilascio') ? undefined : json['centeRilascio'],
    };
}

export function AbilitazioneImpiantistiToJSON(value?: AbilitazioneImpiantisti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qualifica': value.qualifica,
        'lettera': value.lettera,
        'descrizioneLettera': value.descrizioneLettera,
        'lettere': value.lettere,
        'limitazioni': value.limitazioni,
        'provincia': value.provincia,
        'n': value.n,
        'dtAccertamento': value.dtAccertamento,
        'dtIscrizione': value.dtIscrizione,
        'enteRilascio': value.enteRilascio,
        'cqualifica': value.cqualifica,
        'ftutteAttivitaImpresa': value.ftutteAttivitaImpresa,
        'centeRilascio': value.centeRilascio,
    };
}


