/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Atto,
    AttoFromJSON,
    AttoFromJSONTyped,
    AttoToJSON,
    TrascrizioniRs,
    TrascrizioniRsFromJSON,
    TrascrizioniRsFromJSONTyped,
    TrascrizioniRsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolloRs
 */
export interface ProtocolloRs {
    /**
     * 
     * @type {Atto}
     * @memberof ProtocolloRs
     */
    atto?: Atto;
    /**
     * 
     * @type {TrascrizioniRs}
     * @memberof ProtocolloRs
     */
    trascrizioniRs?: TrascrizioniRs;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    annoRegistroOrdine?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    tribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    provinciaTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    nriferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    nregistroOrdine?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolloRs
     */
    ccomuneTribunale?: string;
}

export function ProtocolloRsFromJSON(json: any): ProtocolloRs {
    return ProtocolloRsFromJSONTyped(json, false);
}

export function ProtocolloRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolloRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atto': !exists(json, 'atto') ? undefined : AttoFromJSON(json['atto']),
        'trascrizioniRs': !exists(json, 'trascrizioniRs') ? undefined : TrascrizioniRsFromJSON(json['trascrizioniRs']),
        'annoRegistroOrdine': !exists(json, 'annoRegistroOrdine') ? undefined : json['annoRegistroOrdine'],
        'tribunale': !exists(json, 'tribunale') ? undefined : json['tribunale'],
        'provinciaTribunale': !exists(json, 'provinciaTribunale') ? undefined : json['provinciaTribunale'],
        'nriferimento': !exists(json, 'nriferimento') ? undefined : json['nriferimento'],
        'nregistroOrdine': !exists(json, 'nregistroOrdine') ? undefined : json['nregistroOrdine'],
        'ccomuneTribunale': !exists(json, 'ccomuneTribunale') ? undefined : json['ccomuneTribunale'],
    };
}

export function ProtocolloRsToJSON(value?: ProtocolloRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atto': AttoToJSON(value.atto),
        'trascrizioniRs': TrascrizioniRsToJSON(value.trascrizioniRs),
        'annoRegistroOrdine': value.annoRegistroOrdine,
        'tribunale': value.tribunale,
        'provinciaTribunale': value.provinciaTribunale,
        'nriferimento': value.nriferimento,
        'nregistroOrdine': value.nregistroOrdine,
        'ccomuneTribunale': value.ccomuneTribunale,
    };
}


