/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PotenzaCerealiMacchinari,
    PotenzaCerealiMacchinariFromJSON,
    PotenzaCerealiMacchinariFromJSONTyped,
    PotenzaCerealiMacchinariToJSON,
} from './';

/**
 * 
 * @export
 * @interface PotenzeCerealiMacchinari
 */
export interface PotenzeCerealiMacchinari {
    /**
     * 
     * @type {Array<PotenzaCerealiMacchinari>}
     * @memberof PotenzeCerealiMacchinari
     */
    potenzaCerealiMacchinari: Array<PotenzaCerealiMacchinari>;
}

export function PotenzeCerealiMacchinariFromJSON(json: any): PotenzeCerealiMacchinari {
    return PotenzeCerealiMacchinariFromJSONTyped(json, false);
}

export function PotenzeCerealiMacchinariFromJSONTyped(json: any, ignoreDiscriminator: boolean): PotenzeCerealiMacchinari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'potenzaCerealiMacchinari': ((json['potenzaCerealiMacchinari'] as Array<any>).map(PotenzaCerealiMacchinariFromJSON)),
    };
}

export function PotenzeCerealiMacchinariToJSON(value?: PotenzeCerealiMacchinari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'potenzaCerealiMacchinari': ((value.potenzaCerealiMacchinari as Array<any>).map(PotenzaCerealiMacchinariToJSON)),
    };
}


