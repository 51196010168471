// 
// 
//
import { BackgroundColorButtonGreen } from '@components/ColorTheme';
import hykeeStyle from '@components/hykeeStyle';
import { Company, CompanyLinkRequestCurrentStateEnum, UserExtendedTypeEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import React, { } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';


export default function CompaniesConsultedAcceptedList(props: { goToCompany: (company: Company) => void }) {

  const sharedContext = React.useContext(SharedContext)
  return (
    <>
      {sharedContext.myCompaniesConsultedRequest
        && sharedContext.who == UserExtendedTypeEnum.Consultant
        && sharedContext.myCompaniesConsultedRequest.length > 0
        && sharedContext.myCompaniesConsultedRequest.find(
          (companyLinkRequest) => companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted) != undefined ?
        sharedContext.myCompaniesConsultedRequest.map((companyLinkRequest, key) => {
          return (
            <View key={key}>
              {companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted &&
                <LinearGradient
                  key={companyLinkRequest.company?.id}
                  colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                  locations={[0.4, 0.8]}
                  start={{ x: 1, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  style={styles.borderGradient}
                >
                  <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                  >
                    <View style={hykeeStyle.row} >
                      <TouchableOpacity style={hykeeStyle.col}
                        onPress={() => {
                          props.goToCompany(companyLinkRequest.company!)
                        }}>
                        <View style={styles.titleContainer}>
                          <View style={{ flex: 0.5 }}>
                            <Text numberOfLines={4} style={hykeeStyle.textLabel}>
                              {companyLinkRequest.company?.name}
                            </Text>
                          </View>
                          <View style={{ flex: 0.5 }}>
                            {/*
                            <TouchableOpacity style={styles.sendInvitationButton}>
                              <Text style={styles.buttonTextContainer}>
                                {i18n.t('components.link_companies_data.send_invitation_partner_program')}
                              </Text>
                            </TouchableOpacity>
                            */}
                          </View>
                        </View>
                        <View style={styles.contentLocation}>
                          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                            <Icon name={'calendar'} color={'#00CC83'} />
                            <Text style={styles.textUnderTable}>
                              {moment(companyLinkRequest.date).format("DD/MM/yyyy")}
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                            <Icon name={'link'} color={'#00CC83'} />
                            <Text style={styles.textUnderTable}>
                              {i18n.t('components.link_companies_data.status') + i18n.t('components.link_companies_data.' + companyLinkRequest.currentState)}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </LinearGradient>
                </LinearGradient>}
            </View>)
        }) :
        <View style={styles.containerList}>
          <Text style={styles.textLabel}>

            {i18n.t('components.link_companies_data.no_accepted')}
          </Text>
        </View>}
    </>
  );
}

const styles = StyleSheet.create({
  borderGradient: {
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  containerBack: {
    fontFamily: 'poppins',
    padding: 8,
    margin: 1,
    borderRadius: 13,
    overflow: 'hidden'
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  containerList: {
    marginBottom: 20,
  },
  contentLocation: {
    flexDirection: 'row',
    alignContent: 'center',
    padding: 5,
    justifyContent: "space-between"
  },
  textUnderTable: {
    fontSize: 13,
    paddingHorizontal: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  contentImg: {
    flex: 0.3,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  sendInvitationButton: {
    backgroundColor: BackgroundColorButtonGreen(),
    borderRadius: 10,
  },
  buttonTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    padding: 7,
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins'
  },
});