// 
// 
//
import { NavigationHelper } from '@helpers/NavigationHelper';
import { useFocusEffect } from '@react-navigation/native';
import Icon from '@screens/Tools/Icon';
import { customUserExtendedResourceApi, customUserPlanResourceApi } from '@services/apis/ApiConfiguration';
import { LinearGradient } from 'expo-linear-gradient';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import CreditsSvg from '../../../../assets/svg/CreditsSvg';
import { RootStackScreenProps } from '../../../../types';
import hykeeStyle from '../../../components/hykeeStyle';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';
import AvailableCredits from './AvailableCredits';
import CreditsContext from './CreditsContext';
import AvaiableResearch from './AvailableResearch';
import AvailableResearch from './AvailableResearch';
import Colors from "../../../../constants/Colors";
import { ColorTextLightGreen } from '@components/ColorTheme';
import { Plan, PlanNameEnum, UserPlan } from '@services/apis/generated';

export default function Wallet({ navigation, route }: RootStackScreenProps<'Wallet'>) {

    const [activePlans, setActivePlans] = React.useState<UserPlan[]>([]);
    const context = React.useContext(SharedContext);
    const [arrowVisible, setArrowVisible] = useState(false);
    const arrowBack = route.params?.arrowBack;

    useFocusEffect(
        useCallback(() => {
            return (() => {
            });
        }, [])
    )

    useEffect(() => {
        if (arrowBack) {
            setArrowVisible(true)
        }
    }, [] );

    const navigateToCredits = () => {
        let easyPlan = context.myUserPlans?.find((userPlan) => userPlan.plan?.name == PlanNameEnum.Easy);
        if (!easyPlan)
            NavigationHelper.navigateToPlanCredits(navigation)
    }

    const navigateToAvailableResearch = () => {
        let easyPlan = context.myUserPlans?.find((userPlan) => userPlan.plan?.name == PlanNameEnum.Easy);
        let plusPlan = context.myUserPlans?.find((userPlan) => userPlan.plan?.name == PlanNameEnum.Plus);
        if (!easyPlan || !plusPlan)
            NavigationHelper.navigateToPlanResearch(navigation)
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity
                    style={ (arrowVisible) ? hykeeStyle.goBack : {display:"none"}}
                    onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()}/>
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t('components.wallet.wallet')}</Text>
            </View>
            <ScrollView style={[hykeeStyle.container]}>
                <View style={[styles.contentLabel]}>
                    <TouchableOpacity
                        onPress={() => navigateToCredits()}>
                        <AvailableCredits />
                    </TouchableOpacity>
                </View>
                <View style={styles.contentLabel}>
                    <TouchableOpacity
                        onPress={() => navigateToAvailableResearch()}>
                        <AvailableResearch />
                    </TouchableOpacity>
                </View>
                {context.myUserPlans?.length! > 0 && context.myUserPlans?.map((plan, idx) => {
                    return (
                        <View key={idx} style={[hykeeStyle.directionRow, { justifyContent: 'space-between', marginVertical: 20, marginRight: 30 }]}>
                            <Text style={hykeeStyle.simplyText}>{i18n.t('components.wallet.plan')} {(plan.plan?.name)?.toUpperCase()}</Text>
                            <Text style={[hykeeStyle.simplyText, { color: ColorTextLightGreen() }]}>{i18n.t('components.wallet.active')}</Text>
                        </View>
                    )
                })
                }

            </ScrollView>
        </LinearGradient>
    );

}

const styles = StyleSheet.create({
    planActiveContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    planStatus: {
        flex: 1,
        fontWeight: 'bold',
        color: '#00CC83',
        justifyContent: 'flex-start'
    },
    planTitle: {
        flexDirection: 'row',
        flex: 1,
    },
    contentTitle: {
        marginTop: 50,
        flexDirection: 'row',
    },
    goBack: {
        paddingRight: 10
    },
    contentLabel: {
        marginBottom: 10
    },
    title: {
        color: '#00CC83',
        fontSize: 20,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        justifyContent: 'flex-start'
    },
});