/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrasferimentoAltroRegistro
 */
export interface TrasferimentoAltroRegistro {
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoAltroRegistro
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoAltroRegistro
     */
    ctipo?: string;
}

export function TrasferimentoAltroRegistroFromJSON(json: any): TrasferimentoAltroRegistro {
    return TrasferimentoAltroRegistroFromJSONTyped(json, false);
}

export function TrasferimentoAltroRegistroFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentoAltroRegistro {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function TrasferimentoAltroRegistroToJSON(value?: TrasferimentoAltroRegistro | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'ctipo': value.ctipo,
    };
}


