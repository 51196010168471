/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyAddress,
    CompanyAddressFromJSON,
    CompanyAddressFromJSONTyped,
    CompanyAddressToJSON,
    CompanyDataBlock,
    CompanyDataBlockFromJSON,
    CompanyDataBlockFromJSONTyped,
    CompanyDataBlockToJSON,
    CompanySaved,
    CompanySavedFromJSON,
    CompanySavedFromJSONTyped,
    CompanySavedToJSON,
    CompanySearched,
    CompanySearchedFromJSON,
    CompanySearchedFromJSONTyped,
    CompanySearchedToJSON,
    MarketCompetitor,
    MarketCompetitorFromJSON,
    MarketCompetitorFromJSONTyped,
    MarketCompetitorToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    year?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyData
     */
    validUntil?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyData
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    fiscalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    membershipGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    activity?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyData
     */
    establishmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    shareCapital?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    uniquePartner?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyData
     */
    lastFinancialYearClosingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    accountingPeriod?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    hykeeScore?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    businessScore?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    financialScore?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    mainAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    xmlFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    xmlFilepath?: string;
    /**
     * 
     * @type {Set<CompanyAddress>}
     * @memberof CompanyData
     */
    companyAddresses?: Set<CompanyAddress>;
    /**
     * 
     * @type {Company}
     * @memberof CompanyData
     */
    company?: Company;
    /**
     * 
     * @type {Set<CompanyDataBlock>}
     * @memberof CompanyData
     */
    companyDataBlocks?: Set<CompanyDataBlock>;
    /**
     * 
     * @type {Set<CompanySaved>}
     * @memberof CompanyData
     */
    companySaveds?: Set<CompanySaved>;
    /**
     * 
     * @type {Set<CompanySearched>}
     * @memberof CompanyData
     */
    companySearcheds?: Set<CompanySearched>;
    /**
     * 
     * @type {Set<MarketCompetitor>}
     * @memberof CompanyData
     */
    marketCompetitors?: Set<MarketCompetitor>;
}

export function CompanyDataFromJSON(json: any): CompanyData {
    return CompanyDataFromJSONTyped(json, false);
}

export function CompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'fiscalCode': !exists(json, 'fiscalCode') ? undefined : json['fiscalCode'],
        'membershipGroup': !exists(json, 'membershipGroup') ? undefined : json['membershipGroup'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'establishmentDate': !exists(json, 'establishmentDate') ? undefined : (new Date(json['establishmentDate'])),
        'shareCapital': !exists(json, 'shareCapital') ? undefined : json['shareCapital'],
        'uniquePartner': !exists(json, 'uniquePartner') ? undefined : json['uniquePartner'],
        'lastFinancialYearClosingDate': !exists(json, 'lastFinancialYearClosingDate') ? undefined : (new Date(json['lastFinancialYearClosingDate'])),
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'accountingPeriod': !exists(json, 'accountingPeriod') ? undefined : json['accountingPeriod'],
        'hykeeScore': !exists(json, 'hykeeScore') ? undefined : json['hykeeScore'],
        'businessScore': !exists(json, 'businessScore') ? undefined : json['businessScore'],
        'financialScore': !exists(json, 'financialScore') ? undefined : json['financialScore'],
        'mainAddress': !exists(json, 'mainAddress') ? undefined : json['mainAddress'],
        'xmlFilename': !exists(json, 'xmlFilename') ? undefined : json['xmlFilename'],
        'xmlFilepath': !exists(json, 'xmlFilepath') ? undefined : json['xmlFilepath'],
        'companyAddresses': !exists(json, 'companyAddresses') ? undefined : ((json['companyAddresses'] as Array<any>).map(CompanyAddressFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companyDataBlocks': !exists(json, 'companyDataBlocks') ? undefined : ((json['companyDataBlocks'] as Array<any>).map(CompanyDataBlockFromJSON)),
        'companySaveds': !exists(json, 'companySaveds') ? undefined : ((json['companySaveds'] as Array<any>).map(CompanySavedFromJSON)),
        'companySearcheds': !exists(json, 'companySearcheds') ? undefined : ((json['companySearcheds'] as Array<any>).map(CompanySearchedFromJSON)),
        'marketCompetitors': !exists(json, 'marketCompetitors') ? undefined : ((json['marketCompetitors'] as Array<any>).map(MarketCompetitorFromJSON)),
    };
}

export function CompanyDataToJSON(value?: CompanyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'fiscalCode': value.fiscalCode,
        'membershipGroup': value.membershipGroup,
        'activity': value.activity,
        'establishmentDate': value.establishmentDate === undefined ? undefined : (value.establishmentDate.toISOString()),
        'shareCapital': value.shareCapital,
        'uniquePartner': value.uniquePartner,
        'lastFinancialYearClosingDate': value.lastFinancialYearClosingDate === undefined ? undefined : (value.lastFinancialYearClosingDate.toISOString()),
        'nationality': value.nationality,
        'accountingPeriod': value.accountingPeriod,
        'hykeeScore': value.hykeeScore,
        'businessScore': value.businessScore,
        'financialScore': value.financialScore,
        'mainAddress': value.mainAddress,
        'xmlFilename': value.xmlFilename,
        'xmlFilepath': value.xmlFilepath,
        'companyAddresses': value.companyAddresses === undefined ? undefined : ((value.companyAddresses as Array<any>).map(CompanyAddressToJSON)),
        'company': CompanyToJSON(value.company),
        'companyDataBlocks': value.companyDataBlocks === undefined ? undefined : ((value.companyDataBlocks as Array<any>).map(CompanyDataBlockToJSON)),
        'companySaveds': value.companySaveds === undefined ? undefined : ((value.companySaveds as Array<any>).map(CompanySavedToJSON)),
        'companySearcheds': value.companySearcheds === undefined ? undefined : ((value.companySearcheds as Array<any>).map(CompanySearchedToJSON)),
        'marketCompetitors': value.marketCompetitors === undefined ? undefined : ((value.marketCompetitors as Array<any>).map(MarketCompetitorToJSON)),
    };
}


