/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddettiComune,
    AddettiComuneFromJSON,
    AddettiComuneFromJSONTyped,
    AddettiComuneToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddettiComuni
 */
export interface AddettiComuni {
    /**
     * 
     * @type {Array<AddettiComune>}
     * @memberof AddettiComuni
     */
    addettiComune: Array<AddettiComune>;
}

export function AddettiComuniFromJSON(json: any): AddettiComuni {
    return AddettiComuniFromJSONTyped(json, false);
}

export function AddettiComuniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddettiComuni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addettiComune': ((json['addettiComune'] as Array<any>).map(AddettiComuneFromJSON)),
    };
}

export function AddettiComuniToJSON(value?: AddettiComuni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addettiComune': ((value.addettiComune as Array<any>).map(AddettiComuneToJSON)),
    };
}


