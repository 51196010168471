/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    type?: NotificationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof Notification
     */
    company?: Company;
    /**
     * 
     * @type {Company}
     * @memberof Notification
     */
    consultant?: Company;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    consultantUser?: User;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    companyUser?: User;
}

/**
* @export
* @enum {string}
*/
export enum NotificationTypeEnum {
    SelfAssessmentCompile = 'SELF_ASSESSMENT_COMPILE',
    LinkRequest = 'LINK_REQUEST',
    Insight = 'INSIGHT'
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'consultant': !exists(json, 'consultant') ? undefined : CompanyFromJSON(json['consultant']),
        'consultantUser': !exists(json, 'consultantUser') ? undefined : UserFromJSON(json['consultantUser']),
        'companyUser': !exists(json, 'companyUser') ? undefined : UserFromJSON(json['companyUser']),
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'type': value.type,
        'read': value.read,
        'company': CompanyToJSON(value.company),
        'consultant': CompanyToJSON(value.consultant),
        'consultantUser': UserToJSON(value.consultantUser),
        'companyUser': UserToJSON(value.companyUser),
    };
}


