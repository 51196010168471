import { BackgroundColorButtonBlueSky, BackgroundColorButtonDarkRed, BackgroundColorButtonGreen } from "@components/ColorTheme";
import hykeeStyle from "@components/hykeeStyle";
import { Company } from "@services/apis/generated";
import React from "react";
import { StyleSheet, Modal, TouchableOpacity, View, Text, TextInput, Dimensions } from "react-native";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { i18n } from "../../i18n/i18n";


export default function ModalSimpleSendInvitation(props: { msg: string, companySearched?: Company, action: (text: string) => void, visible: (visible: boolean) => void }) {

    let textInput: string = '';

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={true}
            onRequestClose={() => {
                props.visible ? props.visible(false) : null;
            }}
        >

            <View style={styles.modalView}>
                <View style={{ alignItems: 'center' }}>
                    <SearchIcon width={250} height={80} />
                </View>

                <View style={styles.modalText}>
                    <Text style={styles.textStyle}>
                        {props.companySearched?.name}
                    </Text>
                    <Text style={styles.textStyle}>
                        {props.msg}
                    </Text>

                    <TextInput
                        placeholder={i18n.t('buttons.placeholder_send_invitation')}
                        placeholderTextColor='#737373'
                        onChangeText={(email) => textInput = email}
                        style={styles.textInput}
                    />
                </View>
                <View style={[hykeeStyle.directionRow, { justifyContent: 'space-between', alignItems: 'center' }]}>
                    <TouchableOpacity
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => props.visible ? props.visible(false) : null
                        }
                    >
                        <Text style={styles.buttonText}>{i18n.t('button.cancel')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.button, styles.buttonConfirm]}
                        onPress={() => { props.action ? props.action(textInput) : null, props.visible ? props.visible(false) : null }
                        }
                    >
                        <Text numberOfLines={1} style={styles.buttonText}>{i18n.t('button.confirm')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({

    modalView: {
        alignSelf: 'center',
        backgroundColor: "white",
        position: 'absolute',
        top: 200,
        // top: Dimensions.get('window').height > 700 ? Dimensions.get('window').height / 2 - 100 : 100,
        borderRadius: 20,
        padding: 35,
        width: '85%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        // bottom: 200
    },
    button: {
        borderRadius: 10,
        padding: 10,
        flex: 0.48,
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: BackgroundColorButtonDarkRed(),
    },
    buttonConfirm: {
        backgroundColor: BackgroundColorButtonGreen(),
    },
    buttonText: {
        fontSize: 16,
        color: "white",
        fontFamily: 'poppins',
        textAlign: "center",
    },
    titleStyle: {
        color: "#00CC83",
        padding: 10,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: 'poppins',
        textAlign: "center"
    },
    textStyle: {
        color: "black",
        padding: 10,
        fontSize: 14,
        fontFamily: 'poppins',
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    textInput: {
        backgroundColor: '#464646',
        padding: 13,
        color: 'white',
        borderRadius: 5,
        fontFamily: 'poppins',
    },
});