/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySurvey,
    CompanySurveyFromJSON,
    CompanySurveyFromJSONTyped,
    CompanySurveyToJSON,
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDataQuestionResponseDTO
 */
export interface CompanyDataQuestionResponseDTO {
    /**
     * 
     * @type {CompanySurvey}
     * @memberof CompanyDataQuestionResponseDTO
     */
    companySurvey?: CompanySurvey;
    /**
     * 
     * @type {Array<SurveyQuestionResponse>}
     * @memberof CompanyDataQuestionResponseDTO
     */
    surveyQuestionResponses?: Array<SurveyQuestionResponse>;
}

export function CompanyDataQuestionResponseDTOFromJSON(json: any): CompanyDataQuestionResponseDTO {
    return CompanyDataQuestionResponseDTOFromJSONTyped(json, false);
}

export function CompanyDataQuestionResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDataQuestionResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySurvey': !exists(json, 'companySurvey') ? undefined : CompanySurveyFromJSON(json['companySurvey']),
        'surveyQuestionResponses': !exists(json, 'surveyQuestionResponses') ? undefined : ((json['surveyQuestionResponses'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
    };
}

export function CompanyDataQuestionResponseDTOToJSON(value?: CompanyDataQuestionResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companySurvey': CompanySurveyToJSON(value.companySurvey),
        'surveyQuestionResponses': value.surveyQuestionResponses === undefined ? undefined : ((value.surveyQuestionResponses as Array<any>).map(SurveyQuestionResponseToJSON)),
    };
}


