/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
    DataBlockUser,
    DataBlockUserFromJSON,
    DataBlockUserFromJSONTyped,
    DataBlockUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDataBlock
 */
export interface CompanyDataBlock {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataBlock
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDataBlock
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataBlock
     */
    type?: CompanyDataBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataBlock
     */
    xmlFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataBlock
     */
    xmlFilepath?: string;
    /**
     * 
     * @type {CompanyData}
     * @memberof CompanyDataBlock
     */
    companyData?: CompanyData;
    /**
     * 
     * @type {Set<DataBlockUser>}
     * @memberof CompanyDataBlock
     */
    dataBlockUsers?: Set<DataBlockUser>;
}

/**
* @export
* @enum {string}
*/
export enum CompanyDataBlockTypeEnum {
    Partners = 'PARTNERS',
    Administrators = 'ADMINISTRATORS',
    Shares = 'SHARES'
}

export function CompanyDataBlockFromJSON(json: any): CompanyDataBlock {
    return CompanyDataBlockFromJSONTyped(json, false);
}

export function CompanyDataBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDataBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'xmlFilename': !exists(json, 'xmlFilename') ? undefined : json['xmlFilename'],
        'xmlFilepath': !exists(json, 'xmlFilepath') ? undefined : json['xmlFilepath'],
        'companyData': !exists(json, 'companyData') ? undefined : CompanyDataFromJSON(json['companyData']),
        'dataBlockUsers': !exists(json, 'dataBlockUsers') ? undefined : ((json['dataBlockUsers'] as Array<any>).map(DataBlockUserFromJSON)),
    };
}

export function CompanyDataBlockToJSON(value?: CompanyDataBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'type': value.type,
        'xmlFilename': value.xmlFilename,
        'xmlFilepath': value.xmlFilepath,
        'companyData': CompanyDataToJSON(value.companyData),
        'dataBlockUsers': value.dataBlockUsers === undefined ? undefined : ((value.dataBlockUsers as Array<any>).map(DataBlockUserToJSON)),
    };
}


