/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
}

export function PageableObjectFromJSON(json: any): PageableObject {
    return PageableObjectFromJSONTyped(json, false);
}

export function PageableObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'paged': !exists(json, 'paged') ? undefined : json['paged'],
        'unpaged': !exists(json, 'unpaged') ? undefined : json['unpaged'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function PageableObjectToJSON(value?: PageableObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'pageNumber': value.pageNumber,
        'paged': value.paged,
        'unpaged': value.unpaged,
        'sort': SortToJSON(value.sort),
        'offset': value.offset,
    };
}


