/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Atto,
    AttoFromJSON,
    AttoFromJSONTyped,
    AttoToJSON,
    TrascrizioniRi,
    TrascrizioniRiFromJSON,
    TrascrizioniRiFromJSONTyped,
    TrascrizioniRiToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttoTrascrizioni
 */
export interface AttoTrascrizioni {
    /**
     * 
     * @type {Atto}
     * @memberof AttoTrascrizioni
     */
    atto?: Atto;
    /**
     * 
     * @type {TrascrizioniRi}
     * @memberof AttoTrascrizioni
     */
    trascrizioniRi?: TrascrizioniRi;
}

export function AttoTrascrizioniFromJSON(json: any): AttoTrascrizioni {
    return AttoTrascrizioniFromJSONTyped(json, false);
}

export function AttoTrascrizioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttoTrascrizioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atto': !exists(json, 'atto') ? undefined : AttoFromJSON(json['atto']),
        'trascrizioniRi': !exists(json, 'trascrizioniRi') ? undefined : TrascrizioniRiFromJSON(json['trascrizioniRi']),
    };
}

export function AttoTrascrizioniToJSON(value?: AttoTrascrizioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atto': AttoToJSON(value.atto),
        'trascrizioniRi': TrascrizioniRiToJSON(value.trascrizioniRi),
    };
}


