/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
    PageNotification,
    PageNotificationFromJSON,
    PageNotificationToJSON,
    Pageable,
    PageableFromJSON,
    PageableToJSON,
} from '../models';

export interface CreateNotificationCustomRequest {
    notification: Notification;
}

export interface GetNotificationCustomRequest {
    pageable: Pageable;
}

export interface UpdateNotificationCustomRequest {
    notification: Notification;
}

/**
 * 
 */
export class CustomNotificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createNotificationCustomRaw(requestParameters: CreateNotificationCustomRequest): Promise<runtime.ApiResponse<Array<Notification>>> {
        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling createNotificationCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     */
    async createNotificationCustom(requestParameters: CreateNotificationCustomRequest): Promise<Array<Notification>> {
        const response = await this.createNotificationCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNotificationCountRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/custom/notifications/unread/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getNotificationCount(): Promise<number> {
        const response = await this.getNotificationCountRaw();
        return await response.value();
    }

    /**
     */
    async getNotificationCustomRaw(requestParameters: GetNotificationCustomRequest): Promise<runtime.ApiResponse<PageNotification>> {
        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getNotificationCustom.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageNotificationFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationCustom(requestParameters: GetNotificationCustomRequest): Promise<PageNotification> {
        const response = await this.getNotificationCustomRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNotificationCustomRaw(requestParameters: UpdateNotificationCustomRequest): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling updateNotificationCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async updateNotificationCustom(requestParameters: UpdateNotificationCustomRequest): Promise<Notification> {
        const response = await this.updateNotificationCustomRaw(requestParameters);
        return await response.value();
    }

}
