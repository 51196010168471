/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyLinkRequest,
    CompanyLinkRequestFromJSON,
    CompanyLinkRequestToJSON,
} from '../models';

export interface CreateCompanyLinkRequestRequest {
    companyLinkRequest: CompanyLinkRequest;
}

export interface DeleteCompanyLinkRequestRequest {
    id: number;
}

export interface GetCompanyLinkRequestRequest {
    id: number;
}

export interface PartialUpdateCompanyLinkRequestRequest {
    id: number;
    companyLinkRequest: CompanyLinkRequest;
}

export interface UpdateCompanyLinkRequestRequest {
    id: number;
    companyLinkRequest: CompanyLinkRequest;
}

/**
 * 
 */
export class CompanyLinkRequestResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyLinkRequestRaw(requestParameters: CreateCompanyLinkRequestRequest): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.companyLinkRequest === null || requestParameters.companyLinkRequest === undefined) {
            throw new runtime.RequiredError('companyLinkRequest','Required parameter requestParameters.companyLinkRequest was null or undefined when calling createCompanyLinkRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestToJSON(requestParameters.companyLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyLinkRequest(requestParameters: CreateCompanyLinkRequestRequest): Promise<CompanyLinkRequest> {
        const response = await this.createCompanyLinkRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyLinkRequestRaw(requestParameters: DeleteCompanyLinkRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyLinkRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyLinkRequest(requestParameters: DeleteCompanyLinkRequestRequest): Promise<void> {
        await this.deleteCompanyLinkRequestRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyLinkRequestsRaw(): Promise<runtime.ApiResponse<Array<CompanyLinkRequest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyLinkRequestFromJSON));
    }

    /**
     */
    async getAllCompanyLinkRequests(): Promise<Array<CompanyLinkRequest>> {
        const response = await this.getAllCompanyLinkRequestsRaw();
        return await response.value();
    }

    /**
     */
    async getCompanyLinkRequestRaw(requestParameters: GetCompanyLinkRequestRequest): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyLinkRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyLinkRequest(requestParameters: GetCompanyLinkRequestRequest): Promise<CompanyLinkRequest> {
        const response = await this.getCompanyLinkRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyLinkRequestRaw(requestParameters: PartialUpdateCompanyLinkRequestRequest): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyLinkRequest.');
        }

        if (requestParameters.companyLinkRequest === null || requestParameters.companyLinkRequest === undefined) {
            throw new runtime.RequiredError('companyLinkRequest','Required parameter requestParameters.companyLinkRequest was null or undefined when calling partialUpdateCompanyLinkRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestToJSON(requestParameters.companyLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyLinkRequest(requestParameters: PartialUpdateCompanyLinkRequestRequest): Promise<CompanyLinkRequest> {
        const response = await this.partialUpdateCompanyLinkRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyLinkRequestRaw(requestParameters: UpdateCompanyLinkRequestRequest): Promise<runtime.ApiResponse<CompanyLinkRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyLinkRequest.');
        }

        if (requestParameters.companyLinkRequest === null || requestParameters.companyLinkRequest === undefined) {
            throw new runtime.RequiredError('companyLinkRequest','Required parameter requestParameters.companyLinkRequest was null or undefined when calling updateCompanyLinkRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestToJSON(requestParameters.companyLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyLinkRequest(requestParameters: UpdateCompanyLinkRequestRequest): Promise<CompanyLinkRequest> {
        const response = await this.updateCompanyLinkRequestRaw(requestParameters);
        return await response.value();
    }

}
