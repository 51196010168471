/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassificazioneAteco
 */
export interface ClassificazioneAteco {
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    attivita?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    importanza?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    dtInizio?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    dtRiferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    fonte?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    cimportanza?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    cattivita?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    cnace?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassificazioneAteco
     */
    cfonte?: string;
}

export function ClassificazioneAtecoFromJSON(json: any): ClassificazioneAteco {
    return ClassificazioneAtecoFromJSONTyped(json, false);
}

export function ClassificazioneAtecoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassificazioneAteco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attivita': !exists(json, 'attivita') ? undefined : json['attivita'],
        'importanza': !exists(json, 'importanza') ? undefined : json['importanza'],
        'dtInizio': !exists(json, 'dtInizio') ? undefined : json['dtInizio'],
        'dtRiferimento': !exists(json, 'dtRiferimento') ? undefined : json['dtRiferimento'],
        'fonte': !exists(json, 'fonte') ? undefined : json['fonte'],
        'cimportanza': !exists(json, 'cimportanza') ? undefined : json['cimportanza'],
        'cattivita': !exists(json, 'cattivita') ? undefined : json['cattivita'],
        'cnace': !exists(json, 'cnace') ? undefined : json['cnace'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function ClassificazioneAtecoToJSON(value?: ClassificazioneAteco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attivita': value.attivita,
        'importanza': value.importanza,
        'dtInizio': value.dtInizio,
        'dtRiferimento': value.dtRiferimento,
        'fonte': value.fonte,
        'cimportanza': value.cimportanza,
        'cattivita': value.cattivita,
        'cnace': value.cnace,
        'cfonte': value.cfonte,
    };
}


