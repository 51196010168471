/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyAddress,
    CompanyAddressFromJSON,
    CompanyAddressFromJSONTyped,
    CompanyAddressToJSON,
    CompanySaved,
    CompanySavedFromJSON,
    CompanySavedFromJSONTyped,
    CompanySavedToJSON,
    MarketCompetitor,
    MarketCompetitorFromJSON,
    MarketCompetitorFromJSONTyped,
    MarketCompetitorToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDataFullDTO
 */
export interface CompanyDataFullDTO {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataFullDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataFullDTO
     */
    year?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDataFullDTO
     */
    validUntil?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDataFullDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    fiscalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    membershipGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    activity?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDataFullDTO
     */
    establishmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    shareCapital?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    uniquePartner?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDataFullDTO
     */
    lastFinancialYearClosingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataFullDTO
     */
    accountingPeriod?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataFullDTO
     */
    hykeeScore?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataFullDTO
     */
    businessScore?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataFullDTO
     */
    financialScore?: number;
    /**
     * 
     * @type {Array<CompanyAddress>}
     * @memberof CompanyDataFullDTO
     */
    companyAddresses?: Array<CompanyAddress>;
    /**
     * 
     * @type {Company}
     * @memberof CompanyDataFullDTO
     */
    company?: Company;
    /**
     * 
     * @type {Set<CompanySaved>}
     * @memberof CompanyDataFullDTO
     */
    companySaveds?: Set<CompanySaved>;
    /**
     * 
     * @type {Set<MarketCompetitor>}
     * @memberof CompanyDataFullDTO
     */
    marketCompetitors?: Set<MarketCompetitor>;
}

export function CompanyDataFullDTOFromJSON(json: any): CompanyDataFullDTO {
    return CompanyDataFullDTOFromJSONTyped(json, false);
}

export function CompanyDataFullDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDataFullDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'fiscalCode': !exists(json, 'fiscalCode') ? undefined : json['fiscalCode'],
        'membershipGroup': !exists(json, 'membershipGroup') ? undefined : json['membershipGroup'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'establishmentDate': !exists(json, 'establishmentDate') ? undefined : (new Date(json['establishmentDate'])),
        'shareCapital': !exists(json, 'shareCapital') ? undefined : json['shareCapital'],
        'uniquePartner': !exists(json, 'uniquePartner') ? undefined : json['uniquePartner'],
        'lastFinancialYearClosingDate': !exists(json, 'lastFinancialYearClosingDate') ? undefined : (new Date(json['lastFinancialYearClosingDate'])),
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'accountingPeriod': !exists(json, 'accountingPeriod') ? undefined : json['accountingPeriod'],
        'hykeeScore': !exists(json, 'hykeeScore') ? undefined : json['hykeeScore'],
        'businessScore': !exists(json, 'businessScore') ? undefined : json['businessScore'],
        'financialScore': !exists(json, 'financialScore') ? undefined : json['financialScore'],
        'companyAddresses': !exists(json, 'companyAddresses') ? undefined : ((json['companyAddresses'] as Array<any>).map(CompanyAddressFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'companySaveds': !exists(json, 'companySaveds') ? undefined : ((json['companySaveds'] as Array<any>).map(CompanySavedFromJSON)),
        'marketCompetitors': !exists(json, 'marketCompetitors') ? undefined : ((json['marketCompetitors'] as Array<any>).map(MarketCompetitorFromJSON)),
    };
}

export function CompanyDataFullDTOToJSON(value?: CompanyDataFullDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'fiscalCode': value.fiscalCode,
        'membershipGroup': value.membershipGroup,
        'activity': value.activity,
        'establishmentDate': value.establishmentDate === undefined ? undefined : (value.establishmentDate.toISOString()),
        'shareCapital': value.shareCapital,
        'uniquePartner': value.uniquePartner,
        'lastFinancialYearClosingDate': value.lastFinancialYearClosingDate === undefined ? undefined : (value.lastFinancialYearClosingDate.toISOString()),
        'nationality': value.nationality,
        'accountingPeriod': value.accountingPeriod,
        'hykeeScore': value.hykeeScore,
        'businessScore': value.businessScore,
        'financialScore': value.financialScore,
        'companyAddresses': value.companyAddresses === undefined ? undefined : ((value.companyAddresses as Array<any>).map(CompanyAddressToJSON)),
        'company': CompanyToJSON(value.company),
        'companySaveds': value.companySaveds === undefined ? undefined : ((value.companySaveds as Array<any>).map(CompanySavedToJSON)),
        'marketCompetitors': value.marketCompetitors === undefined ? undefined : ((value.marketCompetitors as Array<any>).map(MarketCompetitorToJSON)),
    };
}


