/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyCriteria,
    CompanyCriteriaFromJSON,
    CompanyCriteriaFromJSONTyped,
    CompanyCriteriaToJSON,
    Insight,
    InsightFromJSON,
    InsightFromJSONTyped,
    InsightToJSON,
} from './';

/**
 * 
 * @export
 * @interface InsightDTO
 */
export interface InsightDTO {
    /**
     * 
     * @type {Insight}
     * @memberof InsightDTO
     */
    insight?: Insight;
    /**
     * 
     * @type {CompanyCriteria}
     * @memberof InsightDTO
     */
    companyCriteria?: CompanyCriteria;
}

export function InsightDTOFromJSON(json: any): InsightDTO {
    return InsightDTOFromJSONTyped(json, false);
}

export function InsightDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insight': !exists(json, 'insight') ? undefined : InsightFromJSON(json['insight']),
        'companyCriteria': !exists(json, 'companyCriteria') ? undefined : CompanyCriteriaFromJSON(json['companyCriteria']),
    };
}

export function InsightDTOToJSON(value?: InsightDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insight': InsightToJSON(value.insight),
        'companyCriteria': CompanyCriteriaToJSON(value.companyCriteria),
    };
}


