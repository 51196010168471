/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DipendentiContratto,
    DipendentiContrattoFromJSON,
    DipendentiContrattoFromJSONTyped,
    DipendentiContrattoToJSON,
} from './';

/**
 * 
 * @export
 * @interface DipendentiContratti
 */
export interface DipendentiContratti {
    /**
     * 
     * @type {Array<DipendentiContratto>}
     * @memberof DipendentiContratti
     */
    dipendentiContratto: Array<DipendentiContratto>;
}

export function DipendentiContrattiFromJSON(json: any): DipendentiContratti {
    return DipendentiContrattiFromJSONTyped(json, false);
}

export function DipendentiContrattiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DipendentiContratti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dipendentiContratto': ((json['dipendentiContratto'] as Array<any>).map(DipendentiContrattoFromJSON)),
    };
}

export function DipendentiContrattiToJSON(value?: DipendentiContratti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dipendentiContratto': ((value.dipendentiContratto as Array<any>).map(DipendentiContrattoToJSON)),
    };
}


