/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MarketCompetitor,
    MarketCompetitorFromJSON,
    MarketCompetitorToJSON,
    MarketCompetitorDTO,
    MarketCompetitorDTOFromJSON,
    MarketCompetitorDTOToJSON,
    MarketCompetitorResponseDTO,
    MarketCompetitorResponseDTOFromJSON,
    MarketCompetitorResponseDTOToJSON,
} from '../models';

export interface CreateMarketCompetitorForLoggedUserRequest {
    marketCompetitorDTO: MarketCompetitorDTO;
}

export interface DeleteMarketCompetitorForLoggedUserRequest {
    id: number;
}

export interface GetAllMyMarketCompetitorsByCompanySelectedRequest {
    companyId: number;
}

/**
 * 
 */
export class CustomMarketCompetitorResourceApi extends runtime.BaseAPI {

    /**
     */
    async createMarketCompetitorForLoggedUserRaw(requestParameters: CreateMarketCompetitorForLoggedUserRequest): Promise<runtime.ApiResponse<MarketCompetitor>> {
        if (requestParameters.marketCompetitorDTO === null || requestParameters.marketCompetitorDTO === undefined) {
            throw new runtime.RequiredError('marketCompetitorDTO','Required parameter requestParameters.marketCompetitorDTO was null or undefined when calling createMarketCompetitorForLoggedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/market-competitors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarketCompetitorDTOToJSON(requestParameters.marketCompetitorDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketCompetitorFromJSON(jsonValue));
    }

    /**
     */
    async createMarketCompetitorForLoggedUser(requestParameters: CreateMarketCompetitorForLoggedUserRequest): Promise<MarketCompetitor> {
        const response = await this.createMarketCompetitorForLoggedUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteMarketCompetitorForLoggedUserRaw(requestParameters: DeleteMarketCompetitorForLoggedUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMarketCompetitorForLoggedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/market-competitors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMarketCompetitorForLoggedUser(requestParameters: DeleteMarketCompetitorForLoggedUserRequest): Promise<void> {
        await this.deleteMarketCompetitorForLoggedUserRaw(requestParameters);
    }

    /**
     */
    async getAllMyMarketCompetitorsRaw(): Promise<runtime.ApiResponse<Array<MarketCompetitorResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/market-competitors/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketCompetitorResponseDTOFromJSON));
    }

    /**
     */
    async getAllMyMarketCompetitors(): Promise<Array<MarketCompetitorResponseDTO>> {
        const response = await this.getAllMyMarketCompetitorsRaw();
        return await response.value();
    }

    /**
     */
    async getAllMyMarketCompetitorsByCompanySelectedRaw(requestParameters: GetAllMyMarketCompetitorsByCompanySelectedRequest): Promise<runtime.ApiResponse<Array<MarketCompetitorResponseDTO>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getAllMyMarketCompetitorsByCompanySelected.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/market-competitors/my/all/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketCompetitorResponseDTOFromJSON));
    }

    /**
     */
    async getAllMyMarketCompetitorsByCompanySelected(requestParameters: GetAllMyMarketCompetitorsByCompanySelectedRequest): Promise<Array<MarketCompetitorResponseDTO>> {
        const response = await this.getAllMyMarketCompetitorsByCompanySelectedRaw(requestParameters);
        return await response.value();
    }

}
