/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoMesi,
    InfoMesiFromJSON,
    InfoMesiFromJSONTyped,
    InfoMesiToJSON,
    ProLocalizzazioni,
    ProLocalizzazioniFromJSON,
    ProLocalizzazioniFromJSONTyped,
    ProLocalizzazioniToJSON,
    ValoriMedi,
    ValoriMediFromJSON,
    ValoriMediFromJSONTyped,
    ValoriMediToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddettiComune
 */
export interface AddettiComune {
    /**
     * 
     * @type {ProLocalizzazioni}
     * @memberof AddettiComune
     */
    proLocalizzazioni?: ProLocalizzazioni;
    /**
     * 
     * @type {InfoMesi}
     * @memberof AddettiComune
     */
    infoMesi?: InfoMesi;
    /**
     * 
     * @type {ValoriMedi}
     * @memberof AddettiComune
     */
    valoriMedi?: ValoriMedi;
    /**
     * 
     * @type {string}
     * @memberof AddettiComune
     */
    comune?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiComune
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiComune
     */
    provinciaTer?: string;
    /**
     * 
     * @type {string}
     * @memberof AddettiComune
     */
    ccomune?: string;
}

export function AddettiComuneFromJSON(json: any): AddettiComune {
    return AddettiComuneFromJSONTyped(json, false);
}

export function AddettiComuneFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddettiComune {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proLocalizzazioni': !exists(json, 'proLocalizzazioni') ? undefined : ProLocalizzazioniFromJSON(json['proLocalizzazioni']),
        'infoMesi': !exists(json, 'infoMesi') ? undefined : InfoMesiFromJSON(json['infoMesi']),
        'valoriMedi': !exists(json, 'valoriMedi') ? undefined : ValoriMediFromJSON(json['valoriMedi']),
        'comune': !exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'provinciaTer': !exists(json, 'provinciaTer') ? undefined : json['provinciaTer'],
        'ccomune': !exists(json, 'ccomune') ? undefined : json['ccomune'],
    };
}

export function AddettiComuneToJSON(value?: AddettiComune | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proLocalizzazioni': ProLocalizzazioniToJSON(value.proLocalizzazioni),
        'infoMesi': InfoMesiToJSON(value.infoMesi),
        'valoriMedi': ValoriMediToJSON(value.valoriMedi),
        'comune': value.comune,
        'provincia': value.provincia,
        'provinciaTer': value.provinciaTer,
        'ccomune': value.ccomune,
    };
}


