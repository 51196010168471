/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenzaAutorizzazione,
    LicenzaAutorizzazioneFromJSON,
    LicenzaAutorizzazioneFromJSONTyped,
    LicenzaAutorizzazioneToJSON,
    MoliniPanificatori,
    MoliniPanificatoriFromJSON,
    MoliniPanificatoriFromJSONTyped,
    MoliniPanificatoriToJSON,
} from './';

/**
 * 
 * @export
 * @interface Licenza
 */
export interface Licenza {
    /**
     * 
     * @type {string}
     * @memberof Licenza
     */
    infoNonDocumentata?: string;
    /**
     * 
     * @type {MoliniPanificatori}
     * @memberof Licenza
     */
    moliniPanificatori?: MoliniPanificatori;
    /**
     * 
     * @type {LicenzaAutorizzazione}
     * @memberof Licenza
     */
    licenzaAutorizzazione?: LicenzaAutorizzazione;
    /**
     * 
     * @type {string}
     * @memberof Licenza
     */
    plicenza?: string;
    /**
     * 
     * @type {string}
     * @memberof Licenza
     */
    fnonDocumentata?: string;
    /**
     * 
     * @type {string}
     * @memberof Licenza
     */
    cnonDocumentata?: string;
}

export function LicenzaFromJSON(json: any): Licenza {
    return LicenzaFromJSONTyped(json, false);
}

export function LicenzaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Licenza {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoNonDocumentata': !exists(json, 'infoNonDocumentata') ? undefined : json['infoNonDocumentata'],
        'moliniPanificatori': !exists(json, 'moliniPanificatori') ? undefined : MoliniPanificatoriFromJSON(json['moliniPanificatori']),
        'licenzaAutorizzazione': !exists(json, 'licenzaAutorizzazione') ? undefined : LicenzaAutorizzazioneFromJSON(json['licenzaAutorizzazione']),
        'plicenza': !exists(json, 'plicenza') ? undefined : json['plicenza'],
        'fnonDocumentata': !exists(json, 'fnonDocumentata') ? undefined : json['fnonDocumentata'],
        'cnonDocumentata': !exists(json, 'cnonDocumentata') ? undefined : json['cnonDocumentata'],
    };
}

export function LicenzaToJSON(value?: Licenza | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoNonDocumentata': value.infoNonDocumentata,
        'moliniPanificatori': MoliniPanificatoriToJSON(value.moliniPanificatori),
        'licenzaAutorizzazione': LicenzaAutorizzazioneToJSON(value.licenzaAutorizzazione),
        'plicenza': value.plicenza,
        'fnonDocumentata': value.fnonDocumentata,
        'cnonDocumentata': value.cnonDocumentata,
    };
}


