/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatingLegalita
 */
export interface RatingLegalita {
    /**
     * 
     * @type {string}
     * @memberof RatingLegalita
     */
    dtUltimoAggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingLegalita
     */
    punteggio?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingLegalita
     */
    identificativo?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingLegalita
     */
    dtRinnovo?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingLegalita
     */
    cfonte?: string;
}

export function RatingLegalitaFromJSON(json: any): RatingLegalita {
    return RatingLegalitaFromJSONTyped(json, false);
}

export function RatingLegalitaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingLegalita {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtUltimoAggiornamento': !exists(json, 'dtUltimoAggiornamento') ? undefined : json['dtUltimoAggiornamento'],
        'punteggio': !exists(json, 'punteggio') ? undefined : json['punteggio'],
        'identificativo': !exists(json, 'identificativo') ? undefined : json['identificativo'],
        'dtRinnovo': !exists(json, 'dtRinnovo') ? undefined : json['dtRinnovo'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function RatingLegalitaToJSON(value?: RatingLegalita | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtUltimoAggiornamento': value.dtUltimoAggiornamento,
        'punteggio': value.punteggio,
        'identificativo': value.identificativo,
        'dtRinnovo': value.dtRinnovo,
        'cfonte': value.cfonte,
    };
}


