/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyDataBlock,
    CompanyDataBlockFromJSON,
    CompanyDataBlockToJSON,
} from '../models';

export interface CreateCompanyDataBlockRequest {
    companyDataBlock: CompanyDataBlock;
}

export interface DeleteCompanyDataBlockRequest {
    id: number;
}

export interface GetCompanyDataBlockRequest {
    id: number;
}

export interface PartialUpdateCompanyDataBlockRequest {
    id: number;
    companyDataBlock: CompanyDataBlock;
}

export interface UpdateCompanyDataBlockRequest {
    id: number;
    companyDataBlock: CompanyDataBlock;
}

/**
 * 
 */
export class CompanyDataBlockResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyDataBlockRaw(requestParameters: CreateCompanyDataBlockRequest): Promise<runtime.ApiResponse<CompanyDataBlock>> {
        if (requestParameters.companyDataBlock === null || requestParameters.companyDataBlock === undefined) {
            throw new runtime.RequiredError('companyDataBlock','Required parameter requestParameters.companyDataBlock was null or undefined when calling createCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data-blocks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataBlockToJSON(requestParameters.companyDataBlock),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataBlockFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyDataBlock(requestParameters: CreateCompanyDataBlockRequest): Promise<CompanyDataBlock> {
        const response = await this.createCompanyDataBlockRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyDataBlockRaw(requestParameters: DeleteCompanyDataBlockRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data-blocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyDataBlock(requestParameters: DeleteCompanyDataBlockRequest): Promise<void> {
        await this.deleteCompanyDataBlockRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyDataBlocksRaw(): Promise<runtime.ApiResponse<Array<CompanyDataBlock>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data-blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDataBlockFromJSON));
    }

    /**
     */
    async getAllCompanyDataBlocks(): Promise<Array<CompanyDataBlock>> {
        const response = await this.getAllCompanyDataBlocksRaw();
        return await response.value();
    }

    /**
     */
    async getCompanyDataBlockRaw(requestParameters: GetCompanyDataBlockRequest): Promise<runtime.ApiResponse<CompanyDataBlock>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-data-blocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataBlockFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyDataBlock(requestParameters: GetCompanyDataBlockRequest): Promise<CompanyDataBlock> {
        const response = await this.getCompanyDataBlockRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyDataBlockRaw(requestParameters: PartialUpdateCompanyDataBlockRequest): Promise<runtime.ApiResponse<CompanyDataBlock>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyDataBlock.');
        }

        if (requestParameters.companyDataBlock === null || requestParameters.companyDataBlock === undefined) {
            throw new runtime.RequiredError('companyDataBlock','Required parameter requestParameters.companyDataBlock was null or undefined when calling partialUpdateCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data-blocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataBlockToJSON(requestParameters.companyDataBlock),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataBlockFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyDataBlock(requestParameters: PartialUpdateCompanyDataBlockRequest): Promise<CompanyDataBlock> {
        const response = await this.partialUpdateCompanyDataBlockRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyDataBlockRaw(requestParameters: UpdateCompanyDataBlockRequest): Promise<runtime.ApiResponse<CompanyDataBlock>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyDataBlock.');
        }

        if (requestParameters.companyDataBlock === null || requestParameters.companyDataBlock === undefined) {
            throw new runtime.RequiredError('companyDataBlock','Required parameter requestParameters.companyDataBlock was null or undefined when calling updateCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-data-blocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataBlockToJSON(requestParameters.companyDataBlock),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataBlockFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyDataBlock(requestParameters: UpdateCompanyDataBlockRequest): Promise<CompanyDataBlock> {
        const response = await this.updateCompanyDataBlockRaw(requestParameters);
        return await response.value();
    }

}
