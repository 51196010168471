/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DarkTheme, DefaultTheme, NavigationContainer, useFocusEffect } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Platform, StyleSheet, } from 'react-native';
import LinkingConfiguration from './LinkingConfiguration';
import LoginScreen from '../screens/auth/login/LoginScreen';
import RegistrationScreen from '../screens/auth/registration/RegistrationScreen';
import SplashScreen from '../screens/Dashboard/SplashScreen';
import { LinearGradient } from 'expo-linear-gradient';
import DashboardScreen from '../screens/Dashboard/DashboardScreen';
import AddProfile from '../screens/Dashboard/ComponentProfile/UserManagement';
import ResearchScreen from '../screens/Dashboard/ResearchScreen';
import SurveyScreen from '@screens/Dashboard/Component/SelfAssestment/SurveyScreen';
import SelfAssessmentScreen from '@screens/Dashboard/Component/SelfAssestment/SelfAssessmentScreen';
import BusinessRatingScreen from '@screens/Dashboard/Component/SelfAssestment/BusinessRatingScreen';
import BottomTabNavigator from "./BottomTabNavigator";
import AdministratorScreen from '@screens/Dashboard/Component/FinancialStatements/AdministratorScreen';
import PartnerScreen from '@screens/Dashboard/Component/FinancialStatements/PartnerScreen';
import AttendanceScreen from '@screens/Dashboard/Component/FinancialStatements/SharesScreen';
import SharesScreen from '@screens/Dashboard/Component/FinancialStatements/SharesScreen';
import ResetPasswordScreen from '@screens/auth/reset-password/ResetPasswordScreen';
import ActivateAccountScreen from '@screens/auth/activate-account/ActivateAccountScreen';
import { PlatformHelper } from "../helpers/PlatformHelper";
import { environment } from "../environments/environment";
import Colors from "../../constants/Colors";
import { BaseAPIManager } from '@services/model/BaseAPIManager';
import { useEffect } from 'react';
import AppContext from './AppContext';


/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator();



export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {


  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

function RootNavigator() {
  const appContext = React.useContext(AppContext);

  useFocusEffect(()=>{
    if(BaseAPIManager.token){
      appContext.setLoggedIn(true);
    } else {
      appContext.setLoggedIn(false);
    }
  })

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <Stack.Navigator
        initialRouteName="Auth">
        {appContext.loggedIn && <Stack.Screen name="App" component={AppNavigator} options={{ headerShown: false }} />}
        {!appContext.loggedIn && <Stack.Screen name="Auth" component={AuthNavigator} options={{ headerShown: false }} />}
      </Stack.Navigator>
    </LinearGradient>
  );
}

function AppNavigator() {
  function canShowFull() {
    return !PlatformHelper.isWeb() || environment.debugRoom;
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <Stack.Navigator
        initialRouteName="BottomTabNavigator">
        {canShowFull() && <Stack.Screen name="Dashboard" component={DashboardScreen} options={{ headerShown: false }} />}
        {canShowFull() && <Stack.Screen name="ResearchScreen" component={ResearchScreen} options={{ headerShown: false }} />}
        {canShowFull() && <Stack.Screen name="BottomTabNavigator" component={BottomTabNavigator} options={{ headerShown: false }} />}
      </Stack.Navigator>
    </LinearGradient>
  );
}

function AuthNavigator() {
  function canShowFull() {
    return !PlatformHelper.isWeb() || environment.debugRoom;
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <Stack.Navigator
        initialRouteName="Root">

        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={{ headerShown: false }} />
        <Stack.Screen name="ActivateAccount" component={ActivateAccountScreen} options={{ headerShown: false }} />
        <Stack.Screen name="Root" component={SplashScreen} options={{ headerShown: false }} />
        {canShowFull() && <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />}
        {canShowFull() && <Stack.Screen name="Registration" component={RegistrationScreen} options={{ headerShown: false }} />}
      </Stack.Navigator>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  containerSA: {
    display: 'flex',
    flex: 1,
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  tabBar: {
    backgroundColor: '#222222',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25
  },
  global: {
    fontFamily: 'poppins',
  },
});