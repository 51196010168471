import React, {useCallback, useEffect} from "react";
import {Profile, RootStackParamList} from "../../../../types";
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {NavigationHelper} from "@helpers/NavigationHelper";
import Icon from "@screens/Tools/Icon";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import * as StorageHelper from '@helpers/StorageHelper';
import {
  Company,
  PushToken,
  PushTokenOperatingSystemEnum,
  UserConsultantInviteCurrentStateEnum,
  UserConsultantInviteRoleEnum,
  UserConsultantRoleEnum,
  UserExtendedTypeEnum
} from "@services/apis/generated";
import hykeeStyle from "@components/hykeeStyle";
import SharedContext from "../../../navigation/SharedContext";
import {
  customPushTokenResourceApi,
  customUserConsultantInviteResourceApi,
  customUserConsultantResourceApi
} from "@services/apis/ApiConfiguration";
import {AlertHelper} from "@helpers/AlertHelper";
import {User} from "../../../services/apis/generated";
import AppContext from "../../../navigation/AppContext";
import { VersionHelper } from "../../../helpers/VersionHelper";
import { useFocusEffect } from "@react-navigation/native";
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";
import { Logger } from "../../../helpers/Logger";
import {AntDesign, FontAwesome, Ionicons} from '@expo/vector-icons';
import {PlatformHelper} from "@helpers/PlatformHelper";

export default function ProfileMenuScreen(props: { profile: Profile | null, user?: User, company: Company | null, navigation: NativeStackNavigationProp<RootStackParamList, "Profile"> }) {
  return renderProfileMenu(props);
}

function renderProfileMenu(props: { profile: Profile | null, user?: User, company: Company | null, navigation: NativeStackNavigationProp<RootStackParamList, "Profile"> }) {
  const [roleUserConsultant, setRoleUserConsultant] = React.useState<UserConsultantRoleEnum | UserConsultantInviteRoleEnum>();
  const [rolePresent, setRolePresent] = React.useState<boolean>(false);
  const [showVersion, setShowVersion] = React.useState<boolean>(false);
  const sharedContext = React.useContext(SharedContext)
  const appContext = React.useContext(AppContext)

  useEffect(() => {
    if (sharedContext.who == UserExtendedTypeEnum.Consultant) {
      customUserConsultantResourceApi.getConsultantByUserId({ userId: sharedContext.userMe?.id! }).then(userConsultants => {
        if (userConsultants.length > 0) {
          let myLinkedConsultat = userConsultants.find((userConsultant) => userConsultant.consultant?.id == sharedContext.myCompanySelected?.id);
          if (myLinkedConsultat) {
            setRoleUserConsultant(myLinkedConsultat.role);
            Logger.log(ProfileMenuScreen.name, "Set role present true");
            setRolePresent(true);
          }
        } else {
          customUserConsultantInviteResourceApi.getAllUserConsultantInviteByUser({ id: sharedContext.userMe?.id! }).then(userConsultantsInvites => {
            if (userConsultantsInvites.length > 0) {
              let myInvitePending = userConsultantsInvites.find((consultantInvite) => consultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Pending);
              if (myInvitePending) {
                setRoleUserConsultant(myInvitePending.role);
                Logger.log(ProfileMenuScreen.name, "Set role present true");
                setRolePresent(true);
              }
            }
          })
        }
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    } else {
      setRolePresent(true);
    }

    return () => {
      Logger.log(ProfileMenuScreen.name, "Resetting");
      setRolePresent(false);
    }
  }, [sharedContext.myCompanySelected?.id])

  useFocusEffect(
    useCallback(() => {
      let loading = !rolePresent;
      // If roleUserConsultant is null it shows the loader, otherwise it is
      // dismissed
      Logger.log(ProfileMenuScreen.name, "UseFocusEffect: " + loading);
      BaseAPIManager.contextLoadingCallback(loading);
    }, [rolePresent])
  )

  function logoutOperations() {
    StorageHelper.logout();
    appContext.setLoggedIn(false)
  }


  function logoutButton() {
    return <View>
      <TouchableOpacity onLongPress={() => setShowVersion(true)}
        onPress={async () => {
          let token = await StorageHelper.readPushToken();
          if (!token) {
            logoutOperations();
            return;
          }
          let platform: PushTokenOperatingSystemEnum = PlatformHelper.isIos() ? PushTokenOperatingSystemEnum.Ios : PushTokenOperatingSystemEnum.Android;
          let pushToken: PushToken = {
            token: token,
            operatingSystem: platform,
          };
          customPushTokenResourceApi.deleteMyPushToken({pushToken: pushToken}).then(res => {
            logoutOperations()
          }).catch(error => {
            logoutOperations()
          })
        }}>
        <View style={styles.row}>
          <View style={styles.icon}>
            <Icon name={'sign-out'} size={24} color={'#00CC83'} />
          </View>
          <View style={styles.textContainer}>
            <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.logout")}</Text>
          </View>
        </View>
        {showVersion && <Text style={[hykeeStyle.mainFont, styles.text]}>{VersionHelper.getAppVersion()}</Text>}
      </TouchableOpacity>
    </View>;
  }

  switch (sharedContext.who) {
    case UserExtendedTypeEnum.Company:
      return <>
        <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToProfileData(props.navigation, props.profile as Profile, props.user);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'user'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.profile_data")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          {/* <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToCompanyData(props.navigation, props.company as Company);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'briefcase'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.company_data")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View> */}

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToNotificationPage( props.navigation, props.profile as Profile, props.user, props.company as Company);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Ionicons name="notifications-outline" size={24} color={'#00CC83'} style={sharedContext.notificationCheck ? {position: 'absolute'} : {}} />
                  {sharedContext.notificationCheck ? <FontAwesome name="circle" size={12} color="red" style={{marginLeft: 10, marginBottom: 15, zIndex: 99999}}/>
                      : <View style={{display:'none'}}></View> }

                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t('components.notification_page.title')}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          {/*
          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToPlansData(props.navigation, props.profile as Profile);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'toggle-on'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.plans")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          */}

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToInsightHome(props.navigation);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'inbox'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.notices")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>


          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToCredits(props.navigation, props.profile as Profile, true);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name="wallet" size={24} type="FontAwesome5" color={"#00CC83"} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.wallets")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>


          {/*
          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToConsultancyData(props.navigation, props.profile as Profile);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'info'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.consultancies")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          */}

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToPartnerData(props.navigation, props.profile as Profile);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Ionicons name="notifications-outline" size={24} color={'#00CC83'} style={sharedContext.linkRequestCheck ? {position: 'absolute'} : {}} />
                  {sharedContext.linkRequestCheck ? <FontAwesome name="circle" size={12} color="red" style={{marginLeft: 10, marginBottom: 15, zIndex: 99999}}/>
                      : <View style={{display:'none'}}></View> }

                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.partners")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToSurvey(props.navigation, sharedContext.myCompanySelected);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'question-circle-o'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text
                    style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.business_rating")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          {logoutButton()}
        </ScrollView>
      </>
      break;
    case UserExtendedTypeEnum.Consultant:
      if (!roleUserConsultant)
        return <>
          <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
            {logoutButton()}
          </ScrollView>
        </>;
      return <>
        <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToProfileData(props.navigation, props.profile as Profile, props.user);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'user'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.profile_data")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          {/* {roleUserConsultant == UserConsultantRoleEnum.Administrator && <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToCompanyData(props.navigation, props.company as Company);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'briefcase'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.company_data")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>} */}
          {roleUserConsultant == UserConsultantRoleEnum.Administrator && <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToUserManagement(props.navigation,);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'users'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text
                    style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.user_management")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>}

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToCredits(props.navigation, props.profile as Profile, true);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'wallet'} type={'FontAwesome5'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.wallets")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToLinkedCompaniesData(props.navigation);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'handshake-o'} size={24} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text
                    style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.linked_companies")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToPartnerProgram(props.navigation);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <AntDesign name="profile" size={24} color="#00CC83" />
                </View>
                <View style={styles.textContainer}>
                  <Text style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.partner_program")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

          {roleUserConsultant != UserConsultantRoleEnum.Administrator && <View>
            <TouchableOpacity onPress={() => {
              NavigationHelper.navigateToPendingRequest(props.navigation);
            }}>
              <View style={styles.row}>
                <View style={styles.icon}>
                  <Icon name={'pending-actions'} type="MaterialIcons" size={26} color={'#00CC83'} />
                </View>
                <View style={styles.textContainer}>
                  <Text
                    style={[hykeeStyle.mainFont, styles.text]}>{i18n.t("screens.profile_menu.request_pending")}</Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>}

          {logoutButton()}

        </ScrollView>
      </>
      break;
    default:
      return <>
        <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
          {logoutButton()}
        </ScrollView>
      </>;
  }
}

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 15,
    alignItems: 'center',
  },
  col: {
    flex: 0.8
  },
  icon: {
    flex: 0.15,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textContainer: {
    flex: 0.85,
    flexDirection: 'row',
    textAlignVertical: 'center',
    justifyContent: 'flex-start'
  },
  text: {
    color: '#ffffff'
  }
})