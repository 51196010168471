/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Plan,
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPlan
 */
export interface UserPlan {
    /**
     * 
     * @type {number}
     * @memberof UserPlan
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserPlan
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserPlan
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserPlan
     */
    activationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserPlan
     */
    renewalDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserPlan
     */
    autoRenew?: boolean;
    /**
     * 
     * @type {User}
     * @memberof UserPlan
     */
    user?: User;
    /**
     * 
     * @type {Plan}
     * @memberof UserPlan
     */
    plan?: Plan;
}

export function UserPlanFromJSON(json: any): UserPlan {
    return UserPlanFromJSONTyped(json, false);
}

export function UserPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'activationDate': !exists(json, 'activationDate') ? undefined : (new Date(json['activationDate'])),
        'renewalDate': !exists(json, 'renewalDate') ? undefined : (new Date(json['renewalDate'])),
        'autoRenew': !exists(json, 'autoRenew') ? undefined : json['autoRenew'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'plan': !exists(json, 'plan') ? undefined : PlanFromJSON(json['plan']),
    };
}

export function UserPlanToJSON(value?: UserPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'activationDate': value.activationDate === undefined ? undefined : (value.activationDate.toISOString()),
        'renewalDate': value.renewalDate === undefined ? undefined : (value.renewalDate.toISOString()),
        'autoRenew': value.autoRenew,
        'user': UserToJSON(value.user),
        'plan': PlanToJSON(value.plan),
    };
}


