/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserConsultantInviteDTO
 */
export interface UserConsultantInviteDTO {
    /**
     * 
     * @type {number}
     * @memberof UserConsultantInviteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInviteDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInviteDTO
     */
    email?: string;
    /**
     * 
     * @type {Company}
     * @memberof UserConsultantInviteDTO
     */
    consultant?: Company;
    /**
     * 
     * @type {string}
     * @memberof UserConsultantInviteDTO
     */
    role?: UserConsultantInviteDTORoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum UserConsultantInviteDTORoleEnum {
    Administrator = 'ADMINISTRATOR',
    Sales = 'SALES',
    Analyst = 'ANALYST'
}

export function UserConsultantInviteDTOFromJSON(json: any): UserConsultantInviteDTO {
    return UserConsultantInviteDTOFromJSONTyped(json, false);
}

export function UserConsultantInviteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConsultantInviteDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'consultant': !exists(json, 'consultant') ? undefined : CompanyFromJSON(json['consultant']),
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function UserConsultantInviteDTOToJSON(value?: UserConsultantInviteDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'consultant': CompanyToJSON(value.consultant),
        'role': value.role,
    };
}


