import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { useFocusEffect } from '@react-navigation/native';
import { customCompanyLinkRequestResourceApi } from '@services/apis/ApiConfiguration';
import { Company } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { Profile, RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';
import CompaniesConsultantAcceptedList from './CompaniesConsultantAcceptedList';
import CompaniesConsultantPendingList from './CompaniesConsultantPendingList';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import { BaseAPIManager } from "../../../services/model/BaseAPIManager";
import ModalSimpleInfoText from "@screens/Modal/ModalSimpleInfoText";



export default function PartnerData({ navigation, route }: RootStackScreenProps<'PartnerData'>, props: { profile: Profile | null }) {


  const [companyConsultedList, setCompanyConsultedList] = React.useState<Company[]>();

  const sharedContext = React.useContext(SharedContext);
  useFocusEffect(
    useCallback(() => {
      BaseAPIManager.contextLoadingCallback(true);
      customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: sharedContext.myCompanySelected?.id! }).then(res => {
        console.log(res);
        sharedContext.setMyCompaniesConsultantRequest(res);
        BaseAPIManager.contextLoadingCallback(false);
      }).catch(error => AlertHelper.showSimpleErrorAlert());
    }, [])
  );

  const goToCompany = (company: Company) => {
    NavigationHelper.navigateToDashboardCompanySearched(navigation, company);
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />

      <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
        <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={'#00CC83'} />
        </TouchableOpacity>
        <Text style={[hykeeStyle.title]}>{i18n.t("screens.profile_menu.partners")}</Text>
      </View>
      <ScrollView style={styles.containerScroll}>
        <View style={styles.containerSubtitle}>
          <Text style={styles.titleText}>
            {i18n.t('components.link_companies_data.title_pending')}</Text>
        </View>
        <CompaniesConsultantPendingList goToCompany={(company) => goToCompany(company)} />
        <View style={styles.containerSubtitle}>
          <Text style={styles.titleText}>
            {i18n.t('components.link_companies_data.title_accepted')}</Text>
        </View>
        <CompaniesConsultantAcceptedList goToCompany={(company) => goToCompany(company)} />

      </ScrollView>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerSubtitle: {
    marginBottom: 20,
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
    marginTop: 10
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    padding: 15
  },
  col: {
    flex: 0.8
  },
});


