/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionSurveyScoreDTO
 */
export interface SectionSurveyScoreDTO {
    /**
     * 
     * @type {string}
     * @memberof SectionSurveyScoreDTO
     */
    section?: SectionSurveyScoreDTOSectionEnum;
    /**
     * 
     * @type {string}
     * @memberof SectionSurveyScoreDTO
     */
    rowLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionSurveyScoreDTO
     */
    numQuestion?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionSurveyScoreDTO
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionSurveyScoreDTO
     */
    maxScore?: number;
}

/**
* @export
* @enum {string}
*/
export enum SectionSurveyScoreDTOSectionEnum {
    Area = 'AREA',
    Agility = 'AGILITY',
    Growth = 'GROWTH',
    Attitude = 'ATTITUDE',
    Lean = 'LEAN'
}

export function SectionSurveyScoreDTOFromJSON(json: any): SectionSurveyScoreDTO {
    return SectionSurveyScoreDTOFromJSONTyped(json, false);
}

export function SectionSurveyScoreDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionSurveyScoreDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : json['section'],
        'rowLabel': !exists(json, 'rowLabel') ? undefined : json['rowLabel'],
        'numQuestion': !exists(json, 'numQuestion') ? undefined : json['numQuestion'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
    };
}

export function SectionSurveyScoreDTOToJSON(value?: SectionSurveyScoreDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': value.section,
        'rowLabel': value.rowLabel,
        'numQuestion': value.numQuestion,
        'score': value.score,
        'maxScore': value.maxScore,
    };
}


