/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Macchinari,
    MacchinariFromJSON,
    MacchinariFromJSONTyped,
    MacchinariToJSON,
} from './';

/**
 * 
 * @export
 * @interface PotenzaCerealiMacchinari
 */
export interface PotenzaCerealiMacchinari {
    /**
     * 
     * @type {Macchinari}
     * @memberof PotenzaCerealiMacchinari
     */
    macchinari?: Macchinari;
    /**
     * 
     * @type {string}
     * @memberof PotenzaCerealiMacchinari
     */
    quintaliPotenzaNominale?: string;
    /**
     * 
     * @type {string}
     * @memberof PotenzaCerealiMacchinari
     */
    quintaliPotenzaReale?: string;
    /**
     * 
     * @type {string}
     * @memberof PotenzaCerealiMacchinari
     */
    tipoCereale?: string;
    /**
     * 
     * @type {string}
     * @memberof PotenzaCerealiMacchinari
     */
    altroTipoCereale?: string;
}

export function PotenzaCerealiMacchinariFromJSON(json: any): PotenzaCerealiMacchinari {
    return PotenzaCerealiMacchinariFromJSONTyped(json, false);
}

export function PotenzaCerealiMacchinariFromJSONTyped(json: any, ignoreDiscriminator: boolean): PotenzaCerealiMacchinari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'macchinari': !exists(json, 'macchinari') ? undefined : MacchinariFromJSON(json['macchinari']),
        'quintaliPotenzaNominale': !exists(json, 'quintaliPotenzaNominale') ? undefined : json['quintaliPotenzaNominale'],
        'quintaliPotenzaReale': !exists(json, 'quintaliPotenzaReale') ? undefined : json['quintaliPotenzaReale'],
        'tipoCereale': !exists(json, 'tipoCereale') ? undefined : json['tipoCereale'],
        'altroTipoCereale': !exists(json, 'altroTipoCereale') ? undefined : json['altroTipoCereale'],
    };
}

export function PotenzaCerealiMacchinariToJSON(value?: PotenzaCerealiMacchinari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'macchinari': MacchinariToJSON(value.macchinari),
        'quintaliPotenzaNominale': value.quintaliPotenzaNominale,
        'quintaliPotenzaReale': value.quintaliPotenzaReale,
        'tipoCereale': value.tipoCereale,
        'altroTipoCereale': value.altroTipoCereale,
    };
}


