/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FloatFilter,
    FloatFilterFromJSON,
    FloatFilterFromJSONTyped,
    FloatFilterToJSON,
    InstantFilter,
    InstantFilterFromJSON,
    InstantFilterFromJSONTyped,
    InstantFilterToJSON,
    LongFilter,
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
    StringFilter,
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
    SurveyStatusFilter,
    SurveyStatusFilterFromJSON,
    SurveyStatusFilterFromJSONTyped,
    SurveyStatusFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomCompanySurveyCriteria
 */
export interface CustomCompanySurveyCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    creationDate?: InstantFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    updateDate?: InstantFilter;
    /**
     * 
     * @type {InstantFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    validUntil?: InstantFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    response?: StringFilter;
    /**
     * 
     * @type {FloatFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    businessScore?: FloatFilter;
    /**
     * 
     * @type {SurveyStatusFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    completed?: SurveyStatusFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    companySurveyQuestionResId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    companyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomCompanySurveyCriteria
     */
    surveyId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomCompanySurveyCriteria
     */
    distinct?: boolean;
}

export function CustomCompanySurveyCriteriaFromJSON(json: any): CustomCompanySurveyCriteria {
    return CustomCompanySurveyCriteriaFromJSONTyped(json, false);
}

export function CustomCompanySurveyCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomCompanySurveyCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'creationDate': !exists(json, 'creationDate') ? undefined : InstantFilterFromJSON(json['creationDate']),
        'updateDate': !exists(json, 'updateDate') ? undefined : InstantFilterFromJSON(json['updateDate']),
        'validUntil': !exists(json, 'validUntil') ? undefined : InstantFilterFromJSON(json['validUntil']),
        'response': !exists(json, 'response') ? undefined : StringFilterFromJSON(json['response']),
        'businessScore': !exists(json, 'businessScore') ? undefined : FloatFilterFromJSON(json['businessScore']),
        'completed': !exists(json, 'completed') ? undefined : SurveyStatusFilterFromJSON(json['completed']),
        'companySurveyQuestionResId': !exists(json, 'companySurveyQuestionResId') ? undefined : LongFilterFromJSON(json['companySurveyQuestionResId']),
        'companyId': !exists(json, 'companyId') ? undefined : LongFilterFromJSON(json['companyId']),
        'surveyId': !exists(json, 'surveyId') ? undefined : LongFilterFromJSON(json['surveyId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomCompanySurveyCriteriaToJSON(value?: CustomCompanySurveyCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'creationDate': InstantFilterToJSON(value.creationDate),
        'updateDate': InstantFilterToJSON(value.updateDate),
        'validUntil': InstantFilterToJSON(value.validUntil),
        'response': StringFilterToJSON(value.response),
        'businessScore': FloatFilterToJSON(value.businessScore),
        'completed': SurveyStatusFilterToJSON(value.completed),
        'companySurveyQuestionResId': LongFilterToJSON(value.companySurveyQuestionResId),
        'companyId': LongFilterToJSON(value.companyId),
        'surveyId': LongFilterToJSON(value.surveyId),
        'distinct': value.distinct,
    };
}


