export const en = {
    login:{
        username: 'Username',
        password: 'Password',
    },
    label:{
        welcome: 'Welcome Back',
    },
    button:{
        confirm:'Confirm',
        cancel:'Cancel',
        ok:'Ok',
    },
    foo: 'Foo En',
    bar: 'Bar en {{someValue}}',
    main_screen_description: 'Il sistema più evoluto per la gestione remota del check-in del tuo Bed & Breakfast',
    server_errors: {
        USER_ALREADY_REGISTERED_TO_COMPANY: "There is a user already registered to the company with the vat number inserted",
        BLOCK_DATA_NOT_PRESENT: "Requested data is not present",
        BALANCE_DOES_NOT_EXIST: "Balance does not exists.",
        NO_CREDITS_AVAILABLE : "Missing Credits.",
        CANNOT_DOWNLOAD_BLOCK : 'Cannot download this data block.',
        ENTITY_NOT_FOUND: 'Entity not found.',
        CANNOT_ACTIVE_PLAN_AUTO_RENEW_IS_ACTIVATED: 'Cannot active plane, auto renew is activated.',
        NO_SEARCHES_AVAILABLE: 'No searches available.',
        NO_CONSULTATIONHOURS_AVAILABLE: 'No consultation hour is available.',
        ALREADY_ACTIVE_USER: 'Already active user.',
        COMPANY_HAS_NO_SURVEYS: 'Company has no surveys.',
        VAT_NUMBER_NOT_FOUND:'Vat number not found.',
        CANNOT_ADD_SAME_COMPANY: 'Cannot add the same company as competitor',
        NOT_ENOUGH_BALANCES_FOR_COMPANY_SCORE: 'Not enough balances for company score.',
        CANNOT_SEARCHES_YOUR_COMPANY:'Cannot searches your company',
        NOTIFICATION_ALREADY_SENT:'The notification has already been sent.',
        COMPETITOR_ALREADY_ADDED:'This Competitor already added to your competitor list',
        EMAIL_ALREADY_EXISTS: "Attention, the entered email belongs to an existing user associated with a different company",
        failed_authentication:'Failed authentication',
        user_not_activated:'User {{user}} was not activated',
        error_generic: 'Something went wrong.',
        existing_device_imei: 'This device already exists. Check the IMEI code.',
        existing_plan: 'This plan already exists.',
        insufficient_credits: 'There are not enough credits left.',
    },
    components: {
        business_score:{
            title:'Business Score',
            error:'Fill in the Self Assessment to see the Business score and Hykee score',
            error_data_incomplete: "The financial data needed to calculate the Financial Score are missing",
            error_search:'This field is not visible for privacy reasons or This Company don\'t complete the Self Assessment',
            send_request_SA:'This Company has not completed the Self Assessment, it sends a notification to the Company.',
            send_request:'Send link',
            show_state_request:'Link sent, see status',
            send_notify:'Do you want to send an invitation to complete the Self Assessment?',
            send_notify_success:'Notification sent successfully',
            info: "The Business Score expresses the solidity of the business model and the attitude to compete in the company's market with agility and speed. It's the result of proprietary algorithms, which take as reference the following areas investigated by the Self-Assessment:\n- Vision, mission e corporate culture\n- Innovation of products and processes\n- Business model\n- Brand positioning\n- Management control",
            meaning: "What does it mean?"
        },
        hykee_score: {
            info: "The Hykee Score expresses the overall rating of the company by correlating quantitative and qualitative analysis, the former is represented by the Financial Rating, which expresses the economic and financial strength of the business, the latter investigates the solidity of the business model and the ability to compete on the market with agility, speed of innovation and growth mindset.",
            meaning: "What does it mean?",
        },
        hykeeFilter: {
            filter_category: "Choose filter",
            filter_order: "Choose order type"
        },
        hykeeList: {
            nextButton: "Next",
            backButton: "Back"
        },
        financial_score:{
            title:'Financial Score',
            unverified:'  Unverified',
            verified:'  Verified',
            show_data:'Show data',
            enter_data:'Enter data',
            incomplete_insert: 'The data to calculate the score is not complete, do you want to insert the missing data?',
            incomplete: 'The data to calculate the score is not complete.',
            info: "The Financial Score expresses the economic and financial strength of the business and it's the result of proprietary algorithms, that take as reference the following assessments:\n(i) business profitability conditions\n(ii) solidity and patrimonial balance\n(iii) ability to create value by correlating the profitability with the management of invested capital\n(iv) ability to honor its short-term and medium to long-term financial obligations",
            meaning: "What does it mean?"
        },
        link_companies_data:{
            status:'Status link: ',
            button_deleted:'Delete',
            button_detail:'Details',
            button_accepted: 'Accept',
            role: 'Role offered: ',
            title_pending:'Pending request',
            title_accepted:'Accepted request',
            title_rejected:'Rejected request',
            no_pending:'No pending request',
            no_accepted:'No accepted request',
            no_rejected:'No rejected request',
            ACCEPTED: 'Accepted',
            REJECTED: 'Rejected',
            PENDING: 'Pending',
            send_invitation_partner_program: "Invite to Partner Program"
        },
        partner_data:{
            status:'Status link: ',
            request_pending:'has asked to connect to your company',
            button_accepted:'Accept',
            button_rejected:'Reject',
            button_detail:'Spend 1 research for details',
            
        },
        partner_program:{
            description: 'Program description',
            program_description: 'General description of the Partner plus module offer for companies that activate the Partner module',
            notice_list: 'Notice list',
            empty_list: 'There aren\'t notices saved as draft.',
            add_new: 'Add new',
            draft: "Draft",
            sent: "Sent",
            date_of_publication: "Date of publication",
            filters: {
                date: "Date",
                ascending: "Ascending",
                descending: "Descending",
                city: "City",
                bologna: "Bologna",
                milano: "Milan",
                roma: "Rome",
                torino: "Turin",
                genova: "Genoa"
            },
            success_modal_text: 'Your changes have been successfully saved',
        },
        add_new_partner_program:{
            title: 'Add new',
            first_paragraph: 'Title',
            title_placeholder: 'Text field title',
            description: 'Description/Content',
            description_placeholder: 'Description of the service offered',
            recipients: 'Recipients',
            button_text_save: "Save",
            button_text_save_send: 'Save and send',
            button_text_delete: 'Delete',
            text_modal_saved: 'Saved in drafts successfully',
            text_modal_send: 'Saved and sent'
        },
        headers:{
            explore:'Explore Financial Statements',
            market:'Market & Competitors',
            business_rating:'Business Rating',
            strategic_positioning:'Strategic Positioning',
            digital_positioning: 'Digital Positioning',
            chamber_data:'Chamber Data',
        },
        strategic_positioning:{
            coming_soon:'Coming Soon...',
        },
        market_competitors:{
            info_plan:'Always keep an eye on the performance of your competitors!',
            info_add_competitors:'Add your competitors to keep them monitored!',
            button_plan:'Activate Plus plan',
            button_add:'Add Competitor',
            confirm_add:'Do you want to spend 1 research to add the company to your competitors?',
            error_company:'The company chosen as a competitor is one of your companies and you cannot add it as a competitor',
            company:'Competitor',
            revenue:'Revenue',
            ebitda : 'Ebitda %',
            leva:'Leva',
            financial_score:'Financial Score',
            manage_competitors: "Manage Competitors",
        },
        insight:{
            title:'Insight News',
            see_all_the_news: 'See all the Partner\'s news',
            no_insight: 'No insight'
        },
        market:{
            title:'Market News',
            data:'Oct 21, 2021',
            infoTitle1:'Ferrero buys Rocher for 1.8M',
            info1:'Sit lectus dictumst in vitae enim sed sed quam. Sit lectus dictumst in vitae enim sed sed quam.',
            infoTitle2:'Industrial production down by 5%',
            info2:'Sit lectus dictumst in vitae enim sed sed quam. Sit lectus dictumst in vitae enim sed sed quam.',
        },
        reservation_details: {
            mail_address: 'Mail address',
            name: 'Name',
            mobile: 'Mobile',
            all_accommodations: 'All Accommodations',
            selected_accommodations: '{{accommodations}} Accommodations',
        },
        plan_selection: {
            title: 'Plan',
            select_plan: 'Select plan',
            plan_label: '{{name}} {{credits}} credits for month',
            autorenew: 'Auto Renew',
            service_expiry_date: 'Service Expiry Date',
        },
        wallet: {
            wallet: 'Wallet',
            plan: 'Plan',
            active: 'active'
        },
        credits: {
            credits: 'Credits',
            no_credit_available: 'No credit available',
            download_chamber_data: 'Do you want to download chamber data of your competitors, customers and suppliers?',
            try_the_platform_features: 'Try now the platform features and use 20 credits for your downloads.',
            buttons:{
                active_the_easy_plan: 'Activate the easy plan'
            }
        },
        research: {
            research: 'Researches',
            no_research_available: 'No research available',
            active_the_plus_plan: 'Activate the PLUS plan to take advantage of 10 monthly searches to analyze your competitors, customers and suppliers.',
            try_easy_plan: 'Do you want to explore Hykee? Try the platform now with the EASY plan and explore all its features!',
            buttons:{
                active_the_easy_plan: 'Activate the easy plan',
                active_the_plus_plan: 'Activate the plus plan'
            }
        },
        chamber_data: {
            administrators: 'Administrators',
            shares: 'Shares',
            partners: 'Partners',
            showTab:'Show',
            administrators_credits: '2 Credits',
            shares_credits: '3 Credits',
            partners_credits: '3 Credits',
            confirm_add:'Do you want to spend {{credits}} to download the chamber data?',
        },
        notification_page: {
            title: 'Notification page',
            self_assestment: 'Request to fill in self assestment',
            link_request: 'Connection request',
            insight: 'New insight',
            from: 'from',
            by: 'by'
        }
    },
    modal:{
        success_website:'WebSite Added Succesfully',
        success_registration:'Registration completed succesfully, check your mail to activate your account',
        remove_user:'Do you want to remove the user ',
        remove_user2:' from your Consultants?',
    },
    sidebar: {
        dashboard: 'Dashboard',
        houses: 'Houses',
        reservations: 'Reservations',
        account: 'Account',
        doors: 'Doors',
        support: 'Support',
        credits: 'Credits',
    },
    services: {
        public: 'Public',
        residential: 'Residential',
    },
    guests: {
        client: 'Client',
        collaborator: 'Collaborator',
    },
    rows: {
        house: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        house_selection: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        accommodation: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        accommodations_selection: {
            caption: 'Choose the Accomodations in',
        },
        door: {
            doors: 'Doors',
            credits_plan: 'Plan',
            add_residential_door: 'Add Residential Door',
            add_public_door: 'Add Public Door',
        },
        recap: {
            all_accommodations: 'All Accommodations',
            selected_accommodations: '{{accommodations}} Accommodations',
            from_date: 'From',
            to_date: 'To',
        },
        credits: {
            credits: 'Credits',
        },
    },
    busines_rating:{
        area:{
            area:'Area',
            product:'Product',
            customer:'Customer',
            businessmodel:'Business Model',
            culture:'Culture',
            goalsvision:'Goals & Vision',
            market:'Market',
            financecheck:'Finance Check',
            result:'Result',
        },
        agility:{
            agility:'Agility',
            focus:'Focus',
            flexibility:'Flexibility',
            velocity:'Velocity',
        },
        growth:{
            growth:'Growth',
            asis:'As Is',
            tobe:'To Be',
            howto:'How To',
        },
        attitude:{
            attitude:'Attitude',
            operationalexcellence:'Operational Excellence',
            customerintimacy:'Customer Intimacy',
            productinnovation:'Product Innovation',
        }
    },
    screens:{
        balance_sheet: {
            tooltip: {
                ceHykeeServizi: "The value includes \"cost for use of third party assets\" (B8 Income Statements)",
                ceHykeeMateriePrime: "The value includes \"change in inventories of raw materials, subsidiary, consumption and goods\" (B11 Income Statements)",
                adjustments: "The Adjustments section shows the values of profitability regarding EBITDA (EBITDA Adj.) and EBIT (EBIT Adj.) net of items of income and expense normally not related to ordinary operations",
                spHykeeAltriCrediti: "The value includes:\n- trade receivables from related companies/subsidiaries/parent companies/companies subject to the supervision of parent companies\n- tax credits and social security\n- deferred tax assets\n- accruals and prepaid expenses",
                spHykeeAltriDebiti: "The value includes:\n- trade payables for advance payments\n- trade payables to related companies/subsidiaries/parent companies/companies subject to the supervision of parent companies\n- tax payables\n- payables to pension and social security institutions\n- accruals and deferred income",
                spHykeeDebitiFinanziari: "The values includes short-term and medium to long-term financial debts as follows:\n- debt obligations\n- payables to shareholders for funding\n- payables to banks\n- payables to other lenders\n- debt evidenced by a certificate",
                ratioHykeeROCE: "Indicator that measures the capability of creating economic value. It's obtained from the ratio between EBIT and Net Invested Capital. Companies with a ROCE equal to MAX have a negative Net Invested Capital, which means that the company is a small cap, a very positive trade cycle, low exposure to financial risk or high cash availability.",
                rfHykeeVarCCON: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarAltriCrediti: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarAltriDebiti: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarFondiETFR: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarPatrimonioNetto: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarDebitiFinanziari: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
                rfHykeeVarAttivitaFinCorrenti: "In case the value is missing, you might need to download previous' years balance sheets to get the Delta",
            },
            contoEconomico: "Income Statement",
            statoPatrimoniale: "Balance Sheet",
            rendicontoFinanziario: "Financial Statement",
            ratio: "Ratios",
            adjustments: "Adjustments",
            days: "d",
            no_balance_sheets: "No result. No accounting data is available for the company searched.",
            confirm_download:'Do you want to spend 1 research to download the other balances?',
            no_credits:"Missing Credits",
            no_balance:'Doesn\'t exist other balances',
            loading: "Loading data...",
            financial_statements: "Financial Statement",
            header_values_euros: "Values in €",
            download_more: "Download More Balance Sheets",
            ceHykeeRicavi: "Revenues",
            ceHykeeValoreDellaProduzione: "Value of Production",
            ceHykeeMateriePrime: "Raw Materials",
            ceHykeeServizi: "Services",
            ceHykeePersonale: "Personnel",
            ceHykeeOneriDiversiDiGestione: "Operating Expenses",
            ceHykeeRettificheFinanziarie: "Financial Corrections",
            ceHykeeEBITDA: "EBITDA",
            ceHykeeEBITDAPERCENTAGE: "EBITDA%",
            ceHykeeDA: "D&A",
            ceHykeeEBIT: "EBIT",
            ceHykeeOneriEProventiFinanziari: "Financial Income and Expenses",
            totaleRettificheValoreAttivitaPassivitaFinanziarie: "Financial Adjustments",
            ceHykeeEBT: "EBT",
            ceHykeeImposte: "Taxes",
            ceHykeeRisultatoEsercizio: "Operating Profit",
            // Incongruenza
            ceAdjHykeeEBITDA: "Adj EBITDA",
            ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione: "Adj Other Revenues / Operating Expenses",
            ceAdjHykeeEBITDAAdj: "EBITDA Adj",
            ceAdjHykeeDA: "D&A",
            ceAdjHykeeEBITAdj: "EBIT Adj",
            spHykeeImmobilizzazioniMateriali: "Tangible Fixed Assets",
            spHykeeImmobilizzazioniFinanziarie: "Financial Fixed Assets",
            spHykeeImmobilizzazioniImmateriali: "Intangible Fixed Assets",
            spHykeeAttivoFisso: "Fixed Assets",
            spHykeeCreditiCommerciali: "Trade Receivables",
            spHykeeDebitiCommerciali: "Trade Payables",
            spHykeeRimanenze: "Inventories",
            creditiEsigibiliEntroEsercizioSuccessivo: "Immediately Payable Receivables",
            creditiEsigibiliOltreEsercizioSuccessivo: "Receivables Payable Beyond the Financial Year",
            totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni: "Total Financial Assets not constituting Fixed Assets",
            spHykeeCCON: "CCON",
            spHykeeAltriCrediti: "Other Receivables",
            spHykeeAltriDebiti: "Other Payables",
            spHykeeFondiETFR: "Employment Severance Indemnity and Funds",
            debitiEsigibiliEntroEsercizioSuccessivoNegative: "Immediately Payable Debts",
            debitiEsigibiliOltreEsercizioSuccessivoNegative: "Debts Payable Beyond the Financial Year",
            totalePassivo: "Total Liabilities",
            spHykeeCIN: "CIN",
            spHykeePatrimonioNetto: "Equity",
            spHykeeCapitaleSociale: "of which share capital",
            spHykeeDebitiFinanziari: "Financial Payables",
            spHykeeLiquidita: "Cash",
            totaleAttivo: "Total Assets",
            spHykeeAttivitaFinanziarie: "Financial Assets",
            spHykeePFN: "PFN",
            spHykeeEplusPFN: "E+PFN",
            rfHykeeEBIT: "EBIT",
            rfHykeeAmmortamentiESvalutazioni: "Depreciation and Impairment Losses",
            rfHykeeRettificheAttivitaFinanziarie: "Adjustments to Financial Assets",
            rfHykeeImposte: "Taxes",
            rfHykeeFundsFromOperationFFO: "Funds from Operation (FFO)",
            rfHykeeVarCCON: "CCON Var.",
            rfHykeeVarAltriCrediti: "Other Receivables Var.",
            rfHykeeVarAltriDebiti: "Other Payables Var.",
            rfHykeeVarFondiETFR: "Employment Severance Indemnity and Funds Var.",
            rfHykeeCashFromOperationCFO: "Cash From Operation (CFO)",
            rfHykeeCapex: "Investments / Divestments (Capex)",
            rfHykeeInvestimentiOnDisinvestimentiFinanziari: "Financial Investments / Divestments",
            rfHykeeFCFO: "Free Cash Flow from Operation (FCFO)",
            rfHykeeProventiEOneriFinanziari: "Financial Expenses and Income",
            rfHykeeFreeCashFlowToEquityFCFE: "Free Cash Flow to Equity (FCFE)",
            rfHykeeVarPatrimonioNetto: "Equity Var.",
            rfHykeeFCFEAdjustedDeltaPFN: "FCFE Adjusted (∆ PFN)",
            rfHykeeVarDebitiFinanziari: "Financial Debts Var.",
            rfHykeeVarAttivitaFinCorrenti: "Current Financial Assets Var.",
            rfHykeeFreeCashFlowDeltaDisponibilitaLiquide: "Free Cash Flow (∆ Cash)",
            ratioHykee2YoYRicavi: "2 YoY Revenues %",
            ratioHykee2YoYVP: "2 YoY VP %",
            ratioHykeeEbitdaPERCENTAGE: "EBITDA %",
            ratioHykeeEbitPERCENTAGE: "EBIT %",
            ratioHykeeEbitdaAdjPERCENTAGE: "EBITDA Adj. %",
            ratioHykeeEbitAdjPERCENTAGE: "EBIT Adj. %",
            ratioHykeeUtileOnPerditaPERCENTAGE: "Operating Profit %",
            ratioHykeeCapexOnRicavi: "Capex / Revenues %",
            ratioHykeeROCE: "ROCE %",
            ratioHykeePFNOnEBITDA: "PFN / EBITDA",
            ratioHykeePFNOnEBITDAAdj: "PFN / EBITDA Adj.",
            ratioHykeeGrossDebtOnPN: "Gross Debt / PN",
            ratioHykeeCCONOnRICAVIPERCENTAGE: "CCON / Revenues %",
            ratioHykeeDSO: "DSO\n(days)",
            ratioHykeeDPOWithoutCapex: "DPO w/o Capex\n(days)",
            ratioHykeeDSI: "DSI\n(days)",
            ratioHykeeFCCR: "FCCR",
            ratioHykeeDSCR: "DSCR"
        },
        administrator_screen: {
            title: "Administrators",
            name: "Name",
            surname: "Surname",
            qualification: "Qualification",
            taxCode: "Tax Code",
            quote: "Quotes",
            error_credits:'Missing Credit'
        },
        partner_screen: {
            title: "Partners",
            nameSurnamePartner: "Partner Name and Surname",
            taxCodePartner: "Partner Tax Code",
            quotaPercentage: "Quota Percentage",
            companyName: "Company Name",
            companyTaxCode: "Company Tax Code",
        },
        shares_screen: {
            title: "Shares",
            taxCode: "Tax Code",
            companyType: "Company Type",
            companyName: "Company Name",
            fulfillmentType: "Fulfillment Type",
            quote: "Quote %"
        },
        consultancies_data:{
            title:'Advice',
            hour_consultancies :'{{hour}}h Advice \nincluded in the PLUS plan',
            reserve: 'Reserve',
            ask_advice: 'Reserve advice',
            reserved: 'Request made',
            spam_advisory: 'Would you like to activate a more extensive consulting plan? \nBuy the ADVISORY module and get 20 hours of advice at a discounted price!',
            spam_plus: 'Activate the PLUS plan to enjoy 2h of advice to evaluate and improve your HYKEE Score. Discover all the advantages of the PLUS plan',
            active_advisory:'Activate the ADVISORY plan',
            active_plus:'Activate the PLUS plan',
            time_left_advisory:'{{hour}}/20h time left',
            time_left_plus:'{{hour}}/2h time left',
            advisory_module:'Advice \nADVISORY Module',
            reserve_plus: 'Book now your {{hour}}h session of dedicated consulting.',
            expiration:'Expiration: {{date}} (12 months from purchase).',
            book_now:'Book now your dedicated advice!',
            plan:'Plan {{plan}}',
            active:'Active',
        },
        profile_menu: {
            profile_data: 'Profile Info',
            company_data: 'Company Info',
            user_management:'User Management',
            plans: 'Plans',
            wallets: 'Wallets',
            consultancies: 'Consultancies',
            partners: 'Linked Partners',
            linked_companies: 'Linked companies',
            request_pending:'Pending request',
            business_rating: 'Business Rating (SA survey)',
            partner_program: 'Partner Program',
            logout: 'Logout',
            notices: 'Notices',
            notices_detail: 'Notices',
            interesting: 'Interesting',
            uninteresting: 'Uninteresting'
        },
        self_assestment:{
            title:'Self Assestment'
        },
        survey:{
            title:'Surveys'
        },
        saved_companies:{
            header_name:'Name',
            header_date:'Date',
            no_company_saved: 'No company saved',
            header_financial:'F.Score',
            header_business:'B.Score',
            header_hykee:'H.Score',
            title_previous:'Your Previous Searches',
            title: 'Saved Companies',
            title_notify:'Recent Notifications',
            notification1: 'Luca di Ferrero completed the Self-Assessment. click to go to the Ferrero page.',
            notification2: 'Luca di Ferrero completed the Self-Assessment.',
            no_companies_saved: 'No saved companies',
            p_iva:'V.N.',
            p_iva_partners:'VAT number'
        },
        research:{            
            companies:'companies',
            title:'Research',
            title_custom:'Custom Searches',
            placeholder_nace_code:'Enter Nace Code',
            placeholder_province:'Add Province or Region',
            placeholder_year:'Add Last Year Budget', 
            title_ateco:'Ateco Code:',
            title_province:'Province:',
            title_year:'Year:',
            no_companies_searched: 'No searched companies',
            available_research: 'Research available',
            used_research: 'Used Research',
            research: 'Research', 
            available_part1: 'You still have',
            avaiable_part2: 'Research available',
            used_part1:'You have used up',
            used_part2: 'Research this month',
        },
        add_profiles:{
            cancel:'Cancel',
            add:'Add',
            title:'Manage Access',
            header_name:'Name',
            header_email:'Email',
            header_role:'Role',
            header_action:'Actions',
            no_consultant:'No consultants found'
        },
        highlights:{
            title:'Highlights',
            success:'Above the industry average',
            warning:'Lower than the Industry average',
        },
        outlook:{
            title:'Outlook'
        },
        credits:{
            available_credits: 'Credits available',
            used_credits: 'Used Credits',
            credits: 'Credits', 
            available_part1: 'You still have',
            avaiable_part2: 'credits available',
            used_part1:'You have used up',
            used_part2: 'credits this month',
        },
        search:{
            info:'Companies are not made up of numbers alone, but of people, processes, decisions and strategies. Disciplined AI correlates this data and provides you with information on its possible future evolution. Enter the name of a company below to get started.',
            credits: 'Credits available',
            search:'Research available',
            send_invitation: "Please, write the company's email for sendindg the invitation to join on Hykee"
        },
        splash:{
            continue: 'Continue to Hykee as',
        },
        login: {
            title: 'Access to your profile',
            description: 'Your email is already registered. Enter the password to log in.',
            registration_ask: 'Don\'t have an account yet? ',
            registration: 'Sign Up',
            forgot_password: 'Forgot Password?',
            forgot_password_success: 'Check your emails to reset your password. A reset link has been sent to {{email}}.',
            send_mail: "Send mail",
            check_mail_reset_password: 'Check your email, we have sent a link for resetting your password',
            reset_password: 'Reset Password',
            confirm_password_error: 'Passwords do not conicide',
            password_changed: 'Great!! you are changed the password, come back to the app.'
        },
        signup: {
            title: 'Create new account',
            phone: 'Telephone Number',
            login_ask:'Already have an account?',
            login: 'Log In',
            activation_code: 'Activation code',
            mail_address: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            first_name: 'First name',
            last_name: 'Last name',
            mobile: 'Mobile',
            country: 'Country',
            address: 'Address',
            town_city: 'City',
            zip: 'Zip',
            postal_code:'Postal Code',
            province: 'Province',
            language: 'Language',
            fiscal_code: 'Fiscal code',
            insert_company_data: 'I\'d like to insert or change my company data',
            company_name: 'Company name',
            company_country: 'Company country',
            company_vat: 'VAT Number',
            sector: 'Sector',
            company_address: 'Company address',
            company_town_city: 'Company Town/City',
            company_pec_mail: 'Company Pec Email',
            company_sdi_code: 'Company Sdi Code',
            usage_threshold: 'Usage Threshold',
            agree_privacy_policy: 'By continuing, you agree to the Terms of Service and the Privacy Policy',
            activation_account: 'Your Hykee account has been created'
        },        
        business_rating:{
            strength:'Strengths',
            info_strength:'Approach Customer Oriented',
            weakness:'Weaknesses',
            info_weakness:'- Product\n- Business Modal',
            label_chart:'Poor prosperity to move fast',
            section_area:'See more detail for section Area',
            section_agility:'See more detail for section Agility',
            section_attitude:'See more detail for section Atitude',
            section_growth:'See more detail for section Growth',
            critical: 'Critical',
            weak: 'Weak',
            good: 'Good',
        },
        company_data: {
            address: 'Address',
            city: 'City',
            activity: 'Activity',
            last_balance: 'Last Balance',
            revenues: 'Revenues',
            ateco: 'Ateco'
        },
        profile: {
            logout:'Logout',
            phone:'Telephone Number',
            email:'Email',
            add_credits: 'Add Credits',
            add_profiles: 'Add Profiles',
            edit: 'edit',
            save: 'save',
            title: 'Profile',
            mail_address: 'Mail address',
            devices_code: 'Passepartout code',
            password: 'Password',
            confirm_password: 'Confirm Password',
            firstName: 'First name',
            lastName: 'Last name',
            mobile: 'Mobile',
            country: 'Country',
            address: 'Address',
            town_city: 'Town/City',
            zip: 'Zip',
            language: 'Language',
            fiscal_code: 'Fiscal code',
            insert_company_data: 'I\'d like to insert or change my company data',
            company_name: 'Company name',
            company_country: 'Company country',
            company_vat: 'VAT Number',
            sector: 'Sector',
            company_address: 'Company address',
            company_town_city: 'Company Town/City',
            company_pec_mail: 'Company Pec Email',
            company_sdi_code: 'Company Sdi Code',
            usage_threshold: 'Usage Threshold',
            current_password: 'Current password',
            new_password: 'New password',
            new_password_confirm: 'Confirm password',
            password_error: {
                error: 'Error',
                success: 'Success',
                fill_all_fields: 'Fill all fields',
                validation_error: 'Password is not valid',
                passwords_not_equal: 'Passwords are not equal',
                password_changed: 'Password changed'
            },
        },
        new_house: {
            title: 'Create your house',
            house_name: 'House name*',
            house_address: 'House complete address*',
            search_address: 'Search',
            house_position: 'House position',
            contact: 'Contact {{index}}',
            add_contact: 'Add a phone number to receive this house notification',
            max_contacts_alert: 'You can set max {{contacts_number}} contacts.',
        },
        new_house_done: {
            title: '',
            its_done_1: 'It\s',
            its_done_2: 'Done!',
            you_can_install_keypad: 'Now that you have a house you can install and connect yours Door’s keypad in it.',
            is_one_of_your_houses: 'is now one of your houses',
        },
        email_verification: {
            title: '',
            description: 'To complete your E-mail verification, please enter the 4 digit activation code we send you',
            mail_address: 'Mail address',
            code: 'Enter the code',
            resend: 'Resend',
        },
        phone_verification: {
            title: '',
            description: 'To complete this phone number verification, please enter the 4 digit activation code we send you by SMS',
            phone: 'Mobile number',
            code: 'Enter the code',
            resend: 'Resend',
        },
        house_detail: {
            house_name: 'House name*',
            house_address: 'House complete address*',
            search_address: 'Search',
            house_position: 'House position',
            add_accommodation: 'Add an accommodation',
            accommodations_list: 'Accommodation list',
            doors_list: 'Doors list',
            add_contact: 'Add a phone number to receive this house notification',
            contact: 'Contact {{index}}',
            max_contacts_alert: 'You can set max {{contacts_number}} contacts.',
        },
        door_detail: {
            title: 'Door',
            door_name: 'Door name*',
            type: 'Type',
            door_serial: 'Serial',
            door_description: 'Door description',
            advanced_settings: 'Advanced settings',
            device_serial: 'Serial number',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Firmware version',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Enable aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            credits_plan: 'Plan',
            scan_imei: 'Scan Imei',
            umount_device: 'Remove device',
        },
        new_door: {
            title: 'Create your Door',
            door_name: 'Door name*',
            type: 'Type',
            door_serial: 'Serial*',
            door_description: 'Door description',
            advanced_settings: 'Advanced settings',
            device_serial: 'Serial number',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Firmware version',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Enable aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            scan_imei: 'Scan Imei',
        },
        door_actions: {
            edit: 'Edit',
            manage_plan: 'Manage plan',
            missing_device: 'Missing device for this door.',
            open: 'Open',
            unlock_success_alert: 'Door unlocked successfully',
        },
        door_plan: {
            save: 'Save',
            stop_plan: 'Suspend plan',
            bound_credits: 'Bound credits',
            free_credits: 'Free credits',
        },
        new_accommodation: {
            title: 'Create your Accommodation',
            accommodation_name: 'Accommodation name*',
            type: 'Type',
            add_button: 'Add new Door',
        },
        accommodation_detail: {
            title: 'Create your Accommodation',
            accommodation_name: 'Accommodation name*',
            type: 'Type',
        },
        door_filters: {
            title: 'Filter your doors',
        },
        reservation_filters: {
            title: 'Filter your reservations',
        },
        dashboard:{
            title: 'Dashboard',
            buttons:{
                company: 'Corporate',
                consultants: 'Intermediaries / Consultants',
                update_accounting_data: "Update accounting data"
            },
            blocks: {
                reservations: {
                    add_button: 'Add a new Reservation',
                    reservations: 'Reservations',
                    checked_in: 'Checked in',
                    confirmed: 'Confirmed',
                    pending: 'Pending',
                },
                houses: {
                    add_button: 'Add a new House',
                    doors: 'Doors',
                    active_doors: 'Active',
                    inactive_doors: 'Inactive',
                },
                credits: {
                    add_button: 'Add Credits',
                    remaining_credits: 'Remaining Credits',
                    active_devices: 'Active Devices',
                    current_monthly_consumption: 'Current monthly consumption',
                    remaining_months: 'Remaining months',
                },
                accommodations: {
                    add_button: 'Add a new Accommodation',
                    doors: 'Doors',
                    active_doors: 'Active Doors',
                    inactive_doors: 'Inactive Doors',
                },
            },
            tabs: {
                reservations: 'Reservations',
                houses: 'Houses',
                credits: 'Credits',
                events: 'Notifications',
            },
        },
        your_houses: {
            title: 'Your Houses',
        },
        events: {
            title: 'Notifications',
        },
        doors: {
            title: 'Doors',
            add_button: 'Add new Door',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters',
        },
        reservations: {
            title: 'Your Reservations',
            add_button: 'Add new Reservation',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters',
        },
        accommodations: {
            title: 'Accommodations',
            subtitle: 'Accommodations'
        },
        intro_reservation: {
            title: 'Add a Reservation',
            subtitle: 'Start by looking for the email of the user to whom you want to assign a reservation',
            search: 'Search',
        },
        intro_reservation_new_user: {
            title: 'Create a Reservation',
            subtitle_1: 'The email you are trying to assign a reservation to is not associated with any B & Free user. The email you are trying to assign a reservation to is not associated with any B & Free user. The email you are trying to assign a reservation to is not associated with any B & Free user.',
            subtitle_2: 'By continuing, you can register the reservation and send the user a personal code valid only for the period of the reservation itself',
        },
        new_reservation_guest: {
            title: 'Create a Reservation',
            first_name: 'First name*',
            type: 'Type',
            last_name: 'Last name*',
            mail: 'Mail address*',
            mobile: 'Mobile*',
        },
        new_reservation_period: {
            title: 'Create a Reservation',
            section_title: 'Choose the Period of the reservation',
            from_date: 'From',
            to_date: 'To',
        },
        new_reservation_houses: {
            title: 'Create a Reservation',
            subtitle: 'Choose the House',
        },
        new_reservation_accommodations: {
            title: 'Create a Reservation',
        },
        new_reservation_done: {
            title: '',
            its_done_1: 'It\s',
            its_done_2: 'Done!',
            recap: 'Recap of the Reservation',
        },
        reservation_detail: {
            title: 'Reservation',
            wait_button: 'Wait for the guest confirmation',
            confirm_button: 'Confirm this Reservation',
            checkin_button: 'Check-in this Reservation',
            reservation_state: 'State:',
            alert_resend_message: 'Do you want to resend the verification code?',
            resend_codes_button: 'Resend access codes',
        },
        support_report: {
            title: 'Support',
            header: 'How can we help you?',
            subheader: 'An operator will answer to your account mail as soon as possible',
            select_problem: 'Select the problem',
            describe: 'And describe the problem',
            ok_title: 'Success',
            ok_message: 'Report sent successfully',
            app_version: 'App version: {{app_version}}'
        },
        get_credits: {
            title: 'Get Credits',
            stripe_ko_message: 'Cannot complete payment.',
            stripe_ok_message: 'Payment completed successfully. Credits will be added soon to your wallet.',
            go_to_buy: 'Open buy page',
        },
        CompanyMicro: {
            loading: "Loading data...",
            title: 'Financial Score',
            firstText: 'By clicking on OK you will be brought back to the mask in which to insert the missing data of the last two years',
            euroText: 'Enter the values in euro units',
            commercialCreditText: 'Commercial credits',
            commercialDebitText: 'Commercial debts',
            financialDebitText: 'Financial debts',
            save_and_confirm: 'Save all and confirm data sending',
            placeholderText: 'Enter the value here',
            success: 'Saved successfully'
        }
    },
    survey_screen: {
        dashboard: {
            title: 'Survey',
            update:'Updated: ',
        }
    },
    error: 'Error',
    errors: {
       something_went_wrong: 'Something went wrong.',
        invalid_email: 'Invalid email',
        empty_password: 'Empty password',
        invalid_password: 'Empty must be at least 8 characters long.',
        invalid_activation_code: 'Invalid activation code.',
        invalid_fiscal_code: 'Invalid fiscal code',
        wrong_credentials_error: 'Wrong credentials.',
        house_data_incomplete: 'Incomplete data',
        house_data_unchanged: 'Data unchanged',
        door_data_incomplete: 'Incomplete data',
        door_data_unchanged: 'Data unchanged',
        accommodation_data_incomplete: 'Incomplete data',
        accommodation_data_unchanged: 'Data unchanged',
        at_least_one_residential: 'At least one residential door must be selected.',
        you_must_fill_field: '"{{field}}" is required',
        reservation_data_incomplete: 'Incomplete data',
        reservation_accommodations_data_incomplete: 'Choose at least one accommodation for every house.',
        reservation_houses_has_no_accommodations: 'Selected house has not any accommodation.',
        support_report_data_incomplete: 'Incomplete data',
        company_not_found: 'Company not found',
        validation: {
            registration: {
                INVALID_SIZE: "Length not valid",
                NOT_BLANK: "Field is required",
                INVALID_EMAIL: "Field must be a valid email"
            }
        }
    },
    alerts: {
        attention: 'Attention',
        are_you_sure: 'Are you sure?',
        success: 'Success',
        yes: 'Yes',
        no: 'No',
        replace: 'Replace',
        delete: 'Delete',
        cancel: 'Cancel',
        want_replace_image: 'Do you want to replace image?',
        want_delete_image: 'Do you want to delete image?',
        which_action_image: 'Which action do you want to perform?',
        ok: 'Ok',
        financial_score_title: 'To obtain the Score, you will need the Commercial Credits, Commercial Debts and Financial Debts of the last two years',
        financial_score_message: 'The data entered will be visible in the app, as well as the Financial Score obtained from these updated calculations'
    },
    inputs: {
        mail_address: 'Mail address',
        password: 'Password',
    },
    buttons: {
        add: 'Add',
        add_plus : '+',
        see_more:'See More',
        see_less:'See Less',
        search:'Search',
        placeholder_web_site:'Enter Website',
        placeholder_search:'Enter VAT Number',
        placeholder_send_invitation: "Enter email",
        continue: 'Continue',
        close:'Close',
        confirm: 'Confirm',
        login: 'Login',
        signup: 'Save',
        new_house: 'Save',
        house_detail: 'Save',
        new_door: 'Save',
        new_accommodation: 'Save',
        profile: 'Save',
        door_filters: 'Apply filters',
        reservation_filters: 'Apply filters',
        connect_first_keypad: 'Connect your door\'s keypad',
        email_verification: 'Continue',
        phone_verification: 'Continue',
        intro_reservation: 'Search',
        intro_reservation_new_user: 'Continue',
        new_reservation_guest: 'Continue',
        new_reservation_period: 'Continue',
        new_reservation_houses_first: 'Select Accommodation',
        new_reservation_houses_second: 'Select All Accommodations',
        new_reservation_accommodations: 'Continue',
        new_reservation_done: 'Save',
        support_report: 'Send',
    },
    common: {
        online: 'Online',
        offline: 'Offline',
        active: 'Active',
        expiring: 'Expiring',
        expired: 'Expired',
        no_data: 'No data',
        type: 'Type',
        service_status: 'Service status',
        status: 'Status',
        connection_status: 'Connection Status',
        houses: 'Houses',
        user_data: 'User data',
        accommodation: 'Accommodation',
        period: 'Period',
        PENDING: 'Pending',
        CHECKEDIN: 'Check In',
        CHECKEDOUT: 'Check Out',
        CANCELED: 'Canceled',
        CONFIRMED: 'Confirmed',
        search_by_email: 'Search by email',
        sort_by: 'Sort by',
        from_date: 'From',
        to_date: 'To',
        SUPPORT_TYPE_OTHER: 'Other',
    },
};