/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyLinkRequestState,
    CompanyLinkRequestStateFromJSON,
    CompanyLinkRequestStateToJSON,
} from '../models';

export interface CreateCompanyLinkRequestStateRequest {
    companyLinkRequestState: CompanyLinkRequestState;
}

export interface DeleteCompanyLinkRequestStateRequest {
    id: number;
}

export interface GetCompanyLinkRequestStateRequest {
    id: number;
}

export interface PartialUpdateCompanyLinkRequestStateRequest {
    id: number;
    companyLinkRequestState: CompanyLinkRequestState;
}

export interface UpdateCompanyLinkRequestStateRequest {
    id: number;
    companyLinkRequestState: CompanyLinkRequestState;
}

/**
 * 
 */
export class CompanyLinkRequestStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createCompanyLinkRequestStateRaw(requestParameters: CreateCompanyLinkRequestStateRequest): Promise<runtime.ApiResponse<CompanyLinkRequestState>> {
        if (requestParameters.companyLinkRequestState === null || requestParameters.companyLinkRequestState === undefined) {
            throw new runtime.RequiredError('companyLinkRequestState','Required parameter requestParameters.companyLinkRequestState was null or undefined when calling createCompanyLinkRequestState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-request-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestStateToJSON(requestParameters.companyLinkRequestState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestStateFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyLinkRequestState(requestParameters: CreateCompanyLinkRequestStateRequest): Promise<CompanyLinkRequestState> {
        const response = await this.createCompanyLinkRequestStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyLinkRequestStateRaw(requestParameters: DeleteCompanyLinkRequestStateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyLinkRequestState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-request-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyLinkRequestState(requestParameters: DeleteCompanyLinkRequestStateRequest): Promise<void> {
        await this.deleteCompanyLinkRequestStateRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyLinkRequestStatesRaw(): Promise<runtime.ApiResponse<Array<CompanyLinkRequestState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-request-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyLinkRequestStateFromJSON));
    }

    /**
     */
    async getAllCompanyLinkRequestStates(): Promise<Array<CompanyLinkRequestState>> {
        const response = await this.getAllCompanyLinkRequestStatesRaw();
        return await response.value();
    }

    /**
     */
    async getCompanyLinkRequestStateRaw(requestParameters: GetCompanyLinkRequestStateRequest): Promise<runtime.ApiResponse<CompanyLinkRequestState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyLinkRequestState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-link-request-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestStateFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyLinkRequestState(requestParameters: GetCompanyLinkRequestStateRequest): Promise<CompanyLinkRequestState> {
        const response = await this.getCompanyLinkRequestStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyLinkRequestStateRaw(requestParameters: PartialUpdateCompanyLinkRequestStateRequest): Promise<runtime.ApiResponse<CompanyLinkRequestState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyLinkRequestState.');
        }

        if (requestParameters.companyLinkRequestState === null || requestParameters.companyLinkRequestState === undefined) {
            throw new runtime.RequiredError('companyLinkRequestState','Required parameter requestParameters.companyLinkRequestState was null or undefined when calling partialUpdateCompanyLinkRequestState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-request-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestStateToJSON(requestParameters.companyLinkRequestState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyLinkRequestState(requestParameters: PartialUpdateCompanyLinkRequestStateRequest): Promise<CompanyLinkRequestState> {
        const response = await this.partialUpdateCompanyLinkRequestStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyLinkRequestStateRaw(requestParameters: UpdateCompanyLinkRequestStateRequest): Promise<runtime.ApiResponse<CompanyLinkRequestState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyLinkRequestState.');
        }

        if (requestParameters.companyLinkRequestState === null || requestParameters.companyLinkRequestState === undefined) {
            throw new runtime.RequiredError('companyLinkRequestState','Required parameter requestParameters.companyLinkRequestState was null or undefined when calling updateCompanyLinkRequestState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-link-request-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLinkRequestStateToJSON(requestParameters.companyLinkRequestState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLinkRequestStateFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyLinkRequestState(requestParameters: UpdateCompanyLinkRequestStateRequest): Promise<CompanyLinkRequestState> {
        const response = await this.updateCompanyLinkRequestStateRaw(requestParameters);
        return await response.value();
    }

}
