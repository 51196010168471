/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Stoccaggio
 */
export interface Stoccaggio {
    /**
     * 
     * @type {string}
     * @memberof Stoccaggio
     */
    magazzini?: string;
    /**
     * 
     * @type {string}
     * @memberof Stoccaggio
     */
    silos?: string;
}

export function StoccaggioFromJSON(json: any): Stoccaggio {
    return StoccaggioFromJSONTyped(json, false);
}

export function StoccaggioFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stoccaggio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'magazzini': !exists(json, 'magazzini') ? undefined : json['magazzini'],
        'silos': !exists(json, 'silos') ? undefined : json['silos'],
    };
}

export function StoccaggioToJSON(value?: Stoccaggio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'magazzini': value.magazzini,
        'silos': value.silos,
    };
}


