/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TitoloOnorifico,
    TitoloOnorificoFromJSON,
    TitoloOnorificoFromJSONTyped,
    TitoloOnorificoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TitoliOnorifici
 */
export interface TitoliOnorifici {
    /**
     * 
     * @type {Array<TitoloOnorifico>}
     * @memberof TitoliOnorifici
     */
    titoloOnorifico?: Array<TitoloOnorifico>;
}

export function TitoliOnorificiFromJSON(json: any): TitoliOnorifici {
    return TitoliOnorificiFromJSONTyped(json, false);
}

export function TitoliOnorificiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitoliOnorifici {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titoloOnorifico': !exists(json, 'titoloOnorifico') ? undefined : ((json['titoloOnorifico'] as Array<any>).map(TitoloOnorificoFromJSON)),
    };
}

export function TitoliOnorificiToJSON(value?: TitoliOnorifici | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'titoloOnorifico': value.titoloOnorifico === undefined ? undefined : ((value.titoloOnorifico as Array<any>).map(TitoloOnorificoToJSON)),
    };
}


