import React, { useRef } from "react";
import { StyleSheet, ScrollView, TouchableOpacity, View, Text, StatusBar, LayoutAnimation, Platform, UIManager, Dimensions } from "react-native";
import { ProgressChart } from "react-native-chart-kit";
import Collapsible from "react-native-collapsible";
import { i18n } from "../../../../i18n/i18n";
import Icon from "../../../Tools/Icon";
import Speedometer from 'react-native-speedometer-chart';



export default function AgilityChart(props: { value: Number, text: String }) {

    return (
        <View style={styles.col}>
            {/* <Text style={styles.labelChart}>
                {i18n.t('screens.business_rating.label_chart')}
            </Text> */}
            <View style={styles.col} >
                <Text style={{marginTop:10,fontFamily:'poppins',color:'white'}}>{props.value != undefined ? props.value.toFixed(1)+'%':0}</Text>
                <Speedometer
                    value={props.value != undefined ? props.value : 0}
                    totalValue={100}
                    innerColor={'#292929'}
                    internalColor={'#00CC83'}
                    outerColor={'#323232'}
                    size={150}
                    showText
                    text={props.text}
                    textStyle={{ fontFamily: 'poppins', padding: 5, color: 'white' }}
                    percentSize={0.8}
                    showLabels
                    labelTextStyle={{ fontFamily: 'poppins', padding: 5, color: 'white' }}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    col: {
        flexDirection: "column",
        alignItems: 'center',
    },
    icon: {
        alignSelf: 'flex-start',
        padding: 5,
    },
    info: {
        color: 'white',
        fontSize: 10,
        padding: 5,
        fontFamily: 'poppins'
    },
    row: {
        padding: 15,
        flexDirection: "row",
    },
    labelChart: {
        fontSize: 13,
        color: 'white',
        padding: 15,
        fontFamily: 'poppins',
        textAlign: 'center'
    },
})