/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionToJSON,
} from '../models';

export interface GetAllSurveyQuestionFilterRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    questionEnContains?: string;
    questionEnDoesNotContain?: string;
    questionEnEquals?: string;
    questionEnNotEquals?: string;
    questionEnSpecified?: boolean;
    questionEnIn?: Array<string>;
    questionEnNotIn?: Array<string>;
    questionItContains?: string;
    questionItDoesNotContain?: string;
    questionItEquals?: string;
    questionItNotEquals?: string;
    questionItSpecified?: boolean;
    questionItIn?: Array<string>;
    questionItNotIn?: Array<string>;
    titleContains?: string;
    titleDoesNotContain?: string;
    titleEquals?: string;
    titleNotEquals?: string;
    titleSpecified?: boolean;
    titleIn?: Array<string>;
    titleNotIn?: Array<string>;
    questionLeanEquals?: boolean;
    questionLeanNotEquals?: boolean;
    questionLeanSpecified?: boolean;
    questionLeanIn?: Array<boolean>;
    questionLeanNotIn?: Array<boolean>;
    questionAreaEquals?: GetAllSurveyQuestionFilterQuestionAreaEqualsEnum;
    questionAreaNotEquals?: GetAllSurveyQuestionFilterQuestionAreaNotEqualsEnum;
    questionAreaSpecified?: boolean;
    questionAreaIn?: Array<GetAllSurveyQuestionFilterQuestionAreaInEnum>;
    questionAreaNotIn?: Array<GetAllSurveyQuestionFilterQuestionAreaNotInEnum>;
    questionAgilityEquals?: GetAllSurveyQuestionFilterQuestionAgilityEqualsEnum;
    questionAgilityNotEquals?: GetAllSurveyQuestionFilterQuestionAgilityNotEqualsEnum;
    questionAgilitySpecified?: boolean;
    questionAgilityIn?: Array<GetAllSurveyQuestionFilterQuestionAgilityInEnum>;
    questionAgilityNotIn?: Array<GetAllSurveyQuestionFilterQuestionAgilityNotInEnum>;
    questionGrowthEquals?: GetAllSurveyQuestionFilterQuestionGrowthEqualsEnum;
    questionGrowthNotEquals?: GetAllSurveyQuestionFilterQuestionGrowthNotEqualsEnum;
    questionGrowthSpecified?: boolean;
    questionGrowthIn?: Array<GetAllSurveyQuestionFilterQuestionGrowthInEnum>;
    questionGrowthNotIn?: Array<GetAllSurveyQuestionFilterQuestionGrowthNotInEnum>;
    questionAttitudeEquals?: GetAllSurveyQuestionFilterQuestionAttitudeEqualsEnum;
    questionAttitudeNotEquals?: GetAllSurveyQuestionFilterQuestionAttitudeNotEqualsEnum;
    questionAttitudeSpecified?: boolean;
    questionAttitudeIn?: Array<GetAllSurveyQuestionFilterQuestionAttitudeInEnum>;
    questionAttitudeNotIn?: Array<GetAllSurveyQuestionFilterQuestionAttitudeNotInEnum>;
    maxScoreGreaterThan?: number;
    maxScoreLessThan?: number;
    maxScoreGreaterThanOrEqual?: number;
    maxScoreLessThanOrEqual?: number;
    maxScoreEquals?: number;
    maxScoreNotEquals?: number;
    maxScoreSpecified?: boolean;
    maxScoreIn?: Array<number>;
    maxScoreNotIn?: Array<number>;
    surveyQuestionResponseIdGreaterThan?: number;
    surveyQuestionResponseIdLessThan?: number;
    surveyQuestionResponseIdGreaterThanOrEqual?: number;
    surveyQuestionResponseIdLessThanOrEqual?: number;
    surveyQuestionResponseIdEquals?: number;
    surveyQuestionResponseIdNotEquals?: number;
    surveyQuestionResponseIdSpecified?: boolean;
    surveyQuestionResponseIdIn?: Array<number>;
    surveyQuestionResponseIdNotIn?: Array<number>;
    surveyIdGreaterThan?: number;
    surveyIdLessThan?: number;
    surveyIdGreaterThanOrEqual?: number;
    surveyIdLessThanOrEqual?: number;
    surveyIdEquals?: number;
    surveyIdNotEquals?: number;
    surveyIdSpecified?: boolean;
    surveyIdIn?: Array<number>;
    surveyIdNotIn?: Array<number>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    distinct?: boolean;
}

/**
 * 
 */
export class CustomSurveyQuestionResourceApi extends runtime.BaseAPI {

    /**
     */
    async getAllSurveyQuestionFilterRaw(requestParameters: GetAllSurveyQuestionFilterRequest): Promise<runtime.ApiResponse<Array<SurveyQuestion>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.questionEnContains !== undefined) {
            queryParameters['questionEn.contains'] = requestParameters.questionEnContains;
        }

        if (requestParameters.questionEnDoesNotContain !== undefined) {
            queryParameters['questionEn.doesNotContain'] = requestParameters.questionEnDoesNotContain;
        }

        if (requestParameters.questionEnEquals !== undefined) {
            queryParameters['questionEn.equals'] = requestParameters.questionEnEquals;
        }

        if (requestParameters.questionEnNotEquals !== undefined) {
            queryParameters['questionEn.notEquals'] = requestParameters.questionEnNotEquals;
        }

        if (requestParameters.questionEnSpecified !== undefined) {
            queryParameters['questionEn.specified'] = requestParameters.questionEnSpecified;
        }

        if (requestParameters.questionEnIn) {
            queryParameters['questionEn.in'] = requestParameters.questionEnIn;
        }

        if (requestParameters.questionEnNotIn) {
            queryParameters['questionEn.notIn'] = requestParameters.questionEnNotIn;
        }

        if (requestParameters.questionItContains !== undefined) {
            queryParameters['questionIt.contains'] = requestParameters.questionItContains;
        }

        if (requestParameters.questionItDoesNotContain !== undefined) {
            queryParameters['questionIt.doesNotContain'] = requestParameters.questionItDoesNotContain;
        }

        if (requestParameters.questionItEquals !== undefined) {
            queryParameters['questionIt.equals'] = requestParameters.questionItEquals;
        }

        if (requestParameters.questionItNotEquals !== undefined) {
            queryParameters['questionIt.notEquals'] = requestParameters.questionItNotEquals;
        }

        if (requestParameters.questionItSpecified !== undefined) {
            queryParameters['questionIt.specified'] = requestParameters.questionItSpecified;
        }

        if (requestParameters.questionItIn) {
            queryParameters['questionIt.in'] = requestParameters.questionItIn;
        }

        if (requestParameters.questionItNotIn) {
            queryParameters['questionIt.notIn'] = requestParameters.questionItNotIn;
        }

        if (requestParameters.titleContains !== undefined) {
            queryParameters['title.contains'] = requestParameters.titleContains;
        }

        if (requestParameters.titleDoesNotContain !== undefined) {
            queryParameters['title.doesNotContain'] = requestParameters.titleDoesNotContain;
        }

        if (requestParameters.titleEquals !== undefined) {
            queryParameters['title.equals'] = requestParameters.titleEquals;
        }

        if (requestParameters.titleNotEquals !== undefined) {
            queryParameters['title.notEquals'] = requestParameters.titleNotEquals;
        }

        if (requestParameters.titleSpecified !== undefined) {
            queryParameters['title.specified'] = requestParameters.titleSpecified;
        }

        if (requestParameters.titleIn) {
            queryParameters['title.in'] = requestParameters.titleIn;
        }

        if (requestParameters.titleNotIn) {
            queryParameters['title.notIn'] = requestParameters.titleNotIn;
        }

        if (requestParameters.questionLeanEquals !== undefined) {
            queryParameters['questionLean.equals'] = requestParameters.questionLeanEquals;
        }

        if (requestParameters.questionLeanNotEquals !== undefined) {
            queryParameters['questionLean.notEquals'] = requestParameters.questionLeanNotEquals;
        }

        if (requestParameters.questionLeanSpecified !== undefined) {
            queryParameters['questionLean.specified'] = requestParameters.questionLeanSpecified;
        }

        if (requestParameters.questionLeanIn) {
            queryParameters['questionLean.in'] = requestParameters.questionLeanIn;
        }

        if (requestParameters.questionLeanNotIn) {
            queryParameters['questionLean.notIn'] = requestParameters.questionLeanNotIn;
        }

        if (requestParameters.questionAreaEquals !== undefined) {
            queryParameters['questionArea.equals'] = requestParameters.questionAreaEquals;
        }

        if (requestParameters.questionAreaNotEquals !== undefined) {
            queryParameters['questionArea.notEquals'] = requestParameters.questionAreaNotEquals;
        }

        if (requestParameters.questionAreaSpecified !== undefined) {
            queryParameters['questionArea.specified'] = requestParameters.questionAreaSpecified;
        }

        if (requestParameters.questionAreaIn) {
            queryParameters['questionArea.in'] = requestParameters.questionAreaIn;
        }

        if (requestParameters.questionAreaNotIn) {
            queryParameters['questionArea.notIn'] = requestParameters.questionAreaNotIn;
        }

        if (requestParameters.questionAgilityEquals !== undefined) {
            queryParameters['questionAgility.equals'] = requestParameters.questionAgilityEquals;
        }

        if (requestParameters.questionAgilityNotEquals !== undefined) {
            queryParameters['questionAgility.notEquals'] = requestParameters.questionAgilityNotEquals;
        }

        if (requestParameters.questionAgilitySpecified !== undefined) {
            queryParameters['questionAgility.specified'] = requestParameters.questionAgilitySpecified;
        }

        if (requestParameters.questionAgilityIn) {
            queryParameters['questionAgility.in'] = requestParameters.questionAgilityIn;
        }

        if (requestParameters.questionAgilityNotIn) {
            queryParameters['questionAgility.notIn'] = requestParameters.questionAgilityNotIn;
        }

        if (requestParameters.questionGrowthEquals !== undefined) {
            queryParameters['questionGrowth.equals'] = requestParameters.questionGrowthEquals;
        }

        if (requestParameters.questionGrowthNotEquals !== undefined) {
            queryParameters['questionGrowth.notEquals'] = requestParameters.questionGrowthNotEquals;
        }

        if (requestParameters.questionGrowthSpecified !== undefined) {
            queryParameters['questionGrowth.specified'] = requestParameters.questionGrowthSpecified;
        }

        if (requestParameters.questionGrowthIn) {
            queryParameters['questionGrowth.in'] = requestParameters.questionGrowthIn;
        }

        if (requestParameters.questionGrowthNotIn) {
            queryParameters['questionGrowth.notIn'] = requestParameters.questionGrowthNotIn;
        }

        if (requestParameters.questionAttitudeEquals !== undefined) {
            queryParameters['questionAttitude.equals'] = requestParameters.questionAttitudeEquals;
        }

        if (requestParameters.questionAttitudeNotEquals !== undefined) {
            queryParameters['questionAttitude.notEquals'] = requestParameters.questionAttitudeNotEquals;
        }

        if (requestParameters.questionAttitudeSpecified !== undefined) {
            queryParameters['questionAttitude.specified'] = requestParameters.questionAttitudeSpecified;
        }

        if (requestParameters.questionAttitudeIn) {
            queryParameters['questionAttitude.in'] = requestParameters.questionAttitudeIn;
        }

        if (requestParameters.questionAttitudeNotIn) {
            queryParameters['questionAttitude.notIn'] = requestParameters.questionAttitudeNotIn;
        }

        if (requestParameters.maxScoreGreaterThan !== undefined) {
            queryParameters['maxScore.greaterThan'] = requestParameters.maxScoreGreaterThan;
        }

        if (requestParameters.maxScoreLessThan !== undefined) {
            queryParameters['maxScore.lessThan'] = requestParameters.maxScoreLessThan;
        }

        if (requestParameters.maxScoreGreaterThanOrEqual !== undefined) {
            queryParameters['maxScore.greaterThanOrEqual'] = requestParameters.maxScoreGreaterThanOrEqual;
        }

        if (requestParameters.maxScoreLessThanOrEqual !== undefined) {
            queryParameters['maxScore.lessThanOrEqual'] = requestParameters.maxScoreLessThanOrEqual;
        }

        if (requestParameters.maxScoreEquals !== undefined) {
            queryParameters['maxScore.equals'] = requestParameters.maxScoreEquals;
        }

        if (requestParameters.maxScoreNotEquals !== undefined) {
            queryParameters['maxScore.notEquals'] = requestParameters.maxScoreNotEquals;
        }

        if (requestParameters.maxScoreSpecified !== undefined) {
            queryParameters['maxScore.specified'] = requestParameters.maxScoreSpecified;
        }

        if (requestParameters.maxScoreIn) {
            queryParameters['maxScore.in'] = requestParameters.maxScoreIn;
        }

        if (requestParameters.maxScoreNotIn) {
            queryParameters['maxScore.notIn'] = requestParameters.maxScoreNotIn;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThan'] = requestParameters.surveyQuestionResponseIdGreaterThan;
        }

        if (requestParameters.surveyQuestionResponseIdLessThan !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThan'] = requestParameters.surveyQuestionResponseIdLessThan;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThanOrEqual'] = requestParameters.surveyQuestionResponseIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThanOrEqual'] = requestParameters.surveyQuestionResponseIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.equals'] = requestParameters.surveyQuestionResponseIdEquals;
        }

        if (requestParameters.surveyQuestionResponseIdNotEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.notEquals'] = requestParameters.surveyQuestionResponseIdNotEquals;
        }

        if (requestParameters.surveyQuestionResponseIdSpecified !== undefined) {
            queryParameters['surveyQuestionResponseId.specified'] = requestParameters.surveyQuestionResponseIdSpecified;
        }

        if (requestParameters.surveyQuestionResponseIdIn) {
            queryParameters['surveyQuestionResponseId.in'] = requestParameters.surveyQuestionResponseIdIn;
        }

        if (requestParameters.surveyQuestionResponseIdNotIn) {
            queryParameters['surveyQuestionResponseId.notIn'] = requestParameters.surveyQuestionResponseIdNotIn;
        }

        if (requestParameters.surveyIdGreaterThan !== undefined) {
            queryParameters['surveyId.greaterThan'] = requestParameters.surveyIdGreaterThan;
        }

        if (requestParameters.surveyIdLessThan !== undefined) {
            queryParameters['surveyId.lessThan'] = requestParameters.surveyIdLessThan;
        }

        if (requestParameters.surveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyId.greaterThanOrEqual'] = requestParameters.surveyIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyIdLessThanOrEqual !== undefined) {
            queryParameters['surveyId.lessThanOrEqual'] = requestParameters.surveyIdLessThanOrEqual;
        }

        if (requestParameters.surveyIdEquals !== undefined) {
            queryParameters['surveyId.equals'] = requestParameters.surveyIdEquals;
        }

        if (requestParameters.surveyIdNotEquals !== undefined) {
            queryParameters['surveyId.notEquals'] = requestParameters.surveyIdNotEquals;
        }

        if (requestParameters.surveyIdSpecified !== undefined) {
            queryParameters['surveyId.specified'] = requestParameters.surveyIdSpecified;
        }

        if (requestParameters.surveyIdIn) {
            queryParameters['surveyId.in'] = requestParameters.surveyIdIn;
        }

        if (requestParameters.surveyIdNotIn) {
            queryParameters['surveyId.notIn'] = requestParameters.surveyIdNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/survey-questions/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyQuestionFromJSON));
    }

    /**
     */
    async getAllSurveyQuestionFilter(requestParameters: GetAllSurveyQuestionFilterRequest): Promise<Array<SurveyQuestion>> {
        const response = await this.getAllSurveyQuestionFilterRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAreaEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAreaNotEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAreaInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAreaNotInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAgilityEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAgilityNotEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAgilityInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAgilityNotInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionGrowthEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionGrowthNotEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionGrowthInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionGrowthNotInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAttitudeEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAttitudeNotEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAttitudeInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionFilterQuestionAttitudeNotInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
