import hykeeStyle from "@components/hykeeStyle";
import { AlertHelper } from "@helpers/AlertHelper";
import ModalSimpleConfirmCancel from "@screens/Modal/ModalSimpleConfirmCancel";
import ModalSimpleError from "@screens/Modal/ModalSimpleError";
import { customCompanyBalanceSheetResourceApi, customScoreResourceApi } from "@services/apis/ApiConfiguration";
import { BalanceXbrlDTO, Company, CompanyBalanceSheet } from "@services/apis/generated";
import React, { useEffect } from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import Collapsible from "react-native-collapsible";
import { Balance, BalanceObj, DownloadedBalance, DownloadedBalanceWithoutFinancialData, Header, Table } from "../../../../../types";
import { i18n } from "../../../../i18n/i18n";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import BalanceSheetDataTable from "./BalanceSheetDataTable";
import { BaseAPIManager } from "../../../../services/model/BaseAPIManager";

export enum HeaderNames {
    financialStatement = "Financial Statement",
    balanceSheetReclass = "Balance Sheet Reclass"
}


export default function FinancialStatements(props: { company: Company , callback: any }) {

    const sharedContext = React.useContext(SharedContext);

    const [balanceSheetHeaders, setBalanceSheetHeaders] = React.useState<Header[]>([]);
    const [balanceList, setBalanceList] = React.useState<number[]>([]);
    const [modalError, setModalError] = React.useState<{ visible: boolean, msg: string }>({ visible: false, msg: "" });
    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [balancesArePresent, setBalancesArePresent] = React.useState<boolean>(true);
    const [collapsibleAnimationEnd, setcollapsibleAnimationEnd] = React.useState<boolean>(false);
    const [lastBalance, setLastBalance] = React.useState<CompanyBalanceSheet>({});
    const [balanceObj, setBalanceObj] = React.useState<BalanceObj>({});


    // const [seeMore, setSeeMore] = React.useState(true);
    const [collapsed, setCollapsed] = React.useState<boolean>(true);

    useEffect(() => {
        getFinancialStatementsData();
    }, [props.company])

    function determineOldestBalance() {
        let lowestBalanceYear: number = Number.MAX_SAFE_INTEGER
        if (balanceSheetHeaders[0]) {
            let headerValues = balanceSheetHeaders[0].headerValues
            for (let i = 0; i < headerValues.length; i++) {
                let parsedYear = headerValues[i]
                if (parsedYear < lowestBalanceYear) {
                    lowestBalanceYear = parsedYear
                }
            }
        }
        if (lowestBalanceYear == Number.MAX_SAFE_INTEGER) {
            let date = new Date().getFullYear();
            lowestBalanceYear = date
        }
        return lowestBalanceYear - 1;
    }

    function sortHeaders() {
        if (balanceSheetHeaders) {
            balanceSheetHeaders.forEach(element => {
                element.headerValues.sort((a, b) => {
                    return (a > b) ? 1 : -1;
                })
            });
        }
    }

    function parseHeaders(response: CompanyBalanceSheet[]) {
        for (let i = 0; i < response.length; i++) {
            let headerName = "";

            switch (i) {
                case 0:
                    headerName = HeaderNames.financialStatement
                    break;
                case 1:
                    headerName = HeaderNames.balanceSheetReclass
                    break;
            }

            let header: Header = {
                headerName: headerName,
                headerValues: []
            };

            if (response) {
                response.map((balance: CompanyBalanceSheet) => {
                    if (balance.year) {
                        header.headerValues.push(balance.year);
                    }
                });
            }

            balanceSheetHeaders.push(header);
            return balanceSheetHeaders
        }
    }

    const [response, setResponse] = React.useState<CompanyBalanceSheet[]>([])

    function addBalanceToHeaders(response: CompanyBalanceSheet): void {
        for (let i = 0; i < balanceSheetHeaders.length; i++) {
            const header = balanceSheetHeaders[i];
            header.headerValues.push(response.year!)
            header.headerValues.push(response.year! - 1)
        }
        setBalanceSheetHeaders({ ...balanceSheetHeaders })
    }

    const getDownloadedBalances = (balanceList: CompanyBalanceSheet[]) => {
        let downloadedBalances: DownloadedBalanceWithoutFinancialData[] = []
        balanceList.forEach((balance) => {
            let downloaded: DownloadedBalanceWithoutFinancialData = {
                anno: balance.year!,
                type: balance.type!,
                currentMoreFieldsState: balance.currentMoreFieldsState,
                spHykeeCreditiCommerciali: balance.spHykeeCreditiCommerciali,
                spHykeeDebitiCommerciali: balance.spHykeeDebitiCommerciali,
                spHykeeDebitiFinanziari: balance.spHykeeDebitiFinanziari
            }
            downloadedBalances.push(downloaded)
        })
        balanceObj.downloadedBalances = downloadedBalances;
        setBalanceObj({ ...balanceObj })
     }

    function getFinancialStatementsData() {
        if (props.company != undefined && props.company.id != -1) {
            customCompanyBalanceSheetResourceApi.getCompanyBalanceSheets({ companyId: props.company.id! }).then((balance) => {
                // If no balance is present, set the boolean for rendering and setLastBalance as an empty object
                if (balance.length == 0) {
                    setBalancesArePresent(false);
                    sharedContext.setLastBalance({})
                    return
                }

                getDownloadedBalances(balance)

                let balanceSheetHeaders = parseHeaders(balance);

                if (balanceSheetHeaders)
                    setBalanceSheetHeaders(balanceSheetHeaders)
                setResponse(balance);

                let balancesId: number[] = [];
                if (balance) {
                    balance.map((balance) => {
                        balancesId.push(balance.id!);
                    });
                }
                setBalanceList(balancesId);

                //Get last balance
                let lastBalance = balance.reduce((a, b) => a.year! < b.year! ? b : a);
                sharedContext.setLastBalance(lastBalance);
                setLastBalance(lastBalance)
                balanceObj.lastBalance = lastBalance;
                setBalanceObj({ ...balanceObj }),
                    props.callback(balanceObj);

                //get revenue form last balance
                customScoreResourceApi.getFinancialData({ id: lastBalance.id! }).then((res) => {
                    if (res) {
                        let revenue = res['ceHykeeRicavi'];
                        sharedContext.setRevenue(revenue!);
                    }
                })
            }).catch((error) => console.log(error)
            )
        }
    }

    const importBalanceSheetByYear = () => {
        BaseAPIManager.contextLoadingCallback(true);
        customCompanyBalanceSheetResourceApi.importBalanceSheetByYear({ companyBalanceSheet: { year: determineOldestBalance(), company: { id: props.company.id! } } }).then((response) => {
            addBalanceToHeaders(response)
            // Sort to order years in ascending order
            sortHeaders()
            getFinancialStatementsData()
            BaseAPIManager.contextLoadingCallback(false);
        }).catch(async (error) => {
            let errorJson = await error.json()
            setModalError({ visible: true, msg: errorJson.detail })
        })
    }

    const toggleExpanded = () => {
        setCollapsed(!collapsed);
        setcollapsibleAnimationEnd(false);
    };

    function setDownloadedBalances(balances: any) {
        props.callback(balances);
    }

    return (
        <View style={[styles.label, { backgroundColor: (collapsed ? '#222222' : '#292929') }]}>
            <TouchableOpacity onPress={toggleExpanded}>
                <View style={styles.header}>
                    <Text style={styles.textLabel}>
                        {i18n.t('components.headers.explore')}
                    </Text>
                    <Icon name={collapsed ? "angle-down" : "angle-up"} color="white" />
                </View>
            </TouchableOpacity>



            <Collapsible
                duration={1000}
                collapsed={collapsed}
                style={styles.contentAccordion}
                enablePointerEvents
                onAnimationEnd={()=>{setcollapsibleAnimationEnd(true)}}
            >
                {balancesArePresent && balanceList.length<4 && 
                    <View style={{ flex: 1 }}>
                        <TouchableOpacity
                            style={{ backgroundColor: "#00CC83" }}
                            onPress={() => setModalVisible(true)}
                        >
                            <View style={{ alignContent: "center", justifyContent: 'center', flex: 1, flexDirection: "row" }}>
                                <View style={{ flexDirection: "row" }}>
                                    <View>
                                        <Text style={[{ textAlign: 'center', color: "white", margin: 10 }, hykeeStyle.mainFont]}>
                                            {i18n.t("screens.balance_sheet.download_more")}
                                        </Text>
                                    </View>
                                    <View style={{ paddingHorizontal: 10, marginTop: 10 }}>
                                        <Icon name={"download"} color="white"></Icon>
                                    </View>
                                </View>

                            </View>

                        </TouchableOpacity>

                    </View>
                }
                {balanceList && balancesArePresent &&
                    <BalanceSheetDataTable balanceListId={balanceList} animationEnd={collapsibleAnimationEnd}></BalanceSheetDataTable>
                }
                {!balancesArePresent &&
                    <View style={{ alignSelf: "center", margin: 20 }}>
                        <Text style={styles.textNoBalances}>{i18n.t("screens.balance_sheet.no_balance_sheets")}</Text>
                    </View>
                }

            </Collapsible>
            {modalVisible &&
                <ModalSimpleConfirmCancel
                    msg={i18n.t('screens.balance_sheet.confirm_download')}
                    action={importBalanceSheetByYear}
                    visible={(visible) => { setModalVisible(visible) }}
                />
            }
            {modalError.visible && <ModalSimpleError msg={i18n.t('server_errors.' + modalError.msg)} visible={(visible) => setModalError({ visible: visible, msg: '' })} />}
        </View>
    );
}

const styles = StyleSheet.create({

    header: {
        flexDirection: "row",
    },
    tableRow: {
        flexDirection: "column",
        backgroundColor: "#494949"
    },
    contentAccordion: {
        color: "white",
        flexDirection: "column",
        height: 'auto',
    },
    buttonSeeMore: {
        alignSelf: 'center'
    },
    textSeeMore: {
        color: '#00CC83',
        margin: 5,
        fontFamily: 'poppins',
        fontSize: 16,
        textDecorationLine: 'underline',
    },
    textNoBalances: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 14,
    },
    label: {
        backgroundColor: "#222222",
        paddingTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        display: "flex",
    },
    textLabel: {
        flex: 0.95,
        alignSelf: "flex-start",
        color: "white",
        marginLeft: 15,
        marginBottom: 10,
        textAlign: 'left',
        fontWeight: 'bold',
    },
});
