/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CertificatoQualita,
    CertificatoQualitaFromJSON,
    CertificatoQualitaFromJSONTyped,
    CertificatoQualitaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Attestazione
 */
export interface Attestazione {
    /**
     * 
     * @type {CertificatoQualita}
     * @memberof Attestazione
     */
    certificatoQualita?: CertificatoQualita;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    dtRilascio?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    dtScadenza?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    regolamento?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    cidentificativoSOA?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    nattestazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Attestazione
     */
    cfonte?: string;
}

export function AttestazioneFromJSON(json: any): Attestazione {
    return AttestazioneFromJSONTyped(json, false);
}

export function AttestazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attestazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'certificatoQualita': !exists(json, 'certificatoQualita') ? undefined : CertificatoQualitaFromJSON(json['certificatoQualita']),
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtRilascio': !exists(json, 'dtRilascio') ? undefined : json['dtRilascio'],
        'dtScadenza': !exists(json, 'dtScadenza') ? undefined : json['dtScadenza'],
        'regolamento': !exists(json, 'regolamento') ? undefined : json['regolamento'],
        'cidentificativoSOA': !exists(json, 'cidentificativoSOA') ? undefined : json['cidentificativoSOA'],
        'nattestazione': !exists(json, 'nattestazione') ? undefined : json['nattestazione'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function AttestazioneToJSON(value?: Attestazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'certificatoQualita': CertificatoQualitaToJSON(value.certificatoQualita),
        'denominazione': value.denominazione,
        'dtRilascio': value.dtRilascio,
        'dtScadenza': value.dtScadenza,
        'regolamento': value.regolamento,
        'cidentificativoSOA': value.cidentificativoSOA,
        'nattestazione': value.nattestazione,
        'cfonte': value.cfonte,
    };
}


