// 
// 
//
import { BackgroundColorButtonGreen, BackgroundColorInputText, ColorTextInput, ColorTextLightGreen } from '@components/ColorTheme';
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { accountResourceApi, customUserExtendedResourceApi, customUserResourceApi } from '@services/apis/ApiConfiguration';
import { ChangePasswordRequest, PartialUpdateUserExtendedRequest, PartialUpdateUserRequest } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useContext, useEffect } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Pressable, Platform, ScrollView } from 'react-native';
import { Profile, RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import Icon from '../../Tools/Icon';
import { isEqual } from "lodash"
import hykeeStyle from '@components/hykeeStyle';
import Colors from "../../../../constants/Colors";
import { User } from "../../../services/apis/generated";
import SharedContext from '../../../navigation/SharedContext';
import Collapsible from 'react-native-collapsible';
import { useFocusEffect } from '@react-navigation/native';


export default function ProfileData({ navigation, route }: RootStackScreenProps<'ProfileData'>) {

  const sharedContext = useContext(SharedContext)
  const [editable, setEditable] = React.useState<{ [key in Fields]: boolean }>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    password: false,
  })


  const [profileState, setProfileState] = React.useState<Profile>({});
  const [user, setUser] = React.useState<User>();

  const [profileEditState, setProfileEditState] = React.useState<Profile>({});

  let passwordStateInit = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: ""
  }

  const [passwordState, setPasswordState] = React.useState(passwordStateInit);

  /*  There are two states for the profile:
  *   profileState, which holds the effective profile data to send in the api call
  *   profileEditState, which holds the values of the different text boxes
  *   When the data is saved (save()), the profileState gets modified with the data to
  *   send to the API.
  */

  enum Fields {
    firstName = "firstName",
    lastName = "lastName",
    email = "email",
    phone = "phone",
    password = "password"
  }

  enum PasswordFields {
    currentPassword = "currentPassword",
    newPassword = "newPassword",
    newPasswordConfirm = "newPasswordConfirm"
  }

  // Populate the profile states on creation
  useFocusEffect(
    useCallback(() => {
      setUser(sharedContext.userMe);
      setProfileState({
        id: sharedContext.userExtendedMe?.id,
        firstName: sharedContext.userExtendedMe?.firstName,
        lastName: sharedContext.userExtendedMe?.lastName,
        email: sharedContext.userMe?.email,
        phone: sharedContext.userExtendedMe?.telephone
      })
      setProfileEditState({
        id: sharedContext.userExtendedMe?.id,
        firstName: sharedContext.userExtendedMe?.firstName,
        lastName: sharedContext.userExtendedMe?.lastName,
        email: sharedContext.userMe?.email,
        phone: sharedContext.userExtendedMe?.telephone
      })
      return () => { // This is the cleanup function, called when the component is unmounted.
        customUserResourceApi.getMe().then((userMe) => {
          sharedContext.setUserMe(userMe)
          customUserExtendedResourceApi.getUserExtendedMe().then((userExtendedMe) => {
            sharedContext.setUserExtendedMe(userExtendedMe)
          }).catch((e) => { AlertHelper.showSimpleErrorAlert() });
        }).catch((e) => { AlertHelper.showSimpleErrorAlert() });

      }

    }, [route, navigation, sharedContext.who])
  );
  useEffect(() => {
    customUserResourceApi.getMe().then((userMe) => {
      sharedContext.setUserMe(userMe)
      setUser(userMe);
      customUserExtendedResourceApi.getUserExtendedMe().then((userExtendedMe) => {
        sharedContext.setUserExtendedMe(userExtendedMe)
        setProfileState({
          id: userExtendedMe.id,
          firstName: userExtendedMe.firstName,
          lastName: userExtendedMe.lastName,
          email: userMe.email,
          phone: userExtendedMe.telephone
        })
        setProfileEditState({
          id: userExtendedMe.id,
          firstName: userExtendedMe.firstName,
          lastName: userExtendedMe.lastName,
          email: userMe.email,
          phone: userExtendedMe.telephone
        })
      }).catch((e) => { AlertHelper.showSimpleErrorAlert() });
    }).catch((e) => { AlertHelper.showSimpleErrorAlert() });
  }, [])

  const toggleSave = (key: Fields) => {
    editable[key] = !editable[key];
    // Creating clone so React knows object has changes
    setEditable(Object.assign({}, editable));

    if (!editable[key]) {
      save(key);
    }
  }

  const togglePasswordEditable = () => {
    editable[Fields.password] = !editable[Fields.password];
    setEditable(Object.assign({}, editable));

    let fieldsAreComplete = (passwordState.newPassword != "") && (passwordState.newPassword != "") && (passwordState.newPasswordConfirm != "")

    if (!editable[Fields.password] && !fieldsAreComplete) {
      AlertHelper.showSimpleAlert(i18n.t('screens.profile.password_error.error'), i18n.t('screens.profile.password_error.fill_all_fields'))
    }
    if (!editable[Fields.password] && fieldsAreComplete) {
      changePassword()
    }
  }

  const changePassword = () => {
    if (!isPasswordValid(passwordState.newPassword)) {
      AlertHelper.showSimpleAlert(i18n.t('screens.profile.password_error.validation_error'), i18n.t('screens.profile.password_error.validation_error'))
      return;
    }
    if (!(passwordState.newPassword === passwordState.newPasswordConfirm)) {
      AlertHelper.showSimpleAlert(i18n.t('screens.profile.password_error.error'), i18n.t('screens.profile.password_error.passwords_not_equal'))
      return;
    }
    let changePasswordRequest: ChangePasswordRequest = {
      passwordChangeDTO: {
        currentPassword: passwordState.currentPassword,
        newPassword: passwordState.newPassword
      }
    };

    accountResourceApi.changePassword(changePasswordRequest).then(() => {
      AlertHelper.showSimpleAlert(i18n.t('screens.profile.password_error.success'), i18n.t('screens.profile.password_error.password_changed'));
    }).catch((e) => {
      AlertHelper.showSimpleAlert("", e.statusText);
    })
    resetPasswordState();
  }

  const resetPasswordState = () => {
    setPasswordState(passwordStateInit);
  }

  const save = (key: Fields) => {

    let inputUser: PartialUpdateUserRequest = {
      id: user?.id!,
      user: {
        id: user?.id,
        login: user?.login!,
        activated: true
      }
    }

    let inputUserExtended: PartialUpdateUserExtendedRequest = {
      id: profileState?.id!,
      userExtended: {
        id: profileState?.id
      }
    }
    switch (key) {
      case Fields.firstName:
        inputUserExtended.userExtended.firstName = profileEditState.firstName
        inputUser.user.firstName = profileEditState.firstName

        break;
      case Fields.lastName:
        inputUserExtended.userExtended.lastName = profileEditState.lastName;
        inputUser.user.lastName = profileEditState.lastName
        break;
      case Fields.phone:
        inputUserExtended.userExtended.telephone = profileEditState.phone
        break;
      case Fields.email:
        inputUser.user.email = profileEditState.email
        inputUser.user.login = profileEditState.email!
        break;
    }

    // check if edit state is equal to user state to avoid unnecessary api calls
    if (!isEqual(profileState, profileEditState)) {
      customUserExtendedResourceApi.partialUpdateUserExtendedMe(inputUserExtended).then(() => {
        profileState[key] = profileEditState[key];
        setProfileState({ ...profileState });
      }).catch((e) => {
        console.error(e);
        AlertHelper.showSimpleErrorAlert();
      });
      customUserResourceApi.partialUpdateUser(inputUser).then(() => {
        profileState[key] = profileEditState[key];
        setProfileState({ ...profileState });
      }).catch((e) => {
        console.error(e);
        AlertHelper.showSimpleErrorAlert();
      })
    }
  }

  const handleChangeText = (key: Fields, text: string): void => {
    profileEditState[key] = text;
    setProfileEditState({ ...profileEditState });
  }

  const handleChangePasswordText = (text: string, field: PasswordFields): void => {
    switch (field) {
      case PasswordFields.currentPassword:
        setPasswordState({
          ...passwordState,
          currentPassword: text
        })
        break;
      case PasswordFields.newPassword:
        setPasswordState({
          ...passwordState,
          newPassword: text
        })
        break;
      case PasswordFields.newPasswordConfirm:
        setPasswordState({
          ...passwordState,
          newPasswordConfirm: text
        })
        break;
    }
  }

  function isPasswordValid(newPassword: string): boolean {
    var passw = /^(?=.{8,}$)(?=(?:.*?[A-Z]){1})(?=.*?[a-z])(?=(?:.*?[0-9]){1}).*$/;
    if (newPassword.match(passw)) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.profile_data")}</Text>
      </View>

      <ScrollView style={[hykeeStyle.containerScroll, { marginTop: 20 }]}>
        <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
          <View style={{ flex: 0.8 }}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.firstName')}
            </Text>
            <Collapsible
              collapsed={!editable[Fields.firstName]}
              duration={500}
            >
              <TextInput defaultValue={profileEditState.firstName} onChangeText={(text) => handleChangeText(Fields.firstName, text)} style={styles.textInput} />
            </Collapsible>
          </View>
          <View style={[hykeeStyle.directionRow, { justifyContent: !editable[Fields.firstName] ? 'space-between' : 'flex-end', marginTop: 10 }]}>
            {!editable[Fields.firstName] ? <Text style={styles.value}>{profileEditState.firstName}</Text> : null}
            <View style={hykeeStyle.icon}>
              <Pressable style={styles.pressable} onPress={() => toggleSave(Fields.firstName)}>
                {editable[Fields.firstName] ? <Icon name={'save'} color={ColorTextInput()}></Icon> : null}
                {!editable[Fields.firstName] ? <Icon name={'edit'} color={ColorTextInput()}></Icon> : null}
                {editable[Fields.firstName] ? <Text style={styles.edit}> {i18n.t('screens.profile.save')} </Text> : null}
                {!editable[Fields.firstName] ? <Text style={styles.edit}> {i18n.t('screens.profile.edit')} </Text> : null}
              </Pressable>
            </View>
          </View>
        </View>
        <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
          <View style={{ flex: 0.8 }}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.lastName')}
            </Text>
            <Collapsible
              collapsed={!editable[Fields.lastName]}
              duration={500}
            >
              <TextInput defaultValue={profileEditState.lastName} onChangeText={(text) => handleChangeText(Fields.lastName, text)} style={styles.textInput} />
            </Collapsible>
          </View>

          <View style={[hykeeStyle.directionRow, { justifyContent: !editable[Fields.lastName] ? 'space-between' : 'flex-end', marginTop: 10 }]}>
            {!editable[Fields.lastName] ? <Text style={styles.value}>{profileEditState.lastName}</Text> : null}
            <View style={hykeeStyle.icon}>
              <Pressable style={styles.pressable} onPress={() => toggleSave(Fields.lastName)}>
                {editable[Fields.lastName] ? <Icon name={'save'} color={ColorTextInput()}></Icon> : null}
                {!editable[Fields.lastName] ? <Icon name={'edit'} color={ColorTextInput()}></Icon> : null}
                {editable[Fields.lastName] ? <Text style={styles.edit}> {i18n.t('screens.profile.save')} </Text> : null}
                {!editable[Fields.lastName] ? <Text style={styles.edit}> {i18n.t('screens.profile.edit')} </Text> : null}
              </Pressable>
            </View>
          </View>
        </View>
        <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
          <View style={{ flex: 0.8 }}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.email')}
            </Text>
            <Collapsible
              collapsed={!editable[Fields.email]}
              duration={500}
            >
              <TextInput defaultValue={profileEditState.email} onChangeText={(text) => handleChangeText(Fields.email, text)} style={styles.textInput} />
            </Collapsible>
          </View>
          <View style={[hykeeStyle.directionRow, { justifyContent: !editable[Fields.email] ? 'space-between' : 'flex-end', marginTop: 10 }]}>
            {!editable[Fields.email] ? <Text style={styles.value}>{profileEditState.email}</Text> : null}
            <View style={hykeeStyle.icon}>
              <Pressable style={styles.pressable} onPress={() => toggleSave(Fields.email)}>
                {editable[Fields.email] ? <Icon name={'save'} color={ColorTextInput()}></Icon> : null}
                {!editable[Fields.email] ? <Icon name={'edit'} color={ColorTextInput()}></Icon> : null}
                {editable[Fields.email] ? <Text style={styles.edit}> {i18n.t('screens.profile.save')} </Text> : null}
                {!editable[Fields.email] ? <Text style={styles.edit}> {i18n.t('screens.profile.edit')} </Text> : null}
              </Pressable>
            </View>
          </View>
        </View>


        <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
          <View style={{ flex: 0.8 }}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.password')}
            </Text>

          </View>
          <Collapsible
            collapsed={!editable[Fields.password]}
            duration={1000}
          >
            <View>
              <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
                <View>
                  <Text style={[hykeeStyle.mainFont, { color: '#ffffff' }]}>
                    {i18n.t('screens.profile.current_password')}:
                  </Text>
                </View>
                <View>
                  <TextInput secureTextEntry onChangeText={(text) => handleChangePasswordText(text, PasswordFields.currentPassword)} style={styles.textInput} />
                </View>
              </View>
              <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
                <View>
                  <Text style={[hykeeStyle.mainFont, { color: '#ffffff' }]}>
                    {i18n.t('screens.profile.new_password')}:
                  </Text>
                </View>
                <View>
                  <TextInput secureTextEntry onChangeText={(text) => handleChangePasswordText(text, PasswordFields.newPassword)} style={styles.textInput} />
                </View>
              </View>
              <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
                <View>
                  <Text style={[hykeeStyle.mainFont, { color: '#ffffff' }]}>
                    {i18n.t('screens.profile.new_password_confirm')}:
                  </Text>
                </View>
                <View>
                  <TextInput secureTextEntry onChangeText={(text) => handleChangePasswordText(text, PasswordFields.newPasswordConfirm)} style={styles.textInput} />
                </View>
              </View>
            </View>
          </Collapsible>

          <View style={[hykeeStyle.directionRow, { justifyContent: !editable[Fields.password] ? 'space-between' : 'flex-end', marginTop: 10 }]}>
            {!editable[Fields.password] ? <Text style={styles.value}>********</Text> : null}
            <View style={hykeeStyle.icon}>
              <Pressable style={styles.pressable} onPress={() => togglePasswordEditable()}>
                {editable[Fields.password] ? <Icon name={'save'} color={ColorTextInput()}></Icon> : null}
                {!editable[Fields.password] ? <Icon name={'edit'} color={ColorTextInput()}></Icon> : null}
                {editable[Fields.password] ? <Text style={styles.edit}> {i18n.t('screens.profile.save')} </Text> : null}
                {!editable[Fields.password] ? <Text style={styles.edit}> {i18n.t('screens.profile.edit')} </Text> : null}
              </Pressable>
            </View>
          </View>
        </View>

        <View style={[hykeeStyle.directionCol, { paddingTop: 20 }]}>
          <View style={{ flex: 0.8 }}>
            <Text style={styles.label}>
              {i18n.t('screens.profile.phone')}
            </Text>

            <Collapsible
              collapsed={!editable[Fields.phone]}
              duration={500}
            >
              <TextInput defaultValue={sharedContext.userExtendedMe?.telephone} onChangeText={(text) => handleChangeText(Fields.phone, text)} style={styles.textInput} />
            </Collapsible>
          </View>

          <View style={[hykeeStyle.directionRow, { justifyContent: !editable[Fields.phone] ? 'space-between' : 'flex-end', marginTop: 10 }]}>
            {!editable[Fields.phone] ? <Text style={styles.value}>{sharedContext.userExtendedMe?.telephone}</Text> : null}
            <View style={hykeeStyle.icon}>
              <Pressable style={styles.pressable} onPress={() => toggleSave(Fields.phone)}>
                {editable[Fields.phone] ? <Icon name={'save'} color={ColorTextInput()}></Icon> : null}
                {!editable[Fields.phone] ? <Icon name={'edit'} color={ColorTextInput()}></Icon> : null}

                {editable[Fields.phone] ? <Text style={styles.edit}> {i18n.t('screens.profile.save')} </Text> : null}
                {!editable[Fields.phone] ? <Text style={styles.edit}> {i18n.t('screens.profile.edit')} </Text> : null}
              </Pressable>
            </View>
          </View>
        </View>
      </ScrollView>

    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  rowPassword: {
    flex: 1,
    padding: 15,
    alignContent: 'center',
    ...Platform.select({
      web: { alignContent: "flex-start" },
    })
  },
  titleText: {
    marginHorizontal: 15,
    marginTop: 5,
    color: ColorTextInput(),
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  // TODO: design a better text input
  textInput: {
    backgroundColor: BackgroundColorInputText(),
    padding: 8,
    color: ColorTextInput(),
    borderRadius: 5,
    fontFamily: 'poppins',
    marginVertical: 10,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    padding: 15,
  },
  col: {
    flex: 0.8
  },
  pressable: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: BackgroundColorButtonGreen(),
    alignItems: 'center',
    borderRadius: 8,
  },
  value: {
    fontFamily: 'poppins',
    color: '#737373',
    fontSize: 12,
    justifyContent: 'flex-start'
  },
  label: {
    fontFamily: 'poppins',
    color: 'white',
    fontSize: 16
  },
  edit: {
    marginLeft: 5,
    fontSize: 10,
    alignSelf: 'center',
    color: ColorTextInput()
  },
});



