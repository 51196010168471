/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrascrizioneProtCompleti,
    TrascrizioneProtCompletiFromJSON,
    TrascrizioneProtCompletiFromJSONTyped,
    TrascrizioneProtCompletiToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrascrizioniProtCompleti
 */
export interface TrascrizioniProtCompleti {
    /**
     * 
     * @type {Array<TrascrizioneProtCompleti>}
     * @memberof TrascrizioniProtCompleti
     */
    trascrizioneProtCompleti: Array<TrascrizioneProtCompleti>;
}

export function TrascrizioniProtCompletiFromJSON(json: any): TrascrizioniProtCompleti {
    return TrascrizioniProtCompletiFromJSONTyped(json, false);
}

export function TrascrizioniProtCompletiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrascrizioniProtCompleti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trascrizioneProtCompleti': ((json['trascrizioneProtCompleti'] as Array<any>).map(TrascrizioneProtCompletiFromJSON)),
    };
}

export function TrascrizioniProtCompletiToJSON(value?: TrascrizioniProtCompleti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trascrizioneProtCompleti': ((value.trascrizioneProtCompleti as Array<any>).map(TrascrizioneProtCompletiToJSON)),
    };
}


