import { NavigationHelper } from '@helpers/NavigationHelper';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useState} from 'react';
import {StyleSheet, View, TouchableOpacity, Text, TextInput, Keyboard} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RootStackScreenProps } from '../../../../../types';
import { i18n } from '../../../../i18n/i18n';
import Icon from '../../../Tools/Icon';
import Colors from "../../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import {Company, CompanyData, CompanySaved, PartialUpdateCompanyCustomRequest} from "@services/apis/generated";
import * as stream from "stream";
import {customCompanyDataResourceApi, customCompanyResourceApi} from "@services/apis/ApiConfiguration";
import {AlertHelper} from "@helpers/AlertHelper";
import SharedContext from "../../../../navigation/SharedContext";
import {Logger} from "@helpers/Logger";
import {GenericHelper} from "@helpers/GenericHelper";
import ModalSimpleSuccess from "@screens/Modal/ModalSimpleSuccess";

export default function PartnerProgramScreen({ navigation, route }: RootStackScreenProps<'PartnerProgramScreen'>) {

    const sharedContext = React.useContext(SharedContext);
    //MOCK
    const partnerProgram = 'Il Partner Program di Alpha Bank consente di accedere in via esclusiva ad aggiornamenti e informative real-time altamente profilate rispetto ai nuovi bandi di finanza agevolata su territorio nazionale ed europeo. Inoltre, gli abbonati al partner program avranno un consulente dedicato che risponderà via email o via chat alle richieste di approfondimento dei clienti entro massimo 60 minuti dalla richiesta.';
    const [partnerText, setPartnerText] = useState<string>('');
    const [modalSuccessPartnerProgram, setModalSuccessPartnerProgram] = useState<boolean>(false)

    const updateAccountingData = (): void => {
        const newCompany = {} as Company;
        newCompany.id = sharedContext.selectedCompany?.id!;
        newCompany.partnerProgramDescriptionText = partnerText;
        customCompanyResourceApi.partialUpdateCompanyCustom({   id: newCompany.id, company: newCompany }).then(company => {
                setPartnerText(newCompany.partnerProgramDescriptionText!)
                setModalSuccessPartnerProgram(true)
            }
        )
    }

    const getCompanyData = (id: number): void => {
        customCompanyDataResourceApi.getCompanyDataByCompanyDataId({ companyDataId: id }).then(res => {
            setPartnerText(res.company?.partnerProgramDescriptionText!)
            if (res.company?.partnerProgramDescriptionText == '' || res.company?.partnerProgramDescriptionText == null)
                setPartnerText('Il Partner Program di Alpha Bank consente di accedere in via esclusiva ad aggiornamenti e informative real-time altamente profilate rispetto ai nuovi bandi di finanza agevolata su territorio nazionale ed europeo. Inoltre, gli abbonati al partner program avranno un consulente dedicato che risponderà via email o via chat alle richieste di approfondimento dei clienti entro massimo 60 minuti dalla richiesta.');
        }).catch(error => Logger.warn('Dashboard', `Company data not found for company ${id}`));
    }

    useEffect(() => {
        getCompanyData(sharedContext.selectedCompany?.id!);
    }, []);

    async function closeKeyboard() {
        Keyboard.dismiss();
        await GenericHelper.delay(500);
        NavigationHelper.navigateToGoBack(navigation);
    }

    return (


        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity style={hykeeStyle.goBack} onPress={() => closeKeyboard()}>
                    <Icon name={'angle-left'} size={24} color={'#00CC83'} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.partner_program")}</Text>
            </View>
            <ScrollView style={styles.containerScroll}>
                <View>
                    <Text style={styles.textLabel}>
                        {i18n.t("components.partner_program.description")}
                    </Text>
                </View>
                <View style={{ borderColor: 'white', borderWidth: 1, padding: 10, marginTop: 30, marginBottom: 30 }}>
                    <TextInput style={styles.textInput} defaultValue={partnerText} multiline={true} onChangeText={setPartnerText}>
                    </TextInput>
                </View>
                <View style={{marginBottom: 30}}>
                    <TouchableOpacity style={styles.button} onPress={() => updateAccountingData()}
                    >
                        <Text style={hykeeStyle.buttonText}>{i18n.t("components.add_new_partner_program.button_text_save")}</Text>
                    </TouchableOpacity>
                </View>
                <View>
                    <TouchableOpacity style={styles.button} onPress={() => {
                        navigation.navigate("PartnerProgramList");
                    }}
                    >
                        <Text style={hykeeStyle.buttonText}>{i18n.t("components.partner_program.notice_list")}</Text>
                    </TouchableOpacity>
                </View>
                {modalSuccessPartnerProgram &&
                    <ModalSimpleSuccess
                        msg={i18n.t('components.partner_program.success_modal_text')}
                        visible={(visible) => { setModalSuccessPartnerProgram(visible) }}
                    />
                }

            </ScrollView>

        </LinearGradient>

    )
}

const styles = StyleSheet.create({
    titleText: {
        marginHorizontal: 15,
        marginTop: 5,
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        fontFamily: 'poppins',
    },
    containerSubtitle: {
        marginBottom: 20,
    },
    containerList: {
        marginBottom: 20,
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00CC83',
        color: 'white',
        padding: 15,
        borderRadius: 5,
    },
    textLabel: {
        marginTop: 10,
        marginHorizontal: 15,
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    titleManage: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        marginBottom: 20,
    },
    container: {
        flex: 0.15,
        marginTop: 50,
        marginBottom: 20,
        flexDirection: 'row',
        marginHorizontal: 20,
        fontFamily: 'poppins',
        alignItems: "center",
        justifyContent: "flex-start"
    },
    containerScroll: {
        flex: 0.85,
        marginBottom: 40,
        marginHorizontal: 15,
    },
    delimiter: {
        borderWidth: 1,
        borderColor: '#505050',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        padding: 15,
    },
    col: {
        flex: 0.8
    },
    textInput: {
        color: 'white',
        padding: 10,
        height: 90,
        fontSize: 18,
    }
});