/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionResponseDTO,
    QuestionResponseDTOFromJSON,
    QuestionResponseDTOFromJSONTyped,
    QuestionResponseDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyQuestionResponseDTO
 */
export interface SurveyQuestionResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionResponseDTO
     */
    surveyId?: number;
    /**
     * 
     * @type {Array<QuestionResponseDTO>}
     * @memberof SurveyQuestionResponseDTO
     */
    questionResponseDTO?: Array<QuestionResponseDTO>;
}

export function SurveyQuestionResponseDTOFromJSON(json: any): SurveyQuestionResponseDTO {
    return SurveyQuestionResponseDTOFromJSONTyped(json, false);
}

export function SurveyQuestionResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyQuestionResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'questionResponseDTO': !exists(json, 'questionResponseDTO') ? undefined : ((json['questionResponseDTO'] as Array<any>).map(QuestionResponseDTOFromJSON)),
    };
}

export function SurveyQuestionResponseDTOToJSON(value?: SurveyQuestionResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'questionResponseDTO': value.questionResponseDTO === undefined ? undefined : ((value.questionResponseDTO as Array<any>).map(QuestionResponseDTOToJSON)),
    };
}


