/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormaGiuridica,
    FormaGiuridicaFromJSON,
    FormaGiuridicaFromJSONTyped,
    FormaGiuridicaToJSON,
    IndirizzoLocalizzazione,
    IndirizzoLocalizzazioneFromJSON,
    IndirizzoLocalizzazioneFromJSONTyped,
    IndirizzoLocalizzazioneToJSON,
    IndirizzoPostaCertificata,
    IndirizzoPostaCertificataFromJSON,
    IndirizzoPostaCertificataFromJSONTyped,
    IndirizzoPostaCertificataToJSON,
    PersonaRappresentante,
    PersonaRappresentanteFromJSON,
    PersonaRappresentanteFromJSONTyped,
    PersonaRappresentanteToJSON,
    PersoneRappresentanti,
    PersoneRappresentantiFromJSON,
    PersoneRappresentantiFromJSONTyped,
    PersoneRappresentantiToJSON,
    SintesiQuotata,
    SintesiQuotataFromJSON,
    SintesiQuotataFromJSONTyped,
    SintesiQuotataToJSON,
    StatoAttivita,
    StatoAttivitaFromJSON,
    StatoAttivitaFromJSONTyped,
    StatoAttivitaToJSON,
    Telefono,
    TelefonoFromJSON,
    TelefonoFromJSONTyped,
    TelefonoToJSON,
    TipiProcedureConcorsuali,
    TipiProcedureConcorsualiFromJSON,
    TipiProcedureConcorsualiFromJSONTyped,
    TipiProcedureConcorsualiToJSON,
} from './';

/**
 * 
 * @export
 * @interface DatiIdentificativi
 */
export interface DatiIdentificativi {
    /**
     * 
     * @type {TipiProcedureConcorsuali}
     * @memberof DatiIdentificativi
     */
    tipiProcedureConcorsuali?: TipiProcedureConcorsuali;
    /**
     * 
     * @type {StatoAttivita}
     * @memberof DatiIdentificativi
     */
    statoAttivita?: StatoAttivita;
    /**
     * 
     * @type {FormaGiuridica}
     * @memberof DatiIdentificativi
     */
    formaGiuridica?: FormaGiuridica;
    /**
     * 
     * @type {IndirizzoLocalizzazione}
     * @memberof DatiIdentificativi
     */
    indirizzoLocalizzazione?: IndirizzoLocalizzazione;
    /**
     * 
     * @type {IndirizzoPostaCertificata}
     * @memberof DatiIdentificativi
     */
    indirizzoPostaCertificata?: IndirizzoPostaCertificata;
    /**
     * 
     * @type {Telefono}
     * @memberof DatiIdentificativi
     */
    telefono?: Telefono;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    sitoInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    email?: string;
    /**
     * 
     * @type {PersoneRappresentanti}
     * @memberof DatiIdentificativi
     */
    personeRappresentanti?: PersoneRappresentanti;
    /**
     * 
     * @type {PersonaRappresentante}
     * @memberof DatiIdentificativi
     */
    personaRappresentante?: PersonaRappresentante;
    /**
     * 
     * @type {SintesiQuotata}
     * @memberof DatiIdentificativi
     */
    sintesiQuotata?: SintesiQuotata;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    fonte?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    tipoSoggetto?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    descrizioneTipoSoggetto?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    tipoImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    descrizioneTipoImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    statoImpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    statoDitta?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtIscrizioneRi?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtIscrizioneRea?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtCancellazione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtCessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtAttoCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtUltimoProtocollo?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    dtModUltimoProtocolloEvaso?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    partitaIva?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    gruppoIva?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    cciaaCompetente?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    faggiornamento?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    ccciaaCompetente?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    fsedeIntercamerale?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    cfonte?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    clei?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIdentificativi
     */
    nrd?: string;
}

export function DatiIdentificativiFromJSON(json: any): DatiIdentificativi {
    return DatiIdentificativiFromJSONTyped(json, false);
}

export function DatiIdentificativiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatiIdentificativi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipiProcedureConcorsuali': !exists(json, 'tipiProcedureConcorsuali') ? undefined : TipiProcedureConcorsualiFromJSON(json['tipiProcedureConcorsuali']),
        'statoAttivita': !exists(json, 'statoAttivita') ? undefined : StatoAttivitaFromJSON(json['statoAttivita']),
        'formaGiuridica': !exists(json, 'formaGiuridica') ? undefined : FormaGiuridicaFromJSON(json['formaGiuridica']),
        'indirizzoLocalizzazione': !exists(json, 'indirizzoLocalizzazione') ? undefined : IndirizzoLocalizzazioneFromJSON(json['indirizzoLocalizzazione']),
        'indirizzoPostaCertificata': !exists(json, 'indirizzoPostaCertificata') ? undefined : IndirizzoPostaCertificataFromJSON(json['indirizzoPostaCertificata']),
        'telefono': !exists(json, 'telefono') ? undefined : TelefonoFromJSON(json['telefono']),
        'sitoInternet': !exists(json, 'sitoInternet') ? undefined : json['sitoInternet'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'personeRappresentanti': !exists(json, 'personeRappresentanti') ? undefined : PersoneRappresentantiFromJSON(json['personeRappresentanti']),
        'personaRappresentante': !exists(json, 'personaRappresentante') ? undefined : PersonaRappresentanteFromJSON(json['personaRappresentante']),
        'sintesiQuotata': !exists(json, 'sintesiQuotata') ? undefined : SintesiQuotataFromJSON(json['sintesiQuotata']),
        'fonte': !exists(json, 'fonte') ? undefined : json['fonte'],
        'tipoSoggetto': !exists(json, 'tipoSoggetto') ? undefined : json['tipoSoggetto'],
        'descrizioneTipoSoggetto': !exists(json, 'descrizioneTipoSoggetto') ? undefined : json['descrizioneTipoSoggetto'],
        'tipoImpresa': !exists(json, 'tipoImpresa') ? undefined : json['tipoImpresa'],
        'descrizioneTipoImpresa': !exists(json, 'descrizioneTipoImpresa') ? undefined : json['descrizioneTipoImpresa'],
        'statoImpresa': !exists(json, 'statoImpresa') ? undefined : json['statoImpresa'],
        'statoDitta': !exists(json, 'statoDitta') ? undefined : json['statoDitta'],
        'dtIscrizioneRi': !exists(json, 'dtIscrizioneRi') ? undefined : json['dtIscrizioneRi'],
        'dtIscrizioneRea': !exists(json, 'dtIscrizioneRea') ? undefined : json['dtIscrizioneRea'],
        'dtCancellazione': !exists(json, 'dtCancellazione') ? undefined : json['dtCancellazione'],
        'dtCessazione': !exists(json, 'dtCessazione') ? undefined : json['dtCessazione'],
        'dtAttoCostituzione': !exists(json, 'dtAttoCostituzione') ? undefined : json['dtAttoCostituzione'],
        'dtCostituzione': !exists(json, 'dtCostituzione') ? undefined : json['dtCostituzione'],
        'dtUltimoProtocollo': !exists(json, 'dtUltimoProtocollo') ? undefined : json['dtUltimoProtocollo'],
        'dtModUltimoProtocolloEvaso': !exists(json, 'dtModUltimoProtocolloEvaso') ? undefined : json['dtModUltimoProtocolloEvaso'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'partitaIva': !exists(json, 'partitaIva') ? undefined : json['partitaIva'],
        'gruppoIva': !exists(json, 'gruppoIva') ? undefined : json['gruppoIva'],
        'cciaaCompetente': !exists(json, 'cciaaCompetente') ? undefined : json['cciaaCompetente'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'faggiornamento': !exists(json, 'faggiornamento') ? undefined : json['faggiornamento'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'ccciaaCompetente': !exists(json, 'ccciaaCompetente') ? undefined : json['ccciaaCompetente'],
        'fsedeIntercamerale': !exists(json, 'fsedeIntercamerale') ? undefined : json['fsedeIntercamerale'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
        'clei': !exists(json, 'clei') ? undefined : json['clei'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
    };
}

export function DatiIdentificativiToJSON(value?: DatiIdentificativi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipiProcedureConcorsuali': TipiProcedureConcorsualiToJSON(value.tipiProcedureConcorsuali),
        'statoAttivita': StatoAttivitaToJSON(value.statoAttivita),
        'formaGiuridica': FormaGiuridicaToJSON(value.formaGiuridica),
        'indirizzoLocalizzazione': IndirizzoLocalizzazioneToJSON(value.indirizzoLocalizzazione),
        'indirizzoPostaCertificata': IndirizzoPostaCertificataToJSON(value.indirizzoPostaCertificata),
        'telefono': TelefonoToJSON(value.telefono),
        'sitoInternet': value.sitoInternet,
        'email': value.email,
        'personeRappresentanti': PersoneRappresentantiToJSON(value.personeRappresentanti),
        'personaRappresentante': PersonaRappresentanteToJSON(value.personaRappresentante),
        'sintesiQuotata': SintesiQuotataToJSON(value.sintesiQuotata),
        'fonte': value.fonte,
        'tipoSoggetto': value.tipoSoggetto,
        'descrizioneTipoSoggetto': value.descrizioneTipoSoggetto,
        'tipoImpresa': value.tipoImpresa,
        'descrizioneTipoImpresa': value.descrizioneTipoImpresa,
        'statoImpresa': value.statoImpresa,
        'statoDitta': value.statoDitta,
        'dtIscrizioneRi': value.dtIscrizioneRi,
        'dtIscrizioneRea': value.dtIscrizioneRea,
        'dtCancellazione': value.dtCancellazione,
        'dtCessazione': value.dtCessazione,
        'dtAttoCostituzione': value.dtAttoCostituzione,
        'dtCostituzione': value.dtCostituzione,
        'dtUltimoProtocollo': value.dtUltimoProtocollo,
        'dtModUltimoProtocolloEvaso': value.dtModUltimoProtocolloEvaso,
        'denominazione': value.denominazione,
        'partitaIva': value.partitaIva,
        'gruppoIva': value.gruppoIva,
        'cciaaCompetente': value.cciaaCompetente,
        'cciaa': value.cciaa,
        'faggiornamento': value.faggiornamento,
        'cfiscale': value.cfiscale,
        'ccciaaCompetente': value.ccciaaCompetente,
        'fsedeIntercamerale': value.fsedeIntercamerale,
        'nrea': value.nrea,
        'cfonte': value.cfonte,
        'clei': value.clei,
        'nrd': value.nrd,
    };
}


