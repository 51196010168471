/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceXbrlDTO
 */
export interface BalanceXbrlDTO {
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    anno?: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceXbrlDTO
     */
    type?: BalanceXbrlDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BalanceXbrlDTO
     */
    currentMoreFieldsState?: BalanceXbrlDTOCurrentMoreFieldsStateEnum;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeRicavi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeValoreDellaProduzione?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeMateriePrime?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeServizi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeePersonale?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeOneriDiversiDiGestione?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeEBITDAPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeEBIT?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeOneriEProventiFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeRettificheFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeImposte?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceHykeeRisultatoEsercizio?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceAdjHykeeEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceAdjHykeeEBITDAAdj?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceAdjHykeeDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ceAdjHykeeEBITAdj?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getCheckUtileNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeImmobilizzazioniMateriali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeImmobilizzazioniFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeImmobilizzazioniImmateriali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeAttivoFisso?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeCreditiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeDebitiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeRimanenze?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeCCON?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeAltriCrediti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeAltriDebiti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeFondiETFR?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeCIN?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeePatrimonioNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeCapitaleSociale?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeDebitiFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeLiquidita?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeAttivitaFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeePFN?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    spHykeeEplusPFN?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getCheckSP?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeEBIT?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeAmmortamentiESvalutazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeRettificheAttivitaFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeImposte?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeFundsFromOperationFFO?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarCCON?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarAltriCrediti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarAltriDebiti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarFondiETFR?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeCashFromOperationCFO?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeCapex?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeInvestimentiOnDisinvestimentiFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeFCFO?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeProventiEOneriFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeFreeCashFlowToEquityFCFE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarPatrimonioNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeFCFEAdjustedDeltaPFN?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarDebitiFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeVarAttivitaFinCorrenti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeFreeCashFlowDeltaDisponibilitaLiquide?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rfHykeeCheckRf?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykee2YoYRicavi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykee2YoYVP?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeEbitdaPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeEbitPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeEbitdaAdjPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeEbitAdjPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeUtileOnPerditaPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeCapexOnRicavi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeROCE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeePFNOnEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeePFNOnEBITDAAdj?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeGrossDebtOnPN?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeCCONOnRICAVIPERCENTAGE?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeDSO?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeDPOWCapex?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeDSI?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeFCCR?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeDSCR?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getCashConversionCycle?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    ratioHykeeDPOWithoutCapex?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getGrossDebtOnEBITDA?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getFCFOOnGROSSDEBT?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getFCFOOnEBITDAExclSvalFin?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    getOneriFinanziariImplicitiSoloSuBanche?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoControllantiTotaleDebitiVersoControllanti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    maxReference?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    positiveReference?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    negativeReference?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleAttivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    cashReference?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleValoreProduzione?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totalePassivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleRimanenze?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneServizi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    passivoRateiRisconti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    attivoRateiRisconti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleFondiRischiOneri?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    utilePerditaEsercizio?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totalePatrimonioNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    patrimonioNettoCapitale?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneGodimentoBeniTerzi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneOneriDiversiGestione?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneAccantonamentiRischi?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneAltriAccantonamenti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleImmobilizzazioniMateriali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleImmobilizzazioniFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleImmobilizzazioniImmateriali?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiAltriDebitiTotaleAltriDebiti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiAccontiTotaleAcconti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiObbligazioniTotaleObbligazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleDisponibilitaLiquide?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleProventiOneriFinanziari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiEsigibiliEntroEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiEsigibiliOltreEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    valoreProduzioneRicaviVenditePrestazioni?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzioneMateriePrimeSussidiarieConsumoMerci?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    costiProduzionePersonaleTotaleCostiPersonale?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleRettificheValoreAttivitaPassivitaFinanziarie?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoClientiTotaleCreditiVersoClienti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    immobilizzazioniMaterialiDestinateAllaVendita?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoControllantiTotaleCreditiVersoControllanti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiCreditiTributariTotaleCreditiTributari?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiImposteAnticipateTotaleImposteAnticipate?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiVersoAltriTotaleCreditiVersoAltri?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    trattamentoFineRapportoLavoroSubordinato?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    totaleCreditiVersoSociVersamentiAncoraDovuti?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiVersoBancheTotaleDebitiVersoBanche?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    immobilizzazioniMaterialiTerreniFabbricati?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiEsigibiliEntroEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    creditiEsigibiliOltreEsercizioSuccessivo?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiEsigibiliEntroEsercizioSuccessivoNegative?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceXbrlDTO
     */
    debitiEsigibiliOltreEsercizioSuccessivoNegative?: number;
}

/**
* @export
* @enum {string}
*/
export enum BalanceXbrlDTOTypeEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}/**
* @export
* @enum {string}
*/
export enum BalanceXbrlDTOCurrentMoreFieldsStateEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}

export function BalanceXbrlDTOFromJSON(json: any): BalanceXbrlDTO {
    return BalanceXbrlDTOFromJSONTyped(json, false);
}

export function BalanceXbrlDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceXbrlDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'currentMoreFieldsState': !exists(json, 'currentMoreFieldsState') ? undefined : json['currentMoreFieldsState'],
        'ceHykeeRicavi': !exists(json, 'ce_Hykee__Ricavi') ? undefined : json['ce_Hykee__Ricavi'],
        'ceHykeeValoreDellaProduzione': !exists(json, 'ce_Hykee__Valore_della_produzione') ? undefined : json['ce_Hykee__Valore_della_produzione'],
        'ceHykeeMateriePrime': !exists(json, 'ce_Hykee__Materie_Prime') ? undefined : json['ce_Hykee__Materie_Prime'],
        'ceHykeeServizi': !exists(json, 'ce_Hykee__Servizi') ? undefined : json['ce_Hykee__Servizi'],
        'ceHykeePersonale': !exists(json, 'ce_Hykee__Personale') ? undefined : json['ce_Hykee__Personale'],
        'ceHykeeOneriDiversiDiGestione': !exists(json, 'ce_Hykee__Oneri_diversi_di_gestione') ? undefined : json['ce_Hykee__Oneri_diversi_di_gestione'],
        'ceHykeeEBITDA': !exists(json, 'ce_Hykee__EBITDA') ? undefined : json['ce_Hykee__EBITDA'],
        'ceHykeeEBITDAPERCENTAGE': !exists(json, 'ce_Hykee__EBITDAPERCENTAGE') ? undefined : json['ce_Hykee__EBITDAPERCENTAGE'],
        'ceHykeeDA': !exists(json, 'ce_Hykee__DA') ? undefined : json['ce_Hykee__DA'],
        'ceHykeeEBIT': !exists(json, 'ce_Hykee__EBIT') ? undefined : json['ce_Hykee__EBIT'],
        'ceHykeeOneriEProventiFinanziari': !exists(json, 'ce_Hykee__Oneri_e_proventi_finanziari') ? undefined : json['ce_Hykee__Oneri_e_proventi_finanziari'],
        'ceHykeeRettificheFinanziarie': !exists(json, 'ce_Hykee__Rettifiche_finanziarie') ? undefined : json['ce_Hykee__Rettifiche_finanziarie'],
        'ceHykeeEBT': !exists(json, 'ce_Hykee__EBT') ? undefined : json['ce_Hykee__EBT'],
        'ceHykeeImposte': !exists(json, 'ce_Hykee__Imposte') ? undefined : json['ce_Hykee__Imposte'],
        'ceHykeeRisultatoEsercizio': !exists(json, 'ce_Hykee__Risultato_esercizio') ? undefined : json['ce_Hykee__Risultato_esercizio'],
        'ceAdjHykeeEBITDA': !exists(json, 'ce_Adj_Hykee__EBITDA') ? undefined : json['ce_Adj_Hykee__EBITDA'],
        'ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione': !exists(json, 'ce_Adj_Hykee__Adj_Saldo_Altri_Ricavi_on_Oneri_diversi_di_gestione') ? undefined : json['ce_Adj_Hykee__Adj_Saldo_Altri_Ricavi_on_Oneri_diversi_di_gestione'],
        'ceAdjHykeeEBITDAAdj': !exists(json, 'ce_Adj_Hykee__EBITDA_Adj') ? undefined : json['ce_Adj_Hykee__EBITDA_Adj'],
        'ceAdjHykeeDA': !exists(json, 'ce_Adj_Hykee__DA') ? undefined : json['ce_Adj_Hykee__DA'],
        'ceAdjHykeeEBITAdj': !exists(json, 'ce_Adj_Hykee__EBIT_Adj') ? undefined : json['ce_Adj_Hykee__EBIT_Adj'],
        'getCheckUtileNetto': !exists(json, 'get___Check_Utile_netto') ? undefined : json['get___Check_Utile_netto'],
        'spHykeeImmobilizzazioniMateriali': !exists(json, 'sp_Hykee__Immobilizzazioni_Materiali') ? undefined : json['sp_Hykee__Immobilizzazioni_Materiali'],
        'spHykeeImmobilizzazioniFinanziarie': !exists(json, 'sp_Hykee__Immobilizzazioni_finanziarie') ? undefined : json['sp_Hykee__Immobilizzazioni_finanziarie'],
        'spHykeeImmobilizzazioniImmateriali': !exists(json, 'sp_Hykee__Immobilizzazioni_immateriali') ? undefined : json['sp_Hykee__Immobilizzazioni_immateriali'],
        'spHykeeAttivoFisso': !exists(json, 'sp_Hykee__Attivo_Fisso') ? undefined : json['sp_Hykee__Attivo_Fisso'],
        'spHykeeCreditiCommerciali': !exists(json, 'sp_Hykee__Crediti_commerciali') ? undefined : json['sp_Hykee__Crediti_commerciali'],
        'spHykeeDebitiCommerciali': !exists(json, 'sp_Hykee__Debiti_Commerciali') ? undefined : json['sp_Hykee__Debiti_Commerciali'],
        'spHykeeRimanenze': !exists(json, 'sp_Hykee__Rimanenze') ? undefined : json['sp_Hykee__Rimanenze'],
        'spHykeeCCON': !exists(json, 'sp_Hykee__CCON') ? undefined : json['sp_Hykee__CCON'],
        'spHykeeAltriCrediti': !exists(json, 'sp_Hykee__Altri_Crediti') ? undefined : json['sp_Hykee__Altri_Crediti'],
        'spHykeeAltriDebiti': !exists(json, 'sp_Hykee__Altri_Debiti') ? undefined : json['sp_Hykee__Altri_Debiti'],
        'spHykeeFondiETFR': !exists(json, 'sp_Hykee__Fondi_e_TFR') ? undefined : json['sp_Hykee__Fondi_e_TFR'],
        'spHykeeCIN': !exists(json, 'sp_Hykee__CIN') ? undefined : json['sp_Hykee__CIN'],
        'spHykeePatrimonioNetto': !exists(json, 'sp_Hykee__Patrimonio_Netto') ? undefined : json['sp_Hykee__Patrimonio_Netto'],
        'spHykeeCapitaleSociale': !exists(json, 'sp_Hykee__Capitale_Sociale') ? undefined : json['sp_Hykee__Capitale_Sociale'],
        'spHykeeDebitiFinanziari': !exists(json, 'sp_Hykee__Debiti_Finanziari') ? undefined : json['sp_Hykee__Debiti_Finanziari'],
        'spHykeeLiquidita': !exists(json, 'sp_Hykee__Liquidita') ? undefined : json['sp_Hykee__Liquidita'],
        'spHykeeAttivitaFinanziarie': !exists(json, 'sp_Hykee__Attivita_finanziarie') ? undefined : json['sp_Hykee__Attivita_finanziarie'],
        'spHykeePFN': !exists(json, 'sp_Hykee__PFN') ? undefined : json['sp_Hykee__PFN'],
        'spHykeeEplusPFN': !exists(json, 'sp_Hykee__EplusPFN') ? undefined : json['sp_Hykee__EplusPFN'],
        'getCheckSP': !exists(json, 'get___Check_SP') ? undefined : json['get___Check_SP'],
        'rfHykeeEBIT': !exists(json, 'rf_Hykee__EBIT') ? undefined : json['rf_Hykee__EBIT'],
        'rfHykeeAmmortamentiESvalutazioni': !exists(json, 'rf_Hykee__Ammortamenti_e_Svalutazioni') ? undefined : json['rf_Hykee__Ammortamenti_e_Svalutazioni'],
        'rfHykeeRettificheAttivitaFinanziarie': !exists(json, 'rf_Hykee__Rettifiche_attivita_finanziarie') ? undefined : json['rf_Hykee__Rettifiche_attivita_finanziarie'],
        'rfHykeeImposte': !exists(json, 'rf_Hykee__Imposte') ? undefined : json['rf_Hykee__Imposte'],
        'rfHykeeFundsFromOperationFFO': !exists(json, 'rf_Hykee__Funds_from_Operation_FFO') ? undefined : json['rf_Hykee__Funds_from_Operation_FFO'],
        'rfHykeeVarCCON': !exists(json, 'rf_Hykee__Var_CCON') ? undefined : json['rf_Hykee__Var_CCON'],
        'rfHykeeVarAltriCrediti': !exists(json, 'rf_Hykee__Var_Altri_Crediti') ? undefined : json['rf_Hykee__Var_Altri_Crediti'],
        'rfHykeeVarAltriDebiti': !exists(json, 'rf_Hykee__Var_Altri_Debiti') ? undefined : json['rf_Hykee__Var_Altri_Debiti'],
        'rfHykeeVarFondiETFR': !exists(json, 'rf_Hykee__Var_Fondi_e_TFR') ? undefined : json['rf_Hykee__Var_Fondi_e_TFR'],
        'rfHykeeCashFromOperationCFO': !exists(json, 'rf_Hykee__Cash_From_Operation_CFO') ? undefined : json['rf_Hykee__Cash_From_Operation_CFO'],
        'rfHykeeCapex': !exists(json, 'rf_Hykee__Capex') ? undefined : json['rf_Hykee__Capex'],
        'rfHykeeInvestimentiOnDisinvestimentiFinanziari': !exists(json, 'rf_Hykee__Investimenti_on_Disinvestimenti_Finanziari') ? undefined : json['rf_Hykee__Investimenti_on_Disinvestimenti_Finanziari'],
        'rfHykeeFCFO': !exists(json, 'rf_Hykee__FCFO') ? undefined : json['rf_Hykee__FCFO'],
        'rfHykeeProventiEOneriFinanziari': !exists(json, 'rf_Hykee__Proventi_e_Oneri_finanziari') ? undefined : json['rf_Hykee__Proventi_e_Oneri_finanziari'],
        'rfHykeeFreeCashFlowToEquityFCFE': !exists(json, 'rf_Hykee__Free_Cash_Flow_to_Equity_FCFE') ? undefined : json['rf_Hykee__Free_Cash_Flow_to_Equity_FCFE'],
        'rfHykeeVarPatrimonioNetto': !exists(json, 'rf_Hykee__Var_Patrimonio_Netto') ? undefined : json['rf_Hykee__Var_Patrimonio_Netto'],
        'rfHykeeFCFEAdjustedDeltaPFN': !exists(json, 'rf_Hykee__FCFE_Adjusted_delta_PFN') ? undefined : json['rf_Hykee__FCFE_Adjusted_delta_PFN'],
        'rfHykeeVarDebitiFinanziari': !exists(json, 'rf_Hykee__Var_Debiti_finanziari') ? undefined : json['rf_Hykee__Var_Debiti_finanziari'],
        'rfHykeeVarAttivitaFinCorrenti': !exists(json, 'rf_Hykee__Var_Attivita_fin_correnti') ? undefined : json['rf_Hykee__Var_Attivita_fin_correnti'],
        'rfHykeeFreeCashFlowDeltaDisponibilitaLiquide': !exists(json, 'rf_Hykee__Free_Cash_Flow_delta_Disponibilita_liquide') ? undefined : json['rf_Hykee__Free_Cash_Flow_delta_Disponibilita_liquide'],
        'rfHykeeCheckRf': !exists(json, 'rf_Hykee__Check_Rf') ? undefined : json['rf_Hykee__Check_Rf'],
        'ratioHykee2YoYRicavi': !exists(json, 'ratio_Hykee__2_YoY_Ricavi') ? undefined : json['ratio_Hykee__2_YoY_Ricavi'],
        'ratioHykee2YoYVP': !exists(json, 'ratio_Hykee__2_YoY_VP') ? undefined : json['ratio_Hykee__2_YoY_VP'],
        'ratioHykeeEbitdaPERCENTAGE': !exists(json, 'ratio_Hykee__EbitdaPERCENTAGE') ? undefined : json['ratio_Hykee__EbitdaPERCENTAGE'],
        'ratioHykeeEbitPERCENTAGE': !exists(json, 'ratio_Hykee__EbitPERCENTAGE') ? undefined : json['ratio_Hykee__EbitPERCENTAGE'],
        'ratioHykeeEbitdaAdjPERCENTAGE': !exists(json, 'ratio_Hykee__Ebitda_Adj_PERCENTAGE') ? undefined : json['ratio_Hykee__Ebitda_Adj_PERCENTAGE'],
        'ratioHykeeEbitAdjPERCENTAGE': !exists(json, 'ratio_Hykee__Ebit_Adj_PERCENTAGE') ? undefined : json['ratio_Hykee__Ebit_Adj_PERCENTAGE'],
        'ratioHykeeUtileOnPerditaPERCENTAGE': !exists(json, 'ratio_Hykee__Utile_on_PerditaPERCENTAGE') ? undefined : json['ratio_Hykee__Utile_on_PerditaPERCENTAGE'],
        'ratioHykeeCapexOnRicavi': !exists(json, 'ratio_Hykee__Capex_on_Ricavi') ? undefined : json['ratio_Hykee__Capex_on_Ricavi'],
        'ratioHykeeROCE': !exists(json, 'ratio_Hykee__ROCE') ? undefined : json['ratio_Hykee__ROCE'],
        'ratioHykeePFNOnEBITDA': !exists(json, 'ratio_Hykee__PFN_on_EBITDA') ? undefined : json['ratio_Hykee__PFN_on_EBITDA'],
        'ratioHykeePFNOnEBITDAAdj': !exists(json, 'ratio_Hykee__PFN_on_EBITDA_Adj') ? undefined : json['ratio_Hykee__PFN_on_EBITDA_Adj'],
        'ratioHykeeGrossDebtOnPN': !exists(json, 'ratio_Hykee__Gross_Debt_on_PN') ? undefined : json['ratio_Hykee__Gross_Debt_on_PN'],
        'ratioHykeeCCONOnRICAVIPERCENTAGE': !exists(json, 'ratio_Hykee__CCON_on_RICAVI_PERCENTAGE') ? undefined : json['ratio_Hykee__CCON_on_RICAVI_PERCENTAGE'],
        'ratioHykeeDSO': !exists(json, 'ratio_Hykee__DSO') ? undefined : json['ratio_Hykee__DSO'],
        'ratioHykeeDPOWCapex': !exists(json, 'ratio_Hykee__DPO_w_Capex') ? undefined : json['ratio_Hykee__DPO_w_Capex'],
        'ratioHykeeDSI': !exists(json, 'ratio_Hykee__DSI') ? undefined : json['ratio_Hykee__DSI'],
        'ratioHykeeFCCR': !exists(json, 'ratio_Hykee__FCCR') ? undefined : json['ratio_Hykee__FCCR'],
        'ratioHykeeDSCR': !exists(json, 'ratio_Hykee__DSCR') ? undefined : json['ratio_Hykee__DSCR'],
        'getCashConversionCycle': !exists(json, 'get___Cash_Conversion_Cycle') ? undefined : json['get___Cash_Conversion_Cycle'],
        'ratioHykeeDPOWithoutCapex': !exists(json, 'ratio_Hykee__DPO_without_Capex') ? undefined : json['ratio_Hykee__DPO_without_Capex'],
        'getGrossDebtOnEBITDA': !exists(json, 'get___Gross_Debt_on_EBITDA') ? undefined : json['get___Gross_Debt_on_EBITDA'],
        'getFCFOOnGROSSDEBT': !exists(json, 'get___FCFO_on_GROSS_DEBT') ? undefined : json['get___FCFO_on_GROSS_DEBT'],
        'getFCFOOnEBITDAExclSvalFin': !exists(json, 'get___FCFO_on_EBITDA__excl_Sval_fin__') ? undefined : json['get___FCFO_on_EBITDA__excl_Sval_fin__'],
        'getOneriFinanziariImplicitiSoloSuBanche': !exists(json, 'get___Oneri_Finanziari_Impliciti__Solo_su_Banche_') ? undefined : json['get___Oneri_Finanziari_Impliciti__Solo_su_Banche_'],
        'rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni': !exists(json, 'rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni') ? undefined : json['rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni'],
        'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo': !exists(json, 'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo') ? undefined : json['debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo'],
        'debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito': !exists(json, 'debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito') ? undefined : json['debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito'],
        'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo': !exists(json, 'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo') ? undefined : json['debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo'],
        'valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi': !exists(json, 'valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi') ? undefined : json['valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi'],
        'costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci': !exists(json, 'costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci') ? undefined : json['costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci'],
        'costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni': !exists(json, 'costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni') ? undefined : json['costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni'],
        'creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate': !exists(json, 'creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate') ? undefined : json['creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate'],
        'creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate': !exists(json, 'creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate') ? undefined : json['creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate'],
        'debitiDebitiVersoControllantiTotaleDebitiVersoControllanti': !exists(json, 'debitiDebitiVersoControllantiTotaleDebitiVersoControllanti') ? undefined : json['debitiDebitiVersoControllantiTotaleDebitiVersoControllanti'],
        'debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate': !exists(json, 'debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate') ? undefined : json['debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate'],
        'debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate': !exists(json, 'debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate') ? undefined : json['debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate'],
        'debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori': !exists(json, 'debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori') ? undefined : json['debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori'],
        'debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti': !exists(json, 'debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti') ? undefined : json['debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti'],
        'debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili': !exists(json, 'debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili') ? undefined : json['debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili'],
        'totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni': !exists(json, 'totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni') ? undefined : json['totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni'],
        'proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH': !exists(json, 'proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH') ? undefined : json['proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH'],
        'imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate': !exists(json, 'imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate') ? undefined : json['imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate'],
        'creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti': !exists(json, 'creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti') ? undefined : json['creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti'],
        'debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti': !exists(json, 'debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti') ? undefined : json['debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti'],
        'maxReference': !exists(json, 'maxReference') ? undefined : json['maxReference'],
        'positiveReference': !exists(json, 'positiveReference') ? undefined : json['positiveReference'],
        'negativeReference': !exists(json, 'negativeReference') ? undefined : json['negativeReference'],
        'totaleAttivo': !exists(json, 'totaleAttivo') ? undefined : json['totaleAttivo'],
        'cashReference': !exists(json, 'cashReference') ? undefined : json['cashReference'],
        'totaleValoreProduzione': !exists(json, 'totaleValoreProduzione') ? undefined : json['totaleValoreProduzione'],
        'totalePassivo': !exists(json, 'totalePassivo') ? undefined : json['totalePassivo'],
        'totaleRimanenze': !exists(json, 'totaleRimanenze') ? undefined : json['totaleRimanenze'],
        'costiProduzioneServizi': !exists(json, 'costiProduzioneServizi') ? undefined : json['costiProduzioneServizi'],
        'passivoRateiRisconti': !exists(json, 'passivoRateiRisconti') ? undefined : json['passivoRateiRisconti'],
        'attivoRateiRisconti': !exists(json, 'attivoRateiRisconti') ? undefined : json['attivoRateiRisconti'],
        'totaleFondiRischiOneri': !exists(json, 'totaleFondiRischiOneri') ? undefined : json['totaleFondiRischiOneri'],
        'utilePerditaEsercizio': !exists(json, 'utilePerditaEsercizio') ? undefined : json['utilePerditaEsercizio'],
        'totalePatrimonioNetto': !exists(json, 'totalePatrimonioNetto') ? undefined : json['totalePatrimonioNetto'],
        'patrimonioNettoCapitale': !exists(json, 'patrimonioNettoCapitale') ? undefined : json['patrimonioNettoCapitale'],
        'costiProduzioneGodimentoBeniTerzi': !exists(json, 'costiProduzioneGodimentoBeniTerzi') ? undefined : json['costiProduzioneGodimentoBeniTerzi'],
        'costiProduzioneOneriDiversiGestione': !exists(json, 'costiProduzioneOneriDiversiGestione') ? undefined : json['costiProduzioneOneriDiversiGestione'],
        'costiProduzioneAccantonamentiRischi': !exists(json, 'costiProduzioneAccantonamentiRischi') ? undefined : json['costiProduzioneAccantonamentiRischi'],
        'costiProduzioneAltriAccantonamenti': !exists(json, 'costiProduzioneAltriAccantonamenti') ? undefined : json['costiProduzioneAltriAccantonamenti'],
        'totaleImmobilizzazioniMateriali': !exists(json, 'totaleImmobilizzazioniMateriali') ? undefined : json['totaleImmobilizzazioniMateriali'],
        'totaleImmobilizzazioniFinanziarie': !exists(json, 'totaleImmobilizzazioniFinanziarie') ? undefined : json['totaleImmobilizzazioniFinanziarie'],
        'totaleImmobilizzazioniImmateriali': !exists(json, 'totaleImmobilizzazioniImmateriali') ? undefined : json['totaleImmobilizzazioniImmateriali'],
        'debitiAltriDebitiTotaleAltriDebiti': !exists(json, 'debitiAltriDebitiTotaleAltriDebiti') ? undefined : json['debitiAltriDebitiTotaleAltriDebiti'],
        'debitiAccontiTotaleAcconti': !exists(json, 'debitiAccontiTotaleAcconti') ? undefined : json['debitiAccontiTotaleAcconti'],
        'debitiObbligazioniTotaleObbligazioni': !exists(json, 'debitiObbligazioniTotaleObbligazioni') ? undefined : json['debitiObbligazioniTotaleObbligazioni'],
        'totaleDisponibilitaLiquide': !exists(json, 'totaleDisponibilitaLiquide') ? undefined : json['totaleDisponibilitaLiquide'],
        'totaleProventiOneriFinanziari': !exists(json, 'totaleProventiOneriFinanziari') ? undefined : json['totaleProventiOneriFinanziari'],
        'debitiEsigibiliEntroEsercizioSuccessivo': !exists(json, 'debitiEsigibiliEntroEsercizioSuccessivo') ? undefined : json['debitiEsigibiliEntroEsercizioSuccessivo'],
        'debitiEsigibiliOltreEsercizioSuccessivo': !exists(json, 'debitiEsigibiliOltreEsercizioSuccessivo') ? undefined : json['debitiEsigibiliOltreEsercizioSuccessivo'],
        'valoreProduzioneRicaviVenditePrestazioni': !exists(json, 'valoreProduzioneRicaviVenditePrestazioni') ? undefined : json['valoreProduzioneRicaviVenditePrestazioni'],
        'costiProduzioneMateriePrimeSussidiarieConsumoMerci': !exists(json, 'costiProduzioneMateriePrimeSussidiarieConsumoMerci') ? undefined : json['costiProduzioneMateriePrimeSussidiarieConsumoMerci'],
        'costiProduzionePersonaleTotaleCostiPersonale': !exists(json, 'costiProduzionePersonaleTotaleCostiPersonale') ? undefined : json['costiProduzionePersonaleTotaleCostiPersonale'],
        'totaleRettificheValoreAttivitaPassivitaFinanziarie': !exists(json, 'totaleRettificheValoreAttivitaPassivitaFinanziarie') ? undefined : json['totaleRettificheValoreAttivitaPassivitaFinanziarie'],
        'creditiVersoClientiTotaleCreditiVersoClienti': !exists(json, 'creditiVersoClientiTotaleCreditiVersoClienti') ? undefined : json['creditiVersoClientiTotaleCreditiVersoClienti'],
        'debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori': !exists(json, 'debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori') ? undefined : json['debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori'],
        'immobilizzazioniMaterialiDestinateAllaVendita': !exists(json, 'immobilizzazioniMaterialiDestinateAllaVendita') ? undefined : json['immobilizzazioniMaterialiDestinateAllaVendita'],
        'creditiVersoControllantiTotaleCreditiVersoControllanti': !exists(json, 'creditiVersoControllantiTotaleCreditiVersoControllanti') ? undefined : json['creditiVersoControllantiTotaleCreditiVersoControllanti'],
        'creditiCreditiTributariTotaleCreditiTributari': !exists(json, 'creditiCreditiTributariTotaleCreditiTributari') ? undefined : json['creditiCreditiTributariTotaleCreditiTributari'],
        'creditiImposteAnticipateTotaleImposteAnticipate': !exists(json, 'creditiImposteAnticipateTotaleImposteAnticipate') ? undefined : json['creditiImposteAnticipateTotaleImposteAnticipate'],
        'creditiVersoAltriTotaleCreditiVersoAltri': !exists(json, 'creditiVersoAltriTotaleCreditiVersoAltri') ? undefined : json['creditiVersoAltriTotaleCreditiVersoAltri'],
        'debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo': !exists(json, 'debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo') ? undefined : json['debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo'],
        'trattamentoFineRapportoLavoroSubordinato': !exists(json, 'trattamentoFineRapportoLavoroSubordinato') ? undefined : json['trattamentoFineRapportoLavoroSubordinato'],
        'totaleCreditiVersoSociVersamentiAncoraDovuti': !exists(json, 'totaleCreditiVersoSociVersamentiAncoraDovuti') ? undefined : json['totaleCreditiVersoSociVersamentiAncoraDovuti'],
        'debitiDebitiVersoBancheTotaleDebitiVersoBanche': !exists(json, 'debitiDebitiVersoBancheTotaleDebitiVersoBanche') ? undefined : json['debitiDebitiVersoBancheTotaleDebitiVersoBanche'],
        'debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo': !exists(json, 'debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo') ? undefined : json['debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo'],
        'immobilizzazioniMaterialiTerreniFabbricati': !exists(json, 'immobilizzazioniMaterialiTerreniFabbricati') ? undefined : json['immobilizzazioniMaterialiTerreniFabbricati'],
        'creditiEsigibiliEntroEsercizioSuccessivo': !exists(json, 'creditiEsigibiliEntroEsercizioSuccessivo') ? undefined : json['creditiEsigibiliEntroEsercizioSuccessivo'],
        'creditiEsigibiliOltreEsercizioSuccessivo': !exists(json, 'creditiEsigibiliOltreEsercizioSuccessivo') ? undefined : json['creditiEsigibiliOltreEsercizioSuccessivo'],
        'debitiEsigibiliEntroEsercizioSuccessivoNegative': !exists(json, 'debitiEsigibiliEntroEsercizioSuccessivoNegative') ? undefined : json['debitiEsigibiliEntroEsercizioSuccessivoNegative'],
        'debitiEsigibiliOltreEsercizioSuccessivoNegative': !exists(json, 'debitiEsigibiliOltreEsercizioSuccessivoNegative') ? undefined : json['debitiEsigibiliOltreEsercizioSuccessivoNegative'],
    };
}

export function BalanceXbrlDTOToJSON(value?: BalanceXbrlDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anno': value.anno,
        'type': value.type,
        'currentMoreFieldsState': value.currentMoreFieldsState,
        'ce_Hykee__Ricavi': value.ceHykeeRicavi,
        'ce_Hykee__Valore_della_produzione': value.ceHykeeValoreDellaProduzione,
        'ce_Hykee__Materie_Prime': value.ceHykeeMateriePrime,
        'ce_Hykee__Servizi': value.ceHykeeServizi,
        'ce_Hykee__Personale': value.ceHykeePersonale,
        'ce_Hykee__Oneri_diversi_di_gestione': value.ceHykeeOneriDiversiDiGestione,
        'ce_Hykee__EBITDA': value.ceHykeeEBITDA,
        'ce_Hykee__EBITDAPERCENTAGE': value.ceHykeeEBITDAPERCENTAGE,
        'ce_Hykee__DA': value.ceHykeeDA,
        'ce_Hykee__EBIT': value.ceHykeeEBIT,
        'ce_Hykee__Oneri_e_proventi_finanziari': value.ceHykeeOneriEProventiFinanziari,
        'ce_Hykee__Rettifiche_finanziarie': value.ceHykeeRettificheFinanziarie,
        'ce_Hykee__EBT': value.ceHykeeEBT,
        'ce_Hykee__Imposte': value.ceHykeeImposte,
        'ce_Hykee__Risultato_esercizio': value.ceHykeeRisultatoEsercizio,
        'ce_Adj_Hykee__EBITDA': value.ceAdjHykeeEBITDA,
        'ce_Adj_Hykee__Adj_Saldo_Altri_Ricavi_on_Oneri_diversi_di_gestione': value.ceAdjHykeeAdjSaldoAltriRicaviOnOneriDiversiDiGestione,
        'ce_Adj_Hykee__EBITDA_Adj': value.ceAdjHykeeEBITDAAdj,
        'ce_Adj_Hykee__DA': value.ceAdjHykeeDA,
        'ce_Adj_Hykee__EBIT_Adj': value.ceAdjHykeeEBITAdj,
        'get___Check_Utile_netto': value.getCheckUtileNetto,
        'sp_Hykee__Immobilizzazioni_Materiali': value.spHykeeImmobilizzazioniMateriali,
        'sp_Hykee__Immobilizzazioni_finanziarie': value.spHykeeImmobilizzazioniFinanziarie,
        'sp_Hykee__Immobilizzazioni_immateriali': value.spHykeeImmobilizzazioniImmateriali,
        'sp_Hykee__Attivo_Fisso': value.spHykeeAttivoFisso,
        'sp_Hykee__Crediti_commerciali': value.spHykeeCreditiCommerciali,
        'sp_Hykee__Debiti_Commerciali': value.spHykeeDebitiCommerciali,
        'sp_Hykee__Rimanenze': value.spHykeeRimanenze,
        'sp_Hykee__CCON': value.spHykeeCCON,
        'sp_Hykee__Altri_Crediti': value.spHykeeAltriCrediti,
        'sp_Hykee__Altri_Debiti': value.spHykeeAltriDebiti,
        'sp_Hykee__Fondi_e_TFR': value.spHykeeFondiETFR,
        'sp_Hykee__CIN': value.spHykeeCIN,
        'sp_Hykee__Patrimonio_Netto': value.spHykeePatrimonioNetto,
        'sp_Hykee__Capitale_Sociale': value.spHykeeCapitaleSociale,
        'sp_Hykee__Debiti_Finanziari': value.spHykeeDebitiFinanziari,
        'sp_Hykee__Liquidita': value.spHykeeLiquidita,
        'sp_Hykee__Attivita_finanziarie': value.spHykeeAttivitaFinanziarie,
        'sp_Hykee__PFN': value.spHykeePFN,
        'sp_Hykee__EplusPFN': value.spHykeeEplusPFN,
        'get___Check_SP': value.getCheckSP,
        'rf_Hykee__EBIT': value.rfHykeeEBIT,
        'rf_Hykee__Ammortamenti_e_Svalutazioni': value.rfHykeeAmmortamentiESvalutazioni,
        'rf_Hykee__Rettifiche_attivita_finanziarie': value.rfHykeeRettificheAttivitaFinanziarie,
        'rf_Hykee__Imposte': value.rfHykeeImposte,
        'rf_Hykee__Funds_from_Operation_FFO': value.rfHykeeFundsFromOperationFFO,
        'rf_Hykee__Var_CCON': value.rfHykeeVarCCON,
        'rf_Hykee__Var_Altri_Crediti': value.rfHykeeVarAltriCrediti,
        'rf_Hykee__Var_Altri_Debiti': value.rfHykeeVarAltriDebiti,
        'rf_Hykee__Var_Fondi_e_TFR': value.rfHykeeVarFondiETFR,
        'rf_Hykee__Cash_From_Operation_CFO': value.rfHykeeCashFromOperationCFO,
        'rf_Hykee__Capex': value.rfHykeeCapex,
        'rf_Hykee__Investimenti_on_Disinvestimenti_Finanziari': value.rfHykeeInvestimentiOnDisinvestimentiFinanziari,
        'rf_Hykee__FCFO': value.rfHykeeFCFO,
        'rf_Hykee__Proventi_e_Oneri_finanziari': value.rfHykeeProventiEOneriFinanziari,
        'rf_Hykee__Free_Cash_Flow_to_Equity_FCFE': value.rfHykeeFreeCashFlowToEquityFCFE,
        'rf_Hykee__Var_Patrimonio_Netto': value.rfHykeeVarPatrimonioNetto,
        'rf_Hykee__FCFE_Adjusted_delta_PFN': value.rfHykeeFCFEAdjustedDeltaPFN,
        'rf_Hykee__Var_Debiti_finanziari': value.rfHykeeVarDebitiFinanziari,
        'rf_Hykee__Var_Attivita_fin_correnti': value.rfHykeeVarAttivitaFinCorrenti,
        'rf_Hykee__Free_Cash_Flow_delta_Disponibilita_liquide': value.rfHykeeFreeCashFlowDeltaDisponibilitaLiquide,
        'rf_Hykee__Check_Rf': value.rfHykeeCheckRf,
        'ratio_Hykee__2_YoY_Ricavi': value.ratioHykee2YoYRicavi,
        'ratio_Hykee__2_YoY_VP': value.ratioHykee2YoYVP,
        'ratio_Hykee__EbitdaPERCENTAGE': value.ratioHykeeEbitdaPERCENTAGE,
        'ratio_Hykee__EbitPERCENTAGE': value.ratioHykeeEbitPERCENTAGE,
        'ratio_Hykee__Ebitda_Adj_PERCENTAGE': value.ratioHykeeEbitdaAdjPERCENTAGE,
        'ratio_Hykee__Ebit_Adj_PERCENTAGE': value.ratioHykeeEbitAdjPERCENTAGE,
        'ratio_Hykee__Utile_on_PerditaPERCENTAGE': value.ratioHykeeUtileOnPerditaPERCENTAGE,
        'ratio_Hykee__Capex_on_Ricavi': value.ratioHykeeCapexOnRicavi,
        'ratio_Hykee__ROCE': value.ratioHykeeROCE,
        'ratio_Hykee__PFN_on_EBITDA': value.ratioHykeePFNOnEBITDA,
        'ratio_Hykee__PFN_on_EBITDA_Adj': value.ratioHykeePFNOnEBITDAAdj,
        'ratio_Hykee__Gross_Debt_on_PN': value.ratioHykeeGrossDebtOnPN,
        'ratio_Hykee__CCON_on_RICAVI_PERCENTAGE': value.ratioHykeeCCONOnRICAVIPERCENTAGE,
        'ratio_Hykee__DSO': value.ratioHykeeDSO,
        'ratio_Hykee__DPO_w_Capex': value.ratioHykeeDPOWCapex,
        'ratio_Hykee__DSI': value.ratioHykeeDSI,
        'ratio_Hykee__FCCR': value.ratioHykeeFCCR,
        'ratio_Hykee__DSCR': value.ratioHykeeDSCR,
        'get___Cash_Conversion_Cycle': value.getCashConversionCycle,
        'ratio_Hykee__DPO_without_Capex': value.ratioHykeeDPOWithoutCapex,
        'get___Gross_Debt_on_EBITDA': value.getGrossDebtOnEBITDA,
        'get___FCFO_on_GROSS_DEBT': value.getFCFOOnGROSSDEBT,
        'get___FCFO_on_EBITDA__excl_Sval_fin__': value.getFCFOOnEBITDAExclSvalFin,
        'get___Oneri_Finanziari_Impliciti__Solo_su_Banche_': value.getOneriFinanziariImplicitiSoloSuBanche,
        'rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni': value.rettificheValoreAttivitaPassivitaFinanziarieSvalutazioniTotaleSvalutazioni,
        'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo': value.debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliEntroEsercizioSuccessivo,
        'debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito': value.debitiDebitiRappresentatiTitoliCreditoTotaleDebitiRappresentatiTitoliCredito,
        'debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo': value.debitiDebitiVersoIstitutiPrevidenzaSicurezzaSocialeEsigibiliOltreEsercizioSuccessivo,
        'valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi': value.valoreProduzioneAltriRicaviProventiTotaleAltriRicaviProventi,
        'costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci': value.costiProduzioneVariazioniRimanenzeMateriePrimeSussidiarieConsumoMerci,
        'costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni': value.costiProduzioneAmmortamentiSvalutazioniTotaleAmmortamentiSvalutazioni,
        'creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate': value.creditiVersoImpreseControllateTotaleCreditiVersoImpreseControllate,
        'creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate': value.creditiVersoImpreseCollegateTotaleCreditiVersoImpreseCollegate,
        'debitiDebitiVersoControllantiTotaleDebitiVersoControllanti': value.debitiDebitiVersoControllantiTotaleDebitiVersoControllanti,
        'debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate': value.debitiDebitiVersoImpreseCollegateTotaleDebitiVersoImpreseCollegate,
        'debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate': value.debitiDebitiVersoImpreseControllateTotaleDebitiVersoImpreseControllate,
        'debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori': value.debitiDebitiVersoAltriFinanziatoriTotaleDebitiVersoAltriFinanziatori,
        'debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti': value.debitiDebitiVersoSociFinanziamentiTotaleDebitiVersoSociFinanziamenti,
        'debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili': value.debitiObbligazioniConvertibiliTotaleObbligazioniConvertibili,
        'totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni': value.totaleAttivitaFinanziarieNonCostituisconoImmobilizzazioni,
        'proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH': value.proventiOneriFinanziariProventiPartecipazioniImpreseControllantiSH,
        'imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate': value.imposteRedditoEsercizioCorrentiDifferiteAnticipateTotaleImposteRedditoEsercizioCorrentiDifferiteAnticipate,
        'creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti': value.creditiVersoImpreseSottoposteControlloControllantiTotaleCreditiVersoImpreseSottoposteControlloControllanti,
        'debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti': value.debitiDebitiVersoImpreseSottoposteControlloControllantiTotaleDebitiVersoImpreseSottoposteControlloControllanti,
        'maxReference': value.maxReference,
        'positiveReference': value.positiveReference,
        'negativeReference': value.negativeReference,
        'totaleAttivo': value.totaleAttivo,
        'cashReference': value.cashReference,
        'totaleValoreProduzione': value.totaleValoreProduzione,
        'totalePassivo': value.totalePassivo,
        'totaleRimanenze': value.totaleRimanenze,
        'costiProduzioneServizi': value.costiProduzioneServizi,
        'passivoRateiRisconti': value.passivoRateiRisconti,
        'attivoRateiRisconti': value.attivoRateiRisconti,
        'totaleFondiRischiOneri': value.totaleFondiRischiOneri,
        'utilePerditaEsercizio': value.utilePerditaEsercizio,
        'totalePatrimonioNetto': value.totalePatrimonioNetto,
        'patrimonioNettoCapitale': value.patrimonioNettoCapitale,
        'costiProduzioneGodimentoBeniTerzi': value.costiProduzioneGodimentoBeniTerzi,
        'costiProduzioneOneriDiversiGestione': value.costiProduzioneOneriDiversiGestione,
        'costiProduzioneAccantonamentiRischi': value.costiProduzioneAccantonamentiRischi,
        'costiProduzioneAltriAccantonamenti': value.costiProduzioneAltriAccantonamenti,
        'totaleImmobilizzazioniMateriali': value.totaleImmobilizzazioniMateriali,
        'totaleImmobilizzazioniFinanziarie': value.totaleImmobilizzazioniFinanziarie,
        'totaleImmobilizzazioniImmateriali': value.totaleImmobilizzazioniImmateriali,
        'debitiAltriDebitiTotaleAltriDebiti': value.debitiAltriDebitiTotaleAltriDebiti,
        'debitiAccontiTotaleAcconti': value.debitiAccontiTotaleAcconti,
        'debitiObbligazioniTotaleObbligazioni': value.debitiObbligazioniTotaleObbligazioni,
        'totaleDisponibilitaLiquide': value.totaleDisponibilitaLiquide,
        'totaleProventiOneriFinanziari': value.totaleProventiOneriFinanziari,
        'debitiEsigibiliEntroEsercizioSuccessivo': value.debitiEsigibiliEntroEsercizioSuccessivo,
        'debitiEsigibiliOltreEsercizioSuccessivo': value.debitiEsigibiliOltreEsercizioSuccessivo,
        'valoreProduzioneRicaviVenditePrestazioni': value.valoreProduzioneRicaviVenditePrestazioni,
        'costiProduzioneMateriePrimeSussidiarieConsumoMerci': value.costiProduzioneMateriePrimeSussidiarieConsumoMerci,
        'costiProduzionePersonaleTotaleCostiPersonale': value.costiProduzionePersonaleTotaleCostiPersonale,
        'totaleRettificheValoreAttivitaPassivitaFinanziarie': value.totaleRettificheValoreAttivitaPassivitaFinanziarie,
        'creditiVersoClientiTotaleCreditiVersoClienti': value.creditiVersoClientiTotaleCreditiVersoClienti,
        'debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori': value.debitiDebitiVersoFornitoriTotaleDebitiVersoFornitori,
        'immobilizzazioniMaterialiDestinateAllaVendita': value.immobilizzazioniMaterialiDestinateAllaVendita,
        'creditiVersoControllantiTotaleCreditiVersoControllanti': value.creditiVersoControllantiTotaleCreditiVersoControllanti,
        'creditiCreditiTributariTotaleCreditiTributari': value.creditiCreditiTributariTotaleCreditiTributari,
        'creditiImposteAnticipateTotaleImposteAnticipate': value.creditiImposteAnticipateTotaleImposteAnticipate,
        'creditiVersoAltriTotaleCreditiVersoAltri': value.creditiVersoAltriTotaleCreditiVersoAltri,
        'debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo': value.debitiDebitiTributariEsigibiliEntroEsercizioSuccessivo,
        'trattamentoFineRapportoLavoroSubordinato': value.trattamentoFineRapportoLavoroSubordinato,
        'totaleCreditiVersoSociVersamentiAncoraDovuti': value.totaleCreditiVersoSociVersamentiAncoraDovuti,
        'debitiDebitiVersoBancheTotaleDebitiVersoBanche': value.debitiDebitiVersoBancheTotaleDebitiVersoBanche,
        'debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo': value.debitiDebitiTributariEsigibiliOltreEsercizioSuccessivo,
        'immobilizzazioniMaterialiTerreniFabbricati': value.immobilizzazioniMaterialiTerreniFabbricati,
        'creditiEsigibiliEntroEsercizioSuccessivo': value.creditiEsigibiliEntroEsercizioSuccessivo,
        'creditiEsigibiliOltreEsercizioSuccessivo': value.creditiEsigibiliOltreEsercizioSuccessivo,
        'debitiEsigibiliEntroEsercizioSuccessivoNegative': value.debitiEsigibiliEntroEsercizioSuccessivoNegative,
        'debitiEsigibiliOltreEsercizioSuccessivoNegative': value.debitiEsigibiliOltreEsercizioSuccessivoNegative,
    };
}


