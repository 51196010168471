'use strict';
import { StyleSheet } from 'react-native';
import { BackgroundColorButtonBlueSky, BackgroundColorButtonDarkRed, BackgroundColorButtonGreen, BackgroundColorButtonGreyLight } from './ColorTheme';

export default StyleSheet.create({
    row: {
        flexDirection:'row',
        flex:1,
    },
    containerCollapse: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        height: 'auto',
    },
    containerTextCollapse: {
        backgroundColor: BackgroundColorButtonGreyLight(),
    },
    directionRow:{
        flexDirection:'row',
    },
    directionCol:{
        flexDirection:'column',
    },
    col: {
        flex: 1,
        marginLeft:5,
        flexDirection:'column'
    },
    goBack: {
        paddingVertical: 20,
        paddingRight:30,
        justifyContent:'flex-start'
    },
    paddingIconStar:{
        paddingVertical: 30,
        paddingRight:30,
        justifyContent:'flex-start'
    },
    textAreaInputContainer: {
        height: 220
    },
    mainFont: {
        fontFamily: 'poppins',
    },
    contentTitle: {
        marginTop:50,
        marginHorizontal: 20,
        flexDirection: 'row',
    },
    container: {
        marginTop:20,
        marginHorizontal: 20,
        flexDirection: 'column',        
    },
    scrollViewMH:{
        maxHeight: 310
    },
    containerScroll: {
        marginHorizontal: 20,
        flexDirection: 'column',
    },
    delimiter: {
        marginTop: 20,
        marginBottom: 20,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#505050',
    },
    title: {
        textAlign: 'left',
        color: '#00CC83',
        fontSize: 20,
        fontFamily: 'poppins',
        flexShrink: 1,
        marginVertical: 20
    },
    simplyText: {
        color: 'white',
        fontSize: 16,
        fontFamily: 'poppins',
    },
    simplyTextNoFlex: {
        margin: 15,
        color: 'white',
        fontSize: 16,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    buttonText: {
        color: 'white',
        fontSize: 13,
        fontFamily: 'poppins'
    },
    valueText: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 13,
        color: 'white',
        fontFamily: 'poppins',
        marginTop: 20
    },
    textInput: {
        flex: 0.7,
        backgroundColor: "white", // '#464646',
        padding: 17,
        fontSize: 13,
        marginRight: 5,
        marginVertical: 10,
        color: 'black',
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    borderGradient: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
        marginTop: 10,
    },
    textLabel:{
        alignSelf:'flex-start',
        fontSize:18,
        padding:5,
        fontFamily:'poppins',
        color:'white'
    },
    icon: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignSelf:'flex-end'
    },
    buttonAccept:{
        alignItems:'center',
        backgroundColor: BackgroundColorButtonGreen(),
        borderRadius:10,
    },
    buttonReject:{
        alignItems:'center',
        backgroundColor: BackgroundColorButtonDarkRed(),
        borderRadius:10,
    },
    buttonDetails:{
        alignItems:'center',
        backgroundColor: BackgroundColorButtonBlueSky(),
        borderRadius:10,
    },
});