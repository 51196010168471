/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SoggettoControllante
 */
export interface SoggettoControllante {
    /**
     * 
     * @type {Array<string>}
     * @memberof SoggettoControllante
     */
    note?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    dtCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    dtRiferimento?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    tipoDichiarazione?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    tipoControllo?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    ctipoDichiarazione?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    ctipoControllo?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof SoggettoControllante
     */
    cstato?: string;
}

export function SoggettoControllanteFromJSON(json: any): SoggettoControllante {
    return SoggettoControllanteFromJSONTyped(json, false);
}

export function SoggettoControllanteFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoggettoControllante {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : json['note'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtCostituzione': !exists(json, 'dtCostituzione') ? undefined : json['dtCostituzione'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'dtRiferimento': !exists(json, 'dtRiferimento') ? undefined : json['dtRiferimento'],
        'tipoDichiarazione': !exists(json, 'tipoDichiarazione') ? undefined : json['tipoDichiarazione'],
        'tipoControllo': !exists(json, 'tipoControllo') ? undefined : json['tipoControllo'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'ctipoDichiarazione': !exists(json, 'ctipoDichiarazione') ? undefined : json['ctipoDichiarazione'],
        'ctipoControllo': !exists(json, 'ctipoControllo') ? undefined : json['ctipoControllo'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
    };
}

export function SoggettoControllanteToJSON(value?: SoggettoControllante | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'denominazione': value.denominazione,
        'dtCostituzione': value.dtCostituzione,
        'stato': value.stato,
        'cciaa': value.cciaa,
        'dtRiferimento': value.dtRiferimento,
        'tipoDichiarazione': value.tipoDichiarazione,
        'tipoControllo': value.tipoControllo,
        'cfiscale': value.cfiscale,
        'ctipoDichiarazione': value.ctipoDichiarazione,
        'ctipoControllo': value.ctipoControllo,
        'nrea': value.nrea,
        'cstato': value.cstato,
    };
}


