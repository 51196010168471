/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bilanci,
    BilanciFromJSON,
    BilanciFromJSONTyped,
    BilanciToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocConsultabili
 */
export interface DocConsultabili {
    /**
     * 
     * @type {Bilanci}
     * @memberof DocConsultabili
     */
    bilanci?: Bilanci;
    /**
     * 
     * @type {string}
     * @memberof DocConsultabili
     */
    naltriAtti?: string;
    /**
     * 
     * @type {string}
     * @memberof DocConsultabili
     */
    fesisteStatuto?: string;
}

export function DocConsultabiliFromJSON(json: any): DocConsultabili {
    return DocConsultabiliFromJSONTyped(json, false);
}

export function DocConsultabiliFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocConsultabili {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bilanci': !exists(json, 'bilanci') ? undefined : BilanciFromJSON(json['bilanci']),
        'naltriAtti': !exists(json, 'naltriAtti') ? undefined : json['naltriAtti'],
        'fesisteStatuto': !exists(json, 'fesisteStatuto') ? undefined : json['fesisteStatuto'],
    };
}

export function DocConsultabiliToJSON(value?: DocConsultabili | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bilanci': BilanciToJSON(value.bilanci),
        'naltriAtti': value.naltriAtti,
        'fesisteStatuto': value.fesisteStatuto,
    };
}


