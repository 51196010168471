/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Atti,
    AttiFromJSON,
    AttiFromJSONTyped,
    AttiToJSON,
    Modelli,
    ModelliFromJSON,
    ModelliFromJSONTyped,
    ModelliToJSON,
} from './';

/**
 * 
 * @export
 * @interface Protocollo
 */
export interface Protocollo {
    /**
     * 
     * @type {Modelli}
     * @memberof Protocollo
     */
    modelli?: Modelli;
    /**
     * 
     * @type {Atti}
     * @memberof Protocollo
     */
    atti?: Atti;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    statoAvanzamento?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    statoPratica?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    cciaaIntercamerale?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    dtProtocollo?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    dtUltimaModifica?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    adempimentoComunica?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    entiDestinatari?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    cstatoAvanzamento?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    cstatoPratica?: string;
    /**
     * 
     * @type {string}
     * @memberof Protocollo
     */
    calbo?: string;
}

export function ProtocolloFromJSON(json: any): Protocollo {
    return ProtocolloFromJSONTyped(json, false);
}

export function ProtocolloFromJSONTyped(json: any, ignoreDiscriminator: boolean): Protocollo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelli': !exists(json, 'modelli') ? undefined : ModelliFromJSON(json['modelli']),
        'atti': !exists(json, 'atti') ? undefined : AttiFromJSON(json['atti']),
        'statoAvanzamento': !exists(json, 'statoAvanzamento') ? undefined : json['statoAvanzamento'],
        'statoPratica': !exists(json, 'statoPratica') ? undefined : json['statoPratica'],
        'cciaaIntercamerale': !exists(json, 'cciaaIntercamerale') ? undefined : json['cciaaIntercamerale'],
        'dtProtocollo': !exists(json, 'dtProtocollo') ? undefined : json['dtProtocollo'],
        'dtUltimaModifica': !exists(json, 'dtUltimaModifica') ? undefined : json['dtUltimaModifica'],
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'adempimentoComunica': !exists(json, 'adempimentoComunica') ? undefined : json['adempimentoComunica'],
        'entiDestinatari': !exists(json, 'entiDestinatari') ? undefined : json['entiDestinatari'],
        'cstatoAvanzamento': !exists(json, 'cstatoAvanzamento') ? undefined : json['cstatoAvanzamento'],
        'cstatoPratica': !exists(json, 'cstatoPratica') ? undefined : json['cstatoPratica'],
        'calbo': !exists(json, 'calbo') ? undefined : json['calbo'],
    };
}

export function ProtocolloToJSON(value?: Protocollo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelli': ModelliToJSON(value.modelli),
        'atti': AttiToJSON(value.atti),
        'statoAvanzamento': value.statoAvanzamento,
        'statoPratica': value.statoPratica,
        'cciaaIntercamerale': value.cciaaIntercamerale,
        'dtProtocollo': value.dtProtocollo,
        'dtUltimaModifica': value.dtUltimaModifica,
        'anno': value.anno,
        'n': value.n,
        'adempimentoComunica': value.adempimentoComunica,
        'entiDestinatari': value.entiDestinatari,
        'cstatoAvanzamento': value.cstatoAvanzamento,
        'cstatoPratica': value.cstatoPratica,
        'calbo': value.calbo,
    };
}


