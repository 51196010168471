import { customScoreResourceApi } from "@services/apis/ApiConfiguration";
import { CompanyAddress, CompanyAteco, CompanyData, CompanyDataFullDTO } from "@services/apis/generated";
import { LinearGradient } from "expo-linear-gradient";
import { values } from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import { StyleSheet, View, Text } from "react-native";
import SharedContext from "../../../navigation/SharedContext";
import { i18n } from "../../../i18n/i18n";
import * as Localization from 'expo-localization';
import { useFocusEffect } from "@react-navigation/native";
import { BalanceObj } from "../../../../types";



export default function BusinessData(props: {lastCompanyData: CompanyData, balanceObject: BalanceObj}) {

    const sharedContext = React.useContext(SharedContext);
    const [mainAddress, setMainAddress] = React.useState<CompanyAddress>();
    const [atecoMain, setAtecoMain] = React.useState<CompanyAteco[]>();
    const componentMounted = useRef(true);
    const [revenue, setRevenue] = React.useState<any>();
    const [lastBalanceDate, setLastBalanceDate] = React.useState<any>();

    const formatter = new Intl.NumberFormat(Localization.locale)

    useEffect(() => {
        if(props.lastCompanyData)
            getMainAddress()

        if(props.balanceObject)
            getHykeeRevenue()
        
    }, [props.balanceObject, props.lastCompanyData])

    function getMainAddress() {
        if (componentMounted.current && props.lastCompanyData) {
            let companyAddressesArray = props.lastCompanyData.companyAddresses as unknown as CompanyAddress[];
            if (companyAddressesArray && companyAddressesArray.length > 0) {
                let mainAddress = companyAddressesArray.find((company) => company.isMainAddress == true);
                setMainAddress(mainAddress);
                let companyAteco = mainAddress?.companyAtecos! as unknown as CompanyAteco[];
                setAtecoMain(companyAteco!);
            }
        }
    }


    const getHykeeRevenue = () => {
        if(props.balanceObject){
            if (!props.balanceObject.lastBalance?.id || !customScoreResourceApi) {
                setRevenue("N/A");
                setLastBalanceDate("N/A");
                return;
            }
    
            if (props.balanceObject.lastBalance.company?.id == sharedContext.selectedCompany?.id) {
                setLastBalanceDate("N/A")
                setRevenue("N/A")
                customScoreResourceApi.getFinancialData({ id: props.balanceObject.lastBalance?.id! }).then((res) => {
    
                    setLastBalanceDate(props.balanceObject.lastBalance?.year)
    
                    let revenue = res['ceHykeeRicavi'] ? res['ceHykeeRicavi'] : "N/A";
                    setRevenue(revenue);
                })
            }
        }


    }


    return (
        <LinearGradient
            colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
            locations={[0.4, 0.8]}
            start={{ x: 1, y: 1 }}
            end={{ x: 0, y: 0 }}
            style={styles.borderGradient}
        >
            <LinearGradient
                colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                start={{ x: 1, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={styles.containerBack}
            >
                <View style={styles.column}>
                    <View style={styles.rowText}>
                        <Text style={styles.textLabel}>
                            {i18n.t("screens.company_data.address") + ": "}
                        </Text>
                        {mainAddress &&
                            <Text style={styles.text}>
                                {mainAddress?.streetToponymy + ' ' + mainAddress?.street + ' ' + mainAddress?.streetNumber}
                            </Text>
                        }
                    </View>
                    <View style={{ flex: 1 }}></View>
                    <View style={styles.rowText}>
                        <Text style={styles.textLabel}>
                            {i18n.t("screens.company_data.city") + ": "}
                        </Text>
                        {mainAddress &&
                            <Text style={styles.text}>
                                {mainAddress?.city}
                            </Text>
                        }
                    </View>
                </View>
                {atecoMain && atecoMain?.map((ateco, idx) => {
                    return (
                        <View key={idx} style={styles.column}>
                            <View style={styles.rowText}>
                                <Text style={styles.textLabel}>
                                    {i18n.t("screens.company_data.ateco") + ": "}
                                </Text>
                                <Text style={styles.text}>
                                    {ateco?.atecoCodingCode}
                                </Text>
                            </View>
                            <View style={{ flex: 1 }}></View>
                            <View style={styles.rowText}>
                                <Text style={styles.textLabel}>
                                    {i18n.t("screens.company_data.activity") + ": "}
                                </Text>
                                <Text style={styles.text}>
                                    {ateco?.atecoActivityCode}
                                </Text>
                            </View>
                        </View>
                    )
                })
                }
                <View style={styles.column}>
                    <View style={styles.rowText}>
                        <Text style={styles.textLabel}>
                            {i18n.t("screens.company_data.last_balance") + ": "}
                        </Text>
                        {sharedContext.selectedCompanyLastData &&
                            <Text style={styles.text}>
                                {lastBalanceDate ? lastBalanceDate : "--"}
                            </Text>
                        }
                    </View>
                    <View style={{ flex: 1 }}></View>
                    <View style={styles.rowText}>
                        <Text style={styles.textLabel}>
                            {i18n.t("screens.company_data.revenues") + ": "}
                        </Text>
                        {sharedContext.selectedCompanyLastData &&
                            <Text style={styles.text}>
                                {(revenue && !isNaN(revenue)) ? '€ ' + formatter.format(revenue) : 'N/A'}
                            </Text>
                        }
                    </View>
                </View>
            </LinearGradient>
        </LinearGradient>
    );
}
const styles = StyleSheet.create({
    borderGradient: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: 'row',
        overflow: 'hidden'
    },
    row: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-around"
    },
    rowText: {
        flexDirection: 'column',
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        padding: 5,
        fontSize: 10,
        flexWrap: "wrap",

    },
    column: {
        flexDirection: 'column',
        flex: 1,
        marginLeft: 0,
        alignSelf: "flex-start",
        padding: 0,

    },
    textLabel: {
        textAlign: 'left',
        fontWeight: 'bold',
        color: "white",
        fontFamily: "poppins",
        fontSize: 12,

    },
    text: {
        textAlign: 'left',
        color: "white",
        fontFamily: "poppins",
        flexDirection: 'row',
        fontSize: 10,
        width: 60
    },
});

function usePrevious(arg0: { receiveAmount: any; sendAmount: any; }) {
    throw new Error("Function not implemented.");
}
