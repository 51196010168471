/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccreditamentoOdc
 */
export interface AccreditamentoOdc {
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    schemaAccreditamento?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    dtEmissione?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    dtScadenza?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    fileDownload?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    ncertificato?: string;
    /**
     * 
     * @type {string}
     * @memberof AccreditamentoOdc
     */
    cschemaAccreditamento?: string;
}

export function AccreditamentoOdcFromJSON(json: any): AccreditamentoOdc {
    return AccreditamentoOdcFromJSONTyped(json, false);
}

export function AccreditamentoOdcFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccreditamentoOdc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schemaAccreditamento': !exists(json, 'schemaAccreditamento') ? undefined : json['schemaAccreditamento'],
        'dtEmissione': !exists(json, 'dtEmissione') ? undefined : json['dtEmissione'],
        'dtScadenza': !exists(json, 'dtScadenza') ? undefined : json['dtScadenza'],
        'fileDownload': !exists(json, 'fileDownload') ? undefined : json['fileDownload'],
        'ncertificato': !exists(json, 'ncertificato') ? undefined : json['ncertificato'],
        'cschemaAccreditamento': !exists(json, 'cschemaAccreditamento') ? undefined : json['cschemaAccreditamento'],
    };
}

export function AccreditamentoOdcToJSON(value?: AccreditamentoOdc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schemaAccreditamento': value.schemaAccreditamento,
        'dtEmissione': value.dtEmissione,
        'dtScadenza': value.dtScadenza,
        'fileDownload': value.fileDownload,
        'ncertificato': value.ncertificato,
        'cschemaAccreditamento': value.cschemaAccreditamento,
    };
}


