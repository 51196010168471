/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InfoMesi,
    InfoMesiFromJSON,
    InfoMesiFromJSONTyped,
    InfoMesiToJSON,
} from './';

/**
 * 
 * @export
 * @interface DipendentiOrarioLavoro
 */
export interface DipendentiOrarioLavoro {
    /**
     * 
     * @type {InfoMesi}
     * @memberof DipendentiOrarioLavoro
     */
    infoMesi?: InfoMesi;
    /**
     * 
     * @type {string}
     * @memberof DipendentiOrarioLavoro
     */
    orarioLavoro?: string;
}

export function DipendentiOrarioLavoroFromJSON(json: any): DipendentiOrarioLavoro {
    return DipendentiOrarioLavoroFromJSONTyped(json, false);
}

export function DipendentiOrarioLavoroFromJSONTyped(json: any, ignoreDiscriminator: boolean): DipendentiOrarioLavoro {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infoMesi': !exists(json, 'infoMesi') ? undefined : InfoMesiFromJSON(json['infoMesi']),
        'orarioLavoro': !exists(json, 'orarioLavoro') ? undefined : json['orarioLavoro'],
    };
}

export function DipendentiOrarioLavoroToJSON(value?: DipendentiOrarioLavoro | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infoMesi': InfoMesiToJSON(value.infoMesi),
        'orarioLavoro': value.orarioLavoro,
    };
}


