/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BalanceMoreFieldsState,
    BalanceMoreFieldsStateFromJSON,
    BalanceMoreFieldsStateFromJSONTyped,
    BalanceMoreFieldsStateToJSON,
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyBalanceSheet
 */
export interface CompanyBalanceSheet {
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    type?: CompanyBalanceSheetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    progressivoBilancio?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    getkDocFisico?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyBalanceSheet
     */
    dtChiusura?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    codAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    descCodAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    getfRipresentato?: CompanyBalanceSheetGetfRipresentatoEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    descFRipresentato?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    getnPagine?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    origineDoc?: CompanyBalanceSheetOrigineDocEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    descOrigineDoc?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    ice?: CompanyBalanceSheetIceEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    descICE?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    ida?: CompanyBalanceSheetIdaEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    descIDA?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    spHykeeCreditiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    spHykeeDebitiCommerciali?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyBalanceSheet
     */
    spHykeeDebitiFinanziari?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBalanceSheet
     */
    consolidato?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    currentMoreFieldsState?: CompanyBalanceSheetCurrentMoreFieldsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    xbrlFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    xbrlFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    xbrlAsPreviousFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    xbrlAsPreviousFilepath?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    sectionsFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBalanceSheet
     */
    sectionFilepath?: string;
    /**
     * 
     * @type {Company}
     * @memberof CompanyBalanceSheet
     */
    company?: Company;
    /**
     * 
     * @type {Set<BalanceMoreFieldsState>}
     * @memberof CompanyBalanceSheet
     */
    balanceMoreFieldsStates?: Set<BalanceMoreFieldsState>;
}

/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetTypeEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetGetfRipresentatoEnum {
    S = 'S',
    N = 'N'
}/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetOrigineDocEnum {
    C = 'C',
    T = 'T'
}/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetIceEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetIdaEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}/**
* @export
* @enum {string}
*/
export enum CompanyBalanceSheetCurrentMoreFieldsStateEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}

export function CompanyBalanceSheetFromJSON(json: any): CompanyBalanceSheet {
    return CompanyBalanceSheetFromJSONTyped(json, false);
}

export function CompanyBalanceSheetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyBalanceSheet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'progressivoBilancio': !exists(json, 'progressivoBilancio') ? undefined : json['progressivoBilancio'],
        'getkDocFisico': !exists(json, 'getkDocFisico') ? undefined : json['getkDocFisico'],
        'dtChiusura': !exists(json, 'dtChiusura') ? undefined : (new Date(json['dtChiusura'])),
        'codAtto': !exists(json, 'codAtto') ? undefined : json['codAtto'],
        'descCodAtto': !exists(json, 'descCodAtto') ? undefined : json['descCodAtto'],
        'getfRipresentato': !exists(json, 'getfRipresentato') ? undefined : json['getfRipresentato'],
        'descFRipresentato': !exists(json, 'descFRipresentato') ? undefined : json['descFRipresentato'],
        'getnPagine': !exists(json, 'getnPagine') ? undefined : json['getnPagine'],
        'origineDoc': !exists(json, 'origineDoc') ? undefined : json['origineDoc'],
        'descOrigineDoc': !exists(json, 'descOrigineDoc') ? undefined : json['descOrigineDoc'],
        'ice': !exists(json, 'ice') ? undefined : json['ice'],
        'descICE': !exists(json, 'descICE') ? undefined : json['descICE'],
        'ida': !exists(json, 'ida') ? undefined : json['ida'],
        'descIDA': !exists(json, 'descIDA') ? undefined : json['descIDA'],
        'spHykeeCreditiCommerciali': !exists(json, 'spHykeeCreditiCommerciali') ? undefined : json['spHykeeCreditiCommerciali'],
        'spHykeeDebitiCommerciali': !exists(json, 'spHykeeDebitiCommerciali') ? undefined : json['spHykeeDebitiCommerciali'],
        'spHykeeDebitiFinanziari': !exists(json, 'spHykeeDebitiFinanziari') ? undefined : json['spHykeeDebitiFinanziari'],
        'consolidato': !exists(json, 'consolidato') ? undefined : json['consolidato'],
        'currentMoreFieldsState': !exists(json, 'currentMoreFieldsState') ? undefined : json['currentMoreFieldsState'],
        'xbrlFilename': !exists(json, 'xbrlFilename') ? undefined : json['xbrlFilename'],
        'xbrlFilepath': !exists(json, 'xbrlFilepath') ? undefined : json['xbrlFilepath'],
        'xbrlAsPreviousFilename': !exists(json, 'xbrlAsPreviousFilename') ? undefined : json['xbrlAsPreviousFilename'],
        'xbrlAsPreviousFilepath': !exists(json, 'xbrlAsPreviousFilepath') ? undefined : json['xbrlAsPreviousFilepath'],
        'sectionsFilename': !exists(json, 'sectionsFilename') ? undefined : json['sectionsFilename'],
        'sectionFilepath': !exists(json, 'sectionFilepath') ? undefined : json['sectionFilepath'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'balanceMoreFieldsStates': !exists(json, 'balanceMoreFieldsStates') ? undefined : ((json['balanceMoreFieldsStates'] as Array<any>).map(BalanceMoreFieldsStateFromJSON)),
    };
}

export function CompanyBalanceSheetToJSON(value?: CompanyBalanceSheet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'type': value.type,
        'progressivoBilancio': value.progressivoBilancio,
        'getkDocFisico': value.getkDocFisico,
        'dtChiusura': value.dtChiusura === undefined ? undefined : (value.dtChiusura.toISOString()),
        'codAtto': value.codAtto,
        'descCodAtto': value.descCodAtto,
        'getfRipresentato': value.getfRipresentato,
        'descFRipresentato': value.descFRipresentato,
        'getnPagine': value.getnPagine,
        'origineDoc': value.origineDoc,
        'descOrigineDoc': value.descOrigineDoc,
        'ice': value.ice,
        'descICE': value.descICE,
        'ida': value.ida,
        'descIDA': value.descIDA,
        'spHykeeCreditiCommerciali': value.spHykeeCreditiCommerciali,
        'spHykeeDebitiCommerciali': value.spHykeeDebitiCommerciali,
        'spHykeeDebitiFinanziari': value.spHykeeDebitiFinanziari,
        'consolidato': value.consolidato,
        'currentMoreFieldsState': value.currentMoreFieldsState,
        'xbrlFilename': value.xbrlFilename,
        'xbrlFilepath': value.xbrlFilepath,
        'xbrlAsPreviousFilename': value.xbrlAsPreviousFilename,
        'xbrlAsPreviousFilepath': value.xbrlAsPreviousFilepath,
        'sectionsFilename': value.sectionsFilename,
        'sectionFilepath': value.sectionFilepath,
        'company': CompanyToJSON(value.company),
        'balanceMoreFieldsStates': value.balanceMoreFieldsStates === undefined ? undefined : ((value.balanceMoreFieldsStates as Array<any>).map(BalanceMoreFieldsStateToJSON)),
    };
}


