/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Atto
 */
export interface Atto {
    /**
     * 
     * @type {Array<object>}
     * @memberof Atto
     */
    estremiNotariliAndOmologazioneAndRegistrazione?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Atto
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof Atto
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Atto
     */
    descrizioneTipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Atto
     */
    dtAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof Atto
     */
    ctipo?: string;
}

export function AttoFromJSON(json: any): Atto {
    return AttoFromJSONTyped(json, false);
}

export function AttoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Atto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiNotariliAndOmologazioneAndRegistrazione': !exists(json, 'estremiNotariliAndOmologazioneAndRegistrazione') ? undefined : json['estremiNotariliAndOmologazioneAndRegistrazione'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'descrizioneTipo': !exists(json, 'descrizioneTipo') ? undefined : json['descrizioneTipo'],
        'dtAtto': !exists(json, 'dtAtto') ? undefined : json['dtAtto'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function AttoToJSON(value?: Atto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiNotariliAndOmologazioneAndRegistrazione': value.estremiNotariliAndOmologazioneAndRegistrazione,
        'c': value.c,
        'tipo': value.tipo,
        'descrizioneTipo': value.descrizioneTipo,
        'dtAtto': value.dtAtto,
        'ctipo': value.ctipo,
    };
}


