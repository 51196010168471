/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyBalanceSheet,
    CompanyBalanceSheetFromJSON,
    CompanyBalanceSheetToJSON,
} from '../models';

export interface CountCompanyBalanceSheetsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    yearGreaterThan?: number;
    yearLessThan?: number;
    yearGreaterThanOrEqual?: number;
    yearLessThanOrEqual?: number;
    yearEquals?: number;
    yearNotEquals?: number;
    yearSpecified?: boolean;
    yearIn?: Array<number>;
    yearNotIn?: Array<number>;
    typeEquals?: CountCompanyBalanceSheetsTypeEqualsEnum;
    typeNotEquals?: CountCompanyBalanceSheetsTypeNotEqualsEnum;
    typeSpecified?: boolean;
    typeIn?: Array<CountCompanyBalanceSheetsTypeInEnum>;
    typeNotIn?: Array<CountCompanyBalanceSheetsTypeNotInEnum>;
    progressivoBilancioGreaterThan?: number;
    progressivoBilancioLessThan?: number;
    progressivoBilancioGreaterThanOrEqual?: number;
    progressivoBilancioLessThanOrEqual?: number;
    progressivoBilancioEquals?: number;
    progressivoBilancioNotEquals?: number;
    progressivoBilancioSpecified?: boolean;
    progressivoBilancioIn?: Array<number>;
    progressivoBilancioNotIn?: Array<number>;
    kDocFisicoContains?: string;
    kDocFisicoDoesNotContain?: string;
    kDocFisicoEquals?: string;
    kDocFisicoNotEquals?: string;
    kDocFisicoSpecified?: boolean;
    kDocFisicoIn?: Array<string>;
    kDocFisicoNotIn?: Array<string>;
    dtChiusuraGreaterThan?: Date;
    dtChiusuraLessThan?: Date;
    dtChiusuraGreaterThanOrEqual?: Date;
    dtChiusuraLessThanOrEqual?: Date;
    dtChiusuraEquals?: Date;
    dtChiusuraNotEquals?: Date;
    dtChiusuraSpecified?: boolean;
    dtChiusuraIn?: Array<Date>;
    dtChiusuraNotIn?: Array<Date>;
    codAttoContains?: string;
    codAttoDoesNotContain?: string;
    codAttoEquals?: string;
    codAttoNotEquals?: string;
    codAttoSpecified?: boolean;
    codAttoIn?: Array<string>;
    codAttoNotIn?: Array<string>;
    descCodAttoContains?: string;
    descCodAttoDoesNotContain?: string;
    descCodAttoEquals?: string;
    descCodAttoNotEquals?: string;
    descCodAttoSpecified?: boolean;
    descCodAttoIn?: Array<string>;
    descCodAttoNotIn?: Array<string>;
    fRipresentatoEquals?: CountCompanyBalanceSheetsFRipresentatoEqualsEnum;
    fRipresentatoNotEquals?: CountCompanyBalanceSheetsFRipresentatoNotEqualsEnum;
    fRipresentatoSpecified?: boolean;
    fRipresentatoIn?: Array<CountCompanyBalanceSheetsFRipresentatoInEnum>;
    fRipresentatoNotIn?: Array<CountCompanyBalanceSheetsFRipresentatoNotInEnum>;
    descFRipresentatoContains?: string;
    descFRipresentatoDoesNotContain?: string;
    descFRipresentatoEquals?: string;
    descFRipresentatoNotEquals?: string;
    descFRipresentatoSpecified?: boolean;
    descFRipresentatoIn?: Array<string>;
    descFRipresentatoNotIn?: Array<string>;
    nPagineGreaterThan?: number;
    nPagineLessThan?: number;
    nPagineGreaterThanOrEqual?: number;
    nPagineLessThanOrEqual?: number;
    nPagineEquals?: number;
    nPagineNotEquals?: number;
    nPagineSpecified?: boolean;
    nPagineIn?: Array<number>;
    nPagineNotIn?: Array<number>;
    origineDocEquals?: CountCompanyBalanceSheetsOrigineDocEqualsEnum;
    origineDocNotEquals?: CountCompanyBalanceSheetsOrigineDocNotEqualsEnum;
    origineDocSpecified?: boolean;
    origineDocIn?: Array<CountCompanyBalanceSheetsOrigineDocInEnum>;
    origineDocNotIn?: Array<CountCompanyBalanceSheetsOrigineDocNotInEnum>;
    descOrigineDocContains?: string;
    descOrigineDocDoesNotContain?: string;
    descOrigineDocEquals?: string;
    descOrigineDocNotEquals?: string;
    descOrigineDocSpecified?: boolean;
    descOrigineDocIn?: Array<string>;
    descOrigineDocNotIn?: Array<string>;
    iceEquals?: CountCompanyBalanceSheetsIceEqualsEnum;
    iceNotEquals?: CountCompanyBalanceSheetsIceNotEqualsEnum;
    iceSpecified?: boolean;
    iceIn?: Array<CountCompanyBalanceSheetsIceInEnum>;
    iceNotIn?: Array<CountCompanyBalanceSheetsIceNotInEnum>;
    descICEContains?: string;
    descICEDoesNotContain?: string;
    descICEEquals?: string;
    descICENotEquals?: string;
    descICESpecified?: boolean;
    descICEIn?: Array<string>;
    descICENotIn?: Array<string>;
    idaEquals?: CountCompanyBalanceSheetsIdaEqualsEnum;
    idaNotEquals?: CountCompanyBalanceSheetsIdaNotEqualsEnum;
    idaSpecified?: boolean;
    idaIn?: Array<CountCompanyBalanceSheetsIdaInEnum>;
    idaNotIn?: Array<CountCompanyBalanceSheetsIdaNotInEnum>;
    descIDAContains?: string;
    descIDADoesNotContain?: string;
    descIDAEquals?: string;
    descIDANotEquals?: string;
    descIDASpecified?: boolean;
    descIDAIn?: Array<string>;
    descIDANotIn?: Array<string>;
    spHykeeCreditiCommercialiGreaterThan?: number;
    spHykeeCreditiCommercialiLessThan?: number;
    spHykeeCreditiCommercialiGreaterThanOrEqual?: number;
    spHykeeCreditiCommercialiLessThanOrEqual?: number;
    spHykeeCreditiCommercialiEquals?: number;
    spHykeeCreditiCommercialiNotEquals?: number;
    spHykeeCreditiCommercialiSpecified?: boolean;
    spHykeeCreditiCommercialiIn?: Array<number>;
    spHykeeCreditiCommercialiNotIn?: Array<number>;
    spHykeeDebitiCommercialiGreaterThan?: number;
    spHykeeDebitiCommercialiLessThan?: number;
    spHykeeDebitiCommercialiGreaterThanOrEqual?: number;
    spHykeeDebitiCommercialiLessThanOrEqual?: number;
    spHykeeDebitiCommercialiEquals?: number;
    spHykeeDebitiCommercialiNotEquals?: number;
    spHykeeDebitiCommercialiSpecified?: boolean;
    spHykeeDebitiCommercialiIn?: Array<number>;
    spHykeeDebitiCommercialiNotIn?: Array<number>;
    spHykeeDebitiFinanziariGreaterThan?: number;
    spHykeeDebitiFinanziariLessThan?: number;
    spHykeeDebitiFinanziariGreaterThanOrEqual?: number;
    spHykeeDebitiFinanziariLessThanOrEqual?: number;
    spHykeeDebitiFinanziariEquals?: number;
    spHykeeDebitiFinanziariNotEquals?: number;
    spHykeeDebitiFinanziariSpecified?: boolean;
    spHykeeDebitiFinanziariIn?: Array<number>;
    spHykeeDebitiFinanziariNotIn?: Array<number>;
    consolidatoEquals?: boolean;
    consolidatoNotEquals?: boolean;
    consolidatoSpecified?: boolean;
    consolidatoIn?: Array<boolean>;
    consolidatoNotIn?: Array<boolean>;
    currentMoreFieldsStateEquals?: CountCompanyBalanceSheetsCurrentMoreFieldsStateEqualsEnum;
    currentMoreFieldsStateNotEquals?: CountCompanyBalanceSheetsCurrentMoreFieldsStateNotEqualsEnum;
    currentMoreFieldsStateSpecified?: boolean;
    currentMoreFieldsStateIn?: Array<CountCompanyBalanceSheetsCurrentMoreFieldsStateInEnum>;
    currentMoreFieldsStateNotIn?: Array<CountCompanyBalanceSheetsCurrentMoreFieldsStateNotInEnum>;
    xbrlFilenameContains?: string;
    xbrlFilenameDoesNotContain?: string;
    xbrlFilenameEquals?: string;
    xbrlFilenameNotEquals?: string;
    xbrlFilenameSpecified?: boolean;
    xbrlFilenameIn?: Array<string>;
    xbrlFilenameNotIn?: Array<string>;
    xbrlFilepathContains?: string;
    xbrlFilepathDoesNotContain?: string;
    xbrlFilepathEquals?: string;
    xbrlFilepathNotEquals?: string;
    xbrlFilepathSpecified?: boolean;
    xbrlFilepathIn?: Array<string>;
    xbrlFilepathNotIn?: Array<string>;
    xbrlAsPreviousFilenameContains?: string;
    xbrlAsPreviousFilenameDoesNotContain?: string;
    xbrlAsPreviousFilenameEquals?: string;
    xbrlAsPreviousFilenameNotEquals?: string;
    xbrlAsPreviousFilenameSpecified?: boolean;
    xbrlAsPreviousFilenameIn?: Array<string>;
    xbrlAsPreviousFilenameNotIn?: Array<string>;
    xbrlAsPreviousFilepathContains?: string;
    xbrlAsPreviousFilepathDoesNotContain?: string;
    xbrlAsPreviousFilepathEquals?: string;
    xbrlAsPreviousFilepathNotEquals?: string;
    xbrlAsPreviousFilepathSpecified?: boolean;
    xbrlAsPreviousFilepathIn?: Array<string>;
    xbrlAsPreviousFilepathNotIn?: Array<string>;
    sectionsFilenameContains?: string;
    sectionsFilenameDoesNotContain?: string;
    sectionsFilenameEquals?: string;
    sectionsFilenameNotEquals?: string;
    sectionsFilenameSpecified?: boolean;
    sectionsFilenameIn?: Array<string>;
    sectionsFilenameNotIn?: Array<string>;
    sectionFilepathContains?: string;
    sectionFilepathDoesNotContain?: string;
    sectionFilepathEquals?: string;
    sectionFilepathNotEquals?: string;
    sectionFilepathSpecified?: boolean;
    sectionFilepathIn?: Array<string>;
    sectionFilepathNotIn?: Array<string>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    balanceMoreFieldsStateIdGreaterThan?: number;
    balanceMoreFieldsStateIdLessThan?: number;
    balanceMoreFieldsStateIdGreaterThanOrEqual?: number;
    balanceMoreFieldsStateIdLessThanOrEqual?: number;
    balanceMoreFieldsStateIdEquals?: number;
    balanceMoreFieldsStateIdNotEquals?: number;
    balanceMoreFieldsStateIdSpecified?: boolean;
    balanceMoreFieldsStateIdIn?: Array<number>;
    balanceMoreFieldsStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanyBalanceSheetRequest {
    companyBalanceSheet: CompanyBalanceSheet;
}

export interface DeleteCompanyBalanceSheetRequest {
    id: number;
}

export interface GetAllCompanyBalanceSheetsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    yearGreaterThan?: number;
    yearLessThan?: number;
    yearGreaterThanOrEqual?: number;
    yearLessThanOrEqual?: number;
    yearEquals?: number;
    yearNotEquals?: number;
    yearSpecified?: boolean;
    yearIn?: Array<number>;
    yearNotIn?: Array<number>;
    typeEquals?: GetAllCompanyBalanceSheetsTypeEqualsEnum;
    typeNotEquals?: GetAllCompanyBalanceSheetsTypeNotEqualsEnum;
    typeSpecified?: boolean;
    typeIn?: Array<GetAllCompanyBalanceSheetsTypeInEnum>;
    typeNotIn?: Array<GetAllCompanyBalanceSheetsTypeNotInEnum>;
    progressivoBilancioGreaterThan?: number;
    progressivoBilancioLessThan?: number;
    progressivoBilancioGreaterThanOrEqual?: number;
    progressivoBilancioLessThanOrEqual?: number;
    progressivoBilancioEquals?: number;
    progressivoBilancioNotEquals?: number;
    progressivoBilancioSpecified?: boolean;
    progressivoBilancioIn?: Array<number>;
    progressivoBilancioNotIn?: Array<number>;
    kDocFisicoContains?: string;
    kDocFisicoDoesNotContain?: string;
    kDocFisicoEquals?: string;
    kDocFisicoNotEquals?: string;
    kDocFisicoSpecified?: boolean;
    kDocFisicoIn?: Array<string>;
    kDocFisicoNotIn?: Array<string>;
    dtChiusuraGreaterThan?: Date;
    dtChiusuraLessThan?: Date;
    dtChiusuraGreaterThanOrEqual?: Date;
    dtChiusuraLessThanOrEqual?: Date;
    dtChiusuraEquals?: Date;
    dtChiusuraNotEquals?: Date;
    dtChiusuraSpecified?: boolean;
    dtChiusuraIn?: Array<Date>;
    dtChiusuraNotIn?: Array<Date>;
    codAttoContains?: string;
    codAttoDoesNotContain?: string;
    codAttoEquals?: string;
    codAttoNotEquals?: string;
    codAttoSpecified?: boolean;
    codAttoIn?: Array<string>;
    codAttoNotIn?: Array<string>;
    descCodAttoContains?: string;
    descCodAttoDoesNotContain?: string;
    descCodAttoEquals?: string;
    descCodAttoNotEquals?: string;
    descCodAttoSpecified?: boolean;
    descCodAttoIn?: Array<string>;
    descCodAttoNotIn?: Array<string>;
    fRipresentatoEquals?: GetAllCompanyBalanceSheetsFRipresentatoEqualsEnum;
    fRipresentatoNotEquals?: GetAllCompanyBalanceSheetsFRipresentatoNotEqualsEnum;
    fRipresentatoSpecified?: boolean;
    fRipresentatoIn?: Array<GetAllCompanyBalanceSheetsFRipresentatoInEnum>;
    fRipresentatoNotIn?: Array<GetAllCompanyBalanceSheetsFRipresentatoNotInEnum>;
    descFRipresentatoContains?: string;
    descFRipresentatoDoesNotContain?: string;
    descFRipresentatoEquals?: string;
    descFRipresentatoNotEquals?: string;
    descFRipresentatoSpecified?: boolean;
    descFRipresentatoIn?: Array<string>;
    descFRipresentatoNotIn?: Array<string>;
    nPagineGreaterThan?: number;
    nPagineLessThan?: number;
    nPagineGreaterThanOrEqual?: number;
    nPagineLessThanOrEqual?: number;
    nPagineEquals?: number;
    nPagineNotEquals?: number;
    nPagineSpecified?: boolean;
    nPagineIn?: Array<number>;
    nPagineNotIn?: Array<number>;
    origineDocEquals?: GetAllCompanyBalanceSheetsOrigineDocEqualsEnum;
    origineDocNotEquals?: GetAllCompanyBalanceSheetsOrigineDocNotEqualsEnum;
    origineDocSpecified?: boolean;
    origineDocIn?: Array<GetAllCompanyBalanceSheetsOrigineDocInEnum>;
    origineDocNotIn?: Array<GetAllCompanyBalanceSheetsOrigineDocNotInEnum>;
    descOrigineDocContains?: string;
    descOrigineDocDoesNotContain?: string;
    descOrigineDocEquals?: string;
    descOrigineDocNotEquals?: string;
    descOrigineDocSpecified?: boolean;
    descOrigineDocIn?: Array<string>;
    descOrigineDocNotIn?: Array<string>;
    iceEquals?: GetAllCompanyBalanceSheetsIceEqualsEnum;
    iceNotEquals?: GetAllCompanyBalanceSheetsIceNotEqualsEnum;
    iceSpecified?: boolean;
    iceIn?: Array<GetAllCompanyBalanceSheetsIceInEnum>;
    iceNotIn?: Array<GetAllCompanyBalanceSheetsIceNotInEnum>;
    descICEContains?: string;
    descICEDoesNotContain?: string;
    descICEEquals?: string;
    descICENotEquals?: string;
    descICESpecified?: boolean;
    descICEIn?: Array<string>;
    descICENotIn?: Array<string>;
    idaEquals?: GetAllCompanyBalanceSheetsIdaEqualsEnum;
    idaNotEquals?: GetAllCompanyBalanceSheetsIdaNotEqualsEnum;
    idaSpecified?: boolean;
    idaIn?: Array<GetAllCompanyBalanceSheetsIdaInEnum>;
    idaNotIn?: Array<GetAllCompanyBalanceSheetsIdaNotInEnum>;
    descIDAContains?: string;
    descIDADoesNotContain?: string;
    descIDAEquals?: string;
    descIDANotEquals?: string;
    descIDASpecified?: boolean;
    descIDAIn?: Array<string>;
    descIDANotIn?: Array<string>;
    spHykeeCreditiCommercialiGreaterThan?: number;
    spHykeeCreditiCommercialiLessThan?: number;
    spHykeeCreditiCommercialiGreaterThanOrEqual?: number;
    spHykeeCreditiCommercialiLessThanOrEqual?: number;
    spHykeeCreditiCommercialiEquals?: number;
    spHykeeCreditiCommercialiNotEquals?: number;
    spHykeeCreditiCommercialiSpecified?: boolean;
    spHykeeCreditiCommercialiIn?: Array<number>;
    spHykeeCreditiCommercialiNotIn?: Array<number>;
    spHykeeDebitiCommercialiGreaterThan?: number;
    spHykeeDebitiCommercialiLessThan?: number;
    spHykeeDebitiCommercialiGreaterThanOrEqual?: number;
    spHykeeDebitiCommercialiLessThanOrEqual?: number;
    spHykeeDebitiCommercialiEquals?: number;
    spHykeeDebitiCommercialiNotEquals?: number;
    spHykeeDebitiCommercialiSpecified?: boolean;
    spHykeeDebitiCommercialiIn?: Array<number>;
    spHykeeDebitiCommercialiNotIn?: Array<number>;
    spHykeeDebitiFinanziariGreaterThan?: number;
    spHykeeDebitiFinanziariLessThan?: number;
    spHykeeDebitiFinanziariGreaterThanOrEqual?: number;
    spHykeeDebitiFinanziariLessThanOrEqual?: number;
    spHykeeDebitiFinanziariEquals?: number;
    spHykeeDebitiFinanziariNotEquals?: number;
    spHykeeDebitiFinanziariSpecified?: boolean;
    spHykeeDebitiFinanziariIn?: Array<number>;
    spHykeeDebitiFinanziariNotIn?: Array<number>;
    consolidatoEquals?: boolean;
    consolidatoNotEquals?: boolean;
    consolidatoSpecified?: boolean;
    consolidatoIn?: Array<boolean>;
    consolidatoNotIn?: Array<boolean>;
    currentMoreFieldsStateEquals?: GetAllCompanyBalanceSheetsCurrentMoreFieldsStateEqualsEnum;
    currentMoreFieldsStateNotEquals?: GetAllCompanyBalanceSheetsCurrentMoreFieldsStateNotEqualsEnum;
    currentMoreFieldsStateSpecified?: boolean;
    currentMoreFieldsStateIn?: Array<GetAllCompanyBalanceSheetsCurrentMoreFieldsStateInEnum>;
    currentMoreFieldsStateNotIn?: Array<GetAllCompanyBalanceSheetsCurrentMoreFieldsStateNotInEnum>;
    xbrlFilenameContains?: string;
    xbrlFilenameDoesNotContain?: string;
    xbrlFilenameEquals?: string;
    xbrlFilenameNotEquals?: string;
    xbrlFilenameSpecified?: boolean;
    xbrlFilenameIn?: Array<string>;
    xbrlFilenameNotIn?: Array<string>;
    xbrlFilepathContains?: string;
    xbrlFilepathDoesNotContain?: string;
    xbrlFilepathEquals?: string;
    xbrlFilepathNotEquals?: string;
    xbrlFilepathSpecified?: boolean;
    xbrlFilepathIn?: Array<string>;
    xbrlFilepathNotIn?: Array<string>;
    xbrlAsPreviousFilenameContains?: string;
    xbrlAsPreviousFilenameDoesNotContain?: string;
    xbrlAsPreviousFilenameEquals?: string;
    xbrlAsPreviousFilenameNotEquals?: string;
    xbrlAsPreviousFilenameSpecified?: boolean;
    xbrlAsPreviousFilenameIn?: Array<string>;
    xbrlAsPreviousFilenameNotIn?: Array<string>;
    xbrlAsPreviousFilepathContains?: string;
    xbrlAsPreviousFilepathDoesNotContain?: string;
    xbrlAsPreviousFilepathEquals?: string;
    xbrlAsPreviousFilepathNotEquals?: string;
    xbrlAsPreviousFilepathSpecified?: boolean;
    xbrlAsPreviousFilepathIn?: Array<string>;
    xbrlAsPreviousFilepathNotIn?: Array<string>;
    sectionsFilenameContains?: string;
    sectionsFilenameDoesNotContain?: string;
    sectionsFilenameEquals?: string;
    sectionsFilenameNotEquals?: string;
    sectionsFilenameSpecified?: boolean;
    sectionsFilenameIn?: Array<string>;
    sectionsFilenameNotIn?: Array<string>;
    sectionFilepathContains?: string;
    sectionFilepathDoesNotContain?: string;
    sectionFilepathEquals?: string;
    sectionFilepathNotEquals?: string;
    sectionFilepathSpecified?: boolean;
    sectionFilepathIn?: Array<string>;
    sectionFilepathNotIn?: Array<string>;
    companyIdGreaterThan?: number;
    companyIdLessThan?: number;
    companyIdGreaterThanOrEqual?: number;
    companyIdLessThanOrEqual?: number;
    companyIdEquals?: number;
    companyIdNotEquals?: number;
    companyIdSpecified?: boolean;
    companyIdIn?: Array<number>;
    companyIdNotIn?: Array<number>;
    balanceMoreFieldsStateIdGreaterThan?: number;
    balanceMoreFieldsStateIdLessThan?: number;
    balanceMoreFieldsStateIdGreaterThanOrEqual?: number;
    balanceMoreFieldsStateIdLessThanOrEqual?: number;
    balanceMoreFieldsStateIdEquals?: number;
    balanceMoreFieldsStateIdNotEquals?: number;
    balanceMoreFieldsStateIdSpecified?: boolean;
    balanceMoreFieldsStateIdIn?: Array<number>;
    balanceMoreFieldsStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanyBalanceSheetRequest {
    id: number;
}

export interface PartialUpdateCompanyBalanceSheetRequest {
    id: number;
    companyBalanceSheet: CompanyBalanceSheet;
}

export interface UpdateCompanyBalanceSheetRequest {
    id: number;
    companyBalanceSheet: CompanyBalanceSheet;
}

/**
 * 
 */
export class CompanyBalanceSheetResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanyBalanceSheetsRaw(requestParameters: CountCompanyBalanceSheetsRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.yearGreaterThan !== undefined) {
            queryParameters['year.greaterThan'] = requestParameters.yearGreaterThan;
        }

        if (requestParameters.yearLessThan !== undefined) {
            queryParameters['year.lessThan'] = requestParameters.yearLessThan;
        }

        if (requestParameters.yearGreaterThanOrEqual !== undefined) {
            queryParameters['year.greaterThanOrEqual'] = requestParameters.yearGreaterThanOrEqual;
        }

        if (requestParameters.yearLessThanOrEqual !== undefined) {
            queryParameters['year.lessThanOrEqual'] = requestParameters.yearLessThanOrEqual;
        }

        if (requestParameters.yearEquals !== undefined) {
            queryParameters['year.equals'] = requestParameters.yearEquals;
        }

        if (requestParameters.yearNotEquals !== undefined) {
            queryParameters['year.notEquals'] = requestParameters.yearNotEquals;
        }

        if (requestParameters.yearSpecified !== undefined) {
            queryParameters['year.specified'] = requestParameters.yearSpecified;
        }

        if (requestParameters.yearIn) {
            queryParameters['year.in'] = requestParameters.yearIn;
        }

        if (requestParameters.yearNotIn) {
            queryParameters['year.notIn'] = requestParameters.yearNotIn;
        }

        if (requestParameters.typeEquals !== undefined) {
            queryParameters['type.equals'] = requestParameters.typeEquals;
        }

        if (requestParameters.typeNotEquals !== undefined) {
            queryParameters['type.notEquals'] = requestParameters.typeNotEquals;
        }

        if (requestParameters.typeSpecified !== undefined) {
            queryParameters['type.specified'] = requestParameters.typeSpecified;
        }

        if (requestParameters.typeIn) {
            queryParameters['type.in'] = requestParameters.typeIn;
        }

        if (requestParameters.typeNotIn) {
            queryParameters['type.notIn'] = requestParameters.typeNotIn;
        }

        if (requestParameters.progressivoBilancioGreaterThan !== undefined) {
            queryParameters['progressivoBilancio.greaterThan'] = requestParameters.progressivoBilancioGreaterThan;
        }

        if (requestParameters.progressivoBilancioLessThan !== undefined) {
            queryParameters['progressivoBilancio.lessThan'] = requestParameters.progressivoBilancioLessThan;
        }

        if (requestParameters.progressivoBilancioGreaterThanOrEqual !== undefined) {
            queryParameters['progressivoBilancio.greaterThanOrEqual'] = requestParameters.progressivoBilancioGreaterThanOrEqual;
        }

        if (requestParameters.progressivoBilancioLessThanOrEqual !== undefined) {
            queryParameters['progressivoBilancio.lessThanOrEqual'] = requestParameters.progressivoBilancioLessThanOrEqual;
        }

        if (requestParameters.progressivoBilancioEquals !== undefined) {
            queryParameters['progressivoBilancio.equals'] = requestParameters.progressivoBilancioEquals;
        }

        if (requestParameters.progressivoBilancioNotEquals !== undefined) {
            queryParameters['progressivoBilancio.notEquals'] = requestParameters.progressivoBilancioNotEquals;
        }

        if (requestParameters.progressivoBilancioSpecified !== undefined) {
            queryParameters['progressivoBilancio.specified'] = requestParameters.progressivoBilancioSpecified;
        }

        if (requestParameters.progressivoBilancioIn) {
            queryParameters['progressivoBilancio.in'] = requestParameters.progressivoBilancioIn;
        }

        if (requestParameters.progressivoBilancioNotIn) {
            queryParameters['progressivoBilancio.notIn'] = requestParameters.progressivoBilancioNotIn;
        }

        if (requestParameters.kDocFisicoContains !== undefined) {
            queryParameters['kDocFisico.contains'] = requestParameters.kDocFisicoContains;
        }

        if (requestParameters.kDocFisicoDoesNotContain !== undefined) {
            queryParameters['kDocFisico.doesNotContain'] = requestParameters.kDocFisicoDoesNotContain;
        }

        if (requestParameters.kDocFisicoEquals !== undefined) {
            queryParameters['kDocFisico.equals'] = requestParameters.kDocFisicoEquals;
        }

        if (requestParameters.kDocFisicoNotEquals !== undefined) {
            queryParameters['kDocFisico.notEquals'] = requestParameters.kDocFisicoNotEquals;
        }

        if (requestParameters.kDocFisicoSpecified !== undefined) {
            queryParameters['kDocFisico.specified'] = requestParameters.kDocFisicoSpecified;
        }

        if (requestParameters.kDocFisicoIn) {
            queryParameters['kDocFisico.in'] = requestParameters.kDocFisicoIn;
        }

        if (requestParameters.kDocFisicoNotIn) {
            queryParameters['kDocFisico.notIn'] = requestParameters.kDocFisicoNotIn;
        }

        if (requestParameters.dtChiusuraGreaterThan !== undefined) {
            queryParameters['dtChiusura.greaterThan'] = (requestParameters.dtChiusuraGreaterThan as any).toISOString();
        }

        if (requestParameters.dtChiusuraLessThan !== undefined) {
            queryParameters['dtChiusura.lessThan'] = (requestParameters.dtChiusuraLessThan as any).toISOString();
        }

        if (requestParameters.dtChiusuraGreaterThanOrEqual !== undefined) {
            queryParameters['dtChiusura.greaterThanOrEqual'] = (requestParameters.dtChiusuraGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dtChiusuraLessThanOrEqual !== undefined) {
            queryParameters['dtChiusura.lessThanOrEqual'] = (requestParameters.dtChiusuraLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dtChiusuraEquals !== undefined) {
            queryParameters['dtChiusura.equals'] = (requestParameters.dtChiusuraEquals as any).toISOString();
        }

        if (requestParameters.dtChiusuraNotEquals !== undefined) {
            queryParameters['dtChiusura.notEquals'] = (requestParameters.dtChiusuraNotEquals as any).toISOString();
        }

        if (requestParameters.dtChiusuraSpecified !== undefined) {
            queryParameters['dtChiusura.specified'] = requestParameters.dtChiusuraSpecified;
        }

        if (requestParameters.dtChiusuraIn) {
            queryParameters['dtChiusura.in'] = requestParameters.dtChiusuraIn;
        }

        if (requestParameters.dtChiusuraNotIn) {
            queryParameters['dtChiusura.notIn'] = requestParameters.dtChiusuraNotIn;
        }

        if (requestParameters.codAttoContains !== undefined) {
            queryParameters['codAtto.contains'] = requestParameters.codAttoContains;
        }

        if (requestParameters.codAttoDoesNotContain !== undefined) {
            queryParameters['codAtto.doesNotContain'] = requestParameters.codAttoDoesNotContain;
        }

        if (requestParameters.codAttoEquals !== undefined) {
            queryParameters['codAtto.equals'] = requestParameters.codAttoEquals;
        }

        if (requestParameters.codAttoNotEquals !== undefined) {
            queryParameters['codAtto.notEquals'] = requestParameters.codAttoNotEquals;
        }

        if (requestParameters.codAttoSpecified !== undefined) {
            queryParameters['codAtto.specified'] = requestParameters.codAttoSpecified;
        }

        if (requestParameters.codAttoIn) {
            queryParameters['codAtto.in'] = requestParameters.codAttoIn;
        }

        if (requestParameters.codAttoNotIn) {
            queryParameters['codAtto.notIn'] = requestParameters.codAttoNotIn;
        }

        if (requestParameters.descCodAttoContains !== undefined) {
            queryParameters['descCodAtto.contains'] = requestParameters.descCodAttoContains;
        }

        if (requestParameters.descCodAttoDoesNotContain !== undefined) {
            queryParameters['descCodAtto.doesNotContain'] = requestParameters.descCodAttoDoesNotContain;
        }

        if (requestParameters.descCodAttoEquals !== undefined) {
            queryParameters['descCodAtto.equals'] = requestParameters.descCodAttoEquals;
        }

        if (requestParameters.descCodAttoNotEquals !== undefined) {
            queryParameters['descCodAtto.notEquals'] = requestParameters.descCodAttoNotEquals;
        }

        if (requestParameters.descCodAttoSpecified !== undefined) {
            queryParameters['descCodAtto.specified'] = requestParameters.descCodAttoSpecified;
        }

        if (requestParameters.descCodAttoIn) {
            queryParameters['descCodAtto.in'] = requestParameters.descCodAttoIn;
        }

        if (requestParameters.descCodAttoNotIn) {
            queryParameters['descCodAtto.notIn'] = requestParameters.descCodAttoNotIn;
        }

        if (requestParameters.fRipresentatoEquals !== undefined) {
            queryParameters['fRipresentato.equals'] = requestParameters.fRipresentatoEquals;
        }

        if (requestParameters.fRipresentatoNotEquals !== undefined) {
            queryParameters['fRipresentato.notEquals'] = requestParameters.fRipresentatoNotEquals;
        }

        if (requestParameters.fRipresentatoSpecified !== undefined) {
            queryParameters['fRipresentato.specified'] = requestParameters.fRipresentatoSpecified;
        }

        if (requestParameters.fRipresentatoIn) {
            queryParameters['fRipresentato.in'] = requestParameters.fRipresentatoIn;
        }

        if (requestParameters.fRipresentatoNotIn) {
            queryParameters['fRipresentato.notIn'] = requestParameters.fRipresentatoNotIn;
        }

        if (requestParameters.descFRipresentatoContains !== undefined) {
            queryParameters['descFRipresentato.contains'] = requestParameters.descFRipresentatoContains;
        }

        if (requestParameters.descFRipresentatoDoesNotContain !== undefined) {
            queryParameters['descFRipresentato.doesNotContain'] = requestParameters.descFRipresentatoDoesNotContain;
        }

        if (requestParameters.descFRipresentatoEquals !== undefined) {
            queryParameters['descFRipresentato.equals'] = requestParameters.descFRipresentatoEquals;
        }

        if (requestParameters.descFRipresentatoNotEquals !== undefined) {
            queryParameters['descFRipresentato.notEquals'] = requestParameters.descFRipresentatoNotEquals;
        }

        if (requestParameters.descFRipresentatoSpecified !== undefined) {
            queryParameters['descFRipresentato.specified'] = requestParameters.descFRipresentatoSpecified;
        }

        if (requestParameters.descFRipresentatoIn) {
            queryParameters['descFRipresentato.in'] = requestParameters.descFRipresentatoIn;
        }

        if (requestParameters.descFRipresentatoNotIn) {
            queryParameters['descFRipresentato.notIn'] = requestParameters.descFRipresentatoNotIn;
        }

        if (requestParameters.nPagineGreaterThan !== undefined) {
            queryParameters['nPagine.greaterThan'] = requestParameters.nPagineGreaterThan;
        }

        if (requestParameters.nPagineLessThan !== undefined) {
            queryParameters['nPagine.lessThan'] = requestParameters.nPagineLessThan;
        }

        if (requestParameters.nPagineGreaterThanOrEqual !== undefined) {
            queryParameters['nPagine.greaterThanOrEqual'] = requestParameters.nPagineGreaterThanOrEqual;
        }

        if (requestParameters.nPagineLessThanOrEqual !== undefined) {
            queryParameters['nPagine.lessThanOrEqual'] = requestParameters.nPagineLessThanOrEqual;
        }

        if (requestParameters.nPagineEquals !== undefined) {
            queryParameters['nPagine.equals'] = requestParameters.nPagineEquals;
        }

        if (requestParameters.nPagineNotEquals !== undefined) {
            queryParameters['nPagine.notEquals'] = requestParameters.nPagineNotEquals;
        }

        if (requestParameters.nPagineSpecified !== undefined) {
            queryParameters['nPagine.specified'] = requestParameters.nPagineSpecified;
        }

        if (requestParameters.nPagineIn) {
            queryParameters['nPagine.in'] = requestParameters.nPagineIn;
        }

        if (requestParameters.nPagineNotIn) {
            queryParameters['nPagine.notIn'] = requestParameters.nPagineNotIn;
        }

        if (requestParameters.origineDocEquals !== undefined) {
            queryParameters['origineDoc.equals'] = requestParameters.origineDocEquals;
        }

        if (requestParameters.origineDocNotEquals !== undefined) {
            queryParameters['origineDoc.notEquals'] = requestParameters.origineDocNotEquals;
        }

        if (requestParameters.origineDocSpecified !== undefined) {
            queryParameters['origineDoc.specified'] = requestParameters.origineDocSpecified;
        }

        if (requestParameters.origineDocIn) {
            queryParameters['origineDoc.in'] = requestParameters.origineDocIn;
        }

        if (requestParameters.origineDocNotIn) {
            queryParameters['origineDoc.notIn'] = requestParameters.origineDocNotIn;
        }

        if (requestParameters.descOrigineDocContains !== undefined) {
            queryParameters['descOrigineDoc.contains'] = requestParameters.descOrigineDocContains;
        }

        if (requestParameters.descOrigineDocDoesNotContain !== undefined) {
            queryParameters['descOrigineDoc.doesNotContain'] = requestParameters.descOrigineDocDoesNotContain;
        }

        if (requestParameters.descOrigineDocEquals !== undefined) {
            queryParameters['descOrigineDoc.equals'] = requestParameters.descOrigineDocEquals;
        }

        if (requestParameters.descOrigineDocNotEquals !== undefined) {
            queryParameters['descOrigineDoc.notEquals'] = requestParameters.descOrigineDocNotEquals;
        }

        if (requestParameters.descOrigineDocSpecified !== undefined) {
            queryParameters['descOrigineDoc.specified'] = requestParameters.descOrigineDocSpecified;
        }

        if (requestParameters.descOrigineDocIn) {
            queryParameters['descOrigineDoc.in'] = requestParameters.descOrigineDocIn;
        }

        if (requestParameters.descOrigineDocNotIn) {
            queryParameters['descOrigineDoc.notIn'] = requestParameters.descOrigineDocNotIn;
        }

        if (requestParameters.iceEquals !== undefined) {
            queryParameters['ice.equals'] = requestParameters.iceEquals;
        }

        if (requestParameters.iceNotEquals !== undefined) {
            queryParameters['ice.notEquals'] = requestParameters.iceNotEquals;
        }

        if (requestParameters.iceSpecified !== undefined) {
            queryParameters['ice.specified'] = requestParameters.iceSpecified;
        }

        if (requestParameters.iceIn) {
            queryParameters['ice.in'] = requestParameters.iceIn;
        }

        if (requestParameters.iceNotIn) {
            queryParameters['ice.notIn'] = requestParameters.iceNotIn;
        }

        if (requestParameters.descICEContains !== undefined) {
            queryParameters['descICE.contains'] = requestParameters.descICEContains;
        }

        if (requestParameters.descICEDoesNotContain !== undefined) {
            queryParameters['descICE.doesNotContain'] = requestParameters.descICEDoesNotContain;
        }

        if (requestParameters.descICEEquals !== undefined) {
            queryParameters['descICE.equals'] = requestParameters.descICEEquals;
        }

        if (requestParameters.descICENotEquals !== undefined) {
            queryParameters['descICE.notEquals'] = requestParameters.descICENotEquals;
        }

        if (requestParameters.descICESpecified !== undefined) {
            queryParameters['descICE.specified'] = requestParameters.descICESpecified;
        }

        if (requestParameters.descICEIn) {
            queryParameters['descICE.in'] = requestParameters.descICEIn;
        }

        if (requestParameters.descICENotIn) {
            queryParameters['descICE.notIn'] = requestParameters.descICENotIn;
        }

        if (requestParameters.idaEquals !== undefined) {
            queryParameters['ida.equals'] = requestParameters.idaEquals;
        }

        if (requestParameters.idaNotEquals !== undefined) {
            queryParameters['ida.notEquals'] = requestParameters.idaNotEquals;
        }

        if (requestParameters.idaSpecified !== undefined) {
            queryParameters['ida.specified'] = requestParameters.idaSpecified;
        }

        if (requestParameters.idaIn) {
            queryParameters['ida.in'] = requestParameters.idaIn;
        }

        if (requestParameters.idaNotIn) {
            queryParameters['ida.notIn'] = requestParameters.idaNotIn;
        }

        if (requestParameters.descIDAContains !== undefined) {
            queryParameters['descIDA.contains'] = requestParameters.descIDAContains;
        }

        if (requestParameters.descIDADoesNotContain !== undefined) {
            queryParameters['descIDA.doesNotContain'] = requestParameters.descIDADoesNotContain;
        }

        if (requestParameters.descIDAEquals !== undefined) {
            queryParameters['descIDA.equals'] = requestParameters.descIDAEquals;
        }

        if (requestParameters.descIDANotEquals !== undefined) {
            queryParameters['descIDA.notEquals'] = requestParameters.descIDANotEquals;
        }

        if (requestParameters.descIDASpecified !== undefined) {
            queryParameters['descIDA.specified'] = requestParameters.descIDASpecified;
        }

        if (requestParameters.descIDAIn) {
            queryParameters['descIDA.in'] = requestParameters.descIDAIn;
        }

        if (requestParameters.descIDANotIn) {
            queryParameters['descIDA.notIn'] = requestParameters.descIDANotIn;
        }

        if (requestParameters.spHykeeCreditiCommercialiGreaterThan !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.greaterThan'] = requestParameters.spHykeeCreditiCommercialiGreaterThan;
        }

        if (requestParameters.spHykeeCreditiCommercialiLessThan !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.lessThan'] = requestParameters.spHykeeCreditiCommercialiLessThan;
        }

        if (requestParameters.spHykeeCreditiCommercialiGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.greaterThanOrEqual'] = requestParameters.spHykeeCreditiCommercialiGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeCreditiCommercialiLessThanOrEqual !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.lessThanOrEqual'] = requestParameters.spHykeeCreditiCommercialiLessThanOrEqual;
        }

        if (requestParameters.spHykeeCreditiCommercialiEquals !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.equals'] = requestParameters.spHykeeCreditiCommercialiEquals;
        }

        if (requestParameters.spHykeeCreditiCommercialiNotEquals !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.notEquals'] = requestParameters.spHykeeCreditiCommercialiNotEquals;
        }

        if (requestParameters.spHykeeCreditiCommercialiSpecified !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.specified'] = requestParameters.spHykeeCreditiCommercialiSpecified;
        }

        if (requestParameters.spHykeeCreditiCommercialiIn) {
            queryParameters['spHykeeCreditiCommerciali.in'] = requestParameters.spHykeeCreditiCommercialiIn;
        }

        if (requestParameters.spHykeeCreditiCommercialiNotIn) {
            queryParameters['spHykeeCreditiCommerciali.notIn'] = requestParameters.spHykeeCreditiCommercialiNotIn;
        }

        if (requestParameters.spHykeeDebitiCommercialiGreaterThan !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.greaterThan'] = requestParameters.spHykeeDebitiCommercialiGreaterThan;
        }

        if (requestParameters.spHykeeDebitiCommercialiLessThan !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.lessThan'] = requestParameters.spHykeeDebitiCommercialiLessThan;
        }

        if (requestParameters.spHykeeDebitiCommercialiGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.greaterThanOrEqual'] = requestParameters.spHykeeDebitiCommercialiGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiCommercialiLessThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.lessThanOrEqual'] = requestParameters.spHykeeDebitiCommercialiLessThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiCommercialiEquals !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.equals'] = requestParameters.spHykeeDebitiCommercialiEquals;
        }

        if (requestParameters.spHykeeDebitiCommercialiNotEquals !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.notEquals'] = requestParameters.spHykeeDebitiCommercialiNotEquals;
        }

        if (requestParameters.spHykeeDebitiCommercialiSpecified !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.specified'] = requestParameters.spHykeeDebitiCommercialiSpecified;
        }

        if (requestParameters.spHykeeDebitiCommercialiIn) {
            queryParameters['spHykeeDebitiCommerciali.in'] = requestParameters.spHykeeDebitiCommercialiIn;
        }

        if (requestParameters.spHykeeDebitiCommercialiNotIn) {
            queryParameters['spHykeeDebitiCommerciali.notIn'] = requestParameters.spHykeeDebitiCommercialiNotIn;
        }

        if (requestParameters.spHykeeDebitiFinanziariGreaterThan !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.greaterThan'] = requestParameters.spHykeeDebitiFinanziariGreaterThan;
        }

        if (requestParameters.spHykeeDebitiFinanziariLessThan !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.lessThan'] = requestParameters.spHykeeDebitiFinanziariLessThan;
        }

        if (requestParameters.spHykeeDebitiFinanziariGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.greaterThanOrEqual'] = requestParameters.spHykeeDebitiFinanziariGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiFinanziariLessThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.lessThanOrEqual'] = requestParameters.spHykeeDebitiFinanziariLessThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiFinanziariEquals !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.equals'] = requestParameters.spHykeeDebitiFinanziariEquals;
        }

        if (requestParameters.spHykeeDebitiFinanziariNotEquals !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.notEquals'] = requestParameters.spHykeeDebitiFinanziariNotEquals;
        }

        if (requestParameters.spHykeeDebitiFinanziariSpecified !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.specified'] = requestParameters.spHykeeDebitiFinanziariSpecified;
        }

        if (requestParameters.spHykeeDebitiFinanziariIn) {
            queryParameters['spHykeeDebitiFinanziari.in'] = requestParameters.spHykeeDebitiFinanziariIn;
        }

        if (requestParameters.spHykeeDebitiFinanziariNotIn) {
            queryParameters['spHykeeDebitiFinanziari.notIn'] = requestParameters.spHykeeDebitiFinanziariNotIn;
        }

        if (requestParameters.consolidatoEquals !== undefined) {
            queryParameters['consolidato.equals'] = requestParameters.consolidatoEquals;
        }

        if (requestParameters.consolidatoNotEquals !== undefined) {
            queryParameters['consolidato.notEquals'] = requestParameters.consolidatoNotEquals;
        }

        if (requestParameters.consolidatoSpecified !== undefined) {
            queryParameters['consolidato.specified'] = requestParameters.consolidatoSpecified;
        }

        if (requestParameters.consolidatoIn) {
            queryParameters['consolidato.in'] = requestParameters.consolidatoIn;
        }

        if (requestParameters.consolidatoNotIn) {
            queryParameters['consolidato.notIn'] = requestParameters.consolidatoNotIn;
        }

        if (requestParameters.currentMoreFieldsStateEquals !== undefined) {
            queryParameters['currentMoreFieldsState.equals'] = requestParameters.currentMoreFieldsStateEquals;
        }

        if (requestParameters.currentMoreFieldsStateNotEquals !== undefined) {
            queryParameters['currentMoreFieldsState.notEquals'] = requestParameters.currentMoreFieldsStateNotEquals;
        }

        if (requestParameters.currentMoreFieldsStateSpecified !== undefined) {
            queryParameters['currentMoreFieldsState.specified'] = requestParameters.currentMoreFieldsStateSpecified;
        }

        if (requestParameters.currentMoreFieldsStateIn) {
            queryParameters['currentMoreFieldsState.in'] = requestParameters.currentMoreFieldsStateIn;
        }

        if (requestParameters.currentMoreFieldsStateNotIn) {
            queryParameters['currentMoreFieldsState.notIn'] = requestParameters.currentMoreFieldsStateNotIn;
        }

        if (requestParameters.xbrlFilenameContains !== undefined) {
            queryParameters['xbrlFilename.contains'] = requestParameters.xbrlFilenameContains;
        }

        if (requestParameters.xbrlFilenameDoesNotContain !== undefined) {
            queryParameters['xbrlFilename.doesNotContain'] = requestParameters.xbrlFilenameDoesNotContain;
        }

        if (requestParameters.xbrlFilenameEquals !== undefined) {
            queryParameters['xbrlFilename.equals'] = requestParameters.xbrlFilenameEquals;
        }

        if (requestParameters.xbrlFilenameNotEquals !== undefined) {
            queryParameters['xbrlFilename.notEquals'] = requestParameters.xbrlFilenameNotEquals;
        }

        if (requestParameters.xbrlFilenameSpecified !== undefined) {
            queryParameters['xbrlFilename.specified'] = requestParameters.xbrlFilenameSpecified;
        }

        if (requestParameters.xbrlFilenameIn) {
            queryParameters['xbrlFilename.in'] = requestParameters.xbrlFilenameIn;
        }

        if (requestParameters.xbrlFilenameNotIn) {
            queryParameters['xbrlFilename.notIn'] = requestParameters.xbrlFilenameNotIn;
        }

        if (requestParameters.xbrlFilepathContains !== undefined) {
            queryParameters['xbrlFilepath.contains'] = requestParameters.xbrlFilepathContains;
        }

        if (requestParameters.xbrlFilepathDoesNotContain !== undefined) {
            queryParameters['xbrlFilepath.doesNotContain'] = requestParameters.xbrlFilepathDoesNotContain;
        }

        if (requestParameters.xbrlFilepathEquals !== undefined) {
            queryParameters['xbrlFilepath.equals'] = requestParameters.xbrlFilepathEquals;
        }

        if (requestParameters.xbrlFilepathNotEquals !== undefined) {
            queryParameters['xbrlFilepath.notEquals'] = requestParameters.xbrlFilepathNotEquals;
        }

        if (requestParameters.xbrlFilepathSpecified !== undefined) {
            queryParameters['xbrlFilepath.specified'] = requestParameters.xbrlFilepathSpecified;
        }

        if (requestParameters.xbrlFilepathIn) {
            queryParameters['xbrlFilepath.in'] = requestParameters.xbrlFilepathIn;
        }

        if (requestParameters.xbrlFilepathNotIn) {
            queryParameters['xbrlFilepath.notIn'] = requestParameters.xbrlFilepathNotIn;
        }

        if (requestParameters.xbrlAsPreviousFilenameContains !== undefined) {
            queryParameters['xbrlAsPreviousFilename.contains'] = requestParameters.xbrlAsPreviousFilenameContains;
        }

        if (requestParameters.xbrlAsPreviousFilenameDoesNotContain !== undefined) {
            queryParameters['xbrlAsPreviousFilename.doesNotContain'] = requestParameters.xbrlAsPreviousFilenameDoesNotContain;
        }

        if (requestParameters.xbrlAsPreviousFilenameEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilename.equals'] = requestParameters.xbrlAsPreviousFilenameEquals;
        }

        if (requestParameters.xbrlAsPreviousFilenameNotEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilename.notEquals'] = requestParameters.xbrlAsPreviousFilenameNotEquals;
        }

        if (requestParameters.xbrlAsPreviousFilenameSpecified !== undefined) {
            queryParameters['xbrlAsPreviousFilename.specified'] = requestParameters.xbrlAsPreviousFilenameSpecified;
        }

        if (requestParameters.xbrlAsPreviousFilenameIn) {
            queryParameters['xbrlAsPreviousFilename.in'] = requestParameters.xbrlAsPreviousFilenameIn;
        }

        if (requestParameters.xbrlAsPreviousFilenameNotIn) {
            queryParameters['xbrlAsPreviousFilename.notIn'] = requestParameters.xbrlAsPreviousFilenameNotIn;
        }

        if (requestParameters.xbrlAsPreviousFilepathContains !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.contains'] = requestParameters.xbrlAsPreviousFilepathContains;
        }

        if (requestParameters.xbrlAsPreviousFilepathDoesNotContain !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.doesNotContain'] = requestParameters.xbrlAsPreviousFilepathDoesNotContain;
        }

        if (requestParameters.xbrlAsPreviousFilepathEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.equals'] = requestParameters.xbrlAsPreviousFilepathEquals;
        }

        if (requestParameters.xbrlAsPreviousFilepathNotEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.notEquals'] = requestParameters.xbrlAsPreviousFilepathNotEquals;
        }

        if (requestParameters.xbrlAsPreviousFilepathSpecified !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.specified'] = requestParameters.xbrlAsPreviousFilepathSpecified;
        }

        if (requestParameters.xbrlAsPreviousFilepathIn) {
            queryParameters['xbrlAsPreviousFilepath.in'] = requestParameters.xbrlAsPreviousFilepathIn;
        }

        if (requestParameters.xbrlAsPreviousFilepathNotIn) {
            queryParameters['xbrlAsPreviousFilepath.notIn'] = requestParameters.xbrlAsPreviousFilepathNotIn;
        }

        if (requestParameters.sectionsFilenameContains !== undefined) {
            queryParameters['sectionsFilename.contains'] = requestParameters.sectionsFilenameContains;
        }

        if (requestParameters.sectionsFilenameDoesNotContain !== undefined) {
            queryParameters['sectionsFilename.doesNotContain'] = requestParameters.sectionsFilenameDoesNotContain;
        }

        if (requestParameters.sectionsFilenameEquals !== undefined) {
            queryParameters['sectionsFilename.equals'] = requestParameters.sectionsFilenameEquals;
        }

        if (requestParameters.sectionsFilenameNotEquals !== undefined) {
            queryParameters['sectionsFilename.notEquals'] = requestParameters.sectionsFilenameNotEquals;
        }

        if (requestParameters.sectionsFilenameSpecified !== undefined) {
            queryParameters['sectionsFilename.specified'] = requestParameters.sectionsFilenameSpecified;
        }

        if (requestParameters.sectionsFilenameIn) {
            queryParameters['sectionsFilename.in'] = requestParameters.sectionsFilenameIn;
        }

        if (requestParameters.sectionsFilenameNotIn) {
            queryParameters['sectionsFilename.notIn'] = requestParameters.sectionsFilenameNotIn;
        }

        if (requestParameters.sectionFilepathContains !== undefined) {
            queryParameters['sectionFilepath.contains'] = requestParameters.sectionFilepathContains;
        }

        if (requestParameters.sectionFilepathDoesNotContain !== undefined) {
            queryParameters['sectionFilepath.doesNotContain'] = requestParameters.sectionFilepathDoesNotContain;
        }

        if (requestParameters.sectionFilepathEquals !== undefined) {
            queryParameters['sectionFilepath.equals'] = requestParameters.sectionFilepathEquals;
        }

        if (requestParameters.sectionFilepathNotEquals !== undefined) {
            queryParameters['sectionFilepath.notEquals'] = requestParameters.sectionFilepathNotEquals;
        }

        if (requestParameters.sectionFilepathSpecified !== undefined) {
            queryParameters['sectionFilepath.specified'] = requestParameters.sectionFilepathSpecified;
        }

        if (requestParameters.sectionFilepathIn) {
            queryParameters['sectionFilepath.in'] = requestParameters.sectionFilepathIn;
        }

        if (requestParameters.sectionFilepathNotIn) {
            queryParameters['sectionFilepath.notIn'] = requestParameters.sectionFilepathNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.balanceMoreFieldsStateIdGreaterThan !== undefined) {
            queryParameters['balanceMoreFieldsStateId.greaterThan'] = requestParameters.balanceMoreFieldsStateIdGreaterThan;
        }

        if (requestParameters.balanceMoreFieldsStateIdLessThan !== undefined) {
            queryParameters['balanceMoreFieldsStateId.lessThan'] = requestParameters.balanceMoreFieldsStateIdLessThan;
        }

        if (requestParameters.balanceMoreFieldsStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['balanceMoreFieldsStateId.greaterThanOrEqual'] = requestParameters.balanceMoreFieldsStateIdGreaterThanOrEqual;
        }

        if (requestParameters.balanceMoreFieldsStateIdLessThanOrEqual !== undefined) {
            queryParameters['balanceMoreFieldsStateId.lessThanOrEqual'] = requestParameters.balanceMoreFieldsStateIdLessThanOrEqual;
        }

        if (requestParameters.balanceMoreFieldsStateIdEquals !== undefined) {
            queryParameters['balanceMoreFieldsStateId.equals'] = requestParameters.balanceMoreFieldsStateIdEquals;
        }

        if (requestParameters.balanceMoreFieldsStateIdNotEquals !== undefined) {
            queryParameters['balanceMoreFieldsStateId.notEquals'] = requestParameters.balanceMoreFieldsStateIdNotEquals;
        }

        if (requestParameters.balanceMoreFieldsStateIdSpecified !== undefined) {
            queryParameters['balanceMoreFieldsStateId.specified'] = requestParameters.balanceMoreFieldsStateIdSpecified;
        }

        if (requestParameters.balanceMoreFieldsStateIdIn) {
            queryParameters['balanceMoreFieldsStateId.in'] = requestParameters.balanceMoreFieldsStateIdIn;
        }

        if (requestParameters.balanceMoreFieldsStateIdNotIn) {
            queryParameters['balanceMoreFieldsStateId.notIn'] = requestParameters.balanceMoreFieldsStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-balance-sheets/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanyBalanceSheets(requestParameters: CountCompanyBalanceSheetsRequest): Promise<number> {
        const response = await this.countCompanyBalanceSheetsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanyBalanceSheetRaw(requestParameters: CreateCompanyBalanceSheetRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.companyBalanceSheet === null || requestParameters.companyBalanceSheet === undefined) {
            throw new runtime.RequiredError('companyBalanceSheet','Required parameter requestParameters.companyBalanceSheet was null or undefined when calling createCompanyBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-balance-sheets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBalanceSheetToJSON(requestParameters.companyBalanceSheet),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyBalanceSheet(requestParameters: CreateCompanyBalanceSheetRequest): Promise<CompanyBalanceSheet> {
        const response = await this.createCompanyBalanceSheetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyBalanceSheetRaw(requestParameters: DeleteCompanyBalanceSheetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-balance-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyBalanceSheet(requestParameters: DeleteCompanyBalanceSheetRequest): Promise<void> {
        await this.deleteCompanyBalanceSheetRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyBalanceSheetsRaw(requestParameters: GetAllCompanyBalanceSheetsRequest): Promise<runtime.ApiResponse<Array<CompanyBalanceSheet>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.yearGreaterThan !== undefined) {
            queryParameters['year.greaterThan'] = requestParameters.yearGreaterThan;
        }

        if (requestParameters.yearLessThan !== undefined) {
            queryParameters['year.lessThan'] = requestParameters.yearLessThan;
        }

        if (requestParameters.yearGreaterThanOrEqual !== undefined) {
            queryParameters['year.greaterThanOrEqual'] = requestParameters.yearGreaterThanOrEqual;
        }

        if (requestParameters.yearLessThanOrEqual !== undefined) {
            queryParameters['year.lessThanOrEqual'] = requestParameters.yearLessThanOrEqual;
        }

        if (requestParameters.yearEquals !== undefined) {
            queryParameters['year.equals'] = requestParameters.yearEquals;
        }

        if (requestParameters.yearNotEquals !== undefined) {
            queryParameters['year.notEquals'] = requestParameters.yearNotEquals;
        }

        if (requestParameters.yearSpecified !== undefined) {
            queryParameters['year.specified'] = requestParameters.yearSpecified;
        }

        if (requestParameters.yearIn) {
            queryParameters['year.in'] = requestParameters.yearIn;
        }

        if (requestParameters.yearNotIn) {
            queryParameters['year.notIn'] = requestParameters.yearNotIn;
        }

        if (requestParameters.typeEquals !== undefined) {
            queryParameters['type.equals'] = requestParameters.typeEquals;
        }

        if (requestParameters.typeNotEquals !== undefined) {
            queryParameters['type.notEquals'] = requestParameters.typeNotEquals;
        }

        if (requestParameters.typeSpecified !== undefined) {
            queryParameters['type.specified'] = requestParameters.typeSpecified;
        }

        if (requestParameters.typeIn) {
            queryParameters['type.in'] = requestParameters.typeIn;
        }

        if (requestParameters.typeNotIn) {
            queryParameters['type.notIn'] = requestParameters.typeNotIn;
        }

        if (requestParameters.progressivoBilancioGreaterThan !== undefined) {
            queryParameters['progressivoBilancio.greaterThan'] = requestParameters.progressivoBilancioGreaterThan;
        }

        if (requestParameters.progressivoBilancioLessThan !== undefined) {
            queryParameters['progressivoBilancio.lessThan'] = requestParameters.progressivoBilancioLessThan;
        }

        if (requestParameters.progressivoBilancioGreaterThanOrEqual !== undefined) {
            queryParameters['progressivoBilancio.greaterThanOrEqual'] = requestParameters.progressivoBilancioGreaterThanOrEqual;
        }

        if (requestParameters.progressivoBilancioLessThanOrEqual !== undefined) {
            queryParameters['progressivoBilancio.lessThanOrEqual'] = requestParameters.progressivoBilancioLessThanOrEqual;
        }

        if (requestParameters.progressivoBilancioEquals !== undefined) {
            queryParameters['progressivoBilancio.equals'] = requestParameters.progressivoBilancioEquals;
        }

        if (requestParameters.progressivoBilancioNotEquals !== undefined) {
            queryParameters['progressivoBilancio.notEquals'] = requestParameters.progressivoBilancioNotEquals;
        }

        if (requestParameters.progressivoBilancioSpecified !== undefined) {
            queryParameters['progressivoBilancio.specified'] = requestParameters.progressivoBilancioSpecified;
        }

        if (requestParameters.progressivoBilancioIn) {
            queryParameters['progressivoBilancio.in'] = requestParameters.progressivoBilancioIn;
        }

        if (requestParameters.progressivoBilancioNotIn) {
            queryParameters['progressivoBilancio.notIn'] = requestParameters.progressivoBilancioNotIn;
        }

        if (requestParameters.kDocFisicoContains !== undefined) {
            queryParameters['kDocFisico.contains'] = requestParameters.kDocFisicoContains;
        }

        if (requestParameters.kDocFisicoDoesNotContain !== undefined) {
            queryParameters['kDocFisico.doesNotContain'] = requestParameters.kDocFisicoDoesNotContain;
        }

        if (requestParameters.kDocFisicoEquals !== undefined) {
            queryParameters['kDocFisico.equals'] = requestParameters.kDocFisicoEquals;
        }

        if (requestParameters.kDocFisicoNotEquals !== undefined) {
            queryParameters['kDocFisico.notEquals'] = requestParameters.kDocFisicoNotEquals;
        }

        if (requestParameters.kDocFisicoSpecified !== undefined) {
            queryParameters['kDocFisico.specified'] = requestParameters.kDocFisicoSpecified;
        }

        if (requestParameters.kDocFisicoIn) {
            queryParameters['kDocFisico.in'] = requestParameters.kDocFisicoIn;
        }

        if (requestParameters.kDocFisicoNotIn) {
            queryParameters['kDocFisico.notIn'] = requestParameters.kDocFisicoNotIn;
        }

        if (requestParameters.dtChiusuraGreaterThan !== undefined) {
            queryParameters['dtChiusura.greaterThan'] = (requestParameters.dtChiusuraGreaterThan as any).toISOString();
        }

        if (requestParameters.dtChiusuraLessThan !== undefined) {
            queryParameters['dtChiusura.lessThan'] = (requestParameters.dtChiusuraLessThan as any).toISOString();
        }

        if (requestParameters.dtChiusuraGreaterThanOrEqual !== undefined) {
            queryParameters['dtChiusura.greaterThanOrEqual'] = (requestParameters.dtChiusuraGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dtChiusuraLessThanOrEqual !== undefined) {
            queryParameters['dtChiusura.lessThanOrEqual'] = (requestParameters.dtChiusuraLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dtChiusuraEquals !== undefined) {
            queryParameters['dtChiusura.equals'] = (requestParameters.dtChiusuraEquals as any).toISOString();
        }

        if (requestParameters.dtChiusuraNotEquals !== undefined) {
            queryParameters['dtChiusura.notEquals'] = (requestParameters.dtChiusuraNotEquals as any).toISOString();
        }

        if (requestParameters.dtChiusuraSpecified !== undefined) {
            queryParameters['dtChiusura.specified'] = requestParameters.dtChiusuraSpecified;
        }

        if (requestParameters.dtChiusuraIn) {
            queryParameters['dtChiusura.in'] = requestParameters.dtChiusuraIn;
        }

        if (requestParameters.dtChiusuraNotIn) {
            queryParameters['dtChiusura.notIn'] = requestParameters.dtChiusuraNotIn;
        }

        if (requestParameters.codAttoContains !== undefined) {
            queryParameters['codAtto.contains'] = requestParameters.codAttoContains;
        }

        if (requestParameters.codAttoDoesNotContain !== undefined) {
            queryParameters['codAtto.doesNotContain'] = requestParameters.codAttoDoesNotContain;
        }

        if (requestParameters.codAttoEquals !== undefined) {
            queryParameters['codAtto.equals'] = requestParameters.codAttoEquals;
        }

        if (requestParameters.codAttoNotEquals !== undefined) {
            queryParameters['codAtto.notEquals'] = requestParameters.codAttoNotEquals;
        }

        if (requestParameters.codAttoSpecified !== undefined) {
            queryParameters['codAtto.specified'] = requestParameters.codAttoSpecified;
        }

        if (requestParameters.codAttoIn) {
            queryParameters['codAtto.in'] = requestParameters.codAttoIn;
        }

        if (requestParameters.codAttoNotIn) {
            queryParameters['codAtto.notIn'] = requestParameters.codAttoNotIn;
        }

        if (requestParameters.descCodAttoContains !== undefined) {
            queryParameters['descCodAtto.contains'] = requestParameters.descCodAttoContains;
        }

        if (requestParameters.descCodAttoDoesNotContain !== undefined) {
            queryParameters['descCodAtto.doesNotContain'] = requestParameters.descCodAttoDoesNotContain;
        }

        if (requestParameters.descCodAttoEquals !== undefined) {
            queryParameters['descCodAtto.equals'] = requestParameters.descCodAttoEquals;
        }

        if (requestParameters.descCodAttoNotEquals !== undefined) {
            queryParameters['descCodAtto.notEquals'] = requestParameters.descCodAttoNotEquals;
        }

        if (requestParameters.descCodAttoSpecified !== undefined) {
            queryParameters['descCodAtto.specified'] = requestParameters.descCodAttoSpecified;
        }

        if (requestParameters.descCodAttoIn) {
            queryParameters['descCodAtto.in'] = requestParameters.descCodAttoIn;
        }

        if (requestParameters.descCodAttoNotIn) {
            queryParameters['descCodAtto.notIn'] = requestParameters.descCodAttoNotIn;
        }

        if (requestParameters.fRipresentatoEquals !== undefined) {
            queryParameters['fRipresentato.equals'] = requestParameters.fRipresentatoEquals;
        }

        if (requestParameters.fRipresentatoNotEquals !== undefined) {
            queryParameters['fRipresentato.notEquals'] = requestParameters.fRipresentatoNotEquals;
        }

        if (requestParameters.fRipresentatoSpecified !== undefined) {
            queryParameters['fRipresentato.specified'] = requestParameters.fRipresentatoSpecified;
        }

        if (requestParameters.fRipresentatoIn) {
            queryParameters['fRipresentato.in'] = requestParameters.fRipresentatoIn;
        }

        if (requestParameters.fRipresentatoNotIn) {
            queryParameters['fRipresentato.notIn'] = requestParameters.fRipresentatoNotIn;
        }

        if (requestParameters.descFRipresentatoContains !== undefined) {
            queryParameters['descFRipresentato.contains'] = requestParameters.descFRipresentatoContains;
        }

        if (requestParameters.descFRipresentatoDoesNotContain !== undefined) {
            queryParameters['descFRipresentato.doesNotContain'] = requestParameters.descFRipresentatoDoesNotContain;
        }

        if (requestParameters.descFRipresentatoEquals !== undefined) {
            queryParameters['descFRipresentato.equals'] = requestParameters.descFRipresentatoEquals;
        }

        if (requestParameters.descFRipresentatoNotEquals !== undefined) {
            queryParameters['descFRipresentato.notEquals'] = requestParameters.descFRipresentatoNotEquals;
        }

        if (requestParameters.descFRipresentatoSpecified !== undefined) {
            queryParameters['descFRipresentato.specified'] = requestParameters.descFRipresentatoSpecified;
        }

        if (requestParameters.descFRipresentatoIn) {
            queryParameters['descFRipresentato.in'] = requestParameters.descFRipresentatoIn;
        }

        if (requestParameters.descFRipresentatoNotIn) {
            queryParameters['descFRipresentato.notIn'] = requestParameters.descFRipresentatoNotIn;
        }

        if (requestParameters.nPagineGreaterThan !== undefined) {
            queryParameters['nPagine.greaterThan'] = requestParameters.nPagineGreaterThan;
        }

        if (requestParameters.nPagineLessThan !== undefined) {
            queryParameters['nPagine.lessThan'] = requestParameters.nPagineLessThan;
        }

        if (requestParameters.nPagineGreaterThanOrEqual !== undefined) {
            queryParameters['nPagine.greaterThanOrEqual'] = requestParameters.nPagineGreaterThanOrEqual;
        }

        if (requestParameters.nPagineLessThanOrEqual !== undefined) {
            queryParameters['nPagine.lessThanOrEqual'] = requestParameters.nPagineLessThanOrEqual;
        }

        if (requestParameters.nPagineEquals !== undefined) {
            queryParameters['nPagine.equals'] = requestParameters.nPagineEquals;
        }

        if (requestParameters.nPagineNotEquals !== undefined) {
            queryParameters['nPagine.notEquals'] = requestParameters.nPagineNotEquals;
        }

        if (requestParameters.nPagineSpecified !== undefined) {
            queryParameters['nPagine.specified'] = requestParameters.nPagineSpecified;
        }

        if (requestParameters.nPagineIn) {
            queryParameters['nPagine.in'] = requestParameters.nPagineIn;
        }

        if (requestParameters.nPagineNotIn) {
            queryParameters['nPagine.notIn'] = requestParameters.nPagineNotIn;
        }

        if (requestParameters.origineDocEquals !== undefined) {
            queryParameters['origineDoc.equals'] = requestParameters.origineDocEquals;
        }

        if (requestParameters.origineDocNotEquals !== undefined) {
            queryParameters['origineDoc.notEquals'] = requestParameters.origineDocNotEquals;
        }

        if (requestParameters.origineDocSpecified !== undefined) {
            queryParameters['origineDoc.specified'] = requestParameters.origineDocSpecified;
        }

        if (requestParameters.origineDocIn) {
            queryParameters['origineDoc.in'] = requestParameters.origineDocIn;
        }

        if (requestParameters.origineDocNotIn) {
            queryParameters['origineDoc.notIn'] = requestParameters.origineDocNotIn;
        }

        if (requestParameters.descOrigineDocContains !== undefined) {
            queryParameters['descOrigineDoc.contains'] = requestParameters.descOrigineDocContains;
        }

        if (requestParameters.descOrigineDocDoesNotContain !== undefined) {
            queryParameters['descOrigineDoc.doesNotContain'] = requestParameters.descOrigineDocDoesNotContain;
        }

        if (requestParameters.descOrigineDocEquals !== undefined) {
            queryParameters['descOrigineDoc.equals'] = requestParameters.descOrigineDocEquals;
        }

        if (requestParameters.descOrigineDocNotEquals !== undefined) {
            queryParameters['descOrigineDoc.notEquals'] = requestParameters.descOrigineDocNotEquals;
        }

        if (requestParameters.descOrigineDocSpecified !== undefined) {
            queryParameters['descOrigineDoc.specified'] = requestParameters.descOrigineDocSpecified;
        }

        if (requestParameters.descOrigineDocIn) {
            queryParameters['descOrigineDoc.in'] = requestParameters.descOrigineDocIn;
        }

        if (requestParameters.descOrigineDocNotIn) {
            queryParameters['descOrigineDoc.notIn'] = requestParameters.descOrigineDocNotIn;
        }

        if (requestParameters.iceEquals !== undefined) {
            queryParameters['ice.equals'] = requestParameters.iceEquals;
        }

        if (requestParameters.iceNotEquals !== undefined) {
            queryParameters['ice.notEquals'] = requestParameters.iceNotEquals;
        }

        if (requestParameters.iceSpecified !== undefined) {
            queryParameters['ice.specified'] = requestParameters.iceSpecified;
        }

        if (requestParameters.iceIn) {
            queryParameters['ice.in'] = requestParameters.iceIn;
        }

        if (requestParameters.iceNotIn) {
            queryParameters['ice.notIn'] = requestParameters.iceNotIn;
        }

        if (requestParameters.descICEContains !== undefined) {
            queryParameters['descICE.contains'] = requestParameters.descICEContains;
        }

        if (requestParameters.descICEDoesNotContain !== undefined) {
            queryParameters['descICE.doesNotContain'] = requestParameters.descICEDoesNotContain;
        }

        if (requestParameters.descICEEquals !== undefined) {
            queryParameters['descICE.equals'] = requestParameters.descICEEquals;
        }

        if (requestParameters.descICENotEquals !== undefined) {
            queryParameters['descICE.notEquals'] = requestParameters.descICENotEquals;
        }

        if (requestParameters.descICESpecified !== undefined) {
            queryParameters['descICE.specified'] = requestParameters.descICESpecified;
        }

        if (requestParameters.descICEIn) {
            queryParameters['descICE.in'] = requestParameters.descICEIn;
        }

        if (requestParameters.descICENotIn) {
            queryParameters['descICE.notIn'] = requestParameters.descICENotIn;
        }

        if (requestParameters.idaEquals !== undefined) {
            queryParameters['ida.equals'] = requestParameters.idaEquals;
        }

        if (requestParameters.idaNotEquals !== undefined) {
            queryParameters['ida.notEquals'] = requestParameters.idaNotEquals;
        }

        if (requestParameters.idaSpecified !== undefined) {
            queryParameters['ida.specified'] = requestParameters.idaSpecified;
        }

        if (requestParameters.idaIn) {
            queryParameters['ida.in'] = requestParameters.idaIn;
        }

        if (requestParameters.idaNotIn) {
            queryParameters['ida.notIn'] = requestParameters.idaNotIn;
        }

        if (requestParameters.descIDAContains !== undefined) {
            queryParameters['descIDA.contains'] = requestParameters.descIDAContains;
        }

        if (requestParameters.descIDADoesNotContain !== undefined) {
            queryParameters['descIDA.doesNotContain'] = requestParameters.descIDADoesNotContain;
        }

        if (requestParameters.descIDAEquals !== undefined) {
            queryParameters['descIDA.equals'] = requestParameters.descIDAEquals;
        }

        if (requestParameters.descIDANotEquals !== undefined) {
            queryParameters['descIDA.notEquals'] = requestParameters.descIDANotEquals;
        }

        if (requestParameters.descIDASpecified !== undefined) {
            queryParameters['descIDA.specified'] = requestParameters.descIDASpecified;
        }

        if (requestParameters.descIDAIn) {
            queryParameters['descIDA.in'] = requestParameters.descIDAIn;
        }

        if (requestParameters.descIDANotIn) {
            queryParameters['descIDA.notIn'] = requestParameters.descIDANotIn;
        }

        if (requestParameters.spHykeeCreditiCommercialiGreaterThan !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.greaterThan'] = requestParameters.spHykeeCreditiCommercialiGreaterThan;
        }

        if (requestParameters.spHykeeCreditiCommercialiLessThan !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.lessThan'] = requestParameters.spHykeeCreditiCommercialiLessThan;
        }

        if (requestParameters.spHykeeCreditiCommercialiGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.greaterThanOrEqual'] = requestParameters.spHykeeCreditiCommercialiGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeCreditiCommercialiLessThanOrEqual !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.lessThanOrEqual'] = requestParameters.spHykeeCreditiCommercialiLessThanOrEqual;
        }

        if (requestParameters.spHykeeCreditiCommercialiEquals !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.equals'] = requestParameters.spHykeeCreditiCommercialiEquals;
        }

        if (requestParameters.spHykeeCreditiCommercialiNotEquals !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.notEquals'] = requestParameters.spHykeeCreditiCommercialiNotEquals;
        }

        if (requestParameters.spHykeeCreditiCommercialiSpecified !== undefined) {
            queryParameters['spHykeeCreditiCommerciali.specified'] = requestParameters.spHykeeCreditiCommercialiSpecified;
        }

        if (requestParameters.spHykeeCreditiCommercialiIn) {
            queryParameters['spHykeeCreditiCommerciali.in'] = requestParameters.spHykeeCreditiCommercialiIn;
        }

        if (requestParameters.spHykeeCreditiCommercialiNotIn) {
            queryParameters['spHykeeCreditiCommerciali.notIn'] = requestParameters.spHykeeCreditiCommercialiNotIn;
        }

        if (requestParameters.spHykeeDebitiCommercialiGreaterThan !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.greaterThan'] = requestParameters.spHykeeDebitiCommercialiGreaterThan;
        }

        if (requestParameters.spHykeeDebitiCommercialiLessThan !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.lessThan'] = requestParameters.spHykeeDebitiCommercialiLessThan;
        }

        if (requestParameters.spHykeeDebitiCommercialiGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.greaterThanOrEqual'] = requestParameters.spHykeeDebitiCommercialiGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiCommercialiLessThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.lessThanOrEqual'] = requestParameters.spHykeeDebitiCommercialiLessThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiCommercialiEquals !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.equals'] = requestParameters.spHykeeDebitiCommercialiEquals;
        }

        if (requestParameters.spHykeeDebitiCommercialiNotEquals !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.notEquals'] = requestParameters.spHykeeDebitiCommercialiNotEquals;
        }

        if (requestParameters.spHykeeDebitiCommercialiSpecified !== undefined) {
            queryParameters['spHykeeDebitiCommerciali.specified'] = requestParameters.spHykeeDebitiCommercialiSpecified;
        }

        if (requestParameters.spHykeeDebitiCommercialiIn) {
            queryParameters['spHykeeDebitiCommerciali.in'] = requestParameters.spHykeeDebitiCommercialiIn;
        }

        if (requestParameters.spHykeeDebitiCommercialiNotIn) {
            queryParameters['spHykeeDebitiCommerciali.notIn'] = requestParameters.spHykeeDebitiCommercialiNotIn;
        }

        if (requestParameters.spHykeeDebitiFinanziariGreaterThan !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.greaterThan'] = requestParameters.spHykeeDebitiFinanziariGreaterThan;
        }

        if (requestParameters.spHykeeDebitiFinanziariLessThan !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.lessThan'] = requestParameters.spHykeeDebitiFinanziariLessThan;
        }

        if (requestParameters.spHykeeDebitiFinanziariGreaterThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.greaterThanOrEqual'] = requestParameters.spHykeeDebitiFinanziariGreaterThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiFinanziariLessThanOrEqual !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.lessThanOrEqual'] = requestParameters.spHykeeDebitiFinanziariLessThanOrEqual;
        }

        if (requestParameters.spHykeeDebitiFinanziariEquals !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.equals'] = requestParameters.spHykeeDebitiFinanziariEquals;
        }

        if (requestParameters.spHykeeDebitiFinanziariNotEquals !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.notEquals'] = requestParameters.spHykeeDebitiFinanziariNotEquals;
        }

        if (requestParameters.spHykeeDebitiFinanziariSpecified !== undefined) {
            queryParameters['spHykeeDebitiFinanziari.specified'] = requestParameters.spHykeeDebitiFinanziariSpecified;
        }

        if (requestParameters.spHykeeDebitiFinanziariIn) {
            queryParameters['spHykeeDebitiFinanziari.in'] = requestParameters.spHykeeDebitiFinanziariIn;
        }

        if (requestParameters.spHykeeDebitiFinanziariNotIn) {
            queryParameters['spHykeeDebitiFinanziari.notIn'] = requestParameters.spHykeeDebitiFinanziariNotIn;
        }

        if (requestParameters.consolidatoEquals !== undefined) {
            queryParameters['consolidato.equals'] = requestParameters.consolidatoEquals;
        }

        if (requestParameters.consolidatoNotEquals !== undefined) {
            queryParameters['consolidato.notEquals'] = requestParameters.consolidatoNotEquals;
        }

        if (requestParameters.consolidatoSpecified !== undefined) {
            queryParameters['consolidato.specified'] = requestParameters.consolidatoSpecified;
        }

        if (requestParameters.consolidatoIn) {
            queryParameters['consolidato.in'] = requestParameters.consolidatoIn;
        }

        if (requestParameters.consolidatoNotIn) {
            queryParameters['consolidato.notIn'] = requestParameters.consolidatoNotIn;
        }

        if (requestParameters.currentMoreFieldsStateEquals !== undefined) {
            queryParameters['currentMoreFieldsState.equals'] = requestParameters.currentMoreFieldsStateEquals;
        }

        if (requestParameters.currentMoreFieldsStateNotEquals !== undefined) {
            queryParameters['currentMoreFieldsState.notEquals'] = requestParameters.currentMoreFieldsStateNotEquals;
        }

        if (requestParameters.currentMoreFieldsStateSpecified !== undefined) {
            queryParameters['currentMoreFieldsState.specified'] = requestParameters.currentMoreFieldsStateSpecified;
        }

        if (requestParameters.currentMoreFieldsStateIn) {
            queryParameters['currentMoreFieldsState.in'] = requestParameters.currentMoreFieldsStateIn;
        }

        if (requestParameters.currentMoreFieldsStateNotIn) {
            queryParameters['currentMoreFieldsState.notIn'] = requestParameters.currentMoreFieldsStateNotIn;
        }

        if (requestParameters.xbrlFilenameContains !== undefined) {
            queryParameters['xbrlFilename.contains'] = requestParameters.xbrlFilenameContains;
        }

        if (requestParameters.xbrlFilenameDoesNotContain !== undefined) {
            queryParameters['xbrlFilename.doesNotContain'] = requestParameters.xbrlFilenameDoesNotContain;
        }

        if (requestParameters.xbrlFilenameEquals !== undefined) {
            queryParameters['xbrlFilename.equals'] = requestParameters.xbrlFilenameEquals;
        }

        if (requestParameters.xbrlFilenameNotEquals !== undefined) {
            queryParameters['xbrlFilename.notEquals'] = requestParameters.xbrlFilenameNotEquals;
        }

        if (requestParameters.xbrlFilenameSpecified !== undefined) {
            queryParameters['xbrlFilename.specified'] = requestParameters.xbrlFilenameSpecified;
        }

        if (requestParameters.xbrlFilenameIn) {
            queryParameters['xbrlFilename.in'] = requestParameters.xbrlFilenameIn;
        }

        if (requestParameters.xbrlFilenameNotIn) {
            queryParameters['xbrlFilename.notIn'] = requestParameters.xbrlFilenameNotIn;
        }

        if (requestParameters.xbrlFilepathContains !== undefined) {
            queryParameters['xbrlFilepath.contains'] = requestParameters.xbrlFilepathContains;
        }

        if (requestParameters.xbrlFilepathDoesNotContain !== undefined) {
            queryParameters['xbrlFilepath.doesNotContain'] = requestParameters.xbrlFilepathDoesNotContain;
        }

        if (requestParameters.xbrlFilepathEquals !== undefined) {
            queryParameters['xbrlFilepath.equals'] = requestParameters.xbrlFilepathEquals;
        }

        if (requestParameters.xbrlFilepathNotEquals !== undefined) {
            queryParameters['xbrlFilepath.notEquals'] = requestParameters.xbrlFilepathNotEquals;
        }

        if (requestParameters.xbrlFilepathSpecified !== undefined) {
            queryParameters['xbrlFilepath.specified'] = requestParameters.xbrlFilepathSpecified;
        }

        if (requestParameters.xbrlFilepathIn) {
            queryParameters['xbrlFilepath.in'] = requestParameters.xbrlFilepathIn;
        }

        if (requestParameters.xbrlFilepathNotIn) {
            queryParameters['xbrlFilepath.notIn'] = requestParameters.xbrlFilepathNotIn;
        }

        if (requestParameters.xbrlAsPreviousFilenameContains !== undefined) {
            queryParameters['xbrlAsPreviousFilename.contains'] = requestParameters.xbrlAsPreviousFilenameContains;
        }

        if (requestParameters.xbrlAsPreviousFilenameDoesNotContain !== undefined) {
            queryParameters['xbrlAsPreviousFilename.doesNotContain'] = requestParameters.xbrlAsPreviousFilenameDoesNotContain;
        }

        if (requestParameters.xbrlAsPreviousFilenameEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilename.equals'] = requestParameters.xbrlAsPreviousFilenameEquals;
        }

        if (requestParameters.xbrlAsPreviousFilenameNotEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilename.notEquals'] = requestParameters.xbrlAsPreviousFilenameNotEquals;
        }

        if (requestParameters.xbrlAsPreviousFilenameSpecified !== undefined) {
            queryParameters['xbrlAsPreviousFilename.specified'] = requestParameters.xbrlAsPreviousFilenameSpecified;
        }

        if (requestParameters.xbrlAsPreviousFilenameIn) {
            queryParameters['xbrlAsPreviousFilename.in'] = requestParameters.xbrlAsPreviousFilenameIn;
        }

        if (requestParameters.xbrlAsPreviousFilenameNotIn) {
            queryParameters['xbrlAsPreviousFilename.notIn'] = requestParameters.xbrlAsPreviousFilenameNotIn;
        }

        if (requestParameters.xbrlAsPreviousFilepathContains !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.contains'] = requestParameters.xbrlAsPreviousFilepathContains;
        }

        if (requestParameters.xbrlAsPreviousFilepathDoesNotContain !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.doesNotContain'] = requestParameters.xbrlAsPreviousFilepathDoesNotContain;
        }

        if (requestParameters.xbrlAsPreviousFilepathEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.equals'] = requestParameters.xbrlAsPreviousFilepathEquals;
        }

        if (requestParameters.xbrlAsPreviousFilepathNotEquals !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.notEquals'] = requestParameters.xbrlAsPreviousFilepathNotEquals;
        }

        if (requestParameters.xbrlAsPreviousFilepathSpecified !== undefined) {
            queryParameters['xbrlAsPreviousFilepath.specified'] = requestParameters.xbrlAsPreviousFilepathSpecified;
        }

        if (requestParameters.xbrlAsPreviousFilepathIn) {
            queryParameters['xbrlAsPreviousFilepath.in'] = requestParameters.xbrlAsPreviousFilepathIn;
        }

        if (requestParameters.xbrlAsPreviousFilepathNotIn) {
            queryParameters['xbrlAsPreviousFilepath.notIn'] = requestParameters.xbrlAsPreviousFilepathNotIn;
        }

        if (requestParameters.sectionsFilenameContains !== undefined) {
            queryParameters['sectionsFilename.contains'] = requestParameters.sectionsFilenameContains;
        }

        if (requestParameters.sectionsFilenameDoesNotContain !== undefined) {
            queryParameters['sectionsFilename.doesNotContain'] = requestParameters.sectionsFilenameDoesNotContain;
        }

        if (requestParameters.sectionsFilenameEquals !== undefined) {
            queryParameters['sectionsFilename.equals'] = requestParameters.sectionsFilenameEquals;
        }

        if (requestParameters.sectionsFilenameNotEquals !== undefined) {
            queryParameters['sectionsFilename.notEquals'] = requestParameters.sectionsFilenameNotEquals;
        }

        if (requestParameters.sectionsFilenameSpecified !== undefined) {
            queryParameters['sectionsFilename.specified'] = requestParameters.sectionsFilenameSpecified;
        }

        if (requestParameters.sectionsFilenameIn) {
            queryParameters['sectionsFilename.in'] = requestParameters.sectionsFilenameIn;
        }

        if (requestParameters.sectionsFilenameNotIn) {
            queryParameters['sectionsFilename.notIn'] = requestParameters.sectionsFilenameNotIn;
        }

        if (requestParameters.sectionFilepathContains !== undefined) {
            queryParameters['sectionFilepath.contains'] = requestParameters.sectionFilepathContains;
        }

        if (requestParameters.sectionFilepathDoesNotContain !== undefined) {
            queryParameters['sectionFilepath.doesNotContain'] = requestParameters.sectionFilepathDoesNotContain;
        }

        if (requestParameters.sectionFilepathEquals !== undefined) {
            queryParameters['sectionFilepath.equals'] = requestParameters.sectionFilepathEquals;
        }

        if (requestParameters.sectionFilepathNotEquals !== undefined) {
            queryParameters['sectionFilepath.notEquals'] = requestParameters.sectionFilepathNotEquals;
        }

        if (requestParameters.sectionFilepathSpecified !== undefined) {
            queryParameters['sectionFilepath.specified'] = requestParameters.sectionFilepathSpecified;
        }

        if (requestParameters.sectionFilepathIn) {
            queryParameters['sectionFilepath.in'] = requestParameters.sectionFilepathIn;
        }

        if (requestParameters.sectionFilepathNotIn) {
            queryParameters['sectionFilepath.notIn'] = requestParameters.sectionFilepathNotIn;
        }

        if (requestParameters.companyIdGreaterThan !== undefined) {
            queryParameters['companyId.greaterThan'] = requestParameters.companyIdGreaterThan;
        }

        if (requestParameters.companyIdLessThan !== undefined) {
            queryParameters['companyId.lessThan'] = requestParameters.companyIdLessThan;
        }

        if (requestParameters.companyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyId.greaterThanOrEqual'] = requestParameters.companyIdGreaterThanOrEqual;
        }

        if (requestParameters.companyIdLessThanOrEqual !== undefined) {
            queryParameters['companyId.lessThanOrEqual'] = requestParameters.companyIdLessThanOrEqual;
        }

        if (requestParameters.companyIdEquals !== undefined) {
            queryParameters['companyId.equals'] = requestParameters.companyIdEquals;
        }

        if (requestParameters.companyIdNotEquals !== undefined) {
            queryParameters['companyId.notEquals'] = requestParameters.companyIdNotEquals;
        }

        if (requestParameters.companyIdSpecified !== undefined) {
            queryParameters['companyId.specified'] = requestParameters.companyIdSpecified;
        }

        if (requestParameters.companyIdIn) {
            queryParameters['companyId.in'] = requestParameters.companyIdIn;
        }

        if (requestParameters.companyIdNotIn) {
            queryParameters['companyId.notIn'] = requestParameters.companyIdNotIn;
        }

        if (requestParameters.balanceMoreFieldsStateIdGreaterThan !== undefined) {
            queryParameters['balanceMoreFieldsStateId.greaterThan'] = requestParameters.balanceMoreFieldsStateIdGreaterThan;
        }

        if (requestParameters.balanceMoreFieldsStateIdLessThan !== undefined) {
            queryParameters['balanceMoreFieldsStateId.lessThan'] = requestParameters.balanceMoreFieldsStateIdLessThan;
        }

        if (requestParameters.balanceMoreFieldsStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['balanceMoreFieldsStateId.greaterThanOrEqual'] = requestParameters.balanceMoreFieldsStateIdGreaterThanOrEqual;
        }

        if (requestParameters.balanceMoreFieldsStateIdLessThanOrEqual !== undefined) {
            queryParameters['balanceMoreFieldsStateId.lessThanOrEqual'] = requestParameters.balanceMoreFieldsStateIdLessThanOrEqual;
        }

        if (requestParameters.balanceMoreFieldsStateIdEquals !== undefined) {
            queryParameters['balanceMoreFieldsStateId.equals'] = requestParameters.balanceMoreFieldsStateIdEquals;
        }

        if (requestParameters.balanceMoreFieldsStateIdNotEquals !== undefined) {
            queryParameters['balanceMoreFieldsStateId.notEquals'] = requestParameters.balanceMoreFieldsStateIdNotEquals;
        }

        if (requestParameters.balanceMoreFieldsStateIdSpecified !== undefined) {
            queryParameters['balanceMoreFieldsStateId.specified'] = requestParameters.balanceMoreFieldsStateIdSpecified;
        }

        if (requestParameters.balanceMoreFieldsStateIdIn) {
            queryParameters['balanceMoreFieldsStateId.in'] = requestParameters.balanceMoreFieldsStateIdIn;
        }

        if (requestParameters.balanceMoreFieldsStateIdNotIn) {
            queryParameters['balanceMoreFieldsStateId.notIn'] = requestParameters.balanceMoreFieldsStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-balance-sheets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyBalanceSheetFromJSON));
    }

    /**
     */
    async getAllCompanyBalanceSheets(requestParameters: GetAllCompanyBalanceSheetsRequest): Promise<Array<CompanyBalanceSheet>> {
        const response = await this.getAllCompanyBalanceSheetsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyBalanceSheetRaw(requestParameters: GetCompanyBalanceSheetRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-balance-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyBalanceSheet(requestParameters: GetCompanyBalanceSheetRequest): Promise<CompanyBalanceSheet> {
        const response = await this.getCompanyBalanceSheetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyBalanceSheetRaw(requestParameters: PartialUpdateCompanyBalanceSheetRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyBalanceSheet.');
        }

        if (requestParameters.companyBalanceSheet === null || requestParameters.companyBalanceSheet === undefined) {
            throw new runtime.RequiredError('companyBalanceSheet','Required parameter requestParameters.companyBalanceSheet was null or undefined when calling partialUpdateCompanyBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-balance-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBalanceSheetToJSON(requestParameters.companyBalanceSheet),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyBalanceSheet(requestParameters: PartialUpdateCompanyBalanceSheetRequest): Promise<CompanyBalanceSheet> {
        const response = await this.partialUpdateCompanyBalanceSheetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyBalanceSheetRaw(requestParameters: UpdateCompanyBalanceSheetRequest): Promise<runtime.ApiResponse<CompanyBalanceSheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyBalanceSheet.');
        }

        if (requestParameters.companyBalanceSheet === null || requestParameters.companyBalanceSheet === undefined) {
            throw new runtime.RequiredError('companyBalanceSheet','Required parameter requestParameters.companyBalanceSheet was null or undefined when calling updateCompanyBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-balance-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBalanceSheetToJSON(requestParameters.companyBalanceSheet),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBalanceSheetFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyBalanceSheet(requestParameters: UpdateCompanyBalanceSheetRequest): Promise<CompanyBalanceSheet> {
        const response = await this.updateCompanyBalanceSheetRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsTypeEqualsEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsTypeNotEqualsEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsTypeInEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsTypeNotInEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsFRipresentatoEqualsEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsFRipresentatoNotEqualsEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsFRipresentatoInEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsFRipresentatoNotInEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsOrigineDocEqualsEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsOrigineDocNotEqualsEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsOrigineDocInEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsOrigineDocNotInEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIceEqualsEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIceNotEqualsEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIceInEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIceNotInEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIdaEqualsEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIdaNotEqualsEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIdaInEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsIdaNotInEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsCurrentMoreFieldsStateEqualsEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsCurrentMoreFieldsStateNotEqualsEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsCurrentMoreFieldsStateInEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyBalanceSheetsCurrentMoreFieldsStateNotInEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsTypeEqualsEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsTypeNotEqualsEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsTypeInEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsTypeNotInEnum {
    Bilancio = 'IT_BILANCIO',
    BilancioEsercizio = 'IT_BILANCIO_ESERCIZIO',
    BilancioOrdinarioEsercizio = 'IT_BILANCIO_ORDINARIO_ESERCIZIO',
    BilancioAbbreviatoEsercizio = 'IT_BILANCIO_ABBREVIATO_ESERCIZIO',
    BilancioConsolidatoEsercizio = 'IT_BILANCIO_CONSOLIDATO_ESERCIZIO',
    BilancioConsolidatoDellaSocietaControllante = 'IT_BILANCIO_CONSOLIDATO_DELLA_SOCIETA_CONTROLLANTE',
    BilancioDiSocietaEsteraAventeSedeSecondariaInItalia = 'IT_BILANCIO_DI_SOCIETA_ESTERA_AVENTE_SEDE_SECONDARIA_IN_ITALIA',
    BilancioSociale = 'IT_BILANCIO_SOCIALE',
    SituazionePatrimonialeEdEconomicaImpresaSociale = 'IT_SITUAZIONE_PATRIMONIALE_ED_ECONOMICA_IMPRESA_SOCIALE',
    BilancioMicroimpresa = 'IT_BILANCIO_MICROIMPRESA',
    BilancioSituazionePatrimonialeConsorzi = 'IT_BILANCIO_SITUAZIONE_PATRIMONIALE_CONSORZI',
    BilancioConsolidatoDiSocietaDiPersone = 'IT_BILANCIO_CONSOLIDATO_DI_SOCIETA_DI_PERSONE',
    SituazionePatrimonialeSoggettiDiversi = 'IT_SITUAZIONE_PATRIMONIALE_SOGGETTI_DIVERSI',
    BilancioFinaleDiLiquidazione = 'IT_BILANCIO_FINALE_DI_LIQUIDAZIONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsFRipresentatoEqualsEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsFRipresentatoNotEqualsEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsFRipresentatoInEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsFRipresentatoNotInEnum {
    S = 'S',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsOrigineDocEqualsEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsOrigineDocNotEqualsEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsOrigineDocInEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsOrigineDocNotInEnum {
    C = 'C',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIceEqualsEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIceNotEqualsEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIceInEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIceNotInEnum {
    A = 'A',
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X',
    Y = 'Y',
    W = 'W'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIdaEqualsEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIdaNotEqualsEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIdaInEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsIdaNotInEnum {
    E = 'E',
    N = 'N',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsCurrentMoreFieldsStateEqualsEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsCurrentMoreFieldsStateNotEqualsEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsCurrentMoreFieldsStateInEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyBalanceSheetsCurrentMoreFieldsStateNotInEnum {
    Missing = 'MISSING',
    Insecure = 'INSECURE',
    Warning = 'WARNING',
    Verified = 'VERIFIED'
}
