/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiAtto,
    EstremiAttoFromJSON,
    EstremiAttoFromJSONTyped,
    EstremiAttoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VariazioneFormaGiuridica
 */
export interface VariazioneFormaGiuridica {
    /**
     * 
     * @type {EstremiAtto}
     * @memberof VariazioneFormaGiuridica
     */
    estremiAtto?: EstremiAtto;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    old?: string;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    dtAtto?: string;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    pvariazione?: string;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    cold?: string;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    cnew?: string;
    /**
     * 
     * @type {string}
     * @memberof VariazioneFormaGiuridica
     */
    _new?: string;
}

export function VariazioneFormaGiuridicaFromJSON(json: any): VariazioneFormaGiuridica {
    return VariazioneFormaGiuridicaFromJSONTyped(json, false);
}

export function VariazioneFormaGiuridicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariazioneFormaGiuridica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiAtto': !exists(json, 'estremiAtto') ? undefined : EstremiAttoFromJSON(json['estremiAtto']),
        'old': !exists(json, 'old') ? undefined : json['old'],
        'dtAtto': !exists(json, 'dtAtto') ? undefined : json['dtAtto'],
        'pvariazione': !exists(json, 'pvariazione') ? undefined : json['pvariazione'],
        'cold': !exists(json, 'cold') ? undefined : json['cold'],
        'cnew': !exists(json, 'cnew') ? undefined : json['cnew'],
        '_new': !exists(json, 'new') ? undefined : json['new'],
    };
}

export function VariazioneFormaGiuridicaToJSON(value?: VariazioneFormaGiuridica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiAtto': EstremiAttoToJSON(value.estremiAtto),
        'old': value.old,
        'dtAtto': value.dtAtto,
        'pvariazione': value.pvariazione,
        'cold': value.cold,
        'cnew': value.cnew,
        'new': value._new,
    };
}


