/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyAddress,
    CompanyAddressFromJSON,
    CompanyAddressToJSON,
} from '../models';

export interface CountCompanyAddressesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    progressiveNumberGreaterThan?: number;
    progressiveNumberLessThan?: number;
    progressiveNumberGreaterThanOrEqual?: number;
    progressiveNumberLessThanOrEqual?: number;
    progressiveNumberEquals?: number;
    progressiveNumberNotEquals?: number;
    progressiveNumberSpecified?: boolean;
    progressiveNumberIn?: Array<number>;
    progressiveNumberNotIn?: Array<number>;
    streetToponymyContains?: string;
    streetToponymyDoesNotContain?: string;
    streetToponymyEquals?: string;
    streetToponymyNotEquals?: string;
    streetToponymySpecified?: boolean;
    streetToponymyIn?: Array<string>;
    streetToponymyNotIn?: Array<string>;
    streetContains?: string;
    streetDoesNotContain?: string;
    streetEquals?: string;
    streetNotEquals?: string;
    streetSpecified?: boolean;
    streetIn?: Array<string>;
    streetNotIn?: Array<string>;
    streetNumberContains?: string;
    streetNumberDoesNotContain?: string;
    streetNumberEquals?: string;
    streetNumberNotEquals?: string;
    streetNumberSpecified?: boolean;
    streetNumberIn?: Array<string>;
    streetNumberNotIn?: Array<string>;
    codComOfficesContains?: string;
    codComOfficesDoesNotContain?: string;
    codComOfficesEquals?: string;
    codComOfficesNotEquals?: string;
    codComOfficesSpecified?: boolean;
    codComOfficesIn?: Array<string>;
    codComOfficesNotIn?: Array<string>;
    cityContains?: string;
    cityDoesNotContain?: string;
    cityEquals?: string;
    cityNotEquals?: string;
    citySpecified?: boolean;
    cityIn?: Array<string>;
    cityNotIn?: Array<string>;
    provinceContains?: string;
    provinceDoesNotContain?: string;
    provinceEquals?: string;
    provinceNotEquals?: string;
    provinceSpecified?: boolean;
    provinceIn?: Array<string>;
    provinceNotIn?: Array<string>;
    regionContains?: string;
    regionDoesNotContain?: string;
    regionEquals?: string;
    regionNotEquals?: string;
    regionSpecified?: boolean;
    regionIn?: Array<string>;
    regionNotIn?: Array<string>;
    countryContains?: string;
    countryDoesNotContain?: string;
    countryEquals?: string;
    countryNotEquals?: string;
    countrySpecified?: boolean;
    countryIn?: Array<string>;
    countryNotIn?: Array<string>;
    zipCodeContains?: string;
    zipCodeDoesNotContain?: string;
    zipCodeEquals?: string;
    zipCodeNotEquals?: string;
    zipCodeSpecified?: boolean;
    zipCodeIn?: Array<string>;
    zipCodeNotIn?: Array<string>;
    legalStatusEquals?: CountCompanyAddressesLegalStatusEqualsEnum;
    legalStatusNotEquals?: CountCompanyAddressesLegalStatusNotEqualsEnum;
    legalStatusSpecified?: boolean;
    legalStatusIn?: Array<CountCompanyAddressesLegalStatusInEnum>;
    legalStatusNotIn?: Array<CountCompanyAddressesLegalStatusNotInEnum>;
    activityStatusEquals?: CountCompanyAddressesActivityStatusEqualsEnum;
    activityStatusNotEquals?: CountCompanyAddressesActivityStatusNotEqualsEnum;
    activityStatusSpecified?: boolean;
    activityStatusIn?: Array<CountCompanyAddressesActivityStatusInEnum>;
    activityStatusNotIn?: Array<CountCompanyAddressesActivityStatusNotInEnum>;
    sectorContains?: string;
    sectorDoesNotContain?: string;
    sectorEquals?: string;
    sectorNotEquals?: string;
    sectorSpecified?: boolean;
    sectorIn?: Array<string>;
    sectorNotIn?: Array<string>;
    telephoneContains?: string;
    telephoneDoesNotContain?: string;
    telephoneEquals?: string;
    telephoneNotEquals?: string;
    telephoneSpecified?: boolean;
    telephoneIn?: Array<string>;
    telephoneNotIn?: Array<string>;
    emailContains?: string;
    emailDoesNotContain?: string;
    emailEquals?: string;
    emailNotEquals?: string;
    emailSpecified?: boolean;
    emailIn?: Array<string>;
    emailNotIn?: Array<string>;
    emailPECContains?: string;
    emailPECDoesNotContain?: string;
    emailPECEquals?: string;
    emailPECNotEquals?: string;
    emailPECSpecified?: boolean;
    emailPECIn?: Array<string>;
    emailPECNotIn?: Array<string>;
    nreaCodeContains?: string;
    nreaCodeDoesNotContain?: string;
    nreaCodeEquals?: string;
    nreaCodeNotEquals?: string;
    nreaCodeSpecified?: boolean;
    nreaCodeIn?: Array<string>;
    nreaCodeNotIn?: Array<string>;
    cciaaCodeContains?: string;
    cciaaCodeDoesNotContain?: string;
    cciaaCodeEquals?: string;
    cciaaCodeNotEquals?: string;
    cciaaCodeSpecified?: boolean;
    cciaaCodeIn?: Array<string>;
    cciaaCodeNotIn?: Array<string>;
    legalNatureContains?: string;
    legalNatureDoesNotContain?: string;
    legalNatureEquals?: string;
    legalNatureNotEquals?: string;
    legalNatureSpecified?: boolean;
    legalNatureIn?: Array<string>;
    legalNatureNotIn?: Array<string>;
    sourceEquals?: CountCompanyAddressesSourceEqualsEnum;
    sourceNotEquals?: CountCompanyAddressesSourceNotEqualsEnum;
    sourceSpecified?: boolean;
    sourceIn?: Array<CountCompanyAddressesSourceInEnum>;
    sourceNotIn?: Array<CountCompanyAddressesSourceNotInEnum>;
    websiteContains?: string;
    websiteDoesNotContain?: string;
    websiteEquals?: string;
    websiteNotEquals?: string;
    websiteSpecified?: boolean;
    websiteIn?: Array<string>;
    websiteNotIn?: Array<string>;
    isMainAddressEquals?: boolean;
    isMainAddressNotEquals?: boolean;
    isMainAddressSpecified?: boolean;
    isMainAddressIn?: Array<boolean>;
    isMainAddressNotIn?: Array<boolean>;
    companyAtecoIdGreaterThan?: number;
    companyAtecoIdLessThan?: number;
    companyAtecoIdGreaterThanOrEqual?: number;
    companyAtecoIdLessThanOrEqual?: number;
    companyAtecoIdEquals?: number;
    companyAtecoIdNotEquals?: number;
    companyAtecoIdSpecified?: boolean;
    companyAtecoIdIn?: Array<number>;
    companyAtecoIdNotIn?: Array<number>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanyAddressRequest {
    companyAddress: CompanyAddress;
}

export interface DeleteCompanyAddressRequest {
    id: number;
}

export interface GetAllCompanyAddressesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    progressiveNumberGreaterThan?: number;
    progressiveNumberLessThan?: number;
    progressiveNumberGreaterThanOrEqual?: number;
    progressiveNumberLessThanOrEqual?: number;
    progressiveNumberEquals?: number;
    progressiveNumberNotEquals?: number;
    progressiveNumberSpecified?: boolean;
    progressiveNumberIn?: Array<number>;
    progressiveNumberNotIn?: Array<number>;
    streetToponymyContains?: string;
    streetToponymyDoesNotContain?: string;
    streetToponymyEquals?: string;
    streetToponymyNotEquals?: string;
    streetToponymySpecified?: boolean;
    streetToponymyIn?: Array<string>;
    streetToponymyNotIn?: Array<string>;
    streetContains?: string;
    streetDoesNotContain?: string;
    streetEquals?: string;
    streetNotEquals?: string;
    streetSpecified?: boolean;
    streetIn?: Array<string>;
    streetNotIn?: Array<string>;
    streetNumberContains?: string;
    streetNumberDoesNotContain?: string;
    streetNumberEquals?: string;
    streetNumberNotEquals?: string;
    streetNumberSpecified?: boolean;
    streetNumberIn?: Array<string>;
    streetNumberNotIn?: Array<string>;
    codComOfficesContains?: string;
    codComOfficesDoesNotContain?: string;
    codComOfficesEquals?: string;
    codComOfficesNotEquals?: string;
    codComOfficesSpecified?: boolean;
    codComOfficesIn?: Array<string>;
    codComOfficesNotIn?: Array<string>;
    cityContains?: string;
    cityDoesNotContain?: string;
    cityEquals?: string;
    cityNotEquals?: string;
    citySpecified?: boolean;
    cityIn?: Array<string>;
    cityNotIn?: Array<string>;
    provinceContains?: string;
    provinceDoesNotContain?: string;
    provinceEquals?: string;
    provinceNotEquals?: string;
    provinceSpecified?: boolean;
    provinceIn?: Array<string>;
    provinceNotIn?: Array<string>;
    regionContains?: string;
    regionDoesNotContain?: string;
    regionEquals?: string;
    regionNotEquals?: string;
    regionSpecified?: boolean;
    regionIn?: Array<string>;
    regionNotIn?: Array<string>;
    countryContains?: string;
    countryDoesNotContain?: string;
    countryEquals?: string;
    countryNotEquals?: string;
    countrySpecified?: boolean;
    countryIn?: Array<string>;
    countryNotIn?: Array<string>;
    zipCodeContains?: string;
    zipCodeDoesNotContain?: string;
    zipCodeEquals?: string;
    zipCodeNotEquals?: string;
    zipCodeSpecified?: boolean;
    zipCodeIn?: Array<string>;
    zipCodeNotIn?: Array<string>;
    legalStatusEquals?: GetAllCompanyAddressesLegalStatusEqualsEnum;
    legalStatusNotEquals?: GetAllCompanyAddressesLegalStatusNotEqualsEnum;
    legalStatusSpecified?: boolean;
    legalStatusIn?: Array<GetAllCompanyAddressesLegalStatusInEnum>;
    legalStatusNotIn?: Array<GetAllCompanyAddressesLegalStatusNotInEnum>;
    activityStatusEquals?: GetAllCompanyAddressesActivityStatusEqualsEnum;
    activityStatusNotEquals?: GetAllCompanyAddressesActivityStatusNotEqualsEnum;
    activityStatusSpecified?: boolean;
    activityStatusIn?: Array<GetAllCompanyAddressesActivityStatusInEnum>;
    activityStatusNotIn?: Array<GetAllCompanyAddressesActivityStatusNotInEnum>;
    sectorContains?: string;
    sectorDoesNotContain?: string;
    sectorEquals?: string;
    sectorNotEquals?: string;
    sectorSpecified?: boolean;
    sectorIn?: Array<string>;
    sectorNotIn?: Array<string>;
    telephoneContains?: string;
    telephoneDoesNotContain?: string;
    telephoneEquals?: string;
    telephoneNotEquals?: string;
    telephoneSpecified?: boolean;
    telephoneIn?: Array<string>;
    telephoneNotIn?: Array<string>;
    emailContains?: string;
    emailDoesNotContain?: string;
    emailEquals?: string;
    emailNotEquals?: string;
    emailSpecified?: boolean;
    emailIn?: Array<string>;
    emailNotIn?: Array<string>;
    emailPECContains?: string;
    emailPECDoesNotContain?: string;
    emailPECEquals?: string;
    emailPECNotEquals?: string;
    emailPECSpecified?: boolean;
    emailPECIn?: Array<string>;
    emailPECNotIn?: Array<string>;
    nreaCodeContains?: string;
    nreaCodeDoesNotContain?: string;
    nreaCodeEquals?: string;
    nreaCodeNotEquals?: string;
    nreaCodeSpecified?: boolean;
    nreaCodeIn?: Array<string>;
    nreaCodeNotIn?: Array<string>;
    cciaaCodeContains?: string;
    cciaaCodeDoesNotContain?: string;
    cciaaCodeEquals?: string;
    cciaaCodeNotEquals?: string;
    cciaaCodeSpecified?: boolean;
    cciaaCodeIn?: Array<string>;
    cciaaCodeNotIn?: Array<string>;
    legalNatureContains?: string;
    legalNatureDoesNotContain?: string;
    legalNatureEquals?: string;
    legalNatureNotEquals?: string;
    legalNatureSpecified?: boolean;
    legalNatureIn?: Array<string>;
    legalNatureNotIn?: Array<string>;
    sourceEquals?: GetAllCompanyAddressesSourceEqualsEnum;
    sourceNotEquals?: GetAllCompanyAddressesSourceNotEqualsEnum;
    sourceSpecified?: boolean;
    sourceIn?: Array<GetAllCompanyAddressesSourceInEnum>;
    sourceNotIn?: Array<GetAllCompanyAddressesSourceNotInEnum>;
    websiteContains?: string;
    websiteDoesNotContain?: string;
    websiteEquals?: string;
    websiteNotEquals?: string;
    websiteSpecified?: boolean;
    websiteIn?: Array<string>;
    websiteNotIn?: Array<string>;
    isMainAddressEquals?: boolean;
    isMainAddressNotEquals?: boolean;
    isMainAddressSpecified?: boolean;
    isMainAddressIn?: Array<boolean>;
    isMainAddressNotIn?: Array<boolean>;
    companyAtecoIdGreaterThan?: number;
    companyAtecoIdLessThan?: number;
    companyAtecoIdGreaterThanOrEqual?: number;
    companyAtecoIdLessThanOrEqual?: number;
    companyAtecoIdEquals?: number;
    companyAtecoIdNotEquals?: number;
    companyAtecoIdSpecified?: boolean;
    companyAtecoIdIn?: Array<number>;
    companyAtecoIdNotIn?: Array<number>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanyAddressRequest {
    id: number;
}

export interface PartialUpdateCompanyAddressRequest {
    id: number;
    companyAddress: CompanyAddress;
}

export interface UpdateCompanyAddressRequest {
    id: number;
    companyAddress: CompanyAddress;
}

/**
 * 
 */
export class CompanyAddressResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompanyAddressesRaw(requestParameters: CountCompanyAddressesRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.progressiveNumberGreaterThan !== undefined) {
            queryParameters['progressiveNumber.greaterThan'] = requestParameters.progressiveNumberGreaterThan;
        }

        if (requestParameters.progressiveNumberLessThan !== undefined) {
            queryParameters['progressiveNumber.lessThan'] = requestParameters.progressiveNumberLessThan;
        }

        if (requestParameters.progressiveNumberGreaterThanOrEqual !== undefined) {
            queryParameters['progressiveNumber.greaterThanOrEqual'] = requestParameters.progressiveNumberGreaterThanOrEqual;
        }

        if (requestParameters.progressiveNumberLessThanOrEqual !== undefined) {
            queryParameters['progressiveNumber.lessThanOrEqual'] = requestParameters.progressiveNumberLessThanOrEqual;
        }

        if (requestParameters.progressiveNumberEquals !== undefined) {
            queryParameters['progressiveNumber.equals'] = requestParameters.progressiveNumberEquals;
        }

        if (requestParameters.progressiveNumberNotEquals !== undefined) {
            queryParameters['progressiveNumber.notEquals'] = requestParameters.progressiveNumberNotEquals;
        }

        if (requestParameters.progressiveNumberSpecified !== undefined) {
            queryParameters['progressiveNumber.specified'] = requestParameters.progressiveNumberSpecified;
        }

        if (requestParameters.progressiveNumberIn) {
            queryParameters['progressiveNumber.in'] = requestParameters.progressiveNumberIn;
        }

        if (requestParameters.progressiveNumberNotIn) {
            queryParameters['progressiveNumber.notIn'] = requestParameters.progressiveNumberNotIn;
        }

        if (requestParameters.streetToponymyContains !== undefined) {
            queryParameters['streetToponymy.contains'] = requestParameters.streetToponymyContains;
        }

        if (requestParameters.streetToponymyDoesNotContain !== undefined) {
            queryParameters['streetToponymy.doesNotContain'] = requestParameters.streetToponymyDoesNotContain;
        }

        if (requestParameters.streetToponymyEquals !== undefined) {
            queryParameters['streetToponymy.equals'] = requestParameters.streetToponymyEquals;
        }

        if (requestParameters.streetToponymyNotEquals !== undefined) {
            queryParameters['streetToponymy.notEquals'] = requestParameters.streetToponymyNotEquals;
        }

        if (requestParameters.streetToponymySpecified !== undefined) {
            queryParameters['streetToponymy.specified'] = requestParameters.streetToponymySpecified;
        }

        if (requestParameters.streetToponymyIn) {
            queryParameters['streetToponymy.in'] = requestParameters.streetToponymyIn;
        }

        if (requestParameters.streetToponymyNotIn) {
            queryParameters['streetToponymy.notIn'] = requestParameters.streetToponymyNotIn;
        }

        if (requestParameters.streetContains !== undefined) {
            queryParameters['street.contains'] = requestParameters.streetContains;
        }

        if (requestParameters.streetDoesNotContain !== undefined) {
            queryParameters['street.doesNotContain'] = requestParameters.streetDoesNotContain;
        }

        if (requestParameters.streetEquals !== undefined) {
            queryParameters['street.equals'] = requestParameters.streetEquals;
        }

        if (requestParameters.streetNotEquals !== undefined) {
            queryParameters['street.notEquals'] = requestParameters.streetNotEquals;
        }

        if (requestParameters.streetSpecified !== undefined) {
            queryParameters['street.specified'] = requestParameters.streetSpecified;
        }

        if (requestParameters.streetIn) {
            queryParameters['street.in'] = requestParameters.streetIn;
        }

        if (requestParameters.streetNotIn) {
            queryParameters['street.notIn'] = requestParameters.streetNotIn;
        }

        if (requestParameters.streetNumberContains !== undefined) {
            queryParameters['streetNumber.contains'] = requestParameters.streetNumberContains;
        }

        if (requestParameters.streetNumberDoesNotContain !== undefined) {
            queryParameters['streetNumber.doesNotContain'] = requestParameters.streetNumberDoesNotContain;
        }

        if (requestParameters.streetNumberEquals !== undefined) {
            queryParameters['streetNumber.equals'] = requestParameters.streetNumberEquals;
        }

        if (requestParameters.streetNumberNotEquals !== undefined) {
            queryParameters['streetNumber.notEquals'] = requestParameters.streetNumberNotEquals;
        }

        if (requestParameters.streetNumberSpecified !== undefined) {
            queryParameters['streetNumber.specified'] = requestParameters.streetNumberSpecified;
        }

        if (requestParameters.streetNumberIn) {
            queryParameters['streetNumber.in'] = requestParameters.streetNumberIn;
        }

        if (requestParameters.streetNumberNotIn) {
            queryParameters['streetNumber.notIn'] = requestParameters.streetNumberNotIn;
        }

        if (requestParameters.codComOfficesContains !== undefined) {
            queryParameters['codComOffices.contains'] = requestParameters.codComOfficesContains;
        }

        if (requestParameters.codComOfficesDoesNotContain !== undefined) {
            queryParameters['codComOffices.doesNotContain'] = requestParameters.codComOfficesDoesNotContain;
        }

        if (requestParameters.codComOfficesEquals !== undefined) {
            queryParameters['codComOffices.equals'] = requestParameters.codComOfficesEquals;
        }

        if (requestParameters.codComOfficesNotEquals !== undefined) {
            queryParameters['codComOffices.notEquals'] = requestParameters.codComOfficesNotEquals;
        }

        if (requestParameters.codComOfficesSpecified !== undefined) {
            queryParameters['codComOffices.specified'] = requestParameters.codComOfficesSpecified;
        }

        if (requestParameters.codComOfficesIn) {
            queryParameters['codComOffices.in'] = requestParameters.codComOfficesIn;
        }

        if (requestParameters.codComOfficesNotIn) {
            queryParameters['codComOffices.notIn'] = requestParameters.codComOfficesNotIn;
        }

        if (requestParameters.cityContains !== undefined) {
            queryParameters['city.contains'] = requestParameters.cityContains;
        }

        if (requestParameters.cityDoesNotContain !== undefined) {
            queryParameters['city.doesNotContain'] = requestParameters.cityDoesNotContain;
        }

        if (requestParameters.cityEquals !== undefined) {
            queryParameters['city.equals'] = requestParameters.cityEquals;
        }

        if (requestParameters.cityNotEquals !== undefined) {
            queryParameters['city.notEquals'] = requestParameters.cityNotEquals;
        }

        if (requestParameters.citySpecified !== undefined) {
            queryParameters['city.specified'] = requestParameters.citySpecified;
        }

        if (requestParameters.cityIn) {
            queryParameters['city.in'] = requestParameters.cityIn;
        }

        if (requestParameters.cityNotIn) {
            queryParameters['city.notIn'] = requestParameters.cityNotIn;
        }

        if (requestParameters.provinceContains !== undefined) {
            queryParameters['province.contains'] = requestParameters.provinceContains;
        }

        if (requestParameters.provinceDoesNotContain !== undefined) {
            queryParameters['province.doesNotContain'] = requestParameters.provinceDoesNotContain;
        }

        if (requestParameters.provinceEquals !== undefined) {
            queryParameters['province.equals'] = requestParameters.provinceEquals;
        }

        if (requestParameters.provinceNotEquals !== undefined) {
            queryParameters['province.notEquals'] = requestParameters.provinceNotEquals;
        }

        if (requestParameters.provinceSpecified !== undefined) {
            queryParameters['province.specified'] = requestParameters.provinceSpecified;
        }

        if (requestParameters.provinceIn) {
            queryParameters['province.in'] = requestParameters.provinceIn;
        }

        if (requestParameters.provinceNotIn) {
            queryParameters['province.notIn'] = requestParameters.provinceNotIn;
        }

        if (requestParameters.regionContains !== undefined) {
            queryParameters['region.contains'] = requestParameters.regionContains;
        }

        if (requestParameters.regionDoesNotContain !== undefined) {
            queryParameters['region.doesNotContain'] = requestParameters.regionDoesNotContain;
        }

        if (requestParameters.regionEquals !== undefined) {
            queryParameters['region.equals'] = requestParameters.regionEquals;
        }

        if (requestParameters.regionNotEquals !== undefined) {
            queryParameters['region.notEquals'] = requestParameters.regionNotEquals;
        }

        if (requestParameters.regionSpecified !== undefined) {
            queryParameters['region.specified'] = requestParameters.regionSpecified;
        }

        if (requestParameters.regionIn) {
            queryParameters['region.in'] = requestParameters.regionIn;
        }

        if (requestParameters.regionNotIn) {
            queryParameters['region.notIn'] = requestParameters.regionNotIn;
        }

        if (requestParameters.countryContains !== undefined) {
            queryParameters['country.contains'] = requestParameters.countryContains;
        }

        if (requestParameters.countryDoesNotContain !== undefined) {
            queryParameters['country.doesNotContain'] = requestParameters.countryDoesNotContain;
        }

        if (requestParameters.countryEquals !== undefined) {
            queryParameters['country.equals'] = requestParameters.countryEquals;
        }

        if (requestParameters.countryNotEquals !== undefined) {
            queryParameters['country.notEquals'] = requestParameters.countryNotEquals;
        }

        if (requestParameters.countrySpecified !== undefined) {
            queryParameters['country.specified'] = requestParameters.countrySpecified;
        }

        if (requestParameters.countryIn) {
            queryParameters['country.in'] = requestParameters.countryIn;
        }

        if (requestParameters.countryNotIn) {
            queryParameters['country.notIn'] = requestParameters.countryNotIn;
        }

        if (requestParameters.zipCodeContains !== undefined) {
            queryParameters['zipCode.contains'] = requestParameters.zipCodeContains;
        }

        if (requestParameters.zipCodeDoesNotContain !== undefined) {
            queryParameters['zipCode.doesNotContain'] = requestParameters.zipCodeDoesNotContain;
        }

        if (requestParameters.zipCodeEquals !== undefined) {
            queryParameters['zipCode.equals'] = requestParameters.zipCodeEquals;
        }

        if (requestParameters.zipCodeNotEquals !== undefined) {
            queryParameters['zipCode.notEquals'] = requestParameters.zipCodeNotEquals;
        }

        if (requestParameters.zipCodeSpecified !== undefined) {
            queryParameters['zipCode.specified'] = requestParameters.zipCodeSpecified;
        }

        if (requestParameters.zipCodeIn) {
            queryParameters['zipCode.in'] = requestParameters.zipCodeIn;
        }

        if (requestParameters.zipCodeNotIn) {
            queryParameters['zipCode.notIn'] = requestParameters.zipCodeNotIn;
        }

        if (requestParameters.legalStatusEquals !== undefined) {
            queryParameters['legalStatus.equals'] = requestParameters.legalStatusEquals;
        }

        if (requestParameters.legalStatusNotEquals !== undefined) {
            queryParameters['legalStatus.notEquals'] = requestParameters.legalStatusNotEquals;
        }

        if (requestParameters.legalStatusSpecified !== undefined) {
            queryParameters['legalStatus.specified'] = requestParameters.legalStatusSpecified;
        }

        if (requestParameters.legalStatusIn) {
            queryParameters['legalStatus.in'] = requestParameters.legalStatusIn;
        }

        if (requestParameters.legalStatusNotIn) {
            queryParameters['legalStatus.notIn'] = requestParameters.legalStatusNotIn;
        }

        if (requestParameters.activityStatusEquals !== undefined) {
            queryParameters['activityStatus.equals'] = requestParameters.activityStatusEquals;
        }

        if (requestParameters.activityStatusNotEquals !== undefined) {
            queryParameters['activityStatus.notEquals'] = requestParameters.activityStatusNotEquals;
        }

        if (requestParameters.activityStatusSpecified !== undefined) {
            queryParameters['activityStatus.specified'] = requestParameters.activityStatusSpecified;
        }

        if (requestParameters.activityStatusIn) {
            queryParameters['activityStatus.in'] = requestParameters.activityStatusIn;
        }

        if (requestParameters.activityStatusNotIn) {
            queryParameters['activityStatus.notIn'] = requestParameters.activityStatusNotIn;
        }

        if (requestParameters.sectorContains !== undefined) {
            queryParameters['sector.contains'] = requestParameters.sectorContains;
        }

        if (requestParameters.sectorDoesNotContain !== undefined) {
            queryParameters['sector.doesNotContain'] = requestParameters.sectorDoesNotContain;
        }

        if (requestParameters.sectorEquals !== undefined) {
            queryParameters['sector.equals'] = requestParameters.sectorEquals;
        }

        if (requestParameters.sectorNotEquals !== undefined) {
            queryParameters['sector.notEquals'] = requestParameters.sectorNotEquals;
        }

        if (requestParameters.sectorSpecified !== undefined) {
            queryParameters['sector.specified'] = requestParameters.sectorSpecified;
        }

        if (requestParameters.sectorIn) {
            queryParameters['sector.in'] = requestParameters.sectorIn;
        }

        if (requestParameters.sectorNotIn) {
            queryParameters['sector.notIn'] = requestParameters.sectorNotIn;
        }

        if (requestParameters.telephoneContains !== undefined) {
            queryParameters['telephone.contains'] = requestParameters.telephoneContains;
        }

        if (requestParameters.telephoneDoesNotContain !== undefined) {
            queryParameters['telephone.doesNotContain'] = requestParameters.telephoneDoesNotContain;
        }

        if (requestParameters.telephoneEquals !== undefined) {
            queryParameters['telephone.equals'] = requestParameters.telephoneEquals;
        }

        if (requestParameters.telephoneNotEquals !== undefined) {
            queryParameters['telephone.notEquals'] = requestParameters.telephoneNotEquals;
        }

        if (requestParameters.telephoneSpecified !== undefined) {
            queryParameters['telephone.specified'] = requestParameters.telephoneSpecified;
        }

        if (requestParameters.telephoneIn) {
            queryParameters['telephone.in'] = requestParameters.telephoneIn;
        }

        if (requestParameters.telephoneNotIn) {
            queryParameters['telephone.notIn'] = requestParameters.telephoneNotIn;
        }

        if (requestParameters.emailContains !== undefined) {
            queryParameters['email.contains'] = requestParameters.emailContains;
        }

        if (requestParameters.emailDoesNotContain !== undefined) {
            queryParameters['email.doesNotContain'] = requestParameters.emailDoesNotContain;
        }

        if (requestParameters.emailEquals !== undefined) {
            queryParameters['email.equals'] = requestParameters.emailEquals;
        }

        if (requestParameters.emailNotEquals !== undefined) {
            queryParameters['email.notEquals'] = requestParameters.emailNotEquals;
        }

        if (requestParameters.emailSpecified !== undefined) {
            queryParameters['email.specified'] = requestParameters.emailSpecified;
        }

        if (requestParameters.emailIn) {
            queryParameters['email.in'] = requestParameters.emailIn;
        }

        if (requestParameters.emailNotIn) {
            queryParameters['email.notIn'] = requestParameters.emailNotIn;
        }

        if (requestParameters.emailPECContains !== undefined) {
            queryParameters['emailPEC.contains'] = requestParameters.emailPECContains;
        }

        if (requestParameters.emailPECDoesNotContain !== undefined) {
            queryParameters['emailPEC.doesNotContain'] = requestParameters.emailPECDoesNotContain;
        }

        if (requestParameters.emailPECEquals !== undefined) {
            queryParameters['emailPEC.equals'] = requestParameters.emailPECEquals;
        }

        if (requestParameters.emailPECNotEquals !== undefined) {
            queryParameters['emailPEC.notEquals'] = requestParameters.emailPECNotEquals;
        }

        if (requestParameters.emailPECSpecified !== undefined) {
            queryParameters['emailPEC.specified'] = requestParameters.emailPECSpecified;
        }

        if (requestParameters.emailPECIn) {
            queryParameters['emailPEC.in'] = requestParameters.emailPECIn;
        }

        if (requestParameters.emailPECNotIn) {
            queryParameters['emailPEC.notIn'] = requestParameters.emailPECNotIn;
        }

        if (requestParameters.nreaCodeContains !== undefined) {
            queryParameters['nreaCode.contains'] = requestParameters.nreaCodeContains;
        }

        if (requestParameters.nreaCodeDoesNotContain !== undefined) {
            queryParameters['nreaCode.doesNotContain'] = requestParameters.nreaCodeDoesNotContain;
        }

        if (requestParameters.nreaCodeEquals !== undefined) {
            queryParameters['nreaCode.equals'] = requestParameters.nreaCodeEquals;
        }

        if (requestParameters.nreaCodeNotEquals !== undefined) {
            queryParameters['nreaCode.notEquals'] = requestParameters.nreaCodeNotEquals;
        }

        if (requestParameters.nreaCodeSpecified !== undefined) {
            queryParameters['nreaCode.specified'] = requestParameters.nreaCodeSpecified;
        }

        if (requestParameters.nreaCodeIn) {
            queryParameters['nreaCode.in'] = requestParameters.nreaCodeIn;
        }

        if (requestParameters.nreaCodeNotIn) {
            queryParameters['nreaCode.notIn'] = requestParameters.nreaCodeNotIn;
        }

        if (requestParameters.cciaaCodeContains !== undefined) {
            queryParameters['cciaaCode.contains'] = requestParameters.cciaaCodeContains;
        }

        if (requestParameters.cciaaCodeDoesNotContain !== undefined) {
            queryParameters['cciaaCode.doesNotContain'] = requestParameters.cciaaCodeDoesNotContain;
        }

        if (requestParameters.cciaaCodeEquals !== undefined) {
            queryParameters['cciaaCode.equals'] = requestParameters.cciaaCodeEquals;
        }

        if (requestParameters.cciaaCodeNotEquals !== undefined) {
            queryParameters['cciaaCode.notEquals'] = requestParameters.cciaaCodeNotEquals;
        }

        if (requestParameters.cciaaCodeSpecified !== undefined) {
            queryParameters['cciaaCode.specified'] = requestParameters.cciaaCodeSpecified;
        }

        if (requestParameters.cciaaCodeIn) {
            queryParameters['cciaaCode.in'] = requestParameters.cciaaCodeIn;
        }

        if (requestParameters.cciaaCodeNotIn) {
            queryParameters['cciaaCode.notIn'] = requestParameters.cciaaCodeNotIn;
        }

        if (requestParameters.legalNatureContains !== undefined) {
            queryParameters['legalNature.contains'] = requestParameters.legalNatureContains;
        }

        if (requestParameters.legalNatureDoesNotContain !== undefined) {
            queryParameters['legalNature.doesNotContain'] = requestParameters.legalNatureDoesNotContain;
        }

        if (requestParameters.legalNatureEquals !== undefined) {
            queryParameters['legalNature.equals'] = requestParameters.legalNatureEquals;
        }

        if (requestParameters.legalNatureNotEquals !== undefined) {
            queryParameters['legalNature.notEquals'] = requestParameters.legalNatureNotEquals;
        }

        if (requestParameters.legalNatureSpecified !== undefined) {
            queryParameters['legalNature.specified'] = requestParameters.legalNatureSpecified;
        }

        if (requestParameters.legalNatureIn) {
            queryParameters['legalNature.in'] = requestParameters.legalNatureIn;
        }

        if (requestParameters.legalNatureNotIn) {
            queryParameters['legalNature.notIn'] = requestParameters.legalNatureNotIn;
        }

        if (requestParameters.sourceEquals !== undefined) {
            queryParameters['source.equals'] = requestParameters.sourceEquals;
        }

        if (requestParameters.sourceNotEquals !== undefined) {
            queryParameters['source.notEquals'] = requestParameters.sourceNotEquals;
        }

        if (requestParameters.sourceSpecified !== undefined) {
            queryParameters['source.specified'] = requestParameters.sourceSpecified;
        }

        if (requestParameters.sourceIn) {
            queryParameters['source.in'] = requestParameters.sourceIn;
        }

        if (requestParameters.sourceNotIn) {
            queryParameters['source.notIn'] = requestParameters.sourceNotIn;
        }

        if (requestParameters.websiteContains !== undefined) {
            queryParameters['website.contains'] = requestParameters.websiteContains;
        }

        if (requestParameters.websiteDoesNotContain !== undefined) {
            queryParameters['website.doesNotContain'] = requestParameters.websiteDoesNotContain;
        }

        if (requestParameters.websiteEquals !== undefined) {
            queryParameters['website.equals'] = requestParameters.websiteEquals;
        }

        if (requestParameters.websiteNotEquals !== undefined) {
            queryParameters['website.notEquals'] = requestParameters.websiteNotEquals;
        }

        if (requestParameters.websiteSpecified !== undefined) {
            queryParameters['website.specified'] = requestParameters.websiteSpecified;
        }

        if (requestParameters.websiteIn) {
            queryParameters['website.in'] = requestParameters.websiteIn;
        }

        if (requestParameters.websiteNotIn) {
            queryParameters['website.notIn'] = requestParameters.websiteNotIn;
        }

        if (requestParameters.isMainAddressEquals !== undefined) {
            queryParameters['isMainAddress.equals'] = requestParameters.isMainAddressEquals;
        }

        if (requestParameters.isMainAddressNotEquals !== undefined) {
            queryParameters['isMainAddress.notEquals'] = requestParameters.isMainAddressNotEquals;
        }

        if (requestParameters.isMainAddressSpecified !== undefined) {
            queryParameters['isMainAddress.specified'] = requestParameters.isMainAddressSpecified;
        }

        if (requestParameters.isMainAddressIn) {
            queryParameters['isMainAddress.in'] = requestParameters.isMainAddressIn;
        }

        if (requestParameters.isMainAddressNotIn) {
            queryParameters['isMainAddress.notIn'] = requestParameters.isMainAddressNotIn;
        }

        if (requestParameters.companyAtecoIdGreaterThan !== undefined) {
            queryParameters['companyAtecoId.greaterThan'] = requestParameters.companyAtecoIdGreaterThan;
        }

        if (requestParameters.companyAtecoIdLessThan !== undefined) {
            queryParameters['companyAtecoId.lessThan'] = requestParameters.companyAtecoIdLessThan;
        }

        if (requestParameters.companyAtecoIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAtecoId.greaterThanOrEqual'] = requestParameters.companyAtecoIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAtecoIdLessThanOrEqual !== undefined) {
            queryParameters['companyAtecoId.lessThanOrEqual'] = requestParameters.companyAtecoIdLessThanOrEqual;
        }

        if (requestParameters.companyAtecoIdEquals !== undefined) {
            queryParameters['companyAtecoId.equals'] = requestParameters.companyAtecoIdEquals;
        }

        if (requestParameters.companyAtecoIdNotEquals !== undefined) {
            queryParameters['companyAtecoId.notEquals'] = requestParameters.companyAtecoIdNotEquals;
        }

        if (requestParameters.companyAtecoIdSpecified !== undefined) {
            queryParameters['companyAtecoId.specified'] = requestParameters.companyAtecoIdSpecified;
        }

        if (requestParameters.companyAtecoIdIn) {
            queryParameters['companyAtecoId.in'] = requestParameters.companyAtecoIdIn;
        }

        if (requestParameters.companyAtecoIdNotIn) {
            queryParameters['companyAtecoId.notIn'] = requestParameters.companyAtecoIdNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-addresses/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanyAddresses(requestParameters: CountCompanyAddressesRequest): Promise<number> {
        const response = await this.countCompanyAddressesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanyAddressRaw(requestParameters: CreateCompanyAddressRequest): Promise<runtime.ApiResponse<CompanyAddress>> {
        if (requestParameters.companyAddress === null || requestParameters.companyAddress === undefined) {
            throw new runtime.RequiredError('companyAddress','Required parameter requestParameters.companyAddress was null or undefined when calling createCompanyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAddressToJSON(requestParameters.companyAddress),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAddressFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyAddress(requestParameters: CreateCompanyAddressRequest): Promise<CompanyAddress> {
        const response = await this.createCompanyAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyAddressRaw(requestParameters: DeleteCompanyAddressRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompanyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyAddress(requestParameters: DeleteCompanyAddressRequest): Promise<void> {
        await this.deleteCompanyAddressRaw(requestParameters);
    }

    /**
     */
    async getAllCompanyAddressesRaw(requestParameters: GetAllCompanyAddressesRequest): Promise<runtime.ApiResponse<Array<CompanyAddress>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.progressiveNumberGreaterThan !== undefined) {
            queryParameters['progressiveNumber.greaterThan'] = requestParameters.progressiveNumberGreaterThan;
        }

        if (requestParameters.progressiveNumberLessThan !== undefined) {
            queryParameters['progressiveNumber.lessThan'] = requestParameters.progressiveNumberLessThan;
        }

        if (requestParameters.progressiveNumberGreaterThanOrEqual !== undefined) {
            queryParameters['progressiveNumber.greaterThanOrEqual'] = requestParameters.progressiveNumberGreaterThanOrEqual;
        }

        if (requestParameters.progressiveNumberLessThanOrEqual !== undefined) {
            queryParameters['progressiveNumber.lessThanOrEqual'] = requestParameters.progressiveNumberLessThanOrEqual;
        }

        if (requestParameters.progressiveNumberEquals !== undefined) {
            queryParameters['progressiveNumber.equals'] = requestParameters.progressiveNumberEquals;
        }

        if (requestParameters.progressiveNumberNotEquals !== undefined) {
            queryParameters['progressiveNumber.notEquals'] = requestParameters.progressiveNumberNotEquals;
        }

        if (requestParameters.progressiveNumberSpecified !== undefined) {
            queryParameters['progressiveNumber.specified'] = requestParameters.progressiveNumberSpecified;
        }

        if (requestParameters.progressiveNumberIn) {
            queryParameters['progressiveNumber.in'] = requestParameters.progressiveNumberIn;
        }

        if (requestParameters.progressiveNumberNotIn) {
            queryParameters['progressiveNumber.notIn'] = requestParameters.progressiveNumberNotIn;
        }

        if (requestParameters.streetToponymyContains !== undefined) {
            queryParameters['streetToponymy.contains'] = requestParameters.streetToponymyContains;
        }

        if (requestParameters.streetToponymyDoesNotContain !== undefined) {
            queryParameters['streetToponymy.doesNotContain'] = requestParameters.streetToponymyDoesNotContain;
        }

        if (requestParameters.streetToponymyEquals !== undefined) {
            queryParameters['streetToponymy.equals'] = requestParameters.streetToponymyEquals;
        }

        if (requestParameters.streetToponymyNotEquals !== undefined) {
            queryParameters['streetToponymy.notEquals'] = requestParameters.streetToponymyNotEquals;
        }

        if (requestParameters.streetToponymySpecified !== undefined) {
            queryParameters['streetToponymy.specified'] = requestParameters.streetToponymySpecified;
        }

        if (requestParameters.streetToponymyIn) {
            queryParameters['streetToponymy.in'] = requestParameters.streetToponymyIn;
        }

        if (requestParameters.streetToponymyNotIn) {
            queryParameters['streetToponymy.notIn'] = requestParameters.streetToponymyNotIn;
        }

        if (requestParameters.streetContains !== undefined) {
            queryParameters['street.contains'] = requestParameters.streetContains;
        }

        if (requestParameters.streetDoesNotContain !== undefined) {
            queryParameters['street.doesNotContain'] = requestParameters.streetDoesNotContain;
        }

        if (requestParameters.streetEquals !== undefined) {
            queryParameters['street.equals'] = requestParameters.streetEquals;
        }

        if (requestParameters.streetNotEquals !== undefined) {
            queryParameters['street.notEquals'] = requestParameters.streetNotEquals;
        }

        if (requestParameters.streetSpecified !== undefined) {
            queryParameters['street.specified'] = requestParameters.streetSpecified;
        }

        if (requestParameters.streetIn) {
            queryParameters['street.in'] = requestParameters.streetIn;
        }

        if (requestParameters.streetNotIn) {
            queryParameters['street.notIn'] = requestParameters.streetNotIn;
        }

        if (requestParameters.streetNumberContains !== undefined) {
            queryParameters['streetNumber.contains'] = requestParameters.streetNumberContains;
        }

        if (requestParameters.streetNumberDoesNotContain !== undefined) {
            queryParameters['streetNumber.doesNotContain'] = requestParameters.streetNumberDoesNotContain;
        }

        if (requestParameters.streetNumberEquals !== undefined) {
            queryParameters['streetNumber.equals'] = requestParameters.streetNumberEquals;
        }

        if (requestParameters.streetNumberNotEquals !== undefined) {
            queryParameters['streetNumber.notEquals'] = requestParameters.streetNumberNotEquals;
        }

        if (requestParameters.streetNumberSpecified !== undefined) {
            queryParameters['streetNumber.specified'] = requestParameters.streetNumberSpecified;
        }

        if (requestParameters.streetNumberIn) {
            queryParameters['streetNumber.in'] = requestParameters.streetNumberIn;
        }

        if (requestParameters.streetNumberNotIn) {
            queryParameters['streetNumber.notIn'] = requestParameters.streetNumberNotIn;
        }

        if (requestParameters.codComOfficesContains !== undefined) {
            queryParameters['codComOffices.contains'] = requestParameters.codComOfficesContains;
        }

        if (requestParameters.codComOfficesDoesNotContain !== undefined) {
            queryParameters['codComOffices.doesNotContain'] = requestParameters.codComOfficesDoesNotContain;
        }

        if (requestParameters.codComOfficesEquals !== undefined) {
            queryParameters['codComOffices.equals'] = requestParameters.codComOfficesEquals;
        }

        if (requestParameters.codComOfficesNotEquals !== undefined) {
            queryParameters['codComOffices.notEquals'] = requestParameters.codComOfficesNotEquals;
        }

        if (requestParameters.codComOfficesSpecified !== undefined) {
            queryParameters['codComOffices.specified'] = requestParameters.codComOfficesSpecified;
        }

        if (requestParameters.codComOfficesIn) {
            queryParameters['codComOffices.in'] = requestParameters.codComOfficesIn;
        }

        if (requestParameters.codComOfficesNotIn) {
            queryParameters['codComOffices.notIn'] = requestParameters.codComOfficesNotIn;
        }

        if (requestParameters.cityContains !== undefined) {
            queryParameters['city.contains'] = requestParameters.cityContains;
        }

        if (requestParameters.cityDoesNotContain !== undefined) {
            queryParameters['city.doesNotContain'] = requestParameters.cityDoesNotContain;
        }

        if (requestParameters.cityEquals !== undefined) {
            queryParameters['city.equals'] = requestParameters.cityEquals;
        }

        if (requestParameters.cityNotEquals !== undefined) {
            queryParameters['city.notEquals'] = requestParameters.cityNotEquals;
        }

        if (requestParameters.citySpecified !== undefined) {
            queryParameters['city.specified'] = requestParameters.citySpecified;
        }

        if (requestParameters.cityIn) {
            queryParameters['city.in'] = requestParameters.cityIn;
        }

        if (requestParameters.cityNotIn) {
            queryParameters['city.notIn'] = requestParameters.cityNotIn;
        }

        if (requestParameters.provinceContains !== undefined) {
            queryParameters['province.contains'] = requestParameters.provinceContains;
        }

        if (requestParameters.provinceDoesNotContain !== undefined) {
            queryParameters['province.doesNotContain'] = requestParameters.provinceDoesNotContain;
        }

        if (requestParameters.provinceEquals !== undefined) {
            queryParameters['province.equals'] = requestParameters.provinceEquals;
        }

        if (requestParameters.provinceNotEquals !== undefined) {
            queryParameters['province.notEquals'] = requestParameters.provinceNotEquals;
        }

        if (requestParameters.provinceSpecified !== undefined) {
            queryParameters['province.specified'] = requestParameters.provinceSpecified;
        }

        if (requestParameters.provinceIn) {
            queryParameters['province.in'] = requestParameters.provinceIn;
        }

        if (requestParameters.provinceNotIn) {
            queryParameters['province.notIn'] = requestParameters.provinceNotIn;
        }

        if (requestParameters.regionContains !== undefined) {
            queryParameters['region.contains'] = requestParameters.regionContains;
        }

        if (requestParameters.regionDoesNotContain !== undefined) {
            queryParameters['region.doesNotContain'] = requestParameters.regionDoesNotContain;
        }

        if (requestParameters.regionEquals !== undefined) {
            queryParameters['region.equals'] = requestParameters.regionEquals;
        }

        if (requestParameters.regionNotEquals !== undefined) {
            queryParameters['region.notEquals'] = requestParameters.regionNotEquals;
        }

        if (requestParameters.regionSpecified !== undefined) {
            queryParameters['region.specified'] = requestParameters.regionSpecified;
        }

        if (requestParameters.regionIn) {
            queryParameters['region.in'] = requestParameters.regionIn;
        }

        if (requestParameters.regionNotIn) {
            queryParameters['region.notIn'] = requestParameters.regionNotIn;
        }

        if (requestParameters.countryContains !== undefined) {
            queryParameters['country.contains'] = requestParameters.countryContains;
        }

        if (requestParameters.countryDoesNotContain !== undefined) {
            queryParameters['country.doesNotContain'] = requestParameters.countryDoesNotContain;
        }

        if (requestParameters.countryEquals !== undefined) {
            queryParameters['country.equals'] = requestParameters.countryEquals;
        }

        if (requestParameters.countryNotEquals !== undefined) {
            queryParameters['country.notEquals'] = requestParameters.countryNotEquals;
        }

        if (requestParameters.countrySpecified !== undefined) {
            queryParameters['country.specified'] = requestParameters.countrySpecified;
        }

        if (requestParameters.countryIn) {
            queryParameters['country.in'] = requestParameters.countryIn;
        }

        if (requestParameters.countryNotIn) {
            queryParameters['country.notIn'] = requestParameters.countryNotIn;
        }

        if (requestParameters.zipCodeContains !== undefined) {
            queryParameters['zipCode.contains'] = requestParameters.zipCodeContains;
        }

        if (requestParameters.zipCodeDoesNotContain !== undefined) {
            queryParameters['zipCode.doesNotContain'] = requestParameters.zipCodeDoesNotContain;
        }

        if (requestParameters.zipCodeEquals !== undefined) {
            queryParameters['zipCode.equals'] = requestParameters.zipCodeEquals;
        }

        if (requestParameters.zipCodeNotEquals !== undefined) {
            queryParameters['zipCode.notEquals'] = requestParameters.zipCodeNotEquals;
        }

        if (requestParameters.zipCodeSpecified !== undefined) {
            queryParameters['zipCode.specified'] = requestParameters.zipCodeSpecified;
        }

        if (requestParameters.zipCodeIn) {
            queryParameters['zipCode.in'] = requestParameters.zipCodeIn;
        }

        if (requestParameters.zipCodeNotIn) {
            queryParameters['zipCode.notIn'] = requestParameters.zipCodeNotIn;
        }

        if (requestParameters.legalStatusEquals !== undefined) {
            queryParameters['legalStatus.equals'] = requestParameters.legalStatusEquals;
        }

        if (requestParameters.legalStatusNotEquals !== undefined) {
            queryParameters['legalStatus.notEquals'] = requestParameters.legalStatusNotEquals;
        }

        if (requestParameters.legalStatusSpecified !== undefined) {
            queryParameters['legalStatus.specified'] = requestParameters.legalStatusSpecified;
        }

        if (requestParameters.legalStatusIn) {
            queryParameters['legalStatus.in'] = requestParameters.legalStatusIn;
        }

        if (requestParameters.legalStatusNotIn) {
            queryParameters['legalStatus.notIn'] = requestParameters.legalStatusNotIn;
        }

        if (requestParameters.activityStatusEquals !== undefined) {
            queryParameters['activityStatus.equals'] = requestParameters.activityStatusEquals;
        }

        if (requestParameters.activityStatusNotEquals !== undefined) {
            queryParameters['activityStatus.notEquals'] = requestParameters.activityStatusNotEquals;
        }

        if (requestParameters.activityStatusSpecified !== undefined) {
            queryParameters['activityStatus.specified'] = requestParameters.activityStatusSpecified;
        }

        if (requestParameters.activityStatusIn) {
            queryParameters['activityStatus.in'] = requestParameters.activityStatusIn;
        }

        if (requestParameters.activityStatusNotIn) {
            queryParameters['activityStatus.notIn'] = requestParameters.activityStatusNotIn;
        }

        if (requestParameters.sectorContains !== undefined) {
            queryParameters['sector.contains'] = requestParameters.sectorContains;
        }

        if (requestParameters.sectorDoesNotContain !== undefined) {
            queryParameters['sector.doesNotContain'] = requestParameters.sectorDoesNotContain;
        }

        if (requestParameters.sectorEquals !== undefined) {
            queryParameters['sector.equals'] = requestParameters.sectorEquals;
        }

        if (requestParameters.sectorNotEquals !== undefined) {
            queryParameters['sector.notEquals'] = requestParameters.sectorNotEquals;
        }

        if (requestParameters.sectorSpecified !== undefined) {
            queryParameters['sector.specified'] = requestParameters.sectorSpecified;
        }

        if (requestParameters.sectorIn) {
            queryParameters['sector.in'] = requestParameters.sectorIn;
        }

        if (requestParameters.sectorNotIn) {
            queryParameters['sector.notIn'] = requestParameters.sectorNotIn;
        }

        if (requestParameters.telephoneContains !== undefined) {
            queryParameters['telephone.contains'] = requestParameters.telephoneContains;
        }

        if (requestParameters.telephoneDoesNotContain !== undefined) {
            queryParameters['telephone.doesNotContain'] = requestParameters.telephoneDoesNotContain;
        }

        if (requestParameters.telephoneEquals !== undefined) {
            queryParameters['telephone.equals'] = requestParameters.telephoneEquals;
        }

        if (requestParameters.telephoneNotEquals !== undefined) {
            queryParameters['telephone.notEquals'] = requestParameters.telephoneNotEquals;
        }

        if (requestParameters.telephoneSpecified !== undefined) {
            queryParameters['telephone.specified'] = requestParameters.telephoneSpecified;
        }

        if (requestParameters.telephoneIn) {
            queryParameters['telephone.in'] = requestParameters.telephoneIn;
        }

        if (requestParameters.telephoneNotIn) {
            queryParameters['telephone.notIn'] = requestParameters.telephoneNotIn;
        }

        if (requestParameters.emailContains !== undefined) {
            queryParameters['email.contains'] = requestParameters.emailContains;
        }

        if (requestParameters.emailDoesNotContain !== undefined) {
            queryParameters['email.doesNotContain'] = requestParameters.emailDoesNotContain;
        }

        if (requestParameters.emailEquals !== undefined) {
            queryParameters['email.equals'] = requestParameters.emailEquals;
        }

        if (requestParameters.emailNotEquals !== undefined) {
            queryParameters['email.notEquals'] = requestParameters.emailNotEquals;
        }

        if (requestParameters.emailSpecified !== undefined) {
            queryParameters['email.specified'] = requestParameters.emailSpecified;
        }

        if (requestParameters.emailIn) {
            queryParameters['email.in'] = requestParameters.emailIn;
        }

        if (requestParameters.emailNotIn) {
            queryParameters['email.notIn'] = requestParameters.emailNotIn;
        }

        if (requestParameters.emailPECContains !== undefined) {
            queryParameters['emailPEC.contains'] = requestParameters.emailPECContains;
        }

        if (requestParameters.emailPECDoesNotContain !== undefined) {
            queryParameters['emailPEC.doesNotContain'] = requestParameters.emailPECDoesNotContain;
        }

        if (requestParameters.emailPECEquals !== undefined) {
            queryParameters['emailPEC.equals'] = requestParameters.emailPECEquals;
        }

        if (requestParameters.emailPECNotEquals !== undefined) {
            queryParameters['emailPEC.notEquals'] = requestParameters.emailPECNotEquals;
        }

        if (requestParameters.emailPECSpecified !== undefined) {
            queryParameters['emailPEC.specified'] = requestParameters.emailPECSpecified;
        }

        if (requestParameters.emailPECIn) {
            queryParameters['emailPEC.in'] = requestParameters.emailPECIn;
        }

        if (requestParameters.emailPECNotIn) {
            queryParameters['emailPEC.notIn'] = requestParameters.emailPECNotIn;
        }

        if (requestParameters.nreaCodeContains !== undefined) {
            queryParameters['nreaCode.contains'] = requestParameters.nreaCodeContains;
        }

        if (requestParameters.nreaCodeDoesNotContain !== undefined) {
            queryParameters['nreaCode.doesNotContain'] = requestParameters.nreaCodeDoesNotContain;
        }

        if (requestParameters.nreaCodeEquals !== undefined) {
            queryParameters['nreaCode.equals'] = requestParameters.nreaCodeEquals;
        }

        if (requestParameters.nreaCodeNotEquals !== undefined) {
            queryParameters['nreaCode.notEquals'] = requestParameters.nreaCodeNotEquals;
        }

        if (requestParameters.nreaCodeSpecified !== undefined) {
            queryParameters['nreaCode.specified'] = requestParameters.nreaCodeSpecified;
        }

        if (requestParameters.nreaCodeIn) {
            queryParameters['nreaCode.in'] = requestParameters.nreaCodeIn;
        }

        if (requestParameters.nreaCodeNotIn) {
            queryParameters['nreaCode.notIn'] = requestParameters.nreaCodeNotIn;
        }

        if (requestParameters.cciaaCodeContains !== undefined) {
            queryParameters['cciaaCode.contains'] = requestParameters.cciaaCodeContains;
        }

        if (requestParameters.cciaaCodeDoesNotContain !== undefined) {
            queryParameters['cciaaCode.doesNotContain'] = requestParameters.cciaaCodeDoesNotContain;
        }

        if (requestParameters.cciaaCodeEquals !== undefined) {
            queryParameters['cciaaCode.equals'] = requestParameters.cciaaCodeEquals;
        }

        if (requestParameters.cciaaCodeNotEquals !== undefined) {
            queryParameters['cciaaCode.notEquals'] = requestParameters.cciaaCodeNotEquals;
        }

        if (requestParameters.cciaaCodeSpecified !== undefined) {
            queryParameters['cciaaCode.specified'] = requestParameters.cciaaCodeSpecified;
        }

        if (requestParameters.cciaaCodeIn) {
            queryParameters['cciaaCode.in'] = requestParameters.cciaaCodeIn;
        }

        if (requestParameters.cciaaCodeNotIn) {
            queryParameters['cciaaCode.notIn'] = requestParameters.cciaaCodeNotIn;
        }

        if (requestParameters.legalNatureContains !== undefined) {
            queryParameters['legalNature.contains'] = requestParameters.legalNatureContains;
        }

        if (requestParameters.legalNatureDoesNotContain !== undefined) {
            queryParameters['legalNature.doesNotContain'] = requestParameters.legalNatureDoesNotContain;
        }

        if (requestParameters.legalNatureEquals !== undefined) {
            queryParameters['legalNature.equals'] = requestParameters.legalNatureEquals;
        }

        if (requestParameters.legalNatureNotEquals !== undefined) {
            queryParameters['legalNature.notEquals'] = requestParameters.legalNatureNotEquals;
        }

        if (requestParameters.legalNatureSpecified !== undefined) {
            queryParameters['legalNature.specified'] = requestParameters.legalNatureSpecified;
        }

        if (requestParameters.legalNatureIn) {
            queryParameters['legalNature.in'] = requestParameters.legalNatureIn;
        }

        if (requestParameters.legalNatureNotIn) {
            queryParameters['legalNature.notIn'] = requestParameters.legalNatureNotIn;
        }

        if (requestParameters.sourceEquals !== undefined) {
            queryParameters['source.equals'] = requestParameters.sourceEquals;
        }

        if (requestParameters.sourceNotEquals !== undefined) {
            queryParameters['source.notEquals'] = requestParameters.sourceNotEquals;
        }

        if (requestParameters.sourceSpecified !== undefined) {
            queryParameters['source.specified'] = requestParameters.sourceSpecified;
        }

        if (requestParameters.sourceIn) {
            queryParameters['source.in'] = requestParameters.sourceIn;
        }

        if (requestParameters.sourceNotIn) {
            queryParameters['source.notIn'] = requestParameters.sourceNotIn;
        }

        if (requestParameters.websiteContains !== undefined) {
            queryParameters['website.contains'] = requestParameters.websiteContains;
        }

        if (requestParameters.websiteDoesNotContain !== undefined) {
            queryParameters['website.doesNotContain'] = requestParameters.websiteDoesNotContain;
        }

        if (requestParameters.websiteEquals !== undefined) {
            queryParameters['website.equals'] = requestParameters.websiteEquals;
        }

        if (requestParameters.websiteNotEquals !== undefined) {
            queryParameters['website.notEquals'] = requestParameters.websiteNotEquals;
        }

        if (requestParameters.websiteSpecified !== undefined) {
            queryParameters['website.specified'] = requestParameters.websiteSpecified;
        }

        if (requestParameters.websiteIn) {
            queryParameters['website.in'] = requestParameters.websiteIn;
        }

        if (requestParameters.websiteNotIn) {
            queryParameters['website.notIn'] = requestParameters.websiteNotIn;
        }

        if (requestParameters.isMainAddressEquals !== undefined) {
            queryParameters['isMainAddress.equals'] = requestParameters.isMainAddressEquals;
        }

        if (requestParameters.isMainAddressNotEquals !== undefined) {
            queryParameters['isMainAddress.notEquals'] = requestParameters.isMainAddressNotEquals;
        }

        if (requestParameters.isMainAddressSpecified !== undefined) {
            queryParameters['isMainAddress.specified'] = requestParameters.isMainAddressSpecified;
        }

        if (requestParameters.isMainAddressIn) {
            queryParameters['isMainAddress.in'] = requestParameters.isMainAddressIn;
        }

        if (requestParameters.isMainAddressNotIn) {
            queryParameters['isMainAddress.notIn'] = requestParameters.isMainAddressNotIn;
        }

        if (requestParameters.companyAtecoIdGreaterThan !== undefined) {
            queryParameters['companyAtecoId.greaterThan'] = requestParameters.companyAtecoIdGreaterThan;
        }

        if (requestParameters.companyAtecoIdLessThan !== undefined) {
            queryParameters['companyAtecoId.lessThan'] = requestParameters.companyAtecoIdLessThan;
        }

        if (requestParameters.companyAtecoIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyAtecoId.greaterThanOrEqual'] = requestParameters.companyAtecoIdGreaterThanOrEqual;
        }

        if (requestParameters.companyAtecoIdLessThanOrEqual !== undefined) {
            queryParameters['companyAtecoId.lessThanOrEqual'] = requestParameters.companyAtecoIdLessThanOrEqual;
        }

        if (requestParameters.companyAtecoIdEquals !== undefined) {
            queryParameters['companyAtecoId.equals'] = requestParameters.companyAtecoIdEquals;
        }

        if (requestParameters.companyAtecoIdNotEquals !== undefined) {
            queryParameters['companyAtecoId.notEquals'] = requestParameters.companyAtecoIdNotEquals;
        }

        if (requestParameters.companyAtecoIdSpecified !== undefined) {
            queryParameters['companyAtecoId.specified'] = requestParameters.companyAtecoIdSpecified;
        }

        if (requestParameters.companyAtecoIdIn) {
            queryParameters['companyAtecoId.in'] = requestParameters.companyAtecoIdIn;
        }

        if (requestParameters.companyAtecoIdNotIn) {
            queryParameters['companyAtecoId.notIn'] = requestParameters.companyAtecoIdNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyAddressFromJSON));
    }

    /**
     */
    async getAllCompanyAddresses(requestParameters: GetAllCompanyAddressesRequest): Promise<Array<CompanyAddress>> {
        const response = await this.getAllCompanyAddressesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyAddressRaw(requestParameters: GetCompanyAddressRequest): Promise<runtime.ApiResponse<CompanyAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/company-addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAddressFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyAddress(requestParameters: GetCompanyAddressRequest): Promise<CompanyAddress> {
        const response = await this.getCompanyAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyAddressRaw(requestParameters: PartialUpdateCompanyAddressRequest): Promise<runtime.ApiResponse<CompanyAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompanyAddress.');
        }

        if (requestParameters.companyAddress === null || requestParameters.companyAddress === undefined) {
            throw new runtime.RequiredError('companyAddress','Required parameter requestParameters.companyAddress was null or undefined when calling partialUpdateCompanyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAddressToJSON(requestParameters.companyAddress),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAddressFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompanyAddress(requestParameters: PartialUpdateCompanyAddressRequest): Promise<CompanyAddress> {
        const response = await this.partialUpdateCompanyAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyAddressRaw(requestParameters: UpdateCompanyAddressRequest): Promise<runtime.ApiResponse<CompanyAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompanyAddress.');
        }

        if (requestParameters.companyAddress === null || requestParameters.companyAddress === undefined) {
            throw new runtime.RequiredError('companyAddress','Required parameter requestParameters.companyAddress was null or undefined when calling updateCompanyAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/company-addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAddressToJSON(requestParameters.companyAddress),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAddressFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyAddress(requestParameters: UpdateCompanyAddressRequest): Promise<CompanyAddress> {
        const response = await this.updateCompanyAddressRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesLegalStatusEqualsEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesLegalStatusNotEqualsEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesLegalStatusInEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesLegalStatusNotInEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesActivityStatusEqualsEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesActivityStatusNotEqualsEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesActivityStatusInEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesActivityStatusNotInEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesSourceEqualsEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesSourceNotEqualsEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesSourceInEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompanyAddressesSourceNotInEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesLegalStatusEqualsEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesLegalStatusNotEqualsEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesLegalStatusInEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesLegalStatusNotInEnum {
    P = 'P',
    C = 'C',
    R = 'R'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesActivityStatusEqualsEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesActivityStatusNotEqualsEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesActivityStatusInEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesActivityStatusNotInEnum {
    A = 'A',
    I = 'I',
    S = 'S'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesSourceEqualsEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesSourceNotEqualsEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesSourceInEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompanyAddressesSourceNotInEnum {
    Pr = 'PR',
    Rd = 'RD',
    Ri = 'RI'
}
