/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DichiarazionePmi,
    DichiarazionePmiFromJSON,
    DichiarazionePmiFromJSONTyped,
    DichiarazionePmiToJSON,
} from './';

/**
 * 
 * @export
 * @interface DichiarazioniPmi
 */
export interface DichiarazioniPmi {
    /**
     * 
     * @type {Array<DichiarazionePmi>}
     * @memberof DichiarazioniPmi
     */
    dichiarazionePmi: Array<DichiarazionePmi>;
}

export function DichiarazioniPmiFromJSON(json: any): DichiarazioniPmi {
    return DichiarazioniPmiFromJSONTyped(json, false);
}

export function DichiarazioniPmiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DichiarazioniPmi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dichiarazionePmi': ((json['dichiarazionePmi'] as Array<any>).map(DichiarazionePmiFromJSON)),
    };
}

export function DichiarazioniPmiToJSON(value?: DichiarazioniPmi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dichiarazionePmi': ((value.dichiarazionePmi as Array<any>).map(DichiarazionePmiToJSON)),
    };
}


