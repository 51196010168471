/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Insight,
    InsightFromJSON,
    InsightToJSON,
} from '../models';

export interface CountInsightsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    titleContains?: string;
    titleDoesNotContain?: string;
    titleEquals?: string;
    titleNotEquals?: string;
    titleSpecified?: boolean;
    titleIn?: Array<string>;
    titleNotIn?: Array<string>;
    descriptionContains?: string;
    descriptionDoesNotContain?: string;
    descriptionEquals?: string;
    descriptionNotEquals?: string;
    descriptionSpecified?: boolean;
    descriptionIn?: Array<string>;
    descriptionNotIn?: Array<string>;
    createdAtGreaterThan?: Date;
    createdAtLessThan?: Date;
    createdAtGreaterThanOrEqual?: Date;
    createdAtLessThanOrEqual?: Date;
    createdAtEquals?: Date;
    createdAtNotEquals?: Date;
    createdAtSpecified?: boolean;
    createdAtIn?: Array<Date>;
    createdAtNotIn?: Array<Date>;
    likeCountGreaterThan?: number;
    likeCountLessThan?: number;
    likeCountGreaterThanOrEqual?: number;
    likeCountLessThanOrEqual?: number;
    likeCountEquals?: number;
    likeCountNotEquals?: number;
    likeCountSpecified?: boolean;
    likeCountIn?: Array<number>;
    likeCountNotIn?: Array<number>;
    dislikeCountGreaterThan?: number;
    dislikeCountLessThan?: number;
    dislikeCountGreaterThanOrEqual?: number;
    dislikeCountLessThanOrEqual?: number;
    dislikeCountEquals?: number;
    dislikeCountNotEquals?: number;
    dislikeCountSpecified?: boolean;
    dislikeCountIn?: Array<number>;
    dislikeCountNotIn?: Array<number>;
    dateOfPublicationGreaterThan?: Date;
    dateOfPublicationLessThan?: Date;
    dateOfPublicationGreaterThanOrEqual?: Date;
    dateOfPublicationLessThanOrEqual?: Date;
    dateOfPublicationEquals?: Date;
    dateOfPublicationNotEquals?: Date;
    dateOfPublicationSpecified?: boolean;
    dateOfPublicationIn?: Array<Date>;
    dateOfPublicationNotIn?: Array<Date>;
    currentInsightStateEquals?: CountInsightsCurrentInsightStateEqualsEnum;
    currentInsightStateNotEquals?: CountInsightsCurrentInsightStateNotEqualsEnum;
    currentInsightStateSpecified?: boolean;
    currentInsightStateIn?: Array<CountInsightsCurrentInsightStateInEnum>;
    currentInsightStateNotIn?: Array<CountInsightsCurrentInsightStateNotInEnum>;
    insightLikeIdGreaterThan?: number;
    insightLikeIdLessThan?: number;
    insightLikeIdGreaterThanOrEqual?: number;
    insightLikeIdLessThanOrEqual?: number;
    insightLikeIdEquals?: number;
    insightLikeIdNotEquals?: number;
    insightLikeIdSpecified?: boolean;
    insightLikeIdIn?: Array<number>;
    insightLikeIdNotIn?: Array<number>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    publisherIdGreaterThan?: number;
    publisherIdLessThan?: number;
    publisherIdGreaterThanOrEqual?: number;
    publisherIdLessThanOrEqual?: number;
    publisherIdEquals?: number;
    publisherIdNotEquals?: number;
    publisherIdSpecified?: boolean;
    publisherIdIn?: Array<number>;
    publisherIdNotIn?: Array<number>;
    recipientIdGreaterThan?: number;
    recipientIdLessThan?: number;
    recipientIdGreaterThanOrEqual?: number;
    recipientIdLessThanOrEqual?: number;
    recipientIdEquals?: number;
    recipientIdNotEquals?: number;
    recipientIdSpecified?: boolean;
    recipientIdIn?: Array<number>;
    recipientIdNotIn?: Array<number>;
    insightStateIdGreaterThan?: number;
    insightStateIdLessThan?: number;
    insightStateIdGreaterThanOrEqual?: number;
    insightStateIdLessThanOrEqual?: number;
    insightStateIdEquals?: number;
    insightStateIdNotEquals?: number;
    insightStateIdSpecified?: boolean;
    insightStateIdIn?: Array<number>;
    insightStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateInsightRequest {
    insight: Insight;
}

export interface DeleteInsightRequest {
    id: number;
}

export interface GetAllInsightsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    titleContains?: string;
    titleDoesNotContain?: string;
    titleEquals?: string;
    titleNotEquals?: string;
    titleSpecified?: boolean;
    titleIn?: Array<string>;
    titleNotIn?: Array<string>;
    descriptionContains?: string;
    descriptionDoesNotContain?: string;
    descriptionEquals?: string;
    descriptionNotEquals?: string;
    descriptionSpecified?: boolean;
    descriptionIn?: Array<string>;
    descriptionNotIn?: Array<string>;
    createdAtGreaterThan?: Date;
    createdAtLessThan?: Date;
    createdAtGreaterThanOrEqual?: Date;
    createdAtLessThanOrEqual?: Date;
    createdAtEquals?: Date;
    createdAtNotEquals?: Date;
    createdAtSpecified?: boolean;
    createdAtIn?: Array<Date>;
    createdAtNotIn?: Array<Date>;
    likeCountGreaterThan?: number;
    likeCountLessThan?: number;
    likeCountGreaterThanOrEqual?: number;
    likeCountLessThanOrEqual?: number;
    likeCountEquals?: number;
    likeCountNotEquals?: number;
    likeCountSpecified?: boolean;
    likeCountIn?: Array<number>;
    likeCountNotIn?: Array<number>;
    dislikeCountGreaterThan?: number;
    dislikeCountLessThan?: number;
    dislikeCountGreaterThanOrEqual?: number;
    dislikeCountLessThanOrEqual?: number;
    dislikeCountEquals?: number;
    dislikeCountNotEquals?: number;
    dislikeCountSpecified?: boolean;
    dislikeCountIn?: Array<number>;
    dislikeCountNotIn?: Array<number>;
    dateOfPublicationGreaterThan?: Date;
    dateOfPublicationLessThan?: Date;
    dateOfPublicationGreaterThanOrEqual?: Date;
    dateOfPublicationLessThanOrEqual?: Date;
    dateOfPublicationEquals?: Date;
    dateOfPublicationNotEquals?: Date;
    dateOfPublicationSpecified?: boolean;
    dateOfPublicationIn?: Array<Date>;
    dateOfPublicationNotIn?: Array<Date>;
    currentInsightStateEquals?: GetAllInsightsCurrentInsightStateEqualsEnum;
    currentInsightStateNotEquals?: GetAllInsightsCurrentInsightStateNotEqualsEnum;
    currentInsightStateSpecified?: boolean;
    currentInsightStateIn?: Array<GetAllInsightsCurrentInsightStateInEnum>;
    currentInsightStateNotIn?: Array<GetAllInsightsCurrentInsightStateNotInEnum>;
    insightLikeIdGreaterThan?: number;
    insightLikeIdLessThan?: number;
    insightLikeIdGreaterThanOrEqual?: number;
    insightLikeIdLessThanOrEqual?: number;
    insightLikeIdEquals?: number;
    insightLikeIdNotEquals?: number;
    insightLikeIdSpecified?: boolean;
    insightLikeIdIn?: Array<number>;
    insightLikeIdNotIn?: Array<number>;
    authorIdGreaterThan?: number;
    authorIdLessThan?: number;
    authorIdGreaterThanOrEqual?: number;
    authorIdLessThanOrEqual?: number;
    authorIdEquals?: number;
    authorIdNotEquals?: number;
    authorIdSpecified?: boolean;
    authorIdIn?: Array<number>;
    authorIdNotIn?: Array<number>;
    publisherIdGreaterThan?: number;
    publisherIdLessThan?: number;
    publisherIdGreaterThanOrEqual?: number;
    publisherIdLessThanOrEqual?: number;
    publisherIdEquals?: number;
    publisherIdNotEquals?: number;
    publisherIdSpecified?: boolean;
    publisherIdIn?: Array<number>;
    publisherIdNotIn?: Array<number>;
    recipientIdGreaterThan?: number;
    recipientIdLessThan?: number;
    recipientIdGreaterThanOrEqual?: number;
    recipientIdLessThanOrEqual?: number;
    recipientIdEquals?: number;
    recipientIdNotEquals?: number;
    recipientIdSpecified?: boolean;
    recipientIdIn?: Array<number>;
    recipientIdNotIn?: Array<number>;
    insightStateIdGreaterThan?: number;
    insightStateIdLessThan?: number;
    insightStateIdGreaterThanOrEqual?: number;
    insightStateIdLessThanOrEqual?: number;
    insightStateIdEquals?: number;
    insightStateIdNotEquals?: number;
    insightStateIdSpecified?: boolean;
    insightStateIdIn?: Array<number>;
    insightStateIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetInsightRequest {
    id: number;
}

export interface PartialUpdateInsightRequest {
    id: number;
    insight: Insight;
}

export interface UpdateInsightRequest {
    id: number;
    insight: Insight;
}

/**
 * 
 */
export class InsightResourceApi extends runtime.BaseAPI {

    /**
     */
    async countInsightsRaw(requestParameters: CountInsightsRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.titleContains !== undefined) {
            queryParameters['title.contains'] = requestParameters.titleContains;
        }

        if (requestParameters.titleDoesNotContain !== undefined) {
            queryParameters['title.doesNotContain'] = requestParameters.titleDoesNotContain;
        }

        if (requestParameters.titleEquals !== undefined) {
            queryParameters['title.equals'] = requestParameters.titleEquals;
        }

        if (requestParameters.titleNotEquals !== undefined) {
            queryParameters['title.notEquals'] = requestParameters.titleNotEquals;
        }

        if (requestParameters.titleSpecified !== undefined) {
            queryParameters['title.specified'] = requestParameters.titleSpecified;
        }

        if (requestParameters.titleIn) {
            queryParameters['title.in'] = requestParameters.titleIn;
        }

        if (requestParameters.titleNotIn) {
            queryParameters['title.notIn'] = requestParameters.titleNotIn;
        }

        if (requestParameters.descriptionContains !== undefined) {
            queryParameters['description.contains'] = requestParameters.descriptionContains;
        }

        if (requestParameters.descriptionDoesNotContain !== undefined) {
            queryParameters['description.doesNotContain'] = requestParameters.descriptionDoesNotContain;
        }

        if (requestParameters.descriptionEquals !== undefined) {
            queryParameters['description.equals'] = requestParameters.descriptionEquals;
        }

        if (requestParameters.descriptionNotEquals !== undefined) {
            queryParameters['description.notEquals'] = requestParameters.descriptionNotEquals;
        }

        if (requestParameters.descriptionSpecified !== undefined) {
            queryParameters['description.specified'] = requestParameters.descriptionSpecified;
        }

        if (requestParameters.descriptionIn) {
            queryParameters['description.in'] = requestParameters.descriptionIn;
        }

        if (requestParameters.descriptionNotIn) {
            queryParameters['description.notIn'] = requestParameters.descriptionNotIn;
        }

        if (requestParameters.createdAtGreaterThan !== undefined) {
            queryParameters['createdAt.greaterThan'] = (requestParameters.createdAtGreaterThan as any).toISOString();
        }

        if (requestParameters.createdAtLessThan !== undefined) {
            queryParameters['createdAt.lessThan'] = (requestParameters.createdAtLessThan as any).toISOString();
        }

        if (requestParameters.createdAtGreaterThanOrEqual !== undefined) {
            queryParameters['createdAt.greaterThanOrEqual'] = (requestParameters.createdAtGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.createdAtLessThanOrEqual !== undefined) {
            queryParameters['createdAt.lessThanOrEqual'] = (requestParameters.createdAtLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.createdAtEquals !== undefined) {
            queryParameters['createdAt.equals'] = (requestParameters.createdAtEquals as any).toISOString();
        }

        if (requestParameters.createdAtNotEquals !== undefined) {
            queryParameters['createdAt.notEquals'] = (requestParameters.createdAtNotEquals as any).toISOString();
        }

        if (requestParameters.createdAtSpecified !== undefined) {
            queryParameters['createdAt.specified'] = requestParameters.createdAtSpecified;
        }

        if (requestParameters.createdAtIn) {
            queryParameters['createdAt.in'] = requestParameters.createdAtIn;
        }

        if (requestParameters.createdAtNotIn) {
            queryParameters['createdAt.notIn'] = requestParameters.createdAtNotIn;
        }

        if (requestParameters.likeCountGreaterThan !== undefined) {
            queryParameters['likeCount.greaterThan'] = requestParameters.likeCountGreaterThan;
        }

        if (requestParameters.likeCountLessThan !== undefined) {
            queryParameters['likeCount.lessThan'] = requestParameters.likeCountLessThan;
        }

        if (requestParameters.likeCountGreaterThanOrEqual !== undefined) {
            queryParameters['likeCount.greaterThanOrEqual'] = requestParameters.likeCountGreaterThanOrEqual;
        }

        if (requestParameters.likeCountLessThanOrEqual !== undefined) {
            queryParameters['likeCount.lessThanOrEqual'] = requestParameters.likeCountLessThanOrEqual;
        }

        if (requestParameters.likeCountEquals !== undefined) {
            queryParameters['likeCount.equals'] = requestParameters.likeCountEquals;
        }

        if (requestParameters.likeCountNotEquals !== undefined) {
            queryParameters['likeCount.notEquals'] = requestParameters.likeCountNotEquals;
        }

        if (requestParameters.likeCountSpecified !== undefined) {
            queryParameters['likeCount.specified'] = requestParameters.likeCountSpecified;
        }

        if (requestParameters.likeCountIn) {
            queryParameters['likeCount.in'] = requestParameters.likeCountIn;
        }

        if (requestParameters.likeCountNotIn) {
            queryParameters['likeCount.notIn'] = requestParameters.likeCountNotIn;
        }

        if (requestParameters.dislikeCountGreaterThan !== undefined) {
            queryParameters['dislikeCount.greaterThan'] = requestParameters.dislikeCountGreaterThan;
        }

        if (requestParameters.dislikeCountLessThan !== undefined) {
            queryParameters['dislikeCount.lessThan'] = requestParameters.dislikeCountLessThan;
        }

        if (requestParameters.dislikeCountGreaterThanOrEqual !== undefined) {
            queryParameters['dislikeCount.greaterThanOrEqual'] = requestParameters.dislikeCountGreaterThanOrEqual;
        }

        if (requestParameters.dislikeCountLessThanOrEqual !== undefined) {
            queryParameters['dislikeCount.lessThanOrEqual'] = requestParameters.dislikeCountLessThanOrEqual;
        }

        if (requestParameters.dislikeCountEquals !== undefined) {
            queryParameters['dislikeCount.equals'] = requestParameters.dislikeCountEquals;
        }

        if (requestParameters.dislikeCountNotEquals !== undefined) {
            queryParameters['dislikeCount.notEquals'] = requestParameters.dislikeCountNotEquals;
        }

        if (requestParameters.dislikeCountSpecified !== undefined) {
            queryParameters['dislikeCount.specified'] = requestParameters.dislikeCountSpecified;
        }

        if (requestParameters.dislikeCountIn) {
            queryParameters['dislikeCount.in'] = requestParameters.dislikeCountIn;
        }

        if (requestParameters.dislikeCountNotIn) {
            queryParameters['dislikeCount.notIn'] = requestParameters.dislikeCountNotIn;
        }

        if (requestParameters.dateOfPublicationGreaterThan !== undefined) {
            queryParameters['dateOfPublication.greaterThan'] = (requestParameters.dateOfPublicationGreaterThan as any).toISOString();
        }

        if (requestParameters.dateOfPublicationLessThan !== undefined) {
            queryParameters['dateOfPublication.lessThan'] = (requestParameters.dateOfPublicationLessThan as any).toISOString();
        }

        if (requestParameters.dateOfPublicationGreaterThanOrEqual !== undefined) {
            queryParameters['dateOfPublication.greaterThanOrEqual'] = (requestParameters.dateOfPublicationGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateOfPublicationLessThanOrEqual !== undefined) {
            queryParameters['dateOfPublication.lessThanOrEqual'] = (requestParameters.dateOfPublicationLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateOfPublicationEquals !== undefined) {
            queryParameters['dateOfPublication.equals'] = (requestParameters.dateOfPublicationEquals as any).toISOString();
        }

        if (requestParameters.dateOfPublicationNotEquals !== undefined) {
            queryParameters['dateOfPublication.notEquals'] = (requestParameters.dateOfPublicationNotEquals as any).toISOString();
        }

        if (requestParameters.dateOfPublicationSpecified !== undefined) {
            queryParameters['dateOfPublication.specified'] = requestParameters.dateOfPublicationSpecified;
        }

        if (requestParameters.dateOfPublicationIn) {
            queryParameters['dateOfPublication.in'] = requestParameters.dateOfPublicationIn;
        }

        if (requestParameters.dateOfPublicationNotIn) {
            queryParameters['dateOfPublication.notIn'] = requestParameters.dateOfPublicationNotIn;
        }

        if (requestParameters.currentInsightStateEquals !== undefined) {
            queryParameters['currentInsightState.equals'] = requestParameters.currentInsightStateEquals;
        }

        if (requestParameters.currentInsightStateNotEquals !== undefined) {
            queryParameters['currentInsightState.notEquals'] = requestParameters.currentInsightStateNotEquals;
        }

        if (requestParameters.currentInsightStateSpecified !== undefined) {
            queryParameters['currentInsightState.specified'] = requestParameters.currentInsightStateSpecified;
        }

        if (requestParameters.currentInsightStateIn) {
            queryParameters['currentInsightState.in'] = requestParameters.currentInsightStateIn;
        }

        if (requestParameters.currentInsightStateNotIn) {
            queryParameters['currentInsightState.notIn'] = requestParameters.currentInsightStateNotIn;
        }

        if (requestParameters.insightLikeIdGreaterThan !== undefined) {
            queryParameters['insightLikeId.greaterThan'] = requestParameters.insightLikeIdGreaterThan;
        }

        if (requestParameters.insightLikeIdLessThan !== undefined) {
            queryParameters['insightLikeId.lessThan'] = requestParameters.insightLikeIdLessThan;
        }

        if (requestParameters.insightLikeIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightLikeId.greaterThanOrEqual'] = requestParameters.insightLikeIdGreaterThanOrEqual;
        }

        if (requestParameters.insightLikeIdLessThanOrEqual !== undefined) {
            queryParameters['insightLikeId.lessThanOrEqual'] = requestParameters.insightLikeIdLessThanOrEqual;
        }

        if (requestParameters.insightLikeIdEquals !== undefined) {
            queryParameters['insightLikeId.equals'] = requestParameters.insightLikeIdEquals;
        }

        if (requestParameters.insightLikeIdNotEquals !== undefined) {
            queryParameters['insightLikeId.notEquals'] = requestParameters.insightLikeIdNotEquals;
        }

        if (requestParameters.insightLikeIdSpecified !== undefined) {
            queryParameters['insightLikeId.specified'] = requestParameters.insightLikeIdSpecified;
        }

        if (requestParameters.insightLikeIdIn) {
            queryParameters['insightLikeId.in'] = requestParameters.insightLikeIdIn;
        }

        if (requestParameters.insightLikeIdNotIn) {
            queryParameters['insightLikeId.notIn'] = requestParameters.insightLikeIdNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.publisherIdGreaterThan !== undefined) {
            queryParameters['publisherId.greaterThan'] = requestParameters.publisherIdGreaterThan;
        }

        if (requestParameters.publisherIdLessThan !== undefined) {
            queryParameters['publisherId.lessThan'] = requestParameters.publisherIdLessThan;
        }

        if (requestParameters.publisherIdGreaterThanOrEqual !== undefined) {
            queryParameters['publisherId.greaterThanOrEqual'] = requestParameters.publisherIdGreaterThanOrEqual;
        }

        if (requestParameters.publisherIdLessThanOrEqual !== undefined) {
            queryParameters['publisherId.lessThanOrEqual'] = requestParameters.publisherIdLessThanOrEqual;
        }

        if (requestParameters.publisherIdEquals !== undefined) {
            queryParameters['publisherId.equals'] = requestParameters.publisherIdEquals;
        }

        if (requestParameters.publisherIdNotEquals !== undefined) {
            queryParameters['publisherId.notEquals'] = requestParameters.publisherIdNotEquals;
        }

        if (requestParameters.publisherIdSpecified !== undefined) {
            queryParameters['publisherId.specified'] = requestParameters.publisherIdSpecified;
        }

        if (requestParameters.publisherIdIn) {
            queryParameters['publisherId.in'] = requestParameters.publisherIdIn;
        }

        if (requestParameters.publisherIdNotIn) {
            queryParameters['publisherId.notIn'] = requestParameters.publisherIdNotIn;
        }

        if (requestParameters.recipientIdGreaterThan !== undefined) {
            queryParameters['recipientId.greaterThan'] = requestParameters.recipientIdGreaterThan;
        }

        if (requestParameters.recipientIdLessThan !== undefined) {
            queryParameters['recipientId.lessThan'] = requestParameters.recipientIdLessThan;
        }

        if (requestParameters.recipientIdGreaterThanOrEqual !== undefined) {
            queryParameters['recipientId.greaterThanOrEqual'] = requestParameters.recipientIdGreaterThanOrEqual;
        }

        if (requestParameters.recipientIdLessThanOrEqual !== undefined) {
            queryParameters['recipientId.lessThanOrEqual'] = requestParameters.recipientIdLessThanOrEqual;
        }

        if (requestParameters.recipientIdEquals !== undefined) {
            queryParameters['recipientId.equals'] = requestParameters.recipientIdEquals;
        }

        if (requestParameters.recipientIdNotEquals !== undefined) {
            queryParameters['recipientId.notEquals'] = requestParameters.recipientIdNotEquals;
        }

        if (requestParameters.recipientIdSpecified !== undefined) {
            queryParameters['recipientId.specified'] = requestParameters.recipientIdSpecified;
        }

        if (requestParameters.recipientIdIn) {
            queryParameters['recipientId.in'] = requestParameters.recipientIdIn;
        }

        if (requestParameters.recipientIdNotIn) {
            queryParameters['recipientId.notIn'] = requestParameters.recipientIdNotIn;
        }

        if (requestParameters.insightStateIdGreaterThan !== undefined) {
            queryParameters['insightStateId.greaterThan'] = requestParameters.insightStateIdGreaterThan;
        }

        if (requestParameters.insightStateIdLessThan !== undefined) {
            queryParameters['insightStateId.lessThan'] = requestParameters.insightStateIdLessThan;
        }

        if (requestParameters.insightStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightStateId.greaterThanOrEqual'] = requestParameters.insightStateIdGreaterThanOrEqual;
        }

        if (requestParameters.insightStateIdLessThanOrEqual !== undefined) {
            queryParameters['insightStateId.lessThanOrEqual'] = requestParameters.insightStateIdLessThanOrEqual;
        }

        if (requestParameters.insightStateIdEquals !== undefined) {
            queryParameters['insightStateId.equals'] = requestParameters.insightStateIdEquals;
        }

        if (requestParameters.insightStateIdNotEquals !== undefined) {
            queryParameters['insightStateId.notEquals'] = requestParameters.insightStateIdNotEquals;
        }

        if (requestParameters.insightStateIdSpecified !== undefined) {
            queryParameters['insightStateId.specified'] = requestParameters.insightStateIdSpecified;
        }

        if (requestParameters.insightStateIdIn) {
            queryParameters['insightStateId.in'] = requestParameters.insightStateIdIn;
        }

        if (requestParameters.insightStateIdNotIn) {
            queryParameters['insightStateId.notIn'] = requestParameters.insightStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countInsights(requestParameters: CountInsightsRequest): Promise<number> {
        const response = await this.countInsightsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createInsightRaw(requestParameters: CreateInsightRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.insight === null || requestParameters.insight === undefined) {
            throw new runtime.RequiredError('insight','Required parameter requestParameters.insight was null or undefined when calling createInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightToJSON(requestParameters.insight),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async createInsight(requestParameters: CreateInsightRequest): Promise<Insight> {
        const response = await this.createInsightRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteInsightRaw(requestParameters: DeleteInsightRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteInsight(requestParameters: DeleteInsightRequest): Promise<void> {
        await this.deleteInsightRaw(requestParameters);
    }

    /**
     */
    async getAllInsightsRaw(requestParameters: GetAllInsightsRequest): Promise<runtime.ApiResponse<Array<Insight>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.titleContains !== undefined) {
            queryParameters['title.contains'] = requestParameters.titleContains;
        }

        if (requestParameters.titleDoesNotContain !== undefined) {
            queryParameters['title.doesNotContain'] = requestParameters.titleDoesNotContain;
        }

        if (requestParameters.titleEquals !== undefined) {
            queryParameters['title.equals'] = requestParameters.titleEquals;
        }

        if (requestParameters.titleNotEquals !== undefined) {
            queryParameters['title.notEquals'] = requestParameters.titleNotEquals;
        }

        if (requestParameters.titleSpecified !== undefined) {
            queryParameters['title.specified'] = requestParameters.titleSpecified;
        }

        if (requestParameters.titleIn) {
            queryParameters['title.in'] = requestParameters.titleIn;
        }

        if (requestParameters.titleNotIn) {
            queryParameters['title.notIn'] = requestParameters.titleNotIn;
        }

        if (requestParameters.descriptionContains !== undefined) {
            queryParameters['description.contains'] = requestParameters.descriptionContains;
        }

        if (requestParameters.descriptionDoesNotContain !== undefined) {
            queryParameters['description.doesNotContain'] = requestParameters.descriptionDoesNotContain;
        }

        if (requestParameters.descriptionEquals !== undefined) {
            queryParameters['description.equals'] = requestParameters.descriptionEquals;
        }

        if (requestParameters.descriptionNotEquals !== undefined) {
            queryParameters['description.notEquals'] = requestParameters.descriptionNotEquals;
        }

        if (requestParameters.descriptionSpecified !== undefined) {
            queryParameters['description.specified'] = requestParameters.descriptionSpecified;
        }

        if (requestParameters.descriptionIn) {
            queryParameters['description.in'] = requestParameters.descriptionIn;
        }

        if (requestParameters.descriptionNotIn) {
            queryParameters['description.notIn'] = requestParameters.descriptionNotIn;
        }

        if (requestParameters.createdAtGreaterThan !== undefined) {
            queryParameters['createdAt.greaterThan'] = (requestParameters.createdAtGreaterThan as any).toISOString();
        }

        if (requestParameters.createdAtLessThan !== undefined) {
            queryParameters['createdAt.lessThan'] = (requestParameters.createdAtLessThan as any).toISOString();
        }

        if (requestParameters.createdAtGreaterThanOrEqual !== undefined) {
            queryParameters['createdAt.greaterThanOrEqual'] = (requestParameters.createdAtGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.createdAtLessThanOrEqual !== undefined) {
            queryParameters['createdAt.lessThanOrEqual'] = (requestParameters.createdAtLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.createdAtEquals !== undefined) {
            queryParameters['createdAt.equals'] = (requestParameters.createdAtEquals as any).toISOString();
        }

        if (requestParameters.createdAtNotEquals !== undefined) {
            queryParameters['createdAt.notEquals'] = (requestParameters.createdAtNotEquals as any).toISOString();
        }

        if (requestParameters.createdAtSpecified !== undefined) {
            queryParameters['createdAt.specified'] = requestParameters.createdAtSpecified;
        }

        if (requestParameters.createdAtIn) {
            queryParameters['createdAt.in'] = requestParameters.createdAtIn;
        }

        if (requestParameters.createdAtNotIn) {
            queryParameters['createdAt.notIn'] = requestParameters.createdAtNotIn;
        }

        if (requestParameters.likeCountGreaterThan !== undefined) {
            queryParameters['likeCount.greaterThan'] = requestParameters.likeCountGreaterThan;
        }

        if (requestParameters.likeCountLessThan !== undefined) {
            queryParameters['likeCount.lessThan'] = requestParameters.likeCountLessThan;
        }

        if (requestParameters.likeCountGreaterThanOrEqual !== undefined) {
            queryParameters['likeCount.greaterThanOrEqual'] = requestParameters.likeCountGreaterThanOrEqual;
        }

        if (requestParameters.likeCountLessThanOrEqual !== undefined) {
            queryParameters['likeCount.lessThanOrEqual'] = requestParameters.likeCountLessThanOrEqual;
        }

        if (requestParameters.likeCountEquals !== undefined) {
            queryParameters['likeCount.equals'] = requestParameters.likeCountEquals;
        }

        if (requestParameters.likeCountNotEquals !== undefined) {
            queryParameters['likeCount.notEquals'] = requestParameters.likeCountNotEquals;
        }

        if (requestParameters.likeCountSpecified !== undefined) {
            queryParameters['likeCount.specified'] = requestParameters.likeCountSpecified;
        }

        if (requestParameters.likeCountIn) {
            queryParameters['likeCount.in'] = requestParameters.likeCountIn;
        }

        if (requestParameters.likeCountNotIn) {
            queryParameters['likeCount.notIn'] = requestParameters.likeCountNotIn;
        }

        if (requestParameters.dislikeCountGreaterThan !== undefined) {
            queryParameters['dislikeCount.greaterThan'] = requestParameters.dislikeCountGreaterThan;
        }

        if (requestParameters.dislikeCountLessThan !== undefined) {
            queryParameters['dislikeCount.lessThan'] = requestParameters.dislikeCountLessThan;
        }

        if (requestParameters.dislikeCountGreaterThanOrEqual !== undefined) {
            queryParameters['dislikeCount.greaterThanOrEqual'] = requestParameters.dislikeCountGreaterThanOrEqual;
        }

        if (requestParameters.dislikeCountLessThanOrEqual !== undefined) {
            queryParameters['dislikeCount.lessThanOrEqual'] = requestParameters.dislikeCountLessThanOrEqual;
        }

        if (requestParameters.dislikeCountEquals !== undefined) {
            queryParameters['dislikeCount.equals'] = requestParameters.dislikeCountEquals;
        }

        if (requestParameters.dislikeCountNotEquals !== undefined) {
            queryParameters['dislikeCount.notEquals'] = requestParameters.dislikeCountNotEquals;
        }

        if (requestParameters.dislikeCountSpecified !== undefined) {
            queryParameters['dislikeCount.specified'] = requestParameters.dislikeCountSpecified;
        }

        if (requestParameters.dislikeCountIn) {
            queryParameters['dislikeCount.in'] = requestParameters.dislikeCountIn;
        }

        if (requestParameters.dislikeCountNotIn) {
            queryParameters['dislikeCount.notIn'] = requestParameters.dislikeCountNotIn;
        }

        if (requestParameters.dateOfPublicationGreaterThan !== undefined) {
            queryParameters['dateOfPublication.greaterThan'] = (requestParameters.dateOfPublicationGreaterThan as any).toISOString();
        }

        if (requestParameters.dateOfPublicationLessThan !== undefined) {
            queryParameters['dateOfPublication.lessThan'] = (requestParameters.dateOfPublicationLessThan as any).toISOString();
        }

        if (requestParameters.dateOfPublicationGreaterThanOrEqual !== undefined) {
            queryParameters['dateOfPublication.greaterThanOrEqual'] = (requestParameters.dateOfPublicationGreaterThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateOfPublicationLessThanOrEqual !== undefined) {
            queryParameters['dateOfPublication.lessThanOrEqual'] = (requestParameters.dateOfPublicationLessThanOrEqual as any).toISOString();
        }

        if (requestParameters.dateOfPublicationEquals !== undefined) {
            queryParameters['dateOfPublication.equals'] = (requestParameters.dateOfPublicationEquals as any).toISOString();
        }

        if (requestParameters.dateOfPublicationNotEquals !== undefined) {
            queryParameters['dateOfPublication.notEquals'] = (requestParameters.dateOfPublicationNotEquals as any).toISOString();
        }

        if (requestParameters.dateOfPublicationSpecified !== undefined) {
            queryParameters['dateOfPublication.specified'] = requestParameters.dateOfPublicationSpecified;
        }

        if (requestParameters.dateOfPublicationIn) {
            queryParameters['dateOfPublication.in'] = requestParameters.dateOfPublicationIn;
        }

        if (requestParameters.dateOfPublicationNotIn) {
            queryParameters['dateOfPublication.notIn'] = requestParameters.dateOfPublicationNotIn;
        }

        if (requestParameters.currentInsightStateEquals !== undefined) {
            queryParameters['currentInsightState.equals'] = requestParameters.currentInsightStateEquals;
        }

        if (requestParameters.currentInsightStateNotEquals !== undefined) {
            queryParameters['currentInsightState.notEquals'] = requestParameters.currentInsightStateNotEquals;
        }

        if (requestParameters.currentInsightStateSpecified !== undefined) {
            queryParameters['currentInsightState.specified'] = requestParameters.currentInsightStateSpecified;
        }

        if (requestParameters.currentInsightStateIn) {
            queryParameters['currentInsightState.in'] = requestParameters.currentInsightStateIn;
        }

        if (requestParameters.currentInsightStateNotIn) {
            queryParameters['currentInsightState.notIn'] = requestParameters.currentInsightStateNotIn;
        }

        if (requestParameters.insightLikeIdGreaterThan !== undefined) {
            queryParameters['insightLikeId.greaterThan'] = requestParameters.insightLikeIdGreaterThan;
        }

        if (requestParameters.insightLikeIdLessThan !== undefined) {
            queryParameters['insightLikeId.lessThan'] = requestParameters.insightLikeIdLessThan;
        }

        if (requestParameters.insightLikeIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightLikeId.greaterThanOrEqual'] = requestParameters.insightLikeIdGreaterThanOrEqual;
        }

        if (requestParameters.insightLikeIdLessThanOrEqual !== undefined) {
            queryParameters['insightLikeId.lessThanOrEqual'] = requestParameters.insightLikeIdLessThanOrEqual;
        }

        if (requestParameters.insightLikeIdEquals !== undefined) {
            queryParameters['insightLikeId.equals'] = requestParameters.insightLikeIdEquals;
        }

        if (requestParameters.insightLikeIdNotEquals !== undefined) {
            queryParameters['insightLikeId.notEquals'] = requestParameters.insightLikeIdNotEquals;
        }

        if (requestParameters.insightLikeIdSpecified !== undefined) {
            queryParameters['insightLikeId.specified'] = requestParameters.insightLikeIdSpecified;
        }

        if (requestParameters.insightLikeIdIn) {
            queryParameters['insightLikeId.in'] = requestParameters.insightLikeIdIn;
        }

        if (requestParameters.insightLikeIdNotIn) {
            queryParameters['insightLikeId.notIn'] = requestParameters.insightLikeIdNotIn;
        }

        if (requestParameters.authorIdGreaterThan !== undefined) {
            queryParameters['authorId.greaterThan'] = requestParameters.authorIdGreaterThan;
        }

        if (requestParameters.authorIdLessThan !== undefined) {
            queryParameters['authorId.lessThan'] = requestParameters.authorIdLessThan;
        }

        if (requestParameters.authorIdGreaterThanOrEqual !== undefined) {
            queryParameters['authorId.greaterThanOrEqual'] = requestParameters.authorIdGreaterThanOrEqual;
        }

        if (requestParameters.authorIdLessThanOrEqual !== undefined) {
            queryParameters['authorId.lessThanOrEqual'] = requestParameters.authorIdLessThanOrEqual;
        }

        if (requestParameters.authorIdEquals !== undefined) {
            queryParameters['authorId.equals'] = requestParameters.authorIdEquals;
        }

        if (requestParameters.authorIdNotEquals !== undefined) {
            queryParameters['authorId.notEquals'] = requestParameters.authorIdNotEquals;
        }

        if (requestParameters.authorIdSpecified !== undefined) {
            queryParameters['authorId.specified'] = requestParameters.authorIdSpecified;
        }

        if (requestParameters.authorIdIn) {
            queryParameters['authorId.in'] = requestParameters.authorIdIn;
        }

        if (requestParameters.authorIdNotIn) {
            queryParameters['authorId.notIn'] = requestParameters.authorIdNotIn;
        }

        if (requestParameters.publisherIdGreaterThan !== undefined) {
            queryParameters['publisherId.greaterThan'] = requestParameters.publisherIdGreaterThan;
        }

        if (requestParameters.publisherIdLessThan !== undefined) {
            queryParameters['publisherId.lessThan'] = requestParameters.publisherIdLessThan;
        }

        if (requestParameters.publisherIdGreaterThanOrEqual !== undefined) {
            queryParameters['publisherId.greaterThanOrEqual'] = requestParameters.publisherIdGreaterThanOrEqual;
        }

        if (requestParameters.publisherIdLessThanOrEqual !== undefined) {
            queryParameters['publisherId.lessThanOrEqual'] = requestParameters.publisherIdLessThanOrEqual;
        }

        if (requestParameters.publisherIdEquals !== undefined) {
            queryParameters['publisherId.equals'] = requestParameters.publisherIdEquals;
        }

        if (requestParameters.publisherIdNotEquals !== undefined) {
            queryParameters['publisherId.notEquals'] = requestParameters.publisherIdNotEquals;
        }

        if (requestParameters.publisherIdSpecified !== undefined) {
            queryParameters['publisherId.specified'] = requestParameters.publisherIdSpecified;
        }

        if (requestParameters.publisherIdIn) {
            queryParameters['publisherId.in'] = requestParameters.publisherIdIn;
        }

        if (requestParameters.publisherIdNotIn) {
            queryParameters['publisherId.notIn'] = requestParameters.publisherIdNotIn;
        }

        if (requestParameters.recipientIdGreaterThan !== undefined) {
            queryParameters['recipientId.greaterThan'] = requestParameters.recipientIdGreaterThan;
        }

        if (requestParameters.recipientIdLessThan !== undefined) {
            queryParameters['recipientId.lessThan'] = requestParameters.recipientIdLessThan;
        }

        if (requestParameters.recipientIdGreaterThanOrEqual !== undefined) {
            queryParameters['recipientId.greaterThanOrEqual'] = requestParameters.recipientIdGreaterThanOrEqual;
        }

        if (requestParameters.recipientIdLessThanOrEqual !== undefined) {
            queryParameters['recipientId.lessThanOrEqual'] = requestParameters.recipientIdLessThanOrEqual;
        }

        if (requestParameters.recipientIdEquals !== undefined) {
            queryParameters['recipientId.equals'] = requestParameters.recipientIdEquals;
        }

        if (requestParameters.recipientIdNotEquals !== undefined) {
            queryParameters['recipientId.notEquals'] = requestParameters.recipientIdNotEquals;
        }

        if (requestParameters.recipientIdSpecified !== undefined) {
            queryParameters['recipientId.specified'] = requestParameters.recipientIdSpecified;
        }

        if (requestParameters.recipientIdIn) {
            queryParameters['recipientId.in'] = requestParameters.recipientIdIn;
        }

        if (requestParameters.recipientIdNotIn) {
            queryParameters['recipientId.notIn'] = requestParameters.recipientIdNotIn;
        }

        if (requestParameters.insightStateIdGreaterThan !== undefined) {
            queryParameters['insightStateId.greaterThan'] = requestParameters.insightStateIdGreaterThan;
        }

        if (requestParameters.insightStateIdLessThan !== undefined) {
            queryParameters['insightStateId.lessThan'] = requestParameters.insightStateIdLessThan;
        }

        if (requestParameters.insightStateIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightStateId.greaterThanOrEqual'] = requestParameters.insightStateIdGreaterThanOrEqual;
        }

        if (requestParameters.insightStateIdLessThanOrEqual !== undefined) {
            queryParameters['insightStateId.lessThanOrEqual'] = requestParameters.insightStateIdLessThanOrEqual;
        }

        if (requestParameters.insightStateIdEquals !== undefined) {
            queryParameters['insightStateId.equals'] = requestParameters.insightStateIdEquals;
        }

        if (requestParameters.insightStateIdNotEquals !== undefined) {
            queryParameters['insightStateId.notEquals'] = requestParameters.insightStateIdNotEquals;
        }

        if (requestParameters.insightStateIdSpecified !== undefined) {
            queryParameters['insightStateId.specified'] = requestParameters.insightStateIdSpecified;
        }

        if (requestParameters.insightStateIdIn) {
            queryParameters['insightStateId.in'] = requestParameters.insightStateIdIn;
        }

        if (requestParameters.insightStateIdNotIn) {
            queryParameters['insightStateId.notIn'] = requestParameters.insightStateIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsightFromJSON));
    }

    /**
     */
    async getAllInsights(requestParameters: GetAllInsightsRequest): Promise<Array<Insight>> {
        const response = await this.getAllInsightsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInsightRaw(requestParameters: GetInsightRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async getInsight(requestParameters: GetInsightRequest): Promise<Insight> {
        const response = await this.getInsightRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateInsightRaw(requestParameters: PartialUpdateInsightRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateInsight.');
        }

        if (requestParameters.insight === null || requestParameters.insight === undefined) {
            throw new runtime.RequiredError('insight','Required parameter requestParameters.insight was null or undefined when calling partialUpdateInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InsightToJSON(requestParameters.insight),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateInsight(requestParameters: PartialUpdateInsightRequest): Promise<Insight> {
        const response = await this.partialUpdateInsightRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateInsightRaw(requestParameters: UpdateInsightRequest): Promise<runtime.ApiResponse<Insight>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInsight.');
        }

        if (requestParameters.insight === null || requestParameters.insight === undefined) {
            throw new runtime.RequiredError('insight','Required parameter requestParameters.insight was null or undefined when calling updateInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightToJSON(requestParameters.insight),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightFromJSON(jsonValue));
    }

    /**
     */
    async updateInsight(requestParameters: UpdateInsightRequest): Promise<Insight> {
        const response = await this.updateInsightRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountInsightsCurrentInsightStateEqualsEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightsCurrentInsightStateNotEqualsEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightsCurrentInsightStateInEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum CountInsightsCurrentInsightStateNotInEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightsCurrentInsightStateEqualsEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightsCurrentInsightStateNotEqualsEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightsCurrentInsightStateInEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllInsightsCurrentInsightStateNotInEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}
