/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyYearDTO
 */
export interface CompanyYearDTO {
    /**
     * 
     * @type {Company}
     * @memberof CompanyYearDTO
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof CompanyYearDTO
     */
    year?: number;
}

export function CompanyYearDTOFromJSON(json: any): CompanyYearDTO {
    return CompanyYearDTOFromJSONTyped(json, false);
}

export function CompanyYearDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyYearDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function CompanyYearDTOToJSON(value?: CompanyYearDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': CompanyToJSON(value.company),
        'year': value.year,
    };
}


