/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SoggettoControllante,
    SoggettoControllanteFromJSON,
    SoggettoControllanteFromJSONTyped,
    SoggettoControllanteToJSON,
} from './';

/**
 * 
 * @export
 * @interface SoggettiControllanti
 */
export interface SoggettiControllanti {
    /**
     * 
     * @type {Array<SoggettoControllante>}
     * @memberof SoggettiControllanti
     */
    soggettoControllante: Array<SoggettoControllante>;
}

export function SoggettiControllantiFromJSON(json: any): SoggettiControllanti {
    return SoggettiControllantiFromJSONTyped(json, false);
}

export function SoggettiControllantiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoggettiControllanti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'soggettoControllante': ((json['soggettoControllante'] as Array<any>).map(SoggettoControllanteFromJSON)),
    };
}

export function SoggettiControllantiToJSON(value?: SoggettiControllanti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'soggettoControllante': ((value.soggettoControllante as Array<any>).map(SoggettoControllanteToJSON)),
    };
}


