/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LongFilter,
    LongFilterFromJSON,
    LongFilterFromJSONTyped,
    LongFilterToJSON,
    StringFilter,
    StringFilterFromJSON,
    StringFilterFromJSONTyped,
    StringFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomSurveyCriteria
 */
export interface CustomSurveyCriteria {
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomSurveyCriteria
     */
    id?: LongFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomSurveyCriteria
     */
    titleEn?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomSurveyCriteria
     */
    titleIt?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomSurveyCriteria
     */
    name?: StringFilter;
    /**
     * 
     * @type {StringFilter}
     * @memberof CustomSurveyCriteria
     */
    key?: StringFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomSurveyCriteria
     */
    companySurveyId?: LongFilter;
    /**
     * 
     * @type {LongFilter}
     * @memberof CustomSurveyCriteria
     */
    surveyQuestionId?: LongFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSurveyCriteria
     */
    distinct?: boolean;
}

export function CustomSurveyCriteriaFromJSON(json: any): CustomSurveyCriteria {
    return CustomSurveyCriteriaFromJSONTyped(json, false);
}

export function CustomSurveyCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomSurveyCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : LongFilterFromJSON(json['id']),
        'titleEn': !exists(json, 'titleEn') ? undefined : StringFilterFromJSON(json['titleEn']),
        'titleIt': !exists(json, 'titleIt') ? undefined : StringFilterFromJSON(json['titleIt']),
        'name': !exists(json, 'name') ? undefined : StringFilterFromJSON(json['name']),
        'key': !exists(json, 'key') ? undefined : StringFilterFromJSON(json['key']),
        'companySurveyId': !exists(json, 'companySurveyId') ? undefined : LongFilterFromJSON(json['companySurveyId']),
        'surveyQuestionId': !exists(json, 'surveyQuestionId') ? undefined : LongFilterFromJSON(json['surveyQuestionId']),
        'distinct': !exists(json, 'distinct') ? undefined : json['distinct'],
    };
}

export function CustomSurveyCriteriaToJSON(value?: CustomSurveyCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': LongFilterToJSON(value.id),
        'titleEn': StringFilterToJSON(value.titleEn),
        'titleIt': StringFilterToJSON(value.titleIt),
        'name': StringFilterToJSON(value.name),
        'key': StringFilterToJSON(value.key),
        'companySurveyId': LongFilterToJSON(value.companySurveyId),
        'surveyQuestionId': LongFilterToJSON(value.surveyQuestionId),
        'distinct': value.distinct,
    };
}


