/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotePartecipazione,
    NotePartecipazioneFromJSON,
    NotePartecipazioneFromJSONTyped,
    NotePartecipazioneToJSON,
    QuoteDirittiImpresa,
    QuoteDirittiImpresaFromJSON,
    QuoteDirittiImpresaFromJSONTyped,
    QuoteDirittiImpresaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Partecipata
 */
export interface Partecipata {
    /**
     * 
     * @type {QuoteDirittiImpresa}
     * @memberof Partecipata
     */
    quoteDirittiImpresa?: QuoteDirittiImpresa;
    /**
     * 
     * @type {NotePartecipazione}
     * @memberof Partecipata
     */
    notePartecipazione?: NotePartecipazione;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    dtCancellazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    dtInizioPartecipazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    dtFinePartecipazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    fconsorzio?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    fcooperativa?: string;
    /**
     * 
     * @type {string}
     * @memberof Partecipata
     */
    fcessata?: string;
}

export function PartecipataFromJSON(json: any): Partecipata {
    return PartecipataFromJSONTyped(json, false);
}

export function PartecipataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partecipata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quoteDirittiImpresa': !exists(json, 'quoteDirittiImpresa') ? undefined : QuoteDirittiImpresaFromJSON(json['quoteDirittiImpresa']),
        'notePartecipazione': !exists(json, 'notePartecipazione') ? undefined : NotePartecipazioneFromJSON(json['notePartecipazione']),
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'dtCancellazione': !exists(json, 'dtCancellazione') ? undefined : json['dtCancellazione'],
        'dtInizioPartecipazione': !exists(json, 'dtInizioPartecipazione') ? undefined : json['dtInizioPartecipazione'],
        'dtFinePartecipazione': !exists(json, 'dtFinePartecipazione') ? undefined : json['dtFinePartecipazione'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'fconsorzio': !exists(json, 'fconsorzio') ? undefined : json['fconsorzio'],
        'fcooperativa': !exists(json, 'fcooperativa') ? undefined : json['fcooperativa'],
        'fcessata': !exists(json, 'fcessata') ? undefined : json['fcessata'],
    };
}

export function PartecipataToJSON(value?: Partecipata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quoteDirittiImpresa': QuoteDirittiImpresaToJSON(value.quoteDirittiImpresa),
        'notePartecipazione': NotePartecipazioneToJSON(value.notePartecipazione),
        'denominazione': value.denominazione,
        'dtCancellazione': value.dtCancellazione,
        'dtInizioPartecipazione': value.dtInizioPartecipazione,
        'dtFinePartecipazione': value.dtFinePartecipazione,
        'cfiscale': value.cfiscale,
        'fconsorzio': value.fconsorzio,
        'fcooperativa': value.fcooperativa,
        'fcessata': value.fcessata,
    };
}


