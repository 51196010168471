/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatiIscrizioneReaRd
 */
export interface DatiIscrizioneReaRd {
    /**
     * 
     * @type {string}
     * @memberof DatiIscrizioneReaRd
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIscrizioneReaRd
     */
    tipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIscrizioneReaRd
     */
    ctipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIscrizioneReaRd
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof DatiIscrizioneReaRd
     */
    nrd?: string;
}

export function DatiIscrizioneReaRdFromJSON(json: any): DatiIscrizioneReaRd {
    return DatiIscrizioneReaRdFromJSONTyped(json, false);
}

export function DatiIscrizioneReaRdFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatiIscrizioneReaRd {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'tipoIscrizione': !exists(json, 'tipoIscrizione') ? undefined : json['tipoIscrizione'],
        'ctipoIscrizione': !exists(json, 'ctipoIscrizione') ? undefined : json['ctipoIscrizione'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
    };
}

export function DatiIscrizioneReaRdToJSON(value?: DatiIscrizioneReaRd | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtIscrizione': value.dtIscrizione,
        'tipoIscrizione': value.tipoIscrizione,
        'ctipoIscrizione': value.ctipoIscrizione,
        'nrea': value.nrea,
        'nrd': value.nrd,
    };
}


