/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Survey,
    SurveyFromJSON,
    SurveyToJSON,
} from '../models';

export interface CountSurveysRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    titleEnContains?: string;
    titleEnDoesNotContain?: string;
    titleEnEquals?: string;
    titleEnNotEquals?: string;
    titleEnSpecified?: boolean;
    titleEnIn?: Array<string>;
    titleEnNotIn?: Array<string>;
    titleItContains?: string;
    titleItDoesNotContain?: string;
    titleItEquals?: string;
    titleItNotEquals?: string;
    titleItSpecified?: boolean;
    titleItIn?: Array<string>;
    titleItNotIn?: Array<string>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    keyContains?: string;
    keyDoesNotContain?: string;
    keyEquals?: string;
    keyNotEquals?: string;
    keySpecified?: boolean;
    keyIn?: Array<string>;
    keyNotIn?: Array<string>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateSurveyRequest {
    survey: Survey;
}

export interface DeleteSurveyRequest {
    id: number;
}

export interface GetAllSurveysRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    titleEnContains?: string;
    titleEnDoesNotContain?: string;
    titleEnEquals?: string;
    titleEnNotEquals?: string;
    titleEnSpecified?: boolean;
    titleEnIn?: Array<string>;
    titleEnNotIn?: Array<string>;
    titleItContains?: string;
    titleItDoesNotContain?: string;
    titleItEquals?: string;
    titleItNotEquals?: string;
    titleItSpecified?: boolean;
    titleItIn?: Array<string>;
    titleItNotIn?: Array<string>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    keyContains?: string;
    keyDoesNotContain?: string;
    keyEquals?: string;
    keyNotEquals?: string;
    keySpecified?: boolean;
    keyIn?: Array<string>;
    keyNotIn?: Array<string>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    surveyQuestionIdGreaterThan?: number;
    surveyQuestionIdLessThan?: number;
    surveyQuestionIdGreaterThanOrEqual?: number;
    surveyQuestionIdLessThanOrEqual?: number;
    surveyQuestionIdEquals?: number;
    surveyQuestionIdNotEquals?: number;
    surveyQuestionIdSpecified?: boolean;
    surveyQuestionIdIn?: Array<number>;
    surveyQuestionIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetSurveyRequest {
    id: number;
}

export interface PartialUpdateSurveyRequest {
    id: number;
    survey: Survey;
}

export interface UpdateSurveyRequest {
    id: number;
    survey: Survey;
}

/**
 * 
 */
export class SurveyResourceApi extends runtime.BaseAPI {

    /**
     */
    async countSurveysRaw(requestParameters: CountSurveysRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.titleEnContains !== undefined) {
            queryParameters['titleEn.contains'] = requestParameters.titleEnContains;
        }

        if (requestParameters.titleEnDoesNotContain !== undefined) {
            queryParameters['titleEn.doesNotContain'] = requestParameters.titleEnDoesNotContain;
        }

        if (requestParameters.titleEnEquals !== undefined) {
            queryParameters['titleEn.equals'] = requestParameters.titleEnEquals;
        }

        if (requestParameters.titleEnNotEquals !== undefined) {
            queryParameters['titleEn.notEquals'] = requestParameters.titleEnNotEquals;
        }

        if (requestParameters.titleEnSpecified !== undefined) {
            queryParameters['titleEn.specified'] = requestParameters.titleEnSpecified;
        }

        if (requestParameters.titleEnIn) {
            queryParameters['titleEn.in'] = requestParameters.titleEnIn;
        }

        if (requestParameters.titleEnNotIn) {
            queryParameters['titleEn.notIn'] = requestParameters.titleEnNotIn;
        }

        if (requestParameters.titleItContains !== undefined) {
            queryParameters['titleIt.contains'] = requestParameters.titleItContains;
        }

        if (requestParameters.titleItDoesNotContain !== undefined) {
            queryParameters['titleIt.doesNotContain'] = requestParameters.titleItDoesNotContain;
        }

        if (requestParameters.titleItEquals !== undefined) {
            queryParameters['titleIt.equals'] = requestParameters.titleItEquals;
        }

        if (requestParameters.titleItNotEquals !== undefined) {
            queryParameters['titleIt.notEquals'] = requestParameters.titleItNotEquals;
        }

        if (requestParameters.titleItSpecified !== undefined) {
            queryParameters['titleIt.specified'] = requestParameters.titleItSpecified;
        }

        if (requestParameters.titleItIn) {
            queryParameters['titleIt.in'] = requestParameters.titleItIn;
        }

        if (requestParameters.titleItNotIn) {
            queryParameters['titleIt.notIn'] = requestParameters.titleItNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.keyContains !== undefined) {
            queryParameters['key.contains'] = requestParameters.keyContains;
        }

        if (requestParameters.keyDoesNotContain !== undefined) {
            queryParameters['key.doesNotContain'] = requestParameters.keyDoesNotContain;
        }

        if (requestParameters.keyEquals !== undefined) {
            queryParameters['key.equals'] = requestParameters.keyEquals;
        }

        if (requestParameters.keyNotEquals !== undefined) {
            queryParameters['key.notEquals'] = requestParameters.keyNotEquals;
        }

        if (requestParameters.keySpecified !== undefined) {
            queryParameters['key.specified'] = requestParameters.keySpecified;
        }

        if (requestParameters.keyIn) {
            queryParameters['key.in'] = requestParameters.keyIn;
        }

        if (requestParameters.keyNotIn) {
            queryParameters['key.notIn'] = requestParameters.keyNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/surveys/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countSurveys(requestParameters: CountSurveysRequest): Promise<number> {
        const response = await this.countSurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSurveyRaw(requestParameters: CreateSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.survey === null || requestParameters.survey === undefined) {
            throw new runtime.RequiredError('survey','Required parameter requestParameters.survey was null or undefined when calling createSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/surveys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyToJSON(requestParameters.survey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     */
    async createSurvey(requestParameters: CreateSurveyRequest): Promise<Survey> {
        const response = await this.createSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSurveyRaw(requestParameters: DeleteSurveyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSurvey(requestParameters: DeleteSurveyRequest): Promise<void> {
        await this.deleteSurveyRaw(requestParameters);
    }

    /**
     */
    async getAllSurveysRaw(requestParameters: GetAllSurveysRequest): Promise<runtime.ApiResponse<Array<Survey>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.titleEnContains !== undefined) {
            queryParameters['titleEn.contains'] = requestParameters.titleEnContains;
        }

        if (requestParameters.titleEnDoesNotContain !== undefined) {
            queryParameters['titleEn.doesNotContain'] = requestParameters.titleEnDoesNotContain;
        }

        if (requestParameters.titleEnEquals !== undefined) {
            queryParameters['titleEn.equals'] = requestParameters.titleEnEquals;
        }

        if (requestParameters.titleEnNotEquals !== undefined) {
            queryParameters['titleEn.notEquals'] = requestParameters.titleEnNotEquals;
        }

        if (requestParameters.titleEnSpecified !== undefined) {
            queryParameters['titleEn.specified'] = requestParameters.titleEnSpecified;
        }

        if (requestParameters.titleEnIn) {
            queryParameters['titleEn.in'] = requestParameters.titleEnIn;
        }

        if (requestParameters.titleEnNotIn) {
            queryParameters['titleEn.notIn'] = requestParameters.titleEnNotIn;
        }

        if (requestParameters.titleItContains !== undefined) {
            queryParameters['titleIt.contains'] = requestParameters.titleItContains;
        }

        if (requestParameters.titleItDoesNotContain !== undefined) {
            queryParameters['titleIt.doesNotContain'] = requestParameters.titleItDoesNotContain;
        }

        if (requestParameters.titleItEquals !== undefined) {
            queryParameters['titleIt.equals'] = requestParameters.titleItEquals;
        }

        if (requestParameters.titleItNotEquals !== undefined) {
            queryParameters['titleIt.notEquals'] = requestParameters.titleItNotEquals;
        }

        if (requestParameters.titleItSpecified !== undefined) {
            queryParameters['titleIt.specified'] = requestParameters.titleItSpecified;
        }

        if (requestParameters.titleItIn) {
            queryParameters['titleIt.in'] = requestParameters.titleItIn;
        }

        if (requestParameters.titleItNotIn) {
            queryParameters['titleIt.notIn'] = requestParameters.titleItNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.keyContains !== undefined) {
            queryParameters['key.contains'] = requestParameters.keyContains;
        }

        if (requestParameters.keyDoesNotContain !== undefined) {
            queryParameters['key.doesNotContain'] = requestParameters.keyDoesNotContain;
        }

        if (requestParameters.keyEquals !== undefined) {
            queryParameters['key.equals'] = requestParameters.keyEquals;
        }

        if (requestParameters.keyNotEquals !== undefined) {
            queryParameters['key.notEquals'] = requestParameters.keyNotEquals;
        }

        if (requestParameters.keySpecified !== undefined) {
            queryParameters['key.specified'] = requestParameters.keySpecified;
        }

        if (requestParameters.keyIn) {
            queryParameters['key.in'] = requestParameters.keyIn;
        }

        if (requestParameters.keyNotIn) {
            queryParameters['key.notIn'] = requestParameters.keyNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.surveyQuestionIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionId.greaterThan'] = requestParameters.surveyQuestionIdGreaterThan;
        }

        if (requestParameters.surveyQuestionIdLessThan !== undefined) {
            queryParameters['surveyQuestionId.lessThan'] = requestParameters.surveyQuestionIdLessThan;
        }

        if (requestParameters.surveyQuestionIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.greaterThanOrEqual'] = requestParameters.surveyQuestionIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionId.lessThanOrEqual'] = requestParameters.surveyQuestionIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionIdEquals !== undefined) {
            queryParameters['surveyQuestionId.equals'] = requestParameters.surveyQuestionIdEquals;
        }

        if (requestParameters.surveyQuestionIdNotEquals !== undefined) {
            queryParameters['surveyQuestionId.notEquals'] = requestParameters.surveyQuestionIdNotEquals;
        }

        if (requestParameters.surveyQuestionIdSpecified !== undefined) {
            queryParameters['surveyQuestionId.specified'] = requestParameters.surveyQuestionIdSpecified;
        }

        if (requestParameters.surveyQuestionIdIn) {
            queryParameters['surveyQuestionId.in'] = requestParameters.surveyQuestionIdIn;
        }

        if (requestParameters.surveyQuestionIdNotIn) {
            queryParameters['surveyQuestionId.notIn'] = requestParameters.surveyQuestionIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyFromJSON));
    }

    /**
     */
    async getAllSurveys(requestParameters: GetAllSurveysRequest): Promise<Array<Survey>> {
        const response = await this.getAllSurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSurveyRaw(requestParameters: GetSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     */
    async getSurvey(requestParameters: GetSurveyRequest): Promise<Survey> {
        const response = await this.getSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateSurveyRaw(requestParameters: PartialUpdateSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSurvey.');
        }

        if (requestParameters.survey === null || requestParameters.survey === undefined) {
            throw new runtime.RequiredError('survey','Required parameter requestParameters.survey was null or undefined when calling partialUpdateSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyToJSON(requestParameters.survey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateSurvey(requestParameters: PartialUpdateSurveyRequest): Promise<Survey> {
        const response = await this.partialUpdateSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateSurveyRaw(requestParameters: UpdateSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSurvey.');
        }

        if (requestParameters.survey === null || requestParameters.survey === undefined) {
            throw new runtime.RequiredError('survey','Required parameter requestParameters.survey was null or undefined when calling updateSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/surveys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyToJSON(requestParameters.survey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     */
    async updateSurvey(requestParameters: UpdateSurveyRequest): Promise<Survey> {
        const response = await this.updateSurveyRaw(requestParameters);
        return await response.value();
    }

}
