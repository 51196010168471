/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanySectorEnumFilter
 */
export interface CompanySectorEnumFilter {
    /**
     * 
     * @type {string}
     * @memberof CompanySectorEnumFilter
     */
    equals?: CompanySectorEnumFilterEqualsEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanySectorEnumFilter
     */
    notEquals?: CompanySectorEnumFilterNotEqualsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySectorEnumFilter
     */
    specified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySectorEnumFilter
     */
    _in?: Array<CompanySectorEnumFilterInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySectorEnumFilter
     */
    notIn?: Array<CompanySectorEnumFilterNotInEnum>;
}

/**
* @export
* @enum {string}
*/
export enum CompanySectorEnumFilterEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum CompanySectorEnumFilterNotEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum CompanySectorEnumFilterInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum CompanySectorEnumFilterNotInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}

export function CompanySectorEnumFilterFromJSON(json: any): CompanySectorEnumFilter {
    return CompanySectorEnumFilterFromJSONTyped(json, false);
}

export function CompanySectorEnumFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySectorEnumFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equals': !exists(json, 'equals') ? undefined : json['equals'],
        'notEquals': !exists(json, 'notEquals') ? undefined : json['notEquals'],
        'specified': !exists(json, 'specified') ? undefined : json['specified'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'notIn') ? undefined : json['notIn'],
    };
}

export function CompanySectorEnumFilterToJSON(value?: CompanySectorEnumFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equals': value.equals,
        'notEquals': value.notEquals,
        'specified': value.specified,
        'in': value._in,
        'notIn': value.notIn,
    };
}


