import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { RootStackScreenProps } from '../../../types';
import SelfAssessmentScreen from './Component/SelfAssestment/SelfAssessmentScreen';
import SurveyScreen from './Component/SelfAssestment/SurveyScreen';
import CompanyData from './ComponentProfile/CompanyData';
import ConsultancyData from './ComponentProfile/ConsultancyData';
import LinkedCompaniesData from './ComponentProfile/LinkedCompaniesData';

import PartnerData from './ComponentProfile/PartnerData';
import PendingRequest from './ComponentProfile/PendingRequest';
import PartnerProgramScreen from './ComponentProfile/PartnerProgram/PartnerProgramScreen';
import AddNewPartnerProgramScreen from './ComponentProfile/PartnerProgram/AddNewPartnerProgramScreen';
import PlansData from './ComponentProfile/PlansData';
import ProfileMenu from './ComponentProfile/Profile';
import ProfileData from './ComponentProfile/ProfileData';
import UserManagement from './ComponentProfile/UserManagement';
import CreditsNavigator from './CreditsNavigator';
import DashboardScreen from './DashboardScreen';
import InsightHome from './ComponentProfile/Insights/InsightHome';
import InsightDetailComponent from './ComponentProfile/Insights/InsightDetailComponent';
import PartnerProgramListScreen from './ComponentProfile/PartnerProgram/PartnerProgramListScreen';
import NotificationPage from "@screens/Dashboard/ComponentProfile/NotificationPage";

export default function ProfileScreen({ navigation, route }: RootStackScreenProps<'ProfileScreen'>) {
  const ProfileStack = createNativeStackNavigator();
  

    return (
      <ProfileStack.Navigator initialRouteName="Profile">
        <ProfileStack.Screen name="Profile" component={ProfileMenu} options={{ headerShown: false }} />
        <ProfileStack.Screen name="ProfileData" component={ProfileData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="InsightHome" component={InsightHome} options={{ headerShown: false }} />
        <ProfileStack.Screen name="InsightDetail" component={InsightDetailComponent} options={{ headerShown: false }} />
        <ProfileStack.Screen name="CompanyData" component={CompanyData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="PlansData" component={PlansData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="CreditsNavigator" component={CreditsNavigator} options={{ headerShown: false }} />
        <ProfileStack.Screen name="ConsultancyData" component={ConsultancyData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="PartnerData" component={PartnerData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="Dashboard" component={DashboardScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="SelfAssessment" component={SelfAssessmentScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="Survey" component={SurveyScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="UserManagement" component={UserManagement} options={{ headerShown: false }} />        
        <ProfileStack.Screen name="LinkedCompaniesData" component={LinkedCompaniesData} options={{ headerShown: false }} />
        <ProfileStack.Screen name="PendingRequest" component={PendingRequest} options={{ headerShown: false }} />
        <ProfileStack.Screen name="PartnerProgramScreen" component={PartnerProgramScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="AddNewPartnerProgram" component={AddNewPartnerProgramScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="PartnerProgramList" component={PartnerProgramListScreen} options={{ headerShown: false }} />
        <ProfileStack.Screen name="NotificationPage" component={NotificationPage} options={{ headerShown: false }} />
      </ProfileStack.Navigator>
    )
  }
