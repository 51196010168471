/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BlocchiImpresa,
    BlocchiImpresaFromJSON,
    BlocchiImpresaToJSON,
    CompanyDataBlock,
    CompanyDataBlockFromJSON,
    CompanyDataBlockToJSON,
} from '../models';

export interface GetCompanyDataBlockDataRequest {
    id: number;
}

export interface ImportCompanyDataBlockRequest {
    companyDataBlock: CompanyDataBlock;
}

/**
 * 
 */
export class CustomCompanyDataBlockResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCompanyDataBlockDataRaw(requestParameters: GetCompanyDataBlockDataRequest): Promise<runtime.ApiResponse<BlocchiImpresa>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyDataBlockData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/company-data-blocks/{id}/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlocchiImpresaFromJSON(jsonValue));
    }

    /**
     */
    async getCompanyDataBlockData(requestParameters: GetCompanyDataBlockDataRequest): Promise<BlocchiImpresa> {
        const response = await this.getCompanyDataBlockDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async importCompanyDataBlockRaw(requestParameters: ImportCompanyDataBlockRequest): Promise<runtime.ApiResponse<CompanyDataBlock>> {
        if (requestParameters.companyDataBlock === null || requestParameters.companyDataBlock === undefined) {
            throw new runtime.RequiredError('companyDataBlock','Required parameter requestParameters.companyDataBlock was null or undefined when calling importCompanyDataBlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/company-data-blocks/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDataBlockToJSON(requestParameters.companyDataBlock),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataBlockFromJSON(jsonValue));
    }

    /**
     */
    async importCompanyDataBlock(requestParameters: ImportCompanyDataBlockRequest): Promise<CompanyDataBlock> {
        const response = await this.importCompanyDataBlockRaw(requestParameters);
        return await response.value();
    }

}
