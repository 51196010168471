import hykeeStyle from '@components/hykeeStyle';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { customUserPlanResourceApi, planResourceApi } from '@services/apis/ApiConfiguration';
import { Plan, PlanNameEnum, UserPlan } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { Profile, RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import Icon from '../../Tools/Icon';
import Colors from "../../../../constants/Colors";
import "intl";
import 'intl/locale-data/jsonp/it-IT'
import { useFocusEffect } from '@react-navigation/native';
import { ColorTextLightGreen } from '@components/ColorTheme';


export default function PlansData({ navigation, route }: RootStackScreenProps<'PlansData'>, props: { profile: Profile | null }) {

  type PlanObject = {
    planType: PlanNameEnum,
    planDescription: string,
    price: number,
    isActive: boolean,
  }

  type MockPlanObject = {
    planType: string,
    planDescription: string,
    price: number,
    isActive: boolean
  }

  const [plans, setPlans] = React.useState<PlanObject[]>([]);
  const [isPlusActive, setIsPlusActive] = React.useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      planResourceApi.getAllPlans().then((allPlans: Plan[]) => {
        customUserPlanResourceApi.getUserPlansByLoggedUser().then((userPlans: UserPlan[]) => {
          let plansObj: PlanObject[] = [];
          allPlans.map((plan) => {
            let obj: PlanObject = {
              planType: plan.name!,
              planDescription: plan.description!,
              price: plan.price!,
              isActive: false,
            }
            obj.isActive = userPlans.find((userPlan) => userPlan.plan?.name == obj.planType) != undefined
            plansObj.push(obj)
          })
          setPlans(plansObj)
        })
      })
    }, [])
  );

  React.useEffect(() => {
    let planPlus = plans.find((plan) => {
      return plan.planType == PlanNameEnum.Plus
    })
    if (planPlus?.isActive) {
      setIsPlusActive(true)
    }
  }, [plans])

  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  })

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />

      <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>
          {i18n.t("screens.profile_menu.plans")}</Text>
      </View>

      <ScrollView style={[hykeeStyle.containerScroll, { marginBottom: 10 }]} >

        {plans && plans.map((plan, key) => {
          if (plan.planType == PlanNameEnum.Advisory || plan.planType == PlanNameEnum.Analyst) {
            if (isPlusActive) {
              return drawPlan(plan, key)
            }
          } else {
            return drawPlan(plan, key)
          }
        })}
        {/* Mock for partner plan */}
        {
          drawPlan(createPartnerPlan() as PlanObject, 1000)
        }
      </ScrollView>

    </LinearGradient>
  )

  function createPartnerPlan(): MockPlanObject {
    let plan: MockPlanObject = {
      planType: "PARTNER PROGRAM",
      planDescription: "Ricezione informative e servizi plus dai Partner collegati",
      price: 1500,
      isActive: true
    }
    return plan
  }
  

  function drawPlan(plan: PlanObject, key: number): JSX.Element | undefined {
    return (
      <View key={key} style={{ flexDirection: "row", marginHorizontal: 20, marginVertical: 25 }}>
        <View style={{ flex: 0.6, flexDirection: "column" }}>
          <View style={{ marginVertical: 3 }}>
            <Text style={[hykeeStyle.mainFont, styles.planTitle]}>
              {plan.planType}
            </Text>
          </View>
          <View >
            <Text style={[hykeeStyle.mainFont, styles.planDetail]}>
              {plan.planDescription}
            </Text>
          </View>

        </View>
        <View style={{ flex: 0.4, flexDirection: "column", alignItems: "flex-end" }}>
          <View style={{ marginVertical: 3 }}>
            <Text style={[hykeeStyle.mainFont, styles.planTitle]}>
              {formatter.format(plan.price)}
            </Text>
          </View>
          {plan.isActive ?
            <View >
              <Icon name="toggle-on" color={"#00CC83"} size={24}></Icon>
            </View>
            :
            <View >
              <Icon name="toggle-off" color={"#737373"} size={24}></Icon>
            </View>}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  planTitle: {
    color: "white",
    fontSize: 20,
  },
  planDetail: {
    color: "white",
    fontSize: 13,
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
});

