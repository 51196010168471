/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BeniServizi,
    BeniServiziFromJSON,
    BeniServiziFromJSONTyped,
    BeniServiziToJSON,
    SettoriAttivita,
    SettoriAttivitaFromJSON,
    SettoriAttivitaFromJSONTyped,
    SettoriAttivitaToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImpresaSociale
 */
export interface ImpresaSociale {
    /**
     * 
     * @type {BeniServizi}
     * @memberof ImpresaSociale
     */
    beniServizi?: BeniServizi;
    /**
     * 
     * @type {SettoriAttivita}
     * @memberof ImpresaSociale
     */
    settoriAttivita?: SettoriAttivita;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSociale
     */
    nlavoratoriSvantaggiati?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpresaSociale
     */
    nlavoratoriDisabili?: string;
}

export function ImpresaSocialeFromJSON(json: any): ImpresaSociale {
    return ImpresaSocialeFromJSONTyped(json, false);
}

export function ImpresaSocialeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpresaSociale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beniServizi': !exists(json, 'beniServizi') ? undefined : BeniServiziFromJSON(json['beniServizi']),
        'settoriAttivita': !exists(json, 'settoriAttivita') ? undefined : SettoriAttivitaFromJSON(json['settoriAttivita']),
        'nlavoratoriSvantaggiati': !exists(json, 'nlavoratoriSvantaggiati') ? undefined : json['nlavoratoriSvantaggiati'],
        'nlavoratoriDisabili': !exists(json, 'nlavoratoriDisabili') ? undefined : json['nlavoratoriDisabili'],
    };
}

export function ImpresaSocialeToJSON(value?: ImpresaSociale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beniServizi': BeniServiziToJSON(value.beniServizi),
        'settoriAttivita': SettoriAttivitaToJSON(value.settoriAttivita),
        'nlavoratoriSvantaggiati': value.nlavoratoriSvantaggiati,
        'nlavoratoriDisabili': value.nlavoratoriDisabili,
    };
}


