/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Plan,
    PlanFromJSON,
    PlanToJSON,
} from '../models';

export interface CreatePlanRequest {
    plan: Plan;
}

export interface DeletePlanRequest {
    id: number;
}

export interface GetPlanRequest {
    id: number;
}

export interface PartialUpdatePlanRequest {
    id: number;
    plan: Plan;
}

export interface UpdatePlanRequest {
    id: number;
    plan: Plan;
}

/**
 * 
 */
export class PlanResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPlanRaw(requestParameters: CreatePlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.plan === null || requestParameters.plan === undefined) {
            throw new runtime.RequiredError('plan','Required parameter requestParameters.plan was null or undefined when calling createPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters.plan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async createPlan(requestParameters: CreatePlanRequest): Promise<Plan> {
        const response = await this.createPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePlanRaw(requestParameters: DeletePlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePlan(requestParameters: DeletePlanRequest): Promise<void> {
        await this.deletePlanRaw(requestParameters);
    }

    /**
     */
    async getAllPlansRaw(): Promise<runtime.ApiResponse<Array<Plan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanFromJSON));
    }

    /**
     */
    async getAllPlans(): Promise<Array<Plan>> {
        const response = await this.getAllPlansRaw();
        return await response.value();
    }

    /**
     */
    async getPlanRaw(requestParameters: GetPlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async getPlan(requestParameters: GetPlanRequest): Promise<Plan> {
        const response = await this.getPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdatePlanRaw(requestParameters: PartialUpdatePlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePlan.');
        }

        if (requestParameters.plan === null || requestParameters.plan === undefined) {
            throw new runtime.RequiredError('plan','Required parameter requestParameters.plan was null or undefined when calling partialUpdatePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters.plan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePlan(requestParameters: PartialUpdatePlanRequest): Promise<Plan> {
        const response = await this.partialUpdatePlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePlanRaw(requestParameters: UpdatePlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePlan.');
        }

        if (requestParameters.plan === null || requestParameters.plan === undefined) {
            throw new runtime.RequiredError('plan','Required parameter requestParameters.plan was null or undefined when calling updatePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters.plan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async updatePlan(requestParameters: UpdatePlanRequest): Promise<Plan> {
        const response = await this.updatePlanRaw(requestParameters);
        return await response.value();
    }

}
