/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiAtto
 */
export interface EstremiAtto {
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    notaio?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    tribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    altreIndicazioni?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    dtRegistrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    localitaUfficioRegistro?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    provinciaUfficioRegistro?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    nregistrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAtto
     */
    ctipo?: string;
}

export function EstremiAttoFromJSON(json: any): EstremiAtto {
    return EstremiAttoFromJSONTyped(json, false);
}

export function EstremiAttoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiAtto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'notaio': !exists(json, 'notaio') ? undefined : json['notaio'],
        'tribunale': !exists(json, 'tribunale') ? undefined : json['tribunale'],
        'altreIndicazioni': !exists(json, 'altreIndicazioni') ? undefined : json['altreIndicazioni'],
        'dtRegistrazione': !exists(json, 'dtRegistrazione') ? undefined : json['dtRegistrazione'],
        'localitaUfficioRegistro': !exists(json, 'localitaUfficioRegistro') ? undefined : json['localitaUfficioRegistro'],
        'provinciaUfficioRegistro': !exists(json, 'provinciaUfficioRegistro') ? undefined : json['provinciaUfficioRegistro'],
        'nregistrazione': !exists(json, 'nregistrazione') ? undefined : json['nregistrazione'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function EstremiAttoToJSON(value?: EstremiAtto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'notaio': value.notaio,
        'tribunale': value.tribunale,
        'altreIndicazioni': value.altreIndicazioni,
        'dtRegistrazione': value.dtRegistrazione,
        'localitaUfficioRegistro': value.localitaUfficioRegistro,
        'provinciaUfficioRegistro': value.provinciaUfficioRegistro,
        'nregistrazione': value.nregistrazione,
        'ctipo': value.ctipo,
    };
}


