import hykeeStyle from '@components/hykeeStyle';
import { CompanySaved, CompanySearched } from '@services/apis/generated';
import moment from 'moment';
import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { i18n } from '../../../i18n/i18n';



export default function PreviousSearches(props: { companiesSearched: CompanySearched[], goToCompany: (companySaved: CompanySaved) => void }) {

    function truncate (word: string, length: number) {
        return word.length <= length ? word : word.substring(0, length) + "..."
    }

    return (
        <View style={styles.col}>
            <Text style={styles.title}>
                {i18n.t('screens.saved_companies.title_previous')}
            </Text>
            <View style={styles.header}>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.header_name')}
                </Text>
                <Text style={styles.textheader}>
                    {i18n.t('screens.saved_companies.header_date')}
                </Text>
            </View>
            {
                (props.companiesSearched && props.companiesSearched.length == 0) &&
                <View style={styles.row}>
                    <Text style={styles.textheaderNoCompaniesSearched}>
                        {i18n.t('screens.research.no_companies_searched')}
                    </Text>
                </View>
            }
            <ScrollView nestedScrollEnabled style={hykeeStyle.scrollViewMH}>
                {
                    (props.companiesSearched && props.companiesSearched.length > 0) && props.companiesSearched.slice(0, 10)?.map((companySearched, key) => {
                        return (
                            <TouchableOpacity key={key} style={styles.row}
                                onPress={() => {
                                    props.goToCompany(companySearched)
                                }}  >
                                <Text style={styles.textheader}>
                                    {truncate(String(companySearched?.company?.name), 13)}
                                </Text>
                                <Text style={styles.textheader}>
                                    {moment(companySearched?.date).format("DD/MM/yyyy")}
                                </Text>
                            </TouchableOpacity>
                        )
                    })
                }
            </ScrollView>
        </View >
    );



}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        backgroundColor: '#292929',
    },
    header: {
        flexDirection: 'row',
        backgroundColor: '#404040',
    },
    textheaderNoCompaniesSearched: {
        flex: 1,
        textAlign: 'center',
        fontSize: 14,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white'
    },
    textheader: {
        flex: 1,
        textAlignVertical: 'center',
        textAlign: 'center',
        fontSize: 14,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white'
    },
    title: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white'
    },
    icon: {
        width: 5,
        height: 5,

    },
    textUnderTable: {
        alignSelf: 'flex-start',
        fontSize: 13,
        paddingHorizontal: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    textLabel: {
        alignSelf: 'flex-start',
        fontSize: 18,

        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    col: {
        flexDirection: 'column',
        marginBottom: 30
    },
    label: {
        color: 'white',
        fontSize: 17,
        paddingVertical: 5,
        fontFamily: 'poppins',
    },
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        display: 'flex',
        fontFamily: 'poppins',
        padding: 8,
        margin: 0.5,
        borderRadius: 13,
        overflow: 'hidden'
    },
})