import { AlertHelper } from "@helpers/AlertHelper";
import { customMarketCompetitorResourceApi } from "@services/apis/ApiConfiguration";
import { Company, CompanyData, MarketCompetitorResponseDTO } from "@services/apis/generated";
import React, { useContext, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { i18n } from "../../../../i18n/i18n";
import SharedContext from "../../../../navigation/SharedContext";
import Icon from "../../../Tools/Icon";
import * as Localization from 'expo-localization';
import { BackgroundColorButtonGreyDark, BackgroundColorButtonGreyLight } from "@components/ColorTheme";



export default function CompetitorList(props: { deleteCompetitors: Boolean, competitors?: MarketCompetitorResponseDTO[], goToCompany: (company: Company) => void, company: Company }) {

    const [companyCompetitors, setCompanyCompetitors] = React.useState<MarketCompetitorResponseDTO[]>([]);
    let index: number = 0;
    const sharedContext = useContext(SharedContext)


    const formatter = new Intl.NumberFormat(Localization.locale)

    const deleteFromMyMarketCompetitor = (marketCompetitor: MarketCompetitorResponseDTO) => {
        customMarketCompetitorResourceApi.deleteMarketCompetitorForLoggedUser({ id: marketCompetitor.marketCompetitor?.id! }).then(() => {
            customMarketCompetitorResourceApi.getAllMyMarketCompetitors().then((myMarketCompetitors) => {
                if (myMarketCompetitors) {
                    setCompanyCompetitors(myMarketCompetitors.filter(mkr => mkr.marketCompetitor?.reference?.id == props.company.id));
                    sharedContext.setMyMarketCompetitors(myMarketCompetitors);
                }
            }).catch(error => AlertHelper.showSimpleErrorAlert());
        }).catch(error => AlertHelper.showSimpleErrorAlert());
    }

    useEffect(() => {
        if (props.competitors && props.company)
            setCompanyCompetitors(props.competitors?.filter(mkr => mkr.marketCompetitor?.reference?.id == props.company.id));
    }, [props.competitors, props.company]);

    function HeaderCompetitorList() {
        return (
            <>

                <View style={[styles.row, styles.contentHeader]}>
                    <View style={{ alignItems: 'center', flex: 0.3 }}>
                        <Text style={styles.textHeader}>
                            {i18n.t('components.market_competitors.company')}
                        </Text>
                    </View>
                    <View style={{ alignItems: 'center', flex: 0.3 }}>
                        <Text style={styles.textHeader}>
                            {i18n.t('components.market_competitors.revenue')}
                        </Text>
                    </View>
                    <View style={{ alignItems: 'center', flex: 0.2 }}>
                        <Text style={styles.textHeader}>
                            {i18n.t('components.market_competitors.ebitda')}
                        </Text>
                    </View>
                    <View style={{ alignItems: 'center', flex: 0.2 }}>
                        <Text style={styles.textHeader}>
                            {i18n.t('components.market_competitors.leva')}
                        </Text>
                    </View>
                    <View style={{ alignItems: 'center', flex: 0.2 }}>
                        <Text style={styles.textHeader}>
                            {i18n.t('components.market_competitors.financial_score')}
                        </Text>
                    </View>
                </View>


            </>
        )
    }

    return (
        <>
            <HeaderCompetitorList />

            {companyCompetitors && companyCompetitors?.map((competitor, key) => {
                let revenue = competitor.revenue ? parseFloat(competitor.revenue!.toFixed(0)) : undefined;
                let ebitda = competitor.ebitda ? parseFloat((competitor.ebitda! * 100).toFixed(1)) : undefined;
                let leva = competitor.leva ? parseFloat(competitor.leva!.toFixed(0)) : undefined;
                let financialScore = competitor.leva ? parseFloat(competitor.financialScore!.toFixed(1)) : undefined;
                return (
                    <View key={key} style={key % 2 == 1 ? [styles.row, { backgroundColor: BackgroundColorButtonGreyDark() }] : [styles.row, { backgroundColor: '#292929' }]}>
                        <TouchableOpacity style={{ flex: 0.3 }}
                            onPress={() => {
                                sharedContext.setSelectedCompany(competitor.marketCompetitor?.competitor)
                                props.goToCompany(competitor.marketCompetitor?.competitor!);
                            }}>
                            <Text numberOfLines={2} style={[styles.textStart, { marginLeft: 15, textAlign: 'left' }]}>
                                {(companyCompetitors?.indexOf(competitor) == undefined ? null :
                                    companyCompetitors.indexOf(competitor) + 1) + '. ' + competitor.marketCompetitor?.competitor?.name
                                }
                            </Text>
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', flex: 0.25, justifyContent: 'center', }}>
                            <Text style={[styles.data, { textAlign: 'center' }]}>
                                { revenue ? ' ' + formatter.format(revenue) : '0'}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', flex: 0.2 }}>

                            <Text style={[styles.data, { textAlign: 'center' }]}>
                                {ebitda ? ' ' + formatter.format(ebitda) + '%' : '0' }
                            </Text>
                        </View>
                        <View style={{ justifyContent: 'center', flexDirection: 'row', flex: 0.2, }}>

                            <Text style={[styles.data, { textAlign: 'center' }]}>
                                {leva ? ' ' + formatter.format(leva) : '0' }
                            </Text>
                        </View>
                        <View style={{ justifyContent: 'center', flexDirection: 'row', flex: 0.2, }}>

                            <Text style={[styles.data, { textAlign: 'center' }]}>
                                {financialScore ? ' ' + formatter.format(financialScore) + '%' : '0'}
                            </Text>
                        </View>
                        {props.deleteCompetitors && <View style={{ flex: 0.05, alignSelf: 'center', }}>
                            <TouchableOpacity style={styles.deleteIcon}
                                onPress={() => { deleteFromMyMarketCompetitor(competitor) }}
                            >
                                <Icon name={'remove'} color={"#D72424"} />
                            </TouchableOpacity>
                        </View>}
                    </View>
                )
            })}

        </>
    )
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        paddingVertical: 10,
        justifyContent: 'space-between'
    },
    scroll: {
        flex: 1,
        // marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        fontFamily: 'poppins',
    },
    textStart: {
        color: 'white',
        paddingVertical: 5,
        fontFamily: 'poppins',
        fontSize: 11
    },
    contentHeader: {
        marginLeft: 0,
        marginTop: -10,
        backgroundColor: BackgroundColorButtonGreyLight()
    },
    textHeader: {
        color: 'white',
        fontFamily: 'poppins',
        fontWeight: 'bold',
        fontSize: 11,
        paddingVertical: 5,
    },
    data: {
        color: 'white',
        fontSize: 12,
        fontFamily: 'poppins',
        paddingVertical: 5,
        alignSelf: 'center'
    },
    deleteIcon: {
        justifyContent: 'flex-end'
    },
})