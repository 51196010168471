import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useState } from "react";
import { StatusBar } from 'expo-status-bar';
import { Platform, ScrollView, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { i18n } from "@i18n/i18n";
import Icon from "@screens/Tools/Icon";
import { customCompanySurveyResourceApi, customSurveyResourceApi } from "@services/apis/ApiConfiguration";
import { Company, CompanySurvey, CompanySurveyCompletedEnum } from "@services/apis/generated";
import { AlertHelper } from "@helpers/AlertHelper";
import HykeeList, { ConfigList } from "@screens/ComponentShared/HykeeList";
import { RootStackScreenProps } from "../../../../../types";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { useFocusEffect } from "@react-navigation/native";
import Colors from "../../../../../constants/Colors";
import hykeeStyle from "@components/hykeeStyle";
import { ColorTextLightGreen } from "@components/ColorTheme";
import moment from "moment";

export default function SurveyScreen({ navigation, route }: RootStackScreenProps<"Survey">) {

  const [arrayHykeeList, setArrayHykeeList] = React.useState<any>([]);
  const [configList, setConfigList] = React.useState<ConfigList>({ listTitle: "", key: "", type: '' });
  const [company, setCompany] = React.useState<Company>((route.params?.company ? route.params.company : route.params) as Company);
  const [companySurvey, setCompanySurvey] = React.useState<CompanySurvey>();
  const [companySurveys, setCompanySurveys] = React.useState<CompanySurvey[]>([]);
  const [companySurveysDate, setCompanySurveysDate] = useState([]);

  useFocusEffect(
    useCallback(() => {
      getCompanySurvey();
    }, [])
  );

  const navigateToBusinessRating = (value: any): void => {
    if (companySurveys) {
      for (let company of companySurveys) {
        if (value.item.id == company.survey?.id && company.completed == CompanySurveyCompletedEnum.Completed) {
          NavigationHelper.navigateToBusinessRating(navigation, company);
        }
      }
    }

  }

  function listCallback(value: any) {
    customCompanySurveyResourceApi.createCompanySurveyCustom({ companySurvey: { company: company, survey: value.item } }).then((res) => {
      setCompanySurvey(res);
      NavigationHelper.navigateToSelfAssessment(navigation, res);
    }).catch(error => { console.log(error), AlertHelper.showSimpleErrorAlert() });

  }

  function getSurvey(companySurvey: Array<CompanySurvey>) {
    customSurveyResourceApi.getAllSurveysFiltered({ customSurveyCriteria: {} }).then((surveyList) => {
      let surveyCompletedList: any[] = surveyList;

      for (let survey of surveyCompletedList) {
        for (let company of companySurvey) {
          if (survey.id == company.survey?.id) {
            survey.completed = company.completed;
            if(company.updateDate!=undefined){
              survey.updateDate = moment(company.updateDate).format("DD/MM/yyyy");
            }else{
              survey.updateDate= undefined
            }
          }
        }
      }

      setArrayHykeeList(surveyCompletedList);
      let configHykeeList: ConfigList = {  key: 'titleIt', type: 'survey', enableActions: true };
      setConfigList(configHykeeList);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const getCompanySurvey = (): void => {
    customCompanySurveyResourceApi.getAllCompanySurveysFilter({ customCompanySurveyCriteria: { companyId: { equals: company.id } } }).then((res) => {
      setCompanySurveys(res);
      getSurvey(res);
    })
  }

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t('survey_screen.dashboard.title')}</Text>


      </View>
      <ScrollView style={styles.containerList} nestedScrollEnabled={true}>
        <HykeeList
          list={arrayHykeeList}
          config={configList}
          passToParent={listCallback}
          navigate={navigateToBusinessRating} />
      </ScrollView>

    </LinearGradient>
  );

}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 50,
    bottom: 20,
    left: 0,
    right: 0,
  },
  containerList: {
    marginHorizontal: 20,
  },
  containerBackGradient: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    padding: 8,
    margin: 0.5,
    borderRadius: 13,
    overflow: 'hidden'
  },
  col: {
    flex: 0.7,
    flexDirection: 'column'
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  borderGradient: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  containerTitle: {
    marginTop: 50,
    marginLeft: 20,
    flexDirection: "row",
    alignItems: 'center',
  },
  goBack: {
    marginRight: 20
  },
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  contentTitle: {
    flexDirection: 'row',
  },
  title: {
    alignSelf: 'center',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
  buttonShowHide: {
    alignSelf: 'center',
    flex: 0.1,
  },
  containerTextInput: {
    flex: 0.7,
    justifyContent: 'flex-start',
    fontFamily: 'poppins',
  },
  textUnderTable: {
    alignSelf: 'flex-start',
    fontSize: 13,
    padding: 10,
    fontFamily: 'poppins',
    color: 'white'
  },
  labelTextInput: {
    alignSelf: 'flex-start',
    color: 'white',
    marginBottom: 5,
    fontFamily: 'poppins',
  },
  textInput: {
    backgroundColor: '#464646',
    padding: 13,
    color: 'white',
    borderRadius: 5,
    marginBottom: 15,
    fontFamily: 'poppins',
  },
  viewForgot: {
    alignSelf: 'flex-end',
    fontFamily: 'poppins',
  },
  textForgot: {
    color: '#00CC83',
    fontFamily: 'poppins',
  },
  buttonLogin: {
    marginTop: 40,
    backgroundColor: '#222222',
    padding: 20,
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  textLogin: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
    fontFamily: 'poppins',
  },
  viewRegistration: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 20,
    flex: 0.1
  },
  textRegistration: {
    color: 'white',
    fontFamily: 'poppins',
  },
  header: {
    padding: 20,
    backgroundColor: '#fff',
  },
  contentAccordion: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  labelAzienda: {
    padding: 10,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  label: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  textSearch: {
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  questionText: {
    textAlign: 'left',
    marginLeft: 15,
    fontFamily: 'poppins',
  },
  containerSA: {
    flex: 1,
    marginHorizontal: 20,
  },
  containerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 50,
    padding: 20,
    borderRadius: 13,
  },
  imageButton: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 30,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
  },

});