/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbilitazioniImpiantisti,
    AbilitazioniImpiantistiFromJSON,
    AbilitazioniImpiantistiFromJSONTyped,
    AbilitazioniImpiantistiToJSON,
    AttiConferimentoCariche,
    AttiConferimentoCaricheFromJSON,
    AttiConferimentoCaricheFromJSONTyped,
    AttiConferimentoCaricheToJSON,
    Fallimento,
    FallimentoFromJSON,
    FallimentoFromJSONTyped,
    FallimentoToJSON,
    Indirizzo,
    IndirizzoFromJSON,
    IndirizzoFromJSONTyped,
    IndirizzoToJSON,
    IndirizzoPostaCertificata,
    IndirizzoPostaCertificataFromJSON,
    IndirizzoPostaCertificataFromJSONTyped,
    IndirizzoPostaCertificataToJSON,
    InformazioniSocio,
    InformazioniSocioFromJSON,
    InformazioniSocioFromJSONTyped,
    InformazioniSocioToJSON,
    Licenze,
    LicenzeFromJSON,
    LicenzeFromJSONTyped,
    LicenzeToJSON,
    PersonaFisica,
    PersonaFisicaFromJSON,
    PersonaFisicaFromJSONTyped,
    PersonaFisicaToJSON,
    PersonaGiuridica,
    PersonaGiuridicaFromJSON,
    PersonaGiuridicaFromJSONTyped,
    PersonaGiuridicaToJSON,
    Quota,
    QuotaFromJSON,
    QuotaFromJSONTyped,
    QuotaToJSON,
    RuoliPersona,
    RuoliPersonaFromJSON,
    RuoliPersonaFromJSONTyped,
    RuoliPersonaToJSON,
    Telefax,
    TelefaxFromJSON,
    TelefaxFromJSONTyped,
    TelefaxToJSON,
    Telefono,
    TelefonoFromJSON,
    TelefonoFromJSONTyped,
    TelefonoToJSON,
} from './';

/**
 * 
 * @export
 * @interface Persona
 */
export interface Persona {
    /**
     * 
     * @type {PersonaFisica}
     * @memberof Persona
     */
    personaFisica?: PersonaFisica;
    /**
     * 
     * @type {PersonaGiuridica}
     * @memberof Persona
     */
    personaGiuridica?: PersonaGiuridica;
    /**
     * 
     * @type {InformazioniSocio}
     * @memberof Persona
     */
    informazioniSocio?: InformazioniSocio;
    /**
     * 
     * @type {IndirizzoPostaCertificata}
     * @memberof Persona
     */
    indirizzoPostaCertificata?: IndirizzoPostaCertificata;
    /**
     * 
     * @type {Indirizzo}
     * @memberof Persona
     */
    indirizzo?: Indirizzo;
    /**
     * 
     * @type {Telefono}
     * @memberof Persona
     */
    telefono?: Telefono;
    /**
     * 
     * @type {Telefax}
     * @memberof Persona
     */
    telefax?: Telefax;
    /**
     * 
     * @type {Quota}
     * @memberof Persona
     */
    quota?: Quota;
    /**
     * 
     * @type {AttiConferimentoCariche}
     * @memberof Persona
     */
    attiConferimentoCariche?: AttiConferimentoCariche;
    /**
     * 
     * @type {Fallimento}
     * @memberof Persona
     */
    fallimento?: Fallimento;
    /**
     * 
     * @type {AbilitazioniImpiantisti}
     * @memberof Persona
     */
    abilitazioniImpiantisti?: AbilitazioniImpiantisti;
    /**
     * 
     * @type {RuoliPersona}
     * @memberof Persona
     */
    ruoliPersona?: RuoliPersona;
    /**
     * 
     * @type {Licenze}
     * @memberof Persona
     */
    licenze?: Licenze;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    progressivo?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    tipoModifica?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    cognome?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    cfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    frappresentanteRea?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    frappresentanteRi?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    frappresentanteAe?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    famministratore?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    fsindaco?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    felettore?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    ffirmaDepositata?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    fincaricatoPco?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    nrea?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    nrd?: string;
}

export function PersonaFromJSON(json: any): Persona {
    return PersonaFromJSONTyped(json, false);
}

export function PersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Persona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personaFisica': !exists(json, 'personaFisica') ? undefined : PersonaFisicaFromJSON(json['personaFisica']),
        'personaGiuridica': !exists(json, 'personaGiuridica') ? undefined : PersonaGiuridicaFromJSON(json['personaGiuridica']),
        'informazioniSocio': !exists(json, 'informazioniSocio') ? undefined : InformazioniSocioFromJSON(json['informazioniSocio']),
        'indirizzoPostaCertificata': !exists(json, 'indirizzoPostaCertificata') ? undefined : IndirizzoPostaCertificataFromJSON(json['indirizzoPostaCertificata']),
        'indirizzo': !exists(json, 'indirizzo') ? undefined : IndirizzoFromJSON(json['indirizzo']),
        'telefono': !exists(json, 'telefono') ? undefined : TelefonoFromJSON(json['telefono']),
        'telefax': !exists(json, 'telefax') ? undefined : TelefaxFromJSON(json['telefax']),
        'quota': !exists(json, 'quota') ? undefined : QuotaFromJSON(json['quota']),
        'attiConferimentoCariche': !exists(json, 'attiConferimentoCariche') ? undefined : AttiConferimentoCaricheFromJSON(json['attiConferimentoCariche']),
        'fallimento': !exists(json, 'fallimento') ? undefined : FallimentoFromJSON(json['fallimento']),
        'abilitazioniImpiantisti': !exists(json, 'abilitazioniImpiantisti') ? undefined : AbilitazioniImpiantistiFromJSON(json['abilitazioniImpiantisti']),
        'ruoliPersona': !exists(json, 'ruoliPersona') ? undefined : RuoliPersonaFromJSON(json['ruoliPersona']),
        'licenze': !exists(json, 'licenze') ? undefined : LicenzeFromJSON(json['licenze']),
        'progressivo': !exists(json, 'progressivo') ? undefined : json['progressivo'],
        'tipoModifica': !exists(json, 'tipoModifica') ? undefined : json['tipoModifica'],
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'cfiscale': !exists(json, 'cfiscale') ? undefined : json['cfiscale'],
        'frappresentanteRea': !exists(json, 'frappresentanteRea') ? undefined : json['frappresentanteRea'],
        'frappresentanteRi': !exists(json, 'frappresentanteRi') ? undefined : json['frappresentanteRi'],
        'frappresentanteAe': !exists(json, 'frappresentanteAe') ? undefined : json['frappresentanteAe'],
        'famministratore': !exists(json, 'famministratore') ? undefined : json['famministratore'],
        'fsindaco': !exists(json, 'fsindaco') ? undefined : json['fsindaco'],
        'felettore': !exists(json, 'felettore') ? undefined : json['felettore'],
        'ffirmaDepositata': !exists(json, 'ffirmaDepositata') ? undefined : json['ffirmaDepositata'],
        'fincaricatoPco': !exists(json, 'fincaricatoPco') ? undefined : json['fincaricatoPco'],
        'nrea': !exists(json, 'nrea') ? undefined : json['nrea'],
        'nrd': !exists(json, 'nrd') ? undefined : json['nrd'],
    };
}

export function PersonaToJSON(value?: Persona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personaFisica': PersonaFisicaToJSON(value.personaFisica),
        'personaGiuridica': PersonaGiuridicaToJSON(value.personaGiuridica),
        'informazioniSocio': InformazioniSocioToJSON(value.informazioniSocio),
        'indirizzoPostaCertificata': IndirizzoPostaCertificataToJSON(value.indirizzoPostaCertificata),
        'indirizzo': IndirizzoToJSON(value.indirizzo),
        'telefono': TelefonoToJSON(value.telefono),
        'telefax': TelefaxToJSON(value.telefax),
        'quota': QuotaToJSON(value.quota),
        'attiConferimentoCariche': AttiConferimentoCaricheToJSON(value.attiConferimentoCariche),
        'fallimento': FallimentoToJSON(value.fallimento),
        'abilitazioniImpiantisti': AbilitazioniImpiantistiToJSON(value.abilitazioniImpiantisti),
        'ruoliPersona': RuoliPersonaToJSON(value.ruoliPersona),
        'licenze': LicenzeToJSON(value.licenze),
        'progressivo': value.progressivo,
        'tipoModifica': value.tipoModifica,
        'cognome': value.cognome,
        'nome': value.nome,
        'cciaa': value.cciaa,
        'cfiscale': value.cfiscale,
        'frappresentanteRea': value.frappresentanteRea,
        'frappresentanteRi': value.frappresentanteRi,
        'frappresentanteAe': value.frappresentanteAe,
        'famministratore': value.famministratore,
        'fsindaco': value.fsindaco,
        'felettore': value.felettore,
        'ffirmaDepositata': value.ffirmaDepositata,
        'fincaricatoPco': value.fincaricatoPco,
        'nrea': value.nrea,
        'nrd': value.nrd,
    };
}


