/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Socio,
    SocioFromJSON,
    SocioFromJSONTyped,
    SocioToJSON,
} from './';

/**
 * 
 * @export
 * @interface Soci
 */
export interface Soci {
    /**
     * 
     * @type {Array<Socio>}
     * @memberof Soci
     */
    socio: Array<Socio>;
}

export function SociFromJSON(json: any): Soci {
    return SociFromJSONTyped(json, false);
}

export function SociFromJSONTyped(json: any, ignoreDiscriminator: boolean): Soci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'socio': ((json['socio'] as Array<any>).map(SocioFromJSON)),
    };
}

export function SociToJSON(value?: Soci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'socio': ((value.socio as Array<any>).map(SocioToJSON)),
    };
}


