/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserExtended,
    UserExtendedFromJSON,
    UserExtendedFromJSONTyped,
    UserExtendedToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminUserFullDTO
 */
export interface AdminUserFullDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminUserFullDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserFullDTO
     */
    activated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    langKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminUserFullDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminUserFullDTO
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminUserFullDTO
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof AdminUserFullDTO
     */
    authorities?: Set<string>;
    /**
     * 
     * @type {UserExtended}
     * @memberof AdminUserFullDTO
     */
    userExtended?: UserExtended;
}

export function AdminUserFullDTOFromJSON(json: any): AdminUserFullDTO {
    return AdminUserFullDTOFromJSONTyped(json, false);
}

export function AdminUserFullDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserFullDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'login': json['login'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'langKey': !exists(json, 'langKey') ? undefined : json['langKey'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (new Date(json['lastModifiedDate'])),
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'userExtended': !exists(json, 'userExtended') ? undefined : UserExtendedFromJSON(json['userExtended']),
    };
}

export function AdminUserFullDTOToJSON(value?: AdminUserFullDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'login': value.login,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'imageUrl': value.imageUrl,
        'activated': value.activated,
        'langKey': value.langKey,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate.toISOString()),
        'authorities': value.authorities,
        'userExtended': UserExtendedToJSON(value.userExtended),
    };
}


