/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrascrizioneRs,
    TrascrizioneRsFromJSON,
    TrascrizioneRsFromJSONTyped,
    TrascrizioneRsToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrascrizioniRs
 */
export interface TrascrizioniRs {
    /**
     * 
     * @type {Array<TrascrizioneRs>}
     * @memberof TrascrizioniRs
     */
    trascrizioneRs: Array<TrascrizioneRs>;
}

export function TrascrizioniRsFromJSON(json: any): TrascrizioniRs {
    return TrascrizioniRsFromJSONTyped(json, false);
}

export function TrascrizioniRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrascrizioniRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trascrizioneRs': ((json['trascrizioneRs'] as Array<any>).map(TrascrizioneRsFromJSON)),
    };
}

export function TrascrizioniRsToJSON(value?: TrascrizioniRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trascrizioneRs': ((value.trascrizioneRs as Array<any>).map(TrascrizioneRsToJSON)),
    };
}


