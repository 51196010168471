/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormaAmministrativa
 */
export interface FormaAmministrativa {
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    finCarica?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    forganoControllo?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    nminAmministratori?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaAmministrativa
     */
    nmaxAmministratori?: string;
}

export function FormaAmministrativaFromJSON(json: any): FormaAmministrativa {
    return FormaAmministrativaFromJSONTyped(json, false);
}

export function FormaAmministrativaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormaAmministrativa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'finCarica': !exists(json, 'finCarica') ? undefined : json['finCarica'],
        'forganoControllo': !exists(json, 'forganoControllo') ? undefined : json['forganoControllo'],
        'nminAmministratori': !exists(json, 'nminAmministratori') ? undefined : json['nminAmministratori'],
        'nmaxAmministratori': !exists(json, 'nmaxAmministratori') ? undefined : json['nmaxAmministratori'],
    };
}

export function FormaAmministrativaToJSON(value?: FormaAmministrativa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'c': value.c,
        'finCarica': value.finCarica,
        'forganoControllo': value.forganoControllo,
        'nminAmministratori': value.nminAmministratori,
        'nmaxAmministratori': value.nmaxAmministratori,
    };
}


