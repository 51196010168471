/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dichiarazione,
    DichiarazioneFromJSON,
    DichiarazioneFromJSONTyped,
    DichiarazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Dichiarazioni
 */
export interface Dichiarazioni {
    /**
     * 
     * @type {Array<Dichiarazione>}
     * @memberof Dichiarazioni
     */
    dichiarazione: Array<Dichiarazione>;
}

export function DichiarazioniFromJSON(json: any): Dichiarazioni {
    return DichiarazioniFromJSONTyped(json, false);
}

export function DichiarazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dichiarazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dichiarazione': ((json['dichiarazione'] as Array<any>).map(DichiarazioneFromJSON)),
    };
}

export function DichiarazioniToJSON(value?: Dichiarazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dichiarazione': ((value.dichiarazione as Array<any>).map(DichiarazioneToJSON)),
    };
}


