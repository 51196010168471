/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbilitazioneImpiantisti,
    AbilitazioneImpiantistiFromJSON,
    AbilitazioneImpiantistiFromJSONTyped,
    AbilitazioneImpiantistiToJSON,
} from './';

/**
 * 
 * @export
 * @interface AbilitazioniImpiantisti
 */
export interface AbilitazioniImpiantisti {
    /**
     * 
     * @type {Array<AbilitazioneImpiantisti>}
     * @memberof AbilitazioniImpiantisti
     */
    abilitazioneImpiantisti: Array<AbilitazioneImpiantisti>;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioniImpiantisti
     */
    riferimentoLegge?: string;
    /**
     * 
     * @type {string}
     * @memberof AbilitazioniImpiantisti
     */
    criferimentoLegge?: string;
}

export function AbilitazioniImpiantistiFromJSON(json: any): AbilitazioniImpiantisti {
    return AbilitazioniImpiantistiFromJSONTyped(json, false);
}

export function AbilitazioniImpiantistiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbilitazioniImpiantisti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abilitazioneImpiantisti': ((json['abilitazioneImpiantisti'] as Array<any>).map(AbilitazioneImpiantistiFromJSON)),
        'riferimentoLegge': !exists(json, 'riferimentoLegge') ? undefined : json['riferimentoLegge'],
        'criferimentoLegge': !exists(json, 'criferimentoLegge') ? undefined : json['criferimentoLegge'],
    };
}

export function AbilitazioniImpiantistiToJSON(value?: AbilitazioniImpiantisti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abilitazioneImpiantisti': ((value.abilitazioneImpiantisti as Array<any>).map(AbilitazioneImpiantistiToJSON)),
        'riferimentoLegge': value.riferimentoLegge,
        'criferimentoLegge': value.criferimentoLegge,
    };
}


