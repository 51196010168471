
export function BackgroundColorButtonDark(){
    return '#000000'
}
export function BackgroundColorButtonGreyDark(){
    return '#303030'
}
export function BackgroundColorButtonGreyLight(){
    return '#464646'
}
export function BackgroundColorButtonDarkLight(){
    return '#222222'
}
export function BackgroundColorButtonGreen(){
    return '#00CC83'
}
export function BackgroundColorButtonBlueSky(){
    return '#009dff'
}
export function BackgroundColorButtonDarkRed(){
    return '#D72424'
}
export function BackgroundColorInputText(){
    return '#464646'
}

export function ColorTextLightGreen(){
    return '#00CC83'
}
export function ColorPlaceHolder(){
    return '#737373'
}

export function ColorLabel(){
    return '#FFFFFF'
}
export function ColorTextInput(){
    return '#FFFFFF'
}

export function ColorTextButton(){
    return '#FFFFFF'
}