/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Licenza,
    LicenzaFromJSON,
    LicenzaFromJSONTyped,
    LicenzaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Licenze
 */
export interface Licenze {
    /**
     * 
     * @type {Array<Licenza>}
     * @memberof Licenze
     */
    licenza: Array<Licenza>;
}

export function LicenzeFromJSON(json: any): Licenze {
    return LicenzeFromJSONTyped(json, false);
}

export function LicenzeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Licenze {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenza': ((json['licenza'] as Array<any>).map(LicenzaFromJSON)),
    };
}

export function LicenzeToJSON(value?: Licenze | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenza': ((value.licenza as Array<any>).map(LicenzaToJSON)),
    };
}


