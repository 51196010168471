/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiPraticaRiconfermata
 */
export interface EstremiPraticaRiconfermata {
    /**
     * 
     * @type {string}
     * @memberof EstremiPraticaRiconfermata
     */
    cciaa?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPraticaRiconfermata
     */
    anno?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiPraticaRiconfermata
     */
    n?: string;
}

export function EstremiPraticaRiconfermataFromJSON(json: any): EstremiPraticaRiconfermata {
    return EstremiPraticaRiconfermataFromJSONTyped(json, false);
}

export function EstremiPraticaRiconfermataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiPraticaRiconfermata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cciaa': !exists(json, 'cciaa') ? undefined : json['cciaa'],
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'n': !exists(json, 'n') ? undefined : json['n'],
    };
}

export function EstremiPraticaRiconfermataToJSON(value?: EstremiPraticaRiconfermata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cciaa': value.cciaa,
        'anno': value.anno,
        'n': value.n,
    };
}


