/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolloAperto,
    ProtocolloApertoFromJSON,
    ProtocolloApertoFromJSONTyped,
    ProtocolloApertoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolliAperti
 */
export interface ProtocolliAperti {
    /**
     * 
     * @type {Array<ProtocolloAperto>}
     * @memberof ProtocolliAperti
     */
    protocolloAperto?: Array<ProtocolloAperto>;
    /**
     * 
     * @type {string}
     * @memberof ProtocolliAperti
     */
    fpresenzaInfo?: string;
}

export function ProtocolliApertiFromJSON(json: any): ProtocolliAperti {
    return ProtocolliApertiFromJSONTyped(json, false);
}

export function ProtocolliApertiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolliAperti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolloAperto': !exists(json, 'protocolloAperto') ? undefined : ((json['protocolloAperto'] as Array<any>).map(ProtocolloApertoFromJSON)),
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function ProtocolliApertiToJSON(value?: ProtocolliAperti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolloAperto': value.protocolloAperto === undefined ? undefined : ((value.protocolloAperto as Array<any>).map(ProtocolloApertoToJSON)),
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


