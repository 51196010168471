/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySurvey,
    CompanySurveyFromJSON,
    CompanySurveyFromJSONTyped,
    CompanySurveyToJSON,
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionFromJSONTyped,
    SurveyQuestionToJSON,
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySurveyQuestionRes
 */
export interface CompanySurveyQuestionRes {
    /**
     * 
     * @type {number}
     * @memberof CompanySurveyQuestionRes
     */
    id?: number;
    /**
     * 
     * @type {SurveyQuestion}
     * @memberof CompanySurveyQuestionRes
     */
    surveyQuestion?: SurveyQuestion;
    /**
     * 
     * @type {SurveyQuestionResponse}
     * @memberof CompanySurveyQuestionRes
     */
    surveyQuestionResponse?: SurveyQuestionResponse;
    /**
     * 
     * @type {CompanySurvey}
     * @memberof CompanySurveyQuestionRes
     */
    companySurvey?: CompanySurvey;
}

export function CompanySurveyQuestionResFromJSON(json: any): CompanySurveyQuestionRes {
    return CompanySurveyQuestionResFromJSONTyped(json, false);
}

export function CompanySurveyQuestionResFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySurveyQuestionRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surveyQuestion': !exists(json, 'surveyQuestion') ? undefined : SurveyQuestionFromJSON(json['surveyQuestion']),
        'surveyQuestionResponse': !exists(json, 'surveyQuestionResponse') ? undefined : SurveyQuestionResponseFromJSON(json['surveyQuestionResponse']),
        'companySurvey': !exists(json, 'companySurvey') ? undefined : CompanySurveyFromJSON(json['companySurvey']),
    };
}

export function CompanySurveyQuestionResToJSON(value?: CompanySurveyQuestionRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'surveyQuestion': SurveyQuestionToJSON(value.surveyQuestion),
        'surveyQuestionResponse': SurveyQuestionResponseToJSON(value.surveyQuestionResponse),
        'companySurvey': CompanySurveyToJSON(value.companySurvey),
    };
}


