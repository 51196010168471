/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnagraficaTitolare,
    AnagraficaTitolareFromJSON,
    AnagraficaTitolareFromJSONTyped,
    AnagraficaTitolareToJSON,
    DirittoPartecipazione,
    DirittoPartecipazioneFromJSON,
    DirittoPartecipazioneFromJSONTyped,
    DirittoPartecipazioneToJSON,
    Domicilio,
    DomicilioFromJSON,
    DomicilioFromJSONTyped,
    DomicilioToJSON,
    IndirizzoPostaCertificata,
    IndirizzoPostaCertificataFromJSON,
    IndirizzoPostaCertificataFromJSONTyped,
    IndirizzoPostaCertificataToJSON,
} from './';

/**
 * 
 * @export
 * @interface Titolare
 */
export interface Titolare {
    /**
     * 
     * @type {AnagraficaTitolare}
     * @memberof Titolare
     */
    anagraficaTitolare?: AnagraficaTitolare;
    /**
     * 
     * @type {Domicilio}
     * @memberof Titolare
     */
    domicilio?: Domicilio;
    /**
     * 
     * @type {IndirizzoPostaCertificata}
     * @memberof Titolare
     */
    indirizzoPostaCertificata?: IndirizzoPostaCertificata;
    /**
     * 
     * @type {DirittoPartecipazione}
     * @memberof Titolare
     */
    dirittoPartecipazione?: DirittoPartecipazione;
    /**
     * 
     * @type {Array<string>}
     * @memberof Titolare
     */
    note?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Titolare
     */
    situazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Titolare
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Titolare
     */
    csituazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Titolare
     */
    frappresentante?: string;
    /**
     * 
     * @type {string}
     * @memberof Titolare
     */
    ctipo?: string;
}

export function TitolareFromJSON(json: any): Titolare {
    return TitolareFromJSONTyped(json, false);
}

export function TitolareFromJSONTyped(json: any, ignoreDiscriminator: boolean): Titolare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anagraficaTitolare': !exists(json, 'anagraficaTitolare') ? undefined : AnagraficaTitolareFromJSON(json['anagraficaTitolare']),
        'domicilio': !exists(json, 'domicilio') ? undefined : DomicilioFromJSON(json['domicilio']),
        'indirizzoPostaCertificata': !exists(json, 'indirizzoPostaCertificata') ? undefined : IndirizzoPostaCertificataFromJSON(json['indirizzoPostaCertificata']),
        'dirittoPartecipazione': !exists(json, 'dirittoPartecipazione') ? undefined : DirittoPartecipazioneFromJSON(json['dirittoPartecipazione']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'situazione': !exists(json, 'situazione') ? undefined : json['situazione'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'csituazione': !exists(json, 'csituazione') ? undefined : json['csituazione'],
        'frappresentante': !exists(json, 'frappresentante') ? undefined : json['frappresentante'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function TitolareToJSON(value?: Titolare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anagraficaTitolare': AnagraficaTitolareToJSON(value.anagraficaTitolare),
        'domicilio': DomicilioToJSON(value.domicilio),
        'indirizzoPostaCertificata': IndirizzoPostaCertificataToJSON(value.indirizzoPostaCertificata),
        'dirittoPartecipazione': DirittoPartecipazioneToJSON(value.dirittoPartecipazione),
        'note': value.note,
        'situazione': value.situazione,
        'tipo': value.tipo,
        'csituazione': value.csituazione,
        'frappresentante': value.frappresentante,
        'ctipo': value.ctipo,
    };
}


