/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComunicazioneCuratore,
    ComunicazioneCuratoreFromJSON,
    ComunicazioneCuratoreFromJSONTyped,
    ComunicazioneCuratoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComunicazioniCuratore
 */
export interface ComunicazioniCuratore {
    /**
     * 
     * @type {Array<ComunicazioneCuratore>}
     * @memberof ComunicazioniCuratore
     */
    comunicazioneCuratore: Array<ComunicazioneCuratore>;
}

export function ComunicazioniCuratoreFromJSON(json: any): ComunicazioniCuratore {
    return ComunicazioniCuratoreFromJSONTyped(json, false);
}

export function ComunicazioniCuratoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComunicazioniCuratore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comunicazioneCuratore': ((json['comunicazioneCuratore'] as Array<any>).map(ComunicazioneCuratoreFromJSON)),
    };
}

export function ComunicazioniCuratoreToJSON(value?: ComunicazioniCuratore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comunicazioneCuratore': ((value.comunicazioneCuratore as Array<any>).map(ComunicazioneCuratoreToJSON)),
    };
}


