/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyLinkRequest,
    CompanyLinkRequestFromJSON,
    CompanyLinkRequestFromJSONTyped,
    CompanyLinkRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvitationDTO
 */
export interface InvitationDTO {
    /**
     * 
     * @type {CompanyLinkRequest}
     * @memberof InvitationDTO
     */
    companyLinkRequest?: CompanyLinkRequest;
    /**
     * 
     * @type {string}
     * @memberof InvitationDTO
     */
    email?: string;
}

export function InvitationDTOFromJSON(json: any): InvitationDTO {
    return InvitationDTOFromJSONTyped(json, false);
}

export function InvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyLinkRequest': !exists(json, 'companyLinkRequest') ? undefined : CompanyLinkRequestFromJSON(json['companyLinkRequest']),
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function InvitationDTOToJSON(value?: InvitationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyLinkRequest': CompanyLinkRequestToJSON(value.companyLinkRequest),
        'email': value.email,
    };
}


