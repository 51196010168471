import React from "react";
import {UserExtended} from "../../../services/apis/generated";

type CreditsContextType =
{
    extendedMe: UserExtended | undefined;
    setExtendedMe: React.Dispatch<React.SetStateAction<UserExtended | undefined>>;
}

const CreditsContext = React.createContext<CreditsContextType>({
    extendedMe: undefined,
    setExtendedMe(value: ((prevState: (UserExtended | undefined)) => (UserExtended | undefined)) | UserExtended | undefined): void {
    }
});

export default CreditsContext;