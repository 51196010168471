/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolloRs,
    ProtocolloRsFromJSON,
    ProtocolloRsFromJSONTyped,
    ProtocolloRsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolliRs
 */
export interface ProtocolliRs {
    /**
     * 
     * @type {Array<ProtocolloRs>}
     * @memberof ProtocolliRs
     */
    protocolloRs: Array<ProtocolloRs>;
}

export function ProtocolliRsFromJSON(json: any): ProtocolliRs {
    return ProtocolliRsFromJSONTyped(json, false);
}

export function ProtocolliRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolliRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolloRs': ((json['protocolloRs'] as Array<any>).map(ProtocolloRsFromJSON)),
    };
}

export function ProtocolliRsToJSON(value?: ProtocolliRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolloRs': ((value.protocolloRs as Array<any>).map(ProtocolloRsToJSON)),
    };
}


