/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScadenzaEsercizi
 */
export interface ScadenzaEsercizi {
    /**
     * 
     * @type {string}
     * @memberof ScadenzaEsercizi
     */
    dtPrimoEsercizio?: string;
    /**
     * 
     * @type {string}
     * @memberof ScadenzaEsercizi
     */
    eserciziSuccessivi?: string;
    /**
     * 
     * @type {string}
     * @memberof ScadenzaEsercizi
     */
    mesiProrogaBilancio?: string;
    /**
     * 
     * @type {string}
     * @memberof ScadenzaEsercizi
     */
    giorniProrogaBilancio?: string;
}

export function ScadenzaEserciziFromJSON(json: any): ScadenzaEsercizi {
    return ScadenzaEserciziFromJSONTyped(json, false);
}

export function ScadenzaEserciziFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScadenzaEsercizi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtPrimoEsercizio': !exists(json, 'dtPrimoEsercizio') ? undefined : json['dtPrimoEsercizio'],
        'eserciziSuccessivi': !exists(json, 'eserciziSuccessivi') ? undefined : json['eserciziSuccessivi'],
        'mesiProrogaBilancio': !exists(json, 'mesiProrogaBilancio') ? undefined : json['mesiProrogaBilancio'],
        'giorniProrogaBilancio': !exists(json, 'giorniProrogaBilancio') ? undefined : json['giorniProrogaBilancio'],
    };
}

export function ScadenzaEserciziToJSON(value?: ScadenzaEsercizi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtPrimoEsercizio': value.dtPrimoEsercizio,
        'eserciziSuccessivi': value.eserciziSuccessivi,
        'mesiProrogaBilancio': value.mesiProrogaBilancio,
        'giorniProrogaBilancio': value.giorniProrogaBilancio,
    };
}


