import React, {useContext, useEffect, useState} from "react";
import {StyleSheet, View, TouchableOpacity, Text, Platform, ScrollView, RefreshControl} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import hykeeStyle from "@components/hykeeStyle";
import Icon from "@screens/Tools/Icon";
import {BackgroundColorButtonDarkLight, BackgroundColorButtonGreen, ColorTextLightGreen} from "@components/ColorTheme";
import { NavigationHelper } from "@helpers/NavigationHelper";
import { i18n } from "@i18n/i18n";
import { LinearGradient } from "expo-linear-gradient";
import Colors from "../../../../constants/Colors";
import {Profile, RootStackParamList, RootStackScreenProps} from "../../../../types";
import SharedContext from "../../../navigation/SharedContext";
import CompaniesConsultantPendingList from "@screens/Dashboard/ComponentProfile/CompaniesConsultantPendingList";
import CompaniesConsultantAcceptedList from "@screens/Dashboard/ComponentProfile/CompaniesConsultantAcceptedList";
import {
    Company,
    CompanyFromJSON,
    CompanyLinkRequestCurrentStateEnum,
    exists, GetNotificationCustomRequest,
    Notification,
    NotificationResourceApi,
    NotificationTypeEnum, Pageable, PageableFromJSON,
    PartialUpdateNotificationRequest,
    PartialUpdateUserRequest, Sort,
    UpdateNotificationRequest,
    User,
    UserConsultantInviteCurrentStateEnum,
    UserConsultantInviteRoleEnum,
    UserConsultantRoleEnum,
    UserExtendedTypeEnum,
    UserFromJSON
} from "@services/apis/generated";
import {
    customNotificationResourceApi,
    customUserConsultantInviteResourceApi,
    customUserConsultantResourceApi
} from "@services/apis/ApiConfiguration";
import {ErrorHelper} from "@helpers/ErrorHelper";
import {Logger} from "@helpers/Logger";
import {get} from "lodash";
import moment from "moment";
import {read, readRefreshToken,} from "@helpers/StorageHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import BottomTabNavigator from "../../../navigation/BottomTabNavigator";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import ProfileMenuScreen from "@screens/Dashboard/ComponentProfile/ProfileMenuScreen";
import AppContext from "../../../navigation/AppContext";

export default function NotificationPage(props: {navigation: NativeStackNavigationProp<RootStackParamList, "NotificationPage">, profile: Profile | null, user?: User, company: Company | null }) {

    const sharedContext = React.useContext(SharedContext)
    const [notification, setNotification] = useState<any>([]);
    const [pages, setPages] = useState<number>(20);

    function getNotification(this: any, pageNum: number, sizeNum: number) {
        var page: Pageable = {
            page: pageNum,
            size: sizeNum,
        }
        var getNotificationCustomRequest: GetNotificationCustomRequest = {
            pageable: page
        }
        customNotificationResourceApi.getNotificationCustom(getNotificationCustomRequest).then((res) => {
            setNotification(res.content)
        })

        customNotificationResourceApi.getNotificationCount().then((res) => {
            res > 0 ? sharedContext.setNotificationCheck(true) : sharedContext.setNotificationCheck(false)
        })

    }

    const time = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            getNotification(0, pages)
        }, time);

        return () => clearInterval(interval);
    }, [])

    function notificationContent(type: NotificationTypeEnum) {
        let message = ''
        switch(type) {
            case 'SELF_ASSESSMENT_COMPILE':
                message = i18n.t('components.notification_page.self_assestment')
                break;
            case 'LINK_REQUEST':
                message = i18n.t('components.notification_page.link_request')
                break;
            case 'INSIGHT':
                message = i18n.t('components.notification_page.insight')
                break;
            default:
                message = ''
        }
        return message
    }

    function readNotification(this: any, notification: Notification, key: number) {
        let notificationUpdated: Notification = {
            id: notification.id,
            creationDate: notification.creationDate,
            type: notification.type,
            read: true,
            company: notification.company,
            consultant: notification.consultant,
            consultantUser: notification.consultantUser,
            companyUser: notification.companyUser,
        }
        let updateNotificationRequest: UpdateNotificationRequest = {
            id: notification.id!,
            notification: notificationUpdated
        }
        customNotificationResourceApi.updateNotificationCustom(updateNotificationRequest).then((res) => {

        })
        customNotificationResourceApi.getNotificationCount().then((res) => {
            res > 0 ? sharedContext.setNotificationCheck(true) : sharedContext.setNotificationCheck(false)
        })
        switch(notification.type) {
            case NotificationTypeEnum.LinkRequest:
                NavigationHelper.navigateToPartnerData(props.navigation, props.profile!);
                break;
            case NotificationTypeEnum.SelfAssessmentCompile:
                NavigationHelper.navigateToSurvey(props.navigation, sharedContext.selectedCompany);
                break;
            case NotificationTypeEnum.Insight:
                NavigationHelper.navigateToInsightHome(props.navigation);
                break;
            default:
        }
    }

    useEffect(() => {
        getNotification(0, pages);
    }, []);

    function morePages() {
        getNotification(0, pages+1);
        setPages(pages+1)
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={hykeeStyle.containerBack}
        >
        <ScrollView
            onContentSizeChange={() => morePages()}
        >
            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity
                    style={hykeeStyle.goBack}
                    onPress={() => NavigationHelper.navigateToGoBack(props.navigation)}>
                    <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t('components.notification_page.title')}</Text>
            </View>
        <>

            {notification.map((element: any, key: any) => {
                return (
                        <TouchableOpacity style={notification[key].read ? styles.unread : styles.read} onPress={() => readNotification(notification[key], key)}>
                            <View style={styles.noticeSector}>
                                <Text style={{color: "white"}}>{notificationContent(notification[key].type)}</Text>
                                <View style={{flexDirection: 'row', marginBottom: 5}}>
                                    <Text style={{color: "white"}}>{i18n.t('components.notification_page.by')} </Text>
                                    <Text style={{color: "white"}}>{notification[key].consultantUser.firstName} </Text>
                                    <Text style={{color: "white"}}>{notification[key].consultantUser.lastName}</Text>
                                    <Text style={{color: "white"}}> {i18n.t('components.notification_page.from')} </Text>
                                    <Text style={{color: "white"}}>{notification[key].consultant.name}</Text>
                                </View>
                                <Text style={styles.date}>{moment(notification[key].creationDate).format("DD/MM/yyyy")}</Text>
                            </View>
                        </TouchableOpacity>
                )
            })
            }
        </>
        </ScrollView>
        </LinearGradient>
    )
}

const styles = StyleSheet.create({
    containerScroll: {
        flex: 0.85,
        marginBottom: 40,
        marginHorizontal: 15,
        marginTop: 10
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginVertical: 10,
    },
    textButton: {
        color: "white",
        fontFamily: "poppins",
        fontSize: 13,
        textAlign: 'center',
        padding: 5,
        paddingVertical: 10,
    },
    borderGradient: {
        display: 'flex',
        fontFamily: 'poppins',
        marginBottom: 20,
        borderRadius: 13,
    },
    containerBack: {
        fontFamily: 'poppins',
        padding: 8,
        margin: 1,
        borderRadius: 13,
        overflow: 'hidden'
    },
    containerList: {
        marginBottom: 20,
    },
    textLabel: {
        marginTop: 10,
        marginHorizontal: 15,
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    noticeSector: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 8,
        margin: 10
    },
    unread: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white',
        padding: 5,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 6
    },
    read: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: BackgroundColorButtonGreen(),
        padding: 5,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 6
    },
    date: {
        color: 'white',
        fontSize: 12,
        marginTop: 10
    },
    button: {
        marginTop: 30,
        backgroundColor: BackgroundColorButtonGreen(),
        padding: 20,
        borderRadius: 15,
        fontFamily: 'poppins',
        marginBottom: 50,
        margin: 10

    },
});


