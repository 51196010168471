/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InformazioneSocio,
    InformazioneSocioFromJSON,
    InformazioneSocioFromJSONTyped,
    InformazioneSocioToJSON,
} from './';

/**
 * 
 * @export
 * @interface InformazioniSocio
 */
export interface InformazioniSocio {
    /**
     * 
     * @type {Array<InformazioneSocio>}
     * @memberof InformazioniSocio
     */
    informazioneSocio: Array<InformazioneSocio>;
}

export function InformazioniSocioFromJSON(json: any): InformazioniSocio {
    return InformazioniSocioFromJSONTyped(json, false);
}

export function InformazioniSocioFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformazioniSocio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'informazioneSocio': ((json['informazioneSocio'] as Array<any>).map(InformazioneSocioFromJSON)),
    };
}

export function InformazioniSocioToJSON(value?: InformazioniSocio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'informazioneSocio': ((value.informazioneSocio as Array<any>).map(InformazioneSocioToJSON)),
    };
}


