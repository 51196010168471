/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyToJSON,
} from '../models';

export interface CountCompaniesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    vatNumberContains?: string;
    vatNumberDoesNotContain?: string;
    vatNumberEquals?: string;
    vatNumberNotEquals?: string;
    vatNumberSpecified?: boolean;
    vatNumberIn?: Array<string>;
    vatNumberNotIn?: Array<string>;
    registeredBySearchEquals?: boolean;
    registeredBySearchNotEquals?: boolean;
    registeredBySearchSpecified?: boolean;
    registeredBySearchIn?: Array<boolean>;
    registeredBySearchNotIn?: Array<boolean>;
    sectorEquals?: CountCompaniesSectorEqualsEnum;
    sectorNotEquals?: CountCompaniesSectorNotEqualsEnum;
    sectorSpecified?: boolean;
    sectorIn?: Array<CountCompaniesSectorInEnum>;
    sectorNotIn?: Array<CountCompaniesSectorNotInEnum>;
    partnerProgramDescriptionTextContains?: string;
    partnerProgramDescriptionTextDoesNotContain?: string;
    partnerProgramDescriptionTextEquals?: string;
    partnerProgramDescriptionTextNotEquals?: string;
    partnerProgramDescriptionTextSpecified?: boolean;
    partnerProgramDescriptionTextIn?: Array<string>;
    partnerProgramDescriptionTextNotIn?: Array<string>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    companyBalanceSheetIdGreaterThan?: number;
    companyBalanceSheetIdLessThan?: number;
    companyBalanceSheetIdGreaterThanOrEqual?: number;
    companyBalanceSheetIdLessThanOrEqual?: number;
    companyBalanceSheetIdEquals?: number;
    companyBalanceSheetIdNotEquals?: number;
    companyBalanceSheetIdSpecified?: boolean;
    companyBalanceSheetIdIn?: Array<number>;
    companyBalanceSheetIdNotIn?: Array<number>;
    activityIdGreaterThan?: number;
    activityIdLessThan?: number;
    activityIdGreaterThanOrEqual?: number;
    activityIdLessThanOrEqual?: number;
    activityIdEquals?: number;
    activityIdNotEquals?: number;
    activityIdSpecified?: boolean;
    activityIdIn?: Array<number>;
    activityIdNotIn?: Array<number>;
    consultedIdGreaterThan?: number;
    consultedIdLessThan?: number;
    consultedIdGreaterThanOrEqual?: number;
    consultedIdLessThanOrEqual?: number;
    consultedIdEquals?: number;
    consultedIdNotEquals?: number;
    consultedIdSpecified?: boolean;
    consultedIdIn?: Array<number>;
    consultedIdNotIn?: Array<number>;
    userCompanyIdGreaterThan?: number;
    userCompanyIdLessThan?: number;
    userCompanyIdGreaterThanOrEqual?: number;
    userCompanyIdLessThanOrEqual?: number;
    userCompanyIdEquals?: number;
    userCompanyIdNotEquals?: number;
    userCompanyIdSpecified?: boolean;
    userCompanyIdIn?: Array<number>;
    userCompanyIdNotIn?: Array<number>;
    userConsultantIdGreaterThan?: number;
    userConsultantIdLessThan?: number;
    userConsultantIdGreaterThanOrEqual?: number;
    userConsultantIdLessThanOrEqual?: number;
    userConsultantIdEquals?: number;
    userConsultantIdNotEquals?: number;
    userConsultantIdSpecified?: boolean;
    userConsultantIdIn?: Array<number>;
    userConsultantIdNotIn?: Array<number>;
    companySavedIdGreaterThan?: number;
    companySavedIdLessThan?: number;
    companySavedIdGreaterThanOrEqual?: number;
    companySavedIdLessThanOrEqual?: number;
    companySavedIdEquals?: number;
    companySavedIdNotEquals?: number;
    companySavedIdSpecified?: boolean;
    companySavedIdIn?: Array<number>;
    companySavedIdNotIn?: Array<number>;
    companySearchedIdGreaterThan?: number;
    companySearchedIdLessThan?: number;
    companySearchedIdGreaterThanOrEqual?: number;
    companySearchedIdLessThanOrEqual?: number;
    companySearchedIdEquals?: number;
    companySearchedIdNotEquals?: number;
    companySearchedIdSpecified?: boolean;
    companySearchedIdIn?: Array<number>;
    companySearchedIdNotIn?: Array<number>;
    insightIdGreaterThan?: number;
    insightIdLessThan?: number;
    insightIdGreaterThanOrEqual?: number;
    insightIdLessThanOrEqual?: number;
    insightIdEquals?: number;
    insightIdNotEquals?: number;
    insightIdSpecified?: boolean;
    insightIdIn?: Array<number>;
    insightIdNotIn?: Array<number>;
    consultantIdGreaterThan?: number;
    consultantIdLessThan?: number;
    consultantIdGreaterThanOrEqual?: number;
    consultantIdLessThanOrEqual?: number;
    consultantIdEquals?: number;
    consultantIdNotEquals?: number;
    consultantIdSpecified?: boolean;
    consultantIdIn?: Array<number>;
    consultantIdNotIn?: Array<number>;
    postIdGreaterThan?: number;
    postIdLessThan?: number;
    postIdGreaterThanOrEqual?: number;
    postIdLessThanOrEqual?: number;
    postIdEquals?: number;
    postIdNotEquals?: number;
    postIdSpecified?: boolean;
    postIdIn?: Array<number>;
    postIdNotIn?: Array<number>;
    receivedInsightIdGreaterThan?: number;
    receivedInsightIdLessThan?: number;
    receivedInsightIdGreaterThanOrEqual?: number;
    receivedInsightIdLessThanOrEqual?: number;
    receivedInsightIdEquals?: number;
    receivedInsightIdNotEquals?: number;
    receivedInsightIdSpecified?: boolean;
    receivedInsightIdIn?: Array<number>;
    receivedInsightIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateCompanyRequest {
    company: Company;
}

export interface DeleteCompanyRequest {
    id: number;
}

export interface GetAllCompaniesRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    nameContains?: string;
    nameDoesNotContain?: string;
    nameEquals?: string;
    nameNotEquals?: string;
    nameSpecified?: boolean;
    nameIn?: Array<string>;
    nameNotIn?: Array<string>;
    vatNumberContains?: string;
    vatNumberDoesNotContain?: string;
    vatNumberEquals?: string;
    vatNumberNotEquals?: string;
    vatNumberSpecified?: boolean;
    vatNumberIn?: Array<string>;
    vatNumberNotIn?: Array<string>;
    registeredBySearchEquals?: boolean;
    registeredBySearchNotEquals?: boolean;
    registeredBySearchSpecified?: boolean;
    registeredBySearchIn?: Array<boolean>;
    registeredBySearchNotIn?: Array<boolean>;
    sectorEquals?: GetAllCompaniesSectorEqualsEnum;
    sectorNotEquals?: GetAllCompaniesSectorNotEqualsEnum;
    sectorSpecified?: boolean;
    sectorIn?: Array<GetAllCompaniesSectorInEnum>;
    sectorNotIn?: Array<GetAllCompaniesSectorNotInEnum>;
    partnerProgramDescriptionTextContains?: string;
    partnerProgramDescriptionTextDoesNotContain?: string;
    partnerProgramDescriptionTextEquals?: string;
    partnerProgramDescriptionTextNotEquals?: string;
    partnerProgramDescriptionTextSpecified?: boolean;
    partnerProgramDescriptionTextIn?: Array<string>;
    partnerProgramDescriptionTextNotIn?: Array<string>;
    companyDataIdGreaterThan?: number;
    companyDataIdLessThan?: number;
    companyDataIdGreaterThanOrEqual?: number;
    companyDataIdLessThanOrEqual?: number;
    companyDataIdEquals?: number;
    companyDataIdNotEquals?: number;
    companyDataIdSpecified?: boolean;
    companyDataIdIn?: Array<number>;
    companyDataIdNotIn?: Array<number>;
    companySurveyIdGreaterThan?: number;
    companySurveyIdLessThan?: number;
    companySurveyIdGreaterThanOrEqual?: number;
    companySurveyIdLessThanOrEqual?: number;
    companySurveyIdEquals?: number;
    companySurveyIdNotEquals?: number;
    companySurveyIdSpecified?: boolean;
    companySurveyIdIn?: Array<number>;
    companySurveyIdNotIn?: Array<number>;
    companyBalanceSheetIdGreaterThan?: number;
    companyBalanceSheetIdLessThan?: number;
    companyBalanceSheetIdGreaterThanOrEqual?: number;
    companyBalanceSheetIdLessThanOrEqual?: number;
    companyBalanceSheetIdEquals?: number;
    companyBalanceSheetIdNotEquals?: number;
    companyBalanceSheetIdSpecified?: boolean;
    companyBalanceSheetIdIn?: Array<number>;
    companyBalanceSheetIdNotIn?: Array<number>;
    activityIdGreaterThan?: number;
    activityIdLessThan?: number;
    activityIdGreaterThanOrEqual?: number;
    activityIdLessThanOrEqual?: number;
    activityIdEquals?: number;
    activityIdNotEquals?: number;
    activityIdSpecified?: boolean;
    activityIdIn?: Array<number>;
    activityIdNotIn?: Array<number>;
    consultedIdGreaterThan?: number;
    consultedIdLessThan?: number;
    consultedIdGreaterThanOrEqual?: number;
    consultedIdLessThanOrEqual?: number;
    consultedIdEquals?: number;
    consultedIdNotEquals?: number;
    consultedIdSpecified?: boolean;
    consultedIdIn?: Array<number>;
    consultedIdNotIn?: Array<number>;
    userCompanyIdGreaterThan?: number;
    userCompanyIdLessThan?: number;
    userCompanyIdGreaterThanOrEqual?: number;
    userCompanyIdLessThanOrEqual?: number;
    userCompanyIdEquals?: number;
    userCompanyIdNotEquals?: number;
    userCompanyIdSpecified?: boolean;
    userCompanyIdIn?: Array<number>;
    userCompanyIdNotIn?: Array<number>;
    userConsultantIdGreaterThan?: number;
    userConsultantIdLessThan?: number;
    userConsultantIdGreaterThanOrEqual?: number;
    userConsultantIdLessThanOrEqual?: number;
    userConsultantIdEquals?: number;
    userConsultantIdNotEquals?: number;
    userConsultantIdSpecified?: boolean;
    userConsultantIdIn?: Array<number>;
    userConsultantIdNotIn?: Array<number>;
    companySavedIdGreaterThan?: number;
    companySavedIdLessThan?: number;
    companySavedIdGreaterThanOrEqual?: number;
    companySavedIdLessThanOrEqual?: number;
    companySavedIdEquals?: number;
    companySavedIdNotEquals?: number;
    companySavedIdSpecified?: boolean;
    companySavedIdIn?: Array<number>;
    companySavedIdNotIn?: Array<number>;
    companySearchedIdGreaterThan?: number;
    companySearchedIdLessThan?: number;
    companySearchedIdGreaterThanOrEqual?: number;
    companySearchedIdLessThanOrEqual?: number;
    companySearchedIdEquals?: number;
    companySearchedIdNotEquals?: number;
    companySearchedIdSpecified?: boolean;
    companySearchedIdIn?: Array<number>;
    companySearchedIdNotIn?: Array<number>;
    insightIdGreaterThan?: number;
    insightIdLessThan?: number;
    insightIdGreaterThanOrEqual?: number;
    insightIdLessThanOrEqual?: number;
    insightIdEquals?: number;
    insightIdNotEquals?: number;
    insightIdSpecified?: boolean;
    insightIdIn?: Array<number>;
    insightIdNotIn?: Array<number>;
    consultantIdGreaterThan?: number;
    consultantIdLessThan?: number;
    consultantIdGreaterThanOrEqual?: number;
    consultantIdLessThanOrEqual?: number;
    consultantIdEquals?: number;
    consultantIdNotEquals?: number;
    consultantIdSpecified?: boolean;
    consultantIdIn?: Array<number>;
    consultantIdNotIn?: Array<number>;
    postIdGreaterThan?: number;
    postIdLessThan?: number;
    postIdGreaterThanOrEqual?: number;
    postIdLessThanOrEqual?: number;
    postIdEquals?: number;
    postIdNotEquals?: number;
    postIdSpecified?: boolean;
    postIdIn?: Array<number>;
    postIdNotIn?: Array<number>;
    receivedInsightIdGreaterThan?: number;
    receivedInsightIdLessThan?: number;
    receivedInsightIdGreaterThanOrEqual?: number;
    receivedInsightIdLessThanOrEqual?: number;
    receivedInsightIdEquals?: number;
    receivedInsightIdNotEquals?: number;
    receivedInsightIdSpecified?: boolean;
    receivedInsightIdIn?: Array<number>;
    receivedInsightIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetCompanyRequest {
    id: number;
}

export interface PartialUpdateCompanyRequest {
    id: number;
    company: Company;
}

export interface UpdateCompanyRequest {
    id: number;
    company: Company;
}

/**
 * 
 */
export class CompanyResourceApi extends runtime.BaseAPI {

    /**
     */
    async countCompaniesRaw(requestParameters: CountCompaniesRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.vatNumberContains !== undefined) {
            queryParameters['vatNumber.contains'] = requestParameters.vatNumberContains;
        }

        if (requestParameters.vatNumberDoesNotContain !== undefined) {
            queryParameters['vatNumber.doesNotContain'] = requestParameters.vatNumberDoesNotContain;
        }

        if (requestParameters.vatNumberEquals !== undefined) {
            queryParameters['vatNumber.equals'] = requestParameters.vatNumberEquals;
        }

        if (requestParameters.vatNumberNotEquals !== undefined) {
            queryParameters['vatNumber.notEquals'] = requestParameters.vatNumberNotEquals;
        }

        if (requestParameters.vatNumberSpecified !== undefined) {
            queryParameters['vatNumber.specified'] = requestParameters.vatNumberSpecified;
        }

        if (requestParameters.vatNumberIn) {
            queryParameters['vatNumber.in'] = requestParameters.vatNumberIn;
        }

        if (requestParameters.vatNumberNotIn) {
            queryParameters['vatNumber.notIn'] = requestParameters.vatNumberNotIn;
        }

        if (requestParameters.registeredBySearchEquals !== undefined) {
            queryParameters['registeredBySearch.equals'] = requestParameters.registeredBySearchEquals;
        }

        if (requestParameters.registeredBySearchNotEquals !== undefined) {
            queryParameters['registeredBySearch.notEquals'] = requestParameters.registeredBySearchNotEquals;
        }

        if (requestParameters.registeredBySearchSpecified !== undefined) {
            queryParameters['registeredBySearch.specified'] = requestParameters.registeredBySearchSpecified;
        }

        if (requestParameters.registeredBySearchIn) {
            queryParameters['registeredBySearch.in'] = requestParameters.registeredBySearchIn;
        }

        if (requestParameters.registeredBySearchNotIn) {
            queryParameters['registeredBySearch.notIn'] = requestParameters.registeredBySearchNotIn;
        }

        if (requestParameters.sectorEquals !== undefined) {
            queryParameters['sector.equals'] = requestParameters.sectorEquals;
        }

        if (requestParameters.sectorNotEquals !== undefined) {
            queryParameters['sector.notEquals'] = requestParameters.sectorNotEquals;
        }

        if (requestParameters.sectorSpecified !== undefined) {
            queryParameters['sector.specified'] = requestParameters.sectorSpecified;
        }

        if (requestParameters.sectorIn) {
            queryParameters['sector.in'] = requestParameters.sectorIn;
        }

        if (requestParameters.sectorNotIn) {
            queryParameters['sector.notIn'] = requestParameters.sectorNotIn;
        }

        if (requestParameters.partnerProgramDescriptionTextContains !== undefined) {
            queryParameters['partnerProgramDescriptionText.contains'] = requestParameters.partnerProgramDescriptionTextContains;
        }

        if (requestParameters.partnerProgramDescriptionTextDoesNotContain !== undefined) {
            queryParameters['partnerProgramDescriptionText.doesNotContain'] = requestParameters.partnerProgramDescriptionTextDoesNotContain;
        }

        if (requestParameters.partnerProgramDescriptionTextEquals !== undefined) {
            queryParameters['partnerProgramDescriptionText.equals'] = requestParameters.partnerProgramDescriptionTextEquals;
        }

        if (requestParameters.partnerProgramDescriptionTextNotEquals !== undefined) {
            queryParameters['partnerProgramDescriptionText.notEquals'] = requestParameters.partnerProgramDescriptionTextNotEquals;
        }

        if (requestParameters.partnerProgramDescriptionTextSpecified !== undefined) {
            queryParameters['partnerProgramDescriptionText.specified'] = requestParameters.partnerProgramDescriptionTextSpecified;
        }

        if (requestParameters.partnerProgramDescriptionTextIn) {
            queryParameters['partnerProgramDescriptionText.in'] = requestParameters.partnerProgramDescriptionTextIn;
        }

        if (requestParameters.partnerProgramDescriptionTextNotIn) {
            queryParameters['partnerProgramDescriptionText.notIn'] = requestParameters.partnerProgramDescriptionTextNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.companyBalanceSheetIdGreaterThan !== undefined) {
            queryParameters['companyBalanceSheetId.greaterThan'] = requestParameters.companyBalanceSheetIdGreaterThan;
        }

        if (requestParameters.companyBalanceSheetIdLessThan !== undefined) {
            queryParameters['companyBalanceSheetId.lessThan'] = requestParameters.companyBalanceSheetIdLessThan;
        }

        if (requestParameters.companyBalanceSheetIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyBalanceSheetId.greaterThanOrEqual'] = requestParameters.companyBalanceSheetIdGreaterThanOrEqual;
        }

        if (requestParameters.companyBalanceSheetIdLessThanOrEqual !== undefined) {
            queryParameters['companyBalanceSheetId.lessThanOrEqual'] = requestParameters.companyBalanceSheetIdLessThanOrEqual;
        }

        if (requestParameters.companyBalanceSheetIdEquals !== undefined) {
            queryParameters['companyBalanceSheetId.equals'] = requestParameters.companyBalanceSheetIdEquals;
        }

        if (requestParameters.companyBalanceSheetIdNotEquals !== undefined) {
            queryParameters['companyBalanceSheetId.notEquals'] = requestParameters.companyBalanceSheetIdNotEquals;
        }

        if (requestParameters.companyBalanceSheetIdSpecified !== undefined) {
            queryParameters['companyBalanceSheetId.specified'] = requestParameters.companyBalanceSheetIdSpecified;
        }

        if (requestParameters.companyBalanceSheetIdIn) {
            queryParameters['companyBalanceSheetId.in'] = requestParameters.companyBalanceSheetIdIn;
        }

        if (requestParameters.companyBalanceSheetIdNotIn) {
            queryParameters['companyBalanceSheetId.notIn'] = requestParameters.companyBalanceSheetIdNotIn;
        }

        if (requestParameters.activityIdGreaterThan !== undefined) {
            queryParameters['activityId.greaterThan'] = requestParameters.activityIdGreaterThan;
        }

        if (requestParameters.activityIdLessThan !== undefined) {
            queryParameters['activityId.lessThan'] = requestParameters.activityIdLessThan;
        }

        if (requestParameters.activityIdGreaterThanOrEqual !== undefined) {
            queryParameters['activityId.greaterThanOrEqual'] = requestParameters.activityIdGreaterThanOrEqual;
        }

        if (requestParameters.activityIdLessThanOrEqual !== undefined) {
            queryParameters['activityId.lessThanOrEqual'] = requestParameters.activityIdLessThanOrEqual;
        }

        if (requestParameters.activityIdEquals !== undefined) {
            queryParameters['activityId.equals'] = requestParameters.activityIdEquals;
        }

        if (requestParameters.activityIdNotEquals !== undefined) {
            queryParameters['activityId.notEquals'] = requestParameters.activityIdNotEquals;
        }

        if (requestParameters.activityIdSpecified !== undefined) {
            queryParameters['activityId.specified'] = requestParameters.activityIdSpecified;
        }

        if (requestParameters.activityIdIn) {
            queryParameters['activityId.in'] = requestParameters.activityIdIn;
        }

        if (requestParameters.activityIdNotIn) {
            queryParameters['activityId.notIn'] = requestParameters.activityIdNotIn;
        }

        if (requestParameters.consultedIdGreaterThan !== undefined) {
            queryParameters['consultedId.greaterThan'] = requestParameters.consultedIdGreaterThan;
        }

        if (requestParameters.consultedIdLessThan !== undefined) {
            queryParameters['consultedId.lessThan'] = requestParameters.consultedIdLessThan;
        }

        if (requestParameters.consultedIdGreaterThanOrEqual !== undefined) {
            queryParameters['consultedId.greaterThanOrEqual'] = requestParameters.consultedIdGreaterThanOrEqual;
        }

        if (requestParameters.consultedIdLessThanOrEqual !== undefined) {
            queryParameters['consultedId.lessThanOrEqual'] = requestParameters.consultedIdLessThanOrEqual;
        }

        if (requestParameters.consultedIdEquals !== undefined) {
            queryParameters['consultedId.equals'] = requestParameters.consultedIdEquals;
        }

        if (requestParameters.consultedIdNotEquals !== undefined) {
            queryParameters['consultedId.notEquals'] = requestParameters.consultedIdNotEquals;
        }

        if (requestParameters.consultedIdSpecified !== undefined) {
            queryParameters['consultedId.specified'] = requestParameters.consultedIdSpecified;
        }

        if (requestParameters.consultedIdIn) {
            queryParameters['consultedId.in'] = requestParameters.consultedIdIn;
        }

        if (requestParameters.consultedIdNotIn) {
            queryParameters['consultedId.notIn'] = requestParameters.consultedIdNotIn;
        }

        if (requestParameters.userCompanyIdGreaterThan !== undefined) {
            queryParameters['userCompanyId.greaterThan'] = requestParameters.userCompanyIdGreaterThan;
        }

        if (requestParameters.userCompanyIdLessThan !== undefined) {
            queryParameters['userCompanyId.lessThan'] = requestParameters.userCompanyIdLessThan;
        }

        if (requestParameters.userCompanyIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyId.greaterThanOrEqual'] = requestParameters.userCompanyIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyId.lessThanOrEqual'] = requestParameters.userCompanyIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyIdEquals !== undefined) {
            queryParameters['userCompanyId.equals'] = requestParameters.userCompanyIdEquals;
        }

        if (requestParameters.userCompanyIdNotEquals !== undefined) {
            queryParameters['userCompanyId.notEquals'] = requestParameters.userCompanyIdNotEquals;
        }

        if (requestParameters.userCompanyIdSpecified !== undefined) {
            queryParameters['userCompanyId.specified'] = requestParameters.userCompanyIdSpecified;
        }

        if (requestParameters.userCompanyIdIn) {
            queryParameters['userCompanyId.in'] = requestParameters.userCompanyIdIn;
        }

        if (requestParameters.userCompanyIdNotIn) {
            queryParameters['userCompanyId.notIn'] = requestParameters.userCompanyIdNotIn;
        }

        if (requestParameters.userConsultantIdGreaterThan !== undefined) {
            queryParameters['userConsultantId.greaterThan'] = requestParameters.userConsultantIdGreaterThan;
        }

        if (requestParameters.userConsultantIdLessThan !== undefined) {
            queryParameters['userConsultantId.lessThan'] = requestParameters.userConsultantIdLessThan;
        }

        if (requestParameters.userConsultantIdGreaterThanOrEqual !== undefined) {
            queryParameters['userConsultantId.greaterThanOrEqual'] = requestParameters.userConsultantIdGreaterThanOrEqual;
        }

        if (requestParameters.userConsultantIdLessThanOrEqual !== undefined) {
            queryParameters['userConsultantId.lessThanOrEqual'] = requestParameters.userConsultantIdLessThanOrEqual;
        }

        if (requestParameters.userConsultantIdEquals !== undefined) {
            queryParameters['userConsultantId.equals'] = requestParameters.userConsultantIdEquals;
        }

        if (requestParameters.userConsultantIdNotEquals !== undefined) {
            queryParameters['userConsultantId.notEquals'] = requestParameters.userConsultantIdNotEquals;
        }

        if (requestParameters.userConsultantIdSpecified !== undefined) {
            queryParameters['userConsultantId.specified'] = requestParameters.userConsultantIdSpecified;
        }

        if (requestParameters.userConsultantIdIn) {
            queryParameters['userConsultantId.in'] = requestParameters.userConsultantIdIn;
        }

        if (requestParameters.userConsultantIdNotIn) {
            queryParameters['userConsultantId.notIn'] = requestParameters.userConsultantIdNotIn;
        }

        if (requestParameters.companySavedIdGreaterThan !== undefined) {
            queryParameters['companySavedId.greaterThan'] = requestParameters.companySavedIdGreaterThan;
        }

        if (requestParameters.companySavedIdLessThan !== undefined) {
            queryParameters['companySavedId.lessThan'] = requestParameters.companySavedIdLessThan;
        }

        if (requestParameters.companySavedIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySavedId.greaterThanOrEqual'] = requestParameters.companySavedIdGreaterThanOrEqual;
        }

        if (requestParameters.companySavedIdLessThanOrEqual !== undefined) {
            queryParameters['companySavedId.lessThanOrEqual'] = requestParameters.companySavedIdLessThanOrEqual;
        }

        if (requestParameters.companySavedIdEquals !== undefined) {
            queryParameters['companySavedId.equals'] = requestParameters.companySavedIdEquals;
        }

        if (requestParameters.companySavedIdNotEquals !== undefined) {
            queryParameters['companySavedId.notEquals'] = requestParameters.companySavedIdNotEquals;
        }

        if (requestParameters.companySavedIdSpecified !== undefined) {
            queryParameters['companySavedId.specified'] = requestParameters.companySavedIdSpecified;
        }

        if (requestParameters.companySavedIdIn) {
            queryParameters['companySavedId.in'] = requestParameters.companySavedIdIn;
        }

        if (requestParameters.companySavedIdNotIn) {
            queryParameters['companySavedId.notIn'] = requestParameters.companySavedIdNotIn;
        }

        if (requestParameters.companySearchedIdGreaterThan !== undefined) {
            queryParameters['companySearchedId.greaterThan'] = requestParameters.companySearchedIdGreaterThan;
        }

        if (requestParameters.companySearchedIdLessThan !== undefined) {
            queryParameters['companySearchedId.lessThan'] = requestParameters.companySearchedIdLessThan;
        }

        if (requestParameters.companySearchedIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySearchedId.greaterThanOrEqual'] = requestParameters.companySearchedIdGreaterThanOrEqual;
        }

        if (requestParameters.companySearchedIdLessThanOrEqual !== undefined) {
            queryParameters['companySearchedId.lessThanOrEqual'] = requestParameters.companySearchedIdLessThanOrEqual;
        }

        if (requestParameters.companySearchedIdEquals !== undefined) {
            queryParameters['companySearchedId.equals'] = requestParameters.companySearchedIdEquals;
        }

        if (requestParameters.companySearchedIdNotEquals !== undefined) {
            queryParameters['companySearchedId.notEquals'] = requestParameters.companySearchedIdNotEquals;
        }

        if (requestParameters.companySearchedIdSpecified !== undefined) {
            queryParameters['companySearchedId.specified'] = requestParameters.companySearchedIdSpecified;
        }

        if (requestParameters.companySearchedIdIn) {
            queryParameters['companySearchedId.in'] = requestParameters.companySearchedIdIn;
        }

        if (requestParameters.companySearchedIdNotIn) {
            queryParameters['companySearchedId.notIn'] = requestParameters.companySearchedIdNotIn;
        }

        if (requestParameters.insightIdGreaterThan !== undefined) {
            queryParameters['insightId.greaterThan'] = requestParameters.insightIdGreaterThan;
        }

        if (requestParameters.insightIdLessThan !== undefined) {
            queryParameters['insightId.lessThan'] = requestParameters.insightIdLessThan;
        }

        if (requestParameters.insightIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightId.greaterThanOrEqual'] = requestParameters.insightIdGreaterThanOrEqual;
        }

        if (requestParameters.insightIdLessThanOrEqual !== undefined) {
            queryParameters['insightId.lessThanOrEqual'] = requestParameters.insightIdLessThanOrEqual;
        }

        if (requestParameters.insightIdEquals !== undefined) {
            queryParameters['insightId.equals'] = requestParameters.insightIdEquals;
        }

        if (requestParameters.insightIdNotEquals !== undefined) {
            queryParameters['insightId.notEquals'] = requestParameters.insightIdNotEquals;
        }

        if (requestParameters.insightIdSpecified !== undefined) {
            queryParameters['insightId.specified'] = requestParameters.insightIdSpecified;
        }

        if (requestParameters.insightIdIn) {
            queryParameters['insightId.in'] = requestParameters.insightIdIn;
        }

        if (requestParameters.insightIdNotIn) {
            queryParameters['insightId.notIn'] = requestParameters.insightIdNotIn;
        }

        if (requestParameters.consultantIdGreaterThan !== undefined) {
            queryParameters['consultantId.greaterThan'] = requestParameters.consultantIdGreaterThan;
        }

        if (requestParameters.consultantIdLessThan !== undefined) {
            queryParameters['consultantId.lessThan'] = requestParameters.consultantIdLessThan;
        }

        if (requestParameters.consultantIdGreaterThanOrEqual !== undefined) {
            queryParameters['consultantId.greaterThanOrEqual'] = requestParameters.consultantIdGreaterThanOrEqual;
        }

        if (requestParameters.consultantIdLessThanOrEqual !== undefined) {
            queryParameters['consultantId.lessThanOrEqual'] = requestParameters.consultantIdLessThanOrEqual;
        }

        if (requestParameters.consultantIdEquals !== undefined) {
            queryParameters['consultantId.equals'] = requestParameters.consultantIdEquals;
        }

        if (requestParameters.consultantIdNotEquals !== undefined) {
            queryParameters['consultantId.notEquals'] = requestParameters.consultantIdNotEquals;
        }

        if (requestParameters.consultantIdSpecified !== undefined) {
            queryParameters['consultantId.specified'] = requestParameters.consultantIdSpecified;
        }

        if (requestParameters.consultantIdIn) {
            queryParameters['consultantId.in'] = requestParameters.consultantIdIn;
        }

        if (requestParameters.consultantIdNotIn) {
            queryParameters['consultantId.notIn'] = requestParameters.consultantIdNotIn;
        }

        if (requestParameters.postIdGreaterThan !== undefined) {
            queryParameters['postId.greaterThan'] = requestParameters.postIdGreaterThan;
        }

        if (requestParameters.postIdLessThan !== undefined) {
            queryParameters['postId.lessThan'] = requestParameters.postIdLessThan;
        }

        if (requestParameters.postIdGreaterThanOrEqual !== undefined) {
            queryParameters['postId.greaterThanOrEqual'] = requestParameters.postIdGreaterThanOrEqual;
        }

        if (requestParameters.postIdLessThanOrEqual !== undefined) {
            queryParameters['postId.lessThanOrEqual'] = requestParameters.postIdLessThanOrEqual;
        }

        if (requestParameters.postIdEquals !== undefined) {
            queryParameters['postId.equals'] = requestParameters.postIdEquals;
        }

        if (requestParameters.postIdNotEquals !== undefined) {
            queryParameters['postId.notEquals'] = requestParameters.postIdNotEquals;
        }

        if (requestParameters.postIdSpecified !== undefined) {
            queryParameters['postId.specified'] = requestParameters.postIdSpecified;
        }

        if (requestParameters.postIdIn) {
            queryParameters['postId.in'] = requestParameters.postIdIn;
        }

        if (requestParameters.postIdNotIn) {
            queryParameters['postId.notIn'] = requestParameters.postIdNotIn;
        }

        if (requestParameters.receivedInsightIdGreaterThan !== undefined) {
            queryParameters['receivedInsightId.greaterThan'] = requestParameters.receivedInsightIdGreaterThan;
        }

        if (requestParameters.receivedInsightIdLessThan !== undefined) {
            queryParameters['receivedInsightId.lessThan'] = requestParameters.receivedInsightIdLessThan;
        }

        if (requestParameters.receivedInsightIdGreaterThanOrEqual !== undefined) {
            queryParameters['receivedInsightId.greaterThanOrEqual'] = requestParameters.receivedInsightIdGreaterThanOrEqual;
        }

        if (requestParameters.receivedInsightIdLessThanOrEqual !== undefined) {
            queryParameters['receivedInsightId.lessThanOrEqual'] = requestParameters.receivedInsightIdLessThanOrEqual;
        }

        if (requestParameters.receivedInsightIdEquals !== undefined) {
            queryParameters['receivedInsightId.equals'] = requestParameters.receivedInsightIdEquals;
        }

        if (requestParameters.receivedInsightIdNotEquals !== undefined) {
            queryParameters['receivedInsightId.notEquals'] = requestParameters.receivedInsightIdNotEquals;
        }

        if (requestParameters.receivedInsightIdSpecified !== undefined) {
            queryParameters['receivedInsightId.specified'] = requestParameters.receivedInsightIdSpecified;
        }

        if (requestParameters.receivedInsightIdIn) {
            queryParameters['receivedInsightId.in'] = requestParameters.receivedInsightIdIn;
        }

        if (requestParameters.receivedInsightIdNotIn) {
            queryParameters['receivedInsightId.notIn'] = requestParameters.receivedInsightIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countCompanies(requestParameters: CountCompaniesRequest): Promise<number> {
        const response = await this.countCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async createCompany(requestParameters: CreateCompanyRequest): Promise<Company> {
        const response = await this.createCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest): Promise<void> {
        await this.deleteCompanyRaw(requestParameters);
    }

    /**
     */
    async getAllCompaniesRaw(requestParameters: GetAllCompaniesRequest): Promise<runtime.ApiResponse<Array<Company>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name.contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameDoesNotContain !== undefined) {
            queryParameters['name.doesNotContain'] = requestParameters.nameDoesNotContain;
        }

        if (requestParameters.nameEquals !== undefined) {
            queryParameters['name.equals'] = requestParameters.nameEquals;
        }

        if (requestParameters.nameNotEquals !== undefined) {
            queryParameters['name.notEquals'] = requestParameters.nameNotEquals;
        }

        if (requestParameters.nameSpecified !== undefined) {
            queryParameters['name.specified'] = requestParameters.nameSpecified;
        }

        if (requestParameters.nameIn) {
            queryParameters['name.in'] = requestParameters.nameIn;
        }

        if (requestParameters.nameNotIn) {
            queryParameters['name.notIn'] = requestParameters.nameNotIn;
        }

        if (requestParameters.vatNumberContains !== undefined) {
            queryParameters['vatNumber.contains'] = requestParameters.vatNumberContains;
        }

        if (requestParameters.vatNumberDoesNotContain !== undefined) {
            queryParameters['vatNumber.doesNotContain'] = requestParameters.vatNumberDoesNotContain;
        }

        if (requestParameters.vatNumberEquals !== undefined) {
            queryParameters['vatNumber.equals'] = requestParameters.vatNumberEquals;
        }

        if (requestParameters.vatNumberNotEquals !== undefined) {
            queryParameters['vatNumber.notEquals'] = requestParameters.vatNumberNotEquals;
        }

        if (requestParameters.vatNumberSpecified !== undefined) {
            queryParameters['vatNumber.specified'] = requestParameters.vatNumberSpecified;
        }

        if (requestParameters.vatNumberIn) {
            queryParameters['vatNumber.in'] = requestParameters.vatNumberIn;
        }

        if (requestParameters.vatNumberNotIn) {
            queryParameters['vatNumber.notIn'] = requestParameters.vatNumberNotIn;
        }

        if (requestParameters.registeredBySearchEquals !== undefined) {
            queryParameters['registeredBySearch.equals'] = requestParameters.registeredBySearchEquals;
        }

        if (requestParameters.registeredBySearchNotEquals !== undefined) {
            queryParameters['registeredBySearch.notEquals'] = requestParameters.registeredBySearchNotEquals;
        }

        if (requestParameters.registeredBySearchSpecified !== undefined) {
            queryParameters['registeredBySearch.specified'] = requestParameters.registeredBySearchSpecified;
        }

        if (requestParameters.registeredBySearchIn) {
            queryParameters['registeredBySearch.in'] = requestParameters.registeredBySearchIn;
        }

        if (requestParameters.registeredBySearchNotIn) {
            queryParameters['registeredBySearch.notIn'] = requestParameters.registeredBySearchNotIn;
        }

        if (requestParameters.sectorEquals !== undefined) {
            queryParameters['sector.equals'] = requestParameters.sectorEquals;
        }

        if (requestParameters.sectorNotEquals !== undefined) {
            queryParameters['sector.notEquals'] = requestParameters.sectorNotEquals;
        }

        if (requestParameters.sectorSpecified !== undefined) {
            queryParameters['sector.specified'] = requestParameters.sectorSpecified;
        }

        if (requestParameters.sectorIn) {
            queryParameters['sector.in'] = requestParameters.sectorIn;
        }

        if (requestParameters.sectorNotIn) {
            queryParameters['sector.notIn'] = requestParameters.sectorNotIn;
        }

        if (requestParameters.partnerProgramDescriptionTextContains !== undefined) {
            queryParameters['partnerProgramDescriptionText.contains'] = requestParameters.partnerProgramDescriptionTextContains;
        }

        if (requestParameters.partnerProgramDescriptionTextDoesNotContain !== undefined) {
            queryParameters['partnerProgramDescriptionText.doesNotContain'] = requestParameters.partnerProgramDescriptionTextDoesNotContain;
        }

        if (requestParameters.partnerProgramDescriptionTextEquals !== undefined) {
            queryParameters['partnerProgramDescriptionText.equals'] = requestParameters.partnerProgramDescriptionTextEquals;
        }

        if (requestParameters.partnerProgramDescriptionTextNotEquals !== undefined) {
            queryParameters['partnerProgramDescriptionText.notEquals'] = requestParameters.partnerProgramDescriptionTextNotEquals;
        }

        if (requestParameters.partnerProgramDescriptionTextSpecified !== undefined) {
            queryParameters['partnerProgramDescriptionText.specified'] = requestParameters.partnerProgramDescriptionTextSpecified;
        }

        if (requestParameters.partnerProgramDescriptionTextIn) {
            queryParameters['partnerProgramDescriptionText.in'] = requestParameters.partnerProgramDescriptionTextIn;
        }

        if (requestParameters.partnerProgramDescriptionTextNotIn) {
            queryParameters['partnerProgramDescriptionText.notIn'] = requestParameters.partnerProgramDescriptionTextNotIn;
        }

        if (requestParameters.companyDataIdGreaterThan !== undefined) {
            queryParameters['companyDataId.greaterThan'] = requestParameters.companyDataIdGreaterThan;
        }

        if (requestParameters.companyDataIdLessThan !== undefined) {
            queryParameters['companyDataId.lessThan'] = requestParameters.companyDataIdLessThan;
        }

        if (requestParameters.companyDataIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyDataId.greaterThanOrEqual'] = requestParameters.companyDataIdGreaterThanOrEqual;
        }

        if (requestParameters.companyDataIdLessThanOrEqual !== undefined) {
            queryParameters['companyDataId.lessThanOrEqual'] = requestParameters.companyDataIdLessThanOrEqual;
        }

        if (requestParameters.companyDataIdEquals !== undefined) {
            queryParameters['companyDataId.equals'] = requestParameters.companyDataIdEquals;
        }

        if (requestParameters.companyDataIdNotEquals !== undefined) {
            queryParameters['companyDataId.notEquals'] = requestParameters.companyDataIdNotEquals;
        }

        if (requestParameters.companyDataIdSpecified !== undefined) {
            queryParameters['companyDataId.specified'] = requestParameters.companyDataIdSpecified;
        }

        if (requestParameters.companyDataIdIn) {
            queryParameters['companyDataId.in'] = requestParameters.companyDataIdIn;
        }

        if (requestParameters.companyDataIdNotIn) {
            queryParameters['companyDataId.notIn'] = requestParameters.companyDataIdNotIn;
        }

        if (requestParameters.companySurveyIdGreaterThan !== undefined) {
            queryParameters['companySurveyId.greaterThan'] = requestParameters.companySurveyIdGreaterThan;
        }

        if (requestParameters.companySurveyIdLessThan !== undefined) {
            queryParameters['companySurveyId.lessThan'] = requestParameters.companySurveyIdLessThan;
        }

        if (requestParameters.companySurveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyId.greaterThanOrEqual'] = requestParameters.companySurveyIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyId.lessThanOrEqual'] = requestParameters.companySurveyIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyIdEquals !== undefined) {
            queryParameters['companySurveyId.equals'] = requestParameters.companySurveyIdEquals;
        }

        if (requestParameters.companySurveyIdNotEquals !== undefined) {
            queryParameters['companySurveyId.notEquals'] = requestParameters.companySurveyIdNotEquals;
        }

        if (requestParameters.companySurveyIdSpecified !== undefined) {
            queryParameters['companySurveyId.specified'] = requestParameters.companySurveyIdSpecified;
        }

        if (requestParameters.companySurveyIdIn) {
            queryParameters['companySurveyId.in'] = requestParameters.companySurveyIdIn;
        }

        if (requestParameters.companySurveyIdNotIn) {
            queryParameters['companySurveyId.notIn'] = requestParameters.companySurveyIdNotIn;
        }

        if (requestParameters.companyBalanceSheetIdGreaterThan !== undefined) {
            queryParameters['companyBalanceSheetId.greaterThan'] = requestParameters.companyBalanceSheetIdGreaterThan;
        }

        if (requestParameters.companyBalanceSheetIdLessThan !== undefined) {
            queryParameters['companyBalanceSheetId.lessThan'] = requestParameters.companyBalanceSheetIdLessThan;
        }

        if (requestParameters.companyBalanceSheetIdGreaterThanOrEqual !== undefined) {
            queryParameters['companyBalanceSheetId.greaterThanOrEqual'] = requestParameters.companyBalanceSheetIdGreaterThanOrEqual;
        }

        if (requestParameters.companyBalanceSheetIdLessThanOrEqual !== undefined) {
            queryParameters['companyBalanceSheetId.lessThanOrEqual'] = requestParameters.companyBalanceSheetIdLessThanOrEqual;
        }

        if (requestParameters.companyBalanceSheetIdEquals !== undefined) {
            queryParameters['companyBalanceSheetId.equals'] = requestParameters.companyBalanceSheetIdEquals;
        }

        if (requestParameters.companyBalanceSheetIdNotEquals !== undefined) {
            queryParameters['companyBalanceSheetId.notEquals'] = requestParameters.companyBalanceSheetIdNotEquals;
        }

        if (requestParameters.companyBalanceSheetIdSpecified !== undefined) {
            queryParameters['companyBalanceSheetId.specified'] = requestParameters.companyBalanceSheetIdSpecified;
        }

        if (requestParameters.companyBalanceSheetIdIn) {
            queryParameters['companyBalanceSheetId.in'] = requestParameters.companyBalanceSheetIdIn;
        }

        if (requestParameters.companyBalanceSheetIdNotIn) {
            queryParameters['companyBalanceSheetId.notIn'] = requestParameters.companyBalanceSheetIdNotIn;
        }

        if (requestParameters.activityIdGreaterThan !== undefined) {
            queryParameters['activityId.greaterThan'] = requestParameters.activityIdGreaterThan;
        }

        if (requestParameters.activityIdLessThan !== undefined) {
            queryParameters['activityId.lessThan'] = requestParameters.activityIdLessThan;
        }

        if (requestParameters.activityIdGreaterThanOrEqual !== undefined) {
            queryParameters['activityId.greaterThanOrEqual'] = requestParameters.activityIdGreaterThanOrEqual;
        }

        if (requestParameters.activityIdLessThanOrEqual !== undefined) {
            queryParameters['activityId.lessThanOrEqual'] = requestParameters.activityIdLessThanOrEqual;
        }

        if (requestParameters.activityIdEquals !== undefined) {
            queryParameters['activityId.equals'] = requestParameters.activityIdEquals;
        }

        if (requestParameters.activityIdNotEquals !== undefined) {
            queryParameters['activityId.notEquals'] = requestParameters.activityIdNotEquals;
        }

        if (requestParameters.activityIdSpecified !== undefined) {
            queryParameters['activityId.specified'] = requestParameters.activityIdSpecified;
        }

        if (requestParameters.activityIdIn) {
            queryParameters['activityId.in'] = requestParameters.activityIdIn;
        }

        if (requestParameters.activityIdNotIn) {
            queryParameters['activityId.notIn'] = requestParameters.activityIdNotIn;
        }

        if (requestParameters.consultedIdGreaterThan !== undefined) {
            queryParameters['consultedId.greaterThan'] = requestParameters.consultedIdGreaterThan;
        }

        if (requestParameters.consultedIdLessThan !== undefined) {
            queryParameters['consultedId.lessThan'] = requestParameters.consultedIdLessThan;
        }

        if (requestParameters.consultedIdGreaterThanOrEqual !== undefined) {
            queryParameters['consultedId.greaterThanOrEqual'] = requestParameters.consultedIdGreaterThanOrEqual;
        }

        if (requestParameters.consultedIdLessThanOrEqual !== undefined) {
            queryParameters['consultedId.lessThanOrEqual'] = requestParameters.consultedIdLessThanOrEqual;
        }

        if (requestParameters.consultedIdEquals !== undefined) {
            queryParameters['consultedId.equals'] = requestParameters.consultedIdEquals;
        }

        if (requestParameters.consultedIdNotEquals !== undefined) {
            queryParameters['consultedId.notEquals'] = requestParameters.consultedIdNotEquals;
        }

        if (requestParameters.consultedIdSpecified !== undefined) {
            queryParameters['consultedId.specified'] = requestParameters.consultedIdSpecified;
        }

        if (requestParameters.consultedIdIn) {
            queryParameters['consultedId.in'] = requestParameters.consultedIdIn;
        }

        if (requestParameters.consultedIdNotIn) {
            queryParameters['consultedId.notIn'] = requestParameters.consultedIdNotIn;
        }

        if (requestParameters.userCompanyIdGreaterThan !== undefined) {
            queryParameters['userCompanyId.greaterThan'] = requestParameters.userCompanyIdGreaterThan;
        }

        if (requestParameters.userCompanyIdLessThan !== undefined) {
            queryParameters['userCompanyId.lessThan'] = requestParameters.userCompanyIdLessThan;
        }

        if (requestParameters.userCompanyIdGreaterThanOrEqual !== undefined) {
            queryParameters['userCompanyId.greaterThanOrEqual'] = requestParameters.userCompanyIdGreaterThanOrEqual;
        }

        if (requestParameters.userCompanyIdLessThanOrEqual !== undefined) {
            queryParameters['userCompanyId.lessThanOrEqual'] = requestParameters.userCompanyIdLessThanOrEqual;
        }

        if (requestParameters.userCompanyIdEquals !== undefined) {
            queryParameters['userCompanyId.equals'] = requestParameters.userCompanyIdEquals;
        }

        if (requestParameters.userCompanyIdNotEquals !== undefined) {
            queryParameters['userCompanyId.notEquals'] = requestParameters.userCompanyIdNotEquals;
        }

        if (requestParameters.userCompanyIdSpecified !== undefined) {
            queryParameters['userCompanyId.specified'] = requestParameters.userCompanyIdSpecified;
        }

        if (requestParameters.userCompanyIdIn) {
            queryParameters['userCompanyId.in'] = requestParameters.userCompanyIdIn;
        }

        if (requestParameters.userCompanyIdNotIn) {
            queryParameters['userCompanyId.notIn'] = requestParameters.userCompanyIdNotIn;
        }

        if (requestParameters.userConsultantIdGreaterThan !== undefined) {
            queryParameters['userConsultantId.greaterThan'] = requestParameters.userConsultantIdGreaterThan;
        }

        if (requestParameters.userConsultantIdLessThan !== undefined) {
            queryParameters['userConsultantId.lessThan'] = requestParameters.userConsultantIdLessThan;
        }

        if (requestParameters.userConsultantIdGreaterThanOrEqual !== undefined) {
            queryParameters['userConsultantId.greaterThanOrEqual'] = requestParameters.userConsultantIdGreaterThanOrEqual;
        }

        if (requestParameters.userConsultantIdLessThanOrEqual !== undefined) {
            queryParameters['userConsultantId.lessThanOrEqual'] = requestParameters.userConsultantIdLessThanOrEqual;
        }

        if (requestParameters.userConsultantIdEquals !== undefined) {
            queryParameters['userConsultantId.equals'] = requestParameters.userConsultantIdEquals;
        }

        if (requestParameters.userConsultantIdNotEquals !== undefined) {
            queryParameters['userConsultantId.notEquals'] = requestParameters.userConsultantIdNotEquals;
        }

        if (requestParameters.userConsultantIdSpecified !== undefined) {
            queryParameters['userConsultantId.specified'] = requestParameters.userConsultantIdSpecified;
        }

        if (requestParameters.userConsultantIdIn) {
            queryParameters['userConsultantId.in'] = requestParameters.userConsultantIdIn;
        }

        if (requestParameters.userConsultantIdNotIn) {
            queryParameters['userConsultantId.notIn'] = requestParameters.userConsultantIdNotIn;
        }

        if (requestParameters.companySavedIdGreaterThan !== undefined) {
            queryParameters['companySavedId.greaterThan'] = requestParameters.companySavedIdGreaterThan;
        }

        if (requestParameters.companySavedIdLessThan !== undefined) {
            queryParameters['companySavedId.lessThan'] = requestParameters.companySavedIdLessThan;
        }

        if (requestParameters.companySavedIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySavedId.greaterThanOrEqual'] = requestParameters.companySavedIdGreaterThanOrEqual;
        }

        if (requestParameters.companySavedIdLessThanOrEqual !== undefined) {
            queryParameters['companySavedId.lessThanOrEqual'] = requestParameters.companySavedIdLessThanOrEqual;
        }

        if (requestParameters.companySavedIdEquals !== undefined) {
            queryParameters['companySavedId.equals'] = requestParameters.companySavedIdEquals;
        }

        if (requestParameters.companySavedIdNotEquals !== undefined) {
            queryParameters['companySavedId.notEquals'] = requestParameters.companySavedIdNotEquals;
        }

        if (requestParameters.companySavedIdSpecified !== undefined) {
            queryParameters['companySavedId.specified'] = requestParameters.companySavedIdSpecified;
        }

        if (requestParameters.companySavedIdIn) {
            queryParameters['companySavedId.in'] = requestParameters.companySavedIdIn;
        }

        if (requestParameters.companySavedIdNotIn) {
            queryParameters['companySavedId.notIn'] = requestParameters.companySavedIdNotIn;
        }

        if (requestParameters.companySearchedIdGreaterThan !== undefined) {
            queryParameters['companySearchedId.greaterThan'] = requestParameters.companySearchedIdGreaterThan;
        }

        if (requestParameters.companySearchedIdLessThan !== undefined) {
            queryParameters['companySearchedId.lessThan'] = requestParameters.companySearchedIdLessThan;
        }

        if (requestParameters.companySearchedIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySearchedId.greaterThanOrEqual'] = requestParameters.companySearchedIdGreaterThanOrEqual;
        }

        if (requestParameters.companySearchedIdLessThanOrEqual !== undefined) {
            queryParameters['companySearchedId.lessThanOrEqual'] = requestParameters.companySearchedIdLessThanOrEqual;
        }

        if (requestParameters.companySearchedIdEquals !== undefined) {
            queryParameters['companySearchedId.equals'] = requestParameters.companySearchedIdEquals;
        }

        if (requestParameters.companySearchedIdNotEquals !== undefined) {
            queryParameters['companySearchedId.notEquals'] = requestParameters.companySearchedIdNotEquals;
        }

        if (requestParameters.companySearchedIdSpecified !== undefined) {
            queryParameters['companySearchedId.specified'] = requestParameters.companySearchedIdSpecified;
        }

        if (requestParameters.companySearchedIdIn) {
            queryParameters['companySearchedId.in'] = requestParameters.companySearchedIdIn;
        }

        if (requestParameters.companySearchedIdNotIn) {
            queryParameters['companySearchedId.notIn'] = requestParameters.companySearchedIdNotIn;
        }

        if (requestParameters.insightIdGreaterThan !== undefined) {
            queryParameters['insightId.greaterThan'] = requestParameters.insightIdGreaterThan;
        }

        if (requestParameters.insightIdLessThan !== undefined) {
            queryParameters['insightId.lessThan'] = requestParameters.insightIdLessThan;
        }

        if (requestParameters.insightIdGreaterThanOrEqual !== undefined) {
            queryParameters['insightId.greaterThanOrEqual'] = requestParameters.insightIdGreaterThanOrEqual;
        }

        if (requestParameters.insightIdLessThanOrEqual !== undefined) {
            queryParameters['insightId.lessThanOrEqual'] = requestParameters.insightIdLessThanOrEqual;
        }

        if (requestParameters.insightIdEquals !== undefined) {
            queryParameters['insightId.equals'] = requestParameters.insightIdEquals;
        }

        if (requestParameters.insightIdNotEquals !== undefined) {
            queryParameters['insightId.notEquals'] = requestParameters.insightIdNotEquals;
        }

        if (requestParameters.insightIdSpecified !== undefined) {
            queryParameters['insightId.specified'] = requestParameters.insightIdSpecified;
        }

        if (requestParameters.insightIdIn) {
            queryParameters['insightId.in'] = requestParameters.insightIdIn;
        }

        if (requestParameters.insightIdNotIn) {
            queryParameters['insightId.notIn'] = requestParameters.insightIdNotIn;
        }

        if (requestParameters.consultantIdGreaterThan !== undefined) {
            queryParameters['consultantId.greaterThan'] = requestParameters.consultantIdGreaterThan;
        }

        if (requestParameters.consultantIdLessThan !== undefined) {
            queryParameters['consultantId.lessThan'] = requestParameters.consultantIdLessThan;
        }

        if (requestParameters.consultantIdGreaterThanOrEqual !== undefined) {
            queryParameters['consultantId.greaterThanOrEqual'] = requestParameters.consultantIdGreaterThanOrEqual;
        }

        if (requestParameters.consultantIdLessThanOrEqual !== undefined) {
            queryParameters['consultantId.lessThanOrEqual'] = requestParameters.consultantIdLessThanOrEqual;
        }

        if (requestParameters.consultantIdEquals !== undefined) {
            queryParameters['consultantId.equals'] = requestParameters.consultantIdEquals;
        }

        if (requestParameters.consultantIdNotEquals !== undefined) {
            queryParameters['consultantId.notEquals'] = requestParameters.consultantIdNotEquals;
        }

        if (requestParameters.consultantIdSpecified !== undefined) {
            queryParameters['consultantId.specified'] = requestParameters.consultantIdSpecified;
        }

        if (requestParameters.consultantIdIn) {
            queryParameters['consultantId.in'] = requestParameters.consultantIdIn;
        }

        if (requestParameters.consultantIdNotIn) {
            queryParameters['consultantId.notIn'] = requestParameters.consultantIdNotIn;
        }

        if (requestParameters.postIdGreaterThan !== undefined) {
            queryParameters['postId.greaterThan'] = requestParameters.postIdGreaterThan;
        }

        if (requestParameters.postIdLessThan !== undefined) {
            queryParameters['postId.lessThan'] = requestParameters.postIdLessThan;
        }

        if (requestParameters.postIdGreaterThanOrEqual !== undefined) {
            queryParameters['postId.greaterThanOrEqual'] = requestParameters.postIdGreaterThanOrEqual;
        }

        if (requestParameters.postIdLessThanOrEqual !== undefined) {
            queryParameters['postId.lessThanOrEqual'] = requestParameters.postIdLessThanOrEqual;
        }

        if (requestParameters.postIdEquals !== undefined) {
            queryParameters['postId.equals'] = requestParameters.postIdEquals;
        }

        if (requestParameters.postIdNotEquals !== undefined) {
            queryParameters['postId.notEquals'] = requestParameters.postIdNotEquals;
        }

        if (requestParameters.postIdSpecified !== undefined) {
            queryParameters['postId.specified'] = requestParameters.postIdSpecified;
        }

        if (requestParameters.postIdIn) {
            queryParameters['postId.in'] = requestParameters.postIdIn;
        }

        if (requestParameters.postIdNotIn) {
            queryParameters['postId.notIn'] = requestParameters.postIdNotIn;
        }

        if (requestParameters.receivedInsightIdGreaterThan !== undefined) {
            queryParameters['receivedInsightId.greaterThan'] = requestParameters.receivedInsightIdGreaterThan;
        }

        if (requestParameters.receivedInsightIdLessThan !== undefined) {
            queryParameters['receivedInsightId.lessThan'] = requestParameters.receivedInsightIdLessThan;
        }

        if (requestParameters.receivedInsightIdGreaterThanOrEqual !== undefined) {
            queryParameters['receivedInsightId.greaterThanOrEqual'] = requestParameters.receivedInsightIdGreaterThanOrEqual;
        }

        if (requestParameters.receivedInsightIdLessThanOrEqual !== undefined) {
            queryParameters['receivedInsightId.lessThanOrEqual'] = requestParameters.receivedInsightIdLessThanOrEqual;
        }

        if (requestParameters.receivedInsightIdEquals !== undefined) {
            queryParameters['receivedInsightId.equals'] = requestParameters.receivedInsightIdEquals;
        }

        if (requestParameters.receivedInsightIdNotEquals !== undefined) {
            queryParameters['receivedInsightId.notEquals'] = requestParameters.receivedInsightIdNotEquals;
        }

        if (requestParameters.receivedInsightIdSpecified !== undefined) {
            queryParameters['receivedInsightId.specified'] = requestParameters.receivedInsightIdSpecified;
        }

        if (requestParameters.receivedInsightIdIn) {
            queryParameters['receivedInsightId.in'] = requestParameters.receivedInsightIdIn;
        }

        if (requestParameters.receivedInsightIdNotIn) {
            queryParameters['receivedInsightId.notIn'] = requestParameters.receivedInsightIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getAllCompanies(requestParameters: GetAllCompaniesRequest): Promise<Array<Company>> {
        const response = await this.getAllCompaniesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompany(requestParameters: GetCompanyRequest): Promise<Company> {
        const response = await this.getCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateCompanyRaw(requestParameters: PartialUpdateCompanyRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateCompany.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling partialUpdateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateCompany(requestParameters: PartialUpdateCompanyRequest): Promise<Company> {
        const response = await this.partialUpdateCompanyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        if (requestParameters.company === null || requestParameters.company === undefined) {
            throw new runtime.RequiredError('company','Required parameter requestParameters.company was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async updateCompany(requestParameters: UpdateCompanyRequest): Promise<Company> {
        const response = await this.updateCompanyRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountCompaniesSectorEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompaniesSectorNotEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompaniesSectorInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum CountCompaniesSectorNotInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompaniesSectorEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompaniesSectorNotEqualsEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompaniesSectorInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCompaniesSectorNotInEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}
