// 
// 
//
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { useFocusEffect } from '@react-navigation/native';
import { customUserConsultantInviteResourceApi } from '@services/apis/ApiConfiguration';
import { UserConsultantInvite, UserConsultantInviteCurrentStateEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../../../../types';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';
import RequestComponent from './RequestComponent';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';


export default function PendingRequest({ navigation, route }: RootStackScreenProps<'PendingRequest'>) {

  const [myUserConsultantInvite, setMyUserConsultantInvite] = React.useState<UserConsultantInvite[]>()

  const sharedContext = React.useContext(SharedContext);

  useFocusEffect(
    useCallback(() => {
      requestList()
    }, [])
  );
  const requestList = () => {
    customUserConsultantInviteResourceApi.getAllUserConsultantInviteByUser({ id: sharedContext.userMe?.id! }).then(res => {
      setMyUserConsultantInvite(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }
  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />

      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={'#00CC83'} />
        </TouchableOpacity>
        <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.request_pending")}</Text>
      </View>
      <ScrollView style={styles.containerScroll}>
        <View>
          <View style={styles.col}>
            <Text style={styles.titleManage}>

              {i18n.t('components.link_companies_data.title_pending')}
            </Text>
          </View>
          {myUserConsultantInvite
            && myUserConsultantInvite.length > 0
            && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Pending) != undefined ?
            myUserConsultantInvite?.map((userConsultantInvite, key) => {
              return (
                <View key={key}>
                  {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Pending &&
                    <RequestComponent request={userConsultantInvite} isUser={true} refreshRequestList={() => { requestList() }} />}
                </View>
              )
            })
            :
            <View style={styles.containerList}>
              <Text style={styles.textLabel}>

                {i18n.t('components.link_companies_data.no_pending')}
              </Text>
            </View>
          }
        </View>
        <View>
          <View style={styles.col}>
            <Text style={styles.titleManage}>

              {i18n.t('components.link_companies_data.title_accepted')}
            </Text>
          </View>
          {myUserConsultantInvite
            && myUserConsultantInvite.length > 0
            && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Accepted) != undefined ?
            myUserConsultantInvite?.map((userConsultantInvite, key) => {
              return (
                <View key={key}>
                  {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Accepted &&
                    <RequestComponent request={userConsultantInvite} isUser={true} refreshRequestList={() => { requestList() }} />}
                </View>
              )
            })
            :
            <View style={styles.containerList}>
              <Text style={styles.textLabel}>

                {i18n.t('components.link_companies_data.no_accepted')}
              </Text>
            </View>
          }
        </View>
        <View>
          <View style={styles.col}>
            <Text style={styles.titleManage}>

              {i18n.t('components.link_companies_data.title_rejected')}
            </Text>
          </View>
          {myUserConsultantInvite
            && myUserConsultantInvite.length > 0
            && myUserConsultantInvite.find((userConsultantInvite) => userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Rejected) != undefined ?
            myUserConsultantInvite?.map((userConsultantInvite, key) => {
              return (
                <View key={key}>
                  {userConsultantInvite.currentState == UserConsultantInviteCurrentStateEnum.Rejected &&
                    <RequestComponent request={userConsultantInvite} isUser={true} refreshRequestList={() => { requestList() }} />}
                </View>
              )
            })
            :
            <View style={styles.containerList}>
              <Text style={styles.textLabel}>

                {i18n.t('components.link_companies_data.no_rejected')}
              </Text>
            </View>
          }
        </View>
      </ScrollView>

    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  titleText: {
    marginHorizontal: 15,
    marginTop: 5,
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerSubtitle: {
    marginBottom: 20,
  },
  containerList: {
    marginBottom: 20,
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  titleManage: {
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 8,
    fontFamily: 'poppins',
    color: 'white',
    marginBottom: 20,
  },
  container: {
    flex: 0.15,
    marginTop: 50,
    marginBottom: 20,
    flexDirection: 'row',
    marginHorizontal: 20,
    fontFamily: 'poppins',
    alignItems: "center",
    justifyContent: "flex-start"
  },
  containerScroll: {
    flex: 0.85,
    marginBottom: 40,
    marginHorizontal: 15,
  },
  delimiter: {
    borderWidth: 1,
    borderColor: '#505050',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    padding: 15,
  },
  col: {
    flex: 0.8
  },
});



