/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EstremiAttoCostituzione
 */
export interface EstremiAttoCostituzione {
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    notaio?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    localitaNotaio?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    provinciaNotaio?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    dtRegistrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    localitaUfficioRegistro?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    provinciaUfficioRegistro?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    nrepertorio?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    nregistrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof EstremiAttoCostituzione
     */
    ctipo?: string;
}

export function EstremiAttoCostituzioneFromJSON(json: any): EstremiAttoCostituzione {
    return EstremiAttoCostituzioneFromJSONTyped(json, false);
}

export function EstremiAttoCostituzioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstremiAttoCostituzione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'notaio': !exists(json, 'notaio') ? undefined : json['notaio'],
        'localitaNotaio': !exists(json, 'localitaNotaio') ? undefined : json['localitaNotaio'],
        'provinciaNotaio': !exists(json, 'provinciaNotaio') ? undefined : json['provinciaNotaio'],
        'dtRegistrazione': !exists(json, 'dtRegistrazione') ? undefined : json['dtRegistrazione'],
        'localitaUfficioRegistro': !exists(json, 'localitaUfficioRegistro') ? undefined : json['localitaUfficioRegistro'],
        'provinciaUfficioRegistro': !exists(json, 'provinciaUfficioRegistro') ? undefined : json['provinciaUfficioRegistro'],
        'nrepertorio': !exists(json, 'nrepertorio') ? undefined : json['nrepertorio'],
        'nregistrazione': !exists(json, 'nregistrazione') ? undefined : json['nregistrazione'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function EstremiAttoCostituzioneToJSON(value?: EstremiAttoCostituzione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'notaio': value.notaio,
        'localitaNotaio': value.localitaNotaio,
        'provinciaNotaio': value.provinciaNotaio,
        'dtRegistrazione': value.dtRegistrazione,
        'localitaUfficioRegistro': value.localitaUfficioRegistro,
        'provinciaUfficioRegistro': value.provinciaUfficioRegistro,
        'nrepertorio': value.nrepertorio,
        'nregistrazione': value.nregistrazione,
        'ctipo': value.ctipo,
    };
}


