import { BottomTabBar } from '@react-navigation/bottom-tabs';
import { UserInterfaceIdiom } from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';
import { Platform } from 'expo-modules-core';
import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { StyleSheet, Image, View, TextInput, TouchableOpacity, Text, Dimensions, ImageBackground } from 'react-native';

import { RootStackScreenProps, RootTabScreenProps, State, UserExtendedType } from '../../../types';
import { BackgroundColorButtonDark, BackgroundColorButtonDarkLight, ColorLabel } from '../../components/ColorTheme';
import { i18n } from '../../i18n/i18n'

import { SplashLogoScreen } from '../../navigation/SplashLogoScreen';
import { UserExtendedTypeEnum } from '../../services/apis/generated';
import * as StorageHelper from '@helpers/StorageHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import { customUserExtendedResourceApi } from '@services/apis/ApiConfiguration';
import { AlertHelper } from '@helpers/AlertHelper';
import {PlatformHelper} from "../../helpers/PlatformHelper";
import {environment} from "../../environments/environment";


export default function SplashScreen({ navigation }: RootStackScreenProps<'SplashScreen'>) {

    let userType: UserExtendedType = {
        userType: UserExtendedTypeEnum.Company
    }
    const [isPressed, setPressed] = React.useState({ isPress: false, userType: '' })

    React.useEffect(() => {
        try{
            let token = useQuery().get('token');
            let activation_key = useQuery().get('key');
            let isWeb = PlatformHelper.isWeb() && !environment.debugRoom;

            if(token && !activation_key){
                location.href.includes('ResetPassword') ? NavigationHelper.navigateToResetPassword(navigation, token) : null;
            } else if(activation_key){
                location.href.includes('ActivateAccount') ? NavigationHelper.navigateToActivateAccount(navigation, activation_key, token) : null;   
            } else if(isWeb){
                NavigationHelper.navigateToResetPassword(navigation);
            }
                
            
        }
        catch(err){
            console.log(err);
        }
        

        StorageHelper.readAccessToken().then(async token => {
            if (token) {
                let type = await StorageHelper.readUserType();
                if (type) {
                    customUserExtendedResourceApi.getUserExtendedMe().then(async response => {
                        if (response.type) {
                            NavigationHelper.navigateToBottomTab(navigation, response);
                        }
                    }).catch((error) => {
                        AlertHelper.showSimpleErrorAlert();
                    })
                }
            }
        });
    }, [])

    /**
     * 
     * @returns query params
     */
     function useQuery() {
        return new URLSearchParams(window.location.search);
    }



    return (
        <View style={styles.containerBack}>
            <StatusBar />
            <ImageBackground
                source={require("../../../assets/images/backgroundSplash.png")}
                style={styles.containerBack}
            >

                <LinearGradient
                    colors={['rgba(65, 65, 65, 0.4)', 'rgba(0, 0, 0, 1) ']}
                    style={styles.containerBack}
                >
                    <View style={styles.container}>
                        <View style={styles.containerLogo}>
                            <SplashLogoScreen splash={true} />
                        </View>
                        <View style={styles.containerButtons}>
                            <Text style={[styles.text, { marginBottom: 10 }]}>{i18n.t('screens.splash.continue')}</Text>
                            <TouchableOpacity
                                style={isPressed.isPress && isPressed.userType == UserExtendedTypeEnum.Company ? styles.buttonHover : styles.button}
                                activeOpacity={0.5}
                                onPress={() => {
                                    setPressed({ isPress: true, userType: UserExtendedTypeEnum.Consultant })
                                    userType.userType = UserExtendedTypeEnum.Company, navigation.navigate('Login', userType)
                                }}
                            >
                                <Text style={styles.text}>{i18n.t('screens.dashboard.buttons.company')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={isPressed.isPress && isPressed.userType == UserExtendedTypeEnum.Consultant ? styles.buttonHover : styles.button}
                                activeOpacity={0.5}
                                onPress={() => {
                                    setPressed({ isPress: true, userType: UserExtendedTypeEnum.Consultant })
                                    userType.userType = UserExtendedTypeEnum.Consultant, navigation.navigate('Login', userType)
                                }}
                            >
                                <Text style={styles.text}>{i18n.t('screens.dashboard.buttons.consultants')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </LinearGradient>
            </ImageBackground>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginHorizontal: 20,
        justifyContent: 'space-between'
    },
    imageBack: {
        flex: 1
    },
    containerBack: {
        flex: 1,
    },
    containerLogo: {
        flex: 1,
    },
    containerButtons: {
        flex: 0.3,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: 30,
    },
    button: {
        marginTop: 10,
        backgroundColor: BackgroundColorButtonDark(),
        padding: 15,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: BackgroundColorButtonDarkLight()
    },
    buttonHover: {
        marginTop: 10,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 15,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: BackgroundColorButtonDarkLight()
    },
    text: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: ColorLabel(),
        fontFamily: 'poppins',
    },
});
