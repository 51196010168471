/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormaAmministrativaInCarica,
    FormaAmministrativaInCaricaFromJSON,
    FormaAmministrativaInCaricaFromJSONTyped,
    FormaAmministrativaInCaricaToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormeAmministrativeInCarica
 */
export interface FormeAmministrativeInCarica {
    /**
     * 
     * @type {Array<FormaAmministrativaInCarica>}
     * @memberof FormeAmministrativeInCarica
     */
    formaAmministrativaInCarica: Array<FormaAmministrativaInCarica>;
}

export function FormeAmministrativeInCaricaFromJSON(json: any): FormeAmministrativeInCarica {
    return FormeAmministrativeInCaricaFromJSONTyped(json, false);
}

export function FormeAmministrativeInCaricaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormeAmministrativeInCarica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formaAmministrativaInCarica': ((json['formaAmministrativaInCarica'] as Array<any>).map(FormaAmministrativaInCaricaFromJSON)),
    };
}

export function FormeAmministrativeInCaricaToJSON(value?: FormeAmministrativeInCarica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formaAmministrativaInCarica': ((value.formaAmministrativaInCarica as Array<any>).map(FormaAmministrativaInCaricaToJSON)),
    };
}


