import React from "react";
import {View, Text} from "react-native"
import Svg, {Path, Defs, Styles, Circle} from "react-native-svg"


interface SearchIconProps{
    width?: number,
    height?: number,
}

const SearchIcon = (props:SearchIconProps) => {
    return(
        <Svg width={props.width} height={props.height} viewBox="0 0 66 66">
        <Circle fill="#00CC83" cx="33" cy="33" r="33"/>
        <Path fill="#FFFFFF" d="M46.29,34.59a11.2,11.2,0,1,0,0,22.39A11.08,11.08,0,0,0,53,54.7l7.23,7.23a1.55,1.55,0,1,0,2.23-2.14l0,0L55.2,52.52a11.08,11.08,0,0,0,2.28-6.73A11.22,11.22,0,0,0,46.29,34.59Zm0,3.09a8.11,8.11,0,0,1,5.9,13.66,1.61,1.61,0,0,0-.34.35,8.11,8.11,0,1,1-5.56-14Z" transform="translate(-16 -15.5)"/>
        </Svg>
    )
}
export default SearchIcon