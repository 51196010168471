/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PushToken,
    PushTokenFromJSON,
    PushTokenToJSON,
} from '../models';

export interface CreatePushTokenRequest {
    pushToken: PushToken;
}

export interface DeletePushTokenRequest {
    id: number;
}

export interface GetPushTokenRequest {
    id: number;
}

export interface PartialUpdatePushTokenRequest {
    id: number;
    pushToken: PushToken;
}

export interface UpdatePushTokenRequest {
    id: number;
    pushToken: PushToken;
}

/**
 * 
 */
export class PushTokenResourceApi extends runtime.BaseAPI {

    /**
     */
    async createPushTokenRaw(requestParameters: CreatePushTokenRequest): Promise<runtime.ApiResponse<PushToken>> {
        if (requestParameters.pushToken === null || requestParameters.pushToken === undefined) {
            throw new runtime.RequiredError('pushToken','Required parameter requestParameters.pushToken was null or undefined when calling createPushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/push-tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushTokenToJSON(requestParameters.pushToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PushTokenFromJSON(jsonValue));
    }

    /**
     */
    async createPushToken(requestParameters: CreatePushTokenRequest): Promise<PushToken> {
        const response = await this.createPushTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePushTokenRaw(requestParameters: DeletePushTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/push-tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePushToken(requestParameters: DeletePushTokenRequest): Promise<void> {
        await this.deletePushTokenRaw(requestParameters);
    }

    /**
     */
    async getAllPushTokensRaw(): Promise<runtime.ApiResponse<Array<PushToken>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/push-tokens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PushTokenFromJSON));
    }

    /**
     */
    async getAllPushTokens(): Promise<Array<PushToken>> {
        const response = await this.getAllPushTokensRaw();
        return await response.value();
    }

    /**
     */
    async getPushTokenRaw(requestParameters: GetPushTokenRequest): Promise<runtime.ApiResponse<PushToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/push-tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PushTokenFromJSON(jsonValue));
    }

    /**
     */
    async getPushToken(requestParameters: GetPushTokenRequest): Promise<PushToken> {
        const response = await this.getPushTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdatePushTokenRaw(requestParameters: PartialUpdatePushTokenRequest): Promise<runtime.ApiResponse<PushToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdatePushToken.');
        }

        if (requestParameters.pushToken === null || requestParameters.pushToken === undefined) {
            throw new runtime.RequiredError('pushToken','Required parameter requestParameters.pushToken was null or undefined when calling partialUpdatePushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/push-tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PushTokenToJSON(requestParameters.pushToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PushTokenFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdatePushToken(requestParameters: PartialUpdatePushTokenRequest): Promise<PushToken> {
        const response = await this.partialUpdatePushTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePushTokenRaw(requestParameters: UpdatePushTokenRequest): Promise<runtime.ApiResponse<PushToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePushToken.');
        }

        if (requestParameters.pushToken === null || requestParameters.pushToken === undefined) {
            throw new runtime.RequiredError('pushToken','Required parameter requestParameters.pushToken was null or undefined when calling updatePushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/push-tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PushTokenToJSON(requestParameters.pushToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PushTokenFromJSON(jsonValue));
    }

    /**
     */
    async updatePushToken(requestParameters: UpdatePushTokenRequest): Promise<PushToken> {
        const response = await this.updatePushTokenRaw(requestParameters);
        return await response.value();
    }

}
