/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlbiRuoliLicenze,
    AlbiRuoliLicenzeFromJSON,
    AlbiRuoliLicenzeFromJSONTyped,
    AlbiRuoliLicenzeToJSON,
    AttivitaAaBz,
    AttivitaAaBzFromJSON,
    AttivitaAaBzFromJSONTyped,
    AttivitaAaBzToJSON,
    AttivitaNoAa,
    AttivitaNoAaFromJSON,
    AttivitaNoAaFromJSONTyped,
    AttivitaNoAaToJSON,
    CessazioneLocalizzazione,
    CessazioneLocalizzazioneFromJSON,
    CessazioneLocalizzazioneFromJSONTyped,
    CessazioneLocalizzazioneToJSON,
    ClassificazioniAteco,
    ClassificazioniAtecoFromJSON,
    ClassificazioniAtecoFromJSONTyped,
    ClassificazioniAtecoToJSON,
    ClassificazioniAteco2002,
    ClassificazioniAteco2002FromJSON,
    ClassificazioniAteco2002FromJSONTyped,
    ClassificazioniAteco2002ToJSON,
    ImpresaSubentrante,
    ImpresaSubentranteFromJSON,
    ImpresaSubentranteFromJSONTyped,
    ImpresaSubentranteToJSON,
    ImpresaSubentrata,
    ImpresaSubentrataFromJSON,
    ImpresaSubentrataFromJSONTyped,
    ImpresaSubentrataToJSON,
    IndirizzoLocalizzazione,
    IndirizzoLocalizzazioneFromJSON,
    IndirizzoLocalizzazioneFromJSONTyped,
    IndirizzoLocalizzazioneToJSON,
    InformazioniSupplementari,
    InformazioniSupplementariFromJSON,
    InformazioniSupplementariFromJSONTyped,
    InformazioniSupplementariToJSON,
    Persone,
    PersoneFromJSON,
    PersoneFromJSONTyped,
    PersoneToJSON,
    SedeFuoriProvincia,
    SedeFuoriProvinciaFromJSON,
    SedeFuoriProvinciaFromJSONTyped,
    SedeFuoriProvinciaToJSON,
    SedeSecondariaRs,
    SedeSecondariaRsFromJSON,
    SedeSecondariaRsFromJSONTyped,
    SedeSecondariaRsToJSON,
    SottoTipi,
    SottoTipiFromJSON,
    SottoTipiFromJSONTyped,
    SottoTipiToJSON,
    Telefax,
    TelefaxFromJSON,
    TelefaxFromJSONTyped,
    TelefaxToJSON,
    Telefono,
    TelefonoFromJSON,
    TelefonoFromJSONTyped,
    TelefonoToJSON,
    TrasferimentoLocalizzazione,
    TrasferimentoLocalizzazioneFromJSON,
    TrasferimentoLocalizzazioneFromJSONTyped,
    TrasferimentoLocalizzazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Localizzazione
 */
export interface Localizzazione {
    /**
     * 
     * @type {SottoTipi}
     * @memberof Localizzazione
     */
    sottoTipi?: SottoTipi;
    /**
     * 
     * @type {IndirizzoLocalizzazione}
     * @memberof Localizzazione
     */
    indirizzoLocalizzazione?: IndirizzoLocalizzazione;
    /**
     * 
     * @type {Telefono}
     * @memberof Localizzazione
     */
    telefono?: Telefono;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    telex?: string;
    /**
     * 
     * @type {Telefax}
     * @memberof Localizzazione
     */
    telefax?: Telefax;
    /**
     * 
     * @type {SedeFuoriProvincia}
     * @memberof Localizzazione
     */
    sedeFuoriProvincia?: SedeFuoriProvincia;
    /**
     * 
     * @type {SedeSecondariaRs}
     * @memberof Localizzazione
     */
    sedeSecondariaRs?: SedeSecondariaRs;
    /**
     * 
     * @type {ImpresaSubentrata}
     * @memberof Localizzazione
     */
    impresaSubentrata?: ImpresaSubentrata;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    attivitaEsercitata?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    attivitaSecondariaEsercitata?: string;
    /**
     * 
     * @type {AttivitaAaBz}
     * @memberof Localizzazione
     */
    attivitaAaBz?: AttivitaAaBz;
    /**
     * 
     * @type {AttivitaNoAa}
     * @memberof Localizzazione
     */
    attivitaNoAa?: AttivitaNoAa;
    /**
     * 
     * @type {ClassificazioniAteco2002}
     * @memberof Localizzazione
     */
    classificazioniAteco2002?: ClassificazioniAteco2002;
    /**
     * 
     * @type {Array<ClassificazioniAteco>}
     * @memberof Localizzazione
     */
    classificazioniAteco?: Array<ClassificazioniAteco>;
    /**
     * 
     * @type {AlbiRuoliLicenze}
     * @memberof Localizzazione
     */
    albiRuoliLicenze?: AlbiRuoliLicenze;
    /**
     * 
     * @type {Persone}
     * @memberof Localizzazione
     */
    persone?: Persone;
    /**
     * 
     * @type {InformazioniSupplementari}
     * @memberof Localizzazione
     */
    informazioniSupplementari?: InformazioniSupplementari;
    /**
     * 
     * @type {CessazioneLocalizzazione}
     * @memberof Localizzazione
     */
    cessazioneLocalizzazione?: CessazioneLocalizzazione;
    /**
     * 
     * @type {TrasferimentoLocalizzazione}
     * @memberof Localizzazione
     */
    trasferimentoLocalizzazione?: TrasferimentoLocalizzazione;
    /**
     * 
     * @type {ImpresaSubentrante}
     * @memberof Localizzazione
     */
    impresaSubentrante?: ImpresaSubentrante;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    progressivo?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    tipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    denominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    insegna?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    dtApertura?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    ctipoIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    fscrittureContabili?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    fdatiRidotti?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    fcessazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    ctipo?: string;
    /**
     * 
     * @type {string}
     * @memberof Localizzazione
     */
    ceuid?: string;
}

export function LocalizzazioneFromJSON(json: any): Localizzazione {
    return LocalizzazioneFromJSONTyped(json, false);
}

export function LocalizzazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Localizzazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sottoTipi': !exists(json, 'sottoTipi') ? undefined : SottoTipiFromJSON(json['sottoTipi']),
        'indirizzoLocalizzazione': !exists(json, 'indirizzoLocalizzazione') ? undefined : IndirizzoLocalizzazioneFromJSON(json['indirizzoLocalizzazione']),
        'telefono': !exists(json, 'telefono') ? undefined : TelefonoFromJSON(json['telefono']),
        'telex': !exists(json, 'telex') ? undefined : json['telex'],
        'telefax': !exists(json, 'telefax') ? undefined : TelefaxFromJSON(json['telefax']),
        'sedeFuoriProvincia': !exists(json, 'sedeFuoriProvincia') ? undefined : SedeFuoriProvinciaFromJSON(json['sedeFuoriProvincia']),
        'sedeSecondariaRs': !exists(json, 'sedeSecondariaRs') ? undefined : SedeSecondariaRsFromJSON(json['sedeSecondariaRs']),
        'impresaSubentrata': !exists(json, 'impresaSubentrata') ? undefined : ImpresaSubentrataFromJSON(json['impresaSubentrata']),
        'attivitaEsercitata': !exists(json, 'attivitaEsercitata') ? undefined : json['attivitaEsercitata'],
        'attivitaSecondariaEsercitata': !exists(json, 'attivitaSecondariaEsercitata') ? undefined : json['attivitaSecondariaEsercitata'],
        'attivitaAaBz': !exists(json, 'attivitaAaBz') ? undefined : AttivitaAaBzFromJSON(json['attivitaAaBz']),
        'attivitaNoAa': !exists(json, 'attivitaNoAa') ? undefined : AttivitaNoAaFromJSON(json['attivitaNoAa']),
        'classificazioniAteco2002': !exists(json, 'classificazioniAteco2002') ? undefined : ClassificazioniAteco2002FromJSON(json['classificazioniAteco2002']),
        'classificazioniAteco': !exists(json, 'classificazioniAteco') ? undefined : ((json['classificazioniAteco'] as Array<any>).map(ClassificazioniAtecoFromJSON)),
        'albiRuoliLicenze': !exists(json, 'albiRuoliLicenze') ? undefined : AlbiRuoliLicenzeFromJSON(json['albiRuoliLicenze']),
        'persone': !exists(json, 'persone') ? undefined : PersoneFromJSON(json['persone']),
        'informazioniSupplementari': !exists(json, 'informazioniSupplementari') ? undefined : InformazioniSupplementariFromJSON(json['informazioniSupplementari']),
        'cessazioneLocalizzazione': !exists(json, 'cessazioneLocalizzazione') ? undefined : CessazioneLocalizzazioneFromJSON(json['cessazioneLocalizzazione']),
        'trasferimentoLocalizzazione': !exists(json, 'trasferimentoLocalizzazione') ? undefined : TrasferimentoLocalizzazioneFromJSON(json['trasferimentoLocalizzazione']),
        'impresaSubentrante': !exists(json, 'impresaSubentrante') ? undefined : ImpresaSubentranteFromJSON(json['impresaSubentrante']),
        'progressivo': !exists(json, 'progressivo') ? undefined : json['progressivo'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'tipoIscrizione': !exists(json, 'tipoIscrizione') ? undefined : json['tipoIscrizione'],
        'denominazione': !exists(json, 'denominazione') ? undefined : json['denominazione'],
        'insegna': !exists(json, 'insegna') ? undefined : json['insegna'],
        'dtApertura': !exists(json, 'dtApertura') ? undefined : json['dtApertura'],
        'ctipoIscrizione': !exists(json, 'ctipoIscrizione') ? undefined : json['ctipoIscrizione'],
        'fscrittureContabili': !exists(json, 'fscrittureContabili') ? undefined : json['fscrittureContabili'],
        'fdatiRidotti': !exists(json, 'fdatiRidotti') ? undefined : json['fdatiRidotti'],
        'fcessazione': !exists(json, 'fcessazione') ? undefined : json['fcessazione'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
        'ceuid': !exists(json, 'ceuid') ? undefined : json['ceuid'],
    };
}

export function LocalizzazioneToJSON(value?: Localizzazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sottoTipi': SottoTipiToJSON(value.sottoTipi),
        'indirizzoLocalizzazione': IndirizzoLocalizzazioneToJSON(value.indirizzoLocalizzazione),
        'telefono': TelefonoToJSON(value.telefono),
        'telex': value.telex,
        'telefax': TelefaxToJSON(value.telefax),
        'sedeFuoriProvincia': SedeFuoriProvinciaToJSON(value.sedeFuoriProvincia),
        'sedeSecondariaRs': SedeSecondariaRsToJSON(value.sedeSecondariaRs),
        'impresaSubentrata': ImpresaSubentrataToJSON(value.impresaSubentrata),
        'attivitaEsercitata': value.attivitaEsercitata,
        'attivitaSecondariaEsercitata': value.attivitaSecondariaEsercitata,
        'attivitaAaBz': AttivitaAaBzToJSON(value.attivitaAaBz),
        'attivitaNoAa': AttivitaNoAaToJSON(value.attivitaNoAa),
        'classificazioniAteco2002': ClassificazioniAteco2002ToJSON(value.classificazioniAteco2002),
        'classificazioniAteco': value.classificazioniAteco === undefined ? undefined : ((value.classificazioniAteco as Array<any>).map(ClassificazioniAtecoToJSON)),
        'albiRuoliLicenze': AlbiRuoliLicenzeToJSON(value.albiRuoliLicenze),
        'persone': PersoneToJSON(value.persone),
        'informazioniSupplementari': InformazioniSupplementariToJSON(value.informazioniSupplementari),
        'cessazioneLocalizzazione': CessazioneLocalizzazioneToJSON(value.cessazioneLocalizzazione),
        'trasferimentoLocalizzazione': TrasferimentoLocalizzazioneToJSON(value.trasferimentoLocalizzazione),
        'impresaSubentrante': ImpresaSubentranteToJSON(value.impresaSubentrante),
        'progressivo': value.progressivo,
        'tipo': value.tipo,
        'tipoIscrizione': value.tipoIscrizione,
        'denominazione': value.denominazione,
        'insegna': value.insegna,
        'dtApertura': value.dtApertura,
        'ctipoIscrizione': value.ctipoIscrizione,
        'fscrittureContabili': value.fscrittureContabili,
        'fdatiRidotti': value.fdatiRidotti,
        'fcessazione': value.fcessazione,
        'ctipo': value.ctipo,
        'ceuid': value.ceuid,
    };
}


