/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuotaDirittoImpresa,
    QuotaDirittoImpresaFromJSON,
    QuotaDirittoImpresaFromJSONTyped,
    QuotaDirittoImpresaToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuoteDirittiImpresa
 */
export interface QuoteDirittiImpresa {
    /**
     * 
     * @type {Array<QuotaDirittoImpresa>}
     * @memberof QuoteDirittiImpresa
     */
    quotaDirittoImpresa: Array<QuotaDirittoImpresa>;
}

export function QuoteDirittiImpresaFromJSON(json: any): QuoteDirittiImpresa {
    return QuoteDirittiImpresaFromJSONTyped(json, false);
}

export function QuoteDirittiImpresaFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteDirittiImpresa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quotaDirittoImpresa': ((json['quotaDirittoImpresa'] as Array<any>).map(QuotaDirittoImpresaFromJSON)),
    };
}

export function QuoteDirittiImpresaToJSON(value?: QuoteDirittiImpresa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quotaDirittoImpresa': ((value.quotaDirittoImpresa as Array<any>).map(QuotaDirittoImpresaToJSON)),
    };
}


