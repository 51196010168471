/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiPratica,
    EstremiPraticaFromJSON,
    EstremiPraticaFromJSONTyped,
    EstremiPraticaToJSON,
    RiquadriTrasferimento,
    RiquadriTrasferimentoFromJSON,
    RiquadriTrasferimentoFromJSONTyped,
    RiquadriTrasferimentoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrasferimentoQuote
 */
export interface TrasferimentoQuote {
    /**
     * 
     * @type {EstremiPratica}
     * @memberof TrasferimentoQuote
     */
    estremiPratica?: EstremiPratica;
    /**
     * 
     * @type {RiquadriTrasferimento}
     * @memberof TrasferimentoQuote
     */
    riquadriTrasferimento?: RiquadriTrasferimento;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrasferimentoQuote
     */
    note?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TrasferimentoQuote
     */
    fcontestuale?: string;
}

export function TrasferimentoQuoteFromJSON(json: any): TrasferimentoQuote {
    return TrasferimentoQuoteFromJSONTyped(json, false);
}

export function TrasferimentoQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrasferimentoQuote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiPratica': !exists(json, 'estremiPratica') ? undefined : EstremiPraticaFromJSON(json['estremiPratica']),
        'riquadriTrasferimento': !exists(json, 'riquadriTrasferimento') ? undefined : RiquadriTrasferimentoFromJSON(json['riquadriTrasferimento']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'fcontestuale': !exists(json, 'fcontestuale') ? undefined : json['fcontestuale'],
    };
}

export function TrasferimentoQuoteToJSON(value?: TrasferimentoQuote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiPratica': EstremiPraticaToJSON(value.estremiPratica),
        'riquadriTrasferimento': RiquadriTrasferimentoToJSON(value.riquadriTrasferimento),
        'note': value.note,
        'fcontestuale': value.fcontestuale,
    };
}


