/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserConsultant
 */
export interface UserConsultant {
    /**
     * 
     * @type {number}
     * @memberof UserConsultant
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserConsultant
     */
    role?: UserConsultantRoleEnum;
    /**
     * 
     * @type {User}
     * @memberof UserConsultant
     */
    user?: User;
    /**
     * 
     * @type {Company}
     * @memberof UserConsultant
     */
    consultant?: Company;
}

/**
* @export
* @enum {string}
*/
export enum UserConsultantRoleEnum {
    Administrator = 'ADMINISTRATOR',
    Sales = 'SALES',
    Analyst = 'ANALYST'
}

export function UserConsultantFromJSON(json: any): UserConsultant {
    return UserConsultantFromJSONTyped(json, false);
}

export function UserConsultantFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConsultant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'consultant': !exists(json, 'consultant') ? undefined : CompanyFromJSON(json['consultant']),
    };
}

export function UserConsultantToJSON(value?: UserConsultant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'user': UserToJSON(value.user),
        'consultant': CompanyToJSON(value.consultant),
    };
}


