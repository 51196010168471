import { FontAwesome } from '@expo/vector-icons';
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import ModalSimpleError from '@screens/Modal/ModalSimpleError';
import Icon from '@screens/Tools/Icon';
import { accountResourceApi, customAccountResourceApi } from '@services/apis/ApiConfiguration';
import { RegistrationDTO, UserExtendedTypeEnum, RegistrationDTOTypeEnum, RegistrationDTOCompanyRoleEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { RootStackScreenProps, UserExtendedType } from '../../../../types';
import {
    BackgroundColorButtonDarkLight,
    BackgroundColorInputText,
    ColorLabel,
    ColorPlaceHolder,
    ColorTextInput,
    ColorTextLightGreen
} from '../../../components/ColorTheme';
import { i18n } from '../../../i18n/i18n'
import { SplashLogoScreen } from '../../../navigation/SplashLogoScreen';
import Colors from "../../../../constants/Colors";
import { ErrorHelper } from '@helpers/ErrorHelper';
import AppContext from '../../../navigation/AppContext';
import ModalSimpleSuccess from '@screens/Modal/ModalSimpleSuccess';


export default function ResetPasswordScreen({ navigation, route }: RootStackScreenProps<'ResetPassword'>) {

    const appContext = useContext(AppContext);
    const [token, setToken] = React.useState<any>(route.params)
    const [visibleModalError, setVisibleModalError] = useState<boolean>(false)
    const [changePassword, setChangePassword] = useState<boolean>(false);

    useEffect(() => {
        if(route.params?.token!=undefined){
            setToken(route.params)
        }else{
            setToken({token:route.params})
        }
        return () => {
            setToken('')
            setVisibleModalError(false)
        }
    }, [])



    const [show, setShow] = React.useState(true);

    enum ValidationFields {
        email = "email",
        login = "login",
        currentPassword = "currentPassword",
        newPassword = "newPassword"
    }
    let initErrorFields: { [key in ValidationFields]: [] } = {
        email: [],
        login: [],
        currentPassword: [],
        newPassword: []
    }

    const [form, setForm] = React.useState<any>({
        email: '',
        login: '',
        currentPassword: '',
        newPassword: []
    });
    const [errorFields, setErrorFields] = React.useState(initErrorFields)
    const [checkEmailMessage, setCheckEmailMessage] = React.useState<boolean>(false);

    function handleChange(key: any) {
        return (event: any) => {
            const value = event;
            if (key == ValidationFields.email)
                setForm((form: any) => ({ ...form, login: value }));

            setForm((form: any) => ({ ...form, [key]: value }));
        }
    }


    function drawValidationErrors(field: ValidationFields): React.ReactNode {
        let validationErrors: any = [];

        for (const [key, value] of Object.entries(errorFields)) {
            if (key == field) {
                value.forEach((element) => {
                    validationErrors.push(<Text style={styles.error} key={element} >{i18n.t("errors.validation.registration." + element)}</Text>)
                })
            }
        }

        if (validationErrors.length > 0) {
            return validationErrors
        } else {
            return null
        }
    }


    function sendMailResetPassword() {
        setErrorFields(initErrorFields);
        setCheckEmailMessage(true);

        customAccountResourceApi.requestPasswordReset({ passwordResetDTO: { email: form.email } }).then((requestSent) => {
            console.log(requestSent, 'REQUEST SENT');
        })

        console.log(form, 'FORM');

    }

    const passwordMatch = (): boolean => {
        let currentPassword = form.currentPassword;
        let newPassword = form.newPassword;

        if (currentPassword != newPassword) {
            setVisibleModalError(true)
            return false
        }
        return true;
    }

    const resetPassword = () => {
        if (passwordMatch()) {
            accountResourceApi.finishPasswordReset({ keyAndPasswordVM: { key: token?.token, newPassword: form.newPassword } }).then((reset) => {
                console.log(reset);
                setChangePassword(true);
            })
        }
    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={styles.container}>
                <SplashLogoScreen welcome={true} />

                {/* GO BACK */}
                {( !token?.token) &&
                    <View style={styles.containerGoBack}>
                        <TouchableOpacity
                            style={styles.goBack}
                            onPress={() => { NavigationHelper.navigateToGoBack(navigation) }}
                        >
                            <Icon name={'angle-left'} size={24} color={'#00CC83'} />

                            <Text style={[styles.textSearch]}>
                                {i18n.t('screens.signup.login')}
                            </Text>
                        </TouchableOpacity>
                    </View>}

                {(!checkEmailMessage && !token?.token) &&
                    <View style={styles.containerTextInput}>
                        <Text style={styles.labelTextInput}>{i18n.t('screens.signup.mail_address')}</Text>
                        <TextInput
                            placeholder={i18n.t('screens.signup.mail_address')}
                            placeholderTextColor={ColorPlaceHolder()}
                            onChangeText={handleChange(ValidationFields.email)}
                            style={styles.textInput}
                        />
                        {errorFields.email.length > 0 && drawValidationErrors(ValidationFields.email)}
                        {errorFields.login.length > 0 && drawValidationErrors(ValidationFields.login)}

                        <TouchableOpacity
                            style={styles.button}
                            activeOpacity={0.5}
                            onPress={() => { sendMailResetPassword() }}
                        >
                            <Text style={styles.text}>{i18n.t('screens.login.send_mail')}</Text>
                        </TouchableOpacity>

                    </View>
                }
                {(checkEmailMessage && !token?.token) &&
                    <View style={styles.containerTextInput}>
                        <Text style={styles.labelTextInput}>{i18n.t('screens.login.check_mail_reset_password')}</Text>
                    </View>
                }

                {token?.token && !changePassword &&
                    <View style={styles.containerTextInput}>

                        <Text style={styles.labelTextInput}>{i18n.t('screens.signup.password')}</Text>
                        <TextInput
                            placeholder={i18n.t('screens.signup.password')}
                            placeholderTextColor={ColorPlaceHolder()}
                            autoCorrect={false}
                            onChangeText={handleChange(ValidationFields.currentPassword)}
                            secureTextEntry={show}
                            style={styles.textInput}
                        >
                        </TextInput>
                        {errorFields.email.length > 0 && drawValidationErrors(ValidationFields.currentPassword)}

                        <Text style={styles.labelTextInput}>{i18n.t('screens.signup.confirm_password')}</Text>
                        <TextInput
                            placeholder={i18n.t('screens.signup.confirm_password')}
                            placeholderTextColor={ColorPlaceHolder()}
                            autoCorrect={false}
                            onChangeText={handleChange(ValidationFields.newPassword)}
                            secureTextEntry={show}
                            style={styles.textInput}
                        >
                        </TextInput>
                        {errorFields.email.length > 0 && drawValidationErrors(ValidationFields.newPassword)}


                        <TouchableOpacity
                            style={styles.button}
                            activeOpacity={0.5}
                            onPress={() => { resetPassword() }}
                        >
                            <Text style={styles.text}>{i18n.t('screens.login.reset_password')}</Text>
                        </TouchableOpacity>

                    </View>
                    
                }
                {changePassword &&
                    <View style={styles.containerTextInput}>
                        <Text style={styles.labelTextInput}>{i18n.t('screens.login.password_changed')}</Text>
                    </View>
                    }
            </View>
            {visibleModalError &&
                <ModalSimpleError
                    msg={i18n.t('screens.login.confirm_password_error')}
                    visible={(visible) => { setVisibleModalError(visible) }}
                />}
        </LinearGradient >
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        justifyContent: 'flex-start',
        fontFamily: 'poppins',
    },
    containerGoBack: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        borderRadius: 13,
        fontFamily: 'gotham',
        paddingBottom: 30
    },
    button: {
        marginTop: 30,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 20,
        borderRadius: 5,
        fontFamily: 'poppins',
        marginBottom: 30
    },
    text: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: ColorTextInput(),
    },
    goBack: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textSearch: {
        textAlign: 'left',
        fontSize: 20,
        fontFamily: 'poppins',
        color: '#00CC83',
        marginLeft: 20
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    buttonShowHide: {
        alignSelf: 'center',
        flex: 0.1,
    },
    containerTextInput: {
        flex: 0.7,
        justifyContent: 'flex-start',
        fontFamily: 'poppins',
    },
    labelTextInput: {
        alignSelf: 'flex-start',
        color: ColorLabel(),
        marginBottom: 5,
        fontFamily: 'poppins',
    },
    textInput: {
        backgroundColor: BackgroundColorInputText(),
        padding: 13,
        color: ColorTextInput(),
        borderRadius: 5,
        marginBottom: 15,
        fontFamily: 'poppins',
    },
    viewForgot: {
        alignSelf: 'flex-end',
        fontFamily: 'poppins',
    },
    textForgot: {
        color: ColorTextLightGreen(),
        fontFamily: 'poppins',
    },
    buttonLogin: {
        marginTop: 40,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 20,
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    textLogin: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: 'white',
        fontFamily: 'poppins',
    },
    viewRegistration: {
        alignSelf: 'center',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginBottom: 20,
        flex: 0.1
    },
    textRegistration: {
        color: 'white',
        fontFamily: 'poppins',
    },
    error: {
        color: "#ff3333",
        fontSize: 12,
        marginHorizontal: 4
    },
});
