/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrascrizioneProtCompleti
 */
export interface TrascrizioneProtCompleti {
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneProtCompleti
     */
    progressivoTrascrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneProtCompleti
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneProtCompleti
     */
    tipoTrascrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof TrascrizioneProtCompleti
     */
    ctipoTrascrizione?: string;
}

export function TrascrizioneProtCompletiFromJSON(json: any): TrascrizioneProtCompleti {
    return TrascrizioneProtCompletiFromJSONTyped(json, false);
}

export function TrascrizioneProtCompletiFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrascrizioneProtCompleti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'progressivoTrascrizione': !exists(json, 'progressivoTrascrizione') ? undefined : json['progressivoTrascrizione'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'tipoTrascrizione': !exists(json, 'tipoTrascrizione') ? undefined : json['tipoTrascrizione'],
        'ctipoTrascrizione': !exists(json, 'ctipoTrascrizione') ? undefined : json['ctipoTrascrizione'],
    };
}

export function TrascrizioneProtCompletiToJSON(value?: TrascrizioneProtCompleti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'progressivoTrascrizione': value.progressivoTrascrizione,
        'dtIscrizione': value.dtIscrizione,
        'tipoTrascrizione': value.tipoTrascrizione,
        'ctipoTrascrizione': value.ctipoTrascrizione,
    };
}


