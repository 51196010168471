/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SurveyQuestion,
    SurveyQuestionFromJSON,
    SurveyQuestionToJSON,
} from '../models';

export interface CountSurveyQuestionsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    questionEnContains?: string;
    questionEnDoesNotContain?: string;
    questionEnEquals?: string;
    questionEnNotEquals?: string;
    questionEnSpecified?: boolean;
    questionEnIn?: Array<string>;
    questionEnNotIn?: Array<string>;
    questionItContains?: string;
    questionItDoesNotContain?: string;
    questionItEquals?: string;
    questionItNotEquals?: string;
    questionItSpecified?: boolean;
    questionItIn?: Array<string>;
    questionItNotIn?: Array<string>;
    titleContains?: string;
    titleDoesNotContain?: string;
    titleEquals?: string;
    titleNotEquals?: string;
    titleSpecified?: boolean;
    titleIn?: Array<string>;
    titleNotIn?: Array<string>;
    questionLeanEquals?: boolean;
    questionLeanNotEquals?: boolean;
    questionLeanSpecified?: boolean;
    questionLeanIn?: Array<boolean>;
    questionLeanNotIn?: Array<boolean>;
    questionAreaEquals?: CountSurveyQuestionsQuestionAreaEqualsEnum;
    questionAreaNotEquals?: CountSurveyQuestionsQuestionAreaNotEqualsEnum;
    questionAreaSpecified?: boolean;
    questionAreaIn?: Array<CountSurveyQuestionsQuestionAreaInEnum>;
    questionAreaNotIn?: Array<CountSurveyQuestionsQuestionAreaNotInEnum>;
    questionAgilityEquals?: CountSurveyQuestionsQuestionAgilityEqualsEnum;
    questionAgilityNotEquals?: CountSurveyQuestionsQuestionAgilityNotEqualsEnum;
    questionAgilitySpecified?: boolean;
    questionAgilityIn?: Array<CountSurveyQuestionsQuestionAgilityInEnum>;
    questionAgilityNotIn?: Array<CountSurveyQuestionsQuestionAgilityNotInEnum>;
    questionGrowthEquals?: CountSurveyQuestionsQuestionGrowthEqualsEnum;
    questionGrowthNotEquals?: CountSurveyQuestionsQuestionGrowthNotEqualsEnum;
    questionGrowthSpecified?: boolean;
    questionGrowthIn?: Array<CountSurveyQuestionsQuestionGrowthInEnum>;
    questionGrowthNotIn?: Array<CountSurveyQuestionsQuestionGrowthNotInEnum>;
    questionAttitudeEquals?: CountSurveyQuestionsQuestionAttitudeEqualsEnum;
    questionAttitudeNotEquals?: CountSurveyQuestionsQuestionAttitudeNotEqualsEnum;
    questionAttitudeSpecified?: boolean;
    questionAttitudeIn?: Array<CountSurveyQuestionsQuestionAttitudeInEnum>;
    questionAttitudeNotIn?: Array<CountSurveyQuestionsQuestionAttitudeNotInEnum>;
    maxScoreGreaterThan?: number;
    maxScoreLessThan?: number;
    maxScoreGreaterThanOrEqual?: number;
    maxScoreLessThanOrEqual?: number;
    maxScoreEquals?: number;
    maxScoreNotEquals?: number;
    maxScoreSpecified?: boolean;
    maxScoreIn?: Array<number>;
    maxScoreNotIn?: Array<number>;
    surveyQuestionResponseIdGreaterThan?: number;
    surveyQuestionResponseIdLessThan?: number;
    surveyQuestionResponseIdGreaterThanOrEqual?: number;
    surveyQuestionResponseIdLessThanOrEqual?: number;
    surveyQuestionResponseIdEquals?: number;
    surveyQuestionResponseIdNotEquals?: number;
    surveyQuestionResponseIdSpecified?: boolean;
    surveyQuestionResponseIdIn?: Array<number>;
    surveyQuestionResponseIdNotIn?: Array<number>;
    surveyIdGreaterThan?: number;
    surveyIdLessThan?: number;
    surveyIdGreaterThanOrEqual?: number;
    surveyIdLessThanOrEqual?: number;
    surveyIdEquals?: number;
    surveyIdNotEquals?: number;
    surveyIdSpecified?: boolean;
    surveyIdIn?: Array<number>;
    surveyIdNotIn?: Array<number>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface CreateSurveyQuestionRequest {
    surveyQuestion: SurveyQuestion;
}

export interface DeleteSurveyQuestionRequest {
    id: number;
}

export interface GetAllSurveyQuestionsRequest {
    idGreaterThan?: number;
    idLessThan?: number;
    idGreaterThanOrEqual?: number;
    idLessThanOrEqual?: number;
    idEquals?: number;
    idNotEquals?: number;
    idSpecified?: boolean;
    idIn?: Array<number>;
    idNotIn?: Array<number>;
    questionEnContains?: string;
    questionEnDoesNotContain?: string;
    questionEnEquals?: string;
    questionEnNotEquals?: string;
    questionEnSpecified?: boolean;
    questionEnIn?: Array<string>;
    questionEnNotIn?: Array<string>;
    questionItContains?: string;
    questionItDoesNotContain?: string;
    questionItEquals?: string;
    questionItNotEquals?: string;
    questionItSpecified?: boolean;
    questionItIn?: Array<string>;
    questionItNotIn?: Array<string>;
    titleContains?: string;
    titleDoesNotContain?: string;
    titleEquals?: string;
    titleNotEquals?: string;
    titleSpecified?: boolean;
    titleIn?: Array<string>;
    titleNotIn?: Array<string>;
    questionLeanEquals?: boolean;
    questionLeanNotEquals?: boolean;
    questionLeanSpecified?: boolean;
    questionLeanIn?: Array<boolean>;
    questionLeanNotIn?: Array<boolean>;
    questionAreaEquals?: GetAllSurveyQuestionsQuestionAreaEqualsEnum;
    questionAreaNotEquals?: GetAllSurveyQuestionsQuestionAreaNotEqualsEnum;
    questionAreaSpecified?: boolean;
    questionAreaIn?: Array<GetAllSurveyQuestionsQuestionAreaInEnum>;
    questionAreaNotIn?: Array<GetAllSurveyQuestionsQuestionAreaNotInEnum>;
    questionAgilityEquals?: GetAllSurveyQuestionsQuestionAgilityEqualsEnum;
    questionAgilityNotEquals?: GetAllSurveyQuestionsQuestionAgilityNotEqualsEnum;
    questionAgilitySpecified?: boolean;
    questionAgilityIn?: Array<GetAllSurveyQuestionsQuestionAgilityInEnum>;
    questionAgilityNotIn?: Array<GetAllSurveyQuestionsQuestionAgilityNotInEnum>;
    questionGrowthEquals?: GetAllSurveyQuestionsQuestionGrowthEqualsEnum;
    questionGrowthNotEquals?: GetAllSurveyQuestionsQuestionGrowthNotEqualsEnum;
    questionGrowthSpecified?: boolean;
    questionGrowthIn?: Array<GetAllSurveyQuestionsQuestionGrowthInEnum>;
    questionGrowthNotIn?: Array<GetAllSurveyQuestionsQuestionGrowthNotInEnum>;
    questionAttitudeEquals?: GetAllSurveyQuestionsQuestionAttitudeEqualsEnum;
    questionAttitudeNotEquals?: GetAllSurveyQuestionsQuestionAttitudeNotEqualsEnum;
    questionAttitudeSpecified?: boolean;
    questionAttitudeIn?: Array<GetAllSurveyQuestionsQuestionAttitudeInEnum>;
    questionAttitudeNotIn?: Array<GetAllSurveyQuestionsQuestionAttitudeNotInEnum>;
    maxScoreGreaterThan?: number;
    maxScoreLessThan?: number;
    maxScoreGreaterThanOrEqual?: number;
    maxScoreLessThanOrEqual?: number;
    maxScoreEquals?: number;
    maxScoreNotEquals?: number;
    maxScoreSpecified?: boolean;
    maxScoreIn?: Array<number>;
    maxScoreNotIn?: Array<number>;
    surveyQuestionResponseIdGreaterThan?: number;
    surveyQuestionResponseIdLessThan?: number;
    surveyQuestionResponseIdGreaterThanOrEqual?: number;
    surveyQuestionResponseIdLessThanOrEqual?: number;
    surveyQuestionResponseIdEquals?: number;
    surveyQuestionResponseIdNotEquals?: number;
    surveyQuestionResponseIdSpecified?: boolean;
    surveyQuestionResponseIdIn?: Array<number>;
    surveyQuestionResponseIdNotIn?: Array<number>;
    surveyIdGreaterThan?: number;
    surveyIdLessThan?: number;
    surveyIdGreaterThanOrEqual?: number;
    surveyIdLessThanOrEqual?: number;
    surveyIdEquals?: number;
    surveyIdNotEquals?: number;
    surveyIdSpecified?: boolean;
    surveyIdIn?: Array<number>;
    surveyIdNotIn?: Array<number>;
    companySurveyQuestionResIdGreaterThan?: number;
    companySurveyQuestionResIdLessThan?: number;
    companySurveyQuestionResIdGreaterThanOrEqual?: number;
    companySurveyQuestionResIdLessThanOrEqual?: number;
    companySurveyQuestionResIdEquals?: number;
    companySurveyQuestionResIdNotEquals?: number;
    companySurveyQuestionResIdSpecified?: boolean;
    companySurveyQuestionResIdIn?: Array<number>;
    companySurveyQuestionResIdNotIn?: Array<number>;
    distinct?: boolean;
}

export interface GetSurveyQuestionRequest {
    id: number;
}

export interface PartialUpdateSurveyQuestionRequest {
    id: number;
    surveyQuestion: SurveyQuestion;
}

export interface UpdateSurveyQuestionRequest {
    id: number;
    surveyQuestion: SurveyQuestion;
}

/**
 * 
 */
export class SurveyQuestionResourceApi extends runtime.BaseAPI {

    /**
     */
    async countSurveyQuestionsRaw(requestParameters: CountSurveyQuestionsRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.questionEnContains !== undefined) {
            queryParameters['questionEn.contains'] = requestParameters.questionEnContains;
        }

        if (requestParameters.questionEnDoesNotContain !== undefined) {
            queryParameters['questionEn.doesNotContain'] = requestParameters.questionEnDoesNotContain;
        }

        if (requestParameters.questionEnEquals !== undefined) {
            queryParameters['questionEn.equals'] = requestParameters.questionEnEquals;
        }

        if (requestParameters.questionEnNotEquals !== undefined) {
            queryParameters['questionEn.notEquals'] = requestParameters.questionEnNotEquals;
        }

        if (requestParameters.questionEnSpecified !== undefined) {
            queryParameters['questionEn.specified'] = requestParameters.questionEnSpecified;
        }

        if (requestParameters.questionEnIn) {
            queryParameters['questionEn.in'] = requestParameters.questionEnIn;
        }

        if (requestParameters.questionEnNotIn) {
            queryParameters['questionEn.notIn'] = requestParameters.questionEnNotIn;
        }

        if (requestParameters.questionItContains !== undefined) {
            queryParameters['questionIt.contains'] = requestParameters.questionItContains;
        }

        if (requestParameters.questionItDoesNotContain !== undefined) {
            queryParameters['questionIt.doesNotContain'] = requestParameters.questionItDoesNotContain;
        }

        if (requestParameters.questionItEquals !== undefined) {
            queryParameters['questionIt.equals'] = requestParameters.questionItEquals;
        }

        if (requestParameters.questionItNotEquals !== undefined) {
            queryParameters['questionIt.notEquals'] = requestParameters.questionItNotEquals;
        }

        if (requestParameters.questionItSpecified !== undefined) {
            queryParameters['questionIt.specified'] = requestParameters.questionItSpecified;
        }

        if (requestParameters.questionItIn) {
            queryParameters['questionIt.in'] = requestParameters.questionItIn;
        }

        if (requestParameters.questionItNotIn) {
            queryParameters['questionIt.notIn'] = requestParameters.questionItNotIn;
        }

        if (requestParameters.titleContains !== undefined) {
            queryParameters['title.contains'] = requestParameters.titleContains;
        }

        if (requestParameters.titleDoesNotContain !== undefined) {
            queryParameters['title.doesNotContain'] = requestParameters.titleDoesNotContain;
        }

        if (requestParameters.titleEquals !== undefined) {
            queryParameters['title.equals'] = requestParameters.titleEquals;
        }

        if (requestParameters.titleNotEquals !== undefined) {
            queryParameters['title.notEquals'] = requestParameters.titleNotEquals;
        }

        if (requestParameters.titleSpecified !== undefined) {
            queryParameters['title.specified'] = requestParameters.titleSpecified;
        }

        if (requestParameters.titleIn) {
            queryParameters['title.in'] = requestParameters.titleIn;
        }

        if (requestParameters.titleNotIn) {
            queryParameters['title.notIn'] = requestParameters.titleNotIn;
        }

        if (requestParameters.questionLeanEquals !== undefined) {
            queryParameters['questionLean.equals'] = requestParameters.questionLeanEquals;
        }

        if (requestParameters.questionLeanNotEquals !== undefined) {
            queryParameters['questionLean.notEquals'] = requestParameters.questionLeanNotEquals;
        }

        if (requestParameters.questionLeanSpecified !== undefined) {
            queryParameters['questionLean.specified'] = requestParameters.questionLeanSpecified;
        }

        if (requestParameters.questionLeanIn) {
            queryParameters['questionLean.in'] = requestParameters.questionLeanIn;
        }

        if (requestParameters.questionLeanNotIn) {
            queryParameters['questionLean.notIn'] = requestParameters.questionLeanNotIn;
        }

        if (requestParameters.questionAreaEquals !== undefined) {
            queryParameters['questionArea.equals'] = requestParameters.questionAreaEquals;
        }

        if (requestParameters.questionAreaNotEquals !== undefined) {
            queryParameters['questionArea.notEquals'] = requestParameters.questionAreaNotEquals;
        }

        if (requestParameters.questionAreaSpecified !== undefined) {
            queryParameters['questionArea.specified'] = requestParameters.questionAreaSpecified;
        }

        if (requestParameters.questionAreaIn) {
            queryParameters['questionArea.in'] = requestParameters.questionAreaIn;
        }

        if (requestParameters.questionAreaNotIn) {
            queryParameters['questionArea.notIn'] = requestParameters.questionAreaNotIn;
        }

        if (requestParameters.questionAgilityEquals !== undefined) {
            queryParameters['questionAgility.equals'] = requestParameters.questionAgilityEquals;
        }

        if (requestParameters.questionAgilityNotEquals !== undefined) {
            queryParameters['questionAgility.notEquals'] = requestParameters.questionAgilityNotEquals;
        }

        if (requestParameters.questionAgilitySpecified !== undefined) {
            queryParameters['questionAgility.specified'] = requestParameters.questionAgilitySpecified;
        }

        if (requestParameters.questionAgilityIn) {
            queryParameters['questionAgility.in'] = requestParameters.questionAgilityIn;
        }

        if (requestParameters.questionAgilityNotIn) {
            queryParameters['questionAgility.notIn'] = requestParameters.questionAgilityNotIn;
        }

        if (requestParameters.questionGrowthEquals !== undefined) {
            queryParameters['questionGrowth.equals'] = requestParameters.questionGrowthEquals;
        }

        if (requestParameters.questionGrowthNotEquals !== undefined) {
            queryParameters['questionGrowth.notEquals'] = requestParameters.questionGrowthNotEquals;
        }

        if (requestParameters.questionGrowthSpecified !== undefined) {
            queryParameters['questionGrowth.specified'] = requestParameters.questionGrowthSpecified;
        }

        if (requestParameters.questionGrowthIn) {
            queryParameters['questionGrowth.in'] = requestParameters.questionGrowthIn;
        }

        if (requestParameters.questionGrowthNotIn) {
            queryParameters['questionGrowth.notIn'] = requestParameters.questionGrowthNotIn;
        }

        if (requestParameters.questionAttitudeEquals !== undefined) {
            queryParameters['questionAttitude.equals'] = requestParameters.questionAttitudeEquals;
        }

        if (requestParameters.questionAttitudeNotEquals !== undefined) {
            queryParameters['questionAttitude.notEquals'] = requestParameters.questionAttitudeNotEquals;
        }

        if (requestParameters.questionAttitudeSpecified !== undefined) {
            queryParameters['questionAttitude.specified'] = requestParameters.questionAttitudeSpecified;
        }

        if (requestParameters.questionAttitudeIn) {
            queryParameters['questionAttitude.in'] = requestParameters.questionAttitudeIn;
        }

        if (requestParameters.questionAttitudeNotIn) {
            queryParameters['questionAttitude.notIn'] = requestParameters.questionAttitudeNotIn;
        }

        if (requestParameters.maxScoreGreaterThan !== undefined) {
            queryParameters['maxScore.greaterThan'] = requestParameters.maxScoreGreaterThan;
        }

        if (requestParameters.maxScoreLessThan !== undefined) {
            queryParameters['maxScore.lessThan'] = requestParameters.maxScoreLessThan;
        }

        if (requestParameters.maxScoreGreaterThanOrEqual !== undefined) {
            queryParameters['maxScore.greaterThanOrEqual'] = requestParameters.maxScoreGreaterThanOrEqual;
        }

        if (requestParameters.maxScoreLessThanOrEqual !== undefined) {
            queryParameters['maxScore.lessThanOrEqual'] = requestParameters.maxScoreLessThanOrEqual;
        }

        if (requestParameters.maxScoreEquals !== undefined) {
            queryParameters['maxScore.equals'] = requestParameters.maxScoreEquals;
        }

        if (requestParameters.maxScoreNotEquals !== undefined) {
            queryParameters['maxScore.notEquals'] = requestParameters.maxScoreNotEquals;
        }

        if (requestParameters.maxScoreSpecified !== undefined) {
            queryParameters['maxScore.specified'] = requestParameters.maxScoreSpecified;
        }

        if (requestParameters.maxScoreIn) {
            queryParameters['maxScore.in'] = requestParameters.maxScoreIn;
        }

        if (requestParameters.maxScoreNotIn) {
            queryParameters['maxScore.notIn'] = requestParameters.maxScoreNotIn;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThan'] = requestParameters.surveyQuestionResponseIdGreaterThan;
        }

        if (requestParameters.surveyQuestionResponseIdLessThan !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThan'] = requestParameters.surveyQuestionResponseIdLessThan;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThanOrEqual'] = requestParameters.surveyQuestionResponseIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThanOrEqual'] = requestParameters.surveyQuestionResponseIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.equals'] = requestParameters.surveyQuestionResponseIdEquals;
        }

        if (requestParameters.surveyQuestionResponseIdNotEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.notEquals'] = requestParameters.surveyQuestionResponseIdNotEquals;
        }

        if (requestParameters.surveyQuestionResponseIdSpecified !== undefined) {
            queryParameters['surveyQuestionResponseId.specified'] = requestParameters.surveyQuestionResponseIdSpecified;
        }

        if (requestParameters.surveyQuestionResponseIdIn) {
            queryParameters['surveyQuestionResponseId.in'] = requestParameters.surveyQuestionResponseIdIn;
        }

        if (requestParameters.surveyQuestionResponseIdNotIn) {
            queryParameters['surveyQuestionResponseId.notIn'] = requestParameters.surveyQuestionResponseIdNotIn;
        }

        if (requestParameters.surveyIdGreaterThan !== undefined) {
            queryParameters['surveyId.greaterThan'] = requestParameters.surveyIdGreaterThan;
        }

        if (requestParameters.surveyIdLessThan !== undefined) {
            queryParameters['surveyId.lessThan'] = requestParameters.surveyIdLessThan;
        }

        if (requestParameters.surveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyId.greaterThanOrEqual'] = requestParameters.surveyIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyIdLessThanOrEqual !== undefined) {
            queryParameters['surveyId.lessThanOrEqual'] = requestParameters.surveyIdLessThanOrEqual;
        }

        if (requestParameters.surveyIdEquals !== undefined) {
            queryParameters['surveyId.equals'] = requestParameters.surveyIdEquals;
        }

        if (requestParameters.surveyIdNotEquals !== undefined) {
            queryParameters['surveyId.notEquals'] = requestParameters.surveyIdNotEquals;
        }

        if (requestParameters.surveyIdSpecified !== undefined) {
            queryParameters['surveyId.specified'] = requestParameters.surveyIdSpecified;
        }

        if (requestParameters.surveyIdIn) {
            queryParameters['surveyId.in'] = requestParameters.surveyIdIn;
        }

        if (requestParameters.surveyIdNotIn) {
            queryParameters['surveyId.notIn'] = requestParameters.surveyIdNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-questions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countSurveyQuestions(requestParameters: CountSurveyQuestionsRequest): Promise<number> {
        const response = await this.countSurveyQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createSurveyQuestionRaw(requestParameters: CreateSurveyQuestionRequest): Promise<runtime.ApiResponse<SurveyQuestion>> {
        if (requestParameters.surveyQuestion === null || requestParameters.surveyQuestion === undefined) {
            throw new runtime.RequiredError('surveyQuestion','Required parameter requestParameters.surveyQuestion was null or undefined when calling createSurveyQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionToJSON(requestParameters.surveyQuestion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFromJSON(jsonValue));
    }

    /**
     */
    async createSurveyQuestion(requestParameters: CreateSurveyQuestionRequest): Promise<SurveyQuestion> {
        const response = await this.createSurveyQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteSurveyQuestionRaw(requestParameters: DeleteSurveyQuestionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSurveyQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSurveyQuestion(requestParameters: DeleteSurveyQuestionRequest): Promise<void> {
        await this.deleteSurveyQuestionRaw(requestParameters);
    }

    /**
     */
    async getAllSurveyQuestionsRaw(requestParameters: GetAllSurveyQuestionsRequest): Promise<runtime.ApiResponse<Array<SurveyQuestion>>> {
        const queryParameters: any = {};

        if (requestParameters.idGreaterThan !== undefined) {
            queryParameters['id.greaterThan'] = requestParameters.idGreaterThan;
        }

        if (requestParameters.idLessThan !== undefined) {
            queryParameters['id.lessThan'] = requestParameters.idLessThan;
        }

        if (requestParameters.idGreaterThanOrEqual !== undefined) {
            queryParameters['id.greaterThanOrEqual'] = requestParameters.idGreaterThanOrEqual;
        }

        if (requestParameters.idLessThanOrEqual !== undefined) {
            queryParameters['id.lessThanOrEqual'] = requestParameters.idLessThanOrEqual;
        }

        if (requestParameters.idEquals !== undefined) {
            queryParameters['id.equals'] = requestParameters.idEquals;
        }

        if (requestParameters.idNotEquals !== undefined) {
            queryParameters['id.notEquals'] = requestParameters.idNotEquals;
        }

        if (requestParameters.idSpecified !== undefined) {
            queryParameters['id.specified'] = requestParameters.idSpecified;
        }

        if (requestParameters.idIn) {
            queryParameters['id.in'] = requestParameters.idIn;
        }

        if (requestParameters.idNotIn) {
            queryParameters['id.notIn'] = requestParameters.idNotIn;
        }

        if (requestParameters.questionEnContains !== undefined) {
            queryParameters['questionEn.contains'] = requestParameters.questionEnContains;
        }

        if (requestParameters.questionEnDoesNotContain !== undefined) {
            queryParameters['questionEn.doesNotContain'] = requestParameters.questionEnDoesNotContain;
        }

        if (requestParameters.questionEnEquals !== undefined) {
            queryParameters['questionEn.equals'] = requestParameters.questionEnEquals;
        }

        if (requestParameters.questionEnNotEquals !== undefined) {
            queryParameters['questionEn.notEquals'] = requestParameters.questionEnNotEquals;
        }

        if (requestParameters.questionEnSpecified !== undefined) {
            queryParameters['questionEn.specified'] = requestParameters.questionEnSpecified;
        }

        if (requestParameters.questionEnIn) {
            queryParameters['questionEn.in'] = requestParameters.questionEnIn;
        }

        if (requestParameters.questionEnNotIn) {
            queryParameters['questionEn.notIn'] = requestParameters.questionEnNotIn;
        }

        if (requestParameters.questionItContains !== undefined) {
            queryParameters['questionIt.contains'] = requestParameters.questionItContains;
        }

        if (requestParameters.questionItDoesNotContain !== undefined) {
            queryParameters['questionIt.doesNotContain'] = requestParameters.questionItDoesNotContain;
        }

        if (requestParameters.questionItEquals !== undefined) {
            queryParameters['questionIt.equals'] = requestParameters.questionItEquals;
        }

        if (requestParameters.questionItNotEquals !== undefined) {
            queryParameters['questionIt.notEquals'] = requestParameters.questionItNotEquals;
        }

        if (requestParameters.questionItSpecified !== undefined) {
            queryParameters['questionIt.specified'] = requestParameters.questionItSpecified;
        }

        if (requestParameters.questionItIn) {
            queryParameters['questionIt.in'] = requestParameters.questionItIn;
        }

        if (requestParameters.questionItNotIn) {
            queryParameters['questionIt.notIn'] = requestParameters.questionItNotIn;
        }

        if (requestParameters.titleContains !== undefined) {
            queryParameters['title.contains'] = requestParameters.titleContains;
        }

        if (requestParameters.titleDoesNotContain !== undefined) {
            queryParameters['title.doesNotContain'] = requestParameters.titleDoesNotContain;
        }

        if (requestParameters.titleEquals !== undefined) {
            queryParameters['title.equals'] = requestParameters.titleEquals;
        }

        if (requestParameters.titleNotEquals !== undefined) {
            queryParameters['title.notEquals'] = requestParameters.titleNotEquals;
        }

        if (requestParameters.titleSpecified !== undefined) {
            queryParameters['title.specified'] = requestParameters.titleSpecified;
        }

        if (requestParameters.titleIn) {
            queryParameters['title.in'] = requestParameters.titleIn;
        }

        if (requestParameters.titleNotIn) {
            queryParameters['title.notIn'] = requestParameters.titleNotIn;
        }

        if (requestParameters.questionLeanEquals !== undefined) {
            queryParameters['questionLean.equals'] = requestParameters.questionLeanEquals;
        }

        if (requestParameters.questionLeanNotEquals !== undefined) {
            queryParameters['questionLean.notEquals'] = requestParameters.questionLeanNotEquals;
        }

        if (requestParameters.questionLeanSpecified !== undefined) {
            queryParameters['questionLean.specified'] = requestParameters.questionLeanSpecified;
        }

        if (requestParameters.questionLeanIn) {
            queryParameters['questionLean.in'] = requestParameters.questionLeanIn;
        }

        if (requestParameters.questionLeanNotIn) {
            queryParameters['questionLean.notIn'] = requestParameters.questionLeanNotIn;
        }

        if (requestParameters.questionAreaEquals !== undefined) {
            queryParameters['questionArea.equals'] = requestParameters.questionAreaEquals;
        }

        if (requestParameters.questionAreaNotEquals !== undefined) {
            queryParameters['questionArea.notEquals'] = requestParameters.questionAreaNotEquals;
        }

        if (requestParameters.questionAreaSpecified !== undefined) {
            queryParameters['questionArea.specified'] = requestParameters.questionAreaSpecified;
        }

        if (requestParameters.questionAreaIn) {
            queryParameters['questionArea.in'] = requestParameters.questionAreaIn;
        }

        if (requestParameters.questionAreaNotIn) {
            queryParameters['questionArea.notIn'] = requestParameters.questionAreaNotIn;
        }

        if (requestParameters.questionAgilityEquals !== undefined) {
            queryParameters['questionAgility.equals'] = requestParameters.questionAgilityEquals;
        }

        if (requestParameters.questionAgilityNotEquals !== undefined) {
            queryParameters['questionAgility.notEquals'] = requestParameters.questionAgilityNotEquals;
        }

        if (requestParameters.questionAgilitySpecified !== undefined) {
            queryParameters['questionAgility.specified'] = requestParameters.questionAgilitySpecified;
        }

        if (requestParameters.questionAgilityIn) {
            queryParameters['questionAgility.in'] = requestParameters.questionAgilityIn;
        }

        if (requestParameters.questionAgilityNotIn) {
            queryParameters['questionAgility.notIn'] = requestParameters.questionAgilityNotIn;
        }

        if (requestParameters.questionGrowthEquals !== undefined) {
            queryParameters['questionGrowth.equals'] = requestParameters.questionGrowthEquals;
        }

        if (requestParameters.questionGrowthNotEquals !== undefined) {
            queryParameters['questionGrowth.notEquals'] = requestParameters.questionGrowthNotEquals;
        }

        if (requestParameters.questionGrowthSpecified !== undefined) {
            queryParameters['questionGrowth.specified'] = requestParameters.questionGrowthSpecified;
        }

        if (requestParameters.questionGrowthIn) {
            queryParameters['questionGrowth.in'] = requestParameters.questionGrowthIn;
        }

        if (requestParameters.questionGrowthNotIn) {
            queryParameters['questionGrowth.notIn'] = requestParameters.questionGrowthNotIn;
        }

        if (requestParameters.questionAttitudeEquals !== undefined) {
            queryParameters['questionAttitude.equals'] = requestParameters.questionAttitudeEquals;
        }

        if (requestParameters.questionAttitudeNotEquals !== undefined) {
            queryParameters['questionAttitude.notEquals'] = requestParameters.questionAttitudeNotEquals;
        }

        if (requestParameters.questionAttitudeSpecified !== undefined) {
            queryParameters['questionAttitude.specified'] = requestParameters.questionAttitudeSpecified;
        }

        if (requestParameters.questionAttitudeIn) {
            queryParameters['questionAttitude.in'] = requestParameters.questionAttitudeIn;
        }

        if (requestParameters.questionAttitudeNotIn) {
            queryParameters['questionAttitude.notIn'] = requestParameters.questionAttitudeNotIn;
        }

        if (requestParameters.maxScoreGreaterThan !== undefined) {
            queryParameters['maxScore.greaterThan'] = requestParameters.maxScoreGreaterThan;
        }

        if (requestParameters.maxScoreLessThan !== undefined) {
            queryParameters['maxScore.lessThan'] = requestParameters.maxScoreLessThan;
        }

        if (requestParameters.maxScoreGreaterThanOrEqual !== undefined) {
            queryParameters['maxScore.greaterThanOrEqual'] = requestParameters.maxScoreGreaterThanOrEqual;
        }

        if (requestParameters.maxScoreLessThanOrEqual !== undefined) {
            queryParameters['maxScore.lessThanOrEqual'] = requestParameters.maxScoreLessThanOrEqual;
        }

        if (requestParameters.maxScoreEquals !== undefined) {
            queryParameters['maxScore.equals'] = requestParameters.maxScoreEquals;
        }

        if (requestParameters.maxScoreNotEquals !== undefined) {
            queryParameters['maxScore.notEquals'] = requestParameters.maxScoreNotEquals;
        }

        if (requestParameters.maxScoreSpecified !== undefined) {
            queryParameters['maxScore.specified'] = requestParameters.maxScoreSpecified;
        }

        if (requestParameters.maxScoreIn) {
            queryParameters['maxScore.in'] = requestParameters.maxScoreIn;
        }

        if (requestParameters.maxScoreNotIn) {
            queryParameters['maxScore.notIn'] = requestParameters.maxScoreNotIn;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThan !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThan'] = requestParameters.surveyQuestionResponseIdGreaterThan;
        }

        if (requestParameters.surveyQuestionResponseIdLessThan !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThan'] = requestParameters.surveyQuestionResponseIdLessThan;
        }

        if (requestParameters.surveyQuestionResponseIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.greaterThanOrEqual'] = requestParameters.surveyQuestionResponseIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdLessThanOrEqual !== undefined) {
            queryParameters['surveyQuestionResponseId.lessThanOrEqual'] = requestParameters.surveyQuestionResponseIdLessThanOrEqual;
        }

        if (requestParameters.surveyQuestionResponseIdEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.equals'] = requestParameters.surveyQuestionResponseIdEquals;
        }

        if (requestParameters.surveyQuestionResponseIdNotEquals !== undefined) {
            queryParameters['surveyQuestionResponseId.notEquals'] = requestParameters.surveyQuestionResponseIdNotEquals;
        }

        if (requestParameters.surveyQuestionResponseIdSpecified !== undefined) {
            queryParameters['surveyQuestionResponseId.specified'] = requestParameters.surveyQuestionResponseIdSpecified;
        }

        if (requestParameters.surveyQuestionResponseIdIn) {
            queryParameters['surveyQuestionResponseId.in'] = requestParameters.surveyQuestionResponseIdIn;
        }

        if (requestParameters.surveyQuestionResponseIdNotIn) {
            queryParameters['surveyQuestionResponseId.notIn'] = requestParameters.surveyQuestionResponseIdNotIn;
        }

        if (requestParameters.surveyIdGreaterThan !== undefined) {
            queryParameters['surveyId.greaterThan'] = requestParameters.surveyIdGreaterThan;
        }

        if (requestParameters.surveyIdLessThan !== undefined) {
            queryParameters['surveyId.lessThan'] = requestParameters.surveyIdLessThan;
        }

        if (requestParameters.surveyIdGreaterThanOrEqual !== undefined) {
            queryParameters['surveyId.greaterThanOrEqual'] = requestParameters.surveyIdGreaterThanOrEqual;
        }

        if (requestParameters.surveyIdLessThanOrEqual !== undefined) {
            queryParameters['surveyId.lessThanOrEqual'] = requestParameters.surveyIdLessThanOrEqual;
        }

        if (requestParameters.surveyIdEquals !== undefined) {
            queryParameters['surveyId.equals'] = requestParameters.surveyIdEquals;
        }

        if (requestParameters.surveyIdNotEquals !== undefined) {
            queryParameters['surveyId.notEquals'] = requestParameters.surveyIdNotEquals;
        }

        if (requestParameters.surveyIdSpecified !== undefined) {
            queryParameters['surveyId.specified'] = requestParameters.surveyIdSpecified;
        }

        if (requestParameters.surveyIdIn) {
            queryParameters['surveyId.in'] = requestParameters.surveyIdIn;
        }

        if (requestParameters.surveyIdNotIn) {
            queryParameters['surveyId.notIn'] = requestParameters.surveyIdNotIn;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThan !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThan'] = requestParameters.companySurveyQuestionResIdGreaterThan;
        }

        if (requestParameters.companySurveyQuestionResIdLessThan !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThan'] = requestParameters.companySurveyQuestionResIdLessThan;
        }

        if (requestParameters.companySurveyQuestionResIdGreaterThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.greaterThanOrEqual'] = requestParameters.companySurveyQuestionResIdGreaterThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdLessThanOrEqual !== undefined) {
            queryParameters['companySurveyQuestionResId.lessThanOrEqual'] = requestParameters.companySurveyQuestionResIdLessThanOrEqual;
        }

        if (requestParameters.companySurveyQuestionResIdEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.equals'] = requestParameters.companySurveyQuestionResIdEquals;
        }

        if (requestParameters.companySurveyQuestionResIdNotEquals !== undefined) {
            queryParameters['companySurveyQuestionResId.notEquals'] = requestParameters.companySurveyQuestionResIdNotEquals;
        }

        if (requestParameters.companySurveyQuestionResIdSpecified !== undefined) {
            queryParameters['companySurveyQuestionResId.specified'] = requestParameters.companySurveyQuestionResIdSpecified;
        }

        if (requestParameters.companySurveyQuestionResIdIn) {
            queryParameters['companySurveyQuestionResId.in'] = requestParameters.companySurveyQuestionResIdIn;
        }

        if (requestParameters.companySurveyQuestionResIdNotIn) {
            queryParameters['companySurveyQuestionResId.notIn'] = requestParameters.companySurveyQuestionResIdNotIn;
        }

        if (requestParameters.distinct !== undefined) {
            queryParameters['distinct'] = requestParameters.distinct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyQuestionFromJSON));
    }

    /**
     */
    async getAllSurveyQuestions(requestParameters: GetAllSurveyQuestionsRequest): Promise<Array<SurveyQuestion>> {
        const response = await this.getAllSurveyQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSurveyQuestionRaw(requestParameters: GetSurveyQuestionRequest): Promise<runtime.ApiResponse<SurveyQuestion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSurveyQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/survey-questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFromJSON(jsonValue));
    }

    /**
     */
    async getSurveyQuestion(requestParameters: GetSurveyQuestionRequest): Promise<SurveyQuestion> {
        const response = await this.getSurveyQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateSurveyQuestionRaw(requestParameters: PartialUpdateSurveyQuestionRequest): Promise<runtime.ApiResponse<SurveyQuestion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateSurveyQuestion.');
        }

        if (requestParameters.surveyQuestion === null || requestParameters.surveyQuestion === undefined) {
            throw new runtime.RequiredError('surveyQuestion','Required parameter requestParameters.surveyQuestion was null or undefined when calling partialUpdateSurveyQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionToJSON(requestParameters.surveyQuestion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateSurveyQuestion(requestParameters: PartialUpdateSurveyQuestionRequest): Promise<SurveyQuestion> {
        const response = await this.partialUpdateSurveyQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateSurveyQuestionRaw(requestParameters: UpdateSurveyQuestionRequest): Promise<runtime.ApiResponse<SurveyQuestion>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSurveyQuestion.');
        }

        if (requestParameters.surveyQuestion === null || requestParameters.surveyQuestion === undefined) {
            throw new runtime.RequiredError('surveyQuestion','Required parameter requestParameters.surveyQuestion was null or undefined when calling updateSurveyQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/survey-questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyQuestionToJSON(requestParameters.surveyQuestion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFromJSON(jsonValue));
    }

    /**
     */
    async updateSurveyQuestion(requestParameters: UpdateSurveyQuestionRequest): Promise<SurveyQuestion> {
        const response = await this.updateSurveyQuestionRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAreaEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAreaNotEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAreaInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAreaNotInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAgilityEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAgilityNotEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAgilityInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAgilityNotInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionGrowthEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionGrowthNotEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionGrowthInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionGrowthNotInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAttitudeEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAttitudeNotEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAttitudeInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum CountSurveyQuestionsQuestionAttitudeNotInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAreaEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAreaNotEqualsEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAreaInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAreaNotInEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAgilityEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAgilityNotEqualsEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAgilityInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAgilityNotInEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionGrowthEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionGrowthNotEqualsEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionGrowthInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionGrowthNotInEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAttitudeEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAttitudeNotEqualsEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAttitudeInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllSurveyQuestionsQuestionAttitudeNotInEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}
