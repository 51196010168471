/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValoreNominaleConferimenti
 */
export interface ValoreNominaleConferimenti {
    /**
     * 
     * @type {string}
     * @memberof ValoreNominaleConferimenti
     */
    valuta?: string;
    /**
     * 
     * @type {string}
     * @memberof ValoreNominaleConferimenti
     */
    ammontare?: string;
    /**
     * 
     * @type {string}
     * @memberof ValoreNominaleConferimenti
     */
    ammontareConvertitoInEuro?: string;
    /**
     * 
     * @type {string}
     * @memberof ValoreNominaleConferimenti
     */
    cvaluta?: string;
}

export function ValoreNominaleConferimentiFromJSON(json: any): ValoreNominaleConferimenti {
    return ValoreNominaleConferimentiFromJSONTyped(json, false);
}

export function ValoreNominaleConferimentiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValoreNominaleConferimenti {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valuta': !exists(json, 'valuta') ? undefined : json['valuta'],
        'ammontare': !exists(json, 'ammontare') ? undefined : json['ammontare'],
        'ammontareConvertitoInEuro': !exists(json, 'ammontareConvertitoInEuro') ? undefined : json['ammontareConvertitoInEuro'],
        'cvaluta': !exists(json, 'cvaluta') ? undefined : json['cvaluta'],
    };
}

export function ValoreNominaleConferimentiToJSON(value?: ValoreNominaleConferimenti | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valuta': value.valuta,
        'ammontare': value.ammontare,
        'ammontareConvertitoInEuro': value.ammontareConvertitoInEuro,
        'cvaluta': value.cvaluta,
    };
}


