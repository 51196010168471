/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
} from '../models';

export interface CreateNotificationRequest {
    notification: Notification;
}

export interface DeleteNotificationRequest {
    id: number;
}

export interface GetNotificationRequest {
    id: number;
}

export interface PartialUpdateNotificationRequest {
    id: number;
    notification: Notification;
}

export interface UpdateNotificationRequest {
    id: number;
    notification: Notification;
}

/**
 * 
 */
export class NotificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async createNotificationRaw(requestParameters: CreateNotificationRequest): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling createNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async createNotification(requestParameters: CreateNotificationRequest): Promise<Notification> {
        const response = await this.createNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest): Promise<void> {
        await this.deleteNotificationRaw(requestParameters);
    }

    /**
     */
    async getAllNotificationsRaw(): Promise<runtime.ApiResponse<Array<Notification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     */
    async getAllNotifications(): Promise<Array<Notification>> {
        const response = await this.getAllNotificationsRaw();
        return await response.value();
    }

    /**
     */
    async getNotificationRaw(requestParameters: GetNotificationRequest): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async getNotification(requestParameters: GetNotificationRequest): Promise<Notification> {
        const response = await this.getNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateNotificationRaw(requestParameters: PartialUpdateNotificationRequest): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateNotification.');
        }

        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling partialUpdateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateNotification(requestParameters: PartialUpdateNotificationRequest): Promise<Notification> {
        const response = await this.partialUpdateNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest): Promise<runtime.ApiResponse<Notification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotification.');
        }

        if (requestParameters.notification === null || requestParameters.notification === undefined) {
            throw new runtime.RequiredError('notification','Required parameter requestParameters.notification was null or undefined when calling updateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationToJSON(requestParameters.notification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationFromJSON(jsonValue));
    }

    /**
     */
    async updateNotification(requestParameters: UpdateNotificationRequest): Promise<Notification> {
        const response = await this.updateNotificationRaw(requestParameters);
        return await response.value();
    }

}
