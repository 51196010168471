/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttoTrascrizioni,
    AttoTrascrizioniFromJSON,
    AttoTrascrizioniFromJSONTyped,
    AttoTrascrizioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttiTrascrizioni
 */
export interface AttiTrascrizioni {
    /**
     * 
     * @type {Array<AttoTrascrizioni>}
     * @memberof AttiTrascrizioni
     */
    attoTrascrizioni: Array<AttoTrascrizioni>;
}

export function AttiTrascrizioniFromJSON(json: any): AttiTrascrizioni {
    return AttiTrascrizioniFromJSONTyped(json, false);
}

export function AttiTrascrizioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttiTrascrizioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attoTrascrizioni': ((json['attoTrascrizioni'] as Array<any>).map(AttoTrascrizioniFromJSON)),
    };
}

export function AttiTrascrizioniToJSON(value?: AttiTrascrizioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attoTrascrizioni': ((value.attoTrascrizioni as Array<any>).map(AttoTrascrizioniToJSON)),
    };
}


