/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IscrizioneRs
 */
export interface IscrizioneRs {
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    localitaTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    provinciaTribunale?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    nfascicolo?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    nrs?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRs
     */
    nvolume?: string;
}

export function IscrizioneRsFromJSON(json: any): IscrizioneRs {
    return IscrizioneRsFromJSONTyped(json, false);
}

export function IscrizioneRsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IscrizioneRs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'localitaTribunale': !exists(json, 'localitaTribunale') ? undefined : json['localitaTribunale'],
        'provinciaTribunale': !exists(json, 'provinciaTribunale') ? undefined : json['provinciaTribunale'],
        'nfascicolo': !exists(json, 'nfascicolo') ? undefined : json['nfascicolo'],
        'nrs': !exists(json, 'nrs') ? undefined : json['nrs'],
        'nvolume': !exists(json, 'nvolume') ? undefined : json['nvolume'],
    };
}

export function IscrizioneRsToJSON(value?: IscrizioneRs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtIscrizione': value.dtIscrizione,
        'localitaTribunale': value.localitaTribunale,
        'provinciaTribunale': value.provinciaTribunale,
        'nfascicolo': value.nfascicolo,
        'nrs': value.nrs,
        'nvolume': value.nvolume,
    };
}


