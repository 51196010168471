import { LinearGradient } from "expo-linear-gradient";
import React, {useCallback, useEffect, useState} from "react";
import { StatusBar } from 'expo-status-bar';
import { ScrollView, StyleSheet, Text, View, TouchableOpacity, Keyboard } from "react-native";
import { NoticeInterface, Preference, BalanceObj, RootStackScreenProps, State } from "../../../types";
import Icon from "../Tools/Icon";
import BusinessRating from "./Component/BusinessRating/BusinessRating";
import BusinessData from "./Component/BusinessData";
import HykeeScore from "./Component/HykeeScore";
import ChamberData from "./Component/ChamberData/ChamberData";
import FinancialStatements from "./Component/FinancialStatements/FinancialStatements";
import MarketCompetitors from "./Component/MarketCompetitors/MarketCompetitors";

import FinancialScore from "./Component/FinancialScore";
import {
  Company,
  CompanyData,
  CompanyLinkRequestCurrentStateEnum,
  CompanySurvey, Insight,
  UserExtendedTypeEnum
} from "../../services/apis/generated";
import {
  customCompanyDataResourceApi,
  customCompanyLinkRequestResourceApi,
  customCompanyResourceApi,
  customCompanySurveyResourceApi, customInsightResourceApi,
  customSurveyResourceApi,
  customUserCompanyResourceApi,
} from "../../services/apis/ApiConfiguration";
import { AlertHelper } from "../../helpers/AlertHelper";
import { Logger } from "../../helpers/Logger";
import { useFocusEffect } from "@react-navigation/native";
import { NavigationHelper } from "@helpers/NavigationHelper";
import SharedContext from "../../navigation/SharedContext";
import Collapsible from "react-native-collapsible";
import { BackgroundColorButtonDarkLight, BackgroundColorButtonGreen } from "@components/ColorTheme";
import BusinessScore from "./Component/BusinessScore";
import moment from "moment";
import Colors from "../../../constants/Colors";
import hykeeStyle from "@components/hykeeStyle";
import { BaseAPIManager } from "../../services/model/BaseAPIManager";
import InsightNews from "./Component/InsightNews";
import DigitalPositioning from "./Component/StrategicPositioning/DigitalPositioning";


export default function DashboardCompany({ route, navigation }: RootStackScreenProps<'DashboardCompany'>) {

  const [insights, setInsights] = React.useState<Insight[]>([]);

  const [onMyCompanies, setOnMyCompanies] = useState<boolean>();
  const [selectedCompanyLastData, setSelectedCompanyLastData] = useState<CompanyData>({});
  const [balanceObj, setBalanceObj] = useState<BalanceObj>();
  const [corporateName, setCorporateName] = useState<string>('');
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [surveyDate, setSurveyDate] = useState<any>()
  const [idCompany, setId] = useState<any>()
  const sharedContext = React.useContext(SharedContext);


  const [company, setCompany] = React.useState<Company>();

  const myroute: State = {//MOCK
    webSite: null,
  }

  function getInsight() {
    customInsightResourceApi.getAllInsightForLoggedUser({company: sharedContext.selectedCompany!})
        .then((insightList: Insight[]) => {
          insightList.sort((a, b) => b.dateOfPublication?.valueOf()! - a.dateOfPublication?.valueOf()!)
          setInsights(insightList);
        }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(() => {
    if (sharedContext.selectedCompany == sharedContext.myCompanySelected) {
      getInsight()
    }
  }, [sharedContext.myCompanySelected, sharedContext.selectedCompany]);


  useFocusEffect(
    useCallback(() => {
      Keyboard.dismiss()
      if (!sharedContext.who)
        return;

      let corporateName = sharedContext.selectedCompany?.name || '';

      //setCorporateName(corporateName);

      setupInit();

      return () => { // This is the cleanup function, called when the component is unmounted.
      }

    }, [route, navigation, sharedContext.who])
  );


  const setupInit = (): void => {
    BaseAPIManager.contextLoadingCallback(true);
    customUserCompanyResourceApi.getAllMyUserCompanies().then(response => {
      let mappedCompanies = response.map(uc => uc.company!);
      sharedContext.setMyCompanies(mappedCompanies);
      let vatNumber = mappedCompanies[0].vatNumber;
      searchCompanyByVatNumber(vatNumber!, mappedCompanies);
      setCompany(mappedCompanies[0])
      setCorporateName(mappedCompanies[0].name!)
      setId(mappedCompanies[0].id)
      const getCompanySurvey = (): void => {
        customCompanySurveyResourceApi.getAllCompanySurveysFilter({ customCompanySurveyCriteria: { companyId: { equals: mappedCompanies[0].id } } }).then((res) => {
          // setCompanySurveys(res);
          getSurvey(res);
          // setSurveyDate(moment(res[0].updateDate).format("DD/MM/yyyy"));
        })
      }
      getCompanySurvey()

    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  /**
   * The function is used to import the company information using the vatNumber (BE: importCompany).
   * @param vatNumber
   * @param mappedCompanies
   */
  const searchCompanyByVatNumber = (vatNumber: string, mappedCompanies: Company[]): void => {
    customCompanyResourceApi.searchDashboardCompanyFull({ searchCompanyFullDTO: { vatNumber: vatNumber } }).then(res => {
      if (mappedCompanies != undefined) {
        let company = mappedCompanies[0];
        getLastCompanyData(company.id!, mappedCompanies);
        getMyCompanyConsultant(company.id!);
      }
    })
  }

  const getLastCompanyData = (id: number, allMyCompanies?: Company[], search?: boolean): void => {
    customCompanyDataResourceApi.getLastCompanyDataByCompany({ companyId: id }).then(res => {
      checkMyCompanies(res.company?.vatNumber!, allMyCompanies ? allMyCompanies : sharedContext.myCompanies!);
      sharedContext.setSelectedCompany(res.company);
      setCorporateName(res.company?.name!);
      sharedContext.setSelectedCompanyLastData(res as CompanyData);
      setSelectedCompanyLastData(res as CompanyData);
      if (!search) {
        sharedContext.setMySelectedCompanyLastData(res as CompanyData);
      }
      BaseAPIManager.contextLoadingCallback(false);
    }).catch(error => Logger.warn('Dashboard', `Company data not found for company ${id}`));
  }

  const getMyCompanyConsultant = (id: number): void => {
    customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByCompany({ id: id }).then(res => {

      sharedContext.setMyCompaniesConsultantRequest(res);
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }


  const checkMyCompanies = (vatNumber: string, allMyCompanies: Company[]): void => {
    for (let company of allMyCompanies) {
      if (company.vatNumber == vatNumber)
        return setOnMyCompanies(true);
    }
    return setOnMyCompanies(false);
  }

  const goToSurvey = (): void => {
    NavigationHelper.navigateToSurvey(navigation, sharedContext.selectedCompany);
  }

  const onMyLinkedCompanies = (): boolean => {
    return sharedContext.who == UserExtendedTypeEnum.Consultant
      && sharedContext.myCompaniesConsultedRequest?.find((companyLinkRequest) => companyLinkRequest.company?.vatNumber == sharedContext.selectedCompany?.vatNumber
        && companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Accepted) != undefined
  }

  function getSurvey(companySurvey: Array<CompanySurvey>) {
    customSurveyResourceApi.getAllSurveysFiltered({ customSurveyCriteria: {} }).then((surveyList) => {
      let surveyCompletedList: any[] = surveyList;
      for (let survey of surveyCompletedList) {
        for (let company of companySurvey) {
          if (survey.id == company.survey?.id) {
            survey.completed = company.completed;
            if (company.updateDate != undefined) {
              setSurveyDate(moment(company.updateDate).format("DD/MM/yyyy"));
            }
          }
        }
      }
    })
  }

  function setLastBalanceCallback(financialStatementProps: any): void {
    setBalanceObj(financialStatementProps);
  }


  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
      onLayout={() => Keyboard.dismiss()}
    >
      <StatusBar style="auto" />
      <View style={[hykeeStyle.container,]} >
        <View style={[hykeeStyle.directionRow, { justifyContent: 'space-between' }]}>
          <View>
            <Text style={hykeeStyle.title}>
              {corporateName + "  "}
            </Text>
          </View>
          <View style={{ justifyContent: "center", alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>

            {sharedContext.myCompanies && sharedContext.myCompanies?.length > 1 && onMyCompanies &&
              <TouchableOpacity style={{ paddingHorizontal: 20 }} onPress={() => setCollapsed(!collapsed)}>
                <Icon name="angle-down" size={24} color={'#00CC83'} />
              </TouchableOpacity>
            }
          </View>
        </View>
        <Collapsible
          duration={300}
          collapsed={collapsed}
          style={styles.containerCollapse}
        >
          {sharedContext.myCompanies && sharedContext.myCompanies?.map((company) => {
            return (
              <TouchableOpacity
                key={company.id}
                style={styles.containerTextCollapse}
                onPress={() => {
                  setCollapsed(!collapsed)
                  sharedContext.setSelectedCompany(company);
                  sharedContext.setMyCompanySelected(company);
                  getLastCompanyData(company.id!, sharedContext.myCompanies!)
                  NavigationHelper.navigateToDashboardCompany(navigation, company);
                }}>
                <Text style={styles.textCollapse}>
                  {company.name + "  "}
                </Text>
              </TouchableOpacity>
            )
          })}
        </Collapsible>
      </View>


      <ScrollView style={hykeeStyle.containerScroll} nestedScrollEnabled>
        {sharedContext.selectedCompanyLastData &&
          <BusinessData lastCompanyData={selectedCompanyLastData} balanceObject={balanceObj!} />
        }
        {sharedContext.selectedCompany &&
          <>
            <HykeeScore onMyCompanies={onMyCompanies!} navigation={navigation} lastCompanyData={selectedCompanyLastData} />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <BusinessScore onMyCompanies={onMyCompanies!} surveyDate={surveyDate} navigation={navigation} />
              <FinancialScore company={company!} onMyCompanies={onMyCompanies!} navigation={navigation} lastCompanyData={selectedCompanyLastData} balanceObject={balanceObj!} />
            </View>
            <View>
              {(sharedContext.who == UserExtendedTypeEnum.Company && onMyCompanies) && <InsightNews insights={insights} navigation={navigation} />}
              {//who == UserExtendedTypeEnum.Company && <InsightsNews />
              }
              {/* {(onMyCompanies || onMyLinkedCompanies()) && <Highlights />}
              {(onMyCompanies || onMyLinkedCompanies()) && <Outlook />} */}
              {company && <FinancialStatements company={company!} callback={setLastBalanceCallback} />}
              {(onMyCompanies || onMyLinkedCompanies()) && <BusinessRating onMyCompanies={onMyCompanies!} goToSurvey={() => onMyCompanies ? goToSurvey() : null} />}
              {(onMyCompanies || onMyLinkedCompanies()) && <MarketCompetitors company={company!} competitors={sharedContext.myMarketCompetitors} goToCompany={(company) => { NavigationHelper.navigateToDashboardSavedCompany(navigation, company.id!) }} />}
              {//(onMyCompanies || onMyLinkedCompanies()) && <StrategicPositioning myroute={myroute} />
              }
              {(onMyCompanies || onMyLinkedCompanies()) && <DigitalPositioning />}
              <ChamberData navigation={navigation} companyDataId={sharedContext.selectedCompanyLastData?.id} />
            </View>
          </>}
      </ScrollView>
    </LinearGradient>
  );

}


const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10
  },

  goBack: {
    marginRight: 30
  },
  header: {
    padding: 20,
    backgroundColor: '#fff',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
  },
  refreshCompanyData: {
    backgroundColor: BackgroundColorButtonGreen(),
    color: 'white',
    padding: 10,
    minWidth: 150,
    textAlign: 'center'
  },
  contentAccordion: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  labelAzienda: {
    marginTop: 50,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  label: {
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30,
    borderRadius: 13,
    flex: 1,
  },
  textSearch: {
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  containerTitle: {
    marginTop: 50,
    marginHorizontal: 20
  },
  iconCollapseContainer: {
  },
  containerTextCollapse: {
    backgroundColor: BackgroundColorButtonDarkLight(),
    marginBottom: 10,
    borderRadius: 5,
  },
  containerCollapse: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  textCollapse: {
    textAlign: 'left',
    fontSize: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontFamily: 'poppins',
    color: '#00CC83',
  },
  textLabel: {
    textAlign: 'left',
    marginLeft: 15,
    fontFamily: 'poppins',
  },
  containerSA: {
    flex: 1,
    marginHorizontal: 20,
  },
  containerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 50,
    padding: 20,
    borderRadius: 13,
  },
  imageButton: {
    marginTop: 40,
    backgroundColor: '#0F8682',
    marginBottom: 30,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white',
  },
  tooltipText: {
      fontFamily: "poppins",
      margin: 5,
      textAlign: 'center',
      fontWeight: '300',
      color: "white"
  }

});



