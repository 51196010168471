/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LicenzaAutorizzazione
 */
export interface LicenzaAutorizzazione {
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    autoritaRilascio?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenzaAutorizzazione
     */
    cautoritaRilascio?: string;
}

export function LicenzaAutorizzazioneFromJSON(json: any): LicenzaAutorizzazione {
    return LicenzaAutorizzazioneFromJSONTyped(json, false);
}

export function LicenzaAutorizzazioneFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenzaAutorizzazione {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoritaRilascio': !exists(json, 'autoritaRilascio') ? undefined : json['autoritaRilascio'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'cautoritaRilascio': !exists(json, 'cautoritaRilascio') ? undefined : json['cautoritaRilascio'],
    };
}

export function LicenzaAutorizzazioneToJSON(value?: LicenzaAutorizzazione | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoritaRilascio': value.autoritaRilascio,
        'n': value.n,
        'dtIscrizione': value.dtIscrizione,
        'c': value.c,
        'tipo': value.tipo,
        'cautoritaRilascio': value.cautoritaRilascio,
    };
}


