// 
// 
//
import React from 'react';
import { StyleSheet, View, Text, Platform } from 'react-native';
import { i18n } from '../../../i18n/i18n'

import DataTop3 from './DataTop3';



export default function ResultResearch() {

  let value = Math.floor(Math.random() * 1000) + 1

  const tab1 = [
    { name: 'Ferrero S.P.A', province: 'BO', r: '12.988', e: '45.000', p: '75.000' },
    { name: 'Agrifood Srl', province: 'BO', r: '12.988', e: '45.000', p: '75.000' },
    { name: 'Firen Srl', province: 'RE', r: '12.988', e: '45.000', p: '75.000' },
  ]
  const tab2 = [
    { name: 'Segafredo', province: 'BO', r: '12.988', e: '45.000', p: '75.000' },
    { name: 'Ranucci', province: 'BO', r: '11.456', e: '34.000', p: '12.000' },
    { name: 'Ginetti', province: 'RE', r: '9.228', e: '4.700', p: '11.030' },
  ]
  return (
    <View style={{ flexDirection: 'column' }}>
      <Text style={styles.resultTitle}>
        {value + ' ' + i18n.t('screens.research.companies')}
      </Text>
      <DataTop3 top3={tab1} />
      <DataTop3 top3={tab2} />
    </View>
  )
}


const styles = StyleSheet.create({
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  resultTitle: {
    marginVertical: 10,
    alignSelf: 'flex-start',
    fontSize: 17,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    color: 'white',
    fontFamily: 'poppins',
  },
  containerResearch: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  labelAzienda: {
    padding: 10,
    flex: 1,
    marginVertical: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 13,
    fontFamily: 'gotham',
  },
  goBack: {
    marginRight: 20
  },
  button: {
    flex: 0.3,
    backgroundColor: '#222222',
    padding: 5,
    marginVertical: 10,
    justifyContent: 'center',
    borderRadius: 5,
    alignItems: 'center'
  },
  titleResearch: {
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 8,
    fontFamily: 'poppins',
    color: 'white'
  },
  textInput: {
    flex: 0.7,
    backgroundColor: '#464646',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    marginVertical: 10,
    color: 'white',
    borderRadius: 5,
    fontFamily: 'poppins',
  },
  contentTitle: {
    height: '10%',
    flexDirection: 'row',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 0.2,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginTop: 20,
    flexDirection: 'column',
    marginHorizontal: 20,
    fontFamily: 'poppins',
  },
  title: {
    textAlign: 'left',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  containerBack: {
    flex: 1,
    display: 'flex',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
});
