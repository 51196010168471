/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SessioneAa,
    SessioneAaFromJSON,
    SessioneAaFromJSONTyped,
    SessioneAaToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessioniAa
 */
export interface SessioniAa {
    /**
     * 
     * @type {Array<SessioneAa>}
     * @memberof SessioniAa
     */
    sessioneAa?: Array<SessioneAa>;
    /**
     * 
     * @type {string}
     * @memberof SessioniAa
     */
    dtIscrizione?: string;
}

export function SessioniAaFromJSON(json: any): SessioniAa {
    return SessioniAaFromJSONTyped(json, false);
}

export function SessioniAaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessioniAa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessioneAa': !exists(json, 'sessioneAa') ? undefined : ((json['sessioneAa'] as Array<any>).map(SessioneAaFromJSON)),
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
    };
}

export function SessioniAaToJSON(value?: SessioniAa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessioneAa': value.sessioneAa === undefined ? undefined : ((value.sessioneAa as Array<any>).map(SessioneAaToJSON)),
        'dtIscrizione': value.dtIscrizione,
    };
}


