/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Insight,
    InsightFromJSON,
    InsightFromJSONTyped,
    InsightToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface InsightLike
 */
export interface InsightLike {
    /**
     * 
     * @type {number}
     * @memberof InsightLike
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InsightLike
     */
    likeState?: InsightLikeLikeStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof InsightLike
     */
    editedAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof InsightLike
     */
    author?: User;
    /**
     * 
     * @type {Insight}
     * @memberof InsightLike
     */
    insight?: Insight;
}

/**
* @export
* @enum {string}
*/
export enum InsightLikeLikeStateEnum {
    Like = 'LIKE',
    Dislike = 'DISLIKE'
}

export function InsightLikeFromJSON(json: any): InsightLike {
    return InsightLikeFromJSONTyped(json, false);
}

export function InsightLikeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightLike {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'likeState': !exists(json, 'likeState') ? undefined : json['likeState'],
        'editedAt': !exists(json, 'editedAt') ? undefined : (new Date(json['editedAt'])),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
        'insight': !exists(json, 'insight') ? undefined : InsightFromJSON(json['insight']),
    };
}

export function InsightLikeToJSON(value?: InsightLike | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'likeState': value.likeState,
        'editedAt': value.editedAt === undefined ? undefined : (value.editedAt.toISOString()),
        'author': UserToJSON(value.author),
        'insight': InsightToJSON(value.insight),
    };
}


