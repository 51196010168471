/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Persona,
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonePco
 */
export interface PersonePco {
    /**
     * 
     * @type {Array<Persona>}
     * @memberof PersonePco
     */
    persona?: Array<Persona>;
}

export function PersonePcoFromJSON(json: any): PersonePco {
    return PersonePcoFromJSONTyped(json, false);
}

export function PersonePcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonePco {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'persona': !exists(json, 'persona') ? undefined : ((json['persona'] as Array<any>).map(PersonaFromJSON)),
    };
}

export function PersonePcoToJSON(value?: PersonePco | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona': value.persona === undefined ? undefined : ((value.persona as Array<any>).map(PersonaToJSON)),
    };
}


