/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySurveyQuestionRes,
    CompanySurveyQuestionResFromJSON,
    CompanySurveyQuestionResFromJSONTyped,
    CompanySurveyQuestionResToJSON,
    Survey,
    SurveyFromJSON,
    SurveyFromJSONTyped,
    SurveyToJSON,
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyQuestion
 */
export interface SurveyQuestion {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionEn?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionIt?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyQuestion
     */
    questionLean?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionArea?: SurveyQuestionQuestionAreaEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionAgility?: SurveyQuestionQuestionAgilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionGrowth?: SurveyQuestionQuestionGrowthEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    questionAttitude?: SurveyQuestionQuestionAttitudeEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestion
     */
    maxScore?: number;
    /**
     * 
     * @type {Set<SurveyQuestionResponse>}
     * @memberof SurveyQuestion
     */
    surveyQuestionResponses?: Set<SurveyQuestionResponse>;
    /**
     * 
     * @type {Survey}
     * @memberof SurveyQuestion
     */
    survey?: Survey;
    /**
     * 
     * @type {Set<CompanySurveyQuestionRes>}
     * @memberof SurveyQuestion
     */
    companySurveyQuestionRes?: Set<CompanySurveyQuestionRes>;
}

/**
* @export
* @enum {string}
*/
export enum SurveyQuestionQuestionAreaEnum {
    Product = 'PRODUCT',
    Customer = 'CUSTOMER',
    Businessmodel = 'BUSINESSMODEL',
    Culture = 'CULTURE',
    Goalsvision = 'GOALSVISION',
    Market = 'MARKET',
    Financecheck = 'FINANCECHECK'
}/**
* @export
* @enum {string}
*/
export enum SurveyQuestionQuestionAgilityEnum {
    Focus = 'FOCUS',
    Flexibility = 'FLEXIBILITY',
    Velocity = 'VELOCITY'
}/**
* @export
* @enum {string}
*/
export enum SurveyQuestionQuestionGrowthEnum {
    Asis = 'ASIS',
    Tobe = 'TOBE',
    Howto = 'HOWTO'
}/**
* @export
* @enum {string}
*/
export enum SurveyQuestionQuestionAttitudeEnum {
    Operationalexcellence = 'OPERATIONALEXCELLENCE',
    Customerintimacy = 'CUSTOMERINTIMACY',
    Productinnovation = 'PRODUCTINNOVATION'
}

export function SurveyQuestionFromJSON(json: any): SurveyQuestion {
    return SurveyQuestionFromJSONTyped(json, false);
}

export function SurveyQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'questionEn': !exists(json, 'questionEn') ? undefined : json['questionEn'],
        'questionIt': !exists(json, 'questionIt') ? undefined : json['questionIt'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'questionLean': !exists(json, 'questionLean') ? undefined : json['questionLean'],
        'questionArea': !exists(json, 'questionArea') ? undefined : json['questionArea'],
        'questionAgility': !exists(json, 'questionAgility') ? undefined : json['questionAgility'],
        'questionGrowth': !exists(json, 'questionGrowth') ? undefined : json['questionGrowth'],
        'questionAttitude': !exists(json, 'questionAttitude') ? undefined : json['questionAttitude'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
        'surveyQuestionResponses': !exists(json, 'surveyQuestionResponses') ? undefined : ((json['surveyQuestionResponses'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
        'survey': !exists(json, 'survey') ? undefined : SurveyFromJSON(json['survey']),
        'companySurveyQuestionRes': !exists(json, 'companySurveyQuestionRes') ? undefined : ((json['companySurveyQuestionRes'] as Array<any>).map(CompanySurveyQuestionResFromJSON)),
    };
}

export function SurveyQuestionToJSON(value?: SurveyQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'questionEn': value.questionEn,
        'questionIt': value.questionIt,
        'title': value.title,
        'questionLean': value.questionLean,
        'questionArea': value.questionArea,
        'questionAgility': value.questionAgility,
        'questionGrowth': value.questionGrowth,
        'questionAttitude': value.questionAttitude,
        'maxScore': value.maxScore,
        'surveyQuestionResponses': value.surveyQuestionResponses === undefined ? undefined : ((value.surveyQuestionResponses as Array<any>).map(SurveyQuestionResponseToJSON)),
        'survey': SurveyToJSON(value.survey),
        'companySurveyQuestionRes': value.companySurveyQuestionRes === undefined ? undefined : ((value.companySurveyQuestionRes as Array<any>).map(CompanySurveyQuestionResToJSON)),
    };
}


