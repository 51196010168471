/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    vatNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    registeredBySearch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    sector?: CompanySectorEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    partnerProgramDescriptionText?: string;
}

/**
* @export
* @enum {string}
*/
export enum CompanySectorEnum {
    Agriculture = 'AGRICULTURE',
    Handicraft = 'HANDICRAFT',
    Automotiveormobility = 'AUTOMOTIVEORMOBILITY',
    Facilityservices = 'FACILITYSERVICES',
    Foodandbeverage = 'FOODANDBEVERAGE',
    Healthcare = 'HEALTHCARE',
    Ict = 'ICT',
    Fashion = 'FASHION',
    Manufacturing = 'MANUFACTURING',
    Logistics = 'LOGISTICS',
    Pharmaceutical = 'PHARMACEUTICAL',
    Utilities = 'UTILITIES',
    Sports = 'SPORTS',
    Other = 'OTHER'
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'registeredBySearch': !exists(json, 'registeredBySearch') ? undefined : json['registeredBySearch'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'partnerProgramDescriptionText': !exists(json, 'partnerProgramDescriptionText') ? undefined : json['partnerProgramDescriptionText'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'vatNumber': value.vatNumber,
        'registeredBySearch': value.registeredBySearch,
        'sector': value.sector,
        'partnerProgramDescriptionText': value.partnerProgramDescriptionText,
    };
}


