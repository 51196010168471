import { NavigationHelper } from '@helpers/NavigationHelper';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { CriteriaSelected, CriteriaType, InsightEnum, RootStackScreenProps } from '../../../../../types';
import { i18n } from '../../../../i18n/i18n';
import Icon from '../../../Tools/Icon';
import Colors from "../../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';
import HykeeFilter from '@screens/ComponentShared/HykeeFilter';
import InsightComponent from '../Insights/InsightComponent';
import { customInsightResourceApi } from '@services/apis/ApiConfiguration';
import { Insight, InsightCurrentInsightStateEnum } from '@services/apis/generated';
import { useFocusEffect } from '@react-navigation/native';
import _ from 'lodash';

export default function PartnerProgramListScreen({ navigation, route }: RootStackScreenProps<'PartnerProgramList'>) {
    enum DateFilter {
        ASCENDING = "ascending",
        DESCENDING = "descending"
    }

    const [view, setView] = React.useState<InsightEnum>(InsightEnum.Draft);
    const [drafts, setDrafts] = React.useState<Insight[]>([])
    const [myInsights, setMyInsights] = React.useState<Insight[]>([])

    const criteria: CriteriaType[] = [
        {
            nameCriteria: 'date',
            dataCriteria: [DateFilter.ASCENDING, DateFilter.DESCENDING],
        },
    ]

    
    const [criteriaSelected, setCriteriaSelected] = React.useState<CriteriaSelected>();

    useFocusEffect(
        useCallback(() => {
            getAllInsights();
        }, [])
    )

    useEffect(() => {
        setMyInsights([...sortInsights(myInsights)]);
    }, [criteriaSelected]);

    function getAllInsights() {
        customInsightResourceApi.getAllInsightsCreatedByLoggedUser().then((response: Insight[]) => {
            setDrafts([])
            setMyInsights([])
            let drafts: Insight[] = []
            let myInsights: Insight[] = []
            response.forEach((insight) => {
                if (insight.currentInsightState == InsightCurrentInsightStateEnum.Draft) {
                    drafts.push(insight)
                } else if (insight.currentInsightState == InsightCurrentInsightStateEnum.Published) {
                    myInsights.push(insight)
                }
            })
            // Sort published insights
            drafts.sort((a, b) => b.createdAt!.valueOf() - a.createdAt!.valueOf())
            setDrafts([...drafts])
            setMyInsights([...sortInsights(myInsights)])
        }).catch((e) => {
            console.log(e)
        })
    }

    function sortInsights(insights: Insight[]) {
        switch (criteriaSelected?.criteriaSelected) {
            case DateFilter.ASCENDING:
                insights.sort((a, b) => a.dateOfPublication?.valueOf()! - b.dateOfPublication?.valueOf()!);
                break;
            case DateFilter.DESCENDING:
                insights.sort((a, b) => b.dateOfPublication?.valueOf()! - a.dateOfPublication?.valueOf()!);
                break;
            default:
                insights.sort((a, b) => a.dateOfPublication?.valueOf()! - b.dateOfPublication?.valueOf()!);
                break;
        }
        return insights;
    }

    function changeInsightView(insightType: InsightEnum) {
        setView(insightType);
    }

    const filterSelectedCallback = (data: string) => {
        setCriteriaSelected({ nameCriteria: "date", criteriaSelected: data })
    }

    function drawInsight(insights: Insight[]) {
        return (
            <>
                {
                    (insights && insights.length > 0) &&
                    <ScrollView style={[hykeeStyle.containerScroll, { paddingBottom: 20 }]}>
                        <HykeeFilter criteria={criteria} callbackCriteria={filterSelectedCallback} isMultiple={false} />
                        {insights && insights.map((insight, idx) => {
                            return (
                                <View key={idx} style={styles.noticeContainer}>
                                    <InsightComponent insight={insight} navigation={navigation} type={view} />
                                </View>
                            )
                        })}


                        {/**ADD NEW NOTICE BUTTON */}
                        <View style={{ marginBottom: 38 }}>
                            <TouchableOpacity style={styles.button} onPress={() => {
                                NavigationHelper.navigateToAddNewPartnerProgramScreen(navigation);
                            }}
                            >
                                <Text style={hykeeStyle.buttonText}>{i18n.t("components.partner_program.add_new")}</Text>
                            </TouchableOpacity>
                        </View>

                    </ScrollView>
                }
                {
                    (insights.length == 0) &&
                    <ScrollView style={[hykeeStyle.containerScroll, { paddingBottom: 20 }]}>

                        <View style={[{ marginTop: 60, marginBottom: 60, alignSelf: 'center' }]}>
                            <Text style={hykeeStyle.buttonText}>{i18n.t("components.partner_program.empty_list")}</Text>
                        </View>


                        {/**ADD NEW NOTICE BUTTON */}
                        <View style={{ marginBottom: 38 }}>
                            <TouchableOpacity style={styles.button} onPress={() => {
                                NavigationHelper.navigateToAddNewPartnerProgramScreen(navigation);
                            }}
                            >
                                <Text style={hykeeStyle.buttonText}>{i18n.t("components.partner_program.add_new")}</Text>
                            </TouchableOpacity>
                        </View>

                    </ScrollView>
                }
            </>

        )

    }

    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />

            <View style={[hykeeStyle.container, hykeeStyle.directionRow, { alignItems: 'center' }]}>
                <TouchableOpacity style={hykeeStyle.goBack} onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
                    <Icon name={'angle-left'} size={24} color={'#00CC83'} />
                </TouchableOpacity>
                <Text style={hykeeStyle.title}>{i18n.t("screens.profile_menu.partner_program")}</Text>
            </View>

            <View style={styles.containerViewButton}>
                {/* DRAFT */}
                <TouchableOpacity
                    style={view == InsightEnum.Draft ? styles.buttonTopClicked : styles.buttonTop}
                    activeOpacity={0.5}
                    onPress={() => { changeInsightView(InsightEnum.Draft) }}
                >
                    <Text style={styles.buttonTopText}>{i18n.t("components.partner_program.draft")}</Text>
                </TouchableOpacity>
                {/* PUBLISHED */}
                <TouchableOpacity
                    style={view == InsightEnum.Published ? styles.buttonTopClicked : styles.buttonTop}
                    activeOpacity={0.5}
                    onPress={() => { changeInsightView(InsightEnum.Published) }}
                >
                    <Text style={styles.buttonTopText}>{i18n.t("components.partner_program.sent")}</Text>
                </TouchableOpacity>
            </View>

            {view == InsightEnum.Draft ? drawInsight(drafts) : drawInsight(myInsights)}

        </LinearGradient>

    )
}

const styles = StyleSheet.create({
    titleText: {
        marginHorizontal: 15,
        marginTop: 5,
        color: '#00CC83',
        fontSize: 20,
        flex: 1,
        fontFamily: 'poppins',
    },
    containerSubtitle: {
        marginBottom: 20,
    },
    containerList: {
        marginBottom: 20,
    },
    containerViewButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00CC83',
        color: 'white',
        padding: 15,
        borderRadius: 5,
    },
    textLabel: {
        marginTop: 10,
        marginHorizontal: 15,
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 5,
        fontFamily: 'poppins',
        color: 'white'
    },
    textContainer: {
        borderColor: 'white',
        borderWidth: 1,
        padding: 10,
        marginTop: 30,
        marginBottom: 30
    },
    titleManage: {
        alignSelf: 'flex-start',
        fontSize: 18,
        padding: 8,
        fontFamily: 'poppins',
        color: 'white',
        marginBottom: 20,
    },
    noticeContainer: {
        flexDirection: 'column',
        paddingBottom: 10
    },
    container: {
        flex: 0.15,
        marginTop: 50,
        marginBottom: 20,
        flexDirection: 'row',
        marginHorizontal: 20,
        fontFamily: 'poppins',
        alignItems: "center",
        justifyContent: "flex-start"
    },
    containerScroll: {
        flex: 0.85,
        marginBottom: 40,
        marginHorizontal: 15,
    },
    delimiter: {
        borderWidth: 1,
        borderColor: '#505050',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    buttonTop: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: "#A5A5A5",
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 10
    },
    buttonTopClicked: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: '#00CC83',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 10
    },
    buttonTopText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 13,
        color: 'white',
        fontFamily: 'poppins',
    },
});