/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cancellazione,
    CancellazioneFromJSON,
    CancellazioneFromJSONTyped,
    CancellazioneToJSON,
    IndirizzoSedeEstero,
    IndirizzoSedeEsteroFromJSON,
    IndirizzoSedeEsteroFromJSONTyped,
    IndirizzoSedeEsteroToJSON,
    InfoAltroRegistro,
    InfoAltroRegistroFromJSON,
    InfoAltroRegistroFromJSONTyped,
    InfoAltroRegistroToJSON,
    TrasferimentoSede,
    TrasferimentoSedeFromJSON,
    TrasferimentoSedeFromJSONTyped,
    TrasferimentoSedeToJSON,
    TrasferimentoSedeUlAttiva,
    TrasferimentoSedeUlAttivaFromJSON,
    TrasferimentoSedeUlAttivaFromJSONTyped,
    TrasferimentoSedeUlAttivaToJSON,
} from './';

/**
 * 
 * @export
 * @interface CancellazioneTrasferimento
 */
export interface CancellazioneTrasferimento {
    /**
     * 
     * @type {Cancellazione}
     * @memberof CancellazioneTrasferimento
     */
    cancellazione?: Cancellazione;
    /**
     * 
     * @type {IndirizzoSedeEstero}
     * @memberof CancellazioneTrasferimento
     */
    indirizzoSedeEstero?: IndirizzoSedeEstero;
    /**
     * 
     * @type {TrasferimentoSede}
     * @memberof CancellazioneTrasferimento
     */
    trasferimentoSede?: TrasferimentoSede;
    /**
     * 
     * @type {TrasferimentoSedeUlAttiva}
     * @memberof CancellazioneTrasferimento
     */
    trasferimentoSedeUlAttiva?: TrasferimentoSedeUlAttiva;
    /**
     * 
     * @type {InfoAltroRegistro}
     * @memberof CancellazioneTrasferimento
     */
    infoAltroRegistro?: InfoAltroRegistro;
}

export function CancellazioneTrasferimentoFromJSON(json: any): CancellazioneTrasferimento {
    return CancellazioneTrasferimentoFromJSONTyped(json, false);
}

export function CancellazioneTrasferimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellazioneTrasferimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancellazione': !exists(json, 'cancellazione') ? undefined : CancellazioneFromJSON(json['cancellazione']),
        'indirizzoSedeEstero': !exists(json, 'indirizzoSedeEstero') ? undefined : IndirizzoSedeEsteroFromJSON(json['indirizzoSedeEstero']),
        'trasferimentoSede': !exists(json, 'trasferimentoSede') ? undefined : TrasferimentoSedeFromJSON(json['trasferimentoSede']),
        'trasferimentoSedeUlAttiva': !exists(json, 'trasferimentoSedeUlAttiva') ? undefined : TrasferimentoSedeUlAttivaFromJSON(json['trasferimentoSedeUlAttiva']),
        'infoAltroRegistro': !exists(json, 'infoAltroRegistro') ? undefined : InfoAltroRegistroFromJSON(json['infoAltroRegistro']),
    };
}

export function CancellazioneTrasferimentoToJSON(value?: CancellazioneTrasferimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancellazione': CancellazioneToJSON(value.cancellazione),
        'indirizzoSedeEstero': IndirizzoSedeEsteroToJSON(value.indirizzoSedeEstero),
        'trasferimentoSede': TrasferimentoSedeToJSON(value.trasferimentoSede),
        'trasferimentoSedeUlAttiva': TrasferimentoSedeUlAttivaToJSON(value.trasferimentoSedeUlAttiva),
        'infoAltroRegistro': InfoAltroRegistroToJSON(value.infoAltroRegistro),
    };
}


