/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EstremiPratica,
    EstremiPraticaFromJSON,
    EstremiPraticaFromJSONTyped,
    EstremiPraticaToJSON,
    EstremiPraticaRiconfermata,
    EstremiPraticaRiconfermataFromJSON,
    EstremiPraticaRiconfermataFromJSONTyped,
    EstremiPraticaRiconfermataToJSON,
    RiquadriTrasferimento,
    RiquadriTrasferimentoFromJSON,
    RiquadriTrasferimentoFromJSONTyped,
    RiquadriTrasferimentoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotazioneLibroSoci
 */
export interface AnnotazioneLibroSoci {
    /**
     * 
     * @type {EstremiPratica}
     * @memberof AnnotazioneLibroSoci
     */
    estremiPratica?: EstremiPratica;
    /**
     * 
     * @type {EstremiPraticaRiconfermata}
     * @memberof AnnotazioneLibroSoci
     */
    estremiPraticaRiconfermata?: EstremiPraticaRiconfermata;
    /**
     * 
     * @type {RiquadriTrasferimento}
     * @memberof AnnotazioneLibroSoci
     */
    riquadriTrasferimento?: RiquadriTrasferimento;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotazioneLibroSoci
     */
    note?: Array<string>;
}

export function AnnotazioneLibroSociFromJSON(json: any): AnnotazioneLibroSoci {
    return AnnotazioneLibroSociFromJSONTyped(json, false);
}

export function AnnotazioneLibroSociFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotazioneLibroSoci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estremiPratica': !exists(json, 'estremiPratica') ? undefined : EstremiPraticaFromJSON(json['estremiPratica']),
        'estremiPraticaRiconfermata': !exists(json, 'estremiPraticaRiconfermata') ? undefined : EstremiPraticaRiconfermataFromJSON(json['estremiPraticaRiconfermata']),
        'riquadriTrasferimento': !exists(json, 'riquadriTrasferimento') ? undefined : RiquadriTrasferimentoFromJSON(json['riquadriTrasferimento']),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function AnnotazioneLibroSociToJSON(value?: AnnotazioneLibroSoci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estremiPratica': EstremiPraticaToJSON(value.estremiPratica),
        'estremiPraticaRiconfermata': EstremiPraticaRiconfermataToJSON(value.estremiPraticaRiconfermata),
        'riquadriTrasferimento': RiquadriTrasferimentoToJSON(value.riquadriTrasferimento),
        'note': value.note,
    };
}


