import { StatusBar } from 'expo-status-bar';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { KeyboardAvoidingView, Platform, StyleSheet } from "react-native";

import useCachedResources from './hooks/useCachedResources';
import { BaseAPIManager } from './app/services/model/BaseAPIManager';
import * as StorageHelper from './app/helpers/StorageHelper';
import { reloadApiConfiguration, userJwtControllerApi } from './app/services/apis/ApiConfiguration';
import Loader from "./app/helpers/LoaderHelper";
import AppContext from "./app/navigation/AppContext";
import { PlatformHelper } from "./app/helpers/PlatformHelper";
import { sentrySetup } from "./app/config/SentryConfig";
import Navigation from './app/navigation/Navigation';
import ModalSimpleError from '@screens/Modal/ModalSimpleError';
import { i18n } from "./app/i18n/i18n";
import { Overlay } from 'react-native-tooltip-mroads';
import {NotificationHelper} from "./app/helpers/NotificationHelper";
import {CustomModalErrorInput} from "./app/interfaces/CustomModalErrorInput";

export default function App() {
    const isLoadingComplete = useCachedResources();

    const [loading, setLoading] = useState<boolean>();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const safeStopLoading = (loading?: boolean) => {
        if (!loading)
            return

        return setTimeout(() => {
            setLoading(false);
        }, 15000);
    }

    const [modalError, setModalError] = React.useState<CustomModalErrorInput>({ visible: false, msg: "" });
    const [timerId, setTimerId] = React.useState<NodeJS.Timeout | undefined>()

    useEffect(() => {
        setup();
    }, []);

    const setCustomModalError = (newValue: CustomModalErrorInput) => {
        setTimeout(() => {
            setModalError(newValue)
        }, 500);
    }

    const setup = async () => {

        if (!PlatformHelper.isWeb()) {
            sentrySetup();
        }
        BaseAPIManager.contextLoadingCallback = function (loading: boolean) {
            setLoading(loading);
            setTimerId(safeStopLoading(loading));
            !loading && timerId ? clearTimeout(timerId) : null;

        };
        BaseAPIManager.logoutCallback = function () {
            StorageHelper.logout();
            setLoggedIn(false);
        };
        reloadApiConfiguration();
        let token = await StorageHelper.readAccessToken();
        if (token != null) {
            BaseAPIManager.setToken(token);
            NotificationHelper.registerForPushNotificationsAsync();
        }
    }


    reloadApiConfiguration();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <AppContext.Provider value={{
                loading, setLoading,
                loggedIn, setLoggedIn,
                modalError, setModalError,
                setCustomModalError
            }}>
                <SafeAreaProvider>
                    <Loader></Loader>
                    <Navigation colorScheme={'dark'}/>
                    <Overlay />
                    {modalError.visible && <ModalSimpleError msg={modalError.msg}
                                                             visible={(visible) => setModalError({
                                                                 visible: visible,
                                                                 msg: ''
                                                             })}/>}
                    <StatusBar/>
                    <KeyboardAvoidingView
                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                    ></KeyboardAvoidingView>
                </SafeAreaProvider>
            </AppContext.Provider>
        );
    }
}
