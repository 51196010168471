/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dichiarazioni,
    DichiarazioniFromJSON,
    DichiarazioniFromJSONTyped,
    DichiarazioniToJSON,
    DurataSocieta,
    DurataSocietaFromJSON,
    DurataSocietaFromJSONTyped,
    DurataSocietaToJSON,
    IscrizioneRs,
    IscrizioneRsFromJSON,
    IscrizioneRsFromJSONTyped,
    IscrizioneRsToJSON,
    Poteri,
    PoteriFromJSON,
    PoteriFromJSONTyped,
    PoteriToJSON,
    Riferimenti,
    RiferimentiFromJSON,
    RiferimentiFromJSONTyped,
    RiferimentiToJSON,
} from './';

/**
 * 
 * @export
 * @interface InfoStatuto
 */
export interface InfoStatuto {
    /**
     * 
     * @type {DurataSocieta}
     * @memberof InfoStatuto
     */
    durataSocieta?: DurataSocieta;
    /**
     * 
     * @type {IscrizioneRs}
     * @memberof InfoStatuto
     */
    iscrizioneRs?: IscrizioneRs;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    oggettoSociale?: string;
    /**
     * 
     * @type {Poteri}
     * @memberof InfoStatuto
     */
    poteri?: Poteri;
    /**
     * 
     * @type {Riferimenti}
     * @memberof InfoStatuto
     */
    riferimenti?: Riferimenti;
    /**
     * 
     * @type {Dichiarazioni}
     * @memberof InfoStatuto
     */
    dichiarazioni?: Dichiarazioni;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    siglaDenominazione?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    dtFondazione?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    dtComunicazioneUnica?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    dtAttoCostituzione?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoStatuto
     */
    dtCostituzione?: string;
}

export function InfoStatutoFromJSON(json: any): InfoStatuto {
    return InfoStatutoFromJSONTyped(json, false);
}

export function InfoStatutoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoStatuto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'durataSocieta': !exists(json, 'durataSocieta') ? undefined : DurataSocietaFromJSON(json['durataSocieta']),
        'iscrizioneRs': !exists(json, 'iscrizioneRs') ? undefined : IscrizioneRsFromJSON(json['iscrizioneRs']),
        'oggettoSociale': !exists(json, 'oggettoSociale') ? undefined : json['oggettoSociale'],
        'poteri': !exists(json, 'poteri') ? undefined : PoteriFromJSON(json['poteri']),
        'riferimenti': !exists(json, 'riferimenti') ? undefined : RiferimentiFromJSON(json['riferimenti']),
        'dichiarazioni': !exists(json, 'dichiarazioni') ? undefined : DichiarazioniFromJSON(json['dichiarazioni']),
        'siglaDenominazione': !exists(json, 'siglaDenominazione') ? undefined : json['siglaDenominazione'],
        'dtFondazione': !exists(json, 'dtFondazione') ? undefined : json['dtFondazione'],
        'dtComunicazioneUnica': !exists(json, 'dtComunicazioneUnica') ? undefined : json['dtComunicazioneUnica'],
        'dtAttoCostituzione': !exists(json, 'dtAttoCostituzione') ? undefined : json['dtAttoCostituzione'],
        'dtCostituzione': !exists(json, 'dtCostituzione') ? undefined : json['dtCostituzione'],
    };
}

export function InfoStatutoToJSON(value?: InfoStatuto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'durataSocieta': DurataSocietaToJSON(value.durataSocieta),
        'iscrizioneRs': IscrizioneRsToJSON(value.iscrizioneRs),
        'oggettoSociale': value.oggettoSociale,
        'poteri': PoteriToJSON(value.poteri),
        'riferimenti': RiferimentiToJSON(value.riferimenti),
        'dichiarazioni': DichiarazioniToJSON(value.dichiarazioni),
        'siglaDenominazione': value.siglaDenominazione,
        'dtFondazione': value.dtFondazione,
        'dtComunicazioneUnica': value.dtComunicazioneUnica,
        'dtAttoCostituzione': value.dtAttoCostituzione,
        'dtCostituzione': value.dtCostituzione,
    };
}


