/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoMese
 */
export interface InfoMese {
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    mese?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    percentualeDipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    ndipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    nindipendenti?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    ncollaboratori?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    ndipNoAgricoli?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    cmese?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoMese
     */
    ntotale?: string;
}

export function InfoMeseFromJSON(json: any): InfoMese {
    return InfoMeseFromJSONTyped(json, false);
}

export function InfoMeseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoMese {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mese': !exists(json, 'mese') ? undefined : json['mese'],
        'percentualeDipendenti': !exists(json, 'percentualeDipendenti') ? undefined : json['percentualeDipendenti'],
        'ndipendenti': !exists(json, 'ndipendenti') ? undefined : json['ndipendenti'],
        'nindipendenti': !exists(json, 'nindipendenti') ? undefined : json['nindipendenti'],
        'ncollaboratori': !exists(json, 'ncollaboratori') ? undefined : json['ncollaboratori'],
        'ndipNoAgricoli': !exists(json, 'ndipNoAgricoli') ? undefined : json['ndipNoAgricoli'],
        'cmese': !exists(json, 'cmese') ? undefined : json['cmese'],
        'ntotale': !exists(json, 'ntotale') ? undefined : json['ntotale'],
    };
}

export function InfoMeseToJSON(value?: InfoMese | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mese': value.mese,
        'percentualeDipendenti': value.percentualeDipendenti,
        'ndipendenti': value.ndipendenti,
        'nindipendenti': value.nindipendenti,
        'ncollaboratori': value.ncollaboratori,
        'ndipNoAgricoli': value.ndipNoAgricoli,
        'cmese': value.cmese,
        'ntotale': value.ntotale,
    };
}


