/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Euid
 */
export interface Euid {
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    stato?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    registro?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    formaGiuridica?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    nregistrazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    cregistro?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    cformaGiuridica?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    cstato?: string;
    /**
     * 
     * @type {string}
     * @memberof Euid
     */
    ceuid?: string;
}

export function EuidFromJSON(json: any): Euid {
    return EuidFromJSONTyped(json, false);
}

export function EuidFromJSONTyped(json: any, ignoreDiscriminator: boolean): Euid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'registro': !exists(json, 'registro') ? undefined : json['registro'],
        'formaGiuridica': !exists(json, 'formaGiuridica') ? undefined : json['formaGiuridica'],
        'nregistrazione': !exists(json, 'nregistrazione') ? undefined : json['nregistrazione'],
        'cregistro': !exists(json, 'cregistro') ? undefined : json['cregistro'],
        'cformaGiuridica': !exists(json, 'cformaGiuridica') ? undefined : json['cformaGiuridica'],
        'cstato': !exists(json, 'cstato') ? undefined : json['cstato'],
        'ceuid': !exists(json, 'ceuid') ? undefined : json['ceuid'],
    };
}

export function EuidToJSON(value?: Euid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stato': value.stato,
        'registro': value.registro,
        'formaGiuridica': value.formaGiuridica,
        'nregistrazione': value.nregistrazione,
        'cregistro': value.cregistro,
        'cformaGiuridica': value.cformaGiuridica,
        'cstato': value.cstato,
        'ceuid': value.ceuid,
    };
}


