/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaratteristicheImpianto1
 */
export interface CaratteristicheImpianto1 {
    /**
     * 
     * @type {string}
     * @memberof CaratteristicheImpianto1
     */
    quintaliPotenzaNominale?: string;
    /**
     * 
     * @type {string}
     * @memberof CaratteristicheImpianto1
     */
    tipoRiscaldamento?: string;
    /**
     * 
     * @type {string}
     * @memberof CaratteristicheImpianto1
     */
    tipoCombustibile?: string;
}

export function CaratteristicheImpianto1FromJSON(json: any): CaratteristicheImpianto1 {
    return CaratteristicheImpianto1FromJSONTyped(json, false);
}

export function CaratteristicheImpianto1FromJSONTyped(json: any, ignoreDiscriminator: boolean): CaratteristicheImpianto1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quintaliPotenzaNominale': !exists(json, 'quintaliPotenzaNominale') ? undefined : json['quintaliPotenzaNominale'],
        'tipoRiscaldamento': !exists(json, 'tipoRiscaldamento') ? undefined : json['tipoRiscaldamento'],
        'tipoCombustibile': !exists(json, 'tipoCombustibile') ? undefined : json['tipoCombustibile'],
    };
}

export function CaratteristicheImpianto1ToJSON(value?: CaratteristicheImpianto1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quintaliPotenzaNominale': value.quintaliPotenzaNominale,
        'tipoRiscaldamento': value.tipoRiscaldamento,
        'tipoCombustibile': value.tipoCombustibile,
    };
}


