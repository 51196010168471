/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Gradimento
 */
export interface Gradimento {
    /**
     * 
     * @type {string}
     * @memberof Gradimento
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Gradimento
     */
    fpresenzaNelloStatuto?: string;
}

export function GradimentoFromJSON(json: any): Gradimento {
    return GradimentoFromJSONTyped(json, false);
}

export function GradimentoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gradimento {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'fpresenzaNelloStatuto': !exists(json, 'fpresenzaNelloStatuto') ? undefined : json['fpresenzaNelloStatuto'],
    };
}

export function GradimentoToJSON(value?: Gradimento | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'fpresenzaNelloStatuto': value.fpresenzaNelloStatuto,
    };
}


