/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserConsultantInviteState,
    UserConsultantInviteStateFromJSON,
    UserConsultantInviteStateToJSON,
} from '../models';

export interface CreateUserConsultantInviteStateRequest {
    userConsultantInviteState: UserConsultantInviteState;
}

export interface DeleteUserConsultantInviteStateRequest {
    id: number;
}

export interface GetUserConsultantInviteStateRequest {
    id: number;
}

export interface PartialUpdateUserConsultantInviteStateRequest {
    id: number;
    userConsultantInviteState: UserConsultantInviteState;
}

export interface UpdateUserConsultantInviteStateRequest {
    id: number;
    userConsultantInviteState: UserConsultantInviteState;
}

/**
 * 
 */
export class UserConsultantInviteStateResourceApi extends runtime.BaseAPI {

    /**
     */
    async createUserConsultantInviteStateRaw(requestParameters: CreateUserConsultantInviteStateRequest): Promise<runtime.ApiResponse<UserConsultantInviteState>> {
        if (requestParameters.userConsultantInviteState === null || requestParameters.userConsultantInviteState === undefined) {
            throw new runtime.RequiredError('userConsultantInviteState','Required parameter requestParameters.userConsultantInviteState was null or undefined when calling createUserConsultantInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invite-states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteStateToJSON(requestParameters.userConsultantInviteState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async createUserConsultantInviteState(requestParameters: CreateUserConsultantInviteStateRequest): Promise<UserConsultantInviteState> {
        const response = await this.createUserConsultantInviteStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserConsultantInviteStateRaw(requestParameters: DeleteUserConsultantInviteStateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConsultantInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserConsultantInviteState(requestParameters: DeleteUserConsultantInviteStateRequest): Promise<void> {
        await this.deleteUserConsultantInviteStateRaw(requestParameters);
    }

    /**
     */
    async getAllUserConsultantInviteStatesRaw(): Promise<runtime.ApiResponse<Array<UserConsultantInviteState>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invite-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserConsultantInviteStateFromJSON));
    }

    /**
     */
    async getAllUserConsultantInviteStates(): Promise<Array<UserConsultantInviteState>> {
        const response = await this.getAllUserConsultantInviteStatesRaw();
        return await response.value();
    }

    /**
     */
    async getUserConsultantInviteStateRaw(requestParameters: GetUserConsultantInviteStateRequest): Promise<runtime.ApiResponse<UserConsultantInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserConsultantInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-consultant-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async getUserConsultantInviteState(requestParameters: GetUserConsultantInviteStateRequest): Promise<UserConsultantInviteState> {
        const response = await this.getUserConsultantInviteStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateUserConsultantInviteStateRaw(requestParameters: PartialUpdateUserConsultantInviteStateRequest): Promise<runtime.ApiResponse<UserConsultantInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateUserConsultantInviteState.');
        }

        if (requestParameters.userConsultantInviteState === null || requestParameters.userConsultantInviteState === undefined) {
            throw new runtime.RequiredError('userConsultantInviteState','Required parameter requestParameters.userConsultantInviteState was null or undefined when calling partialUpdateUserConsultantInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteStateToJSON(requestParameters.userConsultantInviteState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateUserConsultantInviteState(requestParameters: PartialUpdateUserConsultantInviteStateRequest): Promise<UserConsultantInviteState> {
        const response = await this.partialUpdateUserConsultantInviteStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateUserConsultantInviteStateRaw(requestParameters: UpdateUserConsultantInviteStateRequest): Promise<runtime.ApiResponse<UserConsultantInviteState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserConsultantInviteState.');
        }

        if (requestParameters.userConsultantInviteState === null || requestParameters.userConsultantInviteState === undefined) {
            throw new runtime.RequiredError('userConsultantInviteState','Required parameter requestParameters.userConsultantInviteState was null or undefined when calling updateUserConsultantInviteState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-consultant-invite-states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserConsultantInviteStateToJSON(requestParameters.userConsultantInviteState),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConsultantInviteStateFromJSON(jsonValue));
    }

    /**
     */
    async updateUserConsultantInviteState(requestParameters: UpdateUserConsultantInviteStateRequest): Promise<UserConsultantInviteState> {
        const response = await this.updateUserConsultantInviteStateRaw(requestParameters);
        return await response.value();
    }

}
