/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaFusioniScissioniSubentri
 */
export interface TaFusioniScissioniSubentri {
    /**
     * 
     * @type {Array<object>}
     * @memberof TaFusioniScissioniSubentri
     */
    trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof TaFusioniScissioniSubentri
     */
    fpresenzaInfo?: string;
}

export function TaFusioniScissioniSubentriFromJSON(json: any): TaFusioniScissioniSubentri {
    return TaFusioniScissioniSubentriFromJSONTyped(json, false);
}

export function TaFusioniScissioniSubentriFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaFusioniScissioniSubentri {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa': !exists(json, 'trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa') ? undefined : json['trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa'],
        'fpresenzaInfo': !exists(json, 'fpresenzaInfo') ? undefined : json['fpresenzaInfo'],
    };
}

export function TaFusioniScissioniSubentriToJSON(value?: TaFusioniScissioniSubentri | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa': value.trasferimentiAziendaOrFusioniScissioniOrSubentriImpresa,
        'fpresenzaInfo': value.fpresenzaInfo,
    };
}


