import {environment} from "../../environments/environment";

import {BaseAPIManager} from "../model/BaseAPIManager";
import {
    AccountResourceApi,
    CompanySavedResourceApi,
    Configuration,
    ConfigurationParameters,
    CustomAccountResourceApi,
    CustomCompanyBalanceSheetResourceApi,
    CustomCompanyDataBlockResourceApi,
    CustomCompanyDataResourceApi,
    CustomCompanyLinkRequestResourceApi,
    CustomCompanyResourceApi,
    CustomCompanySavedResourceApi,
    CustomCompanySearchedResourceApi,
    CustomCompanySurveyQuestionResResourceApi,
    CustomCompanySurveyResourceApi,
    CustomDataBlockUserResourceApi,
    CustomMarketCompetitorResourceApi,
    CustomNotificationResourceApi, CustomPushTokenResourceApi,
    CustomScoreResourceApi,
    CustomSurveyQuestionResourceApi,
    CustomSurveyQuestionResponseResourceApi,
    CustomSurveyResourceApi,
    CustomUserCompanyResourceApi,
    CustomUserConsultantInviteResourceApi,
    CustomUserConsultantResourceApi,
    CustomUserExtendedResourceApi,
    CustomUserPlanResourceApi,
    CustomUserResourceApi,
    FetchParams,
    HTTPQuery,
    Middleware, NotificationResourceApi,
    PlanResourceApi,
    RequestContext,
    ResponseContext,
    UserJwtControllerApi,
    CustomInsightResourceApi,
    CustomInsightLikeResourceApi,
} from "./generated";
import {Logger} from "../../helpers/Logger";

let userJwtControllerApi: UserJwtControllerApi;
let customAccountResourceApi : CustomAccountResourceApi;
let customUserExtendedResourceApi : CustomUserExtendedResourceApi;
let customUserCompanyResourceApi : CustomUserCompanyResourceApi;
let customSurveyQuestionResourceApi : CustomSurveyQuestionResourceApi;
let customSurveyQuestionResponseResourceApi : CustomSurveyQuestionResponseResourceApi;
let customCompanyDataResourceApi : CustomCompanyDataResourceApi;
let customSurveyResourceApi : CustomSurveyResourceApi;
let customCompanySurveyResourceApi :CustomCompanySurveyResourceApi;
let customCompanySurveyQuestionResResourceApi: CustomCompanySurveyQuestionResResourceApi;
let customUserResourceApi: CustomUserResourceApi;
let accountResourceApi: AccountResourceApi;
let customCompanyResourceApi: CustomCompanyResourceApi;
let customCompanySavedResourceApi:CustomCompanySavedResourceApi;
let customCompanyLinkRequestResourceApi :CustomCompanyLinkRequestResourceApi;
let customCompanyBalanceSheetResourceApi :CustomCompanyBalanceSheetResourceApi;
let customUserConsultantResourceApi : CustomUserConsultantResourceApi;
let customCompanySearchedResourceApi: CustomCompanySearchedResourceApi;
let customUserConsultantInviteResourceApi : CustomUserConsultantInviteResourceApi;
let customCompanyDataBlockResourceApi : CustomCompanyDataBlockResourceApi;
let customScoreResourceApi : CustomScoreResourceApi;
let customMarketCompetitorResourceApi: CustomMarketCompetitorResourceApi; 
let customUserPlanResourceApi: CustomUserPlanResourceApi; 
let planResourceApi: PlanResourceApi;
let customDataBlockUserResourceApi :CustomDataBlockUserResourceApi;
let customNotificationResourceApi : CustomNotificationResourceApi;
let customPushTokenResourceApi : CustomPushTokenResourceApi;
let customInsightResourceApi: CustomInsightResourceApi;
let customInsightLikeResourceApi: CustomInsightLikeResourceApi;

const className = "ApiConfiguration";

export function reloadApiConfiguration(){
    console.log("Reloading API");
    let configParameters: ConfigurationParameters = {};
    configParameters.basePath = environment.apiUrl;
    configParameters.queryParamsStringify = querystring;
    configParameters.fetchApi = async (input: RequestInfo, init?: RequestInit | undefined) => {
        let fetchApi = window.fetch.bind(window);
        try {
            return await fetchApi(input, init);
        }catch (e){ // In case of errors it hides the loader
            BaseAPIManager.contextLoadingCallback(false);
            throw e;
        }
    };
    let loaderMiddleware: Middleware = {
        pre(context: RequestContext): Promise<FetchParams | void> {
            Logger.log(className, `Starting: ${context.url}`);
            return Promise.resolve({ url: context.url, init: context.init });
        },
        post(context: ResponseContext): Promise<Response | void> {
            Logger.log(className, `Ending: ${context.url}`);
            if(context.response.status < 200 || context.response.status > 207){
                BaseAPIManager.contextLoadingCallback(false);
            }
            if(context.response.status == 401){
                BaseAPIManager.logoutCallback();
            }
            Logger.log(className, `Response status code: ${context.response.status}`);
            return Promise.resolve(context.response);
        }
    }
    configParameters.middleware = [loaderMiddleware];
    if(BaseAPIManager.token){
        configParameters.headers = {
            Authorization: 'Bearer ' + BaseAPIManager.token
        };
    }
    let config = new Configuration(configParameters);

    userJwtControllerApi = new UserJwtControllerApi(config);
    customUserExtendedResourceApi = new CustomUserExtendedResourceApi(config);
    customAccountResourceApi = new CustomAccountResourceApi(config);
    customUserCompanyResourceApi = new CustomUserCompanyResourceApi(config);
    customSurveyQuestionResourceApi = new CustomSurveyQuestionResourceApi(config);
    customCompanyDataResourceApi = new CustomCompanyDataResourceApi(config) ;
    customSurveyResourceApi = new CustomSurveyResourceApi(config);
    customSurveyQuestionResponseResourceApi = new CustomSurveyQuestionResponseResourceApi(config);
    customCompanySurveyResourceApi = new CustomCompanySurveyResourceApi(config);
    customCompanySurveyQuestionResResourceApi = new CustomCompanySurveyQuestionResResourceApi(config);
    customUserResourceApi = new CustomUserResourceApi(config);
    accountResourceApi = new AccountResourceApi(config);
    customCompanyResourceApi = new CustomCompanyResourceApi(config);
    customCompanySavedResourceApi = new CustomCompanySavedResourceApi(config);
    customCompanyLinkRequestResourceApi = new CustomCompanyLinkRequestResourceApi(config);
    customCompanyBalanceSheetResourceApi = new CustomCompanyBalanceSheetResourceApi(config);
    customUserConsultantResourceApi = new CustomUserConsultantResourceApi(config);
    customCompanySearchedResourceApi = new CustomCompanySearchedResourceApi(config);
    customUserConsultantInviteResourceApi = new CustomUserConsultantInviteResourceApi(config);
    customCompanyDataBlockResourceApi = new CustomCompanyDataBlockResourceApi(config);
    customScoreResourceApi = new CustomScoreResourceApi(config);
    customMarketCompetitorResourceApi = new CustomMarketCompetitorResourceApi(config);
    customUserPlanResourceApi = new CustomUserPlanResourceApi(config);
    planResourceApi = new PlanResourceApi(config);
    customDataBlockUserResourceApi = new CustomDataBlockUserResourceApi(config);
    customNotificationResourceApi = new CustomNotificationResourceApi(config);
    customPushTokenResourceApi = new CustomPushTokenResourceApi(config);
    customInsightLikeResourceApi = new CustomInsightLikeResourceApi(config);
    customInsightResourceApi = new CustomInsightResourceApi(config);
}

function querystring(params: HTTPQuery, prefix: string = '', includePrefix = false): string {
    return Object.keys(params)
        .map((key) => {
            let fullKey = prefix + (prefix.length ? `.${key}` : key);
            if(!includePrefix)
                fullKey = '';
            const value = params[key];
            if (value instanceof Array) {
                const multiValue = value.map(singleValue => encodeURIComponent(String(singleValue)))
                    .join(`&${encodeURIComponent(fullKey)}=`);
                return `${encodeURIComponent(fullKey)}=${multiValue}`;
            }
            if (value instanceof Object) {
                return querystring(value as HTTPQuery, fullKey, true);
            }
            return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
        })
        .filter(part => part.length > 0)
        .join('&');
}


export {
    userJwtControllerApi,
    customCompanySearchedResourceApi,
    customAccountResourceApi,
    customUserExtendedResourceApi,
    customUserCompanyResourceApi,
    customSurveyQuestionResourceApi,
    customCompanyDataResourceApi,
    customSurveyResourceApi,
    customSurveyQuestionResponseResourceApi,
    customCompanySurveyResourceApi,
    customCompanySurveyQuestionResResourceApi,
    customUserResourceApi,
    accountResourceApi,
    customCompanyResourceApi,
    customCompanySavedResourceApi,
    customCompanyLinkRequestResourceApi,
    customCompanyBalanceSheetResourceApi,
    customUserConsultantResourceApi,
    customUserConsultantInviteResourceApi,
    customCompanyDataBlockResourceApi,
    customScoreResourceApi,
    customMarketCompetitorResourceApi,
    customUserPlanResourceApi,
    planResourceApi,
    customDataBlockUserResourceApi,
    customNotificationResourceApi,
    customPushTokenResourceApi,
    customInsightLikeResourceApi,
    customInsightResourceApi,
};