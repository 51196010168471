/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DataBlockUser,
    DataBlockUserFromJSON,
    DataBlockUserToJSON,
} from '../models';

export interface CreateDataBlockUserRequest {
    dataBlockUser: DataBlockUser;
}

export interface DeleteDataBlockUserRequest {
    id: number;
}

export interface GetDataBlockUserRequest {
    id: number;
}

export interface PartialUpdateDataBlockUserRequest {
    id: number;
    dataBlockUser: DataBlockUser;
}

export interface UpdateDataBlockUserRequest {
    id: number;
    dataBlockUser: DataBlockUser;
}

/**
 * 
 */
export class DataBlockUserResourceApi extends runtime.BaseAPI {

    /**
     */
    async createDataBlockUserRaw(requestParameters: CreateDataBlockUserRequest): Promise<runtime.ApiResponse<DataBlockUser>> {
        if (requestParameters.dataBlockUser === null || requestParameters.dataBlockUser === undefined) {
            throw new runtime.RequiredError('dataBlockUser','Required parameter requestParameters.dataBlockUser was null or undefined when calling createDataBlockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/data-block-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataBlockUserToJSON(requestParameters.dataBlockUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBlockUserFromJSON(jsonValue));
    }

    /**
     */
    async createDataBlockUser(requestParameters: CreateDataBlockUserRequest): Promise<DataBlockUser> {
        const response = await this.createDataBlockUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteDataBlockUserRaw(requestParameters: DeleteDataBlockUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDataBlockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/data-block-users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDataBlockUser(requestParameters: DeleteDataBlockUserRequest): Promise<void> {
        await this.deleteDataBlockUserRaw(requestParameters);
    }

    /**
     */
    async getAllDataBlockUsersRaw(): Promise<runtime.ApiResponse<Array<DataBlockUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/data-block-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataBlockUserFromJSON));
    }

    /**
     */
    async getAllDataBlockUsers(): Promise<Array<DataBlockUser>> {
        const response = await this.getAllDataBlockUsersRaw();
        return await response.value();
    }

    /**
     */
    async getDataBlockUserRaw(requestParameters: GetDataBlockUserRequest): Promise<runtime.ApiResponse<DataBlockUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDataBlockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/data-block-users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBlockUserFromJSON(jsonValue));
    }

    /**
     */
    async getDataBlockUser(requestParameters: GetDataBlockUserRequest): Promise<DataBlockUser> {
        const response = await this.getDataBlockUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async partialUpdateDataBlockUserRaw(requestParameters: PartialUpdateDataBlockUserRequest): Promise<runtime.ApiResponse<DataBlockUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateDataBlockUser.');
        }

        if (requestParameters.dataBlockUser === null || requestParameters.dataBlockUser === undefined) {
            throw new runtime.RequiredError('dataBlockUser','Required parameter requestParameters.dataBlockUser was null or undefined when calling partialUpdateDataBlockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/data-block-users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DataBlockUserToJSON(requestParameters.dataBlockUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBlockUserFromJSON(jsonValue));
    }

    /**
     */
    async partialUpdateDataBlockUser(requestParameters: PartialUpdateDataBlockUserRequest): Promise<DataBlockUser> {
        const response = await this.partialUpdateDataBlockUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateDataBlockUserRaw(requestParameters: UpdateDataBlockUserRequest): Promise<runtime.ApiResponse<DataBlockUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDataBlockUser.');
        }

        if (requestParameters.dataBlockUser === null || requestParameters.dataBlockUser === undefined) {
            throw new runtime.RequiredError('dataBlockUser','Required parameter requestParameters.dataBlockUser was null or undefined when calling updateDataBlockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/data-block-users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DataBlockUserToJSON(requestParameters.dataBlockUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBlockUserFromJSON(jsonValue));
    }

    /**
     */
    async updateDataBlockUser(requestParameters: UpdateDataBlockUserRequest): Promise<DataBlockUser> {
        const response = await this.updateDataBlockUserRaw(requestParameters);
        return await response.value();
    }

}
