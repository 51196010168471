/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Insight,
    InsightFromJSON,
    InsightFromJSONTyped,
    InsightToJSON,
} from './';

/**
 * 
 * @export
 * @interface InsightState
 */
export interface InsightState {
    /**
     * 
     * @type {number}
     * @memberof InsightState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof InsightState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof InsightState
     */
    state?: InsightStateStateEnum;
    /**
     * 
     * @type {Insight}
     * @memberof InsightState
     */
    insight?: Insight;
}

/**
* @export
* @enum {string}
*/
export enum InsightStateStateEnum {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Deleted = 'DELETED'
}

export function InsightStateFromJSON(json: any): InsightState {
    return InsightStateFromJSONTyped(json, false);
}

export function InsightStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'insight': !exists(json, 'insight') ? undefined : InsightFromJSON(json['insight']),
    };
}

export function InsightStateToJSON(value?: InsightState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'insight': InsightToJSON(value.insight),
    };
}


