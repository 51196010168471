// 
// 
//
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Profile, RootStackScreenProps, State } from '../../../../types';
import { i18n } from '../../../i18n/i18n'
import { customUserExtendedResourceApi, customUserResourceApi } from "../../../services/apis/ApiConfiguration";
import { AlertHelper } from "../../../helpers/AlertHelper";
import { User, UserExtended, UserExtendedTypeEnum } from "../../../services/apis/generated";
import ProfileMenuScreen from './ProfileMenuScreen';
import Colors from "../../../../constants/Colors";
import hykeeStyle from '@components/hykeeStyle';

export default function ProfileMenu({ navigation, route }: RootStackScreenProps<'Profile'>) {
    const [user, setUser] = useState<User>();
    const [userExtended, setUserExtended] = useState<UserExtended>({});
    const [myroute, setMyRoute] = useState<State>({
        profiles: route.params?.profiles || [],
        profile: route.params?.profile || { id: -1, userRole: UserExtendedTypeEnum.Company },
        userRole: route.params?.userRole,
    })

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = (): void => {
        // Get User data
        customUserResourceApi.getMe().then(res => {
            let user = res;
            setUser(user);
            setMyRoute(prevState => ({
                ...myroute,
                profile: {
                    ...prevState.profile,
                    id: user.id,
                    email: user.email
                }
            }))
        }).catch(error => {
            console.log(error);
            AlertHelper.showSimpleErrorAlert();
        });

        customUserExtendedResourceApi.getUserExtendedMe().then(res => {
            let userExtended = res;
            setUserExtended(userExtended);
            setMyRoute(prevState => ({
                ...myroute,
                profile: {
                    ...prevState.profile,
                    id: userExtended.id,
                    userRole: userExtended.type!,
                    firstName: userExtended.firstName,
                    lastName: userExtended.lastName,
                    phone: userExtended.telephone,
                }
            }));
        }).catch(error => {
            console.log(error);
            AlertHelper.showSimpleErrorAlert();
        });
    }


    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={hykeeStyle.container}>
                <Text style={hykeeStyle.title}>{i18n.t('screens.profile.title')}</Text>
            </View>
            <ProfileMenuScreen navigation={navigation} company={{}} profile={myroute.profile as Profile} user={user} />

            {/*
                <TouchableOpacity
                    style={styles.addProfiles}
                    onPress={() => {
                    }}
                >
                    <View style={{alignSelf: 'center', flexDirection: 'row'}}>
                        <View style={{alignSelf: 'center', marginBottom: 3}}>
                            <CreditsSvg width={15} height={15}/>
                        </View>
                        <Text style={[styles.label, {paddingHorizontal: 10, paddingVertical: 15}]}>
                            {i18n.t('screens.profile.add_credits')}
                        </Text>
                    </View>
                </TouchableOpacity>


                <TouchableOpacity
                    style={styles.addProfiles}
                    onPress={() => {
                        navigation.navigate("AddProfile", myroute)
                    }}
                >
                    <View style={{alignSelf: 'center', flexDirection: 'row'}}>
                        <View style={{alignSelf: 'center', marginBottom: 3}}>
                            <Icon name={'user'} color={'#00CC83'}/>
                        </View>
                        <Text style={[styles.label, {paddingHorizontal: 10, paddingVertical: 15}]}>
                            {i18n.t('screens.profile.add_profiles')}
                        </Text>
                    </View>
                </TouchableOpacity>


                <TouchableOpacity
                    style={styles.logout}
                    onPress={logout}
                >
                    <View style={{alignSelf: 'center', flexDirection: 'row'}}>
                        <View style={{alignSelf: 'center', marginBottom: 3, transform: [{rotate: '180deg'}]}}>
                            <Icon name={'sign-out'} color={'white'}/>
                        </View>
                        <Text style={[styles.label, {paddingHorizontal: 10, paddingVertical: 15}]}>
                            {i18n.t('screens.profile.logout')}
                        </Text>
                    </View>
                </TouchableOpacity> */}
        </LinearGradient>
    );


}


const styles = StyleSheet.create({
    container: {
        marginTop: 50,
        marginHorizontal: 20,
    },
    containerScroll: {
        marginTop: 20,
        marginBottom: 20,
    },
    delimiter: {
        margin: 20,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#505050',
    },
    title: {
        color: '#00CC83',
        fontSize: 20,
        fontFamily: 'poppins',
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        padding: 15
    },
    col: {
        flex: 0.8

    },
    logout: {
        marginVertical: 5,
        marginHorizontal: 15,
        borderRadius: 5,
        backgroundColor: '#00CC83',
        flex: 1,
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    value: {
        fontFamily: 'poppins',
        color: '#737373',
        fontSize: 12
    },
    label: {
        fontFamily: 'poppins',
        color: 'white',
        fontSize: 16
    },
    edit: {
        marginLeft: 5,
        fontSize: 10,
        alignSelf: 'center',
        color: '#00CC83'
    },
});
