/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
    CompanyDataBlock,
    CompanyDataBlockFromJSON,
    CompanyDataBlockFromJSONTyped,
    CompanyDataBlockToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDataBlockDTO
 */
export interface CompanyDataBlockDTO {
    /**
     * 
     * @type {CompanyDataBlock}
     * @memberof CompanyDataBlockDTO
     */
    companyDataBlock?: CompanyDataBlock;
    /**
     * 
     * @type {CompanyData}
     * @memberof CompanyDataBlockDTO
     */
    companyData?: CompanyData;
}

export function CompanyDataBlockDTOFromJSON(json: any): CompanyDataBlockDTO {
    return CompanyDataBlockDTOFromJSONTyped(json, false);
}

export function CompanyDataBlockDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDataBlockDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyDataBlock': !exists(json, 'companyDataBlock') ? undefined : CompanyDataBlockFromJSON(json['companyDataBlock']),
        'companyData': !exists(json, 'companyData') ? undefined : CompanyDataFromJSON(json['companyData']),
    };
}

export function CompanyDataBlockDTOToJSON(value?: CompanyDataBlockDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyDataBlock': CompanyDataBlockToJSON(value.companyDataBlock),
        'companyData': CompanyDataToJSON(value.companyData),
    };
}


