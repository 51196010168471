/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PattiParasociali
 */
export interface PattiParasociali {
    /**
     * 
     * @type {string}
     * @memberof PattiParasociali
     */
    esercizioDirittoVoto?: string;
    /**
     * 
     * @type {string}
     * @memberof PattiParasociali
     */
    trasferimentoAzioniPartecip?: string;
    /**
     * 
     * @type {string}
     * @memberof PattiParasociali
     */
    esercizioInfluenzaDominante?: string;
    /**
     * 
     * @type {string}
     * @memberof PattiParasociali
     */
    altro?: string;
}

export function PattiParasocialiFromJSON(json: any): PattiParasociali {
    return PattiParasocialiFromJSONTyped(json, false);
}

export function PattiParasocialiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PattiParasociali {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'esercizioDirittoVoto': !exists(json, 'esercizioDirittoVoto') ? undefined : json['esercizioDirittoVoto'],
        'trasferimentoAzioniPartecip': !exists(json, 'trasferimentoAzioniPartecip') ? undefined : json['trasferimentoAzioniPartecip'],
        'esercizioInfluenzaDominante': !exists(json, 'esercizioInfluenzaDominante') ? undefined : json['esercizioInfluenzaDominante'],
        'altro': !exists(json, 'altro') ? undefined : json['altro'],
    };
}

export function PattiParasocialiToJSON(value?: PattiParasociali | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'esercizioDirittoVoto': value.esercizioDirittoVoto,
        'trasferimentoAzioniPartecip': value.trasferimentoAzioniPartecip,
        'esercizioInfluenzaDominante': value.esercizioInfluenzaDominante,
        'altro': value.altro,
    };
}


