/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SessioniAa,
    SessioniAaFromJSON,
    SessioniAaFromJSONTyped,
    SessioniAaToJSON,
    SessioniRdRea,
    SessioniRdReaFromJSON,
    SessioniRdReaFromJSONTyped,
    SessioniRdReaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Mad
 */
export interface Mad {
    /**
     * 
     * @type {SessioniRdRea}
     * @memberof Mad
     */
    sessioniRdRea?: SessioniRdRea;
    /**
     * 
     * @type {SessioniAa}
     * @memberof Mad
     */
    sessioniAa?: SessioniAa;
}

export function MadFromJSON(json: any): Mad {
    return MadFromJSONTyped(json, false);
}

export function MadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mad {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessioniRdRea': !exists(json, 'sessioniRdRea') ? undefined : SessioniRdReaFromJSON(json['sessioniRdRea']),
        'sessioniAa': !exists(json, 'sessioniAa') ? undefined : SessioniAaFromJSON(json['sessioniAa']),
    };
}

export function MadToJSON(value?: Mad | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessioniRdRea': SessioniRdReaToJSON(value.sessioniRdRea),
        'sessioniAa': SessioniAaToJSON(value.sessioniAa),
    };
}


