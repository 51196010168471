/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Localizzazione,
    LocalizzazioneFromJSON,
    LocalizzazioneFromJSONTyped,
    LocalizzazioneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Localizzazioni
 */
export interface Localizzazioni {
    /**
     * 
     * @type {Array<Localizzazione>}
     * @memberof Localizzazioni
     */
    localizzazione: Array<Localizzazione>;
}

export function LocalizzazioniFromJSON(json: any): Localizzazioni {
    return LocalizzazioniFromJSONTyped(json, false);
}

export function LocalizzazioniFromJSONTyped(json: any, ignoreDiscriminator: boolean): Localizzazioni {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localizzazione': ((json['localizzazione'] as Array<any>).map(LocalizzazioneFromJSON)),
    };
}

export function LocalizzazioniToJSON(value?: Localizzazioni | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localizzazione': ((value.localizzazione as Array<any>).map(LocalizzazioneToJSON)),
    };
}


