import { FontAwesome } from '@expo/vector-icons';
import { AlertHelper } from '@helpers/AlertHelper';
import { NavigationHelper } from '@helpers/NavigationHelper';
import ModalSimpleError from '@screens/Modal/ModalSimpleError';
import Icon from '@screens/Tools/Icon';
import { accountResourceApi, customAccountResourceApi } from '@services/apis/ApiConfiguration';
import { RegistrationDTO, UserExtendedTypeEnum, RegistrationDTOTypeEnum, RegistrationDTOCompanyRoleEnum, ActivateAccountRequest } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { RootStackScreenProps, UserExtendedType } from '../../../../types';
import {
    BackgroundColorButtonDarkLight,
    BackgroundColorInputText,
    ColorLabel,
    ColorPlaceHolder,
    ColorTextInput,
    ColorTextLightGreen
} from '../../../components/ColorTheme';
import { i18n } from '../../../i18n/i18n'
import { SplashLogoScreen } from '../../../navigation/SplashLogoScreen';
import Colors from "../../../../constants/Colors";

export default function ActivateAccountScreen({ navigation, route }: RootStackScreenProps<'ActivateAccount'>) {


    const [activationKey, setActivationKey] = React.useState<any>(route.params?.key);
    const [token, setToken] = React.useState<any>(route.params?.token)


    useEffect(() => {
        if (activationKey) {
            activateAccount();
        }
        return () => {
            setActivationKey('');
            setToken('');
        }
    }, [])

    async function activateAccount(){
        await customAccountResourceApi.activateAccountCustom({ key: { key: activationKey } as any }).then(()=>{
            if(token){
                navigation.navigate('ResetPassword', token);
            }
        }).catch(async (error) => {
            let errorJson = await error.json()
            let detail = errorJson.detail;
            if(detail == 'ALREADY_ACTIVE_USER' && token)
                navigation.navigate('ResetPassword', token);
        });      
    }


    return (
        <LinearGradient
            colors={Colors.gradient.baseGradient}
            style={styles.containerBack}
        >
            <StatusBar style="inverted" />
            <View style={styles.container}>
                <SplashLogoScreen welcome={true} />

                <Text style={[styles.activationMessage]}>
                    {i18n.t('screens.signup.activation_account')}
                </Text>
            </View>


        </LinearGradient >
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'column',
        marginHorizontal: 20,
        justifyContent: 'flex-start',
        fontFamily: 'poppins',
    },
    containerGoBack: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        borderRadius: 13,
        fontFamily: 'gotham',
        paddingBottom: 30
    },
    button: {
        marginTop: 30,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 20,
        borderRadius: 5,
        fontFamily: 'poppins',
        marginBottom: 30
    },
    text: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: ColorTextInput(),
    },
    goBack: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textSearch: {
        textAlign: 'left',
        fontSize: 20,
        fontFamily: 'poppins',
        color: '#00CC83',
        marginLeft: 20
    },
    activationMessage: {
        color: 'white',
        fontSize: 20,
    },
    containerBack: {
        flex: 1,
        display: 'flex',
        fontFamily: 'poppins',
    },
    buttonShowHide: {
        alignSelf: 'center',
        flex: 0.1,
    },
    containerTextInput: {
        flex: 0.7,
        justifyContent: 'flex-start',
        fontFamily: 'poppins',
    },
    labelTextInput: {
        alignSelf: 'flex-start',
        color: ColorLabel(),
        marginBottom: 5,
        fontFamily: 'poppins',
    },
    textInput: {
        backgroundColor: BackgroundColorInputText(),
        padding: 13,
        color: ColorTextInput(),
        borderRadius: 5,
        marginBottom: 15,
        fontFamily: 'poppins',
    },
    viewForgot: {
        alignSelf: 'flex-end',
        fontFamily: 'poppins',
    },
    textForgot: {
        color: ColorTextLightGreen(),
        fontFamily: 'poppins',
    },
    buttonLogin: {
        marginTop: 40,
        backgroundColor: BackgroundColorButtonDarkLight(),
        padding: 20,
        borderRadius: 5,
        fontFamily: 'poppins',
    },
    textLogin: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        color: 'white',
        fontFamily: 'poppins',
    },
    viewRegistration: {
        alignSelf: 'center',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginBottom: 20,
        flex: 0.1
    },
    textRegistration: {
        color: 'white',
        fontFamily: 'poppins',
    },
    error: {
        color: "#ff3333",
        fontSize: 12,
        marginHorizontal: 4
    },
});

