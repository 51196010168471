import { LinearGradient } from "expo-linear-gradient";
import React, { Component, useEffect, useState } from "react";
import { StatusBar } from 'expo-status-bar';
import { Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { RootStackScreenProps } from "../../../../../types";
import BusinessRating from "../BusinessRating/BusinessRating";
import AgilityChart from "../BusinessRating/AgilityChart";
import { i18n } from "@i18n/i18n";
import Icon from "@screens/Tools/Icon";
import TableBusinessRating from "../BusinessRating/TableBusinessRating";
import StrengthsRating from "../BusinessRating/StrengthsRating";
import WeaknessesRating from "../BusinessRating/WeaknessesRating";
import { customCompanySurveyResourceApi } from "@services/apis/ApiConfiguration";
import { AlertHelper } from "@helpers/AlertHelper";
import { BusinessRatingDTO, BusinessRatingTableDTOSectionTableEnum, CompanySurvey } from "@services/apis/generated";
import CollapsedBusinessRating from "../BusinessRating/CollapsedBusinessRating";
import Colors from "../../../../../constants/Colors";
import { ColorTextLightGreen } from "@components/ColorTheme";
import hykeeStyle from "@components/hykeeStyle";
import { NavigationHelper } from "@helpers/NavigationHelper";

export interface BusinessRatingTable {
  sectionTable: string,
  dataTable: any[][],
}

export default function BusinessRatingScreen({ navigation, route }: RootStackScreenProps<"BusinessRating">) {

  const [companySurvey, setCompanySurvey] = useState<CompanySurvey>(route.params as CompanySurvey);
  const [agilityChartValue, setAgilityChatValue] = useState<Number>();
  const [growthChart, setGrowthChart] = useState<Number>();
  const [attitudeChart, setAttitudeChart] = useState<Number>()
  const [businessRatingTables, setBusinessRatingTables] = useState<BusinessRatingTable[]>();
  const [collapsed, setCollapsed] = useState<Boolean>();

  useEffect(() => {
    getBusinessRatingTable();
  }, [route]);

  const getBusinessRatingTable = () => {
    customCompanySurveyResourceApi.getBusinessRatingTable({ companySurvey: companySurvey }).then((res) => {
      if (res) {
        setAgilityChatValue((res.agilityPerc! * 100));
        setGrowthChart((res.growthPerc! * 100));
        setAttitudeChart((res.attitudePerc! * 100));
        createTableBusinessRating(res);
      }
    }).catch(error => AlertHelper.showSimpleErrorAlert());
  }

  const createTableBusinessRating = (businessRatingDTO: BusinessRatingDTO) => {
    let businessRatingTables: BusinessRatingTable[] = [];
    businessRatingDTO.tables?.map((tab) => {
      let table: BusinessRatingTable = { sectionTable: '', dataTable: [] }
      table.sectionTable = tab.sectionTable!;
      tab.dataTable!.map((dataRow) => {
        let result = "Critical";
        // let result = i18n.t('screens.business_rating.critical');
        if (dataRow.score! / dataRow.maxScore! > 0.33) {
          result = "Weak";
        }
        if (dataRow.score! / dataRow.maxScore! > 0.66) {
          result = "Good";
        }
        let data = [dataRow.rowLabel, ((dataRow.score! / dataRow.maxScore!) * 100).toFixed(2) + "%", result];
        table.dataTable.push(data);
      })
      businessRatingTables.push(table);
    })
    setBusinessRatingTables(businessRatingTables);
  }
  const toggleExpanded = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LinearGradient
      colors={Colors.gradient.baseGradient}
      style={styles.containerBack}
    >
      <StatusBar style="inverted" />
      <View style={[hykeeStyle.container, hykeeStyle.directionRow]}>
        <TouchableOpacity
          style={hykeeStyle.goBack}
          onPress={() => NavigationHelper.navigateToGoBack(navigation)}>
          <Icon name={'angle-left'} size={24} color={ColorTextLightGreen()} />
        </TouchableOpacity>
        <Text style={styles.title}>
          {i18n.t('components.headers.business_rating')}
        </Text>
      </View>
      <ScrollView style={styles.container}>

        {/* <View style={styles.row}>
          <StrengthsRating />
          <WeaknessesRating />
        </View> */}
        <View style={[styles.table, { marginTop: 20 }]}>
          <CollapsedBusinessRating tab={businessRatingTables?.find((table) => {
            return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Area
          })} />
        </View>
        <View style={styles.table}>
          <View style={styles.table}>
            <AgilityChart value={agilityChartValue!} text={i18n.t("busines_rating.agility.agility")} />
          </View>
          <CollapsedBusinessRating
            tab={businessRatingTables?.find((table) => {
              return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Agility
            })} />
        </View>
        <View style={styles.table}>
          <View style={styles.table}>
            <AgilityChart value={growthChart!} text={i18n.t("busines_rating.growth.growth")} />
          </View>
          <CollapsedBusinessRating
            tab={businessRatingTables?.find((table) => {
              return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Growth
            })} />
        </View>
        <View style={[styles.table, { marginBottom: 30 }]}>
          <View style={styles.table}>
            <AgilityChart value={attitudeChart!} text={i18n.t("busines_rating.attitude.attitude")} />
          </View>
          <CollapsedBusinessRating
            tab={businessRatingTables?.find((table) => {
              return table.sectionTable == BusinessRatingTableDTOSectionTableEnum.Attitude
            })} />
        </View>
      </ScrollView>
    </LinearGradient>
  );

}

const styles = StyleSheet.create({
  containerBack: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontFamily: 'poppins',
    justifyContent: 'flex-start'
  },
  container: {
    marginHorizontal: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    right: 0,
  },
  containerList: {
    padding: 10
  },
  containerTitle: {
    marginTop: 50,
    flexDirection: "row",
    alignItems: 'center',
  },
  goBack: {
    marginRight: 20
  },
  contentLabel: {
    flex: Platform.OS == 'ios' ? 0.3 : 0.25,
    marginBottom: 10
  },
  contentTitle: {
    flexDirection: 'row',
  },
  title: {
    alignSelf: 'center',
    color: '#00CC83',
    fontSize: 20,
    flex: 1,
    fontFamily: 'poppins',
  },
  table: {
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
});