/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Riquadro
 */
export interface Riquadro {
    /**
     * 
     * @type {Array<object>}
     * @memberof Riquadro
     */
    tipoAttoOrComposizioneQuoteOrVincoliQuote?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Riquadro
     */
    c?: string;
    /**
     * 
     * @type {string}
     * @memberof Riquadro
     */
    dtAnnotazione?: string;
    /**
     * 
     * @type {string}
     * @memberof Riquadro
     */
    dtEvento?: string;
    /**
     * 
     * @type {string}
     * @memberof Riquadro
     */
    nricorrenze?: string;
}

export function RiquadroFromJSON(json: any): Riquadro {
    return RiquadroFromJSONTyped(json, false);
}

export function RiquadroFromJSONTyped(json: any, ignoreDiscriminator: boolean): Riquadro {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipoAttoOrComposizioneQuoteOrVincoliQuote': !exists(json, 'tipoAttoOrComposizioneQuoteOrVincoliQuote') ? undefined : json['tipoAttoOrComposizioneQuoteOrVincoliQuote'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'dtAnnotazione': !exists(json, 'dtAnnotazione') ? undefined : json['dtAnnotazione'],
        'dtEvento': !exists(json, 'dtEvento') ? undefined : json['dtEvento'],
        'nricorrenze': !exists(json, 'nricorrenze') ? undefined : json['nricorrenze'],
    };
}

export function RiquadroToJSON(value?: Riquadro | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipoAttoOrComposizioneQuoteOrVincoliQuote': value.tipoAttoOrComposizioneQuoteOrVincoliQuote,
        'c': value.c,
        'dtAnnotazione': value.dtAnnotazione,
        'dtEvento': value.dtEvento,
        'nricorrenze': value.nricorrenze,
    };
}


