/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoriaOpere,
    CategoriaOpereFromJSON,
    CategoriaOpereFromJSONTyped,
    CategoriaOpereToJSON,
} from './';

/**
 * 
 * @export
 * @interface CategorieOpere
 */
export interface CategorieOpere {
    /**
     * 
     * @type {Array<CategoriaOpere>}
     * @memberof CategorieOpere
     */
    categoriaOpere: Array<CategoriaOpere>;
    /**
     * 
     * @type {string}
     * @memberof CategorieOpere
     */
    cfonte?: string;
}

export function CategorieOpereFromJSON(json: any): CategorieOpere {
    return CategorieOpereFromJSONTyped(json, false);
}

export function CategorieOpereFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategorieOpere {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoriaOpere': ((json['categoriaOpere'] as Array<any>).map(CategoriaOpereFromJSON)),
        'cfonte': !exists(json, 'cfonte') ? undefined : json['cfonte'],
    };
}

export function CategorieOpereToJSON(value?: CategorieOpere | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoriaOpere': ((value.categoriaOpere as Array<any>).map(CategoriaOpereToJSON)),
        'cfonte': value.cfonte,
    };
}


