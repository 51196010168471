/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuoloPersona
 */
export interface RuoloPersona {
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    tipo?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    categoria?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    qualifica?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    forma?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    enteRilascio?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    provincia?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    cqualifica?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    centeRilascio?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    fsezioneRea?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    ccategoria?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    cforma?: string;
    /**
     * 
     * @type {string}
     * @memberof RuoloPersona
     */
    ctipo?: string;
}

export function RuoloPersonaFromJSON(json: any): RuoloPersona {
    return RuoloPersonaFromJSONTyped(json, false);
}

export function RuoloPersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuoloPersona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'categoria': !exists(json, 'categoria') ? undefined : json['categoria'],
        'qualifica': !exists(json, 'qualifica') ? undefined : json['qualifica'],
        'forma': !exists(json, 'forma') ? undefined : json['forma'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'enteRilascio': !exists(json, 'enteRilascio') ? undefined : json['enteRilascio'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'cqualifica': !exists(json, 'cqualifica') ? undefined : json['cqualifica'],
        'centeRilascio': !exists(json, 'centeRilascio') ? undefined : json['centeRilascio'],
        'fsezioneRea': !exists(json, 'fsezioneRea') ? undefined : json['fsezioneRea'],
        'ccategoria': !exists(json, 'ccategoria') ? undefined : json['ccategoria'],
        'cforma': !exists(json, 'cforma') ? undefined : json['cforma'],
        'ctipo': !exists(json, 'ctipo') ? undefined : json['ctipo'],
    };
}

export function RuoloPersonaToJSON(value?: RuoloPersona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tipo': value.tipo,
        'categoria': value.categoria,
        'qualifica': value.qualifica,
        'forma': value.forma,
        'n': value.n,
        'dtIscrizione': value.dtIscrizione,
        'enteRilascio': value.enteRilascio,
        'provincia': value.provincia,
        'cqualifica': value.cqualifica,
        'centeRilascio': value.centeRilascio,
        'fsezioneRea': value.fsezioneRea,
        'ccategoria': value.ccategoria,
        'cforma': value.cforma,
        'ctipo': value.ctipo,
    };
}


