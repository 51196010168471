/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonaGiuridicaPrivata
 */
export interface PersonaGiuridicaPrivata {
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridicaPrivata
     */
    registro?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridicaPrivata
     */
    ente?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridicaPrivata
     */
    n?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridicaPrivata
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaGiuridicaPrivata
     */
    faccertamentoRequisiti?: string;
}

export function PersonaGiuridicaPrivataFromJSON(json: any): PersonaGiuridicaPrivata {
    return PersonaGiuridicaPrivataFromJSONTyped(json, false);
}

export function PersonaGiuridicaPrivataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaGiuridicaPrivata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registro': !exists(json, 'registro') ? undefined : json['registro'],
        'ente': !exists(json, 'ente') ? undefined : json['ente'],
        'n': !exists(json, 'n') ? undefined : json['n'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'faccertamentoRequisiti': !exists(json, 'faccertamentoRequisiti') ? undefined : json['faccertamentoRequisiti'],
    };
}

export function PersonaGiuridicaPrivataToJSON(value?: PersonaGiuridicaPrivata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registro': value.registro,
        'ente': value.ente,
        'n': value.n,
        'dtIscrizione': value.dtIscrizione,
        'faccertamentoRequisiti': value.faccertamentoRequisiti,
    };
}


