// 
// 
//
import hykeeStyle from '@components/hykeeStyle';
import { AlertHelper } from '@helpers/AlertHelper';
import { customCompanyLinkRequestResourceApi } from '@services/apis/ApiConfiguration';
import { Company, CompanyLinkRequest, CompanyLinkRequestCurrentStateEnum, CompanyLinkRequestStateStateEnum, UserExtendedTypeEnum } from '@services/apis/generated';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import React, { } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { i18n } from '../../../i18n/i18n';
import SharedContext from '../../../navigation/SharedContext';
import Icon from '../../Tools/Icon';


export default function CompaniesConsultedPendingList(props: { goToCompany: (company: Company) => void }) {

  const sharedContext = React.useContext(SharedContext)

  const RejectRequest = (companyLinkRequest: CompanyLinkRequest) => {
    customCompanyLinkRequestResourceApi.updateCompanyLinkRequestState1({ id: companyLinkRequest.id!, companyLinkRequestState: { state: CompanyLinkRequestStateStateEnum.Rejected } })
      .then((response) => {
        if (response)
          customCompanyLinkRequestResourceApi.getAllCompanyLinkRequestsByConsultant({ id: sharedContext.myCompanySelected?.id! })
            .then((res) => {
              sharedContext.setMyCompaniesConsultedRequest(res);
              sharedContext.setLinkButton(false)
              console.log(res)
            })
            .catch(error => AlertHelper.showSimpleErrorAlert());
      })
      .catch(error => AlertHelper.showSimpleErrorAlert());
  }

  return (
    <>
      {sharedContext.myCompaniesConsultedRequest
        && sharedContext.who == UserExtendedTypeEnum.Consultant
        && sharedContext.myCompaniesConsultedRequest.length > 0
        && sharedContext.myCompaniesConsultedRequest.find(
          (companyLinkRequest) => companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Pending) != undefined ?
        sharedContext.myCompaniesConsultedRequest.map((companyLinkRequest, key) => {
          return (
            <View key={key}>
              {companyLinkRequest.currentState == CompanyLinkRequestCurrentStateEnum.Pending &&
                <LinearGradient
                  key={companyLinkRequest.company?.id}
                  colors={['rgba(0, 0, 0, 1) ', 'rgba(128, 128, 128, 1) ']}
                  locations={[0.4, 0.8]}
                  start={{ x: 1, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  style={styles.borderGradient}
                >
                  <LinearGradient
                    colors={['rgba(100, 100, 100, 1) ', 'rgba(27, 27, 27, 1)']}
                    start={{ x: 1, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    style={styles.containerBack}
                  >
                    <View style={hykeeStyle.col} >
                      <TouchableOpacity style={hykeeStyle.col}
                        onPress={() => {
                          props.goToCompany(companyLinkRequest.company!)
                        }}>
                        <Text style={hykeeStyle.textLabel}>
                          {companyLinkRequest.company?.name}
                        </Text>
                        <View style={styles.contentLocation}>
                          <Icon name={'calendar'} color={'#00CC83'} />
                          <Text style={styles.textUnderTable}>
                            {moment(companyLinkRequest.date).format("DD/MM/yyyy")}
                          </Text>
                          <Icon name={'link'} color={'#00CC83'} />
                          <Text style={styles.textUnderTable}>
                            {i18n.t('components.link_companies_data.status') + companyLinkRequest.currentState}
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <View style={styles.row}>
                        <TouchableOpacity style={[hykeeStyle.buttonReject, { flex: 0.45 }]}
                          onPress={() => {
                            RejectRequest(companyLinkRequest)
                          }}>
                          <Text style={styles.textButton}>
                            {i18n.t('components.link_companies_data.button_deleted')}
                          </Text>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={[hykeeStyle.buttonDetails, { flex: 0.45 }]}
                          onPress={() => {
                            props.goToCompany(companyLinkRequest.company!)
                          }}>
                          <Text style={styles.textButton}>
                            {i18n.t('components.link_companies_data.button_detail')}
                          </Text>
                        </TouchableOpacity> */}
                      </View>
                    </View>
                  </LinearGradient>
                </LinearGradient>}
            </View>)
        })
        :
        <View style={styles.containerList}>
          <Text style={styles.textLabel}>

            {i18n.t('components.link_companies_data.no_pending')}
          </Text>
        </View>

      }
    </>
  );
}

const styles = StyleSheet.create({
  borderGradient: {
    display: 'flex',
    fontFamily: 'poppins',
    marginBottom: 20,
    borderRadius: 13,
  },
  containerList: {
    marginBottom: 20,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 10,
  },
  textButton: {
    color: "white",
    fontFamily: "poppins",
    fontSize: 13,
    textAlign: 'center',
    padding: 5,
    paddingVertical: 10,
  },
  textLabel: {
    marginTop: 10,
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    fontSize: 18,
    padding: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  containerBack: {
    fontFamily: 'poppins',
    padding: 8,
    margin: 1,
    borderRadius: 13,
    overflow: 'hidden'
  },
  contentLocation: {
    flexDirection: 'row',
    alignContent: 'center',
    padding: 5,
  },
  textUnderTable: {
    alignSelf: 'flex-start',
    fontSize: 13,
    flex: 0.5,
    paddingHorizontal: 5,
    fontFamily: 'poppins',
    color: 'white'
  },
  contentImg: {
    flex: 0.3,
  },
});


