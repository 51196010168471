/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Macchinari
 */
export interface Macchinari {
    /**
     * 
     * @type {string}
     * @memberof Macchinari
     */
    caratteristica1?: string;
    /**
     * 
     * @type {string}
     * @memberof Macchinari
     */
    caratteristica2?: string;
    /**
     * 
     * @type {string}
     * @memberof Macchinari
     */
    caratteristica3?: string;
    /**
     * 
     * @type {string}
     * @memberof Macchinari
     */
    caratteristica4?: string;
    /**
     * 
     * @type {string}
     * @memberof Macchinari
     */
    fapparecchiPulitura?: string;
}

export function MacchinariFromJSON(json: any): Macchinari {
    return MacchinariFromJSONTyped(json, false);
}

export function MacchinariFromJSONTyped(json: any, ignoreDiscriminator: boolean): Macchinari {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caratteristica1': !exists(json, 'caratteristica1') ? undefined : json['caratteristica1'],
        'caratteristica2': !exists(json, 'caratteristica2') ? undefined : json['caratteristica2'],
        'caratteristica3': !exists(json, 'caratteristica3') ? undefined : json['caratteristica3'],
        'caratteristica4': !exists(json, 'caratteristica4') ? undefined : json['caratteristica4'],
        'fapparecchiPulitura': !exists(json, 'fapparecchiPulitura') ? undefined : json['fapparecchiPulitura'],
    };
}

export function MacchinariToJSON(value?: Macchinari | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caratteristica1': value.caratteristica1,
        'caratteristica2': value.caratteristica2,
        'caratteristica3': value.caratteristica3,
        'caratteristica4': value.caratteristica4,
        'fapparecchiPulitura': value.fapparecchiPulitura,
    };
}


