/* tslint:disable */
/* eslint-disable */
/**
 * Hykee Backend API
 * Hykee Backend API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sezioni,
    SezioniFromJSON,
    SezioniFromJSONTyped,
    SezioniToJSON,
} from './';

/**
 * 
 * @export
 * @interface IscrizioneRi
 */
export interface IscrizioneRi {
    /**
     * 
     * @type {Sezioni}
     * @memberof IscrizioneRi
     */
    sezioni?: Sezioni;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    provinciaRi?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    cciaaCompetente?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    dtIscrizione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    dtAnnotazione?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    nannotazioneRi?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    nannotazioneRiOld?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    ncfiscale?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    niscrizioneRi?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    niscrizioneRiOld?: string;
    /**
     * 
     * @type {string}
     * @memberof IscrizioneRi
     */
    cniscrizioneRiOld?: string;
}

export function IscrizioneRiFromJSON(json: any): IscrizioneRi {
    return IscrizioneRiFromJSONTyped(json, false);
}

export function IscrizioneRiFromJSONTyped(json: any, ignoreDiscriminator: boolean): IscrizioneRi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sezioni': !exists(json, 'sezioni') ? undefined : SezioniFromJSON(json['sezioni']),
        'provinciaRi': !exists(json, 'provinciaRi') ? undefined : json['provinciaRi'],
        'cciaaCompetente': !exists(json, 'cciaaCompetente') ? undefined : json['cciaaCompetente'],
        'dtIscrizione': !exists(json, 'dtIscrizione') ? undefined : json['dtIscrizione'],
        'dtAnnotazione': !exists(json, 'dtAnnotazione') ? undefined : json['dtAnnotazione'],
        'nannotazioneRi': !exists(json, 'nannotazioneRi') ? undefined : json['nannotazioneRi'],
        'nannotazioneRiOld': !exists(json, 'nannotazioneRiOld') ? undefined : json['nannotazioneRiOld'],
        'ncfiscale': !exists(json, 'ncfiscale') ? undefined : json['ncfiscale'],
        'niscrizioneRi': !exists(json, 'niscrizioneRi') ? undefined : json['niscrizioneRi'],
        'niscrizioneRiOld': !exists(json, 'niscrizioneRiOld') ? undefined : json['niscrizioneRiOld'],
        'cniscrizioneRiOld': !exists(json, 'cniscrizioneRiOld') ? undefined : json['cniscrizioneRiOld'],
    };
}

export function IscrizioneRiToJSON(value?: IscrizioneRi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sezioni': SezioniToJSON(value.sezioni),
        'provinciaRi': value.provinciaRi,
        'cciaaCompetente': value.cciaaCompetente,
        'dtIscrizione': value.dtIscrizione,
        'dtAnnotazione': value.dtAnnotazione,
        'nannotazioneRi': value.nannotazioneRi,
        'nannotazioneRiOld': value.nannotazioneRiOld,
        'ncfiscale': value.ncfiscale,
        'niscrizioneRi': value.niscrizioneRi,
        'niscrizioneRiOld': value.niscrizioneRiOld,
        'cniscrizioneRiOld': value.cniscrizioneRiOld,
    };
}


